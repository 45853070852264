import { useRef } from 'react';
import { useIntl } from 'app/i18n';
import { useOnClickOutside } from 'usehooks-ts';
import { Accordion, Button, Checkbox, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { Panel } from '@fluentui/react/lib/Panel';
import {
  useAppDispatch,
  useAppSelector,
  useControlledFilterDropDown,
  ControlledFilterDropDown,
  selectIsOnMobile,
  FilterItem,
  FilterItemType,
  FilterDropdown,
  SENDING_STATUS_FILTER_ITEMS,
  useResetFilterQueriesOnPanelClose,
  getSendingStatusLocalizationFromStatusValue
} from 'app/common';
import {
  clearAllSendingFilters,
  closeFilterPanel,
  selectFilterItems,
  selectIsFilterPanelOpen,
  firstPageOfSendingsRequested,
  addSendingsFilter,
  removeSendingsFilter,
  startLoadingSendingTitleSuggestions,
  sendingsTitlesSuggestionsRequested,
  startLoadingCreatedBySuggestions,
  sendingsCreatedBySuggestionsRequested,
  startLoadingMediumNameSuggestions,
  sendingsMediumNameSuggestionsRequested,
  sendingsRecipientNameOrEmailAddressSuggestionsRequested,
  startLoadingRecipientNameOrEmailAddressSuggestions,
  sendingsEmailSubjectSuggestionsRequested,
  startLoadingSendingEmailSubjectSuggestions,
  sendingsTagSuggestionsRequested,
  startLoadingTagNameSuggestions,
  selectSendingTitlesSuggestions,
  selectIsLoadingTitleSuggestions,
  selectSendingCreatedBySuggestions,
  selectIsLoadingCreatedBySuggestions,
  selectSendingEmailSubjectSuggestions,
  selectIsLoadingEmailSubjectSuggestions,
  selectSendingTagSuggestions,
  selectIsLoadingTagNameSuggestions,
  removeDateFilters,
  SendingStatus,
  toggleFavouriteFilter
} from 'app/pages/my-activities/sendings';
import { CreatedAtFilter, RecipientsFilters } from 'app/pages/my-activities/sending-filter-search';
import { SENDING_FILTER } from 'app/pages/my-activities/sending-filter-search/filter-fields';

const isFilterAlreadyPresent = (filterItems: FilterItem[], filter: FilterItem) =>
  filterItems.some((item) => item.fieldName === filter.fieldName && item.value === filter.value);

export const SendingFiltersPanel = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const isFiltersPanelOpened = useAppSelector(selectIsFilterPanelOpen);
  const filterItems = useAppSelector(selectFilterItems);
  const isOnMobile = useAppSelector(selectIsOnMobile);

  const sendingTitleControl = useControlledFilterDropDown(sendingsTitlesSuggestionsRequested, startLoadingSendingTitleSuggestions);
  const createdByControl = useControlledFilterDropDown(sendingsCreatedBySuggestionsRequested, startLoadingCreatedBySuggestions);
  const sendingsMediumNameControl = useControlledFilterDropDown(sendingsMediumNameSuggestionsRequested, startLoadingMediumNameSuggestions);
  const sendingsRecipientNameOrEmailAddressControl = useControlledFilterDropDown(
    sendingsRecipientNameOrEmailAddressSuggestionsRequested,
    startLoadingRecipientNameOrEmailAddressSuggestions
  );
  const sendingsEmailSubjectControl = useControlledFilterDropDown(sendingsEmailSubjectSuggestionsRequested, startLoadingSendingEmailSubjectSuggestions);
  const tagNameControl = useControlledFilterDropDown(sendingsTagSuggestionsRequested, startLoadingTagNameSuggestions);

  const { resetQueries } = useResetFilterQueriesOnPanelClose(
    [sendingTitleControl, createdByControl, sendingsMediumNameControl, sendingsRecipientNameOrEmailAddressControl, sendingsEmailSubjectControl, tagNameControl],
    isFiltersPanelOpened
  );

  const handleDismissFilterPanel = () => dispatch(closeFilterPanel());

  const handleFilterAdd = (filterItem: FilterItemType) => {
    if (isFilterAlreadyPresent(filterItems, filterItem)) {
      return;
    }
    dispatch(addSendingsFilter(new FilterItem(filterItem.fieldName, filterItem.value)));
    dispatch(firstPageOfSendingsRequested());
  };

  const handleFilterRemoval = ({ fieldName, value }: FilterItemType) => {
    dispatch(removeSendingsFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfSendingsRequested());
  };

  const handleCreatedAtFilter = ({ fieldName, value }: FilterItemType) => {
    dispatch(removeDateFilters());
    handleFilterAdd({ fieldName, value });
  };

  const handleClearAll = () => {
    dispatch(clearAllSendingFilters());
    dispatch(firstPageOfSendingsRequested());

    resetQueries();
  };

  useOnClickOutside(ref, handleDismissFilterPanel);

  const filters = [
    {
      order: 1,
      key: 'item-title',
      name: SENDING_FILTER.TITLE,
      displayName: formatMessage({ id: 'emailings-labels.email-title' }),
      placeholder: formatMessage({ id: 'filters.filter-by-email-title' }),
      suggestionsSelector: selectSendingTitlesSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingTitleSuggestions,
      control: sendingTitleControl
    },
    {
      order: 2,
      key: 'item-createdBy',
      name: SENDING_FILTER.CREATED_BY,
      displayName: formatMessage({ id: 'emailings-labels.modified-by' }),
      placeholder: formatMessage({ id: 'emailings-labels.filter-modified-by' }),
      suggestionsSelector: selectSendingCreatedBySuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingCreatedBySuggestions,
      control: createdByControl
    },
    {
      order: 6,
      key: 'item-email',
      name: SENDING_FILTER.SUBJECT,
      displayName: formatMessage({ id: 'emailings-labels.subject' }),
      placeholder: formatMessage({ id: 'filters.filter-by-subject' }),
      suggestionsSelector: selectSendingEmailSubjectSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingEmailSubjectSuggestions,
      control: sendingsEmailSubjectControl
    },
    {
      order: 7,
      key: 'item-tag',
      name: SENDING_FILTER.TAG,
      displayName: formatMessage({ id: 'emailings-labels.tags' }),
      placeholder: formatMessage({ id: 'filters.filter-by-tag' }),
      suggestionsSelector: selectSendingTagSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingTagNameSuggestions,
      control: tagNameControl
    }
  ];

  const handleStatusFilterAdd = (filterItem: FilterItem) => {
    const fieldName = SENDING_FILTER.STATUS;
    let value: string;
    switch (filterItem.value) {
      case formatMessage({ id: 'emailings-table.filters.draft' }):
        value = SendingStatus.Draft.toString();
        break;
      case formatMessage({ id: 'emailings-table.filters.waiting-for-distribution' }):
        value = SendingStatus.WaitingForDistribution.toString();
        break;
      case formatMessage({ id: 'emailings-table.filters.ongoing-distribution' }):
        value = SendingStatus.OngoingDistribution.toString();
        break;
      case formatMessage({ id: 'emailings-table.filters.incomplete-distribution' }):
        value = SendingStatus.IncompleteDistribution.toString();
        break;
      case formatMessage({ id: 'emailings-table.filters.successful-distribution' }):
        value = SendingStatus.SuccessfulDistribution.toString();
        break;
      default:
        value = '';
    }

    if (value) {
      handleFilterAdd({ fieldName, value });
    }
  };

  const handleFavoriteFilterCheck = () => {
    dispatch(toggleFavouriteFilter());
    dispatch(firstPageOfSendingsRequested());
  };

  const allSendingFilterItems = SENDING_STATUS_FILTER_ITEMS.map((item) => ({
    ...item,
    fieldName: formatMessage({ id: item.fieldName })
  }));
  const activeSendingFilterItems = filterItems
    .filter((fi) => fi.fieldName === SENDING_FILTER.STATUS)
    .map((item) => ({ ...item, fieldName: formatMessage({ id: getSendingStatusLocalizationFromStatusValue(item.value) }) }));

  const isFavoritesFilterActive = filterItems.some((fi) => fi.fieldName === SENDING_FILTER.FAVOURITE);

  const panels = [
    ...filters.map(({ order, key, name, displayName, placeholder, control, suggestionsSelector, isLoadingSuggestionsSelector }) => ({
      order,
      title: (
        <div className="accordion-head-box" key={`${key}-title`}>
          <span className="accordion-heading">{displayName}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: `${key}-content`,
        content: (
          <ControlledFilterDropDown
            name={name}
            placeholder={placeholder}
            onFilterAdd={handleFilterAdd}
            onFilterRemoval={handleFilterRemoval}
            suggestionsSelector={suggestionsSelector}
            isLoadingSuggestionsSelector={isLoadingSuggestionsSelector}
            items={filterItems.filter((filterItem) => filterItem.fieldName === name)}
            {...control}
          />
        )
      },
      className: 'filter-accordion-item'
    })),
    {
      order: 3,
      title: (
        <div className="accordion-head-box" key="item-createdAt">
          <span className="accordion-heading">{formatMessage({ id: 'emailings-labels.creation-date' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'item-createdAt-content',
        content: <CreatedAtFilter onFilter={handleCreatedAtFilter} />
      },
      className: 'filter-accordion-item'
    },
    {
      order: 4,
      title: (
        <div className="accordion-head-box" key="item-recipient">
          <span className="accordion-heading">{formatMessage({ id: 'emailings-labels.recipients' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'item-recipient-content',
        content: (
          <RecipientsFilters
            onFilterAdd={handleFilterAdd}
            onFilterRemoval={handleFilterRemoval}
            activeRecipientMediumNameFilters={filterItems.filter((fi) => fi.fieldName === 'mediumName')}
            activeRecipientNameOrEmailAddressFilters={filterItems.filter((fi) => fi.fieldName === 'emailOrName')}
            recipientMediumNameControl={sendingsMediumNameControl}
            recipientNameOrEmailAddressControl={sendingsRecipientNameOrEmailAddressControl}
          />
        )
      },
      className: 'filter-accordion-item'
    },
    {
      order: 8,
      title: (
        <div className="accordion-head-box" key="item-status">
          <span className="accordion-heading">{formatMessage({ id: 'emailings-labels.status' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'item-status-content',
        content: (
          <FilterDropdown
            name={SENDING_FILTER.STATUS}
            placeholder={formatMessage({ id: 'filters.filter-by-status' })}
            onFilterAdd={handleStatusFilterAdd}
            onFilterRemoval={handleFilterRemoval}
            allFilterItems={allSendingFilterItems}
            activeFilterItems={activeSendingFilterItems}
          />
        )
      },
      className: 'filter-accordion-item'
    },
    {
      order: 9,
      title: (
        <div className="accordion-head-box" key="item-favorite">
          <span className="accordion-heading">{formatMessage({ id: 'emailings-labels.favorites' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'item-favorite-content',
        content: (
          <div className="filter-panel-wrap">
            <Checkbox
              defaultChecked={isFavoritesFilterActive}
              onClick={handleFavoriteFilterCheck}
              label={formatMessage({ id: 'filters.filter-by-favorites' })}
            />
          </div>
        )
      },
      className: 'filter-accordion-item'
    }
  ].sort((a, b) => a.order - b.order);

  return (
    <div className="wrapper" ref={ref}>
      <Panel
        isBlocking={false}
        customWidth="340px"
        className="filter-panel"
        headerText={formatMessage({ id: 'filters.filters' })}
        isOpen={isFiltersPanelOpened}
        onDismiss={handleDismissFilterPanel}
        closeButtonAriaLabel="Close"
      >
        <div className="filter-panel-accordions">
          <Accordion panels={panels} />
        </div>
        <div className="filter-panel-actions-wrap">
          {filterItems.length > 0 && (
            <div className="filter-panel-actions-holder">
              <div className="filter-panel-actions">
                <Button className="filter-panel-actions-clear" text content={formatMessage({ id: 'filters.clear' })} onClick={handleClearAll} />
              </div>
              {isOnMobile && (
                <div className="filter-panel-actions">
                  <Button className="filter-panel-actions-apply" primary content={formatMessage({ id: 'buttons.apply' })} onClick={handleDismissFilterPanel} />
                </div>
              )}
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};
