import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { getNames } from 'country-list';
import {
  useAppDispatch,
  useAppSelector,
  WizardIntro,
  regEmail,
  regNumbers,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  isPhoneNumberValidOrEmpty,
  isValidUrlField,
  regUrl
} from 'app/common';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import {
  CreateCompanyFooter,
  CreateCompanySteps,
  closeCreateCompanyWizard,
  goToNextCompanyStep,
  goToPreviousCompanyStep,
  setCompanyAddressInfo,
  selectCompanyAddressInfo,
  addCompanyAddressInfo
} from 'app/pages/my-audience/wizard-create-contact';
import { CompanyAddressInfo } from 'app/pages/my-audience/contact-profile';
import { FormattedMessage, useIntl } from 'app/i18n';

export type CreateCompanyAddressInfoForm = {
  address: string;
  poBox: string;
  postalCode: string;
  city: string;
  country: string;
  websiteUrl: string;
  emailAddress: string;
  phoneNumber: string;
  fax: string;
};

export const CreateCompanyAddressInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const companyAddressInfo = useAppSelector<CompanyAddressInfo>(selectCompanyAddressInfo);

  const { handleSubmit, control, watch } = useForm<CreateCompanyAddressInfoForm, any>({
    defaultValues: {
      address: companyAddressInfo.address,
      poBox: companyAddressInfo.poBox,
      postalCode: companyAddressInfo.postalCode,
      city: companyAddressInfo.city,
      country: companyAddressInfo.country,
      websiteUrl: companyAddressInfo.websiteUrl,
      emailAddress: companyAddressInfo.emailAddress,
      phoneNumber: companyAddressInfo.phoneNumber,
      fax: companyAddressInfo.fax
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  useEffect(() => {
    return submitForm;
  }, []);

  const handleNextButtonClick = () => {
    dispatch(goToNextCompanyStep());
  };

  const submitForm = () => {
    handleSubmit(
      (data) => {
        const newCompanyAddressInfo = setCompanyAddressInfo(data);
        dispatch(addCompanyAddressInfo(newCompanyAddressInfo));
      },
      (error) => {
        Sentry.setExtra('Path', CreateCompanyAddressInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousCompanyStep());
  };

  const areGeneralInfoFieldsValid =
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('phoneNumber')) &&
    isValidUrlField(watch('websiteUrl'));

  return (
    <>
      <CreateCompanySteps areFieldsValid={areGeneralInfoFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.company-address-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput name="address" label={formatMessage({ id: 'add-menu-create-contact.company-address-label' })} control={control} />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="poBox" label={formatMessage({ id: 'add-menu-create-contact.company-po-label' })} control={control} />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="postalCode"
              label={formatMessage({ id: 'add-menu-create-contact.company-postal-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regNumbers,
                  message: formatMessage({ id: 'error-messages.not-valid-postal-code' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="city" label={formatMessage({ id: 'add-menu-create-contact.company-city-label' })} control={control} />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-contact.company-country-label'} />
                </span>
              }
              name="country"
              items={getNames()}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.company-country-placeholder' })}
              defaultValue={companyAddressInfo.country}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="websiteUrl"
              label={formatMessage({ id: 'add-menu-create-contact.company-website-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="emailAddress"
              label={formatMessage({ id: 'add-menu-create-contact.company-email-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="phoneNumber"
              label={formatMessage({ id: 'add-menu-create-contact.company-phone-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="fax" label={formatMessage({ id: 'add-menu-create-contact.company-fax-label' })} control={control} />
          </div>
        </div>
        <CreateCompanyFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areGeneralInfoFieldsValid}
        />
      </div>
    </>
  );
};
