import { Doughnut } from 'react-chartjs-2';
import { CHART_CUTOUT, CHART_GREY_COLOR, CHART_OPTIONS, CHART_PURPLE_COLOR, selectIsOnDesktop, useAppSelector } from 'app/common';
import { ChartData, ChartOptions } from 'chart.js';

type AugDoughnutProps = {
  percentage?: number;
  data?: ChartData<'doughnut', number[], unknown>;
  width?: number;
  height?: number;
  options?: ChartOptions;
  onClick?: () => void;
};

export const DoughnutWrapper = ({ data, percentage = 0, width = 150, height = 100, options = CHART_OPTIONS, onClick }: AugDoughnutProps) => {
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const getChartDataForPercentage = (percentage: number) => ({
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [CHART_PURPLE_COLOR, CHART_GREY_COLOR],
        borderColor: [CHART_PURPLE_COLOR, CHART_GREY_COLOR],
        borderWidth: isOnDesktop ? 1 : 13,
        spacing: 0,
        cutout: CHART_CUTOUT
      }
    ]
  });

  return <Doughnut data={data ?? getChartDataForPercentage(percentage)} width={width} height={height} options={options} onClick={onClick} />;
};
