import { selectIsOnDesktop, selectSearchResultsColumnNumber, ShimmerTable, useAppDispatch, useAppSelector } from 'app/common';
import { SendingPanel } from 'app/pages/my-activities/sending-profile';
import {
  closeProfilePanel,
  getSelectedSendingRequested,
  pageOfRecipientsRequested,
  selectIsProfilePanelOpen,
  SendingWizard,
  setEmailPanelActiveIndex,
  updateSendingRequested
} from 'app/pages/my-activities/sending-wizard';
import {
  selectIsLoadingSendings,
  selectSendings,
  Sending,
  setSendingsTableHeader,
  SendingsTableWrap,
  buildLocalizedSendingsColumns,
  setSelectedSendingsIds,
  duplicateSendingRequested,
  selectDuplicatedSendingId,
  selectShouldNavigateToDuplicatedSending,
  shouldNavigateToDuplicatedSending
} from 'app/pages/my-activities/sendings';
import { TemplatesPreview } from 'app/pages/my-activities/templates';
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SendingFiltersPanel } from 'app/pages/my-activities/sending-filter-search';
import emptyEmailingsImage from 'assets/images/empty-sending.png';
import { useIntl } from 'app/i18n';
import 'app/pages/my-activities/sendings/components/Sendings.scss';

export const Sendings: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const columnNumber = useAppSelector<number>(selectSearchResultsColumnNumber);
  const isProfilePanelOpen = useAppSelector<boolean>(selectIsProfilePanelOpen);
  const sendingsStore = useAppSelector<Sending[]>(selectSendings);
  const isLoadingSendings = useAppSelector<boolean>(selectIsLoadingSendings);
  const duplicatedSendingId = useAppSelector<string>(selectDuplicatedSendingId);
  const shouldNavigate = useAppSelector<boolean>(selectShouldNavigateToDuplicatedSending);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  useEffect(() => {
    const tableHeader = buildLocalizedSendingsColumns(
      formatMessage({ id: 'emailings-labels.status' }),
      formatMessage({ id: 'emailings-labels.email-title' }),
      formatMessage({ id: 'emailings-labels.created-at' }),
      formatMessage({ id: 'emailings-labels.modified-at' }),
      formatMessage({ id: 'emailings-labels.modified-by' }),
      formatMessage({ id: 'emailings-labels.number-of-recipients' }),
      formatMessage({ id: 'emailings-labels.blacklists' }),
      formatMessage({ id: 'emailings-labels.subject' }),
      formatMessage({ id: 'search-results-table.sort-ascending' }),
      formatMessage({ id: 'search-results-table.sort-descending' })
    ).map((header, i) => ({
      ...header,
      isColumnVisible: i < columnNumber
    }));
    dispatch(setSendingsTableHeader(tableHeader));
  }, [columnNumber, dispatch, formatMessage]);

  const onCloseSendingPanel = () => {
    dispatch(closeProfilePanel());
    dispatch(setEmailPanelActiveIndex(0));
    dispatch(setSelectedSendingsIds([]));
    navigate('/my-activities/e-mailings');
  };

  const handleEditSending = (sendingId: string) => {
    dispatch(getSelectedSendingRequested(sendingId));
    navigate(`/my-activities/e-mailings/emailing-wizard`);
  };

  const handleSetAsFavorite = (sendingId: string) => {
    const sending = sendingsStore.find((s) => s.id === sendingId);
    const updatedSending = { ...sending, isFavourite: !sending.isFavourite };
    dispatch(updateSendingRequested(updatedSending));
  };

  const handleDuplicateSending = (sendingId: string) => {
    dispatch(duplicateSendingRequested({ sendingId: sendingId, localizedTitlePart: formatMessage({ id: 'labels.copy' }) }));
  };

  if (shouldNavigate) {
    dispatch(setSelectedSendingsIds([duplicatedSendingId]));
    dispatch(getSelectedSendingRequested(duplicatedSendingId));
    dispatch(pageOfRecipientsRequested());
    navigate(`/my-activities/e-mailings/emailing-info/${duplicatedSendingId}/audience`);
    dispatch(shouldNavigateToDuplicatedSending(false));
  }

  return (
    <div className="emailings">
      {isOnDesktop && <TemplatesPreview />}
      <div className="emailings-main">
        <SendingsTableWrap />
        {isLoadingSendings ? (
          <ShimmerTable />
        ) : (
          sendingsStore.length === 0 && (
            <div className="table-results-empty-wrap">
              <div className="table-results-empty">
                <div className="table-results-empty-image">
                  <img src={emptyEmailingsImage} alt="" />
                </div>
                <div className="table-results-empty-text-wrap">
                  <span className="table-results-empty-text">{formatMessage({ id: 'emailings-table.nothing-here-yet' })}</span>
                  <span className="table-results-empty-text">{formatMessage({ id: 'emailings-table.start-by-creating' })}</span>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <Routes>
        <Route
          path="emailing-info/:selectedEmailingId/*"
          element={
            <SendingPanel
              opened={isProfilePanelOpen}
              dismissPanel={onCloseSendingPanel}
              duplicateSending={handleDuplicateSending}
              setAsFavourite={handleSetAsFavorite}
              editSending={handleEditSending}
            />
          }
        />
        <Route path="emailing-wizard/*" element={<SendingWizard />} />
      </Routes>
      <SendingFiltersPanel />
    </div>
  );
};
