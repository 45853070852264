import { Icon } from '@fluentui/react';
import { downloadFileFromByteArray } from 'app/common/downloadFile';
import {
  AlertMessage,
  ChangePhotoDialog,
  openBottomPopup,
  selectIsOnDesktop,
  ThreeDotMenu,
  ThreeDotMenuItems,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { ProfilePanelAvatar } from 'app/pages/my-audience/common';
import { ExportContactCsvHeader, FileTypes } from 'app/pages/my-audience/contacts';
import { copyListsRequested, List } from 'app/pages/my-audience/lists';
import {
  DeleteListDialog,
  exportContactsVCardsRequested,
  ListPanelIntroInfo,
  openChangeImageDialog,
  selectExportedContactsByteArray,
  selectExportedContactsFlag,
  selectIsRemoveListDialogOpened,
  selectNumberOfDesynchronizedContacts,
  selectSelectedList,
  selectSelectedListProfilePictureUrl,
  setExportedContactsFlag,
  setIsRemoveListDialogOpened,
  ExportContactsDialog,
  selectIsExportContactsDialogVisible,
  hideExportContactsDialog,
  exportContactsRequested,
  selectExportFileName,
  showExportContactsDialog,
  selectImageToUpload,
  selectProfilePicturePreviewUrl,
  changeProfilePictureToUpload,
  removeProfilePictureRequested,
  updateProfilePictureRequested,
  removeProfilePicturePreview,
  selectIsChangeImageDialogOpened,
  closeChangeImageDialog
} from 'app/pages/my-audience/lists-profile';
import { useIntl } from 'app/i18n';
import { ListTags } from 'app/pages/my-audience/lists-profile/components/common/ListTags';
import { Permission, useAuthContext } from 'auth';
import { Button } from '@fluentui/react-northstar';

export const ListPanelIntro = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedList = useAppSelector<List>(selectSelectedList);
  const profilePictureUrl = useAppSelector<string>(selectSelectedListProfilePictureUrl);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isRemoveListDialogOpened = useAppSelector<boolean>(selectIsRemoveListDialogOpened);
  const isExportDialogVisible = useAppSelector<boolean>(selectIsExportContactsDialogVisible);
  const exportedContacts = useAppSelector<boolean>(selectExportedContactsFlag);
  const exportedContactsByteArray = useAppSelector<any>(selectExportedContactsByteArray);
  const exportFileName = useAppSelector<string>(selectExportFileName);
  const numberOfDesynchronizedContacts = useAppSelector<number>(selectNumberOfDesynchronizedContacts);

  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageLists);

  const handleAvatarClick = () => {
    dispatch(openChangeImageDialog());
  };

  const threeDotMenuItems: ThreeDotMenuItems[] = [
    {
      key: 'copy-list',
      title: formatMessage({ id: 'list-profile-panel.copy' }),
      className: 'profile-panel-menu-item',
      icon: <Icon iconName="Copy" />,
      onClick: () => onCopyListClick(),
      disabled: !hasPermissionToEdit
    },
    {
      key: 'delete-list',
      title: formatMessage({ id: 'list-profile-panel.delete' }),
      className: 'profile-panel-menu-item danger',
      icon: <Icon iconName="Delete" />,
      onClick: () => onRemoveListClick(),
      disabled: !hasPermissionToEdit
    },
    {
      key: 'delete-list',
      title: formatMessage({ id: 'list-profile-panel.export' }),
      className: 'profile-panel-menu-item',
      icon: <Icon iconName="Contact" />,
      onClick: () => onExportContacts()
    }
  ];

  const onExportContacts = () => {
    dispatch(showExportContactsDialog());
  };

  if (exportedContacts) {
    downloadFileFromByteArray(exportFileName, exportedContactsByteArray);

    dispatch(setExportedContactsFlag(false));
  }

  const onRemoveListClick = () => {
    dispatch(setIsRemoveListDialogOpened(true));
  };

  const onCopyListClick = () => {
    dispatch(copyListsRequested([selectedList]));
  };

  const onCloseExportDialog = () => {
    dispatch(hideExportContactsDialog());
  };

  const onExport = (fileType: FileTypes) => {
    const fileName = formatMessage({ id: 'export-contacts.file-name' });

    if (fileType === FileTypes.csv) {
      const csvHeader = new ExportContactCsvHeader(
        formatMessage({ id: 'export-contacts.name' }),
        formatMessage({ id: 'export-contacts.job-title' }),
        formatMessage({ id: 'export-contacts.email-address' }),
        formatMessage({ id: 'export-contacts.contact-data' }),
        formatMessage({ id: 'export-contacts.zimpel' })
      );

      dispatch(exportContactsRequested({ csvHeader, fileName: `${fileName}.csv` }));
    } else {
      dispatch(exportContactsVCardsRequested(`${fileName}.zip`));
    }

    onCloseExportDialog();
  };

  return (
    <>
      <div className="panel-intro">
        <ProfilePanelAvatar
          firstName={selectedList.name}
          lastName=""
          imageUrl={profilePictureUrl}
          onClick={handleAvatarClick}
          disabled={!hasPermissionToEdit}
        />
        <div className="panel-intro-content editable">
          <ListPanelIntroInfo disabled={!hasPermissionToEdit} />
          <ListTags disabled={!hasPermissionToEdit} />
          <div className="panel-intro-content__bottom">
            <div className="alert-msg">
              {numberOfDesynchronizedContacts > 0 && (
                <AlertMessage
                  message={formatMessage(
                    numberOfDesynchronizedContacts > 1 ? { id: 'list-profile-panel.alert-singular' } : { id: 'list-profile-panel.alert-plural' },
                    {
                      number: numberOfDesynchronizedContacts
                    }
                  )}
                />
              )}
            </div>
          </div>
        </div>
        {isOnDesktop && (
          <div className="panel-intro-menu">
            <ThreeDotMenu threeDotMenuItems={threeDotMenuItems} />
          </div>
        )}
        {!isOnDesktop && (
          <div className="panel-intro-menu">
            <Button
              iconOnly
              icon={
                <Icon
                  iconName={'MoreVertical'}
                  onClick={() => {
                    dispatch(openBottomPopup(threeDotMenuItems.map((item) => ({ ...item, className: 'bottom-popup-items' }))));
                  }}
                />
              }
            ></Button>
          </div>
        )}
      </div>
      <ChangePhotoDialog
        selectImageToUpload={selectImageToUpload}
        selectProfilePicturePreviewUrl={selectProfilePicturePreviewUrl}
        selectIsDialogOpened={selectIsChangeImageDialogOpened}
        name={selectedList.name}
        changeProfilePictureToUpload={changeProfilePictureToUpload}
        removeProfilePictureRequested={removeProfilePictureRequested}
        updateProfilePictureRequested={updateProfilePictureRequested}
        removeProfilePicturePreview={removeProfilePicturePreview}
        closeDialog={closeChangeImageDialog}
      />
      <DeleteListDialog
        selectedList={selectedList}
        opened={isRemoveListDialogOpened}
        dialogTitle={formatMessage({ id: 'delete-list-dialog.title' })}
        dialogText={formatMessage({ id: 'delete-list-dialog.text' }, { listName: selectedList?.name })}
        undoAlertMessage={formatMessage({ id: 'delete-list-dialog.undo-alert-message' }, { listName: selectedList?.name })}
      />
      <ExportContactsDialog isDialogVisible={isExportDialogVisible} onDismiss={onCloseExportDialog} onExport={onExport} />
    </>
  );
};
