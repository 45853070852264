import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  closeCreateContactWizard,
  selectCompaniesContactWizardStep,
  selectIsCreateContactWizardOpen,
  CreateNewContact,
  FinishCreateContact,
  CreateContactFromCompanyWizardStep
} from 'app/pages/my-audience/wizard-create-company';

export const CreateNewContactWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const isCreateContactWizardOpen = useAppSelector<boolean>(selectIsCreateContactWizardOpen);
  const currentStep = useAppSelector<CreateContactFromCompanyWizardStep>(selectCompaniesContactWizardStep);

  const closeCreateContact = () => {
    dispatch(closeCreateContactWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateContactWizardOpen}
      onDismissButtonClick={closeCreateContact}
      headerTitle={formatMessage({ id: 'add-menu-create-company.contact-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateContactFromCompanyWizardStep.CreateNew && <CreateNewContact />}
        {currentStep === CreateContactFromCompanyWizardStep.ReviewAndFinish && <FinishCreateContact />}
      </div>
    </WizardWrap>
  );
};
