import { Loader } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { selectIsImageLoading, startLoadingImage } from 'app/pages/my-activities/sending-wizard';
import { SendingImagePreview } from 'app/pages/my-activities/sending-wizard/components/preview/SendingImagePreview';
import { useEffect } from 'react';

export const PreviewTabPreview = () => {
  const dispatch = useAppDispatch();
  const isImageLoading = useAppSelector(selectIsImageLoading);

  useEffect(() => {
    dispatch(startLoadingImage());
  }, [dispatch]);

  return (
    <>
      {isImageLoading && <Loader size="large" labelPosition="below" />}
      <SendingImagePreview />
    </>
  );
};
