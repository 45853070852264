import { Collaboration, Contact, ContactDataType, OwnContactData } from 'app/pages/my-audience/contacts';
import VCard from 'vcard-creator';
import { printData } from 'app/common';

export const createContactVCard = (contact: Contact, contactData: Collaboration | OwnContactData) => {
  const isContactDataCollaboration = contactData.type === ContactDataType.Collaboration;

  const contactVcard = new VCard();
  contactVcard.addName(contact.lastName, contact.firstName, '', contact.salutation, '');
  contactVcard.addCompany(isContactDataCollaboration ? (contactData as Collaboration)?.medium?.name : '');
  contactVcard.addJobtitle(isContactDataCollaboration ? printData((contactData as Collaboration)?.jobTitle?.name, '') : '');
  contactVcard.addEmail(contact.dataSourceKey !== 'ZIMPEL' ? printData(contactData?.emailAddress, '') : '');
  contactVcard.addPhoneNumber(printData(contactData?.landlinePhoneNumber.value, ''), isContactDataCollaboration ? 'WORK' : 'HOME');
  contactVcard.addPhoneNumber(printData(contactData?.landlinePhoneNumber.value, ''), 'CELL');
  contactVcard.addURL(printData(contactData?.linkedInProfileUrl, ''));
  contactVcard.addPhotoURL(printData(contact.profilePictureUrl, ''));

  return contactVcard;
};
