import { Providers, ProviderState } from '@microsoft/mgt-element';
import { ACCESS_TOKEN_CACHE, accessTokenScopes, getAccessToken, getIdTokenInTeams, msalInstance } from 'auth';

const acquireTokenPaams = { scopes: accessTokenScopes };

export const performSingleSignOnInBrowser = async (
  handleSuccess: (value: { graphApiAccessToken: string; accessToken: string }) => void,
  handleFailure: () => void,
  setLastInteractionEmailAddress: (graphApiAccessToken: string) => void
) =>
  msalInstance
    .acquireTokenSilent(acquireTokenPaams)
    .then((authResult) => authenticateUserInBrowser(authResult.accessToken, authResult.idToken, handleSuccess, handleFailure))
    .catch(() => {
      msalInstance
        .handleRedirectPromise()
        .then((authResult) => {
          setLastInteractionEmailAddress(authResult.accessToken);
          authenticateUserInBrowser(authResult.accessToken, authResult.idToken, handleSuccess, handleFailure);
        })
        .catch(() => msalInstance.loginRedirect(acquireTokenPaams).then(handleFailure).catch(console.error));
    });

const handleFailureSingleSignOnInTeams = (error: Error, callback: () => void) => {
  console.error(error);
  localStorage.removeItem(ACCESS_TOKEN_CACHE);
  callback();
};

const registerAuthCallback = (accessTokenHandler: (accessToken: string) => void) => {
  const updateState = () => {
    const provider = Providers.globalProvider;
    if (provider?.state === ProviderState.SignedIn) {
      Providers.globalProvider
        .getAccessToken()
        .then((accessToken) => {
          if (accessToken === undefined) {
            return;
          }

          accessTokenHandler(accessToken);
          Providers.removeProviderUpdatedListener(updateState);
        })
        .catch(console.error);
    }
  };

  Providers.onProviderUpdated(updateState);
};

export const performSingleSignOnInTeams = async (
  handleSuccess: (value: { accessToken: string; graphApiAccessToken: string }) => void,
  handleFailure: () => void,
  setLastInteractionEmailAddress: (graphApiAccessToken: string) => void,
  adminConsentMissing: () => void
) => {
  Providers.globalProvider
    .getAccessToken()
    .then((accessToken) => {
      if (!accessToken) {
        adminConsentMissing();
        registerAuthCallback((accessToken) => authenticateUserInTeams(accessToken, handleSuccess, handleFailure));
        return;
      }

      setLastInteractionEmailAddress(accessToken);
      authenticateUserInTeams(accessToken, handleSuccess, handleFailure);
    })
    .catch((error) => handleFailureSingleSignOnInTeams(error, handleFailure));
};

export const authenticateUserInTeams = async (
  graphApiAccessToken: string,
  handleSuccess: (value: { accessToken: string; graphApiAccessToken: string }) => void,
  handleFailure: () => void
) => {
  try {
    const idToken = await getIdTokenInTeams();
    const accessToken = await getAccessToken(idToken);

    handleSuccess({ accessToken, graphApiAccessToken });
  } catch (e) {
    handleFailure();
  }
};

export const authenticateUserInBrowser = async (
  graphApiAccessToken: string,
  idToken: string,
  handleSuccess: (value: { accessToken: string; graphApiAccessToken: string }) => void,
  handleFailure: () => void
) => {
  try {
    const accessToken = await getAccessToken(idToken);
    handleSuccess({ accessToken, graphApiAccessToken });
  } catch (e) {
    handleFailure();
  }
};
