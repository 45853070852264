import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  isTextInputValid,
  maximumMediaOutletNameLength,
  MediumCategory,
  regAtLeastOneCharacter,
  useAppDispatch,
  useAppSelector,
  WizardIntro
} from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { selectLocalizedAudiences, selectLocalizedFrequencies } from 'app/localization';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { LocalizedListValue, selectCategories } from 'app/pages/my-audience/media-outlets';
import {
  addMediaOutletGeneralInfo,
  closeCreateMediaOutletWizard,
  CreateMediaOutletFooter,
  CreateMediaOutletSteps,
  goToNextMediaOutletStep,
  MediaOutletGeneralInfo,
  selectMediaOutletGeneralInfo,
  setMediaOutletGeneralInfo
} from 'app/pages/my-audience/wizard-create-contact';

export type CreateMediaOutletGeneralInfoForm = {
  name: string;
  description: string;
  audience: string;
  category: string;
  frequency: string;
};

export const CreateMediaOutletGeneralInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const mediaOutletGeneralInfo = useAppSelector<MediaOutletGeneralInfo>(selectMediaOutletGeneralInfo);

  const allCategories = useAppSelector<MediumCategory[]>(selectCategories);
  const localizedAudiences = useAppSelector<LocalizedListValue[]>(selectLocalizedAudiences);
  const localizedFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);

  const selectedAudience = useMemo(
    () => localizedAudiences.find((a) => a.key === mediaOutletGeneralInfo.audienceKey)?.label,
    [localizedAudiences, mediaOutletGeneralInfo.audienceKey]
  );

  const selectedFrequency = useMemo(
    () => localizedFrequencies.find((a) => a.key === mediaOutletGeneralInfo.frequencyKey)?.label,
    [localizedFrequencies, mediaOutletGeneralInfo.frequencyKey]
  );

  const { handleSubmit, control, watch } = useForm<CreateMediaOutletGeneralInfoForm, any>({
    defaultValues: {
      name: mediaOutletGeneralInfo.name,
      description: mediaOutletGeneralInfo.description,
      audience: selectedAudience,
      category: mediaOutletGeneralInfo.category?.name,
      frequency: selectedFrequency
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  useEffect(() => {
    return submitForm;
  }, []);

  const handleNextButtonClick = () => {
    dispatch(goToNextMediaOutletStep());
  };

  const submitForm = () => {
    handleSubmit(
      (data) => {
        const newMediaOutletGeneralInfo: MediaOutletGeneralInfo = setMediaOutletGeneralInfo(data, localizedAudiences, localizedFrequencies, allCategories);
        dispatch(addMediaOutletGeneralInfo(newMediaOutletGeneralInfo));
      },
      (error) => {
        Sentry.setExtra('Path', CreateMediaOutletGeneralInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const areFieldsValid = isTextInputValid({ inputValue: watch('name'), maxInputLength: maximumMediaOutletNameLength });

  return (
    <>
      <CreateMediaOutletSteps areFieldsValid={areFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.mo-general-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'add-menu-create-contact.mo-name-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="name"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: formatMessage({ id: 'add-menu-create-contact.mo-valid-name' }) })
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumMediaOutletNameLength }),
                  value: maximumMediaOutletNameLength
                },
                required: formatMessage({ id: 'error-messages.required-name' }, { entity: formatMessage({ id: 'add-menu-create-contact.mo-valid-name' }) })
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="description" label={formatMessage({ id: 'add-menu-create-contact.mo-descript-label' })} control={control} />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-contact.mo-audience-label'} />
                </span>
              }
              name="audience"
              items={localizedAudiences.map((audience) => audience.label)}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.mo-audience-placeholder' })}
              defaultValue={selectedAudience}
            />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-contact.mo-categories-label'} />
                </span>
              }
              name="category"
              items={allCategories.map((c) => c.name)}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.mo-categories-placeholder' })}
              defaultValue={mediaOutletGeneralInfo.category?.name}
            />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-contact.mo-frequancy-label'} />
                </span>
              }
              name="frequency"
              items={localizedFrequencies.map((f) => f.label)}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.mo-frequancy-placeholder' })}
              defaultValue={selectedFrequency}
            />
          </div>
        </div>
        <CreateMediaOutletFooter
          onCancelButtonClick={handleCancelButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areFieldsValid}
        />
      </div>
    </>
  );
};
