import { useEffect } from 'react';
import { dataSourcesRequested, useAppDispatch, useSendingWizardRedirection } from 'app/common';
import { getSendersRequested } from 'app/pages/my-activities/sending-wizard';
import {
  clearSendingsFiltersAndSearchText,
  firstPageOfSendingsRequested,
  getAllSendingsTagsRequested,
  setSelectedSendingsIds
} from 'app/pages/my-activities/sendings';
import { ActivitiesOverview } from 'app/pages/my-activities/sendings-overview/ActivitiesOverview';
import { setTemplatesPageNumber, setTemplatesPageSize, templatesRequested } from 'app/pages/my-activities/templates';
import { getAllBlacklistsRequested } from 'app/pages/my-audience/contact-profile';

export const MyActivities = () => {
  const dispatch = useAppDispatch();
  useSendingWizardRedirection();

  useEffect(() => {
    dispatch(clearSendingsFiltersAndSearchText());
    dispatch(dataSourcesRequested());
    dispatch(firstPageOfSendingsRequested());
    dispatch(setSelectedSendingsIds([]));
    dispatch(getAllBlacklistsRequested());
    dispatch(getSendersRequested());
    dispatch(getAllSendingsTagsRequested());

    dispatch(setTemplatesPageSize(10));
    dispatch(setTemplatesPageNumber(1));
    dispatch(templatesRequested(''));
  }, [dispatch]);

  return <ActivitiesOverview />;
};
