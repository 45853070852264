import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { closeAddFromCompanyPanel, selectIsAddFromCompanyPanelOpened } from 'app/pages/my-activities/sending-wizard';
import { AddContactFromCompany } from 'app/pages/my-activities/sending-wizard';

export const AddContactFromCompanyWizard = () => {
  const dispatch = useAppDispatch();

  const isPanelOpened = useAppSelector<boolean>(selectIsAddFromCompanyPanelOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeAddFromCompanyPanel());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isPanelOpened}
      onDismissButtonClick={handleDismissButtonClick}
      customWidth="700px"
      classModifier="narrow"
      hasBackArrow={true}
      onArrowButtonClick={handleDismissButtonClick}
    >
      <div className="wizard-panel-main">
        <AddContactFromCompany />
      </div>
    </WizardWrap>
  );
};
