import { client } from 'app/common/graphql/graphql-gateway.client';
import { addedMediaResort, addedTagToContact, addedTopic, removedTagsFromContact } from 'app/pages/my-audience/contact-profile';
import { removeContact } from 'app/pages/my-audience/contact-profile/services/contact-profile.service';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  Contact,
  contactNameFilterSuggestionsReceived,
  contactNameFilterSuggestionsRequested,
  contactsByIdsRemoved,
  contactSuggestionsReceived,
  contactSuggestionsRequested,
  fetchContactNameFilterSuggestions,
  fetchContactsByQueryParamsPaged,
  fetchContactSuggestions,
  fetchLanguageFilterSuggestions,
  fetchMediumNameFilterSuggestions,
  fetchResortFilterSuggestions,
  fetchRoleFilterSuggestions,
  fetchTagNameFilterSuggestions,
  firstPageOfContactsReceived,
  firstPageOfContactsRequested,
  ISortingInput,
  languageFilterSuggestionsReceived,
  languageFilterSuggestionsRequested,
  mediumNameFilterSuggestionsReceived,
  mediumNameFilterSuggestionsRequested,
  nextPageOfContactsReceived,
  nextPageOfContactsRequested,
  PageOfContacts,
  removeContactRequested,
  removeContactsByIds,
  removeContactsByIdsRequested,
  removedContact,
  resortFilterSuggestionsReceived,
  resortFilterSuggestionsRequested,
  roleFilterSuggestionsReceived,
  roleFilterSuggestionsRequested,
  selectContactsSearchSuggestionsText,
  selectContactsToRemoveIds,
  selectContactToRemove,
  selectFilterSuggestionsPageSize,
  selectFilterSuggestionsSearchText,
  selectListsIdsForBulkUpdate,
  selectPageNumber,
  selectPageSize,
  selectSearchText,
  selectSortingInput,
  selectTopicsIdsForBulkUpdate,
  setTotalCountOfContacts,
  tagNameFilterSuggestionsReceived,
  tagNameFilterSuggestionsRequested,
  getAllContactsTagsRequested,
  allTagsReceived,
  addTagToContactsRequested,
  selectTagToAddToContacts,
  addedTagToContactsSearchResults,
  selectTagToRemoveFromContacts,
  removeTagFromContactsRequested,
  deleteTagsRequested,
  selectTagsToDelete,
  selectContacts,
  tagsDeleted,
  selectTagNameToCreate,
  addNewTagRequested,
  addedNewTag,
  removedTagsFromContactsSearchResults,
  addTagToContacts,
  removeTagFromContacts,
  updateContactsRequested,
  selectFilterItems,
  selectSelectedContactsIds,
  countryFilterSuggestionsReceived,
  countryFilterSuggestionsRequested,
  fetchCountryFilterSuggestions,
  setLoadingCountrySuggestions,
  selectMediaResortsToAddToContact,
  addResortInTable,
  MediaResort,
  addMediaResort,
  selectMediaResortToAdd,
  Topic,
  addTopic,
  selectTopicToAdd,
  createTopicRequested,
  topicCreated,
  createMediaResortRequested,
  mediaResortCreated,
  addMediaResortsToContacts,
  addTopicsToContacts,
  addListsToContacts,
  selectListNameToAdd,
  listCreated,
  createListRequested,
  selectContactsIdsToAddTag
} from 'app/pages/my-audience/contacts';
import {
  closeDeleteEntitiesDialog,
  createAndAssignTag,
  deleteTags,
  fetchTagsByEntityType,
  SearchSuggestionCategory,
  TagCreationInput,
  safe,
  FilterItem,
  safelyHandleError,
  selectSelectedTableRowsIdsSelector,
  selectSelectedTableRows
} from 'app/common';
import { List, addList } from 'app/pages/my-audience/lists';
import { addedNewSearchList } from 'app/pages/my-audience/lists-profile';

export function* fetchContactsPagedWatcher() {
  yield takeEvery(firstPageOfContactsRequested.type, safe(fetchContactsPagedFlow));
  yield takeEvery(nextPageOfContactsRequested.type, safe(fetchContactsPagedFlow));
}

function* fetchContactsPagedFlow() {
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);
  const searchText: string = yield select(selectSearchText);
  const filterItems: FilterItem[] = yield select(selectFilterItems);
  const sortingInput: ISortingInput = yield select(selectSortingInput);

  const pageOfContacts: PageOfContacts = yield call(fetchContactsByQueryParamsPaged, client, searchText, filterItems, pageNumber, pageSize, sortingInput);
  if (pageNumber === 1) {
    yield put(firstPageOfContactsReceived(pageOfContacts.contacts));
  } else {
    yield put(nextPageOfContactsReceived(pageOfContacts.contacts));
  }

  yield put(setTotalCountOfContacts(pageOfContacts.totalCount));
}

function* fetchContactSuggestionsFlow() {
  const searchText: string = yield select(selectContactsSearchSuggestionsText);
  const contactSuggestions: SearchSuggestionCategory[] = yield call(fetchContactSuggestions, client, searchText);
  yield put(contactSuggestionsReceived(contactSuggestions));
}

export function* contactSuggestionsRequestedWatcher() {
  yield takeLatest(contactSuggestionsRequested.type, safe(fetchContactSuggestionsFlow));
}

function* removeContactFlow() {
  const contactToRemove: Contact = yield select(selectContactToRemove);

  const removedContactId = yield call(removeContact, client, contactToRemove.id);
  yield put(removedContact(removedContactId));
}

export function* removeContactRequestedWatcher() {
  yield takeEvery(removeContactRequested.type, safe(removeContactFlow));
}

function* contactNameSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const contacts = yield call(fetchContactNameFilterSuggestions, client, searchText, pageSize);
  yield put(contactNameFilterSuggestionsReceived(contacts));
}

export function* contactNameSuggestionsRequestedWatcher() {
  yield takeEvery(contactNameFilterSuggestionsRequested.type, safe(contactNameSuggestionsFlow));
}

function* roleSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const contacts = yield call(fetchRoleFilterSuggestions, client, searchText, pageSize);
  yield put(roleFilterSuggestionsReceived(contacts));
}

export function* roleSuggestionsRequestedWatcher() {
  yield takeEvery(roleFilterSuggestionsRequested.type, safe(roleSuggestionsFlow));
}

function* mediumNameSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const contacts = yield call(fetchMediumNameFilterSuggestions, client, searchText, pageSize);
  yield put(mediumNameFilterSuggestionsReceived(contacts));
}

export function* mediumNameSuggestionsRequestedWatcher() {
  yield takeEvery(mediumNameFilterSuggestionsRequested.type, safe(mediumNameSuggestionsFlow));
}

function* resortSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const resortFilterSuggestions = yield call(fetchResortFilterSuggestions, client, searchText, pageSize);
  yield put(resortFilterSuggestionsReceived(resortFilterSuggestions));
}

export function* resortSuggestionsRequestedWatcher() {
  yield takeEvery(resortFilterSuggestionsRequested.type, safe(resortSuggestionsFlow));
}

function* languageSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const contacts = yield call(fetchLanguageFilterSuggestions, client, searchText, pageSize);
  yield put(languageFilterSuggestionsReceived(contacts));
}

export function* languageSuggestionsRequestedWatcher() {
  yield takeEvery(languageFilterSuggestionsRequested.type, safe(languageSuggestionsFlow));
}

function* tagNameSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const contacts = yield call(fetchTagNameFilterSuggestions, client, searchText, pageSize);
  yield put(tagNameFilterSuggestionsReceived(contacts));
}

export function* tagNameSuggestionsRequestedWatcher() {
  yield takeEvery(tagNameFilterSuggestionsRequested.type, safe(tagNameSuggestionsFlow));
}

function* countrySuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const contacts = yield call(fetchCountryFilterSuggestions, client, searchText, pageSize);
  yield put(countryFilterSuggestionsReceived(contacts));
}

function* resetCountrySuggestionsLoading() {
  yield put(setLoadingCountrySuggestions(false));
}

export function* countrySuggestionsRequestedWatcher() {
  yield takeEvery(
    countryFilterSuggestionsRequested.type,
    safelyHandleError({ performAction: countrySuggestionsFlow, onFinally: resetCountrySuggestionsLoading })
  );
}

function* removeContactsByIdsFlow() {
  const contactsToRemoveIds: string[] = yield select(selectContactsToRemoveIds);

  const contacts: Contact[] = yield call(removeContactsByIds, client, contactsToRemoveIds);
  yield put(contactsByIdsRemoved(contacts));
  yield put(closeDeleteEntitiesDialog());
}

export function* removeContactsByIdsWatcher() {
  yield takeEvery(removeContactsByIdsRequested.type, safe(removeContactsByIdsFlow));
}

function* fetchAllContactTagsFlow() {
  const allTags = yield call(fetchTagsByEntityType, client, 'Contact');
  yield put(allTagsReceived(allTags));
}

export function* getAllContactTagsRequestedWatcher() {
  yield takeEvery(getAllContactsTagsRequested.type, safe(fetchAllContactTagsFlow));
}

function* addTagToContactsFlow() {
  const tagToAdd = yield select(selectTagToAddToContacts);
  const selectedContactIds = yield select(selectContactsIdsToAddTag);
  yield call(addTagToContacts, client, selectedContactIds, tagToAdd);
}

export function* addTagToContactsRequestedWatcher() {
  yield takeEvery(addTagToContactsRequested.type, safe(addTagToContactsFlow));
}

function* removeTagFromContactsFlow() {
  const tagToRemove = yield select(selectTagToRemoveFromContacts);
  const selectedContactIds = yield select(selectSelectedContactsIds);

  yield call(removeTagFromContacts, client, selectedContactIds, tagToRemove);
}

export function* removeTagFromContactRequestedWatcher() {
  yield takeEvery(removeTagFromContactsRequested.type, safe(removeTagFromContactsFlow));
}

function* deleteTagsFlow() {
  const tagsToDelete = yield select(selectTagsToDelete);
  const tagIdsToDelete = tagsToDelete.map((t) => t.id);
  const contacts = (yield select(selectContacts)).map((c) => c.id);

  yield call(deleteTags, client, tagIdsToDelete);
  yield put(tagsDeleted(tagIdsToDelete));
  yield put(removedTagsFromContact(tagsToDelete));
  yield put(removedTagsFromContactsSearchResults({ contactIds: contacts, tags: tagsToDelete }));
}

export function* deleteTagsRequestedWatcher() {
  yield takeEvery(deleteTagsRequested.type, safe(deleteTagsFlow));
}

function* addTagFlow() {
  const selectedContactIds: string[] = yield select(selectSelectedTableRowsIdsSelector);
  const tagToAdd = yield select(selectTagNameToCreate);

  const tagCreationInput = new TagCreationInput(tagToAdd, null, selectedContactIds, 'Contact');

  const createdTag = yield call(createAndAssignTag, client, tagCreationInput);
  yield put(addedNewTag(createdTag));
  yield put(addedTagToContact(createdTag));
  yield put(addedTagToContactsSearchResults({ contactIds: selectedContactIds, tag: createdTag }));
}

export function* addContactTagRequestedWatcher() {
  yield takeEvery(addNewTagRequested.type, safe(addTagFlow));
}

function* updateContactsFlow() {
  const listsIds = yield select(selectListsIdsForBulkUpdate);
  const mediaResorts: MediaResort[] = yield select(selectMediaResortsToAddToContact);
  const topicsIds = yield select(selectTopicsIdsForBulkUpdate);
  const selectedTableRows = yield select(selectSelectedTableRows);
  const contactsIds = selectedTableRows.map((row) => row.id);

  if (mediaResorts.length) {
    yield call(
      addMediaResortsToContacts,
      client,
      contactsIds,
      mediaResorts.map((mr) => mr.id)
    );

    yield put(addResortInTable({ contactIds: contactsIds, resorts: mediaResorts }));
  }

  if (topicsIds.length) {
    yield call(addTopicsToContacts, client, contactsIds, topicsIds);
  }

  if (listsIds.length) {
    yield call(addListsToContacts, client, contactsIds, listsIds);
  }
}

export function* updateContactsRequestedWatcher() {
  yield takeEvery(updateContactsRequested.type, safe(updateContactsFlow));
}

export function* createMediaResortFlow() {
  const mediaResort: MediaResort = yield select(selectMediaResortToAdd);

  const createdMediaResort: MediaResort = yield call(addMediaResort, client, mediaResort);

  yield put(mediaResortCreated(createdMediaResort));
  yield put(addedMediaResort(createdMediaResort));
}

export function* createMediaResortWatcher() {
  yield takeEvery(createMediaResortRequested.type, safe(createMediaResortFlow));
}

export function* createTopicFlow() {
  const topic: Topic = yield select(selectTopicToAdd);

  const createdTopic: Topic = yield call(addTopic, client, topic);

  yield put(topicCreated(createdTopic));
  yield put(addedTopic(createdTopic));
}

export function* createTopicRequestedWatcher() {
  yield takeEvery(createTopicRequested.type, safe(createTopicFlow));
}

export function* createListFlow() {
  const listToAdd: string = yield select(selectListNameToAdd);
  const createdList: List = yield call(addList, client, listToAdd);

  yield put(listCreated(createdList));
  yield put(addedNewSearchList(createdList));
}

export function* createListRequestedWatcher() {
  yield takeEvery(createListRequested.type, safe(createListFlow));
}
