import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getUserPermissions, localStorageEvents, useListenLocalStorage } from 'app/common';
import { ACCESS_TOKEN_CACHE, Permission } from 'auth';

type AuthContextType = {
  permissions: Permission[];
  hasPermission: (permission: Permission) => boolean;
  hasAnyPermissions: (permissions: Permission[]) => boolean;
  hasAllPermissions: (permissions: Permission[]) => boolean;
};

const AuthContext = createContext<AuthContextType>(null);

const AuthContextProvider = ({ children }) => {
  const [accessToken, setToken] = useState(localStorage.getItem(ACCESS_TOKEN_CACHE));
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const updateToken = useCallback(() => {
    setToken(localStorage.getItem(ACCESS_TOKEN_CACHE));
  }, [setToken]);

  useListenLocalStorage(localStorageEvents.ACCESS_TOKEN_CHANGE_EVENT_NAME, updateToken);

  useEffect(() => {
    if (accessToken) {
      getUserPermissions()
        .then((response) => {
          setPermissions(response.map((permission) => Permission[permission]));
        })
        .catch(() => setPermissions([]));
    }
  }, [accessToken, setPermissions]);

  const contextValue = useMemo(
    () => ({
      permissions,
      hasPermission: (permission: Permission) => permissions.includes(permission),
      hasAnyPermissions: (requiredPermissions: Permission[]) => permissions.some((permission) => requiredPermissions.includes(permission)),
      hasAllPermissions: (requiredPermissions: Permission[]) => requiredPermissions.every((requiredPermission) => permissions.includes(requiredPermission))
    }),
    [permissions]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
