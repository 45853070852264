import { useAppSelector } from 'app/common';
import { List } from 'app/pages/my-audience/lists';
import { selectSelectedList } from 'app/pages/my-audience/lists-profile';
import { DateTime } from 'luxon';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';

export const ListOverviewInfo = () => {
  const list = useAppSelector<List>(selectSelectedList);
  const locale = useAppSelector<string>(selectLocale);

  const { formatMessage } = useIntl();

  return (
    <div className="list-overview-info">
      <div className="list-overview-info-column">
        <div className="list-overview-info-content">
          <span className="list-overview-info-label">{formatMessage({ id: 'list-profile-overview.created-by' })}:</span>
          <div className="list-overview-info-contact">
            <span className="list-overview-info-description">
              {list.createdByContact
                ? `${list.createdByContact?.firstName} ${list.createdByContact?.lastName}`
                : formatMessage({ id: 'error-messages.unknown-user' })}
            </span>
          </div>
        </div>
      </div>
      <div className="list-overview-info-column">
        <div className="list-overview-info-content">
          <span className="list-overview-info-label">{formatMessage({ id: 'list-profile-overview.last-update' })}:</span>
          <span className="list-overview-info-description">
            {DateTime.fromJSDate(list.lastModifiedAt).setLocale(locale).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
          </span>
        </div>
      </div>
    </div>
  );
};
