import { useBoolean } from '@fluentui/react-hooks';
import { Button, ErrorIcon, Tooltip } from '@fluentui/react-northstar';

interface AlertMessageProps {
  message: string;
}

export const AlertMessage = ({ message }: AlertMessageProps) => {
  const [isMessageVisible, { setTrue: showMessage, setFalse: hideMessage }] = useBoolean(true);

  return (
    <div onMouseEnter={showMessage} onMouseLeave={hideMessage}>
      <Tooltip
        open={isMessageVisible}
        pointing
        className="alert-mgs__popup"
        position={'before'}
        trigger={<Button icon={<ErrorIcon />} />}
        content={
          <>
            <p className="alert-mgs__title">
              <ErrorIcon /> ALERT
            </p>
            <span>{message}</span>
          </>
        }
      />
    </div>
  );
};
