import { useState } from 'react';
import { useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { Collaboration, CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';
import {
  selectCollaborationsDetails,
  CollaborationDetailsForm,
  CreateMediaOutletSteps,
  CreateMediaOutletFooter,
  updateCollaboration,
  goToNextStep,
  closeCreateMediaOutletWizard,
  goToPreviousStep,
  removeCollaboration,
  pageOfContactsRequested
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { useIntl } from 'app/i18n';

export const AddCollaborationDetails = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const collaborationDetails = useAppSelector<CollaborationWithContactInfo[]>(selectCollaborationsDetails);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false);

  const removeContact = (contactId: string) => {
    dispatch(removeCollaboration(contactId));
  };

  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(pageOfContactsRequested());
    dispatch(goToPreviousStep());
  };

  const handleUpdateCollaboration = (areFieldsValid: boolean, collaboration: Collaboration, contactId: string, differentAddress: boolean) => {
    setIsNextButtonDisabled(!areFieldsValid);
    dispatch(updateCollaboration({ collaboration, contactId, differentAddress }));
  };

  return (
    <>
      <CreateMediaOutletSteps isStepValid={!isNextButtonDisabled} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-mo.additional-title' })} description="" />
        <div className="wizard-panel-accordions-wrap">
          <div className="wizard-accordion-wrap">
            {collaborationDetails.map((collaboration) => (
              <CollaborationDetailsForm
                collaborationDetails={collaboration}
                removeContact={removeContact}
                key={collaboration.contactId}
                updateCollaboration={handleUpdateCollaboration}
              />
            ))}
          </div>
        </div>
        <CreateMediaOutletFooter
          onNextButtonClick={handleNextButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          isNextButtonDisabled={isNextButtonDisabled}
        />
      </div>
    </>
  );
};
