import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

const getErrorMessage = (error: ApolloError, errors: ReadonlyArray<GraphQLError>) => {
  if (error) return error.message;
  if (errors?.length) return errors[0].message;
  return 'Apollo client received unknown error!';
};

export class GraphQLQueryError extends Error {
  constructor(public error: ApolloError, public errors: ReadonlyArray<GraphQLError>) {
    super(getErrorMessage(error, errors));
    Object.setPrototypeOf(this, GraphQLQueryError.prototype);
  }
}
