import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { Report, Audience } from 'app/pages/my-activities/sending-profile';
import { Sending, SendingStatus } from 'app/pages/my-activities/sendings';
import { selectSelectedSending, selectSelectedSendingOverview } from 'app/pages/my-activities/sending-wizard';
import { useEffect, useState } from 'react';
import { SendingOverview } from 'app/common/graphql/generated/graphql-gateway';

export const ReportTab = () => {
  const { formatMessage } = useIntl();
  const selectedSending = useAppSelector<Sending>(selectSelectedSending);
  const selectedSendingOverview = useAppSelector<SendingOverview>(selectSelectedSendingOverview);
  const [panels, setPanels] = useState([]);

  const shouldLoadPanel = selectedSending.status === SendingStatus.SuccessfulDistribution && selectedSendingOverview;

  useEffect(() => {
    setPanels([]);
    if (shouldLoadPanel) {
      setPanels([
        {
          title: (
            <div className="accordion-head-box" key="sending-overview-title">
              <span className="accordion-heading">{formatMessage({ id: 'sending-profile.report.overview' })}</span>
              <ChevronDownMediumIcon />
            </div>
          ),
          content: { key: 'sending-overview-content', content: <Report /> }
        }
      ]);
    }

    setPanels((prev) => [
      ...prev,
      {
        title: (
          <div className="accordion-head-box" key="about-title-report">
            <span className="accordion-heading">{formatMessage({ id: 'sending-profile.report.audience' })}</span>
            <ChevronDownMediumIcon />
          </div>
        ),
        content: { key: 'about-content', content: <Audience /> }
      }
    ]);
  }, [shouldLoadPanel, selectedSendingOverview]);

  return (
    <div className="emailing-report-tab">
      <Accordion defaultActiveIndex={[0]} panels={panels} />
    </div>
  );
};
