import { SENDING_FILTER } from 'app/pages/my-activities/sending-filter-search';
import { TEMPLATES_FILTER } from 'app/pages/my-activities/templates';
import { COMPANY_FILTER } from 'app/pages/my-audience/companies-filter-search/filter-fields';
import { CONTACT_FILTER } from 'app/pages/my-audience/contact-filter-search/filter-fields';
import { LocalizedListValue } from 'app/pages/my-audience/media-outlets';
import { MEDIA_OUTLET_FILTER } from 'app/pages/my-audience/media-outlets-filter-search/filter-fields';
import { IntlMessage, NestedKeys } from 'app/i18n';

export const getFilterLocalizedValue = (value: string, localizedListValues: LocalizedListValue[]) => {
  if (!localizedListValues) return value;

  return localizedListValues.find((v) => v.key === value)?.label;
};

export const getFilterLocalizationKey = (fieldName: string): NestedKeys<IntlMessage> => {
  switch (fieldName) {
    case CONTACT_FILTER.NAME:
      return 'filters.names';
    case CONTACT_FILTER.ROLE:
      return 'filters.roles';
    case CONTACT_FILTER.MEDIUM:
      return 'filters.mediums';
    case CONTACT_FILTER.LANGUAGE:
      return 'filters.languages';
    case CONTACT_FILTER.RESORTS:
      return 'filters.resorts';
    case CONTACT_FILTER.TAG:
      return 'filters.tags';
    case MEDIA_OUTLET_FILTER.TAG:
      return 'filters.tags';
    case MEDIA_OUTLET_FILTER.LANGUAGE:
      return 'search-suggestion-categories.Language';
    case MEDIA_OUTLET_FILTER.ADDRESS:
      return 'search-suggestion-categories.Address';
    case MEDIA_OUTLET_FILTER.POSTAL_CODE:
      return 'search-suggestion-categories.PostalCode';
    case MEDIA_OUTLET_FILTER.CITY:
      return 'search-suggestion-categories.City';
    case MEDIA_OUTLET_FILTER.FAX:
      return 'search-suggestion-categories.Fax';
    case MEDIA_OUTLET_FILTER.WEBSITE:
      return 'search-suggestion-categories.Website';
    case MEDIA_OUTLET_FILTER.PO_BOX:
      return 'search-suggestion-categories.PoBox';
    case MEDIA_OUTLET_FILTER.FREQUENCY:
      return 'search-suggestion-categories.Frequency';
    case COMPANY_FILTER.CONTACT_NAME:
      return 'filters.contacts';
    case COMPANY_FILTER.COUNTRY:
      return 'filters.country';
    case COMPANY_FILTER.REGISTRATION_NUMBER:
      return 'filters.registration-number';
    case COMPANY_FILTER.DATABASE_TYPE:
      return 'filters.database-type';
    case COMPANY_FILTER.CITY:
      return 'filters.city';
    case COMPANY_FILTER.POSTAL_CODE:
      return 'filters.postal-code';
    case MEDIA_OUTLET_FILTER.CATEGORY:
      return 'media-outlet-labels.category';
    case MEDIA_OUTLET_FILTER.TYPE:
      return 'media-outlet-labels.media-support-types';
    case COMPANY_FILTER.PARENT_COMPANY:
      return 'search-suggestion-categories.ParentCompany';
    case COMPANY_FILTER.CREATED_BY:
      return 'search-suggestion-categories.CreatedBy';
    case COMPANY_FILTER.TAG:
      return 'filters.tags';
    case COMPANY_FILTER.PHONE_NUMBER:
      return 'filters.phone-number';
    case TEMPLATES_FILTER.TITLE:
      return 'filters.title';
    case TEMPLATES_FILTER.CREATED_AT:
      return 'filters.created-at';
    case TEMPLATES_FILTER.IS_PREBUILT:
      return 'filters.is-prebuilt';
    case SENDING_FILTER.STATUS:
      return 'emailings-labels.status';
    case SENDING_FILTER.FAVOURITE:
      return 'emailings-labels.favorites';
  }
};
