import {
  CommandBarButton,
  DetailsListLayoutMode,
  DetailsRow,
  Icon,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import { Button, Checkbox } from '@fluentui/react-northstar';
import { EmptyMessage, useAppDispatch, useAppSelector, usePersistentSelection } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { WarningMessage } from 'app/common/components/warning-message/WarningMessage';
import { useIntl } from 'app/i18n';
import { contactProfilePanelDataRequested, openProfilePanel } from 'app/pages/my-audience/contact-profile';
import { Contact } from 'app/pages/my-audience/contacts';
import { ListContact } from 'app/pages/my-audience/lists';
import {
  ContactsTableToolbar,
  openRemoveContactDialog,
  selectContactsIdsToEdit,
  selectListContacts,
  selectListContactsPageNumber,
  selectListContactsTotalCount,
  selectSelectedContactsPerPage,
  setContactsIdsToEdit,
  setSelectedContacts,
  updateContactInListRequested
} from 'app/pages/my-audience/lists-profile';
import { Permission, useAuthContext } from 'auth';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ContactsTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const listContacts = useAppSelector<ListContact[]>(selectListContacts);
  const totalCountOfContacts = useAppSelector<number>(selectListContactsTotalCount);
  const contactsIdsToEdit = useAppSelector<string[]>(selectContactsIdsToEdit);
  const contactsPerPage = useAppSelector(selectSelectedContactsPerPage);
  const currentPageNumber = useAppSelector(selectListContactsPageNumber);

  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageLists);

  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedContacts: Contact[] = selection.getSelection().map((selection) => selection['contact']);
          dispatch(setSelectedContacts(selectedContacts));
        },
        selectionMode: SelectionMode.multiple
      }),
    []
  );

  const getContactId = <T,>(entity: T): string => {
    const contact = entity as Contact;
    return contact.id;
  };

  usePersistentSelection({
    tableRows: listContacts,
    entitiesPerPage: contactsPerPage,
    selection,
    currentPageNumber,
    getEntityId: getContactId
  });

  const header = [
    {
      key: 'contact-key-name',
      name: formatMessage({ id: 'contacts-table.name' }),
      fieldName: 'name',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'contact-key-title',
      name: formatMessage({ id: 'contacts-table.job-title' }),
      fieldName: 'jobTitle',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'contact-key-email',
      name: formatMessage({ id: 'contacts-table.email' }),
      fieldName: 'email',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'contact-key-contact-data',
      name: formatMessage({ id: 'contacts-table.contact-data' }),
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    { key: 'contact-key-hover-action', name: '', fieldName: 'hoverActions', isResizable: false, minWidth: 60, maxWidth: 60 }
  ];

  const handleEditContactData = (listContact: ListContact, mediumId: string) => {
    dispatch(updateContactInListRequested({ ...listContact, mediumId: mediumId }));
    dispatch(setContactsIdsToEdit(contactsIdsToEdit.filter((id) => id !== listContact.contact.id)));
  };

  const rows = listContacts.map((listContact, index) => {
    const contact = listContact.contact;
    const collaboration = contact.collaborations.find((c) => c.medium?.id === listContact.mediumId);
    const jobTitle = collaboration !== undefined ? collaboration.jobTitle.name : '';
    const emailAddress = collaboration !== undefined ? collaboration.emailAddress : contact.ownContactData.emailAddress;
    const contactData = collaboration !== undefined ? collaboration.medium.name : formatMessage({ id: 'list-profile-contacts.own-contact-data' });

    const contactDataDropdown = [
      ...contact.collaborations.map((collaboration) => ({
        key: collaboration.id,
        text: collaboration.medium.name,
        onClick: () => handleEditContactData(listContact, collaboration.medium.id)
      })),
      {
        key: contact.id,
        text: formatMessage({ id: 'list-profile-contacts.own-contact-data' }),
        onClick: () => handleEditContactData(listContact, '')
      }
    ];

    const handleContactRowClick = (contactId: string) => {
      dispatch(contactProfilePanelDataRequested(contactId));
      dispatch(openProfilePanel());
      navigate(`profile`, { replace: true });
    };

    return {
      key: `${contact.id}`,
      nameText: `${contact.firstName} ${contact.lastName}`,
      name: (
        <span className="link-button" onClick={() => handleContactRowClick(contact.id)}>
          {`${contact.firstName} ${contact.lastName}`}
        </span>
      ),
      jobTitle: jobTitle,
      email: emailAddress || <WarningMessage warningText={formatMessage({ id: 'add-contacts-wizard.contact-has-no-email-address' })} />,
      contactData: !contactsIdsToEdit.includes(contact.id) ? (
        contactData
      ) : (
        <div key={`contact-data-dropdown-${contact.id}`}>
          <CommandBarButton text={formatMessage({ id: 'dropdown-placeholders.collaboration' })} menuProps={{ items: contactDataDropdown }} />
        </div>
      ),
      hoverActions: (
        <>
          {hoverIndex === index && (
            <div className="table-actions">
              <Button text disabled={!hasPermissionToEdit} icon={<Icon iconName="Edit" />} onClick={() => handleClickOnEditButton(contact.id)} />
              <Button text disabled={!hasPermissionToEdit} icon={<Icon iconName="Clear" />} onClick={() => handleClickOnRemoveButton(contact)} />
            </div>
          )}
        </>
      ),
      contact: contact
    };
  });

  const handleClickOnEditButton = (contactId: string) => {
    dispatch(setContactsIdsToEdit([...contactsIdsToEdit, contactId]));
  };

  const handleClickOnRemoveButton = (contact: Contact) => {
    selection.setAllSelected(false);
    selection.setKeySelected(contact.id, true, true);
    dispatch(setSelectedContacts([contact]));
    dispatch(openRemoveContactDialog());
  };

  return (
    <>
      <ContactsTableToolbar disabled={!hasPermissionToEdit} />
      {totalCountOfContacts > 0 ? (
        <>
          {listContacts.length > 0 ? (
            <div
              className="table-wrap table--list"
              onMouseLeave={() => {
                setHoverIndex(-1);
              }}
            >
              <MarqueeSelection selection={selection} className="table" isDraggingConstrainedToRoot={true}>
                <ShimmeredDetailsList
                  items={rows}
                  enableShimmer={!rows}
                  columns={header}
                  setKey="listContactsTableSet"
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={selection}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  checkboxVisibility={1}
                  onRenderRow={(rowProps: IDetailsRowProps) => (
                    <div
                      onMouseEnter={() => {
                        setHoverIndex(rowProps.itemIndex);
                      }}
                    >
                      <DetailsRow
                        key={rowProps.item.id}
                        {...rowProps}
                        onRenderCheck={() => (
                          <div className="row-header-wrap">
                            {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
                              <Checkbox checked={selection.getSelection().includes(rowProps.item)} />
                            ) : (
                              <ProfilePicture name={rowProps.item.nameText} imageUrl={rowProps.item.profilePictureUrl} size="small" />
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )}
                />
              </MarqueeSelection>
            </div>
          ) : (
            <EmptyMessage textValueOptional="OOOPS!" textValue={formatMessage({ id: 'list-profile-contacts.no-results' })} srcValue="/wizard-empty-info.svg" />
          )}
        </>
      ) : (
        <EmptyMessage textValue={formatMessage({ id: 'list-profile-contacts.nothing-here-yet' })} srcValue="/empty-list-message.svg" />
      )}
    </>
  );
};
