import { Checkbox, DetailsListLayoutMode, DetailsRow, MarqueeSelection, Selection, ShimmeredDetailsList, Toggle } from '@fluentui/react';
import { SavedSearchesTableHeader, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { LinkedSavedSearch, List } from 'app/pages/my-audience/lists';
import { selectSelectedList, setSelectedLinkedSavedSearches, updateLinkedSavedSearchRequested } from 'app/pages/my-audience/lists-profile';
import { SelectedListSavedSearchTableData } from 'app/pages/my-audience/saved-searches';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export const SavedSearchesTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedList = useAppSelector<List>(selectSelectedList);

  const tableHeader: SavedSearchesTableHeader[] = [
    {
      key: 'column0',
      name: formatMessage({ id: 'list-profile-saved-searches.name' }),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column1',
      name: formatMessage({ id: 'list-profile-saved-searches.creation-date' }),
      fieldName: 'createdAt',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column2',
      name: formatMessage({ id: 'list-profile-saved-searches.last-update' }),
      fieldName: 'lastModifiedAt',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column3',
      name: formatMessage({ id: 'list-profile-saved-searches.number-of-contacts' }),
      fieldName: 'count',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column4',
      name: formatMessage({ id: 'list-profile-saved-searches.auto-update' }),
      fieldName: 'shouldAutoUpdate',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    }
  ];

  const selection = new Selection();

  const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);
  const [isContactCheckboxVisible, toggleContactCheckBox] = useState(true);
  const [hoveringOnContactIndex, setHoveringOnContactIndex] = useState(0);

  const createLinkedSavedSearchesTableData = (linkedSavedSearches: LinkedSavedSearch[]): SelectedListSavedSearchTableData[] => {
    return linkedSavedSearches?.map(
      (lss, i) =>
        new SelectedListSavedSearchTableData(
          i,
          lss.savedSearch.id,
          lss.savedSearch.name,
          DateTime.fromJSDate(lss.savedSearch.createdAt).toLocaleString(DateTime.DATE_SHORT),
          DateTime.fromJSDate(lss.savedSearch.lastModifiedAt).toLocaleString(DateTime.DATE_SHORT),
          lss.totalCountOfContacts,
          <Toggle checked={lss.shouldAutoUpdate} onChange={() => changeAutoUpdate(lss)} />
        )
    );
  };

  const changeAutoUpdate = (savedSearch: LinkedSavedSearch) => {
    dispatch(updateLinkedSavedSearchRequested({ ...savedSearch, shouldAutoUpdate: !savedSearch.shouldAutoUpdate }));
  };

  const [rows, setRows] = useState(createLinkedSavedSearchesTableData(selectedList.linkedSavedSearches));

  useEffect(() => {
    setRows(createLinkedSavedSearchesTableData(selectedList.linkedSavedSearches));
  }, [selectedList]);

  return (
    <>
      {selectedList.linkedSavedSearches?.length > 0 && (
        <MarqueeSelection selection={selection} className="table-results saved-search-table">
          <ShimmeredDetailsList
            items={rows}
            enableShimmer={!rows}
            columns={tableHeader.filter((column) => column.isColumnVisible === true)}
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn={formatMessage({ id: 'aria-labels.toggle-selection' })}
            ariaLabelForSelectAllCheckbox={formatMessage({ id: 'aria-labels.toggle-selection-for-all-items' })}
            checkButtonAriaLabel={formatMessage({ id: 'aria-labels.select-row' })}
            checkboxVisibility={0}
            onRenderRow={(props) => (
              <div
                onMouseEnter={() => {
                  toggleContactCheckBox(true);
                  setHoveringOnContactIndex(props.itemIndex);
                }}
                onMouseLeave={() => {
                  toggleContactCheckBox(false);
                }}
              >
                <DetailsRow
                  {...props}
                  onRenderCheck={() => (
                    <div className="row-header-wrap">
                      {(isContactCheckboxVisible && hoveringOnContactIndex === props.itemIndex) || selectedRowIndexes.includes(props.itemIndex) ? (
                        <Checkbox
                          checked={selectedRowIndexes.includes(props.itemIndex)}
                          onChange={(event, prop) => {
                            let newRows = null;
                            if (prop) {
                              newRows = [...selectedRowIndexes, props.itemIndex];
                            } else {
                              newRows = selectedRowIndexes.filter((index) => index !== props.itemIndex);
                            }
                            setSelectedRowIndexes(newRows);

                            const selectedSavedSearchesIds: string[] = [];
                            newRows.forEach((index) => {
                              const selectedSavedSearch = selectedList.linkedSavedSearches[index];
                              if (selectedSavedSearch) {
                                selectedSavedSearchesIds.push(selectedSavedSearch.savedSearch.id);
                              }
                            });
                            dispatch(setSelectedLinkedSavedSearches(selectedSavedSearchesIds));
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                />
              </div>
            )}
          />
        </MarqueeSelection>
      )}
    </>
  );
};
