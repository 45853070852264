import { DetailsRow, IColumn, ShimmeredDetailsList } from '@fluentui/react';
import { useAppDispatch, useAppSelector, WizardEmptyInfo } from 'app/common';
import { useIntl } from 'app/i18n';
import { CreateContactTableData } from 'app/pages/my-audience/common';
import {
  goToNextStep,
  resetContactToAdd,
  selectContactsTableData,
  selectContactsTotalCount,
  selectIsLoadingContacts,
  setSelectedContact
} from 'app/pages/my-audience/media-outlet-profile';

export const AllContactTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const tableRows = useAppSelector<CreateContactTableData[]>(selectContactsTableData);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingContacts);
  const totalCountOfContacts = useAppSelector<number>(selectContactsTotalCount);

  const onRowClickWhenAddingContribution = (index: number) => {
    dispatch(resetContactToAdd());
    dispatch(setSelectedContact(tableRows[index].key));
    dispatch(goToNextStep());
  };
  const contactsTableHeader: IColumn[] = [
    {
      key: 'media-outlet-create-contact-image',
      fieldName: 'profilePictureUrl',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isRowHeader: false
    },
    {
      key: 'media-outlet-create-contact-name',
      fieldName: 'name',
      name: formatMessage({ id: 'mo-contact-table.name' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'media-outlet-create-contact-role',
      fieldName: 'role',
      name: formatMessage({ id: 'mo-contact-table.job' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'media-outlet-create-contact-email',
      fieldName: 'emailAddress',
      name: formatMessage({ id: 'mo-contact-table.email' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'media-outlet-create-contact-phone',
      fieldName: 'phoneNumber',
      name: formatMessage({ id: 'mo-contact-table.phone' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    }
  ];

  return (
    <>
      <div className="wizard-panel-table add-contact">
        <ShimmeredDetailsList
          items={tableRows}
          enableShimmer={isLoadingContacts}
          columns={contactsTableHeader}
          checkboxVisibility={2}
          onRenderRow={(props) => (
            <div onClickCapture={() => onRowClickWhenAddingContribution(props.itemIndex)}>
              <DetailsRow {...props} />
            </div>
          )}
        />
        {totalCountOfContacts === 0 && !isLoadingContacts && (
          <WizardEmptyInfo
            message={formatMessage({ id: 'no-contacts-selected-message.title' })}
            description={formatMessage({ id: 'no-contacts-selected-message.description' })}
          />
        )}
      </div>
    </>
  );
};
