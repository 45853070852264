import { Icon } from '@fluentui/react';
import { Button, EditIcon, Tooltip } from '@fluentui/react-northstar';
import {
  CopyToClipboardTooltip,
  DatePicker,
  hideEditAndCopyActions,
  hideEditDatePickerField,
  printDateLocalized,
  selectAreEditAndCopyActionsVisible,
  selectEditableFieldActionsId,
  selectEditableFieldId,
  selectIsDatePickerFieldEditing,
  setEditableFieldActionsId,
  setEditableFieldId,
  showEditAndCopyActions,
  showEditDatePickerField,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import _uniqueId from 'lodash/uniqueId';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

interface EditableDatePickerFieldProps {
  value: Date;
  fieldName: string;
  onChange: (name: string, updatedValue: Date) => void;
  label?: string;
  disabled?: boolean;
}

export const EditableDatePickerField = ({ value, fieldName, onChange, label, disabled = false }: EditableDatePickerFieldProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const [editableFieldId] = useState(parseInt(_uniqueId()));
  const [editableFieldActionsId] = useState(parseInt(_uniqueId()));

  const areEditAndCopyActionsVisble = useAppSelector<boolean>(selectAreEditAndCopyActionsVisible);
  const slectorEditableFieldId = useAppSelector<number>(selectEditableFieldId);
  const selectorEditableActionsId = useAppSelector<number>(selectEditableFieldActionsId);
  const isDatePickerediting = useAppSelector<boolean>(selectIsDatePickerFieldEditing);
  const locale = useAppSelector<string>(selectLocale);

  const [updatedValue, setUpdatedValue] = useState(value);

  const handleDismissEditInput = () => {
    if (isDatePickerediting) {
      setUpdatedValue(value);
      dispatch(hideEditDatePickerField());
    }
  };
  useOnClickOutside(ref, handleDismissEditInput);

  const handleMouseEnter = () => {
    dispatch(setEditableFieldId(editableFieldId));
    dispatch(showEditAndCopyActions());
  };

  const handleMouseLeave = () => {
    dispatch(hideEditAndCopyActions());
  };

  const handleEditButtonClick = () => {
    dispatch(setEditableFieldActionsId(editableFieldActionsId));
    dispatch(showEditDatePickerField());
  };

  const handleEditing = () => {
    onChange(fieldName, updatedValue);
    setUpdatedValue(updatedValue);
    dispatch(hideEditDatePickerField());
  };

  return (
    <div className="editable-fields-row" ref={ref}>
      <span className="editable-fields-row-label">{label ? label + ':' : ''}</span>
      <div className="editable-fields-row-content editable-fields-row-content--dropdown" onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isDatePickerediting && editableFieldActionsId === selectorEditableActionsId ? (
          <div className="editable-fields-row-content-dropdown">
            <DatePicker onSelectDate={(selectedDate) => setUpdatedValue(selectedDate)} value={updatedValue} />
            <div className="editable-fields-input-actions">
              <Button onClick={handleDismissEditInput} iconOnly content={<Icon iconName="Cancel" />} text />
              <Button onClick={handleEditing} iconOnly content={<Icon iconName="CheckMark" />} text />
            </div>
          </div>
        ) : (
          <>
            <span className="editable-fields-value">{printDateLocalized(updatedValue, DateTime.DATE_MED_WITH_WEEKDAY, locale)}</span>
            {areEditAndCopyActionsVisble && editableFieldId === slectorEditableFieldId ? (
              <div className="editable-fields-actions">
                <Tooltip
                  subtle={false}
                  pointing
                  trigger={<Button disabled={disabled} icon={<EditIcon />} onClick={handleEditButtonClick} iconOnly text size="small" />}
                  content={formatMessage({ id: 'button-tooltips.edit' })}
                  position="below"
                />
                <CopyToClipboardTooltip
                  textToCopy={DateTime.fromJSDate(updatedValue).setLocale(locale).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                  label={label}
                  props={{ position: 'below' }}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};
