import { Button, ButtonProps, ComponentEventHandler } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectLoadingAddingAadContactToList } from 'app/pages/my-audience/lists-profile';

interface AddContactFromCompanyFooterProps {
  onAddButtonClick: ComponentEventHandler<ButtonProps>;
}

export const AddContactFromCompanyFooter = ({ onAddButtonClick }: AddContactFromCompanyFooterProps) => {
  const { formatMessage } = useIntl();

  const loadingAddingAadContactToList = useAppSelector<boolean>(selectLoadingAddingAadContactToList);

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel" aria-hidden="true"></div>
      <div className="wizard-panel-bottom-actions">
        <Button
          loading={loadingAddingAadContactToList}
          disabled={loadingAddingAadContactToList}
          content={formatMessage({ id: 'buttons.add' })}
          primary
          onClick={onAddButtonClick}
        />
      </div>
    </div>
  );
};
