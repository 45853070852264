import { DropdownProps } from '@fluentui/react-northstar';

interface IdentifiedObject {
  id: string | number;
}

export interface DropdownItem {
  key: string;
  header: string;
}

export type DropDownPropsWtihValue = DropdownProps & { value: DropdownItem };

export const getDropdownA11ySelectionMessage = {
  onAdd: (item: DropdownItem) => {
    return `${item.header} selected`;
  },
  onRemove: (item: DropdownItem) => {
    return `${item.header} has been removed.`;
  }
};

export const mapDropdownItems = <T extends IdentifiedObject>(items: T[], getDispayValueFn: (item: T) => string): DropdownItem[] => {
  return items.map((x: T) => ({
    key: x.id.toString(),
    header: getDispayValueFn(x)
  }));
};
