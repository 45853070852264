import { useAppSelector, Banner } from 'app/common';
import { MediaOutletsSavedSearches } from 'app/pages/my-audience/media-outlets-saved-searches';
import { selectIsSearchAnimationActive } from 'app/pages/my-audience/media-outlets';
import { MediaOutletsSearch } from 'app/pages/my-audience/media-outlet-search-results';

export const MediaOutlets = () => {
  const isSearchAnimationActive = useAppSelector<boolean>(selectIsSearchAnimationActive);

  return (
    <div className={`container ${isSearchAnimationActive ? 'animation--active' : ''}`}>
      <Banner imageSrc="/media-outlet-banner-image.svg" altText="Search media outlets" modifier="media-outlet" />
      <div className="main-content">
        <div className="search-input-container">
          <MediaOutletsSearch />
        </div>
        <div className="saved-searches">
          <MediaOutletsSavedSearches />
        </div>
      </div>
    </div>
  );
};
