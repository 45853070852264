import { PillData, Pills, useAppDispatch, useAppSelector } from 'app/common';
import { MediaResort, filterMediaResorts, removeMediaResortsRequested, selectFilteredMediaResorts } from 'app/pages/my-audience/media-outlet-profile';
import {
  addMediaResortForBulkUpdate,
  removeMediaResortsForBulkUpdate,
  selectMediaResortsForBulkUpdate,
  createMediaResortRequested
} from 'app/pages/my-audience/media-outlets';
import { useIntl } from 'app/i18n';

export const BulkUpdateMediaResorts = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const allMediaResorts = useAppSelector<MediaResort[]>(selectFilteredMediaResorts);
  const mediaResortsToAdd = useAppSelector<MediaResort[]>(selectMediaResortsForBulkUpdate);

  const handleCreateMediaResort = (mediaResortName: string) => {
    const newMediaResort = new MediaResort('', mediaResortName, '');

    dispatch(createMediaResortRequested(newMediaResort));
  };

  const handleSelectMediaResort = (mediaResortId: string) => {
    const mediaResortToAdd = allMediaResorts.find((mediaResort) => mediaResort.id === mediaResortId);

    dispatch(addMediaResortForBulkUpdate(mediaResortToAdd));
  };

  const handleDeselectMediaResort = (mediaResortId: string) => {
    const mediaResortToRemove = mediaResortsToAdd.find((mediaResort) => mediaResort.id === mediaResortId);

    dispatch(removeMediaResortsForBulkUpdate(mediaResortToRemove));
  };

  const handleDeleteMediaResorts = (mediaResortsIds: string[]) => {
    const mediaResortsToDelete = allMediaResorts.filter((mediaResort) => mediaResortsIds.includes(mediaResort.id));

    dispatch(removeMediaResortsRequested(mediaResortsToDelete));
  };

  const handleFilterMediaResorts = (searchText: string) => {
    dispatch(filterMediaResorts(searchText));
  };

  return (
    <div className="profile-panel-topic-tags-dropdown">
      <Pills
        entityName={formatMessage({ id: 'labels.media-resort' })}
        selectedValues={mediaResortsToAdd.map((mediaResort) => new PillData(mediaResort.id, mediaResort.name, true))}
        dropdownValues={allMediaResorts.map((mediaResort) => new PillData(mediaResort.id, mediaResort.name, true))}
        onAddValue={handleCreateMediaResort}
        onSelectValue={handleSelectMediaResort}
        onDeselectValue={handleDeselectMediaResort}
        onDeleteValues={handleDeleteMediaResorts}
        filterValues={handleFilterMediaResorts}
        enabledDelete={true}
        disabled={false}
      />
    </div>
  );
};
