import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchBlacklistById, MessageData, safe, safelyHandleError, setAlertData, Tag } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import {
  addAadContactsToSending,
  addAadContactsToSendingRequested,
  addAiContentReport,
  addAiContentReportMessageRequested,
  addContactsFromListsRequested,
  addRecipientsFromListToSending,
  addRecipientsToSending,
  addRecipientsToSendingRequested,
  addSendingFromTemplate,
  addTagToSending,
  addTagToSendingRequested,
  beeTokenReceived,
  beeTokenRequested,
  blacklistFromPreviewTabReceived,
  blacklistFromPreviewTabRequested,
  contactsByListIdReceived,
  contactsByNameOrEmailReceived,
  convertTemplateHtmlToJpeg,
  createNewBlankEmailRequested,
  createNewEmailWithRecipientsFromListRequested,
  createNewEmailWithRecipientsRequested,
  createSendingFromTemplateRequested,
  fetchBeeToken,
  fetchContactsByNameOrEmailAddressNotContainedInSendingRecipients,
  fetchRecipientsBySendingIdAndContactName,
  fetchSendersByUserAadEmailAddress,
  fetchSendingById,
  fetchSendingLogsBySendingId,
  fetchSendingsForEditorByQueryParams,
  getContactsByListIdRequested,
  getContactsByNameOrEmailNotContainedInSendingRecipientsRequested,
  getListsByNameRequested,
  getPeoplePickerSuggestionsRequested,
  getSelectedSendingOverviewRequested,
  getSelectedSendingRequested,
  getSendersRequested,
  getSendingOverviewBySendingId,
  getSendingsAudience,
  isOpenAiAddonEnabled,
  isOpenAiEnabledRequested,
  listsByNameReceived,
  loadingAudienceThrewAnError,
  newBlankEmailCreated,
  newEmailWithRecipientsCreated,
  newEmailWithRecipientsFromListCreated,
  openAiEnabledReceived,
  PageOfRecipients,
  pageOfRecipientsReceived,
  pageOfRecipientsRequested,
  pageOfSendingsForEditorReceived,
  pageOfSendingsForEditorRequested,
  peoplePickerSuggestionsReceived,
  recipientsRemovedFromSending,
  removeRecipientsFromSending,
  removeRecipientsFromSendingRequested,
  removeTagFromSending,
  removeTagFromSendingRequested,
  scheduleSending,
  scheduleSendingRequested,
  selectAadSearchText,
  selectAiContentReportMessage,
  selectContactSearchText,
  selectContactsFromCompanyToAdd,
  selectContactsFromListToAdd,
  selectContactsIdsToAdd,
  selectDefaultSendingName,
  selectedSendingOverviewReceived,
  selectedSendingReceived,
  selectEmailBodyInEditor,
  selectListsSearchText,
  selectNewSending,
  selectRecipientIdsToRemove,
  selectRecipientsPageNumber,
  selectRecipientsPageSize,
  selectRecipientsSearchText,
  selectRecipientsToAdd,
  selectRecipientToUpdate,
  selectSearchContactsByNameOrEmailPageSize,
  selectSelectedListId,
  selectSelectedListsIds,
  selectSelectedSending,
  selectSelectedSendingId,
  selectSelectedTemplate,
  selectSendingLogsPageNumber,
  selectSendingLogsPageSize,
  selectSendingScheduledAt,
  selectSendingsPageNumber,
  selectSendingsPageSize,
  selectSendingsSearchText,
  selectTagToAddToSending,
  selectTagToRemoveFromSending,
  selectUpdatedSending,
  selectUpdatedTemplate,
  sendersReceived,
  sendingAudienceReceived,
  sendingAudienceRequested,
  sendingFromTemplateCreated,
  sendingLogsReceived,
  sendingLogsRequested,
  sendingRecipientContactDataUpdated,
  sendingWizardUpdated,
  sendSending,
  sendSendingRequested,
  setIsReportAIContentModalOpen,
  setRecipientsQueryParams,
  templateAsImageReceived,
  templateAsImageRequested,
  updateRecipientContactData,
  updateSelectedTemplate,
  updateSendingRecipientContactDataRequested,
  updateSendingRequested,
  updateTemplateRequested
} from 'app/pages/my-activities/sending-wizard';
import {
  addRecipients,
  addSending,
  addSendingWithContactsIds,
  addSendingWithRecipientsFromLists,
  PageOfSendings,
  Recipient,
  RecipientFromList,
  removeRecipients,
  Sender,
  Sending,
  sendingAdded,
  sendingUpdated,
  updateSending,
  UpdateSendingRecipientInput
} from 'app/pages/my-activities/sendings';
import { AadContact, Blacklist, Contact } from 'app/pages/my-audience/contacts';
import { List, ListContact } from 'app/pages/my-audience/lists';
import {
  addAadContacts,
  fetchAADContactsBySearchText,
  fetchAADGroupsBySearchText,
  fetchContactsByListId,
  fetchListsByName
} from 'app/pages/my-audience/lists-profile/services/list-profile.service';
import { Template, templateUpdated, updateTemplate } from 'app/pages/my-activities/templates';
import { PageOfSendingLog } from 'app/common/graphql/generated/graphql-gateway';

export function* createNewBlankEmailRequestedWatcher() {
  yield takeLatest(createNewBlankEmailRequested.type, safe(createNewBlankEmailRequestedFlow));
}

function* createNewBlankEmailRequestedFlow() {
  const sending: Sending = yield select(selectNewSending);
  const addedSending: Sending = yield call(addSending, client, sending);

  yield put(sendingAdded(addedSending));
  yield put(newBlankEmailCreated(addedSending));
}

export function* isOpenAiAddonEnabledRequestedWatcher() {
  yield takeLatest(isOpenAiEnabledRequested.type, safe(isOpenAiAddonEnabledRequestedFlow));
}

function* isOpenAiAddonEnabledRequestedFlow() {
  const response = yield call(isOpenAiAddonEnabled, client);
  yield put(openAiEnabledReceived(response));
}

export function* createEmailFromTemplateRequestedWatcher() {
  yield takeLatest(createSendingFromTemplateRequested.type, safe(createEmailFromTemplateFlow));
}

function* createEmailFromTemplateFlow() {
  const template: Template = yield select(selectSelectedTemplate);
  const sendingName = yield select(selectDefaultSendingName);
  const addedSending: Sending = yield call(addSendingFromTemplate, client, template.id, sendingName);

  yield put(sendingAdded(addedSending));
  yield put(sendingFromTemplateCreated(addedSending));
}

export function* createNewEmailWithRecipientsRequestedWatcher() {
  yield takeLatest(createNewEmailWithRecipientsRequested.type, safe(createNewEmailWithRecipientsRequestedFlow));
}

function* createNewEmailWithRecipientsRequestedFlow() {
  const sending: Sending = yield select(selectNewSending);
  const contactsIdsToAdd: string[] = yield select(selectContactsIdsToAdd);

  const addedSending: Sending = yield call(addSendingWithContactsIds, client, sending, contactsIdsToAdd);
  yield put(sendingAdded(addedSending));
  yield put(newEmailWithRecipientsCreated(addedSending));
}

export function* createNewEmailWithRecipientsFromListRequestedWatcher() {
  yield takeLatest(createNewEmailWithRecipientsFromListRequested.type, safe(createNewEmailWithRecipientsFromListRequestedFlow));
}

function* createNewEmailWithRecipientsFromListRequestedFlow() {
  const sending: Sending = yield select(selectNewSending);
  const listsIds: string[] = yield select(selectSelectedListsIds);

  const addedSending: Sending = yield call(addSendingWithRecipientsFromLists, client, sending, listsIds);
  yield put(sendingAdded(addedSending));
  yield put(newEmailWithRecipientsFromListCreated(addedSending));
}

export function* sendSendingRequestedWatcher() {
  yield takeLatest(sendSendingRequested.type, safe(sendSendingRequestedFlow));
}

function* sendSendingRequestedFlow() {
  const sending: Sending = yield select(selectSelectedSending);

  const sentSending: Sending = yield call(sendSending, client, sending.id);
  yield put(sendingUpdated(sentSending));
}

export function* scheduleSendingRequestedWatcher() {
  yield takeLatest(scheduleSendingRequested.type, safe(scheduleSendingRequestedFlow));
}

function* scheduleSendingRequestedFlow() {
  const sending: Sending = yield select(selectSelectedSending);
  const scheduledAt: Date = yield select(selectSendingScheduledAt);

  const scheduledSending: Sending = yield call(scheduleSending, client, sending.id, scheduledAt);
  yield put(sendingUpdated(scheduledSending));
}

export function* updateTemplateBodyRequestedWatcher() {
  yield takeLatest(updateTemplateRequested.type, safe(updateTemplateFlow));
}

function* updateTemplateFlow() {
  const template: Template = yield select(selectUpdatedTemplate);

  const updatedTemplate: Template = yield call(updateTemplate, client, template);
  yield put(templateUpdated(updatedTemplate));
  yield put(updateSelectedTemplate(updatedTemplate));
}

export function* addRecipientsToSendingRequestedWatcher() {
  yield takeLatest(addRecipientsToSendingRequested.type, safe(addRecipientsToSendingRequestedFlow));
}

function* addRecipientsToSendingRequestedFlow() {
  const sending: Sending = yield select(selectSelectedSending);
  const recipientsToAdd: Recipient[] = yield select(selectRecipientsToAdd);

  const recipients: Recipient[] = yield call(addRecipientsToSending, client, sending.id, recipientsToAdd);

  const searchText: string = yield select(selectRecipientsSearchText);
  const pageSize: number = yield select(selectRecipientsPageSize);
  const pageNumber: number = yield select(selectRecipientsPageNumber);
  const skip: number = (pageNumber - 1) * pageSize;

  const pageOfRecipients: PageOfRecipients = yield call(fetchRecipientsBySendingIdAndContactName, client, sending.id, searchText, skip, pageSize);

  yield put(pageOfRecipientsReceived(pageOfRecipients));
  yield put(addRecipients({ sendingId: sending.id, numberOfRecipients: recipients.length }));
}

export function* getSendersRequestedWatcher() {
  yield takeLatest(getSendersRequested.type, safe(getSendersRequestedFlow));
}

function* getSendersRequestedFlow() {
  const senders: Sender[] = yield call(fetchSendersByUserAadEmailAddress, client);
  yield put(sendersReceived(senders));
}

export function* getContactSuggestionsRequestedWatcher() {
  yield takeLatest(getContactsByNameOrEmailNotContainedInSendingRecipientsRequested.type, safe(getContactSuggestionsRequestedFlow));
}

function* getContactSuggestionsRequestedFlow() {
  const searchText: string = yield select(selectContactSearchText);
  const selectedSending: Sending = yield select(selectSelectedSending);
  const take: number = yield select(selectSearchContactsByNameOrEmailPageSize);

  const contacts: Contact[] = yield call(fetchContactsByNameOrEmailAddressNotContainedInSendingRecipients, client, searchText, selectedSending.id, take);
  yield put(contactsByNameOrEmailReceived(contacts));
}

export function* removeRecipientsFromSendingRequestedWatcher() {
  yield takeLatest(removeRecipientsFromSendingRequested.type, safe(removeRecipientsFromSendingRequestedFlow));
}

function* removeRecipientsFromSendingRequestedFlow() {
  const sending: Sending = yield select(selectSelectedSending);
  const recipientIdsToRemove: string[] = yield select(selectRecipientIdsToRemove);

  const recipientsIds = yield call(removeRecipientsFromSending, client, sending.id, recipientIdsToRemove);

  yield put(recipientsRemovedFromSending(recipientsIds));
  yield put(removeRecipients({ sendingId: sending.id, numberOfRecipients: recipientsIds.length }));
  yield put(setRecipientsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfRecipientsRequested());
  yield put(setAlertData(new MessageData('alert-recipients-removed-from-sending-counter', { counter: recipientsIds.length })));
}

export function* updateRecipientContactDataRequestedWatcher() {
  yield takeLatest(updateSendingRecipientContactDataRequested.type, safe(updateRecipientContactDataRequestedFlow));
}

function* updateRecipientContactDataRequestedFlow() {
  const sending: Sending = yield select(selectSelectedSending);
  const recipientToUpdate: UpdateSendingRecipientInput = yield select(selectRecipientToUpdate);

  const recipient: Recipient = yield call(
    updateRecipientContactData,
    client,
    sending.id,
    recipientToUpdate.recipientId,
    recipientToUpdate.collaborationId,
    recipientToUpdate.emailAddress
  );
  yield put(sendingRecipientContactDataUpdated(recipient));
}

export function* fetchBeeTokenWatcher() {
  yield takeLatest(beeTokenRequested().type, safe(fetchBeeTokenFlow));
}

function* fetchBeeTokenFlow() {
  const beeToken = yield call(fetchBeeToken);

  yield put(beeTokenReceived(beeToken));
}

export function* getPeoplePickerSuggestionsRequestWatcher() {
  yield takeLatest(getPeoplePickerSuggestionsRequested.type, safe(fetchPeoplePickerSuggestionsFlow));
}

function* fetchPeoplePickerSuggestionsFlow() {
  const aadSearchText: string = yield select(selectAadSearchText);

  const [contacts, groups] = yield all([call(fetchAADContactsBySearchText, client, aadSearchText), call(fetchAADGroupsBySearchText, client, aadSearchText)]);
  yield put(peoplePickerSuggestionsReceived({ contacts, groups }));
}

export function* addAadContactsToSendingRequestedWatcher() {
  yield takeLatest(addAadContactsToSendingRequested.type, safe(addAAdContactsToSendingFlow));
}

function* addAAdContactsToSendingFlow() {
  const aadContactsToAdd: AadContact[] = yield select(selectContactsFromCompanyToAdd);
  const sending: Sending = yield select(selectSelectedSending);

  yield call(addAadContactsToSending, client, sending.id, aadContactsToAdd);

  const searchText = yield select(selectRecipientsSearchText);
  const pageSize = yield select(selectRecipientsPageSize);
  const pageNumber = yield select(selectRecipientsPageNumber);
  const skip = (pageNumber - 1) * pageSize;

  const pageOfRecipients: PageOfRecipients = yield call(fetchRecipientsBySendingIdAndContactName, client, sending.id, searchText, skip, pageSize);
  yield put(pageOfRecipientsReceived(pageOfRecipients));
}

export function* fetchTemplateAsImageWatcher() {
  yield takeEvery(templateAsImageRequested, safe(fetchTemplateAsImageFlow));
}

function* fetchTemplateAsImageFlow() {
  const { htmlBody } = yield select(selectEmailBodyInEditor);

  if (htmlBody === '' || htmlBody === undefined) {
    yield put(templateAsImageReceived(''));
    return;
  }
  const image = yield call(convertTemplateHtmlToJpeg, htmlBody);

  yield put(templateAsImageReceived(image));
}

export function* getContactsByListsIdsRequestWatcher() {
  yield takeEvery(getContactsByListIdRequested.type, safe(fetchContactsByListsIdsFlow));
}

function* fetchContactsByListsIdsFlow() {
  const listId: string = yield select(selectSelectedListId);
  const sending: Sending = yield select(selectSelectedSending);
  const recipients: RecipientFromList[] = [];

  const contacts: ListContact[] = yield call(fetchContactsByListId, client, listId);
  recipients.push(
    ...contacts.map((listContact) => {
      const contact: Contact = listContact.contact;
      const primaryCollaboration = contact.collaborations.find((c) => c.isPrimary);

      const primaryEmailAddress =
        contact.ownContactData && contact.ownContactData.isPrimary ? contact.ownContactData.emailAddress : primaryCollaboration.emailAddress;
      const collaborationId = contact.collaborations.find((c) => c.isPrimary) !== undefined ? contact.collaborations.find((c) => c.isPrimary).id : '';
      return new RecipientFromList('', sending.id, contact, collaborationId, listId, primaryEmailAddress, false, 0);
    })
  );

  yield put(contactsByListIdReceived(recipients));
}

export function* addContactsFromListsRequestedWatcher() {
  yield takeEvery(addContactsFromListsRequested.type, safe(addContactsFromListFlow));
}

function* addContactsFromListFlow() {
  const recipientsToAdd: RecipientFromList[] = yield select(selectContactsFromListToAdd);
  const sending: Sending = yield select(selectSelectedSending);

  const recipients = yield call(addRecipientsFromListToSending, client, sending.id, recipientsToAdd);
  const searchText = yield select(selectRecipientsSearchText);
  const pageSize = yield select(selectRecipientsPageSize);
  const pageNumber = yield select(selectRecipientsPageNumber);
  const skip = (pageNumber - 1) * pageSize;

  const pageOfRecipients: PageOfRecipients = yield call(fetchRecipientsBySendingIdAndContactName, client, sending.id, searchText, skip, pageSize);
  yield put(pageOfRecipientsReceived(pageOfRecipients));
  yield put(addRecipients({ sendingId: sending.id, numberOfRecipients: recipients.length }));
}

export function* getSelectedSendingRequestedWatcher() {
  yield takeLatest(getSelectedSendingRequested.type, safe(getSelectedSendingRequestedFlow));
}

function* getSelectedSendingRequestedFlow() {
  const selectedSendingId: string = yield select(selectSelectedSendingId);
  const sending = yield call(fetchSendingById, client, selectedSendingId);
  yield put(selectedSendingReceived(sending));
}

function* addTagToSendingFlow() {
  const tagToAdd: Tag = yield select(selectTagToAddToSending);
  const selectedSending: Sending = yield select(selectSelectedSending);

  yield call(addTagToSending, client, selectedSending.id, tagToAdd);
}

export function* addTagToSendingRequestedWatcher() {
  yield takeEvery(addTagToSendingRequested.type, safe(addTagToSendingFlow));
}

function* removeTagFromSendingFlow() {
  const tagToRemove: Tag = yield select(selectTagToRemoveFromSending);
  const selectedSending: Sending = yield select(selectSelectedSending);

  yield call(removeTagFromSending, client, selectedSending.id, tagToRemove);
}

export function* removeTagFromSendingRequestedWatcher() {
  yield takeEvery(removeTagFromSendingRequested.type, safe(removeTagFromSendingFlow));
}

function* fetchSendingLogsRequestedFlow() {
  const selectedSending = yield select(selectSelectedSending);
  const pageNumber = yield select(selectSendingLogsPageNumber);
  const pageSize = yield select(selectSendingLogsPageSize);

  const skip = (pageNumber - 1) * pageSize;

  const pageOfSendingLogs: PageOfSendingLog = yield call(fetchSendingLogsBySendingId, client, selectedSending.id, skip, pageSize);
  yield put(sendingLogsReceived({ logs: pageOfSendingLogs.sendingLogs, totalNumberOfLogs: pageOfSendingLogs.totalCount }));
}

export function* fetchSendingLogsRequestedWatcher() {
  yield takeLatest(sendingLogsRequested.type, safe(fetchSendingLogsRequestedFlow));
}

export function* fetchBlacklistFromPreviewTabWatcher() {
  yield takeLatest(blacklistFromPreviewTabRequested.type, safe(fetchBlacklistFromPreviewTabFlow));
}

function* fetchBlacklistFromPreviewTabFlow() {
  const sending: Sending = yield select(selectSelectedSending);
  if (!sending.blacklist.id) {
    return;
  }

  const blacklist: Blacklist = yield call(fetchBlacklistById, client, sending.blacklist.id);
  yield put(blacklistFromPreviewTabReceived(new Blacklist(blacklist.id, blacklist.name)));
}

export function* updateSendingFromWizardRequestedWatcher() {
  yield takeLatest(updateSendingRequested.type, safe(updateSendingFlow));
}

function* updateSendingFlow() {
  const sendingToUpdate: Sending = yield select(selectUpdatedSending);

  const updatedSending = yield call(updateSending, client, sendingToUpdate.id, sendingToUpdate);
  yield put(sendingUpdated(updatedSending));
  yield put(sendingWizardUpdated(updatedSending));
}

function* fetchListsByNameFlow() {
  const listSearchText: string = yield select(selectListsSearchText);
  const lists: List[] = yield call(fetchListsByName, client, listSearchText);
  yield put(listsByNameReceived(lists));
}

export function* fetchListsByNameRequestedWatcher() {
  yield takeLatest(getListsByNameRequested.type, safe(fetchListsByNameFlow));
}

function* fetchRecipientsBySendingIdAndContactNameFlow() {
  const sendingId: string = yield select(selectSelectedSendingId);
  const searchText: string = yield select(selectRecipientsSearchText);
  const pageSize: number = yield select(selectRecipientsPageSize);
  const pageNumber: number = yield select(selectRecipientsPageNumber);
  const skip: number = (pageNumber - 1) * pageSize;

  const pageOfRecipients: PageOfRecipients = yield call(fetchRecipientsBySendingIdAndContactName, client, sendingId, searchText, skip, pageSize);
  yield put(pageOfRecipientsReceived(pageOfRecipients));
}

export function* fetchRecipientsBySendingIdAndContactNameRequestedWatcher() {
  yield takeEvery(pageOfRecipientsRequested.type, safe(fetchRecipientsBySendingIdAndContactNameFlow));
}

function* fetchPageOfSendingsForEditorByQueryParams() {
  const pageNumber = yield select(selectSendingsPageNumber);
  const pageSize = yield select(selectSendingsPageSize);
  const searchText = yield select(selectSendingsSearchText);

  const pageOfSendings: PageOfSendings = yield call(fetchSendingsForEditorByQueryParams, client, pageNumber, pageSize, [], searchText);

  yield put(pageOfSendingsForEditorReceived({ sendings: pageOfSendings.sendings, totalCount: pageOfSendings.totalCount }));
}

export function* fetchSendingsForEditorRequestedWatcher() {
  yield takeLatest(pageOfSendingsForEditorRequested.type, safe(fetchPageOfSendingsForEditorByQueryParams));
}

function* fetchSendingAudienceFlow() {
  const sendingId = yield select(selectSelectedSendingId);
  const preferredNumberOfContactsToDisplay = 10;

  const sendingAudience = yield call(getSendingsAudience, client, sendingId, preferredNumberOfContactsToDisplay);

  yield put(sendingAudienceReceived(sendingAudience));
}

function* loadingAudienceThrewAnErrorFlow() {
  yield put(loadingAudienceThrewAnError());
}

export function* fetchSendingAudienceWatcher() {
  yield takeLatest(sendingAudienceRequested.type, safelyHandleError({ performAction: fetchSendingAudienceFlow, onCatch: loadingAudienceThrewAnErrorFlow }));
}

export function* getSelectedSendingOverviewRequestedWatcher() {
  yield takeLatest(getSelectedSendingOverviewRequested.type, safe(getSelectedSendingOverviewRequestedFlow));
}

function* getSelectedSendingOverviewRequestedFlow() {
  const selectedSendingId: string = yield select(selectSelectedSendingId);
  const sendingOverview = yield call(getSendingOverviewBySendingId, client, selectedSendingId);
  yield put(selectedSendingOverviewReceived(sendingOverview));
}

function* addAiContentReportMessageFlow() {
  const message: string = yield select(selectAiContentReportMessage);

  yield call(addAiContentReport, client, message);

  yield put(setIsReportAIContentModalOpen(false));
  yield put(setAlertData(new MessageData('alert-report-successfully-submitted')));
}

export function* addAiContentReportMessageRequestedWatcher() {
  yield takeLatest(addAiContentReportMessageRequested.type, safe(addAiContentReportMessageFlow));
}
