import { TableHeader } from 'app/common/common.model';
import { ContactsSortOption } from 'app/common/graphql/generated/graphql-gateway';

export const buildLocalizedSendingsColumns = (
  statusLabel: string,
  titleLabel: string,
  createdAtLabel: string,
  lastModifiedAtLabel: string,
  lastModifiedByLabel: string,
  numberOfRecipientsLabel: string,
  blacklistsLabel: string,
  subjectLabel: string,
  sortAscendingAriaLabel: string,
  sortDescendingAriaLabel: string
): TableHeader[] => {
  return [
    {
      key: 'sendingColumn2',
      name: titleLabel,
      fieldName: 'emailJsxElement',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.EmailTitle
    },
    {
      key: 'sendingColumn1',
      name: statusLabel,
      fieldName: 'statusLabel',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.Status
    },
    {
      key: 'sendingColumn3',
      name: createdAtLabel,
      fieldName: 'createdAt',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isColumnVisible: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.CreatedAt
    },
    {
      key: 'sendingColumn4',
      name: lastModifiedAtLabel,
      fieldName: 'lastModified',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isColumnVisible: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.LastModifiedAt
    },
    {
      key: 'sendingColumn5',
      name: lastModifiedByLabel,
      fieldName: 'lastModifiedBy',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isColumnVisible: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.LastModifiedBy
    },
    {
      key: 'sendingColumn6',
      name: numberOfRecipientsLabel,
      fieldName: 'numberOfRecipients',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isColumnVisible: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.NumberOfRecipients
    },
    {
      key: 'sendingColumn7',
      name: blacklistsLabel,
      fieldName: 'blacklists',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isColumnVisible: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.BlacklistName
    },
    {
      key: 'sendingColumn8',
      name: subjectLabel,
      fieldName: 'subject',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isColumnVisible: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel,
      sortDescendingAriaLabel,
      sortOption: ContactsSortOption.Subject
    }
  ];
};
