import { LongTitle, maximumSearchTextLength, selectIsOnDesktop, selectIsOnMobile, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectCountOfLoadedMediaOutlets, selectSearchText, selectTotalCountOfMediaOutlets } from 'app/pages/my-audience/media-outlets';

export const MediaOutletsSearchResultsIntroText = () => {
  const { formatMessage } = useIntl();
  const countOfLoadedEntities = useAppSelector<number>(selectCountOfLoadedMediaOutlets);
  const totalCountOfMediaOutlets = useAppSelector<number>(selectTotalCountOfMediaOutlets);
  const searchText = useAppSelector<string>(selectSearchText);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);
  return (
    <div className="search-results-text mo-search-results-text">
      {isOnDesktop && (
        <span>
          {formatMessage({ id: 'search-results-table.intro.number-of-results' }, { visibleCount: countOfLoadedEntities, totalCount: totalCountOfMediaOutlets })}
          <span className="searched-text">
            &quot;
            <LongTitle value={searchText} maximumLength={maximumSearchTextLength} />
            &quot;
          </span>
        </span>
      )}
      {isOnMobile && <span>{formatMessage({ id: 'search-results-table.intro.number-mobile' }, { totalCount: totalCountOfMediaOutlets })}</span>}
    </div>
  );
};
