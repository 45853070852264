import { Button, EditIcon } from '@fluentui/react-northstar';
import { printData, useAppDispatch, useAppSelector } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import {
  ContactInfo,
  closeCreateContactWizard,
  selectContactInfoToAdd,
  goToPreviousContactStep,
  goToNextContactStep,
  CreateNewContactFooter,
  CreateContactFromCompanySteps,
  goToCreateContactStep,
  CreateContactFromCompanyWizardStep,
  addContactRequested
} from 'app/pages/my-audience/wizard-create-company';

export const FinishCreateContact = () => {
  const contactToAdd = useAppSelector<ContactInfo>(selectContactInfoToAdd);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const handleCancelButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousContactStep());
  };

  const handleNextButtonClick = () => {
    dispatch(goToNextContactStep());
  };

  const onClickEdit = () => {
    dispatch(goToCreateContactStep(CreateContactFromCompanyWizardStep.CreateNew));
  };

  const handleFinishButtonClick = () => {
    dispatch(addContactRequested());
    dispatch(closeCreateContactWizard());
  };

  return (
    <>
      <CreateContactFromCompanySteps />
      <div className="wizard-panel-finish-wrap">
        <div className="wizard-panel-intro">
          <h5 className="wizard-panel-title">
            <FormattedMessage id={'add-menu-create-company.contact-finish-title'} />
          </h5>
        </div>
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-main-intro">
              <h6 className="wizard-panel-finish-title">
                <FormattedMessage id={'add-menu-create-company.contact-finish-info'} />
              </h6>
              <Button icon={<EditIcon />} text content={formatMessage({ id: 'buttons.edit' })} onClick={onClickEdit} />
            </div>
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.contact-title-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(contactToAdd.title)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.contact-first-name-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(contactToAdd.firstName)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.contact-last-name-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(contactToAdd.lastName)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateNewContactFooter
        onCancelButtonClick={handleCancelButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onNextButtonClick={handleNextButtonClick}
        onFinishButtonClick={handleFinishButtonClick}
      />
    </>
  );
};
