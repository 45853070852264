import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { AvatarDetailsInput, AvatarDetailsWithSavedSearchId, gatewayClient as client, safe, setAlertData, MessageData, FilterItem } from 'app/common';
import {
  fetchBulkContactAvatarDetails,
  fetchContactAvatarDetails,
  selectFilterItems as selectFilterItemsFromContacts,
  selectSearchText as selectSearchTextFromContacts
} from 'app/pages/my-audience/contacts';
import {
  addContactSavedSearch,
  addContactSavedSearchRequested,
  contactSavedSearchAdded,
  contactsSavedSearchesReceived,
  contactsSavedSearchesRequested,
  fetchPagedContactSavedSearches,
  firstPageOfSavedSearchesReceived,
  firstPageOfSavedSearchesRequested,
  loadNextContactSavedSearchAfterDeleteRequested,
  removeContactSavedSearch,
  removeContactSavedSearchRequested,
  removedContactSavedSearch,
  selectContactSavedSearches,
  selectContactSavedSearchesToUpdate,
  selectContactSavedSearchToRemoveId,
  selectContactSavedSearchToUpdate,
  selectFilterItem,
  selectPageNumber,
  selectPageSize,
  selectSearchText,
  selectTotalCountOfSavedSearches,
  setTotalCountOfSavedSearches,
  updateContactSavedSearch,
  updateContactSavedSearches,
  updateContactSavedSearchesRequested,
  updateContactSavedSearchRequested,
  updatedContactSavedSearch,
  updatedContactSavedSearches
} from 'app/pages/my-audience/contacts-saved-searches';
import { PageOfSavedSearches, SavedSearch, SavedSearchInput, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';

export function* fetchPagedContactSavedSearchesWatcher() {
  yield takeLatest(firstPageOfSavedSearchesRequested.type, safe(fetchPagedContactSavedSearchesFlow));
  yield takeLatest(contactsSavedSearchesRequested.type, safe(fetchPagedContactSavedSearchesFlow));
}

function* fetchPagedContactSavedSearchesFlow() {
  const pageNumber = yield select(selectPageNumber);
  const pageSize = yield select(selectPageSize);
  const searchText = yield select(selectSearchText);
  const filterItem = yield select(selectFilterItem);

  const skip = (pageNumber - 1) * pageSize;

  const pageOfSavedSearches: PageOfSavedSearches = yield call(fetchPagedContactSavedSearches, client, skip, pageSize, searchText, [filterItem]);

  const savedSearches = pageOfSavedSearches.savedSearches;
  const contactsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const contactsAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchBulkContactAvatarDetails, client, contactsAvatarDetailsInputs);

  contactsAvatarDetails.forEach((contactAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === contactAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = contactAvatarDetails.avatarDetails;
    savedSearch.resultsLength = contactAvatarDetails.totalCount;
  });

  if (pageNumber === 1) {
    yield put(firstPageOfSavedSearchesReceived(savedSearches));
  } else {
    yield put(contactsSavedSearchesReceived(savedSearches));
  }
  yield put(setTotalCountOfSavedSearches(pageOfSavedSearches.totalCount));
}

function* addContactSavedSearchFlow() {
  const searchText: string = yield select(selectSearchTextFromContacts);
  const filterItems: FilterItem[] = yield select(selectFilterItemsFromContacts);

  const savedSearchToAdd = new SavedSearchInput(searchText || '', searchText || '*', filterItems);

  const savedSearch = yield call(addContactSavedSearch, client, savedSearchToAdd);
  const { avatarDetails, totalCount } = yield call(fetchContactAvatarDetails, client, savedSearch.searchText, savedSearch.filterItems);
  savedSearch.avatarDetails = avatarDetails;
  savedSearch.resultsLength = totalCount;

  yield put(contactSavedSearchAdded(savedSearch));
  yield put(setAlertData(new MessageData('alert-saved-search-created')));
}

export function* addContactSavedSearchRequestedWatcher() {
  yield takeEvery(addContactSavedSearchRequested.type, safe(addContactSavedSearchFlow));
}

function* updateContactSavedSearchFlow() {
  const savedSearchToUpdate: SavedSearch = yield select(selectContactSavedSearchToUpdate);
  const savedSearchInput = new SavedSearchInput(savedSearchToUpdate.name, savedSearchToUpdate.searchText, savedSearchToUpdate.filterItems);

  const savedSearch: SavedSearch = yield call(updateContactSavedSearch, client, savedSearchToUpdate.id, savedSearchInput);
  const result = yield call(
    fetchContactAvatarDetails,
    client,
    savedSearch.searchText,
    savedSearch.filterItems.map(({ fieldName, value }) => new FilterItem(fieldName, value))
  );
  savedSearch.avatarDetails = result.avatarDetails;
  savedSearch.resultsLength = result.totalCount;

  yield put(updatedContactSavedSearch(savedSearch));
  yield put(setAlertData(new MessageData('alert-saved-search-updated')));
}

export function* updateContactSavedSearchRequestWatcher() {
  yield takeEvery(updateContactSavedSearchRequested.type, safe(updateContactSavedSearchFlow));
}

function* updateContactSavedSearchesFlow() {
  const savedSearchesToUpdate: SavedSearchWithIdInput[] = yield select(selectContactSavedSearchesToUpdate);

  const savedSearches = yield call(updateContactSavedSearches, client, savedSearchesToUpdate);

  const contactsQueryInput = savedSearches.map((savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems));
  const contactsAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchBulkContactAvatarDetails, client, contactsQueryInput);

  contactsAvatarDetails.forEach((contactAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === contactAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = contactAvatarDetails.avatarDetails;
    savedSearch.resultsLength = contactAvatarDetails.totalCount;
  });

  yield put(updatedContactSavedSearches(savedSearches));
}

export function* updateContactSavedSearchesRequestWatcher() {
  yield takeEvery(updateContactSavedSearchesRequested.type, safe(updateContactSavedSearchesFlow));
}

function* removeContactSavedSearchFlow() {
  const savedSearchToRemoveId: string = yield select(selectContactSavedSearchToRemoveId);
  const totalSavedSearchesCount: number = yield select(selectTotalCountOfSavedSearches);
  const currentSaveSearches: SavedSearch[] = yield select(selectContactSavedSearches);

  const shouldLoadNextContactSavedSearchAfterDelete = currentSaveSearches.length < totalSavedSearchesCount;

  const savedSearch = yield call(removeContactSavedSearch, client, savedSearchToRemoveId);
  yield put(removedContactSavedSearch(savedSearch.id));

  if (shouldLoadNextContactSavedSearchAfterDelete) yield put(loadNextContactSavedSearchAfterDeleteRequested());
}

export function* removeContactSavedSearchRequestWatcher() {
  yield takeEvery(removeContactSavedSearchRequested.type, safe(removeContactSavedSearchFlow));
}

function* loadNextContactSavedSearchAfterDeleteFlow() {
  const searchText: string = yield select(selectSearchText);
  const filterItem: FilterItem = yield select(selectFilterItem);
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);

  const skip = pageNumber * pageSize - 1;
  const pageOfSavedSearches: PageOfSavedSearches = yield call(fetchPagedContactSavedSearches, client, skip, 1, searchText, [filterItem]);

  const savedSearches = pageOfSavedSearches.savedSearches;
  const contactsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const contactsAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchBulkContactAvatarDetails, client, contactsAvatarDetailsInputs);

  contactsAvatarDetails.forEach((contactAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === contactAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = contactAvatarDetails.avatarDetails;
    savedSearch.resultsLength = contactAvatarDetails.totalCount;
  });

  yield put(contactsSavedSearchesReceived(savedSearches));
}

export function* loadNextContactSavedSearchAfterDeleteRequestWatcher() {
  yield takeEvery(loadNextContactSavedSearchAfterDeleteRequested.type, safe(loadNextContactSavedSearchAfterDeleteFlow));
}
