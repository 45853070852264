import { useMemo, useState } from 'react';
import {
  CommandBarButton,
  DetailsListLayoutMode,
  DetailsRow,
  Icon,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import { Button, Checkbox } from '@fluentui/react-northstar';
import { EmptyMessage, useAppDispatch, useAppSelector } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { removeRecipientFromListInStore, selectContactsFromListToAdd, updateRecipientFromList } from 'app/pages/my-activities/sending-wizard';
import { RecipientFromList } from 'app/pages/my-activities/sendings';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { FormattedMessage, useIntl } from 'app/i18n';
import { WarningMessage } from 'app/common/components/warning-message/WarningMessage';

export const AddContactFromListTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const recipientsToAdd = useAppSelector<RecipientFromList[]>(selectContactsFromListToAdd);
  const [editingRecipientIndex, setRecipientIndexToEdit] = useState<number>();

  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  const header = [
    { key: 'add-contact-key-name', name: formatMessage({ id: 'contact-data-labels.name' }), fieldName: 'name', isResizable: true, minWidth: 80, maxWidth: 100 },
    {
      key: 'add-contact-key-title',
      name: formatMessage({ id: 'contact-data-labels.job-title' }),
      fieldName: 'jobTitle',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-email',
      name: formatMessage({ id: 'contact-data-labels.email-address' }),
      fieldName: 'email',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-contact-data',
      name: formatMessage({ id: 'labels.contact-data' }),
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    { key: 'add-contact-key-hover-action', name: '', fieldName: 'hoverActions', isResizable: false, minWidth: 60, maxWidth: 60 }
  ];

  const handleClickOnEditButton = (index: number) => {
    setRecipientIndexToEdit(index);
  };

  const handleClickOnRemoveButton = (recipient: RecipientFromList) => {
    dispatch(removeRecipientFromListInStore(recipient));
  };

  const onNewContactDataSelected = (recipient: RecipientFromList, recipientIndex: number, selectedMediumName: string) => {
    const collaboration: Collaboration = recipient.contact.collaborations.find((c) => c.medium.name === selectedMediumName);
    const emailAddress: string = collaboration ? collaboration.emailAddress : recipient.contact.ownContactData.emailAddress;

    const collaborationId: string = collaboration === undefined ? '' : collaboration.id;

    dispatch(
      updateRecipientFromList({
        recipientIndex: recipientIndex,
        collaborationId: collaborationId,
        emailAddress: emailAddress
      })
    );

    setRecipientIndexToEdit(-1);
  };

  const rows = [
    ...recipientsToAdd.map((recipient, index) => {
      const selectedContactData = recipient.collaborationId
        ? recipient.contact.collaborations.find((c) => c.id === recipient.collaborationId)
        : recipient.contact.ownContactData;

      const contactDataDropdown = [
        ...recipient.contact.collaborations.map((collaboration) => ({
          key: collaboration.id,
          text: collaboration.medium.name,
          onClick: () => onNewContactDataSelected(recipient, index, collaboration.medium.name)
        })),
        {
          key: recipient.contact.id,
          text: formatMessage({ id: 'labels.own-contact-data' }),
          onClick: () => onNewContactDataSelected(recipient, index, '')
        }
      ];

      const primaryCollaboration = recipient.contact.collaborations.find((c) => c.id === recipient.collaborationId);

      return {
        key: `${index}`,
        name: `${recipient.contact.firstName} ${recipient.contact.lastName}`,
        jobTitle: (selectedContactData as Collaboration)?.jobTitle?.name ?? '',
        email: recipient.emailAddress || <WarningMessage warningText={formatMessage({ id: 'add-contacts-wizard.contact-has-no-email-address' })} />,
        contactData:
          editingRecipientIndex === index ? (
            <div key={`contact-data-dropdown-${index}`}>
              <CommandBarButton text={formatMessage({ id: 'dropdown-placeholders.collaboration' })} menuProps={{ items: contactDataDropdown }} />
            </div>
          ) : recipient.collaborationId ? (
            primaryCollaboration?.medium?.name
          ) : (
            <FormattedMessage id={'labels.own-contact-data'} />
          ),
        hoverActions: (
          <>
            {hoverIndex === index && (
              <div className="table-actions">
                <Button text icon={<Icon iconName="Edit" />} onClick={() => handleClickOnEditButton(index)} />
                <Button text icon={<Icon iconName="Clear" />} onClick={() => handleClickOnRemoveButton(recipient)} />
              </div>
            )}
          </>
        ),
        contact: recipient.contact
      };
    })
  ];

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        selectionMode: SelectionMode.multiple
      }),
    []
  );

  return (
    <div className="table-wrap table-wrap--wizard">
      {recipientsToAdd.length > 0 ? (
        <div className="table-wrap table--list">
          <MarqueeSelection selection={selection} className="table" isDraggingConstrainedToRoot={true}>
            <ShimmeredDetailsList
              items={rows}
              enableShimmer={!rows}
              columns={header}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={formatMessage({ id: 'aria-labels.toggle-selection' })}
              ariaLabelForSelectAllCheckbox={formatMessage({ id: 'aria-labels.toggle-selection-for-all-items' })}
              checkButtonAriaLabel={formatMessage({ id: 'aria-labels.select-row' })}
              checkboxVisibility={1}
              onRenderRow={(rowProps: IDetailsRowProps) => (
                <div
                  onMouseEnter={() => {
                    setHoverIndex(rowProps.itemIndex);
                  }}
                >
                  <DetailsRow
                    key={rowProps.item.id}
                    {...rowProps}
                    onRenderCheck={() => (
                      <div className="row-header-wrap">
                        {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
                          <Checkbox checked={selection.getSelection().includes(rowProps.item)} />
                        ) : (
                          <ProfilePicture name={rowProps.item.name} imageUrl={rowProps.item.profilePictureUrl} size="small" />
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            />
          </MarqueeSelection>
        </div>
      ) : (
        <EmptyMessage
          srcValue="/wizard-empty-info.svg"
          textValueOptional={formatMessage({ id: 'no-contacts-selected-message.title' })}
          textValue={formatMessage({ id: 'no-contacts-selected-message.description' })}
        />
      )}
    </div>
  );
};
