import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { isTextInputValid, maximumContactLastNameLength, regAtLeastOneCharacter, useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { ControlledInput } from 'app/pages/my-audience/common/components/controlled/ControlledInput';
import { Contact } from 'app/pages/my-audience/contacts';
import {
  closeCreateContactWizard,
  CreateContactSteps,
  CreateContactWizardFooter,
  createNewContact,
  goToNextStepCreateWizard,
  goToPreviousStepCreateWizard,
  selectContactToAdd,
  setContactToAdd
} from 'app/pages/my-audience/media-outlet-profile';
import { FormattedMessage, useIntl } from 'app/i18n';
import { useCallback, useEffect } from 'react';

export type CreateContactForm = {
  salutation: string;
  firstName: string;
  lastName: string;
};

export const CreateNewContact = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactToAdd = useAppSelector<Contact>(selectContactToAdd);

  const { handleSubmit, control, watch } = useForm<CreateContactForm, any>({
    defaultValues: {
      salutation: contactToAdd.salutation,
      firstName: contactToAdd.firstName,
      lastName: contactToAdd.lastName
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleNextButtonClick = () => dispatch(goToNextStepCreateWizard());

  const handleBackButtonClick = () => dispatch(goToPreviousStepCreateWizard());

  const handleCancelButtonClick = () => dispatch(closeCreateContactWizard());

  const areFieldsValid = isTextInputValid({ inputValue: watch('lastName'), maxInputLength: maximumContactLastNameLength });

  const submitData = useCallback(() => {
    handleSubmit(
      (data) => {
        const contact = createNewContact(data);

        dispatch(setContactToAdd(contact));
      },
      (error) => {
        Sentry.setExtra('Path', CreateNewContact.name);
        Sentry.captureException(error);
      }
    )();
  }, [dispatch, handleSubmit]);

  useEffect(() => {
    return () => {
      submitData();
    };
  }, [submitData]);

  return (
    <>
      <CreateContactSteps areFieldsValid={areFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'mo-create-contact.wizard-header-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'mo-create-contact.wizard-salutation-label' })} control={control} name="salutation" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'mo-create-contact.wizard-name-label' })} control={control} name="firstName" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'mo-create-contact.wizard-lastname-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="lastName"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: formatMessage({ id: 'mo-create-contact.wizard-lastname-label' }) })
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumContactLastNameLength }),
                  value: maximumContactLastNameLength
                },
                required: formatMessage(
                  { id: 'error-messages.required-filed' },
                  { fieldName: formatMessage({ id: 'mo-create-contact.wizard-lastname-label' }) }
                )
              }}
            />
          </div>
        </div>
        <CreateContactWizardFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areFieldsValid}
        />
      </div>
    </>
  );
};
