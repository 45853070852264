import { Input, InputLabelProps, ShorthandValue } from '@fluentui/react-northstar';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface ControlledInputProps {
  name: string;
  className?: string;
  control: Control<any>;
  label?: ShorthandValue<InputLabelProps>;
  rules?: RegisterOptions;
}

export const ControlledInput = ({ name, control, label = '', rules, className = '' }: ControlledInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <>
          <Input
            className={className}
            label={label}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e);
            }}
            value={value}
            onBlur={onBlur}
            name={fieldName}
            error={!value && !!error}
          />
          <span className="error-message">{error && error.message}</span>
        </>
      )}
    />
  );
};
