import { useEffect, useRef, useState } from 'react';
import { DefaultButton, Panel, PanelType } from '@fluentui/react';
import { Accordion, Button, ChevronDownMediumIcon, CloseIcon, Dropdown, DropdownProps, Pill, RadioGroup } from '@fluentui/react-northstar';
import { DateTime } from 'luxon';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import { DatePicker, FilterItem, printDateLocalized, selectIsOnMobile, useAppDispatch, useAppSelector, useOnClickOutside } from 'app/common';
import {
  Template,
  TemplatesFilterByType,
  addSingleFilter,
  addTemplatesFilter,
  clearAllFilters,
  closeFiltersPanel,
  removeAllFiltersWithFieldName,
  removeTemplatesFilter,
  selectFilterItems,
  selectIsFiltersPanelOpened,
  selectSearchSuggestions,
  selectSearchText,
  templateSuggestionsRequested,
  templatesRequested
} from 'app/pages/my-activities/templates';
import 'app/pages/my-audience/common/components/Filter/filter-panel.scss';
import 'app/pages/my-activities/sendings/components/TableFilters.scss';

const FILTER = {
  TITLE: 'title',
  CREATED_AT: 'createdAt',
  IS_PREBUILT: 'isPrebuilt'
};
const filterValues = Object.values(FILTER);

const determineActiveFilterIndexes = (filterItems: FilterItem[] | undefined): number[] => {
  if (!filterItems) return [];

  const initialIndexes = [];
  filterItems.forEach((item) => {
    if (filterValues.includes(item.fieldName)) {
      initialIndexes.push(filterValues.indexOf(item.fieldName));
    }
  });
  return initialIndexes;
};

const computeCheckboxValue = (filterItems: FilterItem[] | undefined): number | undefined => {
  if (!filterItems) return undefined;
  if (!filterItems.some((item) => item.fieldName === FILTER.IS_PREBUILT)) return 2;

  for (const item of filterItems) {
    if (item.fieldName === FILTER.IS_PREBUILT) {
      return item.value === 'true' ? 1 : 0;
    }
  }
};

export const FiltersPanel = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const locale = useAppSelector<string>(selectLocale);
  const isOnMobile = useAppSelector(selectIsOnMobile);
  const isOpened = useAppSelector<boolean>(selectIsFiltersPanelOpened);
  const templatesSuggestions = useAppSelector<Template[]>(selectSearchSuggestions);
  const filterItems = useAppSelector<FilterItem[]>(selectFilterItems);
  const searchText = useAppSelector<string>(selectSearchText);
  const [datePickerValue, setDatePickerValue] = useState<Date | undefined>();
  const [activeIndexes, setActiveIndexes] = useState(determineActiveFilterIndexes(filterItems));
  const ref = useRef(null);
  const panelHeaders = [formatMessage({ id: 'filters.title' }), formatMessage({ id: 'filters.creation-date' }), formatMessage({ id: 'filters.type' })];

  useEffect(() => {
    const updatedIndexes = determineActiveFilterIndexes(filterItems);
    if (!updatedIndexes.includes(filterValues.indexOf(FILTER.CREATED_AT))) {
      setDatePickerValue(undefined);
    }
    setActiveIndexes(updatedIndexes);
  }, [isOpened, filterItems]);

  const dismissPanel = (): void => {
    dispatch(closeFiltersPanel());
  };

  useOnClickOutside(ref, dismissPanel);

  const onDropDownItemSelectionChange = {
    onAdd: (title: string): string => {
      onFilter(title, FILTER.TITLE);
      return `${title} selected.`;
    },
    onRemove: (title: string): string => {
      onUndoFilter(title, FILTER.TITLE);
      return `${title} has been removed.`;
    }
  };

  const onTitleInputChange = (searchText: string): void => {
    dispatch(templateSuggestionsRequested(searchText));
  };

  const onFilter = (value: string, fieldName: string): void => {
    dispatch(addTemplatesFilter(new FilterItem(fieldName, value)));
    dispatch(templatesRequested(searchText));
  };

  const onUndoFilter = (value: string, fieldName: string): void => {
    dispatch(removeTemplatesFilter(new FilterItem(fieldName, value)));
    dispatch(templatesRequested(searchText));
  };

  const onFilterByDate = (value: string, fieldName: string): void => {
    dispatch(addSingleFilter(new FilterItem(fieldName, value.toString())));
    dispatch(templatesRequested(searchText));
  };

  const onFilterByType = (value: TemplatesFilterByType, fieldName: string): void => {
    if (value === TemplatesFilterByType.ShowAll) {
      dispatch(removeAllFiltersWithFieldName(fieldName));
      dispatch(templatesRequested(searchText));
      return;
    }
    dispatch(addSingleFilter(new FilterItem(fieldName, value === TemplatesFilterByType.IsPrebuilt ? 'true' : 'false')));
    dispatch(templatesRequested(searchText));
  };

  const onClearDateInput = (): void => {
    setDatePickerValue(undefined);
    dispatch(removeAllFiltersWithFieldName(FILTER.CREATED_AT));
    dispatch(templatesRequested(searchText));
  };

  const handleClearAll = () => {
    dispatch(clearAllFilters());
    dispatch(templatesRequested(''));
    setActiveIndexes([]);
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);
  const panels = [
    {
      key: 'template-title',
      title: (
        <div className="accordion-head-box" key="sending-overview-title">
          <span className="accordion-heading">{panelHeaders[0]}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'sending-overview-content',
        content: (
          <>
            <div className="filter-panel-dropdown-wrap">
              <Dropdown
                clearable={true}
                search
                className="filter-panel-dropdown ll"
                onInput={(e) => onTitleInputChange(e.target.value)}
                items={templatesSuggestions.map((template) => template.title)}
                placeholder={formatMessage({ id: 'filters.start-typing' })}
                getA11ySelectionMessage={onDropDownItemSelectionChange}
                noResultsMessage={formatMessage({ id: 'filters.no-results' })}
                onBlur={handleBlur}
                open={isOpen}
                onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
              />
              <div className="filter-panel-pills">
                {filterItems
                  ?.filter((f) => f.fieldName === FILTER.TITLE)
                  .map((item, index) => (
                    <Pill key={index} className="filter-panel-pill">
                      <span className="filter-panel-pill__name"> {item.value}</span>
                      <Button icon={<CloseIcon />} iconOnly text onClick={() => onUndoFilter(item.value, FILTER.TITLE)} />
                    </Pill>
                  ))}
              </div>
            </div>
          </>
        )
      }
    },
    {
      key: 'template-creation-date',
      title: (
        <div className="accordion-head-box" key="about-creation-date">
          <span className="accordion-heading">{panelHeaders[1]}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'template-creation-date-content',
        content: (
          <>
            <div className="profil-panel-about-input-wrap">
              <DatePicker
                value={datePickerValue}
                onSelectDate={(selectedDate) => {
                  onFilterByDate(selectedDate.toUTCString(), FILTER.CREATED_AT);
                  setDatePickerValue(selectedDate);
                }}
              />
              <DefaultButton
                aria-label={formatMessage({ id: 'filters.clear-date' })}
                onClick={onClearDateInput}
                text={formatMessage({ id: 'filters.clear' })}
              />
            </div>
          </>
        )
      }
    },
    {
      key: 'template-type',
      title: (
        <div className="accordion-head-box" key="about-title">
          <span className="accordion-heading">{panelHeaders[2]}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'template-type-content',
        content: (
          <RadioGroup
            onCheckedValueChange={(e, d) => onFilterByType(d.value as TemplatesFilterByType, FILTER.IS_PREBUILT)}
            items={[
              {
                key: 'radiogroup-show-all',
                label: formatMessage({ id: 'filters.show-all' }),
                value: TemplatesFilterByType.ShowAll
              },
              {
                key: 'radiogroup-show-is-prebuilt',
                label: formatMessage({ id: 'filters.is-prebuilt' }),
                value: TemplatesFilterByType.IsPrebuilt
              },
              {
                key: 'radiogroup-is-saved',
                label: formatMessage({ id: 'filters.is-saved' }),
                value: TemplatesFilterByType.IsSaved
              }
            ]}
            defaultCheckedValue={computeCheckboxValue(filterItems)}
          />
        )
      }
    }
  ];

  return (
    <div ref={ref}>
      <Panel
        className="filter-panel"
        headerText={formatMessage({ id: 'filters.filters' })}
        isOpen={isOpened}
        isBlocking={false}
        onDismiss={dismissPanel}
        type={PanelType.custom}
        customWidth="340px"
      >
        <div className="filter-panel-accordions">
          <Accordion panels={panels} defaultActiveIndex={activeIndexes} />
        </div>
        <div className="filter-panel-actions-wrap">
          <form>
            {filterItems.length > 0 && (
              <div className="filter-panel-actions-holder">
                <div className="filter-panel-actions">
                  <Button className="filter-panel-actions-clear" text content={formatMessage({ id: 'buttons.clear' })} onClick={handleClearAll} />
                </div>
                {isOnMobile && (
                  <div className="filter-panel-actions">
                    <Button className="filter-panel-actions-apply" primary content={formatMessage({ id: 'buttons.apply' })} onClick={dismissPanel} />
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </Panel>
    </div>
  );
};
