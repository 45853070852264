import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfListContactsRequested,
  resetListContactsTable,
  selectIsLoadingListContacts,
  selectListContactsPageSize,
  selectListContactsTotalCount,
  setListContactsPageNumber,
  setListContactsPageSize
} from 'app/pages/my-audience/lists-profile';

export const ContactsTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOContacts = useAppSelector<number>(selectListContactsTotalCount);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingListContacts);

  const pageSize = useAppSelector<number>(selectListContactsPageSize);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setListContactsPageNumber(pageNumber));
    dispatch(pageOfListContactsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setListContactsPageSize(pageSize));
    dispatch(resetListContactsTable());
    dispatch(pageOfListContactsRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOContacts}
      isLoadingData={isLoadingContacts}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
