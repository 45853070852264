import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button, Form, FormInput, FormTextArea } from '@fluentui/react-northstar';
import { DatePicker, selectSignedInUser, useAppDispatch, useAppSelector, User } from 'app/common';
import { createActivity } from 'app/common/factories/activity.factory';
import { useIntl } from 'app/i18n';
import { addActivityRequested, selectActivityTypeToLog, selectLocalizedActivityTypeToLog, selectSelectedContact } from 'app/pages/my-audience/contact-profile';
import { ActivityParticipant, ActivityTypes, Contact } from 'app/pages/my-audience/contacts';
import { DateTime } from 'luxon';
import { useState } from 'react';

const dialogModalProps = {
  isBlocking: true,
  styles: { main: { maxWidth: 450 } }
};

export interface ContactActivity {
  inputDate: Date;
  title: string;
  subject: string;
  comment: string;
  id?: string;
}

export const LogActivityDialog = ({ opened, dismiss }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const activityType = useAppSelector<ActivityTypes>(selectActivityTypeToLog);
  const localizedActivityType = useAppSelector<string>(selectLocalizedActivityTypeToLog);
  const signedInUser = useAppSelector<User>(selectSignedInUser);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);

  const [activity, setActivity] = useState<ContactActivity>({
    inputDate: DateTime.utc().toJSDate(),
    title: '',
    subject: '',
    comment: ''
  });

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'contacts-add-activity.dialog.header' }, { activityType: localizedActivityType })
  };

  const addNewActivity = () => {
    const collaborationId = selectedContact.collaborations.find((c) => c.isPrimary)?.id ?? '';
    const activityParticipants = [new ActivityParticipant('', selectedContact, collaborationId)];

    const activityToAdd = createActivity({ activity, activityParticipants, activityType, signedInUser });

    dispatch(addActivityRequested(activityToAdd));
    dismiss();
  };

  const updateNewActivity = (e) => {
    setActivity((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleDateChanged = (date: Date) => {
    setActivity((prev) => ({
      ...prev,
      inputDate: date
    }));
  };

  return (
    <>
      <Dialog hidden={!opened} onDismiss={dismiss} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
        <Form onSubmit={addNewActivity} className="activities-log-form">
          <FormInput
            label={formatMessage({ id: 'contacts-add-activity.dialog.title-label' })}
            name="title"
            id="title"
            showSuccessIndicator={false}
            onChange={updateNewActivity}
          />
          {activityType === ActivityTypes.Email || activityType === ActivityTypes.Appointment || activityType === ActivityTypes.Task ? (
            <FormInput
              label={formatMessage({ id: 'contacts-add-activity.dialog.subject-label' })}
              name="subject"
              id="subject"
              required
              showSuccessIndicator={false}
              onChange={updateNewActivity}
            />
          ) : (
            <></>
          )}
          <DatePicker onSelectDate={handleDateChanged} value={activity.inputDate} />
          <FormTextArea name="comment" id="comment" label={formatMessage({ id: 'contacts-add-activity.dialog.comment-label' })} onChange={updateNewActivity} />
          <DialogFooter>
            <Button primary type="submit" content={formatMessage({ id: 'buttons.save' })} />
            <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={dismiss} />
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};
