import { Avatar, Button, CompanionIcon, EmailIcon, PhoneArrowIcon, Tooltip } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { MediaOutlet } from 'app/pages/my-audience/media-outlets';
import { selectSelectedMediaOutlet } from 'app/pages/my-audience/media-outlet-profile';
import { useIntl } from 'app/i18n';

export const MediaOutletQuickActions = () => {
  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);
  const { formatMessage } = useIntl();

  return (
    <div className="quick-actions-wrap">
      <Tooltip
        subtle={false}
        pointing
        trigger={
          selectedMediaOutlet.contactData.landlinePhoneNumber ? (
            <a href={`tel:+${selectedMediaOutlet.contactData.landlinePhoneNumber}`}>
              <Avatar className="quick-action" icon={<PhoneArrowIcon />} />
            </a>
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<PhoneArrowIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'mo-quick-actions.phone-call' })}
      />
      <Tooltip
        subtle={false}
        pointing
        trigger={
          selectedMediaOutlet.contactData.emailAddress ? (
            <a href={`mailto:${selectedMediaOutlet.contactData.emailAddress}`} target="_blank" rel="noreferrer">
              <Avatar className="quick-action" icon={<EmailIcon />} />
            </a>
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<EmailIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'mo-quick-actions.send-email' })}
      />
      <Tooltip
        subtle={false}
        pointing
        trigger={
          selectedMediaOutlet.contactData.websiteUrl ? (
            <a href={`${selectedMediaOutlet.contactData.websiteUrl}`} rel="noreferrer" target="_blank">
              <Avatar className="quick-action" icon={<CompanionIcon />} />
            </a>
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<CompanionIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'mo-quick-actions.website' })}
      />
    </div>
  );
};
