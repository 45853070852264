import axios from 'axios';
import { ACCESS_TOKEN_CACHE } from 'auth';

export const httpClient = axios.create();

httpClient.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_CACHE);
  if (accessToken) {
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});
