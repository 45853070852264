import { MediumCategory, ensureHttpProtocol } from 'app/common';
import {
  AddCompanyProfileInformationForm,
  AddMediaProfileInformationForm,
  CompanyAddressInfo,
  CompanyGeneralInfo,
  CompanyProfileInfo,
  CreateCompanyAddressInfoForm,
  CreateCompanyGeneralInfoForm,
  CreateCompanyProfileInformationForm,
  CreateMediaAddressInfoForm,
  CreateMediaOutletGeneralInfoForm,
  CreateMediaOutletProfileInformationForm,
  MediaOutletAddressInfo,
  MediaOutletCollaborationProfileInfo,
  MediaOutletGeneralInfo
} from 'app/pages/my-audience/contact-profile';
import { Activity, ActivityCardModel, JobTitle, ActivityTypes } from 'app/pages/my-audience/contacts';
import { GeographicalCoverage, LocalizedListValue, MediaOutlet, MediumContactData } from 'app/pages/my-audience/media-outlets';

export const createNewMediaOutlet = (data: AddMediaProfileInformationForm, jobTitles: JobTitle[]) => {
  const selectedJobTitle = jobTitles.find((jt) => jt.name === data.jobTitle);

  return new MediaOutletCollaborationProfileInfo(
    selectedJobTitle,
    data.jobDescription,
    data.resort,
    data.phoneNumber,
    data.mobilePhoneNumber,
    data.emailAddress,
    data.address,
    data.postalCode,
    data.city,
    data.country
  );
};

export const setMediaOutletGeneralInfo = (
  data: CreateMediaOutletGeneralInfoForm,
  audiences: LocalizedListValue[],
  frequencies: LocalizedListValue[],
  supportTypes: LocalizedListValue[]
) => {
  const audience = audiences.find((a) => a.label === data.audienceValue);
  const frequency = frequencies.find((f) => f.label === data.frequencyValue);
  const selectedSupportTypes = data.mediaSupportTypesValues && supportTypes.filter((st) => data.mediaSupportTypesValues.includes(st.label));

  return new MediaOutletGeneralInfo(
    data.name,
    data.description,
    audience?.key ?? '',
    ensureHttpProtocol(data.websiteUrl),
    frequency?.key ?? '',
    data.comment,
    selectedSupportTypes.map((st) => st.key),
    data.preferredLanguages
  );
};

export const setMediaOutletAddressInfo = (data: CreateMediaAddressInfoForm) => {
  return new MediaOutletAddressInfo(
    data.address,
    data.poBox,
    data.postalCode,
    data.city,
    data.country,
    ensureHttpProtocol(data.websiteUrl),
    data.emailAddress,
    data.phoneNumber,
    data.fax
  );
};

export const setCreateMediaOutletProfileInfo = (data: CreateMediaOutletProfileInformationForm, jobTitles: JobTitle[]) => {
  const selectedJobTitle = jobTitles.find((jt) => jt.name === data.jobTitle);

  return new MediaOutletCollaborationProfileInfo(
    selectedJobTitle,
    data.jobDescription,
    data.resort,
    data.phoneNumber,
    data.mobilePhoneNumber,
    data.emailAddress,
    data.address,
    data.postalCode,
    data.city,
    data.country
  );
};

export const createNewCompanyProfileInfo = (data: AddCompanyProfileInformationForm) => {
  return new CompanyProfileInfo(
    data.jobTitle,
    data.jobDescription,
    data.resort,
    data.phoneNumber,
    data.mobilePhoneNumber,
    data.emailAddress,
    data.address,
    data.postalCode,
    data.city,
    data.country
  );
};

export const setCompanyAddressInfo = (data: CreateCompanyAddressInfoForm) => {
  return new CompanyAddressInfo(
    data.address,
    data.poBox,
    data.postalCode,
    data.city,
    data.country,
    ensureHttpProtocol(data.websiteUrl),
    data.emailAddress,
    data.phoneNumber,
    data.fax
  );
};

export const setCreateGeneralInfo = (data: CreateCompanyGeneralInfoForm) => {
  return new CompanyGeneralInfo(data.name, data.categoryName, data.parentCompany, data.comment);
};

export const setCreateCompanyProfileInfo = (data: CreateCompanyProfileInformationForm) => {
  return new CompanyProfileInfo(
    data.jobTitle,
    data.jobDescription,
    data.resort,
    data.phoneNumber,
    data.mobilePhoneNumber,
    data.emailAddress,
    data.address,
    data.postalCode,
    data.city,
    data.country
  );
};

export const createMediaOutlet = (generalInfo: MediaOutletGeneralInfo, addressInfo: MediaOutletAddressInfo): MediaOutlet => {
  // TODO Check if we are adding all fields
  const contactData = new MediumContactData(
    addressInfo.address,
    addressInfo.poBox,
    addressInfo.postalCode,
    addressInfo.country,
    addressInfo.city,
    ensureHttpProtocol(addressInfo.websiteUrl),
    addressInfo.emailAddress,
    addressInfo.phoneNumber,
    addressInfo.fax,
    ''
  );

  return new MediaOutlet(
    '',
    generalInfo.name,
    '',
    generalInfo.description,
    generalInfo.audienceKey,
    {} as MediumCategory,
    generalInfo.frequencyKey,
    {} as GeographicalCoverage,
    generalInfo.mediaSupportTypesKeys,
    [],
    [],
    contactData,
    [],
    [],
    [],
    [],
    '',
    '',
    '',
    '',
    0,
    0
  );
};

export const createActivityCardsData = (activities: Activity[]): ActivityCardModel[] => {
  return activities.map((activity) => {
    let iconName = '';
    let activityType = '';

    switch (activity.type) {
      case ActivityTypes.Event: {
        iconName = 'Event';
        activityType = 'Event';
        break;
      }
      case ActivityTypes.Task: {
        iconName = 'TaskManager';
        activityType = 'Task';
        break;
      }
      case ActivityTypes.Email: {
        iconName = 'Mail';
        activityType = 'Email';
        break;
      }
      case ActivityTypes.Sending: {
        iconName = 'Mail';
        activityType = 'Sending';
        break;
      }
      case ActivityTypes.Call: {
        iconName = 'Phone';
        activityType = 'Call';
        break;
      }
      case ActivityTypes.Webinar: {
        iconName = 'DateTime';
        activityType = 'Webinar';
        break;
      }
      case ActivityTypes.Chat: {
        iconName = 'Chat';
        activityType = 'Chat';
        break;
      }
      case ActivityTypes.Appointment: {
        iconName = 'Meeting';
        activityType = 'Appointment';
        break;
      }
    }

    return new ActivityCardModel(
      activity.id,
      activityType,
      iconName,
      activity.displayDate,
      activity.displayTime,
      activity.title,
      `${activity.createdBy.firstName} ${activity.createdBy.lastName}`
    );
  });
};
