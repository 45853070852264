import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { CreateContactWizardStep, goToCreateContactStep, selectCreateContactCurrentStep } from 'app/pages/my-audience/wizard-create-media-outlet';

export interface CreateContactStepsProps {
  areGeneralInfoValid?: boolean;
}

export const CreateContactSteps: React.FC<CreateContactStepsProps> = ({ areGeneralInfoValid }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const step = useAppSelector<CreateContactWizardStep>(selectCreateContactCurrentStep);

  const handleStepButtonClick = (step: CreateContactWizardStep) => {
    dispatch(goToCreateContactStep(step));
  };

  const styleBreadcrumbStatus = (stepToCompareWith: CreateContactWizardStep): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactWizardStep.GeneralInformation)}
            content={formatMessage({ id: 'add-menu-create-mo.contact-step-info' })}
            title="Information"
            onClick={() => handleStepButtonClick(CreateContactWizardStep.GeneralInformation)}
            disabled={!areGeneralInfoValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactWizardStep.Finish)}
            content={formatMessage({ id: 'add-menu-create-mo.contact-step-finish' })}
            title="Finish"
            onClick={() => handleStepButtonClick(CreateContactWizardStep.Finish)}
            disabled={!areGeneralInfoValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
