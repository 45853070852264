import { NumberWrap, useAppDispatch, useAppSelector, useContactLimitationWarning, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import { AadContact } from 'app/pages/my-audience/contacts';
import {
  addAadContactsToListRequested,
  AddContactFromCompanyFooter,
  AddContactFromCompanySearch,
  AddContactFromCompanyTable,
  selectAADContactsToAddToList,
  selectListContactsTotalCount
} from 'app/pages/my-audience/lists-profile';

export const AddContactFromCompany = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { checkForContactNumberLimit } = useContactLimitationWarning();

  const aadContactsToAdd: AadContact[] = useAppSelector(selectAADContactsToAddToList);
  const numberOfContactsInList = useAppSelector(selectListContactsTotalCount);

  const totalNumberOfContacts = numberOfContactsInList + aadContactsToAdd.length;

  const handleAddButtonClick = () => {
    checkForContactNumberLimit({ totalNumberOfContacts, successCallback: addContactsToList });
  };

  const addContactsToList = () => {
    dispatch(addAadContactsToListRequested());
  };

  return (
    <div className="wizard-content">
      <WizardIntro
        title={formatMessage({ id: 'list-wizards.add-contact-from-company-title' })}
        description={formatMessage({ id: 'list-wizards.add-contact-from-company-subtitle' })}
      />
      <div className="wizard-content-main">
        <NumberWrap numberValue="1">
          <AddContactFromCompanySearch />
        </NumberWrap>
        <NumberWrap numberValue="2">
          <AddContactFromCompanyTable />
        </NumberWrap>
      </div>
      <AddContactFromCompanyFooter onAddButtonClick={handleAddButtonClick} />
    </div>
  );
};
