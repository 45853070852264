import { Avatar, Button, CompanionIcon, EmailIcon, PhoneArrowIcon, Tooltip } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { Company } from 'app/pages/my-audience/companies';
import { selectSelectedCompany } from 'app/pages/my-audience/company-profile';
import { useIntl } from 'app/i18n';

export const CompanyPanelQuickActions = () => {
  const selectedCompany = useAppSelector<Company>(selectSelectedCompany);
  const { formatMessage } = useIntl();
  return (
    <div className="quick-actions-wrap">
      <Tooltip
        subtle={false}
        pointing
        trigger={
          selectedCompany?.contactData?.landlinePhoneNumber ? (
            <a href={`tel:+${selectedCompany?.contactData?.landlinePhoneNumber}`} target="_blank" rel="noreferrer">
              <Avatar className="quick-action" icon={<PhoneArrowIcon />} />
            </a>
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<PhoneArrowIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'company-quick-actions.phone-call' })}
      />
      <Tooltip
        subtle={false}
        pointing
        trigger={
          selectedCompany?.contactData?.emailAddress ? (
            <a href={`mailto:${selectedCompany?.contactData?.emailAddress}`} target="_blank" rel="noreferrer">
              <Avatar className="quick-action" icon={<EmailIcon />} />
            </a>
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<EmailIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'company-quick-actions.send-email' })}
      />
      <Tooltip
        subtle={false}
        pointing
        trigger={
          selectedCompany?.contactData?.websiteUrl ? (
            <a href={`${selectedCompany?.contactData?.websiteUrl}`} rel="noreferrer" target="_blank">
              <Avatar className="quick-action" icon={<CompanionIcon />} />
            </a>
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<CompanionIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'company-quick-actions.website' })}
      />
    </div>
  );
};
