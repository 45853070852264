import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import {
  BottomPopupItems,
  openBottomPopup,
  SearchResultTableData,
  selectIsOnDesktop,
  selectIsOnMobile,
  selectIsOnTouch,
  selectSelectedTableRows,
  useAppDispatch,
  useAppSelector,
  CreateSendingDialog,
  DisableUnauthorized,
  useDeepLinkNavigation,
  FilterItem
} from 'app/common';
import { ContactsSearch, openSavedSearchesPanel } from 'app/pages/my-audience/contacts';
import {
  addContactSavedSearchRequested,
  firstPageOfSavedSearchesRequested,
  selectIsSaveSearchButtonDisabled
} from 'app/pages/my-audience/contacts-saved-searches';
import { ContactsSearchResultsIntroText } from 'app/pages/my-audience/contacts-search-results';
import { createNewBlankEmailRequested, createNewEmailWithRecipientsRequested } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';
import { Permission } from 'auth';

export const ContactsSearchResultsIntro = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { formatMessage } = useIntl();
  useDeepLinkNavigation();

  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isSaveSearchButtonDisabled = useAppSelector<boolean>(selectIsSaveSearchButtonDisabled);

  const [isCreateSendingDialogVisible, setIsCreateSendingDialogVisible] = useState<boolean>(false);
  const [isCreateEmailingButtonDisabled, setIsCreateEmailingButtonDisabled] = useState<boolean>(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);

  const handleCreateNewSavedSearch = () => {
    dispatch(addContactSavedSearchRequested());
    dispatch(openSavedSearchesPanel());
    navigate(`/my-audience/contacts/results/${params.contactsQuery}/saved-searches`);
  };

  const onOpenSavedSearchesPanel = () => {
    dispatch(firstPageOfSavedSearchesRequested({ searchText: '', filterItem: {} as FilterItem }));
    dispatch(openSavedSearchesPanel());
    navigate(`/my-audience/contacts/results/${params.contactsQuery}/saved-searches`);
  };

  const bottomPopupItems: BottomPopupItems[] = [
    {
      key: 'save-search',
      title: formatMessage({ id: 'search-results-header.save-search-label' }),
      className: 'bottom-popup-items',
      onClick: handleCreateNewSavedSearch,
      icon: <Icon iconName="SingleBookmark" />
    },
    {
      key: 'saved-searches',
      title: formatMessage({ id: 'search-results-header.view-saved-searches-label' }),
      className: 'bottom-popup-items',
      onClick: onOpenSavedSearchesPanel,
      icon: <Icon iconName="SearchBookmark" />
    }
  ];

  const handleClickMoreOptions = () => dispatch(openBottomPopup(bottomPopupItems));

  const onCreateSending = () => {
    setIsCreateEmailingButtonDisabled(true);

    if (selectedTableRows.length === 0) {
      dispatch(createNewBlankEmailRequested(formatMessage({ id: 'emailing-wizard.message-with-no-title-text' })));
    } else {
      const contactsIdsToAdd = selectedTableRows.map((row) => row.id);

      dispatch(
        createNewEmailWithRecipientsRequested({
          contactsIds: contactsIdsToAdd,
          sendingTitle: formatMessage({ id: 'emailing-wizard.message-with-no-title-text' })
        })
      );
    }
  };

  const onOpenCreateEmailingDialog = () => {
    setIsCreateSendingDialogVisible(true);
  };

  const onCloseCreateSendingDialog = () => {
    setIsCreateSendingDialogVisible(false);
  };

  const onSearchOutsideClick = () => {
    setIsSearchBarVisible(false);
  };

  return (
    <div className={'search-results-intro'}>
      <div className="search-results-top">
        <ContactsSearchResultsIntroText />
        {isOnDesktop && (
          <div className="add-emailing-button">
            <DisableUnauthorized permissions={[Permission.ManageEmailings]}>
              <Button
                className="search-results-button button-saved-search"
                primary
                onClick={onOpenCreateEmailingDialog}
                disabled={isCreateEmailingButtonDisabled}
              >
                <Icon iconName="Add" />
                {selectedTableRows.length === 0
                  ? formatMessage({ id: 'search-results-header.create-emailing-button' })
                  : formatMessage({ id: 'search-results-header.send-emailing-button' }, { count: selectedTableRows.length })}
              </Button>
            </DisableUnauthorized>
          </div>
        )}
      </div>
      <div className="search-results-component">
        {!isOnTouch && <ContactsSearch key="contacts-search-results-intro-contact-search" />}
        {isOnTouch && isSearchBarVisible && <ContactsSearch key="contacts-search-results-intro-contact-search" onOutsideClick={onSearchOutsideClick} />}
        {isOnDesktop && (
          <div className="search-result-actions">
            <div className="search-results-buttons">
              <Button className="search-results-button button-save-search" primary onClick={handleCreateNewSavedSearch} disabled={isSaveSearchButtonDisabled}>
                <Icon iconName="SingleBookmark" />
                {formatMessage({ id: 'search-results-header.save-search-label' })}
              </Button>
              <Button className="search-results-button button-saved-search" primary onClick={onOpenSavedSearchesPanel}>
                <Icon iconName="View" />
                {formatMessage({ id: 'search-results-header.view-saved-searches-label' })}
              </Button>
            </div>
          </div>
        )}
        <div className="search-results-more-wrap">
          {isOnMobile && !isSearchBarVisible && (
            <Button className="button-search" onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
              <Icon iconName="Search" />
            </Button>
          )}
          {isOnTouch && (
            <Button className="button-more" onClick={handleClickMoreOptions}>
              <Icon iconName="MoreVertical" />
            </Button>
          )}
        </div>
      </div>
      {isCreateSendingDialogVisible && (
        <CreateSendingDialog
          isDialogVisible={isCreateSendingDialogVisible}
          confirmButtonLabel={formatMessage({ id: 'buttons.yes' })}
          cancelButtonLabel={formatMessage({ id: 'buttons.cancel' })}
          dialogMessage={
            selectedTableRows.length === 0
              ? formatMessage({ id: 'search-results-sending-dialog.create-sending-message' })
              : formatMessage({ id: 'search-results-sending-dialog.create-sending-with-recipients-message' })
          }
          onCloseDialog={onCloseCreateSendingDialog}
          onCreateSending={onCreateSending}
        />
      )}
    </div>
  );
};
