import { DateTime } from 'luxon';
import { Tag } from 'app/common';
import { Activity, ActivityParticipant, Contact, Blacklist, ActivityTypes } from 'app/pages/my-audience/contacts';
import { SendingLog, ContactsSortOption, ContactsSortOrder } from 'app/common/graphql/generated/graphql-gateway';

export class Sending extends Activity {
  constructor(
    public id: string,
    public title: string,
    public imageAsBase64: string,
    public status: SendingStatus,
    public sender: Sender,
    public senderEmailAddress: string,
    public senderDisplayName: string,
    public subject: string,
    public respondToEmailAddress: string,
    public htmlBody: string,
    public originalHtmlBody: string,
    public jsonBody: string,
    public mjmlBody: string,
    public numberOfRecipients: number,
    public createdAt: Date,
    public createdBy: ActivityUser,
    public lastModifiedAt: Date,
    public lastModifiedByUser: ActivityUser,
    public isFavourite: boolean,
    public blacklist: Blacklist,
    public mediums: any[],
    public lists: any[],
    public contacts: any[],
    public distributionDuration: number,
    public comment: string,
    public recipients: Recipient[],
    public mailPreviewLink: string,
    public deliveredAt?: Date,
    public sendingScheduledAt?: Date,
    public tags?: Tag[],
    public logs?: SendingLog[],
    public totalNumberOfLogs?: number,
    public sentPercentage?: number
  ) {
    super(
      id,
      ActivityTypes.Sending,
      comment,
      title,
      recipients,
      createdAt,
      createdBy,
      createdAt,
      DateTime.fromJSDate(createdAt).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(createdAt).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export enum SendingStatus {
  Draft = 1,
  WaitingForDistribution = 2,
  OngoingDistribution = 3,
  IncompleteDistribution = 4,
  SuccessfulDistribution = 5
}

export class Recipient extends ActivityParticipant {
  constructor(
    public id: string,
    public sendingId: string,
    public contact: Contact,
    public collaborationId: string,
    public emailAddress: string,
    public isOpened: boolean,
    public linksClicked: number
  ) {
    super(id, contact, collaborationId);
  }
}

export class RecipientFromList extends Recipient {
  constructor(
    public id: string,
    public sendingId: string,
    public contact: Contact,
    public collaborationId: string,
    public listId: string,
    public emailAddress: string,
    public isOpened: boolean,
    public linksClicked: number
  ) {
    super(id, sendingId, contact, collaborationId, emailAddress, isOpened, linksClicked);
  }
}

export class UpdateSendingRecipientInput {
  constructor(public recipientId: string, public collaborationId: string, public emailAddress: string) {}
}

export class Sender {
  constructor(public id: string, public emailAddress: string, public displayName: string, public userId: string) {}
}

export class ActivityUser {
  constructor(public id: string, public firstName: string, public lastName: string, public profilePictureUrl?: string) {}
}

export class EmailingTableRow {
  constructor(
    public sendingId: string,
    public statusLabel: string,
    public sendingStatus: SendingStatus,
    public isFavourite: boolean,
    public profilePictureJSXElement: JSX.Element,
    public emailJsxElement: JSX.Element,
    public createdAt: string,
    public lastModified: string,
    public lastModifiedBy: JSX.Element,
    public numberOfRecipients: string,
    public blacklists: string,
    public subject: string
  ) {}
}

export class EmailBody {
  constructor(public jsonBody: string, public htmlBody: string) {}
}

export class PageOfSendings {
  constructor(public totalCount: number, public sendings: Sending[]) {}
}

export interface ISendingsSortingInput {
  sortOption: ContactsSortOption;
  sortOrder: ContactsSortOrder;
}

export class DefaultSendingsSortingInput implements ISendingsSortingInput {
  sortOption: ContactsSortOption = ContactsSortOption.EmailTitle;
  sortOrder: ContactsSortOrder = ContactsSortOrder.Asc;
}

export class SendingSortingInput implements ISendingsSortingInput {
  constructor(public sortOption: ContactsSortOption, public sortOrder: ContactsSortOrder) {}
}

export class SendingOverview {
  constructor(
    public sendingId: string,
    public sendingStatus: SendingStatus,
    public numberOfDeliveredRecipients: number,
    public totalNumberOfRecipients: number,
    public totalNumberOfOpenings: number,
    public totalNumberOfClickedLinks: number,
    public totalNumberOfFailedDeliveries: number,
    public numberOfOpenedSendings: number,
    public numberOfUnsubscribedRecipients: number,
    public distributionDurationSeconds: number,
    public deliveredPercentage: number,
    public openingsRatePercentage: number,
    public clickRatePercentage: number
  ) {}
}
