import api from '@flatfile/api';
import FlatfileListener from '@flatfile/listener';
import { RecordWithLinks, Sheet } from '@flatfile/api/api';
import { recordDataWithLinksToContacts, sendImportData } from 'app/common';

export const dataListener = (userId: string, tenantId: string, listener: FlatfileListener) => {
  listener.on('job:completed', { job: 'workbook:sheetSubmitAction-Contacts' }, async ({ context: { workbookId, spaceId } }) => {
    const workbookSheets = await api.sheets.list({ workbookId });

    const contacts = await Promise.all(
      workbookSheets.data.map(async (sheet: Sheet) => {
        const records = await api.records.get(sheet.id);
        return recordDataWithLinksToContacts(records.data.records.map((record: RecordWithLinks) => record.values));
      })
    );

    sendImportData(contacts[0], userId, tenantId, spaceId);
  });
};
