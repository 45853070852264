import 'app/pages/my-audience/common/components/export-notification/ExportNotification.scss';
import { useAppDispatch, useAppSelector } from 'app/common';
import { Attachment, CloseIcon, SpeakerPersonIcon } from '@fluentui/react-northstar';
import { hideVcardAttachment, selectIsVcardAttachmentVisible } from 'app/pages/my-audience/contact-profile';
import { FormattedMessage } from 'app/i18n';

export const ExportNotification = () => {
  const dispatch = useAppDispatch();

  const isVcardAttachmentVisible = useAppSelector(selectIsVcardAttachmentVisible);

  const handleDismissButtonClick = () => {
    dispatch(hideVcardAttachment());
  };

  return (
    <>
      {isVcardAttachmentVisible && (
        <div className="export-notification">
          <Attachment
            actionable
            action={{
              icon: <CloseIcon />,
              onClick: handleDismissButtonClick,
              title: 'Close'
            }}
            icon={<SpeakerPersonIcon />}
            header={<FormattedMessage id={'contacts-export-v-card-popup.content'} />}
          />
        </div>
      )}
    </>
  );
};
