import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Avatar, Input, List, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import { AadPickerSuggestion, PickerSuggestionType, debounceTimeInMilliseconds, getInitials, useAppDispatch, useAppSelector } from 'app/common';
import { AadContact, Group } from 'app/pages/my-audience/contacts';
import {
  getPickerSuggestionsRequested,
  selectAADContactsToAddToList,
  selectPickerSuggestions,
  setAadContactsToAddToList
} from 'app/pages/my-audience/lists-profile';
import emptyImage from 'assets/images/empty-search.png';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddContactFromCompanySearch = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const suggestions = useAppSelector<AadPickerSuggestion[]>(selectPickerSuggestions);
  const contactsToAdd = useAppSelector<AadContact[]>(selectAADContactsToAddToList);

  const [searchText, setSearchText] = useState('');

  const onInputChange = (text: string) => {
    setSearchText(text);
    getSuggestions();
  };

  const getSuggestions = useDebouncedCallback(() => {
    dispatch(getPickerSuggestionsRequested(searchText));
  }, debounceTimeInMilliseconds);

  const handleContactSelected = (selectedIndex: number) => {
    const selectedSuggestion: AadPickerSuggestion = suggestions[selectedIndex];

    if (selectedSuggestion.type === PickerSuggestionType.People) {
      dispatch(setAadContactsToAddToList([...contactsToAdd, selectedSuggestion.value as AadContact]));
    } else {
      dispatch(setAadContactsToAddToList([...contactsToAdd, ...(selectedSuggestion.value as Group).members]));
    }

    setSearchText('');
  };

  const getEmailAddressFromAadSuggestion = (suggestion: AadPickerSuggestion): string => {
    if (suggestion.type === PickerSuggestionType.Group) return '';

    return (suggestion.value as AadContact).email;
  };

  return (
    <div className="wizard-search">
      <Input
        className="profile-panel-comments-send-input"
        icon={<SearchIcon />}
        autoComplete="off"
        placeholder={formatMessage({ id: 'list-wizards.add-contact-from-company-search-input' })}
        value={searchText}
        onChange={(e) => onInputChange((e.target as HTMLTextAreaElement).value)}
      />
      {searchText !== '' && (
        <div className="dropdown-segments-wrap">
          <div className="dropdown-segments">
            <Segment key={'dropdown-names'} className="dropdown-segment">
              <List
                onSelectedIndexChange={(_, newProps) => {
                  if (newProps) handleContactSelected(newProps.selectedIndex);
                }}
                selectable
                items={suggestions.slice(0, 6).map((suggestion) => ({
                  key: suggestion.objectId,
                  header: suggestion.name,
                  content: getEmailAddressFromAadSuggestion(suggestion),
                  media: <Avatar getInitials={getInitials} name={suggestion.name} />
                }))}
              />
            </Segment>
          </div>
        </div>
      )}
      {suggestions?.length === 0 && searchText && (
        <Segment className="empty-search">
          <div className="empty-search-wrap">
            <img className="empty-search-image" src={emptyImage} alt="No results found" />
            <Text className="empty-search-text text-bold">
              <FormattedMessage id={'list-wizards.add-contact-no-results-title'} />
            </Text>
            <Text className="empty-search-text">
              <FormattedMessage id={'list-wizards.add-contact-no-results-subtitle'} />
            </Text>
          </div>
        </Segment>
      )}
    </div>
  );
};
