import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { Contact } from 'app/pages/my-audience/contacts';
import { AddContactWizardStep, goToStep, selectContacts, selectCurrentStep, selectSelectedContact } from 'app/pages/my-audience/media-outlet-profile';

interface AddContactStepsProps {
  areProfileInfoFieldsValid?: boolean;
}

export const AddContactSteps = ({ areProfileInfoFieldsValid }: AddContactStepsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const contactToAdd = useAppSelector<Contact>(selectSelectedContact);
  const contacts = useAppSelector<Contact[]>(selectContacts);
  const step = useAppSelector<AddContactWizardStep>(selectCurrentStep);
  const isContactSelected = Object.keys(contactToAdd).length > 0;

  const styleBreadcrumbStatus = (stepToCompareWith: AddContactWizardStep): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (contacts && stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: AddContactWizardStep) => dispatch(goToStep(step));

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddContactWizardStep.SearchContact)}
            content={formatMessage({ id: 'mo-add-contact.wizard-step-search' })}
            onClick={() => handleStepButtonClick(AddContactWizardStep.SearchContact)}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddContactWizardStep.ProfileInformation)}
            content={formatMessage({ id: 'mo-add-contact.wizard-step-profile' })}
            onClick={() => handleStepButtonClick(AddContactWizardStep.ProfileInformation)}
            disabled={!isContactSelected}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddContactWizardStep.Finish)}
            content={formatMessage({ id: 'mo-add-contact.wizard-step-finish' })}
            onClick={() => handleStepButtonClick(AddContactWizardStep.Finish)}
            disabled={!areProfileInfoFieldsValid || !isContactSelected}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
