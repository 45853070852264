import { Comment, ITableData, MediumCategory, TagProjection } from 'app/common';
import { MediaResort } from 'app/pages/my-audience/media-outlet-profile';
import {
  MediaOutletsSortOption as GQLMediaOutletsSortOption,
  MediaOutletsSortOrder as GQLMediaOutletsSortOrder
} from 'app/common/graphql/generated/graphql-gateway';

export class MediaOutlet {
  constructor(
    public id: string,
    public name: string,
    public profilePictureUrl: string,
    public description: string,
    public audienceKey: string,
    public category: MediumCategory,
    public frequencyKey: string,
    public geographicalCoverage: GeographicalCoverage,
    public mediaSupportTypesKeys: string[],
    public comments: string[],
    public preferredLanguages: PreferredLanguage[],
    public contactData: MediumContactData,
    public mediaOutletContacts: MediaOutletContact[],
    public tags: TagProjection[],
    public mediaResorts: MediaResort[],
    public userComments: Comment[],
    public mediaType: string,
    public publicationType: string,
    public wemfArea: string,
    public readership: string,
    public number: number,
    public circulation: number,
    public dataSourceKey?: string
  ) {}
}

export class PreferredLanguage {
  constructor(public id: string, public name: string, public dataSourceKey: string) {}
}

export class MediaOutletReferenceLists {
  constructor(public audiences: Audience[], public frequencies: Frequency[], public mediumSupportTypes: MediumSupportType[]) {}
}

export class LabelLanguage {
  constructor(public name: string, public value: number) {}
}

export class Label {
  constructor(public value: string, public language: LabelLanguage) {}
}

export class ReferenceList {
  constructor(public id: string, public key: string, public labels: Label[]) {}
}

export class Audience extends ReferenceList {
  constructor(public id: string, public key: string, public labels: Label[]) {
    super(id, key, labels);
  }
}

export class LocalizedListValue {
  constructor(public id: string, public key: string, public label: string) {}
}

export class Frequency extends ReferenceList {
  constructor(public id: string, public key: string, public labels: Label[]) {
    super(id, key, labels);
  }
}

export class MediumSupportType extends ReferenceList {
  constructor(public id: string, public key: string, public labels: Label[]) {
    super(id, key, labels);
  }
}

export class MediaOutletContact {
  constructor(public id: string, public firstName: string, public lastName: string, public profilePictureUrl: string) {}
}

export class MediumContactData {
  constructor(
    public street: string,
    public poBox: string,
    public postalCode: string,
    public country: string,
    public city: string,
    public websiteUrl: string,
    public emailAddress: string,
    public landlinePhoneNumber: string,
    public fax: string,
    public alternativeFax: string
  ) {}
}

export class MediaOutletSearchResultTableData implements ITableData {
  constructor(
    public id: string,
    public key: string,
    public profilePictureUrl: string,
    public name: string,
    public category: string,
    public frequency: string,
    public mediaType: string,
    public columns: string,
    public databaseType: string,
    public tags: string,
    public contacts: string,
    public languages: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country: string,
    public fax: string,
    public website: string,
    public poBox: string,
    public type: string,
    public dataSourceKey: string
  ) {}
}

export class GeographicalCoverage {
  constructor(public id: string, public name: string) {}
}

export class PageOfMediaOutlets {
  constructor(public totalCount: number, public mediaOutlets: MediaOutlet[]) {}
}

export enum MediaOutletsSortOption {
  MediaOutletName = GQLMediaOutletsSortOption.MediaOutletName,
  MediumCategoryName = GQLMediaOutletsSortOption.MediumCategoryName,
  Frequency = GQLMediaOutletsSortOption.Frequency,
  MediaType = GQLMediaOutletsSortOption.MediaType,
  MediaResorts = GQLMediaOutletsSortOption.MediaResorts,
  DatabaseType = GQLMediaOutletsSortOption.DatabaseType,
  Tags = GQLMediaOutletsSortOption.Tags,
  Contacts = GQLMediaOutletsSortOption.Contacts,
  Languages = GQLMediaOutletsSortOption.Languages,
  Address = GQLMediaOutletsSortOption.Address,
  PostCode = GQLMediaOutletsSortOption.PostCode,
  City = GQLMediaOutletsSortOption.City,
  Fax = GQLMediaOutletsSortOption.Fax,
  Website = GQLMediaOutletsSortOption.Website,
  PoBox = GQLMediaOutletsSortOption.PoBox,
  CreatedAt = GQLMediaOutletsSortOption.CreatedAt
}

export enum MediaOutletsSortOrder {
  Desc = GQLMediaOutletsSortOrder.Desc,
  Asc = GQLMediaOutletsSortOrder.Asc
}

export interface IMediaOutletsSortingInput {
  sortOption: MediaOutletsSortOption;
  sortOrder: MediaOutletsSortOrder;
}

export class DefaultMediaOutletsSortingInput implements IMediaOutletsSortingInput {
  sortOption: MediaOutletsSortOption = MediaOutletsSortOption.MediaOutletName;
  sortOrder: MediaOutletsSortOrder = MediaOutletsSortOrder.Asc;
}

export class MediaOutletsSortingInput implements IMediaOutletsSortingInput {
  sortOption: MediaOutletsSortOption;
  sortOrder: MediaOutletsSortOrder;

  constructor(sortOptionParam: string, sortOrderParam: MediaOutletsSortOrder) {
    this.sortOption = this.determineSortingOption(sortOptionParam);
    this.sortOrder = sortOrderParam;
  }

  private determineSortingOption = (sortOption: string): MediaOutletsSortOption => {
    switch (sortOption) {
      case 'name':
        return MediaOutletsSortOption.MediaOutletName;

      case 'category':
        return MediaOutletsSortOption.MediumCategoryName;

      case 'frequency':
        return MediaOutletsSortOption.Frequency;

      case 'mediaType':
        return MediaOutletsSortOption.MediaType;

      case 'mediaResorts':
        return MediaOutletsSortOption.MediaResorts;

      case 'databaseType':
        return MediaOutletsSortOption.DatabaseType;

      case 'tags':
        return MediaOutletsSortOption.Tags;

      case 'contacts':
        return MediaOutletsSortOption.Contacts;

      case 'languages':
        return MediaOutletsSortOption.Languages;

      case 'address':
        return MediaOutletsSortOption.Address;

      case 'postalCode':
        return MediaOutletsSortOption.PostCode;

      case 'city':
        return MediaOutletsSortOption.City;

      case 'fax':
        return MediaOutletsSortOption.Fax;

      case 'website':
        return MediaOutletsSortOption.Website;

      case 'poBox':
        return MediaOutletsSortOption.PoBox;

      case 'createdAt':
        return MediaOutletsSortOption.CreatedAt;

      default:
        return MediaOutletsSortOption.MediaOutletName;
    }
  };
}

export class MediaOutletPanelData {
  constructor(public categories: MediumCategory[]) {}
}
