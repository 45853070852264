import React, { useEffect, useState } from 'react';
import { Icon } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Button, Image, Input, Tooltip } from '@fluentui/react-northstar';
import { DeleteTemplateDialog, Template, TemplatePreviewDialog, updateTemplateRequested } from 'app/pages/my-activities/templates';
import { ThreeDotMenu, ThreeDotMenuItems, useAppDispatch } from 'app/common';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';
import 'app/pages/my-activities/templates/components/TemplateCard.scss';

interface TemplateCardProps {
  template: Template;
  onChooseTemplate: (template: Template) => void;
}

export const TemplateCard = ({ template, onChooseTemplate }: TemplateCardProps) => {
  const dispatch = useAppDispatch();
  const { id, imageAsBase64, title } = template;
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();

  const canEditTemplate = hasPermission(Permission.ManageEmailings);

  const [actions, setActions] = useState(false);
  const [isDialogOpened, { setTrue: openPreviewDialog, setFalse: dismissPreviewDialog }] = useBoolean(false);
  const [isRenameEditableFieldVisible, setIsRenameEditableFieldVisible] = useState<boolean>(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState<boolean>(false);
  const [updatedValue, setUpdatedValue] = useState<string>(title);

  useEffect(() => {
    setUpdatedValue(title);
  }, [title]);

  const openSendingWizard = () => {
    onChooseTemplate(template);
  };

  const onDeleteTemplateClick = () => {
    setIsDeleteDialogOpened(true);
  };

  const onCancelDeleteTemplateClick = () => {
    setIsDeleteDialogOpened(false);
  };

  const onRenameTemplateClick = () => {
    setIsRenameEditableFieldVisible(true);
  };

  const onRenameTemplate = () => {
    if (updatedValue.trim() === '') return;

    dispatch(updateTemplateRequested({ ...template, title: updatedValue }));
    setIsRenameEditableFieldVisible(false);
  };

  const onCancelRenameTemplate = () => {
    setIsRenameEditableFieldVisible(false);
    setUpdatedValue(title);
  };

  const handleKeyboardEvents = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onRenameTemplate();
    } else if (event.key === 'Escape') {
      onCancelRenameTemplate();
    }
  };

  const threeDotMenuItems: ThreeDotMenuItems[] = [
    {
      key: 'rename-template',
      title: formatMessage({ id: 'buttons.rename' }),
      className: 'profile-panel-menu-item',
      icon: <Icon iconName="Edit" />,
      onClick: onRenameTemplateClick,
      disabled: !canEditTemplate
    },
    {
      key: 'delete-template',
      title: formatMessage({ id: 'buttons.delete' }),
      className: 'profile-panel-menu-item danger',
      icon: <Icon iconName="Delete" />,
      onClick: onDeleteTemplateClick,
      disabled: !canEditTemplate
    }
  ];

  return (
    <>
      <div className="template-card-wrap">
        <div className="template-card-intro">
          {isRenameEditableFieldVisible ? (
            <div className="template-card-editable-fields-input">
              <Input
                className={`input-wrap`}
                type="text"
                autoFocus
                icon={
                  <div className="editable-fields-input-actions">
                    <Button onClick={onCancelRenameTemplate} iconOnly content={<Icon iconName="Cancel" />} text />
                    <Button onClick={onRenameTemplate} iconOnly content={<Icon iconName="CheckMark" />} text />
                  </div>
                }
                value={updatedValue}
                onChange={(e) => setUpdatedValue((e.target as HTMLTextAreaElement).value)}
                onKeyDown={handleKeyboardEvents}
              />
            </div>
          ) : (
            <>
              <Tooltip subtle={false} pointing content={title} position="below" trigger={<span className="template-card-text">{title}</span>} />
              <ThreeDotMenu threeDotMenuItems={threeDotMenuItems} iconName="More" />
            </>
          )}
        </div>
        <div className="template-card" onMouseEnter={() => setActions(true)} onMouseLeave={() => setActions(false)}>
          <div className="template-card-image">
            <Image src={imageAsBase64} alt="" />
          </div>
          {actions && (
            <div className="template-card-actions">
              <div className="template-card-actions-content">
                <Button content={formatMessage({ id: 'all-templates.preview' })} secondary onClick={openPreviewDialog} />
                <Button content={formatMessage({ id: 'all-templates.choose' })} primary onClick={openSendingWizard} disabled={!canEditTemplate} />
              </div>
            </div>
          )}
        </div>
      </div>
      {isDialogOpened && (
        <TemplatePreviewDialog isDialogOpened={isDialogOpened} dismissDialog={dismissPreviewDialog} template={template} onChooseTemplate={onChooseTemplate} />
      )}
      {isDeleteDialogOpened && <DeleteTemplateDialog id={id} isDialogOpened={isDeleteDialogOpened} closeDialog={onCancelDeleteTemplateClick} />}
    </>
  );
};
