import { Company, CompanySearchResultTableData } from 'app/pages/my-audience/companies';

export const createCompanySearchResultTableData = (company: Company): CompanySearchResultTableData => {
  return new CompanySearchResultTableData(
    company.id,
    company.id,
    company.profilePictureUrl,
    company.name,
    company.category.name,
    company.contactData.country,
    company.contactData.landlinePhoneNumber,
    company.databaseType,
    company.tags
      .map((tag) => tag.name)
      .filter(Boolean)
      .join(', '),
    company.registrationNumber,
    company.contactData.street,
    company.contactData.postalCode,
    company.contactData.city,
    company.contactData.fax,
    company.contactData.websiteUrl,
    company.contactData.poBox,
    'Company',
    company.dataSourceKey
  );
};
