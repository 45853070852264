import enMessages from 'app/i18n/messages/en.json';
import enErrors from 'app/i18n/errors/en.json';
import enAlerts from 'app/i18n/alerts/en.json';
import frMessages from 'app/i18n/messages/fr.json';
import frErrors from 'app/i18n/errors/fr.json';
import frAlerts from 'app/i18n/alerts/fr.json';
import esMessages from 'app/i18n/messages/es.json';
import esErrors from 'app/i18n/errors/es.json';
import esAlerts from 'app/i18n/alerts/es.json';
import deMessages from 'app/i18n/messages/de.json';
import deErrors from 'app/i18n/errors/de.json';
import deAlerts from 'app/i18n/alerts/de.json';
import { IntlMessage } from 'app/i18n';

export const translationsLocale = (locale: string): IntlMessage => {
  if (locale.slice(0, 2).toLowerCase() === 'fr') {
    return concatJsonLocalizations([frMessages, frErrors, frAlerts]);
  } else if (locale.slice(0, 2).toLowerCase() === 'es') {
    return concatJsonLocalizations([esMessages, esErrors, esAlerts]);
  } else if (locale.slice(0, 2).toLowerCase() === 'de') {
    return concatJsonLocalizations([deMessages, deErrors, deAlerts]);
  }

  return concatJsonLocalizations([enMessages, enErrors, enAlerts]);
};

const concatJsonLocalizations = (localizationsArray) => {
  return localizationsArray.reduce((result, json) => {
    return { ...result, ...json };
  }, {});
};
