import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { CommentUser, Comment, resetMentionSuggestions, mentionSuggestionsRequested, Comments } from 'app/common';
import {
  addCommentMentionRequested,
  addCommentToCompanyRequested,
  selectCommentMentions,
  selectSelectedCompanyComments
} from 'app/pages/my-audience/company-profile';
import { Permission, useAuthContext } from 'auth';

export const CompanyComments = () => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector<Comment[]>(selectSelectedCompanyComments);
  const mentions = useAppSelector<CommentUser[]>(selectCommentMentions);

  const { hasAllPermissions } = useAuthContext();
  const hasPermissionToComment = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const onAddComment = (comment: Comment) => {
    dispatch(addCommentToCompanyRequested(comment));
  };

  const onAddCommentMention = (mention: CommentUser) => {
    dispatch(addCommentMentionRequested(mention));
  };

  const onMentionChange = async (mention: string) => {
    if (!mention) {
      dispatch(resetMentionSuggestions());
      return;
    }
    dispatch(mentionSuggestionsRequested(mention));
  };

  return (
    <Comments
      comments={comments}
      mentions={mentions}
      addComment={onAddComment}
      addCommentMention={onAddCommentMention}
      mentionChange={onMentionChange}
      disabled={!hasPermissionToComment}
    />
  );
};
