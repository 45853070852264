import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { isTextInputValid, maximumCompanyNameLength, regAtLeastOneCharacter, useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import {
  GeneralInfo,
  CreateCompanyFooter,
  selectGeneralInfoToAdd,
  setGeneralInfoToAdd,
  closeCreateCompanyWizard,
  goToPreviousStep,
  goToNextStep,
  CreateCompanySteps
} from 'app/pages/my-audience/wizard-create-company';
import { ControlledInput } from 'app/pages/my-audience/common';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddGeneralInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const generalInfo = useAppSelector<GeneralInfo>(selectGeneralInfoToAdd);

  const { handleSubmit, control, watch } = useForm<GeneralInfo, any>({
    defaultValues: {
      name: generalInfo.name,
      parentCompany: generalInfo.parentCompany,
      comment: generalInfo.comment
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };

  const handleNextButtonClick = () => {
    submitData();
    dispatch(goToNextStep());
  };

  const submitData = () => {
    handleSubmit(
      (info: GeneralInfo) => {
        dispatch(setGeneralInfoToAdd(info));
      },
      (error) => {
        Sentry.setExtra('Path', AddGeneralInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleFieldsValidation = () => isTextInputValid({ inputValue: watch('name'), maxInputLength: maximumCompanyNameLength });

  const areGeneralInfoValid = handleFieldsValidation();

  return (
    <>
      <CreateCompanySteps onStepClick={submitData} isStepValid={areGeneralInfoValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-company.wizard-add-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'add-menu-create-company.wizard-name-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="name"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage(
                    { id: 'error-messages.not-valid-name' },
                    { entity: formatMessage({ id: 'add-menu-create-company.wizard-name-company' }) }
                  )
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumCompanyNameLength }),
                  value: maximumCompanyNameLength
                },
                required: formatMessage(
                  { id: 'error-messages.required-filed' },
                  { fieldName: formatMessage({ id: 'add-menu-create-company.wizard-name-company' }) }
                )
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.wizard-parent-label' })} control={control} name="parentCompany" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.wizard-comment-label' })} control={control} name="comment" />
          </div>
        </div>
        <CreateCompanyFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areGeneralInfoValid}
        />
      </div>
    </>
  );
};
