import { DialogType, Dialog, DialogFooter } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectIsRemoveContactDialogOpened, closeRemoveContactDialog, selectSelectedContactsIds } from 'app/pages/my-audience/lists-profile';

interface RemoveContactDialogProps {
  removeSelectedContacts(): void;
}

export const RemoveContactDialog: React.FC<RemoveContactDialogProps> = ({ removeSelectedContacts }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isRemoveContactDialogOpened = useAppSelector<boolean>(selectIsRemoveContactDialogOpened);
  const selectedContactIds = useAppSelector<string[]>(selectSelectedContactsIds);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'list-profile-contacts-table-toolbar.are-you-sure-you-want-to-delete-contacts' }, { counter: selectedContactIds.length })
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const onRemoveHandler = () => {
    removeSelectedContacts();
    dispatch(closeRemoveContactDialog());
  };

  const closeDialog = () => {
    dispatch(closeRemoveContactDialog());
  };

  return (
    <Dialog hidden={!isRemoveContactDialogOpened} onDismiss={closeDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="profile-photo-dialog">
        <DialogFooter>
          <Button tinted onClick={closeDialog} content={formatMessage({ id: 'buttons.cancel' })} />
          <Button primary onClick={onRemoveHandler} content={formatMessage({ id: 'buttons.remove' })} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
