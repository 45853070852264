import { ListOverviewChartInfo, ListOverviewCharts, ListOverviewInfo } from 'app/pages/my-audience/lists-profile';

export const ListOverview = () => {
  return (
    <div className="list-overview">
      <ListOverviewInfo />
      <ListOverviewCharts />
      <ListOverviewChartInfo />
    </div>
  );
};
