import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Comment, CommentUser, ProfilePicture, Tag, TagProjection } from 'app/common';
import { createContactsTableRows, CreateContactTableData } from 'app/pages/my-audience/common';
import { AddCollaboration, Collaboration, Contact, OwnContactData } from 'app/pages/my-audience/contacts';
import { AddContactWizardStep, MediaResort, CreateContactWizardStep } from 'app/pages/my-audience/media-outlet-profile';
import { MediaOutlet } from 'app/pages/my-audience/media-outlets';
import { RootState } from 'app/redux/store';
import { cloneDeep } from 'lodash';

export interface MediaOutletProfileState {
  isProfilePanelOpened: boolean;
  selectedMediaOutletId: string;
  selectedMediaOutlet: MediaOutlet;
  commentToAdd: Comment;
  commentMentions: CommentUser[];
  allTags: Tag[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToMediaOutlet: TagProjection;
  tagToRemoveFromMediaOutlet: TagProjection;
  isLoadingAllTags: boolean;
  imageToUpload: ProfilePicture;
  profilePicturePreviewUrl: string;
  isLoadingSelectedMediaOutlet: boolean;
  allMediaResorts: MediaResort[];
  filteredMediaResorts: MediaResort[];
  mediaResortToAdd: MediaResort;
  mediaResortsToRemove: MediaResort[];
  mediaResortToAddToMediaOutlet: MediaResort;
  mediaResortToRemoveFromMediaOutlet: MediaResort;
  isLoadingAllColumns: boolean;
  mediaOutletToUpdate: MediaOutlet;
  mediaOutletContacts: Contact[];
  isLoadingMediaOutletContacts: boolean;
  collaborationToUpdate: Collaboration;
  contactToUpdate: Contact;
  contactToRemove: Contact;
  collaborationToAdd: Collaboration;
  contactToAdd: Contact;
  ownContactDataToAdd: OwnContactData;
  selectedContact: Contact;
  isLoadingContacts: boolean;
  contacts: Contact[];
  selectedContactForEditing: Contact;
  isRemoveMediaOutletDialogOpened: boolean;
  isTransformMediaOutletDialogOpened: boolean;
  isAddContactWizardOpened: boolean;
  currentStep: AddContactWizardStep;
  currentStepCreate: CreateContactWizardStep;
  isCreateContactWizardOpened: boolean;
  isCreateContactFinishButtonDisabled: boolean;
  isAddContactFinishButtonDisabled: boolean;
  isRemoveCollaborationDialogOpened: boolean;
  isEditCollaborationWizardOpened: boolean;
  selectedCollaborationForEditing: Collaboration;
  areMoreEditableFieldsVisible: boolean;
  contactsPageNumber: number;
  contactsPageSize: number;
  contactsSearchText: string;
  contactsRoleFilter: string;
  contactsTotalCount: number;
  mediaOutletContactsPageNumber: number;
  mediaOutletContactsPageSize: number;
  mediaOutletContactsSearchText: string;
  mediaOutletContactsRoleFilter: string;
  mediaOutletContactsTotalCount: number;
  isChangeImageDialogOpened: boolean;
  isCollaborationsListOutdated: boolean;
}

const initialState: MediaOutletProfileState = {
  isRemoveCollaborationDialogOpened: false,
  isProfilePanelOpened: false,
  selectedMediaOutletId: '',
  selectedMediaOutlet: {} as MediaOutlet,
  commentToAdd: {} as Comment,
  commentMentions: [],
  allTags: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToMediaOutlet: {} as TagProjection,
  tagToRemoveFromMediaOutlet: {} as TagProjection,
  isLoadingAllTags: false,
  imageToUpload: {} as ProfilePicture,
  profilePicturePreviewUrl: '',
  isLoadingSelectedMediaOutlet: true,
  allMediaResorts: [],
  mediaResortToAdd: {} as MediaResort,
  mediaResortsToRemove: [],
  mediaResortToAddToMediaOutlet: {} as MediaResort,
  mediaResortToRemoveFromMediaOutlet: {} as MediaResort,
  isLoadingAllColumns: true,
  mediaOutletToUpdate: {} as MediaOutlet,
  mediaOutletContacts: [],
  isLoadingMediaOutletContacts: true,
  collaborationToUpdate: {} as Collaboration,
  contactToUpdate: {} as Contact,
  contactToRemove: {} as Contact,
  collaborationToAdd: {} as Collaboration,
  contactToAdd: {} as Contact,
  ownContactDataToAdd: {} as OwnContactData,
  selectedContact: {} as Contact,
  isLoadingContacts: true,
  contacts: [],
  selectedContactForEditing: {} as Contact,
  isRemoveMediaOutletDialogOpened: false,
  isTransformMediaOutletDialogOpened: false,
  isAddContactWizardOpened: false,
  currentStep: AddContactWizardStep.SearchContact,
  currentStepCreate: CreateContactWizardStep.CreateNew,
  isCreateContactWizardOpened: false,
  isCreateContactFinishButtonDisabled: false,
  isAddContactFinishButtonDisabled: false,
  isEditCollaborationWizardOpened: false,
  selectedCollaborationForEditing: {} as Collaboration,
  areMoreEditableFieldsVisible: false,
  contactsPageNumber: 1,
  contactsPageSize: 10,
  contactsRoleFilter: '',
  contactsSearchText: '',
  contactsTotalCount: 0,
  mediaOutletContactsRoleFilter: '',
  mediaOutletContactsPageNumber: 1,
  mediaOutletContactsPageSize: 10,
  mediaOutletContactsSearchText: '',
  mediaOutletContactsTotalCount: 0,
  isChangeImageDialogOpened: false,
  filteredMediaResorts: [],
  isCollaborationsListOutdated: false
};

export type CurrentStepSelector = (state: RootState) => AddContactWizardStep;
export const selectCurrentStep: CurrentStepSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.currentStep],
  (currentStep: AddContactWizardStep) => currentStep
);

export type OwnContactDataToAddSelector = (state: RootState) => OwnContactData;
export const selectOwnContactDataToAdd: OwnContactDataToAddSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.ownContactDataToAdd],
  (ownContactDataToAdd: OwnContactData) => ownContactDataToAdd
);

export type CurrentStepCreateSelector = (state: RootState) => CreateContactWizardStep;
export const selectCurrentStepCreate: CurrentStepCreateSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.currentStepCreate],
  (currentStepCreate: CreateContactWizardStep) => currentStepCreate
);

export type IsAddContactWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsAddContactWizardOpened: IsAddContactWizardOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isAddContactWizardOpened],
  (isAddContactWizardOpened: boolean) => isAddContactWizardOpened
);

export type IsCreateContactWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateContactWizardOpened: IsCreateContactWizardOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isCreateContactWizardOpened],
  (isCreateContactWizardOpened: boolean) => isCreateContactWizardOpened
);

export type IsEditCollaborationWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsEditCollaborationWizardOpened: IsEditCollaborationWizardOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isEditCollaborationWizardOpened],
  (isEditCollaborationWizardOpened: boolean) => isEditCollaborationWizardOpened
);

export type IsTransformMediaOutletDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsTransformMediaOutletDialogOpened: IsTransformMediaOutletDialogOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isTransformMediaOutletDialogOpened],
  (isTransformMediaOutletDialogOpened: boolean) => isTransformMediaOutletDialogOpened
);

export type IsRemoveMediaOutletDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsRemoveMediaOutletDialogOpened: IsRemoveMediaOutletDialogOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isRemoveMediaOutletDialogOpened],
  (isRemoveMediaOutletDialogOpened: boolean) => isRemoveMediaOutletDialogOpened
);

export type IsProfilePanelOpenedSelector = (state: RootState) => boolean;
export const selectIsProfilePanelOpened: IsProfilePanelOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isProfilePanelOpened],
  (isProfilePanelOpened: boolean) => isProfilePanelOpened
);

export type IsRemoveCollaborationDialogOpened = (state: RootState) => boolean;
export const selectIsRemoveCollaborationDialogOpened: IsRemoveCollaborationDialogOpened = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isRemoveCollaborationDialogOpened],
  (isRemoveCollaborationDialogOpened: boolean) => isRemoveCollaborationDialogOpened
);

export type SelectedMediaOutletSelector = (state: RootState) => MediaOutlet;
export const selectSelectedMediaOutlet: SelectedMediaOutletSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedMediaOutlet],
  (selectedMediaOutlet: MediaOutlet) => selectedMediaOutlet
);

export type SelectedMediaOutletIdSelector = (state: RootState) => string;
export const selectSelectedMediaOutletId: SelectedMediaOutletIdSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedMediaOutletId],
  (selectedMediaOutletId: string) => selectedMediaOutletId
);

export type SelectedMediaOutletCommentsSelector = (state: RootState) => Comment[];
export const selectSelectedMediaOutletComments: SelectedMediaOutletCommentsSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedMediaOutlet.userComments],
  (userComments: Comment[]) => userComments
);

export type CommentToAddToMediaOutletSelector = (state: RootState) => Comment;
export const selectCommentToAddToMediaOutlet: CommentToAddToMediaOutletSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.commentToAdd],
  (commentToAdd: Comment) => commentToAdd
);

export type CommentMentionsSelector = (state: RootState) => CommentUser[];
export const selectCommentMentions: CommentMentionsSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.commentMentions],
  (commentMentions: CommentUser[]) => commentMentions
);

export type AllTagsValuesSelector = (state: RootState) => string[];
export const selectAllTagsValues: AllTagsValuesSelector = createSelector([(state: RootState) => state.mediaOutletProfile.allTags], (allTags: Tag[]) =>
  allTags.map((tag) => tag?.name)
);

export type tagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: tagNameToCreateSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type SelectedMediaOutletTagsHeaderValueSelector = (state: RootState) => Tag[];
export const selectMediaOutletTagsHeaderValue: SelectedMediaOutletTagsHeaderValueSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedMediaOutlet.tags],
  (tags: Tag[]) => tags
);

export type ImageToUploadSelector = (state: RootState) => ProfilePicture;
export const selectImageToUpload: ImageToUploadSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.imageToUpload],
  (imageToUpload: ProfilePicture) => imageToUpload
);

export type ProfilePicturePreviewUrlSelector = (state: RootState) => string;
export const selectProfilePicturePreviewUrl: ProfilePicturePreviewUrlSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.profilePicturePreviewUrl],
  (profilePicturePreviewUrl: string) => profilePicturePreviewUrl
);

export type SelectedMediaOutletProfilePictureUrlSelector = (state: RootState) => string;
export const selectSelectedMediaOutletProfilePictureUrl: SelectedMediaOutletProfilePictureUrlSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedMediaOutlet.profilePictureUrl],
  (profilePictureUrl: string) => profilePictureUrl
);

export type IsLoadingMediaOutletSelector = (state: RootState) => boolean;
export const selectIsLoadingSelectedMediaOutlet: IsLoadingMediaOutletSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isLoadingSelectedMediaOutlet],
  (isLoadingSelectedMediaOutlet: boolean) => isLoadingSelectedMediaOutlet
);

export type AllMediaResortsNamesSelector = (state: RootState) => string[];
export const selectAllMediaResortsNames: AllMediaResortsNamesSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.allMediaResorts],
  (allMediaResorts: MediaResort[]) => allMediaResorts.map((c) => c.name)
);

export type FilteredMediaResortsSelector = (state: RootState) => MediaResort[];
export const selectFilteredMediaResorts: FilteredMediaResortsSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.filteredMediaResorts],
  (filteredMediaResorts: MediaResort[]) => filteredMediaResorts
);

export type AllMediaResortsSelector = (state: RootState) => MediaResort[];
export const selectAllMediaResorts: AllMediaResortsSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.allMediaResorts],
  (allMediaResorts: MediaResort[]) => allMediaResorts
);

export type MediaResortToAddSelector = (state: RootState) => MediaResort;
export const selectMediaResortToAdd: MediaResortToAddSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaResortToAdd],
  (mediaResortToAdd: MediaResort) => mediaResortToAdd
);

export type MediaResortsToRemoveSelector = (state: RootState) => MediaResort[];
export const selectMediaResortsToRemove: MediaResortsToRemoveSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaResortsToRemove],
  (mediaResortsToRemove: MediaResort[]) => mediaResortsToRemove
);

export type MediaResortToAddToMediaOutletSelector = (state: RootState) => MediaResort;
export const selectMediaResortToAddToMediaOutlet: MediaResortToAddToMediaOutletSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaResortToAddToMediaOutlet],
  (mediaResortToAddToMediaOutlet: MediaResort) => mediaResortToAddToMediaOutlet
);

export type MediaResortToRemoveFromMediaOutletSelector = (state: RootState) => MediaResort;
export const selectMediaResortToRemoveFromMediaOutlet: MediaResortToRemoveFromMediaOutletSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaResortToRemoveFromMediaOutlet],
  (mediaResortToRemoveFromMediaOutlet: MediaResort) => mediaResortToRemoveFromMediaOutlet
);

export type MediaOutletToUpdateSelector = (state: RootState) => MediaOutlet;
export const selectMediaOutletToUpdate: MediaOutletToUpdateSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaOutletToUpdate],
  (mediaOutletToUpdate: MediaOutlet) => mediaOutletToUpdate
);

export type MediaOutletContactsSelector = (state: RootState) => Contact[];
export const selectMediaOutletContacts: MediaOutletContactsSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaOutletContacts],
  (mediaOutletContacts: Contact[]) => mediaOutletContacts
);

export type IsLoadingMediaOutletContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingMediaOutletContacts: IsLoadingMediaOutletContactsSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isLoadingMediaOutletContacts],
  (isLoadingMediaOutletContacts: boolean) => isLoadingMediaOutletContacts
);

export type CollaborationToUpdateSelector = (state: RootState) => Collaboration;
export const selectCollaborationToUpdate: CollaborationToUpdateSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.collaborationToUpdate],
  (collaborationToUpdate: Collaboration) => collaborationToUpdate
);

export type ContactToUpdateSelector = (state: RootState) => Contact;
export const selectContactToUpdate: ContactToUpdateSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactToUpdate],
  (contactToUpdate: Contact) => contactToUpdate
);

export type ContactToRemoveSelector = (state: RootState) => Contact;
export const selectContactToRemove: ContactToRemoveSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactToRemove],
  (contactToRemove: Contact) => contactToRemove
);

export type CollaborationToAddSelector = (state: RootState) => Collaboration;
export const selectCollaborationToAdd: CollaborationToAddSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.collaborationToAdd],
  (collaborationToAdd: Collaboration) => collaborationToAdd
);

export type ContactToAddSelector = (state: RootState) => Contact;
export const selectContactToAdd: ContactToAddSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactToAdd],
  (contactToAdd: Contact) => contactToAdd
);

export type SelectedContactSelector = (state: RootState) => Contact;
export const selectSelectedContact: SelectedContactSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedContact],
  (selectedContact: Contact) => selectedContact
);

export type ContactsSelector = (state: RootState) => Contact[];
export const selectContacts: ContactsSelector = createSelector([(state: RootState) => state.mediaOutletProfile.contacts], (contacts: Contact[]) => contacts);

export type ContactsTableDataSelector = (state: RootState) => CreateContactTableData[];
export const selectContactsTableData: ContactsTableDataSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contacts],
  (contacts: Contact[]) => createContactsTableRows(contacts)
);

export type IsLoadingContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingContacts: IsLoadingContactsSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isLoadingContacts],
  (isLoadingContacts: boolean) => isLoadingContacts
);

export type SelectedContactForEditingSelector = (state: RootState) => Contact;
export const selectSelectedContactForEditing: SelectedContactForEditingSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedContactForEditing],
  (selectedContactForEditing: Contact) => selectedContactForEditing
);

export type CollaborationForEditingSelector = (state: RootState) => Collaboration;
export const selectCollaborationForEditing: CollaborationForEditingSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.selectedCollaborationForEditing],
  (selectedCollaborationForEditing: Collaboration) => selectedCollaborationForEditing
);

export type AreMoreEditableFieldsVisibleSelector = (state: RootState) => boolean;
export const selectAreMoreEditableFieldsVisible: AreMoreEditableFieldsVisibleSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.areMoreEditableFieldsVisible],
  (areMoreEditableFieldsVisible: boolean) => areMoreEditableFieldsVisible
);

export type ContactsPageNumberSelector = (state: RootState) => number;
export const selectContactsPageNumber: ContactsPageNumberSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactsPageNumber],
  (contactsPageNumber: number) => contactsPageNumber
);

export type ContactsPageSizeSelector = (state: RootState) => number;
export const selectContactsPageSize: ContactsPageSizeSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactsPageSize],
  (contactsPageSize: number) => contactsPageSize
);

export type ContactsRoleFilterSelector = (state: RootState) => string;
export const selectContactsRoleFilter: ContactsRoleFilterSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactsRoleFilter],
  (contactsRoleFilter: string) => contactsRoleFilter
);

export type ContactsSearchTextSelector = (state: RootState) => string;
export const selectContactsSearchText: ContactsSearchTextSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactsSearchText],
  (contactsSearchText: string) => contactsSearchText
);

export type ContactsTotalCountSelector = (state: RootState) => number;
export const selectContactsTotalCount: ContactsTotalCountSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.contactsTotalCount],
  (contactsTotalCount: number) => contactsTotalCount
);

export type MediaOutletContactsPageNumberSelector = (state: RootState) => number;
export const selectMediaOutletContactsPageNumber: MediaOutletContactsPageNumberSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaOutletContactsPageNumber],
  (mediaOutletContactsPageNumber: number) => mediaOutletContactsPageNumber
);

export type MediaOutletContactsPageSizeSelector = (state: RootState) => number;
export const selectMediaOutletContactsPageSize: MediaOutletContactsPageSizeSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaOutletContactsPageSize],
  (mediaOutletContactsPageSize: number) => mediaOutletContactsPageSize
);

export type MediaOutletContactsRoleFilterSelector = (state: RootState) => string;
export const selectMediaOutletContactsRoleFilter: MediaOutletContactsRoleFilterSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaOutletContactsRoleFilter],
  (mediaOutletContactsRoleFilter: string) => mediaOutletContactsRoleFilter
);

export type MediaOutletContactsSearchTextSelector = (state: RootState) => string;
export const selectMediaOutletContactsSearchText: MediaOutletContactsSearchTextSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaOutletContactsSearchText],
  (mediaOutletContactsSearchText: string) => mediaOutletContactsSearchText
);

export type MediaOutletContactsTotalCountSelector = (state: RootState) => number;
export const selectMediaOutletContactsTotalCount: MediaOutletContactsTotalCountSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.mediaOutletContactsTotalCount],
  (mediaOutletContactsTotalCount: number) => mediaOutletContactsTotalCount
);

export type IsChangeImageDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsChangeImageDialogOpened: IsChangeImageDialogOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isChangeImageDialogOpened],
  (isChangeImageDialogOpened: boolean) => isChangeImageDialogOpened
);

export type IsCreateContactFinishButtonDisabled = (state: RootState) => boolean;
export const selectIsCreateContactFinishButtonDisabled: IsCreateContactFinishButtonDisabled = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isCreateContactFinishButtonDisabled],
  (isCreateContactFinishButtonDisabled: boolean) => isCreateContactFinishButtonDisabled
);

export type IsAddContactFinishButtonDisabled = (state: RootState) => boolean;
export const selectIsAddContactFinishButtonDisabled: IsAddContactFinishButtonDisabled = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isAddContactFinishButtonDisabled],
  (isAddContactFinishButtonDisabled: boolean) => isAddContactFinishButtonDisabled
);

export type IsCollaborationsListOutdatedSelector = (state: RootState) => boolean;
export const selectIsCollaborationsListOutdated: IsCollaborationsListOutdatedSelector = createSelector(
  [(state: RootState) => state.mediaOutletProfile.isCollaborationsListOutdated],
  (isCollaborationsListOutdated: boolean) => isCollaborationsListOutdated
);

const mediaOutletProfileSlice = createSlice({
  name: 'media-outlet-profile',
  initialState,
  reducers: {
    goToStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    goToNextStep: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep = state.currentStep - 1;
    },
    goToStepCreateWizard: (state, action: PayloadAction<number>) => {
      state.currentStepCreate = action.payload;
    },
    goToNextStepCreateWizard: (state) => {
      state.currentStepCreate = state.currentStepCreate + 1;
    },
    goToPreviousStepCreateWizard: (state) => {
      state.currentStepCreate = state.currentStepCreate - 1;
    },
    openAddContactWizard: (state) => {
      state.isAddContactWizardOpened = true;
      state.currentStep = AddContactWizardStep.SearchContact;
      state.isAddContactFinishButtonDisabled = false;
      state.selectedContact = {} as Contact;
    },
    closeAddContactWizard: (state) => {
      state.isAddContactWizardOpened = false;
      state.currentStep = AddContactWizardStep.SearchContact;
      state.contactToAdd = {} as Contact;
      state.collaborationToAdd = {} as Collaboration;
      state.ownContactDataToAdd = {} as OwnContactData;
    },
    openEditCollaborationWizard: (state) => {
      state.isEditCollaborationWizardOpened = true;
    },
    closeEditCollaborationWizard: (state) => {
      state.isEditCollaborationWizardOpened = false;
    },
    openCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = true;
      state.currentStepCreate = CreateContactWizardStep.CreateNew;
      state.isCreateContactFinishButtonDisabled = false;
    },
    closeCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = false;
      state.currentStepCreate = CreateContactWizardStep.CreateNew;
    },
    openProfilePanel: (state) => {
      state.isProfilePanelOpened = true;
    },
    closeProfilePanel: (state) => {
      state.isProfilePanelOpened = false;
    },
    openRemoveCollaborationDialog: (state) => {
      state.isRemoveCollaborationDialogOpened = true;
    },
    closeRemoveCollaborationDialog: (state) => {
      state.isRemoveCollaborationDialogOpened = false;
    },
    setSelectedMediaOutlet: (state, action: PayloadAction<MediaOutlet>) => {
      state.selectedMediaOutlet = action.payload;
      state.profilePicturePreviewUrl = action.payload.profilePictureUrl;
      state.isLoadingSelectedMediaOutlet = false;
    },
    resetMediaOutletCollaboration: (state) => {
      state.selectedMediaOutlet = {} as MediaOutlet;
      state.profilePicturePreviewUrl = '';
      state.isLoadingSelectedMediaOutlet = false;
    },
    getSelectedMediaOutletRequested: (state, action: PayloadAction<string>) => {
      state.selectedMediaOutletId = action.payload;
      state.isLoadingSelectedMediaOutlet = true;
    },
    selectedMediaOutletReceived: (state, action: PayloadAction<MediaOutlet>) => {
      state.selectedMediaOutlet = action.payload;
      state.isLoadingSelectedMediaOutlet = false;
      state.profilePicturePreviewUrl = action.payload.profilePictureUrl;
    },
    addCommentToMediaOutletRequested: (state, action: PayloadAction<Comment>) => {
      state.commentToAdd = action.payload;
    },
    addedCommentToMediaOutlet: (state, action: PayloadAction<Comment>) => {
      state.selectedMediaOutlet.userComments = [...state.selectedMediaOutlet.userComments, action.payload];
      state.commentToAdd = {} as Comment;
      state.commentMentions = [];
    },
    addCommentMentionRequested: (state, action: PayloadAction<CommentUser>) => {
      state.commentMentions = [...state.commentMentions, action.payload];
    },
    allTagsReceived: (state, action: PayloadAction<Tag[]>) => {
      state.allTags = action.payload;
      state.isLoadingAllTags = false;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.selectedMediaOutlet.tags = [...state.selectedMediaOutlet.tags, action.payload];
      state.tagNameToCreate = '';
    },
    undoAddedNewTag: (state, action: PayloadAction<Tag>) => {
      state.selectedMediaOutlet.tags = state.selectedMediaOutlet.tags.filter((t) => t.id !== action.payload.id);
      state.tagNameToCreate = '';
    },
    tagsDeleted: (state, action: PayloadAction<string[]>) => {
      state.allTags = state.allTags.filter((tag) => !action.payload.includes(tag.id));
      state.selectedMediaOutlet.tags = state.selectedMediaOutlet.tags.filter((tag) => !action.payload.includes(tag.id));
      state.tagsToDelete = [];
    },
    addedTagToMediaOutlet: (state, action: PayloadAction<TagProjection>) => {
      state.tagToAddToMediaOutlet = {} as TagProjection;
      if (!state.selectedMediaOutlet.tags?.every((tag) => tag.id !== action.payload.id)) return;
      state.selectedMediaOutlet.tags = [...state.selectedMediaOutlet.tags, action.payload];
    },
    removedTagsFromMediaOutlet: (state, action: PayloadAction<TagProjection[]>) => {
      state.tagToRemoveFromMediaOutlet = {} as TagProjection;
      if (!state.selectedMediaOutlet.tags) return;
      const tagIds = action.payload.map((t) => t.id);
      state.selectedMediaOutlet.tags = state.selectedMediaOutlet.tags.filter((tag) => !tagIds.includes(tag.id));
    },
    removeProfilePictureRequested: (state) => {
      state.imageToUpload = {} as ProfilePicture;
    },
    updateProfilePictureRequested: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
    },
    profilePictureUpdated: (state, action: PayloadAction<string>) => {
      state.selectedMediaOutlet = { ...state.selectedMediaOutlet, profilePictureUrl: action.payload };
      state.profilePicturePreviewUrl = action.payload;
    },
    removeProfilePicturePreview: (state) => {
      state.profilePicturePreviewUrl = '';
      state.imageToUpload = {} as ProfilePicture;
    },
    changeProfilePictureToUpload: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
      state.profilePicturePreviewUrl = state.selectedMediaOutlet.profilePictureUrl;
    },
    getAllMediaResortsRequested: (state) => {
      state.isLoadingAllColumns = true;
    },
    allMediaResortsReceived: (state, action: PayloadAction<MediaResort[]>) => {
      state.allMediaResorts = action.payload;
      state.filteredMediaResorts = action.payload;
      state.isLoadingAllColumns = false;
    },
    addMediaResortAndAssignToMediaOutletRequested: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortToAdd = action.payload;
    },
    addedNewMediaResort: (state, action: PayloadAction<MediaResort>) => {
      state.allMediaResorts = [...state.allMediaResorts, action.payload];
      state.filteredMediaResorts = [...state.filteredMediaResorts, action.payload];
      state.mediaResortToAdd = {} as MediaResort;
    },
    removeMediaResortsRequested: (state, action: PayloadAction<MediaResort[]>) => {
      state.mediaResortsToRemove = action.payload;
    },
    mediaResortsRemoved: (state, action: PayloadAction<string[]>) => {
      state.allMediaResorts = state.allMediaResorts.filter((t) => !action.payload.includes(t.id));
      state.filteredMediaResorts = state.filteredMediaResorts.filter((t) => !action.payload.includes(t.id));
      state.mediaResortsToRemove = [];
      state.selectedMediaOutlet = {
        ...state.selectedMediaOutlet,
        mediaResorts: state.selectedMediaOutlet.mediaResorts.filter((mr) => !action.payload.includes(mr.id))
      };
    },
    addMediaResortToMediaOutletRequested: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortToAddToMediaOutlet = action.payload;
    },
    addMediaResortToMediaOutletInStore: (state, action: PayloadAction<MediaResort>) => {
      state.selectedMediaOutlet = { ...state.selectedMediaOutlet, mediaResorts: [...state.selectedMediaOutlet.mediaResorts, action.payload] };
      state.mediaResortToAddToMediaOutlet = {} as MediaResort;
    },
    undoAddMediaResortToMediaOutletInStore: (state, action: PayloadAction<MediaResort>) => {
      state.selectedMediaOutlet = {
        ...state.selectedMediaOutlet,
        mediaResorts: state.selectedMediaOutlet.mediaResorts.filter((c) => c.id !== action.payload.id)
      };
      state.mediaResortToAddToMediaOutlet = {} as MediaResort;
    },
    addedMediaResortToMediaOutlet: (state, action: PayloadAction<MediaResort>) => {
      state.selectedMediaOutlet = { ...state.selectedMediaOutlet, mediaResorts: [...state.selectedMediaOutlet.mediaResorts, action.payload] };
      state.mediaResortToAddToMediaOutlet = {} as MediaResort;
    },
    removeMediaResortFromMediaOutletRequested: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortToRemoveFromMediaOutlet = action.payload;
    },
    filterMediaResorts: (state, action: PayloadAction<string>) => {
      state.filteredMediaResorts = state.allMediaResorts.filter((c) => c.name.includes(action.payload));
    },
    updateMediaOutletRequested: (state, action: PayloadAction<MediaOutlet>) => {
      state.selectedMediaOutlet = action.payload;
      state.mediaOutletToUpdate = action.payload;
    },
    updatedMediaOutlet: (state, action: PayloadAction<MediaOutlet>) => {
      state.selectedMediaOutlet = action.payload;
      state.mediaOutletToUpdate = {} as MediaOutlet;
    },
    pageOfMediaOutletContactsRequested: (state) => {
      state.isLoadingMediaOutletContacts = true;
      state.mediaOutletContactsTotalCount = 0;
    },
    pageOfMediaOutletContactsReceived: (state, action: PayloadAction<{ contacts: Contact[]; totalCountOfContacts: number }>) => {
      state.isLoadingMediaOutletContacts = false;
      state.mediaOutletContacts = action.payload.contacts;
      state.mediaOutletContactsTotalCount = action.payload.totalCountOfContacts;
      state.isCollaborationsListOutdated = false;
    },
    setSearchMediaOutletContactsTextAndFilterItem: (state, action: PayloadAction<{ searchText: string; role: string }>) => {
      state.mediaOutletContactsSearchText = action.payload.searchText;
      state.mediaOutletContactsRoleFilter = action.payload.role;
    },
    setMediaOutletContactsPageNumber: (state, action: PayloadAction<number>) => {
      state.mediaOutletContactsPageNumber = action.payload;
    },
    setMediaOutletContactsPageSize: (state, action: PayloadAction<number>) => {
      state.mediaOutletContactsPageSize = action.payload;
      state.mediaOutletContactsPageNumber = 1;
    },
    setTotalCountOfMediaOutletContacts: (state, action: PayloadAction<number>) => {
      state.mediaOutletContactsTotalCount = action.payload;
    },
    updateCollaborationRequested: (state, action: PayloadAction<{ contact: Contact; collaboration: Collaboration }>) => {
      state.contactToUpdate = action.payload.contact;
      state.collaborationToUpdate = action.payload.collaboration;
    },
    updateCollaborationReceived: (state, action: PayloadAction<Contact>) => {
      const contactIndex = state.mediaOutletContacts.findIndex((contact) => contact.id === state.contactToUpdate.id);
      state.mediaOutletContacts[contactIndex].collaborations = action.payload.collaborations;
      state.selectedContactForEditing = {} as Contact;
    },
    removeContributionRequested: (state, action: PayloadAction<Contact>) => {
      state.selectedContactForEditing = action.payload;
    },
    removedContribution: (state) => {
      state.contactToRemove = {} as Contact;
    },
    removeContributionFromStore: (state, action: PayloadAction<Contact>) => {
      state.mediaOutletContacts = state.mediaOutletContacts.filter((c) => c.id !== action.payload.id);
      state.mediaOutletContactsTotalCount -= 1;
    },
    undoRemoveContributionFromStore: (state, action: PayloadAction<Contact>) => {
      state.mediaOutletContacts = [...(state.mediaOutletContacts ?? []), action.payload];
      state.mediaOutletContactsTotalCount += 1;
    },
    addContributionToStore: (state, action: PayloadAction<Contact>) => {
      const newContact = new Contact(
        action.payload.id,
        action.payload.salutation,
        action.payload.firstName,
        action.payload.lastName,
        action.payload.profilePictureUrl,
        action.payload.isGloballySignedOut,
        action.payload.tags,
        action.payload.topics,
        action.payload.mediaResorts,
        action.payload.collaborations ?? [],
        action.payload.comments,
        action.payload.blacklists,
        action.payload.preferredLanguages,
        action.payload.ownContactData
      );

      state.mediaOutletContacts = [...(state.mediaOutletContacts ?? []), newContact];
      state.mediaOutletContactsTotalCount += 1;
    },
    undoAddContributionToStore: (state, action: PayloadAction<string>) => {
      state.mediaOutletContacts = state.mediaOutletContacts.filter((c) => c.id !== action.payload);
      state.collaborationToAdd = {} as Collaboration;
      state.selectedContact = {} as Contact;
      state.mediaOutletContactsTotalCount -= 1;
    },
    setContactToAdd: (state, action: PayloadAction<Contact>) => {
      state.contactToAdd = action.payload;
    },
    setCollaborationToAdd: (state, action: PayloadAction<Collaboration>) => {
      state.collaborationToAdd = action.payload;
    },
    addContactRequested: (state, action: PayloadAction<Contact>) => {
      state.contactToAdd = action.payload;
    },
    addedContact: (state, action: PayloadAction<Contact>) => {
      state.mediaOutletContacts = [...state.mediaOutletContacts, action.payload];
      state.contactToAdd = {} as Contact;
      state.collaborationToAdd = {} as Collaboration;
      state.ownContactDataToAdd = {} as OwnContactData;
    },
    setSelectedContact: (state, action: PayloadAction<string>) => {
      state.selectedContact = state.contacts.find((c) => c.id === action.payload);
    },
    addContributionRequested: (state, action: PayloadAction<AddCollaboration>) => {
      state.collaborationToAdd = action.payload.collaboration;
      state.selectedContact = action.payload.contact;
    },
    addedContribution: (state) => {
      state.collaborationToAdd = {} as Collaboration;
      state.selectedContact = {} as Contact;
      state.ownContactDataToAdd = {} as OwnContactData;
      state.isCollaborationsListOutdated = true;
    },
    pageOfContactsRequested: (state) => {
      state.isLoadingContacts = true;
      state.contactsTotalCount = 0;
    },
    pageOfContactsReceived: (state, action: PayloadAction<{ contacts: Contact[]; totalCountOfContacts: number }>) => {
      state.isLoadingContacts = false;
      state.contacts = action.payload.contacts.filter(
        (contact) => !state.mediaOutletContacts.find((mediaOutletContact) => mediaOutletContact.id === contact.id)
      );
      state.contactsTotalCount = action.payload.totalCountOfContacts;
    },
    setSearchContactsTextAndFilterItem: (state, action: PayloadAction<{ searchText: string; role: string }>) => {
      state.contactsSearchText = action.payload.searchText;
      state.contactsRoleFilter = action.payload.role;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.contactsPageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.contactsPageSize = action.payload;
      state.contactsPageNumber = 1;
    },
    setTotalCountOfContacts: (state, action: PayloadAction<number>) => {
      state.contactsTotalCount = action.payload;
    },
    setSelectedContactForEditing: (state, action: PayloadAction<Contact>) => {
      state.selectedContactForEditing = action.payload;
      state.selectedCollaborationForEditing = action.payload.collaborations.find((c) => c.medium.id === state.selectedMediaOutlet.id);
      state.contactToRemove = action.payload;
    },
    resetSelectedContactForEditing: (state) => {
      state.selectedContactForEditing = {} as Contact;
    },
    resetSelectedMediaOutlet: (state) => {
      state = initialState;
    },
    setIsRemoveMediaOutletDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.isRemoveMediaOutletDialogOpened = action.payload;
    },
    setIsTransformMediaOutletDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.isTransformMediaOutletDialogOpened = action.payload;
    },
    resetSelectedMediaOutletAfterUndo: (state) => {
      state.selectedMediaOutlet = cloneDeep(state.selectedMediaOutlet);
    },
    showMoreEditableFileds: (state) => {
      state.areMoreEditableFieldsVisible = true;
    },
    hideMoreEditableFileds: (state) => {
      state.areMoreEditableFieldsVisible = false;
    },
    openChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = true;
    },
    closeChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = false;
    },
    disableCreateContactFinishButton: (state) => {
      state.isCreateContactFinishButtonDisabled = true;
    },
    disableAddContactFinishButton: (state) => {
      state.isAddContactFinishButtonDisabled = true;
    },
    setOwnContactDataToAdd: (state, action: PayloadAction<OwnContactData>) => {
      state.ownContactDataToAdd = action.payload;
    },
    resetContactToAdd: (state) => {
      state.contactToAdd = {} as Contact;
      state.collaborationToAdd = {} as Collaboration;
      state.ownContactDataToAdd = {} as OwnContactData;
      state.selectedContact = {} as Contact;
    }
  }
});

export const {
  goToPreviousStep,
  goToNextStep,
  goToStep,
  goToPreviousStepCreateWizard,
  goToNextStepCreateWizard,
  goToStepCreateWizard,
  openAddContactWizard,
  closeAddContactWizard,
  openCreateContactWizard,
  closeCreateContactWizard,
  openProfilePanel,
  closeProfilePanel,
  openEditCollaborationWizard,
  closeEditCollaborationWizard,
  setSelectedMediaOutlet,
  resetMediaOutletCollaboration,
  getSelectedMediaOutletRequested,
  addCommentMentionRequested,
  addedCommentToMediaOutlet,
  addCommentToMediaOutletRequested,
  allTagsReceived,
  addedNewTag,
  undoAddedNewTag,
  addedTagToMediaOutlet,
  removedTagsFromMediaOutlet,
  updateProfilePictureRequested,
  profilePictureUpdated,
  removeProfilePicturePreview,
  changeProfilePictureToUpload,
  removeProfilePictureRequested,
  selectedMediaOutletReceived,
  mediaResortsRemoved,
  addMediaResortToMediaOutletRequested,
  addMediaResortToMediaOutletInStore,
  undoAddMediaResortToMediaOutletInStore,
  allMediaResortsReceived,
  addedMediaResortToMediaOutlet,
  addMediaResortAndAssignToMediaOutletRequested,
  getAllMediaResortsRequested,
  removeMediaResortFromMediaOutletRequested,
  removeMediaResortsRequested,
  updateMediaOutletRequested,
  updatedMediaOutlet,
  updateCollaborationRequested,
  updateCollaborationReceived,
  removeContributionRequested,
  removedContribution,
  removeContributionFromStore,
  undoRemoveContributionFromStore,
  addContributionToStore,
  undoAddContributionToStore,
  setCollaborationToAdd,
  setContactToAdd,
  addContactRequested,
  addedContact,
  addContributionRequested,
  addedContribution,
  setSelectedContact,
  setSelectedContactForEditing,
  resetSelectedContactForEditing,
  resetSelectedMediaOutlet,
  setIsRemoveMediaOutletDialogOpened,
  setIsTransformMediaOutletDialogOpened,
  resetSelectedMediaOutletAfterUndo,
  tagsDeleted,
  openRemoveCollaborationDialog,
  closeRemoveCollaborationDialog,
  showMoreEditableFileds,
  hideMoreEditableFileds,
  setTotalCountOfContacts,
  setTotalCountOfMediaOutletContacts,
  openChangeImageDialog,
  closeChangeImageDialog,
  disableCreateContactFinishButton,
  pageOfContactsReceived,
  pageOfContactsRequested,
  setPageNumber,
  setSearchContactsTextAndFilterItem,
  disableAddContactFinishButton,
  pageOfMediaOutletContactsReceived,
  pageOfMediaOutletContactsRequested,
  setMediaOutletContactsPageNumber,
  setSearchMediaOutletContactsTextAndFilterItem,
  setMediaOutletContactsPageSize,
  setPageSize,
  filterMediaResorts,
  setOwnContactDataToAdd,
  resetContactToAdd,
  addedNewMediaResort
} = mediaOutletProfileSlice.actions;

export default mediaOutletProfileSlice.reducer;
