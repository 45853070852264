import { isStringEmpty } from 'app/common';
import { SendingSetup, SendingSetupForm } from 'app/pages/my-activities/sending-wizard';
import { EmailBody, Sender, Sending } from 'app/pages/my-activities/sendings';
import { Blacklist } from 'app/pages/my-audience/common';

export const setSendingSetup = (data: SendingSetupForm, blacklists: Blacklist[], senders: Sender[]) => {
  const blacklist = data.blacklistValue && blacklists.find((bl) => bl.name === data.blacklistValue);
  const sender = data.senderEmailAddress && senders.find((s) => s.emailAddress === data.senderEmailAddress);
  return new SendingSetup(data.title, sender, data.senderDisplayName, data.respondToEmailAddress, blacklist, data.subject);
};

export const wizardHasNoData = (
  isOpenedFromTemplate: boolean,
  changeInEditorHappenedAfterOpenedFromTemplate: boolean,
  sendingSetup: SendingSetup,
  selectedSending: Sending
): boolean => {
  if (isOpenedFromTemplate) {
    return (
      sendingSetup &&
      Object.keys(sendingSetup.sender || {}).length === 0 &&
      isStringEmpty(sendingSetup.subject) &&
      isStringEmpty(sendingSetup.senderDisplayName) &&
      isStringEmpty(sendingSetup?.respondToEmailAddress) &&
      !changeInEditorHappenedAfterOpenedFromTemplate
    );
  }

  return (
    sendingSetup &&
    isStringEmpty(sendingSetup.title) &&
    Object.keys(sendingSetup.sender || {}).length === 0 &&
    isStringEmpty(sendingSetup.subject) &&
    isStringEmpty(sendingSetup.senderDisplayName) &&
    isStringEmpty(sendingSetup.respondToEmailAddress) &&
    Object.keys(selectedSending || {}).length === 0
  );
};

export const createSending = (sending: Sending, sendingSetup: SendingSetup, sendingBody: EmailBody): Sending => {
  return {
    ...sending,
    title: sendingSetup?.title || '',
    subject: sendingSetup?.subject || '',
    sender: sendingSetup?.sender ? sendingSetup.sender : null,
    senderEmailAddress: sendingSetup?.sender?.emailAddress || '',
    senderDisplayName: sendingSetup?.senderDisplayName || '',
    respondToEmailAddress: sendingSetup?.respondToEmailAddress || '',
    blacklist: sendingSetup?.blacklist ? sendingSetup.blacklist : null,
    jsonBody: sendingBody?.jsonBody || '',
    htmlBody: sendingBody?.htmlBody || ''
  };
};

export const updateSendingSetup = (sending: Sending, sendingSetup: SendingSetup) => {
  return {
    ...sending,
    title: sendingSetup?.title || '',
    subject: sendingSetup?.subject || '',
    sender: sendingSetup?.sender ? sendingSetup.sender : null,
    senderEmailAddress: sendingSetup?.sender?.emailAddress || '',
    senderDisplayName: sendingSetup?.senderDisplayName || '',
    respondToEmailAddress: sendingSetup?.respondToEmailAddress || '',
    blacklist: sendingSetup?.blacklist ? sendingSetup.blacklist : null
  };
};
