import BeePlugin from '@mailupinc/bee-plugin';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { selectBeeToken } from 'app/pages/my-activities/sending-wizard';
import { useAppSelector } from 'app/common';
import { selectLocale } from 'app/localization';
import { Template } from 'app/pages/my-activities/templates';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';

interface TemplatePreviewDialogProps {
  isDialogOpened: boolean;
  template: Template;
  dismissDialog: () => void;
  onChooseTemplate: (template: Template) => void;
}

export const TemplatePreviewDialog = ({ isDialogOpened, template, dismissDialog, onChooseTemplate }: TemplatePreviewDialogProps) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();
  const parsedJson = JSON.parse(template.jsonBody);
  const beeToken = useAppSelector<any>(selectBeeToken);
  const locale = useAppSelector<string>(selectLocale);

  const canEditTemplate = hasPermission(Permission.ManageEmailings);

  const dialogContentProps = {
    type: DialogType.normal
  };
  const dialogModalProps = {
    isBlocking: false,
    styles: {
      main: {
        minWidth: '1000px',
        minHeight: '700px',
        width: '90%'
      }
    }
  };

  const openSendingWizard = () => {
    dismissDialog();
    onChooseTemplate(template);
  };

  async function startBeeFreeAsync() {
    const bee = new BeePlugin(beeToken);
    await bee.start(
      {
        uid: 'augure-client',
        container: 'bee-plugin-container-preview',
        language: locale,
        loadingSpinnerDisableOnSave: true,
        trackChanges: true,
        onLoad: () => {
          bee.togglePreview();
          bee.togglePreview();
        },
        onTogglePreview: (toggled: boolean) => {
          if (!toggled) dismissDialog();
        }
      },
      parsedJson
    );
    bee.togglePreview();
  }

  startBeeFreeAsync().then();

  return (
    <Dialog minWidth={'90%'} hidden={!isDialogOpened} onDismiss={dismissDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div id="bee-plugin-container-preview"></div>
      <DialogFooter>
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={dismissDialog} />
        <Button
          content={formatMessage({ id: 'all-templates.choose' })}
          primary
          style={{ backgroundColor: 'rgb(88, 90, 150)', color: 'white' }}
          onClick={openSendingWizard}
          disabled={!canEditTemplate}
        />
      </DialogFooter>
    </Dialog>
  );
};
