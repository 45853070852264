import { useEffect, useState } from 'react';

export interface CommentContentProps {
  content: string;
  mentions: string[];
}

export const CommentContent: React.FC<CommentContentProps> = ({ content, mentions }) => {
  const [styledContent, setStyledContent] = useState(content);

  useEffect(() => {
    mentions.forEach((mention) => setStyledContent((content) => content.replace(`@${mention}`, `<b>@${mention}</b>`)));
  }, [mentions]);

  return <div dangerouslySetInnerHTML={{ __html: styledContent }} />;
};
