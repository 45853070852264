import { useMemo, useState } from 'react';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Icon,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import { Button, Checkbox } from '@fluentui/react-northstar';
import { EmptyMessage, useAppDispatch, useAppSelector } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { AadContact } from 'app/pages/my-audience/contacts';
import { removeContactFromCompanyInStore, selectContactsFromCompanyToAdd } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';

export const AddContactFromCompanyTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const contactsToAdd = useAppSelector<AadContact[]>(selectContactsFromCompanyToAdd);
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  const header = [
    { key: 'add-contact-key-name', name: formatMessage({ id: 'contact-data-labels.name' }), fieldName: 'name', isResizable: true, minWidth: 80, maxWidth: 100 },
    {
      key: 'add-contact-key-email',
      name: formatMessage({ id: 'contact-data-labels.email-address' }),
      fieldName: 'email',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-contact-data',
      name: formatMessage({ id: 'labels.contact-data' }),
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    { key: 'add-contact-key-hover-action', name: '', fieldName: 'hoverActions', isResizable: false, minWidth: 60, maxWidth: 60 }
  ];

  const handleClickOnRemoveButton = (contactId: string) => {
    dispatch(removeContactFromCompanyInStore(contactId));
  };

  const rows = [
    ...contactsToAdd.map((aadContact, index) => {
      return {
        key: `${index}`,
        name: `${aadContact.firstName} ${aadContact.lastName}`,
        email: aadContact?.email,
        contactData: aadContact?.defaultCompany?.name,
        hoverActions: (
          <>
            {hoverIndex === index && (
              <div className="table-actions">
                <Button text icon={<Icon iconName="Clear" />} onClick={() => handleClickOnRemoveButton(aadContact.objectId)} />
              </div>
            )}
          </>
        ),
        contact: aadContact
      };
    })
  ];

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        selectionMode: SelectionMode.multiple
      }),
    []
  );

  return (
    <div className="table-wrap table-wrap--wizard">
      {contactsToAdd.length > 0 ? (
        <div className="table-wrap table--list">
          <MarqueeSelection selection={selection} className="table" isDraggingConstrainedToRoot={true}>
            <ShimmeredDetailsList
              items={rows}
              enableShimmer={!rows}
              columns={header}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={formatMessage({ id: 'aria-labels.toggle-selection' })}
              ariaLabelForSelectAllCheckbox={formatMessage({ id: 'aria-labels.toggle-selection-for-all-items' })}
              checkButtonAriaLabel={formatMessage({ id: 'aria-labels.select-row' })}
              checkboxVisibility={1}
              onRenderRow={(rowProps: IDetailsRowProps) => (
                <div
                  onMouseEnter={() => {
                    setHoverIndex(rowProps.itemIndex);
                  }}
                >
                  <DetailsRow
                    key={rowProps.item.id}
                    {...rowProps}
                    onRenderCheck={() => (
                      <div className="row-header-wrap">
                        {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
                          <Checkbox checked={selection.getSelection().includes(rowProps.item)} />
                        ) : (
                          <ProfilePicture name={rowProps.item.name} imageUrl={rowProps.item.profilePictureUrl} size="small" />
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            />
          </MarqueeSelection>
        </div>
      ) : (
        <EmptyMessage
          srcValue="/wizard-empty-info.svg"
          textValueOptional={formatMessage({ id: 'no-contacts-selected-message.title' })}
          textValue={formatMessage({ id: 'no-contacts-selected-message.description' })}
        />
      )}
    </div>
  );
};
