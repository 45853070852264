import { MediumCategory, ensureHttpProtocol } from 'app/common';
import { Medium } from 'app/pages/my-audience/contact-profile';
import { Collaboration, Contact, JobTitle, OwnContactData, PhoneNumber, CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';
import { GeographicalCoverage, MediumContactData, MediaOutlet, LocalizedListValue } from 'app/pages/my-audience/media-outlets';
import {
  AddAddressInfoForm,
  AddGeneralMediaOutletInfoForm,
  MediaOutletAddressInformation,
  CollaborationForm,
  ContactForm,
  ContactInfo,
  ContactsTableData,
  MediaOutletGeneralInformation
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { AddressInfo } from 'app/pages/my-audience/wizard-create-company';

export const setMediaOutletGeneralInfo = (
  formData: AddGeneralMediaOutletInfoForm,
  audiences: LocalizedListValue[],
  categories: MediumCategory[],
  frequencies: LocalizedListValue[]
): MediaOutletGeneralInformation => {
  const audience = audiences.find((a) => a.label === formData.audience);
  const category = categories.find((c) => c.name === formData.category);
  const frequency = frequencies.find((f) => f.label === formData.frequency);

  return new MediaOutletGeneralInformation(formData.name, formData.description, audience?.key, category, frequency?.key);
};

export const setAddressInfo = (formData: AddAddressInfoForm): MediaOutletAddressInformation => {
  return new MediaOutletAddressInformation(
    formData.emailAddress,
    formData.phoneNumber,
    formData.address,
    formData.postalCode,
    formData.city,
    formData.poBox,
    formData.fax,
    ensureHttpProtocol(formData.website),
    formData.country
  );
};

export const setCollaboration = (formData: CollaborationForm, jobTitles: JobTitle[]): Collaboration => {
  const jobTitle = jobTitles.find((jt) => jt.name === formData.jobTitle);
  return new Collaboration(
    '',
    {} as Medium,
    jobTitle,
    formData.jobDescription,
    formData.address,
    formData.country,
    new PhoneNumber(formData.landlinePhoneNumber, false),
    new PhoneNumber(formData.mobilePhoneNumber, false),
    formData.emailAddress,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false,
    formData.city,
    formData.postalCode
  );
};

export const setContactInfo = (contactForm: ContactForm): ContactInfo => {
  return new ContactInfo(contactForm.title, contactForm.firstName, contactForm.lastName);
};

export const setContact = (contactInfo: ContactInfo): Contact => {
  return new Contact(
    '',
    contactInfo.title,
    contactInfo.firstName,
    contactInfo.lastName,
    '',
    false,
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    new OwnContactData('', '', null, null, '', '', '', '', '', '', '', '', true, '', '', '')
  );
};

export const createContactsTableData = (contacts: Contact[]) => {
  return contacts.map((contact) => {
    const primaryCollaboration = contact.collaborations.find((c) => c.isPrimary);
    const primaryContactData: Collaboration | OwnContactData = primaryCollaboration ? primaryCollaboration : contact.ownContactData;

    const jobTitle = primaryCollaboration?.jobTitle?.name ? primaryCollaboration.jobTitle.name : '/';
    return new ContactsTableData(
      contact.id,
      contact.id,
      `${contact.firstName} ${contact.lastName}`,
      contact.firstName,
      contact.lastName,
      jobTitle,
      primaryContactData?.emailAddress,
      primaryContactData?.landlinePhoneNumber?.value
    );
  });
};

export const createCollaborationDetails = (contact: ContactsTableData, addressInformation: MediaOutletAddressInformation | AddressInfo) => {
  return new CollaborationWithContactInfo(
    contact.id,
    contact.firstName,
    contact.lastName,
    {
      country: addressInformation.country,
      city: addressInformation.city,
      postalCode: addressInformation.postalCode
    } as Collaboration,
    false
  );
};

export const createMediaOutlet = (generalInfo: MediaOutletGeneralInformation, addressInfo: MediaOutletAddressInformation): MediaOutlet => {
  const contactData = new MediumContactData(
    addressInfo.address,
    addressInfo.poBox,
    addressInfo.postalCode,
    addressInfo.country,
    addressInfo.city,
    ensureHttpProtocol(addressInfo.websiteUrl),
    addressInfo.emailAddress,
    addressInfo.phoneNumber,
    addressInfo.fax,
    ''
  );
  return new MediaOutlet(
    '',
    generalInfo.name,
    '',
    generalInfo.description,
    generalInfo.audienceKey,
    generalInfo.category,
    generalInfo.frequencyKey,
    {} as GeographicalCoverage,
    [],
    [],
    [],
    contactData,
    [],
    [],
    [],
    [],
    '',
    '',
    '',
    '',
    0,
    0
  );
};
