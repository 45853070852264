import {
  ENABLE_TOOLTIP_CHART_OPTIONS,
  useAppSelector,
  CHART_PURPLE_COLOR,
  CHART_LIGHT_PURPLE_COLOR,
  CHART_GREY_COLOR,
  CHART_CUTOUT,
  getDurationTimeString
} from 'app/common';
import { SendingStatus } from 'app/pages/my-activities/sendings';
import { selectSelectedSendingOverview } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';
import { SendingOverview } from 'app/common/graphql/generated/graphql-gateway';
import { DoughnutWrapper } from 'app/common/components/doughnut/DoughnutWrapper';

export const Report = () => {
  const {
    sendingStatus,
    numberOfDeliveredRecipients,
    numberOfUnsubscribedRecipients,
    totalNumberOfFailedDeliveries,
    deliveredPercentage,
    openingsRatePercentage,
    totalNumberOfOpenings,
    numberOfOpenedSendings,
    clickRatePercentage,
    totalNumberOfClickedLinks,
    totalNumberOfRecipients,
    distributionDurationSeconds
  } = useAppSelector<SendingOverview>(selectSelectedSendingOverview);
  const { formatMessage } = useIntl();

  const getChartDataForSubscribedUnsubscribedFailedPercentage = (
    totalNumberOfSubscribedRecipients: number,
    totalNumberOfUnsubscribedRecipients: number,
    totalNumberOfFailedDeliveries: number
  ) => ({
    labels: [
      formatMessage({ id: 'sending-profile.report.overview-subscribed' }),
      formatMessage({ id: 'sending-profile.report.overview-unsubscribed' }),
      formatMessage({ id: 'sending-profile.report.overview-failed' })
    ],
    datasets: [
      {
        data: [totalNumberOfSubscribedRecipients, totalNumberOfUnsubscribedRecipients, totalNumberOfFailedDeliveries],
        backgroundColor: [CHART_PURPLE_COLOR, CHART_LIGHT_PURPLE_COLOR, CHART_GREY_COLOR],
        borderColor: [CHART_PURPLE_COLOR, CHART_LIGHT_PURPLE_COLOR, CHART_GREY_COLOR],
        borderWidth: 1,
        spacing: 0,
        cutout: CHART_CUTOUT
      }
    ]
  });

  return (
    <div>
      {sendingStatus === SendingStatus.SuccessfulDistribution && (
        <>
          <div className="profile-panel-chart-wrap">
            <div className="profile-panel-chart-column">
              <div className="profile-panel-chart">
                <DoughnutWrapper
                  data={getChartDataForSubscribedUnsubscribedFailedPercentage(
                    numberOfDeliveredRecipients - numberOfUnsubscribedRecipients,
                    numberOfUnsubscribedRecipients,
                    totalNumberOfFailedDeliveries
                  )}
                  options={ENABLE_TOOLTIP_CHART_OPTIONS}
                />
                <span className="profile-panel-chart-number">{deliveredPercentage}%</span>
              </div>
              <div className="profile-panel-chart-info">
                <span className="profile-panel-chart-title">{formatMessage({ id: 'sending-profile.report.overview-delivered' })}</span>
              </div>
            </div>
            <div className="profile-panel-chart-column">
              <div className="profile-panel-chart">
                <DoughnutWrapper percentage={openingsRatePercentage} />
                <span className="profile-panel-chart-number">{openingsRatePercentage}%</span>
              </div>
              <div className="profile-panel-chart-info">
                <span className="profile-panel-chart-title">{formatMessage({ id: 'sending-profile.report.overview-open-rate' })}</span>
              </div>
            </div>
            <div className="profile-panel-chart-column">
              <div className="profile-panel-chart">
                <DoughnutWrapper percentage={clickRatePercentage} />
                <span className="profile-panel-chart-number">{clickRatePercentage}%</span>
              </div>
              <div className="profile-panel-chart-info">
                <span className="profile-panel-chart-title">{formatMessage({ id: 'sending-profile.report.overview-click-rate' })}</span>
              </div>
            </div>
          </div>
          <div className="profile-panel-chart-titles">
            <p className="profile-panel-chart__title">{formatMessage({ id: 'sending-profile.report.overview-total-recipient' })}</p>
            <p className="profile-panel-chart__title">{formatMessage({ id: 'sending-profile.report.overview-total-open' })}</p>
            <p className="profile-panel-chart__title">{formatMessage({ id: 'sending-profile.report.overview-total-click' })}</p>
          </div>
          <div className="profile-panel-chart-wrap">
            <div className="profile-panel-chart__top">
              <div className="profile-panel-chart-column">
                <div className="profile-panel-chart-info">
                  <span className="profile-panel-chart-rate-number">{totalNumberOfRecipients}</span>
                  <span className="profile-panel-chart-title">{formatMessage({ id: 'sending-profile.report.overview-recip' })}</span>
                </div>
              </div>
            </div>
            <div className="profile-panel-chart-top">
              <div className="profile-panel-chart-column">
                <div className="profile-panel-chart-info">
                  <span className="profile-panel-chart-rate-number">{totalNumberOfOpenings}</span>
                  <span className="profile-panel-chart-title">{formatMessage({ id: 'sending-profile.report.overview-opening' })}</span>
                </div>
              </div>
            </div>
            <div className="profile-panel-chart-top">
              <div className="profile-panel-chart-column">
                <div className="profile-panel-chart-info">
                  <span className="profile-panel-chart-rate-number">{totalNumberOfClickedLinks}</span>
                  <span className="profile-panel-chart-title">{formatMessage({ id: 'sending-profile.report.overview-click' })}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="profile-panel-chart-wrap profile-panel-chart-table-wrap">
            <div className="profile-panel-chart-table">
              <div className="profile-panel-chart-row grey">
                <div className="profile-panel-chart-column">
                  <span className="profile-panel-chart-info-title">{formatMessage({ id: 'sending-profile.report.overview-failed' })}</span>
                  <span className="profile-panel-chart-info-number">{totalNumberOfFailedDeliveries}</span>
                </div>
                <div className="profile-panel-chart-column">
                  <span className="profile-panel-chart-info-title">{formatMessage({ id: 'sending-profile.report.overview-unsubscriptions' })}</span>
                  <span className="profile-panel-chart-info-number">{numberOfUnsubscribedRecipients}</span>
                </div>
              </div>
              <div className="profile-panel-chart-row">
                <div className="profile-panel-chart-column">
                  <span className="profile-panel-chart-info-title">{formatMessage({ id: 'sending-profile.report.overview-number-distinct' })}</span>
                  <span className="profile-panel-chart-info-number">{numberOfOpenedSendings}</span>
                </div>
                <div className="profile-panel-chart-column">
                  <span className="profile-panel-chart-info-title">{formatMessage({ id: 'sending-profile.report.overview-duration' })}</span>
                  <span className="profile-panel-chart-info-number">{getDurationTimeString(distributionDurationSeconds)}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
