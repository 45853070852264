import { DialogType, Dialog, DialogFooter } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { closeDeleteRecipientDialog, selectIsDeleteRecipientDialogOpen } from 'app/pages/my-activities/sending-wizard';

interface DeleteRecipientDialogProps {
  removeSelectedRecipients(): void;
}

export const DeleteRecipientDialog: React.FC<DeleteRecipientDialogProps> = ({ removeSelectedRecipients }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const isDeleteDialogOpened = useAppSelector<boolean>(selectIsDeleteRecipientDialogOpen);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'sending-profile.delete-dialog.title' })
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const onRemoveHandler = () => {
    removeSelectedRecipients();
    dispatch(closeDeleteRecipientDialog());
  };

  const closeDialog = () => {
    dispatch(closeDeleteRecipientDialog());
  };

  return (
    <Dialog hidden={!isDeleteDialogOpened} onDismiss={closeDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="profile-photo-dialog">
        <DialogFooter>
          <Button tinted onClick={closeDialog} content={formatMessage({ id: 'buttons.cancel' })} />
          <Button primary onClick={onRemoveHandler} content={formatMessage({ id: 'buttons.remove' })} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
