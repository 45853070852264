import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { isTextInputValid, maximumMediaOutletNameLength, regAtLeastOneCharacter, useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { ControlledDropdown, ControlledInput, ControlledMultipleDropdown, ControlledTextAreaInput } from 'app/pages/my-audience/common';
import {
  CreateMediaOutletGeneralInfoForm,
  languages,
  MediaOutletGeneralInfo,
  selectMediaOutletGeneralInfoToAdd,
  setIsCreateMediaOutletGeneralInfoStepValid,
  setMediaOutletGeneralInfo,
  setMediaOutletGeneralInfoToAdd
} from 'app/pages/my-audience/contact-profile';
import { LocalizedListValue } from 'app/pages/my-audience/media-outlets';
import { FormattedMessage, useIntl } from 'app/i18n';
import { selectLocalizedAudiences, selectLocalizedFrequencies, selectLocalizedMediumSupportTypes } from 'app/localization';

export const CreateMediaOutletGeneralInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const mediaOutletGeneralInfo = useAppSelector<MediaOutletGeneralInfo>(selectMediaOutletGeneralInfoToAdd);
  const localizedAudiences = useAppSelector<LocalizedListValue[]>(selectLocalizedAudiences);
  const localizedFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);
  const localizedMediumSupportTypes = useAppSelector<LocalizedListValue[]>(selectLocalizedMediumSupportTypes);

  const selectedAudience = localizedAudiences.find((a) => a.key === mediaOutletGeneralInfo.audienceKey)?.label;
  const selectedFrequency = localizedFrequencies.find((a) => a.key === mediaOutletGeneralInfo.frequencyKey)?.label;
  const selectedMediumSupportTypes = mediaOutletGeneralInfo?.mediaSupportTypesKeys?.length
    ? localizedMediumSupportTypes.filter((mst) => mediaOutletGeneralInfo.mediaSupportTypesKeys.includes(mst.key)).map((mst) => mst.label)
    : [];

  const { handleSubmit, control, watch } = useForm<CreateMediaOutletGeneralInfoForm, any>({
    defaultValues: {
      name: mediaOutletGeneralInfo.name,
      description: mediaOutletGeneralInfo.description,
      audienceValue: selectedAudience,
      websiteUrl: mediaOutletGeneralInfo.websiteUrl,
      frequencyValue: selectedFrequency,
      comment: mediaOutletGeneralInfo.comment,
      mediaSupportTypesValues: selectedMediumSupportTypes,
      preferredLanguages: mediaOutletGeneralInfo.preferredLanguages
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const watchName = watch('name');
  const isCreateMediaOutletGeneralInfoStepValid = isTextInputValid({ inputValue: watchName, maxInputLength: maximumMediaOutletNameLength });

  useEffect(() => {
    dispatch(setIsCreateMediaOutletGeneralInfoStepValid(isCreateMediaOutletGeneralInfoStepValid));
  }, [dispatch, isCreateMediaOutletGeneralInfoStepValid]);

  const submitForm = useMemo(
    () =>
      handleSubmit(
        (data) => {
          const newMediaOutletProfileInfo: MediaOutletGeneralInfo = setMediaOutletGeneralInfo(
            data,
            localizedAudiences,
            localizedFrequencies,
            localizedMediumSupportTypes
          );
          dispatch(setMediaOutletGeneralInfoToAdd(newMediaOutletProfileInfo));
        },
        (error) => {
          Sentry.setExtra('Path', CreateMediaOutletGeneralInfo.name);
          Sentry.captureException(error);
        }
      ),
    [dispatch, handleSubmit, localizedAudiences, localizedFrequencies, localizedMediumSupportTypes]
  );

  useEffect(() => {
    return () => {
      submitForm();
    };
  }, [submitForm]);

  return (
    <div className="wizard-panel-form-wrap">
      <WizardIntro title={formatMessage({ id: 'media-outlet-labels.mo-general-title' })} description="" />
      <div className="wizard-panel-form">
        <div className="wizard-panel-input">
          <ControlledInput
            label={
              <span className="required-label">
                <FormattedMessage id={'media-outlet-labels.name'} />
                <span>*</span>
              </span>
            }
            control={control}
            name="name"
            rules={{
              pattern: {
                value: regAtLeastOneCharacter,
                message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: formatMessage({ id: 'labels.media-outlet' }) })
              },
              maxLength: {
                message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumMediaOutletNameLength }),
                value: maximumMediaOutletNameLength
              },
              required: formatMessage({ id: 'error-messages.required-name' }, { entity: formatMessage({ id: 'labels.media-outlet' }) })
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'media-outlet-labels.description' })} control={control} name="description" />
        </div>
        <div className="wizard-panel-dropdown">
          <ControlledDropdown
            label={
              <span className="dropdown-label">
                <FormattedMessage id={'media-outlet-labels.audiences'} />
              </span>
            }
            name="audienceValue"
            items={localizedAudiences.map((a) => a.label)}
            control={control}
            placeholder={formatMessage({ id: 'dropdown-placeholders.audiences' })}
            defaultValue={selectedAudience}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput name="websiteUrl" label={formatMessage({ id: 'media-outlet-labels.website' })} control={control} />
        </div>
        <div className="wizard-panel-dropdown">
          <ControlledDropdown
            label={
              <span className="dropdown-label">
                <FormattedMessage id={'media-outlet-labels.frequency'} />
              </span>
            }
            name="frequencyValue"
            items={localizedFrequencies.map((a) => a.label)}
            control={control}
            placeholder={formatMessage({ id: 'dropdown-placeholders.frequency' })}
            defaultValue={selectedFrequency}
          />
        </div>
        <div className="wizard-panel-dropdown">
          <ControlledMultipleDropdown
            label={
              <span className="dropdown-label">
                <FormattedMessage id={'media-outlet-labels.media-support-types'} />
              </span>
            }
            name="mediaSupportTypesValues"
            items={localizedMediumSupportTypes.map((a) => a.label)}
            control={control}
            placeholder={formatMessage({ id: 'dropdown-placeholders.media-support-types' })}
            defaultValues={selectedMediumSupportTypes}
          />
        </div>
        <div className="wizard-panel-dropdown">
          <ControlledMultipleDropdown
            label={
              <span className="dropdown-label">
                <FormattedMessage id={'media-outlet-labels.preferred-languages'} />
              </span>
            }
            name="preferredLanguages"
            items={languages.map((item) => item.language)}
            control={control}
            placeholder={formatMessage({ id: 'dropdown-placeholders.preferred-languages' })}
            defaultValues={mediaOutletGeneralInfo.preferredLanguages}
          />
        </div>
        <div className="wizard-panel-textarea">
          <ControlledTextAreaInput name="comment" label={formatMessage({ id: 'media-outlet-labels.comment' })} control={control} />
        </div>
      </div>
    </div>
  );
};
