import { Button } from '@fluentui/react-northstar';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { showAlertNotification, useAppDispatch } from 'app/common';
import { useIntl } from 'app/i18n';

export interface DeleteSavedSearchDialogProps {
  opened: boolean;
  removeSavedSearch: () => void;
  close: () => void;
}

export const DeleteSavedSearchDialog = ({ opened, removeSavedSearch, close }: DeleteSavedSearchDialogProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'saved-search-labels.delete-title' }),
    subText: formatMessage({ id: 'saved-search-labels.delete-text' })
  };

  const onDeleteSavedSearch = () => {
    removeSavedSearch();
    close();
    dispatch(showAlertNotification(formatMessage({ id: 'saved-search-labels.success-text' })));
  };

  return (
    <Dialog hidden={!opened} onDismiss={() => close()} dialogContentProps={dialogContentProps}>
      <div className="profile-photo-dialog">
        <DialogFooter>
          <Button primary content={formatMessage({ id: 'buttons.yes' })} onClick={onDeleteSavedSearch} />
          <Button content={formatMessage({ id: 'buttons.no' })} onClick={() => close()} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
