import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pages, teamsCore } from '@microsoft/teams-js';

import { setAudienceCurrentUrl, setActivitiesCurrentUrl } from 'app/common';
import { useAppDispatch } from 'app/common/hooks';

interface Props {
  inTeams: boolean;
}

export const BackButtonHandler = ({ inTeams }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!inTeams) return;

    pages.backStack.registerBackButtonHandler(() => {
      // eslint-disable-next-line no-restricted-globals
      if (history.state.idx === 1) {
        pages.backStack.navigateBack().then();
        return false;
      }
      navigate(-1);
      return true;
    });
  }, [inTeams, navigate]);

  useEffect(() => {
    if (!inTeams) return;

    teamsCore.registerBeforeUnloadHandler(() => {
      if (location.pathname.includes('my-audience')) {
        dispatch(setAudienceCurrentUrl(location.pathname));
      } else {
        dispatch(setActivitiesCurrentUrl(location.pathname));
      }

      return true;
    });
  }, [inTeams, location, dispatch]);

  return <></>;
};
