import { Shimmer, ShimmerElementType } from '@fluentui/react';

export const SuggestionsShimmer = () => {
  const shimmerWithElementFirstRow = [{ type: ShimmerElementType.circle }, { type: ShimmerElementType.gap, width: '2%' }, { type: ShimmerElementType.line }];
  return (
    <>
      <Shimmer shimmerElements={shimmerWithElementFirstRow} />
      <Shimmer shimmerElements={shimmerWithElementFirstRow} />
      <Shimmer shimmerElements={shimmerWithElementFirstRow} />
    </>
  );
};
