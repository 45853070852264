import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { MessageData, AvatarDetailsInput, AvatarDetailsWithSavedSearchId, FilterItem, safe, setAlertData } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { selectFilterItems as selectFilterItemsDtoFromCompanies, selectSearchText as selectSearchTextFromCompanies } from 'app/pages/my-audience/companies';
import {
  addCompanySavedSearch,
  addCompanySavedSearchRequested,
  companiesSavedSearchesReceived,
  companiesSavedSearchesRequested,
  companySavedSearchAdded,
  fetchCompaniesAvatarDetails,
  fetchCompanySavedSearchesPaged,
  firstPageOfSavedSearchesReceived,
  firstPageOfSavedSearchesRequested,
  loadNextCompanySavedSearchAfterDeleteRequested,
  removeCompanySavedSearch,
  removeCompanySavedSearchRequested,
  removedCompanySavedSearch,
  selectCompanySavedSearches,
  selectCompanySavedSearchesToUpdate,
  selectCompanySavedSearchToRemoveId,
  selectCompanySavedSearchToUpdate,
  selectFilterItem,
  selectPageNumber,
  selectPageSize,
  selectSearchText,
  selectTotalCountOfSavedSearches,
  setTotalCountOfSavedSearches,
  updateCompanySavedSearch,
  updateCompanySavedSearches,
  updateCompanySavedSearchesRequested,
  updateCompanySavedSearchRequested,
  updatedCompanySavedSearch,
  updatedCompanySavedSearches
} from 'app/pages/my-audience/companies-saved-searches';
import { PageOfSavedSearches, SavedSearch, SavedSearchInput, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';

export function* fetchPagedCompanySavedSearchesWatcher() {
  yield takeLatest(firstPageOfSavedSearchesRequested.type, safe(fetchPagedCompanySavedSearchesFlow));
  yield takeLatest(companiesSavedSearchesRequested.type, safe(fetchPagedCompanySavedSearchesFlow));
}

function* fetchPagedCompanySavedSearchesFlow() {
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);
  const searchText: string = yield select(selectSearchText);
  const filterItem: FilterItem = yield select(selectFilterItem);

  const skip = (pageNumber - 1) * pageSize;

  const pageOfSavedSearches: PageOfSavedSearches = yield call(fetchCompanySavedSearchesPaged, client, skip, pageSize, searchText, [filterItem]);

  const savedSearches = pageOfSavedSearches.savedSearches;
  const companiesAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const companiesAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchCompaniesAvatarDetails, client, companiesAvatarDetailsInputs);

  companiesAvatarDetails.forEach((companyAvatarDetails) => {
    const savedSearch: SavedSearch = savedSearches.find((s) => s.id === companyAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = companyAvatarDetails.avatarDetails;
    savedSearch.resultsLength = companyAvatarDetails.totalCount;
  });

  if (pageNumber === 1) {
    yield put(firstPageOfSavedSearchesReceived(savedSearches));
  } else {
    yield put(companiesSavedSearchesReceived(savedSearches));
  }
  yield put(setTotalCountOfSavedSearches(pageOfSavedSearches.totalCount));
}

export function* addCompanySavedSearchRequestedWatcher() {
  yield takeEvery(addCompanySavedSearchRequested.type, safe(addCompanySavedSearchFlow));
}

function* addCompanySavedSearchFlow() {
  const searchText: string = yield select(selectSearchTextFromCompanies);
  const filterItems: FilterItem[] = yield select(selectFilterItemsDtoFromCompanies);

  const savedSearchToAdd = new SavedSearchInput(searchText || '', searchText || '*', filterItems);

  const savedSearch = yield call(addCompanySavedSearch, client, savedSearchToAdd);
  const savedSearches = [savedSearch];

  const companiesAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const companiesAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchCompaniesAvatarDetails, client, companiesAvatarDetailsInputs);

  companiesAvatarDetails.forEach((companyAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === companyAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = companyAvatarDetails.avatarDetails;
    savedSearch.resultsLength = companyAvatarDetails.totalCount;
  });

  yield put(companySavedSearchAdded(savedSearch));
  yield put(setAlertData(new MessageData('alert-saved-search-created')));
}

export function* updateCompanySavedSearchRequestWatcher() {
  yield takeEvery(updateCompanySavedSearchRequested.type, safe(updateCompanySavedSearchFlow));
}

function* updateCompanySavedSearchFlow() {
  const savedSearchToUpdate: SavedSearch = yield select(selectCompanySavedSearchToUpdate);
  const savedSearchInput = new SavedSearchInput(savedSearchToUpdate.name, savedSearchToUpdate.searchText, savedSearchToUpdate.filterItems);

  const savedSearch: SavedSearch = yield call(updateCompanySavedSearch, client, savedSearchToUpdate.id, savedSearchInput);
  const savedSearches = [savedSearch];

  const companiesAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const companiesAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchCompaniesAvatarDetails, client, companiesAvatarDetailsInputs);

  companiesAvatarDetails.forEach((companyAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === companyAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = companyAvatarDetails.avatarDetails;
    savedSearch.resultsLength = companyAvatarDetails.totalCount;
  });

  yield put(updatedCompanySavedSearch(savedSearch));
  yield put(setAlertData(new MessageData('alert-saved-search-updated')));
}

export function* updateCompanySavedSearchesRequestWatcher() {
  yield takeEvery(updateCompanySavedSearchesRequested.type, safe(updateCompanySavedSearchesFlow));
}

function* updateCompanySavedSearchesFlow() {
  const savedSearchesToUpdate: SavedSearchWithIdInput[] = yield select(selectCompanySavedSearchesToUpdate);

  const savedSearches: SavedSearch[] = yield call(updateCompanySavedSearches, client, savedSearchesToUpdate);

  const companiesAvatarDetailsInputs: AvatarDetailsInput[] = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const companiesAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchCompaniesAvatarDetails, client, companiesAvatarDetailsInputs);

  companiesAvatarDetails.forEach((companyAvatarDetails) => {
    const savedSearch: SavedSearch = savedSearches.find((s) => s.id === companyAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = companyAvatarDetails.avatarDetails;
    savedSearch.resultsLength = companyAvatarDetails.totalCount;
  });

  yield put(updatedCompanySavedSearches(savedSearches));
}

export function* removeCompanySavedSearchRequestWatcher() {
  yield takeEvery(removeCompanySavedSearchRequested.type, safe(removeCompanySavedSearchFlow));
}

function* removeCompanySavedSearchFlow() {
  const savedSearchToRemoveId: string = yield select(selectCompanySavedSearchToRemoveId);
  const totalSavedSearchesCount: number = yield select(selectTotalCountOfSavedSearches);
  const currentSaveSearches: SavedSearch[] = yield select(selectCompanySavedSearches);

  const shouldLoadNextCompanySavedSearchAfterDelete = currentSaveSearches.length < totalSavedSearchesCount;

  const savedSearch: SavedSearch = yield call(removeCompanySavedSearch, client, savedSearchToRemoveId);
  yield put(removedCompanySavedSearch(savedSearch.id));

  if (shouldLoadNextCompanySavedSearchAfterDelete) yield put(loadNextCompanySavedSearchAfterDeleteRequested());
}

function* loadNextCompanySavedSearchAfterDeleteFlow() {
  const searchText: string = yield select(selectSearchText);
  const filterItem: FilterItem = yield select(selectFilterItem);
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);

  const skip = pageNumber * pageSize - 1;
  const pageOfSavedSearches: PageOfSavedSearches = yield call(fetchCompanySavedSearchesPaged, client, skip, 1, searchText, [filterItem]);

  const savedSearches = pageOfSavedSearches.savedSearches;
  const companiesAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const companiesAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchCompaniesAvatarDetails, client, companiesAvatarDetailsInputs);

  companiesAvatarDetails.forEach((companyAvatarDetails) => {
    const savedSearch: SavedSearch = savedSearches.find((s) => s.id === companyAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = companyAvatarDetails.avatarDetails;
    savedSearch.resultsLength = companyAvatarDetails.totalCount;
  });

  yield put(companiesSavedSearchesReceived(savedSearches));
}

export function* loadNextCompanySavedSearchAfterDeleteRequestWatcher() {
  yield takeEvery(loadNextCompanySavedSearchAfterDeleteRequested.type, safe(loadNextCompanySavedSearchAfterDeleteFlow));
}
