import { isTextInputValid, maximumListNameLength, EditableInputTextField, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { List } from 'app/pages/my-audience/lists';
import { selectSelectedList, updateListRequested } from 'app/pages/my-audience/lists-profile';

interface ListPanelIntroInfoProps {
  disabled?: boolean;
}

export const ListPanelIntroInfo = ({ disabled = false }: ListPanelIntroInfoProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedList = useAppSelector<List>(selectSelectedList);

  const onSaveChanges = (fieldName, updatedValue) => {
    const updatedList = { ...selectedList, [fieldName]: updatedValue };
    dispatch(updateListRequested(updatedList));
  };

  const defaultListDescription = formatMessage({ id: 'list-profile-panel.default-msg' });

  return (
    <div className="panel-intro-info">
      <div className="panel-intro-title">
        <EditableInputTextField
          value={selectedList.name}
          fieldName="name"
          onChange={(fieldName, updatedValue) => onSaveChanges(fieldName, updatedValue)}
          isLink={false}
          isFieldValid={(value: string) => isTextInputValid({ inputValue: value, maxInputLength: maximumListNameLength })}
          disabled={disabled}
        />
      </div>

      <div className="panel-info-description">
        <EditableInputTextField
          value={selectedList.description}
          fieldName="description"
          onChange={(fieldName, updatedValue) => onSaveChanges(fieldName, updatedValue)}
          isLink={false}
          disabled={disabled}
          defaultValueIfEmpty={defaultListDescription}
          maximumNameLength={defaultListDescription.length}
        />
      </div>
    </div>
  );
};
