import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { Panel } from '@fluentui/react';
import { Accordion, Button, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import {
  ControlledFilterDropDown,
  FilterItem,
  FilterItemType,
  selectIsOnMobile,
  useAppDispatch,
  useAppSelector,
  useControlledFilterDropDown,
  useResetFilterQueriesOnPanelClose
} from 'app/common';
import {
  clearAllFilters,
  firstPageOfListsRequested,
  selectFilterItems,
  selectIsFilterPanelOpen,
  closeFiltersPanel,
  addListsFilter,
  removeListsFilter,
  listNamesSuggestionsRequested,
  startLoadingNameSuggestions,
  startLoadingContactSuggestions,
  contactsSuggestionsRequested,
  mediumsSuggestionsRequested,
  startLoadingMediumSuggestions,
  tagsSuggestionsRequested,
  startLoadingTagsSuggestions,
  createdByContactsSuggestionsRequested,
  startLoadingCreatedBySuggestions,
  selectListNameFilterSuggestions,
  selectIsLoadingNameSuggestions,
  selectIsLoadingContactSuggestions,
  selectListContactFilterSuggestions,
  selectListMediumFilterSuggestions,
  selectIsLoadingMediumSuggestions,
  selectListTagsFilterSuggestions,
  selectIsLoadingTagsSuggestions,
  selectListCreatedByFilterSuggestions,
  selectIsLoadingCreatedBySuggestions
} from 'app/pages/my-audience/lists';
import { CreatedAtFilter } from 'app/pages/my-audience/lists-filter-search/CreatedAtFilter';
import { LIST_FILTER } from 'app/pages/my-audience/lists-filter-search/filter-fields';
import { useIntl } from 'app/i18n';

export const ListFiltersPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const filterItems = useAppSelector(selectFilterItems);
  const isOnMobile = useAppSelector(selectIsOnMobile);
  const isFiltersPanelOpened = useAppSelector(selectIsFilterPanelOpen);

  const nameControl = useControlledFilterDropDown(listNamesSuggestionsRequested, startLoadingNameSuggestions);
  const contactNameControl = useControlledFilterDropDown(contactsSuggestionsRequested, startLoadingContactSuggestions);
  const mediumControl = useControlledFilterDropDown(mediumsSuggestionsRequested, startLoadingMediumSuggestions);
  const tagNameControl = useControlledFilterDropDown(tagsSuggestionsRequested, startLoadingTagsSuggestions);
  const createdByControl = useControlledFilterDropDown(createdByContactsSuggestionsRequested, startLoadingCreatedBySuggestions);

  const { resetQueries } = useResetFilterQueriesOnPanelClose(
    [nameControl, contactNameControl, mediumControl, tagNameControl, createdByControl],
    isFiltersPanelOpened
  );

  const handleDismissFilterPanel = () => {
    dispatch(closeFiltersPanel());
    navigate(-1);
  };

  const handleFilterAdd = ({ fieldName, value }: FilterItemType) => {
    dispatch(addListsFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfListsRequested());
  };

  const handleFilterRemoval = ({ fieldName, value }: FilterItemType) => {
    dispatch(removeListsFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfListsRequested());
  };
  const handleClearAll = () => {
    dispatch(clearAllFilters());
    dispatch(firstPageOfListsRequested());

    resetQueries();
  };

  useOnClickOutside(ref, handleDismissFilterPanel);

  const filters = [
    {
      key: 'item-list-names',
      name: LIST_FILTER.NAME,
      displayName: formatMessage({ id: 'filters.names' }),
      placeholder: formatMessage({ id: 'filters.filter-by-name' }),
      suggestionsSelector: selectListNameFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingNameSuggestions,
      control: nameControl
    },
    {
      key: 'item-list-contacts-names',
      name: LIST_FILTER.CONTACT,
      displayName: formatMessage({ id: 'filters.contacts' }),
      placeholder: formatMessage({ id: 'filters.filter-by-contact-name' }),
      suggestionsSelector: selectListContactFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingContactSuggestions,
      control: contactNameControl
    },
    {
      key: 'item-list-mediums-names',
      name: LIST_FILTER.MEDIUM,
      displayName: formatMessage({ id: 'filters.mediums' }),
      placeholder: formatMessage({ id: 'filters.filter-by-medium' }),
      suggestionsSelector: selectListMediumFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingMediumSuggestions,
      control: mediumControl
    },
    {
      key: 'item-list-tags-names',
      name: LIST_FILTER.TAG,
      displayName: formatMessage({ id: 'filters.tags' }),
      placeholder: formatMessage({ id: 'filters.filter-by-tag' }),
      suggestionsSelector: selectListTagsFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingTagsSuggestions,
      control: tagNameControl
    },
    {
      key: 'item-list-created-by-names',
      name: LIST_FILTER.CREATED_BY,
      displayName: formatMessage({ id: 'filters.created-by' }),
      placeholder: formatMessage({ id: 'filters.start-typing' }),
      suggestionsSelector: selectListCreatedByFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingCreatedBySuggestions,
      control: createdByControl
    }
  ];

  const panels = [
    ...filters.map(({ key, name, displayName, placeholder, control, suggestionsSelector, isLoadingSuggestionsSelector }) => ({
      title: (
        <div className="accordion-head-box" key={`${key}-title`}>
          <span className="accordion-heading">{displayName}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: `${key}-content`,
        content: (
          <ControlledFilterDropDown
            name={name}
            placeholder={placeholder}
            onFilterAdd={handleFilterAdd}
            onFilterRemoval={handleFilterRemoval}
            suggestionsSelector={suggestionsSelector}
            isLoadingSuggestionsSelector={isLoadingSuggestionsSelector}
            items={filterItems.filter((filterItem) => filterItem.fieldName === name)}
            {...control}
          />
        )
      },
      className: 'filter-accordion-item'
    })),
    {
      title: (
        <div className="accordion-head-box" key="item-list-created-at-names-title">
          <span className="accordion-heading">{formatMessage({ id: 'filters.created-at' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'item-list-created-at-names-title-content',
        content: <CreatedAtFilter />
      },
      className: 'filter-accordion-item'
    }
  ];

  return (
    <div ref={ref}>
      <Panel
        isBlocking={false}
        customWidth="340px"
        className="filter-panel"
        headerText={formatMessage({ id: 'filters.filters' })}
        isOpen={isFiltersPanelOpened}
        onDismiss={handleDismissFilterPanel}
      >
        <div className="filter-panel-accordions">
          <Accordion panels={panels} />
        </div>
        <div className="filter-panel-actions-wrap">
          {filterItems.length > 0 && (
            <div className="filter-panel-actions-holder">
              <div className="filter-panel-actions">
                <Button className="filter-panel-actions-clear" text content={formatMessage({ id: 'filters.clear' })} onClick={handleClearAll} />
              </div>
              {isOnMobile && (
                <div className="filter-panel-actions">
                  <Button className="filter-panel-actions-apply" primary content={formatMessage({ id: 'buttons.apply' })} onClick={handleDismissFilterPanel} />
                </div>
              )}
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};
