import { PillData, useAppDispatch, useAppSelector, Pills } from 'app/common';
import { deleteTopicsRequested, filterTopics, selectFilteredTopics } from 'app/pages/my-audience/contact-profile';
import { addTopicForBulkUpdate, createTopicRequested, removeTopicForBulkUpdate, selectTopicsToAddToContact, Topic } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const BulkUpdateTopics = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const allTopics = useAppSelector<Topic[]>(selectFilteredTopics);
  const topicsToAddToContact = useAppSelector<Topic[]>(selectTopicsToAddToContact);

  const handleCreateTopic = (topicName: string) => {
    const newTopic = new Topic('', topicName, '');

    dispatch(createTopicRequested(newTopic));
  };

  const handleSelectTopic = (topicId: string) => {
    const topicToAdd = allTopics.find((t) => t.id === topicId);

    dispatch(addTopicForBulkUpdate(topicToAdd));
  };

  const handleDeselectTopic = (topicId: string) => {
    const topicToRemove = topicsToAddToContact.find((t) => t.id === topicId);

    dispatch(removeTopicForBulkUpdate(topicToRemove));
  };

  const handleDeleteTopics = (topicsIds: string[]) => {
    const topicsToDelete = allTopics.filter((topic) => topicsIds.includes(topic.id));

    dispatch(deleteTopicsRequested(topicsToDelete));
  };

  const handleFilterTopics = (searchText: string) => {
    dispatch(filterTopics(searchText));
  };

  return (
    <Pills
      entityName={formatMessage({ id: 'labels.topic' })}
      selectedValues={topicsToAddToContact.map((t) => new PillData(t.id, t.name, true))}
      dropdownValues={allTopics.map((t) => new PillData(t.id, t.name, true))}
      onAddValue={handleCreateTopic}
      onSelectValue={handleSelectTopic}
      onDeselectValue={handleDeselectTopic}
      onDeleteValues={handleDeleteTopics}
      filterValues={handleFilterTopics}
      enabledDelete={true}
      disabled={false}
    />
  );
};
