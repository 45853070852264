interface WizardEmptyInfoProps {
  message: string;
  description?: string;
}

export const WizardEmptyInfo = ({ message, description }: WizardEmptyInfoProps) => {
  return (
    <div className="wizard-empty-info">
      <div className="wizard-empty-info-image">
        <img src="/wizard-empty-info2.svg" alt="Empty Message Notification" />
      </div>
      <div className="wizard-empty-info-text">
        <span className="wizard-empty-info-text text-bold">{message}</span>
        <span className="wizard-empty-info-text">{description}</span>
      </div>
    </div>
  );
};
