import { DateTime, Duration, Interval } from 'luxon';

export type TimeFrameSingular = 'day' | 'week' | 'month' | 'year';
export type TimeFramePlural = 'days' | 'weeks' | 'months' | 'years';

export type FilterItemType = {
  amount: number;
  timeFrame: TimeFramePlural;
};

const getTimeZoneOffsetInHours = () => new Date().getTimezoneOffset() / 60;

export const generateTimeZoneInterval = ({ amount, timeFrame }: FilterItemType) => {
  const offset = getTimeZoneOffsetInHours();
  const dateFilter = { hours: offset };
  dateFilter[timeFrame] = amount;
  const startDate = DateTime.utc().minus(Duration.fromObject(dateFilter));
  const endDate = DateTime.utc().minus(Duration.fromObject({ hours: offset }));

  return Interval.fromDateTimes(startDate, endDate);
};

export const generateFilterFromStartOf = (timeFrame: TimeFrameSingular) => {
  const offset = getTimeZoneOffsetInHours();
  const hoursOffset = { hours: offset };

  const currentDateTime = DateTime.utc().minus(hoursOffset);
  return Interval.fromDateTimes(currentDateTime.startOf(timeFrame), currentDateTime);
};
