import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addMediaOutletCollaborationRequested,
  AddMediaOutletWizardSteps,
  closeAddMediaWizard,
  goToNextStepAddMedia,
  goToPreviousStepAddMedia,
  selectAddMediaOutletCurrentStep,
  selectExistingMediaOutletToAddId,
  selectIsAddMediaOutletWizardFinishButtonDisabled,
  selectIsAddMediaOutletWizardProfileInfoStepValid
} from 'app/pages/my-audience/contact-profile';

export const AddMediaOutletFooter = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector(selectAddMediaOutletCurrentStep);
  const isFinishButtonDisabled = useAppSelector(selectIsAddMediaOutletWizardFinishButtonDisabled);
  const isAddMediaOutletWizardProfileInfoStepValid = useAppSelector(selectIsAddMediaOutletWizardProfileInfoStepValid);
  const existingMediaOutletToAddId = useAppSelector(selectExistingMediaOutletToAddId);

  const handleCancelButtonClick = () => dispatch(closeAddMediaWizard());

  const handleBackButtonClick = () => dispatch(goToPreviousStepAddMedia());

  const handleNextButtonClick = () => dispatch(goToNextStepAddMedia());

  const handleFinishButtonClick = () => dispatch(addMediaOutletCollaborationRequested());

  const isNextButtonDisabled = () => {
    if (currentStep === AddMediaOutletWizardSteps.SearchMediaOutlet) return !existingMediaOutletToAddId;
    if (currentStep === AddMediaOutletWizardSteps.ProfileInformation) return !existingMediaOutletToAddId || !isAddMediaOutletWizardProfileInfoStepValid;
  };

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleCancelButtonClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > AddMediaOutletWizardSteps.SearchMediaOutlet && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={handleBackButtonClick} />
        )}
        {currentStep < AddMediaOutletWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={handleNextButtonClick} disabled={isNextButtonDisabled()} />
        )}
        {currentStep === AddMediaOutletWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.finish' })} primary onClick={handleFinishButtonClick} disabled={isFinishButtonDisabled} />
        )}
      </div>
    </div>
  );
};
