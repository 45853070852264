import { Button, Input, SendIcon } from '@fluentui/react-northstar';
import { Comment, CommentMentionSuggestions, CommentUser, selectSignedInUserOid, selectSignedInUserUsername, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

export interface CommentsInputProps {
  mentions: CommentUser[];
  addComment(comment: Comment): void;
  addCommentMention(mention: CommentUser): void;
  mentionChange(mentionSearchText: string): void;
  disabled?: boolean;
}

export const CommentsInput: React.FC<CommentsInputProps> = ({ mentions, addComment, addCommentMention, mentionChange, disabled = false }) => {
  const { formatMessage } = useIntl();

  const loggedInUsername = useAppSelector<string>(selectSignedInUserUsername);
  const loggedInUserId = useAppSelector<string>(selectSignedInUserOid);

  const [newComment, setNewComment] = useState('');
  const [isMentioning, setIsMentioning] = useState(false);
  const [mentionName, setMentionName] = useState('');

  const makeComment = () => {
    if (newComment === '') {
      return;
    }
    const authorName = loggedInUsername !== undefined && loggedInUsername !== '' ? loggedInUsername : 'Unknown author';
    const authorId = loggedInUserId !== undefined ? loggedInUserId : '';

    const commentToAdd = new Comment('', new CommentUser(authorId, authorName, authorId), newComment, DateTime.utc().toJSDate(), [], mentions);
    addComment(commentToAdd);
    setNewComment('');
  };

  const onAddMention = (mention: CommentUser) => {
    setNewComment(newComment.substring(0, newComment.lastIndexOf(' ')) + ' @' + mention.name);
    setIsMentioning(false);
    setMentionName('');
    addCommentMention(mention);
  };

  const onMentionChange = async (mention: string) => {
    setMentionName(mention);
    mentionChange(mention);
  };

  return (
    <div className="profile-panel-comments-button-wrap">
      <Input
        className="profile-panel-comments-send-input"
        autoComplete="off"
        icon={
          <Button iconOnly disabled={disabled} text title={formatMessage({ id: 'comments.add-button-tooltip' })} icon={<SendIcon onClick={makeComment} />} />
        }
        value={newComment}
        onChange={(e) => {
          setNewComment((e.target as HTMLTextAreaElement).value);
        }}
        placeholder={formatMessage({ id: 'comments.input-placeholder' })}
        onKeyDown={(e) => {
          if (e.key === '@') {
            setIsMentioning(true);
          }
          if (isMentioning && e.key.length === 1) {
            onMentionChange(mentionName + e.key);
          }
          if (isMentioning && e.key === 'Backspace') {
            if (mentionName.length > 0) {
              setMentionName(mentionName.substr(0, mentionName.length - 1));
            } else {
              setIsMentioning(false);
            }
          }
          if (isMentioning && e.key === ' ') {
            setIsMentioning(false);
          }
          if (e.key === 'Enter') {
            makeComment();
          }
        }}
      />
      {isMentioning && (
        <div>
          <CommentMentionSuggestions selectSuggestion={onAddMention} />
        </div>
      )}
    </div>
  );
};
