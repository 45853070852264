import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { CreateCompanyFromContactStep, goToCompanyStep, selectCompanyCurrentStep } from 'app/pages/my-audience/wizard-create-contact';

interface CreateCompanyStepsProps {
  areFieldsValid?: boolean;
}

export const CreateCompanySteps = ({ areFieldsValid }: CreateCompanyStepsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const step = useAppSelector<CreateCompanyFromContactStep>(selectCompanyCurrentStep);

  const styleBreadcrumbStatus = (stepToCompareWith: CreateCompanyFromContactStep): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: CreateCompanyFromContactStep) => {
    dispatch(goToCompanyStep(step));
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyFromContactStep.GeneralInfo)}
            content={formatMessage({ id: 'add-menu-create-contact.company-info-step' })}
            onClick={() => handleStepButtonClick(CreateCompanyFromContactStep.GeneralInfo)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyFromContactStep.Address)}
            content={formatMessage({ id: 'add-menu-create-contact.company-address-step' })}
            onClick={() => handleStepButtonClick(CreateCompanyFromContactStep.Address)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyFromContactStep.Finish)}
            content={formatMessage({ id: 'add-menu-create-contact.company-finish-step' })}
            onClick={() => handleStepButtonClick(CreateCompanyFromContactStep.Finish)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
