import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/redux/store';
import { SavedSearch, SavedSearchInput, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import { FilterItem } from 'app/common';

export interface ContactsSavedSearchesState {
  pageNumber: number;
  pageSize: number;
  searchText: string;
  filterItem: FilterItem;
  totalCountOfSavedSearches: number;
  contactSavedSearches: SavedSearch[];
  contactSavedSearchToAdd: SavedSearchInput;
  contactSavedSearchToUpdate: SavedSearch;
  contactSavedSearchesToUpdate: SavedSearchWithIdInput[];
  contactSavedSearchToRemove: SavedSearch;
  isLoadingContactSavedSearches: boolean;
  isDeleteDialogOpened: boolean;
  isSaveSearchButtonDisabled: boolean;
}

const initialState: ContactsSavedSearchesState = {
  pageNumber: 1,
  pageSize: 6,
  searchText: '',
  filterItem: {} as FilterItem,
  totalCountOfSavedSearches: 0,
  contactSavedSearches: [],
  contactSavedSearchToAdd: {} as SavedSearchInput,
  contactSavedSearchToUpdate: {} as SavedSearch,
  contactSavedSearchesToUpdate: [],
  contactSavedSearchToRemove: {} as SavedSearch,
  isLoadingContactSavedSearches: true,
  isDeleteDialogOpened: false,
  isSaveSearchButtonDisabled: false
};

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.pageNumber],
  (pageNumber: number) => pageNumber
);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.contactsSavedSearches.pageSize], (pageSize: number) => pageSize);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.searchText],
  (searchText: string) => searchText
);

export type FilterItemSelector = (state: RootState) => FilterItem;
export const selectFilterItem: FilterItemSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.filterItem],
  (filterItem: FilterItem) => filterItem
);

export type TotalCountOfSavedSearchesSelector = (state: RootState) => number;
export const selectTotalCountOfSavedSearches: TotalCountOfSavedSearchesSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.totalCountOfSavedSearches],
  (totalCountOfSavedSearches: number) => totalCountOfSavedSearches
);

export type ContactSavedSearchesSelector = (state: RootState) => SavedSearch[];
export const selectContactSavedSearches: ContactSavedSearchesSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.contactSavedSearches],
  (contactSavedSearches: SavedSearch[]) => contactSavedSearches
);

export type ContactSavedSearchToAddSelector = (state: RootState) => SavedSearchInput;
export const selectContactSavedSearchToAdd: ContactSavedSearchToAddSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.contactSavedSearchToAdd],
  (contactSavedSearchToAdd: SavedSearchInput) => contactSavedSearchToAdd
);

export type ContactSavedSearchToUpdateSelector = (state: RootState) => SavedSearch;
export const selectContactSavedSearchToUpdate: ContactSavedSearchToUpdateSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.contactSavedSearchToUpdate],
  (contactSavedSearchToUpdate: SavedSearch) => contactSavedSearchToUpdate
);

export type ContactSavedSearchesToUpdateSelector = (state: RootState) => SavedSearchWithIdInput[];
export const selectContactSavedSearchesToUpdate: ContactSavedSearchesToUpdateSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.contactSavedSearchesToUpdate],
  (contactSavedSearchesToUpdate: SavedSearchWithIdInput[]) => contactSavedSearchesToUpdate
);

export type ContactSavedSearchToRemoveIdSelector = (state: RootState) => string;
export const selectContactSavedSearchToRemoveId: ContactSavedSearchToRemoveIdSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.contactSavedSearchToRemove.id],
  (id: string) => id
);

export type ContactSavedSearchToRemoveSelector = (state: RootState) => SavedSearch;
export const selectContactSavedSearchToRemove: ContactSavedSearchToRemoveSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.contactSavedSearchToRemove],
  (contactSavedSearchToRemove: SavedSearch) => contactSavedSearchToRemove
);

export type IsLoadingContactSavedSearchesSelector = (state: RootState) => boolean;
export const selectIsLoadingContactSavedSearches: IsLoadingContactSavedSearchesSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.isLoadingContactSavedSearches],
  (isLoadingContactSavedSearches: boolean) => isLoadingContactSavedSearches
);

export type IsDeleteDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsDeleteDialogOpened: IsDeleteDialogOpenedSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.isDeleteDialogOpened],
  (isDeleteDialogOpened: boolean) => isDeleteDialogOpened
);

export type IsSaveSearchButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsSaveSearchButtonDisabled: IsSaveSearchButtonDisabledSelector = createSelector(
  [(state: RootState) => state.contactsSavedSearches.isSaveSearchButtonDisabled],
  (isSaveSearchButtonDisabled: boolean) => isSaveSearchButtonDisabled
);

const propComparator = (prop) => (a, b) => a[prop] - b[prop];

const contactsSavedSearchesSlice = createSlice({
  name: 'contacts-saved-searches',
  initialState,
  reducers: {
    setTotalCountOfSavedSearches: (state, action: PayloadAction<number>) => {
      state.totalCountOfSavedSearches = action.payload;
    },
    resetContactsSavedSearches: (state) => {
      state.totalCountOfSavedSearches = 0;
      state.pageNumber = 1;
      state.contactSavedSearches = [];
    },
    firstPageOfSavedSearchesRequested: (state, action: PayloadAction<{ searchText: string; filterItem: FilterItem }>) => {
      state.totalCountOfSavedSearches = 0;
      state.pageNumber = 1;
      state.contactSavedSearches = [];
      state.isLoadingContactSavedSearches = true;
      state.filterItem = action.payload.filterItem;
      state.searchText = action.payload.searchText;
    },
    firstPageOfSavedSearchesReceived: (state, action: PayloadAction<SavedSearch[]>) => {
      state.contactSavedSearches = [...action.payload];
      state.isLoadingContactSavedSearches = false;
    },
    contactsSavedSearchesRequested: (state, action: PayloadAction<{ searchText: string; filterItem: FilterItem; pageNumber: number }>) => {
      state.isLoadingContactSavedSearches = true;
      state.filterItem = action.payload.filterItem;
      state.pageNumber = action.payload.pageNumber;
      state.searchText = action.payload.searchText;
    },
    contactsSavedSearchesReceived: (state, action: PayloadAction<SavedSearch[]>) => {
      state.contactSavedSearches = [...state.contactSavedSearches, ...action.payload];
      state.isLoadingContactSavedSearches = false;
    },
    addContactSavedSearchRequested: (state) => {
      state.isSaveSearchButtonDisabled = true;
    },
    contactSavedSearchAdded: (state, action: PayloadAction<SavedSearch>) => {
      state.contactSavedSearches = [action.payload, ...state.contactSavedSearches];
      state.contactSavedSearchToAdd = {} as SavedSearchInput;
      state.totalCountOfSavedSearches += 1;
      state.isSaveSearchButtonDisabled = false;
    },
    updateContactSavedSearchRequested: (state, action: PayloadAction<SavedSearch>) => {
      state.contactSavedSearchToUpdate = action.payload;
    },
    updatedContactSavedSearch: (state, action: PayloadAction<SavedSearch>) => {
      const index = state.contactSavedSearches.findIndex((savedSearch) => savedSearch.id === action.payload.id);
      state.contactSavedSearches = [...state.contactSavedSearches.slice(0, index), action.payload, ...state.contactSavedSearches.slice(index + 1)];
      state.contactSavedSearchToUpdate = {} as SavedSearch;
    },
    updateContactSavedSearchesRequested: (state, action: PayloadAction<SavedSearchWithIdInput[]>) => {
      state.contactSavedSearchesToUpdate = action.payload;
    },
    updatedContactSavedSearches: (state, action: PayloadAction<SavedSearch[]>) => {
      state.contactSavedSearches = [...action.payload].sort(propComparator('priority'));
      state.contactSavedSearchesToUpdate = [];
    },
    removeContactSavedSearchRequested: (state, action: PayloadAction<SavedSearch>) => {
      state.contactSavedSearchToRemove = action.payload;
    },
    removedContactSavedSearch: (state, action: PayloadAction<string>) => {
      state.contactSavedSearches = state.contactSavedSearches.filter((savedSearch) => savedSearch.id !== action.payload);
      state.contactSavedSearchToRemove = {} as SavedSearch;
      state.totalCountOfSavedSearches -= 1;
    },
    setContactSavedSearchToRemove: (state, action: PayloadAction<SavedSearch>) => {
      state.contactSavedSearchToRemove = action.payload;
      state.isDeleteDialogOpened = true;
    },
    loadNextContactSavedSearchAfterDeleteRequested: () => {},
    closeDeleteDialog: (state) => {
      state.contactSavedSearchToRemove = {} as SavedSearch;
      state.isDeleteDialogOpened = false;
    }
  }
});

export const {
  resetContactsSavedSearches,
  setTotalCountOfSavedSearches,
  addContactSavedSearchRequested,
  contactSavedSearchAdded,
  updateContactSavedSearchRequested,
  updatedContactSavedSearch,
  updateContactSavedSearchesRequested,
  updatedContactSavedSearches,
  removeContactSavedSearchRequested,
  removedContactSavedSearch,
  setContactSavedSearchToRemove,
  closeDeleteDialog,
  firstPageOfSavedSearchesReceived,
  firstPageOfSavedSearchesRequested,
  contactsSavedSearchesReceived,
  contactsSavedSearchesRequested,
  loadNextContactSavedSearchAfterDeleteRequested
} = contactsSavedSearchesSlice.actions;
export default contactsSavedSearchesSlice.reducer;
