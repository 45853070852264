import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regEmail,
  regNumbers,
  useAppDispatch,
  useAppSelector,
  WizardIntro,
  isPhoneNumberValidOrEmpty,
  isValidUrlField,
  regUrl
} from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import {
  addMediaOutletAddressInfo,
  closeCreateMediaOutletWizard,
  CreateMediaOutletFooter,
  CreateMediaOutletSteps,
  goToNextMediaOutletStep,
  goToPreviousMediaOutletStep,
  MediaOutletAddressInfo,
  selectMediaOutletAddressInfo,
  setMediaOutletAddressInfo
} from 'app/pages/my-audience/wizard-create-contact';
import { getNames } from 'country-list';
import { useForm } from 'react-hook-form';

export type CreateMediaOutletAddressInfoForm = {
  address: string;
  poBox: string;
  postalCode: string;
  city: string;
  country: string;
  websiteUrl: string;
  emailAddress: string;
  phoneNumber: string;
  fax: string;
};

export const CreateMediaOutletAddressInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const mediaOutletAddressInfo = useAppSelector<MediaOutletAddressInfo>(selectMediaOutletAddressInfo);

  const { handleSubmit, control, watch } = useForm<CreateMediaOutletAddressInfoForm, any>({
    defaultValues: {
      address: mediaOutletAddressInfo.address,
      poBox: mediaOutletAddressInfo.poBox,
      postalCode: mediaOutletAddressInfo.postalCode,
      city: mediaOutletAddressInfo.city,
      country: mediaOutletAddressInfo.country,
      websiteUrl: mediaOutletAddressInfo.websiteUrl,
      emailAddress: mediaOutletAddressInfo.emailAddress,
      phoneNumber: mediaOutletAddressInfo.phoneNumber,
      fax: mediaOutletAddressInfo.fax
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  useEffect(() => {
    return submitForm;
  }, []);

  const handleNextButtonClick = () => {
    dispatch(goToNextMediaOutletStep());
  };

  const submitForm = () => {
    handleSubmit(
      (data) => {
        const newMediaOutletAddressInfo = setMediaOutletAddressInfo(data);

        dispatch(addMediaOutletAddressInfo(newMediaOutletAddressInfo));
      },
      (error) => {
        Sentry.setExtra('Path', CreateMediaOutletAddressInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousMediaOutletStep());
  };

  const areGeneralInfoFieldsValid =
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('phoneNumber')) &&
    isValidUrlField(watch('websiteUrl'));

  return (
    <>
      <CreateMediaOutletSteps areFieldsValid={areGeneralInfoFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.mo-address-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput name="address" label={formatMessage({ id: 'add-menu-create-contact.mo-address-label' })} control={control} />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="poBox" label={formatMessage({ id: 'add-menu-create-contact.mo-po-label' })} control={control} />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="postalCode"
              label={formatMessage({ id: 'add-menu-create-contact.mo-postal-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regNumbers,
                  message: formatMessage({ id: 'error-messages.not-valid-postal-code' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="city" label={formatMessage({ id: 'add-menu-create-contact.mo-city-label' })} control={control} />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-contact.mo-country-label'} />
                </span>
              }
              name="country"
              items={getNames()}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.mo-country-placeholder' })}
              defaultValue={mediaOutletAddressInfo.country}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="websiteUrl"
              label={formatMessage({ id: 'add-menu-create-contact.mo-website-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="emailAddress"
              label={formatMessage({ id: 'add-menu-create-contact.mo-email-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="phoneNumber"
              label={formatMessage({ id: 'add-menu-create-contact.mo-phone-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="fax" label={formatMessage({ id: 'add-menu-create-contact.mo-fax-label' })} control={control} />
          </div>
        </div>
        <CreateMediaOutletFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areGeneralInfoFieldsValid}
        />
      </div>
    </>
  );
};
