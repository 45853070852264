import { Button, ClipboardCopiedToIcon, Tooltip, TooltipProps } from '@fluentui/react-northstar';
import { showAlertNotification, useAppDispatch } from 'app/common';
import { useIntl } from 'react-intl';

interface CopyToClipboardTooltipProps {
  textToCopy: string;
  label?: string;
  props?: Partial<TooltipProps>;
}

export const CopyToClipboardTooltip = ({ textToCopy = '', label, props }: CopyToClipboardTooltipProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => dispatch(showAlertNotification(formatMessage({ id: 'alert-messages.field-copied' }, { fieldName: label }))))
      .catch(() => copyToClipboard(textToCopy));
  };

  // This is added because functionality (navigator.clipboard.writeText()) is not working on Teams.
  // Microsoft is blocking Clipboard API, so we need to use 'old' solution.
  // Currently, there is no other solution to avoid this.
  const copyToClipboard = (textToCopy: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = textarea.textContent = textToCopy;
    textarea.style.opacity = '0';

    document.body.appendChild(textarea);
    textarea.select();

    const selection = document.getSelection();
    const range = document.createRange();

    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      dispatch(showAlertNotification(formatMessage({ id: 'alert-messages.field-copied' }, { fieldName: label })));
    } finally {
      selection.removeAllRanges();
      document.body.removeChild(textarea);
    }
  };

  return (
    <Tooltip
      subtle={false}
      pointing
      trigger={<Button icon={<ClipboardCopiedToIcon />} onClick={handleCopyToClipboard} iconOnly text size="small" />}
      content={formatMessage({ id: 'button-tooltips.copy' })}
      {...props}
    />
  );
};
