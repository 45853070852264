import { useAppSelector, Comment, useComponentLocationContext, PIVOT_ITEMS, PivotItemInfo } from 'app/common';
import { useNavigate } from 'react-router-dom';
import { selectSelectedContactComments } from 'app/pages/my-audience/contact-profile';
import { useIntl } from 'app/i18n';
import { Pivot, PivotItem } from '@fluentui/react';

export const ContactPanelPivotItems = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { isOutsideContactsPage, getSelectedMenuItemFromPathName } = useComponentLocationContext();

  const comments = useAppSelector<Comment[]>(selectSelectedContactComments);

  const handleTabClick = (relativePath: string) => {
    navigate(relativePath, { replace: true });
  };

  const pivotItems: PivotItemInfo[] = [
    { key: PIVOT_ITEMS.PROFILE, headerText: formatMessage({ id: 'contact-profile-tabs.profile' }) },
    { key: PIVOT_ITEMS.MEDIA_OUTLETS, headerText: formatMessage({ id: 'contact-profile-tabs.media-outlet' }) },
    { key: PIVOT_ITEMS.COMPANIES, headerText: formatMessage({ id: 'contact-profile-tabs.company' }) },
    { key: PIVOT_ITEMS.ACTIVITIES, headerText: formatMessage({ id: 'contact-profile-tabs.activities' }) },
    { key: PIVOT_ITEMS.GDPR, headerText: formatMessage({ id: 'contact-profile-tabs.gdpr' }) },
    { key: PIVOT_ITEMS.COMMENTS, headerText: formatMessage({ id: 'contact-profile-tabs.comments' }, { count: comments.length }) }
  ];

  const renderPivotItem = (pivotItem: PivotItemInfo) => {
    if (isOutsideContactsPage && pivotItem.key !== PIVOT_ITEMS.PROFILE) return;

    return <PivotItem key={pivotItem.key} itemKey={pivotItem.key} headerText={pivotItem.headerText}></PivotItem>;
  };

  return (
    <div className="profile-panel-tabs">
      <Pivot onLinkClick={(e) => handleTabClick(e.props.itemKey)} selectedKey={getSelectedMenuItemFromPathName()}>
        {pivotItems.map((pivotItem) => renderPivotItem(pivotItem))}
      </Pivot>
    </div>
  );
};
