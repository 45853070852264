import { DateTime } from 'luxon';
import { Dialog, DialogType } from '@fluentui/react';
import { useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import { Sending } from 'app/pages/my-activities/sendings';
import { selectSelectedContact, selectSelectedSending } from 'app/pages/my-audience/contact-profile';
import { Contact } from 'app/pages/my-audience/contacts';

const dialogModalProps = {
  isBlocking: true,
  styles: { main: { maxWidth: 450 } }
};

export const SendingDetailsDialog = ({ opened, dismiss }) => {
  const { formatMessage } = useIntl();

  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const selectedSending = useAppSelector<Sending>(selectSelectedSending);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'contacts-sendings-table.dialog-title' })
  };

  const onCloseDialog = () => {
    dismiss();
  };

  const date = DateTime.fromJSDate(new Date(selectedSending?.createdAt)).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

  return (
    <>
      <Dialog hidden={!opened} onDismiss={onCloseDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
        <p>
          {formatMessage({ id: 'contacts-sendings-table.description' })}: <b>{selectedSending.title}</b>
        </p>
        <p>
          {formatMessage({ id: 'contacts-sendings-table.date' })}: <b>{date}</b>
        </p>
        <p>
          {formatMessage({ id: 'contacts-sendings-table.opened' })}:{' '}
          <b>{selectedSending.recipients?.find((recipient) => recipient.contact.id === selectedContact.id).isOpened}</b>
        </p>
        <p>
          {formatMessage({ id: 'contacts-sendings-table.comment' })}: <b>{selectedSending.comment}</b>
        </p>
      </Dialog>
    </>
  );
};
