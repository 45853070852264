import { Comment, CommentUser, Comments, mentionSuggestionsRequested, resetMentionSuggestions, useAppDispatch, useAppSelector } from 'app/common';
import {
  addCommentMentionRequested,
  addCommentToContactRequested,
  selectCommentMentions,
  selectSelectedContactComments
} from 'app/pages/my-audience/contact-profile';
import { Permission, useAuthContext } from 'auth';

export const ContactComments = () => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector<Comment[]>(selectSelectedContactComments);
  const mentions = useAppSelector<CommentUser[]>(selectCommentMentions);
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const onAddComment = (comment: Comment) => {
    dispatch(addCommentToContactRequested(comment));
  };

  const onAddCommentMention = (mention: CommentUser) => {
    dispatch(addCommentMentionRequested(mention));
  };

  const onMentionChange = async (mention: string) => {
    if (!mention) {
      dispatch(resetMentionSuggestions());
      return;
    }
    dispatch(mentionSuggestionsRequested(mention));
  };

  return (
    <Comments
      comments={comments}
      mentions={mentions}
      addComment={onAddComment}
      addCommentMention={onAddCommentMention}
      mentionChange={onMentionChange}
      disabled={!hasPermissionToEdit}
    />
  );
};
