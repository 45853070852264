import { Icon } from '@fluentui/react';
import { Button, CloseIcon, MenuButton, Pill, Popup, Tooltip } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';
import { PillsPopupContent, PillData } from 'app/common';
import './PillsPopup.scss';

interface PillsProps {
  entityName: string;
  selectedValues: PillData[];
  dropdownValues: PillData[];
  enabledDelete: boolean;
  onAddValue: (text: string) => void;
  onSelectValue: (value: string) => void;
  onDeselectValue: (value: string) => void;
  onDeleteValues?: (value: string[]) => void;
  filterValues: (searchText: string) => void;
  pillsDisplayRange?: number;
  disabled?: boolean;
  onLoadMoreCallback?: (searchQuery: string) => void;
}

export const Pills = ({
  selectedValues,
  dropdownValues,
  onAddValue,
  onSelectValue,
  onDeselectValue,
  onDeleteValues,
  entityName,
  filterValues,
  enabledDelete,
  pillsDisplayRange = 4,
  disabled = false,
  onLoadMoreCallback
}: PillsProps) => {
  const { formatMessage } = useIntl();
  const pillsToDisplay = selectedValues?.slice(0, pillsDisplayRange);
  const pillsToGroup = selectedValues?.slice(pillsDisplayRange);

  const onTriggerPopup = () => filterValues('');

  const displayPill = (data: PillData) => {
    return (
      <Pill disabled={disabled} appearance={data.canBeUpdated ? 'outline' : 'filled'} className="tag" key={`tag-${data.id}`}>
        <div className="tag-content">
          <span className="tag-name" title={data.value}>
            {data.value}
          </span>
          {displayRemoveItemTooltip(data)}
        </div>
      </Pill>
    );
  };

  const displayRemoveItemTooltip = ({ id, canBeUpdated }: PillData) => {
    if (!canBeUpdated || disabled) return <></>;

    return (
      <Tooltip
        position="below"
        trigger={<Button icon={<CloseIcon />} iconOnly text onClick={() => onDeselectValue(id)} />}
        content={formatMessage({ id: 'pills.remove-button-tooltip' }, { entity: entityName })}
      />
    );
  };

  return (
    <div className="tags-wrapper">
      <div className="tags-popup">
        <Popup
          trigger={
            <Button
              disabled={disabled}
              className={!dropdownValues?.length ? 'tags-popup-trigger' : 'tags-popup-trigger tags-popup-trigger--icon'}
              text
              icon={<Icon iconName="Add" />}
              content={!selectedValues?.length && formatMessage({ id: 'pills.add-button' }, { entity: entityName })}
              onClick={onTriggerPopup}
            />
          }
          inline={true}
          position={'below'}
          content={
            <PillsPopupContent
              entityName={entityName}
              selectedValues={selectedValues}
              dropdownValues={dropdownValues}
              onAddValue={onAddValue}
              onSelectValue={onSelectValue}
              onDeselectValue={onDeselectValue}
              onDeleteValues={onDeleteValues}
              filterValues={filterValues}
              enabledDelete={enabledDelete}
              onLoadMoreCallback={onLoadMoreCallback}
            />
          }
        />
      </div>
      {pillsToDisplay?.map((data) => displayPill(data))}
      {!!pillsToGroup?.length && (
        <MenuButton
          trigger={
            <Pill className="tag tags-content-more">
              <div className="tag-content">
                <span className="tag-name">
                  {<Icon iconName="Add" />} {pillsToGroup.length}
                </span>
              </div>
            </Pill>
          }
          menu={pillsToGroup.map((item: PillData) => (
            <>
              {item.value}
              {displayRemoveItemTooltip(item)}
            </>
          ))}
          on="hover"
        />
      )}
    </div>
  );
};
