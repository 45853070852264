import { FlatfileProvider } from '@flatfile/react';
import { FlatfileSpace, User, closeFlatfile, selectSignedInUser, selectTenantId, useAppDispatch, useAppSelector } from 'app/common';
import 'app/common/components/flatfile/Flatfile.scss';

export const Flatfile = () => {
  const loggedInUser = useAppSelector<User>(selectSignedInUser);
  const tenantId = useAppSelector<string>(selectTenantId);
  const dispatch = useAppDispatch();

  return (
    <FlatfileProvider
      config={{
        closeSpace: {
          onClose: () => {
            dispatch(closeFlatfile());
          }
        }
      }}
      publishableKey={process.env.REACT_APP_FLATFILE_PK}
    >
      <FlatfileSpace loggedInUserId={loggedInUser.id} tenantId={tenantId} />
    </FlatfileProvider>
  );
};
