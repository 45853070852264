import { IColumn } from '@fluentui/react';
import { Company, CompanySearchResultTableData } from 'app/pages/my-audience/companies';
import { AadContact, Contact, ContactSearchResultTableData, Group } from 'app/pages/my-audience/contacts';
import { List, ListSearchResultTableData } from 'app/pages/my-audience/lists';
import { MediaOutlet, MediaOutletSearchResultTableData } from 'app/pages/my-audience/media-outlets';
import { AvatarDetails } from 'app/pages/my-audience/saved-searches';
import { Interval } from 'luxon';
import { Timeout } from 'managed-timeout';
import { ContactsSortOption } from './graphql/generated/graphql-gateway';
import { SyntheticEvent } from 'react';
import { IntlMessage, NestedKeys } from 'app/i18n';

export enum MyAudienceTabs {
  Contacts = 0,
  MediaOutlets,
  Companies,
  Lists
}

export enum MyActivitiesTab {
  Emailings
}

export enum SearchCategoryName {
  CompanyName = 0,
  ContactName,
  ParentCompany,
  MediaResort,
  TagName,
  PhoneNumber,
  Country,
  RegistrationNumber,
  CreatedBy,
  Address,
  PostalCode,
  City,
  Frequency,
  Fax,
  Website,
  PoBox,
  Role,
  Medium,
  Resort,
  MediaOutletName,
  Column,
  Category,
  MediaSupportType,
  ListName,
  Language
}

export interface ThreeDotMenuItems {
  key: string;
  title: string;
  onClick: (e: SyntheticEvent<HTMLElement, Event>) => void;
  icon: React.ReactElement;
  className: string;
  disabled?: boolean;
}

export class BottomPopupItems {
  constructor(public key: string, public title: string, public onClick: any, public className: string, public icon: any, public disabled?: boolean) {}
}

export class SearchSuggestionCategory {
  constructor(public name: SearchCategoryName, public filterItemFieldName: string, public listItems: SearchSuggestionListItem[]) {}
}

export class SearchSuggestionListItem {
  constructor(public text: string, public profilePictureUrl: string, public subText?: string) {}
}

export class Tag {
  constructor(public id: string, public name: string, public hexColor: string) {}
}

export class TagProjection {
  constructor(public id: string, public name: string) {}
}

export class TagCreationInput {
  constructor(public name: string, public dataSource: DataSource, public entityIds: string[], public entityType: string) {}
}

export type Taggable = Contact | MediaOutlet | Company | List;

export class DataSource {
  constructor(public id: string, public key: string) {}
}

export class Comment {
  constructor(
    public id: string,
    public author: CommentUser,
    public content: string,
    public createdAt: Date,
    public replies: Comment[],
    public mentions: CommentUser[]
  ) {}
}

export class CommentUser {
  constructor(public teamsId: string, public name: string, public aadObjectId: string) {}
}

export class User {
  constructor(public id: string, public aadObjectId: string, public firstName: string, public lastName: string, public emailAddress?: string) {}
}

export class ProfilePicture {
  constructor(public data: string, public fileExtension: string, public mimeType: string) {}
}

export type FilterItemType = {
  fieldName: string;
  value: string;
};

export class FilterItem {
  constructor(public fieldName: string, public value: string) {}
}

export interface UndoableAction {
  executeAction: () => void;
  undoAction: () => void;
}

export interface PendingAction {
  timeout: Timeout;
  undoAction: () => void;
}

export interface TableHeader extends IColumn {
  isColumnVisible?: boolean;
  sortOption?: ContactsSortOption;
}

export interface SavedSearchesTableHeader extends IColumn {
  isColumnVisible?: boolean;
}

export class Language {
  constructor(public id: string, public name: string) {}
}

export class MediumCategoryType {
  constructor(public name: string, public value: number) {}
}

export class MediumCategory {
  constructor(public id: string, public name: string, public type: MediumCategoryType) {}
}

export class UserInfo {
  constructor(public oid: string, public name: string, public emailAddress: string) {}
}

export type SearchResultTableDataItem =
  | ListSearchResultTableData
  | ContactSearchResultTableData
  | MediaOutletSearchResultTableData
  | CompanySearchResultTableData;

export type SearchResultTableData = SearchResultTableDataItem[];

export interface QueryParams {
  searchText: string;
  filterItems: FilterItem[];
}

export class AvatarDetailsInput {
  constructor(public savedSearchId: string, public searchText: string, public filterItems: FilterItem[]) {}
}

export class AvatarDetailsWithSavedSearchId {
  constructor(public savedSearchId: string, public avatarDetails: AvatarDetails[], public totalCount: number) {}
}

export class PillData {
  public constructor(public id: string, public value: string, public canBeUpdated: boolean) {}
}

export class MessageData {
  public constructor(public localizationKey: NestedKeys<IntlMessage>, public args: any = []) {}
}

export interface ITableData {
  key: string;
}

export class DateRange {
  public startDate: string;
  public endDate: string;

  private constructor(startDate: string, endDate: string) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public static parse(interval: Interval): DateRange {
    const startDate = interval.start.toUTC().toISO();
    const endDate = interval.end.toUTC().toISO();

    return new DateRange(startDate, endDate);
  }
}

export interface EntitiesPerPage<T> {
  [page: number]: T[];
}

export interface PivotItemInfo {
  key: string;
  headerText: string;
}
export enum PickerSuggestionType {
  People = 0,
  Group
}

export type AadPickerSuggestion = {
  objectId: string;
  name: string;
  type: PickerSuggestionType;
  value: AadContact | Group;
};
