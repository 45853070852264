import { Blacklist } from 'app/pages/my-audience/common';
import { Recipient, Sender, SendingStatus } from 'app/pages/my-activities/sendings';

export enum SendingWizardStep {
  Setup = 1,
  Audience,
  Email,
  PreviewAndSend
}

export type SendingSetupForm = {
  title: string;
  senderEmailAddress: string;
  senderDisplayName: string;
  respondToEmailAddress: string;
  blacklistValue: string;
  subject: string;
};

export class SendingSetup {
  constructor(
    public title: string,
    public sender: Sender,
    public senderDisplayName: string,
    public respondToEmailAddress: string,
    public blacklist: Blacklist,
    public subject: string
  ) {}
}

export class PageOfRecipients {
  constructor(public recipients: Recipient[], public totalCountOfRecipients: number) {}
}

export class SendingTableRow {
  constructor(public key: string, public title: string, public status: SendingStatus, public mailPreviewLink: string) {}
}
