import { RootState } from 'app/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterItem, QueryParams, TableHeader, SearchSuggestionCategory, TagProjection, Tag } from 'app/common';
import { Contact } from 'app/pages/my-audience/contacts';
import { DefaultListsSortingInput, IListsSortingInput, List, ListContact } from 'app/pages/my-audience/lists';

export interface ListsState {
  totalCountOfLists: number;
  pageNumber: number;
  pageSize: number;
  lists: List[];
  listsPreviousState: List[];
  searchText: string;
  filterItems: FilterItem[];
  sortingInput: IListsSortingInput;
  isSearchAnimationActive: boolean;
  isFiltersPanelOpen: boolean;
  searchSuggestionsText: string;
  listSearchSuggestions: SearchSuggestionCategory[];
  filterSuggestionsSearchText: string;
  filterSuggestionsPageSize: number;
  nameFilterSuggestions: string[];
  isLoadingNameSuggestions: boolean;
  contactFilterSuggestions: string[];
  isLoadingContactSuggestions: boolean;
  mediumFilterSuggestions: string[];
  isLoadingMediumSuggestions: boolean;
  tagsFilterSuggestions: string[];
  isLoadingTagsSuggestions: boolean;
  createdByFilterSuggestions: string[];
  isLoadingCreatedBySuggestions: boolean;
  defaultFilter: FilterItem;
  isCustomPopupOpen: boolean;
  listToRemoveId: string;
  listToRemove: List;
  contactSuggestions: Contact[];
  searchListNamesFilter: FilterItem;
  selectedLists: List[];
  selectedListsIds: string[];
  isLoadingSearchSuggestions: boolean;
  isLoadingSearchResults: boolean;
  searchResultsTableHeader: TableHeader[];
  exportHeader: TableHeader[];
  listsToRemoveIds: string[];
  allTags: Tag[];
  filteredTags: Tag[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToLists: TagProjection;
  tagToRemoveFromLists: TagProjection;
  pageOfListContacts: ListContact[];
  newListName: string;
}

const initialState: ListsState = {
  totalCountOfLists: 0,
  pageNumber: 1,
  pageSize: 30,
  lists: [],
  listsPreviousState: [],
  searchText: '',
  filterItems: [],
  sortingInput: new DefaultListsSortingInput(),
  isSearchAnimationActive: false,
  isFiltersPanelOpen: false,
  searchSuggestionsText: '',
  listSearchSuggestions: [],
  filterSuggestionsSearchText: '',
  filterSuggestionsPageSize: 10,
  nameFilterSuggestions: [],
  isLoadingNameSuggestions: false,
  contactFilterSuggestions: [],
  isLoadingContactSuggestions: false,
  mediumFilterSuggestions: [],
  isLoadingMediumSuggestions: false,
  tagsFilterSuggestions: [],
  isLoadingTagsSuggestions: false,
  createdByFilterSuggestions: [],
  isLoadingCreatedBySuggestions: false,
  defaultFilter: {} as FilterItem,
  isCustomPopupOpen: false,
  listToRemoveId: '',
  listToRemove: {} as List,
  contactSuggestions: [],
  searchListNamesFilter: {} as FilterItem,
  isLoadingSearchSuggestions: false,
  isLoadingSearchResults: false,
  selectedLists: [],
  selectedListsIds: [],
  searchResultsTableHeader: [],
  exportHeader: [],
  listsToRemoveIds: [],
  allTags: [],
  filteredTags: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToLists: {} as TagProjection,
  tagToRemoveFromLists: {} as TagProjection,
  pageOfListContacts: [],
  newListName: ''
};

export type ListsToRemoveIdsSelector = (state: RootState) => string[];
export const selectListsToRemoveIds: ListsToRemoveIdsSelector = createSelector(
  [(state: RootState) => state.lists.listsToRemoveIds],
  (listsToRemoveIds: string[]) => listsToRemoveIds
);

export type SortingInputSelector = (state: RootState) => IListsSortingInput;
export const selectSortingInput: SortingInputSelector = createSelector(
  [(state: RootState) => state.lists.sortingInput],
  (sortingInput: IListsSortingInput) => sortingInput
);

export type TotalCountOfListsSelector = (state: RootState) => number;
export const selectTotalCountOfLists: TotalCountOfListsSelector = createSelector(
  [(state: RootState) => state.lists.totalCountOfLists],
  (totalCountOfLists: number) => totalCountOfLists
);

export type CountOfLoadedListsSelector = (state: RootState) => number;
export const selectCountOfLoadedLists: CountOfLoadedListsSelector = createSelector([(state: RootState) => state.lists.lists], (lists: List[]) => lists.length);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector([(state: RootState) => state.lists.searchText], (searchText: string) => searchText);

export type IsLoadingSearchSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchSuggestions: IsLoadingSearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.isLoadingSearchSuggestions],
  (isLoadingSearchSuggestions: boolean) => isLoadingSearchSuggestions
);

export type IsLoadingSearchResultsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchResults: IsLoadingSearchResultsSelector = createSelector(
  [(state: RootState) => state.lists.isLoadingSearchResults],
  (isLoadingSearchResults: boolean) => isLoadingSearchResults
);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector([(state: RootState) => state.lists.pageNumber], (pageNumber: number) => pageNumber);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.lists.pageSize], (pageSize: number) => pageSize);

export type ListsSelector = (state: RootState) => List[];
export const selectLists: ListsSelector = createSelector([(state: RootState) => state.lists.lists], (lists: List[]) => lists);

export type SearchSuggestionTextSelector = (state: RootState) => string;
export const selectSearchListsSuggestionText: SearchSuggestionTextSelector = createSelector(
  [(state: RootState) => state.lists.searchSuggestionsText],
  (searchSuggestionsText: string) => searchSuggestionsText
);

export type ListsSearchTextSelector = (state: RootState) => string;
export const selectListsSearchText: ListsSearchTextSelector = createSelector(
  [(state: RootState) => state.lists.searchText],
  (searchText: string) => searchText
);

export type FilterItemsSelector = (state: RootState) => FilterItem[];
export const selectFilterItems: FilterItemsSelector = createSelector(
  [(state: RootState) => state.lists.filterItems],
  (filterItems: FilterItem[]) => filterItems
);

export type ListSearchSuggestionsSelector = (state: RootState) => SearchSuggestionCategory[];
export const selectListSearchSuggestions: ListSearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.listSearchSuggestions],
  (listSearchSuggestions: SearchSuggestionCategory[]) => listSearchSuggestions
);

export type ListToRemoveIdSelector = (state: RootState) => string;
export const selectListToRemoveId: ListToRemoveIdSelector = createSelector(
  [(state: RootState) => state.lists.listToRemoveId],
  (listToRemoveId: string) => listToRemoveId
);

export type ListToRemoveSelector = (state: RootState) => List;
export const selectListToRemove: ListToRemoveSelector = createSelector([(state: RootState) => state.lists.listToRemove], (listToRemove: List) => listToRemove);

export type SelectedListsSelector = (state: RootState) => List[];
export const selectSelectedLists: SelectedListsSelector = createSelector(
  [(state: RootState) => state.lists.selectedLists],
  (selectedLists: List[]) => selectedLists
);

export type SearchResultsTableHeaderSelector = (state: RootState) => TableHeader[];
export const selectSearchResultTableHeader: SearchResultsTableHeaderSelector = createSelector(
  [(state: RootState) => state.lists.searchResultsTableHeader],
  (searchResultsTableHeader: TableHeader[]) => searchResultsTableHeader
);

export type ExportHeaderSelector = (state: RootState) => TableHeader[];
export const selectExportHeader: ExportHeaderSelector = createSelector(
  [(state: RootState) => state.lists.exportHeader],
  (exportHeader: TableHeader[]) => exportHeader
);

export type IsSearchAnimationActiveSelector = (state: RootState) => boolean;
export const selectIsSearchAnimationActive: IsSearchAnimationActiveSelector = createSelector(
  [(state: RootState) => state.lists.isSearchAnimationActive],
  (isSearchAnimationActive: boolean) => isSearchAnimationActive
);

export type IsFilterPanelOpenSelector = (state: RootState) => boolean;
export const selectIsFilterPanelOpen: IsFilterPanelOpenSelector = createSelector(
  [(state: RootState) => state.lists.isFiltersPanelOpen],
  (isFiltersPanelOpen: boolean) => isFiltersPanelOpen
);

export type FilterSuggestionsSearchTextSelector = (state: RootState) => string;
export const selectFilterSuggestionsSearchText: FilterSuggestionsSearchTextSelector = createSelector(
  [(state: RootState) => state.lists.filterSuggestionsSearchText],
  (filterSuggestionsSearchText: string) => filterSuggestionsSearchText
);

export type FilterSuggestionsPageSizeSelector = (state: RootState) => number;
export const selectFilterSuggestionsPageSize: FilterSuggestionsPageSizeSelector = createSelector(
  [(state: RootState) => state.lists.filterSuggestionsPageSize],
  (filterSuggestionsPageSize: number) => filterSuggestionsPageSize
);

export type ListNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectListNameFilterSuggestions: ListNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.nameFilterSuggestions],
  (nameFilterSuggestions: string[]) => nameFilterSuggestions
);

export type IsLoadingNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingNameSuggestions: IsLoadingNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.isLoadingNameSuggestions],
  (isLoadingNameSuggestions: boolean) => isLoadingNameSuggestions
);

export type ListContactFilterSuggestionsSelector = (state: RootState) => string[];
export const selectListContactFilterSuggestions: ListContactFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.contactFilterSuggestions],
  (contactFilterSuggestions: string[]) => contactFilterSuggestions
);

export type IsLoadingContactSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingContactSuggestions: IsLoadingContactSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.isLoadingContactSuggestions],
  (isLoadingContactSuggestions: boolean) => isLoadingContactSuggestions
);

export type ListMediumFilterSuggestionsSelector = (state: RootState) => string[];
export const selectListMediumFilterSuggestions: ListMediumFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.mediumFilterSuggestions],
  (mediumFilterSuggestions: string[]) => mediumFilterSuggestions
);

export type IsLoadingMediumSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingMediumSuggestions: IsLoadingMediumSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.isLoadingMediumSuggestions],
  (isLoadingMediumSuggestions: boolean) => isLoadingMediumSuggestions
);

export type ListTagsFilterSuggestionsSelector = (state: RootState) => string[];
export const selectListTagsFilterSuggestions: ListTagsFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.tagsFilterSuggestions],
  (tagsFilterSuggestions: string[]) => tagsFilterSuggestions
);

export type IsLoadingTagsSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingTagsSuggestions: IsLoadingTagsSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.isLoadingTagsSuggestions],
  (isLoadingTagsSuggestions: boolean) => isLoadingTagsSuggestions
);

export type ListCreatedByFilterSuggestionsSelector = (state: RootState) => string[];
export const selectListCreatedByFilterSuggestions: ListCreatedByFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.createdByFilterSuggestions],
  (createdByFilterSuggestions: string[]) => createdByFilterSuggestions
);

export type IsLoadingCreatedBySuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingCreatedBySuggestions: IsLoadingCreatedBySuggestionsSelector = createSelector(
  [(state: RootState) => state.lists.isLoadingCreatedBySuggestions],
  (isLoadingCreatedBySuggestions: boolean) => isLoadingCreatedBySuggestions
);

export type FilteredTagsInPanelSelector = (state: RootState) => Tag[];
export const selectFilteredTagsInPanel: FilteredTagsInPanelSelector = createSelector(
  [(state: RootState) => state.lists.filteredTags],
  (filteredTags: Tag[]) => filteredTags
);

export type SelectedListsIdsSelector = (state: RootState) => string[];
export const selectSelectedListsIds: SelectedListsIdsSelector = createSelector(
  [(state: RootState) => state.lists.selectedListsIds],
  (selectedListsIds: string[]) => selectedListsIds
);

export type AllTagsSelector = (state: RootState) => Tag[];
export const selectAllTags: AllTagsSelector = createSelector([(state: RootState) => state.lists.allTags], (allTags: Tag[]) => allTags);

export type TagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: TagNameToCreateSelector = createSelector(
  [(state: RootState) => state.lists.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type TagsToDeleteSelector = (state: RootState) => Tag[];
export const selectTagsToDelete: TagsToDeleteSelector = createSelector([(state: RootState) => state.lists.tagsToDelete], (tagsToDelete: Tag[]) => tagsToDelete);

export type tagToAddToListsSelector = (state: RootState) => TagProjection;
export const selectTagToAddToLists: tagToAddToListsSelector = createSelector(
  [(state: RootState) => state.lists.tagToAddToLists],
  (tagToAddToLists: TagProjection) => tagToAddToLists
);

export type tagToRemoveFromListsSelector = (state: RootState) => TagProjection;
export const selectTagToRemoveFromLists: tagToRemoveFromListsSelector = createSelector(
  [(state: RootState) => state.lists.tagToRemoveFromLists],
  (tagToRemoveFromLists: TagProjection) => tagToRemoveFromLists
);

export type FilterSuggestionsListIdSelector = (state: RootState) => string;
export const selectFilterSuggestionsListId: FilterSuggestionsListIdSelector = createSelector(
  [(state: RootState) => state.lists.lists],
  (lists: List[]) => lists.find((l) => l.numberOfContacts > 0)?.id ?? lists[0].id
);

export type NewListNameSelector = (state: RootState) => string;
export const selectNewListName: NewListNameSelector = createSelector(
  (state: RootState) => state.lists.newListName,
  (name: string) => name
);

const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    setTotalCountOfLists: (state, action: PayloadAction<number>) => {
      state.totalCountOfLists = action.payload;
    },
    firstPageOfListsRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = 1;
      state.totalCountOfLists = 0;
      state.lists = [];
    },
    firstPageOfListsReceived: (state, action: PayloadAction<List[]>) => {
      state.lists = action.payload;
      state.isLoadingSearchResults = false;

      const topLists = state.lists.slice(0, 5);
      state.nameFilterSuggestions = Array.from(new Set(topLists.map((lists) => lists.name)));
      state.contactFilterSuggestions = Array.from(
        new Set(
          topLists
            .flatMap((list) => state.pageOfListContacts)
            .filter(Boolean)
            .map((listContact) => `${listContact.contact.firstName} ${listContact.contact.lastName}`)
        )
      );
      state.mediumFilterSuggestions = Array.from(new Set(topLists.flatMap((list) => list.mediumNames)));
      state.tagsFilterSuggestions = Array.from(new Set(topLists.flatMap((list) => list.tags).map((tag) => tag.name)));
      state.createdByFilterSuggestions = Array.from(new Set(topLists.map((list) => `${list.createdByContact.firstName} ${list.createdByContact.lastName}`)));
    },
    contactsFilterSuggestionsRequested: () => {},
    contactsFilterSuggestionsReceived: (state, action: PayloadAction<ListContact[]>) => {
      state.pageOfListContacts = action.payload;
    },
    nextPageOfListsRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = state.pageNumber + 1;
    },
    nextPageOfListsReceived: (state, action: PayloadAction<List[]>) => {
      state.lists = [...state.lists, ...action.payload];
      state.isLoadingSearchResults = false;
    },
    listsSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.searchSuggestionsText = action.payload;
      state.isLoadingSearchSuggestions = true;
    },
    listsSuggestionsReceived: (state, action: PayloadAction<SearchSuggestionCategory[]>) => {
      state.listSearchSuggestions = action.payload;
      state.isLoadingSearchSuggestions = false;
    },
    listNamesSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    listNamesSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.nameFilterSuggestions = action.payload;
      state.isLoadingNameSuggestions = false;
    },
    contactsSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    contactsSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.contactFilterSuggestions = action.payload;
      state.isLoadingContactSuggestions = false;
    },
    mediumsSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    mediumsSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.mediumFilterSuggestions = action.payload;
      state.isLoadingMediumSuggestions = false;
    },
    tagsSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    tagsSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.tagsFilterSuggestions = action.payload;
      state.isLoadingTagsSuggestions = false;
    },
    createdByContactsSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    createdByContactsSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.createdByFilterSuggestions = action.payload;
      state.isLoadingCreatedBySuggestions = false;
    },
    // TODO: move this action to sending profile slice. We cannot change lists state on tab on sending profile
    searchListsRequested: (state, action: PayloadAction<string>) => {
      state.totalCountOfLists = 0;
      state.pageNumber = 1;
      state.lists = [];

      state.listSearchSuggestions = [];
      state.searchText = action.payload;
      state.searchSuggestionsText = '';
      state.isLoadingSearchResults = true;
    },
    resetListSuggestions: (state, action: PayloadAction<string>) => {
      state.listSearchSuggestions = [];
      state.searchSuggestionsText = action.payload;
    },
    clearAllFilters: (state) => {
      state.filterItems = [];
    },
    updateListSearchResults: (state, action: PayloadAction<List>) => {
      const index = state.lists.findIndex((mo) => mo.id === action.payload.id);
      state.lists[index] = action.payload;
    },
    updateListSearchResultsContactsCount: (state, action: PayloadAction<{ listId: string; contactsCount: number }>) => {
      const index = state.lists.findIndex((mo) => mo.id === action.payload.listId);
      state.lists[index] = { ...state.lists[index], numberOfContacts: action.payload.contactsCount };
    },
    addedTagToListSearchResults: (state, action: PayloadAction<{ listId: string; tag: TagProjection }>) => {
      const index = state.lists.findIndex((c) => c.id === action.payload.listId);
      state.lists[index] = { ...state.lists[index], tags: [...state.lists[index].tags, action.payload.tag] };
    },
    removedTagFromListSearchResults: (state, action: PayloadAction<{ listId: string; tag: TagProjection }>) => {
      const index = state.lists.findIndex((c) => c.id === action.payload.listId);
      const updatedTags = state.lists[index].tags.filter((t) => t.id !== action.payload.tag.id);
      state.lists[index] = { ...state.lists[index], tags: [...updatedTags] };
    },
    updatedProfilePictureSearchResults: (state, action: PayloadAction<{ listId: string; imageUrl: string }>) => {
      const index = state.lists.findIndex((c) => c.id === action.payload.listId);
      state.lists[index] = { ...state.lists[index], imageUrl: action.payload.imageUrl };
    },
    removeListRequested: (state, action: PayloadAction<List>) => {
      state.listToRemoveId = action.payload.id;
      state.listToRemove = action.payload;
    },
    removedList: (state, action: PayloadAction<string>) => {
      state.lists = state.lists?.filter((list) => list.id !== action.payload);
    },
    removeListFromStore: (state, action: PayloadAction<List>) => {
      state.lists = state.lists?.filter((list) => list.id !== action.payload.id);
    },
    undoRemoveListFromStore: (state, action: PayloadAction<List>) => {
      state.lists = [action.payload, ...state.lists];
    },
    removeListsFromStore: (state, action: PayloadAction<string[]>) => {
      state.listsPreviousState = state.lists;
      state.lists = state.lists?.filter((list) => !action.payload.some((id) => id === list.id));
    },
    undoRemoveListsFromStore: (state) => {
      state.lists = state.listsPreviousState;
      state.listsPreviousState = [];
    },
    addListsFilter: (state, action: PayloadAction<FilterItem>) => {
      if (action.payload.fieldName === 'createdAt') {
        state.filterItems = state.filterItems.filter((item) => item.fieldName !== action.payload.fieldName);
      }
      state.filterItems = [...state.filterItems, action.payload];
    },
    removeListsFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = state.filterItems.filter((item) => item.fieldName !== action.payload.fieldName || item.value !== action.payload.value);
    },
    bulkRefreshLinkedSavedSearchesRequested: (state, action: PayloadAction<List[]>) => {
      state.selectedLists = action.payload;
    },
    copyListsRequested: (state, action: PayloadAction<List[]>) => {
      state.selectedLists = action.payload;
    },
    copiedListsReceived: (state, action: PayloadAction<List[]>) => {
      state.lists = [...state.lists, ...action.payload];
    },
    setSearchResultTableHeader: (state, action: PayloadAction<TableHeader[]>) => {
      state.searchResultsTableHeader = action.payload;
      state.exportHeader = action.payload.filter((headerItem) => headerItem.isColumnVisible && headerItem.fieldName !== 'add');
    },
    startSearchAnimation: (state) => {
      state.isSearchAnimationActive = true;
    },
    addListAndBulkAssignToContactsRequested: (state, action: PayloadAction<string>) => {
      state.newListName = action.payload;
    },
    addNewListFromBulkEditRequested: (state, action: PayloadAction<string>) => {
      state.newListName = action.payload;
    },
    stopSearchAnimation: (state) => {
      state.isSearchAnimationActive = false;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setFilterItems: (state, action: PayloadAction<FilterItem[]>) => {
      state.filterItems = action.payload;
    },
    setQueryParams: (state, action: PayloadAction<QueryParams>) => {
      state.searchText = action.payload.searchText;
      state.filterItems = action.payload.filterItems;
    },
    openFiltersPanel: (state) => {
      state.isFiltersPanelOpen = true;
    },
    closeFiltersPanel: (state) => {
      state.isFiltersPanelOpen = false;
    },
    setSortingInput: (state, action: PayloadAction<IListsSortingInput>) => {
      state.sortingInput = action.payload;
    },
    startLoadingNameSuggestions: (state) => {
      state.isLoadingNameSuggestions = true;
      state.nameFilterSuggestions = [];
    },
    startLoadingContactSuggestions: (state) => {
      state.isLoadingContactSuggestions = true;
      state.contactFilterSuggestions = [];
    },
    startLoadingMediumSuggestions: (state) => {
      state.isLoadingMediumSuggestions = true;
      state.mediumFilterSuggestions = [];
    },
    startLoadingTagsSuggestions: (state) => {
      state.isLoadingTagsSuggestions = true;
      state.tagsFilterSuggestions = [];
    },
    startLoadingCreatedBySuggestions: (state) => {
      state.isLoadingCreatedBySuggestions = true;
      state.createdByFilterSuggestions = [];
    },
    removeListsByIdsRequested: (state, action: PayloadAction<string[]>) => {
      state.listsToRemoveIds = action.payload;
      state.listsPreviousState = [];
    },
    listsByIdsRemoved: (state, action: PayloadAction<List[]>) => {
      state.lists = state.lists.filter((c) => !action.payload.map((p) => p.id).includes(c.id));
      state.listsToRemoveIds = [];
      state.totalCountOfLists = state.totalCountOfLists - action.payload.length;
    },
    filterTags: (state, action: PayloadAction<string>) => {
      state.filteredTags = state.allTags.filter((tag) => tag.name.includes(action.payload));
    },
    addedTagToListsSearchResults: (state, action: PayloadAction<{ selectedListsIds: string[]; tag: Tag }>) => {
      const { selectedListsIds, tag } = action.payload;

      state.lists = state.lists.map((list) => {
        if (!selectedListsIds.includes(list.id) || list.tags.map((t) => t.id).includes(tag.id)) return list;

        return {
          ...list,
          tags: [...list.tags, tag]
        };
      });
    },
    removedTagsFromListsSearchResults: (state, action: PayloadAction<{ selectedListsIds: string[]; tags: Tag[] }>) => {
      const { selectedListsIds, tags } = action.payload;
      selectedListsIds.forEach((element) => {
        const index = state.lists.findIndex((c) => c.id === element);
        tags.forEach((tag) => {
          state.lists[index] = {
            ...state.lists[index],
            tags: state.lists[index].tags.filter((t) => t.id !== tag.id)
          };
        });
      });
    },
    addTagToListsInStore: (state, action: PayloadAction<{ selectedListsIds: string[]; tag: Tag }>) => {
      state.selectedListsIds = action.payload.selectedListsIds;
      state.selectedLists = state.selectedLists.map((company) => ({ ...company, tags: [...company.tags, action.payload.tag] }));
      state.tagToAddToLists = action.payload.tag;
    },
    removeTagFromListsInStore: (state, action: PayloadAction<{ selectedListsIds: string[]; tag: Tag }>) => {
      state.selectedListsIds = action.payload.selectedListsIds;
      state.selectedLists = state.selectedLists.map((company) => ({
        ...company,
        tags: company.tags.filter((tag) => tag.id !== action.payload.tag.id)
      }));
    },
    addTagToListsRequested: (state, action: PayloadAction<{ selectedListsIds: string[]; tag: Tag }>) => {
      state.selectedListsIds = action.payload.selectedListsIds;
      state.tagToAddToLists = action.payload.tag;
    },
    removeTagFromListsRequested: (state, action: PayloadAction<{ selectedListsIds: string[]; tag: Tag }>) => {
      state.selectedListsIds = action.payload.selectedListsIds;
      state.tagToRemoveFromLists = action.payload.tag;
    },
    addNewTagRequested: (state, action: PayloadAction<{ selectedListsIds: string[]; text: string }>) => {
      state.selectedListsIds = action.payload.selectedListsIds;
      state.tagNameToCreate = action.payload.text;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.allTags = [...state.allTags, action.payload];
      state.filteredTags = state.allTags;
      state.selectedLists = state.selectedLists.map((c) => {
        return !c.tags.includes(action.payload) ? (c = { ...c, tags: [...c.tags, action.payload] }) : c;
      });
      state.tagNameToCreate = '';
    },
    deleteTagsRequested: (state, action: PayloadAction<Tag[]>) => {
      state.tagsToDelete = action.payload;
    },
    tagsDeleted: (state, action: PayloadAction<string[]>) => {
      state.allTags = state.allTags.filter((tag) => !action.payload.includes(tag.id));
      state.filteredTags = state.filteredTags.filter((tag) => !action.payload.includes(tag.id));
      state.selectedLists = state.selectedLists.map((c) => (c = { ...c, tags: c.tags.filter((tag) => !action.payload.includes(tag.id)) }));
      state.tagsToDelete = [];
    },
    getAllListsTagsRequested: () => {},
    allTagsReceived: (state, action: PayloadAction<Tag[]>) => {
      state.allTags = action.payload;
      state.filteredTags = action.payload;
    }
  }
});

export const {
  setTotalCountOfLists,
  firstPageOfListsRequested,
  firstPageOfListsReceived,
  nextPageOfListsRequested,
  nextPageOfListsReceived,
  listsSuggestionsRequested,
  listsSuggestionsReceived,
  searchListsRequested,
  resetListSuggestions,
  clearAllFilters,
  updateListSearchResults,
  addedTagToListSearchResults,
  removedTagFromListSearchResults,
  updatedProfilePictureSearchResults,
  removeListRequested,
  removedList,
  removeListFromStore,
  undoRemoveListFromStore,
  removeListsFromStore,
  undoRemoveListsFromStore,
  addListsFilter,
  removeListsFilter,
  listNamesSuggestionsRequested,
  listNamesSuggestionsReceived,
  contactsSuggestionsRequested,
  contactsSuggestionsReceived,
  tagsSuggestionsRequested,
  tagsSuggestionsReceived,
  createdByContactsSuggestionsRequested,
  createdByContactsSuggestionsReceived,
  mediumsSuggestionsRequested,
  mediumsSuggestionsReceived,
  bulkRefreshLinkedSavedSearchesRequested,
  setSearchResultTableHeader,
  copyListsRequested,
  copiedListsReceived,
  startSearchAnimation,
  addListAndBulkAssignToContactsRequested,
  addNewListFromBulkEditRequested,
  stopSearchAnimation,
  setSearchText,
  setFilterItems,
  setQueryParams,
  openFiltersPanel,
  closeFiltersPanel,
  setSortingInput,
  startLoadingNameSuggestions,
  startLoadingContactSuggestions,
  startLoadingMediumSuggestions,
  startLoadingTagsSuggestions,
  startLoadingCreatedBySuggestions,
  removeListsByIdsRequested,
  listsByIdsRemoved,
  filterTags,
  addedTagToListsSearchResults,
  removedTagsFromListsSearchResults,
  addTagToListsInStore,
  removeTagFromListsInStore,
  addTagToListsRequested,
  removeTagFromListsRequested,
  addNewTagRequested,
  addedNewTag,
  deleteTagsRequested,
  tagsDeleted,
  getAllListsTagsRequested,
  allTagsReceived,
  updateListSearchResultsContactsCount,
  contactsFilterSuggestionsReceived,
  contactsFilterSuggestionsRequested
} = listsSlice.actions;

export default listsSlice.reducer;
