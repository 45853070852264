import { PillData, UndoablePills, Tag, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { addedNewTag, selectMediaOutletTagsHeaderValue, selectSelectedMediaOutlet, undoAddedNewTag } from 'app/pages/my-audience/media-outlet-profile';
import {
  addNewTagRequested,
  deleteTagsRequested,
  filterTags,
  removeTagFromMediaOutletsRequested,
  selectFilteredTagsInPanel,
  selectAllTags,
  addTagToMediaOutletsRequested,
  MediaOutlet,
  addedTagToMediaOutletsSearchResults,
  removedTagsFromMediaOutletsSearchResults
} from 'app/pages/my-audience/media-outlets';
import { Permission, useAuthContext } from 'auth';

export const MediaOutletTags = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);
  const mediaOutletIds = [selectedMediaOutlet.id];
  const selectedTags = useAppSelector<Tag[]>(selectMediaOutletTagsHeaderValue);
  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);
  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);

  const addNewTag = (text: string) => dispatch(addNewTagRequested({ mediaOutletIds, text }));

  const addTagToMediaOutlets = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addTagToMediaOutletsRequested({ mediaOutletIds, tag }));
  };

  const addTagToMediaOutletsInStore = (id: string) => {
    const tagToAdd = allTags.find((t) => t.id === id);
    dispatch(addedNewTag(tagToAdd));
    dispatch(addedTagToMediaOutletsSearchResults({ mediaOutletIds, tag: tagToAdd }));
  };

  const removeTagFromMediaOutletsInStore = (id: string) => {
    const tagToUndo = allTags.find((t) => t.id === id);
    dispatch(undoAddedNewTag(tagToUndo));
    dispatch(removedTagsFromMediaOutletsSearchResults({ mediaOutletIds, tags: [tagToUndo] }));
  };

  const deleteTags = (tagIds: string[]) => {
    const tagsToRemove = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tagsToRemove));
  };

  const onFilterTags = (text: string) => dispatch(filterTags(text));

  const removeTagFromMediaOutlets = (id: string) => {
    const tag = allTags.find((t) => t.id === id);

    dispatch(removeTagFromMediaOutletsRequested({ mediaOutletIds, tag }));
  };

  return (
    <UndoablePills
      entityName={formatMessage({ id: 'labels.tag' })}
      selectedValues={selectedTags.map((t) => new PillData(t.id, t.name, true))}
      dropdownValues={filteredTags.map((t) => new PillData(t.id, t.name, true))}
      onAddValue={addNewTag}
      onSelectValue={addTagToMediaOutlets}
      onSelectValueInStore={addTagToMediaOutletsInStore}
      onUndoSelectValueInStore={removeTagFromMediaOutletsInStore}
      onDeselectValue={removeTagFromMediaOutlets}
      onDeselectValueInStore={removeTagFromMediaOutletsInStore}
      onUndoDeselectValueInStore={addTagToMediaOutletsInStore}
      onDeleteValues={deleteTags}
      filterValues={onFilterTags}
      enabledDelete={true}
      disabled={!hasPermissionToEdit}
    />
  );
};
