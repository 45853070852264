import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  ContactsAccordionContent,
  LinkToSavedSearchesAccordionContent,
  RemoveContactDialog,
  removeContactsFromListRequested,
  selectSelectedContactsIds
} from 'app/pages/my-audience/lists-profile';

export const ContactsTab = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedContactsIds = useAppSelector<string[]>(selectSelectedContactsIds);

  const panels = [
    {
      title: (
        <div className="accordion-head-box" key="list-overview-title">
          <span className="accordion-heading">{formatMessage({ id: 'list-profile-contacts.contacts' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'contacts-accortion', content: <ContactsAccordionContent /> }
    },
    {
      title: (
        <div className="accordion-head-box" key="sendings-overview-title">
          <span className="accordion-heading">{formatMessage({ id: 'list-profile-contacts.link-to-saved-search' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'link-to-saved-searches-accordion', content: <LinkToSavedSearchesAccordionContent /> }
    }
  ];

  const handleRemoveSelectedContacts = () => {
    dispatch(removeContactsFromListRequested(selectedContactsIds));
  };

  return (
    <div className="profile-panel-content contact-tab">
      <Accordion panels={panels} defaultActiveIndex={[0]} />
      <RemoveContactDialog removeSelectedContacts={handleRemoveSelectedContacts} />
    </div>
  );
};
