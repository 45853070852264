interface NumberWrapProps {
  numberValue: string;
  children: JSX.Element;
}

export const NumberWrap = ({ children, numberValue }: NumberWrapProps) => {
  return (
    <div className="number-wrap">
      <div className="number-box">
        <span className="number-box-text">{numberValue}</span>
      </div>
      {children}
    </div>
  );
};
