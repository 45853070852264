import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { createAndAssignTag, deleteTags, fetchTagsByEntityType, safe, safelyHandleError, TagCreationInput } from 'app/common';
import {
  addSending,
  addSendingRequested,
  addSendings,
  addSendingsRequested,
  duplicateSendings,
  duplicateSendingsRequested,
  fetchSendingsByQueryParams,
  firstPageOfSendingsReceived,
  firstPageOfSendingsRequested,
  nextPageOfSendingsReceived,
  nextPageOfSendingsRequested,
  PageOfSendings,
  removeSending,
  removeSendingRequested,
  removeSendings,
  removeSendingsRequested,
  getCampaignNameFilterSuggestions,
  getCreatedByFilterSuggestions,
  getEmailSubjectFilterSuggestions,
  getRecipientEmailOrNameFilterSuggestions,
  getRecipientMediumNameFilterSuggestions,
  getTagNameFilterSuggestions,
  getTitleFilterSuggestions,
  selectPageNumber,
  selectPageSize,
  selectSearchText,
  selectSendingsToAdd,
  selectSendingsToRemoveIds,
  selectSendingsToUpdate,
  selectSendingToAdd,
  selectSendingToUpdate,
  Sending,
  sendingAdded,
  sendingsAdded,
  sendingsCampaignSuggestionsReceived,
  sendingsCampaignSuggestionsRequested,
  sendingsCreatedBySuggestionsReceived,
  sendingsCreatedBySuggestionsRequested,
  sendingsEmailSubjectSuggestionsReceived,
  sendingsEmailSubjectSuggestionsRequested,
  sendingsMediumNameSuggestionsReceived,
  sendingsMediumNameSuggestionsRequested,
  sendingsRecipientNameOrEmailAddressSuggestionsReceived,
  sendingsRecipientNameOrEmailAddressSuggestionsRequested,
  sendingsRemoved,
  sendingsTagSuggestionsReceived,
  sendingsTagSuggestionsRequested,
  sendingsTitlesSuggestionsReceived,
  sendingsTitlesSuggestionsRequested,
  sendingsUpdated,
  sendingUpdated,
  setTotalNumberOfSendings,
  updateSending,
  updateSendingRequested,
  updateSendings,
  updateSendingsRequested,
  selectFilterSuggestionsPageSize,
  selectFilterSuggestionsSearchText,
  allTagsReceived,
  getAllSendingsTagsRequested,
  selectTagsToDelete,
  deleteTagsRequested,
  selectTagNameToCreate,
  addedNewTag,
  addNewTagRequested,
  selectSelectedSendingsIds,
  selectFilterItems,
  selectSortingInput,
  SendingSortingInput,
  tagsDeleted,
  duplicateSendingRequested,
  sendingDuplicated,
  selectSendingToDuplicateLocalizedTitlePart,
  selectSendingsToDuplicateIds,
  selectQuickFilterItems,
  shouldNavigateToDuplicatedSending,
  setIsChoosingSendingAsFavorite
} from 'app/pages/my-activities/sendings';
import { addedTagToSending, removedTagsFromSending } from 'app/pages/my-activities/sending-wizard';

function* fetchPageOfSendingsByQueryParams() {
  const pageNumber = yield select(selectPageNumber);
  const pageSize = yield select(selectPageSize);
  const filterItems = yield select(selectFilterItems);
  const quickFilterItems = yield select(selectQuickFilterItems);
  const searchText = yield select(selectSearchText);
  const sortingInput: SendingSortingInput = yield select(selectSortingInput);

  const allFilterItems = filterItems.concat(quickFilterItems);

  const pageOfSendings: PageOfSendings = yield call(fetchSendingsByQueryParams, client, pageNumber, pageSize, allFilterItems, searchText, sortingInput);

  if (pageNumber === 1) {
    yield put(firstPageOfSendingsReceived(pageOfSendings.sendings));
  } else {
    yield put(nextPageOfSendingsReceived(pageOfSendings.sendings));
  }

  yield put(setTotalNumberOfSendings(pageOfSendings.totalCount));
}

export function* fetchSendingsRequestedWatcher() {
  yield takeLatest(firstPageOfSendingsRequested.type, safe(fetchPageOfSendingsByQueryParams));
  yield takeLatest(nextPageOfSendingsRequested.type, safe(fetchPageOfSendingsByQueryParams));
}

export function* updateSendingRequestedWatcher() {
  yield takeLatest(updateSendingRequested.type, safelyHandleError({ performAction: updateSendingFlow, onFinally: finishUpdatingSendingFlow }));
}

function* updateSendingFlow() {
  const sendingToUpdate: Sending = yield select(selectSendingToUpdate);

  const updatedSending = yield call(updateSending, client, sendingToUpdate.id, sendingToUpdate);
  yield put(sendingUpdated(updatedSending));
}

function* finishUpdatingSendingFlow() {
  yield put(setIsChoosingSendingAsFavorite(false));
}

export function* addSendingRequestedWatcher() {
  yield takeLatest(addSendingRequested.type, safe(addSendingFlow));
}

function* addSendingFlow() {
  const sendingToAdd: Sending = yield select(selectSendingToAdd);

  const addedSending = yield call(addSending, client, sendingToAdd);
  yield put(sendingAdded(addedSending));
}

export function* updateSendingsRequestedWatcher() {
  yield takeLatest(updateSendingsRequested.type, safe(updateSendingsFlow));
}

function* updateSendingsFlow() {
  const sendingsToUpdate: Sending[] = yield select(selectSendingsToUpdate);

  const updatedSendings = yield call(updateSendings, client, sendingsToUpdate);
  yield put(sendingsUpdated(updatedSendings));
}

export function* removeSendingRequestedWatcher() {
  yield takeLatest(removeSendingRequested.type, safe(removeSendingFlow));
}

// TODO Revisit this, sendingToRemoveid was never initialized in slice
function* removeSendingFlow() {
  const sendingToRemoveId = ''; //yield select(selectSelectedSendingId);

  const removedSendingId: string = yield call(removeSending, client, sendingToRemoveId);
  yield put(sendingsRemoved([removedSendingId]));
}

export function* removeSendingsRequestedWatcher() {
  yield takeLatest(removeSendingsRequested.type, safe(removeSendingsFlow));
}

function* removeSendingsFlow() {
  const sendingsToRemoveIds: string[] = yield select(selectSendingsToRemoveIds);

  const removedSendingIds = yield call(removeSendings, client, sendingsToRemoveIds);
  yield put(sendingsRemoved(removedSendingIds));
}

export function* addSendingsRequestedWatcher() {
  yield takeLatest(addSendingsRequested.type, safe(addSendingsFlow));
}

function* addSendingsFlow() {
  const sendingsToAdd: Sending[] = yield select(selectSendingsToAdd);

  const addedSendings = yield call(addSendings, client, sendingsToAdd);
  yield put(sendingsAdded(addedSendings));
}

export function* duplicateSendingRequestedWatcher() {
  yield takeLatest(duplicateSendingRequested.type, safe(duplicateSendingFlow));
}

function* duplicateSendingFlow() {
  const selectedSendingIds: string[] = yield select(selectSendingsToDuplicateIds);
  const localizedTitlePart: string = yield select(selectSendingToDuplicateLocalizedTitlePart);

  const addedSendings = yield call(duplicateSendings, client, selectedSendingIds, localizedTitlePart);
  yield put(sendingDuplicated(addedSendings[0]));
  yield put(shouldNavigateToDuplicatedSending(true));
}

export function* duplicateSendingsRequestedWatcher() {
  yield takeLatest(duplicateSendingsRequested.type, safe(duplicateSendingsFlow));
}

function* duplicateSendingsFlow() {
  const selectedSendingIds: string[] = yield select(selectSendingsToDuplicateIds);
  const localizedTitlePart: string = yield select(selectSendingToDuplicateLocalizedTitlePart);

  const addedSendings = yield call(duplicateSendings, client, selectedSendingIds, localizedTitlePart);
  yield put(sendingsAdded(addedSendings));
}

function* fetchSendingTitlesSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const take = yield select(selectFilterSuggestionsPageSize);

  const filterSuggestions = yield call(getTitleFilterSuggestions, client, searchText, take);

  yield put(sendingsTitlesSuggestionsReceived(filterSuggestions));
}

export function* fetchSendingsByTitleWatcher() {
  yield takeLatest(sendingsTitlesSuggestionsRequested.type, safe(fetchSendingTitlesSuggestionsFlow));
}

function* fetchSendingCreatedBySuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const take = yield select(selectFilterSuggestionsPageSize);

  const filterSuggestions = yield call(getCreatedByFilterSuggestions, client, searchText, take);

  yield put(sendingsCreatedBySuggestionsReceived(filterSuggestions));
}

export function* fetchSendingsByCreatedByWatcher() {
  yield takeLatest(sendingsCreatedBySuggestionsRequested.type, safe(fetchSendingCreatedBySuggestionsFlow));
}

function* fetchSendingCampaignSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const take = yield select(selectFilterSuggestionsPageSize);

  const filterSuggestions = yield call(getCampaignNameFilterSuggestions, client, searchText, take);

  yield put(sendingsCampaignSuggestionsReceived(filterSuggestions));
}

export function* fetchSendingsCampaignWatcher() {
  yield takeLatest(sendingsCampaignSuggestionsRequested.type, safe(fetchSendingCampaignSuggestionsFlow));
}

function* fetchSendingEmailSubjectFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const take = yield select(selectFilterSuggestionsPageSize);

  const filterSuggestions = yield call(getEmailSubjectFilterSuggestions, client, searchText, take);

  yield put(sendingsEmailSubjectSuggestionsReceived(filterSuggestions));
}

export function* fetchSendingsEmailSubjectWatcher() {
  yield takeLatest(sendingsEmailSubjectSuggestionsRequested.type, safe(fetchSendingEmailSubjectFlow));
}

function* fetchSendingTagFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const take = yield select(selectFilterSuggestionsPageSize);

  const filterSuggestions: string[] = yield call(getTagNameFilterSuggestions, client, searchText, take);

  yield put(sendingsTagSuggestionsReceived(filterSuggestions));
}

export function* fetchSendingsTagWatcher() {
  yield takeLatest(sendingsTagSuggestionsRequested.type, safe(fetchSendingTagFlow));
}

function* fetchSendingRecipientMediumNameFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const take = yield select(selectFilterSuggestionsPageSize);

  const filterSuggestions: string[] = yield call(getRecipientMediumNameFilterSuggestions, client, searchText, take);

  yield put(sendingsMediumNameSuggestionsReceived(filterSuggestions));
}

export function* fetchSendingsRecipientMediumNameWatcher() {
  yield takeLatest(sendingsMediumNameSuggestionsRequested.type, safe(fetchSendingRecipientMediumNameFlow));
}

function* fetchSendingRecipientEmailOrNameFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const take = yield select(selectFilterSuggestionsPageSize);

  const filterSuggestions: string[] = yield call(getRecipientEmailOrNameFilterSuggestions, client, searchText, take);

  yield put(sendingsRecipientNameOrEmailAddressSuggestionsReceived(filterSuggestions));
}

export function* fetchSendingsRecipientEmailOrNameWatcher() {
  yield takeLatest(sendingsRecipientNameOrEmailAddressSuggestionsRequested.type, safe(fetchSendingRecipientEmailOrNameFlow));
}

function* fetchAllSendingTagsFlow() {
  const allTags = yield call(fetchTagsByEntityType, client, 'Sending');
  yield put(allTagsReceived(allTags));
}

export function* getAllSendingTagsRequestedWatcher() {
  yield takeEvery(getAllSendingsTagsRequested.type, safe(fetchAllSendingTagsFlow));
}

function* deleteTagsFlow() {
  const tagsToDelete = yield select(selectTagsToDelete);
  const tagIdsToDelete = tagsToDelete.map((t) => t.id);

  yield call(deleteTags, client, tagIdsToDelete);

  yield put(tagsDeleted(tagIdsToDelete));
  yield put(removedTagsFromSending(tagIdsToDelete));
}

export function* deleteTagsFromSendingsRequestedWatcher() {
  yield takeEvery(deleteTagsRequested.type, safe(deleteTagsFlow));
}

function* addTagFlow() {
  const selectedSendingIds = yield select(selectSelectedSendingsIds);
  const tagToAdd = yield select(selectTagNameToCreate);

  const tagCreationInput = new TagCreationInput(tagToAdd, null, selectedSendingIds, 'Sending');

  const createdTag = yield call(createAndAssignTag, client, tagCreationInput);
  yield put(addedNewTag(createdTag));
  yield put(addedTagToSending(createdTag));
}

export function* addSendingTagRequestedWatcher() {
  yield takeEvery(addNewTagRequested.type, safe(addTagFlow));
}
