export const getFirstAndLastDaysOfTheCurrentWeek = (): [Date, Date] => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ...

  // Calculate the difference in days between the current day and the start of the week (Sunday)
  const diff = currentDay >= 0 ? currentDay - 0 : 6;

  // Calculate the first day of the week
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - diff);
  firstDay.setHours(0, 0, 0, 0);

  // Calculate the last day of the week
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (6 - diff));
  lastDay.setHours(23, 59, 59, 999);
  return [firstDay, lastDay];
};
