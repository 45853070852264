import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ProfilePanelWrap, useAppDispatch, useAppSelector } from 'app/common';
import {
  selectIsLoadingSelectedCompany,
  selectIsProfilePanelOpened,
  closeProfilePanel,
  CompanyProfilePanelIntro,
  CompanyProfilePivotItems,
  ProfileAccordion,
  ContactsTab,
  CompanyComments,
  resetToInitialState
} from 'app/pages/my-audience/company-profile';
import { ShimmerProfilePanelCompany } from 'app/common/components/shimmer/ShimmerProfilePanelCompany';

export const CompanyPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isProfilePanelOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const isLoadingSelectedCompany = useAppSelector<boolean>(selectIsLoadingSelectedCompany);

  const handleDismissButtonClick = () => {
    dispatch(closeProfilePanel());
    navigate(`/my-audience/companies/results/${params.companiesQuery}`);
    dispatch(resetToInitialState());
  };

  return (
    <>
      {isLoadingSelectedCompany ? (
        <ProfilePanelWrap isProfilePanelOpened={isProfilePanelOpened} onDismissButtonClick={handleDismissButtonClick} classModifier="company">
          <ShimmerProfilePanelCompany />
        </ProfilePanelWrap>
      ) : (
        <ProfilePanelWrap isProfilePanelOpened={isProfilePanelOpened} onDismissButtonClick={handleDismissButtonClick} classModifier="company">
          <div className="profile-panel-container">
            <CompanyProfilePanelIntro />
            <CompanyProfilePivotItems />
            <div className="profile-panel-main">
              <Routes>
                <Route path="profile" element={<ProfileAccordion />} />
                <Route path="contacts" element={<ContactsTab />} />
                <Route path="comments" element={<CompanyComments />} />
              </Routes>
            </div>
          </div>
        </ProfilePanelWrap>
      )}
    </>
  );
};
