interface WizardIntroProps {
  title: string;
  description: string;
}

export const WizardIntro = ({ title, description }: WizardIntroProps) => {
  return (
    <div className="wizard-panel-intro">
      <h5 className="wizard-panel-title">{title}</h5>
      <span className="wizard-panel-description">{description}</span>
    </div>
  );
};
