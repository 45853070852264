import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import {
  AddMediaOutletWizardSteps,
  goToStepAddMedia,
  selectAddMediaOutletCurrentStep,
  selectExistingMediaOutletToAddId,
  selectIsAddMediaOutletWizardProfileInfoStepValid
} from 'app/pages/my-audience/contact-profile';

export const AddMediaOutletSteps = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const step = useAppSelector<AddMediaOutletWizardSteps>(selectAddMediaOutletCurrentStep);
  const isAddMediaOutletWizardProfileInfoStepValid = useAppSelector(selectIsAddMediaOutletWizardProfileInfoStepValid);
  const existingMediaOutletToAddId = useAppSelector(selectExistingMediaOutletToAddId);

  const styleBreadcrumbStatus = (stepToCompareWith: AddMediaOutletWizardSteps): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: AddMediaOutletWizardSteps) => {
    dispatch(goToStepAddMedia(step));
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddMediaOutletWizardSteps.SearchMediaOutlet)}
            content={formatMessage({ id: 'add-medium-wizard-steps.search-medium' }, { mediumType: formatMessage({ id: 'labels.media-outlet' }) })}
            onClick={() => handleStepButtonClick(AddMediaOutletWizardSteps.SearchMediaOutlet)}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddMediaOutletWizardSteps.ProfileInformation)}
            content={formatMessage({ id: 'add-medium-wizard-steps.profile-information' })}
            onClick={() => handleStepButtonClick(AddMediaOutletWizardSteps.ProfileInformation)}
            disabled={!isAddMediaOutletWizardProfileInfoStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddMediaOutletWizardSteps.Finish)}
            content={formatMessage({ id: 'add-medium-wizard-steps.finish' })}
            onClick={() => handleStepButtonClick(AddMediaOutletWizardSteps.Finish)}
            disabled={!existingMediaOutletToAddId || !isAddMediaOutletWizardProfileInfoStepValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
