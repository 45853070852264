import { FilterItem } from 'app/common';
import {
  CompaniesSavedSearch as GqlCompaniesSavedSearch,
  ContactsSavedSearch as GqlContactsSavedSearch,
  MediaOutletsSavedSearch as GqlMediaOutletsSavedSearch
} from 'app/common/graphql/generated/graphql-gateway';
import { SavedSearch } from 'app/pages/my-audience/saved-searches';

export const createSavedSearch = (savedSearch: GqlCompaniesSavedSearch | GqlMediaOutletsSavedSearch | GqlContactsSavedSearch) => {
  return new SavedSearch(
    savedSearch.id,
    savedSearch.name,
    savedSearch.searchText,
    savedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    savedSearch.priority,
    savedSearch.count,
    new Date(savedSearch.createdAt),
    new Date(savedSearch.lastModifiedAt),
    savedSearch.createdBy
  );
};
