import { TableHeader } from 'app/common';

export const buildLocalizedSearchResultsColumns = (
  nameLabel: string,
  numberOfContactsLabel: string,
  mediumsLabel: string,
  createdByLabel: string,
  tagsLabel: string,
  createdAtLabel: string,
  lastUpdateLabel: string,
  sortAscendingAriaLabel: string,
  sortDescendingAriaLabel: string
): TableHeader[] => {
  return [
    {
      key: 'column0',
      name: nameLabel,
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingAriaLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column1',
      name: numberOfContactsLabel,
      fieldName: 'numberOfContacts',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingAriaLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column2',
      name: mediumsLabel,
      fieldName: 'mediumNames',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingAriaLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column3',
      name: createdByLabel,
      fieldName: 'createdBy',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingAriaLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column4',
      name: tagsLabel,
      fieldName: 'tags',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingAriaLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column5',
      name: createdAtLabel,
      fieldName: 'createdAt',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingAriaLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column7',
      name: lastUpdateLabel,
      fieldName: 'lastUpdate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingAriaLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    }
  ];
};
