import { useAppDispatch, useAppSelector } from 'app/common';
import { EmailBody } from 'app/pages/my-activities/sendings';
import {
  beeFreeJsonBodyHasContent,
  imageLoaded,
  selectEmailBodyInEditor,
  selectImageFromTemplate,
  selectIsImageLoading
} from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';
import { EmptySendingPreview } from 'app/pages/my-activities/sending-wizard/components/preview/EmptySendingPreview';

export const SendingImagePreview = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const image = useAppSelector(selectImageFromTemplate);
  const emailBody = useAppSelector<EmailBody>(selectEmailBodyInEditor);
  const jsonBodyHasContent = beeFreeJsonBodyHasContent(emailBody?.jsonBody);
  const isImageLoading = useAppSelector(selectIsImageLoading);

  const onImageLoaded = () => {
    dispatch(imageLoaded());
  };

  return (
    <div className={isImageLoading ? 'hidden' : ''}>
      {jsonBodyHasContent ? (
        <img
          src={image}
          className="sending-image-preview"
          onLoad={onImageLoaded}
          alt={formatMessage({ id: 'emailing-wizard-preview-and-send-step.template-preview-image-alt' })}
        />
      ) : (
        <EmptySendingPreview onImageLoaded={onImageLoaded} />
      )}
    </div>
  );
};
