import React, { useState } from 'react';
import { Icon, Overlay } from '@fluentui/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  openTableMobileColumnSelectionPopup,
  closeTableMobileColumnSelectionPopup,
  TableHeader,
  useAppDispatch,
  useAppSelector,
  selectSearchResultsColumnNumber
} from 'app/common';
import 'app/common/components/table/searchResultsTable.scss';
import { useIntl } from 'app/i18n';

interface NewTablePopupMobileProps {
  tableHeader: TableHeader[];
  onUpdateTableHeader: (updatedHeader: TableHeader[]) => void;
  fixedColumnIndex: number;
}

export const NewTablePopupMobile = ({ tableHeader, onUpdateTableHeader, fixedColumnIndex }: NewTablePopupMobileProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const columnNumber = useAppSelector<number>(selectSearchResultsColumnNumber);
  const [togglePopup, setTogglePopup] = useState(false);
  const [previousItemIndex, setPreviousItemIndex] = useState(0);
  const [localTableHeader, setLocalTableHeader] = useState(tableHeader);
  const visibleColumns = [];

  const columnsToAdd = localTableHeader
    .filter((column) => column.isColumnVisible === false)
    .map((column, i) => (
      <li key={column.key} onClick={() => switchTableHeader(column.name)} id={'item-' + i} className={column.className + ' table-dialog-popup-item'}>
        {column.name}
      </li>
    ));

  // TODO: hanging code, this will be executed on each render in app, we should wrap in some hook
  for (let i = 0; i < localTableHeader.length; i++) {
    if (localTableHeader[i].isColumnVisible === true && localTableHeader[i].fieldName !== 'add') {
      visibleColumns.push({
        fieldName: localTableHeader[i].fieldName,
        element: (
          <div key={localTableHeader[i].key} className="table-dialog-item">
            <span className="table-dialog-item-num">{i + 1 + '.'}</span>
            <span className="table-dialog-item-name">{localTableHeader[i].name}</span>
            {i !== 0 && (
              <div className="table-dialog-item-options">
                <div className="table-dialog-icons">
                  <button type="button" className="table-dialog-icon button-dropdown" onClick={() => onTogglePopup(i)}>
                    <Icon iconName="FlickUp" className="icon-flick" />
                  </button>
                  <button type="button" className="table-dialog-icon">
                    <Icon iconName="CalculatorEqualTo" className="icon-lines" />
                  </button>
                </div>
                {togglePopup && previousItemIndex === i && (
                  <div className="table-dialog-popup">
                    <ul className="table-dialog-popup-list">{columnsToAdd}</ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      });
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const destinationIndex = localTableHeader.findIndex((column) => visibleColumns[result.destination.index - 1].fieldName === column.fieldName);
    const sourceIndex = localTableHeader.findIndex((column) => visibleColumns[result.source.index - 1].fieldName === column.fieldName);

    const tempArray = [...localTableHeader];
    const tempElement = { ...tempArray[destinationIndex] };
    tempArray[destinationIndex] = { ...tempArray[sourceIndex] };
    tempArray[sourceIndex] = { ...tempElement };

    setLocalTableHeader(tempArray);
  };

  const closeTableOptions = () => {
    dispatch(closeTableMobileColumnSelectionPopup());
  };

  const acceptTableOptions = () => {
    onUpdateTableHeader(localTableHeader);
    dispatch(openTableMobileColumnSelectionPopup());
    closeTableOptions();
  };

  const switchTableHeader = (itemToAddName: string) => {
    const itemToAddIndex = localTableHeader.findIndex((column) => column.name === itemToAddName);
    const updatedArray = [...localTableHeader];
    const oldElement = { ...updatedArray[previousItemIndex], isColumnVisible: false };
    updatedArray[previousItemIndex] = { ...updatedArray[itemToAddIndex], isColumnVisible: true };
    updatedArray[itemToAddIndex] = oldElement;

    setLocalTableHeader(updatedArray);
    setTogglePopup(false);
  };

  const onTogglePopup = (i: number) => {
    setPreviousItemIndex(i);
    setTogglePopup(!togglePopup);
  };

  return (
    <Overlay isDarkThemed={true} className="table-dialog-overlay">
      <div className="table-dialog-wrap">
        <div className="table-dialog-intro">
          <button type="button" className="table-dialog-icon" onClick={() => closeTableOptions()}>
            <Icon iconName="clear" className="icon-clear" />
          </button>
          <h5 className="table-dialog-title">{formatMessage({ id: 'aria-labels.add-new-category' })}</h5>
          <button className="table-dialog-icon" onClick={() => acceptTableOptions()}>
            <Icon iconName="CheckMark" className="icon-check" />
          </button>
        </div>
        <div className="table-dialog-content">
          <p className="table-dialog-description">{formatMessage({ id: 'aria-labels.add-new-category' })}</p>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <ul className="table-dialog-list" {...provided.droppableProps} ref={provided.innerRef}>
                  {visibleColumns[fixedColumnIndex].element}
                  {visibleColumns.slice(0, columnNumber).map(
                    (item, index) =>
                      index !== fixedColumnIndex && (
                        <Draggable key={index} draggableId={index.toString()} index={index + 1}>
                          {(provided) => (
                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.element}
                            </li>
                          )}
                        </Draggable>
                      )
                  )}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </Overlay>
  );
};
