export class Contact {
  constructor(
    public lastName: string,
    public salutation?: string,
    public firstName?: string,
    public profilePictureUrl?: string,
    public landlinePhoneNumber?: string,
    public mobilePhoneNumber?: string,
    public fax?: string,
    public emailAddress?: string,
    public street?: string,
    public city?: string,
    public postalCode?: string,
    public country?: string,
    public collaborationMediumName?: string,
    public collaborationMediumType?: string,
    public JobTitleName?: string,
    public jobTitleDescription?: string,
    public personalPhoneNumber?: string,
    public providerLandlinePhoneNumber?: string,
    public providerMobilePhoneNumber?: string,
    public providerFax?: string,
    public providerEmailAddress?: string,
    public protocolTitle?: string,
    public comment?: string,
    public preferredLanguage?: string,
    public dataSourceKey?: string
  ) {}
}
