import { Comment, CommentUser, MediumCategory, MediumCategoryType, TagProjection } from 'app/common/common.model';
import { Company as GqlCompany } from 'app/common/graphql/generated/graphql-gateway';
import { Company, CompanyContact } from 'app/pages/my-audience/companies';
import { MediumType, MediumTypeName } from 'app/pages/my-audience/contacts';
import { MediumContactData } from 'app/pages/my-audience/media-outlets';

export const createCompany = (gqlCompany: GqlCompany): Company => {
  return new Company(
    gqlCompany.id,
    gqlCompany.name,
    gqlCompany.profilePictureUrl,
    gqlCompany.parentCompany,
    gqlCompany.contactData
      ? new MediumContactData(
          gqlCompany.contactData.street,
          gqlCompany.contactData.poBox,
          gqlCompany.contactData.postalCode,
          gqlCompany.contactData.country,
          gqlCompany.contactData.city,
          gqlCompany.contactData.websiteUrl,
          gqlCompany.contactData.emailAddress,
          gqlCompany.contactData.landlinePhoneNumber,
          gqlCompany.contactData.fax,
          gqlCompany.contactData.alternativeFax
        )
      : ({} as MediumContactData),
    gqlCompany.databaseType,
    gqlCompany.registrationNumber,
    gqlCompany.tags ? gqlCompany.tags.map((tag) => new TagProjection(tag.id, tag.name)) : [],
    gqlCompany.comments,
    gqlCompany.companyContacts
      ? gqlCompany.companyContacts.map((item) => new CompanyContact(item.id, item.firstName, item.lastName, item.profilePictureUrl))
      : [],
    gqlCompany.userComments
      ? gqlCompany.userComments.map(
          (comment) =>
            new Comment(
              comment.id,
              new CommentUser(comment?.author?.teamsId, comment?.author?.name, comment?.author?.aadObjectId),
              comment.content,
              new Date(comment.createdAt),
              comment.replies.map(
                (reply) =>
                  new Comment(
                    reply.id,
                    new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
                    reply.content,
                    new Date(reply.createdAt),
                    [],
                    reply.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
                  )
              ),
              comment.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
            )
        )
      : [],
    new Date(gqlCompany.foundationDate),
    gqlCompany.competitors,
    gqlCompany.profit,
    gqlCompany.director,
    gqlCompany.description,
    gqlCompany.category
      ? new MediumCategory(gqlCompany.category.id, gqlCompany.category.name, new MediumCategoryType(MediumTypeName(MediumType.Company), 2))
      : ({} as MediumCategory),
    gqlCompany.dataSourceKey
  );
};
