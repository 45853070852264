import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const ACCESS_TOKEN_CACHE = 'access_token';
export const MICROSOFT_ACCESS_TOKEN_CACHE = 'microsoft_access_token';

const msalMSConfig = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/',
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.info(message);
            return;
        }
      }
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0
  }
};

export const msalInstance = new PublicClientApplication(msalMSConfig);

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

export const accessTokenScopes = ['user.read', 'openid', 'profile', 'people.read', 'user.readbasic.all', 'group.read.all'];

export const teamsMsal2ProviderConfiguration = {
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  scopes: accessTokenScopes,
  authPopupUrl: '/auth',
  autoConsent: true,
  ssoUrl: process.env.REACT_APP_MGTP_URL + '/api/token'
};

export enum Permission {
  Administrator = 'Administrator',
  ManageCompanies = 'ManageCompanies',
  ManageContacts = 'ManageContacts',
  ManageLists = 'ManageLists',
  ManageEvents = 'ManageEvents',
  ManageOwnCompanyAndItsEmployees = 'ManageOwnCompanyAndItsEmployees',
  ManageAppointments = 'ManageAppointments',
  ManageTasks = 'ManageTasks',
  ManageGdprRequests = 'ManageGdprRequests',
  ManageMediaOutlets = 'ManageMediaOutlets',
  ManageNewsReleases = 'ManageNewsReleases',
  ManageEmailings = 'ManageEmailings',
  GlobalAdministrator = 'GlobalAdministrator'
}
