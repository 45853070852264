import { PillData, UndoablePills, Tag, useAppDispatch, useAppSelector } from 'app/common';
import { addNewTagRequested, deleteTagsRequested, filterTags, selectAllTags, selectFilteredTagsInPanel } from 'app/pages/my-activities/sendings';
import {
  addTagToSendingRequested,
  addedTagToSending,
  removeTagFromSendingRequested,
  removedTagsFromSending,
  selectSelectedSendingId,
  selectSendingTagsHeaderValue
} from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';

interface SendingTagsProps {
  disabled: boolean;
}

export const SendingTags = ({ disabled }: SendingTagsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedSendingId = useAppSelector<string>(selectSelectedSendingId);
  const selectedTags = useAppSelector<Tag[]>(selectSendingTagsHeaderValue);
  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);

  const onAddNewTag = (text: string) => dispatch(addNewTagRequested({ sendingIds: [selectedSendingId], text }));

  const handleSelectInStore = (id: string) => {
    const tagToSelect = allTags.find((t) => t.id === id);
    dispatch(addedTagToSending(tagToSelect));
  };

  const handleDeselectInStore = (id: string) => {
    const tagToDeselect = allTags.find((t) => t.id === id);
    dispatch(removedTagsFromSending([tagToDeselect.id]));
  };

  const onAddTagToSending = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addTagToSendingRequested(tag));
  };

  const onDeleteTags = (tagIds: string[]) => {
    const tags = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tags));
  };

  const onFilterTags = (text: string) => {
    dispatch(filterTags(text));
  };

  const onRemoveTagFromSending = (id: string) => {
    const tag = allTags.find((t) => t.id === id);

    dispatch(removeTagFromSendingRequested(tag));
  };

  return (
    <UndoablePills
      entityName={formatMessage({ id: 'labels.tag' })}
      selectedValues={selectedTags?.map((t) => new PillData(t.id, t.name, true))}
      dropdownValues={filteredTags?.map((t) => new PillData(t.id, t.name, true))}
      onAddValue={onAddNewTag}
      onSelectValue={onAddTagToSending}
      onDeselectValue={onRemoveTagFromSending}
      onDeleteValues={onDeleteTags}
      filterValues={onFilterTags}
      enabledDelete={true}
      onDeselectValueInStore={handleDeselectInStore}
      onSelectValueInStore={handleSelectInStore}
      onUndoDeselectValueInStore={handleSelectInStore}
      onUndoSelectValueInStore={handleDeselectInStore}
      disabled={disabled}
    />
  );
};
