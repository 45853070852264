import { IntlMessage, NestedKeys } from 'app/i18n';
import { useCallback } from 'react';
import type { IntlFormatters } from 'react-intl';
import { useIntl as useReactIntl } from 'react-intl';

// The arguments to the original formatMessage function.
type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

export const useIntl = () => {
  // Pull out the original formatMessage function.
  const { formatMessage, ...rest } = useReactIntl();

  // Re-write the formatMessage function but with an enhanced id type.
  const typedFormatMessage = useCallback(
    (
      descriptor: FormatMessageArgs[0] & {
        id?: NestedKeys<IntlMessage>;
      },
      values?: FormatMessageArgs[1],
      options?: FormatMessageArgs[2]
    ) => {
      return formatMessage(descriptor, values, options) as string;
    },
    [formatMessage]
  );

  return {
    ...rest,
    formatMessage: typedFormatMessage
  };
};
