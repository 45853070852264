import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  selectPageSize,
  selectIsLoadingTemplates,
  selectTemplatesTotalCount,
  setTemplatesPageNumber,
  templatesRequested,
  setTemplatesPageSize,
  selectSearchText
} from 'app/pages/my-activities/templates';

export const TemplatesPaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfTemplates = useAppSelector<number>(selectTemplatesTotalCount);
  const isLoadingTemplates = useAppSelector<boolean>(selectIsLoadingTemplates);
  const searchText = useAppSelector<string>(selectSearchText);

  const pageSize = useAppSelector<number>(selectPageSize);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setTemplatesPageNumber(pageNumber));
    dispatch(templatesRequested(searchText));
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setTemplatesPageSize(pageSize));
    dispatch(templatesRequested(searchText));
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfTemplates}
      isLoadingData={isLoadingTemplates}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
