import { useEffect } from 'react';
import { Accordion, Button, ChevronDownMediumIcon, MenuButton } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { Collaboration } from 'app/pages/my-audience/contacts';
import {
  selectMediaOutletActiveClass,
  selectMediaOutletActiveIndex,
  resetMediaOutletActiveClass,
  setMediaOutletActiveIndex,
  MediaOutletInfo,
  openRemoveMediaOutletDialog,
  RemoveMediaOutletDialog,
  setCollaborationToRemove
} from 'app/pages/my-audience/contact-profile';
import { FormattedMessage, useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';

interface MediaOutletsPreviewProps {
  mediaOutletCollaborations: Collaboration[];
}

export const MediaOutletsPreview = ({ mediaOutletCollaborations }: MediaOutletsPreviewProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();

  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);

  const mediaOutletActiveIndex = useAppSelector<number[]>(selectMediaOutletActiveIndex);
  const mediaOutletActiveClass = useAppSelector<string>(selectMediaOutletActiveClass);

  useEffect(() => {
    if (mediaOutletActiveClass === 'active') {
      setTimeout(() => {
        dispatch(resetMediaOutletActiveClass());
      }, 5000);
    }
  }, [dispatch, mediaOutletActiveClass]);

  const handleClickRemoveButton = () => {
    dispatch(openRemoveMediaOutletDialog());
  };

  const actionItem = [
    {
      key: 'remove-collaboration',
      content: (
        <Button
          className="accordion-head-box-multiple-popup-item"
          icon={<Icon iconName="Delete" />}
          iconPosition="before"
          content={formatMessage({ id: 'media-outlet-and-company-tabs.remove-collaboration-button' })}
          onClick={handleClickRemoveButton}
          disabled={!hasPermissionToEdit}
        />
      )
    }
  ];

  const onCollaborationClick = (collaboration: Collaboration) => {
    dispatch(setCollaborationToRemove(collaboration));
  };

  return (
    <div className="editable-fields-accordion-wrap">
      <Accordion
        activeIndex={mediaOutletActiveIndex}
        panels={mediaOutletCollaborations.map((c, i) => ({
          title: (
            <div key={'contact-media-outlet-title-' + i}>
              <div
                className={
                  i === mediaOutletCollaborations.length - 1 ? 'editable-fields-accordion-head ' + mediaOutletActiveClass : 'editable-fields-accordion-head'
                }
              >
                <div className="editable-fields-accordion-content" onClick={() => dispatch(setMediaOutletActiveIndex(i))}>
                  <ChevronDownMediumIcon />
                  <span className="editable-fields-accordion-heading">
                    {c.jobTitle.name} {c.jobTitle.name ? formatMessage({ id: 'media-outlet-and-company-tabs.accordion-header' }) : ''} {c.medium.name}
                  </span>
                </div>
                <div className="editable-fields-accordion-action">
                  <MenuButton
                    trigger={<Button text className="editable-fields-accordion-action-trigger" icon={<Icon iconName="MoreVertical" />} />}
                    menu={actionItem}
                    on="click"
                    onClick={() => onCollaborationClick(c)}
                  />
                </div>
              </div>
            </div>
          ),
          content: {
            key: 'contact-media-outlet-content-' + i,
            content: <MediaOutletInfo mediaOutletInfo={c} />
          }
        }))}
      />
      <RemoveMediaOutletDialog />
    </div>
  );
};
