import { Checkbox, Dropdown, DropdownProps } from '@fluentui/react-northstar';
import { DropdownItem, getDropdownA11ySelectionMessage, mapDropdownItems, selectIsOnDesktop, useAppDispatch, useAppSelector } from 'app/common';
import { Collaboration, Contact, ContactDataType, OwnContactData } from 'app/pages/my-audience/contacts';
import {
  changeSelectedContactData,
  selectSelectedContactData,
  selectSelectedContact,
  updatePrimaryCollaborationRequested,
  updatePrimaryOwnContactDataRequested,
  setIsChoosingContactDataAsPrimary,
  selectIsChoosingContactDataAsPrimary
} from 'app/pages/my-audience/contact-profile';
import { useIntl } from 'app/i18n';
import { useState } from 'react';

const OWN_CONTACT_DATA = 'Own contact data';

interface AboutIntroProps {
  disabled: boolean;
}

export const AboutIntro = ({ disabled }: AboutIntroProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const selectedContactData = useAppSelector<Collaboration | OwnContactData>(selectSelectedContactData);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const isChoosingContactDataAsPrimary = useAppSelector<boolean>(selectIsChoosingContactDataAsPrimary);

  const collaborationItems: DropdownItem[] = mapDropdownItems(selectedContact.collaborations, (collaboration) => collaboration.medium.name);
  if (selectedContact.ownContactData) {
    collaborationItems.push({ key: OWN_CONTACT_DATA, header: formatMessage({ id: 'contacts-about-accordion-item.own-contact-data-label' }) });
  }
  const selectedItemKey = (selectedContactData as Collaboration)?.id ? (selectedContactData as Collaboration).id : OWN_CONTACT_DATA;
  const selectedCollaboration = collaborationItems.find((item) => item.key === selectedItemKey);

  const onCollaborationChange = (_, { value }) => {
    dispatch(changeSelectedContactData(value.key));
  };

  const onPrimaryChange = () => {
    if (selectedContactData?.isPrimary) {
      return;
    }

    const updatedContactData = { ...selectedContactData, isPrimary: true };
    selectedContactData.type === ContactDataType.Collaboration
      ? dispatch(updatePrimaryCollaborationRequested(updatedContactData as Collaboration))
      : dispatch(updatePrimaryOwnContactDataRequested(updatedContactData as OwnContactData));

    dispatch(setIsChoosingContactDataAsPrimary(true));
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);

  return (
    <div className="eidtable-fields-intro">
      <div className="editable-fields-intro-dropdown">
        <Dropdown
          items={collaborationItems}
          value={selectedCollaboration}
          getA11ySelectionMessage={getDropdownA11ySelectionMessage}
          checkable
          onChange={onCollaborationChange}
          onBlur={handleBlur}
          open={isOpen}
          onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
        />
      </div>
      {isOnDesktop && (
        <div className="editable-fields-intro-checkbox">
          <Checkbox
            label={formatMessage({ id: 'contacts-about-accordion-item.set-as-primary-checkbox-label' })}
            checked={selectedContactData?.isPrimary}
            onClick={onPrimaryChange}
            disabled={disabled || isChoosingContactDataAsPrimary}
          />
        </div>
      )}
    </div>
  );
};
