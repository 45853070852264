import { SyntheticEvent } from 'react';
import { Icon } from '@fluentui/react';
import { Button, MenuButton } from '@fluentui/react-northstar';
import { ThreeDotMenuItems } from 'app/common';

interface ThreeDotMenuProps {
  threeDotMenuItems: ThreeDotMenuItems[];
  onMenuButtonClick?: () => void;
  iconName?: string;
}

export const ThreeDotMenu = ({ threeDotMenuItems, onMenuButtonClick, iconName = 'MoreVertical' }: ThreeDotMenuProps) => {
  return (
    <MenuButton
      onClick={onMenuButtonClick}
      trigger={
        <Button
          icon={<Icon iconName={iconName} />}
          iconOnly
          text
          onClick={(e: SyntheticEvent<HTMLElement, Event>) => {
            e.stopPropagation();
          }}
        />
      }
      menu={threeDotMenuItems.map(({ className, title, icon, disabled, onClick, key }: ThreeDotMenuItems) => {
        return {
          className,
          content: title,
          icon,
          disabled,
          onClick,
          key
        };
      })}
    />
  );
};
