import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { debounceTimeInMilliseconds, selectMediaOutletJobTitles, useAppDispatch, useAppSelector } from 'app/common';
import { JobTitle } from 'app/pages/my-audience/contacts';
import {
  AddContactWizard,
  ContactsTable,
  ContactsPaging,
  openAddContactWizard,
  pageOfContactsRequested,
  pageOfMediaOutletContactsRequested,
  setMediaOutletContactsPageNumber,
  setSearchContactsTextAndFilterItem,
  setSearchMediaOutletContactsTextAndFilterItem
} from 'app/pages/my-audience/media-outlet-profile';
import { PanelToolbar } from 'app/pages/my-audience/common/components/panel-toolbar/PanelToolbar';
import { useAuthContext, Permission } from 'auth';

export const ContactsTab = () => {
  const dispatch = useAppDispatch();

  const mediaOutletJobTitles = useAppSelector<JobTitle[]>(selectMediaOutletJobTitles);
  const [inputValue, setInputValue] = useState('');
  const { hasPermission } = useAuthContext();

  const hasPermissionToAddCollaboration = hasPermission(Permission.ManageMediaOutlets);

  const handleSearchByNameAndRole = useDebouncedCallback((searchText: string, role: string) => {
    dispatch(setMediaOutletContactsPageNumber(1));
    dispatch(setSearchMediaOutletContactsTextAndFilterItem({ searchText, role }));
    dispatch(pageOfMediaOutletContactsRequested());
    setInputValue(searchText);
  }, debounceTimeInMilliseconds);

  const handleOpenWizard = () => {
    dispatch(setMediaOutletContactsPageNumber(1));
    dispatch(setSearchContactsTextAndFilterItem({ searchText: '', role: '' }));
    dispatch(pageOfContactsRequested());
    dispatch(openAddContactWizard());
  };

  return (
    <>
      <PanelToolbar
        filterItems={mediaOutletJobTitles.map((jobTitle) => jobTitle.name)}
        onSearchByNameAndRole={handleSearchByNameAndRole}
        onOpenWizard={handleOpenWizard}
        inputValue={inputValue}
        disabled={!hasPermissionToAddCollaboration}
      />
      <ContactsTable />
      <ContactsPaging />
      <AddContactWizard />
    </>
  );
};
