import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import {
  AudienceTable,
  AudienceTablePaging,
  AudienceToolbar,
  resetSelectedSending,
  goToNextStep,
  goToPreviousStep,
  pageOfRecipientsRequested,
  selectRecipientsTotalCount,
  SendingWizardFooter,
  SendingWizardSteps,
  setRecipientsQueryParams
} from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';
import { AddContactFromCompanyWizard, AddContactsFromListsWizard, AddContactsWizard } from 'app/pages/my-activities/sending-wizard';

export const Audience = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const recipientsCount = useAppSelector<number>(selectRecipientsTotalCount);

  useEffect(() => {
    dispatch(setRecipientsQueryParams({ pageNumber: 1, searchText: '' }));
    dispatch(pageOfRecipientsRequested());
  }, [dispatch]);

  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };

  const handleSaveAndCloseButtonClick = () => {
    dispatch(resetSelectedSending());
  };

  return (
    <>
      <SendingWizardSteps areFieldsValid={true} />
      <div className="wizard-panel-form-wrap audience-form-wrap">
        <WizardIntro
          title={formatMessage({ id: 'emailing-wizard-audience-step.header' })}
          description={formatMessage({ id: 'emailing-wizard-audience-step.description' }, { numberOfRecipients: recipientsCount })}
        />
        <AudienceToolbar />
        <AudienceTable />
        <AddContactsWizard />
        <AddContactFromCompanyWizard />
        <AddContactsFromListsWizard />
        <AudienceTablePaging />
        <SendingWizardFooter
          onSaveAndCloseButtonClick={handleSaveAndCloseButtonClick}
          onNextButtonClick={handleNextButtonClick}
          onBackButtonClick={handleBackButtonClick}
        />
      </div>
    </>
  );
};
