import { FilterItem } from 'app/common';
import { AvatarDetails } from 'app/pages/my-audience/saved-searches';

export class MediaOutletsAvatarDetailsInput {
  constructor(public savedSearchId: string, public searchText: string, public filterItems: FilterItem[]) {}
}

export class MediaOutletsAvatarDetailsWithSavedSearchId {
  constructor(public savedSearchId: string, public avatarDetails: AvatarDetails[], public totalCount: number) {}
}
