import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddContact,
  AddContactFinish,
  AddContactWizardSteps,
  AddProfileInformation,
  closeAddContactWizard,
  selectAddContactCurrentStep,
  selectIsAddContectWizardOpened
} from 'app/pages/my-audience/company-profile';

export const AddContactWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector<AddContactWizardSteps>(selectAddContactCurrentStep);
  const isAddContactWizardOpened = useAppSelector<boolean>(selectIsAddContectWizardOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeAddContactWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isAddContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'companies-add-contact.wizard-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === AddContactWizardSteps.SearchContacts && <AddContact />}
        {currentStep === AddContactWizardSteps.ProfileInformation && <AddProfileInformation />}
        {currentStep === AddContactWizardSteps.Finish && <AddContactFinish />}
      </div>
    </WizardWrap>
  );
};
