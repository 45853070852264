import { DetailsListLayoutMode, MarqueeSelection, Selection, ShimmeredDetailsList } from '@fluentui/react';
import { TableHeader, useAppSelector, usePersistentSelection } from 'app/common';
import { ContactsTableData } from 'app/pages/my-audience/wizard-create-media-outlet';
import {
  selectContactsTableData,
  selectIsLoadingContacts,
  selectPageNumber,
  selectSelectedCollaborationsToAddPerPage
} from 'app/pages/my-audience/wizard-create-company';
import { useIntl } from 'app/i18n';
import { CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';

interface CollaborationTableProps {
  selection: Selection;
}

export const CollaborationTable = ({ selection }: CollaborationTableProps) => {
  const tableRows = useAppSelector<ContactsTableData[]>(selectContactsTableData);
  const { formatMessage } = useIntl();
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingContacts);
  const page = useAppSelector<number>(selectPageNumber);
  const collaborationDetailsPerPage = useAppSelector(selectSelectedCollaborationsToAddPerPage);
  const contactsTableHeader: TableHeader[] = [
    {
      key: 'column0',
      name: formatMessage({ id: 'add-menu-create-mo.collaboration-table-name' }),
      fieldName: 'name',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column1',
      name: formatMessage({ id: 'add-menu-create-mo.collaboration-table-job' }),
      fieldName: 'jobTitle',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column2',
      name: formatMessage({ id: 'add-menu-create-mo.collaboration-table-email' }),
      fieldName: 'emailAddress',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column3',
      name: formatMessage({ id: 'companies-contact-table.phone' }),
      fieldName: 'landlinePhoneNumber',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    }
  ];

  const getContactId = <T,>(entity: T): string => {
    const collaboration = entity as CollaborationWithContactInfo;
    return collaboration.contactId;
  };

  usePersistentSelection({
    tableRows: tableRows,
    entitiesPerPage: collaborationDetailsPerPage,
    selection,
    currentPageNumber: page,
    getEntityId: getContactId
  });

  return (
    <>
      <div className="wizard-panel-table">
        <MarqueeSelection selection={selection} isDraggingConstrainedToRoot={true}>
          <ShimmeredDetailsList
            enableShimmer={isLoadingContacts}
            checkboxVisibility={1}
            items={tableRows}
            columns={contactsTableHeader}
            setKey="wizardCreateCompanySet"
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection}
            selectionPreservedOnEmptyClick={false}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
          />
        </MarqueeSelection>
      </div>
    </>
  );
};
