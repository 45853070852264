import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Comment, CommentUser, createCompany, createContact, MediumCategory, ProfilePicture } from 'app/common';
import {
  AddCommentToCompanyDocument,
  AddCommentToCompanyMutation,
  CompaniesCommentInput,
  CompaniesProfilePictureInput,
  Company as GqlCompany,
  CompanyInput,
  Contact as GqlContact,
  FetchCompanyByIdDocument,
  FetchCompanyByIdQuery,
  FetchCompanyCategoriesDocument,
  FetchCompanyCategoriesQuery,
  FetchContactsByCompanyIdNameAndRoleDocument,
  FetchContactsByCompanyIdNameAndRoleQuery,
  FetchContactsByNameAndRoleAndNotContainedInCompanyDocument,
  FetchContactsByNameAndRoleAndNotContainedInCompanyQuery,
  RemoveCompanyProfilePictureDocument,
  RemoveCompanyProfilePictureMutation,
  UpdateCompanyDocument,
  UpdateCompanyMutation,
  UpdateCompanyProfilePictureDocument,
  UpdateCompanyProfilePictureMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { Company, CompanyUpdateModel } from 'app/pages/my-audience/companies';
import { PageOfContacts } from 'app/pages/my-audience/contacts';

export const fetchSelectedCompanyProfile = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<Company> => {
  const response = await client.query<FetchCompanyByIdQuery>({
    query: FetchCompanyByIdDocument,
    variables: {
      id
    }
  });

  return createCompany(response.data.company as GqlCompany);
};

export const addCommentToCompany = async (client: ApolloClient<NormalizedCacheObject>, companyId: string, comment: Comment): Promise<Comment> => {
  const input: CompaniesCommentInput = {
    author: { teamsId: comment.author.teamsId, name: comment.author.name, aadObjectId: comment.author.aadObjectId },
    content: comment.content,
    createdAt: comment.createdAt,
    mentions: comment?.mentions?.map((m) => {
      return { teamsId: m.teamsId, name: m.name, aadObjectId: m.aadObjectId };
    }),
    replies: comment.replies
  };

  const response = await client.mutate<AddCommentToCompanyMutation>({
    mutation: AddCommentToCompanyDocument,
    variables: {
      companyId,
      input
    }
  });

  return new Comment(
    response.data.addCommentToCompany.id,
    new CommentUser(
      response.data.addCommentToCompany?.author?.teamsId,
      response.data.addCommentToCompany?.author?.name,
      response.data.addCommentToCompany?.author?.aadObjectId
    ),
    response.data.addCommentToCompany.content,
    new Date(response.data.addCommentToCompany.createdAt),
    response.data.addCommentToCompany?.replies.map(
      (reply) =>
        new Comment(
          reply.id,
          new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
          reply.content,
          new Date(reply.createdAt),
          [],
          reply.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
        )
    ),
    response.data.addCommentToCompany?.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
  );
};

export const updateProfilePicture = async (client: ApolloClient<NormalizedCacheObject>, companyId: string, profilePicture: ProfilePicture): Promise<string> => {
  const input: CompaniesProfilePictureInput = {
    mimeType: profilePicture.mimeType,
    data: profilePicture.data,
    fileExtension: profilePicture.fileExtension
  };

  const response = await client.mutate<UpdateCompanyProfilePictureMutation>({
    mutation: UpdateCompanyProfilePictureDocument,
    variables: {
      companyId,
      input
    }
  });

  return response.data.updateCompanyProfilePicture;
};

export const removeProfilePicture = async (client: ApolloClient<NormalizedCacheObject>, companyId: string): Promise<string> => {
  const response = await client.mutate<RemoveCompanyProfilePictureMutation>({
    mutation: RemoveCompanyProfilePictureDocument,
    variables: {
      companyId
    }
  });

  return response.data.removeCompanyProfilePicture;
};

export const updateCompany = async (client: ApolloClient<NormalizedCacheObject>, id: string, companyInput: CompanyUpdateModel): Promise<Company> => {
  const input: CompanyInput = {
    name: companyInput.name,
    category: companyInput.category,
    comments: companyInput.comments,
    competitors: companyInput.competitors,
    contactData: companyInput.contactData,
    databaseType: companyInput.databaseType,
    description: companyInput.description,
    director: companyInput.director,
    foundationDate: companyInput.foundationDate,
    parentCompany: companyInput.parentCompany,
    profilePictureUrl: companyInput.profilePictureUrl,
    profit: companyInput.profit,
    registrationNumber: companyInput.registrationNumber
  };
  const response = await client.mutate<UpdateCompanyMutation>({
    mutation: UpdateCompanyDocument,
    variables: {
      id,
      input
    }
  });

  return createCompany(response.data.updateCompany as GqlCompany);
};

export const fetchCompanyContacts = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  name: string,
  role: string,
  companyId: string
): Promise<PageOfContacts> => {
  const response = await client.query<FetchContactsByCompanyIdNameAndRoleQuery>({
    query: FetchContactsByCompanyIdNameAndRoleDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      name: name,
      role: role,
      mediumId: companyId
    }
  });

  return new PageOfContacts(
    response.data.contactsByMediumIdNameAndRole.totalCount,
    response.data.contactsByMediumIdNameAndRole.contacts.map((contact) => createContact(contact as GqlContact))
  );
};

export const fetchContactsByNameAndRoleAndNotContainedInCompany = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  name: string,
  role: string,
  companyId: string
): Promise<PageOfContacts> => {
  const response = await client.query<FetchContactsByNameAndRoleAndNotContainedInCompanyQuery>({
    query: FetchContactsByNameAndRoleAndNotContainedInCompanyDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      name: name,
      role: role,
      mediumId: companyId
    }
  });

  return new PageOfContacts(
    response.data.contactsByNameAndRoleAndNotContainedInMedium.totalCount,
    response.data.contactsByNameAndRoleAndNotContainedInMedium.contacts.map((contact) => createContact(contact as GqlContact))
  );
};

export const fetchCompanyCategories = async (client: ApolloClient<NormalizedCacheObject>): Promise<MediumCategory[]> => {
  const response = await client.query<FetchCompanyCategoriesQuery>({
    query: FetchCompanyCategoriesDocument
  });

  return response.data.companyCategories.map((category) => new MediumCategory(category.id, category.name, null));
};
