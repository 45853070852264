import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { ShimmerTable, getLanguagesRequested, selectSearchResultsColumnNumber, useAppDispatch, useAppSelector } from 'app/common';
import { ContactPanel } from 'app/pages/my-audience/contact-profile';
import {
  buildLocalizedSearchResultsColumns,
  firstPageOfContactsRequested,
  getAllContactsTagsRequested,
  selectIsLoadingSearchResults,
  setSearchResultTableHeader
} from 'app/pages/my-audience/contacts';
import { ContactsSavedSearchesPanel } from 'app/pages/my-audience/contacts-saved-searches';
import { ContactFiltersPanel } from 'app/pages/my-audience/contact-filter-search/ContactFiltersPanel';
import {
  ContactsActionBar,
  ContactsExportDialog,
  ContactsFilterPills,
  ContactsSearchResultsIntro,
  ContactsTable
} from 'app/pages/my-audience/contacts-search-results';
import 'app/pages/my-audience/contacts-search-results/components/search-results.scss';
import { useIntl } from 'app/i18n';

export const ContactsSearchResults = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isLoadingSearchResults = useAppSelector(selectIsLoadingSearchResults);
  const columnNumber = useAppSelector(selectSearchResultsColumnNumber);

  useEffect(() => {
    dispatch(firstPageOfContactsRequested());
    dispatch(getAllContactsTagsRequested());
    dispatch(getLanguagesRequested());
  }, [dispatch]);

  useEffect(() => {
    const tableHeader = buildLocalizedSearchResultsColumns(
      formatMessage({ id: 'contact-data-labels.name' }),
      formatMessage({ id: 'contact-data-labels.role' }),
      formatMessage({ id: 'labels.medium' }),
      formatMessage({ id: 'contact-data-labels.resort' }),
      formatMessage({ id: 'labels.tags' }),
      formatMessage({ id: 'contact-data-labels.preferred-languages' }),
      formatMessage({ id: 'search-results-table.sort-ascending' }),
      formatMessage({ id: 'search-results-table.sort-descending' }),
      formatMessage({ id: 'search-results-table.country' })
    ).map((header, i) => ({ ...header, isColumnVisible: i < columnNumber }));
    dispatch(setSearchResultTableHeader(tableHeader));
  }, [columnNumber, dispatch]);

  return (
    <>
      <div className="search-results-wrap">
        <ContactsSearchResultsIntro />
        <div className="search-results-content">
          <ContactsFilterPills />
          <ContactsActionBar />
          <ContactsTable />
          {isLoadingSearchResults && <ShimmerTable />}
        </div>
        <Routes>
          <Route path="saved-searches/*" element={<ContactsSavedSearchesPanel />} />
          <Route path="contact-info/:selectedContactTableId/*" element={<ContactPanel />} />
          <Route path="filter-contacts/*" element={<ContactFiltersPanel />} />
        </Routes>
      </div>
      <ContactsExportDialog />
    </>
  );
};
