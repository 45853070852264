import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ProfilePicture, createContact, createList } from 'app/common';
import {
  AadContactInput,
  AddAadContactsDocument,
  AddAadContactsMutation,
  AddAadContactsToListDocument,
  AddAadContactsToListMutation,
  AddContactsSavedSearchesToListDocument,
  AddContactsSavedSearchesToListMutation,
  AddContactsToListDocument,
  AddContactsToListMutation,
  ContactsProfilePictureInput,
  ExportContactsFromListToCsvDocument,
  ExportContactsFromListToCsvQuery,
  ExportVCardsForContactsFromListDocument,
  ExportVCardsForContactsFromListQuery,
  FetchAadContactsDocument,
  FetchAadContactsQuery,
  FetchAadGroupMembersDocument,
  FetchAadGroupMembersQuery,
  FetchAadGroupsDocument,
  FetchAadGroupsQuery,
  FetchContactsByIdsPagedDocument,
  FetchContactsByIdsPagedQuery,
  FetchContactsByListIdAndSearchTextDocument,
  FetchContactsByListIdAndSearchTextQuery,
  FetchContactsByListIdDocument,
  FetchContactsByListIdQuery,
  FetchContactsBySearchTextAndNotContainedInListDocument,
  FetchContactsBySearchTextAndNotContainedInListQuery,
  FetchLinkedSavedSearchesByListIdDocument,
  FetchLinkedSavedSearchesByListIdQuery,
  FetchListDocument,
  FetchListQuery,
  FetchListsByNameCreatorAndDateDocument,
  FetchListsByNameCreatorAndDateQuery,
  FetchListsByNameDocument,
  FetchListsByNameQuery,
  FetchMediumNamesByListIdDocument,
  FetchMediumNamesByListIdQuery,
  FetchNumberOfDesynchronizedContactsDocument,
  FetchNumberOfDesynchronizedContactsQuery,
  FetchSendingOverviewsByListIdDocument,
  FetchSendingOverviewsByListIdQuery,
  Contact as GqlContact,
  ContactsSending as GqlSending,
  ListContactInput,
  RefreshLinkedSavedSearchesDocument,
  RefreshLinkedSavedSearchesMutation,
  RemoveContactsFromListDocument,
  RemoveContactsFromListMutation,
  RemoveContactsSavedSearchesFromListDocument,
  RemoveContactsSavedSearchesFromListMutation,
  RemoveListProfilePictureDocument,
  RemoveListProfilePictureMutation,
  SendingOverview,
  UpdateContactInListDocument,
  UpdateContactInListMutation,
  UpdateContactsSavedSearchDocument,
  UpdateContactsSavedSearchMutation,
  UpdateListProfilePictureDocument,
  UpdateListProfilePictureMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { createContactInput } from 'app/pages/my-audience/common';
import { Medium } from 'app/pages/my-audience/contact-profile';
import {
  AadContact,
  Collaboration,
  Contact,
  ExportContactCsvHeader,
  Group,
  JobTitle,
  OwnContactData,
  PageOfContacts,
  PhoneNumber
} from 'app/pages/my-audience/contacts';
import { LinkedSavedSearch, List, ListContact, PageOfListContacts } from 'app/pages/my-audience/lists';
import { ListFilterItems } from 'app/pages/my-audience/lists-profile';
import { SavedSearch } from 'app/pages/my-audience/saved-searches';

export const updateProfilePicture = async (client: ApolloClient<NormalizedCacheObject>, listId: string, profilePicture: ProfilePicture): Promise<string> => {
  const input: ContactsProfilePictureInput = {
    mimeType: profilePicture.mimeType,
    data: profilePicture.data,
    fileExtension: profilePicture.fileExtension
  };

  const response = await client.mutate<UpdateListProfilePictureMutation>({
    mutation: UpdateListProfilePictureDocument,
    variables: {
      listId,
      input
    }
  });

  return response.data.updateListProfilePicture;
};

export const removeProfilePicture = async (client: ApolloClient<NormalizedCacheObject>, listId: string): Promise<string> => {
  const response = await client.mutate<RemoveListProfilePictureMutation>({
    mutation: RemoveListProfilePictureDocument,
    variables: {
      listId: listId
    }
  });

  return response.data.removeListProfilePicture;
};

export const fetchList = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<List> => {
  const response = await client.query<FetchListQuery>({
    query: FetchListDocument,
    variables: {
      id: id
    }
  });

  return createList(response.data.list);
};

export const fetchListContactsByListIdAndSearchText = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  searchText: string,
  listId: string
): Promise<PageOfListContacts> => {
  const response = await client.query<FetchContactsByListIdAndSearchTextQuery>({
    query: FetchContactsByListIdAndSearchTextDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      searchText: searchText,
      listId: listId
    }
  });

  return new PageOfListContacts(
    response.data.contactsByListIdAndSearchText.totalCount,
    response.data.contactsByListIdAndSearchText.listContacts.map(
      (listContact) =>
        new ListContact(
          listContact.id,
          listContact.listId,
          {
            id: listContact.contact.id,
            firstName: listContact.contact.firstName,
            lastName: listContact.contact.lastName,
            ownContactData: listContact.contact.ownContactData
              ? new OwnContactData(
                  listContact.contact.ownContactData.address,
                  listContact.contact.ownContactData.country,
                  listContact.contact.ownContactData.landlinePhoneNumber
                    ? new PhoneNumber(
                        listContact.contact.ownContactData.landlinePhoneNumber.value,
                        listContact.contact.ownContactData.landlinePhoneNumber.isPrimary
                      )
                    : ({} as PhoneNumber),
                  listContact.contact.ownContactData.mobilePhoneNumber
                    ? new PhoneNumber(
                        listContact.contact.ownContactData.mobilePhoneNumber.value,
                        listContact.contact.ownContactData.mobilePhoneNumber.isPrimary
                      )
                    : ({} as PhoneNumber),
                  listContact.contact.ownContactData.emailAddress,
                  listContact.contact.ownContactData.blogUrl,
                  listContact.contact.ownContactData.websiteUrl,
                  listContact.contact.ownContactData.twitterProfileUrl,
                  listContact.contact.ownContactData.instagramProfileUrl,
                  listContact.contact.ownContactData.linkedInProfileUrl,
                  listContact.contact.ownContactData.youtubeProfileUrl,
                  listContact.contact.ownContactData.facebookProfileUrl,
                  listContact.contact.ownContactData.isPrimary,
                  listContact.contact.ownContactData.city,
                  listContact.contact.ownContactData.postalCode,
                  listContact.contact.ownContactData.fax
                )
              : undefined,
            collaborations: listContact.contact.collaborations?.map(
              (c) =>
                new Collaboration(
                  c.id,
                  new Medium(c.medium?.id, c.medium?.name, c.medium?.type.value),
                  c.jobTitle ? new JobTitle(c.jobTitle?.id, c.jobTitle?.name, c.jobTitle?.dataSourceKey, c.jobTitle?.mediumType?.value) : ({} as JobTitle),
                  c.jobDescription,
                  c.address,
                  c.country,
                  c.landlinePhoneNumber ? new PhoneNumber(c.landlinePhoneNumber?.value, c.landlinePhoneNumber.isPrimary) : ({} as PhoneNumber),
                  c.mobilePhoneNumber ? new PhoneNumber(c.mobilePhoneNumber?.value, c.mobilePhoneNumber.isPrimary) : ({} as PhoneNumber),
                  c.emailAddress,
                  c.blogUrl,
                  c.websiteUrl,
                  c.twitterProfileUrl,
                  c.instagramProfileUrl,
                  c.linkedInProfileUrl,
                  c.youtubeProfileUrl,
                  c.facebookProfileUrl,
                  c.isPrimary,
                  c.city,
                  c.postalCode
                )
            )
          } as Contact,
          listContact.mailingAddressCompanyId
        )
    )
  );
};

export const fetchContactsByIdsPaged = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  searchText: string,
  contactsIds: string[]
): Promise<PageOfContacts> => {
  const response = await client.query<FetchContactsByIdsPagedQuery>({
    query: FetchContactsByIdsPagedDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      searchText,
      contactsIds
    }
  });

  return new PageOfContacts(
    response.data.contactsByIdsPaged.totalCount,
    response.data.contactsByIdsPaged.contacts.map((contact) => createContact(contact as GqlContact))
  );
};

export const updateContactInList = async (
  client: ApolloClient<NormalizedCacheObject>,
  listContactId: string,
  listContact: ListContact
): Promise<ListContact> => {
  const input: ListContactInput = {
    mailingAddressCompanyId: listContact.mediumId,
    contact: createContactInput(listContact.contact)
  };

  const response = await client.mutate<UpdateContactInListMutation>({
    mutation: UpdateContactInListDocument,
    variables: {
      listContactId,
      input
    }
  });

  return new ListContact(
    response.data.updateListContact.id,
    response.data.updateListContact.listId,
    {
      id: listContact.contact.id,
      firstName: listContact.contact.firstName,
      lastName: listContact.contact.lastName
    } as Contact,
    response.data.updateListContact.mailingAddressCompanyId
  );
};

export const fetchContactsBySearchTextAndNotContainedInList = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  searchText: string,
  listId: string
): Promise<PageOfContacts> => {
  const response = await client.query<FetchContactsBySearchTextAndNotContainedInListQuery>({
    query: FetchContactsBySearchTextAndNotContainedInListDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      searchText: searchText,
      listId: listId
    }
  });

  return new PageOfContacts(
    response.data.contactsBySearchTextAndNotContainedInList.totalCount,
    response.data.contactsBySearchTextAndNotContainedInList.contacts.map((contact) => createContact(contact as GqlContact))
  );
};

export const addContactsToList = async (client: ApolloClient<NormalizedCacheObject>, listId: string, contacts: ListContact[]): Promise<ListContact[]> => {
  const listContactsInput = contacts.map((lc) => ({
    listId: lc.listId,
    mailingAddressCompanyId: lc.mediumId,
    contact: createContactInput(lc.contact)
  }));

  const response = await client.mutate<AddContactsToListMutation>({
    mutation: AddContactsToListDocument,
    variables: {
      listId,
      input: listContactsInput
    }
  });

  return response.data.addContactsToList.map(
    (c) =>
      new ListContact(c.id, c.listId, { id: c.contact.id, firstName: c.contact.firstName, lastName: c.contact.lastName } as Contact, c.mailingAddressCompanyId)
  );
};

export const removeContactsFromList = async (client: ApolloClient<NormalizedCacheObject>, listId: string, contactsIds: string[]): Promise<List> => {
  const response = await client.mutate<RemoveContactsFromListMutation>({
    mutation: RemoveContactsFromListDocument,
    variables: {
      listId,
      contactsIds
    }
  });

  return createList(response.data.removeContactsFromList);
};

export const fetchAADContactsBySearchText = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take?: number): Promise<AadContact[]> => {
  const response = await client.query<FetchAadContactsQuery>({
    query: FetchAadContactsDocument,
    variables: {
      searchText,
      take: take ?? 5
    }
  });

  return response.data.aadContacts.map(
    (aadContact) =>
      new AadContact(aadContact.objectId, aadContact.contactId, aadContact.firstName, aadContact.lastName, aadContact.email, aadContact.defaultCompany)
  );
};

export const fetchAADGroupsBySearchText = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take?: number): Promise<Group[]> => {
  const response = await client.query<FetchAadGroupsQuery>({
    query: FetchAadGroupsDocument,
    variables: {
      searchText,
      take: take ?? 5
    }
  });

  return response.data.aadGroups.map(
    (node) =>
      new Group(
        node.objectId,
        node.name,
        node.members.map((member) => new AadContact(member.objectId, member.contactId, member.firstName, member.lastName, member.email, member.defaultCompany))
      )
  );
};

export const fetchAADGroupMembersByGroupId = async (client: ApolloClient<NormalizedCacheObject>, groupId: string): Promise<AadContact[]> => {
  const response = await client.query<FetchAadGroupMembersQuery>({
    query: FetchAadGroupMembersDocument,
    variables: {
      groupId: groupId
    }
  });

  return response.data.aadGroupMembers.map(
    (aadContact) =>
      new AadContact(aadContact.objectId, aadContact.contactId, aadContact.firstName, aadContact.lastName, aadContact.email, aadContact.defaultCompany)
  );
};

export const addAadContactsToList = async (client: ApolloClient<NormalizedCacheObject>, listId: string, contacts: AadContact[]): Promise<string[]> => {
  const aadContactsInput: AadContactInput[] = contacts.map(({ objectId, firstName, lastName, email }: AadContact) => {
    const input: AadContactInput = {
      objectId,
      firstName,
      lastName,
      email
    };
    return input;
  });

  const response = await client.query<AddAadContactsToListMutation>({
    query: AddAadContactsToListDocument,
    variables: {
      listId: listId,
      input: aadContactsInput
    }
  });

  return response.data.addAadContactsToList;
};

export const addAadContacts = async (client: ApolloClient<NormalizedCacheObject>, contacts: AadContact[]): Promise<Contact[]> => {
  const aadContactsInput: AadContactInput[] = contacts.map(({ objectId, firstName, lastName, email }: AadContact) => {
    const input: AadContactInput = {
      objectId,
      firstName,
      lastName,
      email
    };
    return input;
  });

  const response = await client.mutate<AddAadContactsMutation>({
    mutation: AddAadContactsDocument,
    variables: {
      input: aadContactsInput
    }
  });

  return response.data.addAadContacts.map(
    (contact) =>
      new Contact(
        contact.id,
        contact.salutation,
        contact.firstName,
        contact.lastName,
        contact.profilePictureUrl,
        false,
        [],
        [],
        [],
        contact.collaborations.map(
          (collaboration) =>
            new Collaboration(
              collaboration.id,
              new Medium(collaboration.medium.id, collaboration.medium.name, collaboration.medium.type.value),
              collaboration.jobTitle
                ? new JobTitle(
                    collaboration.jobTitle?.id,
                    collaboration.jobTitle?.name,
                    collaboration.jobTitle?.dataSourceKey,
                    collaboration.jobTitle?.mediumType?.value
                  )
                : ({} as JobTitle),
              collaboration.jobDescription,
              '',
              '',
              {} as PhoneNumber,
              {} as PhoneNumber,
              collaboration.emailAddress,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              collaboration.isPrimary,
              '',
              ''
            )
        ),
        [],
        [],
        [],
        contact.ownContactData
          ? new OwnContactData(
              '',
              '',
              {} as PhoneNumber,
              {} as PhoneNumber,
              contact.ownContactData?.emailAddress,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              contact.ownContactData?.isPrimary,
              '',
              '',
              ''
            )
          : ({} as OwnContactData)
      )
  );
};

export const fetchListsByNameCreatorNameAndDate = async (client: ApolloClient<NormalizedCacheObject>, filterItems: ListFilterItems): Promise<List[]> => {
  const response = await client.query<FetchListsByNameCreatorAndDateQuery>({
    query: FetchListsByNameCreatorAndDateDocument,
    variables: {
      name: filterItems.name,
      createdBy: filterItems.createdBy,
      dateFrom: filterItems.dateFrom,
      dateTo: filterItems.dateTo
    }
  });

  return response.data.listsByNameCreatorAndDate.map((list) => createList(list));
};

export const fetchListsByName = async (client: ApolloClient<NormalizedCacheObject>, name: string, skip = 0, take = 10): Promise<List[]> => {
  const response = await client.query<FetchListsByNameQuery>({
    query: FetchListsByNameDocument,
    variables: {
      name,
      skip,
      take
    }
  });

  return response.data.listsByName.map((list) => createList(list));
};

export const addContactsSavedSearchesToList = async (
  client: ApolloClient<NormalizedCacheObject>,
  listId: string,
  savedSearchesIds: string[]
): Promise<List> => {
  const response = await client.mutate<AddContactsSavedSearchesToListMutation>({
    mutation: AddContactsSavedSearchesToListDocument,
    variables: {
      listId,
      savedSearchesIds
    }
  });

  return createList(response.data.addContactsSavedSearchesToList);
};

export const removeContactsSavedSearchesFromList = async (
  client: ApolloClient<NormalizedCacheObject>,
  listId: string,
  savedSearchesIds: string[]
): Promise<List> => {
  const response = await client.mutate<RemoveContactsSavedSearchesFromListMutation>({
    mutation: RemoveContactsSavedSearchesFromListDocument,
    variables: {
      listId,
      savedSearchesIds
    }
  });

  return createList(response.data.removeContactsSavedSearchesFromList);
};

export const updateContactsSavedSearch = async (
  client: ApolloClient<NormalizedCacheObject>,
  listId: string,
  savedSearchId: string,
  shouldAutoUpdate: boolean
): Promise<List> => {
  const response = await client.mutate<UpdateContactsSavedSearchMutation>({
    mutation: UpdateContactsSavedSearchDocument,
    variables: {
      listId,
      savedSearchId,
      shouldAutoUpdate
    }
  });

  return createList(response.data.updateContactsSavedSearch);
};

export const refreshLinkedSavedSearches = async (client: ApolloClient<NormalizedCacheObject>, listId: string, savedSearchesIds: string[]): Promise<List> => {
  const response = await client.mutate<RefreshLinkedSavedSearchesMutation>({
    mutation: RefreshLinkedSavedSearchesDocument,
    variables: {
      listId,
      savedSearchesIds
    }
  });

  return createList(response.data.refreshLinkedSavedSearches);
};

export const fetchSendingOverviewByListId = async (client: ApolloClient<NormalizedCacheObject>, listId: string): Promise<SendingOverview[]> => {
  const response = await client.query<FetchSendingOverviewsByListIdQuery>({
    query: FetchSendingOverviewsByListIdDocument,
    variables: {
      listId
    }
  });

  return response.data.sendingOverviewsByListId;
};

export const fetchLinkedSavedSearchesByListId = async (client: ApolloClient<NormalizedCacheObject>, listId: string): Promise<LinkedSavedSearch[]> => {
  const response = await client.query<FetchLinkedSavedSearchesByListIdQuery>({
    query: FetchLinkedSavedSearchesByListIdDocument,
    variables: {
      listId
    }
  });

  return response.data.linkedSavedSearchesByListId.map(
    (linkedSavedSearch) =>
      new LinkedSavedSearch(
        new SavedSearch(
          linkedSavedSearch.savedSearch.id,
          linkedSavedSearch.savedSearch.name,
          '',
          [],
          0,
          0,
          new Date(linkedSavedSearch.savedSearch.createdAt),
          new Date(linkedSavedSearch.savedSearch.lastModifiedAt),
          '',
          [],
          0
        ),
        linkedSavedSearch.totalCountOfContacts,
        linkedSavedSearch.shouldAutoUpdate
      )
  );
};

export const fetchContactsByListId = async (client: ApolloClient<NormalizedCacheObject>, listId: string): Promise<ListContact[]> => {
  const response = await client.query<FetchContactsByListIdQuery>({
    query: FetchContactsByListIdDocument,
    variables: {
      listId
    }
  });

  return response.data.contactsByListId.map(
    (listContact) =>
      new ListContact(
        listContact.id,
        listContact.listId,
        {
          id: listContact.contact.id,
          firstName: listContact.contact.firstName,
          lastName: listContact.contact.lastName,
          ownContactData: listContact.contact.ownContactData
            ? new OwnContactData(
                listContact.contact.ownContactData.address,
                listContact.contact.ownContactData.country,
                listContact.contact.ownContactData.landlinePhoneNumber
                  ? new PhoneNumber(
                      listContact.contact.ownContactData.landlinePhoneNumber.value,
                      listContact.contact.ownContactData.landlinePhoneNumber.isPrimary
                    )
                  : ({} as PhoneNumber),
                listContact.contact.ownContactData.mobilePhoneNumber
                  ? new PhoneNumber(listContact.contact.ownContactData.mobilePhoneNumber.value, listContact.contact.ownContactData.mobilePhoneNumber.isPrimary)
                  : ({} as PhoneNumber),
                listContact.contact.ownContactData.emailAddress,
                listContact.contact.ownContactData.blogUrl,
                listContact.contact.ownContactData.websiteUrl,
                listContact.contact.ownContactData.twitterProfileUrl,
                listContact.contact.ownContactData.instagramProfileUrl,
                listContact.contact.ownContactData.linkedInProfileUrl,
                listContact.contact.ownContactData.youtubeProfileUrl,
                listContact.contact.ownContactData.facebookProfileUrl,
                listContact.contact.ownContactData.isPrimary,
                listContact.contact.ownContactData.city,
                listContact.contact.ownContactData.postalCode,
                listContact.contact.ownContactData.fax
              )
            : undefined,
          collaborations: listContact.contact.collaborations?.map(
            (c) =>
              new Collaboration(
                c.id,
                new Medium(c.medium?.id, c.medium?.name, c.medium?.type.value),
                c.jobTitle ? new JobTitle(c.jobTitle?.id, c.jobTitle?.name, c.jobTitle?.dataSourceKey, c.jobTitle?.mediumType?.value) : ({} as JobTitle),
                c.jobDescription,
                c.address,
                c.country,
                {} as PhoneNumber,
                {} as PhoneNumber,
                c.emailAddress,
                c.blogUrl,
                c.websiteUrl,
                c.twitterProfileUrl,
                c.instagramProfileUrl,
                c.linkedInProfileUrl,
                c.youtubeProfileUrl,
                c.facebookProfileUrl,
                c.isPrimary,
                c.city,
                c.postalCode
              )
          )
        } as Contact,
        listContact.mailingAddressCompanyId
      )
  );
};

export const exportContacts = async (client: ApolloClient<NormalizedCacheObject>, listId: string, csvHeader: ExportContactCsvHeader): Promise<Uint8Array> => {
  const response = await client.query<ExportContactsFromListToCsvQuery>({
    query: ExportContactsFromListToCsvDocument,
    variables: {
      listId,
      csvHeader
    }
  });

  return response.data.exportContactsFromListToCsv;
};

export const createContactVCards = async (client: ApolloClient<NormalizedCacheObject>, listId: string): Promise<Uint8Array> => {
  const response = await client.query<ExportVCardsForContactsFromListQuery>({
    query: ExportVCardsForContactsFromListDocument,
    variables: {
      listId
    }
  });

  return response.data.exportVCardsForContactsFromList;
};

export const fetchNumberOfDesynchronizedContacts = async (client: ApolloClient<NormalizedCacheObject>, listId: string): Promise<number> => {
  const response = await client.query<FetchNumberOfDesynchronizedContactsQuery>({
    query: FetchNumberOfDesynchronizedContactsDocument,
    variables: {
      listId
    }
  });

  return response.data.numberOfDesynchronizedContactsInList;
};

export const fetchMediumNamesByListId = async (client: ApolloClient<NormalizedCacheObject>, listId: string): Promise<string[]> => {
  const response = await client.query<FetchMediumNamesByListIdQuery>({
    query: FetchMediumNamesByListIdDocument,
    variables: {
      listId
    }
  });

  return response.data.mediumNamesByListId ?? [];
};
