import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  createMediaOutlet,
  createSearchSuggestionCategory,
  FilterItem,
  MediumCategory,
  MediumCategoryType,
  SearchSuggestionCategory,
  Tag,
  TagProjection
} from 'app/common';
import {
  AddMediaOutletDocument,
  AddMediaOutletMutation,
  AddMediumCategoryDocument,
  AddMediumCategoryMutation,
  AddTagToMediaOutletsDocument,
  AddTagToMediaOutletsMutation,
  FetchMediaOutletsByQueryParamsDocument,
  FetchMediaOutletsByQueryParamsQuery,
  FetchMediaOutletsDataDocument,
  FetchMediaOutletsDataQuery,
  FetchMediaOutletSearchSuggestionsDocument,
  FetchMediaOutletSearchSuggestionsQuery,
  FetchReferenceListsLocalizationQueryDocument,
  FetchReferenceListsLocalizationQueryQuery,
  MediaOutlet as GqlMediaOutlet,
  MediaOutletsTagInput,
  MediaOutletSuggestionsByCategoryDocument,
  MediaOutletSuggestionsByCategoryQuery,
  MediaOutletSuggestionsByContactNameDocument,
  MediaOutletSuggestionsByContactNameQuery,
  MediaOutletSuggestionsByFrequencyDocument,
  MediaOutletSuggestionsByFrequencyQuery,
  MediaOutletSuggestionsByLanguageDocument,
  MediaOutletSuggestionsByLanguageQuery,
  MediaOutletSuggestionsByMediaTypeDocument,
  MediaOutletSuggestionsByMediaTypeQuery,
  MediaOutletSuggestionsByNameDocument,
  MediaOutletSuggestionsByNameQuery,
  MediaOutletSuggestionsByTagNameDocument,
  MediaOutletSuggestionsByTagNameQuery,
  RemoveMediaOutletsDocument,
  RemoveMediaOutletsMutation,
  RemoveMediumCategoryDocument,
  RemoveMediumCategoryMutation,
  RemoveTagFromMediaOutletsDocument,
  RemoveTagFromMediaOutletsMutation,
  TransformMediaOutletIntoCompanyDocument,
  TransformMediaOutletIntoCompanyMutation,
  UpdateBulkMediaOutletsDocument,
  UpdateBulkMediaOutletsMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { createMediaOutletInputType } from 'app/pages/my-audience/media-outlet-profile';
import {
  IMediaOutletsSortingInput,
  MediaOutlet,
  MediaOutletPanelData,
  PageOfMediaOutlets,
  MediaOutletReferenceLists,
  Audience,
  LabelLanguage,
  Label,
  Frequency,
  MediumSupportType
} from 'app/pages/my-audience/media-outlets';

export const fetchMediaOutletsByQueryParamsPaged = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  filterItems: FilterItem[],
  skip: number,
  pageSize: number,
  sortingInput: IMediaOutletsSortingInput
): Promise<PageOfMediaOutlets> => {
  const response = await client.query<FetchMediaOutletsByQueryParamsQuery>({
    query: FetchMediaOutletsByQueryParamsDocument,
    variables: {
      searchText: searchText,
      filterItems: filterItems,
      skip: skip,
      take: pageSize,
      sortingInput: sortingInput
    }
  });

  return new PageOfMediaOutlets(
    response.data.mediaOutlets?.totalCount,
    response.data.mediaOutlets?.mediaOutlets.map((mediaOutlet: GqlMediaOutlet) => createMediaOutlet(mediaOutlet))
  );
};

export const addMediaOutlet = async (client: ApolloClient<NormalizedCacheObject>, mediaOutlet: MediaOutlet): Promise<MediaOutlet> => {
  const mediaOutletInput = createMediaOutletInputType(mediaOutlet);
  const response = await client.mutate<AddMediaOutletMutation>({
    mutation: AddMediaOutletDocument,
    variables: {
      mediaOutlet: mediaOutletInput
    }
  });

  return createMediaOutlet(response.data.addMediaOutlet as GqlMediaOutlet);
};

export const fetchMediaOutletSuggestions = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  language: string
): Promise<SearchSuggestionCategory[]> => {
  const response = await client.query<FetchMediaOutletSearchSuggestionsQuery>({
    query: FetchMediaOutletSearchSuggestionsDocument,
    variables: {
      searchText,
      language
    }
  });

  return response.data.searchMediaOutletSuggestions.map((ssc) => createSearchSuggestionCategory(ssc));
};

export const transformMediaOutletIntoCompany = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<string> => {
  const response = await client.mutate<TransformMediaOutletIntoCompanyMutation>({
    mutation: TransformMediaOutletIntoCompanyDocument,
    variables: {
      id: id
    }
  });

  return response.data.transformMediaOutletIntoCompany.id;
};

export const fetchMediaOutletSuggestionsByName = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<MediaOutletSuggestionsByNameQuery>({
    query: MediaOutletSuggestionsByNameDocument,
    variables: {
      name: searchText,
      take
    }
  });

  return response.data.mediaOutletSuggestionsByName;
};

export const fetchMediaOutletSuggestionsByContactName = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<MediaOutletSuggestionsByContactNameQuery>({
    query: MediaOutletSuggestionsByContactNameDocument,
    variables: {
      contactName: searchText,
      take
    }
  });

  return response.data.mediaOutletSuggestionsByContactName;
};

export const fetchMediaOutletSuggestionsByTagName = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<MediaOutletSuggestionsByTagNameQuery>({
    query: MediaOutletSuggestionsByTagNameDocument,
    variables: {
      tagName: searchText,
      take
    }
  });

  return response.data.mediaOutletSuggestionsByTagName;
};

export const fetchMediaOutletSuggestionsByCategory = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<MediaOutletSuggestionsByCategoryQuery>({
    query: MediaOutletSuggestionsByCategoryDocument,
    variables: {
      category: searchText,
      take
    }
  });

  return response.data.mediaOutletSuggestionsByCategory;
};

export const fetchMediaOutletSuggestionsByLanguage = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<MediaOutletSuggestionsByLanguageQuery>({
    query: MediaOutletSuggestionsByLanguageDocument,
    variables: {
      language: searchText,
      take
    }
  });

  return response.data.mediaOutletSuggestionsByLanguage;
};

export const fetchMediaOutletSuggestionsByMediaType = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<MediaOutletSuggestionsByMediaTypeQuery>({
    query: MediaOutletSuggestionsByMediaTypeDocument,
    variables: {
      mediaType: searchText,
      take
    }
  });

  return response.data.mediaOutletSuggestionsByMediaType;
};

export const fetchMediaOutletSuggestionsByFrequency = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<MediaOutletSuggestionsByFrequencyQuery>({
    query: MediaOutletSuggestionsByFrequencyDocument,
    variables: {
      frequency: searchText,
      take
    }
  });

  return response.data.mediaOutletSuggestionsByFrequency;
};

export const removeMediaOutletsByIds = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<MediaOutlet[]> => {
  const response = await client.mutate<RemoveMediaOutletsMutation>({
    mutation: RemoveMediaOutletsDocument,
    variables: {
      ids
    }
  });

  return response.data.removeMediaOutlets.map((c) => createMediaOutlet(c as GqlMediaOutlet));
};

export const updateBulkMediaOutlets = async (client: ApolloClient<NormalizedCacheObject>, ids: string[], mediaResortsIds: string[]): Promise<MediaOutlet[]> => {
  const response = await client.mutate<UpdateBulkMediaOutletsMutation>({
    mutation: UpdateBulkMediaOutletsDocument,
    variables: {
      ids,
      mediaResortsIds
    }
  });

  return response.data.updateBulkMediaOutlets.map((mediaOutlet) => createMediaOutlet(mediaOutlet as GqlMediaOutlet));
};

export const addTagToMediaOutlets = async (client: ApolloClient<NormalizedCacheObject>, mediaOutletIds: string[], tag: Tag): Promise<TagProjection> => {
  const tagInput: MediaOutletsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<AddTagToMediaOutletsMutation>({
    mutation: AddTagToMediaOutletsDocument,
    variables: {
      mediaOutletIds: mediaOutletIds,
      input: tagInput
    }
  });

  return new TagProjection(response.data.addTagToMediaOutlets.id, response.data.addTagToMediaOutlets.name);
};

export const removeTagFromMediaOutlets = async (client: ApolloClient<NormalizedCacheObject>, mediaOutletIds: string[], tag: Tag): Promise<TagProjection> => {
  const input: MediaOutletsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<RemoveTagFromMediaOutletsMutation>({
    mutation: RemoveTagFromMediaOutletsDocument,
    variables: {
      mediaOutletIds,
      input
    }
  });

  return new TagProjection(response.data.removeTagFromMediaOutlets.id, response.data.removeTagFromMediaOutlets.name);
};

export const fetchMediaOutletsData = async (client: ApolloClient<NormalizedCacheObject>): Promise<MediaOutletPanelData> => {
  const response = await client.query<FetchMediaOutletsDataQuery>({
    query: FetchMediaOutletsDataDocument
  });

  return new MediaOutletPanelData(
    response.data.mediaOutletCategories.map((c) => new MediumCategory(c.id, c.name, new MediumCategoryType(c.type.name, c.type.value)))
  );
};

export const fetchReferenceListsLocalization = async (client: ApolloClient<NormalizedCacheObject>) => {
  const response = await client.query<FetchReferenceListsLocalizationQueryQuery>({
    query: FetchReferenceListsLocalizationQueryDocument
  });

  return new MediaOutletReferenceLists(
    response.data.mediaOutletAudiences?.map(
      (a) =>
        new Audience(
          a.id,
          a.key,
          a.labels.map((l) => new Label(l.value, new LabelLanguage(l.language.name, l.language.value)))
        )
    ),
    response.data.mediaOutletMediumPublishingFrequency?.map(
      (a) =>
        new Frequency(
          a.id,
          a.key,
          a.labels.map((l) => new Label(l.value, new LabelLanguage(l.language.name, l.language.value)))
        )
    ),
    response.data.mediaOutletMediumSupportTypes?.map(
      (a) =>
        new MediumSupportType(
          a.id,
          a.key,
          a.labels.map((l) => new Label(l.value, new LabelLanguage(l.language.name, l.language.value)))
        )
    )
  );
};

export const addMediumCategory = async (client: ApolloClient<NormalizedCacheObject>, mediumCategoryName: string): Promise<MediumCategory> => {
  const response = await client.mutate<AddMediumCategoryMutation>({
    mutation: AddMediumCategoryDocument,
    variables: {
      input: {
        name: mediumCategoryName,
        type: {
          name: 'MediaOutlet',
          value: 1
        }
      }
    }
  });

  return new MediumCategory(
    response.data.addMediumCategory.id,
    response.data.addMediumCategory.name,
    new MediumCategoryType(response.data.addMediumCategory.type.name, response.data.addMediumCategory.type.value)
  );
};

export const removeMediumCategory = async (client: ApolloClient<NormalizedCacheObject>, mediumCategoryId: string): Promise<string> => {
  const response = await client.mutate<RemoveMediumCategoryMutation>({
    mutation: RemoveMediumCategoryDocument,
    variables: {
      mediumCategoryId
    }
  });

  return response.data.removeMediumCategory;
};
