import { printData, showAlertNotification, useAppDispatch, useAppSelector, WizardFinishIntro, WizardIntro } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { createContactWithCollaboration } from 'app/pages/my-audience/common';
import { OwnContactData } from 'app/pages/my-audience/contacts';
import {
  addContactRequested,
  closeAddContactWizard,
  closeCreateContactWizard,
  CreateContactSteps,
  CreateContactWizardFooter,
  CreateContactWizardStep,
  disableCreateContactFinishButton,
  goToPreviousStepCreateWizard,
  goToStepCreateWizard,
  selectCollaborationToAdd,
  selectContactToAdd,
  selectIsCreateContactFinishButtonDisabled,
  selectOwnContactDataToAdd
} from 'app/pages/my-audience/media-outlet-profile';

export const CreateContactFinish = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const collaborationToAdd = useAppSelector(selectCollaborationToAdd);
  const contactToAdd = useAppSelector(selectContactToAdd);
  const ownContactDataToAdd = useAppSelector<OwnContactData>(selectOwnContactDataToAdd);
  const isCreateContactFinishButtonDisabled = useAppSelector<boolean>(selectIsCreateContactFinishButtonDisabled);

  const onEdit = (step: CreateContactWizardStep) => {
    dispatch(goToStepCreateWizard(step));
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStepCreateWizard());
  };

  const handleFinishButtonClick = () => {
    const contact = createContactWithCollaboration(contactToAdd, collaborationToAdd, ownContactDataToAdd);
    dispatch(addContactRequested(contact));
    dispatch(disableCreateContactFinishButton());
    dispatch(closeCreateContactWizard());
    dispatch(closeAddContactWizard());
    dispatch(showAlertNotification(formatMessage({ id: 'mo-add-contact.success-msg-alert' })));
  };

  return (
    <>
      <CreateContactSteps />
      <div className="wizard-panel-finish-wrap">
        <WizardIntro title={formatMessage({ id: 'mo-create-contact.wizard-finish-title' })} description="" />
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                onEditButtonClick={() => onEdit(CreateContactWizardStep.CreateNew)}
                title={formatMessage({ id: 'mo-create-contact.wizard-personal-title' })}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-salutation-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(contactToAdd.salutation)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-name-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(contactToAdd.firstName)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-lastname-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(contactToAdd.lastName)}</span>
                </div>
              </div>
            </div>
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                onEditButtonClick={() => onEdit(CreateContactWizardStep.OwnContactData)}
                title={formatMessage({ id: 'mo-create-contact.wizard-own-contact-title' })}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-email-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.emailAddress)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-phone-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.landlinePhoneNumber?.value)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-mobile-phone-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.mobilePhoneNumber?.value)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-address-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.address)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-city-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.city)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-postal-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.postalCode)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-country-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.country)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-blog-url'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.blogUrl)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-website-url'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.websiteUrl)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-twitter-url'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.twitterProfileUrl)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-insta-url'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.instagramProfileUrl)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-ln-url'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.linkedInProfileUrl)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-youtube-url'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.youtubeProfileUrl)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-facebook-url'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(ownContactDataToAdd.facebookProfileUrl)}</span>
                </div>
              </div>
            </div>
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                onEditButtonClick={() => onEdit(CreateContactWizardStep.ProfileInformation)}
                title={formatMessage({ id: 'mo-create-contact.wizard-personal-title' })}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-role-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.jobTitle?.name)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-job-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.jobDescription)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-personal-phone-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.landlinePhoneNumber?.value)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-personal-mobile-phone-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.mobilePhoneNumber?.value)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-personal-email-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.emailAddress)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-personal-address-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.address)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-zip-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.postalCode)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-personal-city-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.city)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'mo-create-contact.wizard-personal-country-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaborationToAdd.country)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateContactWizardFooter
        onCancelButtonClick={handleCancelButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onFinishButtonClick={handleFinishButtonClick}
        isNextButtonDisabled={isCreateContactFinishButtonDisabled}
      />
    </>
  );
};
