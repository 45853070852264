import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ShimmerTable, TableHeader, selectSearchResultsColumnNumber, setSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import { firstPageOfListsRequested, getAllListsTagsRequested, selectIsLoadingSearchResults, setSearchResultTableHeader } from 'app/pages/my-audience/lists';
import { ListPanel } from 'app/pages/my-audience/lists-profile';
import { ListFiltersPanel } from 'app/pages/my-audience/lists-filter-search/ListFiltersPanel';
import {
  ListsActionBar,
  ListsExportDialog,
  ListsFilterPills,
  ListsSearchResultsIntro,
  ListsTable,
  buildLocalizedSearchResultsColumns
} from 'app/pages/my-audience/lists-search-results';
import { useIntl } from 'app/i18n';

export const ListsSearchResults = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const isLoadingSearchResults = useAppSelector<boolean>(selectIsLoadingSearchResults);
  const columnNumber = useAppSelector<number>(selectSearchResultsColumnNumber);

  useEffect(() => {
    dispatch(firstPageOfListsRequested());
    dispatch(getAllListsTagsRequested());
    dispatch(setSelectedTableRows([]));
  }, [dispatch]);

  useEffect(() => {
    const tableHeader = buildLocalizedSearchResultsColumns(
      formatMessage({ id: 'lists-search-results-table.name' }),
      formatMessage({ id: 'lists-search-results-table.number-of-contacts' }),
      formatMessage({ id: 'lists-search-results-table.mediums' }),
      formatMessage({ id: 'lists-search-results-table.created-by' }),
      formatMessage({ id: 'labels.tags' }),
      formatMessage({ id: 'lists-search-results-table.created-at' }),
      formatMessage({ id: 'lists-search-results-table.last-update' }),
      formatMessage({ id: 'search-results-table.sort-ascending' }),
      formatMessage({ id: 'search-results-table.sort-descending' })
    ).map((header: TableHeader, i: number) => ({
      ...header,
      isColumnVisible: i < columnNumber
    }));
    dispatch(setSearchResultTableHeader(tableHeader));
  }, [columnNumber, dispatch]);

  return (
    <>
      <div className="search-results-wrap list-search">
        <ListsSearchResultsIntro />
        <div className="search-results-content">
          <ListsFilterPills />
          <ListsActionBar />
          <ListsTable />
          {isLoadingSearchResults ? <ShimmerTable /> : <></>}
        </div>
      </div>
      <Routes>
        <Route path="list-info/:selectedListId/*" element={<ListPanel />} />
        <Route path="filter-lists/*" element={<ListFiltersPanel />} />
      </Routes>
      <ListsExportDialog />
    </>
  );
};
