import { Language, MediumCategory } from 'app/common';
import { MediumType } from 'app/pages/my-audience/contacts';

export enum CreateContactWizardStep {
  GeneralInfo = 1,
  Collaboration,
  AdditionalInfo,
  Finish
}

export enum CreateMediaOutletFromContactStep {
  GeneralInfo = 1,
  AddressInfo,
  Finish
}

export enum CreateCompanyFromContactStep {
  GeneralInfo = 1,
  Address,
  Finish
}

export class ContactGeneralInfo {
  constructor(
    public salutation: string,
    public lastName: string,
    public firstName: string,
    public language: Language,
    public emailAddress: string,
    public phoneNumber: string,
    public mobilePhoneNumber: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country: string,
    public blog: string,
    public websiteUrl: string,
    public twitterUrl: string,
    public instagramUrl: string,
    public linkedinUrl: string,
    public youtubeUrl: string,
    public facebookUrl: string
  ) {}
}

export class MediaOutletGeneralInfo {
  constructor(public name: string, public description: string, public audienceKey: string, public category: MediumCategory, public frequencyKey: string) {}
}

export class MediaOutletAddressInfo {
  constructor(
    public address: string,
    public poBox: string,
    public postalCode: string,
    public city: string,
    public country: string,
    public websiteUrl: string,
    public emailAddress: string,
    public phoneNumber: string,
    public fax: string
  ) {}
}

export class CompanyGeneralInfo {
  constructor(public name: string, public parentCompany: string, public comment: string) {}
}

export class CompanyAddressInfo {
  constructor(
    public address: string,
    public poBox: string,
    public postalCode: string,
    public city: string,
    public country: string,
    public websiteUrl: string,
    public emailAddress: string,
    public phoneNumber: string,
    public fax: string
  ) {}
}

export class CollaborationTableData {
  constructor(
    public id: string,
    public key: string,
    public mediumType: MediumType,
    public mediumTypeName: string,
    public name: string,
    public zip: string,
    public city: string,
    public country: string
  ) {}
}

export type AddGeneralContactInfoForm = {
  salutation: string;
  lastName: string;
  firstName: string;
  language: string;
  emailAddress: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  blog: string;
  websiteUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  linkedinUrl: string;
  youtubeUrl: string;
  facebookUrl: string;
};

export type AddAdditionalContactInfoForm = {
  jobTitle: string;
  jobDescription: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  emailAddress: string;
};
