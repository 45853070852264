import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectSelectedWizardContactsIds, selectSelectedWizardContactsLength, setWizardContactsIdsToEdit } from 'app/pages/my-audience/lists-profile';

interface AddContactTableToolbarProps {
  removeContactsFromTable: (contactIds: string[]) => void;
}

export const AddContactTableToolbar = ({ removeContactsFromTable }: AddContactTableToolbarProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedContactsIds = useAppSelector<string[]>(selectSelectedWizardContactsIds);
  const selectedContactsLength = useAppSelector<number>(selectSelectedWizardContactsLength);

  const handleClickOnRemoveButton = () => {
    removeContactsFromTable(selectedContactsIds);
  };

  return (
    <div className="table-toolbar">
      <div className="table-toolbar-column">
        <div className="table-toolbar-content">
          <Button
            disabled={selectedContactsLength === 0}
            content={formatMessage({ id: 'list-wizards.add-contact-edit-button' })}
            text
            icon={<Icon iconName="Edit" />}
            className="table-toolbar-action-button"
            onClick={() => dispatch(setWizardContactsIdsToEdit(selectedContactsIds))}
          />
          <Button
            disabled={selectedContactsLength === 0}
            content={formatMessage({ id: 'list-wizards.add-contact-remove-button' })}
            text
            icon={<Icon iconName="Clear" />}
            className="table-toolbar-action-button"
            onClick={handleClickOnRemoveButton}
          />
        </div>
      </div>
      {selectedContactsLength !== 0 && (
        <div className="table-toolbar-column">
          <span className="table-toolbar-text">
            {selectedContactsLength === 1 ? selectedContactsLength + ' contact' : selectedContactsLength + ' contacts'}
          </span>
        </div>
      )}
    </div>
  );
};
