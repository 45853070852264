import { useState } from 'react';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { CameraIcon } from '@fluentui/react-northstar';

interface ProfilePanelAvatarProps {
  firstName: string;
  lastName: string;
  imageUrl: string;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
}

export const ProfilePanelAvatar = ({ firstName, lastName, imageUrl, onClick, disabled = false }: ProfilePanelAvatarProps) => {
  const [isAvatarSkeletonVisible, setIsAvatarSkeletonVisible] = useState(false);

  const handleOnMouseEnter = () => {
    if (disabled) return;
    setIsAvatarSkeletonVisible(true);
  };

  const handleOnMouseLeave = () => {
    if (disabled) return;
    setIsAvatarSkeletonVisible(false);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return;
    onClick(event);
  };

  return (
    <div className="panel-avatar-holder" onClick={handleOnClick}>
      <div className="panel-avatar" onMouseLeave={handleOnMouseLeave} onMouseEnter={handleOnMouseEnter}>
        <ProfilePicture name={`${firstName} ${lastName}`} imageUrl={imageUrl} size={'largest'} />
        {isAvatarSkeletonVisible && (
          <div className="panel-avatar-skelet">
            <CameraIcon />
          </div>
        )}
      </div>
    </div>
  );
};
