import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/redux/store';
import { SavedSearch, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import { FilterItem } from 'app/common';

export interface CompaniesSavedSearchesState {
  pageNumber: number;
  pageSize: number;
  searchText: string;
  filterItem: FilterItem;
  totalCountOfSavedSearches: number;
  companySavedSearches: SavedSearch[];
  companySavedSearchToUpdate: SavedSearch;
  companySavedSearchesToUpdate: SavedSearchWithIdInput[];
  companySavedSearchToRemove: SavedSearch;
  isLoadingCompanySavedSearches: boolean;
  isDeleteDialogOpened: boolean;
  isSaveSearchButtonDisabled: boolean;
}

const initialState: CompaniesSavedSearchesState = {
  pageNumber: 0,
  pageSize: 6,
  searchText: '',
  filterItem: {} as FilterItem,
  totalCountOfSavedSearches: 0,
  companySavedSearches: [],
  companySavedSearchToUpdate: {} as SavedSearch,
  companySavedSearchesToUpdate: [],
  companySavedSearchToRemove: {} as SavedSearch,
  isLoadingCompanySavedSearches: true,
  isDeleteDialogOpened: false,
  isSaveSearchButtonDisabled: false
};

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.pageNumber],
  (pageNumber: number) => pageNumber
);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.companiesSavedSearches.pageSize], (pageSize: number) => pageSize);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.searchText],
  (searchText: string) => searchText
);

export type FilterItemSelector = (state: RootState) => FilterItem;
export const selectFilterItem: FilterItemSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.filterItem],
  (filterItem: FilterItem) => filterItem
);

export type TotalCountOfSavedSearchesSelector = (state: RootState) => number;
export const selectTotalCountOfSavedSearches: TotalCountOfSavedSearchesSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.totalCountOfSavedSearches],
  (totalCountOfSavedSearches: number) => totalCountOfSavedSearches
);

export type CompanySavedSearchesSelector = (state: RootState) => SavedSearch[];
export const selectCompanySavedSearches: CompanySavedSearchesSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.companySavedSearches],
  (companySavedSearches: SavedSearch[]) => companySavedSearches
);

export type CompanySavedSearchToUpdateSelector = (state: RootState) => SavedSearch;
export const selectCompanySavedSearchToUpdate: CompanySavedSearchToUpdateSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.companySavedSearchToUpdate],
  (companySavedSearchToUpdate: SavedSearch) => companySavedSearchToUpdate
);

export type CompanySavedSearchesToUpdateSelector = (state: RootState) => SavedSearchWithIdInput[];
export const selectCompanySavedSearchesToUpdate: CompanySavedSearchesToUpdateSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.companySavedSearchesToUpdate],
  (companySavedSearchesToUpdate: SavedSearchWithIdInput[]) => companySavedSearchesToUpdate
);

export type CompanySavedSearchToRemoveIdSelector = (state: RootState) => string;
export const selectCompanySavedSearchToRemoveId: CompanySavedSearchToRemoveIdSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.companySavedSearchToRemove.id],
  (companySavedSearchToRemoveId: string) => companySavedSearchToRemoveId
);

export type CompanySavedSearchToRemoveSelector = (state: RootState) => SavedSearch;
export const selectCompanySavedSearchToRemove: CompanySavedSearchToRemoveSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.companySavedSearchToRemove],
  (companySavedSearchToRemove: SavedSearch) => companySavedSearchToRemove
);

export type IsLoadingCompanySavedSearchesSelector = (state: RootState) => boolean;
export const selectIsLoadingCompanySavedSearches: IsLoadingCompanySavedSearchesSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.isLoadingCompanySavedSearches],
  (isLoadingCompanySavedSearches: boolean) => isLoadingCompanySavedSearches
);

export type IsDeleteDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsDeleteDialogOpened: IsDeleteDialogOpenedSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.isDeleteDialogOpened],
  (isDeleteDialogOpened: boolean) => isDeleteDialogOpened
);

export type IsSaveSearchButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsSaveSearchButtonDisabled: IsSaveSearchButtonDisabledSelector = createSelector(
  [(state: RootState) => state.companiesSavedSearches.isSaveSearchButtonDisabled],
  (isSaveSearchButtonDisabled: boolean) => isSaveSearchButtonDisabled
);

const companiesSavedSearchesSlice = createSlice({
  name: 'companies-saved-searches',
  initialState,
  reducers: {
    setTotalCountOfSavedSearches: (state, action: PayloadAction<number>) => {
      state.totalCountOfSavedSearches = action.payload;
    },
    firstPageOfSavedSearchesRequested: (state, action: PayloadAction<{ searchText: string; filterItem: FilterItem }>) => {
      state.totalCountOfSavedSearches = 0;
      state.pageNumber = 1;
      state.companySavedSearches = [];
      state.isLoadingCompanySavedSearches = true;
      state.filterItem = action.payload.filterItem;
      state.searchText = action.payload.searchText;
    },
    firstPageOfSavedSearchesReceived: (state, action: PayloadAction<SavedSearch[]>) => {
      state.companySavedSearches = [...action.payload];
      state.isLoadingCompanySavedSearches = false;
    },
    companiesSavedSearchesRequested: (state, action: PayloadAction<{ searchText: string; filterItem: FilterItem; pageNumber: number }>) => {
      state.isLoadingCompanySavedSearches = true;
      state.filterItem = action.payload.filterItem;
      state.pageNumber = action.payload.pageNumber;
      state.searchText = action.payload.searchText;
    },
    companiesSavedSearchesReceived: (state, action: PayloadAction<SavedSearch[]>) => {
      state.companySavedSearches = [...state.companySavedSearches, ...action.payload];
      state.isLoadingCompanySavedSearches = false;
    },
    addCompanySavedSearchRequested: (state) => {
      state.isSaveSearchButtonDisabled = true;
    },
    companySavedSearchAdded: (state, action: PayloadAction<SavedSearch>) => {
      state.companySavedSearches = [action.payload, ...state.companySavedSearches];
      state.totalCountOfSavedSearches += 1;
      state.isSaveSearchButtonDisabled = false;
    },
    updateCompanySavedSearchRequested: (state, action: PayloadAction<SavedSearch>) => {
      state.companySavedSearchToUpdate = action.payload;
    },
    updatedCompanySavedSearch: (state, action: PayloadAction<SavedSearch>) => {
      const index = state.companySavedSearches.findIndex((savedSearch) => savedSearch.id === action.payload.id);
      state.companySavedSearches = [...state.companySavedSearches.slice(0, index), action.payload, ...state.companySavedSearches.slice(index + 1)];
      state.companySavedSearchToUpdate = {} as SavedSearch;
    },
    updateCompanySavedSearchesRequested: (state, action: PayloadAction<SavedSearchWithIdInput[]>) => {
      state.companySavedSearchesToUpdate = action.payload;
    },
    updatedCompanySavedSearches: (state, action: PayloadAction<SavedSearch[]>) => {
      state.companySavedSearches = [...action.payload];
      state.companySavedSearchesToUpdate = [];
    },
    removeCompanySavedSearchRequested: (state, action: PayloadAction<SavedSearch>) => {
      state.companySavedSearchToRemove = action.payload;
    },
    removedCompanySavedSearch: (state, action: PayloadAction<string>) => {
      state.companySavedSearches = state.companySavedSearches.filter((savedSearch) => savedSearch.id !== action.payload);
      state.companySavedSearchToRemove = {} as SavedSearch;
      state.totalCountOfSavedSearches = state.totalCountOfSavedSearches - 1;
    },
    setCompanySavedSearchToRemove: (state, action: PayloadAction<SavedSearch>) => {
      state.companySavedSearchToRemove = action.payload;
      state.isDeleteDialogOpened = true;
    },
    loadNextCompanySavedSearchAfterDeleteRequested: () => {},
    closeDeleteDialog: (state) => {
      state.companySavedSearchToRemove = {} as SavedSearch;
      state.isDeleteDialogOpened = false;
    }
  }
});

export const {
  setTotalCountOfSavedSearches,
  firstPageOfSavedSearchesRequested,
  firstPageOfSavedSearchesReceived,
  companiesSavedSearchesRequested,
  companiesSavedSearchesReceived,
  addCompanySavedSearchRequested,
  companySavedSearchAdded,
  updateCompanySavedSearchRequested,
  updatedCompanySavedSearch,
  updateCompanySavedSearchesRequested,
  updatedCompanySavedSearches,
  removeCompanySavedSearchRequested,
  removedCompanySavedSearch,
  setCompanySavedSearchToRemove,
  loadNextCompanySavedSearchAfterDeleteRequested,
  closeDeleteDialog
} = companiesSavedSearchesSlice.actions;
export default companiesSavedSearchesSlice.reducer;
