import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IObjectWithKey,
  MarqueeSelection,
  PrimaryButton,
  SelectionMode,
  Selection,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  DetailsHeader,
  IDetailsRowProps,
  DetailsRow
} from '@fluentui/react';
import { Input, SearchIcon, Checkbox, Provider } from '@fluentui/react-northstar';
import { ISpecialLink } from '@mailupinc/bee-plugin/dist/types/bee';
import { useMemo, useState } from 'react';
import 'app/pages/my-activities/sending-wizard/components/email/OnlineContentDialog.scss';
import { Pagination, SendingTableRow } from 'app/pages/my-activities/sending-wizard';
import { Sending, SendingStatus } from 'app/pages/my-activities/sendings';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useTeams } from 'msteams-react-base-component';
import { useDebouncedCallback } from 'use-debounce';
import { debounceTimeInMilliseconds } from 'app/common';

interface LocalizationMessage {
  tableHeaderTitle: string;
  tableHeaderStatus: string;
  tableHeaderCreationDate: string;
  specialLinkType: string;
  specialLinkLabel: string;
  header: string;
  numberOfSendings: string;
  searchInputPlaceholder: string;
  ariaLabelForSelectionColumn: string;
  checkButtonAriaLabel: string;
  cancelButton: string;
  chooseButton: string;
  paging: {
    nextButton: string;
    previousButton: string;
    dropdownPlaceholder: string;
  };
}

interface OnlineContentDialogProps {
  sendings: Sending[];
  totalCountOfSendings: number;
  pageSize: number;
  isLoadingSendings: boolean;
  localizationMessage: LocalizationMessage;
  onResolve(data: ISpecialLink): void;
  onReject(): void;
  onSearchSendings(searchText: string): void;
  onPageClick(pageNumber: number): void;
}

export const OnlineContentDialog = ({
  sendings,
  totalCountOfSendings,
  pageSize,
  isLoadingSendings,
  localizationMessage,
  onResolve,
  onReject,
  onSearchSendings,
  onPageClick
}: OnlineContentDialogProps) => {
  const [{ theme }] = useTeams();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);
  const [selectedSendingId, setSelectedSendingId] = useState<string>('');
  const [selectedSendingMailPreviewLink, setSelectedSendingMailPreviewLink] = useState('');

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedTableRows = selection.getSelection() as SendingTableRow[];
          setSelectedSendingId(selectedTableRows.length > 0 ? selectedTableRows[0].key : '');
          setSelectedSendingMailPreviewLink(selectedTableRows.length > 0 ? selectedTableRows[0].mailPreviewLink : '');
        },
        selectionMode: SelectionMode.single
      }),
    []
  );

  const tableHeader = [
    {
      key: 'sending-key-title',
      name: localizationMessage.tableHeaderTitle,
      fieldName: 'title',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'sending-key-status',
      name: localizationMessage.tableHeaderStatus,
      fieldName: 'status',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'sending-key-created-at',
      name: localizationMessage.tableHeaderCreationDate,
      fieldName: 'createdAt',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'sending-key-empty',
      name: '',
      fieldName: '',
      isResizable: false,
      minWidth: 1,
      maxWidth: 1
    }
  ];

  const tableRows = sendings.map((sending) => {
    return {
      key: sending.id,
      title: sending.title,
      status: SendingStatus[sending.status],
      createdAt: sending.date,
      mailPreviewLink: sending.mailPreviewLink
    };
  });

  const handleResolve = () => {
    setIsOpen(false);
    onResolve({
      type: localizationMessage.specialLinkType,
      label: localizationMessage.specialLinkLabel,
      link: selectedSendingMailPreviewLink
    });
  };

  const handleReject = () => {
    setIsOpen(false);
    onReject();
  };

  const handleSearchSendings = useDebouncedCallback((searchText: string) => onSearchSendings(searchText), debounceTimeInMilliseconds);

  return (
    <Provider theme={theme}>
      <Dialog
        hidden={!isOpen}
        onDismiss={handleReject}
        dialogContentProps={{ type: DialogType.normal, title: localizationMessage.header }}
        modalProps={{ className: 'online-content-dialog', isBlocking: true }}
      >
        <div className="online-content-dialog-toolbar">
          <div className="online-content-dialog-toolbar-search">
            <span className="online-content-dialog-toolbar-items">{localizationMessage.numberOfSendings}</span>
          </div>
          <div>
            <Input
              clearable
              icon={<SearchIcon />}
              placeholder={localizationMessage.searchInputPlaceholder}
              onChange={(_, data) => handleSearchSendings(data.value ?? '')}
            />
          </div>
        </div>
        <MarqueeSelection selection={selection} className="table-results">
          <div id="scrollableDiv">
            <ShimmeredDetailsList
              items={tableRows}
              enableShimmer={!tableRows}
              columns={tableHeader}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={localizationMessage.ariaLabelForSelectionColumn}
              checkButtonAriaLabel={localizationMessage.checkButtonAriaLabel}
              checkboxVisibility={0}
              onRenderDetailsHeader={(props) => <DetailsHeader {...props}></DetailsHeader>}
              onRenderRow={(rowProps: IDetailsRowProps) => (
                <div
                  onMouseEnter={() => {
                    setHoverIndex(rowProps.itemIndex);
                  }}
                >
                  <DetailsRow
                    key={rowProps.item.id}
                    {...rowProps}
                    onRenderCheck={() => (
                      <div className="row-header-wrap">
                        {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
                          <Checkbox className="table-checkbox" checked={selection.getSelection().includes(rowProps.item)} />
                        ) : (
                          <ProfilePicture name={rowProps.item.title} imageUrl={rowProps.item.profilePictureUrl} size="small" />
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            />
          </div>
        </MarqueeSelection>
        <Pagination
          totalCount={totalCountOfSendings}
          pageSize={pageSize}
          onPageClick={onPageClick}
          isLoadingData={isLoadingSendings}
          localization={localizationMessage.paging}
        />
        <DialogFooter>
          <DefaultButton className="dialog-outline-btn" onClick={handleReject} text={localizationMessage.cancelButton} />
          <PrimaryButton className="dialog-purple-btn" disabled={selectedSendingId === ''} onClick={handleResolve} text={localizationMessage.chooseButton} />
        </DialogFooter>
      </Dialog>
    </Provider>
  );
};
