import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { client } from 'app/common/graphql/graphql-gateway.client';
import {
  addedNewTag,
  addedTagToListsSearchResults,
  addNewTagRequested,
  addTagToListsRequested,
  allTagsReceived,
  bulkRefreshLinkedSavedSearchesRequested,
  contactsFilterSuggestionsReceived,
  contactsFilterSuggestionsRequested,
  contactsSuggestionsReceived,
  contactsSuggestionsRequested,
  copiedListsReceived,
  copyListsRequested,
  createdByContactsSuggestionsReceived,
  createdByContactsSuggestionsRequested,
  deleteTagsRequested,
  fetchListsByQueryParamsPaged,
  fetchListsSuggestionsByContactName,
  fetchListsSuggestionsByMediumName,
  fetchListSuggestionsByCreatedBy,
  fetchListSuggestionsByName,
  fetchListSuggestionsByTagName,
  firstPageOfListsReceived,
  firstPageOfListsRequested,
  getAllListsTagsRequested,
  IListsSortingInput,
  List,
  listNamesSuggestionsReceived,
  listNamesSuggestionsRequested,
  listsByIdsRemoved,
  listsSuggestionsReceived,
  listsSuggestionsRequested,
  mediumsSuggestionsReceived,
  mediumsSuggestionsRequested,
  nextPageOfListsReceived,
  nextPageOfListsRequested,
  PageOfListContacts,
  PageOfLists,
  removedList,
  removedTagsFromListsSearchResults,
  removeListRequested,
  removeListsByIds,
  removeListsByIdsRequested,
  removeTagFromListsRequested,
  selectFilterItems,
  selectFilterSuggestionsListId,
  selectFilterSuggestionsPageSize,
  selectFilterSuggestionsSearchText,
  selectListsToRemoveIds,
  selectListToRemoveId,
  selectPageNumber,
  selectPageSize,
  selectSearchListsSuggestionText,
  selectSearchText,
  selectSelectedLists,
  selectSelectedListsIds,
  selectSortingInput,
  selectTagNameToCreate,
  selectTagsToDelete,
  selectTagToAddToLists,
  selectTagToRemoveFromLists,
  setTotalCountOfLists,
  tagsDeleted,
  tagsSuggestionsReceived,
  tagsSuggestionsRequested
} from 'app/pages/my-audience/lists';
import {
  addTagToLists,
  bulkRefreshLinkedSavedSearches,
  copyLists,
  fetchListSuggestions,
  removeList,
  removeTagFromLists
} from 'app/pages/my-audience/lists/services/lists.service';
import {
  closeDeleteEntitiesDialog,
  createAndAssignTag,
  deleteTags,
  fetchTagsByEntityType,
  FilterItem,
  safe,
  SearchSuggestionCategory,
  selectSelectedTableRowsIdsSelector,
  selectSignedInUser,
  TagCreationInput,
  User
} from 'app/common';
import { addedTagToList, fetchListContactsByListIdAndSearchText, removedTagsFromLists, selectSelectedListId } from 'app/pages/my-audience/lists-profile';

export function* fetchListsPagedWatcher() {
  yield takeEvery(firstPageOfListsRequested.type, safe(fetchListsPagedFlow));
  yield takeEvery(nextPageOfListsRequested.type, safe(fetchListsPagedFlow));
}

function* fetchListsPagedFlow() {
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);
  const searchText: string = yield select(selectSearchText);
  const filterItems: FilterItem[] = yield select(selectFilterItems);
  const sortingInput: IListsSortingInput = yield select(selectSortingInput);

  const pageOfLists: PageOfLists = yield call(fetchListsByQueryParamsPaged, client, searchText, filterItems, pageNumber, pageSize, sortingInput);
  if (pageNumber === 1) {
    yield put(firstPageOfListsReceived(pageOfLists.lists));
  } else {
    yield put(nextPageOfListsReceived(pageOfLists.lists));
  }

  yield put(setTotalCountOfLists(pageOfLists.totalCount));
}

export function* listSuggestionsRequestedWatcher() {
  yield takeLatest(listsSuggestionsRequested.type, safe(fetchListsSuggestionsFlow));
}

function* fetchListsSuggestionsFlow() {
  const searchText: string = yield select(selectSearchListsSuggestionText);
  const listsSuggestions: SearchSuggestionCategory[] = yield call(fetchListSuggestions, client, searchText);
  yield put(listsSuggestionsReceived(listsSuggestions));
}

function* removeListFlow() {
  const selectedListId: string = yield select(selectListToRemoveId);

  const removedListId: string = yield call(removeList, client, selectedListId);
  yield put(removedList(removedListId));
}

export function* removeListRequestedWatcher() {
  yield takeEvery(removeListRequested.type, safe(removeListFlow));
}

function* refreshLinkedSavedSearchesFlow() {
  const selectedLists: List[] = yield select(selectSelectedLists);

  yield call(
    bulkRefreshLinkedSavedSearches,
    client,
    selectedLists.map((list) => list.id)
  );

  yield put(firstPageOfListsRequested());
}

export function* refreshLinkedSavedSearchesRequestedWatcher() {
  yield takeEvery(bulkRefreshLinkedSavedSearchesRequested.type, safe(refreshLinkedSavedSearchesFlow));
}

function* copyListsFlow() {
  const selectedLists: List[] = yield select(selectSelectedLists);
  const selectedListsIds: string[] = selectedLists.map((list) => list.id);
  const signedInUser: User = yield select(selectSignedInUser);

  const lists: List[] = yield call(copyLists, client, selectedListsIds, signedInUser.id);
  yield put(copiedListsReceived(lists));
}

export function* copyListsRequestedWatcher() {
  yield takeEvery(copyListsRequested.type, safe(copyListsFlow));
}

function* listNameFilterSuggestionsRequestedFlow() {
  const name: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const listNames: string[] = yield call(fetchListSuggestionsByName, client, name, pageSize);
  yield put(listNamesSuggestionsReceived(listNames));
}

export function* listNameFilterSuggestionsRequestedWatcher() {
  yield takeEvery(listNamesSuggestionsRequested.type, safe(listNameFilterSuggestionsRequestedFlow));
}

function* fetchContactsSuggestionsFlow() {
  const name: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const contactsSuggestions: string[] = yield call(fetchListsSuggestionsByContactName, client, name, pageSize);
  yield put(contactsSuggestionsReceived(contactsSuggestions));
}

export function* listContactFilterSuggestionsRequestedWatcher() {
  yield takeLatest(contactsSuggestionsRequested.type, safe(fetchContactsSuggestionsFlow));
}

function* fetchMediumsSuggestionsFlow() {
  const name: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const mediaSuggestions: string[] = yield call(fetchListsSuggestionsByMediumName, client, name, pageSize);
  yield put(mediumsSuggestionsReceived(mediaSuggestions));
}

export function* mediumsSuggestionsRequestedWatcher() {
  yield takeLatest(mediumsSuggestionsRequested.type, safe(fetchMediumsSuggestionsFlow));
}

function* fetchTagsSuggestionsFlow() {
  const name: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const listTagSuggestions: string[] = yield call(fetchListSuggestionsByTagName, client, name, pageSize);
  yield put(tagsSuggestionsReceived(listTagSuggestions));
}

export function* tagsSuggestionsRequestedWatcher() {
  yield takeLatest(tagsSuggestionsRequested.type, safe(fetchTagsSuggestionsFlow));
}

function* fetchCreatedByContactsSuggestionsFlow() {
  const name: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const createdBySuggestions: string[] = yield call(fetchListSuggestionsByCreatedBy, client, name, pageSize);
  yield put(createdByContactsSuggestionsReceived(createdBySuggestions));
}

export function* createdByContactsSuggestionsRequestedWatcher() {
  yield takeLatest(createdByContactsSuggestionsRequested.type, safe(fetchCreatedByContactsSuggestionsFlow));
}

function* removeListsByIdsFlow() {
  const listsToRemoveIds: string[] = yield select(selectListsToRemoveIds);

  const lists: List[] = yield call(removeListsByIds, client, listsToRemoveIds);
  yield put(listsByIdsRemoved(lists));
  yield put(closeDeleteEntitiesDialog());
}

export function* removeListsByIdsWatcher() {
  yield takeEvery(removeListsByIdsRequested.type, safe(removeListsByIdsFlow));
}

function* addTagToListsFlow() {
  const tagToAdd = yield select(selectTagToAddToLists);
  const selectedListsIds = yield select(selectSelectedListsIds);

  yield call(addTagToLists, client, selectedListsIds, tagToAdd);
}

export function* addTagToListsRequestedWatcher() {
  yield takeEvery(addTagToListsRequested.type, safe(addTagToListsFlow));
}

function* addTagFlow() {
  const selectedListsIds = yield select(selectSelectedTableRowsIdsSelector);
  const tagToAdd = yield select(selectTagNameToCreate);
  const selectedListId = yield select(selectSelectedListId);

  const tagCreationInput = new TagCreationInput(tagToAdd, null, selectedListsIds, 'List');

  const createdTag = yield call(createAndAssignTag, client, tagCreationInput);
  if (selectedListId) yield put(addedTagToList(createdTag));

  yield put(addedNewTag(createdTag));
  yield put(addedTagToListsSearchResults({ selectedListsIds, tag: createdTag }));
}

export function* addListTagRequestedWatcher() {
  yield takeEvery(addNewTagRequested.type, safe(addTagFlow));
}

function* removeTagFromListsFlow() {
  const tagToRemove = yield select(selectTagToRemoveFromLists);
  const selectedCompanyIds = yield select(selectSelectedListsIds);

  yield call(removeTagFromLists, client, selectedCompanyIds, tagToRemove);
}

export function* removeTagFromListsRequestedWatcher() {
  yield takeEvery(removeTagFromListsRequested.type, safe(removeTagFromListsFlow));
}

function* deleteTagsFlow() {
  const tagsToDelete = yield select(selectTagsToDelete);
  const tagIdsToDelete = tagsToDelete.map((t) => t.id);
  const selectedListsIds = yield select(selectSelectedListsIds);

  yield call(deleteTags, client, tagIdsToDelete);
  yield put(tagsDeleted(tagIdsToDelete));
  yield put(removedTagsFromListsSearchResults({ selectedListsIds, tags: tagsToDelete }));
  yield put(removedTagsFromLists(tagIdsToDelete));
}

export function* deleteTagsFromListsRequestedWatcher() {
  yield takeEvery(deleteTagsRequested.type, safe(deleteTagsFlow));
}

function* fetchAllListTagsFlow() {
  const allTags = yield call(fetchTagsByEntityType, client, 'List');
  yield put(allTagsReceived(allTags));
}

export function* getAllListTagsRequestedWatcher() {
  yield takeEvery(getAllListsTagsRequested.type, safe(fetchAllListTagsFlow));
}

function* fetchListContactsFilterSuggestionsFlow() {
  const listId: string = yield select(selectFilterSuggestionsListId);

  const pageOfContacts: PageOfListContacts = yield call(fetchListContactsByListIdAndSearchText, client, 1, 5, '', listId);
  yield put(contactsFilterSuggestionsReceived(pageOfContacts.listContacts));
}

export function* getListContactsFilterSuggestionsRequestedWatcher() {
  yield takeEvery(contactsFilterSuggestionsRequested.type, safe(fetchListContactsFilterSuggestionsFlow));
}
