import { Alert } from '@fluentui/react-northstar';
import { MessageData, defaultTimeoutInMilliseconds, resetErrorData, selectErrorData, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { useEffect, useMemo } from 'react';
import format from 'string-format';

export const AlertWarning = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const errorData = useAppSelector<MessageData>(selectErrorData);

  const localizedErrorMessage = useMemo((): string | null => {
    if (!errorData?.localizationKey) return null;

    const message = formatMessage({ id: errorData.localizationKey });
    if (!message) return formatMessage({ id: 'default' });

    return format(message, errorData.args);
  }, [errorData, formatMessage]);

  useEffect(() => {
    if (localizedErrorMessage) {
      setTimeout(() => {
        dispatch(resetErrorData());
      }, defaultTimeoutInMilliseconds);
    }
  }, [localizedErrorMessage]);

  const handleVisibleChange = () => dispatch(resetErrorData());

  if (!localizedErrorMessage) {
    return null;
  }

  return (
    <Alert
      className="alert-warning"
      style={{
        position: 'fixed',
        top: '60px',
        right: '20px',
        zIndex: '10000000',
        width: '30%'
      }}
      content={localizedErrorMessage}
      dismissible
      dismissAction={{
        'aria-label': 'close'
      }}
      variables={{
        oof: true
      }}
      onVisibleChange={handleVisibleChange}
    />
  );
};
