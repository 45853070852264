import { hideUndoAlert, UndoableDialog, useAppDispatch } from 'app/common';
import { closeProfilePanel, setIsRemoveContactDialogOpened } from 'app/pages/my-audience/contact-profile';
import { Contact, removeContactFromStore, removeContactRequested, undoRemoveContactFromStore } from 'app/pages/my-audience/contacts';

interface DeleteContactDialogProps {
  opened: boolean;
  dialogTitle: string;
  undoAlertMessage: string;
  selectedContact: Contact;
  dialogText?: string;
  warningMessages?: string[];
}

export const DeleteContactDialog = ({ opened, dialogTitle, dialogText, undoAlertMessage, selectedContact, warningMessages }: DeleteContactDialogProps) => {
  const dispatch = useAppDispatch();
  const canDeleteContact = !selectedContact.isUser;

  const onCloseDialog = () => {
    dispatch(setIsRemoveContactDialogOpened(false));
  };

  const onExecuteAction = () => {
    dispatch(removeContactRequested(selectedContact));
    dispatch(hideUndoAlert());
  };

  const onExecuteInStore = () => {
    dispatch(removeContactFromStore(selectedContact));
    dispatch(closeProfilePanel());
  };

  const onRevertAction = () => {
    dispatch(undoRemoveContactFromStore(selectedContact));
  };

  return (
    <UndoableDialog
      deleteButtonDisabled={!canDeleteContact}
      opened={opened}
      dialogTitle={canDeleteContact && dialogTitle}
      dialogText={dialogText}
      alertMessage={undoAlertMessage}
      onExecuteAction={onExecuteAction}
      onExecuteInStore={onExecuteInStore}
      onRevertAction={onRevertAction}
      onClose={onCloseDialog}
      warningMessages={warningMessages}
    />
  );
};
