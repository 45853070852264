/* eslint-disable react-hooks/exhaustive-deps */
import Bee from '@mailupinc/bee-plugin';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ISpecialLink } from '@mailupinc/bee-plugin/dist/types/bee';
import { User, selectSignedInUser, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import {
  OnlineContentDialog,
  changeInEditorHappenedAfterOpenedFromTemplate,
  pageOfSendingsForEditorRequested,
  saveEmailBodyInStore,
  selectBeeInstance,
  selectIsLoadingSendings,
  selectIsOpenAiEnabled,
  selectIsOpenedFromTemplate,
  selectSelectedSendingJsonBody,
  selectSendings,
  selectSendingsPageSize,
  selectTotalCountOfSendings,
  setIsSavingInProgress
} from 'app/pages/my-activities/sending-wizard';
import { Sending } from 'app/pages/my-activities/sendings';

export const Editor = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const onlineContentDialogProps = useRef(null);
  const sendingsSearchText = useRef('');

  const beeInstance = useAppSelector<Bee>(selectBeeInstance);
  const locale = useAppSelector<string>(selectLocale);
  const isOpenedFromTemplate = useAppSelector<boolean>(selectIsOpenedFromTemplate);
  const jsonBody = useAppSelector<string>(selectSelectedSendingJsonBody);

  const sendings = useAppSelector<Sending[]>(selectSendings);
  const isLoadingSendings = useAppSelector<boolean>(selectIsLoadingSendings);
  const totalCountOfSendings = useAppSelector<number>(selectTotalCountOfSendings);
  const sendingsPageSize = useAppSelector<number>(selectSendingsPageSize);
  const signedInUser = useAppSelector<User>(selectSignedInUser);
  const isOpenAiEnabled = useAppSelector<boolean>(selectIsOpenAiEnabled);

  const mergeTags = [
    {
      name: formatMessage({ id: 'editor-merge-tags.first-name.name' }),
      value: '[FORENAME]'
    },
    {
      name: formatMessage({ id: 'editor-merge-tags.last-name.name' }),
      value: '[SURNAME]'
    },
    {
      name: formatMessage({ id: 'editor-merge-tags.email-address.name' }),
      value: '[EMAIL]'
    }
  ];

  const specialLinks = [
    {
      type: formatMessage({ id: 'editor-special-links.types.frequently-used' }),
      label: formatMessage({ id: 'editor-special-links.labels.unsubscribe-link' }),
      link: 'https://[unsubscribe]/'
    },
    {
      type: formatMessage({ id: 'editor-special-links.types.gdpr' }),
      label: formatMessage({ id: 'editor-special-links.labels.unsubscribe-link' }),
      link: 'https://[unsubscribe]/'
    },
    {
      type: formatMessage({ id: 'editor-special-links.types.gdpr' }),
      label: formatMessage({ id: 'editor-special-links.labels.opt-out-request' }),
      link: 'https://[opt-out-request]/'
    },
    {
      type: formatMessage({ id: 'editor-special-links.types.gdpr' }),
      label: formatMessage({ id: 'editor-special-links.labels.rectification-request' }),
      link: 'https://[rectification-request]/'
    },
    {
      type: formatMessage({ id: 'editor-special-links.types.gdpr' }),
      label: formatMessage({ id: 'editor-special-links.labels.portability-request' }),
      link: 'https://[portability-request]/'
    },
    {
      type: formatMessage({ id: 'editor-special-links.types.gdpr' }),
      label: formatMessage({ id: 'editor-special-links.labels.explicit-consent-request' }),
      link: 'https://[explicit-consent-request]/'
    }
  ];

  useEffect(() => {
    const parentElement = document.getElementById('modal-container');
    if (parentElement) {
      onlineContentDialogProps.current = {
        ...onlineContentDialogProps.current,
        sendings: sendings,
        totalCountOfSendings: totalCountOfSendings,
        isLoadingSendings: isLoadingSendings,
        localizationMessage: {
          ...onlineContentDialogProps.current.localizationMessage,
          numberOfSendings: formatMessage({ id: 'editor-online-content-dialog.number-of-sendings' }, { counter: totalCountOfSendings })
        }
      };
      const updatedModal = React.createElement(OnlineContentDialog, onlineContentDialogProps.current);
      ReactDOM.render(updatedModal, parentElement);
    }
  }, [sendings, totalCountOfSendings, isLoadingSendings]);

  const beeConfig = {
    uid: signedInUser.id,
    container: 'bee-plugin-container',
    specialLinks: specialLinks,
    mergeTags: mergeTags,
    language: locale.includes('-') ? locale : `${locale}-${locale.toUpperCase()}`,
    loadingSpinnerDisableOnSave: true,
    trackChanges: true,
    topBarEnabled: false,
    addOns: [
      {
        id: 'ai-integration',
        enabled: isOpenAiEnabled,
        settings: {
          isSuggestionsDisabled: true
        }
      }
    ],
    onChange: () => {
      dispatch(setIsSavingInProgress(true));
      beeInstance.save();

      if (isOpenedFromTemplate) {
        dispatch(changeInEditorHappenedAfterOpenedFromTemplate());
      }
    },
    onSave: (jsonFile: string, htmlFile: string) => {
      dispatch(saveEmailBodyInStore({ json: jsonFile, html: htmlFile }));
      dispatch(setIsSavingInProgress(false));
    },
    onSend: () => {},
    contentDialog: {
      specialLinks: {
        label: formatMessage({ id: 'editor-online-content-dialog.label' }),
        handler: async function (resolve, reject) {
          dispatch(pageOfSendingsForEditorRequested({ searchText: '', pageNumber: 1, pageSize: sendingsPageSize }));

          const rootDiv = document.getElementById('root');
          const modalContainer = document.createElement('div');
          modalContainer.id = 'modal-container';
          rootDiv.appendChild(modalContainer);

          onlineContentDialogProps.current = {
            localizationMessage: {
              tableHeaderTitle: formatMessage({ id: 'editor-online-content-dialog.table-header.title' }),
              tableHeaderStatus: formatMessage({ id: 'editor-online-content-dialog.table-header.status' }),
              tableHeaderCreationDate: formatMessage({ id: 'editor-online-content-dialog.table-header.creation-date' }),
              specialLinkType: formatMessage({ id: 'editor-online-content-dialog.special-link-type' }),
              specialLinkLabel: formatMessage({ id: 'editor-online-content-dialog.label' }),
              header: formatMessage({ id: 'editor-online-content-dialog.header' }),
              numberOfSendings: formatMessage({ id: 'editor-online-content-dialog.number-of-sendings' }, { counter: totalCountOfSendings }),
              searchInputPlaceholder: formatMessage({ id: 'editor-online-content-dialog.search-input-placeholder' }),
              ariaLabelForSelectionColumn: formatMessage({ id: 'aria-labels.toggle-selection' }),
              checkButtonAriaLabel: formatMessage({ id: 'aria-labels.click-button' }),
              cancelButton: formatMessage({ id: 'buttons.cancel' }),
              chooseButton: formatMessage({ id: 'buttons.choose' }),
              paging: {
                nextButton: formatMessage({ id: 'buttons.next' }),
                previousButton: formatMessage({ id: 'buttons.previous' })
              }
            },
            sendings: sendings,
            totalCountOfSendings: totalCountOfSendings,
            pageSize: sendingsPageSize,
            isLoadingSendings: isLoadingSendings,
            onSearchSendings: (searchText: string) => {
              sendingsSearchText.current = searchText;
              dispatch(pageOfSendingsForEditorRequested({ searchText: searchText, pageNumber: 1, pageSize: sendingsPageSize }));
            },
            onPageClick: (pageNumber: number) => {
              dispatch(pageOfSendingsForEditorRequested({ searchText: sendingsSearchText.current, pageNumber: pageNumber, pageSize: sendingsPageSize }));
            },
            onResolve: (specialLink: ISpecialLink) => {
              ReactDOM.unmountComponentAtNode(modalContainer);
              rootDiv.removeChild(modalContainer);
              resolve(specialLink);
            },
            onReject: () => {
              ReactDOM.unmountComponentAtNode(modalContainer);
              rootDiv.removeChild(modalContainer);
              reject();
            }
          };

          const element = React.createElement(OnlineContentDialog, onlineContentDialogProps.current);
          ReactDOM.render(element, modalContainer);
        }
      }
    }
  };

  useEffect(() => {
    async function startBeeFreeAsync() {
      await beeInstance.start(beeConfig, !!jsonBody ? JSON.parse(jsonBody) : {});
      beeInstance.toggleMergeTagsPreview();
    }

    startBeeFreeAsync().then();
  }, [beeInstance, dispatch, locale, jsonBody, isOpenedFromTemplate]);

  return <div id="bee-plugin-container" />;
};
