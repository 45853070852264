import { Button } from '@fluentui/react-northstar';
import { Dialog, DialogFooter, DialogType, IComboBox, TimePicker } from '@fluentui/react';
import { DatePicker, useAppDispatch, useAppSelector } from 'app/common';
import {
  closeScheduleSendDialog,
  resetSelectedSending,
  scheduleSendingRequested,
  selectIsScheduleSendDialogVisible
} from 'app/pages/my-activities/sending-wizard';
import { DateTime } from 'luxon';
import { FormattedMessage, useIntl } from 'app/i18n';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const ScheduleSendDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const isDialogOpened = useAppSelector<boolean>(selectIsScheduleSendDialogVisible);
  const [selectedDateTime, setSelectedDateTime] = useState<Date>();
  const [hasTimeBeenManuallySelected, setHasTimeBeenManuallySelected] = useState<boolean>(false);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'emailing-wizard-preview-and-send-step.schedule-send-dialog.title' })
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const onCloseDialog = () => {
    dispatch(closeScheduleSendDialog());
  };

  const setDateAndSuggestedTime = (date: Date) => {
    let scheduledDateTime = new Date(selectedDateTime);

    if (!hasTimeBeenManuallySelected) {
      scheduledDateTime = roundToTheNearestScheduledTime(date);
    }
    scheduledDateTime.setFullYear(date.getFullYear());
    scheduledDateTime.setMonth(date.getMonth());
    scheduledDateTime.setDate(date.getDate());

    setSelectedDateTime(scheduledDateTime);
  };

  const roundToTheNearestScheduledTime = (date: Date): Date => {
    const currentDate = new Date();
    const shouldRoundToNextHour = currentDate.getMinutes() >= 30;
    let scheduledHour = currentDate.getHours();
    let scheduledMinutes = 0;

    if (shouldRoundToNextHour) {
      scheduledHour += 1;
    } else {
      scheduledMinutes = 30;
    }

    const updatedDate = new Date(date);
    updatedDate.setHours(scheduledHour);
    updatedDate.setMinutes(scheduledMinutes);

    return updatedDate;
  };

  const handleTimeSelected = (selectedTime: Date) => {
    const selectedDateTimeTemp = new Date(selectedDateTime);

    selectedDateTimeTemp.setHours(selectedTime.getHours());
    selectedDateTimeTemp.setMinutes(selectedTime.getMinutes());
    selectedDateTimeTemp.setSeconds(selectedTime.getSeconds());
    selectedDateTimeTemp.setMilliseconds(selectedTime.getMilliseconds());

    setSelectedDateTime(selectedDateTimeTemp);
    setHasTimeBeenManuallySelected(true);
  };

  const onScheduleSend = () => {
    dispatch(scheduleSendingRequested(DateTime.fromJSDate(selectedDateTime).toUTC()));
    dispatch(closeScheduleSendDialog());
    dispatch(resetSelectedSending());
    navigate(`/my-activities/e-mailings`);
  };

  return (
    <Dialog hidden={!isDialogOpened} onDismiss={onCloseDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="profile-photo-dialog">
        <div>
          <FormattedMessage id={'emailing-wizard-preview-and-send-step.schedule-send-dialog.setup-schedule'} />
        </div>
        <DatePicker onSelectDate={setDateAndSuggestedTime} value={selectedDateTime} />
        <TimePicker
          disabled={!selectedDateTime}
          useHour12
          allowFreeform
          label={formatMessage({ id: 'emailing-wizard-preview-and-send-step.schedule-send-dialog.choose-time' })}
          onChange={(_: React.FormEvent<IComboBox>, selectedTime: Date) => handleTimeSelected(selectedTime)}
          useComboBoxAsMenuWidth
        />
        <DialogFooter>
          <Button disabled={!selectedDateTime} primary content={formatMessage({ id: 'buttons.send' })} onClick={onScheduleSend} />
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCloseDialog} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
