import { TeamsMsal2Provider } from '@microsoft/mgt-teams-msal2-provider';
import { useIntl } from 'app/i18n';
import * as MicrosoftTeams from '@microsoft/teams-js';

export const ConsentPopup = () => {
  const { formatMessage } = useIntl();

  TeamsMsal2Provider.microsoftTeamsLib = MicrosoftTeams;
  TeamsMsal2Provider.handleAuth()
    .then(() => {
      if (window.location.href.includes('error=access_denied&error_subcode=cancel')) {
        window.close();
      }
    })
    .catch((err) => console.log(err));

  return (
    <div>
      <h1>{formatMessage({ id: 'admin-consent.redirecting' })}</h1>
    </div>
  );
};
