import { call, put, select, takeEvery } from 'redux-saga/effects';
import { MessageData, Comment, MediumCategory, ProfilePicture, safe, setAlertData } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { Company, CompanyUpdateModel, updateCompany as updateCompanyInTable, updatedProfilePictureSearchResults } from 'app/pages/my-audience/companies';
import {
  addCommentToCompany,
  addCommentToCompanyRequested,
  addContactRequested,
  addContributionRequested,
  addedCommentToCompany,
  addedContact,
  addedContribution,
  allCompanyCategoriesReceived,
  fetchCompanyCategories,
  fetchCompanyContacts,
  fetchContactsByNameAndRoleAndNotContainedInCompany,
  fetchSelectedCompanyProfile,
  fetchSelectedCompanyRequested,
  getAllCompanyCategoriesRequested,
  pageOfCompanyContactsReceived,
  pageOfCompanyContactsRequested,
  pageOfWizardContactsReceived,
  pageOfWizardContactsRequested,
  profilePictureUpdated,
  removeContributionRequested,
  removedContribution,
  removeProfilePicture,
  removeProfilePictureRequested,
  selectCollaborationToAdd,
  selectCollaborationToUpdate,
  selectCommentToAddToCompany,
  selectCompanyContactsPageNumber,
  selectCompanyContactsPageSize,
  selectCompanyContactsRoleFilter,
  selectCompanyContactsSearchText,
  selectCompanyToUpdate,
  selectContactToAdd,
  selectContactToRemove,
  selectContactToUpdate,
  selectedCompanyReceived,
  selectImageToUpload,
  selectSelectedCompany,
  selectSelectedCompanyId,
  selectSelectedContact,
  selectWizardContactsPageNumber,
  selectWizardContactsPageSize,
  selectWizardContactsRoleFilter,
  selectWizardContactsSearchText,
  updateCollaborationReceived,
  updateCollaborationRequested,
  updateCompany,
  updateCompanyRequested,
  updatedCompany,
  updateProfilePicture,
  updateProfilePictureRequested
} from 'app/pages/my-audience/company-profile';
import { addCollaboration, removeCollaboration, updateCollaboration } from 'app/pages/my-audience/contact-profile';
import { addContact, Collaboration, Contact, PageOfContacts } from 'app/pages/my-audience/contacts';

function* fetchSelectedCompanyFlow() {
  const selectedCompanyId: string = yield select(selectSelectedCompanyId);
  const selectedCompany: Company = yield call(fetchSelectedCompanyProfile, client, selectedCompanyId);
  yield put(selectedCompanyReceived(selectedCompany));
}

export function* fetchSelectedCompanyRequestedWatcher() {
  yield takeEvery(fetchSelectedCompanyRequested.type, safe(fetchSelectedCompanyFlow));
}

function* addCommentToCompanyFlow() {
  const commentToAdd: Comment = yield select(selectCommentToAddToCompany);
  const selectedCompanyId: string = yield select(selectSelectedCompanyId);

  const comment: Comment = yield call(addCommentToCompany, client, selectedCompanyId, commentToAdd);
  yield put(addedCommentToCompany(comment));
}

export function* addCommentToCompanyRequestedWatcher() {
  yield takeEvery(addCommentToCompanyRequested.type, safe(addCommentToCompanyFlow));
}

function* updateProfilePictureFlow() {
  const selectedCompany: Company = yield select(selectSelectedCompany);
  const imageToUpload: ProfilePicture = yield select(selectImageToUpload);

  const imageUrl: string = yield call(updateProfilePicture, client, selectedCompany.id, imageToUpload);
  yield put(profilePictureUpdated(imageUrl));
  yield put(updatedProfilePictureSearchResults({ companyId: selectedCompany.id, imageUrl: imageUrl }));
}

export function* updateCompanyProfilePictureRequestedWatcher() {
  yield takeEvery(updateProfilePictureRequested.type, safe(updateProfilePictureFlow));
}

function* removeProfilePictureFlow() {
  const selectedCompany = yield select(selectSelectedCompany);

  yield call(removeProfilePicture, client, selectedCompany.id);
  yield put(profilePictureUpdated(''));
  yield put(updatedProfilePictureSearchResults({ companyId: selectedCompany.id, imageUrl: '' }));
}

export function* removeCompanyProfilePictureRequestedWatcher() {
  yield takeEvery(removeProfilePictureRequested.type, safe(removeProfilePictureFlow));
}

function* updateCompanyFlow() {
  const id: string = yield select(selectSelectedCompanyId);
  const companyToUpdate: Company = yield select(selectCompanyToUpdate);
  const updateModel = new CompanyUpdateModel(
    companyToUpdate.name,
    companyToUpdate.profilePictureUrl,
    companyToUpdate.parentCompany,
    companyToUpdate.contactData,
    companyToUpdate.databaseType,
    companyToUpdate.registrationNumber,
    companyToUpdate.comments,
    companyToUpdate.competitors,
    companyToUpdate.profit,
    companyToUpdate.director,
    companyToUpdate.description,
    companyToUpdate.category.id ? companyToUpdate.category : null,
    companyToUpdate.foundationDate
  );

  const company: Company = yield call(updateCompany, client, id, updateModel);
  yield put(updatedCompany(company));
  yield put(updateCompanyInTable(company));
  yield put(setAlertData(new MessageData('alert-company-updated')));
}

export function* updateCompanyRequestedWatcher() {
  yield takeEvery(updateCompanyRequested.type, safe(updateCompanyFlow));
}

function* fetchCompanyContactsFlow() {
  const selectedCompanyId: string = yield select(selectSelectedCompanyId);
  const pageNumber: number = yield select(selectCompanyContactsPageNumber);
  const pageSize: number = yield select(selectCompanyContactsPageSize);
  const searchText: string = yield select(selectCompanyContactsSearchText);
  const role: string = yield select(selectCompanyContactsRoleFilter);

  const pageOfContacts: PageOfContacts = yield call(fetchCompanyContacts, client, pageNumber, pageSize, searchText, role, selectedCompanyId);

  yield put(pageOfCompanyContactsReceived({ contacts: pageOfContacts.contacts, totalCountOfContacts: pageOfContacts.totalCount }));
}

export function* getCompanyContactsRequestedWatcher() {
  yield takeEvery(pageOfCompanyContactsRequested.type, safe(fetchCompanyContactsFlow));
}

function* addContactCompanyFlow() {
  const contactToAdd: Contact = yield select(selectContactToAdd);

  const contact = yield call(addContact, client, contactToAdd);
  yield put(addedContact(contact));
}

export function* addContactCompanyRequestedWatcher() {
  yield takeEvery(addContactRequested.type, safe(addContactCompanyFlow));
}

function* updateContributionCompanyFlow() {
  const contact: Contact = yield select(selectContactToUpdate);
  const collaborationToUpdate: Collaboration = yield select(selectCollaborationToUpdate);
  const updatedContact: Contact = yield call(updateCollaboration, client, contact.id, collaborationToUpdate);

  yield put(updateCollaborationReceived(updatedContact));
  yield put(setAlertData(new MessageData('alert-collaboration-updated')));
}

export function* updateContributionCompanyRequestedWatcher() {
  yield takeEvery(updateCollaborationRequested.type, safe(updateContributionCompanyFlow));
}

function* removeContributionCompanyFlow() {
  const companyId: string = yield select(selectSelectedCompanyId);
  const contactToRemove: Contact = yield select(selectContactToRemove);

  yield call(removeCollaboration, client, contactToRemove.id, contactToRemove.collaborations.find((c) => c.medium.id === companyId).id);
  yield put(removedContribution());
}

export function* removeContributionCompanyRequestedWatcher() {
  yield takeEvery(removeContributionRequested.type, safe(removeContributionCompanyFlow));
}

function* addContributionCompanyFlow() {
  const collaborationToAdd: Collaboration = yield select(selectCollaborationToAdd);
  const selectedContact: Contact = yield select(selectSelectedContact);

  yield call(addCollaboration, client, selectedContact.id, collaborationToAdd);
  yield put(addedContribution());
}

export function* addContributionCompanyRequestedWatcher() {
  yield takeEvery(addContributionRequested.type, safe(addContributionCompanyFlow));
}

function* fetchAllCompanyCategoriesFlow() {
  const allCompanyCategories: MediumCategory[] = yield call(fetchCompanyCategories, client);
  yield put(allCompanyCategoriesReceived(allCompanyCategories));
}

export function* getAllCompanyCategoriesRequestedWatcher() {
  yield takeEvery(getAllCompanyCategoriesRequested.type, safe(fetchAllCompanyCategoriesFlow));
}

function* fetchWizardContactsFlow() {
  const selectedCompanyId: string = yield select(selectSelectedCompanyId);
  const pageNumber: number = yield select(selectWizardContactsPageNumber);
  const pageSize: number = yield select(selectWizardContactsPageSize);
  const searchText: string = yield select(selectWizardContactsSearchText);
  const role: string = yield select(selectWizardContactsRoleFilter);

  const pageOfContacts: PageOfContacts = yield call(
    fetchContactsByNameAndRoleAndNotContainedInCompany,
    client,
    pageNumber,
    pageSize,
    searchText,
    role,
    selectedCompanyId
  );

  yield put(pageOfWizardContactsReceived({ contacts: pageOfContacts.contacts, totalCountOfContacts: pageOfContacts.totalCount }));
}

export function* getWizardContactsRequestedWatcher() {
  yield takeEvery(pageOfWizardContactsRequested.type, safe(fetchWizardContactsFlow));
}
