import * as microsoftTeams from '@microsoft/teams-js';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ACCESS_TOKEN_CACHE, MICROSOFT_ACCESS_TOKEN_CACHE, accessTokenScopes, msalInstance } from 'auth';
import { authenticateUser } from 'app/common';
import { Providers } from '@microsoft/mgt-element';
import { localStorageEvents, setLocalStorage } from 'app/common/utils/local-storage';

// We will provide new token 10s before token actually expire to prevent interruption
const invalidateTokenSecondsBeforeExpiration = 10;
const getExpirationMilliSeconds = (token: string) => (jwt_decode<JwtPayload>(token).exp - invalidateTokenSecondsBeforeExpiration) * 1000;
const tokenExpired = (token: string) => Date.now() >= getExpirationMilliSeconds(token);

const updateAccessTokenInStorage = (accessToken: string) => {
  setLocalStorage({ key: ACCESS_TOKEN_CACHE, value: accessToken, silent: false, eventName: localStorageEvents.ACCESS_TOKEN_CHANGE_EVENT_NAME });
};

export const getAccessToken = async (idToken: string) => {
  const tokenInCache = localStorage.getItem(ACCESS_TOKEN_CACHE);
  if (tokenInCache && !tokenExpired(tokenInCache)) return tokenInCache;

  const accessToken = await authenticateUser(idToken);
  updateAccessTokenInStorage(accessToken);

  return accessToken;
};

export const getMicrosoftAccessToken = async () => {
  const tokenInCache = localStorage.getItem(MICROSOFT_ACCESS_TOKEN_CACHE);
  if (tokenInCache && !tokenExpired(tokenInCache)) return tokenInCache;

  const tokenFromProvider = await Providers.globalProvider.getAccessToken();
  localStorage.setItem(MICROSOFT_ACCESS_TOKEN_CACHE, tokenFromProvider);
  return tokenFromProvider;
};

const handleGetTokensSilentFailure = (error: Error) => {
  console.error(`Can't obtain access token silently: ${error.message}`);
  return null;
};

export const getTokensSilentInTeams = async () => {
  try {
    const idToken = await microsoftTeams.authentication.getAuthToken({ silent: true });
    const [accessToken, graphApiAccessToken] = await Promise.all([getAccessToken(idToken), getMicrosoftAccessToken()]);

    return { accessToken, graphApiAccessToken };
  } catch (error) {
    return handleGetTokensSilentFailure(error);
  }
};

export const getTokensSilentInBrowser = async () =>
  await msalInstance
    .acquireTokenSilent({ scopes: accessTokenScopes, account: msalInstance.getAllAccounts()[0] })
    .then(async ({ accessToken, idToken }) => {
      const usersToken = await getAccessToken(idToken);

      return { accessToken: usersToken, graphApiAccessToken: accessToken };
    })
    .catch(handleGetTokensSilentFailure);

export const getIdTokenInTeams = async () => {
  return await microsoftTeams.authentication.getAuthToken({ silent: true });
};
