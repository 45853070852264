import { Medium } from 'app/pages/my-audience/contact-profile';
import { Comment, ITableData, TagProjection } from 'app/common';
import { ActivityUser } from 'app/pages/my-activities/sendings';
import { DateTime } from 'luxon';
import { DefaultCompany } from 'app/common/graphql/generated/graphql-gateway';

export enum DataSourceStatus {
  Synchronized = 0,
  Desynchronized,
  Modified
}

export class Contact {
  constructor(
    public id: string,
    public salutation: string,
    public firstName: string,
    public lastName: string,
    public profilePictureUrl: string,
    public isGloballySignedOut: boolean,
    public tags: TagProjection[],
    public topics: Topic[],
    public mediaResorts: MediaResort[],
    public collaborations: Collaboration[],
    public comments: Comment[],
    public blacklists: Blacklist[],
    public preferredLanguages: PreferredLanguage[],
    public ownContactData?: OwnContactData,
    public dataSourceKey?: string,
    public isUser?: boolean,
    public dataSourceStatus?: DataSourceStatus
  ) {}

  defaultPhoneNumber = (): PhoneNumber => {
    const collaboration = this.collaborations.find((c) => c.landlinePhoneNumber.isPrimary || c.mobilePhoneNumber.isPrimary);
    if (collaboration !== undefined) {
      return collaboration.landlinePhoneNumber.isPrimary ? collaboration.landlinePhoneNumber : collaboration.mobilePhoneNumber;
    }

    if (this.ownContactData?.landlinePhoneNumber.isPrimary || this.ownContactData?.mobilePhoneNumber.isPrimary) {
      return this.ownContactData.landlinePhoneNumber.isPrimary ? this.ownContactData.landlinePhoneNumber : this.ownContactData.mobilePhoneNumber;
    }

    return undefined;
  };

  defaultPhoneNumberContactData = (): ContactData | Collaboration => {
    const defaultCollaboration = this.collaborations.find((c) => c.landlinePhoneNumber.isPrimary || c.mobilePhoneNumber.isPrimary);
    const defaultOwnContactData =
      this.ownContactData.mobilePhoneNumber.isPrimary || this.ownContactData.landlinePhoneNumber.isPrimary ? this.ownContactData : undefined;

    return defaultCollaboration ? defaultCollaboration : defaultOwnContactData;
  };

  primaryEmailAddress = (): string => {
    const primaryCollaboration = this.collaborations.find((c) => c.isPrimary);
    if (primaryCollaboration !== undefined) {
      return primaryCollaboration.emailAddress;
    }

    return this.ownContactData && this.ownContactData.isPrimary ? this.ownContactData.emailAddress : '';
  };

  hasEmailAddress = (): boolean => {
    if (this.primaryEmailAddress() !== '') {
      return true;
    }

    if (this.collaborations.find((c) => c.emailAddress !== '') !== undefined || this.ownContactData?.emailAddress !== '') {
      return true;
    }

    return false;
  };

  sendingContactData = () => {
    const primaryCollaboration = this.collaborations.find((c) => c.isPrimary);
    if (primaryCollaboration !== undefined && primaryCollaboration?.emailAddress !== '') {
      return primaryCollaboration;
    }

    if (this.ownContactData?.isPrimary && this.ownContactData?.emailAddress !== '') {
      return this.ownContactData;
    }

    const collaboration = this.collaborations.find((c) => c.emailAddress !== '');

    return collaboration ? collaboration : this.ownContactData?.emailAddress !== '' ? this.ownContactData : undefined;
  };
}

export class ActivityParticipant {
  constructor(public id: string, public contact: Contact, public collaborationId: string) {}
}

export class ActivityCardModel {
  constructor(
    public id: string,
    public activityType: string,
    public iconName: string,
    public date: string,
    public time: string,
    public title: string,
    public createdByName: string
  ) {}
}

export enum ActivityTypes {
  Email = 0,
  Call,
  Appointment,
  Webinar,
  Event,
  Chat,
  Task,
  Sending
}

export enum ActivityTimelineTypes {
  Upcoming = 0,
  Past
}

export class Activity {
  constructor(
    public id: string,
    public type: ActivityTypes,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public date: Date,
    public displayDate: string,
    public displayTime: string
  ) {}
}

export class Email extends Activity {
  constructor(
    public id: string,
    public subject: string,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public scheduledFor?: Date
  ) {
    super(
      id,
      ActivityTypes.Email,
      comment,
      title,
      participants,
      createdAt,
      createdBy,
      scheduledFor,
      DateTime.fromJSDate(scheduledFor).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(scheduledFor).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export class Call extends Activity {
  constructor(
    public id: string,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public scheduledFor?: Date
  ) {
    super(
      id,
      ActivityTypes.Call,
      comment,
      title,
      participants,
      createdAt,
      createdBy,
      scheduledFor,
      DateTime.fromJSDate(scheduledFor).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(scheduledFor).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export class Appointment extends Activity {
  constructor(
    public id: string,
    public subject: string,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public startsAt: Date,
    public endsAt: Date
  ) {
    super(
      id,
      ActivityTypes.Appointment,
      comment,
      title,
      participants,
      createdAt,
      createdBy,
      startsAt,
      DateTime.fromJSDate(startsAt).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(startsAt).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export class Webinar extends Activity {
  constructor(
    public id: string,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public startsAt: Date,
    public endsAt: Date
  ) {
    super(
      id,
      ActivityTypes.Webinar,
      comment,
      title,
      participants,
      createdAt,
      createdBy,
      startsAt,
      DateTime.fromJSDate(startsAt).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(startsAt).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export class Event extends Activity {
  constructor(
    public id: string,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public startsAt: Date,
    public endsAt: Date
  ) {
    super(
      id,
      ActivityTypes.Event,
      comment,
      title,
      participants,
      createdAt,
      createdBy,
      startsAt,
      DateTime.fromJSDate(startsAt).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(startsAt).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export class Chat extends Activity {
  constructor(
    public id: string,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public scheduledFor?: Date
  ) {
    super(
      id,
      ActivityTypes.Chat,
      comment,
      title,
      participants,
      createdAt,
      createdBy,
      scheduledFor,
      DateTime.fromJSDate(scheduledFor).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(scheduledFor).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export class Task extends Activity {
  constructor(
    public id: string,
    public subject: string,
    public comment: string,
    public title: string,
    public participants: ActivityParticipant[],
    public createdAt: Date,
    public createdBy: ActivityUser,
    public deadline: Date
  ) {
    super(
      id,
      ActivityTypes.Task,
      comment,
      title,
      participants,
      createdAt,
      createdBy,
      deadline,
      DateTime.fromJSDate(deadline).toLocaleString(DateTime.DATE_SHORT),
      DateTime.fromJSDate(deadline).toLocaleString(DateTime.TIME_SIMPLE)
    );
  }
}

export class ContactData {
  constructor(
    public type: ContactDataType,
    public address: string,
    public country: string,
    public landlinePhoneNumber: PhoneNumber,
    public mobilePhoneNumber: PhoneNumber,
    public emailAddress: string,
    public blogUrl: string,
    public websiteUrl: string,
    public twitterProfileUrl: string,
    public instagramProfileUrl: string,
    public linkedInProfileUrl: string,
    public youtubeProfileUrl: string,
    public facebookProfileUrl: string,
    public isPrimary: boolean,
    public city: string,
    public postalCode: string
  ) {}
}

export enum ContactDataType {
  Collaboration = 1,
  OwnContactData
}

export type AddCollaboration = {
  collaboration: Collaboration;
  contact: Contact;
};

export type FilterValuePair = {
  label: string;
  value: string | ActivityTypes;
};

export class OwnContactData extends ContactData {
  constructor(
    public address: string,
    public country: string,
    public landlinePhoneNumber: PhoneNumber,
    public mobilePhoneNumber: PhoneNumber,
    public emailAddress: string,
    public blogUrl: string,
    public websiteUrl: string,
    public twitterProfileUrl: string,
    public instagramProfileUrl: string,
    public linkedInProfileUrl: string,
    public youtubeProfileUrl: string,
    public facebookProfileUrl: string,
    public isPrimary: boolean,
    public city: string,
    public postalCode: string,
    public fax: string
  ) {
    super(
      ContactDataType.OwnContactData,
      address,
      country,
      landlinePhoneNumber,
      mobilePhoneNumber,
      emailAddress,
      blogUrl,
      websiteUrl,
      twitterProfileUrl,
      instagramProfileUrl,
      linkedInProfileUrl,
      youtubeProfileUrl,
      facebookProfileUrl,
      isPrimary,
      city,
      postalCode
    );
  }
}

export class Collaboration extends ContactData {
  constructor(
    public id: string,
    public medium: Medium,
    public jobTitle: JobTitle,
    public jobDescription: string,
    public address: string,
    public country: string,
    public landlinePhoneNumber: PhoneNumber,
    public mobilePhoneNumber: PhoneNumber,
    public emailAddress: string,
    public blogUrl: string,
    public websiteUrl: string,
    public twitterProfileUrl: string,
    public instagramProfileUrl: string,
    public linkedInProfileUrl: string,
    public youtubeProfileUrl: string,
    public facebookProfileUrl: string,
    public isPrimary: boolean,
    public city: string,
    public postalCode: string
  ) {
    super(
      ContactDataType.Collaboration,
      address,
      country,
      landlinePhoneNumber,
      mobilePhoneNumber,
      emailAddress,
      blogUrl,
      websiteUrl,
      twitterProfileUrl,
      instagramProfileUrl,
      linkedInProfileUrl,
      youtubeProfileUrl,
      facebookProfileUrl,
      isPrimary,
      city,
      postalCode
    );
  }
}

export class JobTitle {
  constructor(public id: string, public name: string, public dataSourceKey: string, public mediumType: MediumType) {}
}

export class PhoneNumber {
  constructor(public value: string, public isPrimary: boolean) {}
}

export class Topic {
  constructor(public id: string, public name: string, public dataSourceKey: string) {}
}

export class MediaResort {
  constructor(public id: string, public name: string, public dataSourceKey: string) {}
}

export class Blacklist {
  constructor(public id: string, public name: string) {}
}

export class PreferredLanguage {
  constructor(public id: string, public name: string, public dataSourceKey: string) {}
}

export enum MediumType {
  MediaOutlet = 1,
  Company = 2
}

export const MediumTypeName = (value: number): string => {
  if (value === 1) {
    return 'MediaOutlet';
  } else {
    return 'Company';
  }
};

export class MediumTypeInput {
  constructor(public name: string, public value: MediumType) {}
}

export class ContactSearchResultTableData implements ITableData {
  constructor(
    public id: string,
    public key: string,
    public name: string,
    public profilePictureUrl: string,
    public role: string,
    public mediumName: string,
    public resort: string,
    public tags: string,
    public preferredLanguages: string,
    public country: string,
    public dataSourceKey: string,
    public isUser: boolean
  ) {}
}

export class Group {
  constructor(public objectId: string, public name: string, public members: AadContact[]) {}
}

export class AadContact {
  constructor(
    public objectId: string,
    public contactId: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public defaultCompany: DefaultCompany
  ) {}
}

export class PageOfContacts {
  constructor(public totalCount: number, public contacts: Contact[]) {}
}

export class PageOfActivities {
  constructor(public totalCount: number, public activities: Activity[]) {}
}

export enum FileTypes {
  csv = 'csv',
  vCard = 'vCard'
}

export interface ISortingInput {
  sortOption: string;
  sortOrder: string;
}

export class ContactsSortingInput implements ISortingInput {
  sortOption: string;
  sortOrder: string;

  constructor(sortOptionParam: string, sortOrderParam: string) {
    this.sortOption = this.determineSortingOption(sortOptionParam);
    this.sortOrder = sortOrderParam;
  }

  private determineSortingOption = (sortOption: string): string => {
    switch (sortOption) {
      case 'name':
        return 'CONTACT_NAME';
      case 'role':
        return 'ROLE';
      case 'mediumName':
        return 'MEDIUM';
      case 'resort':
        return 'RESORT';
      case 'tags':
        return 'TAGS';
      case 'preferredLanguages':
        return 'LANGUAGES';
      default:
        return 'NAME';
    }
  };
}

export class ExportContactCsvHeader {
  constructor(
    public nameLabel: string,
    public jobTitleLabel: string,
    public emailAddressLabel: string,
    public contactDataLabel: string,
    public zimpelDoesNotAllowExportMessage: string
  ) {}
}

export class CollaborationWithContactInfo {
  constructor(
    public contactId: string,
    public firstName: string,
    public lastName: string,
    public collaboration: Collaboration,
    public useDifferentAddress: boolean
  ) {}
}

export class CollaborationDetails {
  constructor(public collaboration: Collaboration, public useDifferentAddress: boolean) {}
}

export class CollaborationWithContactId {
  constructor(public contactId: string, public collaboration: Collaboration) {}
}
