import { Pivot, PivotItem } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'app/i18n';
import { PIVOT_ITEMS } from 'app/common';

export const ListPanelPivotItems = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleTabClick = (relativePath: string) => {
    navigate(relativePath, { replace: true });
  };

  return (
    <div className="profile-panel-tabs">
      <Pivot onLinkClick={(e) => handleTabClick(e.props.itemKey)}>
        <PivotItem itemKey={PIVOT_ITEMS.PROFILE} headerText={formatMessage({ id: 'list-profile-tabs.profile' })}></PivotItem>
        <PivotItem itemKey={PIVOT_ITEMS.CONTACTS} headerText={formatMessage({ id: 'list-profile-tabs.contacts' })}></PivotItem>
      </Pivot>
    </div>
  );
};
