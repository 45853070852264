import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createCompany, createSearchSuggestionCategory, FilterItem, SearchSuggestionCategory, Tag, TagProjection } from 'app/common';
import {
  AddCompanyDocument,
  AddCompanyMutation,
  Company as GqlCompany,
  CompanyInput,
  CompanySuggestionsByCityDocument,
  CompanySuggestionsByCityQuery,
  CompanySuggestionsByContactNameDocument,
  CompanySuggestionsByContactNameQuery,
  CompanySuggestionsByCountryDocument,
  CompanySuggestionsByCountryQuery,
  CompanySuggestionsByDatabaseTypeDocument,
  CompanySuggestionsByDatabaseTypeQuery,
  CompanySuggestionsByNameDocument,
  CompanySuggestionsByNameQuery,
  CompanySuggestionsByPostCodeDocument,
  CompanySuggestionsByPostCodeQuery,
  CompanySuggestionsByRegistrationNumberDocument,
  CompanySuggestionsByRegistrationNumberQuery,
  CompanySuggestionsByTagNameDocument,
  CompanySuggestionsByTagNameQuery,
  FetchCompaniesByQueryParamsPagedDocument,
  FetchCompaniesByQueryParamsPagedQuery,
  FetchCompaniesSearchSuggestionsDocument,
  FetchCompaniesSearchSuggestionsQuery,
  RemoveCompaniesDocument,
  RemoveCompaniesMutation,
  RemoveCompanyDocument,
  RemoveCompanyMutation,
  TransformCompanyIntoMediaOutletDocument,
  TransformCompanyIntoMediaOutletMutation,
  AddTagToCompaniesDocument,
  AddTagToCompaniesMutation,
  RemoveTagFromCompaniesMutation,
  RemoveTagFromCompaniesDocument,
  CompaniesTagInput
} from 'app/common/graphql/generated/graphql-gateway';
import { Company, ICompaniesSortingInput, PageOfCompanies } from 'app/pages/my-audience/companies';

export const fetchCompaniesByQueryParamsPaged = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  filterItems: FilterItem[],
  pageNumber: number,
  pageSize: number,
  sortingInput: ICompaniesSortingInput
): Promise<PageOfCompanies> => {
  const response = await client.query<FetchCompaniesByQueryParamsPagedQuery>({
    query: FetchCompaniesByQueryParamsPagedDocument,
    variables: {
      searchText,
      filterItems,
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      sortingInput
    }
  });

  return new PageOfCompanies(
    response.data.companies?.totalCount,
    response.data.companies?.companies.map((company: GqlCompany) => createCompany(company))
  );
};

export const addCompany = async (client: ApolloClient<NormalizedCacheObject>, company: Company): Promise<Company> => {
  const companyInput: CompanyInput = {
    category: company.category,
    comments: company.comments,
    companyContacts: company.companyContacts,
    competitors: company.competitors,
    contactData: company.contactData,
    foundationDate: company.foundationDate,
    databaseType: company.databaseType,
    description: company.description,
    director: company.director,
    name: company.name,
    parentCompany: company.parentCompany,
    profilePictureUrl: company.profilePictureUrl,
    profit: company.profit,
    registrationNumber: company.registrationNumber,
    tags: company.tags,
    userComments: company.userComments,
    dataSourceKey: company.dataSourceKey
  };
  const response = await client.mutate<AddCompanyMutation>({
    mutation: AddCompanyDocument,
    variables: {
      company: companyInput
    }
  });

  return createCompany(response.data.addCompany as GqlCompany);
};

export const fetchCompanySearchSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string): Promise<SearchSuggestionCategory[]> => {
  const response = await client.query<FetchCompaniesSearchSuggestionsQuery>({
    query: FetchCompaniesSearchSuggestionsDocument,
    variables: {
      searchText
    }
  });

  return response.data.companySearchSuggestions.map((ssc) => createSearchSuggestionCategory(ssc));
};

export const transformCompanyIntoMediaOutlet = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<string> => {
  const response = await client.mutate<TransformCompanyIntoMediaOutletMutation>({
    mutation: TransformCompanyIntoMediaOutletDocument,
    variables: {
      id
    }
  });

  return response.data.transformCompanyIntoMediaOutlet.id;
};

export const removeCompany = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<string> => {
  const response = await client.mutate<RemoveCompanyMutation>({
    mutation: RemoveCompanyDocument,
    variables: {
      id
    }
  });

  return response.data.removeCompany.id;
};

export const fetchCompanySuggestionsByName = async (client: ApolloClient<NormalizedCacheObject>, name: string, take: number): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByNameQuery>({
    query: CompanySuggestionsByNameDocument,
    variables: {
      name,
      take
    }
  });

  return response.data.companySuggestionsByName;
};

export const fetchCompanySuggestionsByTagName = async (client: ApolloClient<NormalizedCacheObject>, tagName: string, take: number): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByTagNameQuery>({
    query: CompanySuggestionsByTagNameDocument,
    variables: {
      tagName,
      take
    }
  });
  return response.data.companySuggestionsByTagName;
};

export const fetchCompanySuggestionsByCountry = async (client: ApolloClient<NormalizedCacheObject>, country: string, take: number): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByCountryQuery>({
    query: CompanySuggestionsByCountryDocument,
    variables: {
      country,
      take
    }
  });

  return response.data.companySuggestionsByCountry;
};

export const fetchCompanySuggestionsByRegistrationNumber = async (
  client: ApolloClient<NormalizedCacheObject>,
  registrationNumber: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByRegistrationNumberQuery>({
    query: CompanySuggestionsByRegistrationNumberDocument,
    variables: {
      registrationNumber,
      take
    }
  });

  return response.data.companySuggestionsByRegistrationNumber;
};

export const fetchCompanySuggestionsByDatabaseType = async (
  client: ApolloClient<NormalizedCacheObject>,
  databaseType: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByDatabaseTypeQuery>({
    query: CompanySuggestionsByDatabaseTypeDocument,
    variables: {
      databaseType,
      take
    }
  });

  return response.data.companySuggestionsByDatabaseType;
};

export const fetchCompanySuggestionsByCity = async (client: ApolloClient<NormalizedCacheObject>, city: string, take: number): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByCityQuery>({
    query: CompanySuggestionsByCityDocument,
    variables: {
      city,
      take
    }
  });

  return response.data.companySuggestionsByCity;
};

export const fetchCompanySuggestionsByPostCode = async (client: ApolloClient<NormalizedCacheObject>, postCode: string, take: number): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByPostCodeQuery>({
    query: CompanySuggestionsByPostCodeDocument,
    variables: {
      postCode,
      take
    }
  });

  return response.data.companySuggestionsByPostCode;
};

export const fetchCompanySuggestionsByContactName = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactName: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<CompanySuggestionsByContactNameQuery>({
    query: CompanySuggestionsByContactNameDocument,
    variables: {
      contactName,
      take
    }
  });

  return response.data.companySuggestionsByContactName;
};

export const removeCompaniesByIds = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<Company[]> => {
  const response = await client.mutate<RemoveCompaniesMutation>({
    mutation: RemoveCompaniesDocument,
    variables: {
      ids
    }
  });

  return response.data.removeCompanies.map((c) => createCompany(c as GqlCompany));
};

export const addTagToCompanies = async (client: ApolloClient<NormalizedCacheObject>, companyIds: string[], tag: Tag): Promise<TagProjection> => {
  const input: CompaniesTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<AddTagToCompaniesMutation>({
    mutation: AddTagToCompaniesDocument,
    variables: {
      companyIds,
      input
    }
  });

  return new TagProjection(response.data.addTagToCompanies.id, response.data.addTagToCompanies.name);
};

export const removeTagFromCompanies = async (client: ApolloClient<NormalizedCacheObject>, companyIds: string[], tag: Tag): Promise<TagProjection> => {
  const input: CompaniesTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<RemoveTagFromCompaniesMutation>({
    mutation: RemoveTagFromCompaniesDocument,
    variables: {
      companyIds,
      input
    }
  });

  return new TagProjection(response.data.removeTagFromCompanies.id, response.data.removeTagFromCompanies.name);
};
