import { Icon } from '@fluentui/react';
import { Button, MenuButton, MenuItemProps, MenuProps, ShorthandValue, Tooltip } from '@fluentui/react-northstar';

interface CreateMenuProps {
  menuItems: ShorthandValue<MenuProps>;
  collapsedSidebar: boolean;
  content: string;
}

export const CreateMenu = ({ menuItems, collapsedSidebar, content }: CreateMenuProps) => {
  return (
    <MenuButton
      trigger={
        collapsedSidebar ? (
          <Tooltip
            subtle={false}
            pointing
            position="before"
            content={content}
            trigger={<Button icon={<Icon iconName="Add" />} content={!collapsedSidebar && { content }} primary />}
          />
        ) : (
          <Button icon={<Icon iconName="Add" />} content={!collapsedSidebar && { content }} primary />
        )
      }
      menu={menuItems}
      position="above"
    />
  );
};
