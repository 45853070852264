import { Button, EditIcon } from '@fluentui/react-northstar';
import { printData, useAppDispatch, useAppSelector } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import {
  ContactInfo,
  CreateContactFooter,
  CreateContactSteps,
  selectContactInfoToAdd,
  addContactRequested,
  closeCreateContactWizard,
  goToCreateContactPreviousStep,
  goToCreateContactStep
} from 'app/pages/my-audience/wizard-create-media-outlet';

export const FinishCreateContact = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactToAdd = useAppSelector<ContactInfo>(selectContactInfoToAdd);

  const handleEdit = () => {
    dispatch(goToCreateContactStep(1));
  };

  const handleFinishButtonClick = () => {
    dispatch(addContactRequested());
    dispatch(closeCreateContactWizard());
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToCreateContactPreviousStep());
  };

  return (
    <>
      <CreateContactSteps />
      <div className="wizard-panel-finish-wrap">
        <div className="wizard-panel-intro">
          <h5 className="wizard-panel-title">
            <FormattedMessage id={'add-menu-create-mo.contact-finish-title'} />
          </h5>
        </div>
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-main-intro">
              <h6 className="wizard-panel-finish-title">
                <FormattedMessage id={'add-menu-create-mo.contact-step-info'} />
              </h6>
              <Button icon={<EditIcon />} iconOnly text content={formatMessage({ id: 'buttons.edit' })} onClick={handleEdit} />
            </div>
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-mo.contact-title-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(contactToAdd.title)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-mo.contact-first-name-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(contactToAdd.firstName)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-mo.contact-last-name-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(contactToAdd.lastName)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateContactFooter
        onFinishButtonClick={handleFinishButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onCancelButtonClick={handleCancelButtonClick}
      />
    </>
  );
};
