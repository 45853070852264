import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterItem, TableHeader, Tag, TagProjection } from 'app/common';
import { DefaultSendingsSortingInput, Sending, SendingSortingInput, SendingStatus } from 'app/pages/my-activities/sendings';
import { Blacklist } from 'app/pages/my-audience/contacts';
import { RootState } from 'app/redux/store';
import { SENDING_FILTER } from 'app/pages/my-activities/sending-filter-search/filter-fields';

export interface SendingsState {
  sendings: Sending[];
  pageNumber: number;
  pageSize: number;
  totalNumberOfSendings: number;
  searchText: string;
  sendingToUpdate: Sending;
  sendingsToUpdate: Sending[];
  sendingsToAdd: Sending[];
  sendingToAdd: Sending;
  sendingsToRemoveIds: string[];
  sendingsToDuplicateIds: string[];
  blacklistIdsFromSendingTable: Blacklist[];
  sendingBlacklistId: string;
  isLoadingSendings: boolean;
  selectedSendingsIds: string[];
  sendingsTableHeader: TableHeader[];
  filterItems: FilterItem[];
  quickFilterItems: FilterItem[];
  sortingInput: SendingSortingInput;
  isFilterPanelOpen: boolean;
  filterSuggestionsSearchText: string;
  filterSuggestionsPageSize: number;
  isLoadingTitleSuggestions: boolean;
  isLoadingCreatedBySuggestions: boolean;
  isLoadingCampaignNameSuggestions: boolean;
  isLoadingEmailSubjectSuggestions: boolean;
  isLoadingTagNameSuggestions: boolean;
  isLoadingRecipientMediumNameSuggestions: boolean;
  isLoadingRecipientNameOrEmailAddressSuggestions: boolean;
  sendingTitlesFilterSuggestions: string[];
  sendingCreatedByFilterSuggestions: string[];
  sendingCampaignFilterSuggestions: string[];
  sendingEmailSubjectFilterSuggestions: string[];
  sendingTagFilterSuggestions: string[];
  recipientMediumNameFilterSuggestions: string[];
  sendingRecipientNameOrEmailAddressFilterSuggestions: string[];
  allTags: Tag[];
  filteredTags: Tag[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToSendings: TagProjection;
  tagToRemoveFromSendings: TagProjection;
  selectedSendings: Sending[];
  previousSendings: Sending[];
  sendingToRemove: Sending;
  isRemoveSendingsDialogOpened: boolean;
  isDeletingSendingInProgress: boolean;
  sendingToDuplicateLocalizedTitlePart: string;
  duplicatedSendingId: string;
  shouldNavigateToDuplicatedSending: boolean;
  isChoosingSendingAsFavorite: boolean;
}

const initialState: SendingsState = {
  sendings: [],
  sendingToUpdate: {} as Sending,
  sendingsToUpdate: [],
  sendingsToRemoveIds: [],
  sendingsToAdd: [],
  sendingToAdd: {} as Sending,
  sendingsToDuplicateIds: [],
  pageNumber: 1,
  pageSize: 10,
  totalNumberOfSendings: 0,
  filterSuggestionsSearchText: '',
  searchText: '',
  filterItems: [],
  quickFilterItems: [],
  sortingInput: new DefaultSendingsSortingInput(),
  sendingTitlesFilterSuggestions: [],
  sendingCreatedByFilterSuggestions: [],
  sendingCampaignFilterSuggestions: [],
  sendingEmailSubjectFilterSuggestions: [],
  sendingTagFilterSuggestions: [],
  recipientMediumNameFilterSuggestions: [],
  sendingRecipientNameOrEmailAddressFilterSuggestions: [],
  blacklistIdsFromSendingTable: [],
  sendingBlacklistId: '',
  isFilterPanelOpen: false,
  isLoadingSendings: false,
  selectedSendingsIds: [],
  sendingsTableHeader: [],
  isLoadingTitleSuggestions: false,
  isLoadingCreatedBySuggestions: false,
  isLoadingCampaignNameSuggestions: false,
  isLoadingEmailSubjectSuggestions: false,
  isLoadingTagNameSuggestions: false,
  isLoadingRecipientMediumNameSuggestions: false,
  isLoadingRecipientNameOrEmailAddressSuggestions: false,
  filterSuggestionsPageSize: 10,
  allTags: [],
  filteredTags: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToSendings: {} as TagProjection,
  tagToRemoveFromSendings: {} as TagProjection,
  selectedSendings: [],
  previousSendings: [],
  sendingToRemove: {} as Sending,
  isRemoveSendingsDialogOpened: false,
  isDeletingSendingInProgress: false,
  sendingToDuplicateLocalizedTitlePart: '',
  duplicatedSendingId: '',
  shouldNavigateToDuplicatedSending: false,
  isChoosingSendingAsFavorite: false
};

export type FilterSuggestionsPageSizeSelector = (state: RootState) => number;
export const selectFilterSuggestionsPageSize: FilterSuggestionsPageSizeSelector = createSelector(
  [(state: RootState) => state.sendings.filterSuggestionsPageSize],
  (filterSuggestionsPageSize: number) => filterSuggestionsPageSize
);

export type IsLoadingCreatedBySuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingCreatedBySuggestions: IsLoadingCreatedBySuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingCreatedBySuggestions],
  (isLoadingCreatedBySuggestions: boolean) => isLoadingCreatedBySuggestions
);

export type IsRemoveSendingsDialogOpened = (state: RootState) => boolean;
export const selectIsRemoveSendingsDialogOpened: IsRemoveSendingsDialogOpened = createSelector(
  [(state: RootState) => state.sendings.isRemoveSendingsDialogOpened],
  (isRemoveSendingsDialogOpened: boolean) => isRemoveSendingsDialogOpened
);

export type IsDeletingSendingInProgress = (state: RootState) => boolean;
export const selectIsDeletingSendingInProgress: IsDeletingSendingInProgress = createSelector(
  [(state: RootState) => state.sendings.isDeletingSendingInProgress],
  (isDeletingSendingInProgress: boolean) => isDeletingSendingInProgress
);

export type IsLoadingRecipientNameOrEmailAddressSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingRecipientNameOrEmailAddressSuggestions: IsLoadingRecipientNameOrEmailAddressSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingRecipientNameOrEmailAddressSuggestions],
  (isLoadingRecipientNameOrEmailAddressSuggestions: boolean) => isLoadingRecipientNameOrEmailAddressSuggestions
);

export type IsLoadingRecipientMediumNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingRecipientMediumNameSuggestions: IsLoadingRecipientMediumNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingRecipientMediumNameSuggestions],
  (isLoadingRecipientMediumNameSuggestions: boolean) => isLoadingRecipientMediumNameSuggestions
);

export type IsLoadingTagNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingTagNameSuggestions: IsLoadingTagNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingTagNameSuggestions],
  (isLoadingTagNameSuggestions: boolean) => isLoadingTagNameSuggestions
);

export type IsLoadingEmailSubjectSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingEmailSubjectSuggestions: IsLoadingEmailSubjectSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingEmailSubjectSuggestions],
  (isLoadingEmailSubjectSuggestions: boolean) => isLoadingEmailSubjectSuggestions
);

export type IsLoadingCampaignNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingCampaignNameSuggestions: IsLoadingCampaignNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingCampaignNameSuggestions],
  (isLoadingCampaignNameSuggestions: boolean) => isLoadingCampaignNameSuggestions
);

export type IsLoadingTitleSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingTitleSuggestions: IsLoadingTitleSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingTitleSuggestions],
  (isLoadingTitleSuggestions: boolean) => isLoadingTitleSuggestions
);

export type IsLoadingSendingsSelector = (state: RootState) => boolean;
export const selectIsLoadingSendings: IsLoadingSendingsSelector = createSelector(
  [(state: RootState) => state.sendings.isLoadingSendings],
  (isLoadingSendings: boolean) => isLoadingSendings
);

export type SendingsSelector = (state: RootState) => Sending[];
export const selectSendings: SendingsSelector = createSelector([(state: RootState) => state.sendings.sendings], (sendings: Sending[]) => sendings);

export type SendingsTableHeaderSelector = (state: RootState) => TableHeader[];
export const selectSendingsTableHeader: SendingsTableHeaderSelector = createSelector(
  [(state: RootState) => state.sendings.sendingsTableHeader],
  (sendingsTableHeader: TableHeader[]) => sendingsTableHeader
);

export type TotalNumberOfSendingsSelector = (state: RootState) => number;
export const selectTotalNumberOfSendings: TotalNumberOfSendingsSelector = createSelector(
  [(state: RootState) => state.sendings.totalNumberOfSendings],
  (totalNumberOfSendings: number) => totalNumberOfSendings
);

export type SendingsToDuplicateIdsSelector = (state: RootState) => string[];
export const selectSendingsToDuplicateIds: SendingsToDuplicateIdsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingsToDuplicateIds],
  (sendingsToDuplicateIds: string[]) => sendingsToDuplicateIds
);

export type SendingToUpdateSelector = (state: RootState) => Sending;
export const selectSendingToUpdate: SendingToUpdateSelector = createSelector(
  [(state: RootState) => state.sendings.sendingToUpdate],
  (sendingToUpdate: Sending) => sendingToUpdate
);

export type SendingToAddSelector = (state: RootState) => Sending;
export const selectSendingToAdd: SendingToAddSelector = createSelector(
  [(state: RootState) => state.sendings.sendingToAdd],
  (sendingToAdd: Sending) => sendingToAdd
);

export type SendingsToUpdateSelector = (state: RootState) => Sending[];
export const selectSendingsToUpdate: SendingsToUpdateSelector = createSelector(
  [(state: RootState) => state.sendings.sendingsToUpdate],
  (sendingsToUpdate: Sending[]) => sendingsToUpdate
);

export type SendingsToRemoveIdsSelector = (state: RootState) => string[];
export const selectSendingsToRemoveIds: SendingsToRemoveIdsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingsToRemoveIds],
  (sendingsToRemoveIds: string[]) => sendingsToRemoveIds
);

export type SelectedSendingsTitlesSelector = (state: RootState) => string[];
export const selectSelectedSendingsTitles: SelectedSendingsTitlesSelector = createSelector(
  [(state: RootState) => state.sendings.sendings, (state: RootState) => state.sendings.selectedSendingsIds],
  (sendings: Sending[], selectedSendingsIds: string[]) =>
    sendings.reduce((sendingsTitles, sending) => [...sendingsTitles, ...(selectedSendingsIds.includes(sending.id) ? [sending.title] : [])], [])
);

export type SendingsToAddSelector = (state: RootState) => Sending[];
export const selectSendingsToAdd: SendingsToAddSelector = createSelector(
  [(state: RootState) => state.sendings.sendingsToAdd],
  (sendingsToAdd: Sending[]) => sendingsToAdd
);

export type SelectedSendingsIdsSelector = (state: RootState) => string[];
export const selectSelectedSendingsIds: SelectedSendingsIdsSelector = createSelector(
  [(state: RootState) => state.sendings.selectedSendingsIds],
  (selectedSendingsIds: string[]) => selectedSendingsIds
);

export type SelectedSendingsIdsCountSelector = (state: RootState) => number;
export const selectSelectedSendingsIdsCount: SelectedSendingsIdsCountSelector = createSelector(
  [(state: RootState) => state.sendings.selectedSendingsIds],
  (selectedSendingsIds: string[]) => selectedSendingsIds.length
);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector([(state: RootState) => state.sendings.pageNumber], (pageNumber: number) => pageNumber);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.sendings.pageSize], (pageSize: number) => pageSize);

export type FiltersSearchTextSelector = (state: RootState) => string;
export const selectFilterSuggestionsSearchText: FiltersSearchTextSelector = createSelector(
  [(state: RootState) => state.sendings.filterSuggestionsSearchText],
  (filterSuggestionsSearchText: string) => filterSuggestionsSearchText
);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector([(state: RootState) => state.sendings.searchText], (searchText: string) => searchText);

export type FilterItemsSelector = (state: RootState) => FilterItem[];
export const selectFilterItems: FilterItemsSelector = createSelector(
  [(state: RootState) => state.sendings.filterItems],
  (filterItems: FilterItem[]) => filterItems
);

export type QuickFilterItemsSelector = (state: RootState) => FilterItem[];
export const selectQuickFilterItems: QuickFilterItemsSelector = createSelector(
  [(state: RootState) => state.sendings.quickFilterItems],
  (quickFilterItems: FilterItem[]) => quickFilterItems
);

export type SortingInputSelector = (state: RootState) => SendingSortingInput;
export const selectSortingInput: SortingInputSelector = createSelector(
  [(state: RootState) => state.sendings.sortingInput],
  (sortingInput: SendingSortingInput) => sortingInput
);

export type SendingTitleFilterSuggestionsSelector = (state: RootState) => string[];
export const selectSendingTitlesSuggestions: SendingTitleFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingTitlesFilterSuggestions],
  (sendingTitlesFilterSuggestions: string[]) => sendingTitlesFilterSuggestions
);

export type SendingCreatedByFilterSuggestionsSelector = (state: RootState) => string[];
export const selectSendingCreatedBySuggestions: SendingCreatedByFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingCreatedByFilterSuggestions],
  (sendingCreatedByFilterSuggestions: string[]) => sendingCreatedByFilterSuggestions
);

export type SendingCampaignFilterSuggestionsSelector = (state: RootState) => string[];
export const selectSendingCampaignSuggestions: SendingCampaignFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingCampaignFilterSuggestions],
  (sendingCampaignFilterSuggestions: string[]) => sendingCampaignFilterSuggestions
);

export type SendingEmailSubjectFilterSuggestionsSelector = (state: RootState) => string[];
export const selectSendingEmailSubjectSuggestions: SendingEmailSubjectFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingEmailSubjectFilterSuggestions],
  (sendingEmailSubjectFilterSuggestions: string[]) => sendingEmailSubjectFilterSuggestions
);

export type SendingTagFilterSuggestionsSelector = (state: RootState) => string[];
export const selectSendingTagSuggestions: SendingTagFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingTagFilterSuggestions],
  (sendingTagFilterSuggestions: string[]) => sendingTagFilterSuggestions
);

export type RecipientMediumNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectRecipientMediumNameSuggestions: RecipientMediumNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.recipientMediumNameFilterSuggestions],
  (recipientMediumNameFilterSuggestions: string[]) => recipientMediumNameFilterSuggestions
);

export type SendingRecipientNameOrEmailAddressFilterSuggestionsSelector = (state: RootState) => string[];
export const selectSendingRecipientNameOrEmailAddressSuggestions: SendingRecipientNameOrEmailAddressFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendings.sendingRecipientNameOrEmailAddressFilterSuggestions],
  (sendingRecipientNameOrEmailAddressFilterSuggestions: string[]) => sendingRecipientNameOrEmailAddressFilterSuggestions
);
export type IsFilterPanelOpenSelector = (state: RootState) => boolean;
export const selectIsFilterPanelOpen: IsFilterPanelOpenSelector = createSelector(
  [(state: RootState) => state.sendings.isFilterPanelOpen],
  (isFilterPanelOpen: boolean) => isFilterPanelOpen
);

export type AllTagsSelector = (state: RootState) => Tag[];
export const selectAllTags: AllTagsSelector = createSelector([(state: RootState) => state.sendings.allTags], (allTags: Tag[]) => allTags);

export type FilteredTagsInPanelSelector = (state: RootState) => Tag[];
export const selectFilteredTagsInPanel: FilteredTagsInPanelSelector = createSelector(
  [(state: RootState) => state.sendings.filteredTags],
  (filteredTags: Tag[]) => filteredTags
);

export type TagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: TagNameToCreateSelector = createSelector(
  [(state: RootState) => state.sendings.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type TagsToDeleteSelector = (state: RootState) => Tag[];
export const selectTagsToDelete: TagsToDeleteSelector = createSelector(
  [(state: RootState) => state.sendings.tagsToDelete],
  (tagsToDelete: Tag[]) => tagsToDelete
);

export type tagToAddToSendingsSelector = (state: RootState) => TagProjection;
export const selectTagToAddToSendings: tagToAddToSendingsSelector = createSelector(
  [(state: RootState) => state.sendings.tagToAddToSendings],
  (tagToAddToSendings: TagProjection) => tagToAddToSendings
);

export type TagToRemoveFromSendingsSelector = (state: RootState) => TagProjection;
export const selectTagToRemoveFromSendings: TagToRemoveFromSendingsSelector = createSelector(
  [(state: RootState) => state.sendings.tagToRemoveFromSendings],
  (tagToRemoveFromSendings: TagProjection) => tagToRemoveFromSendings
);

export type SendingToRemoveSelector = (state: RootState) => Sending;
export const selectSendingToRemove: SendingToRemoveSelector = createSelector(
  [(state: RootState) => state.sendings.sendingToRemove],
  (sendingToRemove: Sending) => sendingToRemove
);

export type DuplicatedSendingIdSelector = (state: RootState) => string;
export const selectDuplicatedSendingId: DuplicatedSendingIdSelector = createSelector(
  [(state: RootState) => state.sendings.duplicatedSendingId],
  (duplicatedSendingId: string) => duplicatedSendingId
);

export type SendingToDuplicateLocalizedTitlePartSelector = (state: RootState) => string;
export const selectSendingToDuplicateLocalizedTitlePart: SendingToDuplicateLocalizedTitlePartSelector = createSelector(
  [(state: RootState) => state.sendings.sendingToDuplicateLocalizedTitlePart],
  (sendingToDuplicateLocalizedTitlePart: string) => sendingToDuplicateLocalizedTitlePart
);

export type ShouldNavigateToDuplicatedSendingSelector = (state: RootState) => boolean;
export const selectShouldNavigateToDuplicatedSending: ShouldNavigateToDuplicatedSendingSelector = createSelector(
  [(state: RootState) => state.sendings.shouldNavigateToDuplicatedSending],
  (shouldNavigateToDuplicatedSending: boolean) => shouldNavigateToDuplicatedSending
);

export type IsChoosingSendingAsFavoriteSelector = (state: RootState) => boolean;
export const selectIsChoosingSendingAsFavorite: IsChoosingSendingAsFavoriteSelector = createSelector(
  [(state: RootState) => state.sendings.isChoosingSendingAsFavorite],
  (isChoosingSendingAsFavorite: boolean) => isChoosingSendingAsFavorite
);

const sendingsSlice = createSlice({
  name: 'sendings',
  initialState,
  reducers: {
    firstPageOfSendingsRequested: (state) => {
      state.isLoadingSendings = true;
      state.pageNumber = 1;
      state.totalNumberOfSendings = 0;
      state.sendings = [];
    },
    firstPageOfSendingsReceived: (state, action: PayloadAction<Sending[]>) => {
      const receivedSendings = action.payload.map((sending) => {
        return { ...sending, tags: sending.tags.filter((t) => state.allTags.map((tag) => tag.id).includes(t.id)) };
      });
      state.sendings = receivedSendings;
      state.isLoadingSendings = false;

      const topSendings = state.sendings.slice(0, 5);
      state.sendingTitlesFilterSuggestions = Array.from(new Set(topSendings.map((sending) => sending.title).filter(Boolean)));
      state.sendingCreatedByFilterSuggestions = Array.from(
        new Set(topSendings.filter((sending) => sending.createdBy?.lastName).map((sending) => `${sending.createdBy.firstName} ${sending.createdBy.lastName}`))
      );
      state.sendingEmailSubjectFilterSuggestions = Array.from(new Set(topSendings.map((sending) => sending.subject).filter(Boolean)));
      state.sendingTagFilterSuggestions = Array.from(new Set(topSendings.flatMap((sending) => sending.tags).map((tag) => tag.name)));
    },
    nextPageOfSendingsRequested: (state) => {
      state.isLoadingSendings = true;
      state.pageNumber = state.pageNumber + 1;
    },
    nextPageOfSendingsReceived: (state, action: PayloadAction<Sending[]>) => {
      const receivedSendings = action.payload.map((sending) => {
        return { ...sending, tags: sending.tags.filter((t) => state.allTags.map((tag) => tag.id).includes(t.id)) };
      });
      state.sendings = [...state.sendings, ...receivedSendings];
      state.isLoadingSendings = false;
    },
    resetPageNumber: (state) => {
      state.pageNumber = 1;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setFilterSuggestionsSearchText: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    setTotalNumberOfSendings: (state, action: PayloadAction<number>) => {
      state.totalNumberOfSendings = action.payload;
    },
    updateSendingRequested: (state, action: PayloadAction<Sending>) => {
      state.sendingToUpdate = action.payload;
    },
    sendingUpdated: (state, action: PayloadAction<Sending>) => {
      const sendingToUpdateIndex = state.sendings.findIndex((sending) => sending.id === action.payload.id);
      state.sendings[sendingToUpdateIndex] = action.payload;
    },
    addRecipients: (state, action: PayloadAction<{ sendingId: string; numberOfRecipients: number }>) => {
      const sendingToUpdateIndex = state.sendings.findIndex((sending) => sending.id === action.payload.sendingId);
      if (sendingToUpdateIndex < 0) return;
      state.sendings[sendingToUpdateIndex] = {
        ...state.sendings[sendingToUpdateIndex],
        numberOfRecipients: state.sendings[sendingToUpdateIndex].numberOfRecipients + action.payload.numberOfRecipients
      };
    },
    removeRecipients: (state, action: PayloadAction<{ sendingId: string; numberOfRecipients: number }>) => {
      const sendingToUpdateIndex = state.sendings.findIndex((sending) => sending.id === action.payload.sendingId);
      if (sendingToUpdateIndex < 0) return;
      state.sendings[sendingToUpdateIndex] = {
        ...state.sendings[sendingToUpdateIndex],
        numberOfRecipients: state.sendings[sendingToUpdateIndex].numberOfRecipients - action.payload.numberOfRecipients
      };
    },
    sendingUpdatedFromSync: (state, action: PayloadAction<Sending>) => {
      const sendingToUpdateIndex = state.sendings.findIndex((sending) => sending.id === action.payload.id);
      state.sendings[sendingToUpdateIndex] = action.payload;
    },
    addSendingRequested: (state, action: PayloadAction<Sending>) => {
      state.sendingToAdd = action.payload;
    },
    sendingAdded: (state, action: PayloadAction<Sending>) => {
      state.sendings = [...state.sendings, action.payload];
      state.totalNumberOfSendings += 1;
    },
    sendingDuplicated: (state, action: PayloadAction<Sending>) => {
      state.sendings = [...state.sendings, action.payload];
      state.duplicatedSendingId = action.payload.id;
      state.totalNumberOfSendings += 1;
    },
    updateSendingsRequested: (state, action: PayloadAction<Sending[]>) => {
      state.sendingsToUpdate = action.payload;
    },
    sendingsUpdated: (state, action: PayloadAction<Sending[]>) => {
      const newSendings = [...state.sendings];
      for (const element of action.payload) {
        const sendingToUpdateIndex = state.sendings.findIndex((sending) => sending.id === element.id);
        newSendings[sendingToUpdateIndex] = element;
      }
      state.sendings = newSendings;
    },
    removeSendingRequested: (state, action: PayloadAction<Sending>) => {
      state.sendingToRemove = action.payload;
    },
    sendingRemoved: (state, action: PayloadAction<string>) => {
      state.sendings = state.sendings.filter((sending) => sending.id !== action.payload);
      state.selectedSendingsIds = state.selectedSendingsIds.filter((id) => id !== action.payload);
      state.totalNumberOfSendings -= 1;
      state.sendingToRemove = {} as Sending;
    },
    removeSendingsRequested: (state, action: PayloadAction<string[]>) => {
      state.sendingsToRemoveIds = action.payload;
    },
    sendingsRemoved: (state, action: PayloadAction<string[]>) => {
      state.sendings = state.sendings.filter((sending) => !action.payload.includes(sending.id));
      state.selectedSendingsIds = state.selectedSendingsIds.filter((id) => !action.payload.includes(id));
      state.sendingsToRemoveIds = [];
      state.totalNumberOfSendings -= action.payload.length;
      state.isDeletingSendingInProgress = false;
    },
    removeSendingsFromStore: (state, action: PayloadAction<string[]>) => {
      state.isDeletingSendingInProgress = true;
      state.previousSendings = state.sendings;
      state.sendings = state.sendings?.filter((sending) => !action.payload.some((id) => id === sending.id));
      state.selectedSendingsIds = [];
    },
    undoRemoveSendingsFromStore: (state) => {
      state.sendings = state.previousSendings;
      state.previousSendings = [];
    },
    addSendingsRequested: (state, action: PayloadAction<Sending[]>) => {
      state.sendingsToAdd = action.payload;
    },
    duplicateSendingRequested: (state, action: PayloadAction<{ sendingId: string; localizedTitlePart: string }>) => {
      state.sendingsToDuplicateIds = [action.payload.sendingId];
      state.sendingToDuplicateLocalizedTitlePart = action.payload.localizedTitlePart;
    },
    duplicateSendingsRequested: (state, action: PayloadAction<{ sendingIds: string[]; localizedTitlePart: string }>) => {
      state.sendingsToDuplicateIds = action.payload.sendingIds;
      state.sendingToDuplicateLocalizedTitlePart = action.payload.localizedTitlePart;
    },
    sendingsAdded: (state, action: PayloadAction<Sending[]>) => {
      state.sendings = [...state.sendings, ...action.payload];
    },
    addSendingsFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = [...state.filterItems, action.payload];
    },
    removeDateFilters: (state) => {
      state.filterItems = state.filterItems.filter((fi) => fi.fieldName !== SENDING_FILTER.CREATED_AT);
    },
    removeSendingsFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = state.filterItems.filter((item) => item.fieldName !== action.payload.fieldName || item.value !== action.payload.value);
    },
    clearAllSendingFilters: (state) => {
      state.filterItems = [];
      state.quickFilterItems = [];
      state.sendingTitlesFilterSuggestions = [];
      state.sendingCampaignFilterSuggestions = [];
      state.sendingCreatedByFilterSuggestions = [];
      state.sendingRecipientNameOrEmailAddressFilterSuggestions = [];
      state.sendingEmailSubjectFilterSuggestions = [];
      state.recipientMediumNameFilterSuggestions = [];
      state.sendingTagFilterSuggestions = [];
    },
    clearSendingsFiltersAndSearchText: (state) => {
      state.filterItems = [];
      state.quickFilterItems = [];
      state.sendingTitlesFilterSuggestions = [];
      state.sendingCampaignFilterSuggestions = [];
      state.sendingCreatedByFilterSuggestions = [];
      state.sendingRecipientNameOrEmailAddressFilterSuggestions = [];
      state.sendingEmailSubjectFilterSuggestions = [];
      state.recipientMediumNameFilterSuggestions = [];
      state.sendingTagFilterSuggestions = [];
      state.searchText = '';
      state.sortingInput = new DefaultSendingsSortingInput();
    },
    startLoadingSendingTitleSuggestions: (state) => {
      state.isLoadingTitleSuggestions = true;
    },
    sendingsTitlesSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.sendingTitlesFilterSuggestions = [];
      state.filterSuggestionsSearchText = action.payload;
    },
    sendingsTitlesSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.sendingTitlesFilterSuggestions = Array.from(new Set(action.payload));
      state.isLoadingTitleSuggestions = false;
    },
    startLoadingCreatedBySuggestions: (state) => {
      state.isLoadingCreatedBySuggestions = true;
    },
    sendingsCreatedBySuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.sendingCreatedByFilterSuggestions = [];
      state.filterSuggestionsSearchText = action.payload;
    },
    sendingsCreatedBySuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.sendingCreatedByFilterSuggestions = action.payload;
      state.isLoadingCreatedBySuggestions = false;
    },
    startLoadingCampaignSuggestions: (state) => {
      state.isLoadingCampaignNameSuggestions = true;
    },
    sendingsCampaignSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.sendingCampaignFilterSuggestions = [];
      state.filterSuggestionsSearchText = action.payload;
    },
    sendingsCampaignSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.sendingCampaignFilterSuggestions = action.payload;
      state.isLoadingCampaignNameSuggestions = false;
    },
    startLoadingSendingEmailSubjectSuggestions: (state) => {
      state.isLoadingEmailSubjectSuggestions = true;
    },
    sendingsEmailSubjectSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.sendingEmailSubjectFilterSuggestions = [];
      state.filterSuggestionsSearchText = action.payload;
    },
    sendingsEmailSubjectSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.sendingEmailSubjectFilterSuggestions = action.payload;
      state.isLoadingEmailSubjectSuggestions = false;
    },
    startLoadingTagNameSuggestions: (state) => {
      state.isLoadingTagNameSuggestions = true;
    },
    sendingsTagSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.sendingTagFilterSuggestions = [];
      state.filterSuggestionsSearchText = action.payload;
    },
    sendingsTagSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.sendingTagFilterSuggestions = action.payload;
      state.isLoadingTagNameSuggestions = false;
    },
    startLoadingMediumNameSuggestions: (state) => {
      state.isLoadingRecipientMediumNameSuggestions = true;
    },
    sendingsMediumNameSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.recipientMediumNameFilterSuggestions = [];
      state.filterSuggestionsSearchText = action.payload;
    },
    sendingsMediumNameSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.recipientMediumNameFilterSuggestions = action.payload;
      state.isLoadingRecipientMediumNameSuggestions = false;
    },
    startLoadingRecipientNameOrEmailAddressSuggestions: (state) => {
      state.isLoadingRecipientNameOrEmailAddressSuggestions = true;
    },
    sendingsRecipientNameOrEmailAddressSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.sendingRecipientNameOrEmailAddressFilterSuggestions = [];
      state.filterSuggestionsSearchText = action.payload;
    },
    sendingsRecipientNameOrEmailAddressSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.sendingRecipientNameOrEmailAddressFilterSuggestions = action.payload;
      state.isLoadingRecipientNameOrEmailAddressSuggestions = false;
    },
    openFilterPanel: (state) => {
      state.isFilterPanelOpen = true;
    },
    closeFilterPanel: (state) => {
      state.isFilterPanelOpen = false;
    },
    setSelectedSendingsIds: (state, action: PayloadAction<string[]>) => {
      state.selectedSendingsIds = action.payload;
    },
    setSendingsTableHeader: (state, action: PayloadAction<TableHeader[]>) => {
      state.sendingsTableHeader = action.payload;
    },
    toggleFavouriteFilter: (state) => {
      const isChecked = state.filterItems.some((item) => item.fieldName === SENDING_FILTER.FAVOURITE);
      if (isChecked) {
        state.filterItems = [...state.filterItems].filter((item) => item.fieldName !== SENDING_FILTER.FAVOURITE);
        return;
      }
      state.filterItems = [...state.filterItems, { fieldName: SENDING_FILTER.FAVOURITE, value: 'true' }];
    },
    addIsFavouriteFilter: (state, action: PayloadAction<boolean>) => {
      state.quickFilterItems = state.quickFilterItems.filter((item) => item.fieldName !== SENDING_FILTER.FAVOURITE && item.fieldName !== SENDING_FILTER.STATUS);
      state.quickFilterItems = [...state.quickFilterItems, new FilterItem(SENDING_FILTER.FAVOURITE, action.payload.toString())];
    },
    addSendingStatusFilter: (state, action: PayloadAction<SendingStatus>) => {
      state.quickFilterItems = state.quickFilterItems.filter((item) => item.fieldName !== SENDING_FILTER.STATUS && item.fieldName !== SENDING_FILTER.FAVOURITE);
      state.quickFilterItems = [...state.quickFilterItems, new FilterItem(SENDING_FILTER.STATUS, action.payload.toString())];
    },
    addNewTagRequested: (state, action: PayloadAction<{ sendingIds: string[]; text: string }>) => {
      state.selectedSendingsIds = action.payload.sendingIds;
      state.tagNameToCreate = action.payload.text;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.allTags = [...state.allTags, action.payload];
      state.filteredTags = state.allTags;
      state.selectedSendings = state.selectedSendings.map((c) => {
        return !c.tags.includes(action.payload) ? { ...c, tags: [...c.tags, action.payload] } : c;
      });
      state.tagNameToCreate = '';
    },
    deleteTagsRequested: (state, action: PayloadAction<Tag[]>) => {
      state.tagsToDelete = action.payload;
    },
    tagsDeleted: (state, action: PayloadAction<string[]>) => {
      state.allTags = state.allTags.filter((tag) => !action.payload.includes(tag.id));
      state.filteredTags = state.filteredTags.filter((tag) => !action.payload.includes(tag.id));
      state.selectedSendings = state.selectedSendings.map((c) => ({ ...c, tags: c.tags.filter((tag) => !action.payload.includes(tag.id)) }));
      state.tagsToDelete = [];
    },
    filterTags: (state, action: PayloadAction<string>) => {
      state.filteredTags = state.allTags.filter((tag) => tag.name.includes(action.payload));
    },
    getAllSendingsTagsRequested: () => {},
    allTagsReceived: (state, action: PayloadAction<Tag[]>) => {
      state.allTags = action.payload;
    },
    openRemoveSendingsDialog: (state) => {
      state.isRemoveSendingsDialogOpened = true;
    },
    closeRemoveSendingsDialog: (state) => {
      state.isRemoveSendingsDialogOpened = false;
    },
    setSortingInput: (state, action: PayloadAction<SendingSortingInput>) => {
      state.sortingInput = action.payload;
    },
    resetSendingDuplicatedId: (state) => {
      state.duplicatedSendingId = '';
    },
    shouldNavigateToDuplicatedSending: (state, action: PayloadAction<boolean>) => {
      state.shouldNavigateToDuplicatedSending = action.payload;
    },
    setIsChoosingSendingAsFavorite: (state, action: PayloadAction<boolean>) => {
      state.isChoosingSendingAsFavorite = action.payload;
    }
  }
});

export const {
  updateSendingRequested,
  sendingUpdated,
  removeSendingRequested,
  updateSendingsRequested,
  sendingsUpdated,
  removeSendingsRequested,
  sendingsRemoved,
  addSendingsRequested,
  sendingsAdded,
  addSendingRequested,
  sendingAdded,
  duplicateSendingRequested,
  duplicateSendingsRequested,
  sendingUpdatedFromSync,
  setTotalNumberOfSendings,
  clearAllSendingFilters,
  addSendingsFilter,
  removeSendingsFilter,
  sendingsTitlesSuggestionsRequested,
  sendingsTitlesSuggestionsReceived,
  sendingsCreatedBySuggestionsRequested,
  sendingsCreatedBySuggestionsReceived,
  sendingsCampaignSuggestionsRequested,
  sendingsCampaignSuggestionsReceived,
  sendingsEmailSubjectSuggestionsRequested,
  sendingsEmailSubjectSuggestionsReceived,
  sendingsTagSuggestionsRequested,
  sendingsTagSuggestionsReceived,
  sendingsMediumNameSuggestionsRequested,
  sendingsMediumNameSuggestionsReceived,
  sendingsRecipientNameOrEmailAddressSuggestionsRequested,
  sendingsRecipientNameOrEmailAddressSuggestionsReceived,
  openFilterPanel,
  closeFilterPanel,
  firstPageOfSendingsReceived,
  firstPageOfSendingsRequested,
  nextPageOfSendingsReceived,
  nextPageOfSendingsRequested,
  resetPageNumber,
  sendingRemoved,
  setFilterSuggestionsSearchText,
  setSearchText,
  setSelectedSendingsIds,
  setSendingsTableHeader,
  startLoadingCampaignSuggestions,
  startLoadingCreatedBySuggestions,
  startLoadingMediumNameSuggestions,
  startLoadingRecipientNameOrEmailAddressSuggestions,
  startLoadingSendingEmailSubjectSuggestions,
  startLoadingTagNameSuggestions,
  startLoadingSendingTitleSuggestions,
  toggleFavouriteFilter,
  addIsFavouriteFilter,
  addSendingStatusFilter,
  addNewTagRequested,
  addedNewTag,
  deleteTagsRequested,
  tagsDeleted,
  filterTags,
  getAllSendingsTagsRequested,
  allTagsReceived,
  addRecipients,
  removeRecipients,
  removeSendingsFromStore,
  undoRemoveSendingsFromStore,
  openRemoveSendingsDialog,
  closeRemoveSendingsDialog,
  clearSendingsFiltersAndSearchText,
  removeDateFilters,
  setSortingInput,
  sendingDuplicated,
  resetSendingDuplicatedId,
  shouldNavigateToDuplicatedSending,
  setIsChoosingSendingAsFavorite
} = sendingsSlice.actions;

export default sendingsSlice.reducer;
