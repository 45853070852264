import { DialogType } from '@fluentui/react';
import { hideUndoAlert, showAlertNotification, UndoableDialog, useAppDispatch, useAppSelector } from 'app/common';
import { Contact } from 'app/pages/my-audience/contacts';
import {
  closeRemoveCollaborationDialog,
  removeContributionFromStore,
  removeContributionRequested,
  selectIsRemoveCollaborationDialogOpened,
  selectSelectedContactForEditing,
  selectSelectedMediaOutletId,
  undoRemoveContributionFromStore
} from 'app/pages/my-audience/media-outlet-profile';
import { addContactToMediaOutletInTable, removeContactFromMediaOutletInTable } from 'app/pages/my-audience/media-outlets';
import 'sass/shared/dialog/Dialog.scss';

export const RemoveCollaborationDialog = () => {
  const dispatch = useAppDispatch();

  const isRemoveCollaborationDialogOpened = useAppSelector<boolean>(selectIsRemoveCollaborationDialogOpened);
  const selectedMediaoutletId = useAppSelector(selectSelectedMediaOutletId);
  const contactToRemove = useAppSelector<Contact>(selectSelectedContactForEditing);

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Delete contact',
    subText: `Are you sure you want to delete contact ${contactToRemove.firstName} ${contactToRemove.lastName} from the list?`
  };

  const handleRemoveCollaboration = () => {
    dispatch(removeContributionFromStore(contactToRemove));
    dispatch(removeContactFromMediaOutletInTable({ mediaOutletId: selectedMediaoutletId, contactId: contactToRemove.id }));
    dispatch(closeRemoveCollaborationDialog());
  };

  const onExecuteAction = () => {
    dispatch(removeContributionRequested(contactToRemove));
    dispatch(hideUndoAlert());
    dispatch(showAlertNotification('Collaboration is successfully removed'));
  };

  const onUndo = () => {
    dispatch(undoRemoveContributionFromStore(contactToRemove));
    dispatch(addContactToMediaOutletInTable({ mediaOutletId: selectedMediaoutletId, contact: contactToRemove }));
  };

  const handleDismissButtonClick = () => {
    dispatch(closeRemoveCollaborationDialog());
  };

  return (
    <UndoableDialog
      opened={isRemoveCollaborationDialogOpened}
      dialogTitle={dialogContentProps.title}
      dialogText={dialogContentProps.subText}
      alertMessage={`Undo removing ${contactToRemove.firstName} ${contactToRemove.lastName} from the list`}
      onExecuteAction={onExecuteAction}
      onExecuteInStore={handleRemoveCollaboration}
      onRevertAction={onUndo}
      onClose={handleDismissButtonClick}
    />
  );
};
