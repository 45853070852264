import { IColumn } from '@fluentui/react';
import { NewTable, TableHeader, createMediaOutletTableData, getAllJobTitlesRequested, getLanguagesRequested, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectLocalizedFrequencies, selectLocalizedMediumSupportTypes } from 'app/localization';
import {
  getAllMediaResortsRequested,
  getSelectedMediaOutletRequested,
  openProfilePanel,
  pageOfMediaOutletContactsRequested,
  setMediaOutletContactsPageNumber,
  setSearchMediaOutletContactsTextAndFilterItem
} from 'app/pages/my-audience/media-outlet-profile';
import {
  LocalizedListValue,
  MediaOutlet,
  MediaOutletsSortOrder,
  MediaOutletsSortingInput,
  firstPageOfMediaOutletsRequested,
  getAllMediaOutletsTagsRequested,
  nextPageOfMediaOutletsRequested,
  selectIsLoadingSearchResults,
  selectMediaOutlets,
  selectSearchResultTableHeader,
  selectTotalCountOfMediaOutlets,
  setSearchResultTableHeader,
  setSortingInput
} from 'app/pages/my-audience/media-outlets';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const MediaOutletsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { formatMessage } = useIntl();

  const header = useAppSelector<TableHeader[]>(selectSearchResultTableHeader);
  const mediaOutlets = useAppSelector<MediaOutlet[]>(selectMediaOutlets);
  const isLoadingSearchResults = useAppSelector<boolean>(selectIsLoadingSearchResults);
  const totalCountOfMediaOutlets = useAppSelector<number>(selectTotalCountOfMediaOutlets);
  const localizedMediumSupportTypes = useAppSelector<LocalizedListValue[]>(selectLocalizedMediumSupportTypes);
  const localizedFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    setTableRows(createMediaOutletTableData(mediaOutlets, localizedFrequencies, localizedMediumSupportTypes, formatMessage({ id: 'labels.media-outlet' })));
  }, [localizedFrequencies, localizedMediumSupportTypes, mediaOutlets, header]);

  const handleOpenProfile = (mediaOutletId: string) => {
    dispatch(openProfilePanel());
    dispatch(getAllMediaOutletsTagsRequested());
    dispatch(getAllMediaResortsRequested());
    dispatch(getSelectedMediaOutletRequested(mediaOutletId));
    dispatch(setMediaOutletContactsPageNumber(1));
    dispatch(setSearchMediaOutletContactsTextAndFilterItem({ searchText: '', role: '' }));
    dispatch(pageOfMediaOutletContactsRequested());
    dispatch(getAllJobTitlesRequested());
    dispatch(getLanguagesRequested());
    navigate(`/my-audience/media-outlets/results/${params.mediaOutletsQuery}/media-outlet-info/${mediaOutletId}/profile`);
  };

  const handleLoadMoreMediaOutlets = () => {
    dispatch(nextPageOfMediaOutletsRequested());
  };

  const handleColumnClicked = (selectedColumn: IColumn): void => {
    // For new sorting order take opposite of current state.
    const sortOrder: MediaOutletsSortOrder = selectedColumn.isSortedDescending ? MediaOutletsSortOrder.Asc : MediaOutletsSortOrder.Desc;
    const mediaOutletsSortingInput = new MediaOutletsSortingInput(selectedColumn.fieldName, sortOrder);
    dispatch(setSortingInput(mediaOutletsSortingInput));

    const index = header.findIndex((column) => selectedColumn.fieldName === column.fieldName);
    const changedHeader = { ...header[index], isSortedDescending: !selectedColumn.isSortedDescending, isSorted: true };

    const headerCopy = header.map((column: IColumn) => {
      return { ...column, isSorted: false, isSortedDescending: true };
    });

    headerCopy.splice(index, 1, changedHeader);
    dispatch(setSearchResultTableHeader(headerCopy));
    dispatch(firstPageOfMediaOutletsRequested());
  };

  const handleUpdateTableHeader = (updatedHeader: TableHeader[]) => {
    dispatch(setSearchResultTableHeader(updatedHeader));
  };

  return (
    <NewTable
      isLoadingSearchResults={isLoadingSearchResults}
      fixedColumnIndex={0}
      onOpenProfile={handleOpenProfile}
      header={header}
      tableRows={tableRows}
      totalCountOfEntities={totalCountOfMediaOutlets}
      handleLoadMore={handleLoadMoreMediaOutlets}
      onColumnClicked={handleColumnClicked}
      onUpdateTableHeader={handleUpdateTableHeader}
    />
  );
};
