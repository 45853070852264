import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  isEmailAddressValidOrEmpty,
  isTextInputValid,
  maximumSendingTitleLength,
  regAtLeastOneCharacter,
  regEmail,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import {
  resetSelectedSending,
  goToNextStep,
  selectSelectedSending,
  selectSenders,
  selectSendingToAddSetup,
  SendingSetup,
  SendingSetupForm,
  SendingWizardFooter,
  SendingWizardSteps,
  setSendingSetup,
  updateSelectedSendingSetup,
  updateSendingRequested,
  updateSendingSetup
} from 'app/pages/my-activities/sending-wizard';
import { Sender, Sending } from 'app/pages/my-activities/sendings';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { selectAllBlacklistsNames, selectAllBlacklistsValues } from 'app/pages/my-audience/contact-profile';
import { Blacklist } from 'app/pages/my-audience/contacts';
import { FormattedMessage, useIntl } from 'app/i18n';

export const WizardSetup = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedSending = useAppSelector<Sending>(selectSelectedSending);
  const setup = useAppSelector<SendingSetup>(selectSendingToAddSetup);
  const blacklists = useAppSelector<Blacklist[]>(selectAllBlacklistsValues);
  const blacklistsNames = useAppSelector<string[]>(selectAllBlacklistsNames);
  const senders = useAppSelector<Sender[]>(selectSenders);

  const sendersEmailAddresses = useMemo(() => senders.map((s) => s.emailAddress), [senders]);

  const { handleSubmit, control, watch } = useForm<SendingSetupForm, any>({
    defaultValues: {
      title: setup.title,
      subject: setup.subject,
      blacklistValue: setup.blacklist?.name,
      respondToEmailAddress: setup.respondToEmailAddress,
      senderDisplayName: setup.senderDisplayName,
      senderEmailAddress: setup.sender?.emailAddress
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleSaveAndCloseButtonClick = () => {
    handleFormSubmit();
    dispatch(resetSelectedSending());
  };

  const handleNextButtonClick = () => {
    handleFormSubmit();
    dispatch(goToNextStep());
  };

  const handleFormSubmit = () => {
    handleSubmit(
      (data) => {
        const sendingSetup: SendingSetup = setSendingSetup(data, blacklists, senders);
        const updatedSending: Sending = updateSendingSetup(selectedSending, sendingSetup);

        dispatch(updateSelectedSendingSetup(sendingSetup));
        dispatch(updateSendingRequested(updatedSending));
      },
      (error) => {
        Sentry.setExtra('Path', WizardSetup.name);
        Sentry.captureException(error);
      }
    )();
  };

  const areFieldsValid: boolean =
    isEmailAddressValidOrEmpty(watch('respondToEmailAddress')) &&
    isEmailAddressValidOrEmpty(watch('senderEmailAddress')) &&
    isTextInputValid({ inputValue: watch('title'), maxInputLength: maximumSendingTitleLength });

  return (
    <>
      <SendingWizardSteps areFieldsValid={areFieldsValid} submitForm={handleFormSubmit} />
      <div className="wizard-panel-form-wrap">
        <div className="wizard-panel-intro">
          <h5 className="wizard-panel-title">
            <FormattedMessage id={'emailing-wizard-setup-step.header'} />
          </h5>
        </div>
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'emailing-wizard.labels.title'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="title"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: formatMessage({ id: 'emailing-wizard.labels.title' }) })
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumSendingTitleLength }),
                  value: maximumSendingTitleLength
                },
                required: formatMessage({ id: 'error-messages.required-filed' }, { fieldName: formatMessage({ id: 'emailing-wizard.labels.title' }) })
              }}
            />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'emailing-wizard.labels.sender-email-address'} />
                </span>
              }
              name="senderEmailAddress"
              items={sendersEmailAddresses}
              control={control}
              placeholder={formatMessage({ id: 'emailing-wizard-setup-step.placeholders.sender-email-address' })}
              noResultsMessage={formatMessage({ id: 'emailing-wizard-setup-step.messages.no-senders-found' })}
              defaultValue={setup.sender?.emailAddress}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'emailing-wizard.labels.name' })} control={control} name="senderDisplayName" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="respondToEmailAddress"
              label={formatMessage({ id: 'emailing-wizard.labels.respond-to-address' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'emailing-wizard.labels.blacklist'} />
                </span>
              }
              name="blacklistValue"
              items={blacklistsNames}
              control={control}
              placeholder={formatMessage({ id: 'emailing-wizard-setup-step.placeholders.blacklist' })}
              noResultsMessage={formatMessage({ id: 'emailing-wizard-setup-step.messages.no-blacklists-found' })}
              defaultValue={setup.blacklist?.name}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'emailing-wizard.labels.subject' })} control={control} name="subject" />
          </div>
        </div>
        <SendingWizardFooter
          onSaveAndCloseButtonClick={handleSaveAndCloseButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areFieldsValid}
        />
      </div>
    </>
  );
};
