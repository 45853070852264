import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { Panel } from '@fluentui/react';
import { Accordion, Button, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import {
  ControlledFilterDropDown,
  FilterItem,
  FilterItemType,
  selectIsOnMobile,
  useAppDispatch,
  useAppSelector,
  useControlledFilterDropDown,
  useResetFilterQueriesOnPanelClose
} from 'app/common';
import {
  addCompaniesFilter,
  cityFilterSuggestionsRequested,
  clearAllFilters,
  closeFiltersPanel,
  companyNameFilterSuggestionsRequested,
  contactNameFilterSuggestionsRequested,
  countryFilterSuggestionsRequested,
  databaseTypeFilterSuggestionsRequested,
  firstPageOfCompaniesRequested,
  postalCodeFilterSuggestionsRequested,
  registrationNumberFilterSuggestionsRequested,
  removeCompaniesFilter,
  selectCityFilterSuggestions,
  selectCompanyNameFilterSuggestions,
  selectContactNameFilterSuggestions,
  selectCountryFilterSuggestions,
  selectFilterItems,
  selectIsFilterPanelOpen,
  selectIsLoadingCitySuggestions,
  selectIsLoadingContactNameSuggestions,
  selectIsLoadingCountrySuggestions,
  selectIsLoadingNameSuggestions,
  selectIsLoadingPostCodeSuggestions,
  selectIsLoadingRegistrationNumberSuggestions,
  selectIsLoadingTagNameSuggestions,
  selectPostalCodeFilterSuggestions,
  selectRegistrationNumberFilterSuggestions,
  selectTagNameFilterSuggestions,
  startLoadingCitySuggestions,
  startLoadingContactNameSuggestions,
  startLoadingCountrySuggestions,
  startLoadingDatabaseTypeSuggestions,
  startLoadingNameSuggestions,
  startLoadingPostalCodeSuggestions,
  startLoadingRegistrationNumberSuggestions,
  startLoadingTagSuggestions,
  tagFilterSuggestionsRequested
} from 'app/pages/my-audience/companies';
import { COMPANY_FILTER } from 'app/pages/my-audience/companies-filter-search/filter-fields';
import { useIntl } from 'app/i18n';

export const CompanyFiltersPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const isFilterPanelOpen = useAppSelector(selectIsFilterPanelOpen);
  const filterItems = useAppSelector(selectFilterItems);
  const isOnMobile = useAppSelector(selectIsOnMobile);

  const companyNameControl = useControlledFilterDropDown(companyNameFilterSuggestionsRequested, startLoadingNameSuggestions);
  const contactNameControl = useControlledFilterDropDown(contactNameFilterSuggestionsRequested, startLoadingContactNameSuggestions);
  const tagNameControl = useControlledFilterDropDown(tagFilterSuggestionsRequested, startLoadingTagSuggestions);
  const countryControl = useControlledFilterDropDown(countryFilterSuggestionsRequested, startLoadingCountrySuggestions);
  const registrationNumberControl = useControlledFilterDropDown(registrationNumberFilterSuggestionsRequested, startLoadingRegistrationNumberSuggestions);
  const databaseTypeControl = useControlledFilterDropDown(databaseTypeFilterSuggestionsRequested, startLoadingDatabaseTypeSuggestions);
  const cityControl = useControlledFilterDropDown(cityFilterSuggestionsRequested, startLoadingCitySuggestions);
  const postalCodeControl = useControlledFilterDropDown(postalCodeFilterSuggestionsRequested, startLoadingPostalCodeSuggestions);

  const { resetQueries } = useResetFilterQueriesOnPanelClose(
    [companyNameControl, contactNameControl, tagNameControl, countryControl, registrationNumberControl, databaseTypeControl, cityControl, postalCodeControl],
    isFilterPanelOpen
  );

  const handleDismissFilterPanel = () => {
    dispatch(closeFiltersPanel());
    navigate(-1);
  };

  const handleFilterAdd = ({ fieldName, value }: FilterItemType) => {
    dispatch(addCompaniesFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfCompaniesRequested());
  };

  const handleFilterRemoval = ({ fieldName, value }: FilterItemType) => {
    dispatch(removeCompaniesFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfCompaniesRequested());
  };

  const handleClearAll = () => {
    dispatch(clearAllFilters());
    dispatch(firstPageOfCompaniesRequested());

    resetQueries();
  };

  useOnClickOutside(ref, handleDismissFilterPanel);

  const filters = [
    {
      key: 'item-name',
      name: COMPANY_FILTER.NAME,
      displayName: formatMessage({ id: 'filters.names' }),
      placeholder: formatMessage({ id: 'filters.filter-by-name' }),
      suggestionsSelector: selectCompanyNameFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingNameSuggestions,
      control: companyNameControl
    },
    {
      key: 'item-contact',
      name: COMPANY_FILTER.CONTACT_NAME,
      displayName: formatMessage({ id: 'filters.contacts' }),
      placeholder: formatMessage({ id: 'filters.filter-by-contact-name' }),
      suggestionsSelector: selectContactNameFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingContactNameSuggestions,
      control: contactNameControl
    },
    {
      key: 'item-tags',
      name: COMPANY_FILTER.TAG,
      displayName: formatMessage({ id: 'filters.tags' }),
      placeholder: formatMessage({ id: 'filters.filter-by-tag' }),
      suggestionsSelector: selectTagNameFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingTagNameSuggestions,
      control: tagNameControl
    },
    {
      key: 'item-country',
      name: COMPANY_FILTER.COUNTRY,
      displayName: formatMessage({ id: 'filters.country' }),
      placeholder: formatMessage({ id: 'filters.filter-by-country' }),
      suggestionsSelector: selectCountryFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingCountrySuggestions,
      control: countryControl
    },
    {
      key: 'item-registration-number',
      name: COMPANY_FILTER.REGISTRATION_NUMBER,
      displayName: formatMessage({ id: 'filters.registration-number' }),
      placeholder: formatMessage({ id: 'filters.filter-by-registration-number' }),
      suggestionsSelector: selectRegistrationNumberFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingRegistrationNumberSuggestions,
      control: registrationNumberControl
    },
    {
      key: 'item-city',
      name: COMPANY_FILTER.CITY,
      displayName: formatMessage({ id: 'filters.city' }),
      placeholder: formatMessage({ id: 'filters.filter-by-city' }),
      suggestionsSelector: selectCityFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingCitySuggestions,
      control: cityControl
    },
    {
      key: 'item-post-code',
      name: COMPANY_FILTER.POSTAL_CODE,
      displayName: formatMessage({ id: 'filters.postal-code' }),
      placeholder: formatMessage({ id: 'filters.filter-by-postal-code' }),
      suggestionsSelector: selectPostalCodeFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingPostCodeSuggestions,
      control: postalCodeControl
    }
  ];

  const panels = filters.map(({ key, name, displayName, placeholder, control, suggestionsSelector, isLoadingSuggestionsSelector }) => ({
    title: (
      <div className="accordion-head-box" key={`${key}-title`}>
        <span className="accordion-heading">{displayName}</span>
        <ChevronDownMediumIcon />
      </div>
    ),
    content: {
      key: `${key}-content`,
      content: (
        <ControlledFilterDropDown
          name={name}
          placeholder={placeholder}
          onFilterAdd={handleFilterAdd}
          onFilterRemoval={handleFilterRemoval}
          suggestionsSelector={suggestionsSelector}
          isLoadingSuggestionsSelector={isLoadingSuggestionsSelector}
          items={filterItems.filter((filterItem) => filterItem.fieldName === name)}
          {...control}
        />
      )
    },
    className: 'filter-accordion-item'
  }));

  return (
    <div ref={ref}>
      <Panel
        isBlocking={false}
        customWidth="340px"
        className="filter-panel"
        headerText={formatMessage({ id: 'filters.filters' })}
        isOpen={isFilterPanelOpen}
        onDismiss={handleDismissFilterPanel}
      >
        <div className="filter-panel-accordions">
          <Accordion panels={panels} />
        </div>
        <div className="filter-panel-actions-wrap">
          {filterItems.length > 0 && (
            <div className="filter-panel-actions-holder">
              <div className="filter-panel-actions">
                <Button className="filter-panel-actions-clear" text content={formatMessage({ id: 'filters.clear' })} onClick={handleClearAll} />
              </div>
              {isOnMobile && (
                <div className="filter-panel-actions">
                  <Button className="filter-panel-actions-apply" primary content={formatMessage({ id: 'buttons.apply' })} onClick={handleDismissFilterPanel} />
                </div>
              )}
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};
