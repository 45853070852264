import { useRef, useState } from 'react';
import { DetailsRow, IDetailsRowProps, IObjectWithKey, Selection } from '@fluentui/react';
import { Checkbox } from '@fluentui/react-northstar';
import { selectIsOnMobile, useAppSelector } from 'app/common';

interface SendingsTableRowProps {
  rowProps: IDetailsRowProps;
  onOpenProfile: (id: string) => void;

  selection: Selection<IObjectWithKey>;
}

export const SendingsTableRow = ({ rowProps, onOpenProfile, selection }: SendingsTableRowProps) => {
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);
  const checkBoxClicked = useRef<boolean>(false);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);

  const onRowClick = () => {
    if (checkBoxClicked.current) {
      checkBoxClicked.current = false;
      return;
    }

    onOpenProfile(rowProps.item.sendingId);
  };

  const handleCheckboxClick = () => {
    checkBoxClicked.current = true;
  };

  const onProfilePictureClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    if (!isOnMobile) {
      return;
    }

    event.stopPropagation();
    setHoverIndex(index);
  };

  return (
    <div
      onMouseEnter={() => {
        setHoverIndex(rowProps.itemIndex);
      }}
      onMouseLeave={() => {
        setHoverIndex(undefined);
      }}
      onClick={onRowClick}
    >
      <DetailsRow
        {...rowProps}
        onRenderCheck={() => (
          <div className="row-header-wrap">
            {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
              <Checkbox checked={selection.getSelection().includes(rowProps.item)} onClick={handleCheckboxClick} />
            ) : (
              <div onClick={(event) => onProfilePictureClick(event, rowProps.itemIndex)}>{rowProps.item.profilePictureJSXElement}</div>
            )}
          </div>
        )}
      />
    </div>
  );
};
