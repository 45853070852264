import { Routes } from 'react-router';
import { Route, useNavigate, useParams } from 'react-router-dom';
import { PIVOT_ITEMS, ProfilePanelWrap, useAppDispatch, useAppSelector, useComponentLocationContext } from 'app/common';
import {
  Activities,
  closeProfilePanel,
  Companies,
  ContactComments,
  ContactPanelPivotItems,
  ContactProfilePanelIntro,
  GDPR,
  MediaOutlets,
  ProfileAccordion,
  resetSelectedContactInfo,
  selectIsLoadingContactProfileInfo,
  selectIsProfilePanelOpened
} from 'app/pages/my-audience/contact-profile';
import 'sass/shared/profile-panel/ProfilePanel.scss';
import { ShimmerProfilePanel } from 'app/common/components/shimmer/ShimmerProfilePanel';

export const ContactPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { isOutsideContactsPage } = useComponentLocationContext();

  const isLoadingContactProfileInfo = useAppSelector<boolean>(selectIsLoadingContactProfileInfo);
  const isProfilePanelOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);

  const onNavigateToActivities = () => {
    navigate(PIVOT_ITEMS.ACTIVITIES, { replace: true });
  };

  const handleDismissButtonClick = () => {
    dispatch(closeProfilePanel());
    dispatch(resetSelectedContactInfo());
    navigate(`/my-audience/contacts/results/${params.contactsQuery}`);
  };

  const handleDismissButtonClickOutsideContactsPage = () => {
    dispatch(closeProfilePanel());
    dispatch(resetSelectedContactInfo());
  };

  return (
    <ProfilePanelWrap
      isProfilePanelOpened={isProfilePanelOpened}
      onDismissButtonClick={isOutsideContactsPage ? handleDismissButtonClickOutsideContactsPage : handleDismissButtonClick}
      classModifier="contact"
      width="600px"
      isBlocking={isOutsideContactsPage}
    >
      {isLoadingContactProfileInfo ? (
        <ShimmerProfilePanel />
      ) : (
        <div className="profile-panel-container">
          <ContactProfilePanelIntro />
          <ContactPanelPivotItems />
          <div className="profile-panel-main">
            <Routes>
              <Route path="profile" element={<ProfileAccordion onClickOnChart={onNavigateToActivities} />} />
              <Route path="media-outlets" element={<MediaOutlets />} />
              <Route path="companies" element={<Companies />} />
              <Route path="activities" element={<Activities />} />
              <Route path="gdpr" element={<GDPR />} />
              <Route path="comments" element={<ContactComments />} />
            </Routes>
          </div>
        </div>
      )}
    </ProfilePanelWrap>
  );
};
