import { FormEvent, useState } from 'react';
import { Button, CloseIcon, Dropdown, DropdownProps, Pill } from '@fluentui/react-northstar';
import { useDebouncedCallback } from 'use-debounce';
import { RootState } from 'app/redux/store';
import { debounceTimeInMilliseconds, FilterItem, FilterItemType, useAppSelector, useReferenceListLocalization } from 'app/common';
import { useIntl } from 'app/i18n';

interface ControlledFilterDropDownProps {
  name: string;
  items: FilterItem[];
  searchQuery: string;
  placeholder: string;
  onQueryChange: (value: string) => void;
  onFilterAdd: ({ fieldName, value }: FilterItemType) => void;
  onFilterRemoval: ({ fieldName, value }: FilterItemType) => void;
  suggestionsSelector: (state: RootState) => string[];
  isLoadingSuggestionsSelector: (state: RootState) => boolean;
  onSuggestionsRequested: () => void;
}

export const ControlledFilterDropDown = ({
  name,
  items,
  searchQuery,
  placeholder,
  onQueryChange,
  onFilterAdd,
  onFilterRemoval,
  suggestionsSelector,
  isLoadingSuggestionsSelector,
  onSuggestionsRequested
}: ControlledFilterDropDownProps) => {
  const { formatMessage } = useIntl();
  const suggestions = useAppSelector(suggestionsSelector);
  const isLoading = useAppSelector(isLoadingSuggestionsSelector);
  const [selectedValue, setSelectedValue] = useState('');
  const loadingMessage = formatMessage({ id: 'filters.loading' });
  const noResultsMessage = formatMessage({ id: 'filters.no-results' });
  const handleSuggestionsRequested = useDebouncedCallback(onSuggestionsRequested, debounceTimeInMilliseconds);
  const { localizeFilterItem } = useReferenceListLocalization();

  const handleInputChange = ({ target }: FormEvent<HTMLInputElement>) => {
    onQueryChange((target as HTMLInputElement).value);
    handleSuggestionsRequested();
  };

  const handleOnChange = (_, { value }: DropdownProps) => {
    setSelectedValue('');
    if (value) onFilterAdd({ fieldName: name, value: `${value}` });
  };

  const a11ySelectionMessage = {
    onAdd: (name: string): string => `${name} selected.`,
    onRemove: (name: string): string => `${name} has been removed.`
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);

  return (
    <div className="filter-panel-dropdown-wrap">
      <Dropdown
        className="filter-panel-dropdown"
        search
        clearable={true}
        items={suggestions}
        value={selectedValue}
        loading={isLoading}
        searchQuery={searchQuery}
        loadingMessage={loadingMessage}
        noResultsMessage={noResultsMessage}
        placeholder={placeholder}
        onInput={handleInputChange}
        onChange={handleOnChange}
        getA11ySelectionMessage={a11ySelectionMessage}
        onBlur={handleBlur}
        open={isOpen}
        onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
      />
      <div className="filter-panel-pills">
        {items.map((filterItem: FilterItem) => (
          <Pill key={filterItem.fieldName} className="filter-panel-pill">
            <span className="filter-panel-pill__name"> {localizeFilterItem(filterItem)}</span>
            <Button icon={<CloseIcon />} iconOnly text onClick={() => onFilterRemoval({ fieldName: filterItem.fieldName, value: filterItem.value })} />
          </Pill>
        ))}
      </div>
    </div>
  );
};
