import {
  ActionBar,
  closeDeleteEntitiesDialog,
  hideUndoAlert,
  openDeleteEntitiesDialog,
  openExportDialog,
  SearchResultTableData,
  selectIsDeleteEntitiesDialogOpen,
  selectSelectedTableRows,
  UndoableDialog,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { UpdateContactsDialog } from 'app/pages/my-audience/contacts-search-results';
import {
  removeContactsByIdsRequested,
  openUpdateDialog,
  removeContactsFromStore,
  undoRemoveContactsFromStore,
  ContactSearchResultTableData
} from 'app/pages/my-audience/contacts';
import { getAllMediaResortsRequested, getAllTopicsRequested, selectIsProfilePanelOpened } from 'app/pages/my-audience/contact-profile';
import { ContactsTagsPopup } from 'app/pages/my-audience/contacts-search-results/components/ContactsTagsPopup';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';
import { getListsByNameRequested } from 'app/pages/my-audience/lists-profile';

export const ContactsActionBar = () => {
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();
  const dispatch = useAppDispatch();
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isDeleteContactsDialogOpened = useAppSelector<boolean>(selectIsDeleteEntitiesDialogOpen);
  const isContactProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const isActionBarVisible = !!selectedTableRows.length && !isContactProfileOpened;
  const numberOfUsers = (selectedTableRows as ContactSearchResultTableData[]).filter((row) => row.isUser).length;
  const canDeleteContacts = selectedTableRows?.length > numberOfUsers;

  const canUpdateContact = hasAllPermissions([Permission.ManageContacts, Permission.ManageCompanies]);

  const onExport = () => {
    dispatch(openExportDialog());
  };

  const onUpdate = () => {
    dispatch(openUpdateDialog());
    dispatch(getAllMediaResortsRequested());
    dispatch(getAllTopicsRequested());
    dispatch(getListsByNameRequested(''));
  };

  const onDelete = () => {
    dispatch(openDeleteEntitiesDialog());
  };

  const onConfirmDelete = () => {
    const contactsToDeleteIds = calculateContactsToDeleteIds();
    if (contactsToDeleteIds.length > 0) {
      dispatch(removeContactsByIdsRequested(contactsToDeleteIds));
    }
    dispatch(hideUndoAlert());
  };

  const onCancelDelete = () => {
    dispatch(closeDeleteEntitiesDialog());
  };

  const calculateContactsToDeleteIds = () =>
    (selectedTableRows as ContactSearchResultTableData[]).filter((row) => !row.isUser && !row.dataSourceKey).map((c) => c.id);

  const onDeleteFromStore = () => {
    dispatch(removeContactsFromStore(calculateContactsToDeleteIds()));
  };

  const onRevertAction = () => {
    dispatch(undoRemoveContactsFromStore());
  };

  const getWarningMessages = () => {
    const messages = [];
    if (numberOfUsers > 0) {
      messages.push(formatMessage({ id: 'delete-contacts-dialog.warning-message' }, { count: numberOfUsers }));
    }

    const externalSourceContactsLength = (selectedTableRows as ContactSearchResultTableData[]).filter((row) => row.dataSourceKey).length;
    if (externalSourceContactsLength > 0) {
      messages.push(formatMessage({ id: 'delete-contacts-dialog.additional-info-external' }, { counter: externalSourceContactsLength }));
    }

    return messages;
  };

  return (
    <>
      <ActionBar
        isVisible={isActionBarVisible}
        onExport={onExport}
        onUpdate={onUpdate}
        onDelete={onDelete}
        tagsContent={<ContactsTagsPopup disabled={!canUpdateContact} />}
        showUpdateButton={true}
        disabled={!canUpdateContact}
      />
      <UndoableDialog
        opened={isDeleteContactsDialogOpened}
        dialogTitle={formatMessage({ id: 'delete-contacts-dialog.title' })}
        dialogText={canDeleteContacts && formatMessage({ id: 'delete-contacts-dialog.are-you-sure' }, { counter: selectedTableRows?.length })}
        alertMessage={formatMessage(
          { id: 'delete-companies-dialog.undo-delete' },
          { entityName: selectedTableRows.length > 1 ? formatMessage({ id: 'labels.contacts' }) : formatMessage({ id: 'labels.contact' }) }
        )}
        onExecuteAction={onConfirmDelete}
        onExecuteInStore={onDeleteFromStore}
        onRevertAction={onRevertAction}
        onClose={onCancelDelete}
        warningMessages={getWarningMessages()}
        deleteButtonDisabled={!canDeleteContacts}
      />
      <UpdateContactsDialog />
    </>
  );
};
