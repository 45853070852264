export const MEDIA_OUTLET_FILTER = {
  NAME: 'name',
  CONTACT_NAME: 'contactName',
  TAG: 'tag',
  CATEGORY: 'category',
  TYPE: 'mediaType',
  FREQUENCY: 'frequency',
  LANGUAGE: 'language',
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  FAX: 'fax',
  WEBSITE: 'website',
  PO_BOX: 'poBox'
};
