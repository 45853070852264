import { useRef, useState } from 'react';
import { GridItem } from 'react-grid-dnd';
import { Icon } from '@fluentui/react';
import { DateTime } from 'luxon';
import { Avatar, Button, Card, EditIcon, Input, Tooltip, TrashCanIcon } from '@fluentui/react-northstar';
import { useOnClickOutside } from 'usehooks-ts';
import { useIntl as useReactIntl } from 'react-intl';
import { useIntl } from 'app/i18n';
import {
  BottomPopupItems,
  allowGoToResultsOnClickCard,
  disallowGoToResultsOnClickCard,
  getFilterLocalizationKey,
  groupFiltersByFieldName,
  hideActiveClassOnCard,
  hideCardEditInput,
  hideEditAndDeleteIconsOnCards,
  openBottomPopup,
  selectAreEditAndDeleteIconsVisible,
  selectCardActiveClass,
  selectIsEditedSavedSearchCard,
  selectIsOnDesktop,
  selectIsOnTouch,
  selectSavedSearchCardKey,
  showActiveClassOnCard,
  showCardEditInput,
  showEditAndDeleteIconsOnCards,
  useAppDispatch,
  useAppSelector,
  useLongPress,
  useReferenceListLocalization
} from 'app/common';
import { SavedSearch } from 'app/pages/my-audience/saved-searches';

export interface SavedSearchItemProps {
  isEditing: boolean;
  savedSearch: SavedSearch;
  onUpdateSavedSearch: (updatedSavedSearch: SavedSearch) => void;
  onSetSavedSearchToRemove: (savedSearchToRemove: SavedSearch) => void;
  onSavedSearchClick: (savedSearch: SavedSearch) => void;
}

export const SavedSearchItem = ({ isEditing, savedSearch, onUpdateSavedSearch, onSetSavedSearchToRemove, onSavedSearchClick }: SavedSearchItemProps) => {
  const { formatMessage } = useIntl();
  const intl = useReactIntl();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { isLongPress, longPressHandlers } = useLongPress(200);

  const areEditAndDeleteIconsVisible = useAppSelector(selectAreEditAndDeleteIconsVisible);
  const savedSearchCardKey = useAppSelector(selectSavedSearchCardKey);
  const activeClass = useAppSelector(selectCardActiveClass);
  const isEditedSavedSearchCard = useAppSelector(selectIsEditedSavedSearchCard);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const groupedFilterValues = groupFiltersByFieldName(savedSearch.filterItems);
  const { localizeFilterItem } = useReferenceListLocalization();

  const [name, setName] = useState(savedSearch.name);

  const cancelEdit = () => {
    setName(savedSearch.name);
    handleDismissEditInput();
  };

  const handleDismissEditInput = () => {
    if (isEditing) {
      dispatch(hideCardEditInput());
      dispatch(allowGoToResultsOnClickCard());
    }
  };

  useOnClickOutside(ref, cancelEdit);

  const handleRemoveSavedSearchCard = () => {
    onSetSavedSearchToRemove(savedSearch);
  };

  const handleEditButtonClick = () => {
    isEditing && dispatch(disallowGoToResultsOnClickCard());
    dispatch(showCardEditInput(savedSearch.id));
  };

  const handleMoreOptionsClick = () => {
    dispatch(openBottomPopup(bottomPopupItems));
  };

  const handleOnMouseEnter = () => {
    dispatch(showEditAndDeleteIconsOnCards(savedSearch.id));
    isEditing && dispatch(hideEditAndDeleteIconsOnCards(savedSearch.id));
  };

  const handleOnMouseLeave = () => {
    setName(savedSearch.name);
    dispatch(hideEditAndDeleteIconsOnCards(savedSearch.id));
    isEditing && dispatch(hideCardEditInput());
  };

  const handleEditing = () => {
    onUpdateSavedSearch(
      new SavedSearch(
        savedSearch.id,
        name,
        savedSearch.searchText,
        savedSearch.filterItems,
        savedSearch.priority,
        savedSearch.count,
        savedSearch.createdAt,
        DateTime.utc().toJSDate(),
        savedSearch.createdById
      )
    );
    dispatch(showActiveClassOnCard());
    handleDismissEditInput();

    const timer = setTimeout(() => {
      dispatch(hideActiveClassOnCard());
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const handleEditingSavedSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEditing();
    } else if (event.key === 'Escape') {
      cancelEdit();
    }
  };

  const onCardClick = (savedSearch: SavedSearch) => {
    onSavedSearchClick(savedSearch);
  };

  const handleOnClick = () => {
    if (isLongPress) {
      return;
    }
    !isEditing && onCardClick(savedSearch);
    !isEditing && isOnTouch && onCardClick(savedSearch);
  };

  const bottomPopupItems: BottomPopupItems[] = [
    {
      key: 'edit-saved-search-card',
      title: 'Edit',
      className: 'bottom-popup-items',
      onClick: handleEditButtonClick,
      icon: <Icon iconName="Edit" />
    },
    {
      key: 'delete-saved-search-card',
      title: 'Delete',
      className: 'bottom-popup-items',
      onClick: handleRemoveSavedSearchCard,
      icon: <Icon iconName="Delete" />
    }
  ];

  return (
    <div>
      <div>
        <GridItem key={savedSearch.id} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
          <Card
            className={isEditedSavedSearchCard && savedSearch.id === savedSearchCardKey ? `card ${activeClass} ` : 'card'}
            elevated
            onClick={handleOnClick}
            {...longPressHandlers}
          >
            <Card.Body>
              {isEditing && savedSearch.id === savedSearchCardKey ? (
                <div className="card-input" ref={ref}>
                  <Input
                    value={name}
                    onChange={(e) => setName((e.target as HTMLTextAreaElement).value)}
                    onKeyDown={(event) => handleEditingSavedSearch(event)}
                    icon={
                      <div className="card-input-actions">
                        <Button onClick={handleEditing} iconOnly content={<Icon iconName="CheckMark" />} text />
                        <Button onClick={cancelEdit} iconOnly content={<Icon iconName="Cancel" />} text />
                      </div>
                    }
                  />
                </div>
              ) : (
                <>
                  <div className="card-top">
                    <div className="card-text__wrap">
                      <span>{savedSearch.name}</span>
                      {Object.keys(groupedFilterValues).map((key) => (
                        <span key={key}>
                          <span className="card-text">
                            {intl.formatMessage({ id: getFilterLocalizationKey(groupedFilterValues[key][0].fieldName) || 'filters.filters' })}:{' '}
                          </span>
                          {groupedFilterValues[key].map((filterItem) => (
                            <span key={filterItem.fieldName}>
                              &quot;{localizeFilterItem(filterItem)}
                              &quot;
                            </span>
                          ))}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="card-avatars-wrap">
                    <div className="card-avatars">
                      {savedSearch?.avatarDetails &&
                        savedSearch?.avatarDetails.slice(0, 7).map((item) => (
                          <Tooltip
                            subtle={false}
                            pointing
                            key={item.name}
                            trigger={
                              <Avatar
                                key={item.name}
                                name={item.name}
                                image={item.profilePictureUrl}
                                size="smallest"
                                label={{
                                  variables: {
                                    backgroundColor: 'blue'
                                  }
                                }}
                              />
                            }
                            content={item.name}
                            position="below"
                            align="center"
                          />
                        ))}
                      {savedSearch?.avatarDetails && savedSearch?.resultsLength - 7 > 0 && (
                        <Tooltip
                          key="saved-search-card-users"
                          subtle={false}
                          pointing
                          trigger={
                            <Avatar
                              getInitials={() => `+${savedSearch?.resultsLength - 7}`}
                              size="smaller"
                              label={{
                                variables: {
                                  backgroundColor: 'blue'
                                }
                              }}
                            />
                          }
                          content={savedSearch?.resultsLength - 7 + ' more'}
                          position="below"
                          align="center"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
          {areEditAndDeleteIconsVisible && savedSearch.id === savedSearchCardKey && isOnDesktop && !isEditing && (
            <div className="card-icons">
              <div className="card-edit-wrap">
                <Tooltip
                  key="saved-search-card-edit"
                  subtle={false}
                  pointing
                  trigger={<Button icon={<EditIcon size="small" />} onClick={handleEditButtonClick} iconOnly text />}
                  content={formatMessage({ id: 'saved-searches-item.edit-tooltip' })}
                  position="below"
                  align="center"
                />
              </div>
              <div className="card-delete-wrap">
                <Tooltip
                  key="saved-search-card-delete"
                  subtle={false}
                  pointing
                  trigger={<Button onClick={handleRemoveSavedSearchCard} icon={<TrashCanIcon size="small" />} iconOnly text />}
                  content={formatMessage({ id: 'saved-searches-item.delete-tooltip' })}
                  position="below"
                  align="center"
                />
              </div>
            </div>
          )}
          {!(isEditing && savedSearch.id === savedSearchCardKey) && isOnTouch && (
            <div className="bottom-popup-icon-wrap" onClick={handleMoreOptionsClick}>
              <button className="bottom-popup-icon">
                <Icon iconName="MoreVertical" />
              </button>
            </div>
          )}
        </GridItem>
      </div>
    </div>
  );
};
