import { WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import { CreateMediaOutletWizard, CreateMediaToolbar, SearchMediaOutletTable, CollaborationTablePaging } from 'app/pages/my-audience/contact-profile';

export const SearchMediaOutlet = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="wizard-panel-form-wrap">
      <WizardIntro
        title={formatMessage({ id: 'add-medium-wizard-steps.search-medium' }, { mediumType: formatMessage({ id: 'labels.media-outlet' }) })}
        description=""
      />
      <CreateMediaToolbar />
      <SearchMediaOutletTable />
      <CollaborationTablePaging />
      <CreateMediaOutletWizard />
    </div>
  );
};
