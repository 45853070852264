import { TagProjection } from 'app/common';
import { Contact } from 'app/pages/my-audience/contacts';
import { LinkedSavedSearch, List } from 'app/pages/my-audience/lists';
import { SavedSearch } from 'app/pages/my-audience/saved-searches';

export const createList = (gqlList: any): List => {
  return new List(
    gqlList.id,
    gqlList.name,
    gqlList.imageUrl,
    gqlList.description,
    new Date(gqlList.createdAt),
    new Date(gqlList.lastModifiedAt),
    gqlList.campaign,
    gqlList.createdBy,
    gqlList.lastModifiedBy,
    gqlList.tags?.map((tag) => new TagProjection(tag.id, tag.name)),
    gqlList.numberOfContacts,
    gqlList.createdByContact
      ? ({ id: gqlList.createdByContact.id, firstName: gqlList.createdByContact.firstName, lastName: gqlList.createdByContact.lastName } as Contact)
      : ({} as Contact),
    gqlList.linkedSavedSearches?.map(
      (lss) =>
        new LinkedSavedSearch(
          new SavedSearch(
            lss.savedSearch.id,
            lss.savedSearch.name,
            lss.savedSearch.searchText,
            [],
            0,
            0,
            new Date(lss.savedSearch.createdAt),
            new Date(lss.savedSearch.lastModifiedAt),
            '',
            []
          ),
          lss.totalCountOfContacts,
          lss.shouldAutoUpdate
        )
    ),
    gqlList.mediumNames
  );
};
