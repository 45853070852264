interface WizardIntroInfoProps {
  title: string;
  description?: string;
  info: JSX.Element;
}

export const WizardIntroInfo = ({ title, description, info }: WizardIntroInfoProps) => {
  return (
    <div className="wizard-panel-intro">
      <h5 className="wizard-panel-title">{title}</h5>
      <span className="wizard-panel-description">{description}</span>
      <span className="wizard-panel-description">{info}</span>
    </div>
  );
};
