type AudienceHeaderProps = {
  name: string;
  email: string;
  contactData: string;
};

export const audienceHeader = ({ name, email, contactData }: AudienceHeaderProps) => {
  return [
    {
      key: 'name',
      className: 'purple',
      name,
      fieldName: 'name',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200
    },
    {
      key: 'emailAddress',
      className: 'purple',
      name: email,
      fieldName: 'emailAddress',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200
    },
    {
      key: 'contactData',
      name: contactData,
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200
    },
    { key: 'hoverActions', name: '', fieldName: 'hoverActions', isResizable: true, minWidth: 200, maxWidth: 200 }
  ];
};
