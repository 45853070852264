import { TextArea } from '@fluentui/react-northstar';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface ControlledTextAreaProps {
  name: string;
  label: string;
  control: Control<any>;
  rules?: RegisterOptions;
}

export const ControlledTextAreaInput = ({ label, name, control, rules }: ControlledTextAreaProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <>
          <span>{label}</span>
          <TextArea onChange={onChange} value={value} onBlur={onBlur} name={fieldName} error={!value && !!error} />
          <span className="error-message">{error && error.message}</span>
        </>
      )}
    />
  );
};
