import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { isTextInputValid, maximumCompanyNameLength, regAtLeastOneCharacter, useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { ControlledInput, ControlledTextAreaInput } from 'app/pages/my-audience/common';
import {
  CreateCompanyFooter,
  CreateCompanySteps,
  closeCreateCompanyWizard,
  goToNextCompanyStep,
  selectCompanyGeneralInfo,
  setCompanyGeneralInfo,
  addCompanyGeneralInfo,
  CompanyGeneralInfo
} from 'app/pages/my-audience/wizard-create-contact';
import { FormattedMessage, useIntl } from 'app/i18n';

export type CreateCompanyGeneralInfoForm = {
  name: string;
  parentCompany: string;
  comment: string;
};

export const CreateCompanyGeneralInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const companyGeneralInfo = useAppSelector<CompanyGeneralInfo>(selectCompanyGeneralInfo);

  const { handleSubmit, control, watch } = useForm<CreateCompanyGeneralInfoForm, any>({
    defaultValues: {
      name: companyGeneralInfo.name,
      parentCompany: companyGeneralInfo.parentCompany,
      comment: companyGeneralInfo.comment
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  useEffect(() => {
    return submitForm;
  }, []);

  const handleNextButtonClick = () => {
    dispatch(goToNextCompanyStep());
  };

  const submitForm = () => {
    handleSubmit(
      (data) => {
        const newCompanyGeneralInfo: CompanyGeneralInfo = setCompanyGeneralInfo(data);
        dispatch(addCompanyGeneralInfo(newCompanyGeneralInfo));
      },
      (error) => {
        Sentry.setExtra('Path', CreateCompanyGeneralInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };

  const areFieldsValid = isTextInputValid({ inputValue: watch('name'), maxInputLength: maximumCompanyNameLength });

  return (
    <>
      <CreateCompanySteps areFieldsValid={areFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.company-general-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'add-menu-create-contact.company-name-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="name"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage(
                    { id: 'error-messages.not-valid-name' },
                    { entity: formatMessage({ id: 'add-menu-create-contact.company-valid-name' }) }
                  )
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumCompanyNameLength }),
                  value: maximumCompanyNameLength
                },
                required: formatMessage({ id: 'error-messages.required-name' }, { entity: formatMessage({ id: 'add-menu-create-contact.company-valid-name' }) })
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput name="parentCompany" label={formatMessage({ id: 'add-menu-create-contact.company-parent-label' })} control={control} />
          </div>
          <div className="wizard-panel-textarea">
            <ControlledTextAreaInput name="comment" label={formatMessage({ id: 'add-menu-create-contact.company-comment-label' })} control={control} />
          </div>
        </div>
        <CreateCompanyFooter onCancelButtonClick={handleCancelButtonClick} onNextButtonClick={handleNextButtonClick} isNextButtonDisabled={!areFieldsValid} />
      </div>
    </>
  );
};
