import React, { useState } from 'react';
import { DetailsRow, IDetailsRowProps, IObjectWithKey, Selection } from '@fluentui/react';
import { Checkbox } from '@fluentui/react-northstar';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { selectIsOnMobile, useAppSelector } from 'app/common';

interface TableRowProps {
  rowProps: IDetailsRowProps;
  onOpenProfile: (id: string) => void;
  selection: Selection<IObjectWithKey>;
}

export const TableRow = ({ rowProps, onOpenProfile, selection }: TableRowProps) => {
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);

  const onRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const element = event.target as HTMLElement;
    if (!element.outerHTML.includes('ui-checkbox__indicator')) {
      onOpenProfile(rowProps.item.id);
    }
  };

  const onProfilePictureClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    if (!isOnMobile) {
      return;
    }

    event.stopPropagation();
    setHoverIndex(index);
  };

  return (
    <div
      onClick={onRowClick}
      onMouseEnter={() => {
        setHoverIndex(rowProps.itemIndex);
      }}
      onMouseLeave={() => {
        setHoverIndex(undefined);
      }}
    >
      <DetailsRow
        key={rowProps.item.id}
        {...rowProps}
        onRenderCheck={() => (
          <div className="row-header-wrap">
            {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
              <Checkbox checked={selection.getSelection().includes(rowProps.item)} />
            ) : (
              <div onClick={(event) => onProfilePictureClick(event, rowProps.itemIndex)}>
                <ProfilePicture name={rowProps.item.name} imageUrl={rowProps.item.profilePictureUrl} size="medium" />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};
