import { ReactElement } from 'react';
import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { selectedTableRowsCountSelector, Tag, TagProjection, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import 'app/common/components/action-bar.scss';

interface TagsContentProps {
  selectedTags: Tag[];
  allTags: Tag[];
  filteredTags: Tag[];
  filterTags: (text: string) => void;
  addNewTag: (text: string) => void;
  addTagToSelectedItem: (tag: TagProjection) => void;
  resetSelectedItemAfterUndo: () => void;
  removeTagFromSelectedItemInStore: (tag: TagProjection) => void;
  removeTagFromSelectedItem: (tag: TagProjection) => void;
  deleteTags: (tags: Tag[]) => void;
}

interface ActionBarProps {
  onExport: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onRefresh?: () => void;
  tagsContent: ReactElement<TagsContentProps>;
  showUpdateButton: boolean;
  isVisible: boolean;
  disabled?: boolean;
}

export const ActionBar = ({ onExport, onUpdate, onDelete, onRefresh, tagsContent, showUpdateButton, isVisible, disabled = false }: ActionBarProps) => {
  const { formatMessage } = useIntl();
  const selectedTableRowsCount = useAppSelector<number>(selectedTableRowsCountSelector);

  const onRefreshListLinkedSavedSearches = onRefresh && (
    <div className="action-bar-button">
      <Button
        text
        content={formatMessage({ id: 'lists-search-results-table.refresh-saved-searches' })}
        icon={<Icon iconName="Refresh" />}
        onClick={onRefresh}
      />
    </div>
  );

  return (
    isVisible && (
      <div className="action-bar-wrap">
        <span className="action-bar-selected">{formatMessage({ id: 'search-results-table.selected-count' }, { count: selectedTableRowsCount })}</span>
        <div className="action-bar-buttons">
          {onRefreshListLinkedSavedSearches}
          <div className="action-bar-button export-icon">
            <Button text content={formatMessage({ id: 'buttons.export' })} icon={<Icon iconName="Export" />} onClick={onExport} />
          </div>
          <div className="action-bar-button">
            <Button disabled={disabled} text content={formatMessage({ id: 'buttons.delete' })} icon={<Icon iconName="Delete" />} onClick={onDelete} />
          </div>
          {showUpdateButton && (
            <div className="action-bar-button">
              <Button disabled={disabled} text content={formatMessage({ id: 'buttons.edit' })} icon={<Icon iconName="Edit" />} onClick={onUpdate} />
            </div>
          )}
          <div className="action-bar-button">
            <div className="tags-wrapper">
              <div className="tags-popup">{tagsContent}</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
