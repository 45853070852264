import { DateTime } from 'luxon';
import { useBoolean } from '@fluentui/react-hooks';
import { Table } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { Recipient, Sending } from 'app/pages/my-activities/sendings';
import {
  selectSelectedContactRecipients,
  selectSelectedContactSendingActivities,
  SendingDetailsDialog,
  setSelectedSending
} from 'app/pages/my-audience/contact-profile';

export const SendingsAccordionItem = () => {
  const { formatMessage } = useIntl();

  const sendingActivities = useAppSelector<Sending[]>(selectSelectedContactSendingActivities);
  const recipients = useAppSelector<Recipient[]>(selectSelectedContactRecipients);

  const dispatch = useAppDispatch();
  const header = {
    items: [
      formatMessage({ id: 'contacts-sendings-table.description' }),
      formatMessage({ id: 'contacts-sendings-table.date' }),
      formatMessage({ id: 'contacts-sendings-table.opened' }),
      formatMessage({ id: 'contacts-sendings-table.links-clicked' })
    ]
  };
  const [isSendingDetailDialogOpened, { setTrue: openSendingDetailDialog, setFalse: dismissSendingDetailDialog }] = useBoolean(false);

  const onSendingItemClick = (sending) => {
    dispatch(setSelectedSending(sending));
    openSendingDetailDialog();
  };

  const rows = sendingActivities.map((sending) => {
    const recipientData = recipients.find((r) => r.sendingId === sending.id);
    if (!recipientData) {
      return {};
    }

    const date = DateTime.fromJSDate(new Date(sending?.createdAt)).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

    return {
      key: sending.id,
      items: [`${sending.title}`, date, `${recipientData.isOpened}`, `${recipientData.linksClicked}`],
      onClick: () => onSendingItemClick(sending)
    };
  });

  return (
    <>
      <Table header={header} rows={rows} aria-label="Static table" />
      <SendingDetailsDialog opened={isSendingDetailDialogOpened} dismiss={dismissSendingDetailDialog} />
    </>
  );
};
