import { Selection } from '@fluentui/react';
import { useAppDispatch, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateMediaOutletSteps,
  CollaborationTable,
  CollaborationTableToolbar,
  goToNextStep,
  goToPreviousStep,
  CreateContactFromMediaOutletWizard,
  CollaborationTablePaging,
  closeCreateMediaOutletWizard,
  CreateMediaOutletFooter
} from 'app/pages/my-audience/wizard-create-media-outlet';

interface AddCollaborationProps {
  selection: Selection;
}

export const AddCollaboration = ({ selection }: AddCollaborationProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const handleDismissButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };

  return (
    <>
      <CreateMediaOutletSteps isStepValid={true} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-mo.collaboration-title' })} description="" />
        <CollaborationTableToolbar />
        <CollaborationTable selection={selection} />
        <CollaborationTablePaging />
        <CreateContactFromMediaOutletWizard />
        <CreateMediaOutletFooter
          onNextButtonClick={handleNextButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onCancelButtonClick={handleDismissButtonClick}
          isNextButtonDisabled={false}
        />
      </div>
    </>
  );
};
