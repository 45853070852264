import { useState } from 'react';
import { Button } from '@fluentui/react-northstar';
import { Label } from '@fluentui/react';
import { Interval } from 'luxon';
import { DatePicker, DateRange, FilterItem, useAppDispatch } from 'app/common';
import { addListsFilter, firstPageOfListsRequested } from 'app/pages/my-audience/lists';
import { LIST_FILTER } from 'app/pages/my-audience/lists-filter-search/filter-fields';
import { useIntl } from 'app/i18n';
import { FilterItemType, TimeFrameSingular, generateFilterFromStartOf, generateTimeZoneInterval } from 'app/common/factories/time-zone';

export const CreatedAtFilter = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();

  const onFromDateChanged = (selectedDate: Date) => {
    setFrom(selectedDate);
    if (to) {
      const int = Interval.fromDateTimes(selectedDate, to);
      if (int.isValid) addFilter(int);
    }
  };

  const onToDateChanged = (selectedDate: Date) => {
    setTo(selectedDate);
    if (from) {
      const int = Interval.fromDateTimes(from, selectedDate);
      if (int.isValid) addFilter(int);
    }
  };

  const applyFilterStartingFrom = (startFrom: TimeFrameSingular) => {
    const interval = generateFilterFromStartOf(startFrom);
    addFilter(interval);
  };

  const applyFilter = (filter: FilterItemType) => {
    const interval = generateTimeZoneInterval(filter);
    addFilter(interval);
  };

  const addFilter = (interval: Interval) => {
    const dateRange = DateRange.parse(interval);
    dispatch(addListsFilter(new FilterItem(LIST_FILTER.CREATED_AT, `${dateRange.startDate} - ${dateRange.endDate}`)));
    dispatch(firstPageOfListsRequested());
  };

  return (
    <div className="date-wrap">
      <div className="accordion-head-box">
        <Label>{formatMessage({ id: 'filter-by-date-popup.from-label' })}</Label>
        <DatePicker onSelectDate={(selectedDate) => onFromDateChanged(selectedDate)} value={from} />
        <Label>{formatMessage({ id: 'filter-by-date-popup.to-label' })}</Label>
        <DatePicker onSelectDate={(selectedDate) => onToDateChanged(selectedDate)} value={to} />
      </div>
      <div className="button-creation-date-wrap">
        <Button
          className="button-creation-date"
          content={formatMessage({ id: 'filter-by-date-popup.buttons.this-week' })}
          onClick={() => applyFilterStartingFrom('week')}
        />
        <br />
        <Button
          className="button-creation-date"
          content={formatMessage({ id: 'filter-by-date-popup.buttons.this-month' })}
          onClick={() => applyFilterStartingFrom('month')}
        />
        <br />
        <Button
          className="button-creation-date"
          content={formatMessage({ id: 'filter-by-date-popup.buttons.past-month' })}
          onClick={() => applyFilter({ amount: 1, timeFrame: 'months' })}
        />
        <br />
        <Button
          className="button-creation-date"
          content={formatMessage({ id: 'filter-by-date-popup.buttons.past-two-months' })}
          onClick={() => applyFilter({ amount: 2, timeFrame: 'months' })}
        />
      </div>
    </div>
  );
};
