import { useNavigate } from 'react-router-dom';
import {
  selectIsLoadingCompanySavedSearches,
  selectCompanySavedSearches,
  selectCompanySavedSearchToRemove,
  updateCompanySavedSearchesRequested,
  updateCompanySavedSearchRequested,
  setCompanySavedSearchToRemove,
  removeCompanySavedSearchRequested,
  selectIsDeleteDialogOpened,
  closeDeleteDialog,
  selectTotalCountOfSavedSearches,
  selectSearchText,
  selectFilterItem,
  selectPageNumber,
  firstPageOfSavedSearchesRequested,
  companiesSavedSearchesRequested
} from 'app/pages/my-audience/companies-saved-searches';
import { closeSavedSearchesPanel, firstPageOfCompaniesRequested, selectIsSavedSearchesPanelOpen, setQueryParams } from 'app/pages/my-audience/companies';
import { SavedSearchPanel, SavedSearch, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import { FilterItem, useAppDispatch, useAppSelector } from 'app/common';

export const CompaniesSavedSearchesPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoadingCompanySavedSearches = useAppSelector<boolean>(selectIsLoadingCompanySavedSearches);
  const isDeleteDialogOpened = useAppSelector<boolean>(selectIsDeleteDialogOpened);
  const totalCountOfSavedSearches = useAppSelector<number>(selectTotalCountOfSavedSearches);
  const searchText = useAppSelector<string>(selectSearchText);
  const filterItem = useAppSelector<FilterItem>(selectFilterItem);
  const pageNumber = useAppSelector<number>(selectPageNumber);
  const companySavedSearches = useAppSelector<SavedSearch[]>(selectCompanySavedSearches);
  const savedSearchToRemove = useAppSelector<SavedSearch>(selectCompanySavedSearchToRemove);
  const isSavedSearchesPanelOpen = useAppSelector<boolean>(selectIsSavedSearchesPanelOpen);

  const handleSearchSavedSearches = (searchText: string) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleFilterSavedSearches = (filterItem: FilterItem) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleLoadMore = () => {
    dispatch(
      companiesSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem,
        pageNumber: pageNumber + 1
      })
    );
  };

  const handleResetFilterSavedSearches = () => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: {} as FilterItem
      })
    );
  };

  const onUpdateCompanySavedSearches = (reorderedSavedSearches: SavedSearch[]) => {
    // Update handles only reordering
    if (reorderedSavedSearches.length === totalCountOfSavedSearches) {
      dispatch(
        updateCompanySavedSearchesRequested(
          reorderedSavedSearches.map((ss) => new SavedSearchWithIdInput(ss.id, ss.name, ss.searchText, ss.filterItems, ss.priority, ss.count))
        )
      );
    }
  };

  const onUpdateCompanySavedSearch = (updatedSavedSearch: SavedSearch) => {
    dispatch(updateCompanySavedSearchRequested(updatedSavedSearch));
  };

  const onSetCompanySavedSearchToRemove = (savedSearchToRemove: SavedSearch) => {
    dispatch(setCompanySavedSearchToRemove(savedSearchToRemove));
  };

  const onSearchCompanySavedSearch = (savedSearch: SavedSearch) => {
    dispatch(setQueryParams({ searchText: savedSearch.searchText, filterItems: savedSearch.filterItems }));
    dispatch(firstPageOfCompaniesRequested());
    navigate(`/my-audience/companies/results/${savedSearch.searchText ?? '*'}`);
  };

  const onRemoveCompanySavedSearch = () => {
    dispatch(removeCompanySavedSearchRequested(savedSearchToRemove));
  };

  const onCloseDeleteDialog = () => {
    dispatch(closeDeleteDialog());
  };

  const onDismissSavedSearchesPanel = () => {
    dispatch(closeSavedSearchesPanel());
    navigate(-1);
  };

  return (
    <SavedSearchPanel
      isOpen={isSavedSearchesPanelOpen}
      dismissPanel={onDismissSavedSearchesPanel}
      isLoadingSavedSearches={isLoadingCompanySavedSearches}
      savedSearches={companySavedSearches}
      totalCount={totalCountOfSavedSearches}
      onSearchSavedSearches={handleSearchSavedSearches}
      onFilterSavedSearches={handleFilterSavedSearches}
      onResetFilter={handleResetFilterSavedSearches}
      onHandleLoadMore={handleLoadMore}
      onSavedSearchClick={onSearchCompanySavedSearch}
      onReorderSavedSearches={onUpdateCompanySavedSearches}
      onSetSavedSearchToRemove={onSetCompanySavedSearchToRemove}
      onUpdateSavedSearch={onUpdateCompanySavedSearch}
      isDeleteDialogOpened={isDeleteDialogOpened}
      onRemoveSavedSearch={onRemoveCompanySavedSearch}
      onCloseDeleteDialog={onCloseDeleteDialog}
      searchText={searchText}
    />
  );
};
