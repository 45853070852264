import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';
import { MediaResorts, ContactData, Overview } from 'app/pages/my-audience/media-outlet-profile';

export const ProfileAccordion = () => {
  const { formatMessage } = useIntl();
  const panels = [
    {
      title: (
        <div className="accordion-head-box" key="contact-data-title">
          <span className="accordion-heading">{formatMessage({ id: 'mo-profile-tab-accordion.contact-data' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'contact-data-content', content: <ContactData /> }
    },
    {
      title: (
        <div className="accordion-head-box" key="overview-title">
          <span className="accordion-heading">{formatMessage({ id: 'mo-profile-tab-accordion.overview' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'overview-content', content: <Overview /> }
    },
    {
      title: (
        <div className="accordion-head-box" key="columns-title">
          <span className="accordion-heading">{formatMessage({ id: 'mo-profile-tab-accordion.media-resorts' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'columns-content',
        content: <MediaResorts />
      }
    }
  ];

  return (
    <div className="profile-panel-content">
      <Accordion defaultActiveIndex={[0]} panels={panels} />
    </div>
  );
};
