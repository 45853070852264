import { useNavigate, useParams } from 'react-router-dom';
import { FilterItem, SearchResultTableData, selectSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import {
  List,
  contactsFilterSuggestionsRequested,
  firstPageOfListsRequested,
  openFiltersPanel,
  removeListsFilter,
  selectFilterItems,
  selectLists
} from 'app/pages/my-audience/lists';
import { FilterPills } from 'app/pages/my-audience/common';
import { selectIsProfilePanelOpened } from 'app/pages/my-audience/lists-profile';

export const ListsFilterPills = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isListProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const areFiltersVisible = selectedTableRows.length === 0 || isListProfileOpened;
  const lists = useAppSelector<List[]>(selectLists);
  const filterItems = useAppSelector<FilterItem[]>(selectFilterItems);

  const handleOpenFiltersPanel = () => {
    dispatch(openFiltersPanel());
    if (lists.length > 0 && lists.some((list) => list.numberOfContacts > 0)) {
      dispatch(contactsFilterSuggestionsRequested());
    }
    navigate(`/my-audience/lists/results/${params.listsQuery}/filter-lists`);
  };

  const handleRemoveFilter = (filterItem: FilterItem) => {
    dispatch(removeListsFilter(filterItem));
  };

  const handleSearchLists = () => {
    dispatch(firstPageOfListsRequested());
  };

  return (
    areFiltersVisible && (
      <FilterPills filterItems={filterItems} onOpenFiltersPanel={handleOpenFiltersPanel} onRemoveFilter={handleRemoveFilter} onSearch={handleSearchLists} />
    )
  );
};
