import { Button } from '@fluentui/react-northstar';
import {
  fieldContainsNonEmptyValue,
  hideUndoAlert,
  isEmailAddressValidOrEmpty,
  isValidUrlField,
  EditableDropdownField,
  EditableFieldWithStar,
  EditableInputTextField,
  selectIsUndoAlertVisible,
  showUndoAlert,
  undoQueue,
  useAppDispatch,
  useAppSelector,
  isPhoneNumberValidOrEmpty,
  ensureHttpProtocol
} from 'app/common';
import { useIntl } from 'app/i18n';
import {
  hideMoreEditableFields,
  resetSelectedContactAfterUndo,
  selectIsChoosingPhoneNumberDefaultState,
  selectSelectedContact,
  selectSelectedContactData,
  setIsChoosingPhoneNumberDefaultState,
  updateCollaborationInStore,
  updateCollaborationRequested,
  updateOwnContactDataInStore,
  updateOwnContactDataRequested
} from 'app/pages/my-audience/contact-profile';
import {
  Collaboration,
  Contact,
  ContactDataType,
  OwnContactData,
  PhoneNumber,
  undoUpdateContactInTable,
  updateCollaborationInTable
} from 'app/pages/my-audience/contacts';
import { getNames } from 'country-list';

interface AboutMoreFieldsProps {
  disabled: boolean;
}

export const AboutMoreFields = ({ disabled }: AboutMoreFieldsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedContactData = useAppSelector<Collaboration | OwnContactData>(selectSelectedContactData);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const isUndoAlertVisible = useAppSelector<boolean>(selectIsUndoAlertVisible);
  const isChoosingPhoneNumberDefaultState = useAppSelector<boolean>(selectIsChoosingPhoneNumberDefaultState);

  const updateCollaboration = (selectedContactDataLocal: Collaboration) => {
    dispatch(updateCollaborationInStore(selectedContactDataLocal));
    dispatch(updateCollaborationInTable({ contactId: selectedContact.id, collaboration: selectedContactDataLocal }));

    undoQueue.waitAndExecute({
      executeAction: () => {
        dispatch(updateCollaborationRequested(selectedContactDataLocal as Collaboration));
        dispatch(hideUndoAlert());
      },
      undoAction: () => {
        dispatch(resetSelectedContactAfterUndo());
        dispatch(undoUpdateContactInTable());
      }
    });
  };

  const updateOwnContactData = (selectedContactDataLocal: OwnContactData) => {
    dispatch(updateOwnContactDataInStore(selectedContactDataLocal));

    undoQueue.waitAndExecute({
      executeAction: () => {
        dispatch(updateOwnContactDataRequested(selectedContactDataLocal as OwnContactData));
        dispatch(hideUndoAlert());
      },
      undoAction: () => {
        dispatch(resetSelectedContactAfterUndo());
        dispatch(undoUpdateContactInTable());
      }
    });
  };

  const onSaveContactDataChanges = (name: string, updatedValue: string) => {
    const updatedContactData: Collaboration | OwnContactData = {
      ...selectedContactData,
      [name]:
        name === 'landlinePhoneNumber' || name === 'mobilePhoneNumber' ? new PhoneNumber(updatedValue, selectedContactData?.[name]?.isPrimary) : updatedValue
    };

    if (selectedContactData.type == ContactDataType.Collaboration) {
      updateCollaboration(updatedContactData as Collaboration);
    } else {
      updateOwnContactData(updatedContactData as OwnContactData);
    }

    dispatch(showUndoAlert(formatMessage({ id: 'alert-messages.contact-updated' })));
  };

  const onSaveUrl = (fieldName: string, url: string) => {
    const updatedContactData: Collaboration | OwnContactData = {
      ...selectedContactData,
      [fieldName]: ensureHttpProtocol(url)
    };

    if (selectedContactData.type == ContactDataType.Collaboration) {
      updateCollaboration(updatedContactData as Collaboration);
    } else {
      updateOwnContactData(updatedContactData as OwnContactData);
    }

    dispatch(showUndoAlert(formatMessage({ id: 'alert-messages.contact-updated' })));
  };

  const handleShowLessButtonClick = () => {
    dispatch(hideMoreEditableFields());
  };

  const handleSetNumberAsFavourite = (name: string, newValue: boolean) => {
    if (!newValue) {
      removeDefaultNumber(selectedContactData);
      return;
    }

    const updatedContactData = {
      ...selectedContactData,
      landlinePhoneNumber: new PhoneNumber(selectedContactData.landlinePhoneNumber.value, name === 'landlinePhoneNumber'),
      mobilePhoneNumber: new PhoneNumber(selectedContactData.mobilePhoneNumber.value, name === 'mobilePhoneNumber')
    };

    selectedContactData.type === ContactDataType.OwnContactData
      ? dispatch(updateOwnContactDataRequested(updatedContactData as OwnContactData))
      : dispatch(updateCollaborationRequested(updatedContactData as Collaboration));

    dispatch(setIsChoosingPhoneNumberDefaultState(true));
  };

  const removeDefaultNumber = (contactData: Collaboration | OwnContactData) => {
    const updatedContactData = {
      ...contactData,
      landlinePhoneNumber: new PhoneNumber(contactData.landlinePhoneNumber.value, false),
      mobilePhoneNumber: new PhoneNumber(contactData.mobilePhoneNumber.value, false)
    };

    if (contactData.type === ContactDataType.Collaboration) {
      dispatch(updateCollaborationRequested(updatedContactData as Collaboration));
    } else {
      dispatch(updateOwnContactDataRequested(updatedContactData as OwnContactData));
    }

    dispatch(setIsChoosingPhoneNumberDefaultState(true));
  };

  return (
    <>
      <EditableFieldWithStar
        value={selectedContactData?.mobilePhoneNumber?.value}
        fieldName="mobilePhoneNumber"
        onChange={onSaveContactDataChanges}
        label={formatMessage({ id: 'contact-data-labels.mobile-phone-number' })}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-phone-number' })}
        isPrimary={selectedContactData?.mobilePhoneNumber?.isPrimary}
        onChangePriority={handleSetNumberAsFavourite}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.mobilePhoneNumber?.value)}
        isFieldValid={isPhoneNumberValidOrEmpty}
        disabled={disabled || isUndoAlertVisible || isChoosingPhoneNumberDefaultState}
      />
      <EditableInputTextField
        value={selectedContactData?.emailAddress}
        fieldName="emailAddress"
        onChange={onSaveContactDataChanges}
        label={formatMessage({ id: 'contact-data-labels.email-address' })}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-email-address' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.emailAddress)}
        linkUrl={'mailto:' + selectedContactData?.emailAddress}
        isFieldValid={isEmailAddressValidOrEmpty}
        disabled={disabled}
      />
      <EditableInputTextField
        value={selectedContactData?.address}
        fieldName="address"
        onChange={onSaveContactDataChanges}
        label={formatMessage({ id: 'contact-data-labels.address' })}
        isLink={false}
        disabled={disabled}
      />
      <EditableDropdownField
        value={selectedContactData?.country}
        fieldName="country"
        suggestions={getNames()}
        onSaveChanges={onSaveContactDataChanges}
        label={formatMessage({ id: 'contact-data-labels.country' })}
        disabled={disabled}
      />
      <EditableInputTextField
        value={selectedContactData?.city}
        fieldName="city"
        onChange={onSaveContactDataChanges}
        label={formatMessage({ id: 'contact-data-labels.city' })}
        isLink={false}
        disabled={disabled}
      />
      <EditableInputTextField
        value={selectedContactData?.postalCode}
        fieldName="postalCode"
        onChange={onSaveContactDataChanges}
        label={formatMessage({ id: 'contact-data-labels.postal-code' })}
        isLink={false}
        disabled={disabled}
      />
      <EditableInputTextField
        value={selectedContactData?.blogUrl}
        fieldName="blogUrl"
        onChange={onSaveUrl}
        label={formatMessage({ id: 'contact-data-labels.blog' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.blogUrl)}
        linkUrl={selectedContactData?.blogUrl}
        disabled={disabled}
        isFieldValid={isValidUrlField}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
      />
      <EditableInputTextField
        value={selectedContactData?.websiteUrl}
        fieldName="websiteUrl"
        onChange={onSaveUrl}
        label={formatMessage({ id: 'contact-data-labels.website' })}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.websiteUrl)}
        linkUrl={selectedContactData?.websiteUrl}
        isFieldValid={isValidUrlField}
        disabled={disabled}
      />
      <EditableInputTextField
        value={selectedContactData?.twitterProfileUrl}
        fieldName="twitterProfileUrl"
        onChange={onSaveUrl}
        label={formatMessage({ id: 'contact-data-labels.twitter' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.twitterProfileUrl)}
        linkUrl={selectedContactData?.twitterProfileUrl}
        disabled={disabled}
        isFieldValid={isValidUrlField}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
      />
      <EditableInputTextField
        value={selectedContactData?.instagramProfileUrl}
        fieldName="instagramProfileUrl"
        onChange={onSaveUrl}
        label={formatMessage({ id: 'contact-data-labels.instagram' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.instagramProfileUrl)}
        linkUrl={selectedContactData?.instagramProfileUrl}
        disabled={disabled}
        isFieldValid={isValidUrlField}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
      />
      <EditableInputTextField
        value={selectedContactData?.linkedInProfileUrl}
        fieldName="linkedInProfileUrl"
        onChange={onSaveUrl}
        label={formatMessage({ id: 'contact-data-labels.linkedin' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.linkedInProfileUrl)}
        linkUrl={selectedContactData?.linkedInProfileUrl}
        disabled={disabled}
        isFieldValid={isValidUrlField}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
      />
      <EditableInputTextField
        value={selectedContactData?.youtubeProfileUrl}
        fieldName="youtubeProfileUrl"
        onChange={onSaveUrl}
        label={formatMessage({ id: 'contact-data-labels.youtube' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.youtubeProfileUrl)}
        linkUrl={selectedContactData?.youtubeProfileUrl}
        disabled={disabled}
        isFieldValid={isValidUrlField}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
      />
      <EditableInputTextField
        value={selectedContactData?.facebookProfileUrl}
        fieldName="facebookProfileUrl"
        onChange={onSaveUrl}
        label={formatMessage({ id: 'contact-data-labels.facebook' })}
        isLink={fieldContainsNonEmptyValue(selectedContactData?.facebookProfileUrl)}
        linkUrl={selectedContactData?.facebookProfileUrl}
        disabled={disabled}
        isFieldValid={isValidUrlField}
        errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
      />
      <div className="editable-fields-button">
        <Button onClick={handleShowLessButtonClick} text content={formatMessage({ id: 'buttons.show-less' })} />
      </div>
    </>
  );
};
