import { Dialog, DialogType } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { Contact } from 'app/pages/my-audience/contacts';
import { closeQRCodeDialog, selectContactVCardStringValue, selectIsQRCodeDialogOpened, selectSelectedContact } from 'app/pages/my-audience/contact-profile';
import QRCode from 'qrcode.react';
import { useIntl } from 'app/i18n';

export const QRCodeDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedContactVCard = useAppSelector<string>(selectContactVCardStringValue);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const isQRCodeDialogOpened = useAppSelector<boolean>(selectIsQRCodeDialogOpened);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'qr-code-dialog.title' }, { contactName: `${selectedContact.firstName} ${selectedContact.lastName}` }),
    className: 'profile-panel-qr-code__dialog'
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const handleDismissButtonClick = () => {
    dispatch(closeQRCodeDialog());
  };

  return (
    <Dialog hidden={!isQRCodeDialogOpened} onDismiss={handleDismissButtonClick} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="profile-panel-qr-code">
        <div className="profile-panel-qr-code-canvas">
          <QRCode id="QRCode" value={selectedContactVCard} />
        </div>
      </div>
    </Dialog>
  );
};
