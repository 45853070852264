import { call, put, select, takeEvery } from 'redux-saga/effects';
import { MessageData, safe, setAlertData } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import {
  addCompany,
  CompaniesSortOption,
  CompaniesSortOrder,
  Company,
  fetchCompaniesByQueryParamsPaged,
  PageOfCompanies
} from 'app/pages/my-audience/companies';
import { addContact, CollaborationDetails, Contact, firstPageOfContactsRequested, OwnContactData, PreferredLanguage } from 'app/pages/my-audience/contacts';
import {
  addMediaOutlet,
  fetchMediaOutletsByQueryParamsPaged,
  MediaOutlet,
  MediaOutletsSortOption,
  MediaOutletsSortOrder,
  PageOfMediaOutlets
} from 'app/pages/my-audience/media-outlets';
import {
  addCompanyRequested,
  addContactRequested,
  addMediaOutletRequested,
  closeCreateContactWizard,
  ContactGeneralInfo,
  pageOfMediaOutletsAndCompaniesReceived,
  pageOfMediaOutletsAndCompaniesRequested,
  selectCollaborationsToAdd,
  selectCompanyToAdd,
  selectContactInfoToAdd,
  selectMediaOutlets,
  selectMediaOutletToAdd,
  selectMediaSearchText,
  selectPageNumber,
  selectPageSize
} from 'app/pages/my-audience/wizard-create-contact';

function* addContactFromWizardFlow() {
  const info: ContactGeneralInfo = yield select(selectContactInfoToAdd);
  const collaborationDetails: CollaborationDetails[] = yield select(selectCollaborationsToAdd);
  const collaborationsToAdd = collaborationDetails.map((c, i) => ({ ...c.collaboration, isPrimary: i === 0 }));

  const ownContactData = new OwnContactData(
    info.address,
    info.country,
    info.phoneNumber
      ? {
          value: info.phoneNumber,
          isPrimary: false
        }
      : null,
    info.mobilePhoneNumber
      ? {
          value: info.mobilePhoneNumber,
          isPrimary: false
        }
      : null,
    info.emailAddress,
    info.blog,
    info.websiteUrl,
    info.twitterUrl,
    info.instagramUrl,
    info.linkedinUrl,
    info.youtubeUrl,
    info.facebookUrl,
    collaborationDetails.length === 0,
    info.city,
    info.postalCode,
    ''
  );

  const contactData: Contact = new Contact(
    '',
    info.salutation,
    info.firstName,
    info.lastName,
    '',
    false,
    [],
    [],
    [],
    collaborationsToAdd,
    [],
    [],
    info.language ? [new PreferredLanguage(info.language.id, info.language.name, '')] : [],
    ownContactData
  );

  yield call(addContact, client, contactData);
  yield put(closeCreateContactWizard());
  yield put(firstPageOfContactsRequested());
  yield put(setAlertData(new MessageData('alert-contact-added')));
}

export function* addContactFromWizardRequestedWatcher() {
  yield takeEvery(addContactRequested.type, safe(addContactFromWizardFlow));
}

function* mediaOutletsAndCompaniesRequestedFlow() {
  const searchText = yield select(selectMediaSearchText);
  const pageSize = yield select(selectPageSize);
  const pageNumber = yield select(selectPageNumber);
  const mediaOutlets = yield select(selectMediaOutlets);

  const pageOfCompanies: PageOfCompanies = yield call(fetchCompaniesByQueryParamsPaged, client, searchText, [], pageNumber, pageSize, {
    sortOption: CompaniesSortOption.CreatedAt,
    sortOrder: CompaniesSortOrder.Desc
  });

  const numberOfCompanies = pageOfCompanies.companies.length;
  const pageOfMediaOutlets: PageOfMediaOutlets = yield call(
    fetchMediaOutletsByQueryParamsPaged,
    client,
    searchText,
    [],
    (pageNumber - 1) * mediaOutlets.length,
    pageSize - numberOfCompanies,
    {
      sortOption: MediaOutletsSortOption.CreatedAt,
      sortOrder: MediaOutletsSortOrder.Desc
    }
  );

  yield put(pageOfMediaOutletsAndCompaniesReceived({ pageOfMediaOutlets: pageOfMediaOutlets, pageOfCompanies: pageOfCompanies }));
}

export function* mediaOutletsAndCompaniesRequestedWatcher() {
  yield takeEvery(pageOfMediaOutletsAndCompaniesRequested.type, safe(mediaOutletsAndCompaniesRequestedFlow));
}

function* addCompanyFlow() {
  const companyToAdd: Company = yield select(selectCompanyToAdd);

  yield call(addCompany, client, companyToAdd);

  yield put(pageOfMediaOutletsAndCompaniesRequested());
  yield put(setAlertData(new MessageData('alert-company-added')));
}

export function* addCompanyFromContactWizardWatcher() {
  yield takeEvery(addCompanyRequested.type, safe(addCompanyFlow));
}

function* addMediaOutletFlow() {
  const mediaOutletToAdd: MediaOutlet = yield select(selectMediaOutletToAdd);

  yield call(addMediaOutlet, client, mediaOutletToAdd);

  yield put(pageOfMediaOutletsAndCompaniesRequested());
  yield put(setAlertData(new MessageData('alert-media-outlet-added')));
}

export function* addMediaOutletFromContactWizardWizardWatcher() {
  yield takeEvery(addMediaOutletRequested.type, safe(addMediaOutletFlow));
}
