import {
  ActionBar,
  closeDeleteEntitiesDialog,
  DeleteEntitiesDialog,
  hideUndoAlert,
  openDeleteEntitiesDialog,
  openExportDialog,
  SearchResultTableData,
  selectSelectedTableRows,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import {
  List,
  bulkRefreshLinkedSavedSearchesRequested,
  removeListsByIdsRequested,
  removeListsFromStore,
  selectLists,
  undoRemoveListsFromStore
} from 'app/pages/my-audience/lists';
import { useIntl } from 'app/i18n';
import 'app/common/components/action-bar.scss';
import { ListsTagsPopup } from 'app/pages/my-audience/lists-search-results';
import { Permission, useAuthContext } from 'auth';
import { selectIsProfilePanelOpened } from 'app/pages/my-audience/lists-profile';

export const ListsActionBar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const listsStore = useAppSelector<List[]>(selectLists);
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isListProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const isActionBarVisible = !!selectedTableRows.length && !isListProfileOpened;

  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageLists);

  const onExport = () => {
    dispatch(openExportDialog());
  };

  const onRefreshLinkedSavedSearches = () => {
    const selectedLists = listsStore.filter((list) => selectedTableRows.map((list) => list.id).includes(list.id));
    dispatch(bulkRefreshLinkedSavedSearchesRequested(selectedLists));
  };

  const onDelete = () => {
    dispatch(openDeleteEntitiesDialog());
  };

  const onDeleteFromStore = () => {
    const listIdsToBeDeleted = selectedTableRows.map((row) => row.id);
    dispatch(removeListsFromStore(listIdsToBeDeleted));
  };

  const onConfirmDelete = () => {
    const listIdsToBeDeleted = selectedTableRows.map((row) => row.id);
    dispatch(removeListsByIdsRequested(listIdsToBeDeleted));
    dispatch(hideUndoAlert());
  };

  const onRevertAction = () => {
    dispatch(undoRemoveListsFromStore());
  };

  const onCancelDelete = () => {
    dispatch(closeDeleteEntitiesDialog());
  };

  return (
    <>
      <DeleteEntitiesDialog
        entityNameInSingular={formatMessage({ id: 'labels.list' })}
        entityNameInPlural={formatMessage({ id: 'labels.lists' })}
        numberOfSelectedEntities={selectedTableRows.length}
        onExecuteAction={onConfirmDelete}
        onExecuteInStore={onDeleteFromStore}
        onRevertAction={onRevertAction}
        onCancel={onCancelDelete}
      />
      <ActionBar
        isVisible={isActionBarVisible}
        onExport={onExport}
        onDelete={onDelete}
        onRefresh={onRefreshLinkedSavedSearches}
        showUpdateButton={false}
        tagsContent={<ListsTagsPopup />}
        disabled={!hasPermissionToEdit}
      />
    </>
  );
};
