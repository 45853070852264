import { Icon } from '@fluentui/react';
import { Button, EditIcon, Input, PopupIcon, StarIcon, Tooltip } from '@fluentui/react-northstar';
import {
  CopyToClipboardTooltip,
  hideEditAndCopyActions,
  isStringEmpty,
  hideEditInputFieldText,
  selectAreEditAndCopyActionsVisible,
  selectEditableFieldActionsId,
  selectEditableFieldId,
  selectIsInputFieldEditing,
  setEditableFieldActionsId,
  setEditableFieldId,
  showEditAndCopyActions,
  showEditInputFieldText,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { useIntl } from 'app/i18n';
import _uniqueId from 'lodash/uniqueId';
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

interface EditableInputTextFieldProps {
  value: string;
  fieldName: string;
  onChange: (name: string, updatedValue: string) => void;
  label?: string;
  errorMsg?: string;
  isLink: boolean;
  isPrimary: boolean;
  disabled?: boolean;
  onChangePriority: (param: string, newValue: boolean) => void;
  isFieldValid?: (value: string) => boolean;
}

export const EditableFieldWithStar = ({
  value,
  fieldName,
  onChange,
  label,
  errorMsg,
  isLink,
  isPrimary,
  onChangePriority,
  isFieldValid,
  disabled = false
}: EditableInputTextFieldProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const editableFieldId = useRef<number>(parseInt(_uniqueId()));
  const editableFieldActionsId = useRef<number>(parseInt(_uniqueId()));

  const areEditAndCopyActionsVisible = useAppSelector<boolean>(selectAreEditAndCopyActionsVisible);
  const selectorEditableFieldId = useAppSelector<number>(selectEditableFieldId);
  const selectorEditableActionsId = useAppSelector<number>(selectEditableFieldActionsId);
  const isInputEditing = useAppSelector<boolean>(selectIsInputFieldEditing);

  const [errorClass, setErrorClass] = useState('');
  const [updatedValue, setUpdatedValue] = useState(value);

  useEffect(() => {
    setUpdatedValue(value);
  }, [fieldName, value, selectorEditableActionsId]);

  const handleDismissEditInput = () => {
    if (isInputEditing) {
      setUpdatedValue(value);
      dispatch(hideEditInputFieldText());
    }
  };
  useOnClickOutside(ref, handleDismissEditInput);

  const handleKeyboardEvents = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEditing();
    } else if (event.key === 'Escape') {
      handleDismissEditInput();
    }
  };

  const handleMouseEnter = () => {
    dispatch(setEditableFieldId(editableFieldId.current));
    dispatch(showEditAndCopyActions());
  };

  const handleMouseLeave = () => {
    dispatch(hideEditAndCopyActions());
  };

  const handleEditButtonClick = () => {
    dispatch(setEditableFieldActionsId(editableFieldActionsId.current));
    dispatch(showEditInputFieldText());
  };

  const handleEditing = () => {
    if (isFieldValid && !isFieldValid(updatedValue)) {
      setErrorClass('error');
      return;
    }

    setErrorClass('');
    onChange(fieldName, updatedValue);
    setUpdatedValue(updatedValue);
    dispatch(hideEditInputFieldText());
  };

  const setAsPrimary = () => {
    onChangePriority(fieldName, !isPrimary);
  };

  return (
    <div className="editable-fields-row">
      <span className="editable-fields-row-label">{label ? label + ':' : ''}</span>
      <div className="editable-fields-row-content" ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isInputEditing && editableFieldActionsId.current === selectorEditableActionsId ? (
          <div className={`editable-fields-input ${errorClass}`}>
            <Input
              className={`input-wrap ${errorClass}`}
              type="text"
              autoFocus
              icon={
                <div className="editable-fields-input-actions">
                  <Button onClick={handleDismissEditInput} iconOnly content={<Icon iconName="Cancel" />} text />
                  <Button onClick={handleEditing} disabled={disabled} iconOnly content={<Icon iconName="CheckMark" />} text />
                </div>
              }
              value={updatedValue}
              onChange={(e) => setUpdatedValue((e.target as HTMLTextAreaElement).value)}
              onKeyDown={(e) => handleKeyboardEvents(e)}
            />
            <span className="editable-fields-error-msg">{errorMsg}</span>
          </div>
        ) : (
          <>
            {isLink ? (
              <a href={'tel:+' + updatedValue} className="editable-fields-value link" rel="noreferrer" target="_blank">
                {updatedValue && updatedValue !== '' ? updatedValue : '/'}
                <PopupIcon />
              </a>
            ) : (
              <span className={isStringEmpty(updatedValue) ? 'editable-fields-value editable-fields-empty' : 'editable-fields-value link'}>
                {updatedValue && updatedValue !== '' ? updatedValue : '/'}
              </span>
            )}
            {updatedValue && (
              <div className="profile-panel-tooltip-wrap">
                <Tooltip
                  subtle={false}
                  pointing
                  trigger={
                    <Button
                      icon={<StarIcon />}
                      primary={isPrimary}
                      onClick={setAsPrimary}
                      iconOnly
                      disabled={disabled}
                      size="small"
                      className={isPrimary ? 'button-star button-star--primary' : 'button-star'}
                    />
                  }
                  content={isPrimary ? 'Default number' : 'Set as Default number'}
                />
              </div>
            )}
            {areEditAndCopyActionsVisible && editableFieldId.current === selectorEditableFieldId ? (
              <div className="editable-fields-actions">
                <Tooltip
                  subtle={false}
                  pointing
                  trigger={<Button disabled={disabled} icon={<EditIcon />} onClick={handleEditButtonClick} iconOnly text size="small" />}
                  content={formatMessage({ id: 'button-tooltips.edit' })}
                  position="below"
                />
                <CopyToClipboardTooltip textToCopy={updatedValue} label={label} props={{ position: 'below' }} />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};
