import { useAppDispatch, useAppSelector } from 'app/common';
import { AudienceTable, AudienceTableActionBar, AudienceTablePaging, DeleteRecipientDialog } from 'app/pages/my-activities/sending-profile';
import {
  removeRecipientsFromSendingRequested,
  selectSelectedRecipientsIds,
  selectSelectedSendingRecipients,
  setEditingRecipientsIds
} from 'app/pages/my-activities/sending-wizard';
import { Recipient } from 'app/pages/my-activities/sendings';
import 'app/pages/my-activities/sending-profile/components/audience-tab.scss';

interface AudienceTabProps {
  disabled: boolean;
}

export const AudienceTab = ({ disabled }: AudienceTabProps) => {
  const dispatch = useAppDispatch();
  const recipients = useAppSelector<Recipient[]>(selectSelectedSendingRecipients);
  const selectedRecipientsIds = useAppSelector<string[]>(selectSelectedRecipientsIds);

  const addSelectedRecipientsToEditingRecipientIds = () => {
    dispatch(setEditingRecipientsIds(selectedRecipientsIds));
  };

  const removeSelectedRecipients = () => {
    if (selectedRecipientsIds.length > 0) {
      dispatch(removeRecipientsFromSendingRequested(selectedRecipientsIds));
    }
  };

  return (
    <div className="audience-table-tab">
      <AudienceTableActionBar
        selectedRecipientsOnPage={recipients.filter((recipient) => selectedRecipientsIds.includes(recipient.id))}
        selectedRecipientsCount={selectedRecipientsIds.length}
        addSelectedRecipientsToEditingRecipientIds={addSelectedRecipientsToEditingRecipientIds}
        disabled={disabled}
      />
      <AudienceTable disabled={disabled} />
      <AudienceTablePaging />
      <DeleteRecipientDialog removeSelectedRecipients={removeSelectedRecipients} />
    </div>
  );
};
