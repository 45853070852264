import { Button, EditIcon } from '@fluentui/react-northstar';
import { printData, useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';
import {
  AddressInfo,
  goToStep,
  CreateCompanyWizardStep,
  GeneralInfo,
  CreateCompanyFooter,
  CreateCompanySteps,
  createCompany,
  selectIsFinishButtonDisabled,
  setIsFinishButtonDisabled,
  addCompanyRequested
} from 'app/pages/my-audience/wizard-create-company';
import {
  selectAddressInfoToAdd,
  selectGeneralInfoToAdd,
  closeCreateCompanyWizard,
  goToPreviousStep,
  selectCollaborationDetails
} from 'app/pages/my-audience/wizard-create-company';
import { ReviewCollaborations } from 'app/pages/my-audience/wizard-create-media-outlet';

export const FinishCreateCompany = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const collaborationsDetails = useAppSelector<CollaborationWithContactInfo[]>(selectCollaborationDetails);
  const generalInfo = useAppSelector<GeneralInfo>(selectGeneralInfoToAdd);
  const addressInfo = useAppSelector<AddressInfo>(selectAddressInfoToAdd);
  const isFinishButtonDisabled = useAppSelector<boolean>(selectIsFinishButtonDisabled);

  const handleEditClick = (step: CreateCompanyWizardStep) => {
    dispatch(goToStep(step));
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };
  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };

  const handleFinishButtonClick = () => {
    const companyToAdd = createCompany({ generalInfo, addressInfo });
    dispatch(setIsFinishButtonDisabled(true));
    dispatch(addCompanyRequested(companyToAdd));
  };

  return (
    <>
      <CreateCompanySteps isStepValid={true} />
      <div className="wizard-panel-finish-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-company.additional-finish-title' })} description="" />
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-main-intro">
              <h6 className="wizard-panel-finish-title">
                <FormattedMessage id={'add-menu-create-company.additional-finish-info'} />
              </h6>
              <Button
                icon={<EditIcon />}
                text
                content={formatMessage({ id: 'buttons.edit' })}
                onClick={() => handleEditClick(CreateCompanyWizardStep.GeneralInfo)}
              />
            </div>
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.wizard-name-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(generalInfo.name)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.wizard-parent-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(generalInfo.parentCompany)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.wizard-comment-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(generalInfo.comment)}</span>
              </div>
            </div>
          </div>
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-main-intro">
              <h6 className="wizard-panel-finish-title">
                <FormattedMessage id={'add-menu-create-company.additional-finish-address'} />
              </h6>
              <Button
                icon={<EditIcon />}
                text
                content={formatMessage({ id: 'buttons.edit' })}
                onClick={() => handleEditClick(CreateCompanyWizardStep.AddressInfo)}
              />
            </div>
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.address-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.address)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.po-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.poBox)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.postal-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.postalCode)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.city-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.city)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.country-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.country)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.website-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.website)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.email-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.email)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.phone-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.phoneNumber)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'add-menu-create-company.fax-label'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfo.fax)}</span>
              </div>
            </div>
          </div>
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-main-intro">
              <h6 className="wizard-panel-finish-title">
                <FormattedMessage id={'add-menu-create-company.additional-finish-collaboration'} />
              </h6>
              <Button
                icon={<EditIcon />}
                text
                content={formatMessage({ id: 'buttons.edit' })}
                onClick={() => handleEditClick(CreateCompanyWizardStep.AdditionalInfo)}
              />
            </div>
            <div className="wizard-panel-finish-main-content">
              <div className="profile-panel-content">
                {collaborationsDetails.map((details) => (
                  <div key={details.contactId}>
                    <ReviewCollaborations collaborationDetails={details} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <CreateCompanyFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onFinishButtonClick={handleFinishButtonClick}
          isNextButtonDisabled={false}
          isFinishButtonDisabled={isFinishButtonDisabled}
        />
      </div>
    </>
  );
};
