import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Checkbox, Icon } from '@fluentui/react';
import { Button, MenuButton } from '@fluentui/react-northstar';
import { getNames } from 'country-list';
import {
  useAppSelector,
  regEmail,
  regNumbers,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  useAppDispatch,
  selectCompanyJobTitles,
  addJobTitleRequested,
  regPhoneNumber,
  isPhoneNumberValidOrEmpty
} from 'app/common';
import { ControlledCreateDropdown, ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { Collaboration, JobTitle, CollaborationWithContactInfo, MediumType } from 'app/pages/my-audience/contacts';
import { CollaborationForm, setCollaboration } from 'app/pages/my-audience/wizard-create-company';
import { FormattedMessage, useIntl } from 'app/i18n';

interface CollaborationDetailsFormProps {
  collaborationDetails: CollaborationWithContactInfo;
  removeContact: (contactId: string) => void;
  updateCollaboration: (areFieldsValid: boolean, contactId: string, collaboration: Collaboration, isUsingDifferentAddress: boolean) => void;
}

export const CollaborationDetailsForm = ({ collaborationDetails, removeContact, updateCollaboration }: CollaborationDetailsFormProps) => {
  const dispatch = useAppDispatch();
  const jobTitles = useAppSelector<JobTitle[]>(selectCompanyJobTitles);
  const { formatMessage } = useIntl();
  const [isUpdatingCollaboration, setIsUpdatingCollaboration] = useState(false);
  const [isUsingDifferentAddress, setIsUsingDifferentAddress] = useState(collaborationDetails.useDifferentAddress);

  const { control, watch } = useForm<CollaborationForm, any>({
    defaultValues: {
      jobTitle: collaborationDetails.collaboration.jobTitle?.name,
      address: collaborationDetails.collaboration.address,
      city: collaborationDetails.collaboration.city,
      country: collaborationDetails.collaboration.country,
      emailAddress: collaborationDetails.collaboration.emailAddress,
      jobDescription: collaborationDetails.collaboration.jobDescription,
      landlinePhoneNumber: collaborationDetails.collaboration.landlinePhoneNumber?.value,
      mobilePhoneNumber: collaborationDetails.collaboration.mobilePhoneNumber?.value,
      postalCode: collaborationDetails.collaboration.postalCode
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  watch((data) => handleFieldsUpdate(data));

  const handleFieldsUpdate = (data) => {
    const newCollaboration: Collaboration = setCollaboration(data, jobTitles);

    updateCollaboration(handleFieldsValidation(), collaborationDetails.contactId, newCollaboration, isUsingDifferentAddress);
  };

  const actionItem = [
    {
      key: 'remove-contact-collaboration',
      onClick: () => removeContact(collaborationDetails.contactId),
      content: (
        <span className="custom-accordion-head-actions-item">
          <Icon iconName="Delete" />
          <span className="custom-accordion-head-actions-item-text">
            <FormattedMessage id={'buttons.remove'} />
          </span>
        </span>
      )
    }
  ];

  const handleFieldsValidation = () =>
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('landlinePhoneNumber')) &&
    isPhoneNumberValidOrEmpty(watch('mobilePhoneNumber'));

  const toggleUpdateAddressInfoForm = () => {
    setIsUpdatingCollaboration((state) => !state);
  };

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', MediumType.Company);

    dispatch(addJobTitleRequested(jobTitle));
  };

  return (
    <>
      <div className="custom-accordion" key="sending-overview-title" onClick={toggleUpdateAddressInfoForm}>
        <div className="custom-accordion-head">
          <div className="custom-accordion-head-content">
            <span className="custom-accordion-head-icon">
              <Icon iconName="ChevronDown" className={isUpdatingCollaboration && ' expanded'} />
            </span>
            <span className="custom-accordion-head-icon-title">{`${collaborationDetails.firstName} ${collaborationDetails.lastName}`}</span>
          </div>
          <div className="custom-accordion-head-actions">
            <MenuButton
              trigger={<Button onClick={(e) => e.stopPropagation()} text iconOnly icon={<Icon iconName="MoreVertical" />} />}
              menu={actionItem}
              on="click"
            />
          </div>
        </div>
      </div>
      {isUpdatingCollaboration && (
        <>
          <div className="wizard-panel-form">
            <div className="custom-dropdown">
              <ControlledCreateDropdown
                label={
                  <span className="dropdown-label">
                    <FormattedMessage id={'add-menu-create-contact.additional-job-title-label'} />
                  </span>
                }
                name="jobTitle"
                items={jobTitles.map((job) => job.name)}
                control={control}
                placeholder={formatMessage({ id: 'add-menu-create-contact.additional-job-title-placeholder' })}
                defaultValue={collaborationDetails.collaboration.jobTitle?.name}
                createNewEntityMessage={formatMessage({ id: 'aria-labels.create-job-title' })}
                onCreateNewEntityClick={handleCreateNewJobTitle}
              />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput label={formatMessage({ id: 'add-menu-create-company.additional-job-desc-label' })} control={control} name="jobDescription" />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput
                label={formatMessage({ id: 'add-menu-create-company.additional-phone-label' })}
                control={control}
                rules={{
                  pattern: {
                    value: regPhoneNumber,
                    message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                  }
                }}
                name="landlinePhoneNumber"
              />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput
                label={formatMessage({ id: 'add-menu-create-company.additional-mobile-phone-label' })}
                control={control}
                rules={{
                  pattern: {
                    value: regPhoneNumber,
                    message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                  }
                }}
                name="mobilePhoneNumber"
              />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput
                name="emailAddress"
                label={formatMessage({ id: 'add-menu-create-company.additional-email-label' })}
                control={control}
                rules={{
                  pattern: {
                    value: regEmail,
                    message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                  }
                }}
              />
            </div>
            <div className="wizard-panel-checkbox">
              <Checkbox
                checked={isUsingDifferentAddress}
                onChange={() => setIsUsingDifferentAddress((prevState) => !prevState)}
                label={formatMessage({ id: 'add-menu-create-company.additional-checkout-msg' })}
              />
            </div>
          </div>
          {isUsingDifferentAddress && (
            <div className="wizard-panel-form">
              <div className="wizard-panel-dropdown">
                <ControlledDropdown
                  label={
                    <span className="dropdown-label">
                      <FormattedMessage id={'add-menu-create-company.additional-checkout-country'} />
                    </span>
                  }
                  name="country"
                  items={getNames()}
                  control={control}
                  placeholder={formatMessage({ id: 'add-menu-create-company.additional-checkout-country-placeholder' })}
                  defaultValue={collaborationDetails.collaboration.country}
                />
              </div>
              <div className="wizard-panel-input">
                <ControlledInput label={formatMessage({ id: 'add-menu-create-company.additional-checkout-city' })} control={control} name="city" />
              </div>
              <div className="wizard-panel-input">
                <ControlledInput
                  label={formatMessage({ id: 'add-menu-create-company.additional-checkout-zip' })}
                  control={control}
                  rules={{
                    pattern: {
                      value: regNumbers,
                      message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                    }
                  }}
                  name="postalCode"
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
