import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { Button, Dropdown, Menu } from '@fluentui/react-northstar';
import { Providers } from '@microsoft/mgt-element';

import { useAppDispatch, useAppSelector, selectIsOnDesktop, signOut } from 'app/common';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES, setLocale } from 'app/localization';

import './MainNavigation.scss';
import { useIntl } from 'app/i18n';

export const MainNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isOnDesktop = useAppSelector(selectIsOnDesktop);

  const [isThemeDefault, setIsThemeDefault] = useState(false);

  const myAudienceContent = formatMessage({ id: 'navigation.my-audience-item' });
  const myActivitiesContent = formatMessage({ id: 'navigation.my-activities-item' });

  const mainNavItems = [
    {
      key: 'main-navigation-1',
      content: myAudienceContent,
      icon: <Icon iconName="People" />,
      className: 'main-navigation-item'
    },
    {
      key: 'main-navigation-2',
      icon: <Icon iconName="FrontCamera" />,
      content: myActivitiesContent,
      className: 'main-navigation-item'
    }
  ];

  const onMenuItemClick = (e: SyntheticEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    if (element.innerHTML.includes(myAudienceContent)) navigate('/my-audience');
    else if (element.innerHTML.includes(myActivitiesContent)) navigate('/my-activities/e-mailings');
  };

  const toggleTheme = () => {
    setIsThemeDefault(!isThemeDefault);
    if (isThemeDefault === true) {
      document.documentElement.className = 'theme--default';
    } else {
      document.documentElement.className = 'theme--dark';
    }
  };

  const handleSignOut = () => {
    dispatch(signOut());
    Providers.globalProvider = undefined;
  };

  return (
    <nav className="main-navigation">
      {isOnDesktop && (
        <>
          <Menu defaultActiveIndex={0} items={mainNavItems} onItemClick={(e) => onMenuItemClick(e)} className="main-navigation-list" />

          <div className="main-navigation-actions">
            <Dropdown
              className="main-navigation-localization-button"
              items={SUPPORTED_LOCALES}
              defaultValue={DEFAULT_LOCALE}
              onChange={(_, item) => dispatch(setLocale(item.value.toString()))}
            />
            <Button id="btnSignOut" onClick={handleSignOut} className="main-navigation-button" content={formatMessage({ id: 'navigation.sign-out-label' })} />
            <Button
              className="main-navigation-button"
              onClick={toggleTheme}
              content={isThemeDefault ? formatMessage({ id: 'navigation.light-theme-label' }) : formatMessage({ id: 'navigation.dark-theme-label' })}
            />
          </div>
        </>
      )}
    </nav>
  );
};
