export class MediaResort {
  constructor(public id: string, public name: string, public dataSourceKey: string) {}
}

export enum AddContactWizardStep {
  SearchContact = 1,
  ProfileInformation,
  Finish
}

export enum CreateContactWizardStep {
  CreateNew = 1,
  OwnContactData,
  ProfileInformation,
  Finish
}

export enum EditCollaborationStep {
  editCollaboraton = 1
}

export type AddProfileInformationForm = {
  jobTitle: string;
  jobDescription: string;
  resort: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  emailAddress: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
};

export type AddProfileInformationCreateWizardForm = {
  jobTitle: string;
  jobDescription: string;
  resort: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  emailAddress: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
};

export type AddOwnContactDataForm = {
  address: string;
  city: string;
  postalCode: string;
  country: string;
  emailAddress: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
  blog: string;
  websiteUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  linkedinUrl: string;
  youtubeUrl: string;
  facebookUrl: string;
};

export type EditProfileInformationForm = {
  jobTitle: string;
  jobDescription: string;
  resort: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  emailAddress: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
};
