import { useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import { Collaboration, CollaborationDetails } from 'app/pages/my-audience/contacts';
import {
  CreateContactFooter,
  CreateContactSteps,
  goToNextStep,
  selectCollaborationsToAdd,
  removeCollaborationToAdd,
  AdditionalInformationForm,
  updateContactCollaboration,
  setAreAdditionalInfoValid,
  selectAreAdditionalInfoValid
} from 'app/pages/my-audience/wizard-create-contact';

export const AdditionalContactInformation = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const areFieldsValid = useAppSelector<boolean>(selectAreAdditionalInfoValid);

  const collaborationsToAdd = useAppSelector<CollaborationDetails[]>(selectCollaborationsToAdd);

  const removeCollaboration = (mediumId: string) => {
    dispatch(removeCollaborationToAdd(mediumId));
  };

  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  const handleUpdateCollaboration = (areFieldsValid: boolean, collaboration: Collaboration, useDifferentAddress: boolean) => {
    dispatch(setAreAdditionalInfoValid(areFieldsValid));
    dispatch(updateContactCollaboration({ mediumId: collaboration.medium.id, collaboration: { collaboration, useDifferentAddress } }));
  };

  return (
    <>
      <CreateContactSteps isStepValid={areFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.additional-header-title' })} description="" />
        <div className="wizard-panel-accordions-wrap">
          <div className="wizard-accordion-wrap">
            {collaborationsToAdd.map((collaborationDetails) => (
              <AdditionalInformationForm
                collaborationDetails={collaborationDetails}
                removeCollaboration={removeCollaboration}
                updateCollaboration={handleUpdateCollaboration}
                key={collaborationDetails.collaboration.medium.id}
              />
            ))}
          </div>
        </div>
        <CreateContactFooter handleNextButtonClick={handleNextButtonClick} />
      </div>
    </>
  );
};
