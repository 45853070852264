import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { maximumListNameLength, regAtLeastOneCharacter, useAppDispatch, useAppSelector } from 'app/common';
import { addListRequested, disableNavigationToNewlyCreatedList, selectShouldNavigateToNewlyCreatedList } from 'app/pages/my-audience/contact-profile';
import { List, selectSearchText } from 'app/pages/my-audience/lists';
import {
  closeCreateListWizard,
  disableFinishButton,
  restartListWizard,
  selectIsCreateListWizardOpened,
  selectIsFinishButtonDisabled,
  selectListAddedId
} from 'app/pages/my-audience/wizard-create-list';
import {
  getAllTagsRequested,
  getSelectedListRequested,
  getSelectedListSendingOverviewsRequested,
  openProfilePanel,
  pageOfListContactsRequested,
  setListContactsQueryParams,
  setPanelActiveIndex,
  setSelectedContacts
} from 'app/pages/my-audience/lists-profile';
import { ControlledInput } from 'app/pages/my-audience/common';
import { FormattedMessage, useIntl } from 'app/i18n';

export type CreateListForm = {
  name: string;
};

export const AddListDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isCreateListWizardOpened = useAppSelector<boolean>(selectIsCreateListWizardOpened);
  const listAddedId = useAppSelector<string>(selectListAddedId);
  const searchText = useAppSelector<string>(selectSearchText);
  const isFinishButtonDisabled = useAppSelector<boolean>(selectIsFinishButtonDisabled);
  const shouldNavigateToNewlyCreatedList = useAppSelector<boolean>(selectShouldNavigateToNewlyCreatedList);

  const navigate = useNavigate();

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'add-menu-create-list.header-title' })
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const closeAddListDialog = () => {
    dispatch(restartListWizard());
    dispatch(closeCreateListWizard());
    reset();
  };

  const { handleSubmit, control, formState, reset } = useForm<CreateListForm, any>({
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const onCreateList = () => {
    handleSubmit(
      (data) => {
        dispatch(addListRequested({ name: data.name } as List));
        dispatch(disableFinishButton());
        closeAddListDialog();
      },
      (error) => {
        Sentry.setExtra('Path', AddListDialog.name);
        Sentry.captureException(error);
      }
    )();
  };

  const navigateToListProfile = () => {
    dispatch(setPanelActiveIndex(0));
    dispatch(openProfilePanel());
    dispatch(getSelectedListRequested(listAddedId));
    dispatch(getAllTagsRequested());
    dispatch(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
    dispatch(pageOfListContactsRequested());
    dispatch(setSelectedContacts([]));
    dispatch(getSelectedListSendingOverviewsRequested());
    dispatch(disableNavigationToNewlyCreatedList());

    navigate(`lists/results/${searchText === undefined || searchText === '' ? '*' : searchText}/list-info/${listAddedId}/profile`);
  };

  if (shouldNavigateToNewlyCreatedList) {
    navigateToListProfile();
  }

  return (
    <div className="profile-panel-media-wrap">
      <Dialog hidden={!isCreateListWizardOpened} onDismiss={closeAddListDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
        <div className="wizard-panel-content">
          <ControlledInput
            label={
              <span className="required-label">
                <FormattedMessage id={'add-menu-create-list.name-label'} />
                <span>*</span>
              </span>
            }
            control={control}
            name="name"
            rules={{
              pattern: {
                value: regAtLeastOneCharacter,
                message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: formatMessage({ id: 'add-menu-create-list.list-name-label' }) })
              },
              maxLength: {
                message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumListNameLength }),
                value: maximumListNameLength
              },
              required: formatMessage({ id: 'error-messages.required-name' }, { entity: formatMessage({ id: 'add-menu-create-list.list-label' }) })
            }}
          />
        </div>
        <div className="profile-photo-dialog">
          <DialogFooter>
            <Button primary content={formatMessage({ id: 'buttons.create' })} disabled={!formState.isValid || isFinishButtonDisabled} onClick={onCreateList} />
            <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={closeAddListDialog} />
          </DialogFooter>
        </div>
      </Dialog>
    </div>
  );
};
