import { Button } from '@fluentui/react-northstar';
import {
  fieldContainsNonEmptyValue,
  hideUndoAlert,
  isTextInputValid,
  maximumContactLastNameLength,
  EditableDropdownField,
  EditableFieldWithStar,
  EditableInputTextField,
  selectAllJobTitles,
  selectIsUndoAlertVisible,
  showUndoAlert,
  undoQueue,
  useAppDispatch,
  useAppSelector,
  addJobTitleRequested,
  isPhoneNumberValidOrEmpty
} from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AboutIntro,
  AboutMoreFields,
  ContactUpdateModel,
  resetSelectedContactAfterUndo,
  selectAreMoreEditableFieldsVisible,
  selectIsChoosingPhoneNumberDefaultState,
  selectSelectedContact,
  selectSelectedContactData,
  setIsChoosingPhoneNumberDefaultState,
  showMoreEditableFields,
  updateCollaborationInStore,
  updateCollaborationRequested,
  updateContactInStore,
  updateContactRequested,
  updateOwnContactDataInStore,
  updateOwnContactDataRequested
} from 'app/pages/my-audience/contact-profile';
import {
  Collaboration,
  Contact,
  ContactDataType,
  JobTitle,
  MediumType,
  OwnContactData,
  PhoneNumber,
  undoUpdateContactInTable,
  updateCollaborationInTable,
  updateContactInTable
} from 'app/pages/my-audience/contacts';
import { Permission, useAuthContext } from 'auth';

export const About = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions, hasPermission } = useAuthContext();

  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const selectedContactData = useAppSelector<Collaboration | OwnContactData>(selectSelectedContactData);
  const allJobTitles = useAppSelector<JobTitle[]>(selectAllJobTitles);
  const areMoreEditableFieldsVisible = useAppSelector(selectAreMoreEditableFieldsVisible);
  const isChoosingPhoneNumberDefaultState = useAppSelector<boolean>(selectIsChoosingPhoneNumberDefaultState);

  const isUndoAlertVisible = useAppSelector<boolean>(selectIsUndoAlertVisible);

  const hasPermissionToEditContactAndCompany = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);
  const hasPermissionToEditMediaOutlet = hasPermission(Permission.ManageMediaOutlets);

  const hasPermissionToEditSelectedContactData =
    selectedContactData?.type === ContactDataType.Collaboration && (selectedContactData as Collaboration).medium.type === MediumType.MediaOutlet
      ? hasPermissionToEditMediaOutlet
      : hasPermissionToEditContactAndCompany;

  const onSaveContactChanges = (fieldName: string, updatedValue: string) => {
    const updatedContact = { ...selectedContact, [fieldName]: updatedValue } as Contact;

    const contactUpdateModel = new ContactUpdateModel(
      updatedContact.salutation,
      updatedContact.firstName,
      updatedContact.lastName,
      updatedContact.isGloballySignedOut
    );

    dispatch(updateContactInStore(contactUpdateModel));
    dispatch(updateContactInTable(updatedContact));

    undoQueue.waitAndExecute({
      executeAction: () => {
        dispatch(updateContactRequested(contactUpdateModel));
        dispatch(hideUndoAlert());
      },
      undoAction: () => {
        dispatch(resetSelectedContactAfterUndo());
        dispatch(undoUpdateContactInTable());
      }
    });

    dispatch(showUndoAlert(formatMessage({ id: 'alert-messages.contact-updated' })));
  };

  const onSaveContactDataChanges = (name: string, updatedValue: string) => {
    const updatedContactData: Collaboration | OwnContactData = {
      ...selectedContactData,
      [name]:
        name === 'landlinePhoneNumber' || name === 'mobilePhoneNumber' ? new PhoneNumber(updatedValue, selectedContactData?.[name]?.isPrimary) : updatedValue
    };

    if (selectedContactData.type === ContactDataType.Collaboration) {
      updateCollaboration(updatedContactData as Collaboration);
    } else {
      updateOwnContactData(updatedContactData as OwnContactData);
    }

    dispatch(showUndoAlert(formatMessage({ id: 'alert-messages.contact-updated' })));
  };

  const onSaveJobTitleChanges = (name: string, updatedValue: string) => {
    const selectedJobTitle = allJobTitles.find((jobTitle) => jobTitle.name === updatedValue);
    const updatedContactData = { ...selectedContactData, [name]: selectedJobTitle };

    updateCollaboration(updatedContactData as Collaboration);
    dispatch(showUndoAlert(formatMessage({ id: 'alert-messages.contact-updated' })));
  };

  const updateOwnContactData = (selectedContactDataLocal: OwnContactData) => {
    dispatch(updateOwnContactDataInStore(selectedContactDataLocal));

    undoQueue.waitAndExecute({
      executeAction: () => {
        dispatch(updateOwnContactDataRequested(selectedContactDataLocal));
        dispatch(hideUndoAlert());
      },
      undoAction: () => {
        dispatch(resetSelectedContactAfterUndo());
        dispatch(undoUpdateContactInTable());
      }
    });
  };

  const updateCollaboration = (updatedContactData: Collaboration) => {
    dispatch(updateCollaborationInStore(updatedContactData));
    dispatch(updateCollaborationInTable({ contactId: selectedContact.id, collaboration: updatedContactData }));

    undoQueue.waitAndExecute({
      executeAction: () => {
        dispatch(updateCollaborationRequested(updatedContactData));
        dispatch(hideUndoAlert());
      },
      undoAction: () => {
        dispatch(resetSelectedContactAfterUndo());
        dispatch(undoUpdateContactInTable());
      }
    });
  };

  const handleSetNumberAsFavourite = (name: string, newValue: boolean) => {
    if (!newValue) {
      removeDefaultNumber(selectedContactData);
      return;
    }

    const updatedContactData = {
      ...selectedContactData,
      landlinePhoneNumber: new PhoneNumber(selectedContactData.landlinePhoneNumber.value, name === 'landlinePhoneNumber'),
      mobilePhoneNumber: new PhoneNumber(selectedContactData.mobilePhoneNumber.value, name === 'mobilePhoneNumber')
    };

    selectedContactData.type === ContactDataType.OwnContactData
      ? dispatch(updateOwnContactDataRequested(updatedContactData as OwnContactData))
      : dispatch(updateCollaborationRequested(updatedContactData as Collaboration));

    dispatch(setIsChoosingPhoneNumberDefaultState(true));
  };

  const removeDefaultNumber = (contactData: Collaboration | OwnContactData) => {
    const updatedContactData = {
      ...contactData,
      landlinePhoneNumber: new PhoneNumber(contactData.landlinePhoneNumber.value, false),
      mobilePhoneNumber: new PhoneNumber(contactData.mobilePhoneNumber.value, false)
    };

    if (contactData.type === ContactDataType.Collaboration) {
      dispatch(updateCollaborationRequested(updatedContactData as Collaboration));
    } else {
      dispatch(updateOwnContactDataRequested(updatedContactData as OwnContactData));
    }

    dispatch(setIsChoosingPhoneNumberDefaultState(true));
  };

  const handleShowMoreButtonClick = () => {
    dispatch(showMoreEditableFields());
  };

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', (selectedContactData as Collaboration).medium.type);

    dispatch(addJobTitleRequested(jobTitle));
  };

  return (
    <div className="editable-fields-wrap">
      <div className="editable-fields-content">
        <AboutIntro disabled={!hasPermissionToEditSelectedContactData} />
        <EditableInputTextField
          value={selectedContact.salutation}
          fieldName="salutation"
          onChange={onSaveContactChanges}
          label={formatMessage({ id: 'contact-data-labels.salutation' })}
          isLink={false}
          disabled={!hasPermissionToEditContactAndCompany}
        />
        <EditableInputTextField
          value={selectedContact.firstName}
          fieldName="firstName"
          onChange={onSaveContactChanges}
          label={formatMessage({ id: 'contact-data-labels.first-name' })}
          isLink={false}
          disabled={!hasPermissionToEditContactAndCompany}
        />
        <EditableInputTextField
          value={selectedContact.lastName}
          fieldName="lastName"
          onChange={onSaveContactChanges}
          label={formatMessage({ id: 'contact-data-labels.last-name' })}
          isLink={false}
          isFieldValid={(value: string) => isTextInputValid({ inputValue: value, maxInputLength: maximumContactLastNameLength })}
          disabled={!hasPermissionToEditContactAndCompany}
        />
        {selectedContactData?.type === ContactDataType.Collaboration && (
          <EditableDropdownField
            value={(selectedContactData as Collaboration)?.jobTitle?.name}
            fieldName="jobTitle"
            suggestions={allJobTitles
              .filter((jobTitle) => jobTitle.mediumType === (selectedContactData as Collaboration)?.medium?.type)
              .map((jobTitle) => jobTitle.name)}
            onSaveChanges={onSaveJobTitleChanges}
            label={formatMessage({ id: 'contact-data-labels.role' })}
            disabled={!hasPermissionToEditSelectedContactData}
            createNewEntityMessage={formatMessage({ id: 'aria-labels.create-job-title' })}
            onCreateNew={handleCreateNewJobTitle}
          />
        )}
        <EditableFieldWithStar
          value={selectedContactData?.landlinePhoneNumber?.value}
          fieldName="landlinePhoneNumber"
          onChange={onSaveContactDataChanges}
          label={formatMessage({ id: 'contact-data-labels.tel' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-phone-number' })}
          isLink={fieldContainsNonEmptyValue(selectedContactData?.landlinePhoneNumber?.value)}
          isPrimary={selectedContactData?.landlinePhoneNumber?.isPrimary}
          onChangePriority={handleSetNumberAsFavourite}
          isFieldValid={isPhoneNumberValidOrEmpty}
          disabled={!hasPermissionToEditSelectedContactData || isUndoAlertVisible || isChoosingPhoneNumberDefaultState}
        />
        {!areMoreEditableFieldsVisible ? (
          <div className="editable-fields-button">
            <Button onClick={handleShowMoreButtonClick} text content={formatMessage({ id: 'buttons.show-more' })} />
          </div>
        ) : (
          <AboutMoreFields disabled={!hasPermissionToEditSelectedContactData} />
        )}
      </div>
    </div>
  );
};
