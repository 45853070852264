import 'app/common/components/change-photo-dialog/ChangePhotoDialog.scss';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import _ from 'lodash';
import { Avatar, Button, FilesUploadIcon } from '@fluentui/react-northstar';
import { Dialog, DialogFooter, DialogType, Icon } from '@fluentui/react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ProfilePicture, getInitials } from 'app/common';
import { RootState } from 'app/redux/store';
import { FormattedMessage, useIntl } from 'app/i18n';

interface ChangePhotoDialogProps {
  selectImageToUpload: (state: RootState) => ProfilePicture;
  selectProfilePicturePreviewUrl: (state: RootState) => string;
  selectIsDialogOpened: (state: RootState) => boolean;
  name: string;
  changeProfilePictureToUpload: ActionCreatorWithPayload<any, string>;
  removeProfilePictureRequested: ActionCreatorWithoutPayload<string>;
  updateProfilePictureRequested: ActionCreatorWithPayload<any, string>;
  removeProfilePicturePreview: ActionCreatorWithoutPayload<string>;
  closeDialog: ActionCreatorWithoutPayload<string>;
}

export const ChangePhotoDialog = ({
  selectImageToUpload,
  selectProfilePicturePreviewUrl,
  selectIsDialogOpened,
  name,
  changeProfilePictureToUpload,
  removeProfilePictureRequested,
  updateProfilePictureRequested,
  removeProfilePicturePreview,
  closeDialog
}: ChangePhotoDialogProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const imageToUpload = useAppSelector<ProfilePicture>(selectImageToUpload);
  const profilePicturePreviewUrl = useAppSelector<string>(selectProfilePicturePreviewUrl);
  const [imageList, setImageList] = useState([] as ImageListType);
  const isChangeImageDialogOpened = useAppSelector<boolean>(selectIsDialogOpened);

  const [profilePictureData, setProfilePictureData] = useState('');
  const profilePictureUrl = useRef<string>();

  useEffect(() => {
    profilePictureUrl.current = profilePicturePreviewUrl;
  }, [isChangeImageDialogOpened]);

  const newFileSelected = (imageList: ImageListType) => {
    if (!imageList[0]) {
      return;
    }

    setProfilePictureData(imageList[0].data_url);
    setImageList(imageList);
    const fileExtension = imageList[0].file.name.split('.').pop();

    dispatch(changeProfilePictureToUpload(new ProfilePicture(imageList[0].data_url, fileExtension, '')));
    setImageList([]);
  };

  const handleOnChange = () => {
    if (!profilePictureData && !profilePictureUrl.current) {
      handleClickDismissButton();
      return;
    }

    if (!profilePictureData && profilePictureUrl.current) {
      dispatch(removeProfilePictureRequested());
      handleClickDismissButton();
      return;
    }

    const imageDataParts = profilePictureData.split(',');
    const data = imageDataParts[1];
    const mimeType = _.trimEnd(imageDataParts[0].split(':')[1], ';base64');

    const profilePicture = new ProfilePicture(data, imageToUpload.fileExtension, mimeType);
    dispatch(updateProfilePictureRequested(profilePicture));

    handleClickDismissButton();
  };

  const removeProfilePicture = () => {
    dispatch(removeProfilePicturePreview());
    setProfilePictureData('');
  };

  const handleClickDismissButton = () => {
    profilePictureUrl.current = '';
    dispatch(closeDialog());
    dispatch(changeProfilePictureToUpload(new ProfilePicture('', '', '')));
  };

  return (
    <Dialog
      hidden={!isChangeImageDialogOpened}
      onDismiss={handleClickDismissButton}
      dialogContentProps={{ type: DialogType.normal, title: formatMessage({ id: 'change-profile-picture-dialog.title' }) }}
      modalProps={{ className: 'profile-image-dialog-wrap', isBlocking: true }}
    >
      <div className="profile-image-dialog">
        <div className="profile-image-dialog-content">
          <div className="profile-image-dialog-actions">
            <div className="profile-image-dialog-upload">
              <ImageUploading multiple value={imageList} onChange={newFileSelected} maxNumber={1} dataURLKey="data_url">
                {({ onImageUpload, dragProps }) => (
                  <div className="upload__image-wrapper">
                    <button className="profile-image-dialog-upload-button" onClick={onImageUpload} {...dragProps}>
                      <FilesUploadIcon />
                      <span className="profile-image-dialog-upload-name">
                        <FormattedMessage id={'change-profile-picture-dialog.upload-photo-button'} />
                      </span>
                    </button>
                  </div>
                )}
              </ImageUploading>
            </div>
            <div className="profile-image-dialog-upload-remove" onClick={removeProfilePicture}>
              <button type="button" className="profile-image-dialog-remove-button">
                <Icon iconName="Delete" />
                <span className="profile-image-dialog-remove-name">
                  <FormattedMessage id={'change-profile-picture-dialog.remove-photo-button'} />
                </span>
              </button>
            </div>
          </div>
          <div className="profile-image-dialog-photo-edit">
            {imageToUpload.data || profilePicturePreviewUrl ? (
              <Avatar className="profile-image-dialog-photo-avatar" image={imageToUpload.data ? imageToUpload.data : profilePicturePreviewUrl} />
            ) : (
              <Avatar className="profile-image-dialog-photo-avatar" name={name} getInitials={getInitials} />
            )}
          </div>
        </div>
        <DialogFooter>
          <Button content={formatMessage({ id: 'buttons.close' })} onClick={handleClickDismissButton} />
          <Button primary content={formatMessage({ id: 'buttons.save' })} onClick={handleOnChange} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
