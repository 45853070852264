import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import {
  CreateContactWizardSteps,
  goToStepCreateContact,
  selectContactToAdd,
  selectCreateContactCurrentStep,
  selectOwnContactDataToAdd
} from 'app/pages/my-audience/company-profile';
import { Contact, OwnContactData } from 'app/pages/my-audience/contacts';

interface CreateContactStepsProps {
  areFieldsValid?: boolean;
}

export const CreateContactSteps = ({ areFieldsValid = true }: CreateContactStepsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const contactToAdd = useAppSelector<Contact>(selectContactToAdd);
  const ownContactToAdd = useAppSelector<OwnContactData>(selectOwnContactDataToAdd);

  const isContactEmpty = Object.keys(contactToAdd).length === 0;
  const isOwnContactDataEmpty = Object.keys(ownContactToAdd).length === 0;

  const step = useAppSelector<CreateContactWizardSteps>(selectCreateContactCurrentStep);

  const styleBreadcrumbStatus = (stepToCompareWith: CreateContactWizardSteps): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: CreateContactWizardSteps) => {
    dispatch(goToStepCreateContact(step));
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactWizardSteps.CreateNew)}
            content={formatMessage({ id: 'companies-create-contact.wizard-step-create' })}
            onClick={() => handleStepButtonClick(CreateContactWizardSteps.CreateNew)}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactWizardSteps.OwnContactData)}
            content={formatMessage({ id: 'companies-create-contact.wizard-step-contact-data' })}
            onClick={() => handleStepButtonClick(CreateContactWizardSteps.OwnContactData)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactWizardSteps.ProfileInformation)}
            content={formatMessage({ id: 'companies-create-contact.wizard-step-profile-info' })}
            onClick={() => handleStepButtonClick(CreateContactWizardSteps.ProfileInformation)}
            disabled={!areFieldsValid || isContactEmpty}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactWizardSteps.Finish)}
            content={formatMessage({ id: 'companies-create-contact.wizard-step-finish' })}
            onClick={() => handleStepButtonClick(CreateContactWizardSteps.Finish)}
            disabled={!areFieldsValid || isOwnContactDataEmpty}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
