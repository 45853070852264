import { PillData, UndoablePills, Tag, useAppDispatch, useAppSelector } from 'app/common';
import { addedTagToCompany, removedTagsFromCompanies, selectCompanyTagsHeaderValue, selectSelectedCompany } from 'app/pages/my-audience/company-profile';
import {
  addNewTagRequested,
  addTagToCompaniesRequested,
  deleteTagsRequested,
  filterTags,
  removeTagFromCompaniesRequested,
  selectFilteredTagsInPanel,
  selectAllTags,
  addedTagToCompaniesSearchResults,
  Company,
  removedTagsFromCompaniesSearchResults
} from 'app/pages/my-audience/companies';
import { useIntl } from 'app/i18n';

interface CompanyTagsProps {
  disabled?: boolean;
}

export const CompanyTags = ({ disabled = false }: CompanyTagsProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const selectedCompany = useAppSelector<Company>(selectSelectedCompany);
  const selectedCompaniesIds = [selectedCompany.id];
  const selectedTags = useAppSelector<Tag[]>(selectCompanyTagsHeaderValue);
  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);

  const onAddNewTag = (text: string) => dispatch(addNewTagRequested({ selectedCompaniesIds, text }));

  const addTagToCompaniesInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addedTagToCompany(tag));
    dispatch(addedTagToCompaniesSearchResults({ selectedCompaniesIds, tag }));
  };

  const removeTagFromCompaniesInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removedTagsFromCompanies([id]));
    dispatch(removedTagsFromCompaniesSearchResults({ selectedCompaniesIds, tags: [tag] }));
  };

  const onAddTagToCompanies = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addTagToCompaniesRequested({ selectedCompaniesIds, tag }));
  };

  const onDeleteTags = (tagIds: string[]) => {
    const tags = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tags));
  };

  const onFilterTags = (text: string) => {
    dispatch(filterTags(text));
  };

  const onRemoveTagFromCompanies = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removeTagFromCompaniesRequested({ selectedCompaniesIds, tag }));
  };

  return (
    <UndoablePills
      entityName={formatMessage({ id: 'labels.tag' })}
      selectedValues={selectedTags?.map((t) => new PillData(t.id, t.name, true))}
      dropdownValues={filteredTags?.map((t) => new PillData(t.id, t.name, true))}
      onAddValue={onAddNewTag}
      onSelectValue={onAddTagToCompanies}
      onDeselectValue={onRemoveTagFromCompanies}
      onDeleteValues={onDeleteTags}
      filterValues={onFilterTags}
      enabledDelete={true}
      onSelectValueInStore={addTagToCompaniesInStore}
      onUndoSelectValueInStore={removeTagFromCompaniesInStore}
      onDeselectValueInStore={removeTagFromCompaniesInStore}
      onUndoDeselectValueInStore={addTagToCompaniesInStore}
      disabled={disabled}
    />
  );
};
