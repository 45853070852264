import { EntitiesPerPage, printData } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { ContactInput } from 'app/common/graphql/generated/graphql-gateway';
import { CreateContactTableData } from 'app/pages/my-audience/common';
import { Collaboration, Contact, MediumType, MediumTypeName, OwnContactData } from 'app/pages/my-audience/contacts';

export const createContactsTableRows = (contacts: Contact[]): CreateContactTableData[] => {
  return contacts.map((contact) => {
    const primaryCollaboration = contact.collaborations.find((col) => col.isPrimary);
    return new CreateContactTableData(
      contact.id,
      <ProfilePicture name={`${contact.firstName} ${contact.lastName}`} imageUrl={contact.profilePictureUrl} size="small" />,
      `${contact.firstName} ${contact.lastName}`,
      printData(primaryCollaboration?.jobTitle.name),
      primaryCollaboration?.emailAddress,
      primaryCollaboration?.landlinePhoneNumber.isPrimary ? primaryCollaboration?.landlinePhoneNumber.value : primaryCollaboration?.mobilePhoneNumber.value
    );
  });
};

export const createContactWithCollaboration = (contactToAdd: Contact, collaborationToAdd: Collaboration, ownContactDataToAdd: OwnContactData): Contact => {
  return new Contact(
    contactToAdd.id || '',
    contactToAdd.salutation,
    contactToAdd.firstName,
    contactToAdd.lastName,
    '',
    false,
    [],
    [],
    [],
    [
      new Collaboration(
        '',
        collaborationToAdd.medium,
        collaborationToAdd.jobTitle,
        collaborationToAdd.jobDescription,
        collaborationToAdd.address,
        collaborationToAdd.country,
        collaborationToAdd.landlinePhoneNumber,
        collaborationToAdd.mobilePhoneNumber,
        collaborationToAdd.emailAddress,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        true,
        collaborationToAdd.city,
        collaborationToAdd.postalCode
      )
    ],
    [],
    [],
    [],
    { ...ownContactDataToAdd, isPrimary: false }
  );
};

export const createContactInput = (contact: Contact): ContactInput => {
  return {
    id: contact.id,
    salutation: contact.salutation,
    firstName: contact.firstName,
    lastName: contact.lastName,
    profilePictureUrl: contact.profilePictureUrl,
    isGloballySignedOut: contact.isGloballySignedOut,
    tags: [],
    topics: [],
    mediaResorts: [],
    ownContactData: {
      address: contact.ownContactData?.address,
      blogUrl: contact.ownContactData?.blogUrl,
      city: contact.ownContactData?.city,
      country: contact.ownContactData?.country,
      emailAddress: contact.ownContactData?.emailAddress,
      facebookProfileUrl: contact.ownContactData?.facebookProfileUrl,
      fax: contact.ownContactData?.fax,
      instagramProfileUrl: contact.ownContactData?.instagramProfileUrl,
      isPrimary: contact.ownContactData?.isPrimary,
      landlinePhoneNumber: contact.ownContactData?.landlinePhoneNumber?.value
        ? {
            value: contact.ownContactData?.landlinePhoneNumber.value,
            isPrimary: contact.ownContactData?.landlinePhoneNumber.isPrimary
          }
        : undefined,
      mobilePhoneNumber: contact.ownContactData?.mobilePhoneNumber?.value
        ? {
            value: contact.ownContactData?.mobilePhoneNumber.value,
            isPrimary: contact.ownContactData?.mobilePhoneNumber.isPrimary
          }
        : undefined,
      linkedInProfileUrl: contact.ownContactData?.linkedInProfileUrl,
      postalCode: contact.ownContactData?.postalCode,
      twitterProfileUrl: contact.ownContactData?.twitterProfileUrl,
      websiteUrl: contact.ownContactData?.websiteUrl,
      youtubeProfileUrl: contact.ownContactData?.youtubeProfileUrl
    },
    collaborations: contact.collaborations?.length
      ? contact.collaborations.map((_, i) => ({
          jobTitle: contact.collaborations[i].jobTitle?.id
            ? {
                id: contact.collaborations[i].jobTitle?.id,
                dataSourceKey: contact.collaborations[i].jobTitle?.dataSourceKey,
                name: contact.collaborations[i].jobTitle?.name,
                mediumType: {
                  name: MediumTypeName(contact.collaborations[i].jobTitle?.mediumType),
                  value: contact.collaborations[i].jobTitle?.mediumType
                }
              }
            : undefined,
          medium: {
            id: contact.collaborations[i].medium.id,
            name: contact.collaborations[i].medium.name,
            type: {
              name: MediumType[contact.collaborations[i].medium.type],
              value: contact.collaborations[i].medium.type
            }
          },
          address: contact.collaborations[i].address,
          country: contact.collaborations[i].country,
          landlinePhoneNumber: contact.collaborations[i].landlinePhoneNumber?.value
            ? {
                value: contact.collaborations[i].landlinePhoneNumber.value,
                isPrimary: contact.collaborations[i].landlinePhoneNumber.isPrimary
              }
            : undefined,
          mobilePhoneNumber: contact.collaborations[i].mobilePhoneNumber?.value
            ? {
                value: contact.collaborations[i].mobilePhoneNumber.value,
                isPrimary: contact.collaborations[i].mobilePhoneNumber.isPrimary
              }
            : undefined,
          emailAddress: contact.collaborations[i].emailAddress,
          blogUrl: contact.collaborations[i].blogUrl,
          websiteUrl: contact.collaborations[i].websiteUrl,
          twitterProfileUrl: contact.collaborations[i].twitterProfileUrl,
          instagramProfileUrl: contact.collaborations[i].instagramProfileUrl,
          linkedInProfileUrl: contact.collaborations[i].linkedInProfileUrl,
          youtubeProfileUrl: contact.collaborations[i].youtubeProfileUrl,
          facebookProfileUrl: contact.collaborations[i].facebookProfileUrl,
          isPrimary: contact.collaborations[i].isPrimary,
          jobDescription: contact.collaborations[i].jobDescription,
          city: contact.collaborations[i].city,
          postalCode: contact.collaborations[i].postalCode
        }))
      : [],
    comments: [],
    blacklists: [],
    preferredLanguages: []
  };
};

export const mapEntitiesPerPageToList = <T,>(selectedContactsPerPage: EntitiesPerPage<T>) => {
  const entitiesPerPageValues: T[][] = Object.values(selectedContactsPerPage);
  const entitiesToAdd = entitiesPerPageValues.reduce((agg: T[], current: T[]) => [...agg, ...current], []);
  return entitiesToAdd.filter((entity: T, index: number) => entitiesToAdd.indexOf(entity) === index);
};
