import { isStringEmpty, printData, useAppDispatch, useAppSelector, WizardFinishIntro, WizardIntro } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { selectLocalizedAudiences, selectLocalizedFrequencies } from 'app/localization';
import {
  goToStepCreateMedia,
  MediaOutletAddressInfo,
  MediaOutletGeneralInfo,
  MediaOutletCollaborationProfileInfo,
  selectMediaOutletAddressInfoToAdd,
  selectMediaOutletGeneralInfoToAdd,
  selectCollaborationInfoToAdd
} from 'app/pages/my-audience/contact-profile';
import { LocalizedListValue } from 'app/pages/my-audience/media-outlets';

export const CreateMediaOutletFinish = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const generalInfoToAdd = useAppSelector<MediaOutletGeneralInfo>(selectMediaOutletGeneralInfoToAdd);
  const addressInfoToAdd = useAppSelector<MediaOutletAddressInfo>(selectMediaOutletAddressInfoToAdd);
  const profileInfoToAdd = useAppSelector<MediaOutletCollaborationProfileInfo>(selectCollaborationInfoToAdd);

  const localizedFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);
  const localizedAudiences = useAppSelector<LocalizedListValue[]>(selectLocalizedAudiences);

  const selectedFrequency = localizedFrequencies.find((f) => f.key === generalInfoToAdd.frequencyKey)?.label;
  const selectedAudience = localizedAudiences.find((f) => f.key === generalInfoToAdd.audienceKey)?.label;

  const handleEditGeneralInfoButtonClick = () => {
    dispatch(goToStepCreateMedia(1));
  };

  const handleEditAddressInfoButtonClick = () => {
    dispatch(goToStepCreateMedia(2));
  };

  const handleEditProfileInfoButtonClick = () => {
    dispatch(goToStepCreateMedia(3));
  };

  return (
    <div className="wizard-panel-finish-wrap">
      <WizardIntro title={formatMessage({ id: 'wizard-finish-step-title' })} description="" />
      <div className="wizard-panel-finish-main-container">
        <div className="wizard-panel-finish-main">
          <div className="wizard-panel-finish-content">
            <WizardFinishIntro
              title={formatMessage({ id: 'add-medium-wizard-steps.general-information' })}
              onEditButtonClick={handleEditGeneralInfoButtonClick}
            />
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'media-outlet-labels.name'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.name)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'media-outlet-labels.description'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.description)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'media-outlet-labels.audiences'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(selectedAudience)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'media-outlet-labels.website'} />
                </span>
                {!isStringEmpty(generalInfoToAdd.websiteUrl) ? (
                  <a href={generalInfoToAdd.websiteUrl} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(generalInfoToAdd.websiteUrl)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.websiteUrl)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'media-outlet-labels.frequency'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(selectedFrequency)}</span>
              </div>
            </div>
          </div>
          <div className="wizard-panel-finish-content">
            <WizardFinishIntro
              title={formatMessage({ id: 'add-medium-wizard-steps.address-information' })}
              onEditButtonClick={handleEditAddressInfoButtonClick}
            />
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.address'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.address)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.p-o-box'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.poBox)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.postal-code'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.postalCode)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.city'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.city)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.country'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.country)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.website'} />
                </span>
                {!isStringEmpty(addressInfoToAdd.websiteUrl) ? (
                  <a href={addressInfoToAdd.websiteUrl} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(addressInfoToAdd.websiteUrl)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.websiteUrl)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.email'} />
                </span>
                {!isStringEmpty(addressInfoToAdd.emailAddress) ? (
                  <a href={'mailto:' + addressInfoToAdd.emailAddress} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(addressInfoToAdd.emailAddress)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.emailAddress)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  {' '}
                  <FormattedMessage id={'medium-address-labels.phone-number'} />
                </span>
                {!isStringEmpty(addressInfoToAdd.phoneNumber) ? (
                  <a href={'tel:+' + addressInfoToAdd.phoneNumber} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(addressInfoToAdd.phoneNumber)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.phoneNumber)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'medium-address-labels.fax'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.fax)}</span>
              </div>
            </div>
          </div>
          <div className="wizard-panel-finish-content">
            <WizardFinishIntro
              title={formatMessage({ id: 'add-medium-wizard-steps.profile-information' })}
              onEditButtonClick={handleEditProfileInfoButtonClick}
            />
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.job-title'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.jobTitle?.name)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.job-description'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.jobDescription)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.resort'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.resort)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.phone-number'} />
                </span>
                {!isStringEmpty(profileInfoToAdd.phoneNumber) ? (
                  <a href={'tel:+' + profileInfoToAdd.phoneNumber} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(profileInfoToAdd.phoneNumber)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.phoneNumber)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.mobile-phone-number'} />
                </span>
                {!isStringEmpty(profileInfoToAdd.mobilePhoneNumber) ? (
                  <a href={'tel:+' + profileInfoToAdd.mobilePhoneNumber} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(profileInfoToAdd.mobilePhoneNumber)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.mobilePhoneNumber)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  {' '}
                  <FormattedMessage id={'contact-data-labels.email-address'} />
                </span>
                {!isStringEmpty(profileInfoToAdd.emailAddress) ? (
                  <a href={'mailto:' + profileInfoToAdd.emailAddress} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(profileInfoToAdd.emailAddress)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.emailAddress)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.address'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.address)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.postal-code'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.postalCode)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.city'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.city)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.country'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.country)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
