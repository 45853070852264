import { Avatar, ShorthandCollection, Table, TableRowProps } from '@fluentui/react-northstar';
import { ShimmerTable, useAppDispatch, useAppSelector, WizardEmptyInfo } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectLocalizedFrequencies } from 'app/localization';
import {
  goToNextStepAddMedia,
  selectIsLoadingMediaOutlets,
  selectMediaOutlets,
  setExistingMediaOutlet,
  selectMediaOutletsTotalCount
} from 'app/pages/my-audience/contact-profile';
import { LocalizedListValue, MediaOutlet } from 'app/pages/my-audience/media-outlets';
import { getInitials } from 'app/common/validation';

export const SearchMediaOutletTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isLoadingMediaOutlets = useAppSelector<boolean>(selectIsLoadingMediaOutlets);
  const mediaOutlets = useAppSelector<MediaOutlet[]>(selectMediaOutlets);
  const frequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);
  const totalCountOfMediaOutlets = useAppSelector<number>(selectMediaOutletsTotalCount);

  const header = {
    items: [
      '',
      formatMessage({ id: 'media-outlet-labels.name' }),
      formatMessage({ id: 'media-outlet-labels.category' }),
      formatMessage({ id: 'media-outlet-labels.frequency' }),
      formatMessage({ id: 'medium-address-labels.postal-code' })
    ]
  };
  const rows: ShorthandCollection<TableRowProps> = mediaOutlets.map((mo, i) => ({
    key: 'mo-wizard-' + mo.name,
    items: [
      { content: <Avatar image={mo.profilePictureUrl} getInitials={getInitials} name={mo.name} /> },
      { content: mo.name, key: 'mo-name-' + mo.name + i },
      { content: mo.category?.name, key: 'mo-categry-mo-' + mo.category?.name + i },
      { content: frequencies.find((f) => f.key === mo.frequencyKey)?.label, key: 'mo-frequency-' + i },
      { content: mo.contactData?.postalCode, key: 'mo-postalCode-' + mo.contactData.postalCode + i }
    ],
    onClick: () => {
      dispatch(setExistingMediaOutlet(mo.id));
      dispatch(goToNextStepAddMedia());
    }
  }));

  return (
    <div className="wizard-panel-table">
      <Table header={header} rows={rows} aria-label="Static table" />
      {isLoadingMediaOutlets && <ShimmerTable />}
      {totalCountOfMediaOutlets === 0 && (
        <WizardEmptyInfo
          message={formatMessage({ id: 'wizard-media-outlets-empty-search.message' })}
          description={formatMessage({ id: 'wizard-media-outlets-empty-search.description' })}
        />
      )}
    </div>
  );
};
