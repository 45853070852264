import Bee from '@mailupinc/bee-plugin';
import { useState } from 'react';
import { Icon } from '@fluentui/react';
import { ArrowDownIcon, Button, MenuButton } from '@fluentui/react-northstar';
import { showAlertNotification, ThreeDotMenuItems, useAppDispatch, useAppSelector } from 'app/common';
import {
  beeFreeJsonBodyHasContent,
  openSaveTemplateDialog,
  openTemplatesPopup,
  selectBeeInstance,
  selectEmailBodyInEditor,
  selectSelectedTemplate,
  setIsReportAIContentModalOpen,
  updateTemplateRequested,
  ReportContent,
  selectIsOpenAiEnabled
} from 'app/pages/my-activities/sending-wizard';
import { Template } from 'app/pages/my-activities/templates';
import { EmailBody } from 'app/pages/my-activities/sendings';
import { useIntl } from 'app/i18n';

export const MenuItems = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [isMenuOpen, toggleMenu] = useState<boolean>();
  const beeInstance = useAppSelector<Bee>(selectBeeInstance);
  const selectedTemplate = useAppSelector<Template>(selectSelectedTemplate);
  const selectedEmailBody = useAppSelector<EmailBody>(selectEmailBodyInEditor);
  const jsonBodyHasContent = beeFreeJsonBodyHasContent(selectedEmailBody.jsonBody);
  const isOpenAiEnabled = useAppSelector<boolean>(selectIsOpenAiEnabled);

  const menuItems: ThreeDotMenuItems[] = [
    {
      key: 'save-template',
      title: formatMessage({ id: 'emailing-wizard-email-step.template-menu-items.save' }),
      className: 'profile-panel-menu-item',
      icon: <Icon className="row-icon-star" iconName="SaveIcon" />,
      onClick: () => onSave(),
      disabled: !jsonBodyHasContent
    },
    {
      key: 'save-template',
      title: formatMessage({ id: 'emailing-wizard-email-step.template-menu-items.save-as' }),
      className: 'profile-panel-menu-item',
      icon: <Icon className="row-icon-star" iconName="SaveIcon" />,
      onClick: () => onSaveAs(),
      disabled: !jsonBodyHasContent
    },
    {
      key: 'load-template',
      title: formatMessage({ id: 'emailing-wizard-email-step.template-menu-items.load-template' }),
      className: 'profile-panel-menu-item',
      icon: <Icon iconName="LoadIcon" />,
      onClick: () => dispatch(openTemplatesPopup()),
      disabled: false
    }
  ];

  const onSave = () => {
    if (selectedTemplate?.id) {
      const updatedTemplate: Template = { ...selectedTemplate, jsonBody: selectedEmailBody.jsonBody, htmlBody: selectedEmailBody.htmlBody };
      dispatch(updateTemplateRequested(updatedTemplate));

      dispatch(
        showAlertNotification(formatMessage({ id: 'emailing-wizard-email-step.saved-template-alert-message' }, { templateTitle: selectedTemplate.title }))
      );
      return;
    }
    onSaveAs();
  };

  const onSaveAs = () => {
    dispatch(openSaveTemplateDialog());
  };

  const onPreview = () => {
    beeInstance.togglePreview();
  };

  const handleReportClick = () => {
    dispatch(setIsReportAIContentModalOpen(true));
  };

  return (
    <>
      <div className="sendings-buttons">
        {isOpenAiEnabled && (
          <div className="ai-content-warning">
            <p>
              <b>{formatMessage({ id: 'emailing-wizard.labels.ai-disclaimer-bold' })}</b> {formatMessage({ id: 'emailing-wizard.labels.ai-disclaimer' })}
            </p>
            <Icon iconName="Info" onClick={handleReportClick} />
          </div>
        )}
        <MenuButton
          open={isMenuOpen}
          onOpenChange={(_, { open }) => toggleMenu(open)}
          trigger={<Button icon={<ArrowDownIcon />} content={formatMessage({ id: 'buttons.template' })} />}
          menu={menuItems.map((menuItem) => ({
            content: (
              <span key={`list-${menuItem.key}`} className={menuItem.className} onClick={menuItem.onClick}>
                {menuItem.icon}
                <span key={`name-${menuItem.key}`} className="profile-panel-menu-name">
                  {menuItem.title}
                </span>
              </span>
            ),
            disabled: menuItem.disabled
          }))}
        />
        <Button content={formatMessage({ id: 'buttons.preview' })} onClick={onPreview} />
      </div>
      <ReportContent />
    </>
  );
};
