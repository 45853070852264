import { SearchCategoryName, SearchSuggestionCategory, SearchSuggestionListItem } from 'app/common';

export type GqlSearchCategoryName = {
  name?: string;
  value?: number;
};

export type SuggestionItem = {
  imageUrl?: string;
  subtext?: string;
  text?: string;
};

export type SuggestionCategory = {
  filterItemFieldName: string;
  searchCategoryName: GqlSearchCategoryName;
  suggestionsList: SuggestionItem[];
};

const createSearchSuggestionItem = (item: SuggestionItem) => {
  return new SearchSuggestionListItem(item.text, item.imageUrl, item.subtext || '');
};

export const createSearchSuggestionCategory = (gqlListSearchSuggestion: SuggestionCategory): SearchSuggestionCategory => {
  return new SearchSuggestionCategory(
    SearchCategoryName[gqlListSearchSuggestion.searchCategoryName.name],
    gqlListSearchSuggestion.filterItemFieldName,
    gqlListSearchSuggestion.suggestionsList.map((item: SuggestionItem) => createSearchSuggestionItem(item))
  );
};
