import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ProfilePanelWrap, ShimmerProfilePanelList, useAppDispatch, useAppSelector } from 'app/common';
import {
  closeAddSavedSearchesPanel,
  closeProfilePanel,
  ContactsTab,
  ListPanelIntro,
  ListPanelPivotItems,
  ProfileAccordion,
  selectIsLoadingSelectedList,
  selectIsProfilePanelOpened
} from 'app/pages/my-audience/lists-profile';

export const ListPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isProfilePanelOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const isLoadingSelectedList = useAppSelector<boolean>(selectIsLoadingSelectedList);

  const handleDismissButtonClick = () => {
    dispatch(closeProfilePanel());
    dispatch(closeAddSavedSearchesPanel());
    navigate(`/my-audience/lists/results/${params.listsQuery}`);
  };

  return (
    <>
      {isLoadingSelectedList ? (
        <ProfilePanelWrap isProfilePanelOpened={isProfilePanelOpened} onDismissButtonClick={handleDismissButtonClick} classModifier="list" width="650px">
          <ShimmerProfilePanelList />
        </ProfilePanelWrap>
      ) : (
        <ProfilePanelWrap isProfilePanelOpened={isProfilePanelOpened} onDismissButtonClick={handleDismissButtonClick} classModifier="list" width="650px">
          <div className="profile-panel-container">
            <ListPanelIntro />
            <ListPanelPivotItems />
            <div className="profile-panel-main">
              <Routes>
                <Route path="profile" element={<ProfileAccordion />} />
                <Route path="contacts/*" element={<ContactsTab />} />
              </Routes>
            </div>
          </div>
        </ProfilePanelWrap>
      )}
    </>
  );
};
