import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateContactProfileInformation,
  closeCreateContactWizard,
  CreateContactFinish,
  CreateContactWizardStep,
  CreateNewContact,
  selectCurrentStepCreate,
  selectIsCreateContactWizardOpened,
  AddOwnContactData
} from 'app/pages/my-audience/media-outlet-profile';

export const CreateContactWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isCreateContactWizardOpened = useAppSelector<boolean>(selectIsCreateContactWizardOpened);
  const currentStep = useAppSelector<CreateContactWizardStep>(selectCurrentStepCreate);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'mo-create-contact.wizard-header-title' })}
      customWidth="750px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateContactWizardStep.CreateNew && <CreateNewContact />}
        {currentStep === CreateContactWizardStep.OwnContactData && <AddOwnContactData />}
        {currentStep === CreateContactWizardStep.ProfileInformation && <CreateContactProfileInformation />}
        {currentStep === CreateContactWizardStep.Finish && <CreateContactFinish />}
      </div>
    </WizardWrap>
  );
};
