import { PillData, Tag, useAppDispatch, useAppSelector } from 'app/common';
import { UndoablePills } from 'app/common/components/tags-pills/UndoablePills';
import { useIntl } from 'app/i18n';
import { addedTagToContact, removedTagsFromContact, selectContactTagsHeaderValue, selectSelectedContact } from 'app/pages/my-audience/contact-profile';
import {
  addNewTagRequested,
  addTagToContactsRequested,
  deleteTagsRequested,
  filterTags,
  removeTagFromContactsRequested,
  selectFilteredTagsInPanel,
  selectAllTags,
  removedTagsFromContactsSearchResults,
  Contact,
  addedTagToContactsSearchResults
} from 'app/pages/my-audience/contacts';
import { Permission, useAuthContext } from 'auth';

export const ContactTags = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const selectedTags = useAppSelector<Tag[]>(selectContactTagsHeaderValue);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const selectedContactIds = [selectedContact.id];
  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);

  const onAddNewTag = (text: string) => dispatch(addNewTagRequested({ selectedContactIds, text }));

  const onAddTagToContacts = (id: string) => {
    const tag = allTags.find((tag) => tag.id === id);
    dispatch(addTagToContactsRequested({ selectedContactIds, tag }));
  };

  const addTagToContactInStore = (id: string) => {
    const tagToAdd = allTags.find((tag) => tag.id === id);
    dispatch(addedTagToContact(tagToAdd));
    dispatch(addedTagToContactsSearchResults({ contactIds: selectedContactIds, tag: tagToAdd }));
  };

  const removeTagFromContactInStore = (id: string) => {
    const tagToRemove = allTags.find((tag) => tag.id === id);
    dispatch(removedTagsFromContact([tagToRemove]));
    dispatch(removedTagsFromContactsSearchResults({ contactIds: selectedContactIds, tags: [tagToRemove] }));
  };

  const onDeleteTags = (ids: string[]) => {
    const tagsToDelete = allTags.filter((tag) => ids.includes(tag.id));
    dispatch(deleteTagsRequested(tagsToDelete));
  };

  const onRemoveTagFromContacts = (id: string) => {
    const tag = allTags.find((tag) => tag.id === id);
    dispatch(removeTagFromContactsRequested({ selectedContactIds, tag }));
  };

  const handleFilterValues = (searchText: string) => dispatch(filterTags(searchText));

  return (
    <UndoablePills
      entityName={formatMessage({ id: 'labels.tag' })}
      selectedValues={selectedTags.map((t) => new PillData(t.id, t.name, true))}
      dropdownValues={filteredTags.map((t) => new PillData(t.id, t.name, true))}
      onAddValue={onAddNewTag}
      onSelectValue={onAddTagToContacts}
      onDeselectValue={onRemoveTagFromContacts}
      onDeleteValues={onDeleteTags}
      filterValues={handleFilterValues}
      enabledDelete={true}
      onDeselectValueInStore={removeTagFromContactInStore}
      onSelectValueInStore={addTagToContactInStore}
      onUndoDeselectValueInStore={addTagToContactInStore}
      onUndoSelectValueInStore={removeTagFromContactInStore}
      disabled={!hasPermissionToEdit}
    />
  );
};
