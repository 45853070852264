import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import {
  MediumCategory,
  useAppSelector,
  WizardIntro,
  useAppDispatch,
  regAtLeastOneCharacter,
  maximumMediaOutletNameLength,
  isTextInputValid
} from 'app/common';
import {
  AddGeneralMediaOutletInfoForm,
  CreateMediaOutletSteps,
  MediaOutletGeneralInformation,
  selectGeneralInfoToAdd,
  CreateMediaOutletFooter,
  setMediaOutletGeneralInfo,
  addGeneralMediaOutletInformation,
  goToNextStep,
  closeCreateMediaOutletWizard
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { ControlledCreateDropdown, ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { selectCategories, LocalizedListValue, addMediumCategoryRequested } from 'app/pages/my-audience/media-outlets';
import { selectLocalizedAudiences, selectLocalizedFrequencies } from 'app/localization';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddGeneralInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const mediaOutletGeneralInfo = useAppSelector<MediaOutletGeneralInformation>(selectGeneralInfoToAdd);
  const localizedAudiences = useAppSelector<LocalizedListValue[]>(selectLocalizedAudiences);
  const localizedFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);
  const categories = useAppSelector<MediumCategory[]>(selectCategories);

  const selectedAudience = localizedAudiences.find((a) => a.key === mediaOutletGeneralInfo.audienceKey)?.label;
  const selectedFrequency = localizedFrequencies.find((a) => a.key === mediaOutletGeneralInfo.frequencyKey)?.label;

  const { handleSubmit, control, watch } = useForm<AddGeneralMediaOutletInfoForm, any>({
    defaultValues: {
      name: mediaOutletGeneralInfo.name,
      description: mediaOutletGeneralInfo.description,
      audience: selectedAudience,
      category: mediaOutletGeneralInfo.category?.name,
      frequency: selectedFrequency
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleNextButtonClick = () => {
    handleSubmit(
      (data) => {
        const newMediaOutletGeneralInfo: MediaOutletGeneralInformation = setMediaOutletGeneralInfo(data, localizedAudiences, categories, localizedFrequencies);

        dispatch(addGeneralMediaOutletInformation(newMediaOutletGeneralInfo));
        dispatch(goToNextStep());
      },
      (error) => {
        Sentry.setExtra('Path', AddGeneralInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const handleFieldsValidation = () => {
    const watchName = watch('name');
    const isNameValid = isTextInputValid({ inputValue: watchName, maxInputLength: maximumMediaOutletNameLength });

    return isNameValid;
  };

  const areGeneralInfoValid = handleFieldsValidation();

  const submitData = () => {
    handleSubmit(
      (data) => {
        const newMediaOutletGeneralInfo: MediaOutletGeneralInformation = setMediaOutletGeneralInfo(data, localizedAudiences, categories, localizedFrequencies);

        dispatch(addGeneralMediaOutletInformation(newMediaOutletGeneralInfo));
      },
      (error) => {
        Sentry.setExtra('Path', AddGeneralInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleCreateNewCategory = (categoryName: string) => {
    dispatch(addMediumCategoryRequested(categoryName));
  };

  return (
    <>
      <CreateMediaOutletSteps onStepClick={submitData} isStepValid={areGeneralInfoValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-mo.wizard-add-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'add-menu-create-mo.wizard-name-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="name"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: formatMessage({ id: 'add-menu-create-mo.wizard-mo-label' }) })
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumMediaOutletNameLength }),
                  value: maximumMediaOutletNameLength
                },
                required: formatMessage({ id: 'error-messages.required-filed' }, { fieldName: formatMessage({ id: 'add-menu-create-mo.wizard-mo-label' }) })
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-mo.wizard-description-label' })} control={control} name="description" />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-mo.wizard-audience-label'} />
                </span>
              }
              name="audience"
              items={localizedAudiences.map((a) => a.label)}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-mo.wizard-audience-placeholder' })}
              defaultValue={selectedAudience}
            />
          </div>
          <div className="custom-dropdown">
            <ControlledCreateDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-mo.wizard-category-label'} />
                </span>
              }
              name="category"
              items={categories.map((c) => c.name)}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-mo.wizard-category-placeholder' })}
              defaultValue={mediaOutletGeneralInfo.category?.name}
              createNewEntityMessage={formatMessage({ id: 'aria-labels.click-to-add-new-category' })}
              onCreateNewEntityClick={handleCreateNewCategory}
            />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-mo.wizard-frequency-label'} />
                </span>
              }
              name="frequency"
              items={localizedFrequencies.map((f) => f.label)}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-mo.wizard-frequancy-placeholder' })}
              defaultValue={selectedFrequency}
            />
          </div>
        </div>
        <CreateMediaOutletFooter
          onNextButtonClick={handleNextButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
          isNextButtonDisabled={!areGeneralInfoValid}
        />
      </div>
    </>
  );
};
