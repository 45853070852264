import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { FormattedMessage } from 'app/i18n';
import { AdditionalInformation, ContactData } from 'app/pages/my-audience/company-profile';

export const ProfileAccordion = () => {
  const panels = [
    {
      title: (
        <div className="accordion-head-box" key="contact-data-title">
          <span className="accordion-heading">
            <FormattedMessage id={'companies-profile-tab-accordion.contact-data'} />
          </span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'contact-data-content', content: <ContactData /> }
    },
    {
      title: (
        <div className="accordion-head-box" key="additional-information-title">
          <span className="accordion-heading">
            <FormattedMessage id={'companies-profile-tab-accordion.additional-info'} />
          </span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'additional-information-content', content: <AdditionalInformation /> }
    }
  ];

  return (
    <div className="profile-panel-content">
      <Accordion defaultActiveIndex={[0]} panels={panels} />
    </div>
  );
};
