import { Icon } from '@fluentui/react';
import {
  AlertMessage,
  BottomPopupItems,
  ChangePhotoDialog,
  ThreeDotMenu,
  ThreeDotMenuItems,
  downloadFile,
  openBottomPopup,
  selectIsOnDesktop,
  useAppDispatch,
  useAppSelector,
  useComponentLocationContext
} from 'app/common';
import { createContactVCard } from 'app/common/factories/contact-v-card.factory';
import { useIntl } from 'app/i18n';
import { ProfilePanelAvatar } from 'app/pages/my-audience/common';
import {
  ContactProfileIntroInfo,
  ContactQuickActions,
  ContactTags,
  DeleteContactDialog,
  PhoneToDialDialog,
  QRCodeDialog,
  changeProfilePictureToUpload,
  closeChangeImageDialog,
  createContactVCardStringValue,
  openChangeImageDialog,
  openQRCodeDialog,
  removeProfilePicturePreview,
  removeProfilePictureRequested,
  selectContactVCardStringValue,
  selectImageToUpload,
  selectIsChangeImageDialogOpened,
  selectIsRemoveContactDialogOpened,
  selectProfilePicturePreviewUrl,
  selectSelectedContact,
  selectSelectedContactData,
  setIsRemoveContactDialogOpened,
  showVcardAttachment,
  updateProfilePictureRequested
} from 'app/pages/my-audience/contact-profile';
import { Collaboration, Contact, DataSourceStatus, OwnContactData } from 'app/pages/my-audience/contacts';
import { Permission, useAuthContext } from 'auth';
import { Button } from '@fluentui/react-northstar';
import { useEffect, useRef, useState } from 'react';

export const ContactProfilePanelIntro = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();
  const { isOutsideContactsPage } = useComponentLocationContext();

  const selectedContactVCard = useAppSelector<string>(selectContactVCardStringValue);
  const selectedContactData = useAppSelector<Collaboration | OwnContactData>(selectSelectedContactData);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const isRemoveContactDialogOpened = useAppSelector(selectIsRemoveContactDialogOpened);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const hasPermissionToEditContact = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);
  const [toggleImageDialog, setToggleImageDialog] = useState(false);
  const [isExportTriggered, setIsExportTriggered] = useState(false);

  useEffect(() => {
    if (isExportTriggered) {
      downloadFile({ data: selectedContactVCard, name: `${selectedContact.firstName}${selectedContact.lastName}.vcf`, ref });
      dispatch(showVcardAttachment());
      setIsExportTriggered(false);
    }
  }, [isExportTriggered]);

  const onExportVCardClick = () => {
    generateSelectedContactVCard();
    exportContactVCard();
  };

  const onGenerateQRClick = () => {
    generateSelectedContactVCard();
    dispatch(openQRCodeDialog());
  };

  const onRemoveContactClick = () => {
    dispatch(setIsRemoveContactDialogOpened(true));
  };

  const threeDotMenuItems: ThreeDotMenuItems[] = [
    {
      key: 'export-v-card',
      title: formatMessage({ id: 'contacts-three-dots-menu.export-v-card-menu-item' }),
      className: 'panel-intro-menu-item',
      icon: <Icon iconName="ContactCard" />,
      onClick: () => onExportVCardClick()
    },
    {
      key: 'qr-code',
      title: formatMessage({ id: 'contacts-three-dots-menu.qr-code-menu-item' }),
      className: 'panel-intro-menu-item',
      icon: <Icon iconName="QRCode" />,
      onClick: () => onGenerateQRClick()
    },
    {
      key: 'delete-contact',
      title: formatMessage({ id: 'contacts-three-dots-menu.delete-contact-menu-item' }),
      className: 'panel-intro-menu-item danger',
      icon: <Icon iconName="Delete" />,
      onClick: () => onRemoveContactClick(),
      disabled: !hasPermissionToEditContact
    }
  ];

  const generateSelectedContactVCard = () => {
    const contactVcard = createContactVCard(selectedContact, selectedContactData);
    dispatch(createContactVCardStringValue(contactVcard.toString()));
  };

  const exportContactVCard = () => {
    setIsExportTriggered(true);
  };

  const imageActionsItems: BottomPopupItems[] = [
    {
      key: 'take-photo',
      title: formatMessage({ id: 'change-profile-picture-dialog.title' }),
      className: 'panel-intro-menu-item',
      icon: <Icon iconName="Camera" />,
      onClick: () => onExportVCardClick()
    },
    {
      key: 'existing-photo',
      title: formatMessage({ id: 'change-profile-picture-dialog.upload-photo-button' }),
      className: 'panel-intro-menu-item',
      icon: <Icon iconName="Photo2Add" />,
      onClick: () => onGenerateQRClick()
    },
    {
      key: 'remove-photo',
      title: formatMessage({ id: 'change-profile-picture-dialog.remove-photo-button' }),
      className: 'panel-intro-menu-item danger',
      icon: <Icon iconName="Delete" />,
      onClick: () => onRemoveContactClick()
    }
  ];

  const handleImageOptions = () => {
    dispatch(openBottomPopup(imageActionsItems));
  };

  const handleOnAvatarClick = () => {
    dispatch(openChangeImageDialog());
  };

  const getWarningMessages = () => {
    const messages = [];

    if (selectedContact.isUser) {
      messages.push(
        formatMessage({ id: 'delete-contact-dialog.delete-user-text' }, { contactName: `${selectedContact?.firstName} ${selectedContact?.lastName}` })
      );
    }

    if (selectedContact.dataSourceKey) {
      messages.push(
        formatMessage({ id: 'delete-contact-dialog.external-db-message' }, { contactName: `${selectedContact?.firstName} ${selectedContact?.lastName}` })
      );
    }

    return messages;
  };

  return (
    <>
      <div className="panel-intro">
        <ProfilePanelAvatar
          onClick={handleOnAvatarClick}
          lastName={selectedContact.lastName}
          firstName={selectedContact.firstName}
          imageUrl={selectedContact.profilePictureUrl}
          disabled={!hasPermissionToEditContact}
        />
        <div className="panel-intro-content">
          <ContactProfileIntroInfo />
          <ContactTags />
          <div className="panel-intro-content__bottom">
            <ContactQuickActions />
            {selectedContact.dataSourceStatus === DataSourceStatus.Desynchronized && (
              <div className="alert-msg">
                <AlertMessage message={formatMessage({ id: 'alert-contact-desynchronized' })} />
              </div>
            )}
          </div>
        </div>
        {!isOutsideContactsPage && isOnDesktop && (
          <div className="panel-intro-menu">
            <ThreeDotMenu threeDotMenuItems={threeDotMenuItems} />
          </div>
        )}
        {!isOnDesktop && (
          <div className="panel-intro-menu">
            <Button
              iconOnly
              icon={
                <Icon
                  iconName={'MoreVertical'}
                  onClick={() => {
                    dispatch(openBottomPopup(threeDotMenuItems.map((item) => ({ ...item, className: 'bottom-popup-items' }))));
                  }}
                />
              }
            ></Button>
          </div>
        )}
      </div>
      <ChangePhotoDialog
        selectImageToUpload={selectImageToUpload}
        selectProfilePicturePreviewUrl={selectProfilePicturePreviewUrl}
        selectIsDialogOpened={selectIsChangeImageDialogOpened}
        name={`${selectedContact.firstName} ${selectedContact.lastName}`}
        changeProfilePictureToUpload={changeProfilePictureToUpload}
        removeProfilePictureRequested={removeProfilePictureRequested}
        updateProfilePictureRequested={updateProfilePictureRequested}
        removeProfilePicturePreview={removeProfilePicturePreview}
        closeDialog={closeChangeImageDialog}
      />
      <PhoneToDialDialog />
      <QRCodeDialog />
      <DeleteContactDialog
        selectedContact={selectedContact}
        opened={isRemoveContactDialogOpened}
        dialogTitle={formatMessage({ id: 'delete-contacts-dialog.are-you-sure' }, { counter: 1 })}
        warningMessages={getWarningMessages()}
        undoAlertMessage={formatMessage(
          { id: 'delete-contact-dialog.undo-alert-message' },
          { contactName: `${selectedContact?.firstName} ${selectedContact?.lastName}` }
        )}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a ref={ref} href="/" style={{ display: 'none' }} />
    </>
  );
};
