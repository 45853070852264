import { DateTime } from 'luxon';
import { useState } from 'react';
import { Input, SearchIcon, Table } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { filterSendingsByDate, resetSendingsFilter, searchSendings, selectFilteredSendingOverviews } from 'app/pages/my-audience/lists-profile';
import { useIntl } from 'app/i18n';
import { DirectionalHint } from '@fluentui/react';
import { ContextualDateFiltersMenu } from 'app/common/components/date-filters-menu';
import { SendingOverview } from 'app/common/graphql/generated/graphql-gateway';

export const Sendings = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const sendingOverviews = useAppSelector<SendingOverview[]>(selectFilteredSendingOverviews);
  const [searchText, setSearchText] = useState('');

  const tableRows = sendingOverviews.map(
    ({ sendingId, title, createdAt, totalNumberOfOpenings, totalNumberOfClickedLinks, totalNumberOfRecipients }: SendingOverview) => ({
      key: sendingId,
      items: [
        title,
        DateTime.fromJSDate(new Date(createdAt)).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
        totalNumberOfOpenings,
        totalNumberOfClickedLinks,
        totalNumberOfRecipients
      ]
    })
  );

  const header = {
    items: [
      formatMessage({ id: 'list-profile-sendings-table.title' }),
      formatMessage({ id: 'list-profile-sendings-table.date' }),
      formatMessage({ id: 'list-profile-sendings-table.opened' }),
      formatMessage({ id: 'list-profile-sendings-table.links-clicked' }),
      formatMessage({ id: 'list-profile-sendings-table.recipients' })
    ]
  };

  const onInputChange = (inputText: string) => {
    setSearchText(inputText);
    dispatch(searchSendings(inputText));
  };

  const filter = (startDate: Date, endDate: Date) => {
    dispatch(filterSendingsByDate({ startDate, endDate }));
  };

  const resetFilter = () => {
    dispatch(resetSendingsFilter());
  };

  return (
    <>
      <div className="profile-panel-activities-dropdowns profile-panel-sendings">
        <div className="profile-panel-sendings-dropdown">
          <Input
            icon={<SearchIcon />}
            placeholder={formatMessage({ id: 'list-profile-sendings-table-toolbar.search' })}
            autoComplete="off"
            value={searchText}
            onChange={(e) => onInputChange((e.target as HTMLTextAreaElement).value)}
          />
          <div className="profile-panel-activities-filter">
            <ContextualDateFiltersMenu direction={DirectionalHint.bottomLeftEdge} onFilter={filter} onResetFilter={resetFilter} />
          </div>
        </div>
      </div>
      <div className="wizard-panel-table sendings-table">
        <Table header={header} rows={tableRows} aria-label="Static table" />
      </div>
    </>
  );
};
