import { useMemo, useState } from 'react';
import { Button } from '@fluentui/react-northstar';
import { CommandBarButton, DetailsListLayoutMode, Icon, MarqueeSelection, ShimmeredDetailsList } from '@fluentui/react';
import { DetailsRow, Selection, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { EntitiesPerPage, useAppDispatch, useAppSelector, usePersistentSelection } from 'app/common';
import {
  removeRecipientsFromSendingRequested,
  selectEditingRecipientsIds,
  selectIsLoadingRecipients,
  selectRecipientsPageNumber,
  selectSelectedRecipientsIdsPerPage,
  selectSelectedSendingRecipients,
  setEditingRecipientsIds,
  setSelectedRecipientsIdsIfNotLoading,
  updateSendingRecipientContactDataRequested
} from 'app/pages/my-activities/sending-wizard';
import { Recipient, UpdateSendingRecipientInput } from 'app/pages/my-activities/sendings';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';
import { WarningMessage } from 'app/common/components/warning-message/WarningMessage';
import { ContactPanel, contactProfilePanelDataRequested, openProfilePanel } from 'app/pages/my-audience/contact-profile';
import { useNavigate } from 'react-router-dom';

export const AudienceTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const selectedSendingRecipients = useAppSelector<Recipient[]>(selectSelectedSendingRecipients);
  const isLoadingRecipients = useAppSelector<boolean>(selectIsLoadingRecipients);
  const recipientsPageNumber = useAppSelector<number>(selectRecipientsPageNumber);
  const editingRecipientsIds = useAppSelector<string[]>(selectEditingRecipientsIds);
  const selectedRecipientsIdsPerPage = useAppSelector<EntitiesPerPage<string>>(selectSelectedRecipientsIdsPerPage);

  const [hoveringOnRecipientIndex, setHoveringOnRecipientIndex] = useState(-1);

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedItems = selection.getSelection();

          if (selectedItems.includes(undefined)) {
            return;
          }

          dispatch(setSelectedRecipientsIdsIfNotLoading(selectedItems.map((s) => s.key.toString())));
        },
        selectionMode: SelectionMode.multiple
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getRecipientId = <T,>(entity: T): string => {
    const recipientId = entity as string;
    return recipientId;
  };

  usePersistentSelection({
    tableRows: selectedSendingRecipients,
    entitiesPerPage: selectedRecipientsIdsPerPage,
    selection,
    currentPageNumber: recipientsPageNumber,
    getEntityId: getRecipientId
  });

  const header = [
    { key: 'audienceWizardName', name: formatMessage({ id: 'contact-data-labels.name' }), fieldName: 'name', isResizable: true, minWidth: 100, maxWidth: 200 },
    {
      key: 'audienceWizardJobTitle',
      name: formatMessage({ id: 'contact-data-labels.job-title' }),
      fieldName: 'jobTitle',
      isResizable: true,
      minWidth: 100,
      maxWidth: 130
    },
    {
      key: 'audienceWizardEmail',
      name: formatMessage({ id: 'contact-data-labels.email-address' }),
      fieldName: 'email',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200,
      className: 'email'
    },
    {
      key: 'audienceWizardContactData',
      name: formatMessage({ id: 'labels.contact-data' }),
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200
    },
    { key: 'audienceWizardHoverActions', name: '', fieldName: 'hoverActions', isResizable: true, minWidth: 100, maxWidth: 200 }
  ];

  const onUpdateContactData = (recipient: Recipient, collaborationId: string) => {
    const emailAddress =
      collaborationId !== ''
        ? recipient.contact.collaborations.find((c) => c.id === collaborationId).emailAddress
        : recipient.contact.ownContactData.emailAddress;

    dispatch(updateSendingRecipientContactDataRequested(new UpdateSendingRecipientInput(recipient.id, collaborationId, emailAddress)));
    dispatch(setEditingRecipientsIds(editingRecipientsIds.filter((id) => id !== recipient.id)));
  };

  const handleRecipientRowClick = (contactId: string) => {
    dispatch(contactProfilePanelDataRequested(contactId));
    dispatch(openProfilePanel());
    navigate(`profile`, { replace: true });
  };

  const rows = selectedSendingRecipients?.map((recipient, index) => {
    if (!recipient.contact?.id) {
      return {
        key: recipient.id,
        onDelete: () => removeRecipientFromSending(recipient),
        name: '',
        jobTitle: '',
        email: recipient.emailAddress,
        contactData: '',
        hoverActions: (
          <>
            <div>
              <Button text icon={<Icon iconName="Delete" />} onClick={() => removeRecipientFromSending(recipient)} />
            </div>
          </>
        )
      };
    }

    const selectedContactData = recipient?.collaborationId
      ? recipient?.contact?.collaborations?.find((c) => c.id === recipient.collaborationId)
      : recipient?.contact?.ownContactData;

    const contactDataDropdown = [
      ...recipient.contact?.collaborations.map((collaboration) => ({
        key: collaboration.id + recipient.id,
        text: collaboration.medium?.name,
        onClick: () => onUpdateContactData(recipient, collaboration.id)
      })),
      {
        key: recipient.contact?.id,
        text: formatMessage({ id: 'labels.own-contact-data' }),
        onClick: () => onUpdateContactData(recipient, '')
      }
    ];

    const maybeEmailAddress = recipient?.emailAddress || (
      <WarningMessage warningText={formatMessage({ id: 'add-contacts-wizard.contact-has-no-email-address' })} />
    );

    return {
      key: recipient.id,
      onDelete: () => removeRecipientFromSending(recipient),
      name: (
        <span className="link-button" onClick={() => handleRecipientRowClick(recipient.contact?.id)}>
          {`${recipient.contact?.firstName} ${recipient.contact?.lastName}`}
        </span>
      ),
      jobTitle: (selectedContactData as Collaboration)?.jobTitle?.name ?? '',
      email: !editingRecipientsIds.includes(recipient.id) ? (
        maybeEmailAddress
      ) : (
        <div key={recipient.contact?.id}>
          <CommandBarButton text={formatMessage({ id: 'dropdown-placeholders.collaboration' })} menuProps={{ items: contactDataDropdown }} />
        </div>
      ),
      contactData: (selectedContactData as Collaboration)?.medium?.name || formatMessage({ id: 'labels.own-contact-data' }),
      hoverActions: (
        <>
          {hoveringOnRecipientIndex === index ? (
            <div>
              <Button text icon={<Icon iconName="Delete" />} onClick={() => removeRecipientFromSending(recipient)} />
              <Button text icon={<Icon iconName="Edit" />} onClick={() => onEditContactData(recipient.id)} />
            </div>
          ) : (
            <></>
          )}
        </>
      )
    };
  });

  const onEditContactData = (recipientId: string) => {
    dispatch(setEditingRecipientsIds([...editingRecipientsIds, recipientId]));
  };

  const removeRecipientFromSending = (recipient: Recipient) => {
    dispatch(removeRecipientsFromSendingRequested([recipient.id]));
  };

  return (
    <>
      <div className="wizard-panel-table audience-table">
        <div
          onMouseLeave={() => {
            setHoveringOnRecipientIndex(-1);
          }}
        >
          <MarqueeSelection selection={selection} isDraggingConstrainedToRoot={true}>
            <ShimmeredDetailsList
              items={rows}
              columns={header}
              enableShimmer={isLoadingRecipients}
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              setKey="sendingWizardAudienceTableSet"
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={formatMessage({ id: 'aria-labels.toggle-selection' })}
              ariaLabelForSelectAllCheckbox={formatMessage({ id: 'aria-labels.toggle-selection-for-all-items' })}
              checkButtonAriaLabel={formatMessage({ id: 'aria-labels.select-row' })}
              onRenderRow={(props) => (
                <div
                  onMouseEnter={() => {
                    setHoveringOnRecipientIndex(props.itemIndex);
                  }}
                >
                  <DetailsRow {...props}></DetailsRow>
                </div>
              )}
            />
          </MarqueeSelection>
        </div>
      </div>
      <ContactPanel />
    </>
  );
};
