import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { client } from 'app/common/graphql/graphql-gateway.client';
import {
  addedNewTag,
  addedTagToCompaniesSearchResults,
  addNewTagRequested,
  addTagToCompanies,
  addTagToCompaniesRequested,
  allTagsReceived,
  cityFilterSuggestionsReceived,
  cityFilterSuggestionsRequested,
  companiesByIdsRemoved,
  CompaniesSortingInput,
  companiesSuggestionsReceived,
  companiesSuggestionsRequested,
  Company,
  companyNameFilterSuggestionsReceived,
  companyNameFilterSuggestionsRequested,
  companyTransformedIntoMediaOutlet,
  contactNameFilterSuggestionsReceived,
  contactNameFilterSuggestionsRequested,
  countryFilterSuggestionsReceived,
  countryFilterSuggestionsRequested,
  databaseTypeFilterSuggestionsReceived,
  databaseTypeFilterSuggestionsRequested,
  deleteTagsRequested,
  fetchCompaniesByQueryParamsPaged,
  fetchCompanySearchSuggestions,
  fetchCompanySuggestionsByCity,
  fetchCompanySuggestionsByContactName,
  fetchCompanySuggestionsByCountry,
  fetchCompanySuggestionsByDatabaseType,
  fetchCompanySuggestionsByName,
  fetchCompanySuggestionsByPostCode,
  fetchCompanySuggestionsByRegistrationNumber,
  fetchCompanySuggestionsByTagName,
  firstPageOfCompaniesReceived,
  firstPageOfCompaniesRequested,
  getAllCompaniesTagsRequested,
  nextPageOfCompaniesReceived,
  nextPageOfCompaniesRequested,
  PageOfCompanies,
  postalCodeFilterSuggestionsReceived,
  postalCodeFilterSuggestionsRequested,
  registrationNumberFilterSuggestionsReceived,
  registrationNumberFilterSuggestionsRequested,
  removeCompaniesByIds,
  removeCompaniesByIdsRequested,
  removeCompany,
  removeCompanyRequested,
  removedCompany,
  removedTagsFromCompaniesSearchResults,
  removeTagFromCompanies,
  removeTagFromCompaniesRequested,
  selectCompaniesSearchSuggestionsText,
  selectCompaniesToRemoveIds,
  selectFilterItems,
  selectFilterSuggestionsPageSize,
  selectFilterSuggestionsSearchText,
  selectPageNumber,
  selectPageSize,
  selectSearchText,
  selectSelectedCompaniesIds,
  selectSortingInput,
  selectTagNameToCreate,
  selectTagsToDelete,
  selectTagToAddToCompanies,
  selectTagToRemoveFromCompanies,
  setTotalCountOfCompanies,
  tagFilterSuggestionsReceived,
  tagFilterSuggestionsRequested,
  tagsDeleted,
  transformCompanyIntoMediaOutlet,
  transformCompanyIntoMediaOutletRequested
} from 'app/pages/my-audience/companies';
import {
  addedTagToCompany,
  closeProfilePanel,
  removedTagsFromCompanies,
  resetToInitialState,
  selectSelectedCompany
} from 'app/pages/my-audience/company-profile';
import {
  closeDeleteEntitiesDialog,
  createAndAssignTag,
  deleteTags,
  fetchTagsByEntityType,
  FilterItem,
  safe,
  SearchSuggestionCategory,
  selectSelectedTableRowsIdsSelector,
  TagCreationInput
} from 'app/common';

export function* fetchCompaniesPagedWatcher() {
  yield takeEvery(firstPageOfCompaniesRequested.type, safe(fetchCompaniesPagedFlow));
  yield takeEvery(nextPageOfCompaniesRequested.type, safe(fetchCompaniesPagedFlow));
}

function* fetchCompaniesPagedFlow() {
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);
  const searchText: string = yield select(selectSearchText);
  const filterItems: FilterItem[] = yield select(selectFilterItems);
  const sortingInput: CompaniesSortingInput = yield select(selectSortingInput);

  const pageOfCompanies: PageOfCompanies = yield call(fetchCompaniesByQueryParamsPaged, client, searchText, filterItems, pageNumber, pageSize, sortingInput);
  if (pageNumber === 1) {
    yield put(firstPageOfCompaniesReceived(pageOfCompanies.companies));
  } else {
    yield put(nextPageOfCompaniesReceived(pageOfCompanies.companies));
  }

  yield put(setTotalCountOfCompanies(pageOfCompanies.totalCount));
}

function* fetchCompaniesSuggestionsFlow() {
  const searchText: string = yield select(selectCompaniesSearchSuggestionsText);
  const companiesSuggestions: SearchSuggestionCategory[] = yield call(fetchCompanySearchSuggestions, client, searchText);
  yield put(companiesSuggestionsReceived(companiesSuggestions));
}

export function* companiesSuggestionsRequestedWatcher() {
  yield takeLatest(companiesSuggestionsRequested.type, safe(fetchCompaniesSuggestionsFlow));
}

function* removeCompanyFlow() {
  const selectedCompany: Company = yield select(selectSelectedCompany);

  const removeCompanyId: string = yield call(removeCompany, client, selectedCompany.id);
  yield put(removedCompany(removeCompanyId));
}

export function* removeCompanyRequestedWatcher() {
  yield takeEvery(removeCompanyRequested.type, safe(removeCompanyFlow));
}

function* transformCompanyIntoMediaOutletFlow() {
  const selectedCompany: Company = yield select(selectSelectedCompany);

  yield call(transformCompanyIntoMediaOutlet, client, selectedCompany.id);
  yield put(companyTransformedIntoMediaOutlet(selectedCompany.id));
  yield put(closeProfilePanel());
  yield put(resetToInitialState());
}

export function* transformCompanyIntoMediaOutletRequestedWatcher() {
  yield takeEvery(transformCompanyIntoMediaOutletRequested.type, safe(transformCompanyIntoMediaOutletFlow));
}

function* companyNameFilterSuggestionsFlow() {
  const name: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByName, client, name, pageSize);
  yield put(companyNameFilterSuggestionsReceived(companies));
}

export function* companyNameFilterSuggestionsRequestedWatcher() {
  yield takeEvery(companyNameFilterSuggestionsRequested.type, safe(companyNameFilterSuggestionsFlow));
}

function* contactNameFilterSuggestionsFlow() {
  const contactName: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByContactName, client, contactName, pageSize);
  yield put(contactNameFilterSuggestionsReceived(companies));
}

export function* contactNameFilterSuggestionsRequestedWatcher() {
  yield takeEvery(contactNameFilterSuggestionsRequested.type, safe(contactNameFilterSuggestionsFlow));
}

function* tagNameFilterSuggestionsFlow() {
  const tagName: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByTagName, client, tagName, pageSize);
  yield put(tagFilterSuggestionsReceived(companies));
}

export function* tagNameFilterSuggestionsRequestedWatcher() {
  yield takeEvery(tagFilterSuggestionsRequested.type, safe(tagNameFilterSuggestionsFlow));
}

function* countryFilterSuggestionsFlow() {
  const country: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByCountry, client, country, pageSize);
  yield put(countryFilterSuggestionsReceived(companies));
}

export function* countryFilterSuggestionsRequestedWatcher() {
  yield takeEvery(countryFilterSuggestionsRequested.type, safe(countryFilterSuggestionsFlow));
}

function* registrationNumberFilterSuggestionsFlow() {
  const regNumber: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByRegistrationNumber, client, regNumber, pageSize);
  yield put(registrationNumberFilterSuggestionsReceived(companies));
}

export function* registrationNumberFilterSuggestionsRequestedWatcher() {
  yield takeEvery(registrationNumberFilterSuggestionsRequested.type, safe(registrationNumberFilterSuggestionsFlow));
}

function* databaseTypeFilterSuggestionsFlow() {
  const databaseType: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByDatabaseType, client, databaseType, pageSize);
  yield put(databaseTypeFilterSuggestionsReceived(companies));
}

export function* databaseTypeFilterSuggestionsRequestedWatcher() {
  yield takeEvery(databaseTypeFilterSuggestionsRequested.type, safe(databaseTypeFilterSuggestionsFlow));
}

function* cityFilterSuggestionsFlow() {
  const city: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByCity, client, city, pageSize);
  yield put(cityFilterSuggestionsReceived(companies));
}

export function* cityFilterSuggestionsRequestedWatcher() {
  yield takeEvery(cityFilterSuggestionsRequested.type, safe(cityFilterSuggestionsFlow));
}

function* postalCodeFilterSuggestionsFlow() {
  const postalCode: string = yield select(selectFilterSuggestionsSearchText);
  const pageSize: number = yield select(selectFilterSuggestionsPageSize);

  const companies: string[] = yield call(fetchCompanySuggestionsByPostCode, client, postalCode, pageSize);
  yield put(postalCodeFilterSuggestionsReceived(companies));
}

export function* postalCodeFilterSuggestionsRequestedWatcher() {
  yield takeEvery(postalCodeFilterSuggestionsRequested.type, safe(postalCodeFilterSuggestionsFlow));
}

function* removeCompaniesByIdsFlow() {
  const companiesToRemoveIds: string[] = yield select(selectCompaniesToRemoveIds);

  const companies: Company[] = yield call(removeCompaniesByIds, client, companiesToRemoveIds);
  yield put(companiesByIdsRemoved(companies));
  yield put(closeDeleteEntitiesDialog());
}

export function* removeCompaniesByIdsWatcher() {
  yield takeEvery(removeCompaniesByIdsRequested.type, safe(removeCompaniesByIdsFlow));
}

function* fetchAllCompanyTagsFlow() {
  const allTags = yield call(fetchTagsByEntityType, client, 'Company');
  yield put(allTagsReceived(allTags));
}

export function* getAllCompanyTagsRequestedWatcher() {
  yield takeEvery(getAllCompaniesTagsRequested.type, safe(fetchAllCompanyTagsFlow));
}

function* addTagToCompaniesFlow() {
  const tagToAdd = yield select(selectTagToAddToCompanies);
  const selectedCompanyIds = yield select(selectSelectedCompaniesIds);

  yield call(addTagToCompanies, client, selectedCompanyIds, tagToAdd);
}

export function* addTagToCompaniesRequestedWatcher() {
  yield takeEvery(addTagToCompaniesRequested.type, safe(addTagToCompaniesFlow));
}

function* removeTagFromCompaniesFlow() {
  const tagToRemove = yield select(selectTagToRemoveFromCompanies);
  const selectedCompanyIds = yield select(selectSelectedCompaniesIds);

  yield call(removeTagFromCompanies, client, selectedCompanyIds, tagToRemove);
}

export function* removeTagFromCompaniesRequestedWatcher() {
  yield takeEvery(removeTagFromCompaniesRequested.type, safe(removeTagFromCompaniesFlow));
}

function* deleteTagsFlow() {
  const tagsToDelete = yield select(selectTagsToDelete);
  const tagIdsToDelete = tagsToDelete.map((t) => t.id);
  const selectedCompaniesIds = yield select(selectSelectedCompaniesIds);

  yield call(deleteTags, client, tagIdsToDelete);
  yield put(tagsDeleted(tagIdsToDelete));
  yield put(removedTagsFromCompanies(tagIdsToDelete));
  yield put(removedTagsFromCompaniesSearchResults({ selectedCompaniesIds, tags: tagsToDelete }));
}

export function* deleteTagsFromCompaniesRequestedWatcher() {
  yield takeEvery(deleteTagsRequested.type, safe(deleteTagsFlow));
}

function* addTagFlow() {
  const selectedCompaniesIds: string[] = yield select(selectSelectedTableRowsIdsSelector);
  const tagToAdd = yield select(selectTagNameToCreate);

  const tagCreationInput = new TagCreationInput(tagToAdd, null, selectedCompaniesIds, 'Company');

  const createdTag = yield call(createAndAssignTag, client, tagCreationInput);
  yield put(addedNewTag(createdTag));
  yield put(addedTagToCompany(createdTag));
  yield put(addedTagToCompaniesSearchResults({ selectedCompaniesIds, tag: createdTag }));
}

export function* addCompanyTagRequestedWatcher() {
  yield takeEvery(addNewTagRequested.type, safe(addTagFlow));
}
