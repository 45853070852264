import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  Comment,
  CommentUser,
  createCompany,
  createContact,
  createSending,
  createMediaOutlet,
  Tag,
  createRecipient,
  FilterItem,
  httpClient,
  createList
} from 'app/common';
import {
  AddAppointmentDocument,
  AddAppointmentMutation,
  AddBlacklistToContactDocument,
  AddBlacklistToContactMutation,
  AddCallDocument,
  AddCallMutation,
  AddChatDocument,
  AddChatMutation,
  AddCollaborationDocument,
  AddCollaborationMutation,
  AddCommentToContactDocument,
  AddCommentToContactMutation,
  AddEmailDocument,
  AddEmailMutation,
  AddEventDocument,
  AddEventMutation,
  AddListToContactDocument,
  AddListToContactMutation,
  AddMediaResortToContactDocument,
  AddMediaResortToContactMutation,
  AddTaskDocument,
  AddTaskMutation,
  AddTopicToContactDocument,
  AddTopicToContactMutation,
  AddWebinarDocument,
  AddWebinarMutation,
  AppointmentInput,
  BlacklistCreationInput,
  BlacklistInput,
  CallInput,
  ChatInput,
  CollaborationInput,
  CollaborationWithIdInput,
  Company as GqlCompany,
  Contact as GqlContact,
  ContactsSending as GqlSending,
  ContactInput,
  ContactsCommentInput,
  ContactsCommentWithIdInput,
  ContactsProfilePictureInput,
  CreateAndAssignBlacklistDocument,
  CreateAndAssignBlacklistMutation,
  EmailInput,
  EventInput,
  FetchActivityEmailAddressDocument,
  FetchActivityEmailAddressQuery,
  FetchAllJobTitlesDocument,
  FetchAllJobTitlesQuery,
  FetchAllMediaResortsDocument,
  FetchAllMediaResortsQuery,
  FetchAllTopicsDocument,
  FetchAllTopicsQuery,
  FetchCompaniesByNameAndCategoryAndNotContainedInContactDocument,
  FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery,
  FetchContactAvatarDetailsByContactIdDocument,
  FetchContactAvatarDetailsByContactIdQuery,
  FetchContactProfileDocument,
  FetchContactProfileQuery,
  FetchContactsProfilePanelDataDocument,
  FetchContactsProfilePanelDataQuery,
  FetchMediaOutletsByNameAndCategoryAndNotContainedInContactDocument,
  FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery,
  FetchSelectedContactActivitiesDocument,
  FetchSelectedContactActivitiesQuery,
  MediaOutlet as GqlMediaOutlet,
  OwnContactDataInput,
  RemoveBlacklistFromContactDocument,
  RemoveBlacklistFromContactMutation,
  RemoveCollaborationDocument,
  RemoveCollaborationMutation,
  RemoveCommentFromContactDocument,
  RemoveCommentFromContactMutation,
  RemoveContactDocument,
  RemoveContactMutation,
  RemoveListFromContactDocument,
  RemoveListFromContactMutation,
  RemoveMediaResortFromContactDocument,
  RemoveMediaResortFromContactMutation,
  RemoveProfilePictureDocument,
  RemoveProfilePictureMutation,
  RemoveTopicFromContactDocument,
  RemoveTopicFromContactMutation,
  RemoveTopicsDocument,
  RemoveTopicsMutation,
  TaskInput,
  UpdateCollaborationDocument,
  UpdateCollaborationMutation,
  UpdateCommentDocument,
  UpdateCommentMutation,
  UpdateContactDocument,
  UpdateContactMutation,
  UpdateOwnContactDataDocument,
  UpdateOwnContactDataMutation,
  UpdateProfilePictureDocument,
  UpdateProfilePictureMutation,
  WebinarInput,
  FetchRecipientsByContactIdQuery,
  FetchRecipientsByContactIdDocument,
  DeleteBlacklistsMutation,
  DeleteBlacklistsDocument,
  FetchPagedSelectedContactActivitiesDocument,
  FetchPagedSelectedContactActivitiesQuery,
  FetchSendingsByContactIdQuery,
  FetchSendingsByContactIdDocument,
  CollaborationWithContactIdInput,
  AddCollaborationsMutation,
  AddCollaborationsDocument,
  DeleteMediaResortsMutation,
  DeleteMediaResortsDocument,
  FetchListsByContactIdQuery,
  FetchListsByContactIdDocument
} from 'app/common/graphql/generated/graphql-gateway';
import { ActivityUser, Recipient, Sending } from 'app/pages/my-activities/sendings';
import { PageOfCompanies } from 'app/pages/my-audience/companies';
import { ContactProfilePanelData, ContactProfilePicture, ContactUpdateModel, Medium } from 'app/pages/my-audience/contact-profile';
import {
  Activity,
  ActivityParticipant,
  Appointment,
  Blacklist,
  Call,
  Chat,
  Collaboration,
  CollaborationWithContactId,
  Contact,
  Email,
  Event,
  JobTitle,
  MediaResort,
  MediumType,
  MediumTypeName,
  OwnContactData,
  PageOfActivities,
  PhoneNumber,
  PreferredLanguage,
  Task,
  Topic,
  Webinar
} from 'app/pages/my-audience/contacts';
import { PageOfMediaOutlets } from 'app/pages/my-audience/media-outlets';
import { AvatarDetails } from 'app/pages/my-audience/saved-searches';
import { List } from 'app/pages/my-audience/lists';

export const fetchRecipientsByContactId = async (client: ApolloClient<NormalizedCacheObject>, contactId: string): Promise<Recipient[]> => {
  const response = await client.query<FetchRecipientsByContactIdQuery>({
    query: FetchRecipientsByContactIdDocument,
    variables: {
      contactId
    }
  });

  return response.data.recipientsByContactId.map((recipient) => createRecipient(recipient));
};

export const fetchSelectedContactProfile = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<Contact> => {
  const response = await client.query<FetchContactProfileQuery>({
    query: FetchContactProfileDocument,
    variables: {
      id
    }
  });

  return new Contact(
    response.data.contact.id,
    response.data.contact.salutation,
    response.data.contact.firstName,
    response.data.contact.lastName,
    response.data.contact.profilePictureUrl,
    response.data.contact.isGloballySignedOut,
    response.data.contact.tags.map((node) => new Tag(node.id, node.name, '')),
    response.data.contact.topics.map((node) => new Topic(node.topicId, node.topicName, node.dataSourceKey)),
    response.data.contact.mediaResorts.map((node) => new MediaResort(node.mediaResortId, node.mediaResortName, node.dataSourceKey)),
    response.data.contact.collaborations.map(
      (node) =>
        new Collaboration(
          node.id,
          new Medium(node.medium?.id, node.medium?.name, node.medium?.type.value),
          new JobTitle(node.jobTitle?.id, node.jobTitle?.name, node.jobTitle?.dataSourceKey, node.jobTitle?.mediumType.value),
          node.jobDescription,
          node.address,
          node.country,
          node.landlinePhoneNumber?.value ? new PhoneNumber(node.landlinePhoneNumber.value, node.landlinePhoneNumber.isPrimary) : new PhoneNumber('', false),
          node.mobilePhoneNumber?.value ? new PhoneNumber(node.mobilePhoneNumber.value, node.mobilePhoneNumber.isPrimary) : new PhoneNumber('', false),
          node.emailAddress,
          node.blogUrl,
          node.websiteUrl,
          node.twitterProfileUrl,
          node.instagramProfileUrl,
          node.linkedInProfileUrl,
          node.youtubeProfileUrl,
          node.facebookProfileUrl,
          node.isPrimary,
          node.city,
          node.postalCode
        )
    ),
    response.data.contact.comments?.map(
      (comment) =>
        new Comment(
          comment.id,
          new CommentUser(comment?.author?.teamsId, comment?.author?.name, comment?.author?.aadObjectId),
          comment.content,
          new Date(comment.createdAt),
          comment.replies?.map(
            (reply) =>
              new Comment(
                reply.id,
                new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
                reply.content,
                new Date(reply.createdAt),
                [],
                reply.mentions?.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
              )
          ),
          comment.mentions?.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
        )
    ),
    response.data.contact.blacklists.map((blacklist) => new Blacklist(blacklist.id, blacklist.name)),
    response.data.contact.preferredLanguages?.map((language) => new PreferredLanguage(language.id, language.name, language.dataSourceKey)),
    response.data.contact.ownContactData
      ? new OwnContactData(
          response.data.contact.ownContactData?.address,
          response.data.contact.ownContactData?.country,
          response.data.contact.ownContactData?.landlinePhoneNumber
            ? new PhoneNumber(
                response.data.contact.ownContactData?.landlinePhoneNumber.value,
                response.data.contact.ownContactData?.landlinePhoneNumber.isPrimary
              )
            : new PhoneNumber('', false),
          response.data.contact.ownContactData?.mobilePhoneNumber
            ? new PhoneNumber(response.data.contact.ownContactData?.mobilePhoneNumber.value, response.data.contact.ownContactData?.mobilePhoneNumber.isPrimary)
            : new PhoneNumber('', false),
          response.data.contact.ownContactData?.emailAddress,
          response.data.contact.ownContactData?.blogUrl,
          response.data.contact.ownContactData?.websiteUrl,
          response.data.contact.ownContactData?.twitterProfileUrl,
          response.data.contact.ownContactData?.instagramProfileUrl,
          response.data.contact.ownContactData?.linkedInProfileUrl,
          response.data.contact.ownContactData?.youtubeProfileUrl,
          response.data.contact.ownContactData?.facebookProfileUrl,
          response.data.contact.ownContactData?.isPrimary,
          response.data.contact.ownContactData?.city,
          response.data.contact.ownContactData?.postalCode,
          response.data.contact.ownContactData?.fax
        )
      : undefined,
    '',
    response.data.contact.isUser,
    response.data.contact.dataSourceStatus?.value
  );
};

export const fetchContactAvatarDetailsByContactId = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<AvatarDetails> => {
  const response = await client.query<FetchContactAvatarDetailsByContactIdQuery>({
    query: FetchContactAvatarDetailsByContactIdDocument,
    variables: {
      id: id
    }
  });

  return new AvatarDetails(`${response.data.contact?.firstName} ${response.data.contact?.lastName}`, response.data.contact?.profilePictureUrl);
};

export const fetchSelectedContactActivities = async (client: ApolloClient<NormalizedCacheObject>, contactId: string): Promise<Activity[]> => {
  const response = await client.query<FetchSelectedContactActivitiesQuery>({
    query: FetchSelectedContactActivitiesDocument,
    variables: {
      contactId: contactId
    }
  });

  return response.data.activitiesByContactId.map((activity: any) => {
    switch (activity.__typename.toString()) {
      case 'Call':
        return new Call(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date(activity.scheduledFor)
        );
      case 'Chat':
        return new Chat(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date(activity.scheduledFor)
        );
      case 'Email':
        return new Email(
          activity.id,
          activity.subject,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date(activity.scheduledFor)
        );
      case 'Event':
        return new Event(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      case 'Appointment':
        return new Appointment(
          activity.id,
          activity.subject,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      case 'ContactsSending':
        return createSending(activity as GqlSending);
      case 'Task':
        return new Task(
          activity.id,
          activity.subject,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as Task).deadline)
        );
      case 'Webinar':
        return new Webinar(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      default: {
        return new Webinar(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      }
    }
  });
};

export const fetchPagedSelectedContactActivities = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactId: string,
  pageNumber: number,
  pageSize: number,
  filterItems: FilterItem[]
): Promise<PageOfActivities> => {
  const response = await client.query<FetchPagedSelectedContactActivitiesQuery>({
    query: FetchPagedSelectedContactActivitiesDocument,
    variables: {
      contactId: contactId,
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      filterItems: filterItems
    }
  });

  const activities = response.data.activitiesByContactIdPaged.activities.map((activity: any) => {
    switch (activity.__typename.toString()) {
      case 'Call':
        return new Call(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date(activity.scheduledFor)
        );
      case 'Chat':
        return new Chat(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date(activity.scheduledFor)
        );
      case 'Email':
        return new Email(
          activity.id,
          activity.subject,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date(activity.scheduledFor)
        );
      case 'Event':
        return new Event(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      case 'Appointment':
        return new Appointment(
          activity.id,
          activity.subject,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      case 'ContactsSending':
        return createSending(activity as GqlSending);
      case 'Task':
        return new Task(
          activity.id,
          activity.subject,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as Task).deadline)
        );
      case 'Webinar':
        return new Webinar(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      default: {
        return new Webinar(
          activity.id,
          activity.comment,
          activity.title,
          activity.participantsIds,
          new Date(activity.createdAt),
          new ActivityUser(activity.createdByUser.id, activity.createdByUser.firstName, activity.createdByUser.lastName, ''),
          new Date((activity as any).startsAt),
          new Date((activity as any).endsAt)
        );
      }
    }
  });

  return new PageOfActivities(response.data.activitiesByContactIdPaged.totalCount, activities);
};

export const updateCollaboration = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, collaboration: Collaboration): Promise<Contact> => {
  const input: CollaborationWithIdInput = {
    id: collaboration.id,
    jobTitle: collaboration.jobTitle?.id
      ? {
          id: collaboration.jobTitle.id,
          dataSourceKey: collaboration.jobTitle.dataSourceKey,
          name: collaboration.jobTitle.name,
          mediumType: {
            name: MediumType[collaboration.jobTitle.mediumType],
            value: collaboration.jobTitle.mediumType
          }
        }
      : null,
    address: collaboration.address,
    country: collaboration.country,
    mobilePhoneNumber: collaboration.mobilePhoneNumber?.value
      ? {
          value: collaboration.mobilePhoneNumber.value,
          isPrimary: collaboration.mobilePhoneNumber.isPrimary
        }
      : null,
    landlinePhoneNumber: collaboration.landlinePhoneNumber?.value
      ? {
          value: collaboration.landlinePhoneNumber.value,
          isPrimary: collaboration.landlinePhoneNumber.isPrimary
        }
      : null,
    emailAddress: collaboration.emailAddress,
    blogUrl: collaboration.blogUrl,
    websiteUrl: collaboration.websiteUrl,
    twitterProfileUrl: collaboration.twitterProfileUrl,
    instagramProfileUrl: collaboration.instagramProfileUrl,
    linkedInProfileUrl: collaboration.linkedInProfileUrl,
    youtubeProfileUrl: collaboration.youtubeProfileUrl,
    facebookProfileUrl: collaboration.facebookProfileUrl,
    isPrimary: collaboration.isPrimary,
    medium: {
      id: collaboration.medium.id,
      name: collaboration.medium.name,
      type: {
        name: MediumTypeName(collaboration.medium.type),
        value: collaboration.medium.type
      }
    },
    jobDescription: collaboration.jobDescription,
    city: collaboration.city,
    postalCode: collaboration.postalCode
  };

  const response = await client.mutate<UpdateCollaborationMutation>({
    mutation: UpdateCollaborationDocument,
    variables: {
      contactId,
      input
    }
  });

  return createContact(response.data.updateCollaboration as GqlContact);
};

export const updateOwnContactData = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactId: string,
  ownContactData: OwnContactData
): Promise<Contact> => {
  const input: OwnContactDataInput = {
    address: ownContactData.address,
    blogUrl: ownContactData?.blogUrl,
    city: ownContactData?.city,
    country: ownContactData?.country,
    emailAddress: ownContactData?.emailAddress,
    facebookProfileUrl: ownContactData?.facebookProfileUrl,
    fax: ownContactData?.fax,
    instagramProfileUrl: ownContactData?.instagramProfileUrl,
    isPrimary: ownContactData?.isPrimary,
    linkedInProfileUrl: ownContactData?.linkedInProfileUrl,
    mobilePhoneNumber: ownContactData?.mobilePhoneNumber?.value ? ownContactData?.mobilePhoneNumber : null,
    landlinePhoneNumber: ownContactData?.landlinePhoneNumber?.value ? ownContactData?.landlinePhoneNumber : null,
    postalCode: ownContactData?.postalCode,
    twitterProfileUrl: ownContactData?.twitterProfileUrl,
    websiteUrl: ownContactData?.websiteUrl,
    xingUrl: '',
    youtubeProfileUrl: ownContactData?.youtubeProfileUrl
  };
  const response = await client.mutate<UpdateOwnContactDataMutation>({
    mutation: UpdateOwnContactDataDocument,
    variables: {
      contactId,
      input
    }
  });

  return createContact(response.data.updateOwnContactData as GqlContact);
};

export const removeCollaboration = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, collaborationId: string): Promise<Collaboration> => {
  const response = await client.mutate<RemoveCollaborationMutation>({
    mutation: RemoveCollaborationDocument,
    variables: {
      contactId,
      collaborationId
    }
  });

  return new Collaboration(
    response.data.removeCollaboration.id,
    new Medium(
      response.data.removeCollaboration.medium?.id,
      response.data.removeCollaboration.medium?.name,
      response.data.removeCollaboration.medium?.type.value
    ),
    response.data.removeCollaboration.jobTitle
      ? new JobTitle(
          response.data.removeCollaboration.jobTitle.id,
          response.data.removeCollaboration.jobTitle.name,
          response.data.removeCollaboration.jobTitle.dataSourceKey,
          response.data.removeCollaboration.jobTitle.mediumType.value
        )
      : ({} as JobTitle),
    response.data.removeCollaboration.jobDescription,
    response.data.removeCollaboration.address,
    response.data.removeCollaboration.country,
    response.data.removeCollaboration.landlinePhoneNumber
      ? new PhoneNumber(response.data.removeCollaboration.landlinePhoneNumber.value, response.data.removeCollaboration.landlinePhoneNumber.isPrimary)
      : ({} as PhoneNumber),
    response.data.removeCollaboration.mobilePhoneNumber
      ? new PhoneNumber(response.data.removeCollaboration.mobilePhoneNumber.value, response.data.removeCollaboration.mobilePhoneNumber.isPrimary)
      : ({} as PhoneNumber),
    response.data.removeCollaboration.emailAddress,
    response.data.removeCollaboration.blogUrl,
    response.data.removeCollaboration.websiteUrl,
    response.data.removeCollaboration.twitterProfileUrl,
    response.data.removeCollaboration.instagramProfileUrl,
    response.data.removeCollaboration.linkedInProfileUrl,
    response.data.removeCollaboration.youtubeProfileUrl,
    response.data.removeCollaboration.facebookProfileUrl,
    response.data.removeCollaboration.isPrimary,
    response.data.removeCollaboration.city,
    response.data.removeCollaboration.postalCode
  );
};

export const addCollaboration = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactId: string,
  collaboration: Collaboration
): Promise<Collaboration> => {
  const input: CollaborationInput = {
    jobTitle: collaboration.jobTitle
      ? {
          id: collaboration.jobTitle?.id,
          dataSourceKey: collaboration.jobTitle?.dataSourceKey,
          name: collaboration.jobTitle?.name,
          mediumType: collaboration.jobTitle.mediumType
            ? {
                name: collaboration.jobTitle?.mediumType ? MediumTypeName(collaboration.jobTitle?.mediumType) : '',
                value: collaboration.jobTitle?.mediumType
              }
            : undefined
        }
      : undefined,
    address: collaboration.address,
    country: collaboration.country,
    mobilePhoneNumber: collaboration.mobilePhoneNumber
      ? {
          value: collaboration.mobilePhoneNumber?.value,
          isPrimary: collaboration.mobilePhoneNumber?.isPrimary
        }
      : undefined,
    landlinePhoneNumber: collaboration.landlinePhoneNumber
      ? { value: collaboration.landlinePhoneNumber?.value, isPrimary: collaboration.landlinePhoneNumber?.isPrimary }
      : undefined,
    emailAddress: collaboration.emailAddress,
    blogUrl: collaboration.blogUrl,
    websiteUrl: collaboration.websiteUrl,
    twitterProfileUrl: collaboration.twitterProfileUrl,
    instagramProfileUrl: collaboration.instagramProfileUrl,
    linkedInProfileUrl: collaboration.linkedInProfileUrl,
    youtubeProfileUrl: collaboration.youtubeProfileUrl,
    facebookProfileUrl: collaboration.facebookProfileUrl,
    isPrimary: collaboration.isPrimary,
    medium: {
      id: collaboration.medium.id,
      name: collaboration.medium.name,
      type: {
        name: MediumTypeName(collaboration.medium.type),
        value: collaboration.medium.type
      }
    },
    jobDescription: collaboration.jobDescription,
    city: collaboration.city,
    postalCode: collaboration.postalCode
  };

  const response = await client.mutate<AddCollaborationMutation>({
    mutation: AddCollaborationDocument,
    variables: {
      contactId,
      input
    }
  });

  return new Collaboration(
    response.data.addCollaboration.id,
    new Medium(response.data.addCollaboration.medium.id, response.data.addCollaboration.medium.name, response.data.addCollaboration.medium.type.value),
    response.data.addCollaboration.jobTitle?.name
      ? new JobTitle(
          response.data.addCollaboration.jobTitle.id,
          response.data.addCollaboration.jobTitle.name,
          response.data.addCollaboration.jobTitle.dataSourceKey,
          response.data.addCollaboration.jobTitle.mediumType.value
        )
      : ({} as JobTitle),
    response.data.addCollaboration.jobDescription,
    response.data.addCollaboration.address,
    response.data.addCollaboration.country,
    response.data.addCollaboration.landlinePhoneNumber?.value
      ? new PhoneNumber(response.data.addCollaboration.landlinePhoneNumber.value, response.data.addCollaboration.landlinePhoneNumber.isPrimary)
      : ({} as PhoneNumber),
    response.data.addCollaboration.mobilePhoneNumber?.value
      ? new PhoneNumber(response.data.addCollaboration.mobilePhoneNumber.value, response.data.addCollaboration.mobilePhoneNumber.isPrimary)
      : ({} as PhoneNumber),
    response.data.addCollaboration.emailAddress,
    response.data.addCollaboration.blogUrl,
    response.data.addCollaboration.websiteUrl,
    response.data.addCollaboration.twitterProfileUrl,
    response.data.addCollaboration.instagramProfileUrl,
    response.data.addCollaboration.linkedInProfileUrl,
    response.data.addCollaboration.youtubeProfileUrl,
    response.data.addCollaboration.facebookProfileUrl,
    response.data.addCollaboration.isPrimary,
    response.data.addCollaboration.city,
    response.data.addCollaboration.postalCode
  );
};

export const addCollaborations = async (
  client: ApolloClient<NormalizedCacheObject>,
  collaborationsWithContactIds: CollaborationWithContactId[]
): Promise<Collaboration[]> => {
  const input: CollaborationWithContactIdInput[] = collaborationsWithContactIds.map((collabPair) => {
    const collaboration = collabPair.collaboration;

    const collaborationToAdd = {
      jobTitle: collaboration.jobTitle
        ? {
            id: collaboration.jobTitle?.id,
            dataSourceKey: collaboration.jobTitle?.dataSourceKey,
            name: collaboration.jobTitle?.name,
            mediumType: collaboration.jobTitle.mediumType
              ? {
                  name: collaboration.jobTitle?.mediumType ? MediumTypeName(collaboration.jobTitle?.mediumType) : '',
                  value: collaboration.jobTitle?.mediumType
                }
              : undefined
          }
        : undefined,
      address: collaboration.address,
      country: collaboration.country,
      mobilePhoneNumber: collaboration.mobilePhoneNumber
        ? {
            value: collaboration.mobilePhoneNumber?.value,
            isPrimary: collaboration.mobilePhoneNumber?.isPrimary
          }
        : undefined,
      landlinePhoneNumber: collaboration.landlinePhoneNumber
        ? { value: collaboration.landlinePhoneNumber?.value, isPrimary: collaboration.landlinePhoneNumber?.isPrimary }
        : undefined,
      emailAddress: collaboration.emailAddress,
      blogUrl: collaboration.blogUrl,
      websiteUrl: collaboration.websiteUrl,
      twitterProfileUrl: collaboration.twitterProfileUrl,
      instagramProfileUrl: collaboration.instagramProfileUrl,
      linkedInProfileUrl: collaboration.linkedInProfileUrl,
      youtubeProfileUrl: collaboration.youtubeProfileUrl,
      facebookProfileUrl: collaboration.facebookProfileUrl,
      isPrimary: collaboration.isPrimary,
      medium: {
        id: collaboration.medium.id,
        name: collaboration.medium.name,
        type: {
          name: MediumTypeName(collaboration.medium.type),
          value: collaboration.medium.type
        }
      },
      jobDescription: collaboration.jobDescription,
      city: collaboration.city,
      postalCode: collaboration.postalCode
    };

    return { contactId: collabPair.contactId, collaboration: collaborationToAdd };
  });

  const response = await client.mutate<AddCollaborationsMutation>({
    mutation: AddCollaborationsDocument,
    variables: {
      input
    }
  });

  return response.data.addCollaborations.map(
    (collaboration) =>
      new Collaboration(
        collaboration.id,
        new Medium(collaboration.medium.id, collaboration.medium.name, collaboration.medium.type.value),
        collaboration.jobTitle?.name
          ? new JobTitle(collaboration.jobTitle.id, collaboration.jobTitle.name, collaboration.jobTitle.dataSourceKey, collaboration.jobTitle.mediumType.value)
          : ({} as JobTitle),
        collaboration.jobDescription,
        collaboration.address,
        collaboration.country,
        collaboration.landlinePhoneNumber?.value
          ? new PhoneNumber(collaboration.landlinePhoneNumber.value, collaboration.landlinePhoneNumber.isPrimary)
          : ({} as PhoneNumber),
        collaboration.mobilePhoneNumber?.value
          ? new PhoneNumber(collaboration.mobilePhoneNumber.value, collaboration.mobilePhoneNumber.isPrimary)
          : ({} as PhoneNumber),
        collaboration.emailAddress,
        collaboration.blogUrl,
        collaboration.websiteUrl,
        collaboration.twitterProfileUrl,
        collaboration.instagramProfileUrl,
        collaboration.linkedInProfileUrl,
        collaboration.youtubeProfileUrl,
        collaboration.facebookProfileUrl,
        collaboration.isPrimary,
        collaboration.city,
        collaboration.postalCode
      )
  );
};

export const fetchAllTopics = async (client: ApolloClient<NormalizedCacheObject>): Promise<Topic[]> => {
  const response = await client.query<FetchAllTopicsQuery>({
    query: FetchAllTopicsDocument
  });

  return response.data.topics.map((t) => new Topic(t.id, t.name, t.dataSourceKey));
};

export const fetchAllMediaResorts = async (client: ApolloClient<NormalizedCacheObject>): Promise<MediaResort[]> => {
  const response = await client.query<FetchAllMediaResortsQuery>({
    query: FetchAllMediaResortsDocument
  });

  return response.data.mediaResorts.map((mr) => new MediaResort(mr.id, mr.name, mr.dataSourceKey));
};

export const addTopicToContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, topicId: string): Promise<Topic> => {
  const response = await client.mutate<AddTopicToContactMutation>({
    mutation: AddTopicToContactDocument,
    variables: {
      contactId,
      topicId
    }
  });

  return new Topic(response.data.addTopicToContact.id, response.data.addTopicToContact.name, response.data.addTopicToContact.dataSourceKey);
};

export const removeTopicFromContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, topicId: string): Promise<Topic> => {
  const response = await client.mutate<RemoveTopicFromContactMutation>({
    mutation: RemoveTopicFromContactDocument,
    variables: {
      contactId,
      topicId
    }
  });

  return new Topic(response.data.removeTopicFromContact.id, response.data.removeTopicFromContact.name, response.data.removeTopicFromContact.dataSourceKey);
};

export const addMediaResortToContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, mediaResortId: string): Promise<MediaResort> => {
  const response = await client.mutate<AddMediaResortToContactMutation>({
    mutation: AddMediaResortToContactDocument,
    variables: {
      contactId,
      mediaResortId
    }
  });

  return new MediaResort(
    response.data.addMediaResortToContact.id,
    response.data.addMediaResortToContact.name,
    response.data.addMediaResortToContact.dataSourceKey
  );
};

export const removeMediaResortFromContact = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactId: string,
  mediaResortId: string
): Promise<MediaResort> => {
  const response = await client.mutate<RemoveMediaResortFromContactMutation>({
    mutation: RemoveMediaResortFromContactDocument,
    variables: {
      contactId,
      mediaResortId
    }
  });

  return new MediaResort(
    response.data.removeMediaResortFromContact.id,
    response.data.removeMediaResortFromContact.name,
    response.data.removeMediaResortFromContact.dataSourceKey
  );
};

export const addListToContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, listId: string): Promise<List> => {
  const response = await client.mutate<AddListToContactMutation>({
    mutation: AddListToContactDocument,
    variables: {
      contactId: contactId,
      listId: listId
    }
  });

  return createList(response.data.addListToContact);
};

export const removeListFromContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, listId: string): Promise<List> => {
  const response = await client.mutate<RemoveListFromContactMutation>({
    mutation: RemoveListFromContactDocument,
    variables: {
      contactId: contactId,
      listId: listId
    }
  });

  return createList(response.data.removeListFromContact);
};

export const updateProfilePicture = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactId: string,
  profilePicture: ContactProfilePicture
): Promise<string> => {
  const input: ContactsProfilePictureInput = {
    mimeType: profilePicture.mimeType,
    data: profilePicture.data,
    fileExtension: profilePicture.fileExtension
  };

  const response = await client.mutate<UpdateProfilePictureMutation>({
    mutation: UpdateProfilePictureDocument,
    variables: {
      contactId,
      input
    }
  });

  return response.data.updateProfilePicture;
};

export const removeProfilePicture = async (client: ApolloClient<NormalizedCacheObject>, contactId: string): Promise<string> => {
  const response = await client.mutate<RemoveProfilePictureMutation>({
    mutation: RemoveProfilePictureDocument,
    variables: {
      contactId
    }
  });

  return response.data.removeProfilePicture;
};

export const updateContact = async (client: ApolloClient<NormalizedCacheObject>, id: string, contactInput: ContactUpdateModel): Promise<ContactUpdateModel> => {
  const input: ContactInput = {
    firstName: contactInput.firstName,
    lastName: contactInput.lastName,
    salutation: contactInput.salutation,
    isGloballySignedOut: contactInput.isGloballySignedOut
  };

  const response = await client.mutate<UpdateContactMutation>({
    mutation: UpdateContactDocument,
    variables: {
      id,
      input
    }
  });

  return new ContactUpdateModel(
    response.data.updateContact.salutation,
    response.data.updateContact.firstName,
    response.data.updateContact.lastName,
    response.data.updateContact.isGloballySignedOut
  );
};

export const removeContact = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<string> => {
  const response = await client.mutate<RemoveContactMutation>({
    mutation: RemoveContactDocument,
    variables: {
      id
    }
  });

  return response.data.removeContact.id;
};

export const addCommentToContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, comment: Comment): Promise<Comment> => {
  const input: ContactsCommentInput = {
    author: { teamsId: comment.author.teamsId, name: comment.author.name, aadObjectId: comment.author.aadObjectId },
    content: comment.content,
    createdAt: comment.createdAt,
    mentions: comment?.mentions?.map((m) => {
      return { teamsId: m.teamsId, name: m.name, aadObjectId: m.aadObjectId };
    }),
    replies: comment.replies
  };

  const response = await client.mutate<AddCommentToContactMutation>({
    mutation: AddCommentToContactDocument,
    variables: {
      contactId,
      input
    }
  });

  return new Comment(
    response.data.addCommentToContact.id,
    new CommentUser(
      response.data.addCommentToContact?.author?.teamsId,
      response.data.addCommentToContact?.author?.name,
      response.data.addCommentToContact?.author?.aadObjectId
    ),
    response.data.addCommentToContact.content,
    new Date(response.data.addCommentToContact.createdAt),
    response.data.addCommentToContact?.replies.map(
      (reply) =>
        new Comment(
          reply.id,
          new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
          reply.content,
          new Date(reply.createdAt),
          [],
          reply.mentions.map((m) => new CommentUser(m.teamsId, m.name, m.aadObjectId))
        )
    ),
    response.data.addCommentToContact?.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
  );
};

export const removeCommentFromContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, commentId: string): Promise<Comment> => {
  const response = await client.mutate<RemoveCommentFromContactMutation>({
    mutation: RemoveCommentFromContactDocument,
    variables: {
      contactId: contactId,
      commentId: commentId
    }
  });

  return new Comment(
    response.data.removeCommentFromContact.id,
    new CommentUser(
      response.data.removeCommentFromContact?.author?.teamsId,
      response.data.removeCommentFromContact?.author?.name,
      response.data.removeCommentFromContact?.author?.aadObjectId
    ),
    response.data.removeCommentFromContact.content,
    new Date(response.data.removeCommentFromContact.createdAt),
    response.data.removeCommentFromContact?.replies.map(
      (reply) =>
        new Comment(
          reply.id,
          new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
          reply.content,
          new Date(reply.createdAt),
          [],
          reply.mentions.map((m) => new CommentUser(m.teamsId, m.name, m.aadObjectId))
        )
    ),
    response.data.removeCommentFromContact?.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
  );
};

export const updateComment = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, comment: Comment): Promise<Comment> => {
  const input: ContactsCommentWithIdInput = {
    id: comment.id,
    author: {
      teamsId: comment.author.teamsId,
      name: comment.author.name,
      aadObjectId: comment.author.aadObjectId
    },
    content: comment.content,
    createdAt: comment.createdAt,
    replies: comment?.replies,
    mentions: comment?.mentions.map((m) => {
      return { teamsId: m.teamsId, name: m.name, aadObjectId: m.aadObjectId };
    })
  };

  const response = await client.mutate<UpdateCommentMutation>({
    mutation: UpdateCommentDocument,
    variables: {
      contactId,
      input
    }
  });

  return new Comment(
    response.data.updateComment.id,
    new CommentUser(response.data.updateComment?.author?.teamsId, response.data.updateComment?.author?.name, response.data.updateComment?.author?.aadObjectId),
    response.data.updateComment.content,
    new Date(response.data.updateComment.createdAt),
    response.data.updateComment?.replies.map(
      (reply) =>
        new Comment(
          reply.id,
          new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
          reply.content,
          new Date(reply.createdAt),
          [],
          reply.mentions.map((m) => new CommentUser(m.teamsId, m.name, m.aadObjectId))
        )
    ),
    response.data.updateComment?.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
  );
};

export const logActivity = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  activity: Email | Call | Appointment | Webinar | Event | Chat | Task,
  signedInUserId
): Promise<Activity> => {
  if (activity instanceof Email) {
    const input: EmailInput = {
      title: activity.title,
      comment: activity.comment,
      subject: activity.subject,
      id: activity.id,
      scheduledFor: activity.scheduledFor
    };
    const response = await client.mutate<AddEmailMutation>({
      mutation: AddEmailDocument,
      variables: {
        signedInUserId: signedInUserId,
        contactId: id,
        input
      }
    });

    return new Email(
      response.data.addEmail.id,
      (response.data.addEmail as Email).subject,
      response.data.addEmail.comment,
      response.data.addEmail.title,
      response.data.addEmail.participants.map(
        (participant) =>
          new ActivityParticipant(
            participant.id,
            new Contact(
              participant.contact.id,
              '',
              participant.contact.firstName,
              participant.contact.lastName,
              '',
              false,
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              null,
              ''
            ),
            participant.collaborationId
          )
      ),
      new Date(response.data.addEmail.createdAt),
      new ActivityUser(
        response.data.addEmail.createdByUser.id,
        response.data.addEmail.createdByUser.firstName,
        response.data.addEmail.createdByUser.lastName,
        ''
      ),
      new Date(response.data.addEmail.scheduledFor)
    );
  } else if (activity instanceof Call) {
    const input: CallInput = {
      title: activity.title,
      comment: activity.comment,
      id: activity.id,
      scheduledFor: activity.scheduledFor
    };
    const response = await client.mutate<AddCallMutation>({
      mutation: AddCallDocument,
      variables: {
        contactId: id,
        signedInUserId: signedInUserId,
        input
      }
    });

    return new Call(
      response.data.addCall.id,
      response.data.addCall.comment,
      response.data.addCall.title,
      response.data.addCall.participants.map(
        (participant) =>
          new ActivityParticipant(
            participant.id,
            new Contact(
              participant.contact.id,
              '',
              participant.contact.firstName,
              participant.contact.lastName,
              '',
              false,
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              null,
              ''
            ),
            participant.collaborationId
          )
      ),
      new Date(response.data.addCall.createdAt),
      new ActivityUser(response.data.addCall.createdByUser.id, response.data.addCall.createdByUser.firstName, response.data.addCall.createdByUser.lastName, ''),
      new Date(response.data.addCall.scheduledFor)
    );
  } else if (activity instanceof Appointment) {
    const input: AppointmentInput = {
      title: activity.title,
      comment: activity.comment,
      id: activity.id,
      startsAt: activity.startsAt,
      endsAt: activity.endsAt,
      subject: activity.subject
    };
    const response = await client.mutate<AddAppointmentMutation>({
      mutation: AddAppointmentDocument,
      variables: {
        contactId: id,
        signedInUserId,
        input
      }
    });

    return new Appointment(
      response.data.addAppointment.id,
      (response.data.addAppointment as Appointment).subject,
      response.data.addAppointment.comment,
      response.data.addAppointment.title,
      response.data.addAppointment.participants.map(
        (participant) =>
          new ActivityParticipant(
            participant.id,
            new Contact(
              participant.contact.id,
              '',
              participant.contact.firstName,
              participant.contact.lastName,
              '',
              false,
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              null,
              ''
            ),
            participant.collaborationId
          )
      ),
      new Date(response.data.addAppointment.createdAt),
      new ActivityUser(
        response.data.addAppointment.createdByUser.id,
        response.data.addAppointment.createdByUser.firstName,
        response.data.addAppointment.createdByUser.lastName,
        ''
      ),
      new Date((response.data.addAppointment as Appointment).startsAt),
      new Date((response.data.addAppointment as Appointment).endsAt)
    );
  } else if (activity instanceof Webinar) {
    const input: WebinarInput = {
      title: activity.title,
      comment: activity.comment,
      id: activity.id,
      startsAt: activity.startsAt,
      endsAt: activity.endsAt
    };
    const response = await client.mutate<AddWebinarMutation>({
      mutation: AddWebinarDocument,
      variables: {
        contactId: id,
        input,
        signedInUserId
      }
    });
    return new Webinar(
      response.data.addWebinar.id,
      response.data.addWebinar.comment,
      response.data.addWebinar.title,
      response.data.addWebinar.participants.map(
        (participant) =>
          new ActivityParticipant(
            participant.id,
            new Contact(
              participant.contact.id,
              '',
              participant.contact.firstName,
              participant.contact.lastName,
              '',
              false,
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              null,
              ''
            ),
            participant.collaborationId
          )
      ),
      new Date(response.data.addWebinar.createdAt),
      new ActivityUser(
        response.data.addWebinar.createdByUser.id,
        response.data.addWebinar.createdByUser.firstName,
        response.data.addWebinar.createdByUser.lastName,
        ''
      ),
      new Date((response.data.addWebinar as Webinar).startsAt),
      new Date((response.data.addWebinar as Webinar).endsAt)
    );
  } else if (activity instanceof Event) {
    const input: EventInput = {
      title: activity.title,
      comment: activity.comment,
      id: activity.id,
      startsAt: activity.startsAt,
      endsAt: activity.endsAt
    };
    const response = await client.mutate<AddEventMutation>({
      mutation: AddEventDocument,
      variables: {
        contactId: id,
        input,
        signedInUserId
      }
    });

    return new Event(
      response.data.addEvent.id,
      response.data.addEvent.comment,
      response.data.addEvent.title,
      response.data.addEvent.participants.map(
        (participant) =>
          new ActivityParticipant(
            participant.id,
            new Contact(
              participant.contact.id,
              '',
              participant.contact.firstName,
              participant.contact.lastName,
              '',
              false,
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              null,
              ''
            ),
            participant.collaborationId
          )
      ),
      new Date(response.data.addEvent.createdAt),
      new ActivityUser(
        response.data.addEvent.createdByUser.id,
        response.data.addEvent.createdByUser.firstName,
        response.data.addEvent.createdByUser.lastName,
        ''
      ),
      new Date((response.data.addEvent as Event).startsAt),
      new Date((response.data.addEvent as Event).endsAt)
    );
  } else if (activity instanceof Chat) {
    const input: ChatInput = {
      title: activity.title,
      comment: activity.comment,
      id: activity.id,
      scheduledFor: activity.scheduledFor
    };
    const response = await client.mutate<AddChatMutation>({
      mutation: AddChatDocument,
      variables: {
        contactId: id,
        input,
        signedInUserId
      }
    });

    return new Chat(
      response.data.addChat.id,
      response.data.addChat.comment,
      response.data.addChat.title,
      response.data.addChat.participants.map(
        (participant) =>
          new ActivityParticipant(
            participant.id,
            new Contact(
              participant.contact.id,
              '',
              participant.contact.firstName,
              participant.contact.lastName,
              '',
              false,
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              null,
              ''
            ),
            participant.collaborationId
          )
      ),
      new Date(response.data.addChat.createdAt),
      new ActivityUser(response.data.addChat.createdByUser.id, response.data.addChat.createdByUser.firstName, response.data.addChat.createdByUser.lastName, ''),
      new Date(response.data.addChat.scheduledFor)
    );
  } else {
    const input: TaskInput = {
      title: activity.title,
      comment: activity.comment,
      id: activity.id,
      deadline: activity.deadline,
      subject: activity.subject
    };
    const response = await client.mutate<AddTaskMutation>({
      mutation: AddTaskDocument,
      variables: {
        contactId: id,
        input,
        signedInUserId
      }
    });

    return new Task(
      response.data.addTask.id,
      (response.data.addTask as Task).subject,
      response.data.addTask.comment,
      response.data.addTask.title,
      response.data.addTask.participants.map(
        (participant) =>
          new ActivityParticipant(
            participant.id,
            new Contact(
              participant.contact.id,
              '',
              participant.contact.firstName,
              participant.contact.lastName,
              '',
              false,
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              null,
              ''
            ),
            participant.collaborationId
          )
      ),
      new Date(response.data.addTask.createdAt),
      new ActivityUser(response.data.addTask.createdByUser.id, response.data.addTask.createdByUser.firstName, response.data.addTask.createdByUser.lastName, ''),
      new Date((response.data.addTask as Task).deadline)
    );
  }
};

export const addBlacklistToContact = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, blacklistToAdd: Blacklist): Promise<Blacklist> => {
  const blacklist: BlacklistInput = {
    id: blacklistToAdd.id,
    name: blacklistToAdd.name
  };
  const response = await client.mutate<AddBlacklistToContactMutation>({
    mutation: AddBlacklistToContactDocument,
    variables: {
      contactId,
      blacklist
    }
  });

  return blacklistToAdd;
};

export const removeBlacklistFromContact = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactId: string,
  blacklistToRemove: Blacklist
): Promise<Blacklist> => {
  const blacklist: BlacklistInput = {
    id: blacklistToRemove.id,
    name: blacklistToRemove.name
  };

  const response = await client.mutate<RemoveBlacklistFromContactMutation>({
    mutation: RemoveBlacklistFromContactDocument,
    variables: {
      contactId,
      blacklist
    }
  });

  return blacklistToRemove;
};

export const addNewBlacklist = async (client: ApolloClient<NormalizedCacheObject>, contactId: string, blacklistName: string): Promise<Blacklist> => {
  const input: BlacklistCreationInput = {
    name: blacklistName,
    entityId: contactId,
    entityType: 'Contact'
  };

  const response = await client.mutate<CreateAndAssignBlacklistMutation>({
    mutation: CreateAndAssignBlacklistDocument,
    variables: {
      input
    }
  });

  return new Blacklist(response.data.createAndAssignBlacklist.id, response.data.createAndAssignBlacklist.name);
};

export const fetchAllJobTitles = async (client: ApolloClient<NormalizedCacheObject>): Promise<JobTitle[]> => {
  const response = await client.query<FetchAllJobTitlesQuery>({
    query: FetchAllJobTitlesDocument
  });

  return response.data.jobTitles.map((jobTitle) => new JobTitle(jobTitle.id, jobTitle.name, jobTitle.dataSourceKey, jobTitle.mediumType.value));
};

export const fetchListsByContactId = async (client: ApolloClient<NormalizedCacheObject>, contactId: string): Promise<List[]> => {
  const response = await client.query<FetchListsByContactIdQuery>({
    query: FetchListsByContactIdDocument,
    variables: {
      contactId
    }
  });

  return response.data.listsByContactId.map((list) => createList(list));
};

export const fetchContactProfilePanelData = async (client: ApolloClient<NormalizedCacheObject>, id: string) => {
  const response = await client.query<FetchContactsProfilePanelDataQuery>({
    query: FetchContactsProfilePanelDataDocument,
    variables: {
      id: id
    }
  });

  const allTopics = response.data.contact.topics.map((t) => new Topic(t.topicId, t.topicName, t.dataSourceKey));
  const allMediaResorts = response.data.contact.mediaResorts.map((mr) => new MediaResort(mr.mediaResortId, mr.mediaResortName, mr.dataSourceKey));

  const selectedContactInfo = new Contact(
    response.data.contact.id,
    response.data.contact.salutation,
    response.data.contact.firstName,
    response.data.contact.lastName,
    response.data.contact.profilePictureUrl,
    response.data.contact.isGloballySignedOut,
    response.data.contact.tags.map((node) => new Tag(node.id, node.name, '')),
    response.data.contact.topics.map((node) => new Topic(node.topicId, node.topicName, node.dataSourceKey)),
    response.data.contact.mediaResorts.map((node) => new MediaResort(node.mediaResortId, node.mediaResortName, node.dataSourceKey)),
    response.data.contact.collaborations
      ? response.data.contact.collaborations.map(
          (node) =>
            new Collaboration(
              node.id,
              node.medium?.id ? new Medium(node.medium?.id, node.medium?.name, node.medium?.type.value) : ({} as Medium),
              node.jobTitle?.id
                ? new JobTitle(node.jobTitle?.id, node.jobTitle?.name, node.jobTitle?.dataSourceKey, node.jobTitle?.mediumType?.value)
                : ({} as JobTitle),
              node.jobDescription,
              node.address,
              node.country,
              node.landlinePhoneNumber ? new PhoneNumber(node.landlinePhoneNumber.value, node.landlinePhoneNumber.isPrimary) : ({} as PhoneNumber),
              node.mobilePhoneNumber ? new PhoneNumber(node.mobilePhoneNumber.value, node.mobilePhoneNumber.isPrimary) : ({} as PhoneNumber),
              node.emailAddress,
              node.blogUrl,
              node.websiteUrl,
              node.twitterProfileUrl,
              node.instagramProfileUrl,
              node.linkedInProfileUrl,
              node.youtubeProfileUrl,
              node.facebookProfileUrl,
              node.isPrimary,
              node.city,
              node.postalCode
            )
        )
      : [],
    response.data.contact.comments?.map(
      (comment) =>
        new Comment(
          comment.id,
          new CommentUser(comment?.author?.teamsId, comment?.author?.name, comment?.author?.aadObjectId),
          comment.content,
          new Date(comment.createdAt),
          comment.replies?.map(
            (reply) =>
              new Comment(
                reply.id,
                new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
                reply.content,
                new Date(reply.createdAt),
                [],
                reply.mentions?.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
              )
          ),
          comment.mentions?.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
        )
    ),
    response.data.contact.blacklists?.length > 0 ? response.data.contact.blacklists.map((blacklist) => new Blacklist(blacklist.id, blacklist.name)) : [],
    [],
    response.data.contact.ownContactData
      ? new OwnContactData(
          response.data.contact.ownContactData?.address,
          response.data.contact.ownContactData?.country,
          response.data.contact.ownContactData?.landlinePhoneNumber
            ? new PhoneNumber(
                response.data.contact.ownContactData.landlinePhoneNumber.value,
                response.data.contact.ownContactData.landlinePhoneNumber.isPrimary
              )
            : ({} as PhoneNumber),
          response.data.contact.ownContactData?.mobilePhoneNumber
            ? new PhoneNumber(response.data.contact.ownContactData.mobilePhoneNumber.value, response.data.contact.ownContactData.mobilePhoneNumber.isPrimary)
            : ({} as PhoneNumber),
          response.data.contact.ownContactData?.emailAddress,
          response.data.contact.ownContactData?.blogUrl,
          response.data.contact.ownContactData?.websiteUrl,
          response.data.contact.ownContactData?.twitterProfileUrl,
          response.data.contact.ownContactData?.instagramProfileUrl,
          response.data.contact.ownContactData?.linkedInProfileUrl,
          response.data.contact.ownContactData?.youtubeProfileUrl,
          response.data.contact.ownContactData?.facebookProfileUrl,
          response.data.contact.ownContactData?.isPrimary,
          response.data.contact.ownContactData?.city,
          response.data.contact.ownContactData?.postalCode,
          response.data.contact.ownContactData?.fax
        )
      : undefined,
    undefined,
    response.data.contact.isUser,
    response.data.contact.dataSourceStatus?.value
  );
  //todo get all blacklists
  return new ContactProfilePanelData(selectedContactInfo, allTopics, allMediaResorts, []);
};

export const fetchActivityEmailAddress = async (client: ApolloClient<NormalizedCacheObject>): Promise<string> => {
  const response = await client.query<FetchActivityEmailAddressQuery>({
    query: FetchActivityEmailAddressDocument
  });

  return response.data.activityEmailAddress;
};

export const fetchMediaOutletsByNameAndCategoryAndNotContainedInContact = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  name: string,
  category: string,
  contactId: string
): Promise<PageOfMediaOutlets> => {
  const response = await client.query<FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery>({
    query: FetchMediaOutletsByNameAndCategoryAndNotContainedInContactDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      name: name,
      category: category,
      contactId: contactId
    }
  });

  return new PageOfMediaOutlets(
    response.data.mediaOutletsByNameAndCategoryAndNotContainedInContact?.totalCount,
    response.data.mediaOutletsByNameAndCategoryAndNotContainedInContact?.mediaOutlets.map((mediaOutlet: GqlMediaOutlet) => createMediaOutlet(mediaOutlet))
  );
};

export const fetchCompaniesByNameAndCategoryAndNotContainedInContact = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  name: string,
  category: string,
  contactId: string
): Promise<PageOfCompanies> => {
  const response = await client.query<FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery>({
    query: FetchCompaniesByNameAndCategoryAndNotContainedInContactDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      name: name,
      category: category,
      contactId: contactId
    }
  });

  return new PageOfCompanies(
    response.data.companiesByNameAndCategoryAndNotContainedInContact?.totalCount,
    response.data.companiesByNameAndCategoryAndNotContainedInContact?.companies.map((company: GqlCompany) => createCompany(company))
  );
};

export const removeTopics = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<string[]> => {
  const response = await client.mutate<RemoveTopicsMutation>({
    mutation: RemoveTopicsDocument,
    variables: {
      ids
    }
  });

  return response.data.removeTopics;
};

export const removeMediaResorts = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<string[]> => {
  const response = await client.mutate<DeleteMediaResortsMutation>({
    mutation: DeleteMediaResortsDocument,
    variables: {
      ids
    }
  });

  return response.data.deleteMediaResorts;
};

export const fetchContactHasTeamsEnabledByEmail = async (signedInUserEmailAddress: string, contactEmailAddress: string): Promise<boolean> => {
  return httpClient
    .get(`${process.env.REACT_APP_CONTACTS_URL}/api/teams/hasAccountByEmail`, {
      params: {
        signedInUserEmailAddress,
        contactEmailAddress
      }
    })
    .then((response) => {
      return Boolean(response.data);
    });
};

export const removeBlacklists = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<string[]> => {
  const response = await client.mutate<DeleteBlacklistsMutation>({
    mutation: DeleteBlacklistsDocument,
    variables: {
      ids
    }
  });
  return response.data.deleteBlacklists;
};

export const fetchSendingsByContactId = async (client: ApolloClient<NormalizedCacheObject>, contactId: string): Promise<Sending[]> => {
  const response = await client.query<FetchSendingsByContactIdQuery>({
    query: FetchSendingsByContactIdDocument,
    variables: {
      contactId: contactId
    }
  });

  return response.data.sendingsByContactId.map((sending) => createSending(sending));
};
