import { Panel, PanelType } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { AddSavedSearches, closeAddSavedSearchesPanel, selectIsAddSavedSearchesPanelOpened } from 'app/pages/my-audience/lists-profile';

export const LinkToSavedSearchPanel = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const isPanelOpen = useAppSelector<boolean>(selectIsAddSavedSearchesPanelOpened);

  const handleDismissFilterPanel = () => {
    dispatch(closeAddSavedSearchesPanel());
  };

  return (
    <div className="wrapper">
      <Panel
        isBlocking={false}
        customWidth="700px"
        className="filter-panel"
        headerText={formatMessage({ id: 'list-profile-saved-searches.add-saved-search' })}
        isOpen={isPanelOpen}
        onDismiss={handleDismissFilterPanel}
        closeButtonAriaLabel="Close"
        type={PanelType.custom}
      >
        <AddSavedSearches onClosePanel={handleDismissFilterPanel} />
      </Panel>
    </div>
  );
};
