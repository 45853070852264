import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { closeAddContactWizard, selectIsAddContactWizardPanelOpened } from 'app/pages/my-activities/sending-wizard';
import { AddContacts } from 'app/pages/my-activities/sending-wizard';

export const AddContactsWizard = () => {
  const dispatch = useAppDispatch();
  const isPanelOpened = useAppSelector<boolean>(selectIsAddContactWizardPanelOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeAddContactWizard());
  };

  return (
    <WizardWrap
      isWizardOpened={isPanelOpened}
      onDismissButtonClick={handleDismissButtonClick}
      customWidth="750px"
      classModifier="narrow"
      hasBackArrow={true}
      onArrowButtonClick={handleDismissButtonClick}
      isLightDismiss={false}
    >
      <div className="wizard-panel-main">
        <AddContacts />
      </div>
    </WizardWrap>
  );
};
