import { ITableData, TagProjection } from 'app/common';
import { ListsSortOption as GQLListsSortOption, ContactsSortOrder as GQLListsSortOrder } from 'app/common/graphql/generated/graphql-gateway';
import { Contact } from 'app/pages/my-audience/contacts';
import { SavedSearch } from 'app/pages/my-audience/saved-searches';

export class List {
  constructor(
    public id: string,
    public name: string,
    public imageUrl: string,
    public description: string,
    public createdAt: Date,
    public lastModifiedAt: Date,
    public campaign: string,
    public createdBy: string,
    public lastModifiedBy: string,
    public tags: TagProjection[],
    public numberOfContacts: number,
    public createdByContact: Contact,
    public linkedSavedSearches: LinkedSavedSearch[],
    public mediumNames?: string[]
  ) {}
}

export class LinkedSavedSearch {
  constructor(public savedSearch: SavedSearch, public totalCountOfContacts: number, public shouldAutoUpdate: boolean) {}
}

export class ListContact {
  constructor(public id: string, public listId: string, public contact: Contact, public mediumId: string) {}
}

export class PageOfListContacts {
  constructor(public totalCount: number, public listContacts: ListContact[]) {}
}

export class ListSearchResultTableData implements ITableData {
  constructor(
    public id: string,
    public key: string,
    public name: string,
    public profilePictureUrl: string,
    public numberOfContacts: number,
    public mediumNames: string,
    public createdBy: string,
    public tags: string,
    public createdAt: string,
    public campaign: string,
    public lastUpdate: string
  ) {}
}

export class ListContactsTableData {
  constructor(public key: number, public name: string, public role: string, public emailAddress: string, public contactData: string) {}
}

export class ListsTableData {
  constructor(public key: number, public name: string, public createdAt: string, public createdBy: string, public numberOfContacts: number) {}
}

export class PageOfLists {
  constructor(public totalCount: number, public lists: List[]) {}
}

export enum ListsSortOption {
  ListName = GQLListsSortOption.ListName,
  Tags = GQLListsSortOption.Tags,
  Campaign = GQLListsSortOption.Campaign,
  NumberOfContacts = GQLListsSortOption.NumberOfContacts,
  CreatedAt = GQLListsSortOption.CreatedAt,
  CreatedBy = GQLListsSortOption.CreatedBy,
  LastModifiedAt = GQLListsSortOption.LastModifiedAt,
  MediaOutletsAndCompaniesNames = GQLListsSortOption.MediaOutletsAndCompaniesNames
}

export enum ListsSortOrder {
  Desc = GQLListsSortOrder.Desc,
  Asc = GQLListsSortOrder.Asc
}

export interface IListsSortingInput {
  listsSortOption: ListsSortOption;
  listsSortOrder: ListsSortOrder;
}

export class DefaultListsSortingInput implements IListsSortingInput {
  listsSortOption: ListsSortOption = ListsSortOption.ListName;
  listsSortOrder: ListsSortOrder = ListsSortOrder.Asc;
}

export class ListsSortingInput implements IListsSortingInput {
  listsSortOption: ListsSortOption;
  listsSortOrder: ListsSortOrder;

  constructor(sortOption: string, sortOrder: ListsSortOrder) {
    this.listsSortOption = this.determineSortingOption(sortOption);
    this.listsSortOrder = sortOrder;
  }

  private determineSortingOption = (sortOption: string) => {
    switch (sortOption) {
      case 'name':
        return ListsSortOption.ListName;

      case 'numberOfContacts':
        return ListsSortOption.NumberOfContacts;

      case 'mediumNames':
        return ListsSortOption.MediaOutletsAndCompaniesNames;

      case 'createdBy':
        return ListsSortOption.CreatedBy;

      case 'tags':
        return ListsSortOption.Tags;

      case 'createdAt':
        return ListsSortOption.CreatedAt;

      case 'campaign':
        return ListsSortOption.Campaign;

      case 'lastUpdate':
        return ListsSortOption.LastModifiedAt;

      default:
        return ListsSortOption.ListName;
    }
  };
}
