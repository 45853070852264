import { useEffect } from 'react';
import { Flatfile, dataSourcesRequested, getAllJobTitlesRequested, selectIsFlatfileOpen } from 'app/common';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { AudienceOverview } from 'app/pages/my-audience/audience-overview/AudienceOverview';
import { mediaOutletPanelDataRequested } from 'app/pages/my-audience/media-outlets';
import { referenceListLocalizationRequested, selectLocale } from 'app/localization';
import { getAllBlacklistsRequested } from 'app/pages/my-audience/contact-profile';

export const MyAudience = () => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectLocale);
  const isFlatfileOpen = useAppSelector(selectIsFlatfileOpen);

  useEffect(() => {
    dispatch(referenceListLocalizationRequested());
    dispatch(dataSourcesRequested());
    dispatch(getAllJobTitlesRequested());
    dispatch(mediaOutletPanelDataRequested());
    dispatch(getAllBlacklistsRequested());
  }, [locale, dispatch]);

  return (
    <>
      <AudienceOverview />
      {isFlatfileOpen && <Flatfile />}
    </>
  );
};
