export enum AddContactWizardSteps {
  SearchContacts = 1,
  ProfileInformation,
  Finish
}

export enum CreateContactWizardSteps {
  CreateNew = 1,
  OwnContactData,
  ProfileInformation,
  Finish
}

export type AddProfileInformationForm = {
  jobTitle: string;
  jobDescription: string;
  resort: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  emailAddress: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
};

export type EditProfileInformationForm = {
  jobTitle: string;
  jobDescription: string;
  resort: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  emailAddress: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
};
