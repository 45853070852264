import { Dropdown, DropdownProps } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';
import { useState } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface ControlledDropdownProps {
  label: JSX.Element;
  name: string;
  control: Control<any>;
  items: string[];
  placeholder: string;
  rules?: RegisterOptions;
  noResultsMessage?: string;
  defaultValue?: string;
}

export const ControlledDropdown = ({ label, name, control, items, placeholder, rules, defaultValue, noResultsMessage }: ControlledDropdownProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {label}
          <Dropdown
            clearable
            checkable
            placeholder={placeholder}
            defaultValue={defaultValue}
            items={items}
            onChange={(_, option) => {
              onChange(option.value);
            }}
            noResultsMessage={noResultsMessage || formatMessage({ id: 'emailing-wizard-setup-step.messages.no-results-found' })}
            onBlur={handleBlur}
            open={isOpen}
            onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
            error={!value && !!error}
          />
          <span className="error-message">{error && error.message}</span>
        </>
      )}
    />
  );
};
