import { Button, CloseIcon, Pill } from '@fluentui/react-northstar';
import { FilterItem, getFilterLocalizationKey, useReferenceListLocalization } from 'app/common';
import { useIntl } from 'react-intl';

type FilterPillItemProps = {
  item: FilterItem;
  onOpenFilterPanel: () => void;
  onUndoFilter: (item: FilterItem) => void;
};

export const FilterPillItem = ({ item, onOpenFilterPanel, onUndoFilter }: FilterPillItemProps) => {
  const { formatMessage } = useIntl();
  const { localizeFilterItem } = useReferenceListLocalization();

  return (
    <Pill key={item.fieldName} className="filter-pill">
      <span className="filter-pill-text" onClick={onOpenFilterPanel}>
        <span className="filter-pill-field-name">{formatMessage({ id: getFilterLocalizationKey(item.fieldName) || 'filters.filters' })}: </span>
        <span className="filter-pill-name">{localizeFilterItem(item)}</span>
      </span>
      <Button icon={<CloseIcon />} iconOnly text onClick={() => onUndoFilter(item)} />
    </Pill>
  );
};
