import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { client } from 'app/common/graphql/graphql-gateway.client';
import {
  addMediaOutletSavedSearch,
  removeMediaOutletSavedSearch,
  updateMediaOutletSavedSearch,
  updateMediaOutletSavedSearches,
  addMediaOutletSavedSearchRequested,
  mediaOutletSavedSearchAdded,
  updateMediaOutletSavedSearchRequested,
  selectMediaOutletSavedSearchToUpdate,
  updatedMediaOutletSavedSearch,
  selectMediaOutletSavedSearchesToUpdate,
  updatedMediaOutletSavedSearches,
  updateMediaOutletSavedSearchesRequested,
  removeMediaOutletSavedSearchRequested,
  selectMediaOutletSavedSearchToRemoveId,
  removedMediaOutletSavedSearch,
  selectFilterItem,
  selectPageNumber,
  selectPageSize,
  selectSearchText,
  fetchMediaOutletsSavedSearchesPaged,
  firstPageOfSavedSearchesRequested,
  mediaOutletsSavedSearchesRequested,
  fetchMediaOutletsAvatarDetails,
  firstPageOfSavedSearchesReceived,
  setTotalCountOfSavedSearches,
  mediaOutletsSavedSearchesReceived,
  MediaOutletsAvatarDetailsInput,
  MediaOutletsAvatarDetailsWithSavedSearchId,
  selectTotalCountOfSavedSearches,
  selectMediaOutletSavedSearches,
  loadNextMediaOutletSavedSearchAfterDeleteRequested
} from 'app/pages/my-audience/media-outlets-saved-searches';
import { PageOfSavedSearches, SavedSearch, SavedSearchInput, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import { MessageData, FilterItem, safe, setAlertData } from 'app/common';
import {
  selectSearchText as selectSearchTextFromMediaOutlets,
  selectFilterItems as selectFilterItemsDtoFromMediaOutlets
} from 'app/pages/my-audience/media-outlets';

export function* fetchPagedMediaOutletsSavedSearchesWatcher() {
  yield takeLatest(firstPageOfSavedSearchesRequested.type, safe(fetchPagedMediaOutletsSavedSearchesFlow));
  yield takeLatest(mediaOutletsSavedSearchesRequested.type, safe(fetchPagedMediaOutletsSavedSearchesFlow));
}

function* fetchPagedMediaOutletsSavedSearchesFlow() {
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);
  const searchText: string = yield select(selectSearchText);
  const filterItem: FilterItem = yield select(selectFilterItem);

  const skip = (pageNumber - 1) * pageSize;

  const pageOfSavedSearches: PageOfSavedSearches = yield call(fetchMediaOutletsSavedSearchesPaged, client, skip, pageSize, searchText, [filterItem]);

  const savedSearches = pageOfSavedSearches.savedSearches;

  const mediaOutletsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new MediaOutletsAvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const mediaOutletsAvatarDetails: MediaOutletsAvatarDetailsWithSavedSearchId[] = yield call(
    fetchMediaOutletsAvatarDetails,
    client,
    mediaOutletsAvatarDetailsInputs
  );

  mediaOutletsAvatarDetails.forEach((mediaOutletAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === mediaOutletAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = mediaOutletAvatarDetails.avatarDetails;
    savedSearch.resultsLength = mediaOutletAvatarDetails.totalCount;
  });

  if (pageNumber === 1) {
    yield put(firstPageOfSavedSearchesReceived(savedSearches));
  } else {
    yield put(mediaOutletsSavedSearchesReceived(savedSearches));
  }
  yield put(setTotalCountOfSavedSearches(pageOfSavedSearches.totalCount));
}

export function* addMediaOutletSavedSearchRequestedWatcher() {
  yield takeEvery(addMediaOutletSavedSearchRequested.type, safe(addMediaOutletSavedSearchFlow));
}

function* addMediaOutletSavedSearchFlow() {
  const searchText: string = yield select(selectSearchTextFromMediaOutlets);
  const filterItems: FilterItem[] = yield select(selectFilterItemsDtoFromMediaOutlets);

  const savedSearchToAdd = new SavedSearchInput(searchText || '', searchText || '*', filterItems);

  const savedSearch: SavedSearch = yield call(addMediaOutletSavedSearch, client, savedSearchToAdd);

  const savedSearches = [savedSearch];

  const mediaOutletsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new MediaOutletsAvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const mediaOutletsAvatarDetails: MediaOutletsAvatarDetailsWithSavedSearchId[] = yield call(
    fetchMediaOutletsAvatarDetails,
    client,
    mediaOutletsAvatarDetailsInputs
  );

  mediaOutletsAvatarDetails.forEach((mediaOutletAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === mediaOutletAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = mediaOutletAvatarDetails.avatarDetails;
    savedSearch.resultsLength = mediaOutletAvatarDetails.totalCount;
  });

  yield put(mediaOutletSavedSearchAdded(savedSearch));
  yield put(setAlertData(new MessageData('alert-saved-search-created')));
}

export function* updateMediaOutletSavedSearchRequestWatcher() {
  yield takeEvery(updateMediaOutletSavedSearchRequested.type, safe(updateMediaOutletSavedSearchFlow));
}

function* updateMediaOutletSavedSearchFlow() {
  const savedSearchToUpdate: SavedSearch = yield select(selectMediaOutletSavedSearchToUpdate);
  const savedSearchInput = new SavedSearchInput(savedSearchToUpdate.name, savedSearchToUpdate.searchText, savedSearchToUpdate.filterItems);

  const savedSearch: SavedSearch = yield call(updateMediaOutletSavedSearch, client, savedSearchToUpdate.id, savedSearchInput);

  const savedSearches = [savedSearch];

  const mediaOutletsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new MediaOutletsAvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const mediaOutletsAvatarDetails: MediaOutletsAvatarDetailsWithSavedSearchId[] = yield call(
    fetchMediaOutletsAvatarDetails,
    client,
    mediaOutletsAvatarDetailsInputs
  );

  mediaOutletsAvatarDetails.forEach((mediaOutletAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === mediaOutletAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = mediaOutletAvatarDetails.avatarDetails;
    savedSearch.resultsLength = mediaOutletAvatarDetails.totalCount;
  });

  yield put(updatedMediaOutletSavedSearch(savedSearch));
  yield put(setAlertData(new MessageData('alert-saved-search-updated')));
}

export function* updateMediaOutletSavedSearchesRequestWatcher() {
  yield takeEvery(updateMediaOutletSavedSearchesRequested.type, safe(updateMediaOutletSavedSearchesFlow));
}

function* updateMediaOutletSavedSearchesFlow() {
  const savedSearchesToUpdate: SavedSearchWithIdInput[] = yield select(selectMediaOutletSavedSearchesToUpdate);

  const savedSearches: SavedSearch[] = yield call(updateMediaOutletSavedSearches, client, savedSearchesToUpdate);

  const mediaOutletsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new MediaOutletsAvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const mediaOutletsAvatarDetails: MediaOutletsAvatarDetailsWithSavedSearchId[] = yield call(
    fetchMediaOutletsAvatarDetails,
    client,
    mediaOutletsAvatarDetailsInputs
  );

  mediaOutletsAvatarDetails.forEach((mediaOutletAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === mediaOutletAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = mediaOutletAvatarDetails.avatarDetails;
    savedSearch.resultsLength = mediaOutletAvatarDetails.totalCount;
  });

  yield put(updatedMediaOutletSavedSearches(savedSearches));
}

export function* removeMediaOutletSavedSearchRequestWatcher() {
  yield takeEvery(removeMediaOutletSavedSearchRequested.type, safe(removeMediaOutletSavedSearchFlow));
}

function* removeMediaOutletSavedSearchFlow() {
  const savedSearchToRemoveId: string = yield select(selectMediaOutletSavedSearchToRemoveId);
  const totalSavedSearchesCount: number = yield select(selectTotalCountOfSavedSearches);
  const currentSaveSearches: SavedSearch[] = yield select(selectMediaOutletSavedSearches);

  const shouldLoadNextMediaOutletSavedSearchAfterDelete = currentSaveSearches.length < totalSavedSearchesCount;

  const savedSearch: SavedSearch = yield call(removeMediaOutletSavedSearch, client, savedSearchToRemoveId);
  yield put(removedMediaOutletSavedSearch(savedSearch.id));

  if (shouldLoadNextMediaOutletSavedSearchAfterDelete) yield put(loadNextMediaOutletSavedSearchAfterDeleteRequested());
}

function* loadNextCompanySavedSearchAfterDeleteFlow() {
  const searchText: string = yield select(selectSearchText);
  const filterItem: FilterItem = yield select(selectFilterItem);
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);

  const skip = pageNumber * pageSize - 1;
  const pageOfSavedSearches: PageOfSavedSearches = yield call(fetchMediaOutletsSavedSearchesPaged, client, skip, 1, searchText, [filterItem]);

  const savedSearches = pageOfSavedSearches.savedSearches;

  const mediaOutletsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new MediaOutletsAvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const mediaOutletsAvatarDetails: MediaOutletsAvatarDetailsWithSavedSearchId[] = yield call(
    fetchMediaOutletsAvatarDetails,
    client,
    mediaOutletsAvatarDetailsInputs
  );

  mediaOutletsAvatarDetails.forEach((mediaOutletAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === mediaOutletAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = mediaOutletAvatarDetails.avatarDetails;
    savedSearch.resultsLength = mediaOutletAvatarDetails.totalCount;
  });

  yield put(mediaOutletsSavedSearchesReceived(savedSearches));
}

export function* loadNextMediaOutletSavedSearchAfterDeleteRequestWatcher() {
  yield takeEvery(loadNextMediaOutletSavedSearchAfterDeleteRequested.type, safe(loadNextCompanySavedSearchAfterDeleteFlow));
}
