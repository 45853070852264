import { useState } from 'react';
import { useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { Collaboration, CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';
import {
  CreateCompanySteps,
  closeCreateCompanyWizard,
  goToPreviousStep,
  goToNextStep,
  CreateCompanyFooter,
  selectCollaborationDetails,
  CollaborationDetailsForm,
  updateCollaboration,
  removeCollaboration,
  pageOfContactsRequested
} from 'app/pages/my-audience/wizard-create-company';
import { useIntl } from 'app/i18n';

export const AddAdditionalInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const collaborationDetails = useAppSelector<CollaborationWithContactInfo[]>(selectCollaborationDetails);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };
  const handleBackButtonClick = () => {
    dispatch(pageOfContactsRequested());
    dispatch(goToPreviousStep());
  };
  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  const removeContact = (contactId: string) => {
    dispatch(removeCollaboration(contactId));
  };

  const handleUpdateCollaboration = (areFieldsValid: boolean, contactId: string, collaboration: Collaboration, useDifferentAddress: boolean) => {
    setIsNextButtonDisabled(!areFieldsValid);
    dispatch(updateCollaboration({ collaboration, contactId, useDifferentAddress }));
  };

  return (
    <>
      <CreateCompanySteps isStepValid={!isNextButtonDisabled} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-company.additional-title' })} description="" />
        {collaborationDetails.map((collaboration) => (
          <CollaborationDetailsForm
            collaborationDetails={collaboration}
            removeContact={removeContact}
            key={collaboration.contactId}
            updateCollaboration={handleUpdateCollaboration}
          />
        ))}
      </div>
      <CreateCompanyFooter
        onCancelButtonClick={handleCancelButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onNextButtonClick={handleNextButtonClick}
        isNextButtonDisabled={isNextButtonDisabled}
      />
    </>
  );
};
