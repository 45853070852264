import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { getNames } from 'country-list';
import { isStringEmpty, regPhoneNumber, regEmail, regNumbers, useAppDispatch, useAppSelector, isValidUrlField, regUrl } from 'app/common';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import {
  AddAddressInfoForm,
  MediaOutletAddressInformation,
  selectAddressInfoToAdd,
  setAddressInfo,
  addAddressInformation,
  goToNextStep,
  closeCreateMediaOutletWizard,
  CreateMediaOutletSteps,
  CreateMediaOutletFooter,
  goToPreviousStep,
  pageOfContactsRequested,
  setPageNumber
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddAddressInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const addressInfoToAdd = useAppSelector<MediaOutletAddressInformation>(selectAddressInfoToAdd);

  const { handleSubmit, control, watch } = useForm<AddAddressInfoForm, any>({
    defaultValues: {
      address: addressInfoToAdd.address,
      city: addressInfoToAdd.city,
      country: addressInfoToAdd.country,
      emailAddress: addressInfoToAdd.emailAddress,
      fax: addressInfoToAdd.fax,
      phoneNumber: addressInfoToAdd.phoneNumber,
      poBox: addressInfoToAdd.poBox,
      postalCode: addressInfoToAdd.postalCode,
      website: addressInfoToAdd.websiteUrl
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleFieldsValidation = () => {
    const watchEmailAddress = watch('emailAddress');
    const isEmailAddressValid = watchEmailAddress?.match(regEmail) !== null || isStringEmpty(watchEmailAddress);

    const watchPostalCode = watch('postalCode');
    const isPostalCodeValid = watchPostalCode?.match(regNumbers) !== null || isStringEmpty(watchPostalCode);

    const watchLandlinePhoneNumber = watch('phoneNumber');
    const isLandlinePhoneNumberValid = watchLandlinePhoneNumber?.match(regPhoneNumber) !== null || isStringEmpty(watchLandlinePhoneNumber);

    return isEmailAddressValid && isPostalCodeValid && isLandlinePhoneNumberValid && isValidUrlField(watch('website'));
  };

  const handleNextButtonClick = () => {
    submitForm();
    dispatch(setPageNumber(1));
    dispatch(pageOfContactsRequested());
    dispatch(goToNextStep());
  };

  const submitForm = () => {
    handleSubmit(
      (data) => {
        const newAddressInfo: MediaOutletAddressInformation = setAddressInfo(data);
        dispatch(addAddressInformation(newAddressInfo));
      },
      (error) => {
        Sentry.setExtra('Path', AddAddressInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const areGeneralInfoValid = handleFieldsValidation();

  return (
    <>
      <CreateMediaOutletSteps isStepValid={areGeneralInfoValid} onStepClick={submitForm} />
      <div className="wizard-panel-form-wrap">
        <div className="wizard-panel-intro">
          <h5 className="wizard-panel-title">
            <FormattedMessage id={'add-menu-create-mo.mo-address-title'} />
          </h5>
          <span className="wizard-panel-description"></span>
        </div>
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-mo.mo-address-label' })} control={control} name="address" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-mo.mo-po-label' })} control={control} name="poBox" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-mo.mo-postal-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regNumbers,
                  message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                }
              }}
              name="postalCode"
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-mo.mo-city-label' })} control={control} name="city" />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-mo.mo-country-label'} />
                </span>
              }
              name="country"
              items={getNames()}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-mo.mo-country-placeholder' })}
              defaultValue={addressInfoToAdd.country}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-mo.mo-website-label' })}
              control={control}
              name="website"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="emailAddress"
              label={formatMessage({ id: 'add-menu-create-mo.mo-email-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />{' '}
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-mo.mo-phone-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
              name="phoneNumber"
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-mo.mo-fax-label' })} control={control} name="fax" />
          </div>
        </div>
        <CreateMediaOutletFooter
          onNextButtonClick={handleNextButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
          isNextButtonDisabled={!areGeneralInfoValid}
        />
      </div>
    </>
  );
};
