interface DownloadFileProps {
  data?: string;
  name: string;
  ref: React.MutableRefObject<any>;
  blob?: Blob;
}

export const downloadZipFromBase64 = (dataAsBase64: string, name: string) => {
  const element = document.createElement('a');
  const href = 'data:application/zip;base64,' + dataAsBase64;
  element.href = href;
  element.download = name;
  element.click();
  element.href = '/';
};

export const downloadFile = ({ data, name, ref, blob }: DownloadFileProps) => {
  const blobData = blob ?? new Blob([data], { type: 'octet-stream' });
  const href = URL.createObjectURL(blobData);

  ref.current.href = href;
  ref.current.download = name;
  ref.current.click();

  URL.revokeObjectURL(href);
  ref.current.href = '/';
};

export const downloadFileFromByteArray = (fileName: string, data: string) => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  const blob = new Blob([bytes]);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(link.href);
  link.href = '/';
};
