import { MessageData, safe, safelyHandleError, setAlertData } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { addCompany, Company, firstPageOfCompaniesRequested } from 'app/pages/my-audience/companies';
import { addCollaborations, addCollaboration } from 'app/pages/my-audience/contact-profile';
import {
  Collaboration,
  Contact,
  MediumType,
  addContact,
  PageOfContacts,
  fetchContactsByQueryParamsPaged,
  ContactsSortingInput,
  CollaborationWithContactInfo,
  CollaborationWithContactId
} from 'app/pages/my-audience/contacts';
import {
  addCollaborationRequested,
  addCompanyRequested,
  addContactRequested,
  addedCollaboration,
  addedCompany,
  addedContact,
  selectCollaborationDetails,
  selectCollaborationToAdd,
  selectCompanyToAdd,
  selectSelectedContactId,
  selectContactsSearchText,
  selectPageNumber,
  selectPageSize,
  pageOfContactsRequested,
  pageOfContactsReceived,
  closeCreateCompanyWizard,
  selectContactToAdd,
  setIsLoadingAddingCompany,
  setIsFinishButtonDisabled
} from 'app/pages/my-audience/wizard-create-company';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

function* getContactSuggestionsRequestedFlow() {
  const searchText = yield select(selectContactsSearchText);

  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);

  const pageOfContacts: PageOfContacts = yield call(
    fetchContactsByQueryParamsPaged,
    client,
    searchText,
    [],
    pageNumber,
    pageSize,
    new ContactsSortingInput('name', 'ASC')
  );

  yield put(pageOfContactsReceived({ contacts: pageOfContacts.contacts, totalCountOfContacts: pageOfContacts.totalCount }));
}

export function* getContactSuggestionsCompaniesRequestedWatcher() {
  yield takeLatest(pageOfContactsRequested.type, safe(getContactSuggestionsRequestedFlow));
}

function* addContactFlow() {
  const contactToAdd: Contact = yield select(selectContactToAdd);

  const contact = yield call(addContact, client, contactToAdd);
  yield put(addedContact(contact));
}

export function* addContactFromCompanyWizardRequestedWatcher() {
  yield takeEvery(addContactRequested.type, safe(addContactFlow));
}

function* addCompanyFlow() {
  const companyToAdd: Company = yield select(selectCompanyToAdd);

  const company: Company = yield call(addCompany, client, companyToAdd);
  const collaborationDetails: CollaborationWithContactInfo[] = yield select(selectCollaborationDetails);

  const collaborationsWithContactIds = [];

  for (let i = 0; i < collaborationDetails.length; i++) {
    const newCollaboration = {
      ...collaborationDetails[i].collaboration,
      medium: {
        id: company.id,
        name: company.name,
        type: MediumType.Company
      }
    };

    collaborationsWithContactIds.push(new CollaborationWithContactId(collaborationDetails[i].contactId, newCollaboration));
  }

  if (collaborationsWithContactIds.length) {
    yield call(addCollaborations, client, collaborationsWithContactIds);
  }

  yield put(addedCompany());
  yield put(closeCreateCompanyWizard());
  yield put(firstPageOfCompaniesRequested());
  yield put(setAlertData(new MessageData('alert-company-added')));
}

function* resetAddCompanyState() {
  yield put(setIsLoadingAddingCompany(false));
  yield put(setIsFinishButtonDisabled(false));
}

export function* addCompanyWizardWatcher() {
  yield takeEvery(addCompanyRequested.type, safelyHandleError({ performAction: addCompanyFlow, onCatch: resetAddCompanyState }));
}

function* addCollaborationFlow() {
  const collaborationToAdd: Collaboration = yield select(selectCollaborationToAdd);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const collaboration = yield call(addCollaboration, client, selectedContactId, collaborationToAdd);
  yield put(addedCollaboration(collaboration));
}

export function* addCollaborationCompanyWizardRequestedWatcher() {
  yield takeEvery(addCollaborationRequested.type, safe(addCollaborationFlow));
}
