import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Icon, Panel } from '@fluentui/react';
import { Button, FilterIcon, Input, MenuButton } from '@fluentui/react-northstar';
import { swap } from 'react-grid-dnd';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDebouncedCallback } from 'use-debounce';
import { Interval } from 'luxon';
import { DateRange, debounceTimeInMilliseconds, FilterItem, ShimmerCards } from 'app/common';
import { DeleteSavedSearchDialog, SavedSearch, SavedSearchCard } from 'app/pages/my-audience/saved-searches';
import { useOnClickOutside } from 'usehooks-ts';
import emptyImage from 'assets/images/empty-search.png';
import 'app/pages/my-audience/saved-searches/components/SavedSearchPanel.scss';
import { useIntl } from 'app/i18n';
import { FilterItemType, generateTimeZoneInterval } from 'app/common/factories/time-zone';

export interface SavedSearchPanelProps {
  isOpen: boolean;
  dismissPanel: () => void;
  isLoadingSavedSearches: boolean;
  savedSearches: SavedSearch[];
  totalCount: number;
  onSearchSavedSearches?: (searchText: string) => void;
  onFilterSavedSearches?: (filterItem: FilterItem) => void;
  onResetFilter?: () => void;
  onHandleLoadMore?: () => void;
  onSavedSearchClick: (savedSearch: SavedSearch) => void;
  onReorderSavedSearches: (reorderedSavedSearches: SavedSearch[]) => void;
  onSetSavedSearchToRemove: (savedSearchToRemove: SavedSearch) => void;
  onUpdateSavedSearch: (updatedSavedSearch: SavedSearch) => void;
  isDeleteDialogOpened: boolean;
  onRemoveSavedSearch: () => void;
  onCloseDeleteDialog: () => void;
  searchText?: string;
}

export const SavedSearchPanel = ({
  isOpen,
  dismissPanel,
  isLoadingSavedSearches,
  savedSearches,
  totalCount,
  onSearchSavedSearches,
  onFilterSavedSearches,
  onResetFilter,
  onHandleLoadMore,
  onSavedSearchClick,
  onReorderSavedSearches,
  onSetSavedSearchToRemove,
  onUpdateSavedSearch,
  isDeleteDialogOpened,
  onRemoveSavedSearch,
  onCloseDeleteDialog,
  searchText
}: SavedSearchPanelProps) => {
  const { formatMessage } = useIntl();
  const ref = useRef(null);

  const [isOpenCurrent, setIsOpenCurrent] = useState(false);
  const [localSavedSearches, setLocalSavedSearches] = useState(savedSearches);
  const [isSearchInputExpanded, setIsSearchInputExpanded] = useState(false);
  const [ignorePanelAnimation, setIgnorePanelAnimation] = useState(false);

  useEffect(() => {
    if (isOpen && !isOpenCurrent) {
      setIsOpenCurrent(true);
    }
    if (!isOpen && isOpenCurrent) {
      setIsOpenCurrent(false);
    }
  }, [isOpen, isOpenCurrent, searchText, setIsOpenCurrent]);

  useEffect(() => {
    setLocalSavedSearches(savedSearches);
  }, [savedSearches]);

  const handleExpandedInput = () => {
    setIsSearchInputExpanded(false);
  };

  useOnClickOutside(ref, handleExpandedInput);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedSavedSearches = swap([...localSavedSearches], result.source.index, result.destination.index).map((item: SavedSearch, index: number) => ({
      ...item,
      priority: index
    }));
    setLocalSavedSearches(reorderedSavedSearches);
    onReorderSavedSearches(reorderedSavedSearches);
  };

  const createCreatedAtFilterItem = (interval: Interval): FilterItem => {
    const dateRange = DateRange.parse(interval);
    return new FilterItem('createdAt', `${dateRange.startDate} - ${dateRange.endDate}`);
  };

  const applyFilter = (filter: FilterItemType) => {
    const timeInterval = generateTimeZoneInterval(filter);
    const filterInterval = createCreatedAtFilterItem(timeInterval);
    onFilterSavedSearches(filterInterval);
  };

  const filterItems = [
    {
      key: 'showAllFilterItemPanel',
      content: formatMessage({ id: 'saved-searches.show-all-filter-item' }),
      onClick: onResetFilter
    },
    {
      key: 'last7DaysFilterItemPanel',
      content: formatMessage({ id: 'saved-searches.last-7-days-filter-item' }),
      onClick: () => applyFilter({ amount: 7, timeFrame: 'days' })
    },
    {
      key: 'last30DaysFilterItemPanel',
      content: formatMessage({ id: 'saved-searches.last-30-days-filter-item' }),
      onClick: () => applyFilter({ amount: 1, timeFrame: 'months' })
    },
    {
      key: 'last6MonthsFilterItemPanel',
      content: formatMessage({ id: 'saved-searches.last-6-months-filter-item' }),
      onClick: () => applyFilter({ amount: 6, timeFrame: 'months' })
    },
    {
      key: 'last12MonthsFilterItemPanel',
      content: formatMessage({ id: 'saved-searches.last-12-months-filter-item' }),
      onClick: () => applyFilter({ amount: 12, timeFrame: 'months' })
    }
  ];

  const handleSearchSavedSearches = (event: SyntheticEvent<HTMLElement>) => {
    const inputSearchText = (event.target as HTMLTextAreaElement).value ?? '';
    onSearchSavedSearches(inputSearchText);
  };

  const handleSearchSavedSearchesInputChange = useDebouncedCallback(handleSearchSavedSearches, debounceTimeInMilliseconds);

  // react-beautiful-dnd library doesn't work inside panel because panel has defined animation.
  // Fix is to disable animation if we want to use drag and drop inside panel.
  // Animation is missing only when we are closing panel, on opening animation is present.
  const handleDraggableCardMouseEnter = () => setIgnorePanelAnimation(true);

  return (
    <div className="panel-saved-searches-wrap">
      <Panel
        isOpen={isOpen}
        isBlocking={false}
        onDismiss={dismissPanel}
        className={`panel-saved-searches ${ignorePanelAnimation && 'ignore-animation'}`}
        isLightDismiss={true}
        headerText={
          isLoadingSavedSearches ? formatMessage({ id: 'saved-searches.title' }) : formatMessage({ id: 'saved-searches.count-title' }, { count: totalCount })
        }
      >
        <div className="saved-searches-intro">
          <div className="saved-searches-inputs">
            <div className="saved-searches-filter">
              <MenuButton
                trigger={<Button className="filter-button" icon={<FilterIcon />} content={formatMessage({ id: 'saved-searches.filter-button-label' })} />}
                menu={filterItems}
                on="click"
              />
            </div>
            <div className="saved-searches-find" ref={ref}>
              <Input
                clearable
                className={isSearchInputExpanded ? 'saved-searches-search-input expanded' : 'saved-searches-search-input'}
                icon={<Icon iconName="Search" />}
                placeholder={formatMessage({ id: 'saved-searches.find-input-placeholder' })}
                onClick={() => setIsSearchInputExpanded(true)}
                onChange={handleSearchSavedSearchesInputChange}
              />
            </div>
          </div>
        </div>
        {totalCount !== 0 && (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="panel-card-wrap">
                    {localSavedSearches.map((item: SavedSearch, index: number) => (
                      <SavedSearchCard
                        savedSearch={item}
                        index={index}
                        key={item.id}
                        onUpdateSavedSearch={onUpdateSavedSearch}
                        onSetSavedSearchToRemove={onSetSavedSearchToRemove}
                        onSavedSearchClick={onSavedSearchClick}
                        onMouseEnter={handleDraggableCardMouseEnter}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {totalCount - localSavedSearches.length > 0 && !isLoadingSavedSearches && (
              <div className="saved-searches-button" style={{ marginTop: '20px' }}>
                <Button content={formatMessage({ id: 'saved-searches.load-more' })} onClick={onHandleLoadMore} className="button-load-more" />
              </div>
            )}
          </>
        )}
        {totalCount === 0 && !isLoadingSavedSearches && (
          <div className="saved-searches-empty">
            <img src={emptyImage} className="saved-searches-empty-img" alt="" />
            <span className="saved-searches-empty-text text-bold ">{formatMessage({ id: 'saved-searches.empty-message' })}</span>
            <span className="saved-searches-empty-text">{formatMessage({ id: 'saved-searches.empty-hint-message' })}</span>
          </div>
        )}
        <div className="card-panel-background-wrap">
          {localSavedSearches.map((item) => (
            <span key={item.id} className="card-panel-background" aria-hidden="true" />
          ))}
        </div>
        {isLoadingSavedSearches && <ShimmerCards />}
        <DeleteSavedSearchDialog opened={isDeleteDialogOpened} removeSavedSearch={onRemoveSavedSearch} close={onCloseDeleteDialog} />
      </Panel>
    </div>
  );
};
