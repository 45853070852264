import { FontWeights, mergeStyleSets, Modal } from '@fluentui/react';
import { Button, CloseIcon } from '@fluentui/react-northstar';
import { EmptyMessage, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { chooseTemplate, closeTemplatesPopup, selectIsTemplatesPopupVisible } from 'app/pages/my-activities/sending-wizard';
import { Template, TemplateCard, TemplatesOverviewTop, selectTemplates } from 'app/pages/my-activities/templates';

export const LoadTemplates = () => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector<Template[]>(selectTemplates);
  const { formatMessage } = useIntl();
  const isPopupVisible = useAppSelector<boolean>(selectIsTemplatesPopupVisible);

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch'
    },
    header: [
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px'
      }
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 }
      }
    }
  });

  const onDismissPopup = () => {
    dispatch(closeTemplatesPopup());
  };

  const onChooseTemplate = (template: Template) => {
    dispatch(chooseTemplate(template));
    dispatch(closeTemplatesPopup());
  };

  return (
    <>
      <Modal
        className="load-template-modul"
        isOpen={isPopupVisible}
        onDismiss={onDismissPopup}
        isBlocking={false}
        containerClassName={contentStyles.container}
        dragOptions={undefined}
      >
        <div className={contentStyles.body}>
          <Button content={<CloseIcon />} onClick={onDismissPopup} className="close-button" />
          <TemplatesOverviewTop></TemplatesOverviewTop>
          {templates.length > 0 ? (
            <div className="templates">
              <div className="templates-cards">
                {templates.map((template) => (
                  <TemplateCard key={template.id} template={template} onChooseTemplate={onChooseTemplate} />
                ))}
              </div>
            </div>
          ) : (
            <div className="load-template-empty">
              <EmptyMessage
                srcValue="/wizard-empty-info2.svg"
                textValueOptional={formatMessage({ id: 'all-templates.empty-title' })}
                textValue={formatMessage({ id: 'all-templates.empty-msg' })}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
