import { useState } from 'react';
import { Icon } from '@fluentui/react';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { printData } from 'app/common';
import { FormattedMessage } from 'app/i18n';

interface ReviewCollaborationInformationProps {
  collaboration: Collaboration;
  printMultipleValues: (values: string[]) => string;
}

export const ReviewCollaborationInformation = ({ collaboration, printMultipleValues }: ReviewCollaborationInformationProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <div className="custom-accordion" key="sending-overview-title" onClick={() => setIsExpanded((state) => !state)}>
        <div className="custom-accordion-head">
          <div className="custom-accordion-head-content">
            <span className="custom-accordion-head-icon">
              <Icon iconName="ChevronDown" className={isExpanded && ' expanded'} />
            </span>
            <span className="custom-accordion-head-icon-title">{collaboration.medium?.name}</span>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="wizard-panel-finish-main-content">
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-job-title-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.jobTitle?.name)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-job-desc-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.jobDescription)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-phone-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.landlinePhoneNumber?.value)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-mobile-phone-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.mobilePhoneNumber?.value)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-email-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.emailAddress)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-finish-zip'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printMultipleValues([collaboration.postalCode, collaboration.city])}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-checkout-country'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.country)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-contact.additional-finish-address'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.address)}</span>
          </div>
        </div>
      )}
    </>
  );
};
