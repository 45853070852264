import { Contact as GqlContact } from 'app/common/graphql/generated/graphql-gateway';
import { Tag, CommentUser, Comment } from 'app/common';
import {
  Blacklist,
  Collaboration,
  Contact,
  JobTitle,
  MediaResort,
  OwnContactData,
  PhoneNumber,
  PreferredLanguage,
  Topic
} from 'app/pages/my-audience/contacts';
import { Medium } from 'app/pages/my-audience/contact-profile';

export const createContact = (gqlContact: GqlContact): Contact => {
  return new Contact(
    gqlContact.id,
    gqlContact.salutation,
    gqlContact.firstName,
    gqlContact.lastName,
    gqlContact.profilePictureUrl,
    gqlContact.isGloballySignedOut,
    gqlContact.tags?.map((tag) => new Tag(tag.id, tag.name, '')),
    gqlContact.topics?.map((topic) => new Topic(topic.topicId, topic.topicName, topic.dataSourceKey)),
    gqlContact.mediaResorts?.map((mr) => new MediaResort(mr.mediaResortId, mr.mediaResortName, mr.dataSourceKey)),
    gqlContact.collaborations?.map(
      (c) =>
        new Collaboration(
          c.id,
          new Medium(c.medium?.id, c.medium?.name, c.medium?.type.value),
          c.jobTitle ? new JobTitle(c.jobTitle?.id, c.jobTitle?.name, c.jobTitle?.dataSourceKey, c.jobTitle?.mediumType?.value) : ({} as JobTitle),
          c.jobDescription,
          c.address,
          c.country,
          c.landlinePhoneNumber ? new PhoneNumber(c.landlinePhoneNumber?.value, c.landlinePhoneNumber.isPrimary) : ({} as PhoneNumber),
          c.mobilePhoneNumber ? new PhoneNumber(c.mobilePhoneNumber?.value, c.mobilePhoneNumber.isPrimary) : ({} as PhoneNumber),
          c.emailAddress,
          c.blogUrl,
          c.websiteUrl,
          c.twitterProfileUrl,
          c.instagramProfileUrl,
          c.linkedInProfileUrl,
          c.youtubeProfileUrl,
          c.facebookProfileUrl,
          c.isPrimary,
          c.city,
          c.postalCode
        )
    ),
    gqlContact.comments?.map(
      (comment) =>
        new Comment(
          comment.id,
          new CommentUser(comment?.author?.teamsId, comment?.author?.name, comment?.author?.aadObjectId),
          comment.content,
          new Date(comment.createdAt),
          comment.replies.map(
            (reply) =>
              new Comment(
                reply.id,
                new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
                reply.content,
                new Date(reply.createdAt),
                [],
                reply.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
              )
          ),
          comment.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
        )
    ),
    gqlContact?.blacklists.map((blacklist) => new Blacklist(blacklist.id, blacklist.name)),
    gqlContact?.preferredLanguages?.map((language) => new PreferredLanguage(language.id, language.name, language.dataSourceKey)),
    gqlContact.ownContactData
      ? new OwnContactData(
          gqlContact.ownContactData.address,
          gqlContact.ownContactData.country,
          gqlContact.ownContactData.landlinePhoneNumber
            ? new PhoneNumber(gqlContact.ownContactData.landlinePhoneNumber.value, gqlContact.ownContactData.landlinePhoneNumber.isPrimary)
            : ({} as PhoneNumber),
          gqlContact.ownContactData.mobilePhoneNumber
            ? new PhoneNumber(gqlContact.ownContactData.mobilePhoneNumber.value, gqlContact.ownContactData.mobilePhoneNumber.isPrimary)
            : ({} as PhoneNumber),
          gqlContact.ownContactData.emailAddress,
          gqlContact.ownContactData.blogUrl,
          gqlContact.ownContactData.websiteUrl,
          gqlContact.ownContactData.twitterProfileUrl,
          gqlContact.ownContactData.instagramProfileUrl,
          gqlContact.ownContactData.linkedInProfileUrl,
          gqlContact.ownContactData.youtubeProfileUrl,
          gqlContact.ownContactData.facebookProfileUrl,
          gqlContact.ownContactData.isPrimary,
          gqlContact.ownContactData.city,
          gqlContact.ownContactData.postalCode,
          gqlContact.ownContactData.fax
        )
      : undefined,
    gqlContact.dataSourceKey,
    gqlContact.isUser,
    gqlContact.dataSourceStatus?.value
  );
};
