import { DateTime, DateTimeFormatOptions } from 'luxon';

export const printData = (data: string | undefined, defaultIfEmpty = '/') => (data?.trim() ? data : defaultIfEmpty);
export const printDateLocalized = (date: Date | undefined, dateTimeFormat: DateTimeFormatOptions, locale: string) =>
  date?.getUTCFullYear() !== 1 ? DateTime.fromJSDate(date).setLocale(locale).toLocaleString(dateTimeFormat) : '/';

export const getDurationTimeString = (distributionDurationSeconds: number) => {
  if (distributionDurationSeconds < 60) {
    return `${distributionDurationSeconds}s`;
  }

  let minutes = Math.floor(distributionDurationSeconds / 60);
  const seconds = distributionDurationSeconds - minutes * 60;

  if (minutes < 60) {
    return `${minutes}m ${seconds}s`;
  }

  const hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;

  return `${hours}h ${minutes}m ${seconds}s`;
};
