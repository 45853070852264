import { Icon } from '@fluentui/react';
import { Avatar, List } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';
import { ActivityCardModel } from 'app/pages/my-audience/contacts';

interface ActivitiesListProps {
  activities: ActivityCardModel[];
  title: string;
  onCardClick: (activityId: string) => void;
}

export const ActivitiesList = ({ activities, title, onCardClick }: ActivitiesListProps) => {
  const { formatMessage } = useIntl();

  const getLocalizedActivityType = (activityType: string): string => {
    switch (activityType) {
      case 'Event': {
        return formatMessage({ id: 'contacts-activity-types-labels.event' });
      }
      case 'Task': {
        return formatMessage({ id: 'contacts-activity-types-labels.task' });
      }
      case 'Email': {
        return formatMessage({ id: 'contacts-activity-types-labels.email' });
      }
      case 'Sending': {
        return formatMessage({ id: 'contacts-activity-types-labels.sending' });
      }
      case 'Call': {
        return formatMessage({ id: 'contacts-activity-types-labels.call' });
      }
      case 'Webinar': {
        return formatMessage({ id: 'contacts-activity-types-labels.webinar' });
      }
      case 'Chat': {
        return formatMessage({ id: 'contacts-activity-types-labels.chat' });
      }
      case 'Appointment': {
        return formatMessage({ id: 'contacts-activity-types-labels.meeting' });
      }
      default: {
        return '';
      }
    }
  };

  const activityListItems = activities.map((item) => ({
    key: item.id,
    header: getLocalizedActivityType(item.activityType),
    media: <Avatar icon={<Icon iconName={item.iconName} />} name={item.activityType} />,
    headerMedia: (
      <div className="list-content">
        <span className="list-content-date">{item.date}</span>
        <span className="list-content-time">{item.time}</span>
      </div>
    ),
    content: (
      <div className="list-content">
        <span className="list-content-item bold-text">{item.title}</span>
        <span className="list-content-item">{item.createdByName}</span>
      </div>
    ),
    onClick: () => onCardClick(item.id)
  }));

  return (
    <>
      <span className="profile-panel-activities-label">{title}</span>
      <div className="profile-panel-activities-list">
        <List items={activityListItems} />
      </div>
    </>
  );
};
