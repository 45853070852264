import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { About, Lists, TopicsAndMediaResorts } from 'app/pages/my-audience/contact-profile';
import { SendingsOverview } from 'app/pages/my-audience/contact-profile/components/profile-tab/SendingsOverview';
import { FormattedMessage } from 'app/i18n';

interface ProfileAccordionProps {
  onClickOnChart: () => void;
}

export const ProfileAccordion = ({ onClickOnChart }: ProfileAccordionProps) => {
  const panels = [
    {
      title: (
        <div className="accordion-head-box" key="sending-overview-title">
          <span className="accordion-heading">
            <FormattedMessage id={'contacts-profile-tab-accordion.overview'} />
          </span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'sending-overview-content', content: <SendingsOverview onClickOnChart={onClickOnChart} /> }
    },
    {
      title: (
        <div className="accordion-head-box" key="about-title">
          <span className="accordion-heading">
            <FormattedMessage id={'contacts-profile-tab-accordion.about'} />
          </span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'about-content', content: <About /> }
    },
    {
      title: (
        <div className="accordion-head-box" key="topics-and-media-columns-title">
          <span className="accordion-heading">
            <FormattedMessage id={'contacts-profile-tab-accordion.topics-and-media-resorts'} />
          </span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'topics-and-media-columns-content',
        content: <TopicsAndMediaResorts />
      }
    },
    {
      title: (
        <div className="accordion-head-box" key="lists-title">
          <span className="accordion-heading">
            <FormattedMessage id={'contacts-profile-tab-accordion.lists'} />
          </span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'lists-content', content: <Lists /> }
    }
  ];

  return (
    <div className="profile-panel-content">
      <Accordion defaultActiveIndex={[1]} panels={panels} />
    </div>
  );
};
