import { fetchAllJobTitles } from 'app/pages/my-audience/contact-profile/services/contact-profile.service';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  allJobTitlesReceived,
  dataSourcesReceived,
  dataSourcesRequested,
  getAllJobTitlesRequested,
  mentionSuggestionsReceived,
  mentionSuggestionsRequested,
  selectMentionSuggestionsSearchText,
  languagesReceived,
  getLanguagesRequested,
  addJobTitleRequested,
  jobTitleAdded,
  selectJobTitleToAdd,
  removeJobTitleRequested,
  selectJobTitleIdToRemove,
  jobTitleRemoved
} from 'app/common';
import { fetchDataSources, fetchLanguages, fetchMentionSuggestions } from 'app/common/services/common.service';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { safe } from './graphql/safe-interceptor';
import { JobTitle, addJobTitle, removeJobTitle } from 'app/pages/my-audience/contacts';

function* fetchMentionSuggestionsFlow() {
  const searchText = yield select(selectMentionSuggestionsSearchText);
  const mentionSuggestions = yield call(fetchMentionSuggestions, searchText);

  yield put(mentionSuggestionsReceived(mentionSuggestions));
}

export function* mentionSuggestionsRequestedWatcher() {
  yield takeLatest(mentionSuggestionsRequested.type, safe(fetchMentionSuggestionsFlow));
}

function* getAllJobTitlesFlow() {
  const allJobTitles = yield call(fetchAllJobTitles, client);

  yield put(allJobTitlesReceived(allJobTitles));
}

export function* getAllJobTitlesRequestedWatcher() {
  yield takeEvery(getAllJobTitlesRequested.type, safe(getAllJobTitlesFlow));
}

function* fetchDataSourcesFlow() {
  const dataSources = yield call(fetchDataSources, client);

  yield put(dataSourcesReceived(dataSources));
}

export function* fetchDataSourcesRequestedWatcher() {
  yield takeEvery(dataSourcesRequested.type, safe(fetchDataSourcesFlow));
}

function* getLanguagesFlow() {
  const languages = yield call(fetchLanguages, client);

  yield put(languagesReceived(languages));
}

export function* getLanguagesRequestedWatcher() {
  yield takeEvery(getLanguagesRequested.type, safe(getLanguagesFlow));
}

function* addJobTitleFlow() {
  const jobTitleToAdd: JobTitle = yield select(selectJobTitleToAdd);

  const jobTitle: JobTitle = yield call(addJobTitle, client, jobTitleToAdd);

  yield put(jobTitleAdded(jobTitle));
}

export function* addJobTitleRequestedWatcher() {
  yield takeEvery(addJobTitleRequested.type, safe(addJobTitleFlow));
}

function* removeJobTitleFlow() {
  const jobTitleId: string = yield select(selectJobTitleIdToRemove);

  const removedId: string = yield call(removeJobTitle, client, jobTitleId);

  yield put(jobTitleRemoved(removedId));
}

export function* removeJobTitleRequestedWatcher() {
  yield takeEvery(removeJobTitleRequested.type, safe(removeJobTitleFlow));
}
