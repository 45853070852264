import { Table } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { AudienceTablePaging } from 'app/pages/my-activities/sending-profile';
import { selectSelectedSendingRecipients } from 'app/pages/my-activities/sending-wizard';
import { Recipient } from 'app/pages/my-activities/sendings';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const Audience = () => {
  const { formatMessage } = useIntl();

  const recipients = useAppSelector<Recipient[]>(selectSelectedSendingRecipients);

  const header = {
    items: ['Recipient', 'Email', 'Medium', 'Opened', 'Links clicked']
  };

  const rows = recipients.map((recipient) => {
    const collaboration: Collaboration = recipient.contact?.collaborations?.find((c) => c.id === recipient.collaborationId);
    const recipientName = recipient.contact?.id ? `${recipient.contact.firstName} ${recipient.contact.lastName}` : formatMessage({ id: 'labels.removed-user' });

    return {
      key: `sending-profile-audience-${recipient.id}`,
      items: [
        { key: `sending-profile-audience-name-${recipient.id}`, content: recipientName },
        {
          key: `sending-profile-audience-email-${recipient.id}`,
          content: `${recipient.emailAddress}`
        },
        { key: `sending-profile-audience-medium-${recipient.id}`, content: `${collaboration?.medium?.name ?? '/'}` },
        { key: `sending-profile-audience-is-open-${recipient.id}`, content: `${recipient.isOpened}` },
        { key: `sending-profile-audience-clicked-${recipient.id}`, content: `${recipient.linksClicked}` }
      ]
    };
  });

  return (
    <>
      <Table header={header} rows={rows} aria-label="Static table" />
      <AudienceTablePaging />
    </>
  );
};
