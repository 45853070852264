import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { getNames } from 'country-list';
import {
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regEmail,
  regNumbers,
  useAppDispatch,
  useAppSelector,
  WizardIntro,
  isPhoneNumberValidOrEmpty,
  isValidUrlField,
  regUrl
} from 'app/common';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import {
  MediaOutletAddressInfo,
  selectMediaOutletAddressInfoToAdd,
  setIsCreateMediaOutletAddressInfoStepValid,
  setMediaOutletAddressInfo,
  setMediaOutletAddressInfoToAdd
} from 'app/pages/my-audience/contact-profile';
import { FormattedMessage, useIntl } from 'app/i18n';

export type CreateMediaAddressInfoForm = {
  address: string;
  poBox: string;
  postalCode: string;
  city: string;
  country: string;
  websiteUrl: string;
  emailAddress: string;
  phoneNumber: string;
  fax: string;
};

export const CreateMediaOutletAddressInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const mediaOutletAddressInfo = useAppSelector<MediaOutletAddressInfo>(selectMediaOutletAddressInfoToAdd);

  const { handleSubmit, control, watch } = useForm<CreateMediaAddressInfoForm, any>({
    defaultValues: {
      address: mediaOutletAddressInfo.address,
      poBox: mediaOutletAddressInfo.poBox,
      postalCode: mediaOutletAddressInfo.postalCode,
      city: mediaOutletAddressInfo.city,
      country: mediaOutletAddressInfo.country,
      websiteUrl: mediaOutletAddressInfo.websiteUrl,
      emailAddress: mediaOutletAddressInfo.emailAddress,
      phoneNumber: mediaOutletAddressInfo.phoneNumber,
      fax: mediaOutletAddressInfo.fax
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const submitForm = useMemo(
    () =>
      handleSubmit(
        (data) => {
          const newMediaOutletAddressInfo = setMediaOutletAddressInfo(data);
          dispatch(setMediaOutletAddressInfoToAdd(newMediaOutletAddressInfo));
        },
        (error) => {
          Sentry.setExtra('Path', CreateMediaOutletAddressInfo.name);
          Sentry.captureException(error);
        }
      ),
    [dispatch, handleSubmit]
  );

  useEffect(() => {
    return () => {
      submitForm();
    };
  }, [submitForm]);

  const handleFieldsValidation = () =>
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isNumberValidOrEmpty(watch('fax')) &&
    isPhoneNumberValidOrEmpty(watch('phoneNumber')) &&
    isValidUrlField(watch('websiteUrl'));

  const areProfileInfoFieldsValid = handleFieldsValidation();

  useEffect(() => {
    dispatch(setIsCreateMediaOutletAddressInfoStepValid(areProfileInfoFieldsValid));
  }, [dispatch, areProfileInfoFieldsValid]);

  return (
    <div className="wizard-panel-form-wrap">
      <WizardIntro title={formatMessage({ id: 'add-medium-wizard-steps.address-information' })} description="" />
      <div className="wizard-panel-form">
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'medium-address-labels.address' })} control={control} name="address" />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'medium-address-labels.p-o-box' })} control={control} name="poBox" />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            label={formatMessage({ id: 'medium-address-labels.postal-code' })}
            control={control}
            name="postalCode"
            rules={{
              pattern: {
                value: regNumbers,
                message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'medium-address-labels.city' })} control={control} name="city" />
        </div>
        <div className="wizard-panel-dropdown">
          <ControlledDropdown
            label={
              <span className="dropdown-label">
                <FormattedMessage id={'medium-address-labels.country'} />
              </span>
            }
            name="country"
            items={getNames()}
            control={control}
            placeholder={formatMessage({ id: 'dropdown-placeholders.country' })}
            defaultValue={mediaOutletAddressInfo.country}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            label={formatMessage({ id: 'medium-address-labels.website' })}
            control={control}
            name="websiteUrl"
            rules={{
              pattern: {
                value: regUrl,
                message: formatMessage({ id: 'error-messages.not-valid-website' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            label={formatMessage({ id: 'medium-address-labels.email' })}
            control={control}
            name="emailAddress"
            rules={{
              pattern: {
                value: regEmail,
                message: formatMessage({ id: 'error-messages.not-valid-email-address' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            label={formatMessage({ id: 'medium-address-labels.phone-number' })}
            control={control}
            name="phoneNumber"
            rules={{
              pattern: {
                value: regPhoneNumber,
                message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            label={formatMessage({ id: 'medium-address-labels.fax' })}
            control={control}
            name="fax"
            rules={{
              pattern: {
                value: regNumbers,
                message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
