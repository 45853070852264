import { useRef, useState } from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button, Dropdown } from '@fluentui/react-northstar';
import { closeExportDialog, useAppDispatch } from 'app/common';
import { downloadFile } from 'app/common/downloadFile';
import { useIntl } from 'app/i18n';
import { FileTypes } from 'app/pages/my-audience/contacts';
import { Parser } from 'json2csv';

const dialogModalProps = {
  isBlocking: true,
  styles: { main: { maxWidth: 800 } }
};

interface ExportDialogProps {
  isDialogVisible: boolean;
  fileName: string;
  dataToExport: any[];
  showFileTypesDropdown: boolean;
  onExportVCards?: () => void;
}

export const ExportDialog = ({ isDialogVisible, fileName, dataToExport, onExportVCards, showFileTypesDropdown }: ExportDialogProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const ref = useRef(null);
  const fileTypes = showFileTypesDropdown ? Object.keys(FileTypes) : [FileTypes.csv];
  const [fileType, setFileType] = useState<string>('');
  const json2csvParser = new Parser();

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'export-dialog.select-file-type' })
  };

  const dismiss = () => {
    setFileType('');
    dispatch(closeExportDialog());
  };

  const onSelectFileType = (selectedFileType: string) => {
    setFileType(selectedFileType);
  };

  const onExport = () => {
    if (fileType === FileTypes.vCard) {
      onExportVCards();
      dismiss();
      return;
    }

    const data = json2csvParser.parse(dataToExport);
    downloadFile({ data, name: `${fileName}.csv`, ref });
    dismiss();
  };

  return (
    <Dialog hidden={!isDialogVisible} onDismiss={dismiss} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="profile-photo-dialog">
        <Dropdown
          items={fileTypes}
          checkable
          placeholder={formatMessage({ id: 'export-contacts.choose-file-type' })}
          getA11ySelectionMessage={{
            onAdd: (item) => {
              onSelectFileType(item.toString());
              return `${item}`;
            }
          }}
        />
        <DialogFooter>
          <Button
            primary
            onClick={onExport}
            disabled={fileType !== FileTypes.csv && fileType !== FileTypes.vCard}
            content={formatMessage({ id: 'buttons.export' })}
          />
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={dismiss} />
        </DialogFooter>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a ref={ref} href="/" style={{ display: 'none' }} />
      </div>
    </Dialog>
  );
};
