import { Icon } from '@fluentui/react';
import { Button, Dropdown, DropdownItemProps, DropdownProps, EditIcon, ShorthandCollection, Tooltip } from '@fluentui/react-northstar';
import {
  CopyToClipboardTooltip,
  hideDropdownEditActions,
  hideEditAndCopyActions,
  printData,
  selectAreEditAndCopyActionsVisible,
  selectEditableFieldActionsId,
  selectEditableFieldId,
  selectIsDropdownEditing,
  setEditableFieldActionsId,
  setEditableFieldId,
  showDropdownEditActions,
  showEditAndCopyActions,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { useIntl } from 'app/i18n';
import _uniqueId from 'lodash/uniqueId';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

type DropdownItem = {
  key: string;
  content: string;
};

interface EditableMultipleSelectionFieldProps {
  items: DropdownItem[];
  suggestions: DropdownItem[];
  onSave: (selectedItemsIds: string[]) => void;
  label?: string;
  disabled?: boolean;
}

export const EditableMultipleSelectionField = ({ items, suggestions, label, onSave, disabled = false }: EditableMultipleSelectionFieldProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const [editableFieldId] = useState(parseInt(_uniqueId()));
  const [editableFieldActionsId] = useState(parseInt(_uniqueId()));

  const areEditAndCopyActionsVisble = useAppSelector<boolean>(selectAreEditAndCopyActionsVisible);
  const selectorEditableFieldId = useAppSelector<number>(selectEditableFieldId);
  const selectorEditableActionsId = useAppSelector<number>(selectEditableFieldActionsId);
  const isDropdownEditing = useAppSelector<boolean>(selectIsDropdownEditing);

  const [updatedValues, setUpdatedValues] = useState(items);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleCancelChanges = () => {
    if (isDropdownEditing) {
      setUpdatedValues(items);
      dispatch(hideDropdownEditActions());
    }
  };
  useOnClickOutside(ref, handleCancelChanges);

  const handleMouseEnter = () => {
    dispatch(setEditableFieldId(editableFieldId));
    dispatch(showEditAndCopyActions());
  };

  const handleMouseLeave = () => {
    dispatch(hideEditAndCopyActions());
  };

  const handleEditButtonClick = () => {
    if (disabled) return;
    dispatch(setEditableFieldActionsId(editableFieldActionsId));
    dispatch(showDropdownEditActions());
  };

  const handleSaveChanges = () => {
    dispatch(hideDropdownEditActions());
    onSave(updatedValues.map((item) => item.key));
  };

  const handleAddNewValue = (suggestion: DropdownItem) => {
    setUpdatedValues((prevState) => [...prevState, suggestion]);
  };

  const handleRemoveNewValue = (item: string) => {
    setUpdatedValues((prevState) => prevState.filter((currentItem) => currentItem.content !== item));
  };

  const onSearch = (items: ShorthandCollection<DropdownItemProps>, searchQuery: string) =>
    items.filter((item: DropdownItem) => item.content.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()));
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);
  return (
    <div className="editable-fields-row" ref={ref}>
      <span className="editable-fields-row-label">{label ? label + ':' : ''}</span>
      <div
        className="editable-fields-row-content editable-fields-row-content--dropdown editable-fields-row-content--multiple-selected"
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isDropdownEditing && editableFieldActionsId === selectorEditableActionsId ? (
          <div className="editable-fields-row-content-dropdown">
            <Dropdown
              multiple
              search={(filteredItemsByValue, searchQuery) => onSearch(filteredItemsByValue, searchQuery)}
              searchQuery={searchQuery}
              onBlur={handleBlur}
              open={isOpen}
              onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
              onSearchQueryChange={(_, data) => setSearchQuery(data.searchQuery)}
              value={updatedValues.map((val) => val.content)}
              items={suggestions.filter((s) => !updatedValues.map((up) => up.key).includes(s.key))}
              noResultsMessage={formatMessage({ id: 'information-messages.no-matches' })}
              getA11ySelectionMessage={{
                onAdd: (item: DropdownItem) => {
                  handleAddNewValue(item);
                  return `${item.content} has been selected.`;
                },
                onRemove: (item: string) => {
                  handleRemoveNewValue(item);
                  return `${item} has been selected.`;
                }
              }}
            />
            <div className="editable-fields-input-actions">
              <Button onClick={handleCancelChanges} iconOnly content={<Icon iconName="Cancel" />} text />
              <Button onClick={handleSaveChanges} iconOnly content={<Icon iconName="CheckMark" />} text />
            </div>
          </div>
        ) : (
          <>
            <span className="editable-fields-value">{printData(items?.map((item) => item.content).join(', '))}</span>
            {areEditAndCopyActionsVisble && editableFieldId === selectorEditableFieldId ? (
              <div className="editable-fields-actions">
                <Tooltip
                  subtle={false}
                  pointing
                  trigger={<Button disabled={disabled} icon={<EditIcon />} onClick={handleEditButtonClick} iconOnly text size="small" />}
                  content={formatMessage({ id: 'button-tooltips.edit' })}
                  position="below"
                />
                <CopyToClipboardTooltip
                  textToCopy={updatedValues?.map((updatedValue) => updatedValue.content).join(', ')}
                  label={label}
                  props={{ position: 'below' }}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};
