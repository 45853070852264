import { RecordDataWithLinks } from '@flatfile/api/api';
import { Contact } from 'app/common';

export const recordDataWithLinksToContacts = (records: RecordDataWithLinks[]) => {
  return records.map((record) => {
    return new Contact(
      record.last_name?.value?.toString(),
      record.salutation?.value?.toString(),
      record.first_name?.value?.toString(),
      record.profile_picture_url?.value?.toString(),
      record.landline_phone_number?.value?.toString(),
      record.mobile_phone_number?.value?.toString(),
      record.fax?.value?.toString(),
      record.email_address?.value?.toString(),
      record.street?.value?.toString(),
      record.city?.value?.toString(),
      record.postal_code?.value?.toString(),
      record.country?.value?.toString(),
      record.collaboration_medium_name?.value?.toString(),
      record.collaboration_medium_type?.value?.toString(),
      record.job_title_name?.value?.toString(),
      record.job_title_description?.value?.toString(),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      record.protocol_title?.value?.toString(),
      record.comment?.value?.toString()
    );
  });
};
