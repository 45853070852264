import { Panel, PanelType } from '@fluentui/react';
import { selectIsOnDesktop, setSelectedTableRows, useAppDispatch, useAppSelector, useOnClickOutside } from 'app/common';
import { useRef } from 'react';

interface ProfilePanelWrapProps {
  children: JSX.Element;
  isProfilePanelOpened: boolean;
  onDismissButtonClick: () => void;
  classModifier?: string;
  width?: string;
  isBlocking?: boolean;
}

export const ProfilePanelWrap = ({
  children,
  isProfilePanelOpened,
  onDismissButtonClick,
  classModifier,
  width = '655px',
  isBlocking = false
}: ProfilePanelWrapProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const handleDismiss = () => {
    dispatch(setSelectedTableRows([]));
    onDismissButtonClick();
  };

  const dismissOnOutsideClick = () => {
    if (!isOnDesktop) {
      return;
    }

    handleDismiss();
  };

  useOnClickOutside(ref, dismissOnOutsideClick);

  return (
    <div ref={ref}>
      <Panel
        isOpen={isProfilePanelOpened}
        isBlocking={isBlocking}
        onDismiss={handleDismiss}
        type={PanelType.custom}
        customWidth={width}
        className={`profile-panel-wrap profile-panel-wrap--${classModifier}`}
      >
        {children}
      </Panel>
    </div>
  );
};
