import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ByteArray: any;
  DateTime: any;
  TimeSpan: any;
  URL: any;
};

export type AadContact = {
  __typename?: 'AadContact';
  /** The ID of the contact */
  contactId?: Maybe<Scalars['ID']>;
  /** The default collaboration of the contact */
  defaultCompany?: Maybe<DefaultCompany>;
  /** The Email of the contact */
  email?: Maybe<Scalars['String']>;
  /** The First Name of the contact */
  firstName?: Maybe<Scalars['String']>;
  /** The Last Name of the contact */
  lastName?: Maybe<Scalars['String']>;
  /** The object ID of the contact */
  objectId: Scalars['ID'];
};

export type AadContactInput = {
  /** The Email of the aad contact */
  email?: InputMaybe<Scalars['String']>;
  /** The First Name of the aad contact */
  firstName?: InputMaybe<Scalars['String']>;
  /** The Last Name of the aad contact */
  lastName?: InputMaybe<Scalars['String']>;
  /** The object ID of the aad contact */
  objectId: Scalars['ID'];
};

export type Activity = {
  /** Comment of the activity */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the activity */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the activity */
  createdByUser?: Maybe<ContactsUser>;
  /** Id of the activity */
  id?: Maybe<Scalars['ID']>;
  /** Participants ids of the activity */
  participants?: Maybe<Array<IActivityParticipant>>;
  /** Title of the activity */
  title?: Maybe<Scalars['String']>;
};

export type ActivityParticipant = IActivityParticipant & {
  __typename?: 'ActivityParticipant';
  /** The collaboration id of the activity participant */
  collaborationId?: Maybe<Scalars['ID']>;
  /** The contact of the activity participant */
  contact?: Maybe<Contact>;
  /** The id of the activity participant */
  id?: Maybe<Scalars['ID']>;
};

export type AiContentReport = {
  __typename?: 'AiContentReport';
  /** Date and time when report was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Report message. */
  message?: Maybe<Scalars['String']>;
};

export type Alias = {
  __typename?: 'Alias';
  /** The ID of the alias. */
  id?: Maybe<Scalars['ID']>;
  /** The label of the alias. */
  label?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Appointment = Activity & {
  __typename?: 'Appointment';
  /** Comment of the activity */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the activity */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the activity */
  createdByUser?: Maybe<ContactsUser>;
  /** When does the activity end */
  endsAt?: Maybe<Scalars['DateTime']>;
  /** Id of the activity */
  id?: Maybe<Scalars['ID']>;
  /** Participants of the activity */
  participants?: Maybe<Array<ActivityParticipant>>;
  /** When does the activity start */
  startsAt?: Maybe<Scalars['DateTime']>;
  /** Subject of the activity */
  subject?: Maybe<Scalars['String']>;
  /** Title of the activity */
  title?: Maybe<Scalars['String']>;
};

export type AppointmentInput = {
  /** Comment of the activity */
  comment?: InputMaybe<Scalars['String']>;
  /** When does the activity end */
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** Id of the activity */
  id?: InputMaybe<Scalars['ID']>;
  /** When does the activity start */
  startsAt?: InputMaybe<Scalars['DateTime']>;
  /** Subject of the activity */
  subject?: InputMaybe<Scalars['String']>;
  /** Title of the activity */
  title?: InputMaybe<Scalars['String']>;
};

export type Audience = ReferenceList & {
  __typename?: 'Audience';
  /** Id of the reference list */
  id?: Maybe<Scalars['ID']>;
  /** Key of the reference list */
  key?: Maybe<Scalars['String']>;
  /** Labels of the reference list */
  labels?: Maybe<Array<Maybe<MediaOutletsLabel>>>;
};

export type Blacklist = {
  __typename?: 'Blacklist';
  /** The date and time when the blacklist was created */
  createdAt?: Maybe<Scalars['String']>;
  /** The teamsID of the user who created the blacklist */
  createdBy?: Maybe<Scalars['String']>;
  /** The ID of the blacklist */
  id?: Maybe<Scalars['ID']>;
  /** The date and time when the blacklist was updated last */
  lastModifiedAt?: Maybe<Scalars['String']>;
  /** The teamsID of the user who edited the blacklist last */
  lastModifiedBy?: Maybe<Scalars['String']>;
  /** The name of the blacklist */
  name?: Maybe<Scalars['String']>;
  ownersIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BlacklistCreationInput = {
  /** The id of the entity to assign to the blacklist */
  entityId?: InputMaybe<Scalars['ID']>;
  /** The entity type to assign to the blacklist */
  entityType?: InputMaybe<Scalars['String']>;
  /** The name of the tag */
  name?: InputMaybe<Scalars['String']>;
};

export type BlacklistInput = {
  /** The id of the blacklist */
  id?: InputMaybe<Scalars['ID']>;
  /** Name of the blacklist */
  name?: InputMaybe<Scalars['String']>;
};

export type BlacklistUpdateInput = {
  /** The time when the user created the blacklist */
  createdAt?: InputMaybe<Scalars['String']>;
  /** The id of the user that created the blacklist */
  createdBy?: InputMaybe<Scalars['String']>;
  /** The id of the blacklist */
  id?: InputMaybe<Scalars['ID']>;
  /** The time when the user last modified the blacklist */
  lastModifiedAt?: InputMaybe<Scalars['String']>;
  /** The id of the user that last modified the blacklist */
  lastModifiedBy?: InputMaybe<Scalars['String']>;
  /** The name of the blacklist */
  name?: InputMaybe<Scalars['String']>;
  /** The ids of the users that own the blacklist */
  ownersIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Call = Activity & {
  __typename?: 'Call';
  /** Comment of the call */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the call */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the call */
  createdByUser?: Maybe<ContactsUser>;
  /** Id of the call */
  id?: Maybe<Scalars['ID']>;
  /** Participants of the call */
  participants?: Maybe<Array<ActivityParticipant>>;
  /** Scheduled date of the call */
  scheduledFor?: Maybe<Scalars['DateTime']>;
  /** Title of the call */
  title?: Maybe<Scalars['String']>;
};

export type CallInput = {
  /** Comment of the call */
  comment?: InputMaybe<Scalars['String']>;
  /** Id of the call */
  id?: InputMaybe<Scalars['ID']>;
  /** Scheduled date of a call */
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  /** Title of the call */
  title?: InputMaybe<Scalars['String']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  /** Id of the campaign */
  id?: Maybe<Scalars['ID']>;
  /** Name of the campaign */
  name?: Maybe<Scalars['String']>;
};

export type Chat = Activity & {
  __typename?: 'Chat';
  /** Comment of the chat */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the chat */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the chat */
  createdByUser?: Maybe<ContactsUser>;
  /** Id of the chat */
  id?: Maybe<Scalars['ID']>;
  /** Participants of the chat */
  participants?: Maybe<Array<ActivityParticipant>>;
  /** Scheduled for date of the chat */
  scheduledFor?: Maybe<Scalars['DateTime']>;
  /** Title of the chat */
  title?: Maybe<Scalars['String']>;
};

export type ChatInput = {
  /** Comment of the chat */
  comment?: InputMaybe<Scalars['String']>;
  /** Id of the chat */
  id?: InputMaybe<Scalars['ID']>;
  /** Scheduled date of the chat */
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  /** Title of the chat */
  title?: InputMaybe<Scalars['String']>;
};

export type Collaboration = ContactData & {
  __typename?: 'Collaboration';
  /** The Address of the collaboration. */
  address?: Maybe<Scalars['String']>;
  /** The Blog Url of the collaboration. */
  blogUrl?: Maybe<Scalars['String']>;
  /** The City of the collaboration. */
  city?: Maybe<Scalars['String']>;
  /** The Country of the collaboration. */
  country?: Maybe<Scalars['String']>;
  /** The Email Address of the collaboration. */
  emailAddress?: Maybe<Scalars['String']>;
  /** The FacebookProfileUrl of the collaboration. */
  facebookProfileUrl?: Maybe<Scalars['String']>;
  /** The fax of the collaboration. */
  fax?: Maybe<Scalars['String']>;
  /** Id of the collaboration */
  id: Scalars['ID'];
  /** The InstagramProfileUrl of the collaboration. */
  instagramProfileUrl?: Maybe<Scalars['String']>;
  /** Tells if it is a primary collaboration of a contact. */
  isPrimary: Scalars['Boolean'];
  /** The JobDescription of the collaboration. */
  jobDescription?: Maybe<Scalars['String']>;
  /** The Job title of the collaboration. */
  jobTitle?: Maybe<JobTitleGraphType>;
  /** The Phone Number of the collaboration. */
  landlinePhoneNumber?: Maybe<PhoneNumber>;
  /** The LinkedInProfileUrl of the collaboration. */
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  /** The Medium of the collaboration. */
  medium?: Maybe<Medium>;
  /** The Mobile Phone Number of the collaboration. */
  mobilePhoneNumber?: Maybe<PhoneNumber>;
  /** The Zip of the collaboration. */
  postalCode?: Maybe<Scalars['String']>;
  /** The TwitterProfileUrl of the collaboration. */
  twitterProfileUrl?: Maybe<Scalars['String']>;
  /** The viadeo profile url of the collaboration. */
  viadeoProfileUrl?: Maybe<Scalars['String']>;
  /** The WebsiteUrl of the collaboration. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** The xing url of the collaboration. */
  xingUrl?: Maybe<Scalars['String']>;
  /** The YoutubeProfileUrl of the collaboration. */
  youtubeProfileUrl?: Maybe<Scalars['String']>;
};

export type CollaborationInput = {
  /** The Address of the collaboration. */
  address?: InputMaybe<Scalars['String']>;
  /** The Blog Url of the collaboration. */
  blogUrl?: InputMaybe<Scalars['String']>;
  /** The City of the collaboration. */
  city?: InputMaybe<Scalars['String']>;
  /** The Country of the collaboration. */
  country?: InputMaybe<Scalars['String']>;
  /** The Email Address of the collaboration. */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** The FacebookProfileUrl of the collaboration. */
  facebookProfileUrl?: InputMaybe<Scalars['String']>;
  /** The fax of the collaboration. */
  fax?: InputMaybe<Scalars['String']>;
  /** The InstagramProfileUrl of the collaboration. */
  instagramProfileUrl?: InputMaybe<Scalars['String']>;
  /** Tells if it is a primary collaboration of a contact. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The JobDescription of the collaboration. */
  jobDescription?: InputMaybe<Scalars['String']>;
  /** The job title of the collaboration. */
  jobTitle?: InputMaybe<JobTitleInput>;
  /** The Phone Number of the collaboration. */
  landlinePhoneNumber?: InputMaybe<PhoneNumberInput>;
  /** The LinkedInProfileUrl of the collaboration. */
  linkedInProfileUrl?: InputMaybe<Scalars['String']>;
  /** The Medium of the collaboration. */
  medium?: InputMaybe<MediumInput>;
  /** The Mobile Phone Number of the collaboration. */
  mobilePhoneNumber?: InputMaybe<PhoneNumberInput>;
  /** The postal code of the collaboration. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** The TwitterProfileUrl of the collaboration. */
  twitterProfileUrl?: InputMaybe<Scalars['String']>;
  /** The WebsiteUrl of the collaboration. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** The YoutubeProfileUrl of the collaboration. */
  youtubeProfileUrl?: InputMaybe<Scalars['String']>;
};

export type CollaborationWithContactIdInput = {
  /** Collaboration */
  collaboration?: InputMaybe<CollaborationInput>;
  /** Contact id to which collaboration should be linked. */
  contactId?: InputMaybe<Scalars['ID']>;
};

export type CollaborationWithIdInput = {
  /** The Address of the contact. */
  address?: InputMaybe<Scalars['String']>;
  /** The Blog Url of the contact. */
  blogUrl?: InputMaybe<Scalars['String']>;
  /** The City of the contact. */
  city?: InputMaybe<Scalars['String']>;
  /** The Country of the contact. */
  country?: InputMaybe<Scalars['String']>;
  /** The Email Address of the contact. */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** The FacebookProfileUrl of the contact. */
  facebookProfileUrl?: InputMaybe<Scalars['String']>;
  /** The fax of the contact. */
  fax?: InputMaybe<Scalars['String']>;
  /** Id of the collaboration */
  id?: InputMaybe<Scalars['ID']>;
  /** The InstagramProfileUrl of the contact. */
  instagramProfileUrl?: InputMaybe<Scalars['String']>;
  /** Tells if it is a primary contact data of a contact. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The JobDescription of the contact. */
  jobDescription?: InputMaybe<Scalars['String']>;
  /** The Role of the contact. */
  jobTitle?: InputMaybe<JobTitleInput>;
  /** The Phone Number of the contact. */
  landlinePhoneNumber?: InputMaybe<PhoneNumberInput>;
  /** The LinkedInProfileUrl of the contact. */
  linkedInProfileUrl?: InputMaybe<Scalars['String']>;
  /** The Medium of the contact. */
  medium?: InputMaybe<MediumInput>;
  /** The Mobile Phone Number of the contact. */
  mobilePhoneNumber?: InputMaybe<PhoneNumberInput>;
  /** The Zip of the contact. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** The TwitterProfileUrl of the contact. */
  twitterProfileUrl?: InputMaybe<Scalars['String']>;
  /** The viadeo profile url of the contact. */
  viadeoProfileUrl?: InputMaybe<Scalars['String']>;
  /** The WebsiteUrl of the contact. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** The YoutubeProfileUrl of the contact. */
  youtubeProfileUrl?: InputMaybe<Scalars['String']>;
};

export type CompaniesAvatarDetailsInput = {
  /** Array of filter items of saved search */
  filterItems: Array<CompaniesFilterItemInput>;
  /** The id of saved search */
  savedSearchId: Scalars['String'];
  /** Search text of saved search */
  searchText: Scalars['String'];
};

export type CompaniesAvatarDetailsWithSavedSearchId = {
  __typename?: 'CompaniesAvatarDetailsWithSavedSearchId';
  /** Companies of saved search */
  pageOfCompanies: PageOfCompanies;
  /** Id of the saved search */
  savedSearchId: Scalars['ID'];
};

export type CompaniesComment = {
  __typename?: 'CompaniesComment';
  /** The author of the comment */
  author?: Maybe<CompaniesUser>;
  /** The content of the comment */
  content?: Maybe<Scalars['String']>;
  /** The date and time of the comment */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the comment */
  id?: Maybe<Scalars['ID']>;
  /** Mentions replies. */
  mentions?: Maybe<Array<CompaniesUser>>;
  /** Comment replies. */
  replies?: Maybe<Array<CompaniesComment>>;
};

export type CompaniesCommentInput = {
  /** The author of the comment */
  author?: InputMaybe<CompaniesUserInput>;
  /** The content of the comment */
  content?: InputMaybe<Scalars['String']>;
  /** The date and time of the comment */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** The mentions of the comment. */
  mentions?: InputMaybe<Array<CompaniesUserInput>>;
  /** The replies of the comment. */
  replies?: InputMaybe<Array<CompaniesCommentInput>>;
};

export type CompaniesContactData = {
  __typename?: 'CompaniesContactData';
  /** The alternative fax of the contact data. */
  alternativeFax?: Maybe<Scalars['String']>;
  /** The city of the contact data. */
  city?: Maybe<Scalars['String']>;
  /** The country of the contact data. */
  country?: Maybe<Scalars['String']>;
  /** The email address of the contact data. */
  emailAddress?: Maybe<Scalars['String']>;
  /** The fax of the contact data. */
  fax?: Maybe<Scalars['String']>;
  /** The ID of the contact data */
  id?: Maybe<Scalars['ID']>;
  /** The landline phone number of the contact data. */
  landlinePhoneNumber?: Maybe<Scalars['String']>;
  /** The P.O. box of the contact data. */
  poBox?: Maybe<Scalars['String']>;
  /** The postal code of the contact data. */
  postalCode?: Maybe<Scalars['String']>;
  /** The street of the contact data. */
  street?: Maybe<Scalars['String']>;
  /** The website URL of the contact data. */
  websiteUrl?: Maybe<Scalars['String']>;
};

export type CompaniesContactDataInput = {
  /** The alternative fax of the address */
  alternativeFax?: InputMaybe<Scalars['String']>;
  /** The city of the address. */
  city?: InputMaybe<Scalars['String']>;
  /** The country of the address */
  country?: InputMaybe<Scalars['String']>;
  /** The email address of the address. */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** The fax of the address */
  fax?: InputMaybe<Scalars['String']>;
  /** The landline phone number of the address */
  landlinePhoneNumber?: InputMaybe<Scalars['String']>;
  /** The P.O. box of the address. */
  poBox?: InputMaybe<Scalars['String']>;
  /** The ZIP of the address. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** The street of the address. */
  street?: InputMaybe<Scalars['String']>;
  /** The website URL of the address. */
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type CompaniesFilterItem = {
  __typename?: 'CompaniesFilterItem';
  /** The field name of the filter item. */
  fieldName?: Maybe<Scalars['String']>;
  /** The ID of the filter item. */
  id?: Maybe<Scalars['ID']>;
  /** The value of the filter item. */
  value?: Maybe<Scalars['String']>;
};

export type CompaniesFilterItemInput = {
  /** The field name of the filter item. */
  fieldName?: InputMaybe<Scalars['String']>;
  /** The value of the filter item. */
  value?: InputMaybe<Scalars['String']>;
};

export type CompaniesMediumCategory = {
  __typename?: 'CompaniesMediumCategory';
  /** The id of the medium category */
  id?: Maybe<Scalars['ID']>;
  /** The name of the medium category */
  name?: Maybe<Scalars['String']>;
  /** The type of the medium category */
  type?: Maybe<Companies_MediumCategoryType>;
};

export type CompaniesMediumCategoryInput = {
  /** The id of the medium category */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the medium category */
  name?: InputMaybe<Scalars['String']>;
  /** The type of the medium category */
  type?: InputMaybe<MediumCategoryTypeInput>;
};

export type CompaniesProfilePictureInput = {
  /** The data stream of the profile picture. */
  data?: InputMaybe<Scalars['ID']>;
  /** The file extension of the profile picture. */
  fileExtension?: InputMaybe<Scalars['String']>;
  /** The mime type of the profile picture. */
  mimeType?: InputMaybe<Scalars['String']>;
};

export type CompaniesSavedSearch = {
  __typename?: 'CompaniesSavedSearch';
  /** The count of the saved search results */
  count?: Maybe<Scalars['Int']>;
  /** The creation date and time of the saved search */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The creator of the saved search */
  createdBy?: Maybe<Scalars['ID']>;
  /** The saved search filter items */
  filterItems: Array<CompaniesFilterItem>;
  /** The ID of the saved search */
  id?: Maybe<Scalars['ID']>;
  /** The last modification date and time of the saved search */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** The name of the saved search */
  name?: Maybe<Scalars['String']>;
  /** The priority of the saved search */
  priority?: Maybe<Scalars['Int']>;
  /** The search text */
  searchText?: Maybe<Scalars['String']>;
};

export type CompaniesSavedSearchInput = {
  /** The saved search filter items */
  filterItems?: InputMaybe<Array<CompaniesFilterItemInput>>;
  /** The name of the saved search */
  name?: InputMaybe<Scalars['String']>;
  /** The search text */
  searchText?: InputMaybe<Scalars['String']>;
};

export type CompaniesSavedSearchWithIdInput = {
  /** The count of the saved search results. */
  count?: InputMaybe<Scalars['Int']>;
  /** The saved search filter items */
  filterItems?: InputMaybe<Array<CompaniesFilterItemInput>>;
  /** The ID of the saved search */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the saved search */
  name?: InputMaybe<Scalars['String']>;
  /** The priority of the saved search. */
  priority?: InputMaybe<Scalars['Int']>;
  /** The search text */
  searchText?: InputMaybe<Scalars['String']>;
};

export type CompaniesSearchSuggestionsCategory = {
  __typename?: 'CompaniesSearchSuggestionsCategory';
  /** The filter item field name of the search suggestion category */
  filterItemFieldName: Scalars['String'];
  /** The name of the search suggestion category */
  searchCategoryName: SearchCategoryName;
  /** Suggestions List */
  suggestionsList: Array<CompaniesSuggestionItem>;
};

export enum CompaniesSortOption {
  Address = 'ADDRESS',
  City = 'CITY',
  CompanyName = 'COMPANY_NAME',
  Country = 'COUNTRY',
  CreatedAt = 'CREATED_AT',
  DatabaseType = 'DATABASE_TYPE',
  Fax = 'FAX',
  MediumCategoryName = 'MEDIUM_CATEGORY_NAME',
  MobilePhoneNumber = 'MOBILE_PHONE_NUMBER',
  PostalCode = 'POSTAL_CODE',
  PoBox = 'PO_BOX',
  RegistrationNumber = 'REGISTRATION_NUMBER',
  Tags = 'TAGS',
  Website = 'WEBSITE'
}

export enum CompaniesSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type CompaniesSortingInput = {
  /** Sorting option */
  sortOption: CompaniesSortOption;
  /** Sorting order */
  sortOrder: CompaniesSortOrder;
};

export type CompaniesSuggestionItem = {
  __typename?: 'CompaniesSuggestionItem';
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['ID']>;
};

export type CompaniesTag = {
  __typename?: 'CompaniesTag';
  /** The ID of the tag */
  id?: Maybe<Scalars['ID']>;
  /** The value of the tag. */
  name?: Maybe<Scalars['String']>;
};

export type CompaniesTagInput = {
  /** The ID of the tag. */
  id?: InputMaybe<Scalars['ID']>;
  /** The value of the tag. */
  name?: InputMaybe<Scalars['String']>;
};

export type CompaniesUser = {
  __typename?: 'CompaniesUser';
  /** The AAD object ID of the user */
  aadObjectId?: Maybe<Scalars['String']>;
  /** The ID of the user. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the user */
  name?: Maybe<Scalars['String']>;
  /** The teams ID of the user. */
  teamsId?: Maybe<Scalars['String']>;
};

export type CompaniesUserInput = {
  /** The AAD object ID of the user */
  aadObjectId?: InputMaybe<Scalars['String']>;
  /** The name of the user */
  name?: InputMaybe<Scalars['String']>;
  /** The teams ID of the user. */
  teamsId?: InputMaybe<Scalars['String']>;
};

export type Companies_MediumCategoryType = {
  __typename?: 'Companies_MediumCategoryType';
  /** The name of the medium type */
  name?: Maybe<Scalars['String']>;
  /** The value of the medium type */
  value?: Maybe<Scalars['Int']>;
};

export type Companies_PageOfSavedSearches = {
  __typename?: 'Companies_PageOfSavedSearches';
  /** List of saved searches */
  savedSearches: Array<CompaniesSavedSearch>;
  /** Total count of saved searches */
  totalCount: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  /** The alias of the company. */
  alias?: Maybe<Alias>;
  /** Blacklist Ids the company belongs to. */
  blacklistsIds: Array<Maybe<Scalars['String']>>;
  /** The category of the company. */
  category?: Maybe<CompaniesMediumCategory>;
  /** The comments of the company. */
  comments?: Maybe<Array<Scalars['String']>>;
  /** The company contacts of the company. */
  companyContacts?: Maybe<Array<CompanyContact>>;
  /** The name of the company. */
  companyName?: Maybe<Scalars['String']>;
  /** The competitors of the company. */
  competitors?: Maybe<Array<Scalars['String']>>;
  /** The address of the company. */
  contactData?: Maybe<CompaniesContactData>;
  /** The date and time of creation of the company. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The id of creator of the company. */
  createdBy?: Maybe<Scalars['ID']>;
  /** The data source key of the company. */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The database type of the company. */
  databaseType?: Maybe<Scalars['String']>;
  /** The description of the company. */
  description?: Maybe<Scalars['String']>;
  /** The director of the company. */
  director?: Maybe<Scalars['String']>;
  /** The foundation date of the company. */
  foundationDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the company. */
  id?: Maybe<Scalars['ID']>;
  /** The date and time of last modification of the company. */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** The id of the user who last modified the company. */
  lastModifiedBy?: Maybe<Scalars['ID']>;
  /** The name of the company. */
  name?: Maybe<Scalars['String']>;
  /** The parent company of the company. */
  parentCompany?: Maybe<Scalars['String']>;
  /** The logo url of the company. */
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** The profit of the company. */
  profit?: Maybe<Scalars['Float']>;
  /** The registration number of the company */
  registrationNumber?: Maybe<Scalars['String']>;
  /** The tags of the company. */
  tags?: Maybe<Array<CompaniesTag>>;
  /** The user comments of the company. */
  userComments?: Maybe<Array<CompaniesComment>>;
};

export type CompanyContact = {
  __typename?: 'CompanyContact';
  /** First name of the contact. */
  firstName?: Maybe<Scalars['String']>;
  /** Id of the contact. */
  id?: Maybe<Scalars['ID']>;
  /** Last name of the contact. */
  lastName?: Maybe<Scalars['String']>;
  /** Profile picture url of the contact. */
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type CompanyContactInput = {
  /** First name of the contact. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Id of the contact. */
  id?: InputMaybe<Scalars['ID']>;
  /** Last name of the contact. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Profile picture url of the contact. */
  profilePictureUrl?: InputMaybe<Scalars['String']>;
};

export type CompanyInput = {
  /** The category of the medium. */
  category?: InputMaybe<CompaniesMediumCategoryInput>;
  /** Comments */
  comments?: InputMaybe<Array<Scalars['String']>>;
  /** Company contacts. */
  companyContacts?: InputMaybe<Array<CompanyContactInput>>;
  /** Company competitors. */
  competitors?: InputMaybe<Array<Scalars['String']>>;
  /** The address of the company. */
  contactData?: InputMaybe<CompaniesContactDataInput>;
  /** The datasource key of the company */
  dataSourceKey?: InputMaybe<Scalars['String']>;
  /** The database type of the company. */
  databaseType?: InputMaybe<Scalars['String']>;
  /** The description of the company. */
  description?: InputMaybe<Scalars['String']>;
  /** The director of the company. */
  director?: InputMaybe<Scalars['String']>;
  /** The foundation date of the company. */
  foundationDate?: InputMaybe<Scalars['DateTime']>;
  /** The name of the company. */
  name?: InputMaybe<Scalars['String']>;
  /** The parent company of the company. */
  parentCompany?: InputMaybe<Scalars['String']>;
  /** The profile picture url of the company. */
  profilePictureUrl?: InputMaybe<Scalars['String']>;
  /** The profit of the company. */
  profit?: InputMaybe<Scalars['Float']>;
  /** The registration number of the company. */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** Company tags. */
  tags?: InputMaybe<Array<CompaniesTagInput>>;
  /** Company user comments. */
  userComments?: InputMaybe<Array<CompaniesCommentInput>>;
};

export type Contact = {
  __typename?: 'Contact';
  /** Blacklists of the contact */
  blacklists?: Maybe<Array<ContactsBlacklist>>;
  /** Collaborations of the contact */
  collaborations?: Maybe<Array<Collaboration>>;
  /** Comments of the contact */
  comments?: Maybe<Array<ContactsComment>>;
  /** The Data source key of the contact */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** Data source status of a contact */
  dataSourceStatus?: Maybe<DataSourceStatus>;
  /** The First Name of the contact */
  firstName?: Maybe<Scalars['String']>;
  /** The ID of the contact */
  id?: Maybe<Scalars['ID']>;
  /** The is globally signed out the contact */
  isGloballySignedOut?: Maybe<Scalars['Boolean']>;
  /** Is contact user as well */
  isUser: Scalars['Boolean'];
  /** The Last Name of the contact */
  lastName?: Maybe<Scalars['String']>;
  /** Media Resorts of the contact */
  mediaResorts?: Maybe<Array<ContactsMediaResort>>;
  /** Own contact data of the contact */
  ownContactData?: Maybe<OwnContactData>;
  /** Preferred languages of the contact */
  preferredLanguages?: Maybe<Array<ContactsPreferredLanguage>>;
  /** The Profile Picture Url of the contact */
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** The Salutation of the contact */
  salutation?: Maybe<Scalars['String']>;
  /** Tags of the contact */
  tags?: Maybe<Array<ContactsTag>>;
  /** Topics of the contact */
  topics?: Maybe<Array<ContactsTopics>>;
};

export type ContactData = {
  /** The address of the contact. */
  address?: Maybe<Scalars['String']>;
  /** The blog URL of the contact. */
  blogUrl?: Maybe<Scalars['String']>;
  /** The city of the contact. */
  city?: Maybe<Scalars['String']>;
  /** The country of the contact. */
  country?: Maybe<Scalars['String']>;
  /** The email address of the contact. */
  emailAddress?: Maybe<Scalars['String']>;
  /** The facebook profile URL of the contact. */
  facebookProfileUrl?: Maybe<Scalars['String']>;
  /** The fax of the contact. */
  fax?: Maybe<Scalars['String']>;
  /** The ID of the contact */
  id: Scalars['ID'];
  /** The instagram profile URL of the contact. */
  instagramProfileUrl?: Maybe<Scalars['String']>;
  /** Tells if it is a primary contact data of a contact. */
  isPrimary: Scalars['Boolean'];
  /** The phone number of the contact. */
  landlinePhoneNumber?: Maybe<PhoneNumber>;
  /** The linkedIn profile URL of the contact. */
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  /** The mobile phone number of the contact. */
  mobilePhoneNumber?: Maybe<PhoneNumber>;
  /** The zip of the contact. */
  postalCode?: Maybe<Scalars['String']>;
  /** The twitter profile URL of the contact. */
  twitterProfileUrl?: Maybe<Scalars['String']>;
  /** The viadeo profile URL of the contact. */
  viadeoProfileUrl?: Maybe<Scalars['String']>;
  /** The website URL of the contact. */
  websiteUrl?: Maybe<Scalars['String']>;
  xingUrl?: Maybe<Scalars['String']>;
  /** The youtube profile URL of the contact. */
  youtubeProfileUrl?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  /** Blacklists of the contact */
  blacklists?: InputMaybe<Array<BlacklistInput>>;
  /** Collaborations of the contact */
  collaborations?: InputMaybe<Array<CollaborationInput>>;
  /** Comments of the contact */
  comments?: InputMaybe<Array<ContactsCommentInput>>;
  /** The First Name of the contact */
  firstName?: InputMaybe<Scalars['String']>;
  /** The Id of the contact */
  id?: InputMaybe<Scalars['ID']>;
  /** The is globally signed out of the contact */
  isGloballySignedOut?: InputMaybe<Scalars['Boolean']>;
  /** The Last Name of the contact */
  lastName?: InputMaybe<Scalars['String']>;
  /** Media Resorts of the contact */
  mediaResorts?: InputMaybe<Array<ContactsMediaResortsInput>>;
  /** Own contact data of the contact */
  ownContactData?: InputMaybe<OwnContactDataInput>;
  /** Preferred languages of the contact */
  preferredLanguages?: InputMaybe<Array<ContactsPreferredLanguageInput>>;
  /** The Profile Picture Url of the contact */
  profilePictureUrl?: InputMaybe<Scalars['String']>;
  /** The Salutation of the contact */
  salutation?: InputMaybe<Scalars['String']>;
  /** Tags of the contact */
  tags?: InputMaybe<Array<ContactsTagInput>>;
  /** Topics of the contact */
  topics?: InputMaybe<Array<ContactsTopicsInput>>;
};

export type ContactWithIdInput = {
  /** Blacklists of the contact */
  blacklists?: InputMaybe<Array<BlacklistInput>>;
  /** Collaborations of the contact */
  collaborations?: InputMaybe<Array<CollaborationInput>>;
  /** Comments of the contact */
  comments?: InputMaybe<Array<ContactsCommentInput>>;
  /** The First Name of the contact */
  firstName?: InputMaybe<Scalars['String']>;
  /** The Id of the contact */
  id?: InputMaybe<Scalars['ID']>;
  /** The Last Name of the contact */
  lastName?: InputMaybe<Scalars['String']>;
  /** Media Resorts of the contact */
  mediaResorts?: InputMaybe<Array<ContactsMediaResortsInput>>;
  /** Preferred languages of the contact */
  preferredLanguages?: InputMaybe<Array<ContactsPreferredLanguageInput>>;
  /** Tags of the contact */
  tags?: InputMaybe<Array<ContactsTagInput>>;
  /** Topics of the contact */
  topics?: InputMaybe<Array<TopicInput>>;
};

export type ContactsBlacklist = {
  __typename?: 'ContactsBlacklist';
  /** The id of the blacklist */
  id?: Maybe<Scalars['ID']>;
  /** The value of the blacklist */
  name?: Maybe<Scalars['String']>;
};

export type ContactsComment = {
  __typename?: 'ContactsComment';
  /** Author of the comment */
  author?: Maybe<TenantUser>;
  /** Content of the comment */
  content?: Maybe<Scalars['String']>;
  /** Date and time when comment was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Id of the comment */
  id?: Maybe<Scalars['ID']>;
  /** Mentions of the comment */
  mentions?: Maybe<Array<TenantUser>>;
  /** Replies of the comment */
  replies?: Maybe<Array<ContactsComment>>;
};

export type ContactsCommentInput = {
  /** Author of the comment */
  author?: InputMaybe<CompaniesUserInput>;
  /** Content of the comment */
  content?: InputMaybe<Scalars['String']>;
  /** Date and time when comment was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Mentions of the comment */
  mentions?: InputMaybe<Array<CompaniesUserInput>>;
  /** Replies of the comment */
  replies?: InputMaybe<Array<ContactsCommentInput>>;
};

export type ContactsCommentWithIdInput = {
  /** Author of the comment */
  author?: InputMaybe<CompaniesUserInput>;
  /** Content of the comment */
  content?: InputMaybe<Scalars['String']>;
  /** Date and time when comment was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Id of the comment */
  id?: InputMaybe<Scalars['ID']>;
  /** Mentions of the comment */
  mentions?: InputMaybe<Array<CompaniesUserInput>>;
  /** Replies of the comment */
  replies?: InputMaybe<Array<ContactsCommentInput>>;
};

export type ContactsFilterItem = {
  __typename?: 'ContactsFilterItem';
  /** The field name of the filter item */
  fieldName?: Maybe<Scalars['String']>;
  /** Id of user settings */
  id?: Maybe<Scalars['ID']>;
  /** The value of the filter item */
  value?: Maybe<Scalars['String']>;
};

export type ContactsFilterItemInput = {
  /** The field name of the filter item */
  fieldName?: InputMaybe<Scalars['String']>;
  /** The value of the filter item */
  value?: InputMaybe<Scalars['String']>;
};

export type ContactsListInputType = {
  /** The description of the list */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the list */
  id?: InputMaybe<Scalars['ID']>;
  /** The image URL of the list */
  imageUrl?: InputMaybe<Scalars['String']>;
  /** The name of the list */
  name: Scalars['String'];
};

export type ContactsMediaResort = {
  __typename?: 'ContactsMediaResort';
  /** Data source key of the media resort */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The ID of the media resort */
  mediaResortId?: Maybe<Scalars['ID']>;
  /** Name of the media resort */
  mediaResortName?: Maybe<Scalars['String']>;
  /** Type of the media resort */
  type?: Maybe<Scalars['String']>;
};

export type ContactsMediaResortsInput = {
  /** The ID of the media resort */
  mediaResortId?: InputMaybe<Scalars['ID']>;
  /** Name of the media resort */
  mediaResortName?: InputMaybe<Scalars['String']>;
};

export type ContactsPreferredLanguage = {
  __typename?: 'ContactsPreferredLanguage';
  /** Language data source key */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** Id of the language */
  id?: Maybe<Scalars['ID']>;
  /** Language name */
  name?: Maybe<Scalars['String']>;
};

export type ContactsPreferredLanguageInput = {
  /** Language data source key */
  dataSourceKey?: InputMaybe<Scalars['String']>;
  /** Id of the language */
  id?: InputMaybe<Scalars['ID']>;
  /** Language name */
  name?: InputMaybe<Scalars['String']>;
};

export type ContactsProfilePictureInput = {
  /** The data stream of the profile picture */
  data?: InputMaybe<Scalars['String']>;
  /** The file extension of the profile picture */
  fileExtension?: InputMaybe<Scalars['String']>;
  /** The mime type of the profile picture */
  mimeType?: InputMaybe<Scalars['String']>;
};

export type ContactsQueryInput = {
  /** Array of filter items of saved search */
  filterItems: Array<ContactsFilterItemInput>;
  /** The id of saved search */
  savedSearchId: Scalars['String'];
  /** Search text of saved search */
  searchText: Scalars['String'];
};

export type ContactsSavedSearch = {
  __typename?: 'ContactsSavedSearch';
  /** The count of the saved search results */
  count?: Maybe<Scalars['Int']>;
  /** The creation date and time of the saved search */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The creator of the saved search */
  createdBy?: Maybe<Scalars['ID']>;
  /** The saved search filter items */
  filterItems: Array<ContactsFilterItem>;
  /** The ID of the saved search */
  id?: Maybe<Scalars['ID']>;
  /** The last modification date and time of the saved search */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** The name of the saved search */
  name?: Maybe<Scalars['String']>;
  /** The priority of the saved search */
  priority?: Maybe<Scalars['Int']>;
  /** The search text */
  searchText?: Maybe<Scalars['String']>;
};

export type ContactsSavedSearchInput = {
  /** The saved search filter items */
  filterItems?: InputMaybe<Array<ContactsFilterItemInput>>;
  /** The name of the saved search */
  name?: InputMaybe<Scalars['String']>;
  /** The search text */
  searchText?: InputMaybe<Scalars['String']>;
};

export type ContactsSavedSearchWithIdInput = {
  /** The count of the saved search results. */
  count?: InputMaybe<Scalars['Int']>;
  /** The saved search filter items */
  filterItems?: InputMaybe<Array<ContactsFilterItemInput>>;
  /** The ID of the saved search */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the saved search */
  name?: InputMaybe<Scalars['String']>;
  /** The priority of the saved search. */
  priority?: InputMaybe<Scalars['Int']>;
  /** The search text */
  searchText?: InputMaybe<Scalars['String']>;
};

export type ContactsSearchSuggestionCategory = {
  __typename?: 'ContactsSearchSuggestionCategory';
  /** The filter item field name of the search suggestion category */
  filterItemFieldName: Scalars['String'];
  /** The name of the search suggestion category */
  searchCategoryName: SearchCategoryName;
  /** Suggestions List */
  suggestionsList: Array<ContactsSuggestionItem>;
};

export type ContactsSending = Activity & {
  __typename?: 'ContactsSending';
  /** The Blacklist of the sending */
  blacklist?: Maybe<ContactsBlacklist>;
  /** The campaign of the sending */
  campaign?: Maybe<Campaign>;
  /** The comment of the sending */
  comment?: Maybe<Scalars['String']>;
  /** The Created At of the sending */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the sending */
  createdByUser?: Maybe<ContactsUser>;
  /** The delivered at of the sending */
  deliveredAt?: Maybe<Scalars['DateTime']>;
  /** The Distribution Duration of the sending */
  distributionDuration?: Maybe<Scalars['TimeSpan']>;
  /** The Html body of the sending */
  htmlBody?: Maybe<Scalars['String']>;
  /** The id of the sending */
  id?: Maybe<Scalars['ID']>;
  /** The Image as base64 string of the sending */
  imageAsBase64?: Maybe<Scalars['String']>;
  /** The is favourite of the sending */
  isFavourite?: Maybe<Scalars['Boolean']>;
  /** The Json Body body of the sending */
  jsonBody?: Maybe<Scalars['String']>;
  /** The Last Modified At of the sending */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** The Last Modified By of the sending */
  lastModifiedByUser?: Maybe<ContactsUser>;
  /** The mail preview link of the sending */
  mailPreviewLink?: Maybe<Scalars['String']>;
  /** The mediums of the sending */
  mediumsIds?: Maybe<Array<Medium>>;
  /** The Mjml Body body of the sending */
  mjmlBody?: Maybe<Scalars['String']>;
  /** The Number Of Recipients of the sending */
  numberOfRecipients?: Maybe<Scalars['Int']>;
  /** The On Behalf Of Medium of the sending */
  onBehalfOfMediumId?: Maybe<Scalars['String']>;
  /** The Original Html Body body of the sending */
  originalHtmlBody?: Maybe<Scalars['String']>;
  /** Participants of the activity */
  participants?: Maybe<Array<IActivityParticipant>>;
  /** The recipient ids of the sending */
  recipientsIds?: Maybe<Array<Scalars['String']>>;
  /** The Respond To Email Address body of the sending */
  respondToEmailAddress?: Maybe<Scalars['String']>;
  /** The Sender of the sending */
  sender?: Maybe<Sender>;
  /** The Sender Email Address of the sending */
  senderDisplayName?: Maybe<Scalars['String']>;
  /** The Sender Email Address of the sending */
  senderEmailAddress?: Maybe<Scalars['String']>;
  /** The scheduled at of the sending */
  sendingScheduledAt?: Maybe<Scalars['DateTime']>;
  /** The status of the sending */
  status?: Maybe<SendingStatus>;
  /** The Subject of the sending */
  subject?: Maybe<Scalars['String']>;
  /** The tags of the sending */
  tags?: Maybe<Array<ContactsTag>>;
  /** The Title of the sending */
  title?: Maybe<Scalars['String']>;
};

export type ContactsSendingInput = {
  /** The blacklists of the sending */
  blacklist?: InputMaybe<BlacklistInput>;
  /** The html body of the sending */
  htmlBody?: InputMaybe<Scalars['String']>;
  /** The id of the sending */
  id?: InputMaybe<Scalars['ID']>;
  /** The is favourite the sending */
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  /** The json body of the sending */
  jsonBody?: InputMaybe<Scalars['String']>;
  /** The respond to email address of the sending */
  respondToEmailAddress?: InputMaybe<Scalars['String']>;
  /** The Sender of the sending */
  sender?: InputMaybe<SenderInput>;
  /** The Sender display name of the sending */
  senderDisplayName?: InputMaybe<Scalars['String']>;
  /** The Sender email address of the sending */
  senderEmailAddress?: InputMaybe<Scalars['String']>;
  /** The Subject of the sending */
  subject?: InputMaybe<Scalars['String']>;
  /** The Description of the sending */
  title?: InputMaybe<Scalars['String']>;
};

export enum ContactsSortOption {
  BlacklistName = 'BLACKLIST_NAME',
  ContactName = 'CONTACT_NAME',
  CreatedAt = 'CREATED_AT',
  EmailTitle = 'EMAIL_TITLE',
  Languages = 'LANGUAGES',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  LastModifiedBy = 'LAST_MODIFIED_BY',
  Medium = 'MEDIUM',
  NumberOfRecipients = 'NUMBER_OF_RECIPIENTS',
  Resort = 'RESORT',
  Role = 'ROLE',
  Status = 'STATUS',
  Subject = 'SUBJECT',
  Tags = 'TAGS'
}

export enum ContactsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ContactsSortingInput = {
  /** Sorting option */
  contactsSortOption: ContactsSortOption;
  /** Sorting order */
  sortOrder: ContactsSortOrder;
};

export type ContactsSuggestionItem = {
  __typename?: 'ContactsSuggestionItem';
  /** The image url of the suggestion item */
  imageUrl?: Maybe<Scalars['String']>;
  /** The subtext of the suggestion item */
  subtext?: Maybe<Scalars['String']>;
  /** The text of the suggestion item */
  text?: Maybe<Scalars['String']>;
};

export type ContactsTag = {
  __typename?: 'ContactsTag';
  /** The id of the tag */
  id?: Maybe<Scalars['ID']>;
  /** The value of the tag */
  name?: Maybe<Scalars['String']>;
};

export type ContactsTagInput = {
  /** The value of the tag */
  id?: InputMaybe<Scalars['ID']>;
  /** The value of the tag */
  name?: InputMaybe<Scalars['String']>;
};

export type ContactsTopics = {
  __typename?: 'ContactsTopics';
  /** Data source key of the topic */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The ID of the topic */
  topicId?: Maybe<Scalars['ID']>;
  /** Name of the topic */
  topicName?: Maybe<Scalars['String']>;
  /** Type of the topic */
  type?: Maybe<Scalars['String']>;
};

export type ContactsTopicsInput = {
  /** The ID of the topic */
  topicId?: InputMaybe<Scalars['ID']>;
  /** Name of the topic */
  topicName?: InputMaybe<Scalars['String']>;
};

export type ContactsUser = {
  __typename?: 'ContactsUser';
  /** The AAD object id of the user */
  aadObjectId?: Maybe<Scalars['String']>;
  /** The first name of the user */
  firstName: Scalars['String'];
  /** The id of the user */
  id: Scalars['ID'];
  /** The last name of the user */
  lastName: Scalars['String'];
  /** The profile picture of the user */
  profilePictureUrl: Scalars['String'];
};

export type ContactsWithSavedSearchId = {
  __typename?: 'ContactsWithSavedSearchId';
  /** Contacts of saved search */
  pageOfContacts: PageOfContacts;
  /** Id of the saved search */
  savedSearchId: Scalars['ID'];
};

export type Contacts_PageOfSavedSearches = {
  __typename?: 'Contacts_PageOfSavedSearches';
  /** List of saved searches */
  savedSearches: Array<ContactsSavedSearch>;
  /** Total count of saved searches */
  totalCount: Scalars['Int'];
};

export type CrossCutting_MediaResort = {
  __typename?: 'CrossCutting_MediaResort';
  /** Data source key of the media resort */
  dataSourceKey: Scalars['String'];
  /** The ID of the media resort */
  id: Scalars['ID'];
  /** Name of the media resort */
  name: Scalars['String'];
  /** Type of the media resort */
  type: Scalars['String'];
};

export type CsvHeader = {
  /** Contact data of the csv header */
  contactDataLabel?: InputMaybe<Scalars['String']>;
  /** Email address label of the csv header */
  emailAddressLabel?: InputMaybe<Scalars['String']>;
  /** Job title label of the csv header */
  jobTitleLabel?: InputMaybe<Scalars['String']>;
  /** Name label of the csv header */
  nameLabel?: InputMaybe<Scalars['String']>;
  /** Zimpel does not allow export message */
  zimpelDoesNotAllowExportMessage?: InputMaybe<Scalars['String']>;
};

export type DataSource = ReferenceList & {
  __typename?: 'DataSource';
  /** The ID of the data source */
  id?: Maybe<Scalars['ID']>;
  /** The key of the data source. */
  key?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<MediaOutletsLabel>>>;
};

export type DataSourceInput = {
  /** The id of the data source */
  id?: InputMaybe<Scalars['ID']>;
  /** The key of the data source */
  key?: InputMaybe<Scalars['String']>;
};

export type DataSourceStatus = {
  __typename?: 'DataSourceStatus';
  /** The name of the data source status type */
  name?: Maybe<Scalars['String']>;
  /** The value of the data source status type */
  value?: Maybe<Scalars['Int']>;
};

export type DefaultCompany = {
  __typename?: 'DefaultCompany';
  /** The id of the default company */
  id: Scalars['ID'];
  /** The name of the default company */
  name?: Maybe<Scalars['String']>;
};

export type Distribution = ReferenceList & {
  __typename?: 'Distribution';
  /** Id of the reference list */
  id?: Maybe<Scalars['ID']>;
  /** Key of the reference list */
  key?: Maybe<Scalars['String']>;
  /** Labels of the reference list */
  labels?: Maybe<Array<Maybe<MediaOutletsLabel>>>;
};

export type Email = Activity & {
  __typename?: 'Email';
  /** Comment of the email */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the email */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the email */
  createdByUser?: Maybe<ContactsUser>;
  /** Id of the email */
  id?: Maybe<Scalars['ID']>;
  /** Participants of the email */
  participants?: Maybe<Array<ActivityParticipant>>;
  /** Scheduled date of an email */
  scheduledFor?: Maybe<Scalars['DateTime']>;
  /** Subject of the email */
  subject?: Maybe<Scalars['String']>;
  /** Title of the email */
  title?: Maybe<Scalars['String']>;
};

export type EmailInput = {
  /** Comment of the email */
  comment?: InputMaybe<Scalars['String']>;
  /** Id of the email */
  id?: InputMaybe<Scalars['ID']>;
  /** Date to which is email scheduled for */
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  /** Subject of the email */
  subject?: InputMaybe<Scalars['String']>;
  /** Title of the email */
  title?: InputMaybe<Scalars['String']>;
};

export type Event = Activity & {
  __typename?: 'Event';
  /** Comment of the email */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the email */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the email */
  createdByUser?: Maybe<ContactsUser>;
  /** When does the email start */
  endsAt?: Maybe<Scalars['DateTime']>;
  /** Id of the email */
  id?: Maybe<Scalars['ID']>;
  /** Participants of the email */
  participants?: Maybe<Array<ActivityParticipant>>;
  /** When does the email start */
  startsAt?: Maybe<Scalars['DateTime']>;
  /** Title of the email */
  title?: Maybe<Scalars['String']>;
};

export type EventInput = {
  /** Comment of the activity */
  comment?: InputMaybe<Scalars['String']>;
  /** When does the activity end */
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** Id of the activity */
  id?: InputMaybe<Scalars['ID']>;
  /** When does the activity start */
  startsAt?: InputMaybe<Scalars['DateTime']>;
  /** Title of the activity */
  title?: InputMaybe<Scalars['String']>;
};

export type GroupGraphType = {
  __typename?: 'GroupGraphType';
  /** Members of the group */
  members?: Maybe<Array<AadContact>>;
  /** The name of the group */
  name?: Maybe<Scalars['String']>;
  /** The object ID of the group */
  objectId?: Maybe<Scalars['String']>;
};

export type IActivityParticipant = {
  /** The collaboration id of the recipient */
  collaborationId?: Maybe<Scalars['ID']>;
  /** The contact id of the recipient */
  contact?: Maybe<Contact>;
  /** The id of the recipient */
  id?: Maybe<Scalars['ID']>;
};

export type IRecipient = {
  /** The collaboration id of the recipient */
  collaborationId?: Maybe<Scalars['ID']>;
  /** The contact id of the recipient */
  contact?: Maybe<Contact>;
  /** The email address of the recipient */
  emailAddress?: Maybe<Scalars['String']>;
  /** The id of the recipient */
  id?: Maybe<Scalars['ID']>;
  /** Did the recipient open the sending */
  isOpened?: Maybe<Scalars['Boolean']>;
  /** The number of links clicked by the recipient */
  linksClicked?: Maybe<Scalars['Int']>;
  /** The sending status of the recipient */
  recipientStatus?: Maybe<Scalars['String']>;
};

export type JobTitleGraphType = {
  __typename?: 'JobTitleGraphType';
  /** The data source key of the job title */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The object ID of the job title */
  id?: Maybe<Scalars['String']>;
  /** The medium type of the job title */
  mediumType?: Maybe<MediumType>;
  /** The name of the job title */
  name?: Maybe<Scalars['String']>;
};

export type JobTitleInput = {
  /** The  data source key  of the job title */
  dataSourceKey?: InputMaybe<Scalars['String']>;
  /** The id code of the job title */
  id?: InputMaybe<Scalars['String']>;
  /** The medium type of the job title */
  mediumType?: InputMaybe<MediumTypeInput>;
  /** The name of the job title */
  name?: InputMaybe<Scalars['String']>;
};

export type Language = {
  __typename?: 'Language';
  /** The ID of the language */
  id: Scalars['ID'];
  /** The name of the language. */
  name: Scalars['String'];
};

export type LinkedSavedSearch = {
  __typename?: 'LinkedSavedSearch';
  /** Saved search */
  savedSearch?: Maybe<ContactsSavedSearch>;
  /** Should the saved search auto update */
  shouldAutoUpdate?: Maybe<Scalars['Boolean']>;
  /** Total count of saved search results */
  totalCountOfContacts?: Maybe<Scalars['Int']>;
};

export type ListContactGraphType = {
  __typename?: 'ListContactGraphType';
  /** The contact in list */
  contact?: Maybe<Contact>;
  /** The creation date of the list contact */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The creator ID of the list contact */
  createdBy?: Maybe<Scalars['ID']>;
  /** The ID of the list contact */
  id?: Maybe<Scalars['ID']>;
  /** Is contact excluded */
  isExcluded?: Maybe<Scalars['Boolean']>;
  /** The last update of the list contact */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** The last modifier ID of the list contact */
  lastModifiedBy?: Maybe<Scalars['ID']>;
  /** The ID of the list */
  listId?: Maybe<Scalars['ID']>;
  /** The ID of the medium */
  mailingAddressCompanyId?: Maybe<Scalars['ID']>;
};

export type ListContactInput = {
  /** The Salutation of the contact */
  contact?: InputMaybe<ContactInput>;
  /** The list id of the contact */
  listId?: InputMaybe<Scalars['ID']>;
  /** The selected medium of the contact */
  mailingAddressCompanyId?: InputMaybe<Scalars['ID']>;
};

export type ListGraphType = {
  __typename?: 'ListGraphType';
  /** The campaign of the list */
  campaign?: Maybe<Scalars['String']>;
  /** Contacts of the list */
  contacts?: Maybe<Array<Contact>>;
  /** The creation date of the list */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The creator ID of the list */
  createdBy?: Maybe<Scalars['ID']>;
  /** The creator of the list */
  createdByContact?: Maybe<Contact>;
  /** The description of the list */
  description?: Maybe<Scalars['String']>;
  /** The ID of the list */
  id?: Maybe<Scalars['ID']>;
  /** The image URL of the list */
  imageUrl?: Maybe<Scalars['String']>;
  /** Indicator is the list closed */
  isClosed?: Maybe<Scalars['Boolean']>;
  /** The last update of the list */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** The last modifier ID of the list */
  lastModifiedBy?: Maybe<Scalars['ID']>;
  /** The last modifier of the list */
  lastModifiedByContact?: Maybe<Contact>;
  /** Contacts saved searches of the list */
  linkedSavedSearches?: Maybe<Array<LinkedSavedSearch>>;
  /** The medium names of the list contacts */
  mediumNames?: Maybe<Array<Scalars['String']>>;
  /** The name of the list */
  name?: Maybe<Scalars['String']>;
  /** The number of contacts of the list */
  numberOfContacts?: Maybe<Scalars['Int']>;
  /** Owner IDs of the list */
  ownersIds?: Maybe<Array<Contact>>;
  /** Tags of the list */
  tags?: Maybe<Array<ContactsTag>>;
};

export type ListsSearchCategoryName = {
  __typename?: 'ListsSearchCategoryName';
  /** The name of the search suggestion category */
  name?: Maybe<Scalars['String']>;
  /** The value of the search suggestion category */
  value?: Maybe<Scalars['Int']>;
};

export type ListsSearchSuggestionCategory = {
  __typename?: 'ListsSearchSuggestionCategory';
  /** The filter item field name of the search suggestion category */
  filterItemFieldName: Scalars['String'];
  /** The name of the search suggestion category */
  searchCategoryName: ListsSearchCategoryName;
  /** Suggestions List */
  suggestionsList: Array<ContactsSuggestionItem>;
};

export enum ListsSortOption {
  Campaign = 'CAMPAIGN',
  CreatedAt = 'CREATED_AT',
  CreatedBy = 'CREATED_BY',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  ListName = 'LIST_NAME',
  MediaOutletsAndCompaniesNames = 'MEDIA_OUTLETS_AND_COMPANIES_NAMES',
  NumberOfContacts = 'NUMBER_OF_CONTACTS',
  Tags = 'TAGS'
}

export type ListsSortingInput = {
  /** Sorting option */
  listsSortOption: ListsSortOption;
  /** Sorting order */
  listsSortOrder: ContactsSortOrder;
};

export type MediaOutlet = {
  __typename?: 'MediaOutlet';
  /** The advertising value equivalent of the media outlet. */
  advertisingValueEquivalent?: Maybe<Scalars['String']>;
  /** The audience name of the media outlet. */
  audience?: Maybe<Audience>;
  /** The category of the media outlet. */
  category?: Maybe<MediaOutletsMediumCategory>;
  /** The circulation of the media outlet. */
  circulation?: Maybe<Scalars['Float']>;
  /** The comments of the media outlet. */
  comments?: Maybe<Array<Scalars['String']>>;
  /** The company name of the media outlet */
  companyName?: Maybe<Scalars['String']>;
  /** The contact data of the media outlet */
  contactData?: Maybe<MediaOutletsContactData>;
  /** The data source key of the media outlet. */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The description of the media outlet. */
  description?: Maybe<Scalars['String']>;
  /** The distributions of the media outlet. */
  distributions?: Maybe<Array<Distribution>>;
  /** The frequency of the media outlet. */
  frequency?: Maybe<MediumPublishingFrequency>;
  /** The full page cost of the media outlet */
  fullPageCost?: Maybe<Scalars['Float']>;
  /** The geographical coverage of the media outlet. */
  geographicalCoverage?: Maybe<MediaOutletsGeographicalCoverage>;
  /** The ID of the media outlet */
  id?: Maybe<Scalars['ID']>;
  /** The launch date of the media outlet. */
  launchDate?: Maybe<Scalars['String']>;
  /** The contacts of the media outlet. */
  mediaOutletContacts?: Maybe<Array<MediaOutletContact>>;
  /** The media resorts of the media outlet. */
  mediaResorts?: Maybe<Array<MediaOutletsMediaResort>>;
  /** The media type of the media outlet. */
  mediaType?: Maybe<Scalars['String']>;
  /** The media supported types of the media outlet. */
  mediumSupportTypes?: Maybe<Array<MediumSupportType>>;
  /** The name of the media outlet. */
  name?: Maybe<Scalars['String']>;
  /** The preferred languages of the media outlet. */
  preferredLanguages?: Maybe<Array<MediaOutletsPreferredLanguage>>;
  /** The price of the media outlet */
  price?: Maybe<Scalars['String']>;
  /** The profile of the media outlet */
  profile?: Maybe<Scalars['String']>;
  /** The profile picture url of the media outlet. */
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** The publication type of the media outlet. */
  publicationType?: Maybe<Scalars['String']>;
  /** The publishing date of the media outlet */
  publishingDate?: Maybe<Scalars['String']>;
  /** The publishing group id of the media outlet. */
  publishingGroupId?: Maybe<Scalars['String']>;
  /** The publishing group name of the media outlet */
  publishingGroupName?: Maybe<Scalars['String']>;
  /** The readership of the media outlet. */
  readership?: Maybe<Scalars['String']>;
  /** The source of circulation value of the media outlet */
  sourceOfCirculationValue?: Maybe<Scalars['String']>;
  /** The submission deadline of the media outlet. */
  submissionDeadline?: Maybe<Scalars['String']>;
  /** The tags of the media outlet. */
  tags?: Maybe<Array<MediaOutletsTag>>;
  /** The user comments of the media outlet. */
  userComments?: Maybe<Array<MediaOutletsComment>>;
  /** The website URL of the media outlet. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** The WEMF area of the media outlet. */
  wemfArea?: Maybe<Scalars['String']>;
};

export type MediaOutletContact = {
  __typename?: 'MediaOutletContact';
  /** First name of the contact. */
  firstName?: Maybe<Scalars['String']>;
  /** Id of the contact. */
  id?: Maybe<Scalars['ID']>;
  /** Last name of the contact. */
  lastName?: Maybe<Scalars['String']>;
  /** Profile picture url of the contact. */
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type MediaOutletInput = {
  /** The audience name of the media outlet. */
  audience?: InputMaybe<MediaOutletsAudienceInput>;
  /** The category of the medium. */
  category?: InputMaybe<MediaOutletsMediumCategoryInput>;
  /** The circulation of the media outlet. */
  circulation?: InputMaybe<Scalars['Float']>;
  /** The comments of the media outlet. */
  comments?: InputMaybe<Array<Scalars['String']>>;
  /** The contact data of the media outlet */
  contactData?: InputMaybe<MediaOutletsContactDataInput>;
  /** The datasource key of the media outlet. */
  dataSourceKey?: InputMaybe<Scalars['String']>;
  /** The description of the media outlet. */
  description?: InputMaybe<Scalars['String']>;
  /** The frequency of the media outlet. */
  frequency?: InputMaybe<MediaOutletsFrequencyInput>;
  /** The geographical coverage of the media outlet. */
  geographicalCoverage?: InputMaybe<MediaOutletsGeographicalCoverageInput>;
  /** The media type of the media outlet. */
  mediaType?: InputMaybe<Scalars['String']>;
  /** The media supported types of the media outlet. */
  mediumSupportTypes?: InputMaybe<Array<InputMaybe<MediaOutletsMediumSupportTypeInput>>>;
  /** The name of the media outlet. */
  name?: InputMaybe<Scalars['String']>;
  /** The number of the media outlet. */
  number?: InputMaybe<Scalars['Int']>;
  /** The preferred languages of the media outlet. */
  preferredLanguages?: InputMaybe<Array<MediaOutletsPreferredLanguageInput>>;
  /** The profile picture url of the media outlet. */
  profilePictureUrl?: InputMaybe<Scalars['String']>;
  /** The publication type of the media outlet. */
  publicationType?: InputMaybe<Scalars['String']>;
  /** The readership of the media outlet. */
  readership?: InputMaybe<Scalars['String']>;
  /** The website URL of the media outlet. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** The WEMF area of the media outlet. */
  wemfArea?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsAudienceInput = {
  /** The key of the audience */
  key?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsAugureLanguage = {
  __typename?: 'MediaOutletsAugureLanguage';
  /** The name of the augure language */
  name: Scalars['String'];
  /** The value of the augure language */
  value: Scalars['Int'];
};

export type MediaOutletsAvatarDetailsInput = {
  /** Array of filter items of saved search */
  filterItems: Array<MediaOutletsFilterItemInput>;
  /** The id of saved search */
  savedSearchId: Scalars['String'];
  /** Search text of saved search */
  searchText: Scalars['String'];
};

export type MediaOutletsAvatarDetailsWithSavedSearchId = {
  __typename?: 'MediaOutletsAvatarDetailsWithSavedSearchId';
  /** Media outlets of saved search */
  pageOfMediaOutlets: PageOfMediaOutlets;
  /** Id of the saved search */
  savedSearchId: Scalars['ID'];
};

export type MediaOutletsComment = {
  __typename?: 'MediaOutletsComment';
  /** The author of the comment */
  author?: Maybe<MediaOutletsUser>;
  /** The content of the comment */
  content?: Maybe<Scalars['String']>;
  /** The date and time of the comment */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the comment */
  id?: Maybe<Scalars['ID']>;
  /** Comment mentions */
  mentions?: Maybe<Array<MediaOutletsUser>>;
  /** Comment replies */
  replies?: Maybe<Array<MediaOutletsComment>>;
};

export type MediaOutletsCommentInput = {
  /** The author of the comment */
  author?: InputMaybe<MediaOutletsUserInput>;
  /** The content of the comment */
  content?: InputMaybe<Scalars['String']>;
  /** The date and time of the comment */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Comment mentions */
  mentions?: InputMaybe<Array<InputMaybe<MediaOutletsUserInput>>>;
  /** Comment replies */
  replies?: InputMaybe<Array<InputMaybe<MediaOutletsCommentInput>>>;
};

export type MediaOutletsContactData = {
  __typename?: 'MediaOutletsContactData';
  /** The alternative fax of the address */
  alternativeFax?: Maybe<Scalars['String']>;
  /** The city of the address. */
  city?: Maybe<Scalars['String']>;
  /** The country of the address */
  country?: Maybe<Scalars['String']>;
  /** The email address of the address. */
  emailAddress?: Maybe<Scalars['String']>;
  /** The fax of the address */
  fax?: Maybe<Scalars['String']>;
  /** The ID of the address */
  id?: Maybe<Scalars['ID']>;
  /** The landline phone number of the address */
  landlinePhoneNumber?: Maybe<Scalars['String']>;
  /** The P.O. box of the address. */
  poBox?: Maybe<Scalars['String']>;
  /** The ZIP of the address. */
  postalCode?: Maybe<Scalars['String']>;
  /** The street of the address. */
  street?: Maybe<Scalars['String']>;
  /** The website URL of the address. */
  websiteUrl?: Maybe<Scalars['String']>;
};

export type MediaOutletsContactDataInput = {
  /** The alternative fax of the address */
  alternativeFax?: InputMaybe<Scalars['String']>;
  /** The city of the address. */
  city?: InputMaybe<Scalars['String']>;
  /** The country of the address */
  country?: InputMaybe<Scalars['String']>;
  /** The email address of the address. */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** The fax of the address */
  fax?: InputMaybe<Scalars['String']>;
  /** The landline phone number of the address */
  landlinePhoneNumber?: InputMaybe<Scalars['String']>;
  /** The P.O. box of the address. */
  poBox?: InputMaybe<Scalars['String']>;
  /** The ZIP of the address. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** The street of the address. */
  street?: InputMaybe<Scalars['String']>;
  /** The website URL of the address. */
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsFilter = {
  __typename?: 'MediaOutletsFilter';
  /** The field name of the filter item */
  fieldName?: Maybe<Scalars['String']>;
  /** The ID of the filter item */
  id?: Maybe<Scalars['ID']>;
  /** The value of the filter item */
  value?: Maybe<Scalars['String']>;
};

export type MediaOutletsFilterItemInput = {
  /** The field name of the filter item */
  fieldName?: InputMaybe<Scalars['String']>;
  /** The value of the filter item */
  value?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsFrequencyInput = {
  /** The key of the frequency. */
  key?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsGeographicalCoverage = {
  __typename?: 'MediaOutletsGeographicalCoverage';
  /** The data source key of the geographical area */
  dataSourceKey: Scalars['String'];
  /** The id of the geographical area */
  id: Scalars['ID'];
  /** The name of the geographical area */
  name: Scalars['String'];
};

export type MediaOutletsGeographicalCoverageInput = {
  /** The data source key of the geographical area */
  dataSourceKey?: InputMaybe<Scalars['String']>;
  /** The id of the geographical area */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the geographical area */
  name?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsLabel = {
  __typename?: 'MediaOutletsLabel';
  /** The language of the label */
  language?: Maybe<MediaOutletsAugureLanguage>;
  /** The value of the label */
  value?: Maybe<Scalars['String']>;
};

export type MediaOutletsMediaResort = {
  __typename?: 'MediaOutletsMediaResort';
  /** Data source key of the media resort */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The ID of the media resort */
  mediaResortId?: Maybe<Scalars['ID']>;
  /** Name of the media resort */
  mediaResortName?: Maybe<Scalars['String']>;
  /** Type of the media resort */
  type?: Maybe<Scalars['String']>;
};

export type MediaOutletsMediumCategory = {
  __typename?: 'MediaOutletsMediumCategory';
  /** The id of the medium category */
  id: Scalars['ID'];
  /** The name of the medium category */
  name: Scalars['String'];
  /** The type of the medium category */
  type: MediumCategoryType;
};

export type MediaOutletsMediumCategoryInput = {
  /** The id of the medium category */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the medium category */
  name?: InputMaybe<Scalars['String']>;
  /** The type of the medium category */
  type?: InputMaybe<MediumCategoryTypeInput>;
};

export type MediaOutletsMediumSupportTypeInput = {
  /** The key of the media support type */
  key?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsPreferredLanguage = {
  __typename?: 'MediaOutletsPreferredLanguage';
  /** Language data source key */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** Id of the language */
  id?: Maybe<Scalars['ID']>;
  /** Language name */
  name?: Maybe<Scalars['String']>;
};

export type MediaOutletsPreferredLanguageInput = {
  /** Language data source key */
  dataSourceKey?: InputMaybe<Scalars['String']>;
  /** Id of the language */
  id?: InputMaybe<Scalars['ID']>;
  /** Language name */
  name?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsProfilePictureInput = {
  /** The data stream of the profile picture */
  data?: InputMaybe<Scalars['String']>;
  /** The file extension of the profile picture */
  fileExtension?: InputMaybe<Scalars['String']>;
  /** The mime type of the profile picture */
  mimeType?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsSavedSearch = {
  __typename?: 'MediaOutletsSavedSearch';
  /** The count of the saved search results */
  count?: Maybe<Scalars['Int']>;
  /** The creation date and time of the saved search */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The creator of the saved search */
  createdBy?: Maybe<Scalars['ID']>;
  /** The saved search filter items */
  filterItems: Array<MediaOutletsFilter>;
  /** The ID of the saved search */
  id?: Maybe<Scalars['ID']>;
  /** The last modification date and time of the saved search */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** The name of the saved search */
  name?: Maybe<Scalars['String']>;
  /** The priority of the saved search */
  priority?: Maybe<Scalars['Int']>;
  /** The search text */
  searchText?: Maybe<Scalars['String']>;
};

export type MediaOutletsSavedSearchInput = {
  /** The saved search filter items */
  filterItems?: InputMaybe<Array<MediaOutletsFilterItemInput>>;
  /** The name of the saved search */
  name?: InputMaybe<Scalars['String']>;
  /** The search text */
  searchText?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsSavedSearchWithIdInput = {
  /** The count of the saved search results. */
  count?: InputMaybe<Scalars['Int']>;
  /** The saved search filter items */
  filterItems?: InputMaybe<Array<MediaOutletsFilterItemInput>>;
  /** The ID of the saved search */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the saved search */
  name?: InputMaybe<Scalars['String']>;
  /** The priority of the saved search. */
  priority?: InputMaybe<Scalars['Int']>;
  /** The search text */
  searchText?: InputMaybe<Scalars['String']>;
};

export type MediaOutletsSearchSuggestionsCategory = {
  __typename?: 'MediaOutletsSearchSuggestionsCategory';
  /** The filter item field name of the search suggestion category */
  filterItemFieldName: Scalars['String'];
  /** The name of the search suggestion category */
  searchCategoryName: SearchCategoryName;
  /** Suggestions List */
  suggestionsList: Array<MediaOutletsSuggestionItem>;
};

export enum MediaOutletsSortOption {
  Address = 'ADDRESS',
  City = 'CITY',
  Contacts = 'CONTACTS',
  CreatedAt = 'CREATED_AT',
  DatabaseType = 'DATABASE_TYPE',
  Fax = 'FAX',
  Frequency = 'FREQUENCY',
  Languages = 'LANGUAGES',
  MediaOutletName = 'MEDIA_OUTLET_NAME',
  MediaResorts = 'MEDIA_RESORTS',
  MediaType = 'MEDIA_TYPE',
  MediumCategoryName = 'MEDIUM_CATEGORY_NAME',
  PostCode = 'POST_CODE',
  PoBox = 'PO_BOX',
  Tags = 'TAGS',
  Website = 'WEBSITE'
}

export enum MediaOutletsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type MediaOutletsSortingInput = {
  /** Sorting option */
  sortOption: MediaOutletsSortOption;
  /** Sorting order */
  sortOrder: MediaOutletsSortOrder;
};

export type MediaOutletsSuggestionItem = {
  __typename?: 'MediaOutletsSuggestionItem';
  /** Suggestion item image URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** Suggestion item text */
  text?: Maybe<Scalars['String']>;
};

export type MediaOutletsTag = {
  __typename?: 'MediaOutletsTag';
  /** The ID of the tag */
  id?: Maybe<Scalars['ID']>;
  /** The value of the tag */
  name?: Maybe<Scalars['String']>;
};

export type MediaOutletsTagInput = {
  /** The ID of the tag */
  id: Scalars['ID'];
  /** The value of the tag */
  name: Scalars['String'];
};

export type MediaOutletsUser = {
  __typename?: 'MediaOutletsUser';
  /** The AAD object ID of the user */
  aadObjectId?: Maybe<Scalars['String']>;
  /** The ID of the user */
  id?: Maybe<Scalars['ID']>;
  /** The name of the user */
  name?: Maybe<Scalars['String']>;
  /** The teams ID of the user */
  teamsId?: Maybe<Scalars['String']>;
};

export type MediaOutletsUserInput = {
  /** The AAD object ID of the user */
  aadObjectId?: InputMaybe<Scalars['String']>;
  /** The name of the user */
  name?: InputMaybe<Scalars['String']>;
  /** The teams ID of the user */
  teamsId?: InputMaybe<Scalars['String']>;
};

export type MediaResort = {
  __typename?: 'MediaResort';
  /** Data source key of the media resort */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The ID of the media resort */
  id?: Maybe<Scalars['ID']>;
  /** Name of the media resort */
  name?: Maybe<Scalars['String']>;
};

export type MediaResortInput = {
  /** The name of the media resort */
  name: Scalars['String'];
};

export type Medium = {
  __typename?: 'Medium';
  /** The ID of the medium */
  id?: Maybe<Scalars['ID']>;
  /** The name of the medium */
  name?: Maybe<Scalars['String']>;
  /** The type of the medium */
  type?: Maybe<MediumType>;
};

export type MediumCategoryType = {
  __typename?: 'MediumCategoryType';
  /** The name of the medium type */
  name: Scalars['String'];
  /** The value of the medium type */
  value: Scalars['Int'];
};

export type MediumCategoryTypeInput = {
  /** The name of the medium type */
  name: Scalars['String'];
  /** The value of the medium type */
  value: Scalars['Int'];
};

export type MediumInput = {
  /** The ID of the medium */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the medium */
  name?: InputMaybe<Scalars['String']>;
  /** The type of the medium */
  type?: InputMaybe<MediumTypeInput>;
};

export type MediumPublishingFrequency = ReferenceList & {
  __typename?: 'MediumPublishingFrequency';
  /** Id of the reference list */
  id?: Maybe<Scalars['ID']>;
  /** Key of the reference list */
  key?: Maybe<Scalars['String']>;
  /** Labels of the reference list */
  labels?: Maybe<Array<Maybe<MediaOutletsLabel>>>;
};

export type MediumSupportType = ReferenceList & {
  __typename?: 'MediumSupportType';
  /** Id of the reference list */
  id?: Maybe<Scalars['ID']>;
  /** Key of the reference list */
  key?: Maybe<Scalars['String']>;
  /** Labels of the reference list */
  labels?: Maybe<Array<Maybe<MediaOutletsLabel>>>;
};

export type MediumType = {
  __typename?: 'MediumType';
  /** The name of the medium type */
  name?: Maybe<Scalars['String']>;
  /** The value of the medium type */
  value?: Maybe<Scalars['Int']>;
};

export type MediumTypeInput = {
  /** The name of the medium type */
  name?: InputMaybe<Scalars['String']>;
  /** The value of the medium type */
  value?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAadContacts: Array<Contact>;
  addAadContactsToList: Array<Scalars['String']>;
  addAadContactsToSending: Array<Scalars['String']>;
  addAppointment: Appointment;
  addCall: Call;
  addChat: Chat;
  addCollaboration?: Maybe<Collaboration>;
  addCollaborations?: Maybe<Array<Maybe<Collaboration>>>;
  addCommentToCompany: CompaniesComment;
  addCommentToContact: ContactsComment;
  addCommentToList: ContactsComment;
  addCommentToMediaOutlet: MediaOutletsComment;
  addCompany?: Maybe<Company>;
  addCompanyToBlacklist: Company;
  addContact: Contact;
  addContactSending: ContactsSending;
  addContactSendings: Array<ContactsSending>;
  addContactToBlacklist?: Maybe<Contact>;
  addContactsSavedSearchesToList: ListGraphType;
  addContactsToList: Array<Maybe<ListContactGraphType>>;
  addEmail: Email;
  addEvent: Activity;
  addHarmfulContentReport: AiContentReport;
  addJobTitle?: Maybe<JobTitleGraphType>;
  addList?: Maybe<ListGraphType>;
  addListToContact: ListGraphType;
  addListsToContacts?: Maybe<Array<Scalars['String']>>;
  addMediaOutlet?: Maybe<MediaOutlet>;
  addMediaResort: MediaResort;
  addMediaResortToContact: MediaResort;
  addMediaResortToMediaOutlet: MediaResort;
  addMediaResortsToContacts?: Maybe<Array<Scalars['String']>>;
  addMediumCategory?: Maybe<MediaOutletsMediumCategory>;
  addRecipientsFromListsToSending?: Maybe<Array<Maybe<IActivityParticipant>>>;
  addRecipientsToSending?: Maybe<Array<Maybe<IActivityParticipant>>>;
  addSendingFromTemplate?: Maybe<ContactsSending>;
  addSendingWithContactsIds: ContactsSending;
  addSendingWithRecipientsFromLists: ContactsSending;
  addTagToCompanies: CompaniesTag;
  addTagToContacts: ContactsTag;
  addTagToLists: ContactsTag;
  addTagToMediaOutlets: MediaOutletsTag;
  addTagToSendings: ContactsTag;
  addTask: Activity;
  addTemplate: Template;
  addTopic: Topic;
  addTopicToContact: Topic;
  addTopicsToContacts: Array<Maybe<Scalars['String']>>;
  addWebinar: Activity;
  bulkRefreshLinkedSavedSearches: Array<ListGraphType>;
  createAndAssignBlacklist?: Maybe<Blacklist>;
  createAndAssignTag: Tag;
  deleteBlacklist?: Maybe<Blacklist>;
  deleteBlacklists?: Maybe<Array<Scalars['String']>>;
  deleteMediaResorts?: Maybe<Array<Scalars['String']>>;
  deleteTags: Array<Scalars['String']>;
  duplicateContactSendings: Array<ContactsSending>;
  duplicateLists: Array<ListGraphType>;
  refreshLinkedSavedSearches: ListGraphType;
  removeCollaboration?: Maybe<Collaboration>;
  removeCommentFromContact?: Maybe<ContactsComment>;
  removeCompanies: Array<Company>;
  removeCompany?: Maybe<Company>;
  removeCompanyFromBlacklist: Company;
  removeCompanyProfilePicture: Scalars['URL'];
  removeCompanySavedSearch: CompaniesSavedSearch;
  removeContact?: Maybe<Contact>;
  removeContactFromBlacklist?: Maybe<Contact>;
  removeContactSending?: Maybe<ContactsSending>;
  removeContactSendings: Array<Scalars['String']>;
  removeContacts: Array<Contact>;
  removeContactsFromList: ListGraphType;
  removeContactsSavedSearchesFromList: ListGraphType;
  removeJobTitle?: Maybe<Scalars['String']>;
  removeList?: Maybe<ListGraphType>;
  removeListFromContact: ListGraphType;
  removeListProfilePicture: Scalars['URL'];
  removeLists: Array<ListGraphType>;
  removeMediaOutlet: MediaOutlet;
  removeMediaOutletProfilePicture: Scalars['URL'];
  removeMediaOutletSavedSearch: MediaOutletsSavedSearch;
  removeMediaOutlets: Array<MediaOutlet>;
  removeMediaResortFromContact: MediaResort;
  removeMediaResortFromMediaOutlet: MediaResort;
  removeMediumCategory?: Maybe<Scalars['ID']>;
  removeProfilePicture: Scalars['URL'];
  removeRecipientsFromSending: Array<Scalars['ID']>;
  removeSavedSearch?: Maybe<ContactsSavedSearch>;
  removeTagFromCompanies: CompaniesTag;
  removeTagFromContacts: ContactsTag;
  removeTagFromLists: ContactsTag;
  removeTagFromMediaOutlets: MediaOutletsTag;
  removeTagFromSending: ContactsTag;
  removeTagFromSendings: ContactsTag;
  removeTemplate: Template;
  removeTopicFromContact: Topic;
  removeTopics?: Maybe<Array<Scalars['String']>>;
  saveCompanySearch: CompaniesSavedSearch;
  saveMediaOutletSearch: MediaOutletsSavedSearch;
  saveSearch?: Maybe<ContactsSavedSearch>;
  scheduleSendingDistribution: ContactsSending;
  sendSending: ContactsSending;
  transformCompanyIntoMediaOutlet: Company;
  transformMediaOutletIntoCompany: MediaOutlet;
  updateBlacklist: Blacklist;
  updateBulkMediaOutlets: Array<MediaOutlet>;
  updateCollaboration: Contact;
  updateComment: ContactsComment;
  updateCompany?: Maybe<Company>;
  updateCompanyProfilePicture: Scalars['URL'];
  updateCompanySavedSearch: CompaniesSavedSearch;
  updateCompanySavedSearches: Array<CompaniesSavedSearch>;
  updateContact?: Maybe<Contact>;
  updateContactSending: ContactsSending;
  updateContactSendings: Array<ContactsSending>;
  updateContactsSavedSearch: ListGraphType;
  updateList?: Maybe<ListGraphType>;
  updateListContact: ListContactGraphType;
  updateListProfilePicture: Scalars['URL'];
  updateMediaOutlet?: Maybe<MediaOutlet>;
  updateMediaOutletProfilePicture: Scalars['URL'];
  updateMediaOutletSavedSearch: MediaOutletsSavedSearch;
  updateMediaOutletSavedSearches: Array<MediaOutletsSavedSearch>;
  updateOwnContactData: Contact;
  updateProfilePicture: Scalars['URL'];
  updateSavedSearch: ContactsSavedSearch;
  updateSavedSearches: Array<ContactsSavedSearch>;
  updateSendingRecipientContactData?: Maybe<IActivityParticipant>;
  updateTemplate: Template;
};

export type MutationAddAadContactsArgs = {
  input: Array<AadContactInput>;
};

export type MutationAddAadContactsToListArgs = {
  input: Array<AadContactInput>;
  listId: Scalars['ID'];
};

export type MutationAddAadContactsToSendingArgs = {
  input: Array<AadContactInput>;
  sendingId: Scalars['ID'];
};

export type MutationAddAppointmentArgs = {
  contactId: Scalars['ID'];
  input: AppointmentInput;
};

export type MutationAddCallArgs = {
  contactId: Scalars['ID'];
  input: CallInput;
};

export type MutationAddChatArgs = {
  contactId: Scalars['ID'];
  input: ChatInput;
};

export type MutationAddCollaborationArgs = {
  contactId: Scalars['ID'];
  input: CollaborationInput;
};

export type MutationAddCollaborationsArgs = {
  input: Array<InputMaybe<CollaborationWithContactIdInput>>;
};

export type MutationAddCommentToCompanyArgs = {
  companyId: Scalars['ID'];
  input: CompaniesCommentInput;
};

export type MutationAddCommentToContactArgs = {
  contactId: Scalars['ID'];
  input: ContactsCommentInput;
};

export type MutationAddCommentToListArgs = {
  input: ContactsCommentInput;
  listId: Scalars['ID'];
};

export type MutationAddCommentToMediaOutletArgs = {
  input: MediaOutletsCommentInput;
  mediaOutletId: Scalars['ID'];
};

export type MutationAddCompanyArgs = {
  input: CompanyInput;
};

export type MutationAddCompanyToBlacklistArgs = {
  blacklistId: Scalars['ID'];
  companyId: Scalars['ID'];
};

export type MutationAddContactArgs = {
  input: ContactInput;
};

export type MutationAddContactSendingArgs = {
  input: ContactsSendingInput;
};

export type MutationAddContactSendingsArgs = {
  input: Array<ContactsSendingInput>;
};

export type MutationAddContactToBlacklistArgs = {
  blacklist: BlacklistInput;
  contactId: Scalars['ID'];
};

export type MutationAddContactsSavedSearchesToListArgs = {
  listId: Scalars['ID'];
  savedSearchesIds: Array<Scalars['ID']>;
};

export type MutationAddContactsToListArgs = {
  input: Array<ListContactInput>;
  listId: Scalars['ID'];
};

export type MutationAddEmailArgs = {
  contactId: Scalars['ID'];
  input: EmailInput;
};

export type MutationAddEventArgs = {
  contactId: Scalars['ID'];
  input: EventInput;
};

export type MutationAddHarmfulContentReportArgs = {
  message: Scalars['String'];
};

export type MutationAddJobTitleArgs = {
  input: JobTitleInput;
};

export type MutationAddListArgs = {
  input: ContactsListInputType;
};

export type MutationAddListToContactArgs = {
  contactId: Scalars['ID'];
  listId: Scalars['ID'];
};

export type MutationAddListsToContactsArgs = {
  contactsIds?: InputMaybe<Array<Scalars['ID']>>;
  listsIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationAddMediaOutletArgs = {
  input: MediaOutletInput;
};

export type MutationAddMediaResortArgs = {
  input: MediaResortInput;
};

export type MutationAddMediaResortToContactArgs = {
  contactId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
};

export type MutationAddMediaResortToMediaOutletArgs = {
  mediaOutletId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
};

export type MutationAddMediaResortsToContactsArgs = {
  contactsIds?: InputMaybe<Array<Scalars['ID']>>;
  mediaResortsIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationAddMediumCategoryArgs = {
  input: MediaOutletsMediumCategoryInput;
};

export type MutationAddRecipientsFromListsToSendingArgs = {
  recipientsFromLists: Array<RecipientFromListInput>;
  sendingId: Scalars['ID'];
};

export type MutationAddRecipientsToSendingArgs = {
  recipients: Array<RecipientInput>;
  sendingId: Scalars['ID'];
};

export type MutationAddSendingFromTemplateArgs = {
  sendingName: Scalars['String'];
  templateId: Scalars['ID'];
};

export type MutationAddSendingWithContactsIdsArgs = {
  contactsIds: Array<Scalars['ID']>;
  input: ContactsSendingInput;
};

export type MutationAddSendingWithRecipientsFromListsArgs = {
  input: ContactsSendingInput;
  listsIds: Array<Scalars['ID']>;
};

export type MutationAddTagToCompaniesArgs = {
  companyIds: Array<Scalars['ID']>;
  input: CompaniesTagInput;
};

export type MutationAddTagToContactsArgs = {
  contactIds: Array<Scalars['ID']>;
  input: ContactsTagInput;
};

export type MutationAddTagToListsArgs = {
  input: ContactsTagInput;
  listIds: Array<Scalars['ID']>;
};

export type MutationAddTagToMediaOutletsArgs = {
  input: MediaOutletsTagInput;
  mediaOutletIds: Array<Scalars['ID']>;
};

export type MutationAddTagToSendingsArgs = {
  input: ContactsTagInput;
  sendingIds: Array<Scalars['ID']>;
};

export type MutationAddTaskArgs = {
  contactId: Scalars['ID'];
  input: TaskInput;
};

export type MutationAddTemplateArgs = {
  input: TemplateInput;
};

export type MutationAddTopicArgs = {
  input: TopicInput;
};

export type MutationAddTopicToContactArgs = {
  contactId: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type MutationAddTopicsToContactsArgs = {
  contactsIds?: InputMaybe<Array<Scalars['ID']>>;
  topicsIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationAddWebinarArgs = {
  contactId: Scalars['ID'];
  input: WebinarInput;
};

export type MutationBulkRefreshLinkedSavedSearchesArgs = {
  listsIds: Array<Scalars['ID']>;
};

export type MutationCreateAndAssignBlacklistArgs = {
  input: BlacklistCreationInput;
};

export type MutationCreateAndAssignTagArgs = {
  input: TagCreationInput;
};

export type MutationDeleteBlacklistArgs = {
  blacklistId: Scalars['String'];
};

export type MutationDeleteBlacklistsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationDeleteMediaResortsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationDeleteTagsArgs = {
  tagIds: Array<Scalars['String']>;
};

export type MutationDuplicateContactSendingsArgs = {
  localizedTitlePart: Scalars['String'];
  sendingsIds: Array<Scalars['ID']>;
};

export type MutationDuplicateListsArgs = {
  listsIds: Array<Scalars['ID']>;
};

export type MutationRefreshLinkedSavedSearchesArgs = {
  listId: Scalars['ID'];
  savedSearchesIds: Array<Scalars['ID']>;
};

export type MutationRemoveCollaborationArgs = {
  collaborationId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type MutationRemoveCommentFromContactArgs = {
  commentId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type MutationRemoveCompaniesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationRemoveCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveCompanyFromBlacklistArgs = {
  blacklistId: Scalars['ID'];
  companyId: Scalars['ID'];
};

export type MutationRemoveCompanyProfilePictureArgs = {
  companyId: Scalars['ID'];
};

export type MutationRemoveCompanySavedSearchArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveContactArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveContactFromBlacklistArgs = {
  blacklist: BlacklistInput;
  contactId: Scalars['ID'];
};

export type MutationRemoveContactSendingArgs = {
  sendingId: Scalars['ID'];
};

export type MutationRemoveContactSendingsArgs = {
  input: Array<Scalars['String']>;
};

export type MutationRemoveContactsArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationRemoveContactsFromListArgs = {
  contactsIds: Array<Scalars['ID']>;
  listId: Scalars['ID'];
};

export type MutationRemoveContactsSavedSearchesFromListArgs = {
  listId: Scalars['ID'];
  savedSearchesIds: Array<Scalars['ID']>;
};

export type MutationRemoveJobTitleArgs = {
  jobTitleId: Scalars['ID'];
};

export type MutationRemoveListArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveListFromContactArgs = {
  contactId: Scalars['ID'];
  listId: Scalars['ID'];
};

export type MutationRemoveListProfilePictureArgs = {
  listId: Scalars['ID'];
};

export type MutationRemoveListsArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationRemoveMediaOutletArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveMediaOutletProfilePictureArgs = {
  mediaOutletId: Scalars['ID'];
};

export type MutationRemoveMediaOutletSavedSearchArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveMediaOutletsArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationRemoveMediaResortFromContactArgs = {
  contactId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
};

export type MutationRemoveMediaResortFromMediaOutletArgs = {
  mediaOutletId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
};

export type MutationRemoveMediumCategoryArgs = {
  mediumCategoryId: Scalars['ID'];
};

export type MutationRemoveProfilePictureArgs = {
  contactId: Scalars['ID'];
};

export type MutationRemoveRecipientsFromSendingArgs = {
  recipientsIds: Array<Scalars['ID']>;
  sendingId: Scalars['ID'];
};

export type MutationRemoveSavedSearchArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveTagFromCompaniesArgs = {
  companyIds: Array<Scalars['ID']>;
  input: CompaniesTagInput;
};

export type MutationRemoveTagFromContactsArgs = {
  contactIds: Array<Scalars['ID']>;
  input: ContactsTagInput;
};

export type MutationRemoveTagFromListsArgs = {
  input: ContactsTagInput;
  listIds: Array<Scalars['ID']>;
};

export type MutationRemoveTagFromMediaOutletsArgs = {
  input: MediaOutletsTagInput;
  mediaOutletIds: Array<Scalars['ID']>;
};

export type MutationRemoveTagFromSendingArgs = {
  input: ContactsTagInput;
  sendingId: Scalars['ID'];
};

export type MutationRemoveTagFromSendingsArgs = {
  input: ContactsTagInput;
  sendingIds: Array<Scalars['ID']>;
};

export type MutationRemoveTemplateArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveTopicFromContactArgs = {
  contactId: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type MutationRemoveTopicsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationSaveCompanySearchArgs = {
  input: CompaniesSavedSearchInput;
};

export type MutationSaveMediaOutletSearchArgs = {
  input: MediaOutletsSavedSearchInput;
};

export type MutationSaveSearchArgs = {
  input: ContactsSavedSearchInput;
};

export type MutationScheduleSendingDistributionArgs = {
  distributionDateTime: Scalars['DateTime'];
  sendingId: Scalars['ID'];
};

export type MutationSendSendingArgs = {
  sendingId: Scalars['ID'];
};

export type MutationTransformCompanyIntoMediaOutletArgs = {
  id: Scalars['ID'];
};

export type MutationTransformMediaOutletIntoCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateBlacklistArgs = {
  input: BlacklistUpdateInput;
};

export type MutationUpdateBulkMediaOutletsArgs = {
  ids: Array<Scalars['ID']>;
  mediaResortsIds: Array<Scalars['ID']>;
};

export type MutationUpdateCollaborationArgs = {
  contactId: Scalars['ID'];
  input: CollaborationWithIdInput;
};

export type MutationUpdateCommentArgs = {
  contactId: Scalars['ID'];
  input: ContactsCommentWithIdInput;
};

export type MutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  input: CompanyInput;
};

export type MutationUpdateCompanyProfilePictureArgs = {
  companyId: Scalars['ID'];
  input: CompaniesProfilePictureInput;
};

export type MutationUpdateCompanySavedSearchArgs = {
  id: Scalars['ID'];
  input: CompaniesSavedSearchInput;
};

export type MutationUpdateCompanySavedSearchesArgs = {
  input: Array<CompaniesSavedSearchWithIdInput>;
};

export type MutationUpdateContactArgs = {
  id: Scalars['ID'];
  input: ContactInput;
};

export type MutationUpdateContactSendingArgs = {
  input: ContactsSendingInput;
  sendingId: Scalars['ID'];
};

export type MutationUpdateContactSendingsArgs = {
  input: Array<ContactsSendingInput>;
};

export type MutationUpdateContactsSavedSearchArgs = {
  listId: Scalars['ID'];
  savedSearchId: Scalars['ID'];
  shouldAutoUpdate: Scalars['Boolean'];
};

export type MutationUpdateListArgs = {
  input: ContactsListInputType;
  listId: Scalars['ID'];
};

export type MutationUpdateListContactArgs = {
  input: ListContactInput;
  listContactId: Scalars['ID'];
};

export type MutationUpdateListProfilePictureArgs = {
  input: ContactsProfilePictureInput;
  listId: Scalars['ID'];
};

export type MutationUpdateMediaOutletArgs = {
  id: Scalars['ID'];
  input: MediaOutletInput;
};

export type MutationUpdateMediaOutletProfilePictureArgs = {
  input: MediaOutletsProfilePictureInput;
  mediaOutletId: Scalars['ID'];
};

export type MutationUpdateMediaOutletSavedSearchArgs = {
  id: Scalars['ID'];
  input: MediaOutletsSavedSearchInput;
};

export type MutationUpdateMediaOutletSavedSearchesArgs = {
  input: Array<MediaOutletsSavedSearchWithIdInput>;
};

export type MutationUpdateOwnContactDataArgs = {
  contactId: Scalars['ID'];
  input: OwnContactDataInput;
};

export type MutationUpdateProfilePictureArgs = {
  contactId: Scalars['ID'];
  input: ContactsProfilePictureInput;
};

export type MutationUpdateSavedSearchArgs = {
  id: Scalars['ID'];
  input: ContactsSavedSearchInput;
};

export type MutationUpdateSavedSearchesArgs = {
  input: Array<ContactsSavedSearchWithIdInput>;
};

export type MutationUpdateSendingRecipientContactDataArgs = {
  collaborationId: Scalars['ID'];
  emailAddress: Scalars['String'];
  recipientId: Scalars['ID'];
  sendingId: Scalars['ID'];
};

export type MutationUpdateTemplateArgs = {
  input: TemplateInput;
};

export type OwnContactData = ContactData & {
  __typename?: 'OwnContactData';
  /** The Address of the contact. */
  address?: Maybe<Scalars['String']>;
  /** The BlogUrl of the contact. */
  blogUrl?: Maybe<Scalars['String']>;
  /** The city of the contact. */
  city?: Maybe<Scalars['String']>;
  /** The Country of the contact. */
  country?: Maybe<Scalars['String']>;
  /** The Email Address of the contact. */
  emailAddress?: Maybe<Scalars['String']>;
  /** The Facebook Profile Url of the contact. */
  facebookProfileUrl?: Maybe<Scalars['String']>;
  /** The fax of the contact. */
  fax?: Maybe<Scalars['String']>;
  /** The id of the contact */
  id: Scalars['ID'];
  /** The Instagram Profile Url of the contact. */
  instagramProfileUrl?: Maybe<Scalars['String']>;
  /** Tells if it is a primary contact data of a contact. */
  isPrimary: Scalars['Boolean'];
  /** The Phone Number of the contact. */
  landlinePhoneNumber?: Maybe<PhoneNumber>;
  /** The LinkedIn Profile Url of the contact. */
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  /** The Mobile Phone Number of the contact. */
  mobilePhoneNumber?: Maybe<PhoneNumber>;
  /** The postal code of the contact. */
  postalCode?: Maybe<Scalars['String']>;
  /** The Twitter Profile Url of the contact. */
  twitterProfileUrl?: Maybe<Scalars['String']>;
  /** The viadeo profile url of the contact. */
  viadeoProfileUrl?: Maybe<Scalars['String']>;
  /** The Website Url of the contact. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** The Xing Url of the contact. */
  xingUrl?: Maybe<Scalars['String']>;
  /** The Youtube Profile Url of the contact. */
  youtubeProfileUrl?: Maybe<Scalars['String']>;
};

export type OwnContactDataInput = {
  /** The Address of the contact. */
  address?: InputMaybe<Scalars['String']>;
  /** The BlogUrl of the contact. */
  blogUrl?: InputMaybe<Scalars['String']>;
  /** The city of the contact. */
  city?: InputMaybe<Scalars['String']>;
  /** The Country of the contact. */
  country?: InputMaybe<Scalars['String']>;
  /** The Email Address of the contact. */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** The Facebook Profile Url of the contact. */
  facebookProfileUrl?: InputMaybe<Scalars['String']>;
  /** The fax of the contact. */
  fax?: InputMaybe<Scalars['String']>;
  /** The Instagram Profile Url of the contact. */
  instagramProfileUrl?: InputMaybe<Scalars['String']>;
  /** Tells if it is a primary contact data of a contact. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The Phone Number of the contact. */
  landlinePhoneNumber?: InputMaybe<PhoneNumberInput>;
  /** The LinkedIn Profile Url of the contact. */
  linkedInProfileUrl?: InputMaybe<Scalars['String']>;
  /** The Mobile Phone Number of the contact. */
  mobilePhoneNumber?: InputMaybe<PhoneNumberInput>;
  /** The fax of the contact. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** The Twitter Profile Url of the contact. */
  twitterProfileUrl?: InputMaybe<Scalars['String']>;
  /** The Website Url of the contact. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** The Xing Url of the contact. */
  xingUrl?: InputMaybe<Scalars['String']>;
  /** The Youtube Profile Url of the contact. */
  youtubeProfileUrl?: InputMaybe<Scalars['String']>;
};

export type PageOfActivities = {
  __typename?: 'PageOfActivities';
  /** List of activities */
  activities: Array<Activity>;
  /** Total count of activities */
  totalCount: Scalars['Int'];
};

export type PageOfActivityParticipants = {
  __typename?: 'PageOfActivityParticipants';
  /** List of participants */
  activityParticipants: Array<IActivityParticipant>;
  /** Total count of participants */
  totalCount: Scalars['Int'];
};

export type PageOfCompanies = {
  __typename?: 'PageOfCompanies';
  /** List of companies */
  companies: Array<Company>;
  /** Total count of companies */
  totalCount: Scalars['Int'];
};

export type PageOfContacts = {
  __typename?: 'PageOfContacts';
  /** List of contacts */
  contacts: Array<Contact>;
  /** Total count of saved searches */
  totalCount: Scalars['Int'];
};

export type PageOfListContacts = {
  __typename?: 'PageOfListContacts';
  /** List of list contacts */
  listContacts: Array<ListContactGraphType>;
  /** Total count of saved searches */
  totalCount: Scalars['Int'];
};

export type PageOfLists = {
  __typename?: 'PageOfLists';
  /** List of lists */
  lists: Array<ListGraphType>;
  /** Total count of lists */
  totalCount: Scalars['Int'];
};

export type PageOfMediaOutlets = {
  __typename?: 'PageOfMediaOutlets';
  /** List of media outlets */
  mediaOutlets: Array<MediaOutlet>;
  /** Total count of media outlets */
  totalCount: Scalars['Int'];
};

export type PageOfSavedSearches = {
  __typename?: 'PageOfSavedSearches';
  /** List of saved searches */
  savedSearches: Array<MediaOutletsSavedSearch>;
  /** Total count of saved searches */
  totalCount: Scalars['Int'];
};

export type PageOfSendingLog = {
  __typename?: 'PageOfSendingLog';
  /** List of sending logs */
  sendingLogs: Array<SendingLog>;
  /** Total count of sending logs */
  totalCount: Scalars['Int'];
};

export type PageOfSendings = {
  __typename?: 'PageOfSendings';
  /** List of sendings */
  sendings: Array<ContactsSending>;
  /** Total count of sendings */
  totalCount: Scalars['Int'];
};

export type PageOfTemplates = {
  __typename?: 'PageOfTemplates';
  /** List of templates */
  templates: Array<Template>;
  /** Total count of templates */
  totalCount: Scalars['Int'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  /** Is the phone number primary */
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** The value of the phone number */
  value?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  /** Is the phone number primary */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The value of the phone number */
  value?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aadContacts?: Maybe<Array<AadContact>>;
  aadGroupMembers?: Maybe<Array<AadContact>>;
  aadGroups?: Maybe<Array<GroupGraphType>>;
  activitiesByContactId: Array<Activity>;
  activitiesByContactIdPaged: PageOfActivities;
  activityEmailAddress: Scalars['String'];
  blacklistById?: Maybe<Blacklist>;
  blacklists: Array<Blacklist>;
  campaigns: Array<Campaign>;
  companies?: Maybe<PageOfCompanies>;
  companiesAvatarDetails: Array<CompaniesAvatarDetailsWithSavedSearchId>;
  companiesByContactId: Array<Company>;
  companiesByName: Array<Company>;
  companiesByNameAndCategoryAndNotContainedInContact?: Maybe<PageOfCompanies>;
  companiesSavedSearches: PageOfSavedSearches;
  company?: Maybe<Company>;
  companyCategories: Array<CompaniesMediumCategory>;
  companySearchSuggestions: Array<CompaniesSearchSuggestionsCategory>;
  companySuggestionsByCity: Array<Scalars['String']>;
  companySuggestionsByContactName: Array<Scalars['String']>;
  companySuggestionsByCountry: Array<Scalars['String']>;
  companySuggestionsByDatabaseType: Array<Scalars['String']>;
  companySuggestionsByName: Array<Scalars['String']>;
  companySuggestionsByPostCode: Array<Scalars['String']>;
  companySuggestionsByRegistrationNumber: Array<Scalars['String']>;
  companySuggestionsByTagName: Array<Scalars['String']>;
  contact?: Maybe<Contact>;
  contactNameFilterSuggestions: Array<Scalars['String']>;
  contactSendingById?: Maybe<ContactsSending>;
  contacts: PageOfContacts;
  contactsAvatarDetails?: Maybe<Array<ContactsWithSavedSearchId>>;
  contactsByIds: Array<Contact>;
  contactsByIdsPaged: PageOfContacts;
  contactsByListId: Array<ListContactGraphType>;
  contactsByListIdAndSearchText: PageOfListContacts;
  contactsByMediumIdNameAndRole: PageOfContacts;
  contactsByName: Array<Contact>;
  contactsByNameAndRoleAndNotContainedInMedium: PageOfContacts;
  contactsByNameOrEmailAddress: Array<Contact>;
  contactsByNameOrEmailAddressAndNotContainedInSendingParticipants: Array<Contact>;
  contactsBySearchTextAndNotContainedInList: PageOfContacts;
  contactsSavedSearchesResults?: Maybe<Array<SavedSearchResultsWithSavedSearchId>>;
  countryFilterSuggestions: Array<Scalars['String']>;
  dataSources: Array<DataSource>;
  exportContactsFromListToCsv: Scalars['ByteArray'];
  exportVCardsForContactsFromList: Scalars['ByteArray'];
  geographicalCoverages: Array<MediaOutletsGeographicalCoverage>;
  isOpenAiAddonEnabled?: Maybe<Scalars['Boolean']>;
  jobTitles: Array<JobTitleGraphType>;
  languageFilterSuggestions: Array<Scalars['String']>;
  languages: Array<Language>;
  linkedSavedSearchesByListId?: Maybe<Array<LinkedSavedSearch>>;
  list?: Maybe<ListGraphType>;
  listContactsByListId: Array<ListContactGraphType>;
  lists: PageOfLists;
  listsByContactId: Array<ListGraphType>;
  listsByIds: Array<ListGraphType>;
  listsByName: Array<ListGraphType>;
  listsByNameCreatorAndDate?: Maybe<Array<ListGraphType>>;
  listsByTags: Array<ListGraphType>;
  listsSuggestionsByContactName: Array<Scalars['String']>;
  listsSuggestionsByCreatedBy: Array<Scalars['String']>;
  listsSuggestionsByMediumName: Array<Scalars['String']>;
  listsSuggestionsByName: Array<Scalars['String']>;
  listsSuggestionsByTagName: Array<Scalars['String']>;
  mediaOutlet?: Maybe<MediaOutlet>;
  mediaOutletAudiences: Array<ReferenceList>;
  mediaOutletCategories: Array<MediaOutletsMediumCategory>;
  mediaOutletDistributions: Array<ReferenceList>;
  mediaOutletMediumPublishingFrequency: Array<ReferenceList>;
  mediaOutletMediumSupportTypes: Array<ReferenceList>;
  mediaOutletSavedSearches: PageOfSavedSearches;
  mediaOutletSuggestionsByCategory: Array<Scalars['String']>;
  mediaOutletSuggestionsByContactName: Array<Scalars['String']>;
  mediaOutletSuggestionsByFrequency: Array<Scalars['String']>;
  mediaOutletSuggestionsByLanguage: Array<Scalars['String']>;
  mediaOutletSuggestionsByMediaType: Array<Scalars['String']>;
  mediaOutletSuggestionsByName: Array<Scalars['String']>;
  mediaOutletSuggestionsByTagName: Array<Scalars['String']>;
  mediaOutlets?: Maybe<PageOfMediaOutlets>;
  mediaOutletsAvatarDetails: Array<MediaOutletsAvatarDetailsWithSavedSearchId>;
  mediaOutletsByContactId: Array<MediaOutlet>;
  mediaOutletsByName: Array<MediaOutlet>;
  mediaOutletsByNameAndCategory: Array<MediaOutlet>;
  mediaOutletsByNameAndCategoryAndNotContainedInContact?: Maybe<PageOfMediaOutlets>;
  mediaResorts: Array<MediaResort>;
  mediumNameFilterSuggestions: Array<Scalars['String']>;
  mediumNamesByListId?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfDesynchronizedContactsInList?: Maybe<Scalars['Int']>;
  recipientsByContactId: Array<IActivityParticipant>;
  recipientsByIds: Array<IActivityParticipant>;
  recipientsBySendingIdAndContactName: PageOfActivityParticipants;
  resortFilterSuggestions: Array<Scalars['String']>;
  roleFilterSuggestions: Array<Scalars['String']>;
  savedSearch?: Maybe<ContactsSavedSearch>;
  savedSearches: PageOfSavedSearches;
  savedSearchesByIds: Array<ContactsSavedSearch>;
  searchListsSuggestions: Array<ListsSearchSuggestionCategory>;
  searchMediaOutletSuggestions: Array<MediaOutletsSearchSuggestionsCategory>;
  searchSuggestions: Array<ContactsSearchSuggestionCategory>;
  sendersBySharedWithUserAadObjectId: Array<Sender>;
  sendingAudienceById?: Maybe<SendingAudience>;
  sendingLogs: PageOfSendingLog;
  sendingOverviewBySendingId?: Maybe<SendingOverview>;
  sendingOverviewsByListId: Array<SendingOverview>;
  sendings: PageOfSendings;
  sendingsByContactId: Array<ContactsSending>;
  sendingsCreatedByUserFilterSuggestions: Array<Scalars['String']>;
  sendingsFilterSuggestionsByCampaignName: Array<Scalars['String']>;
  sendingsFilterSuggestionsByEmailSubject: Array<Scalars['String']>;
  sendingsFilterSuggestionsByRecipientEmailOrName: Array<Scalars['String']>;
  sendingsFilterSuggestionsByRecipientMediumName: Array<Scalars['String']>;
  sendingsFilterSuggestionsByTagName: Array<Scalars['String']>;
  sendingsTitleFilterSuggestions: Array<Scalars['String']>;
  tagNameFilterSuggestions: Array<Scalars['String']>;
  tags: Array<Tag>;
  templates: PageOfTemplates;
  topics: Array<Topic>;
  userByAadObjectId?: Maybe<ContactsUser>;
  usersByIds: Array<ContactsUser>;
};

export type QueryAadContactsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryAadGroupMembersArgs = {
  groupId: Scalars['String'];
};

export type QueryAadGroupsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryActivitiesByContactIdArgs = {
  contactId: Scalars['ID'];
};

export type QueryActivitiesByContactIdPagedArgs = {
  contactId: Scalars['ID'];
  filterItems?: InputMaybe<Array<InputMaybe<ContactsFilterItemInput>>>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryBlacklistByIdArgs = {
  id: Scalars['String'];
};

export type QueryCompaniesArgs = {
  filterItems: Array<CompaniesFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  sortingInput: CompaniesSortingInput;
  take: Scalars['Int'];
};

export type QueryCompaniesAvatarDetailsArgs = {
  companiesAvatarDetailsInput: Array<CompaniesAvatarDetailsInput>;
};

export type QueryCompaniesByContactIdArgs = {
  contactId: Scalars['ID'];
};

export type QueryCompaniesByNameArgs = {
  name: Scalars['String'];
};

export type QueryCompaniesByNameAndCategoryAndNotContainedInContactArgs = {
  category: Scalars['String'];
  contactId: Scalars['ID'];
  name: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryCompaniesSavedSearchesArgs = {
  filterItems: Array<CompaniesFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryCompanySearchSuggestionsArgs = {
  searchText: Scalars['String'];
};

export type QueryCompanySuggestionsByCityArgs = {
  city: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryCompanySuggestionsByContactNameArgs = {
  contactName: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryCompanySuggestionsByCountryArgs = {
  country: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryCompanySuggestionsByDatabaseTypeArgs = {
  databaseType: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryCompanySuggestionsByNameArgs = {
  name: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryCompanySuggestionsByPostCodeArgs = {
  postCode: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryCompanySuggestionsByRegistrationNumberArgs = {
  registrationNumber: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryCompanySuggestionsByTagNameArgs = {
  tagName: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryContactArgs = {
  id: Scalars['ID'];
};

export type QueryContactNameFilterSuggestionsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryContactSendingByIdArgs = {
  sendingId: Scalars['ID'];
};

export type QueryContactsArgs = {
  filterItems: Array<InputMaybe<ContactsFilterItemInput>>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  sortingInput: ContactsSortingInput;
  take: Scalars['Int'];
};

export type QueryContactsAvatarDetailsArgs = {
  contactsQueryInput: Array<ContactsQueryInput>;
};

export type QueryContactsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryContactsByIdsPagedArgs = {
  contactsIds: Array<InputMaybe<Scalars['ID']>>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryContactsByListIdArgs = {
  listId: Scalars['ID'];
};

export type QueryContactsByListIdAndSearchTextArgs = {
  listId: Scalars['ID'];
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryContactsByMediumIdNameAndRoleArgs = {
  mediumId: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryContactsByNameArgs = {
  name: Scalars['String'];
};

export type QueryContactsByNameAndRoleAndNotContainedInMediumArgs = {
  mediumId: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryContactsByNameOrEmailAddressArgs = {
  searchText: Scalars['String'];
};

export type QueryContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsArgs = {
  searchText: Scalars['String'];
  sendingId: Scalars['ID'];
  take: Scalars['Int'];
};

export type QueryContactsBySearchTextAndNotContainedInListArgs = {
  listId: Scalars['ID'];
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryContactsSavedSearchesResultsArgs = {
  contactsQueryInput: Array<ContactsQueryInput>;
};

export type QueryCountryFilterSuggestionsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryExportContactsFromListToCsvArgs = {
  csvHeader: CsvHeader;
  listId: Scalars['ID'];
};

export type QueryExportVCardsForContactsFromListArgs = {
  listId: Scalars['ID'];
};

export type QueryLanguageFilterSuggestionsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryLinkedSavedSearchesByListIdArgs = {
  listId: Scalars['ID'];
};

export type QueryListArgs = {
  id: Scalars['ID'];
};

export type QueryListContactsByListIdArgs = {
  listId: Scalars['ID'];
};

export type QueryListsArgs = {
  filterItems: Array<ContactsFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  sortingInput: ListsSortingInput;
  take: Scalars['Int'];
};

export type QueryListsByContactIdArgs = {
  contactId: Scalars['ID'];
};

export type QueryListsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryListsByNameArgs = {
  name: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryListsByNameCreatorAndDateArgs = {
  createdBy: Scalars['String'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  name: Scalars['String'];
};

export type QueryListsByTagsArgs = {
  tag: Scalars['String'];
};

export type QueryListsSuggestionsByContactNameArgs = {
  name: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryListsSuggestionsByCreatedByArgs = {
  name: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryListsSuggestionsByMediumNameArgs = {
  name: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryListsSuggestionsByNameArgs = {
  name: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryListsSuggestionsByTagNameArgs = {
  name: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletArgs = {
  id: Scalars['ID'];
};

export type QueryMediaOutletSavedSearchesArgs = {
  filterItems: Array<MediaOutletsFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryMediaOutletSuggestionsByCategoryArgs = {
  category: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletSuggestionsByContactNameArgs = {
  contactName: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletSuggestionsByFrequencyArgs = {
  frequency: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletSuggestionsByLanguageArgs = {
  language: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletSuggestionsByMediaTypeArgs = {
  mediaType: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletSuggestionsByNameArgs = {
  name: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletSuggestionsByTagNameArgs = {
  tagName: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediaOutletsArgs = {
  filterItems: Array<MediaOutletsFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  sortingInput: MediaOutletsSortingInput;
  take: Scalars['Int'];
};

export type QueryMediaOutletsAvatarDetailsArgs = {
  mediaOutletsAvatarDetailsInput: Array<MediaOutletsAvatarDetailsInput>;
};

export type QueryMediaOutletsByContactIdArgs = {
  contactId: Scalars['ID'];
};

export type QueryMediaOutletsByNameArgs = {
  name: Scalars['String'];
};

export type QueryMediaOutletsByNameAndCategoryArgs = {
  category: Scalars['String'];
  name: Scalars['String'];
};

export type QueryMediaOutletsByNameAndCategoryAndNotContainedInContactArgs = {
  category: Scalars['String'];
  contactId: Scalars['ID'];
  name: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryMediumNameFilterSuggestionsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryMediumNamesByListIdArgs = {
  listId: Scalars['ID'];
};

export type QueryNumberOfDesynchronizedContactsInListArgs = {
  listId: Scalars['ID'];
};

export type QueryRecipientsByContactIdArgs = {
  contactId: Scalars['ID'];
};

export type QueryRecipientsByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryRecipientsBySendingIdAndContactNameArgs = {
  contactName: Scalars['String'];
  sendingId: Scalars['ID'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryResortFilterSuggestionsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryRoleFilterSuggestionsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QuerySavedSearchArgs = {
  id: Scalars['ID'];
};

export type QuerySavedSearchesArgs = {
  filterItems: Array<ContactsFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QuerySavedSearchesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QuerySearchListsSuggestionsArgs = {
  searchText: Scalars['String'];
};

export type QuerySearchMediaOutletSuggestionsArgs = {
  language: Scalars['String'];
  searchText: Scalars['String'];
};

export type QuerySearchSuggestionsArgs = {
  searchText: Scalars['String'];
};

export type QuerySendingAudienceByIdArgs = {
  pageSize: Scalars['Int'];
  sendingId: Scalars['ID'];
};

export type QuerySendingLogsArgs = {
  sendingId: Scalars['ID'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QuerySendingOverviewBySendingIdArgs = {
  sendingId: Scalars['ID'];
};

export type QuerySendingOverviewsByListIdArgs = {
  listId: Scalars['ID'];
};

export type QuerySendingsArgs = {
  filterItems: Array<ContactsFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  sortingInput: ContactsSortingInput;
  take: Scalars['Int'];
};

export type QuerySendingsByContactIdArgs = {
  contactId: Scalars['String'];
};

export type QuerySendingsCreatedByUserFilterSuggestionsArgs = {
  createdBy: Scalars['String'];
  take: Scalars['Int'];
};

export type QuerySendingsFilterSuggestionsByCampaignNameArgs = {
  campaignName: Scalars['String'];
  take: Scalars['Int'];
};

export type QuerySendingsFilterSuggestionsByEmailSubjectArgs = {
  emailSubject: Scalars['String'];
  take: Scalars['Int'];
};

export type QuerySendingsFilterSuggestionsByRecipientEmailOrNameArgs = {
  recipientNameOrEmail: Scalars['String'];
  take: Scalars['Int'];
};

export type QuerySendingsFilterSuggestionsByRecipientMediumNameArgs = {
  recipientMediumName: Scalars['String'];
  take: Scalars['Int'];
};

export type QuerySendingsFilterSuggestionsByTagNameArgs = {
  tagName: Scalars['String'];
  take: Scalars['Int'];
};

export type QuerySendingsTitleFilterSuggestionsArgs = {
  take: Scalars['Int'];
  title: Scalars['String'];
};

export type QueryTagNameFilterSuggestionsArgs = {
  searchText: Scalars['String'];
  take: Scalars['Int'];
};

export type QueryTagsArgs = {
  entityType: Scalars['String'];
};

export type QueryTemplatesArgs = {
  filterItems: Array<ContactsFilterItemInput>;
  searchText: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QueryUsersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type Recipient = IActivityParticipant &
  IRecipient & {
    __typename?: 'Recipient';
    /** The collaboration id of the recipient */
    collaborationId?: Maybe<Scalars['ID']>;
    /** The contact id of the recipient */
    contact?: Maybe<Contact>;
    /** The email address of the recipient */
    emailAddress?: Maybe<Scalars['String']>;
    /** The id of the recipient */
    id?: Maybe<Scalars['ID']>;
    /** Did the recipient open the sending */
    isOpened?: Maybe<Scalars['Boolean']>;
    /** The number of links clicked by the recipient */
    linksClicked?: Maybe<Scalars['Int']>;
    /** The sending status of the recipient */
    recipientStatus?: Maybe<Scalars['String']>;
    /** The sending id of the recipient */
    sendingId?: Maybe<Scalars['ID']>;
  };

export type RecipientFromList = IActivityParticipant &
  IRecipient & {
    __typename?: 'RecipientFromList';
    /** The collaboration id of the recipient */
    collaborationId?: Maybe<Scalars['ID']>;
    /** The contact id of the recipient */
    contact?: Maybe<Contact>;
    /** The email address of the recipient */
    emailAddress?: Maybe<Scalars['String']>;
    /** The id of the recipient */
    id?: Maybe<Scalars['ID']>;
    /** Did the recipient open the sending */
    isOpened?: Maybe<Scalars['Boolean']>;
    /** The number of links clicked by the recipient */
    linksClicked?: Maybe<Scalars['Int']>;
    /** The list id of the recipient */
    listId?: Maybe<Scalars['String']>;
    /** The sending status of the recipient */
    recipientStatus?: Maybe<Scalars['String']>;
    /** The sending id of the recipient */
    sendingId?: Maybe<Scalars['ID']>;
  };

export type RecipientFromListInput = {
  /** The collaboration id of the recipient */
  collaborationId?: InputMaybe<Scalars['ID']>;
  /** The contact of the recipient */
  contact?: InputMaybe<ContactWithIdInput>;
  /** The email address of the recipient */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** The id of the recipient */
  id?: InputMaybe<Scalars['ID']>;
  /** The list id of the recipient */
  listId?: InputMaybe<Scalars['ID']>;
  /** The sending id of the recipient */
  sendingId?: InputMaybe<Scalars['ID']>;
};

export type RecipientInput = {
  /** The collaboration id of the recipient */
  collaborationId?: InputMaybe<Scalars['ID']>;
  /** The contact of the recipient */
  contact?: InputMaybe<ContactWithIdInput>;
  /** The email address of the recipient */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** The id of the recipient */
  id?: InputMaybe<Scalars['ID']>;
  /** The sending id of the recipient */
  sendingId?: InputMaybe<Scalars['ID']>;
};

export type ReferenceList = {
  /** Id of the reference list */
  id?: Maybe<Scalars['ID']>;
  /** Key of the reference list */
  key?: Maybe<Scalars['String']>;
  /** Labels of the reference list */
  labels?: Maybe<Array<Maybe<MediaOutletsLabel>>>;
};

export type SavedSearchResultContact = {
  __typename?: 'SavedSearchResultContact';
  /** Company names of the contact */
  companies?: Maybe<Array<Medium>>;
  /** The First Name of the contact */
  firstName?: Maybe<Scalars['String']>;
  /** The ID of the contact */
  id?: Maybe<Scalars['ID']>;
  /** The Last Name of the contact */
  lastName?: Maybe<Scalars['String']>;
  /** Media outlet names of the contact */
  mediaOutlets?: Maybe<Array<Medium>>;
  /** The Profile Picture Url of the contact */
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type SavedSearchResultsWithSavedSearchId = {
  __typename?: 'SavedSearchResultsWithSavedSearchId';
  /** Contacts of saved search */
  contacts?: Maybe<Array<SavedSearchResultContact>>;
  /** Id of the saved search */
  savedSearchId: Scalars['ID'];
};

export type SearchCategoryName = {
  __typename?: 'SearchCategoryName';
  /** The name of the search suggestion category */
  name?: Maybe<Scalars['String']>;
  /** The value of the search suggestion category */
  value?: Maybe<Scalars['Int']>;
};

export type Sender = {
  __typename?: 'Sender';
  /** The display name of the sender */
  displayName?: Maybe<Scalars['String']>;
  /** The email address of the sender */
  emailAddress?: Maybe<Scalars['String']>;
  /** The id of the sender */
  id?: Maybe<Scalars['ID']>;
  /** The display name of the sender */
  userId?: Maybe<Scalars['String']>;
};

export type SenderInput = {
  /** The id of the sender */
  id?: InputMaybe<Scalars['ID']>;
};

export type SendingAudience = {
  __typename?: 'SendingAudience';
  /** Name of recipients from the first page of sending recipients */
  namesOfFirstPageOfContacts?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Total number of recipients in sending */
  numberOfRecipients?: Maybe<Scalars['Int']>;
  /** Number of recipients in sending without an email address */
  numberOfRecipientsWithoutEmailAddress?: Maybe<Scalars['Int']>;
};

export type SendingLog = {
  __typename?: 'SendingLog';
  /** Creation date of a log */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Description of a action */
  description?: Maybe<Scalars['String']>;
  /** ID of a sending */
  sendingId?: Maybe<Scalars['ID']>;
  /** The subject of a Sending */
  subject?: Maybe<Scalars['String']>;
  /** User name of the action initiator */
  userName?: Maybe<Scalars['String']>;
};

export type SendingOverview = {
  __typename?: 'SendingOverview';
  /** Clicks rate percentage */
  clickRatePercentage?: Maybe<Scalars['Int']>;
  /** Creation date of the sending */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Percent of delivered emails */
  deliveredPercentage?: Maybe<Scalars['Int']>;
  /** The Distribution Duration of the sending */
  distributionDurationSeconds?: Maybe<Scalars['Int']>;
  /** The number of recipients who received the mail */
  numberOfDeliveredRecipients?: Maybe<Scalars['Int']>;
  /** The number of recipients who opened the mail */
  numberOfOpenedSendings?: Maybe<Scalars['Int']>;
  /** The number of recipients who unsubscribed */
  numberOfUnsubscribedRecipients?: Maybe<Scalars['Int']>;
  /** Openings rate percentage */
  openingsRatePercentage?: Maybe<Scalars['Int']>;
  /** Sending identifier */
  sendingId?: Maybe<Scalars['String']>;
  /** The status of the sending */
  sendingStatus?: Maybe<Scalars['Int']>;
  /** Sending title */
  title?: Maybe<Scalars['String']>;
  /** Total number of links clicks */
  totalNumberOfClickedLinks?: Maybe<Scalars['Int']>;
  /** The number of recipients who didn't receive the mail */
  totalNumberOfFailedDeliveries?: Maybe<Scalars['Int']>;
  /** Total number of email openings */
  totalNumberOfOpenings?: Maybe<Scalars['Int']>;
  /** Total number of recipients */
  totalNumberOfRecipients?: Maybe<Scalars['Int']>;
};

export type SendingStatus = {
  __typename?: 'SendingStatus';
  /** The name of the sending status */
  name?: Maybe<Scalars['String']>;
  /** The value of the sending status */
  value?: Maybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'Tag';
  /** The entity types on which the tag can be applied */
  entityTypes: Array<Maybe<Scalars['String']>>;
  /** The color of the tag */
  hexColor: Scalars['String'];
  /** The id of the tag */
  id: Scalars['ID'];
  /** The name of the tag */
  name: Scalars['String'];
};

export type TagCreationInput = {
  /** The data source */
  dataSource?: InputMaybe<DataSourceInput>;
  /** The ids of the entities on which to assign the tag */
  entityIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The entity type on which to assign the tag */
  entityType?: InputMaybe<Scalars['String']>;
  /** The name of the tag */
  name?: InputMaybe<Scalars['String']>;
};

export type Task = Activity & {
  __typename?: 'Task';
  /** Comment of the task */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the task */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the task */
  createdByUser?: Maybe<ContactsUser>;
  /** Deadline of the task */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Id of the task */
  id?: Maybe<Scalars['ID']>;
  /** Participants of the task */
  participants?: Maybe<Array<ActivityParticipant>>;
  /** Subject of the task */
  subject?: Maybe<Scalars['String']>;
  /** Title of the task */
  title?: Maybe<Scalars['String']>;
};

export type TaskInput = {
  /** Comment of the task */
  comment?: InputMaybe<Scalars['String']>;
  /** Deadline of the task */
  deadline?: InputMaybe<Scalars['DateTime']>;
  /** Id of the task */
  id?: InputMaybe<Scalars['ID']>;
  /** Subject of the task */
  subject?: InputMaybe<Scalars['String']>;
  /** Title of the task */
  title?: InputMaybe<Scalars['String']>;
};

export type Template = {
  __typename?: 'Template';
  /** When the template was created. */
  createdAt: Scalars['DateTime'];
  /** Who created the template */
  createdBy: Scalars['ID'];
  /** The html body of the template */
  htmlBody: Scalars['String'];
  /** The id of the template */
  id: Scalars['ID'];
  /** The image as base 64 string of the template */
  imageAsBase64: Scalars['String'];
  /** Indicates whether the template is prebuilt */
  isPrebuilt: Scalars['Boolean'];
  /** The json body of the template */
  jsonBody: Scalars['String'];
  /** When the template was last modified. */
  lastModifiedAt: Scalars['DateTime'];
  /** Who last modified the template */
  lastModifiedBy: Scalars['ID'];
  /** The title of the template */
  title: Scalars['String'];
};

export type TemplateInput = {
  /** The html body of the template */
  htmlBody: Scalars['String'];
  /** The id of the template */
  id: Scalars['ID'];
  /** The json body of the template */
  imageAsBase64: Scalars['String'];
  /** The json body of the template */
  jsonBody: Scalars['String'];
  /** The title of the template */
  title: Scalars['String'];
};

export type TenantUser = {
  __typename?: 'TenantUser';
  /** The AAD object ID of the user */
  aadObjectId?: Maybe<Scalars['String']>;
  /** The ID of the user. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the user */
  name?: Maybe<Scalars['String']>;
  /** The teams ID of the user. */
  teamsId?: Maybe<Scalars['String']>;
};

export type Topic = {
  __typename?: 'Topic';
  /** Data source key of the topic */
  dataSourceKey?: Maybe<Scalars['String']>;
  /** The ID of the topic */
  id: Scalars['ID'];
  /** Name of the topic */
  name: Scalars['String'];
  /** Type of the topic */
  type: Scalars['String'];
};

export type TopicInput = {
  /** The name of the topic */
  name?: InputMaybe<Scalars['String']>;
};

export type Webinar = Activity & {
  __typename?: 'Webinar';
  /** Comment of the activity */
  comment?: Maybe<Scalars['String']>;
  /** Created at of the activity */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created by of the activity */
  createdByUser?: Maybe<ContactsUser>;
  /** When does the activity end */
  endsAt?: Maybe<Scalars['DateTime']>;
  /** Id of the activity */
  id?: Maybe<Scalars['ID']>;
  /** Participants of the activity */
  participants?: Maybe<Array<ActivityParticipant>>;
  /** When does the activity start */
  startsAt?: Maybe<Scalars['DateTime']>;
  /** Title of the activity */
  title?: Maybe<Scalars['String']>;
};

export type WebinarInput = {
  /** Comment of the activity */
  comment?: InputMaybe<Scalars['String']>;
  /** When does the activity end */
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** Id of the activity */
  id?: InputMaybe<Scalars['ID']>;
  /** When does the activity start */
  startsAt?: InputMaybe<Scalars['DateTime']>;
  /** Title of the activity */
  title?: InputMaybe<Scalars['String']>;
};

export type CreateAndAssignTagMutationVariables = Exact<{
  input: TagCreationInput;
}>;

export type CreateAndAssignTagMutation = { __typename?: 'Mutation'; createAndAssignTag: { __typename?: 'Tag'; id: string; name: string; hexColor: string } };

export type DeleteTagsMutationVariables = Exact<{
  tagIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteTagsMutation = { __typename?: 'Mutation'; deleteTags: Array<string> };

export type FetchTagsByEntityTypeQueryVariables = Exact<{
  entityType: Scalars['String'];
}>;

export type FetchTagsByEntityTypeQuery = { __typename?: 'Query'; tags: Array<{ __typename?: 'Tag'; id: string; name: string; hexColor: string }> };

export type FetchDataSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchDataSourcesQuery = { __typename?: 'Query'; dataSources: Array<{ __typename?: 'DataSource'; id?: string | null; key?: string | null }> };

export type FetchBlacklistByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FetchBlacklistByIdQuery = { __typename?: 'Query'; blacklistById?: { __typename?: 'Blacklist'; id?: string | null; name?: string | null } | null };

export type FetchAllLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllLanguagesQuery = { __typename?: 'Query'; languages: Array<{ __typename?: 'Language'; id: string; name: string }> };

export type AddRecipientsToSendingMutationVariables = Exact<{
  sendingId: Scalars['ID'];
  recipients: Array<RecipientInput> | RecipientInput;
}>;

export type AddRecipientsToSendingMutation = {
  __typename?: 'Mutation';
  addRecipientsToSending?: Array<
    | { __typename?: 'ActivityParticipant' }
    | {
        __typename?: 'Recipient';
        id?: string | null;
        sendingId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        linksClicked?: number | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          isGloballySignedOut?: boolean | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
    | {
        __typename?: 'RecipientFromList';
        id?: string | null;
        sendingId?: string | null;
        listId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        linksClicked?: number | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          isGloballySignedOut?: boolean | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
    | null
  > | null;
};

export type RemoveRecipientsFromSendingMutationVariables = Exact<{
  sendingId: Scalars['ID'];
  recipientsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveRecipientsFromSendingMutation = { __typename?: 'Mutation'; removeRecipientsFromSending: Array<string> };

export type UpdateSendingRecipientContactDataMutationVariables = Exact<{
  sendingId: Scalars['ID'];
  recipientId: Scalars['ID'];
  collaborationId: Scalars['ID'];
  emailAddress: Scalars['String'];
}>;

export type UpdateSendingRecipientContactDataMutation = {
  __typename?: 'Mutation';
  updateSendingRecipientContactData?:
    | { __typename?: 'ActivityParticipant' }
    | {
        __typename?: 'Recipient';
        id?: string | null;
        sendingId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        linksClicked?: number | null;
        emailAddress?: string | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          isGloballySignedOut?: boolean | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
    | {
        __typename?: 'RecipientFromList';
        id?: string | null;
        sendingId?: string | null;
        listId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        emailAddress?: string | null;
        linksClicked?: number | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          isGloballySignedOut?: boolean | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
    | null;
};

export type AddRecipientsFromListsToSendingMutationVariables = Exact<{
  sendingId: Scalars['ID'];
  recipientsFromLists: Array<RecipientFromListInput> | RecipientFromListInput;
}>;

export type AddRecipientsFromListsToSendingMutation = {
  __typename?: 'Mutation';
  addRecipientsFromListsToSending?: Array<
    | { __typename?: 'ActivityParticipant' }
    | {
        __typename?: 'Recipient';
        id?: string | null;
        sendingId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        linksClicked?: number | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
    | {
        __typename?: 'RecipientFromList';
        id?: string | null;
        sendingId?: string | null;
        listId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        linksClicked?: number | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          isGloballySignedOut?: boolean | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
    | null
  > | null;
};

export type AddSendingFromTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
  sendingName: Scalars['String'];
}>;

export type AddSendingFromTemplateMutation = {
  __typename?: 'Mutation';
  addSendingFromTemplate?: {
    __typename?: 'ContactsSending';
    id?: string | null;
    title?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    subject?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    recipientsIds?: Array<string> | null;
    mailPreviewLink?: string | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    participants?: Array<
      | {
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
          } | null;
        }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          collaborationId?: string | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          collaborationId?: string | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
  } | null;
};

export type AddTagToSendingsMutationVariables = Exact<{
  sendingIds: Array<Scalars['ID']> | Scalars['ID'];
  input: ContactsTagInput;
}>;

export type AddTagToSendingsMutation = { __typename?: 'Mutation'; addTagToSendings: { __typename?: 'ContactsTag'; id?: string | null; name?: string | null } };

export type RemoveTagFromSendingsMutationVariables = Exact<{
  sendingIds: Array<Scalars['ID']> | Scalars['ID'];
  input: ContactsTagInput;
}>;

export type RemoveTagFromSendingsMutation = {
  __typename?: 'Mutation';
  removeTagFromSendings: { __typename?: 'ContactsTag'; id?: string | null; name?: string | null };
};

export type SendSendingMutationVariables = Exact<{
  sendingId: Scalars['ID'];
}>;

export type SendSendingMutation = {
  __typename?: 'Mutation';
  sendSending: {
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          sendingId?: string | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          sendingId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  };
};

export type ScheduleSendingDistributionMutationVariables = Exact<{
  sendingId: Scalars['ID'];
  distributionDateTime: Scalars['DateTime'];
}>;

export type ScheduleSendingDistributionMutation = {
  __typename?: 'Mutation';
  scheduleSendingDistribution: {
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          sendingId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  };
};

export type AddHarmfulContentReportMutationVariables = Exact<{
  message: Scalars['String'];
}>;

export type AddHarmfulContentReportMutation = {
  __typename?: 'Mutation';
  addHarmfulContentReport: { __typename?: 'AiContentReport'; createdAt?: any | null; message?: string | null };
};

export type AddAadContactsToSendingMutationVariables = Exact<{
  sendingId: Scalars['ID'];
  input: Array<AadContactInput> | AadContactInput;
}>;

export type AddAadContactsToSendingMutation = { __typename?: 'Mutation'; addAadContactsToSending: Array<string> };

export type FetchSendersByUserAadIdQueryVariables = Exact<{ [key: string]: never }>;

export type FetchSendersByUserAadIdQuery = {
  __typename?: 'Query';
  sendersBySharedWithUserAadObjectId: Array<{
    __typename?: 'Sender';
    id?: string | null;
    emailAddress?: string | null;
    displayName?: string | null;
    userId?: string | null;
  }>;
};

export type IsOpenAiAddonEnabledQueryVariables = Exact<{ [key: string]: never }>;

export type IsOpenAiAddonEnabledQuery = { __typename?: 'Query'; isOpenAiAddonEnabled?: boolean | null };

export type FetchRecipientsBySendingIdAndContactNameQueryVariables = Exact<{
  sendingId: Scalars['ID'];
  contactName: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;

export type FetchRecipientsBySendingIdAndContactNameQuery = {
  __typename?: 'Query';
  recipientsBySendingIdAndContactName: {
    __typename?: 'PageOfActivityParticipants';
    totalCount: number;
    activityParticipants: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          emailAddress?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          sendingId?: string | null;
          emailAddress?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    >;
  };
};

export type FetchContactsByNameOrEmailAddressQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;

export type FetchContactsByNameOrEmailAddressQuery = {
  __typename?: 'Query';
  contactsByNameOrEmailAddress: Array<{
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  }>;
};

export type FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryVariables = Exact<{
  searchText: Scalars['String'];
  sendingId: Scalars['ID'];
  take: Scalars['Int'];
}>;

export type FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery = {
  __typename?: 'Query';
  contactsByNameOrEmailAddressAndNotContainedInSendingParticipants: Array<{
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  }>;
};

export type FetchSendingByIdQueryVariables = Exact<{
  sendingId: Scalars['ID'];
}>;

export type FetchSendingByIdQuery = {
  __typename?: 'Query';
  contactSendingById?: {
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    sendingScheduledAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    mailPreviewLink?: string | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          sendingId?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  } | null;
};

export type FetchSendingLogsBySendingIdQueryVariables = Exact<{
  sendingId: Scalars['ID'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;

export type FetchSendingLogsBySendingIdQuery = {
  __typename?: 'Query';
  sendingLogs: {
    __typename?: 'PageOfSendingLog';
    totalCount: number;
    sendingLogs: Array<{
      __typename?: 'SendingLog';
      userName?: string | null;
      subject?: string | null;
      creationDate?: any | null;
      description?: string | null;
    }>;
  };
};

export type FetchSendingsForEditorByQueryParamsQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  sortingInput: ContactsSortingInput;
}>;

export type FetchSendingsForEditorByQueryParamsQuery = {
  __typename?: 'Query';
  sendings: {
    __typename?: 'PageOfSendings';
    totalCount: number;
    sendings: Array<{
      __typename?: 'ContactsSending';
      id?: string | null;
      title?: string | null;
      createdAt?: any | null;
      mailPreviewLink?: string | null;
      status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    }>;
  };
};

export type SendingAudienceBySendingIdQueryVariables = Exact<{
  sendingId: Scalars['ID'];
  pageSize: Scalars['Int'];
}>;

export type SendingAudienceBySendingIdQuery = {
  __typename?: 'Query';
  sendingAudienceById?: {
    __typename?: 'SendingAudience';
    numberOfRecipients?: number | null;
    numberOfRecipientsWithoutEmailAddress?: number | null;
    namesOfFirstPageOfContacts?: Array<string | null> | null;
  } | null;
};

export type RemoveSendingMutationVariables = Exact<{
  sendingId: Scalars['ID'];
}>;

export type RemoveSendingMutation = { __typename?: 'Mutation'; removeContactSending?: { __typename?: 'ContactsSending'; id?: string | null } | null };

export type AddSendingMutationVariables = Exact<{
  input: ContactsSendingInput;
}>;

export type AddSendingMutation = {
  __typename?: 'Mutation';
  addContactSending: {
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          sendingId?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  };
};

export type AddSendingWithRecipientsFromListsMutationVariables = Exact<{
  input: ContactsSendingInput;
  listsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AddSendingWithRecipientsFromListsMutation = {
  __typename?: 'Mutation';
  addSendingWithRecipientsFromLists: {
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  };
};

export type AddSendingWithContactsIdsMutationVariables = Exact<{
  input: ContactsSendingInput;
  contactsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AddSendingWithContactsIdsMutation = {
  __typename?: 'Mutation';
  addSendingWithContactsIds: {
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  };
};

export type UpdateSendingMutationVariables = Exact<{
  sendingId: Scalars['ID'];
  input: ContactsSendingInput;
}>;

export type UpdateSendingMutation = {
  __typename?: 'Mutation';
  updateContactSending: {
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    mailPreviewLink?: string | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          sendingId?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  };
};

export type UpdateSendingsMutationVariables = Exact<{
  input: Array<ContactsSendingInput> | ContactsSendingInput;
}>;

export type UpdateSendingsMutation = {
  __typename?: 'Mutation';
  updateContactSendings: Array<{
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          sendingId?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  }>;
};

export type AddSendingsMutationVariables = Exact<{
  input: Array<ContactsSendingInput> | ContactsSendingInput;
}>;

export type AddSendingsMutation = {
  __typename?: 'Mutation';
  addContactSendings: Array<{
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          sendingId?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  }>;
};

export type RemoveSendingsMutationVariables = Exact<{
  input: Array<Scalars['String']> | Scalars['String'];
}>;

export type RemoveSendingsMutation = { __typename?: 'Mutation'; removeContactSendings: Array<string> };

export type DuplicateSendingsMutationVariables = Exact<{
  sendingsIds: Array<Scalars['ID']> | Scalars['ID'];
  localizedTitlePart: Scalars['String'];
}>;

export type DuplicateSendingsMutation = {
  __typename?: 'Mutation';
  duplicateContactSendings: Array<{
    __typename?: 'ContactsSending';
    id?: string | null;
    imageAsBase64?: string | null;
    isFavourite?: boolean | null;
    title?: string | null;
    subject?: string | null;
    htmlBody?: string | null;
    jsonBody?: string | null;
    originalHtmlBody?: string | null;
    mjmlBody?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    respondToEmailAddress?: string | null;
    senderDisplayName?: string | null;
    senderEmailAddress?: string | null;
    deliveredAt?: any | null;
    numberOfRecipients?: number | null;
    distributionDuration?: any | null;
    blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
    sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
    participants?: Array<
      | { __typename?: 'ActivityParticipant' }
      | {
          __typename?: 'Recipient';
          id?: string | null;
          sendingId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'RecipientFromList';
          id?: string | null;
          sendingId?: string | null;
          listId?: string | null;
          collaborationId?: string | null;
          isOpened?: boolean | null;
          linksClicked?: number | null;
          contact?: {
            __typename?: 'Contact';
            id?: string | null;
            salutation?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            profilePictureUrl?: string | null;
            isGloballySignedOut?: boolean | null;
            tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
            topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
            mediaResorts?: Array<{
              __typename?: 'ContactsMediaResort';
              mediaResortId?: string | null;
              mediaResortName?: string | null;
              dataSourceKey?: string | null;
            }> | null;
            comments?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              replies?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
              }> | null;
            }> | null;
            blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
            ownContactData?: {
              __typename?: 'OwnContactData';
              address?: string | null;
              country?: string | null;
              city?: string | null;
              postalCode?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            } | null;
            collaborations?: Array<{
              __typename?: 'Collaboration';
              id: string;
              jobDescription?: string | null;
              address?: string | null;
              country?: string | null;
              city?: string | null;
              fax?: string | null;
              emailAddress?: string | null;
              blogUrl?: string | null;
              websiteUrl?: string | null;
              twitterProfileUrl?: string | null;
              instagramProfileUrl?: string | null;
              linkedInProfileUrl?: string | null;
              youtubeProfileUrl?: string | null;
              facebookProfileUrl?: string | null;
              isPrimary: boolean;
              postalCode?: string | null;
              medium?: {
                __typename?: 'Medium';
                id?: string | null;
                name?: string | null;
                type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              jobTitle?: {
                __typename?: 'JobTitleGraphType';
                id?: string | null;
                name?: string | null;
                dataSourceKey?: string | null;
                mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
              } | null;
              landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            }> | null;
          } | null;
        }
    > | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
  }>;
};

export type FetchSendingsByQueryParamsQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  sortingInput: ContactsSortingInput;
}>;

export type FetchSendingsByQueryParamsQuery = {
  __typename?: 'Query';
  sendings: {
    __typename?: 'PageOfSendings';
    totalCount: number;
    sendings: Array<{
      __typename?: 'ContactsSending';
      id?: string | null;
      imageAsBase64?: string | null;
      isFavourite?: boolean | null;
      title?: string | null;
      subject?: string | null;
      htmlBody?: string | null;
      jsonBody?: string | null;
      originalHtmlBody?: string | null;
      mjmlBody?: string | null;
      createdAt?: any | null;
      lastModifiedAt?: any | null;
      respondToEmailAddress?: string | null;
      senderDisplayName?: string | null;
      senderEmailAddress?: string | null;
      deliveredAt?: any | null;
      numberOfRecipients?: number | null;
      distributionDuration?: any | null;
      blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
      status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
      createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
      lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string; profilePictureUrl: string } | null;
      sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
      participants?: Array<
        | { __typename?: 'ActivityParticipant' }
        | {
            __typename?: 'Recipient';
            id?: string | null;
            sendingId?: string | null;
            collaborationId?: string | null;
            isOpened?: boolean | null;
            linksClicked?: number | null;
            contact?: {
              __typename?: 'Contact';
              id?: string | null;
              salutation?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              profilePictureUrl?: string | null;
              isGloballySignedOut?: boolean | null;
              tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
              topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
              mediaResorts?: Array<{
                __typename?: 'ContactsMediaResort';
                mediaResortId?: string | null;
                mediaResortName?: string | null;
                dataSourceKey?: string | null;
              }> | null;
              comments?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
                replies?: Array<{
                  __typename?: 'ContactsComment';
                  id?: string | null;
                  content?: string | null;
                  createdAt?: any | null;
                  author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                  mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
                }> | null;
              }> | null;
              blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
              ownContactData?: {
                __typename?: 'OwnContactData';
                address?: string | null;
                country?: string | null;
                city?: string | null;
                postalCode?: string | null;
                fax?: string | null;
                emailAddress?: string | null;
                blogUrl?: string | null;
                websiteUrl?: string | null;
                twitterProfileUrl?: string | null;
                instagramProfileUrl?: string | null;
                linkedInProfileUrl?: string | null;
                youtubeProfileUrl?: string | null;
                facebookProfileUrl?: string | null;
                isPrimary: boolean;
                landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
                mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              } | null;
              collaborations?: Array<{
                __typename?: 'Collaboration';
                id: string;
                jobDescription?: string | null;
                address?: string | null;
                country?: string | null;
                city?: string | null;
                fax?: string | null;
                emailAddress?: string | null;
                blogUrl?: string | null;
                websiteUrl?: string | null;
                twitterProfileUrl?: string | null;
                instagramProfileUrl?: string | null;
                linkedInProfileUrl?: string | null;
                youtubeProfileUrl?: string | null;
                facebookProfileUrl?: string | null;
                isPrimary: boolean;
                postalCode?: string | null;
                medium?: {
                  __typename?: 'Medium';
                  id?: string | null;
                  name?: string | null;
                  type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
                } | null;
                jobTitle?: {
                  __typename?: 'JobTitleGraphType';
                  id?: string | null;
                  name?: string | null;
                  dataSourceKey?: string | null;
                  mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
                } | null;
                landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
                mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'RecipientFromList';
            id?: string | null;
            sendingId?: string | null;
            listId?: string | null;
            collaborationId?: string | null;
            isOpened?: boolean | null;
            linksClicked?: number | null;
            contact?: {
              __typename?: 'Contact';
              id?: string | null;
              salutation?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              profilePictureUrl?: string | null;
              isGloballySignedOut?: boolean | null;
              tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
              topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
              mediaResorts?: Array<{
                __typename?: 'ContactsMediaResort';
                mediaResortId?: string | null;
                mediaResortName?: string | null;
                dataSourceKey?: string | null;
              }> | null;
              comments?: Array<{
                __typename?: 'ContactsComment';
                id?: string | null;
                content?: string | null;
                createdAt?: any | null;
                author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
                replies?: Array<{
                  __typename?: 'ContactsComment';
                  id?: string | null;
                  content?: string | null;
                  createdAt?: any | null;
                  author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
                  mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
                }> | null;
              }> | null;
              blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
              ownContactData?: {
                __typename?: 'OwnContactData';
                address?: string | null;
                country?: string | null;
                city?: string | null;
                postalCode?: string | null;
                fax?: string | null;
                emailAddress?: string | null;
                blogUrl?: string | null;
                websiteUrl?: string | null;
                twitterProfileUrl?: string | null;
                instagramProfileUrl?: string | null;
                linkedInProfileUrl?: string | null;
                youtubeProfileUrl?: string | null;
                facebookProfileUrl?: string | null;
                isPrimary: boolean;
                landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
                mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              } | null;
              collaborations?: Array<{
                __typename?: 'Collaboration';
                id: string;
                jobDescription?: string | null;
                address?: string | null;
                country?: string | null;
                city?: string | null;
                fax?: string | null;
                emailAddress?: string | null;
                blogUrl?: string | null;
                websiteUrl?: string | null;
                twitterProfileUrl?: string | null;
                instagramProfileUrl?: string | null;
                linkedInProfileUrl?: string | null;
                youtubeProfileUrl?: string | null;
                facebookProfileUrl?: string | null;
                isPrimary: boolean;
                postalCode?: string | null;
                medium?: {
                  __typename?: 'Medium';
                  id?: string | null;
                  name?: string | null;
                  type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
                } | null;
                jobTitle?: {
                  __typename?: 'JobTitleGraphType';
                  id?: string | null;
                  name?: string | null;
                  dataSourceKey?: string | null;
                  mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
                } | null;
                landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
                mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
              }> | null;
            } | null;
          }
      > | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
    }>;
  };
};

export type FetchUsersByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type FetchUsersByIdsQuery = { __typename?: 'Query'; usersByIds: Array<{ __typename?: 'ContactsUser'; id: string }> };

export type SendingsTitleFilterSuggestionsQueryVariables = Exact<{
  title: Scalars['String'];
  take: Scalars['Int'];
}>;

export type SendingsTitleFilterSuggestionsQuery = { __typename?: 'Query'; sendingsTitleFilterSuggestions: Array<string> };

export type SendingsCreatedByUserFilterSuggestionsQueryVariables = Exact<{
  createdBy: Scalars['String'];
  take: Scalars['Int'];
}>;

export type SendingsCreatedByUserFilterSuggestionsQuery = { __typename?: 'Query'; sendingsCreatedByUserFilterSuggestions: Array<string> };

export type SendingsFilterSuggestionsByCampaignNameQueryVariables = Exact<{
  campaignName: Scalars['String'];
  take: Scalars['Int'];
}>;

export type SendingsFilterSuggestionsByCampaignNameQuery = { __typename?: 'Query'; sendingsFilterSuggestionsByCampaignName: Array<string> };

export type SendingsFilterSuggestionsByEmailSubjectQueryVariables = Exact<{
  emailSubject: Scalars['String'];
  take: Scalars['Int'];
}>;

export type SendingsFilterSuggestionsByEmailSubjectQuery = { __typename?: 'Query'; sendingsFilterSuggestionsByEmailSubject: Array<string> };

export type SendingsFilterSuggestionsByTagNameQueryVariables = Exact<{
  tagName: Scalars['String'];
  take: Scalars['Int'];
}>;

export type SendingsFilterSuggestionsByTagNameQuery = { __typename?: 'Query'; sendingsFilterSuggestionsByTagName: Array<string> };

export type SendingsFilterSuggestionsByRecipientMediumNameQueryVariables = Exact<{
  recipientMediumName: Scalars['String'];
  take: Scalars['Int'];
}>;

export type SendingsFilterSuggestionsByRecipientMediumNameQuery = { __typename?: 'Query'; sendingsFilterSuggestionsByRecipientMediumName: Array<string> };

export type SendingsFilterSuggestionsByRecipientEmailOrNameQueryVariables = Exact<{
  recipientNameOrEmail: Scalars['String'];
  take: Scalars['Int'];
}>;

export type SendingsFilterSuggestionsByRecipientEmailOrNameQuery = { __typename?: 'Query'; sendingsFilterSuggestionsByRecipientEmailOrName: Array<string> };

export type SendingOverviewBySendingIdQueryVariables = Exact<{
  sendingId: Scalars['ID'];
}>;

export type SendingOverviewBySendingIdQuery = {
  __typename?: 'Query';
  sendingOverviewBySendingId?: {
    __typename?: 'SendingOverview';
    sendingId?: string | null;
    sendingStatus?: number | null;
    numberOfDeliveredRecipients?: number | null;
    totalNumberOfRecipients?: number | null;
    totalNumberOfOpenings?: number | null;
    totalNumberOfClickedLinks?: number | null;
    totalNumberOfFailedDeliveries?: number | null;
    numberOfOpenedSendings?: number | null;
    numberOfUnsubscribedRecipients?: number | null;
    distributionDurationSeconds?: number | null;
    deliveredPercentage?: number | null;
    openingsRatePercentage?: number | null;
    clickRatePercentage?: number | null;
  } | null;
};

export type AddTemplateMutationVariables = Exact<{
  input: TemplateInput;
}>;

export type AddTemplateMutation = {
  __typename?: 'Mutation';
  addTemplate: {
    __typename?: 'Template';
    id: string;
    title: string;
    jsonBody: string;
    htmlBody: string;
    imageAsBase64: string;
    isPrebuilt: boolean;
    createdAt: any;
    createdBy: string;
    lastModifiedAt: any;
    lastModifiedBy: string;
  };
};

export type RemoveTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveTemplateMutation = {
  __typename?: 'Mutation';
  removeTemplate: {
    __typename?: 'Template';
    id: string;
    title: string;
    jsonBody: string;
    htmlBody: string;
    imageAsBase64: string;
    isPrebuilt: boolean;
    createdAt: any;
    createdBy: string;
    lastModifiedAt: any;
    lastModifiedBy: string;
  };
};

export type UpdateTemplateMutationVariables = Exact<{
  input: TemplateInput;
}>;

export type UpdateTemplateMutation = {
  __typename?: 'Mutation';
  updateTemplate: {
    __typename?: 'Template';
    id: string;
    title: string;
    jsonBody: string;
    htmlBody: string;
    imageAsBase64: string;
    isPrebuilt: boolean;
    createdAt: any;
    createdBy: string;
    lastModifiedAt: any;
    lastModifiedBy: string;
  };
};

export type FetchTemplatesQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;

export type FetchTemplatesQuery = {
  __typename?: 'Query';
  templates: {
    __typename?: 'PageOfTemplates';
    totalCount: number;
    templates: Array<{
      __typename?: 'Template';
      id: string;
      title: string;
      jsonBody: string;
      htmlBody: string;
      imageAsBase64: string;
      isPrebuilt: boolean;
      createdAt: any;
      createdBy: string;
      lastModifiedAt: any;
      lastModifiedBy: string;
    }>;
  };
};

export type CreateAndAssignBlacklistMutationVariables = Exact<{
  input: BlacklistCreationInput;
}>;

export type CreateAndAssignBlacklistMutation = {
  __typename?: 'Mutation';
  createAndAssignBlacklist?: { __typename?: 'Blacklist'; id?: string | null; name?: string | null } | null;
};

export type FetchAllBlacklistsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllBlacklistsQuery = { __typename?: 'Query'; blacklists: Array<{ __typename?: 'Blacklist'; id?: string | null; name?: string | null }> };

export type AddCompanySavedSearchMutationVariables = Exact<{
  savedCompanySearch: CompaniesSavedSearchInput;
}>;

export type AddCompanySavedSearchMutation = {
  __typename?: 'Mutation';
  saveCompanySearch: {
    __typename?: 'CompaniesSavedSearch';
    id?: string | null;
    searchText?: string | null;
    name?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'CompaniesFilterItem'; fieldName?: string | null; value?: string | null }>;
  };
};

export type UpdateCompanySavedSearchMutationVariables = Exact<{
  id: Scalars['ID'];
  savedCompanySearch: CompaniesSavedSearchInput;
}>;

export type UpdateCompanySavedSearchMutation = {
  __typename?: 'Mutation';
  updateCompanySavedSearch: {
    __typename?: 'CompaniesSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'CompaniesFilterItem'; fieldName?: string | null; value?: string | null }>;
  };
};

export type UpdateCompanySavedSearchesMutationVariables = Exact<{
  savedCompanySearches: Array<CompaniesSavedSearchWithIdInput> | CompaniesSavedSearchWithIdInput;
}>;

export type UpdateCompanySavedSearchesMutation = {
  __typename?: 'Mutation';
  updateCompanySavedSearches: Array<{
    __typename?: 'CompaniesSavedSearch';
    id?: string | null;
    name?: string | null;
    priority?: number | null;
    searchText?: string | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'CompaniesFilterItem'; fieldName?: string | null; value?: string | null }>;
  }>;
};

export type RemoveCompanySavedSearchMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveCompanySavedSearchMutation = {
  __typename?: 'Mutation';
  removeCompanySavedSearch: {
    __typename?: 'CompaniesSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'CompaniesFilterItem'; fieldName?: string | null; value?: string | null }>;
  };
};

export type FetchCompaniesSavedSearchesPagedQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<CompaniesFilterItemInput> | CompaniesFilterItemInput;
  take: Scalars['Int'];
  skip: Scalars['Int'];
}>;

export type FetchCompaniesSavedSearchesPagedQuery = {
  __typename?: 'Query';
  companiesSavedSearches: {
    __typename?: 'PageOfSavedSearches';
    totalCount: number;
    savedSearches: Array<{
      __typename?: 'MediaOutletsSavedSearch';
      id?: string | null;
      name?: string | null;
      searchText?: string | null;
      priority?: number | null;
      count?: number | null;
      createdAt?: any | null;
      lastModifiedAt?: any | null;
      createdBy?: string | null;
      filterItems: Array<{ __typename?: 'MediaOutletsFilter'; fieldName?: string | null; value?: string | null }>;
    }>;
  };
};

export type FetchCompaniesAvatarDetailsQueryVariables = Exact<{
  companiesAvatarDetails: Array<CompaniesAvatarDetailsInput> | CompaniesAvatarDetailsInput;
}>;

export type FetchCompaniesAvatarDetailsQuery = {
  __typename?: 'Query';
  companiesAvatarDetails: Array<{
    __typename?: 'CompaniesAvatarDetailsWithSavedSearchId';
    savedSearchId: string;
    pageOfCompanies: {
      __typename?: 'PageOfCompanies';
      totalCount: number;
      companies: Array<{ __typename?: 'Company'; id?: string | null; name?: string | null; profilePictureUrl?: string | null }>;
    };
  }>;
};

export type AddCompanyMutationVariables = Exact<{
  company: CompanyInput;
}>;

export type AddCompanyMutation = {
  __typename?: 'Mutation';
  addCompany?: {
    __typename?: 'Company';
    id?: string | null;
    name?: string | null;
    profilePictureUrl?: string | null;
    databaseType?: string | null;
    registrationNumber?: string | null;
    parentCompany?: string | null;
    comments?: Array<string> | null;
    director?: string | null;
    competitors?: Array<string> | null;
    foundationDate?: any | null;
    profit?: number | null;
    description?: string | null;
    tags?: Array<{ __typename?: 'CompaniesTag'; id?: string | null; name?: string | null }> | null;
    category?: { __typename?: 'CompaniesMediumCategory'; id?: string | null; name?: string | null } | null;
    contactData?: {
      __typename?: 'CompaniesContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    companyContacts?: Array<{
      __typename?: 'CompanyContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'CompaniesComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'CompaniesComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type TransformCompanyIntoMediaOutletMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TransformCompanyIntoMediaOutletMutation = {
  __typename?: 'Mutation';
  transformCompanyIntoMediaOutlet: { __typename?: 'Company'; id?: string | null };
};

export type RemoveCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveCompanyMutation = { __typename?: 'Mutation'; removeCompany?: { __typename?: 'Company'; id?: string | null } | null };

export type RemoveCompaniesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveCompaniesMutation = {
  __typename?: 'Mutation';
  removeCompanies: Array<{ __typename?: 'Company'; id?: string | null; companyName?: string | null; name?: string | null }>;
};

export type FetchCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchCompanyByIdQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    name?: string | null;
    dataSourceKey?: string | null;
    profilePictureUrl?: string | null;
    databaseType?: string | null;
    registrationNumber?: string | null;
    parentCompany?: string | null;
    comments?: Array<string> | null;
    director?: string | null;
    competitors?: Array<string> | null;
    foundationDate?: any | null;
    profit?: number | null;
    description?: string | null;
    tags?: Array<{ __typename?: 'CompaniesTag'; id?: string | null; name?: string | null }> | null;
    category?: { __typename?: 'CompaniesMediumCategory'; id?: string | null; name?: string | null } | null;
    contactData?: {
      __typename?: 'CompaniesContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    companyContacts?: Array<{
      __typename?: 'CompanyContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'CompaniesComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'CompaniesComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type FetchCompaniesByContactIdQueryVariables = Exact<{
  contactId: Scalars['ID'];
}>;

export type FetchCompaniesByContactIdQuery = {
  __typename?: 'Query';
  companiesByContactId: Array<{
    __typename?: 'Company';
    id?: string | null;
    name?: string | null;
    dataSourceKey?: string | null;
    profilePictureUrl?: string | null;
    databaseType?: string | null;
    registrationNumber?: string | null;
    parentCompany?: string | null;
    comments?: Array<string> | null;
    director?: string | null;
    competitors?: Array<string> | null;
    foundationDate?: any | null;
    profit?: number | null;
    description?: string | null;
    tags?: Array<{ __typename?: 'CompaniesTag'; id?: string | null; name?: string | null }> | null;
    category?: { __typename?: 'CompaniesMediumCategory'; id?: string | null; name?: string | null } | null;
    contactData?: {
      __typename?: 'CompaniesContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    companyContacts?: Array<{
      __typename?: 'CompanyContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'CompaniesComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'CompaniesComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  }>;
};

export type FetchCompaniesSearchSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;

export type FetchCompaniesSearchSuggestionsQuery = {
  __typename?: 'Query';
  companySearchSuggestions: Array<{
    __typename?: 'CompaniesSearchSuggestionsCategory';
    filterItemFieldName: string;
    searchCategoryName: { __typename?: 'SearchCategoryName'; name?: string | null; value?: number | null };
    suggestionsList: Array<{ __typename?: 'CompaniesSuggestionItem'; text?: string | null; imageUrl?: string | null }>;
  }>;
};

export type FetchCompaniesByQueryParamsPagedQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<CompaniesFilterItemInput> | CompaniesFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  sortingInput: CompaniesSortingInput;
}>;

export type FetchCompaniesByQueryParamsPagedQuery = {
  __typename?: 'Query';
  companies?: {
    __typename?: 'PageOfCompanies';
    totalCount: number;
    companies: Array<{
      __typename?: 'Company';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
      profilePictureUrl?: string | null;
      databaseType?: string | null;
      registrationNumber?: string | null;
      parentCompany?: string | null;
      comments?: Array<string> | null;
      director?: string | null;
      competitors?: Array<string> | null;
      foundationDate?: any | null;
      profit?: number | null;
      description?: string | null;
      tags?: Array<{ __typename?: 'CompaniesTag'; id?: string | null; name?: string | null }> | null;
      category?: { __typename?: 'CompaniesMediumCategory'; id?: string | null; name?: string | null } | null;
      contactData?: {
        __typename?: 'CompaniesContactData';
        street?: string | null;
        poBox?: string | null;
        postalCode?: string | null;
        country?: string | null;
        city?: string | null;
        websiteUrl?: string | null;
        emailAddress?: string | null;
        landlinePhoneNumber?: string | null;
        fax?: string | null;
        alternativeFax?: string | null;
      } | null;
      companyContacts?: Array<{
        __typename?: 'CompanyContact';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePictureUrl?: string | null;
      }> | null;
      userComments?: Array<{
        __typename?: 'CompaniesComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'CompaniesComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
    }>;
  } | null;
};

export type CompanySuggestionsByNameQueryVariables = Exact<{
  name: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByNameQuery = { __typename?: 'Query'; companySuggestionsByName: Array<string> };

export type CompanySuggestionsByContactNameQueryVariables = Exact<{
  contactName: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByContactNameQuery = { __typename?: 'Query'; companySuggestionsByContactName: Array<string> };

export type CompanySuggestionsByTagNameQueryVariables = Exact<{
  tagName: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByTagNameQuery = { __typename?: 'Query'; companySuggestionsByTagName: Array<string> };

export type CompanySuggestionsByCountryQueryVariables = Exact<{
  country: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByCountryQuery = { __typename?: 'Query'; companySuggestionsByCountry: Array<string> };

export type CompanySuggestionsByCityQueryVariables = Exact<{
  city: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByCityQuery = { __typename?: 'Query'; companySuggestionsByCity: Array<string> };

export type CompanySuggestionsByPostCodeQueryVariables = Exact<{
  postCode: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByPostCodeQuery = { __typename?: 'Query'; companySuggestionsByPostCode: Array<string> };

export type CompanySuggestionsByRegistrationNumberQueryVariables = Exact<{
  registrationNumber: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByRegistrationNumberQuery = { __typename?: 'Query'; companySuggestionsByRegistrationNumber: Array<string> };

export type CompanySuggestionsByDatabaseTypeQueryVariables = Exact<{
  databaseType: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CompanySuggestionsByDatabaseTypeQuery = { __typename?: 'Query'; companySuggestionsByDatabaseType: Array<string> };

export type AddTagToCompaniesMutationVariables = Exact<{
  companyIds: Array<Scalars['ID']> | Scalars['ID'];
  input: CompaniesTagInput;
}>;

export type AddTagToCompaniesMutation = {
  __typename?: 'Mutation';
  addTagToCompanies: { __typename?: 'CompaniesTag'; id?: string | null; name?: string | null };
};

export type RemoveTagFromCompaniesMutationVariables = Exact<{
  companyIds: Array<Scalars['ID']> | Scalars['ID'];
  input: CompaniesTagInput;
}>;

export type RemoveTagFromCompaniesMutation = {
  __typename?: 'Mutation';
  removeTagFromCompanies: { __typename?: 'CompaniesTag'; id?: string | null; name?: string | null };
};

export type UpdateCompanyProfilePictureMutationVariables = Exact<{
  companyId: Scalars['ID'];
  input: CompaniesProfilePictureInput;
}>;

export type UpdateCompanyProfilePictureMutation = { __typename?: 'Mutation'; updateCompanyProfilePicture: any };

export type RemoveCompanyProfilePictureMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type RemoveCompanyProfilePictureMutation = { __typename?: 'Mutation'; removeCompanyProfilePicture: any };

export type AddCommentToCompanyMutationVariables = Exact<{
  companyId: Scalars['ID'];
  input: CompaniesCommentInput;
}>;

export type AddCommentToCompanyMutation = {
  __typename?: 'Mutation';
  addCommentToCompany: {
    __typename?: 'CompaniesComment';
    id?: string | null;
    content?: string | null;
    createdAt?: any | null;
    author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
    mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    replies?: Array<{
      __typename?: 'CompaniesComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    }> | null;
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany?: {
    __typename?: 'Company';
    id?: string | null;
    name?: string | null;
    profilePictureUrl?: string | null;
    databaseType?: string | null;
    registrationNumber?: string | null;
    parentCompany?: string | null;
    comments?: Array<string> | null;
    director?: string | null;
    competitors?: Array<string> | null;
    foundationDate?: any | null;
    profit?: number | null;
    description?: string | null;
    tags?: Array<{ __typename?: 'CompaniesTag'; id?: string | null; name?: string | null }> | null;
    category?: { __typename?: 'CompaniesMediumCategory'; id?: string | null; name?: string | null } | null;
    contactData?: {
      __typename?: 'CompaniesContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    companyContacts?: Array<{
      __typename?: 'CompanyContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'CompaniesComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'CompaniesComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type FetchContactsByCompanyIdNameAndRoleQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  name: Scalars['String'];
  role: Scalars['String'];
  mediumId: Scalars['ID'];
}>;

export type FetchContactsByCompanyIdNameAndRoleQuery = {
  __typename?: 'Query';
  contactsByMediumIdNameAndRole: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      isGloballySignedOut?: boolean | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
      mediaResorts?: Array<{
        __typename?: 'ContactsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      comments?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'ContactsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
      blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }>;
  };
};

export type FetchContactsByNameAndRoleAndNotContainedInCompanyQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  name: Scalars['String'];
  role: Scalars['String'];
  mediumId: Scalars['ID'];
}>;

export type FetchContactsByNameAndRoleAndNotContainedInCompanyQuery = {
  __typename?: 'Query';
  contactsByNameAndRoleAndNotContainedInMedium: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      isGloballySignedOut?: boolean | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
      mediaResorts?: Array<{
        __typename?: 'ContactsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      comments?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'ContactsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
      blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }>;
  };
};

export type FetchCompanyCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCompanyCategoriesQuery = {
  __typename?: 'Query';
  companyCategories: Array<{ __typename?: 'CompaniesMediumCategory'; id?: string | null; name?: string | null }>;
};

export type AddTagToContactsMutationVariables = Exact<{
  contactIds: Array<Scalars['ID']> | Scalars['ID'];
  input: ContactsTagInput;
}>;

export type AddTagToContactsMutation = { __typename?: 'Mutation'; addTagToContacts: { __typename?: 'ContactsTag'; id?: string | null; name?: string | null } };

export type RemoveTagFromContactsMutationVariables = Exact<{
  contactIds: Array<Scalars['ID']> | Scalars['ID'];
  input: ContactsTagInput;
}>;

export type RemoveTagFromContactsMutation = {
  __typename?: 'Mutation';
  removeTagFromContacts: { __typename?: 'ContactsTag'; id?: string | null; name?: string | null };
};

export type AddTopicToContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  topicId: Scalars['ID'];
}>;

export type AddTopicToContactMutation = {
  __typename?: 'Mutation';
  addTopicToContact: { __typename?: 'Topic'; id: string; name: string; dataSourceKey?: string | null };
};

export type RemoveTopicFromContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  topicId: Scalars['ID'];
}>;

export type RemoveTopicFromContactMutation = {
  __typename?: 'Mutation';
  removeTopicFromContact: { __typename?: 'Topic'; id: string; name: string; dataSourceKey?: string | null };
};

export type AddMediaResortToContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
}>;

export type AddMediaResortToContactMutation = {
  __typename?: 'Mutation';
  addMediaResortToContact: { __typename?: 'MediaResort'; id?: string | null; name?: string | null; dataSourceKey?: string | null };
};

export type RemoveMediaResortFromContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
}>;

export type RemoveMediaResortFromContactMutation = {
  __typename?: 'Mutation';
  removeMediaResortFromContact: { __typename?: 'MediaResort'; id?: string | null; name?: string | null; dataSourceKey?: string | null };
};

export type AddListToContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  listId: Scalars['ID'];
}>;

export type AddListToContactMutation = {
  __typename?: 'Mutation';
  addListToContact: { __typename?: 'ListGraphType'; id?: string | null; name?: string | null };
};

export type RemoveListFromContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  listId: Scalars['ID'];
}>;

export type RemoveListFromContactMutation = {
  __typename?: 'Mutation';
  removeListFromContact: { __typename?: 'ListGraphType'; id?: string | null; name?: string | null };
};

export type AddBlacklistToContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  blacklist: BlacklistInput;
}>;

export type AddBlacklistToContactMutation = {
  __typename?: 'Mutation';
  addContactToBlacklist?: {
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  } | null;
};

export type RemoveBlacklistFromContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  blacklist: BlacklistInput;
}>;

export type RemoveBlacklistFromContactMutation = {
  __typename?: 'Mutation';
  removeContactFromBlacklist?: {
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  } | null;
};

export type UpdateOwnContactDataMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: OwnContactDataInput;
}>;

export type UpdateOwnContactDataMutation = {
  __typename?: 'Mutation';
  updateOwnContactData: {
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    preferredLanguages?: Array<{ __typename?: 'ContactsPreferredLanguage'; id?: string | null; name?: string | null }> | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  };
};

export type UpdateCollaborationMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: CollaborationWithIdInput;
}>;

export type UpdateCollaborationMutation = {
  __typename?: 'Mutation';
  updateCollaboration: {
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    preferredLanguages?: Array<{ __typename?: 'ContactsPreferredLanguage'; id?: string | null; name?: string | null }> | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  };
};

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact?: {
    __typename?: 'Contact';
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    isGloballySignedOut?: boolean | null;
  } | null;
};

export type UpdateProfilePictureMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: ContactsProfilePictureInput;
}>;

export type UpdateProfilePictureMutation = { __typename?: 'Mutation'; updateProfilePicture: any };

export type RemoveProfilePictureMutationVariables = Exact<{
  contactId: Scalars['ID'];
}>;

export type RemoveProfilePictureMutation = { __typename?: 'Mutation'; removeProfilePicture: any };

export type RemoveContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveContactMutation = { __typename?: 'Mutation'; removeContact?: { __typename?: 'Contact'; id?: string | null } | null };

export type AddCollaborationMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: CollaborationInput;
}>;

export type AddCollaborationMutation = {
  __typename?: 'Mutation';
  addCollaboration?: {
    __typename?: 'Collaboration';
    id: string;
    jobDescription?: string | null;
    address?: string | null;
    country?: string | null;
    city?: string | null;
    fax?: string | null;
    emailAddress?: string | null;
    blogUrl?: string | null;
    websiteUrl?: string | null;
    twitterProfileUrl?: string | null;
    instagramProfileUrl?: string | null;
    linkedInProfileUrl?: string | null;
    youtubeProfileUrl?: string | null;
    facebookProfileUrl?: string | null;
    isPrimary: boolean;
    postalCode?: string | null;
    medium?: {
      __typename?: 'Medium';
      id?: string | null;
      name?: string | null;
      type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
    } | null;
    jobTitle?: {
      __typename?: 'JobTitleGraphType';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
      mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
    } | null;
    landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
  } | null;
};

export type AddCollaborationsMutationVariables = Exact<{
  input: Array<InputMaybe<CollaborationWithContactIdInput>> | InputMaybe<CollaborationWithContactIdInput>;
}>;

export type AddCollaborationsMutation = {
  __typename?: 'Mutation';
  addCollaborations?: Array<{
    __typename?: 'Collaboration';
    id: string;
    jobDescription?: string | null;
    address?: string | null;
    country?: string | null;
    city?: string | null;
    fax?: string | null;
    emailAddress?: string | null;
    blogUrl?: string | null;
    websiteUrl?: string | null;
    twitterProfileUrl?: string | null;
    instagramProfileUrl?: string | null;
    linkedInProfileUrl?: string | null;
    youtubeProfileUrl?: string | null;
    facebookProfileUrl?: string | null;
    isPrimary: boolean;
    postalCode?: string | null;
    medium?: {
      __typename?: 'Medium';
      id?: string | null;
      name?: string | null;
      type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
    } | null;
    jobTitle?: {
      __typename?: 'JobTitleGraphType';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
      mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
    } | null;
    landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
  } | null> | null;
};

export type RemoveCollaborationMutationVariables = Exact<{
  contactId: Scalars['ID'];
  collaborationId: Scalars['ID'];
}>;

export type RemoveCollaborationMutation = {
  __typename?: 'Mutation';
  removeCollaboration?: {
    __typename?: 'Collaboration';
    id: string;
    jobDescription?: string | null;
    address?: string | null;
    country?: string | null;
    city?: string | null;
    fax?: string | null;
    emailAddress?: string | null;
    blogUrl?: string | null;
    websiteUrl?: string | null;
    twitterProfileUrl?: string | null;
    instagramProfileUrl?: string | null;
    linkedInProfileUrl?: string | null;
    youtubeProfileUrl?: string | null;
    facebookProfileUrl?: string | null;
    isPrimary: boolean;
    postalCode?: string | null;
    medium?: {
      __typename?: 'Medium';
      id?: string | null;
      name?: string | null;
      type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
    } | null;
    jobTitle?: {
      __typename?: 'JobTitleGraphType';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
      mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
    } | null;
    landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
  } | null;
};

export type AddCommentToContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: ContactsCommentInput;
}>;

export type AddCommentToContactMutation = {
  __typename?: 'Mutation';
  addCommentToContact: {
    __typename?: 'ContactsComment';
    id?: string | null;
    content?: string | null;
    createdAt?: any | null;
    author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
    mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    replies?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    }> | null;
  };
};

export type RemoveCommentFromContactMutationVariables = Exact<{
  contactId: Scalars['ID'];
  commentId: Scalars['ID'];
}>;

export type RemoveCommentFromContactMutation = {
  __typename?: 'Mutation';
  removeCommentFromContact?: {
    __typename?: 'ContactsComment';
    id?: string | null;
    content?: string | null;
    createdAt?: any | null;
    author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
    mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    replies?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    }> | null;
  } | null;
};

export type UpdateCommentMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: ContactsCommentWithIdInput;
}>;

export type UpdateCommentMutation = {
  __typename?: 'Mutation';
  updateComment: {
    __typename?: 'ContactsComment';
    id?: string | null;
    content?: string | null;
    createdAt?: any | null;
    author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
    mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    replies?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    }> | null;
  };
};

export type AddEmailMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: EmailInput;
}>;

export type AddEmailMutation = {
  __typename?: 'Mutation';
  addEmail: {
    __typename?: 'Email';
    subject?: string | null;
    scheduledFor?: any | null;
    id?: string | null;
    comment?: string | null;
    title?: string | null;
    createdAt?: any | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    participants?: Array<{
      __typename?: 'ActivityParticipant';
      id?: string | null;
      collaborationId?: string | null;
      contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
    }> | null;
  };
};

export type AddCallMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: CallInput;
}>;

export type AddCallMutation = {
  __typename?: 'Mutation';
  addCall: {
    __typename?: 'Call';
    id?: string | null;
    comment?: string | null;
    title?: string | null;
    createdAt?: any | null;
    scheduledFor?: any | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    participants?: Array<{
      __typename?: 'ActivityParticipant';
      id?: string | null;
      collaborationId?: string | null;
      contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
    }> | null;
  };
};

export type AddAppointmentMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: AppointmentInput;
}>;

export type AddAppointmentMutation = {
  __typename?: 'Mutation';
  addAppointment: {
    __typename?: 'Appointment';
    subject?: string | null;
    startsAt?: any | null;
    endsAt?: any | null;
    id?: string | null;
    comment?: string | null;
    title?: string | null;
    createdAt?: any | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    participants?: Array<{
      __typename?: 'ActivityParticipant';
      id?: string | null;
      collaborationId?: string | null;
      contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
    }> | null;
  };
};

export type AddWebinarMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: WebinarInput;
}>;

export type AddWebinarMutation = {
  __typename?: 'Mutation';
  addWebinar:
    | {
        __typename?: 'Appointment';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Call';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Chat';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'ContactsSending';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<
          | {
              __typename?: 'ActivityParticipant';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
          | {
              __typename?: 'Recipient';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
          | {
              __typename?: 'RecipientFromList';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
        > | null;
      }
    | {
        __typename?: 'Email';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Event';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Task';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Webinar';
        startsAt?: any | null;
        endsAt?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      };
};

export type AddEventMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: EventInput;
}>;

export type AddEventMutation = {
  __typename?: 'Mutation';
  addEvent:
    | {
        __typename?: 'Appointment';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Call';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Chat';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'ContactsSending';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<
          | {
              __typename?: 'ActivityParticipant';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
          | {
              __typename?: 'Recipient';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
          | {
              __typename?: 'RecipientFromList';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
        > | null;
      }
    | {
        __typename?: 'Email';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Event';
        startsAt?: any | null;
        endsAt?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Task';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Webinar';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      };
};

export type AddChatMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: ChatInput;
}>;

export type AddChatMutation = {
  __typename?: 'Mutation';
  addChat: {
    __typename?: 'Chat';
    id?: string | null;
    comment?: string | null;
    title?: string | null;
    createdAt?: any | null;
    scheduledFor?: any | null;
    createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
    participants?: Array<{
      __typename?: 'ActivityParticipant';
      id?: string | null;
      collaborationId?: string | null;
      contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
    }> | null;
  };
};

export type AddTaskMutationVariables = Exact<{
  contactId: Scalars['ID'];
  input: TaskInput;
}>;

export type AddTaskMutation = {
  __typename?: 'Mutation';
  addTask:
    | {
        __typename?: 'Appointment';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Call';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Chat';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'ContactsSending';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<
          | {
              __typename?: 'ActivityParticipant';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
          | {
              __typename?: 'Recipient';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
          | {
              __typename?: 'RecipientFromList';
              id?: string | null;
              collaborationId?: string | null;
              contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
            }
        > | null;
      }
    | {
        __typename?: 'Email';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Event';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Task';
        deadline?: any | null;
        subject?: string | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      }
    | {
        __typename?: 'Webinar';
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        participants?: Array<{
          __typename?: 'ActivityParticipant';
          id?: string | null;
          collaborationId?: string | null;
          contact?: { __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null } | null;
        }> | null;
      };
};

export type DeleteMediaResortsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type DeleteMediaResortsMutation = { __typename?: 'Mutation'; deleteMediaResorts?: Array<string> | null };

export type RemoveTopicsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type RemoveTopicsMutation = { __typename?: 'Mutation'; removeTopics?: Array<string> | null };

export type DeleteBlacklistsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type DeleteBlacklistsMutation = { __typename?: 'Mutation'; deleteBlacklists?: Array<string> | null };

export type FetchContactProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchContactProfileQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    isUser: boolean;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    dataSourceStatus?: { __typename?: 'DataSourceStatus'; name?: string | null; value?: number | null } | null;
    preferredLanguages?: Array<{ __typename?: 'ContactsPreferredLanguage'; id?: string | null; name?: string | null; dataSourceKey?: string | null }> | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  } | null;
};

export type FetchContactAvatarDetailsByContactIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchContactAvatarDetailsByContactIdQuery = {
  __typename?: 'Query';
  contact?: { __typename?: 'Contact'; firstName?: string | null; lastName?: string | null; profilePictureUrl?: string | null } | null;
};

export type FetchListsByContactIdQueryVariables = Exact<{
  contactId: Scalars['ID'];
}>;

export type FetchListsByContactIdQuery = {
  __typename?: 'Query';
  listsByContactId: Array<{ __typename?: 'ListGraphType'; id?: string | null; name?: string | null }>;
};

export type FetchRecipientsByContactIdQueryVariables = Exact<{
  contactId: Scalars['ID'];
}>;

export type FetchRecipientsByContactIdQuery = {
  __typename?: 'Query';
  recipientsByContactId: Array<
    | { __typename?: 'ActivityParticipant' }
    | {
        __typename?: 'Recipient';
        id?: string | null;
        sendingId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        linksClicked?: number | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          isGloballySignedOut?: boolean | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
    | {
        __typename?: 'RecipientFromList';
        id?: string | null;
        sendingId?: string | null;
        listId?: string | null;
        collaborationId?: string | null;
        isOpened?: boolean | null;
        linksClicked?: number | null;
        contact?: {
          __typename?: 'Contact';
          id?: string | null;
          salutation?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profilePictureUrl?: string | null;
          isGloballySignedOut?: boolean | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
          mediaResorts?: Array<{
            __typename?: 'ContactsMediaResort';
            mediaResortId?: string | null;
            mediaResortName?: string | null;
            dataSourceKey?: string | null;
          }> | null;
          comments?: Array<{
            __typename?: 'ContactsComment';
            id?: string | null;
            content?: string | null;
            createdAt?: any | null;
            author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
            mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            replies?: Array<{
              __typename?: 'ContactsComment';
              id?: string | null;
              content?: string | null;
              createdAt?: any | null;
              author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
              mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
            }> | null;
          }> | null;
          blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
          ownContactData?: {
            __typename?: 'OwnContactData';
            address?: string | null;
            country?: string | null;
            city?: string | null;
            postalCode?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          } | null;
          collaborations?: Array<{
            __typename?: 'Collaboration';
            id: string;
            jobDescription?: string | null;
            address?: string | null;
            country?: string | null;
            city?: string | null;
            fax?: string | null;
            emailAddress?: string | null;
            blogUrl?: string | null;
            websiteUrl?: string | null;
            twitterProfileUrl?: string | null;
            instagramProfileUrl?: string | null;
            linkedInProfileUrl?: string | null;
            youtubeProfileUrl?: string | null;
            facebookProfileUrl?: string | null;
            isPrimary: boolean;
            postalCode?: string | null;
            medium?: {
              __typename?: 'Medium';
              id?: string | null;
              name?: string | null;
              type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            jobTitle?: {
              __typename?: 'JobTitleGraphType';
              id?: string | null;
              name?: string | null;
              dataSourceKey?: string | null;
              mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
            } | null;
            landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
            mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          }> | null;
        } | null;
      }
  >;
};

export type FetchContactsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type FetchContactsByIdsQuery = {
  __typename?: 'Query';
  contactsByIds: Array<{
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  }>;
};

export type FetchAllTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllTopicsQuery = { __typename?: 'Query'; topics: Array<{ __typename?: 'Topic'; id: string; name: string; dataSourceKey?: string | null }> };

export type FetchAllMediaResortsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllMediaResortsQuery = {
  __typename?: 'Query';
  mediaResorts: Array<{ __typename?: 'MediaResort'; id?: string | null; name?: string | null; dataSourceKey?: string | null }>;
};

export type FetchSelectedContactActivitiesQueryVariables = Exact<{
  contactId: Scalars['ID'];
}>;

export type FetchSelectedContactActivitiesQuery = {
  __typename?: 'Query';
  activitiesByContactId: Array<
    | {
        __typename?: 'Appointment';
        subject?: string | null;
        startsAt?: any | null;
        endsAt?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
      }
    | {
        __typename?: 'Call';
        scheduledFor?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
      }
    | {
        __typename?: 'Chat';
        scheduledFor?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
      }
    | {
        __typename?: 'ContactsSending';
        id?: string | null;
        imageAsBase64?: string | null;
        isFavourite?: boolean | null;
        title?: string | null;
        subject?: string | null;
        htmlBody?: string | null;
        jsonBody?: string | null;
        originalHtmlBody?: string | null;
        mjmlBody?: string | null;
        createdAt?: any | null;
        lastModifiedAt?: any | null;
        respondToEmailAddress?: string | null;
        senderDisplayName?: string | null;
        senderEmailAddress?: string | null;
        deliveredAt?: any | null;
        recipientsIds?: Array<string> | null;
        numberOfRecipients?: number | null;
        distributionDuration?: any | null;
        comment?: string | null;
        blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
        status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
        lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
        tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
        campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
      }
    | {
        __typename?: 'Email';
        scheduledFor?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
      }
    | {
        __typename?: 'Event';
        startsAt?: any | null;
        endsAt?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
      }
    | {
        __typename?: 'Task';
        deadline?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
      }
    | {
        __typename?: 'Webinar';
        startsAt?: any | null;
        endsAt?: any | null;
        id?: string | null;
        comment?: string | null;
        title?: string | null;
        createdAt?: any | null;
        createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
      }
  >;
};

export type FetchPagedSelectedContactActivitiesQueryVariables = Exact<{
  contactId: Scalars['ID'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
}>;

export type FetchPagedSelectedContactActivitiesQuery = {
  __typename?: 'Query';
  activitiesByContactIdPaged: {
    __typename?: 'PageOfActivities';
    totalCount: number;
    activities: Array<
      | {
          __typename?: 'Appointment';
          subject?: string | null;
          startsAt?: any | null;
          endsAt?: any | null;
          id?: string | null;
          comment?: string | null;
          title?: string | null;
          createdAt?: any | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        }
      | {
          __typename?: 'Call';
          scheduledFor?: any | null;
          id?: string | null;
          comment?: string | null;
          title?: string | null;
          createdAt?: any | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        }
      | {
          __typename?: 'Chat';
          scheduledFor?: any | null;
          id?: string | null;
          comment?: string | null;
          title?: string | null;
          createdAt?: any | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        }
      | {
          __typename?: 'ContactsSending';
          id?: string | null;
          imageAsBase64?: string | null;
          isFavourite?: boolean | null;
          title?: string | null;
          subject?: string | null;
          htmlBody?: string | null;
          jsonBody?: string | null;
          originalHtmlBody?: string | null;
          mjmlBody?: string | null;
          createdAt?: any | null;
          lastModifiedAt?: any | null;
          respondToEmailAddress?: string | null;
          senderDisplayName?: string | null;
          senderEmailAddress?: string | null;
          deliveredAt?: any | null;
          recipientsIds?: Array<string> | null;
          numberOfRecipients?: number | null;
          distributionDuration?: any | null;
          comment?: string | null;
          blacklist?: { __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null } | null;
          status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
          lastModifiedByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
          sender?: { __typename?: 'Sender'; id?: string | null; displayName?: string | null; emailAddress?: string | null; userId?: string | null } | null;
          tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
          campaign?: { __typename?: 'Campaign'; name?: string | null } | null;
        }
      | {
          __typename?: 'Email';
          scheduledFor?: any | null;
          id?: string | null;
          comment?: string | null;
          title?: string | null;
          createdAt?: any | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        }
      | {
          __typename?: 'Event';
          startsAt?: any | null;
          endsAt?: any | null;
          id?: string | null;
          comment?: string | null;
          title?: string | null;
          createdAt?: any | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        }
      | {
          __typename?: 'Task';
          deadline?: any | null;
          id?: string | null;
          comment?: string | null;
          title?: string | null;
          createdAt?: any | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        }
      | {
          __typename?: 'Webinar';
          startsAt?: any | null;
          endsAt?: any | null;
          id?: string | null;
          comment?: string | null;
          title?: string | null;
          createdAt?: any | null;
          createdByUser?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
        }
    >;
  };
};

export type FetchAllJobTitlesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllJobTitlesQuery = {
  __typename?: 'Query';
  jobTitles: Array<{
    __typename?: 'JobTitleGraphType';
    id?: string | null;
    dataSourceKey?: string | null;
    name?: string | null;
    mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
  }>;
};

export type FetchContactsProfilePanelDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchContactsProfilePanelDataQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    isUser: boolean;
    dataSourceStatus?: { __typename?: 'DataSourceStatus'; name?: string | null; value?: number | null } | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  } | null;
};

export type FetchActivityEmailAddressQueryVariables = Exact<{ [key: string]: never }>;

export type FetchActivityEmailAddressQuery = { __typename?: 'Query'; activityEmailAddress: string };

export type FetchUserByAadObjectIdQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserByAadObjectIdQuery = {
  __typename?: 'Query';
  userByAadObjectId?: { __typename?: 'ContactsUser'; id: string; firstName: string; lastName: string } | null;
};

export type FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  name: Scalars['String'];
  category: Scalars['String'];
  contactId: Scalars['ID'];
}>;

export type FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery = {
  __typename?: 'Query';
  mediaOutletsByNameAndCategoryAndNotContainedInContact?: {
    __typename?: 'PageOfMediaOutlets';
    totalCount: number;
    mediaOutlets: Array<{
      __typename?: 'MediaOutlet';
      id?: string | null;
      name?: string | null;
      profilePictureUrl?: string | null;
      description?: string | null;
      websiteUrl?: string | null;
      mediaType?: string | null;
      publicationType?: string | null;
      readership?: string | null;
      circulation?: number | null;
      audience?: { __typename?: 'Audience'; id?: string | null; key?: string | null } | null;
      category?: { __typename?: 'MediaOutletsMediumCategory'; id: string; name: string } | null;
      frequency?: { __typename?: 'MediumPublishingFrequency'; id?: string | null; key?: string | null } | null;
      contactData?: {
        __typename?: 'MediaOutletsContactData';
        street?: string | null;
        poBox?: string | null;
        postalCode?: string | null;
        country?: string | null;
        city?: string | null;
        websiteUrl?: string | null;
        emailAddress?: string | null;
        landlinePhoneNumber?: string | null;
        fax?: string | null;
        alternativeFax?: string | null;
      } | null;
      mediumSupportTypes?: Array<{ __typename?: 'MediumSupportType'; id?: string | null; key?: string | null }> | null;
      distributions?: Array<{ __typename?: 'Distribution'; key?: string | null }> | null;
      mediaOutletContacts?: Array<{
        __typename?: 'MediaOutletContact';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePictureUrl?: string | null;
      }> | null;
      tags?: Array<{ __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null }> | null;
      preferredLanguages?: Array<{
        __typename?: 'MediaOutletsPreferredLanguage';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      mediaResorts?: Array<{
        __typename?: 'MediaOutletsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      userComments?: Array<{
        __typename?: 'MediaOutletsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'MediaOutletsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
    }>;
  } | null;
};

export type FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  name: Scalars['String'];
  category: Scalars['String'];
  contactId: Scalars['ID'];
}>;

export type FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery = {
  __typename?: 'Query';
  companiesByNameAndCategoryAndNotContainedInContact?: {
    __typename?: 'PageOfCompanies';
    totalCount: number;
    companies: Array<{
      __typename?: 'Company';
      id?: string | null;
      name?: string | null;
      profilePictureUrl?: string | null;
      parentCompany?: string | null;
      director?: string | null;
      competitors?: Array<string> | null;
      createdAt?: any | null;
      profit?: number | null;
      description?: string | null;
      databaseType?: string | null;
      registrationNumber?: string | null;
      comments?: Array<string> | null;
      category?: { __typename?: 'CompaniesMediumCategory'; id?: string | null; name?: string | null } | null;
      contactData?: {
        __typename?: 'CompaniesContactData';
        street?: string | null;
        poBox?: string | null;
        postalCode?: string | null;
        country?: string | null;
        city?: string | null;
        websiteUrl?: string | null;
        emailAddress?: string | null;
        landlinePhoneNumber?: string | null;
        fax?: string | null;
        alternativeFax?: string | null;
      } | null;
      companyContacts?: Array<{
        __typename?: 'CompanyContact';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePictureUrl?: string | null;
      }> | null;
      tags?: Array<{ __typename?: 'CompaniesTag'; id?: string | null; name?: string | null }> | null;
      userComments?: Array<{
        __typename?: 'CompaniesComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'CompaniesComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'CompaniesUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
    }>;
  } | null;
};

export type FetchSendingsByContactIdQueryVariables = Exact<{
  contactId: Scalars['String'];
}>;

export type FetchSendingsByContactIdQuery = {
  __typename?: 'Query';
  sendingsByContactId: Array<{
    __typename?: 'ContactsSending';
    id?: string | null;
    title?: string | null;
    createdAt?: any | null;
    recipientsIds?: Array<string> | null;
    status?: { __typename?: 'SendingStatus'; name?: string | null; value?: number | null } | null;
  }>;
};

export type AddSavedSearchMutationVariables = Exact<{
  savedSearch: ContactsSavedSearchInput;
}>;

export type AddSavedSearchMutation = {
  __typename?: 'Mutation';
  saveSearch?: {
    __typename?: 'ContactsSavedSearch';
    id?: string | null;
    searchText?: string | null;
    name?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'ContactsFilterItem'; fieldName?: string | null; value?: string | null }>;
  } | null;
};

export type UpdateSavedSearchMutationVariables = Exact<{
  id: Scalars['ID'];
  savedSearch: ContactsSavedSearchInput;
}>;

export type UpdateSavedSearchMutation = {
  __typename?: 'Mutation';
  updateSavedSearch: {
    __typename?: 'ContactsSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'ContactsFilterItem'; fieldName?: string | null; value?: string | null }>;
  };
};

export type UpdateSavedSearchesMutationVariables = Exact<{
  savedSearches: Array<ContactsSavedSearchWithIdInput> | ContactsSavedSearchWithIdInput;
}>;

export type UpdateSavedSearchesMutation = {
  __typename?: 'Mutation';
  updateSavedSearches: Array<{
    __typename?: 'ContactsSavedSearch';
    id?: string | null;
    name?: string | null;
    priority?: number | null;
    searchText?: string | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'ContactsFilterItem'; fieldName?: string | null; value?: string | null }>;
  }>;
};

export type RemoveSavedSearchMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveSavedSearchMutation = {
  __typename?: 'Mutation';
  removeSavedSearch?: {
    __typename?: 'ContactsSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'ContactsFilterItem'; fieldName?: string | null; value?: string | null }>;
  } | null;
};

export type FetchPagedSavedSearchesQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  searchText: Scalars['String'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
}>;

export type FetchPagedSavedSearchesQuery = {
  __typename?: 'Query';
  savedSearches: {
    __typename?: 'PageOfSavedSearches';
    totalCount: number;
    savedSearches: Array<{
      __typename?: 'MediaOutletsSavedSearch';
      id?: string | null;
      name?: string | null;
      searchText?: string | null;
      priority?: number | null;
      count?: number | null;
      createdAt?: any | null;
      lastModifiedAt?: any | null;
      createdBy?: string | null;
      filterItems: Array<{ __typename?: 'MediaOutletsFilter'; id?: string | null; fieldName?: string | null; value?: string | null }>;
    }>;
  };
};

export type FetchSavedSearchByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchSavedSearchByIdQuery = {
  __typename?: 'Query';
  savedSearch?: {
    __typename?: 'ContactsSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'ContactsFilterItem'; id?: string | null; fieldName?: string | null; value?: string | null }>;
  } | null;
};

export type FetchSavedSearchesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type FetchSavedSearchesByIdsQuery = {
  __typename?: 'Query';
  savedSearchesByIds: Array<{
    __typename?: 'ContactsSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'ContactsFilterItem'; fieldName?: string | null; value?: string | null }>;
  }>;
};

export type AddContactMutationVariables = Exact<{
  input: ContactInput;
}>;

export type AddContactMutation = {
  __typename?: 'Mutation';
  addContact: {
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    preferredLanguages?: Array<{ __typename?: 'ContactsPreferredLanguage'; id?: string | null; name?: string | null; dataSourceKey?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  };
};

export type RemoveContactsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveContactsMutation = {
  __typename?: 'Mutation';
  removeContacts: Array<{
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    isGloballySignedOut?: boolean | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'ContactsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ContactsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
    blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
    preferredLanguages?: Array<{ __typename?: 'ContactsPreferredLanguage'; id?: string | null; name?: string | null; dataSourceKey?: string | null }> | null;
    ownContactData?: {
      __typename?: 'OwnContactData';
      address?: string | null;
      country?: string | null;
      city?: string | null;
      postalCode?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      jobDescription?: string | null;
      address?: string | null;
      country?: string | null;
      city?: string | null;
      fax?: string | null;
      emailAddress?: string | null;
      blogUrl?: string | null;
      websiteUrl?: string | null;
      twitterProfileUrl?: string | null;
      instagramProfileUrl?: string | null;
      linkedInProfileUrl?: string | null;
      youtubeProfileUrl?: string | null;
      facebookProfileUrl?: string | null;
      isPrimary: boolean;
      postalCode?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
    }> | null;
  }>;
};

export type AddMediaResortMutationVariables = Exact<{
  input: MediaResortInput;
}>;

export type AddMediaResortMutation = {
  __typename?: 'Mutation';
  addMediaResort: { __typename?: 'MediaResort'; id?: string | null; name?: string | null; dataSourceKey?: string | null };
};

export type AddTopicMutationVariables = Exact<{
  input: TopicInput;
}>;

export type AddTopicMutation = { __typename?: 'Mutation'; addTopic: { __typename?: 'Topic'; id: string; name: string; dataSourceKey?: string | null } };

export type AddMediaResortsToContactsMutationVariables = Exact<{
  contactsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  mediaResortsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type AddMediaResortsToContactsMutation = { __typename?: 'Mutation'; addMediaResortsToContacts?: Array<string> | null };

export type AddTopicsToContactsMutationVariables = Exact<{
  contactsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  topicsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type AddTopicsToContactsMutation = { __typename?: 'Mutation'; addTopicsToContacts: Array<string | null> };

export type AddListsToContactsMutationVariables = Exact<{
  contactsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  listsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type AddListsToContactsMutation = { __typename?: 'Mutation'; addListsToContacts?: Array<string> | null };

export type AddJobTitleMutationVariables = Exact<{
  input: JobTitleInput;
}>;

export type AddJobTitleMutation = {
  __typename?: 'Mutation';
  addJobTitle?: {
    __typename?: 'JobTitleGraphType';
    id?: string | null;
    name?: string | null;
    dataSourceKey?: string | null;
    mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
  } | null;
};

export type RemoveJobTitleMutationVariables = Exact<{
  jobTitleId: Scalars['ID'];
}>;

export type RemoveJobTitleMutation = { __typename?: 'Mutation'; removeJobTitle?: string | null };

export type FetchContactsBySearchTextQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  sortingInput: ContactsSortingInput;
}>;

export type FetchContactsBySearchTextQuery = {
  __typename?: 'Query';
  contacts: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      dataSourceKey?: string | null;
      isGloballySignedOut?: boolean | null;
      isUser: boolean;
      preferredLanguages?: Array<{ __typename?: 'ContactsPreferredLanguage'; id?: string | null; name?: string | null; dataSourceKey?: string | null }> | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
      mediaResorts?: Array<{
        __typename?: 'ContactsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      comments?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'ContactsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
      blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }>;
  };
};

export type FetchContactSearchSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;

export type FetchContactSearchSuggestionsQuery = {
  __typename?: 'Query';
  searchSuggestions: Array<{
    __typename?: 'ContactsSearchSuggestionCategory';
    filterItemFieldName: string;
    searchCategoryName: { __typename?: 'SearchCategoryName'; name?: string | null; value?: number | null };
    suggestionsList: Array<{ __typename?: 'ContactsSuggestionItem'; text?: string | null; imageUrl?: string | null; subtext?: string | null }>;
  }>;
};

export type FetchContactAvatarDetailsQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  sortingInput: ContactsSortingInput;
}>;

export type FetchContactAvatarDetailsQuery = {
  __typename?: 'Query';
  contacts: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{ __typename?: 'Contact'; firstName?: string | null; lastName?: string | null; profilePictureUrl?: string | null }>;
  };
};

export type FetchBulkContactsAvatarDetailsQueryVariables = Exact<{
  contactQueryInput: Array<ContactsQueryInput> | ContactsQueryInput;
}>;

export type FetchBulkContactsAvatarDetailsQuery = {
  __typename?: 'Query';
  contactsAvatarDetails?: Array<{
    __typename?: 'ContactsWithSavedSearchId';
    savedSearchId: string;
    pageOfContacts: {
      __typename?: 'PageOfContacts';
      totalCount: number;
      contacts: Array<{ __typename?: 'Contact'; id?: string | null; firstName?: string | null; lastName?: string | null; profilePictureUrl?: string | null }>;
    };
  }> | null;
};

export type ContactNameFilterSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type ContactNameFilterSuggestionsQuery = { __typename?: 'Query'; contactNameFilterSuggestions: Array<string> };

export type RoleFilterSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type RoleFilterSuggestionsQuery = { __typename?: 'Query'; roleFilterSuggestions: Array<string> };

export type MediumNameFilterSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediumNameFilterSuggestionsQuery = { __typename?: 'Query'; mediumNameFilterSuggestions: Array<string> };

export type ResortFilterSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type ResortFilterSuggestionsQuery = { __typename?: 'Query'; resortFilterSuggestions: Array<string> };

export type LanguageFilterSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type LanguageFilterSuggestionsQuery = { __typename?: 'Query'; languageFilterSuggestions: Array<string> };

export type TagNameFilterSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type TagNameFilterSuggestionsQuery = { __typename?: 'Query'; tagNameFilterSuggestions: Array<string> };

export type CountryFilterSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type CountryFilterSuggestionsQuery = { __typename?: 'Query'; countryFilterSuggestions: Array<string> };

export type AddTagToListsMutationVariables = Exact<{
  listIds: Array<Scalars['ID']> | Scalars['ID'];
  input: ContactsTagInput;
}>;

export type AddTagToListsMutation = { __typename?: 'Mutation'; addTagToLists: { __typename?: 'ContactsTag'; id?: string | null; name?: string | null } };

export type RemoveTagFromListsMutationVariables = Exact<{
  listIds: Array<Scalars['ID']> | Scalars['ID'];
  input: ContactsTagInput;
}>;

export type RemoveTagFromListsMutation = {
  __typename?: 'Mutation';
  removeTagFromLists: { __typename?: 'ContactsTag'; id?: string | null; name?: string | null };
};

export type UpdateListProfilePictureMutationVariables = Exact<{
  listId: Scalars['ID'];
  input: ContactsProfilePictureInput;
}>;

export type UpdateListProfilePictureMutation = { __typename?: 'Mutation'; updateListProfilePicture: any };

export type RemoveListProfilePictureMutationVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type RemoveListProfilePictureMutation = { __typename?: 'Mutation'; removeListProfilePicture: any };

export type AddContactsSavedSearchesToListMutationVariables = Exact<{
  listId: Scalars['ID'];
  savedSearchesIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AddContactsSavedSearchesToListMutation = {
  __typename?: 'Mutation';
  addContactsSavedSearchesToList: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    linkedSavedSearches?: Array<{
      __typename?: 'LinkedSavedSearch';
      totalCountOfContacts?: number | null;
      shouldAutoUpdate?: boolean | null;
      savedSearch?: {
        __typename?: 'ContactsSavedSearch';
        id?: string | null;
        name?: string | null;
        lastModifiedAt?: any | null;
        createdAt?: any | null;
      } | null;
    }> | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  };
};

export type RemoveContactsSavedSearchesFromListMutationVariables = Exact<{
  listId: Scalars['ID'];
  savedSearchesIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveContactsSavedSearchesFromListMutation = {
  __typename?: 'Mutation';
  removeContactsSavedSearchesFromList: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    linkedSavedSearches?: Array<{
      __typename?: 'LinkedSavedSearch';
      totalCountOfContacts?: number | null;
      shouldAutoUpdate?: boolean | null;
      savedSearch?: {
        __typename?: 'ContactsSavedSearch';
        id?: string | null;
        name?: string | null;
        lastModifiedAt?: any | null;
        createdAt?: any | null;
      } | null;
    }> | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  };
};

export type RefreshLinkedSavedSearchesMutationVariables = Exact<{
  listId: Scalars['ID'];
  savedSearchesIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RefreshLinkedSavedSearchesMutation = {
  __typename?: 'Mutation';
  refreshLinkedSavedSearches: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    linkedSavedSearches?: Array<{
      __typename?: 'LinkedSavedSearch';
      totalCountOfContacts?: number | null;
      shouldAutoUpdate?: boolean | null;
      savedSearch?: {
        __typename?: 'ContactsSavedSearch';
        id?: string | null;
        name?: string | null;
        lastModifiedAt?: any | null;
        createdAt?: any | null;
      } | null;
    }> | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  };
};

export type UpdateContactsSavedSearchMutationVariables = Exact<{
  listId: Scalars['ID'];
  savedSearchId: Scalars['ID'];
  shouldAutoUpdate: Scalars['Boolean'];
}>;

export type UpdateContactsSavedSearchMutation = {
  __typename?: 'Mutation';
  updateContactsSavedSearch: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    linkedSavedSearches?: Array<{
      __typename?: 'LinkedSavedSearch';
      totalCountOfContacts?: number | null;
      shouldAutoUpdate?: boolean | null;
      savedSearch?: {
        __typename?: 'ContactsSavedSearch';
        id?: string | null;
        name?: string | null;
        lastModifiedAt?: any | null;
        createdAt?: any | null;
      } | null;
    }> | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  };
};

export type UpdateListMutationVariables = Exact<{
  listId: Scalars['ID'];
  input: ContactsListInputType;
}>;

export type UpdateListMutation = {
  __typename?: 'Mutation';
  updateList?: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    linkedSavedSearches?: Array<{
      __typename?: 'LinkedSavedSearch';
      totalCountOfContacts?: number | null;
      shouldAutoUpdate?: boolean | null;
      savedSearch?: {
        __typename?: 'ContactsSavedSearch';
        id?: string | null;
        name?: string | null;
        lastModifiedAt?: any | null;
        createdAt?: any | null;
      } | null;
    }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  } | null;
};

export type RemoveContactsFromListMutationVariables = Exact<{
  listId: Scalars['ID'];
  contactsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveContactsFromListMutation = {
  __typename?: 'Mutation';
  removeContactsFromList: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    contacts?: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      dataSourceKey?: string | null;
      isGloballySignedOut?: boolean | null;
      isUser: boolean;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }> | null;
  };
};

export type AddAadContactsMutationVariables = Exact<{
  input: Array<AadContactInput> | AadContactInput;
}>;

export type AddAadContactsMutation = {
  __typename?: 'Mutation';
  addAadContacts: Array<{
    __typename?: 'Contact';
    id?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profilePictureUrl?: string | null;
    ownContactData?: { __typename?: 'OwnContactData'; isPrimary: boolean; emailAddress?: string | null } | null;
    collaborations?: Array<{
      __typename?: 'Collaboration';
      id: string;
      isPrimary: boolean;
      emailAddress?: string | null;
      jobDescription?: string | null;
      medium?: {
        __typename?: 'Medium';
        id?: string | null;
        name?: string | null;
        type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
      jobTitle?: {
        __typename?: 'JobTitleGraphType';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
        mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
      } | null;
    }> | null;
  }>;
};

export type AddContactsToListMutationVariables = Exact<{
  listId: Scalars['ID'];
  input: Array<ListContactInput> | ListContactInput;
}>;

export type AddContactsToListMutation = {
  __typename?: 'Mutation';
  addContactsToList: Array<{
    __typename?: 'ListContactGraphType';
    id?: string | null;
    listId?: string | null;
    mailingAddressCompanyId?: string | null;
    contact?: {
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      dataSourceKey?: string | null;
      isGloballySignedOut?: boolean | null;
      isUser: boolean;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    } | null;
  } | null>;
};

export type UpdateContactInListMutationVariables = Exact<{
  listContactId: Scalars['ID'];
  input: ListContactInput;
}>;

export type UpdateContactInListMutation = {
  __typename?: 'Mutation';
  updateListContact: {
    __typename?: 'ListContactGraphType';
    id?: string | null;
    listId?: string | null;
    isExcluded?: boolean | null;
    mailingAddressCompanyId?: string | null;
    createdAt?: any | null;
    createdBy?: string | null;
    lastModifiedAt?: any | null;
    lastModifiedBy?: string | null;
    contact?: {
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      dataSourceKey?: string | null;
      isGloballySignedOut?: boolean | null;
      isUser: boolean;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    } | null;
  };
};

export type AddAadContactsToListMutationVariables = Exact<{
  listId: Scalars['ID'];
  input: Array<AadContactInput> | AadContactInput;
}>;

export type AddAadContactsToListMutation = { __typename?: 'Mutation'; addAadContactsToList: Array<string> };

export type FetchListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchListQuery = {
  __typename?: 'Query';
  list?: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    mediumNames?: Array<string> | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    linkedSavedSearches?: Array<{
      __typename?: 'LinkedSavedSearch';
      totalCountOfContacts?: number | null;
      shouldAutoUpdate?: boolean | null;
      savedSearch?: {
        __typename?: 'ContactsSavedSearch';
        id?: string | null;
        name?: string | null;
        lastModifiedAt?: any | null;
        createdAt?: any | null;
      } | null;
    }> | null;
  } | null;
};

export type FetchContactsByListIdAndSearchTextQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  searchText: Scalars['String'];
  listId: Scalars['ID'];
}>;

export type FetchContactsByListIdAndSearchTextQuery = {
  __typename?: 'Query';
  contactsByListIdAndSearchText: {
    __typename?: 'PageOfListContacts';
    totalCount: number;
    listContacts: Array<{
      __typename?: 'ListContactGraphType';
      id?: string | null;
      listId?: string | null;
      mailingAddressCompanyId?: string | null;
      contact?: {
        __typename?: 'Contact';
        id?: string | null;
        salutation?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePictureUrl?: string | null;
        dataSourceKey?: string | null;
        isGloballySignedOut?: boolean | null;
        isUser: boolean;
        ownContactData?: {
          __typename?: 'OwnContactData';
          address?: string | null;
          country?: string | null;
          city?: string | null;
          postalCode?: string | null;
          fax?: string | null;
          emailAddress?: string | null;
          blogUrl?: string | null;
          websiteUrl?: string | null;
          twitterProfileUrl?: string | null;
          instagramProfileUrl?: string | null;
          linkedInProfileUrl?: string | null;
          youtubeProfileUrl?: string | null;
          facebookProfileUrl?: string | null;
          isPrimary: boolean;
          landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        } | null;
        collaborations?: Array<{
          __typename?: 'Collaboration';
          id: string;
          jobDescription?: string | null;
          address?: string | null;
          country?: string | null;
          city?: string | null;
          fax?: string | null;
          emailAddress?: string | null;
          blogUrl?: string | null;
          websiteUrl?: string | null;
          twitterProfileUrl?: string | null;
          instagramProfileUrl?: string | null;
          linkedInProfileUrl?: string | null;
          youtubeProfileUrl?: string | null;
          facebookProfileUrl?: string | null;
          isPrimary: boolean;
          postalCode?: string | null;
          medium?: {
            __typename?: 'Medium';
            id?: string | null;
            name?: string | null;
            type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
          } | null;
          jobTitle?: {
            __typename?: 'JobTitleGraphType';
            id?: string | null;
            name?: string | null;
            dataSourceKey?: string | null;
            mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
          } | null;
          landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
          mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        }> | null;
      } | null;
    }>;
  };
};

export type FetchContactsByIdsPagedQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  contactsIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;

export type FetchContactsByIdsPagedQuery = {
  __typename?: 'Query';
  contactsByIdsPaged: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      isGloballySignedOut?: boolean | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
      mediaResorts?: Array<{
        __typename?: 'ContactsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      comments?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'ContactsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
      blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }>;
  };
};

export type FetchContactsBySearchTextAndNotContainedInListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  searchText: Scalars['String'];
  listId: Scalars['ID'];
}>;

export type FetchContactsBySearchTextAndNotContainedInListQuery = {
  __typename?: 'Query';
  contactsBySearchTextAndNotContainedInList: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      isGloballySignedOut?: boolean | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
      mediaResorts?: Array<{
        __typename?: 'ContactsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      comments?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'ContactsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
      blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }>;
  };
};

export type FetchAadContactsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type FetchAadContactsQuery = {
  __typename?: 'Query';
  aadContacts?: Array<{
    __typename?: 'AadContact';
    objectId: string;
    contactId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    defaultCompany?: { __typename?: 'DefaultCompany'; id: string; name?: string | null } | null;
  }> | null;
};

export type FetchAadGroupsQueryVariables = Exact<{
  searchText: Scalars['String'];
  take: Scalars['Int'];
}>;

export type FetchAadGroupsQuery = {
  __typename?: 'Query';
  aadGroups?: Array<{
    __typename?: 'GroupGraphType';
    objectId?: string | null;
    name?: string | null;
    members?: Array<{
      __typename?: 'AadContact';
      objectId: string;
      contactId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      defaultCompany?: { __typename?: 'DefaultCompany'; id: string; name?: string | null } | null;
    }> | null;
  }> | null;
};

export type FetchAadGroupMembersQueryVariables = Exact<{
  groupId: Scalars['String'];
}>;

export type FetchAadGroupMembersQuery = {
  __typename?: 'Query';
  aadGroupMembers?: Array<{
    __typename?: 'AadContact';
    objectId: string;
    contactId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    defaultCompany?: { __typename?: 'DefaultCompany'; id: string; name?: string | null } | null;
  }> | null;
};

export type FetchListsByNameCreatorAndDateQueryVariables = Exact<{
  name: Scalars['String'];
  createdBy: Scalars['String'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
}>;

export type FetchListsByNameCreatorAndDateQuery = {
  __typename?: 'Query';
  listsByNameCreatorAndDate?: Array<{
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  }> | null;
};

export type FetchSendingOverviewsByListIdQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type FetchSendingOverviewsByListIdQuery = {
  __typename?: 'Query';
  sendingOverviewsByListId: Array<{
    __typename?: 'SendingOverview';
    sendingId?: string | null;
    sendingStatus?: number | null;
    numberOfDeliveredRecipients?: number | null;
    totalNumberOfRecipients?: number | null;
    totalNumberOfOpenings?: number | null;
    totalNumberOfClickedLinks?: number | null;
    totalNumberOfFailedDeliveries?: number | null;
    numberOfOpenedSendings?: number | null;
    numberOfUnsubscribedRecipients?: number | null;
    distributionDurationSeconds?: number | null;
    deliveredPercentage?: number | null;
    openingsRatePercentage?: number | null;
    clickRatePercentage?: number | null;
    title?: string | null;
    createdAt?: any | null;
  }>;
};

export type FetchLinkedSavedSearchesByListIdQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type FetchLinkedSavedSearchesByListIdQuery = {
  __typename?: 'Query';
  linkedSavedSearchesByListId?: Array<{
    __typename?: 'LinkedSavedSearch';
    totalCountOfContacts?: number | null;
    shouldAutoUpdate?: boolean | null;
    savedSearch?: { __typename?: 'ContactsSavedSearch'; id?: string | null; name?: string | null; lastModifiedAt?: any | null; createdAt?: any | null } | null;
  }> | null;
};

export type FetchContactsSavedSearchesResultsQueryVariables = Exact<{
  contactsQueryInput: Array<ContactsQueryInput> | ContactsQueryInput;
}>;

export type FetchContactsSavedSearchesResultsQuery = {
  __typename?: 'Query';
  contactsSavedSearchesResults?: Array<{
    __typename?: 'SavedSearchResultsWithSavedSearchId';
    savedSearchId: string;
    contacts?: Array<{
      __typename?: 'SavedSearchResultContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
  }> | null;
};

export type FetchContactInListByListIdQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type FetchContactInListByListIdQuery = {
  __typename?: 'Query';
  listContactsByListId: Array<{
    __typename?: 'ListContactGraphType';
    id?: string | null;
    listId?: string | null;
    isExcluded?: boolean | null;
    mailingAddressCompanyId?: string | null;
    createdAt?: any | null;
    createdBy?: string | null;
    lastModifiedAt?: any | null;
    lastModifiedBy?: string | null;
    contact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    } | null;
  }>;
};

export type FetchListsByNameQueryVariables = Exact<{
  name: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;

export type FetchListsByNameQuery = {
  __typename?: 'Query';
  listsByName: Array<{
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  }>;
};

export type FetchContactsByListIdQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type FetchContactsByListIdQuery = {
  __typename?: 'Query';
  contactsByListId: Array<{
    __typename?: 'ListContactGraphType';
    id?: string | null;
    listId?: string | null;
    mailingAddressCompanyId?: string | null;
    contact?: {
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
      }> | null;
    } | null;
  }>;
};

export type ExportContactsFromListToCsvQueryVariables = Exact<{
  listId: Scalars['ID'];
  csvHeader: CsvHeader;
}>;

export type ExportContactsFromListToCsvQuery = { __typename?: 'Query'; exportContactsFromListToCsv: any };

export type ExportVCardsForContactsFromListQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type ExportVCardsForContactsFromListQuery = { __typename?: 'Query'; exportVCardsForContactsFromList: any };

export type FetchNumberOfDesynchronizedContactsQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type FetchNumberOfDesynchronizedContactsQuery = { __typename?: 'Query'; numberOfDesynchronizedContactsInList?: number | null };

export type FetchMediumNamesByListIdQueryVariables = Exact<{
  listId: Scalars['ID'];
}>;

export type FetchMediumNamesByListIdQuery = { __typename?: 'Query'; mediumNamesByListId?: Array<string | null> | null };

export type AddListMutationVariables = Exact<{
  input: ContactsListInputType;
}>;

export type AddListMutation = {
  __typename?: 'Mutation';
  addList?: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  } | null;
};

export type RemoveListMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveListMutation = {
  __typename?: 'Mutation';
  removeList?: {
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  } | null;
};

export type DuplicateListsMutationVariables = Exact<{
  listsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DuplicateListsMutation = {
  __typename?: 'Mutation';
  duplicateLists: Array<{
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  }>;
};

export type RemoveListsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveListsMutation = {
  __typename?: 'Mutation';
  removeLists: Array<{
    __typename?: 'ListGraphType';
    id?: string | null;
    name?: string | null;
    imageUrl?: string | null;
    description?: string | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    campaign?: string | null;
    createdBy?: string | null;
    lastModifiedBy?: string | null;
    numberOfContacts?: number | null;
    tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
    createdByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
    lastModifiedByContact?: {
      __typename?: 'Contact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    } | null;
  }>;
};

export type BulkRefreshLinkedSavedSearchesMutationVariables = Exact<{
  listsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type BulkRefreshLinkedSavedSearchesMutation = {
  __typename?: 'Mutation';
  bulkRefreshLinkedSavedSearches: Array<{ __typename?: 'ListGraphType'; id?: string | null; name?: string | null; numberOfContacts?: number | null }>;
};

export type FetchListsByQueryParamsPagedQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<ContactsFilterItemInput> | ContactsFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  sortingInput: ListsSortingInput;
}>;

export type FetchListsByQueryParamsPagedQuery = {
  __typename?: 'Query';
  lists: {
    __typename?: 'PageOfLists';
    totalCount: number;
    lists: Array<{
      __typename?: 'ListGraphType';
      id?: string | null;
      name?: string | null;
      imageUrl?: string | null;
      description?: string | null;
      createdAt?: any | null;
      lastModifiedAt?: any | null;
      campaign?: string | null;
      createdBy?: string | null;
      lastModifiedBy?: string | null;
      numberOfContacts?: number | null;
      mediumNames?: Array<string> | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      createdByContact?: {
        __typename?: 'Contact';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePictureUrl?: string | null;
      } | null;
      lastModifiedByContact?: {
        __typename?: 'Contact';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePictureUrl?: string | null;
      } | null;
    }>;
  };
};

export type FetchListsSearchSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;

export type FetchListsSearchSuggestionsQuery = {
  __typename?: 'Query';
  searchListsSuggestions: Array<{
    __typename?: 'ListsSearchSuggestionCategory';
    filterItemFieldName: string;
    searchCategoryName: { __typename?: 'ListsSearchCategoryName'; name?: string | null; value?: number | null };
    suggestionsList: Array<{ __typename?: 'ContactsSuggestionItem'; text?: string | null; imageUrl?: string | null; subtext?: string | null }>;
  }>;
};

export type ListSuggestionsByNameQueryVariables = Exact<{
  name: Scalars['String'];
  take: Scalars['Int'];
}>;

export type ListSuggestionsByNameQuery = { __typename?: 'Query'; listsSuggestionsByName: Array<string> };

export type ListSuggestionsByContactNameQueryVariables = Exact<{
  name: Scalars['String'];
  take: Scalars['Int'];
}>;

export type ListSuggestionsByContactNameQuery = { __typename?: 'Query'; listsSuggestionsByContactName: Array<string> };

export type ListSuggestionsByMediumNameQueryVariables = Exact<{
  name: Scalars['String'];
  take: Scalars['Int'];
}>;

export type ListSuggestionsByMediumNameQuery = { __typename?: 'Query'; listsSuggestionsByMediumName: Array<string> };

export type ListSuggestionsByTagNameQueryVariables = Exact<{
  name: Scalars['String'];
  take: Scalars['Int'];
}>;

export type ListSuggestionsByTagNameQuery = { __typename?: 'Query'; listsSuggestionsByTagName: Array<string> };

export type ListSuggestionsByCreatedByQueryVariables = Exact<{
  name: Scalars['String'];
  take: Scalars['Int'];
}>;

export type ListSuggestionsByCreatedByQuery = { __typename?: 'Query'; listsSuggestionsByCreatedBy: Array<string> };

export type AddCommentToMediaOutletMutationVariables = Exact<{
  mediaOutletId: Scalars['ID'];
  input: MediaOutletsCommentInput;
}>;

export type AddCommentToMediaOutletMutation = {
  __typename?: 'Mutation';
  addCommentToMediaOutlet: {
    __typename?: 'MediaOutletsComment';
    id?: string | null;
    content?: string | null;
    createdAt?: any | null;
    author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
    mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    replies?: Array<{
      __typename?: 'MediaOutletsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
    }> | null;
  };
};

export type AddTagToMediaOutletsMutationVariables = Exact<{
  mediaOutletIds: Array<Scalars['ID']> | Scalars['ID'];
  input: MediaOutletsTagInput;
}>;

export type AddTagToMediaOutletsMutation = {
  __typename?: 'Mutation';
  addTagToMediaOutlets: { __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null };
};

export type RemoveTagFromMediaOutletsMutationVariables = Exact<{
  mediaOutletIds: Array<Scalars['ID']> | Scalars['ID'];
  input: MediaOutletsTagInput;
}>;

export type RemoveTagFromMediaOutletsMutation = {
  __typename?: 'Mutation';
  removeTagFromMediaOutlets: { __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null };
};

export type UpdateMediaOutletProfilePictureMutationVariables = Exact<{
  mediaOutletId: Scalars['ID'];
  input: MediaOutletsProfilePictureInput;
}>;

export type UpdateMediaOutletProfilePictureMutation = { __typename?: 'Mutation'; updateMediaOutletProfilePicture: any };

export type RemoveMediaOutletProfilePictureMutationVariables = Exact<{
  mediaOutletId: Scalars['ID'];
}>;

export type RemoveMediaOutletProfilePictureMutation = { __typename?: 'Mutation'; removeMediaOutletProfilePicture: any };

export type AddMediaResortToMediaOutletMutationVariables = Exact<{
  mediaOutletId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
}>;

export type AddMediaResortToMediaOutletMutation = {
  __typename?: 'Mutation';
  addMediaResortToMediaOutlet: { __typename?: 'MediaResort'; id?: string | null; name?: string | null; dataSourceKey?: string | null };
};

export type RemoveMediaResortFromMediaOutletMutationVariables = Exact<{
  mediaOutletId: Scalars['ID'];
  mediaResortId: Scalars['ID'];
}>;

export type RemoveMediaResortFromMediaOutletMutation = {
  __typename?: 'Mutation';
  removeMediaResortFromMediaOutlet: { __typename?: 'MediaResort'; id?: string | null; name?: string | null; dataSourceKey?: string | null };
};

export type UpdateMediaOutletMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MediaOutletInput;
}>;

export type UpdateMediaOutletMutation = {
  __typename?: 'Mutation';
  updateMediaOutlet?: {
    __typename?: 'MediaOutlet';
    id?: string | null;
    name?: string | null;
    dataSourceKey?: string | null;
    profilePictureUrl?: string | null;
    description?: string | null;
    wemfArea?: string | null;
    websiteUrl?: string | null;
    mediaType?: string | null;
    publicationType?: string | null;
    readership?: string | null;
    circulation?: number | null;
    comments?: Array<string> | null;
    audience?: { __typename?: 'Audience'; id?: string | null; key?: string | null } | null;
    category?: { __typename?: 'MediaOutletsMediumCategory'; id: string; name: string } | null;
    geographicalCoverage?: { __typename?: 'MediaOutletsGeographicalCoverage'; id: string; name: string } | null;
    frequency?: { __typename?: 'MediumPublishingFrequency'; id?: string | null; key?: string | null } | null;
    distributions?: Array<{ __typename?: 'Distribution'; key?: string | null }> | null;
    mediumSupportTypes?: Array<{ __typename?: 'MediumSupportType'; id?: string | null; key?: string | null }> | null;
    preferredLanguages?: Array<{
      __typename?: 'MediaOutletsPreferredLanguage';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    mediaResorts?: Array<{
      __typename?: 'MediaOutletsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null }> | null;
    contactData?: {
      __typename?: 'MediaOutletsContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    mediaOutletContacts?: Array<{
      __typename?: 'MediaOutletContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'MediaOutletsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'MediaOutletsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type RemoveMediaOutletMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveMediaOutletMutation = { __typename?: 'Mutation'; removeMediaOutlet: { __typename?: 'MediaOutlet'; id?: string | null } };

export type FetchContactsByMediaOutletIdNameAndRoleQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  name: Scalars['String'];
  role: Scalars['String'];
  mediumId: Scalars['ID'];
}>;

export type FetchContactsByMediaOutletIdNameAndRoleQuery = {
  __typename?: 'Query';
  contactsByMediumIdNameAndRole: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      isGloballySignedOut?: boolean | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
      mediaResorts?: Array<{
        __typename?: 'ContactsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      comments?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'ContactsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
      blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }>;
  };
};

export type FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  name: Scalars['String'];
  role: Scalars['String'];
  mediumId: Scalars['ID'];
}>;

export type FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery = {
  __typename?: 'Query';
  contactsByNameAndRoleAndNotContainedInMedium: {
    __typename?: 'PageOfContacts';
    totalCount: number;
    contacts: Array<{
      __typename?: 'Contact';
      id?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
      isGloballySignedOut?: boolean | null;
      tags?: Array<{ __typename?: 'ContactsTag'; id?: string | null; name?: string | null }> | null;
      topics?: Array<{ __typename?: 'ContactsTopics'; topicId?: string | null; topicName?: string | null; dataSourceKey?: string | null }> | null;
      mediaResorts?: Array<{
        __typename?: 'ContactsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      comments?: Array<{
        __typename?: 'ContactsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'ContactsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'TenantUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
      blacklists?: Array<{ __typename?: 'ContactsBlacklist'; id?: string | null; name?: string | null }> | null;
      ownContactData?: {
        __typename?: 'OwnContactData';
        address?: string | null;
        country?: string | null;
        city?: string | null;
        postalCode?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      } | null;
      collaborations?: Array<{
        __typename?: 'Collaboration';
        id: string;
        jobDescription?: string | null;
        address?: string | null;
        country?: string | null;
        city?: string | null;
        fax?: string | null;
        emailAddress?: string | null;
        blogUrl?: string | null;
        websiteUrl?: string | null;
        twitterProfileUrl?: string | null;
        instagramProfileUrl?: string | null;
        linkedInProfileUrl?: string | null;
        youtubeProfileUrl?: string | null;
        facebookProfileUrl?: string | null;
        isPrimary: boolean;
        postalCode?: string | null;
        medium?: {
          __typename?: 'Medium';
          id?: string | null;
          name?: string | null;
          type?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        jobTitle?: {
          __typename?: 'JobTitleGraphType';
          id?: string | null;
          name?: string | null;
          dataSourceKey?: string | null;
          mediumType?: { __typename?: 'MediumType'; name?: string | null; value?: number | null } | null;
        } | null;
        landlinePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
        mobilePhoneNumber?: { __typename?: 'PhoneNumber'; value?: string | null; isPrimary?: boolean | null } | null;
      }> | null;
    }>;
  };
};

export type AddMediaOutletSavedSearchMutationVariables = Exact<{
  savedMediaOutletSearch: MediaOutletsSavedSearchInput;
}>;

export type AddMediaOutletSavedSearchMutation = {
  __typename?: 'Mutation';
  saveMediaOutletSearch: {
    __typename?: 'MediaOutletsSavedSearch';
    id?: string | null;
    searchText?: string | null;
    name?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'MediaOutletsFilter'; fieldName?: string | null; value?: string | null }>;
  };
};

export type UpdateMediaOutletSavedSearchMutationVariables = Exact<{
  id: Scalars['ID'];
  savedMediaOutletSearch: MediaOutletsSavedSearchInput;
}>;

export type UpdateMediaOutletSavedSearchMutation = {
  __typename?: 'Mutation';
  updateMediaOutletSavedSearch: {
    __typename?: 'MediaOutletsSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'MediaOutletsFilter'; fieldName?: string | null; value?: string | null }>;
  };
};

export type UpdateMediaOutletSavedSearchesMutationVariables = Exact<{
  savedMediaOutletSearches: Array<MediaOutletsSavedSearchWithIdInput> | MediaOutletsSavedSearchWithIdInput;
}>;

export type UpdateMediaOutletSavedSearchesMutation = {
  __typename?: 'Mutation';
  updateMediaOutletSavedSearches: Array<{
    __typename?: 'MediaOutletsSavedSearch';
    id?: string | null;
    name?: string | null;
    priority?: number | null;
    searchText?: string | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'MediaOutletsFilter'; fieldName?: string | null; value?: string | null }>;
  }>;
};

export type RemoveMediaOutletSavedSearchMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveMediaOutletSavedSearchMutation = {
  __typename?: 'Mutation';
  removeMediaOutletSavedSearch: {
    __typename?: 'MediaOutletsSavedSearch';
    id?: string | null;
    name?: string | null;
    searchText?: string | null;
    priority?: number | null;
    count?: number | null;
    createdAt?: any | null;
    lastModifiedAt?: any | null;
    createdBy?: string | null;
    filterItems: Array<{ __typename?: 'MediaOutletsFilter'; fieldName?: string | null; value?: string | null }>;
  };
};

export type FetchMediaOutletsSavedSearchesPagedQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<MediaOutletsFilterItemInput> | MediaOutletsFilterItemInput;
  take: Scalars['Int'];
  skip: Scalars['Int'];
}>;

export type FetchMediaOutletsSavedSearchesPagedQuery = {
  __typename?: 'Query';
  mediaOutletSavedSearches: {
    __typename?: 'PageOfSavedSearches';
    totalCount: number;
    savedSearches: Array<{
      __typename?: 'MediaOutletsSavedSearch';
      id?: string | null;
      name?: string | null;
      searchText?: string | null;
      priority?: number | null;
      count?: number | null;
      createdAt?: any | null;
      lastModifiedAt?: any | null;
      createdBy?: string | null;
      filterItems: Array<{ __typename?: 'MediaOutletsFilter'; fieldName?: string | null; value?: string | null }>;
    }>;
  };
};

export type FetchMediaOutletsAvatarDetailsQueryVariables = Exact<{
  mediaOutletAvatarDetails: Array<MediaOutletsAvatarDetailsInput> | MediaOutletsAvatarDetailsInput;
}>;

export type FetchMediaOutletsAvatarDetailsQuery = {
  __typename?: 'Query';
  mediaOutletsAvatarDetails: Array<{
    __typename?: 'MediaOutletsAvatarDetailsWithSavedSearchId';
    savedSearchId: string;
    pageOfMediaOutlets: {
      __typename?: 'PageOfMediaOutlets';
      totalCount: number;
      mediaOutlets: Array<{ __typename?: 'MediaOutlet'; id?: string | null; name?: string | null; profilePictureUrl?: string | null }>;
    };
  }>;
};

export type AddMediaOutletMutationVariables = Exact<{
  mediaOutlet: MediaOutletInput;
}>;

export type AddMediaOutletMutation = {
  __typename?: 'Mutation';
  addMediaOutlet?: {
    __typename?: 'MediaOutlet';
    id?: string | null;
    name?: string | null;
    dataSourceKey?: string | null;
    description?: string | null;
    websiteUrl?: string | null;
    mediaType?: string | null;
    publicationType?: string | null;
    readership?: string | null;
    circulation?: number | null;
    comments?: Array<string> | null;
    audience?: { __typename?: 'Audience'; id?: string | null; key?: string | null } | null;
    category?: { __typename?: 'MediaOutletsMediumCategory'; id: string; name: string } | null;
    frequency?: { __typename?: 'MediumPublishingFrequency'; id?: string | null; key?: string | null } | null;
    geographicalCoverage?: { __typename?: 'MediaOutletsGeographicalCoverage'; id: string; name: string } | null;
    mediumSupportTypes?: Array<{ __typename?: 'MediumSupportType'; id?: string | null; key?: string | null }> | null;
    distributions?: Array<{ __typename?: 'Distribution'; key?: string | null }> | null;
    preferredLanguages?: Array<{ __typename?: 'MediaOutletsPreferredLanguage'; id?: string | null; name?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'MediaOutletsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null }> | null;
    contactData?: {
      __typename?: 'MediaOutletsContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    mediaOutletContacts?: Array<{
      __typename?: 'MediaOutletContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'MediaOutletsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'MediaOutletsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type TransformMediaOutletIntoCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TransformMediaOutletIntoCompanyMutation = {
  __typename?: 'Mutation';
  transformMediaOutletIntoCompany: { __typename?: 'MediaOutlet'; id?: string | null };
};

export type RemoveMediaOutletsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveMediaOutletsMutation = {
  __typename?: 'Mutation';
  removeMediaOutlets: Array<{
    __typename?: 'MediaOutlet';
    id?: string | null;
    name?: string | null;
    contactData?: {
      __typename?: 'MediaOutletsContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
  }>;
};

export type UpdateBulkMediaOutletsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  mediaResortsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UpdateBulkMediaOutletsMutation = {
  __typename?: 'Mutation';
  updateBulkMediaOutlets: Array<{
    __typename?: 'MediaOutlet';
    id?: string | null;
    name?: string | null;
    contactData?: {
      __typename?: 'MediaOutletsContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
  }>;
};

export type AddMediumCategoryMutationVariables = Exact<{
  input: MediaOutletsMediumCategoryInput;
}>;

export type AddMediumCategoryMutation = {
  __typename?: 'Mutation';
  addMediumCategory?: {
    __typename?: 'MediaOutletsMediumCategory';
    id: string;
    name: string;
    type: { __typename?: 'MediumCategoryType'; name: string; value: number };
  } | null;
};

export type RemoveMediumCategoryMutationVariables = Exact<{
  mediumCategoryId: Scalars['ID'];
}>;

export type RemoveMediumCategoryMutation = { __typename?: 'Mutation'; removeMediumCategory?: string | null };

export type FetchMediaOutletByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FetchMediaOutletByIdQuery = {
  __typename?: 'Query';
  mediaOutlet?: {
    __typename?: 'MediaOutlet';
    id?: string | null;
    name?: string | null;
    dataSourceKey?: string | null;
    profilePictureUrl?: string | null;
    description?: string | null;
    websiteUrl?: string | null;
    mediaType?: string | null;
    publicationType?: string | null;
    readership?: string | null;
    circulation?: number | null;
    comments?: Array<string> | null;
    audience?: { __typename?: 'Audience'; id?: string | null; key?: string | null } | null;
    geographicalCoverage?: { __typename?: 'MediaOutletsGeographicalCoverage'; id: string; name: string } | null;
    category?: { __typename?: 'MediaOutletsMediumCategory'; id: string; name: string } | null;
    frequency?: { __typename?: 'MediumPublishingFrequency'; id?: string | null; key?: string | null } | null;
    mediumSupportTypes?: Array<{ __typename?: 'MediumSupportType'; id?: string | null; key?: string | null }> | null;
    distributions?: Array<{ __typename?: 'Distribution'; key?: string | null }> | null;
    preferredLanguages?: Array<{
      __typename?: 'MediaOutletsPreferredLanguage';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    contactData?: {
      __typename?: 'MediaOutletsContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    mediaOutletContacts?: Array<{
      __typename?: 'MediaOutletContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'MediaOutletsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'MediaOutletsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'MediaOutletsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type FetchMediaOutletsByContactIdQueryVariables = Exact<{
  contactId: Scalars['ID'];
}>;

export type FetchMediaOutletsByContactIdQuery = {
  __typename?: 'Query';
  mediaOutletsByContactId: Array<{
    __typename?: 'MediaOutlet';
    id?: string | null;
    name?: string | null;
    dataSourceKey?: string | null;
    profilePictureUrl?: string | null;
    description?: string | null;
    websiteUrl?: string | null;
    mediaType?: string | null;
    publicationType?: string | null;
    readership?: string | null;
    circulation?: number | null;
    comments?: Array<string> | null;
    audience?: { __typename?: 'Audience'; id?: string | null; key?: string | null } | null;
    category?: { __typename?: 'MediaOutletsMediumCategory'; id: string; name: string } | null;
    frequency?: { __typename?: 'MediumPublishingFrequency'; id?: string | null; key?: string | null } | null;
    mediumSupportTypes?: Array<{ __typename?: 'MediumSupportType'; id?: string | null; key?: string | null }> | null;
    distributions?: Array<{ __typename?: 'Distribution'; key?: string | null }> | null;
    preferredLanguages?: Array<{
      __typename?: 'MediaOutletsPreferredLanguage';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    contactData?: {
      __typename?: 'MediaOutletsContactData';
      street?: string | null;
      poBox?: string | null;
      postalCode?: string | null;
      country?: string | null;
      city?: string | null;
      websiteUrl?: string | null;
      emailAddress?: string | null;
      landlinePhoneNumber?: string | null;
      fax?: string | null;
      alternativeFax?: string | null;
    } | null;
    mediaOutletContacts?: Array<{
      __typename?: 'MediaOutletContact';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      profilePictureUrl?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null }> | null;
    mediaResorts?: Array<{
      __typename?: 'MediaOutletsMediaResort';
      mediaResortId?: string | null;
      mediaResortName?: string | null;
      dataSourceKey?: string | null;
    }> | null;
    userComments?: Array<{
      __typename?: 'MediaOutletsComment';
      id?: string | null;
      content?: string | null;
      createdAt?: any | null;
      author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
      mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      replies?: Array<{
        __typename?: 'MediaOutletsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
      }> | null;
    }> | null;
  }>;
};

export type FetchMediaOutletsByQueryParamsQueryVariables = Exact<{
  searchText: Scalars['String'];
  filterItems: Array<MediaOutletsFilterItemInput> | MediaOutletsFilterItemInput;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  sortingInput: MediaOutletsSortingInput;
}>;

export type FetchMediaOutletsByQueryParamsQuery = {
  __typename?: 'Query';
  mediaOutlets?: {
    __typename?: 'PageOfMediaOutlets';
    totalCount: number;
    mediaOutlets: Array<{
      __typename?: 'MediaOutlet';
      id?: string | null;
      name?: string | null;
      dataSourceKey?: string | null;
      profilePictureUrl?: string | null;
      description?: string | null;
      websiteUrl?: string | null;
      mediaType?: string | null;
      publicationType?: string | null;
      readership?: string | null;
      circulation?: number | null;
      audience?: { __typename?: 'Audience'; id?: string | null; key?: string | null } | null;
      category?: { __typename?: 'MediaOutletsMediumCategory'; id: string; name: string } | null;
      frequency?: { __typename?: 'MediumPublishingFrequency'; id?: string | null; key?: string | null } | null;
      contactData?: {
        __typename?: 'MediaOutletsContactData';
        street?: string | null;
        poBox?: string | null;
        postalCode?: string | null;
        country?: string | null;
        city?: string | null;
        websiteUrl?: string | null;
        emailAddress?: string | null;
        landlinePhoneNumber?: string | null;
        fax?: string | null;
        alternativeFax?: string | null;
      } | null;
      mediumSupportTypes?: Array<{ __typename?: 'MediumSupportType'; id?: string | null; key?: string | null }> | null;
      distributions?: Array<{ __typename?: 'Distribution'; key?: string | null }> | null;
      mediaOutletContacts?: Array<{
        __typename?: 'MediaOutletContact';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePictureUrl?: string | null;
      }> | null;
      tags?: Array<{ __typename?: 'MediaOutletsTag'; id?: string | null; name?: string | null }> | null;
      preferredLanguages?: Array<{
        __typename?: 'MediaOutletsPreferredLanguage';
        id?: string | null;
        name?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      mediaResorts?: Array<{
        __typename?: 'MediaOutletsMediaResort';
        mediaResortId?: string | null;
        mediaResortName?: string | null;
        dataSourceKey?: string | null;
      }> | null;
      userComments?: Array<{
        __typename?: 'MediaOutletsComment';
        id?: string | null;
        content?: string | null;
        createdAt?: any | null;
        author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
        mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        replies?: Array<{
          __typename?: 'MediaOutletsComment';
          id?: string | null;
          content?: string | null;
          createdAt?: any | null;
          author?: { __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null } | null;
          mentions?: Array<{ __typename?: 'MediaOutletsUser'; teamsId?: string | null; name?: string | null; aadObjectId?: string | null }> | null;
        }> | null;
      }> | null;
    }>;
  } | null;
};

export type FetchMediaOutletSearchSuggestionsQueryVariables = Exact<{
  searchText: Scalars['String'];
  language: Scalars['String'];
}>;

export type FetchMediaOutletSearchSuggestionsQuery = {
  __typename?: 'Query';
  searchMediaOutletSuggestions: Array<{
    __typename?: 'MediaOutletsSearchSuggestionsCategory';
    filterItemFieldName: string;
    searchCategoryName: { __typename?: 'SearchCategoryName'; name?: string | null; value?: number | null };
    suggestionsList: Array<{ __typename?: 'MediaOutletsSuggestionItem'; text?: string | null; imageUrl?: string | null }>;
  }>;
};

export type MediaOutletSuggestionsByNameQueryVariables = Exact<{
  name: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediaOutletSuggestionsByNameQuery = { __typename?: 'Query'; mediaOutletSuggestionsByName: Array<string> };

export type MediaOutletSuggestionsByContactNameQueryVariables = Exact<{
  contactName: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediaOutletSuggestionsByContactNameQuery = { __typename?: 'Query'; mediaOutletSuggestionsByContactName: Array<string> };

export type MediaOutletSuggestionsByTagNameQueryVariables = Exact<{
  tagName: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediaOutletSuggestionsByTagNameQuery = { __typename?: 'Query'; mediaOutletSuggestionsByTagName: Array<string> };

export type MediaOutletSuggestionsByCategoryQueryVariables = Exact<{
  category: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediaOutletSuggestionsByCategoryQuery = { __typename?: 'Query'; mediaOutletSuggestionsByCategory: Array<string> };

export type MediaOutletSuggestionsByLanguageQueryVariables = Exact<{
  language: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediaOutletSuggestionsByLanguageQuery = { __typename?: 'Query'; mediaOutletSuggestionsByLanguage: Array<string> };

export type MediaOutletSuggestionsByMediaTypeQueryVariables = Exact<{
  mediaType: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediaOutletSuggestionsByMediaTypeQuery = { __typename?: 'Query'; mediaOutletSuggestionsByMediaType: Array<string> };

export type MediaOutletSuggestionsByFrequencyQueryVariables = Exact<{
  frequency: Scalars['String'];
  take: Scalars['Int'];
}>;

export type MediaOutletSuggestionsByFrequencyQuery = { __typename?: 'Query'; mediaOutletSuggestionsByFrequency: Array<string> };

export type FetchMediaOutletsDataQueryVariables = Exact<{ [key: string]: never }>;

export type FetchMediaOutletsDataQuery = {
  __typename?: 'Query';
  mediaOutletCategories: Array<{
    __typename?: 'MediaOutletsMediumCategory';
    id: string;
    name: string;
    type: { __typename?: 'MediumCategoryType'; name: string; value: number };
  }>;
};

export type FetchReferenceListsLocalizationQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchReferenceListsLocalizationQueryQuery = {
  __typename?: 'Query';
  mediaOutletAudiences: Array<
    | {
        __typename?: 'Audience';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'DataSource';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'Distribution';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'MediumPublishingFrequency';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'MediumSupportType';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
  >;
  mediaOutletMediumSupportTypes: Array<
    | {
        __typename?: 'Audience';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'DataSource';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'Distribution';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'MediumPublishingFrequency';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'MediumSupportType';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
  >;
  mediaOutletMediumPublishingFrequency: Array<
    | {
        __typename?: 'Audience';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'DataSource';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'Distribution';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'MediumPublishingFrequency';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
    | {
        __typename?: 'MediumSupportType';
        id?: string | null;
        key?: string | null;
        labels?: Array<{
          __typename?: 'MediaOutletsLabel';
          value?: string | null;
          language?: { __typename?: 'MediaOutletsAugureLanguage'; name: string; value: number } | null;
        } | null> | null;
      }
  >;
};

export const CreateAndAssignTagDocument = gql`
  mutation CreateAndAssignTag($input: TagCreationInput!) {
    createAndAssignTag(input: $input) {
      id
      name
      hexColor
    }
  }
`;
export type CreateAndAssignTagMutationFn = Apollo.MutationFunction<CreateAndAssignTagMutation, CreateAndAssignTagMutationVariables>;

/**
 * __useCreateAndAssignTagMutation__
 *
 * To run a mutation, you first call `useCreateAndAssignTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndAssignTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndAssignTagMutation, { data, loading, error }] = useCreateAndAssignTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAndAssignTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateAndAssignTagMutation, CreateAndAssignTagMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAndAssignTagMutation, CreateAndAssignTagMutationVariables>(CreateAndAssignTagDocument, options);
}
export type CreateAndAssignTagMutationHookResult = ReturnType<typeof useCreateAndAssignTagMutation>;
export type CreateAndAssignTagMutationResult = Apollo.MutationResult<CreateAndAssignTagMutation>;
export type CreateAndAssignTagMutationOptions = Apollo.BaseMutationOptions<CreateAndAssignTagMutation, CreateAndAssignTagMutationVariables>;
export const DeleteTagsDocument = gql`
  mutation DeleteTags($tagIds: [String!]!) {
    deleteTags(tagIds: $tagIds)
  }
`;
export type DeleteTagsMutationFn = Apollo.MutationFunction<DeleteTagsMutation, DeleteTagsMutationVariables>;

/**
 * __useDeleteTagsMutation__
 *
 * To run a mutation, you first call `useDeleteTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagsMutation, { data, loading, error }] = useDeleteTagsMutation({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useDeleteTagsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagsMutation, DeleteTagsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagsMutation, DeleteTagsMutationVariables>(DeleteTagsDocument, options);
}
export type DeleteTagsMutationHookResult = ReturnType<typeof useDeleteTagsMutation>;
export type DeleteTagsMutationResult = Apollo.MutationResult<DeleteTagsMutation>;
export type DeleteTagsMutationOptions = Apollo.BaseMutationOptions<DeleteTagsMutation, DeleteTagsMutationVariables>;
export const FetchTagsByEntityTypeDocument = gql`
  query FetchTagsByEntityType($entityType: String!) {
    tags(entityType: $entityType) {
      id
      name
      hexColor
    }
  }
`;

/**
 * __useFetchTagsByEntityTypeQuery__
 *
 * To run a query within a React component, call `useFetchTagsByEntityTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagsByEntityTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagsByEntityTypeQuery({
 *   variables: {
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useFetchTagsByEntityTypeQuery(baseOptions: Apollo.QueryHookOptions<FetchTagsByEntityTypeQuery, FetchTagsByEntityTypeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTagsByEntityTypeQuery, FetchTagsByEntityTypeQueryVariables>(FetchTagsByEntityTypeDocument, options);
}
export function useFetchTagsByEntityTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchTagsByEntityTypeQuery, FetchTagsByEntityTypeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTagsByEntityTypeQuery, FetchTagsByEntityTypeQueryVariables>(FetchTagsByEntityTypeDocument, options);
}
export type FetchTagsByEntityTypeQueryHookResult = ReturnType<typeof useFetchTagsByEntityTypeQuery>;
export type FetchTagsByEntityTypeLazyQueryHookResult = ReturnType<typeof useFetchTagsByEntityTypeLazyQuery>;
export type FetchTagsByEntityTypeQueryResult = Apollo.QueryResult<FetchTagsByEntityTypeQuery, FetchTagsByEntityTypeQueryVariables>;
export const FetchDataSourcesDocument = gql`
  query FetchDataSources {
    dataSources {
      id
      key
    }
  }
`;

/**
 * __useFetchDataSourcesQuery__
 *
 * To run a query within a React component, call `useFetchDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDataSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchDataSourcesQuery(baseOptions?: Apollo.QueryHookOptions<FetchDataSourcesQuery, FetchDataSourcesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDataSourcesQuery, FetchDataSourcesQueryVariables>(FetchDataSourcesDocument, options);
}
export function useFetchDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDataSourcesQuery, FetchDataSourcesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDataSourcesQuery, FetchDataSourcesQueryVariables>(FetchDataSourcesDocument, options);
}
export type FetchDataSourcesQueryHookResult = ReturnType<typeof useFetchDataSourcesQuery>;
export type FetchDataSourcesLazyQueryHookResult = ReturnType<typeof useFetchDataSourcesLazyQuery>;
export type FetchDataSourcesQueryResult = Apollo.QueryResult<FetchDataSourcesQuery, FetchDataSourcesQueryVariables>;
export const FetchBlacklistByIdDocument = gql`
  query FetchBlacklistById($id: String!) {
    blacklistById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useFetchBlacklistByIdQuery__
 *
 * To run a query within a React component, call `useFetchBlacklistByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBlacklistByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBlacklistByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchBlacklistByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchBlacklistByIdQuery, FetchBlacklistByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchBlacklistByIdQuery, FetchBlacklistByIdQueryVariables>(FetchBlacklistByIdDocument, options);
}
export function useFetchBlacklistByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchBlacklistByIdQuery, FetchBlacklistByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchBlacklistByIdQuery, FetchBlacklistByIdQueryVariables>(FetchBlacklistByIdDocument, options);
}
export type FetchBlacklistByIdQueryHookResult = ReturnType<typeof useFetchBlacklistByIdQuery>;
export type FetchBlacklistByIdLazyQueryHookResult = ReturnType<typeof useFetchBlacklistByIdLazyQuery>;
export type FetchBlacklistByIdQueryResult = Apollo.QueryResult<FetchBlacklistByIdQuery, FetchBlacklistByIdQueryVariables>;
export const FetchAllLanguagesDocument = gql`
  query FetchAllLanguages {
    languages {
      id
      name
    }
  }
`;

/**
 * __useFetchAllLanguagesQuery__
 *
 * To run a query within a React component, call `useFetchAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllLanguagesQuery, FetchAllLanguagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllLanguagesQuery, FetchAllLanguagesQueryVariables>(FetchAllLanguagesDocument, options);
}
export function useFetchAllLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllLanguagesQuery, FetchAllLanguagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllLanguagesQuery, FetchAllLanguagesQueryVariables>(FetchAllLanguagesDocument, options);
}
export type FetchAllLanguagesQueryHookResult = ReturnType<typeof useFetchAllLanguagesQuery>;
export type FetchAllLanguagesLazyQueryHookResult = ReturnType<typeof useFetchAllLanguagesLazyQuery>;
export type FetchAllLanguagesQueryResult = Apollo.QueryResult<FetchAllLanguagesQuery, FetchAllLanguagesQueryVariables>;
export const AddRecipientsToSendingDocument = gql`
  mutation AddRecipientsToSending($sendingId: ID!, $recipients: [RecipientInput!]!) {
    addRecipientsToSending(sendingId: $sendingId, recipients: $recipients) {
      ... on Recipient {
        id
        sendingId
        collaborationId
        isOpened
        linksClicked
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          isGloballySignedOut
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
      ... on RecipientFromList {
        id
        sendingId
        listId
        collaborationId
        isOpened
        linksClicked
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          isGloballySignedOut
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
    }
  }
`;
export type AddRecipientsToSendingMutationFn = Apollo.MutationFunction<AddRecipientsToSendingMutation, AddRecipientsToSendingMutationVariables>;

/**
 * __useAddRecipientsToSendingMutation__
 *
 * To run a mutation, you first call `useAddRecipientsToSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecipientsToSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecipientsToSendingMutation, { data, loading, error }] = useAddRecipientsToSendingMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      recipients: // value for 'recipients'
 *   },
 * });
 */
export function useAddRecipientsToSendingMutation(
  baseOptions?: Apollo.MutationHookOptions<AddRecipientsToSendingMutation, AddRecipientsToSendingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRecipientsToSendingMutation, AddRecipientsToSendingMutationVariables>(AddRecipientsToSendingDocument, options);
}
export type AddRecipientsToSendingMutationHookResult = ReturnType<typeof useAddRecipientsToSendingMutation>;
export type AddRecipientsToSendingMutationResult = Apollo.MutationResult<AddRecipientsToSendingMutation>;
export type AddRecipientsToSendingMutationOptions = Apollo.BaseMutationOptions<AddRecipientsToSendingMutation, AddRecipientsToSendingMutationVariables>;
export const RemoveRecipientsFromSendingDocument = gql`
  mutation RemoveRecipientsFromSending($sendingId: ID!, $recipientsIds: [ID!]!) {
    removeRecipientsFromSending(sendingId: $sendingId, recipientsIds: $recipientsIds)
  }
`;
export type RemoveRecipientsFromSendingMutationFn = Apollo.MutationFunction<RemoveRecipientsFromSendingMutation, RemoveRecipientsFromSendingMutationVariables>;

/**
 * __useRemoveRecipientsFromSendingMutation__
 *
 * To run a mutation, you first call `useRemoveRecipientsFromSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecipientsFromSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecipientsFromSendingMutation, { data, loading, error }] = useRemoveRecipientsFromSendingMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      recipientsIds: // value for 'recipientsIds'
 *   },
 * });
 */
export function useRemoveRecipientsFromSendingMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveRecipientsFromSendingMutation, RemoveRecipientsFromSendingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveRecipientsFromSendingMutation, RemoveRecipientsFromSendingMutationVariables>(RemoveRecipientsFromSendingDocument, options);
}
export type RemoveRecipientsFromSendingMutationHookResult = ReturnType<typeof useRemoveRecipientsFromSendingMutation>;
export type RemoveRecipientsFromSendingMutationResult = Apollo.MutationResult<RemoveRecipientsFromSendingMutation>;
export type RemoveRecipientsFromSendingMutationOptions = Apollo.BaseMutationOptions<
  RemoveRecipientsFromSendingMutation,
  RemoveRecipientsFromSendingMutationVariables
>;
export const UpdateSendingRecipientContactDataDocument = gql`
  mutation updateSendingRecipientContactData($sendingId: ID!, $recipientId: ID!, $collaborationId: ID!, $emailAddress: String!) {
    updateSendingRecipientContactData(sendingId: $sendingId, recipientId: $recipientId, collaborationId: $collaborationId, emailAddress: $emailAddress) {
      ... on Recipient {
        id
        sendingId
        collaborationId
        isOpened
        linksClicked
        emailAddress
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          isGloballySignedOut
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
      ... on RecipientFromList {
        id
        sendingId
        listId
        collaborationId
        isOpened
        emailAddress
        linksClicked
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          isGloballySignedOut
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
    }
  }
`;
export type UpdateSendingRecipientContactDataMutationFn = Apollo.MutationFunction<
  UpdateSendingRecipientContactDataMutation,
  UpdateSendingRecipientContactDataMutationVariables
>;

/**
 * __useUpdateSendingRecipientContactDataMutation__
 *
 * To run a mutation, you first call `useUpdateSendingRecipientContactDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendingRecipientContactDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendingRecipientContactDataMutation, { data, loading, error }] = useUpdateSendingRecipientContactDataMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      recipientId: // value for 'recipientId'
 *      collaborationId: // value for 'collaborationId'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useUpdateSendingRecipientContactDataMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSendingRecipientContactDataMutation, UpdateSendingRecipientContactDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSendingRecipientContactDataMutation, UpdateSendingRecipientContactDataMutationVariables>(
    UpdateSendingRecipientContactDataDocument,
    options
  );
}
export type UpdateSendingRecipientContactDataMutationHookResult = ReturnType<typeof useUpdateSendingRecipientContactDataMutation>;
export type UpdateSendingRecipientContactDataMutationResult = Apollo.MutationResult<UpdateSendingRecipientContactDataMutation>;
export type UpdateSendingRecipientContactDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateSendingRecipientContactDataMutation,
  UpdateSendingRecipientContactDataMutationVariables
>;
export const AddRecipientsFromListsToSendingDocument = gql`
  mutation AddRecipientsFromListsToSending($sendingId: ID!, $recipientsFromLists: [RecipientFromListInput!]!) {
    addRecipientsFromListsToSending(sendingId: $sendingId, recipientsFromLists: $recipientsFromLists) {
      ... on Recipient {
        id
        sendingId
        collaborationId
        isOpened
        linksClicked
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
      ... on RecipientFromList {
        id
        sendingId
        listId
        collaborationId
        isOpened
        linksClicked
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          isGloballySignedOut
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
    }
  }
`;
export type AddRecipientsFromListsToSendingMutationFn = Apollo.MutationFunction<
  AddRecipientsFromListsToSendingMutation,
  AddRecipientsFromListsToSendingMutationVariables
>;

/**
 * __useAddRecipientsFromListsToSendingMutation__
 *
 * To run a mutation, you first call `useAddRecipientsFromListsToSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecipientsFromListsToSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecipientsFromListsToSendingMutation, { data, loading, error }] = useAddRecipientsFromListsToSendingMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      recipientsFromLists: // value for 'recipientsFromLists'
 *   },
 * });
 */
export function useAddRecipientsFromListsToSendingMutation(
  baseOptions?: Apollo.MutationHookOptions<AddRecipientsFromListsToSendingMutation, AddRecipientsFromListsToSendingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRecipientsFromListsToSendingMutation, AddRecipientsFromListsToSendingMutationVariables>(
    AddRecipientsFromListsToSendingDocument,
    options
  );
}
export type AddRecipientsFromListsToSendingMutationHookResult = ReturnType<typeof useAddRecipientsFromListsToSendingMutation>;
export type AddRecipientsFromListsToSendingMutationResult = Apollo.MutationResult<AddRecipientsFromListsToSendingMutation>;
export type AddRecipientsFromListsToSendingMutationOptions = Apollo.BaseMutationOptions<
  AddRecipientsFromListsToSendingMutation,
  AddRecipientsFromListsToSendingMutationVariables
>;
export const AddSendingFromTemplateDocument = gql`
  mutation AddSendingFromTemplate($templateId: ID!, $sendingName: String!) {
    addSendingFromTemplate(templateId: $templateId, sendingName: $sendingName) {
      id
      title
      htmlBody
      jsonBody
      status {
        name
        value
      }
      createdAt
      createdByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedAt
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      createdByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      subject
      imageAsBase64
      isFavourite
      recipientsIds
      participants {
        id
        contact {
          id
          firstName
          lastName
          profilePictureUrl
        }
        collaborationId
      }
      tags {
        id
        name
      }
      mailPreviewLink
    }
  }
`;
export type AddSendingFromTemplateMutationFn = Apollo.MutationFunction<AddSendingFromTemplateMutation, AddSendingFromTemplateMutationVariables>;

/**
 * __useAddSendingFromTemplateMutation__
 *
 * To run a mutation, you first call `useAddSendingFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSendingFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSendingFromTemplateMutation, { data, loading, error }] = useAddSendingFromTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      sendingName: // value for 'sendingName'
 *   },
 * });
 */
export function useAddSendingFromTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSendingFromTemplateMutation, AddSendingFromTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSendingFromTemplateMutation, AddSendingFromTemplateMutationVariables>(AddSendingFromTemplateDocument, options);
}
export type AddSendingFromTemplateMutationHookResult = ReturnType<typeof useAddSendingFromTemplateMutation>;
export type AddSendingFromTemplateMutationResult = Apollo.MutationResult<AddSendingFromTemplateMutation>;
export type AddSendingFromTemplateMutationOptions = Apollo.BaseMutationOptions<AddSendingFromTemplateMutation, AddSendingFromTemplateMutationVariables>;
export const AddTagToSendingsDocument = gql`
  mutation AddTagToSendings($sendingIds: [ID!]!, $input: ContactsTagInput!) {
    addTagToSendings(sendingIds: $sendingIds, input: $input) {
      id
      name
    }
  }
`;
export type AddTagToSendingsMutationFn = Apollo.MutationFunction<AddTagToSendingsMutation, AddTagToSendingsMutationVariables>;

/**
 * __useAddTagToSendingsMutation__
 *
 * To run a mutation, you first call `useAddTagToSendingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToSendingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToSendingsMutation, { data, loading, error }] = useAddTagToSendingsMutation({
 *   variables: {
 *      sendingIds: // value for 'sendingIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTagToSendingsMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToSendingsMutation, AddTagToSendingsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTagToSendingsMutation, AddTagToSendingsMutationVariables>(AddTagToSendingsDocument, options);
}
export type AddTagToSendingsMutationHookResult = ReturnType<typeof useAddTagToSendingsMutation>;
export type AddTagToSendingsMutationResult = Apollo.MutationResult<AddTagToSendingsMutation>;
export type AddTagToSendingsMutationOptions = Apollo.BaseMutationOptions<AddTagToSendingsMutation, AddTagToSendingsMutationVariables>;
export const RemoveTagFromSendingsDocument = gql`
  mutation RemoveTagFromSendings($sendingIds: [ID!]!, $input: ContactsTagInput!) {
    removeTagFromSendings(sendingIds: $sendingIds, input: $input) {
      id
      name
    }
  }
`;
export type RemoveTagFromSendingsMutationFn = Apollo.MutationFunction<RemoveTagFromSendingsMutation, RemoveTagFromSendingsMutationVariables>;

/**
 * __useRemoveTagFromSendingsMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromSendingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromSendingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromSendingsMutation, { data, loading, error }] = useRemoveTagFromSendingsMutation({
 *   variables: {
 *      sendingIds: // value for 'sendingIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTagFromSendingsMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTagFromSendingsMutation, RemoveTagFromSendingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTagFromSendingsMutation, RemoveTagFromSendingsMutationVariables>(RemoveTagFromSendingsDocument, options);
}
export type RemoveTagFromSendingsMutationHookResult = ReturnType<typeof useRemoveTagFromSendingsMutation>;
export type RemoveTagFromSendingsMutationResult = Apollo.MutationResult<RemoveTagFromSendingsMutation>;
export type RemoveTagFromSendingsMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromSendingsMutation, RemoveTagFromSendingsMutationVariables>;
export const SendSendingDocument = gql`
  mutation SendSending($sendingId: ID!) {
    sendSending(sendingId: $sendingId) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          collaborationId
          isOpened
          linksClicked
          sendingId
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          listId
          collaborationId
          sendingId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type SendSendingMutationFn = Apollo.MutationFunction<SendSendingMutation, SendSendingMutationVariables>;

/**
 * __useSendSendingMutation__
 *
 * To run a mutation, you first call `useSendSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSendingMutation, { data, loading, error }] = useSendSendingMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *   },
 * });
 */
export function useSendSendingMutation(baseOptions?: Apollo.MutationHookOptions<SendSendingMutation, SendSendingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendSendingMutation, SendSendingMutationVariables>(SendSendingDocument, options);
}
export type SendSendingMutationHookResult = ReturnType<typeof useSendSendingMutation>;
export type SendSendingMutationResult = Apollo.MutationResult<SendSendingMutation>;
export type SendSendingMutationOptions = Apollo.BaseMutationOptions<SendSendingMutation, SendSendingMutationVariables>;
export const ScheduleSendingDistributionDocument = gql`
  mutation ScheduleSendingDistribution($sendingId: ID!, $distributionDateTime: DateTime!) {
    scheduleSendingDistribution(sendingId: $sendingId, distributionDateTime: $distributionDateTime) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          listId
          collaborationId
          sendingId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type ScheduleSendingDistributionMutationFn = Apollo.MutationFunction<ScheduleSendingDistributionMutation, ScheduleSendingDistributionMutationVariables>;

/**
 * __useScheduleSendingDistributionMutation__
 *
 * To run a mutation, you first call `useScheduleSendingDistributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleSendingDistributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleSendingDistributionMutation, { data, loading, error }] = useScheduleSendingDistributionMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      distributionDateTime: // value for 'distributionDateTime'
 *   },
 * });
 */
export function useScheduleSendingDistributionMutation(
  baseOptions?: Apollo.MutationHookOptions<ScheduleSendingDistributionMutation, ScheduleSendingDistributionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ScheduleSendingDistributionMutation, ScheduleSendingDistributionMutationVariables>(ScheduleSendingDistributionDocument, options);
}
export type ScheduleSendingDistributionMutationHookResult = ReturnType<typeof useScheduleSendingDistributionMutation>;
export type ScheduleSendingDistributionMutationResult = Apollo.MutationResult<ScheduleSendingDistributionMutation>;
export type ScheduleSendingDistributionMutationOptions = Apollo.BaseMutationOptions<
  ScheduleSendingDistributionMutation,
  ScheduleSendingDistributionMutationVariables
>;
export const AddHarmfulContentReportDocument = gql`
  mutation AddHarmfulContentReport($message: String!) {
    addHarmfulContentReport(message: $message) {
      createdAt
      message
    }
  }
`;
export type AddHarmfulContentReportMutationFn = Apollo.MutationFunction<AddHarmfulContentReportMutation, AddHarmfulContentReportMutationVariables>;

/**
 * __useAddHarmfulContentReportMutation__
 *
 * To run a mutation, you first call `useAddHarmfulContentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHarmfulContentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHarmfulContentReportMutation, { data, loading, error }] = useAddHarmfulContentReportMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddHarmfulContentReportMutation(
  baseOptions?: Apollo.MutationHookOptions<AddHarmfulContentReportMutation, AddHarmfulContentReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddHarmfulContentReportMutation, AddHarmfulContentReportMutationVariables>(AddHarmfulContentReportDocument, options);
}
export type AddHarmfulContentReportMutationHookResult = ReturnType<typeof useAddHarmfulContentReportMutation>;
export type AddHarmfulContentReportMutationResult = Apollo.MutationResult<AddHarmfulContentReportMutation>;
export type AddHarmfulContentReportMutationOptions = Apollo.BaseMutationOptions<AddHarmfulContentReportMutation, AddHarmfulContentReportMutationVariables>;
export const AddAadContactsToSendingDocument = gql`
  mutation AddAadContactsToSending($sendingId: ID!, $input: [AadContactInput!]!) {
    addAadContactsToSending(sendingId: $sendingId, input: $input)
  }
`;
export type AddAadContactsToSendingMutationFn = Apollo.MutationFunction<AddAadContactsToSendingMutation, AddAadContactsToSendingMutationVariables>;

/**
 * __useAddAadContactsToSendingMutation__
 *
 * To run a mutation, you first call `useAddAadContactsToSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAadContactsToSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAadContactsToSendingMutation, { data, loading, error }] = useAddAadContactsToSendingMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAadContactsToSendingMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAadContactsToSendingMutation, AddAadContactsToSendingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAadContactsToSendingMutation, AddAadContactsToSendingMutationVariables>(AddAadContactsToSendingDocument, options);
}
export type AddAadContactsToSendingMutationHookResult = ReturnType<typeof useAddAadContactsToSendingMutation>;
export type AddAadContactsToSendingMutationResult = Apollo.MutationResult<AddAadContactsToSendingMutation>;
export type AddAadContactsToSendingMutationOptions = Apollo.BaseMutationOptions<AddAadContactsToSendingMutation, AddAadContactsToSendingMutationVariables>;
export const FetchSendersByUserAadIdDocument = gql`
  query FetchSendersByUserAadId {
    sendersBySharedWithUserAadObjectId {
      id
      emailAddress
      displayName
      userId
    }
  }
`;

/**
 * __useFetchSendersByUserAadIdQuery__
 *
 * To run a query within a React component, call `useFetchSendersByUserAadIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSendersByUserAadIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSendersByUserAadIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSendersByUserAadIdQuery(baseOptions?: Apollo.QueryHookOptions<FetchSendersByUserAadIdQuery, FetchSendersByUserAadIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSendersByUserAadIdQuery, FetchSendersByUserAadIdQueryVariables>(FetchSendersByUserAadIdDocument, options);
}
export function useFetchSendersByUserAadIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSendersByUserAadIdQuery, FetchSendersByUserAadIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSendersByUserAadIdQuery, FetchSendersByUserAadIdQueryVariables>(FetchSendersByUserAadIdDocument, options);
}
export type FetchSendersByUserAadIdQueryHookResult = ReturnType<typeof useFetchSendersByUserAadIdQuery>;
export type FetchSendersByUserAadIdLazyQueryHookResult = ReturnType<typeof useFetchSendersByUserAadIdLazyQuery>;
export type FetchSendersByUserAadIdQueryResult = Apollo.QueryResult<FetchSendersByUserAadIdQuery, FetchSendersByUserAadIdQueryVariables>;
export const IsOpenAiAddonEnabledDocument = gql`
  query IsOpenAiAddonEnabled {
    isOpenAiAddonEnabled
  }
`;

/**
 * __useIsOpenAiAddonEnabledQuery__
 *
 * To run a query within a React component, call `useIsOpenAiAddonEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOpenAiAddonEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOpenAiAddonEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsOpenAiAddonEnabledQuery(baseOptions?: Apollo.QueryHookOptions<IsOpenAiAddonEnabledQuery, IsOpenAiAddonEnabledQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsOpenAiAddonEnabledQuery, IsOpenAiAddonEnabledQueryVariables>(IsOpenAiAddonEnabledDocument, options);
}
export function useIsOpenAiAddonEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsOpenAiAddonEnabledQuery, IsOpenAiAddonEnabledQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsOpenAiAddonEnabledQuery, IsOpenAiAddonEnabledQueryVariables>(IsOpenAiAddonEnabledDocument, options);
}
export type IsOpenAiAddonEnabledQueryHookResult = ReturnType<typeof useIsOpenAiAddonEnabledQuery>;
export type IsOpenAiAddonEnabledLazyQueryHookResult = ReturnType<typeof useIsOpenAiAddonEnabledLazyQuery>;
export type IsOpenAiAddonEnabledQueryResult = Apollo.QueryResult<IsOpenAiAddonEnabledQuery, IsOpenAiAddonEnabledQueryVariables>;
export const FetchRecipientsBySendingIdAndContactNameDocument = gql`
  query FetchRecipientsBySendingIdAndContactName($sendingId: ID!, $contactName: String!, $skip: Int!, $take: Int!) {
    recipientsBySendingIdAndContactName(sendingId: $sendingId, contactName: $contactName, skip: $skip, take: $take) {
      activityParticipants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          emailAddress
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          sendingId
          emailAddress
          listId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useFetchRecipientsBySendingIdAndContactNameQuery__
 *
 * To run a query within a React component, call `useFetchRecipientsBySendingIdAndContactNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecipientsBySendingIdAndContactNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecipientsBySendingIdAndContactNameQuery({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      contactName: // value for 'contactName'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFetchRecipientsBySendingIdAndContactNameQuery(
  baseOptions: Apollo.QueryHookOptions<FetchRecipientsBySendingIdAndContactNameQuery, FetchRecipientsBySendingIdAndContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchRecipientsBySendingIdAndContactNameQuery, FetchRecipientsBySendingIdAndContactNameQueryVariables>(
    FetchRecipientsBySendingIdAndContactNameDocument,
    options
  );
}
export function useFetchRecipientsBySendingIdAndContactNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchRecipientsBySendingIdAndContactNameQuery, FetchRecipientsBySendingIdAndContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchRecipientsBySendingIdAndContactNameQuery, FetchRecipientsBySendingIdAndContactNameQueryVariables>(
    FetchRecipientsBySendingIdAndContactNameDocument,
    options
  );
}
export type FetchRecipientsBySendingIdAndContactNameQueryHookResult = ReturnType<typeof useFetchRecipientsBySendingIdAndContactNameQuery>;
export type FetchRecipientsBySendingIdAndContactNameLazyQueryHookResult = ReturnType<typeof useFetchRecipientsBySendingIdAndContactNameLazyQuery>;
export type FetchRecipientsBySendingIdAndContactNameQueryResult = Apollo.QueryResult<
  FetchRecipientsBySendingIdAndContactNameQuery,
  FetchRecipientsBySendingIdAndContactNameQueryVariables
>;
export const FetchContactsByNameOrEmailAddressDocument = gql`
  query FetchContactsByNameOrEmailAddress($searchText: String!) {
    contactsByNameOrEmailAddress(searchText: $searchText) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;

/**
 * __useFetchContactsByNameOrEmailAddressQuery__
 *
 * To run a query within a React component, call `useFetchContactsByNameOrEmailAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByNameOrEmailAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByNameOrEmailAddressQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useFetchContactsByNameOrEmailAddressQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsByNameOrEmailAddressQuery, FetchContactsByNameOrEmailAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByNameOrEmailAddressQuery, FetchContactsByNameOrEmailAddressQueryVariables>(
    FetchContactsByNameOrEmailAddressDocument,
    options
  );
}
export function useFetchContactsByNameOrEmailAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsByNameOrEmailAddressQuery, FetchContactsByNameOrEmailAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByNameOrEmailAddressQuery, FetchContactsByNameOrEmailAddressQueryVariables>(
    FetchContactsByNameOrEmailAddressDocument,
    options
  );
}
export type FetchContactsByNameOrEmailAddressQueryHookResult = ReturnType<typeof useFetchContactsByNameOrEmailAddressQuery>;
export type FetchContactsByNameOrEmailAddressLazyQueryHookResult = ReturnType<typeof useFetchContactsByNameOrEmailAddressLazyQuery>;
export type FetchContactsByNameOrEmailAddressQueryResult = Apollo.QueryResult<
  FetchContactsByNameOrEmailAddressQuery,
  FetchContactsByNameOrEmailAddressQueryVariables
>;
export const FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsDocument = gql`
  query FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipants($searchText: String!, $sendingId: ID!, $take: Int!) {
    contactsByNameOrEmailAddressAndNotContainedInSendingParticipants(searchText: $searchText, sendingId: $sendingId, take: $take) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;

/**
 * __useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery__
 *
 * To run a query within a React component, call `useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      sendingId: // value for 'sendingId'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery,
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery,
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryVariables
  >(FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsDocument, options);
}
export function useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery,
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery,
    FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryVariables
  >(FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsDocument, options);
}
export type FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryHookResult = ReturnType<
  typeof useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery
>;
export type FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsLazyQueryHookResult = ReturnType<
  typeof useFetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsLazyQuery
>;
export type FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryResult = Apollo.QueryResult<
  FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery,
  FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQueryVariables
>;
export const FetchSendingByIdDocument = gql`
  query fetchSendingById($sendingId: ID!) {
    contactSendingById(sendingId: $sendingId) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      sendingScheduledAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          sendingId
          listId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
      mailPreviewLink
    }
  }
`;

/**
 * __useFetchSendingByIdQuery__
 *
 * To run a query within a React component, call `useFetchSendingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSendingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSendingByIdQuery({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *   },
 * });
 */
export function useFetchSendingByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchSendingByIdQuery, FetchSendingByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSendingByIdQuery, FetchSendingByIdQueryVariables>(FetchSendingByIdDocument, options);
}
export function useFetchSendingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSendingByIdQuery, FetchSendingByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSendingByIdQuery, FetchSendingByIdQueryVariables>(FetchSendingByIdDocument, options);
}
export type FetchSendingByIdQueryHookResult = ReturnType<typeof useFetchSendingByIdQuery>;
export type FetchSendingByIdLazyQueryHookResult = ReturnType<typeof useFetchSendingByIdLazyQuery>;
export type FetchSendingByIdQueryResult = Apollo.QueryResult<FetchSendingByIdQuery, FetchSendingByIdQueryVariables>;
export const FetchSendingLogsBySendingIdDocument = gql`
  query fetchSendingLogsBySendingId($sendingId: ID!, $skip: Int!, $take: Int!) {
    sendingLogs(sendingId: $sendingId, skip: $skip, take: $take) {
      totalCount
      sendingLogs {
        userName
        subject
        creationDate
        description
      }
    }
  }
`;

/**
 * __useFetchSendingLogsBySendingIdQuery__
 *
 * To run a query within a React component, call `useFetchSendingLogsBySendingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSendingLogsBySendingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSendingLogsBySendingIdQuery({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFetchSendingLogsBySendingIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchSendingLogsBySendingIdQuery, FetchSendingLogsBySendingIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSendingLogsBySendingIdQuery, FetchSendingLogsBySendingIdQueryVariables>(FetchSendingLogsBySendingIdDocument, options);
}
export function useFetchSendingLogsBySendingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSendingLogsBySendingIdQuery, FetchSendingLogsBySendingIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSendingLogsBySendingIdQuery, FetchSendingLogsBySendingIdQueryVariables>(FetchSendingLogsBySendingIdDocument, options);
}
export type FetchSendingLogsBySendingIdQueryHookResult = ReturnType<typeof useFetchSendingLogsBySendingIdQuery>;
export type FetchSendingLogsBySendingIdLazyQueryHookResult = ReturnType<typeof useFetchSendingLogsBySendingIdLazyQuery>;
export type FetchSendingLogsBySendingIdQueryResult = Apollo.QueryResult<FetchSendingLogsBySendingIdQuery, FetchSendingLogsBySendingIdQueryVariables>;
export const FetchSendingsForEditorByQueryParamsDocument = gql`
  query FetchSendingsForEditorByQueryParams(
    $searchText: String!
    $filterItems: [ContactsFilterItemInput!]!
    $skip: Int!
    $take: Int!
    $sortingInput: ContactsSortingInput!
  ) {
    sendings(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take, sortingInput: $sortingInput) {
      totalCount
      sendings {
        id
        title
        status {
          name
          value
        }
        createdAt
        mailPreviewLink
      }
    }
  }
`;

/**
 * __useFetchSendingsForEditorByQueryParamsQuery__
 *
 * To run a query within a React component, call `useFetchSendingsForEditorByQueryParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSendingsForEditorByQueryParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSendingsForEditorByQueryParamsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortingInput: // value for 'sortingInput'
 *   },
 * });
 */
export function useFetchSendingsForEditorByQueryParamsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchSendingsForEditorByQueryParamsQuery, FetchSendingsForEditorByQueryParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSendingsForEditorByQueryParamsQuery, FetchSendingsForEditorByQueryParamsQueryVariables>(
    FetchSendingsForEditorByQueryParamsDocument,
    options
  );
}
export function useFetchSendingsForEditorByQueryParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSendingsForEditorByQueryParamsQuery, FetchSendingsForEditorByQueryParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSendingsForEditorByQueryParamsQuery, FetchSendingsForEditorByQueryParamsQueryVariables>(
    FetchSendingsForEditorByQueryParamsDocument,
    options
  );
}
export type FetchSendingsForEditorByQueryParamsQueryHookResult = ReturnType<typeof useFetchSendingsForEditorByQueryParamsQuery>;
export type FetchSendingsForEditorByQueryParamsLazyQueryHookResult = ReturnType<typeof useFetchSendingsForEditorByQueryParamsLazyQuery>;
export type FetchSendingsForEditorByQueryParamsQueryResult = Apollo.QueryResult<
  FetchSendingsForEditorByQueryParamsQuery,
  FetchSendingsForEditorByQueryParamsQueryVariables
>;
export const SendingAudienceBySendingIdDocument = gql`
  query sendingAudienceBySendingId($sendingId: ID!, $pageSize: Int!) {
    sendingAudienceById(sendingId: $sendingId, pageSize: $pageSize) {
      numberOfRecipients
      numberOfRecipientsWithoutEmailAddress
      namesOfFirstPageOfContacts
    }
  }
`;

/**
 * __useSendingAudienceBySendingIdQuery__
 *
 * To run a query within a React component, call `useSendingAudienceBySendingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingAudienceBySendingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingAudienceBySendingIdQuery({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSendingAudienceBySendingIdQuery(
  baseOptions: Apollo.QueryHookOptions<SendingAudienceBySendingIdQuery, SendingAudienceBySendingIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingAudienceBySendingIdQuery, SendingAudienceBySendingIdQueryVariables>(SendingAudienceBySendingIdDocument, options);
}
export function useSendingAudienceBySendingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingAudienceBySendingIdQuery, SendingAudienceBySendingIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingAudienceBySendingIdQuery, SendingAudienceBySendingIdQueryVariables>(SendingAudienceBySendingIdDocument, options);
}
export type SendingAudienceBySendingIdQueryHookResult = ReturnType<typeof useSendingAudienceBySendingIdQuery>;
export type SendingAudienceBySendingIdLazyQueryHookResult = ReturnType<typeof useSendingAudienceBySendingIdLazyQuery>;
export type SendingAudienceBySendingIdQueryResult = Apollo.QueryResult<SendingAudienceBySendingIdQuery, SendingAudienceBySendingIdQueryVariables>;
export const RemoveSendingDocument = gql`
  mutation RemoveSending($sendingId: ID!) {
    removeContactSending(sendingId: $sendingId) {
      id
    }
  }
`;
export type RemoveSendingMutationFn = Apollo.MutationFunction<RemoveSendingMutation, RemoveSendingMutationVariables>;

/**
 * __useRemoveSendingMutation__
 *
 * To run a mutation, you first call `useRemoveSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSendingMutation, { data, loading, error }] = useRemoveSendingMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *   },
 * });
 */
export function useRemoveSendingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSendingMutation, RemoveSendingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveSendingMutation, RemoveSendingMutationVariables>(RemoveSendingDocument, options);
}
export type RemoveSendingMutationHookResult = ReturnType<typeof useRemoveSendingMutation>;
export type RemoveSendingMutationResult = Apollo.MutationResult<RemoveSendingMutation>;
export type RemoveSendingMutationOptions = Apollo.BaseMutationOptions<RemoveSendingMutation, RemoveSendingMutationVariables>;
export const AddSendingDocument = gql`
  mutation AddSending($input: ContactsSendingInput!) {
    addContactSending(input: $input) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          sendingId
          listId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type AddSendingMutationFn = Apollo.MutationFunction<AddSendingMutation, AddSendingMutationVariables>;

/**
 * __useAddSendingMutation__
 *
 * To run a mutation, you first call `useAddSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSendingMutation, { data, loading, error }] = useAddSendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSendingMutation(baseOptions?: Apollo.MutationHookOptions<AddSendingMutation, AddSendingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSendingMutation, AddSendingMutationVariables>(AddSendingDocument, options);
}
export type AddSendingMutationHookResult = ReturnType<typeof useAddSendingMutation>;
export type AddSendingMutationResult = Apollo.MutationResult<AddSendingMutation>;
export type AddSendingMutationOptions = Apollo.BaseMutationOptions<AddSendingMutation, AddSendingMutationVariables>;
export const AddSendingWithRecipientsFromListsDocument = gql`
  mutation AddSendingWithRecipientsFromLists($input: ContactsSendingInput!, $listsIds: [ID!]!) {
    addSendingWithRecipientsFromLists(input: $input, listsIds: $listsIds) {
      id
      imageAsBase64
      isFavourite
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type AddSendingWithRecipientsFromListsMutationFn = Apollo.MutationFunction<
  AddSendingWithRecipientsFromListsMutation,
  AddSendingWithRecipientsFromListsMutationVariables
>;

/**
 * __useAddSendingWithRecipientsFromListsMutation__
 *
 * To run a mutation, you first call `useAddSendingWithRecipientsFromListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSendingWithRecipientsFromListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSendingWithRecipientsFromListsMutation, { data, loading, error }] = useAddSendingWithRecipientsFromListsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      listsIds: // value for 'listsIds'
 *   },
 * });
 */
export function useAddSendingWithRecipientsFromListsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSendingWithRecipientsFromListsMutation, AddSendingWithRecipientsFromListsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSendingWithRecipientsFromListsMutation, AddSendingWithRecipientsFromListsMutationVariables>(
    AddSendingWithRecipientsFromListsDocument,
    options
  );
}
export type AddSendingWithRecipientsFromListsMutationHookResult = ReturnType<typeof useAddSendingWithRecipientsFromListsMutation>;
export type AddSendingWithRecipientsFromListsMutationResult = Apollo.MutationResult<AddSendingWithRecipientsFromListsMutation>;
export type AddSendingWithRecipientsFromListsMutationOptions = Apollo.BaseMutationOptions<
  AddSendingWithRecipientsFromListsMutation,
  AddSendingWithRecipientsFromListsMutationVariables
>;
export const AddSendingWithContactsIdsDocument = gql`
  mutation AddSendingWithContactsIds($input: ContactsSendingInput!, $contactsIds: [ID!]!) {
    addSendingWithContactsIds(input: $input, contactsIds: $contactsIds) {
      id
      imageAsBase64
      isFavourite
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type AddSendingWithContactsIdsMutationFn = Apollo.MutationFunction<AddSendingWithContactsIdsMutation, AddSendingWithContactsIdsMutationVariables>;

/**
 * __useAddSendingWithContactsIdsMutation__
 *
 * To run a mutation, you first call `useAddSendingWithContactsIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSendingWithContactsIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSendingWithContactsIdsMutation, { data, loading, error }] = useAddSendingWithContactsIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactsIds: // value for 'contactsIds'
 *   },
 * });
 */
export function useAddSendingWithContactsIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSendingWithContactsIdsMutation, AddSendingWithContactsIdsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSendingWithContactsIdsMutation, AddSendingWithContactsIdsMutationVariables>(AddSendingWithContactsIdsDocument, options);
}
export type AddSendingWithContactsIdsMutationHookResult = ReturnType<typeof useAddSendingWithContactsIdsMutation>;
export type AddSendingWithContactsIdsMutationResult = Apollo.MutationResult<AddSendingWithContactsIdsMutation>;
export type AddSendingWithContactsIdsMutationOptions = Apollo.BaseMutationOptions<
  AddSendingWithContactsIdsMutation,
  AddSendingWithContactsIdsMutationVariables
>;
export const UpdateSendingDocument = gql`
  mutation UpdateSending($sendingId: ID!, $input: ContactsSendingInput!) {
    updateContactSending(sendingId: $sendingId, input: $input) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          sendingId
          listId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
      mailPreviewLink
    }
  }
`;
export type UpdateSendingMutationFn = Apollo.MutationFunction<UpdateSendingMutation, UpdateSendingMutationVariables>;

/**
 * __useUpdateSendingMutation__
 *
 * To run a mutation, you first call `useUpdateSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendingMutation, { data, loading, error }] = useUpdateSendingMutation({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSendingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSendingMutation, UpdateSendingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSendingMutation, UpdateSendingMutationVariables>(UpdateSendingDocument, options);
}
export type UpdateSendingMutationHookResult = ReturnType<typeof useUpdateSendingMutation>;
export type UpdateSendingMutationResult = Apollo.MutationResult<UpdateSendingMutation>;
export type UpdateSendingMutationOptions = Apollo.BaseMutationOptions<UpdateSendingMutation, UpdateSendingMutationVariables>;
export const UpdateSendingsDocument = gql`
  mutation UpdateSendings($input: [ContactsSendingInput!]!) {
    updateContactSendings(input: $input) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          sendingId
          listId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type UpdateSendingsMutationFn = Apollo.MutationFunction<UpdateSendingsMutation, UpdateSendingsMutationVariables>;

/**
 * __useUpdateSendingsMutation__
 *
 * To run a mutation, you first call `useUpdateSendingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendingsMutation, { data, loading, error }] = useUpdateSendingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSendingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSendingsMutation, UpdateSendingsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSendingsMutation, UpdateSendingsMutationVariables>(UpdateSendingsDocument, options);
}
export type UpdateSendingsMutationHookResult = ReturnType<typeof useUpdateSendingsMutation>;
export type UpdateSendingsMutationResult = Apollo.MutationResult<UpdateSendingsMutation>;
export type UpdateSendingsMutationOptions = Apollo.BaseMutationOptions<UpdateSendingsMutation, UpdateSendingsMutationVariables>;
export const AddSendingsDocument = gql`
  mutation AddSendings($input: [ContactsSendingInput!]!) {
    addContactSendings(input: $input) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      createdByUser {
        id
        firstName
        lastName
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          sendingId
          listId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type AddSendingsMutationFn = Apollo.MutationFunction<AddSendingsMutation, AddSendingsMutationVariables>;

/**
 * __useAddSendingsMutation__
 *
 * To run a mutation, you first call `useAddSendingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSendingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSendingsMutation, { data, loading, error }] = useAddSendingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSendingsMutation(baseOptions?: Apollo.MutationHookOptions<AddSendingsMutation, AddSendingsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSendingsMutation, AddSendingsMutationVariables>(AddSendingsDocument, options);
}
export type AddSendingsMutationHookResult = ReturnType<typeof useAddSendingsMutation>;
export type AddSendingsMutationResult = Apollo.MutationResult<AddSendingsMutation>;
export type AddSendingsMutationOptions = Apollo.BaseMutationOptions<AddSendingsMutation, AddSendingsMutationVariables>;
export const RemoveSendingsDocument = gql`
  mutation RemoveSendings($input: [String!]!) {
    removeContactSendings(input: $input)
  }
`;
export type RemoveSendingsMutationFn = Apollo.MutationFunction<RemoveSendingsMutation, RemoveSendingsMutationVariables>;

/**
 * __useRemoveSendingsMutation__
 *
 * To run a mutation, you first call `useRemoveSendingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSendingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSendingsMutation, { data, loading, error }] = useRemoveSendingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSendingsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSendingsMutation, RemoveSendingsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveSendingsMutation, RemoveSendingsMutationVariables>(RemoveSendingsDocument, options);
}
export type RemoveSendingsMutationHookResult = ReturnType<typeof useRemoveSendingsMutation>;
export type RemoveSendingsMutationResult = Apollo.MutationResult<RemoveSendingsMutation>;
export type RemoveSendingsMutationOptions = Apollo.BaseMutationOptions<RemoveSendingsMutation, RemoveSendingsMutationVariables>;
export const DuplicateSendingsDocument = gql`
  mutation DuplicateSendings($sendingsIds: [ID!]!, $localizedTitlePart: String!) {
    duplicateContactSendings(sendingsIds: $sendingsIds, localizedTitlePart: $localizedTitlePart) {
      id
      imageAsBase64
      isFavourite
      blacklist {
        id
        name
      }
      title
      subject
      htmlBody
      jsonBody
      originalHtmlBody
      mjmlBody
      status {
        name
        value
      }
      createdByUser {
        id
        firstName
        lastName
      }
      createdAt
      lastModifiedAt
      respondToEmailAddress
      lastModifiedByUser {
        id
        firstName
        lastName
        profilePictureUrl
      }
      sender {
        id
        displayName
        emailAddress
        userId
      }
      senderDisplayName
      senderEmailAddress
      deliveredAt
      numberOfRecipients
      distributionDuration
      participants {
        ... on Recipient {
          id
          sendingId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
        ... on RecipientFromList {
          id
          sendingId
          listId
          collaborationId
          isOpened
          linksClicked
          contact {
            id
            salutation
            firstName
            lastName
            profilePictureUrl
            isGloballySignedOut
            tags {
              id
              name
            }
            topics {
              topicId
              topicName
              dataSourceKey
            }
            mediaResorts {
              mediaResortId
              mediaResortName
              dataSourceKey
            }
            comments {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
              replies {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
              }
            }
            blacklists {
              id
              name
            }
            ownContactData {
              address
              country
              city
              postalCode
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
            }
            collaborations {
              id
              medium {
                id
                name
                type {
                  name
                  value
                }
              }
              jobDescription
              jobTitle {
                id
                name
                dataSourceKey
                mediumType {
                  name
                  value
                }
              }
              address
              country
              city
              fax
              landlinePhoneNumber {
                value
                isPrimary
              }
              mobilePhoneNumber {
                value
                isPrimary
              }
              emailAddress
              blogUrl
              websiteUrl
              twitterProfileUrl
              instagramProfileUrl
              linkedInProfileUrl
              youtubeProfileUrl
              facebookProfileUrl
              isPrimary
              postalCode
            }
          }
        }
      }
      tags {
        id
        name
      }
      campaign {
        name
      }
    }
  }
`;
export type DuplicateSendingsMutationFn = Apollo.MutationFunction<DuplicateSendingsMutation, DuplicateSendingsMutationVariables>;

/**
 * __useDuplicateSendingsMutation__
 *
 * To run a mutation, you first call `useDuplicateSendingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateSendingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateSendingsMutation, { data, loading, error }] = useDuplicateSendingsMutation({
 *   variables: {
 *      sendingsIds: // value for 'sendingsIds'
 *      localizedTitlePart: // value for 'localizedTitlePart'
 *   },
 * });
 */
export function useDuplicateSendingsMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateSendingsMutation, DuplicateSendingsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateSendingsMutation, DuplicateSendingsMutationVariables>(DuplicateSendingsDocument, options);
}
export type DuplicateSendingsMutationHookResult = ReturnType<typeof useDuplicateSendingsMutation>;
export type DuplicateSendingsMutationResult = Apollo.MutationResult<DuplicateSendingsMutation>;
export type DuplicateSendingsMutationOptions = Apollo.BaseMutationOptions<DuplicateSendingsMutation, DuplicateSendingsMutationVariables>;
export const FetchSendingsByQueryParamsDocument = gql`
  query fetchSendingsByQueryParams(
    $searchText: String!
    $filterItems: [ContactsFilterItemInput!]!
    $skip: Int!
    $take: Int!
    $sortingInput: ContactsSortingInput!
  ) {
    sendings(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take, sortingInput: $sortingInput) {
      totalCount
      sendings {
        id
        imageAsBase64
        isFavourite
        blacklist {
          id
          name
        }
        title
        subject
        htmlBody
        jsonBody
        originalHtmlBody
        mjmlBody
        status {
          name
          value
        }
        createdAt
        lastModifiedAt
        respondToEmailAddress
        createdByUser {
          id
          firstName
          lastName
          profilePictureUrl
        }
        lastModifiedByUser {
          id
          firstName
          lastName
          profilePictureUrl
        }
        sender {
          id
          displayName
          emailAddress
          userId
        }
        senderDisplayName
        senderEmailAddress
        deliveredAt
        numberOfRecipients
        distributionDuration
        participants {
          ... on Recipient {
            id
            sendingId
            collaborationId
            isOpened
            linksClicked
            contact {
              id
              salutation
              firstName
              lastName
              profilePictureUrl
              isGloballySignedOut
              tags {
                id
                name
              }
              topics {
                topicId
                topicName
                dataSourceKey
              }
              mediaResorts {
                mediaResortId
                mediaResortName
                dataSourceKey
              }
              comments {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
                replies {
                  id
                  author {
                    teamsId
                    name
                    aadObjectId
                  }
                  content
                  createdAt
                  mentions {
                    teamsId
                    name
                    aadObjectId
                  }
                }
              }
              blacklists {
                id
                name
              }
              ownContactData {
                address
                country
                city
                postalCode
                fax
                landlinePhoneNumber {
                  value
                  isPrimary
                }
                mobilePhoneNumber {
                  value
                  isPrimary
                }
                emailAddress
                blogUrl
                websiteUrl
                twitterProfileUrl
                instagramProfileUrl
                linkedInProfileUrl
                youtubeProfileUrl
                facebookProfileUrl
                isPrimary
              }
              collaborations {
                id
                medium {
                  id
                  name
                  type {
                    name
                    value
                  }
                }
                jobDescription
                jobTitle {
                  id
                  name
                  dataSourceKey
                  mediumType {
                    name
                    value
                  }
                }
                address
                country
                city
                fax
                landlinePhoneNumber {
                  value
                  isPrimary
                }
                mobilePhoneNumber {
                  value
                  isPrimary
                }
                emailAddress
                blogUrl
                websiteUrl
                twitterProfileUrl
                instagramProfileUrl
                linkedInProfileUrl
                youtubeProfileUrl
                facebookProfileUrl
                isPrimary
                postalCode
              }
            }
          }
          ... on RecipientFromList {
            id
            sendingId
            listId
            collaborationId
            isOpened
            linksClicked
            contact {
              id
              salutation
              firstName
              lastName
              profilePictureUrl
              isGloballySignedOut
              tags {
                id
                name
              }
              topics {
                topicId
                topicName
                dataSourceKey
              }
              mediaResorts {
                mediaResortId
                mediaResortName
                dataSourceKey
              }
              comments {
                id
                author {
                  teamsId
                  name
                  aadObjectId
                }
                content
                createdAt
                mentions {
                  teamsId
                  name
                  aadObjectId
                }
                replies {
                  id
                  author {
                    teamsId
                    name
                    aadObjectId
                  }
                  content
                  createdAt
                  mentions {
                    teamsId
                    name
                    aadObjectId
                  }
                }
              }
              blacklists {
                id
                name
              }
              ownContactData {
                address
                country
                city
                postalCode
                fax
                landlinePhoneNumber {
                  value
                  isPrimary
                }
                mobilePhoneNumber {
                  value
                  isPrimary
                }
                emailAddress
                blogUrl
                websiteUrl
                twitterProfileUrl
                instagramProfileUrl
                linkedInProfileUrl
                youtubeProfileUrl
                facebookProfileUrl
                isPrimary
              }
              collaborations {
                id
                medium {
                  id
                  name
                  type {
                    name
                    value
                  }
                }
                jobDescription
                jobTitle {
                  id
                  name
                  dataSourceKey
                  mediumType {
                    name
                    value
                  }
                }
                address
                country
                city
                fax
                landlinePhoneNumber {
                  value
                  isPrimary
                }
                mobilePhoneNumber {
                  value
                  isPrimary
                }
                emailAddress
                blogUrl
                websiteUrl
                twitterProfileUrl
                instagramProfileUrl
                linkedInProfileUrl
                youtubeProfileUrl
                facebookProfileUrl
                isPrimary
                postalCode
              }
            }
          }
        }
        tags {
          id
          name
        }
        campaign {
          name
        }
      }
    }
  }
`;

/**
 * __useFetchSendingsByQueryParamsQuery__
 *
 * To run a query within a React component, call `useFetchSendingsByQueryParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSendingsByQueryParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSendingsByQueryParamsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortingInput: // value for 'sortingInput'
 *   },
 * });
 */
export function useFetchSendingsByQueryParamsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchSendingsByQueryParamsQuery, FetchSendingsByQueryParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSendingsByQueryParamsQuery, FetchSendingsByQueryParamsQueryVariables>(FetchSendingsByQueryParamsDocument, options);
}
export function useFetchSendingsByQueryParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSendingsByQueryParamsQuery, FetchSendingsByQueryParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSendingsByQueryParamsQuery, FetchSendingsByQueryParamsQueryVariables>(FetchSendingsByQueryParamsDocument, options);
}
export type FetchSendingsByQueryParamsQueryHookResult = ReturnType<typeof useFetchSendingsByQueryParamsQuery>;
export type FetchSendingsByQueryParamsLazyQueryHookResult = ReturnType<typeof useFetchSendingsByQueryParamsLazyQuery>;
export type FetchSendingsByQueryParamsQueryResult = Apollo.QueryResult<FetchSendingsByQueryParamsQuery, FetchSendingsByQueryParamsQueryVariables>;
export const FetchUsersByIdsDocument = gql`
  query fetchUsersByIds($ids: [ID!]!) {
    usersByIds(ids: $ids) {
      id
    }
  }
`;

/**
 * __useFetchUsersByIdsQuery__
 *
 * To run a query within a React component, call `useFetchUsersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFetchUsersByIdsQuery(baseOptions: Apollo.QueryHookOptions<FetchUsersByIdsQuery, FetchUsersByIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchUsersByIdsQuery, FetchUsersByIdsQueryVariables>(FetchUsersByIdsDocument, options);
}
export function useFetchUsersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUsersByIdsQuery, FetchUsersByIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchUsersByIdsQuery, FetchUsersByIdsQueryVariables>(FetchUsersByIdsDocument, options);
}
export type FetchUsersByIdsQueryHookResult = ReturnType<typeof useFetchUsersByIdsQuery>;
export type FetchUsersByIdsLazyQueryHookResult = ReturnType<typeof useFetchUsersByIdsLazyQuery>;
export type FetchUsersByIdsQueryResult = Apollo.QueryResult<FetchUsersByIdsQuery, FetchUsersByIdsQueryVariables>;
export const SendingsTitleFilterSuggestionsDocument = gql`
  query sendingsTitleFilterSuggestions($title: String!, $take: Int!) {
    sendingsTitleFilterSuggestions(title: $title, take: $take)
  }
`;

/**
 * __useSendingsTitleFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useSendingsTitleFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingsTitleFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingsTitleFilterSuggestionsQuery({
 *   variables: {
 *      title: // value for 'title'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSendingsTitleFilterSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<SendingsTitleFilterSuggestionsQuery, SendingsTitleFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingsTitleFilterSuggestionsQuery, SendingsTitleFilterSuggestionsQueryVariables>(SendingsTitleFilterSuggestionsDocument, options);
}
export function useSendingsTitleFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingsTitleFilterSuggestionsQuery, SendingsTitleFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingsTitleFilterSuggestionsQuery, SendingsTitleFilterSuggestionsQueryVariables>(
    SendingsTitleFilterSuggestionsDocument,
    options
  );
}
export type SendingsTitleFilterSuggestionsQueryHookResult = ReturnType<typeof useSendingsTitleFilterSuggestionsQuery>;
export type SendingsTitleFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useSendingsTitleFilterSuggestionsLazyQuery>;
export type SendingsTitleFilterSuggestionsQueryResult = Apollo.QueryResult<SendingsTitleFilterSuggestionsQuery, SendingsTitleFilterSuggestionsQueryVariables>;
export const SendingsCreatedByUserFilterSuggestionsDocument = gql`
  query sendingsCreatedByUserFilterSuggestions($createdBy: String!, $take: Int!) {
    sendingsCreatedByUserFilterSuggestions(createdBy: $createdBy, take: $take)
  }
`;

/**
 * __useSendingsCreatedByUserFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useSendingsCreatedByUserFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingsCreatedByUserFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingsCreatedByUserFilterSuggestionsQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSendingsCreatedByUserFilterSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<SendingsCreatedByUserFilterSuggestionsQuery, SendingsCreatedByUserFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingsCreatedByUserFilterSuggestionsQuery, SendingsCreatedByUserFilterSuggestionsQueryVariables>(
    SendingsCreatedByUserFilterSuggestionsDocument,
    options
  );
}
export function useSendingsCreatedByUserFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingsCreatedByUserFilterSuggestionsQuery, SendingsCreatedByUserFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingsCreatedByUserFilterSuggestionsQuery, SendingsCreatedByUserFilterSuggestionsQueryVariables>(
    SendingsCreatedByUserFilterSuggestionsDocument,
    options
  );
}
export type SendingsCreatedByUserFilterSuggestionsQueryHookResult = ReturnType<typeof useSendingsCreatedByUserFilterSuggestionsQuery>;
export type SendingsCreatedByUserFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useSendingsCreatedByUserFilterSuggestionsLazyQuery>;
export type SendingsCreatedByUserFilterSuggestionsQueryResult = Apollo.QueryResult<
  SendingsCreatedByUserFilterSuggestionsQuery,
  SendingsCreatedByUserFilterSuggestionsQueryVariables
>;
export const SendingsFilterSuggestionsByCampaignNameDocument = gql`
  query sendingsFilterSuggestionsByCampaignName($campaignName: String!, $take: Int!) {
    sendingsFilterSuggestionsByCampaignName(campaignName: $campaignName, take: $take)
  }
`;

/**
 * __useSendingsFilterSuggestionsByCampaignNameQuery__
 *
 * To run a query within a React component, call `useSendingsFilterSuggestionsByCampaignNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingsFilterSuggestionsByCampaignNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingsFilterSuggestionsByCampaignNameQuery({
 *   variables: {
 *      campaignName: // value for 'campaignName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSendingsFilterSuggestionsByCampaignNameQuery(
  baseOptions: Apollo.QueryHookOptions<SendingsFilterSuggestionsByCampaignNameQuery, SendingsFilterSuggestionsByCampaignNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingsFilterSuggestionsByCampaignNameQuery, SendingsFilterSuggestionsByCampaignNameQueryVariables>(
    SendingsFilterSuggestionsByCampaignNameDocument,
    options
  );
}
export function useSendingsFilterSuggestionsByCampaignNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingsFilterSuggestionsByCampaignNameQuery, SendingsFilterSuggestionsByCampaignNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingsFilterSuggestionsByCampaignNameQuery, SendingsFilterSuggestionsByCampaignNameQueryVariables>(
    SendingsFilterSuggestionsByCampaignNameDocument,
    options
  );
}
export type SendingsFilterSuggestionsByCampaignNameQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByCampaignNameQuery>;
export type SendingsFilterSuggestionsByCampaignNameLazyQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByCampaignNameLazyQuery>;
export type SendingsFilterSuggestionsByCampaignNameQueryResult = Apollo.QueryResult<
  SendingsFilterSuggestionsByCampaignNameQuery,
  SendingsFilterSuggestionsByCampaignNameQueryVariables
>;
export const SendingsFilterSuggestionsByEmailSubjectDocument = gql`
  query sendingsFilterSuggestionsByEmailSubject($emailSubject: String!, $take: Int!) {
    sendingsFilterSuggestionsByEmailSubject(emailSubject: $emailSubject, take: $take)
  }
`;

/**
 * __useSendingsFilterSuggestionsByEmailSubjectQuery__
 *
 * To run a query within a React component, call `useSendingsFilterSuggestionsByEmailSubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingsFilterSuggestionsByEmailSubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingsFilterSuggestionsByEmailSubjectQuery({
 *   variables: {
 *      emailSubject: // value for 'emailSubject'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSendingsFilterSuggestionsByEmailSubjectQuery(
  baseOptions: Apollo.QueryHookOptions<SendingsFilterSuggestionsByEmailSubjectQuery, SendingsFilterSuggestionsByEmailSubjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingsFilterSuggestionsByEmailSubjectQuery, SendingsFilterSuggestionsByEmailSubjectQueryVariables>(
    SendingsFilterSuggestionsByEmailSubjectDocument,
    options
  );
}
export function useSendingsFilterSuggestionsByEmailSubjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingsFilterSuggestionsByEmailSubjectQuery, SendingsFilterSuggestionsByEmailSubjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingsFilterSuggestionsByEmailSubjectQuery, SendingsFilterSuggestionsByEmailSubjectQueryVariables>(
    SendingsFilterSuggestionsByEmailSubjectDocument,
    options
  );
}
export type SendingsFilterSuggestionsByEmailSubjectQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByEmailSubjectQuery>;
export type SendingsFilterSuggestionsByEmailSubjectLazyQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByEmailSubjectLazyQuery>;
export type SendingsFilterSuggestionsByEmailSubjectQueryResult = Apollo.QueryResult<
  SendingsFilterSuggestionsByEmailSubjectQuery,
  SendingsFilterSuggestionsByEmailSubjectQueryVariables
>;
export const SendingsFilterSuggestionsByTagNameDocument = gql`
  query sendingsFilterSuggestionsByTagName($tagName: String!, $take: Int!) {
    sendingsFilterSuggestionsByTagName(tagName: $tagName, take: $take)
  }
`;

/**
 * __useSendingsFilterSuggestionsByTagNameQuery__
 *
 * To run a query within a React component, call `useSendingsFilterSuggestionsByTagNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingsFilterSuggestionsByTagNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingsFilterSuggestionsByTagNameQuery({
 *   variables: {
 *      tagName: // value for 'tagName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSendingsFilterSuggestionsByTagNameQuery(
  baseOptions: Apollo.QueryHookOptions<SendingsFilterSuggestionsByTagNameQuery, SendingsFilterSuggestionsByTagNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingsFilterSuggestionsByTagNameQuery, SendingsFilterSuggestionsByTagNameQueryVariables>(
    SendingsFilterSuggestionsByTagNameDocument,
    options
  );
}
export function useSendingsFilterSuggestionsByTagNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingsFilterSuggestionsByTagNameQuery, SendingsFilterSuggestionsByTagNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingsFilterSuggestionsByTagNameQuery, SendingsFilterSuggestionsByTagNameQueryVariables>(
    SendingsFilterSuggestionsByTagNameDocument,
    options
  );
}
export type SendingsFilterSuggestionsByTagNameQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByTagNameQuery>;
export type SendingsFilterSuggestionsByTagNameLazyQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByTagNameLazyQuery>;
export type SendingsFilterSuggestionsByTagNameQueryResult = Apollo.QueryResult<
  SendingsFilterSuggestionsByTagNameQuery,
  SendingsFilterSuggestionsByTagNameQueryVariables
>;
export const SendingsFilterSuggestionsByRecipientMediumNameDocument = gql`
  query sendingsFilterSuggestionsByRecipientMediumName($recipientMediumName: String!, $take: Int!) {
    sendingsFilterSuggestionsByRecipientMediumName(recipientMediumName: $recipientMediumName, take: $take)
  }
`;

/**
 * __useSendingsFilterSuggestionsByRecipientMediumNameQuery__
 *
 * To run a query within a React component, call `useSendingsFilterSuggestionsByRecipientMediumNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingsFilterSuggestionsByRecipientMediumNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingsFilterSuggestionsByRecipientMediumNameQuery({
 *   variables: {
 *      recipientMediumName: // value for 'recipientMediumName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSendingsFilterSuggestionsByRecipientMediumNameQuery(
  baseOptions: Apollo.QueryHookOptions<SendingsFilterSuggestionsByRecipientMediumNameQuery, SendingsFilterSuggestionsByRecipientMediumNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingsFilterSuggestionsByRecipientMediumNameQuery, SendingsFilterSuggestionsByRecipientMediumNameQueryVariables>(
    SendingsFilterSuggestionsByRecipientMediumNameDocument,
    options
  );
}
export function useSendingsFilterSuggestionsByRecipientMediumNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingsFilterSuggestionsByRecipientMediumNameQuery, SendingsFilterSuggestionsByRecipientMediumNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingsFilterSuggestionsByRecipientMediumNameQuery, SendingsFilterSuggestionsByRecipientMediumNameQueryVariables>(
    SendingsFilterSuggestionsByRecipientMediumNameDocument,
    options
  );
}
export type SendingsFilterSuggestionsByRecipientMediumNameQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByRecipientMediumNameQuery>;
export type SendingsFilterSuggestionsByRecipientMediumNameLazyQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByRecipientMediumNameLazyQuery>;
export type SendingsFilterSuggestionsByRecipientMediumNameQueryResult = Apollo.QueryResult<
  SendingsFilterSuggestionsByRecipientMediumNameQuery,
  SendingsFilterSuggestionsByRecipientMediumNameQueryVariables
>;
export const SendingsFilterSuggestionsByRecipientEmailOrNameDocument = gql`
  query sendingsFilterSuggestionsByRecipientEmailOrName($recipientNameOrEmail: String!, $take: Int!) {
    sendingsFilterSuggestionsByRecipientEmailOrName(recipientNameOrEmail: $recipientNameOrEmail, take: $take)
  }
`;

/**
 * __useSendingsFilterSuggestionsByRecipientEmailOrNameQuery__
 *
 * To run a query within a React component, call `useSendingsFilterSuggestionsByRecipientEmailOrNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingsFilterSuggestionsByRecipientEmailOrNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingsFilterSuggestionsByRecipientEmailOrNameQuery({
 *   variables: {
 *      recipientNameOrEmail: // value for 'recipientNameOrEmail'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSendingsFilterSuggestionsByRecipientEmailOrNameQuery(
  baseOptions: Apollo.QueryHookOptions<SendingsFilterSuggestionsByRecipientEmailOrNameQuery, SendingsFilterSuggestionsByRecipientEmailOrNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingsFilterSuggestionsByRecipientEmailOrNameQuery, SendingsFilterSuggestionsByRecipientEmailOrNameQueryVariables>(
    SendingsFilterSuggestionsByRecipientEmailOrNameDocument,
    options
  );
}
export function useSendingsFilterSuggestionsByRecipientEmailOrNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingsFilterSuggestionsByRecipientEmailOrNameQuery, SendingsFilterSuggestionsByRecipientEmailOrNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingsFilterSuggestionsByRecipientEmailOrNameQuery, SendingsFilterSuggestionsByRecipientEmailOrNameQueryVariables>(
    SendingsFilterSuggestionsByRecipientEmailOrNameDocument,
    options
  );
}
export type SendingsFilterSuggestionsByRecipientEmailOrNameQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByRecipientEmailOrNameQuery>;
export type SendingsFilterSuggestionsByRecipientEmailOrNameLazyQueryHookResult = ReturnType<typeof useSendingsFilterSuggestionsByRecipientEmailOrNameLazyQuery>;
export type SendingsFilterSuggestionsByRecipientEmailOrNameQueryResult = Apollo.QueryResult<
  SendingsFilterSuggestionsByRecipientEmailOrNameQuery,
  SendingsFilterSuggestionsByRecipientEmailOrNameQueryVariables
>;
export const SendingOverviewBySendingIdDocument = gql`
  query sendingOverviewBySendingId($sendingId: ID!) {
    sendingOverviewBySendingId(sendingId: $sendingId) {
      sendingId
      sendingStatus
      numberOfDeliveredRecipients
      totalNumberOfRecipients
      totalNumberOfOpenings
      totalNumberOfClickedLinks
      totalNumberOfFailedDeliveries
      numberOfOpenedSendings
      numberOfUnsubscribedRecipients
      distributionDurationSeconds
      deliveredPercentage
      openingsRatePercentage
      clickRatePercentage
    }
  }
`;

/**
 * __useSendingOverviewBySendingIdQuery__
 *
 * To run a query within a React component, call `useSendingOverviewBySendingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendingOverviewBySendingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendingOverviewBySendingIdQuery({
 *   variables: {
 *      sendingId: // value for 'sendingId'
 *   },
 * });
 */
export function useSendingOverviewBySendingIdQuery(
  baseOptions: Apollo.QueryHookOptions<SendingOverviewBySendingIdQuery, SendingOverviewBySendingIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendingOverviewBySendingIdQuery, SendingOverviewBySendingIdQueryVariables>(SendingOverviewBySendingIdDocument, options);
}
export function useSendingOverviewBySendingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendingOverviewBySendingIdQuery, SendingOverviewBySendingIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendingOverviewBySendingIdQuery, SendingOverviewBySendingIdQueryVariables>(SendingOverviewBySendingIdDocument, options);
}
export type SendingOverviewBySendingIdQueryHookResult = ReturnType<typeof useSendingOverviewBySendingIdQuery>;
export type SendingOverviewBySendingIdLazyQueryHookResult = ReturnType<typeof useSendingOverviewBySendingIdLazyQuery>;
export type SendingOverviewBySendingIdQueryResult = Apollo.QueryResult<SendingOverviewBySendingIdQuery, SendingOverviewBySendingIdQueryVariables>;
export const AddTemplateDocument = gql`
  mutation AddTemplate($input: TemplateInput!) {
    addTemplate(input: $input) {
      id
      title
      jsonBody
      htmlBody
      imageAsBase64
      isPrebuilt
      createdAt
      createdBy
      lastModifiedAt
      lastModifiedBy
    }
  }
`;
export type AddTemplateMutationFn = Apollo.MutationFunction<AddTemplateMutation, AddTemplateMutationVariables>;

/**
 * __useAddTemplateMutation__
 *
 * To run a mutation, you first call `useAddTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMutation, { data, loading, error }] = useAddTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateMutation, AddTemplateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTemplateMutation, AddTemplateMutationVariables>(AddTemplateDocument, options);
}
export type AddTemplateMutationHookResult = ReturnType<typeof useAddTemplateMutation>;
export type AddTemplateMutationResult = Apollo.MutationResult<AddTemplateMutation>;
export type AddTemplateMutationOptions = Apollo.BaseMutationOptions<AddTemplateMutation, AddTemplateMutationVariables>;
export const RemoveTemplateDocument = gql`
  mutation RemoveTemplate($id: ID!) {
    removeTemplate(id: $id) {
      id
      title
      jsonBody
      htmlBody
      imageAsBase64
      isPrebuilt
      createdAt
      createdBy
      lastModifiedAt
      lastModifiedBy
    }
  }
`;
export type RemoveTemplateMutationFn = Apollo.MutationFunction<RemoveTemplateMutation, RemoveTemplateMutationVariables>;

/**
 * __useRemoveTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTemplateMutation, { data, loading, error }] = useRemoveTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTemplateMutation, RemoveTemplateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTemplateMutation, RemoveTemplateMutationVariables>(RemoveTemplateDocument, options);
}
export type RemoveTemplateMutationHookResult = ReturnType<typeof useRemoveTemplateMutation>;
export type RemoveTemplateMutationResult = Apollo.MutationResult<RemoveTemplateMutation>;
export type RemoveTemplateMutationOptions = Apollo.BaseMutationOptions<RemoveTemplateMutation, RemoveTemplateMutationVariables>;
export const UpdateTemplateDocument = gql`
  mutation UpdateTemplate($input: TemplateInput!) {
    updateTemplate(input: $input) {
      id
      title
      jsonBody
      htmlBody
      imageAsBase64
      isPrebuilt
      createdAt
      createdBy
      lastModifiedAt
      lastModifiedBy
    }
  }
`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
}
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const FetchTemplatesDocument = gql`
  query fetchTemplates($searchText: String!, $filterItems: [ContactsFilterItemInput!]!, $skip: Int!, $take: Int!) {
    templates(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take) {
      totalCount
      templates {
        id
        title
        jsonBody
        htmlBody
        imageAsBase64
        isPrebuilt
        createdAt
        createdBy
        lastModifiedAt
        lastModifiedBy
      }
    }
  }
`;

/**
 * __useFetchTemplatesQuery__
 *
 * To run a query within a React component, call `useFetchTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTemplatesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFetchTemplatesQuery(baseOptions: Apollo.QueryHookOptions<FetchTemplatesQuery, FetchTemplatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchTemplatesQuery, FetchTemplatesQueryVariables>(FetchTemplatesDocument, options);
}
export function useFetchTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchTemplatesQuery, FetchTemplatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchTemplatesQuery, FetchTemplatesQueryVariables>(FetchTemplatesDocument, options);
}
export type FetchTemplatesQueryHookResult = ReturnType<typeof useFetchTemplatesQuery>;
export type FetchTemplatesLazyQueryHookResult = ReturnType<typeof useFetchTemplatesLazyQuery>;
export type FetchTemplatesQueryResult = Apollo.QueryResult<FetchTemplatesQuery, FetchTemplatesQueryVariables>;
export const CreateAndAssignBlacklistDocument = gql`
  mutation CreateAndAssignBlacklist($input: BlacklistCreationInput!) {
    createAndAssignBlacklist(input: $input) {
      id
      name
    }
  }
`;
export type CreateAndAssignBlacklistMutationFn = Apollo.MutationFunction<CreateAndAssignBlacklistMutation, CreateAndAssignBlacklistMutationVariables>;

/**
 * __useCreateAndAssignBlacklistMutation__
 *
 * To run a mutation, you first call `useCreateAndAssignBlacklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndAssignBlacklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndAssignBlacklistMutation, { data, loading, error }] = useCreateAndAssignBlacklistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAndAssignBlacklistMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAndAssignBlacklistMutation, CreateAndAssignBlacklistMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAndAssignBlacklistMutation, CreateAndAssignBlacklistMutationVariables>(CreateAndAssignBlacklistDocument, options);
}
export type CreateAndAssignBlacklistMutationHookResult = ReturnType<typeof useCreateAndAssignBlacklistMutation>;
export type CreateAndAssignBlacklistMutationResult = Apollo.MutationResult<CreateAndAssignBlacklistMutation>;
export type CreateAndAssignBlacklistMutationOptions = Apollo.BaseMutationOptions<CreateAndAssignBlacklistMutation, CreateAndAssignBlacklistMutationVariables>;
export const FetchAllBlacklistsDocument = gql`
  query FetchAllBlacklists {
    blacklists {
      id
      name
    }
  }
`;

/**
 * __useFetchAllBlacklistsQuery__
 *
 * To run a query within a React component, call `useFetchAllBlacklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllBlacklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllBlacklistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllBlacklistsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllBlacklistsQuery, FetchAllBlacklistsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllBlacklistsQuery, FetchAllBlacklistsQueryVariables>(FetchAllBlacklistsDocument, options);
}
export function useFetchAllBlacklistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllBlacklistsQuery, FetchAllBlacklistsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllBlacklistsQuery, FetchAllBlacklistsQueryVariables>(FetchAllBlacklistsDocument, options);
}
export type FetchAllBlacklistsQueryHookResult = ReturnType<typeof useFetchAllBlacklistsQuery>;
export type FetchAllBlacklistsLazyQueryHookResult = ReturnType<typeof useFetchAllBlacklistsLazyQuery>;
export type FetchAllBlacklistsQueryResult = Apollo.QueryResult<FetchAllBlacklistsQuery, FetchAllBlacklistsQueryVariables>;
export const AddCompanySavedSearchDocument = gql`
  mutation AddCompanySavedSearch($savedCompanySearch: CompaniesSavedSearchInput!) {
    saveCompanySearch(input: $savedCompanySearch) {
      id
      searchText
      filterItems {
        fieldName
        value
      }
      name
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type AddCompanySavedSearchMutationFn = Apollo.MutationFunction<AddCompanySavedSearchMutation, AddCompanySavedSearchMutationVariables>;

/**
 * __useAddCompanySavedSearchMutation__
 *
 * To run a mutation, you first call `useAddCompanySavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanySavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanySavedSearchMutation, { data, loading, error }] = useAddCompanySavedSearchMutation({
 *   variables: {
 *      savedCompanySearch: // value for 'savedCompanySearch'
 *   },
 * });
 */
export function useAddCompanySavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCompanySavedSearchMutation, AddCompanySavedSearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompanySavedSearchMutation, AddCompanySavedSearchMutationVariables>(AddCompanySavedSearchDocument, options);
}
export type AddCompanySavedSearchMutationHookResult = ReturnType<typeof useAddCompanySavedSearchMutation>;
export type AddCompanySavedSearchMutationResult = Apollo.MutationResult<AddCompanySavedSearchMutation>;
export type AddCompanySavedSearchMutationOptions = Apollo.BaseMutationOptions<AddCompanySavedSearchMutation, AddCompanySavedSearchMutationVariables>;
export const UpdateCompanySavedSearchDocument = gql`
  mutation UpdateCompanySavedSearch($id: ID!, $savedCompanySearch: CompaniesSavedSearchInput!) {
    updateCompanySavedSearch(id: $id, input: $savedCompanySearch) {
      id
      name
      searchText
      filterItems {
        fieldName
        value
      }
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type UpdateCompanySavedSearchMutationFn = Apollo.MutationFunction<UpdateCompanySavedSearchMutation, UpdateCompanySavedSearchMutationVariables>;

/**
 * __useUpdateCompanySavedSearchMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySavedSearchMutation, { data, loading, error }] = useUpdateCompanySavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      savedCompanySearch: // value for 'savedCompanySearch'
 *   },
 * });
 */
export function useUpdateCompanySavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanySavedSearchMutation, UpdateCompanySavedSearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanySavedSearchMutation, UpdateCompanySavedSearchMutationVariables>(UpdateCompanySavedSearchDocument, options);
}
export type UpdateCompanySavedSearchMutationHookResult = ReturnType<typeof useUpdateCompanySavedSearchMutation>;
export type UpdateCompanySavedSearchMutationResult = Apollo.MutationResult<UpdateCompanySavedSearchMutation>;
export type UpdateCompanySavedSearchMutationOptions = Apollo.BaseMutationOptions<UpdateCompanySavedSearchMutation, UpdateCompanySavedSearchMutationVariables>;
export const UpdateCompanySavedSearchesDocument = gql`
  mutation UpdateCompanySavedSearches($savedCompanySearches: [CompaniesSavedSearchWithIdInput!]!) {
    updateCompanySavedSearches(input: $savedCompanySearches) {
      id
      name
      priority
      searchText
      filterItems {
        fieldName
        value
      }
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type UpdateCompanySavedSearchesMutationFn = Apollo.MutationFunction<UpdateCompanySavedSearchesMutation, UpdateCompanySavedSearchesMutationVariables>;

/**
 * __useUpdateCompanySavedSearchesMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySavedSearchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySavedSearchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySavedSearchesMutation, { data, loading, error }] = useUpdateCompanySavedSearchesMutation({
 *   variables: {
 *      savedCompanySearches: // value for 'savedCompanySearches'
 *   },
 * });
 */
export function useUpdateCompanySavedSearchesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanySavedSearchesMutation, UpdateCompanySavedSearchesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanySavedSearchesMutation, UpdateCompanySavedSearchesMutationVariables>(UpdateCompanySavedSearchesDocument, options);
}
export type UpdateCompanySavedSearchesMutationHookResult = ReturnType<typeof useUpdateCompanySavedSearchesMutation>;
export type UpdateCompanySavedSearchesMutationResult = Apollo.MutationResult<UpdateCompanySavedSearchesMutation>;
export type UpdateCompanySavedSearchesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanySavedSearchesMutation,
  UpdateCompanySavedSearchesMutationVariables
>;
export const RemoveCompanySavedSearchDocument = gql`
  mutation RemoveCompanySavedSearch($id: ID!) {
    removeCompanySavedSearch(id: $id) {
      id
      name
      searchText
      filterItems {
        fieldName
        value
      }
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type RemoveCompanySavedSearchMutationFn = Apollo.MutationFunction<RemoveCompanySavedSearchMutation, RemoveCompanySavedSearchMutationVariables>;

/**
 * __useRemoveCompanySavedSearchMutation__
 *
 * To run a mutation, you first call `useRemoveCompanySavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanySavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanySavedSearchMutation, { data, loading, error }] = useRemoveCompanySavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanySavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCompanySavedSearchMutation, RemoveCompanySavedSearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCompanySavedSearchMutation, RemoveCompanySavedSearchMutationVariables>(RemoveCompanySavedSearchDocument, options);
}
export type RemoveCompanySavedSearchMutationHookResult = ReturnType<typeof useRemoveCompanySavedSearchMutation>;
export type RemoveCompanySavedSearchMutationResult = Apollo.MutationResult<RemoveCompanySavedSearchMutation>;
export type RemoveCompanySavedSearchMutationOptions = Apollo.BaseMutationOptions<RemoveCompanySavedSearchMutation, RemoveCompanySavedSearchMutationVariables>;
export const FetchCompaniesSavedSearchesPagedDocument = gql`
  query FetchCompaniesSavedSearchesPaged($searchText: String!, $filterItems: [CompaniesFilterItemInput!]!, $take: Int!, $skip: Int!) {
    companiesSavedSearches(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take) {
      totalCount
      savedSearches {
        id
        name
        searchText
        priority
        count
        filterItems {
          fieldName
          value
        }
        createdAt
        lastModifiedAt
        createdBy
      }
    }
  }
`;

/**
 * __useFetchCompaniesSavedSearchesPagedQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesSavedSearchesPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesSavedSearchesPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesSavedSearchesPagedQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFetchCompaniesSavedSearchesPagedQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompaniesSavedSearchesPagedQuery, FetchCompaniesSavedSearchesPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompaniesSavedSearchesPagedQuery, FetchCompaniesSavedSearchesPagedQueryVariables>(
    FetchCompaniesSavedSearchesPagedDocument,
    options
  );
}
export function useFetchCompaniesSavedSearchesPagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompaniesSavedSearchesPagedQuery, FetchCompaniesSavedSearchesPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCompaniesSavedSearchesPagedQuery, FetchCompaniesSavedSearchesPagedQueryVariables>(
    FetchCompaniesSavedSearchesPagedDocument,
    options
  );
}
export type FetchCompaniesSavedSearchesPagedQueryHookResult = ReturnType<typeof useFetchCompaniesSavedSearchesPagedQuery>;
export type FetchCompaniesSavedSearchesPagedLazyQueryHookResult = ReturnType<typeof useFetchCompaniesSavedSearchesPagedLazyQuery>;
export type FetchCompaniesSavedSearchesPagedQueryResult = Apollo.QueryResult<
  FetchCompaniesSavedSearchesPagedQuery,
  FetchCompaniesSavedSearchesPagedQueryVariables
>;
export const FetchCompaniesAvatarDetailsDocument = gql`
  query FetchCompaniesAvatarDetails($companiesAvatarDetails: [CompaniesAvatarDetailsInput!]!) {
    companiesAvatarDetails(companiesAvatarDetailsInput: $companiesAvatarDetails) {
      savedSearchId
      pageOfCompanies {
        totalCount
        companies {
          id
          name
          profilePictureUrl
        }
      }
    }
  }
`;

/**
 * __useFetchCompaniesAvatarDetailsQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesAvatarDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesAvatarDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesAvatarDetailsQuery({
 *   variables: {
 *      companiesAvatarDetails: // value for 'companiesAvatarDetails'
 *   },
 * });
 */
export function useFetchCompaniesAvatarDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompaniesAvatarDetailsQuery, FetchCompaniesAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompaniesAvatarDetailsQuery, FetchCompaniesAvatarDetailsQueryVariables>(FetchCompaniesAvatarDetailsDocument, options);
}
export function useFetchCompaniesAvatarDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompaniesAvatarDetailsQuery, FetchCompaniesAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCompaniesAvatarDetailsQuery, FetchCompaniesAvatarDetailsQueryVariables>(FetchCompaniesAvatarDetailsDocument, options);
}
export type FetchCompaniesAvatarDetailsQueryHookResult = ReturnType<typeof useFetchCompaniesAvatarDetailsQuery>;
export type FetchCompaniesAvatarDetailsLazyQueryHookResult = ReturnType<typeof useFetchCompaniesAvatarDetailsLazyQuery>;
export type FetchCompaniesAvatarDetailsQueryResult = Apollo.QueryResult<FetchCompaniesAvatarDetailsQuery, FetchCompaniesAvatarDetailsQueryVariables>;
export const AddCompanyDocument = gql`
  mutation AddCompany($company: CompanyInput!) {
    addCompany(input: $company) {
      id
      name
      profilePictureUrl
      databaseType
      registrationNumber
      tags {
        id
        name
      }
      category {
        id
        name
      }
      parentCompany
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      comments
      companyContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      director
      competitors
      foundationDate
      profit
      description
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;
export type AddCompanyMutationFn = Apollo.MutationFunction<AddCompanyMutation, AddCompanyMutationVariables>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useAddCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyMutation, AddCompanyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(AddCompanyDocument, options);
}
export type AddCompanyMutationHookResult = ReturnType<typeof useAddCompanyMutation>;
export type AddCompanyMutationResult = Apollo.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<AddCompanyMutation, AddCompanyMutationVariables>;
export const TransformCompanyIntoMediaOutletDocument = gql`
  mutation TransformCompanyIntoMediaOutlet($id: ID!) {
    transformCompanyIntoMediaOutlet(id: $id) {
      id
    }
  }
`;
export type TransformCompanyIntoMediaOutletMutationFn = Apollo.MutationFunction<
  TransformCompanyIntoMediaOutletMutation,
  TransformCompanyIntoMediaOutletMutationVariables
>;

/**
 * __useTransformCompanyIntoMediaOutletMutation__
 *
 * To run a mutation, you first call `useTransformCompanyIntoMediaOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransformCompanyIntoMediaOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transformCompanyIntoMediaOutletMutation, { data, loading, error }] = useTransformCompanyIntoMediaOutletMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransformCompanyIntoMediaOutletMutation(
  baseOptions?: Apollo.MutationHookOptions<TransformCompanyIntoMediaOutletMutation, TransformCompanyIntoMediaOutletMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransformCompanyIntoMediaOutletMutation, TransformCompanyIntoMediaOutletMutationVariables>(
    TransformCompanyIntoMediaOutletDocument,
    options
  );
}
export type TransformCompanyIntoMediaOutletMutationHookResult = ReturnType<typeof useTransformCompanyIntoMediaOutletMutation>;
export type TransformCompanyIntoMediaOutletMutationResult = Apollo.MutationResult<TransformCompanyIntoMediaOutletMutation>;
export type TransformCompanyIntoMediaOutletMutationOptions = Apollo.BaseMutationOptions<
  TransformCompanyIntoMediaOutletMutation,
  TransformCompanyIntoMediaOutletMutationVariables
>;
export const RemoveCompanyDocument = gql`
  mutation RemoveCompany($id: ID!) {
    removeCompany(id: $id) {
      id
    }
  }
`;
export type RemoveCompanyMutationFn = Apollo.MutationFunction<RemoveCompanyMutation, RemoveCompanyMutationVariables>;

/**
 * __useRemoveCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMutation, { data, loading, error }] = useRemoveCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyMutation, RemoveCompanyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCompanyMutation, RemoveCompanyMutationVariables>(RemoveCompanyDocument, options);
}
export type RemoveCompanyMutationHookResult = ReturnType<typeof useRemoveCompanyMutation>;
export type RemoveCompanyMutationResult = Apollo.MutationResult<RemoveCompanyMutation>;
export type RemoveCompanyMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyMutation, RemoveCompanyMutationVariables>;
export const RemoveCompaniesDocument = gql`
  mutation RemoveCompanies($ids: [ID!]!) {
    removeCompanies(ids: $ids) {
      id
      companyName
      name
    }
  }
`;
export type RemoveCompaniesMutationFn = Apollo.MutationFunction<RemoveCompaniesMutation, RemoveCompaniesMutationVariables>;

/**
 * __useRemoveCompaniesMutation__
 *
 * To run a mutation, you first call `useRemoveCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompaniesMutation, { data, loading, error }] = useRemoveCompaniesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompaniesMutation, RemoveCompaniesMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCompaniesMutation, RemoveCompaniesMutationVariables>(RemoveCompaniesDocument, options);
}
export type RemoveCompaniesMutationHookResult = ReturnType<typeof useRemoveCompaniesMutation>;
export type RemoveCompaniesMutationResult = Apollo.MutationResult<RemoveCompaniesMutation>;
export type RemoveCompaniesMutationOptions = Apollo.BaseMutationOptions<RemoveCompaniesMutation, RemoveCompaniesMutationVariables>;
export const FetchCompanyByIdDocument = gql`
  query FetchCompanyById($id: ID!) {
    company(id: $id) {
      id
      name
      dataSourceKey
      profilePictureUrl
      databaseType
      registrationNumber
      tags {
        id
        name
      }
      category {
        id
        name
      }
      parentCompany
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      comments
      companyContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      director
      competitors
      foundationDate
      profit
      description
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchCompanyByIdQuery__
 *
 * To run a query within a React component, call `useFetchCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>(FetchCompanyByIdDocument, options);
}
export function useFetchCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>(FetchCompanyByIdDocument, options);
}
export type FetchCompanyByIdQueryHookResult = ReturnType<typeof useFetchCompanyByIdQuery>;
export type FetchCompanyByIdLazyQueryHookResult = ReturnType<typeof useFetchCompanyByIdLazyQuery>;
export type FetchCompanyByIdQueryResult = Apollo.QueryResult<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>;
export const FetchCompaniesByContactIdDocument = gql`
  query FetchCompaniesByContactId($contactId: ID!) {
    companiesByContactId(contactId: $contactId) {
      id
      name
      dataSourceKey
      profilePictureUrl
      databaseType
      registrationNumber
      tags {
        id
        name
      }
      category {
        id
        name
      }
      parentCompany
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      comments
      companyContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      director
      competitors
      foundationDate
      profit
      description
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchCompaniesByContactIdQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesByContactIdQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchCompaniesByContactIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompaniesByContactIdQuery, FetchCompaniesByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompaniesByContactIdQuery, FetchCompaniesByContactIdQueryVariables>(FetchCompaniesByContactIdDocument, options);
}
export function useFetchCompaniesByContactIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompaniesByContactIdQuery, FetchCompaniesByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCompaniesByContactIdQuery, FetchCompaniesByContactIdQueryVariables>(FetchCompaniesByContactIdDocument, options);
}
export type FetchCompaniesByContactIdQueryHookResult = ReturnType<typeof useFetchCompaniesByContactIdQuery>;
export type FetchCompaniesByContactIdLazyQueryHookResult = ReturnType<typeof useFetchCompaniesByContactIdLazyQuery>;
export type FetchCompaniesByContactIdQueryResult = Apollo.QueryResult<FetchCompaniesByContactIdQuery, FetchCompaniesByContactIdQueryVariables>;
export const FetchCompaniesSearchSuggestionsDocument = gql`
  query FetchCompaniesSearchSuggestions($searchText: String!) {
    companySearchSuggestions(searchText: $searchText) {
      searchCategoryName {
        name
        value
      }
      filterItemFieldName
      suggestionsList {
        text
        imageUrl
      }
    }
  }
`;

/**
 * __useFetchCompaniesSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesSearchSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useFetchCompaniesSearchSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompaniesSearchSuggestionsQuery, FetchCompaniesSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompaniesSearchSuggestionsQuery, FetchCompaniesSearchSuggestionsQueryVariables>(FetchCompaniesSearchSuggestionsDocument, options);
}
export function useFetchCompaniesSearchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompaniesSearchSuggestionsQuery, FetchCompaniesSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCompaniesSearchSuggestionsQuery, FetchCompaniesSearchSuggestionsQueryVariables>(
    FetchCompaniesSearchSuggestionsDocument,
    options
  );
}
export type FetchCompaniesSearchSuggestionsQueryHookResult = ReturnType<typeof useFetchCompaniesSearchSuggestionsQuery>;
export type FetchCompaniesSearchSuggestionsLazyQueryHookResult = ReturnType<typeof useFetchCompaniesSearchSuggestionsLazyQuery>;
export type FetchCompaniesSearchSuggestionsQueryResult = Apollo.QueryResult<
  FetchCompaniesSearchSuggestionsQuery,
  FetchCompaniesSearchSuggestionsQueryVariables
>;
export const FetchCompaniesByQueryParamsPagedDocument = gql`
  query FetchCompaniesByQueryParamsPaged(
    $searchText: String!
    $filterItems: [CompaniesFilterItemInput!]!
    $skip: Int!
    $take: Int!
    $sortingInput: CompaniesSortingInput!
  ) {
    companies(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take, sortingInput: $sortingInput) {
      totalCount
      companies {
        id
        name
        dataSourceKey
        profilePictureUrl
        databaseType
        registrationNumber
        tags {
          id
          name
        }
        category {
          id
          name
        }
        parentCompany
        contactData {
          street
          poBox
          postalCode
          country
          city
          websiteUrl
          emailAddress
          landlinePhoneNumber
          fax
          alternativeFax
        }
        comments
        companyContacts {
          id
          firstName
          lastName
          profilePictureUrl
        }
        director
        competitors
        foundationDate
        profit
        description
        userComments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchCompaniesByQueryParamsPagedQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesByQueryParamsPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesByQueryParamsPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesByQueryParamsPagedQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortingInput: // value for 'sortingInput'
 *   },
 * });
 */
export function useFetchCompaniesByQueryParamsPagedQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompaniesByQueryParamsPagedQuery, FetchCompaniesByQueryParamsPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompaniesByQueryParamsPagedQuery, FetchCompaniesByQueryParamsPagedQueryVariables>(
    FetchCompaniesByQueryParamsPagedDocument,
    options
  );
}
export function useFetchCompaniesByQueryParamsPagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompaniesByQueryParamsPagedQuery, FetchCompaniesByQueryParamsPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCompaniesByQueryParamsPagedQuery, FetchCompaniesByQueryParamsPagedQueryVariables>(
    FetchCompaniesByQueryParamsPagedDocument,
    options
  );
}
export type FetchCompaniesByQueryParamsPagedQueryHookResult = ReturnType<typeof useFetchCompaniesByQueryParamsPagedQuery>;
export type FetchCompaniesByQueryParamsPagedLazyQueryHookResult = ReturnType<typeof useFetchCompaniesByQueryParamsPagedLazyQuery>;
export type FetchCompaniesByQueryParamsPagedQueryResult = Apollo.QueryResult<
  FetchCompaniesByQueryParamsPagedQuery,
  FetchCompaniesByQueryParamsPagedQueryVariables
>;
export const CompanySuggestionsByNameDocument = gql`
  query companySuggestionsByName($name: String!, $take: Int!) {
    companySuggestionsByName(name: $name, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByNameQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByNameQuery(baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByNameQuery, CompanySuggestionsByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByNameQuery, CompanySuggestionsByNameQueryVariables>(CompanySuggestionsByNameDocument, options);
}
export function useCompanySuggestionsByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByNameQuery, CompanySuggestionsByNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByNameQuery, CompanySuggestionsByNameQueryVariables>(CompanySuggestionsByNameDocument, options);
}
export type CompanySuggestionsByNameQueryHookResult = ReturnType<typeof useCompanySuggestionsByNameQuery>;
export type CompanySuggestionsByNameLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByNameLazyQuery>;
export type CompanySuggestionsByNameQueryResult = Apollo.QueryResult<CompanySuggestionsByNameQuery, CompanySuggestionsByNameQueryVariables>;
export const CompanySuggestionsByContactNameDocument = gql`
  query companySuggestionsByContactName($contactName: String!, $take: Int!) {
    companySuggestionsByContactName(contactName: $contactName, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByContactNameQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByContactNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByContactNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByContactNameQuery({
 *   variables: {
 *      contactName: // value for 'contactName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByContactNameQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByContactNameQuery, CompanySuggestionsByContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByContactNameQuery, CompanySuggestionsByContactNameQueryVariables>(CompanySuggestionsByContactNameDocument, options);
}
export function useCompanySuggestionsByContactNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByContactNameQuery, CompanySuggestionsByContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByContactNameQuery, CompanySuggestionsByContactNameQueryVariables>(
    CompanySuggestionsByContactNameDocument,
    options
  );
}
export type CompanySuggestionsByContactNameQueryHookResult = ReturnType<typeof useCompanySuggestionsByContactNameQuery>;
export type CompanySuggestionsByContactNameLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByContactNameLazyQuery>;
export type CompanySuggestionsByContactNameQueryResult = Apollo.QueryResult<
  CompanySuggestionsByContactNameQuery,
  CompanySuggestionsByContactNameQueryVariables
>;
export const CompanySuggestionsByTagNameDocument = gql`
  query companySuggestionsByTagName($tagName: String!, $take: Int!) {
    companySuggestionsByTagName(tagName: $tagName, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByTagNameQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByTagNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByTagNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByTagNameQuery({
 *   variables: {
 *      tagName: // value for 'tagName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByTagNameQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByTagNameQuery, CompanySuggestionsByTagNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByTagNameQuery, CompanySuggestionsByTagNameQueryVariables>(CompanySuggestionsByTagNameDocument, options);
}
export function useCompanySuggestionsByTagNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByTagNameQuery, CompanySuggestionsByTagNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByTagNameQuery, CompanySuggestionsByTagNameQueryVariables>(CompanySuggestionsByTagNameDocument, options);
}
export type CompanySuggestionsByTagNameQueryHookResult = ReturnType<typeof useCompanySuggestionsByTagNameQuery>;
export type CompanySuggestionsByTagNameLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByTagNameLazyQuery>;
export type CompanySuggestionsByTagNameQueryResult = Apollo.QueryResult<CompanySuggestionsByTagNameQuery, CompanySuggestionsByTagNameQueryVariables>;
export const CompanySuggestionsByCountryDocument = gql`
  query companySuggestionsByCountry($country: String!, $take: Int!) {
    companySuggestionsByCountry(country: $country, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByCountryQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByCountryQuery({
 *   variables: {
 *      country: // value for 'country'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByCountryQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByCountryQuery, CompanySuggestionsByCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByCountryQuery, CompanySuggestionsByCountryQueryVariables>(CompanySuggestionsByCountryDocument, options);
}
export function useCompanySuggestionsByCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByCountryQuery, CompanySuggestionsByCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByCountryQuery, CompanySuggestionsByCountryQueryVariables>(CompanySuggestionsByCountryDocument, options);
}
export type CompanySuggestionsByCountryQueryHookResult = ReturnType<typeof useCompanySuggestionsByCountryQuery>;
export type CompanySuggestionsByCountryLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByCountryLazyQuery>;
export type CompanySuggestionsByCountryQueryResult = Apollo.QueryResult<CompanySuggestionsByCountryQuery, CompanySuggestionsByCountryQueryVariables>;
export const CompanySuggestionsByCityDocument = gql`
  query companySuggestionsByCity($city: String!, $take: Int!) {
    companySuggestionsByCity(city: $city, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByCityQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByCityQuery({
 *   variables: {
 *      city: // value for 'city'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByCityQuery(baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByCityQuery, CompanySuggestionsByCityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByCityQuery, CompanySuggestionsByCityQueryVariables>(CompanySuggestionsByCityDocument, options);
}
export function useCompanySuggestionsByCityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByCityQuery, CompanySuggestionsByCityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByCityQuery, CompanySuggestionsByCityQueryVariables>(CompanySuggestionsByCityDocument, options);
}
export type CompanySuggestionsByCityQueryHookResult = ReturnType<typeof useCompanySuggestionsByCityQuery>;
export type CompanySuggestionsByCityLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByCityLazyQuery>;
export type CompanySuggestionsByCityQueryResult = Apollo.QueryResult<CompanySuggestionsByCityQuery, CompanySuggestionsByCityQueryVariables>;
export const CompanySuggestionsByPostCodeDocument = gql`
  query companySuggestionsByPostCode($postCode: String!, $take: Int!) {
    companySuggestionsByPostCode(postCode: $postCode, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByPostCodeQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByPostCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByPostCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByPostCodeQuery({
 *   variables: {
 *      postCode: // value for 'postCode'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByPostCodeQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByPostCodeQuery, CompanySuggestionsByPostCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByPostCodeQuery, CompanySuggestionsByPostCodeQueryVariables>(CompanySuggestionsByPostCodeDocument, options);
}
export function useCompanySuggestionsByPostCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByPostCodeQuery, CompanySuggestionsByPostCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByPostCodeQuery, CompanySuggestionsByPostCodeQueryVariables>(CompanySuggestionsByPostCodeDocument, options);
}
export type CompanySuggestionsByPostCodeQueryHookResult = ReturnType<typeof useCompanySuggestionsByPostCodeQuery>;
export type CompanySuggestionsByPostCodeLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByPostCodeLazyQuery>;
export type CompanySuggestionsByPostCodeQueryResult = Apollo.QueryResult<CompanySuggestionsByPostCodeQuery, CompanySuggestionsByPostCodeQueryVariables>;
export const CompanySuggestionsByRegistrationNumberDocument = gql`
  query companySuggestionsByRegistrationNumber($registrationNumber: String!, $take: Int!) {
    companySuggestionsByRegistrationNumber(registrationNumber: $registrationNumber, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByRegistrationNumberQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByRegistrationNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByRegistrationNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByRegistrationNumberQuery({
 *   variables: {
 *      registrationNumber: // value for 'registrationNumber'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByRegistrationNumberQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByRegistrationNumberQuery, CompanySuggestionsByRegistrationNumberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByRegistrationNumberQuery, CompanySuggestionsByRegistrationNumberQueryVariables>(
    CompanySuggestionsByRegistrationNumberDocument,
    options
  );
}
export function useCompanySuggestionsByRegistrationNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByRegistrationNumberQuery, CompanySuggestionsByRegistrationNumberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByRegistrationNumberQuery, CompanySuggestionsByRegistrationNumberQueryVariables>(
    CompanySuggestionsByRegistrationNumberDocument,
    options
  );
}
export type CompanySuggestionsByRegistrationNumberQueryHookResult = ReturnType<typeof useCompanySuggestionsByRegistrationNumberQuery>;
export type CompanySuggestionsByRegistrationNumberLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByRegistrationNumberLazyQuery>;
export type CompanySuggestionsByRegistrationNumberQueryResult = Apollo.QueryResult<
  CompanySuggestionsByRegistrationNumberQuery,
  CompanySuggestionsByRegistrationNumberQueryVariables
>;
export const CompanySuggestionsByDatabaseTypeDocument = gql`
  query companySuggestionsByDatabaseType($databaseType: String!, $take: Int!) {
    companySuggestionsByDatabaseType(databaseType: $databaseType, take: $take)
  }
`;

/**
 * __useCompanySuggestionsByDatabaseTypeQuery__
 *
 * To run a query within a React component, call `useCompanySuggestionsByDatabaseTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuggestionsByDatabaseTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuggestionsByDatabaseTypeQuery({
 *   variables: {
 *      databaseType: // value for 'databaseType'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanySuggestionsByDatabaseTypeQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySuggestionsByDatabaseTypeQuery, CompanySuggestionsByDatabaseTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuggestionsByDatabaseTypeQuery, CompanySuggestionsByDatabaseTypeQueryVariables>(
    CompanySuggestionsByDatabaseTypeDocument,
    options
  );
}
export function useCompanySuggestionsByDatabaseTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySuggestionsByDatabaseTypeQuery, CompanySuggestionsByDatabaseTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySuggestionsByDatabaseTypeQuery, CompanySuggestionsByDatabaseTypeQueryVariables>(
    CompanySuggestionsByDatabaseTypeDocument,
    options
  );
}
export type CompanySuggestionsByDatabaseTypeQueryHookResult = ReturnType<typeof useCompanySuggestionsByDatabaseTypeQuery>;
export type CompanySuggestionsByDatabaseTypeLazyQueryHookResult = ReturnType<typeof useCompanySuggestionsByDatabaseTypeLazyQuery>;
export type CompanySuggestionsByDatabaseTypeQueryResult = Apollo.QueryResult<
  CompanySuggestionsByDatabaseTypeQuery,
  CompanySuggestionsByDatabaseTypeQueryVariables
>;
export const AddTagToCompaniesDocument = gql`
  mutation AddTagToCompanies($companyIds: [ID!]!, $input: CompaniesTagInput!) {
    addTagToCompanies(companyIds: $companyIds, input: $input) {
      id
      name
    }
  }
`;
export type AddTagToCompaniesMutationFn = Apollo.MutationFunction<AddTagToCompaniesMutation, AddTagToCompaniesMutationVariables>;

/**
 * __useAddTagToCompaniesMutation__
 *
 * To run a mutation, you first call `useAddTagToCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToCompaniesMutation, { data, loading, error }] = useAddTagToCompaniesMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTagToCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToCompaniesMutation, AddTagToCompaniesMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTagToCompaniesMutation, AddTagToCompaniesMutationVariables>(AddTagToCompaniesDocument, options);
}
export type AddTagToCompaniesMutationHookResult = ReturnType<typeof useAddTagToCompaniesMutation>;
export type AddTagToCompaniesMutationResult = Apollo.MutationResult<AddTagToCompaniesMutation>;
export type AddTagToCompaniesMutationOptions = Apollo.BaseMutationOptions<AddTagToCompaniesMutation, AddTagToCompaniesMutationVariables>;
export const RemoveTagFromCompaniesDocument = gql`
  mutation RemoveTagFromCompanies($companyIds: [ID!]!, $input: CompaniesTagInput!) {
    removeTagFromCompanies(companyIds: $companyIds, input: $input) {
      id
      name
    }
  }
`;
export type RemoveTagFromCompaniesMutationFn = Apollo.MutationFunction<RemoveTagFromCompaniesMutation, RemoveTagFromCompaniesMutationVariables>;

/**
 * __useRemoveTagFromCompaniesMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromCompaniesMutation, { data, loading, error }] = useRemoveTagFromCompaniesMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTagFromCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTagFromCompaniesMutation, RemoveTagFromCompaniesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTagFromCompaniesMutation, RemoveTagFromCompaniesMutationVariables>(RemoveTagFromCompaniesDocument, options);
}
export type RemoveTagFromCompaniesMutationHookResult = ReturnType<typeof useRemoveTagFromCompaniesMutation>;
export type RemoveTagFromCompaniesMutationResult = Apollo.MutationResult<RemoveTagFromCompaniesMutation>;
export type RemoveTagFromCompaniesMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromCompaniesMutation, RemoveTagFromCompaniesMutationVariables>;
export const UpdateCompanyProfilePictureDocument = gql`
  mutation UpdateCompanyProfilePicture($companyId: ID!, $input: CompaniesProfilePictureInput!) {
    updateCompanyProfilePicture(companyId: $companyId, input: $input)
  }
`;
export type UpdateCompanyProfilePictureMutationFn = Apollo.MutationFunction<UpdateCompanyProfilePictureMutation, UpdateCompanyProfilePictureMutationVariables>;

/**
 * __useUpdateCompanyProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyProfilePictureMutation, { data, loading, error }] = useUpdateCompanyProfilePictureMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyProfilePictureMutation, UpdateCompanyProfilePictureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyProfilePictureMutation, UpdateCompanyProfilePictureMutationVariables>(UpdateCompanyProfilePictureDocument, options);
}
export type UpdateCompanyProfilePictureMutationHookResult = ReturnType<typeof useUpdateCompanyProfilePictureMutation>;
export type UpdateCompanyProfilePictureMutationResult = Apollo.MutationResult<UpdateCompanyProfilePictureMutation>;
export type UpdateCompanyProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyProfilePictureMutation,
  UpdateCompanyProfilePictureMutationVariables
>;
export const RemoveCompanyProfilePictureDocument = gql`
  mutation RemoveCompanyProfilePicture($companyId: ID!) {
    removeCompanyProfilePicture(companyId: $companyId)
  }
`;
export type RemoveCompanyProfilePictureMutationFn = Apollo.MutationFunction<RemoveCompanyProfilePictureMutation, RemoveCompanyProfilePictureMutationVariables>;

/**
 * __useRemoveCompanyProfilePictureMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyProfilePictureMutation, { data, loading, error }] = useRemoveCompanyProfilePictureMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRemoveCompanyProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCompanyProfilePictureMutation, RemoveCompanyProfilePictureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCompanyProfilePictureMutation, RemoveCompanyProfilePictureMutationVariables>(RemoveCompanyProfilePictureDocument, options);
}
export type RemoveCompanyProfilePictureMutationHookResult = ReturnType<typeof useRemoveCompanyProfilePictureMutation>;
export type RemoveCompanyProfilePictureMutationResult = Apollo.MutationResult<RemoveCompanyProfilePictureMutation>;
export type RemoveCompanyProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  RemoveCompanyProfilePictureMutation,
  RemoveCompanyProfilePictureMutationVariables
>;
export const AddCommentToCompanyDocument = gql`
  mutation AddCommentToCompany($companyId: ID!, $input: CompaniesCommentInput!) {
    addCommentToCompany(companyId: $companyId, input: $input) {
      id
      author {
        teamsId
        name
        aadObjectId
      }
      content
      createdAt
      mentions {
        teamsId
        name
        aadObjectId
      }
      replies {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
      }
    }
  }
`;
export type AddCommentToCompanyMutationFn = Apollo.MutationFunction<AddCommentToCompanyMutation, AddCommentToCompanyMutationVariables>;

/**
 * __useAddCommentToCompanyMutation__
 *
 * To run a mutation, you first call `useAddCommentToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentToCompanyMutation, { data, loading, error }] = useAddCommentToCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentToCompanyMutation, AddCommentToCompanyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCommentToCompanyMutation, AddCommentToCompanyMutationVariables>(AddCommentToCompanyDocument, options);
}
export type AddCommentToCompanyMutationHookResult = ReturnType<typeof useAddCommentToCompanyMutation>;
export type AddCommentToCompanyMutationResult = Apollo.MutationResult<AddCommentToCompanyMutation>;
export type AddCommentToCompanyMutationOptions = Apollo.BaseMutationOptions<AddCommentToCompanyMutation, AddCommentToCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($id: ID!, $input: CompanyInput!) {
    updateCompany(id: $id, input: $input) {
      id
      name
      profilePictureUrl
      databaseType
      registrationNumber
      tags {
        id
        name
      }
      category {
        id
        name
      }
      parentCompany
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      comments
      companyContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      director
      competitors
      foundationDate
      profit
      description
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const FetchContactsByCompanyIdNameAndRoleDocument = gql`
  query FetchContactsByCompanyIdNameAndRole($skip: Int!, $take: Int!, $name: String!, $role: String!, $mediumId: ID!) {
    contactsByMediumIdNameAndRole(skip: $skip, take: $take, name: $name, role: $role, mediumId: $mediumId) {
      totalCount
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        isGloballySignedOut
        tags {
          id
          name
        }
        topics {
          topicId
          topicName
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        comments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
        blacklists {
          id
          name
        }
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsByCompanyIdNameAndRoleQuery__
 *
 * To run a query within a React component, call `useFetchContactsByCompanyIdNameAndRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByCompanyIdNameAndRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByCompanyIdNameAndRoleQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      mediumId: // value for 'mediumId'
 *   },
 * });
 */
export function useFetchContactsByCompanyIdNameAndRoleQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsByCompanyIdNameAndRoleQuery, FetchContactsByCompanyIdNameAndRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByCompanyIdNameAndRoleQuery, FetchContactsByCompanyIdNameAndRoleQueryVariables>(
    FetchContactsByCompanyIdNameAndRoleDocument,
    options
  );
}
export function useFetchContactsByCompanyIdNameAndRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsByCompanyIdNameAndRoleQuery, FetchContactsByCompanyIdNameAndRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByCompanyIdNameAndRoleQuery, FetchContactsByCompanyIdNameAndRoleQueryVariables>(
    FetchContactsByCompanyIdNameAndRoleDocument,
    options
  );
}
export type FetchContactsByCompanyIdNameAndRoleQueryHookResult = ReturnType<typeof useFetchContactsByCompanyIdNameAndRoleQuery>;
export type FetchContactsByCompanyIdNameAndRoleLazyQueryHookResult = ReturnType<typeof useFetchContactsByCompanyIdNameAndRoleLazyQuery>;
export type FetchContactsByCompanyIdNameAndRoleQueryResult = Apollo.QueryResult<
  FetchContactsByCompanyIdNameAndRoleQuery,
  FetchContactsByCompanyIdNameAndRoleQueryVariables
>;
export const FetchContactsByNameAndRoleAndNotContainedInCompanyDocument = gql`
  query FetchContactsByNameAndRoleAndNotContainedInCompany($skip: Int!, $take: Int!, $name: String!, $role: String!, $mediumId: ID!) {
    contactsByNameAndRoleAndNotContainedInMedium(skip: $skip, take: $take, name: $name, role: $role, mediumId: $mediumId) {
      totalCount
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        isGloballySignedOut
        tags {
          id
          name
        }
        topics {
          topicId
          topicName
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        comments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
        blacklists {
          id
          name
        }
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsByNameAndRoleAndNotContainedInCompanyQuery__
 *
 * To run a query within a React component, call `useFetchContactsByNameAndRoleAndNotContainedInCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByNameAndRoleAndNotContainedInCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByNameAndRoleAndNotContainedInCompanyQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      mediumId: // value for 'mediumId'
 *   },
 * });
 */
export function useFetchContactsByNameAndRoleAndNotContainedInCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchContactsByNameAndRoleAndNotContainedInCompanyQuery,
    FetchContactsByNameAndRoleAndNotContainedInCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByNameAndRoleAndNotContainedInCompanyQuery, FetchContactsByNameAndRoleAndNotContainedInCompanyQueryVariables>(
    FetchContactsByNameAndRoleAndNotContainedInCompanyDocument,
    options
  );
}
export function useFetchContactsByNameAndRoleAndNotContainedInCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchContactsByNameAndRoleAndNotContainedInCompanyQuery,
    FetchContactsByNameAndRoleAndNotContainedInCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByNameAndRoleAndNotContainedInCompanyQuery, FetchContactsByNameAndRoleAndNotContainedInCompanyQueryVariables>(
    FetchContactsByNameAndRoleAndNotContainedInCompanyDocument,
    options
  );
}
export type FetchContactsByNameAndRoleAndNotContainedInCompanyQueryHookResult = ReturnType<typeof useFetchContactsByNameAndRoleAndNotContainedInCompanyQuery>;
export type FetchContactsByNameAndRoleAndNotContainedInCompanyLazyQueryHookResult = ReturnType<
  typeof useFetchContactsByNameAndRoleAndNotContainedInCompanyLazyQuery
>;
export type FetchContactsByNameAndRoleAndNotContainedInCompanyQueryResult = Apollo.QueryResult<
  FetchContactsByNameAndRoleAndNotContainedInCompanyQuery,
  FetchContactsByNameAndRoleAndNotContainedInCompanyQueryVariables
>;
export const FetchCompanyCategoriesDocument = gql`
  query FetchCompanyCategories {
    companyCategories {
      id
      name
    }
  }
`;

/**
 * __useFetchCompanyCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchCompanyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompanyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompanyCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCompanyCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<FetchCompanyCategoriesQuery, FetchCompanyCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompanyCategoriesQuery, FetchCompanyCategoriesQueryVariables>(FetchCompanyCategoriesDocument, options);
}
export function useFetchCompanyCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompanyCategoriesQuery, FetchCompanyCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchCompanyCategoriesQuery, FetchCompanyCategoriesQueryVariables>(FetchCompanyCategoriesDocument, options);
}
export type FetchCompanyCategoriesQueryHookResult = ReturnType<typeof useFetchCompanyCategoriesQuery>;
export type FetchCompanyCategoriesLazyQueryHookResult = ReturnType<typeof useFetchCompanyCategoriesLazyQuery>;
export type FetchCompanyCategoriesQueryResult = Apollo.QueryResult<FetchCompanyCategoriesQuery, FetchCompanyCategoriesQueryVariables>;
export const AddTagToContactsDocument = gql`
  mutation AddTagToContacts($contactIds: [ID!]!, $input: ContactsTagInput!) {
    addTagToContacts(contactIds: $contactIds, input: $input) {
      id
      name
    }
  }
`;
export type AddTagToContactsMutationFn = Apollo.MutationFunction<AddTagToContactsMutation, AddTagToContactsMutationVariables>;

/**
 * __useAddTagToContactsMutation__
 *
 * To run a mutation, you first call `useAddTagToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToContactsMutation, { data, loading, error }] = useAddTagToContactsMutation({
 *   variables: {
 *      contactIds: // value for 'contactIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTagToContactsMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToContactsMutation, AddTagToContactsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTagToContactsMutation, AddTagToContactsMutationVariables>(AddTagToContactsDocument, options);
}
export type AddTagToContactsMutationHookResult = ReturnType<typeof useAddTagToContactsMutation>;
export type AddTagToContactsMutationResult = Apollo.MutationResult<AddTagToContactsMutation>;
export type AddTagToContactsMutationOptions = Apollo.BaseMutationOptions<AddTagToContactsMutation, AddTagToContactsMutationVariables>;
export const RemoveTagFromContactsDocument = gql`
  mutation RemoveTagFromContacts($contactIds: [ID!]!, $input: ContactsTagInput!) {
    removeTagFromContacts(contactIds: $contactIds, input: $input) {
      id
      name
    }
  }
`;
export type RemoveTagFromContactsMutationFn = Apollo.MutationFunction<RemoveTagFromContactsMutation, RemoveTagFromContactsMutationVariables>;

/**
 * __useRemoveTagFromContactsMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromContactsMutation, { data, loading, error }] = useRemoveTagFromContactsMutation({
 *   variables: {
 *      contactIds: // value for 'contactIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTagFromContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTagFromContactsMutation, RemoveTagFromContactsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTagFromContactsMutation, RemoveTagFromContactsMutationVariables>(RemoveTagFromContactsDocument, options);
}
export type RemoveTagFromContactsMutationHookResult = ReturnType<typeof useRemoveTagFromContactsMutation>;
export type RemoveTagFromContactsMutationResult = Apollo.MutationResult<RemoveTagFromContactsMutation>;
export type RemoveTagFromContactsMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromContactsMutation, RemoveTagFromContactsMutationVariables>;
export const AddTopicToContactDocument = gql`
  mutation AddTopicToContact($contactId: ID!, $topicId: ID!) {
    addTopicToContact(contactId: $contactId, topicId: $topicId) {
      id
      name
      dataSourceKey
    }
  }
`;
export type AddTopicToContactMutationFn = Apollo.MutationFunction<AddTopicToContactMutation, AddTopicToContactMutationVariables>;

/**
 * __useAddTopicToContactMutation__
 *
 * To run a mutation, you first call `useAddTopicToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTopicToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTopicToContactMutation, { data, loading, error }] = useAddTopicToContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useAddTopicToContactMutation(baseOptions?: Apollo.MutationHookOptions<AddTopicToContactMutation, AddTopicToContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTopicToContactMutation, AddTopicToContactMutationVariables>(AddTopicToContactDocument, options);
}
export type AddTopicToContactMutationHookResult = ReturnType<typeof useAddTopicToContactMutation>;
export type AddTopicToContactMutationResult = Apollo.MutationResult<AddTopicToContactMutation>;
export type AddTopicToContactMutationOptions = Apollo.BaseMutationOptions<AddTopicToContactMutation, AddTopicToContactMutationVariables>;
export const RemoveTopicFromContactDocument = gql`
  mutation RemoveTopicFromContact($contactId: ID!, $topicId: ID!) {
    removeTopicFromContact(contactId: $contactId, topicId: $topicId) {
      id
      name
      dataSourceKey
    }
  }
`;
export type RemoveTopicFromContactMutationFn = Apollo.MutationFunction<RemoveTopicFromContactMutation, RemoveTopicFromContactMutationVariables>;

/**
 * __useRemoveTopicFromContactMutation__
 *
 * To run a mutation, you first call `useRemoveTopicFromContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTopicFromContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTopicFromContactMutation, { data, loading, error }] = useRemoveTopicFromContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useRemoveTopicFromContactMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTopicFromContactMutation, RemoveTopicFromContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTopicFromContactMutation, RemoveTopicFromContactMutationVariables>(RemoveTopicFromContactDocument, options);
}
export type RemoveTopicFromContactMutationHookResult = ReturnType<typeof useRemoveTopicFromContactMutation>;
export type RemoveTopicFromContactMutationResult = Apollo.MutationResult<RemoveTopicFromContactMutation>;
export type RemoveTopicFromContactMutationOptions = Apollo.BaseMutationOptions<RemoveTopicFromContactMutation, RemoveTopicFromContactMutationVariables>;
export const AddMediaResortToContactDocument = gql`
  mutation AddMediaResortToContact($contactId: ID!, $mediaResortId: ID!) {
    addMediaResortToContact(contactId: $contactId, mediaResortId: $mediaResortId) {
      id
      name
      dataSourceKey
    }
  }
`;
export type AddMediaResortToContactMutationFn = Apollo.MutationFunction<AddMediaResortToContactMutation, AddMediaResortToContactMutationVariables>;

/**
 * __useAddMediaResortToContactMutation__
 *
 * To run a mutation, you first call `useAddMediaResortToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaResortToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaResortToContactMutation, { data, loading, error }] = useAddMediaResortToContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      mediaResortId: // value for 'mediaResortId'
 *   },
 * });
 */
export function useAddMediaResortToContactMutation(
  baseOptions?: Apollo.MutationHookOptions<AddMediaResortToContactMutation, AddMediaResortToContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMediaResortToContactMutation, AddMediaResortToContactMutationVariables>(AddMediaResortToContactDocument, options);
}
export type AddMediaResortToContactMutationHookResult = ReturnType<typeof useAddMediaResortToContactMutation>;
export type AddMediaResortToContactMutationResult = Apollo.MutationResult<AddMediaResortToContactMutation>;
export type AddMediaResortToContactMutationOptions = Apollo.BaseMutationOptions<AddMediaResortToContactMutation, AddMediaResortToContactMutationVariables>;
export const RemoveMediaResortFromContactDocument = gql`
  mutation RemoveMediaResortFromContact($contactId: ID!, $mediaResortId: ID!) {
    removeMediaResortFromContact(contactId: $contactId, mediaResortId: $mediaResortId) {
      id
      name
      dataSourceKey
    }
  }
`;
export type RemoveMediaResortFromContactMutationFn = Apollo.MutationFunction<
  RemoveMediaResortFromContactMutation,
  RemoveMediaResortFromContactMutationVariables
>;

/**
 * __useRemoveMediaResortFromContactMutation__
 *
 * To run a mutation, you first call `useRemoveMediaResortFromContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaResortFromContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaResortFromContactMutation, { data, loading, error }] = useRemoveMediaResortFromContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      mediaResortId: // value for 'mediaResortId'
 *   },
 * });
 */
export function useRemoveMediaResortFromContactMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMediaResortFromContactMutation, RemoveMediaResortFromContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediaResortFromContactMutation, RemoveMediaResortFromContactMutationVariables>(RemoveMediaResortFromContactDocument, options);
}
export type RemoveMediaResortFromContactMutationHookResult = ReturnType<typeof useRemoveMediaResortFromContactMutation>;
export type RemoveMediaResortFromContactMutationResult = Apollo.MutationResult<RemoveMediaResortFromContactMutation>;
export type RemoveMediaResortFromContactMutationOptions = Apollo.BaseMutationOptions<
  RemoveMediaResortFromContactMutation,
  RemoveMediaResortFromContactMutationVariables
>;
export const AddListToContactDocument = gql`
  mutation AddListToContact($contactId: ID!, $listId: ID!) {
    addListToContact(contactId: $contactId, listId: $listId) {
      id
      name
    }
  }
`;
export type AddListToContactMutationFn = Apollo.MutationFunction<AddListToContactMutation, AddListToContactMutationVariables>;

/**
 * __useAddListToContactMutation__
 *
 * To run a mutation, you first call `useAddListToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddListToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addListToContactMutation, { data, loading, error }] = useAddListToContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useAddListToContactMutation(baseOptions?: Apollo.MutationHookOptions<AddListToContactMutation, AddListToContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddListToContactMutation, AddListToContactMutationVariables>(AddListToContactDocument, options);
}
export type AddListToContactMutationHookResult = ReturnType<typeof useAddListToContactMutation>;
export type AddListToContactMutationResult = Apollo.MutationResult<AddListToContactMutation>;
export type AddListToContactMutationOptions = Apollo.BaseMutationOptions<AddListToContactMutation, AddListToContactMutationVariables>;
export const RemoveListFromContactDocument = gql`
  mutation RemoveListFromContact($contactId: ID!, $listId: ID!) {
    removeListFromContact(contactId: $contactId, listId: $listId) {
      id
      name
    }
  }
`;
export type RemoveListFromContactMutationFn = Apollo.MutationFunction<RemoveListFromContactMutation, RemoveListFromContactMutationVariables>;

/**
 * __useRemoveListFromContactMutation__
 *
 * To run a mutation, you first call `useRemoveListFromContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveListFromContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeListFromContactMutation, { data, loading, error }] = useRemoveListFromContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRemoveListFromContactMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveListFromContactMutation, RemoveListFromContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveListFromContactMutation, RemoveListFromContactMutationVariables>(RemoveListFromContactDocument, options);
}
export type RemoveListFromContactMutationHookResult = ReturnType<typeof useRemoveListFromContactMutation>;
export type RemoveListFromContactMutationResult = Apollo.MutationResult<RemoveListFromContactMutation>;
export type RemoveListFromContactMutationOptions = Apollo.BaseMutationOptions<RemoveListFromContactMutation, RemoveListFromContactMutationVariables>;
export const AddBlacklistToContactDocument = gql`
  mutation AddBlacklistToContact($contactId: ID!, $blacklist: BlacklistInput!) {
    addContactToBlacklist(contactId: $contactId, blacklist: $blacklist) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;
export type AddBlacklistToContactMutationFn = Apollo.MutationFunction<AddBlacklistToContactMutation, AddBlacklistToContactMutationVariables>;

/**
 * __useAddBlacklistToContactMutation__
 *
 * To run a mutation, you first call `useAddBlacklistToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBlacklistToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBlacklistToContactMutation, { data, loading, error }] = useAddBlacklistToContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      blacklist: // value for 'blacklist'
 *   },
 * });
 */
export function useAddBlacklistToContactMutation(
  baseOptions?: Apollo.MutationHookOptions<AddBlacklistToContactMutation, AddBlacklistToContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBlacklistToContactMutation, AddBlacklistToContactMutationVariables>(AddBlacklistToContactDocument, options);
}
export type AddBlacklistToContactMutationHookResult = ReturnType<typeof useAddBlacklistToContactMutation>;
export type AddBlacklistToContactMutationResult = Apollo.MutationResult<AddBlacklistToContactMutation>;
export type AddBlacklistToContactMutationOptions = Apollo.BaseMutationOptions<AddBlacklistToContactMutation, AddBlacklistToContactMutationVariables>;
export const RemoveBlacklistFromContactDocument = gql`
  mutation RemoveBlacklistFromContact($contactId: ID!, $blacklist: BlacklistInput!) {
    removeContactFromBlacklist(contactId: $contactId, blacklist: $blacklist) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;
export type RemoveBlacklistFromContactMutationFn = Apollo.MutationFunction<RemoveBlacklistFromContactMutation, RemoveBlacklistFromContactMutationVariables>;

/**
 * __useRemoveBlacklistFromContactMutation__
 *
 * To run a mutation, you first call `useRemoveBlacklistFromContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBlacklistFromContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBlacklistFromContactMutation, { data, loading, error }] = useRemoveBlacklistFromContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      blacklist: // value for 'blacklist'
 *   },
 * });
 */
export function useRemoveBlacklistFromContactMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveBlacklistFromContactMutation, RemoveBlacklistFromContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveBlacklistFromContactMutation, RemoveBlacklistFromContactMutationVariables>(RemoveBlacklistFromContactDocument, options);
}
export type RemoveBlacklistFromContactMutationHookResult = ReturnType<typeof useRemoveBlacklistFromContactMutation>;
export type RemoveBlacklistFromContactMutationResult = Apollo.MutationResult<RemoveBlacklistFromContactMutation>;
export type RemoveBlacklistFromContactMutationOptions = Apollo.BaseMutationOptions<
  RemoveBlacklistFromContactMutation,
  RemoveBlacklistFromContactMutationVariables
>;
export const UpdateOwnContactDataDocument = gql`
  mutation UpdateOwnContactData($contactId: ID!, $input: OwnContactDataInput!) {
    updateOwnContactData(contactId: $contactId, input: $input) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      preferredLanguages {
        id
        name
      }
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;
export type UpdateOwnContactDataMutationFn = Apollo.MutationFunction<UpdateOwnContactDataMutation, UpdateOwnContactDataMutationVariables>;

/**
 * __useUpdateOwnContactDataMutation__
 *
 * To run a mutation, you first call `useUpdateOwnContactDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnContactDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnContactDataMutation, { data, loading, error }] = useUpdateOwnContactDataMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnContactDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnContactDataMutation, UpdateOwnContactDataMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnContactDataMutation, UpdateOwnContactDataMutationVariables>(UpdateOwnContactDataDocument, options);
}
export type UpdateOwnContactDataMutationHookResult = ReturnType<typeof useUpdateOwnContactDataMutation>;
export type UpdateOwnContactDataMutationResult = Apollo.MutationResult<UpdateOwnContactDataMutation>;
export type UpdateOwnContactDataMutationOptions = Apollo.BaseMutationOptions<UpdateOwnContactDataMutation, UpdateOwnContactDataMutationVariables>;
export const UpdateCollaborationDocument = gql`
  mutation UpdateCollaboration($contactId: ID!, $input: CollaborationWithIdInput!) {
    updateCollaboration(contactId: $contactId, input: $input) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      preferredLanguages {
        id
        name
      }
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;
export type UpdateCollaborationMutationFn = Apollo.MutationFunction<UpdateCollaborationMutation, UpdateCollaborationMutationVariables>;

/**
 * __useUpdateCollaborationMutation__
 *
 * To run a mutation, you first call `useUpdateCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollaborationMutation, { data, loading, error }] = useUpdateCollaborationMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollaborationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollaborationMutation, UpdateCollaborationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCollaborationMutation, UpdateCollaborationMutationVariables>(UpdateCollaborationDocument, options);
}
export type UpdateCollaborationMutationHookResult = ReturnType<typeof useUpdateCollaborationMutation>;
export type UpdateCollaborationMutationResult = Apollo.MutationResult<UpdateCollaborationMutation>;
export type UpdateCollaborationMutationOptions = Apollo.BaseMutationOptions<UpdateCollaborationMutation, UpdateCollaborationMutationVariables>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($id: ID!, $input: ContactInput!) {
    updateContact(id: $id, input: $input) {
      salutation
      firstName
      lastName
      isGloballySignedOut
    }
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const UpdateProfilePictureDocument = gql`
  mutation UpdateProfilePicture($contactId: ID!, $input: ContactsProfilePictureInput!) {
    updateProfilePicture(contactId: $contactId, input: $input)
  }
`;
export type UpdateProfilePictureMutationFn = Apollo.MutationFunction<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;

/**
 * __useUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePictureMutation, { data, loading, error }] = useUpdateProfilePictureMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>(UpdateProfilePictureDocument, options);
}
export type UpdateProfilePictureMutationHookResult = ReturnType<typeof useUpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationResult = Apollo.MutationResult<UpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;
export const RemoveProfilePictureDocument = gql`
  mutation RemoveProfilePicture($contactId: ID!) {
    removeProfilePicture(contactId: $contactId)
  }
`;
export type RemoveProfilePictureMutationFn = Apollo.MutationFunction<RemoveProfilePictureMutation, RemoveProfilePictureMutationVariables>;

/**
 * __useRemoveProfilePictureMutation__
 *
 * To run a mutation, you first call `useRemoveProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProfilePictureMutation, { data, loading, error }] = useRemoveProfilePictureMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useRemoveProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProfilePictureMutation, RemoveProfilePictureMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProfilePictureMutation, RemoveProfilePictureMutationVariables>(RemoveProfilePictureDocument, options);
}
export type RemoveProfilePictureMutationHookResult = ReturnType<typeof useRemoveProfilePictureMutation>;
export type RemoveProfilePictureMutationResult = Apollo.MutationResult<RemoveProfilePictureMutation>;
export type RemoveProfilePictureMutationOptions = Apollo.BaseMutationOptions<RemoveProfilePictureMutation, RemoveProfilePictureMutationVariables>;
export const RemoveContactDocument = gql`
  mutation RemoveContact($id: ID!) {
    removeContact(id: $id) {
      id
    }
  }
`;
export type RemoveContactMutationFn = Apollo.MutationFunction<RemoveContactMutation, RemoveContactMutationVariables>;

/**
 * __useRemoveContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactMutation, { data, loading, error }] = useRemoveContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContactMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactMutation, RemoveContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContactMutation, RemoveContactMutationVariables>(RemoveContactDocument, options);
}
export type RemoveContactMutationHookResult = ReturnType<typeof useRemoveContactMutation>;
export type RemoveContactMutationResult = Apollo.MutationResult<RemoveContactMutation>;
export type RemoveContactMutationOptions = Apollo.BaseMutationOptions<RemoveContactMutation, RemoveContactMutationVariables>;
export const AddCollaborationDocument = gql`
  mutation AddCollaboration($contactId: ID!, $input: CollaborationInput!) {
    addCollaboration(contactId: $contactId, input: $input) {
      id
      medium {
        id
        name
        type {
          name
          value
        }
      }
      jobDescription
      jobTitle {
        id
        name
        dataSourceKey
        mediumType {
          name
          value
        }
      }
      address
      country
      city
      fax
      landlinePhoneNumber {
        value
        isPrimary
      }
      mobilePhoneNumber {
        value
        isPrimary
      }
      emailAddress
      blogUrl
      websiteUrl
      twitterProfileUrl
      instagramProfileUrl
      linkedInProfileUrl
      youtubeProfileUrl
      facebookProfileUrl
      isPrimary
      postalCode
    }
  }
`;
export type AddCollaborationMutationFn = Apollo.MutationFunction<AddCollaborationMutation, AddCollaborationMutationVariables>;

/**
 * __useAddCollaborationMutation__
 *
 * To run a mutation, you first call `useAddCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaborationMutation, { data, loading, error }] = useAddCollaborationMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCollaborationMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaborationMutation, AddCollaborationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCollaborationMutation, AddCollaborationMutationVariables>(AddCollaborationDocument, options);
}
export type AddCollaborationMutationHookResult = ReturnType<typeof useAddCollaborationMutation>;
export type AddCollaborationMutationResult = Apollo.MutationResult<AddCollaborationMutation>;
export type AddCollaborationMutationOptions = Apollo.BaseMutationOptions<AddCollaborationMutation, AddCollaborationMutationVariables>;
export const AddCollaborationsDocument = gql`
  mutation AddCollaborations($input: [CollaborationWithContactIdInput]!) {
    addCollaborations(input: $input) {
      id
      medium {
        id
        name
        type {
          name
          value
        }
      }
      jobDescription
      jobTitle {
        id
        name
        dataSourceKey
        mediumType {
          name
          value
        }
      }
      address
      country
      city
      fax
      landlinePhoneNumber {
        value
        isPrimary
      }
      mobilePhoneNumber {
        value
        isPrimary
      }
      emailAddress
      blogUrl
      websiteUrl
      twitterProfileUrl
      instagramProfileUrl
      linkedInProfileUrl
      youtubeProfileUrl
      facebookProfileUrl
      isPrimary
      postalCode
    }
  }
`;
export type AddCollaborationsMutationFn = Apollo.MutationFunction<AddCollaborationsMutation, AddCollaborationsMutationVariables>;

/**
 * __useAddCollaborationsMutation__
 *
 * To run a mutation, you first call `useAddCollaborationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaborationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaborationsMutation, { data, loading, error }] = useAddCollaborationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCollaborationsMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaborationsMutation, AddCollaborationsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCollaborationsMutation, AddCollaborationsMutationVariables>(AddCollaborationsDocument, options);
}
export type AddCollaborationsMutationHookResult = ReturnType<typeof useAddCollaborationsMutation>;
export type AddCollaborationsMutationResult = Apollo.MutationResult<AddCollaborationsMutation>;
export type AddCollaborationsMutationOptions = Apollo.BaseMutationOptions<AddCollaborationsMutation, AddCollaborationsMutationVariables>;
export const RemoveCollaborationDocument = gql`
  mutation RemoveCollaboration($contactId: ID!, $collaborationId: ID!) {
    removeCollaboration(contactId: $contactId, collaborationId: $collaborationId) {
      id
      medium {
        id
        name
        type {
          name
          value
        }
      }
      jobDescription
      jobTitle {
        id
        name
        dataSourceKey
        mediumType {
          name
          value
        }
      }
      address
      country
      city
      fax
      landlinePhoneNumber {
        value
        isPrimary
      }
      mobilePhoneNumber {
        value
        isPrimary
      }
      emailAddress
      blogUrl
      websiteUrl
      twitterProfileUrl
      instagramProfileUrl
      linkedInProfileUrl
      youtubeProfileUrl
      facebookProfileUrl
      isPrimary
      postalCode
    }
  }
`;
export type RemoveCollaborationMutationFn = Apollo.MutationFunction<RemoveCollaborationMutation, RemoveCollaborationMutationVariables>;

/**
 * __useRemoveCollaborationMutation__
 *
 * To run a mutation, you first call `useRemoveCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollaborationMutation, { data, loading, error }] = useRemoveCollaborationMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      collaborationId: // value for 'collaborationId'
 *   },
 * });
 */
export function useRemoveCollaborationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCollaborationMutation, RemoveCollaborationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCollaborationMutation, RemoveCollaborationMutationVariables>(RemoveCollaborationDocument, options);
}
export type RemoveCollaborationMutationHookResult = ReturnType<typeof useRemoveCollaborationMutation>;
export type RemoveCollaborationMutationResult = Apollo.MutationResult<RemoveCollaborationMutation>;
export type RemoveCollaborationMutationOptions = Apollo.BaseMutationOptions<RemoveCollaborationMutation, RemoveCollaborationMutationVariables>;
export const AddCommentToContactDocument = gql`
  mutation AddCommentToContact($contactId: ID!, $input: ContactsCommentInput!) {
    addCommentToContact(contactId: $contactId, input: $input) {
      id
      author {
        teamsId
        name
        aadObjectId
      }
      content
      createdAt
      mentions {
        teamsId
        name
        aadObjectId
      }
      replies {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
      }
    }
  }
`;
export type AddCommentToContactMutationFn = Apollo.MutationFunction<AddCommentToContactMutation, AddCommentToContactMutationVariables>;

/**
 * __useAddCommentToContactMutation__
 *
 * To run a mutation, you first call `useAddCommentToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentToContactMutation, { data, loading, error }] = useAddCommentToContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentToContactMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentToContactMutation, AddCommentToContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCommentToContactMutation, AddCommentToContactMutationVariables>(AddCommentToContactDocument, options);
}
export type AddCommentToContactMutationHookResult = ReturnType<typeof useAddCommentToContactMutation>;
export type AddCommentToContactMutationResult = Apollo.MutationResult<AddCommentToContactMutation>;
export type AddCommentToContactMutationOptions = Apollo.BaseMutationOptions<AddCommentToContactMutation, AddCommentToContactMutationVariables>;
export const RemoveCommentFromContactDocument = gql`
  mutation RemoveCommentFromContact($contactId: ID!, $commentId: ID!) {
    removeCommentFromContact(contactId: $contactId, commentId: $commentId) {
      id
      author {
        teamsId
        name
        aadObjectId
      }
      content
      createdAt
      mentions {
        teamsId
        name
        aadObjectId
      }
      replies {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
      }
    }
  }
`;
export type RemoveCommentFromContactMutationFn = Apollo.MutationFunction<RemoveCommentFromContactMutation, RemoveCommentFromContactMutationVariables>;

/**
 * __useRemoveCommentFromContactMutation__
 *
 * To run a mutation, you first call `useRemoveCommentFromContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentFromContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentFromContactMutation, { data, loading, error }] = useRemoveCommentFromContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useRemoveCommentFromContactMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCommentFromContactMutation, RemoveCommentFromContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCommentFromContactMutation, RemoveCommentFromContactMutationVariables>(RemoveCommentFromContactDocument, options);
}
export type RemoveCommentFromContactMutationHookResult = ReturnType<typeof useRemoveCommentFromContactMutation>;
export type RemoveCommentFromContactMutationResult = Apollo.MutationResult<RemoveCommentFromContactMutation>;
export type RemoveCommentFromContactMutationOptions = Apollo.BaseMutationOptions<RemoveCommentFromContactMutation, RemoveCommentFromContactMutationVariables>;
export const UpdateCommentDocument = gql`
  mutation UpdateComment($contactId: ID!, $input: ContactsCommentWithIdInput!) {
    updateComment(contactId: $contactId, input: $input) {
      id
      author {
        teamsId
        name
        aadObjectId
      }
      content
      createdAt
      mentions {
        teamsId
        name
        aadObjectId
      }
      replies {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
      }
    }
  }
`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
}
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const AddEmailDocument = gql`
  mutation AddEmail($contactId: ID!, $input: EmailInput!) {
    addEmail(contactId: $contactId, input: $input) {
      id
      comment
      ... on Email {
        subject
        scheduledFor
      }
      title
      createdAt
      createdByUser {
        id
        firstName
        lastName
      }
      participants {
        id
        collaborationId
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddEmailMutationFn = Apollo.MutationFunction<AddEmailMutation, AddEmailMutationVariables>;

/**
 * __useAddEmailMutation__
 *
 * To run a mutation, you first call `useAddEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailMutation, { data, loading, error }] = useAddEmailMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEmailMutation(baseOptions?: Apollo.MutationHookOptions<AddEmailMutation, AddEmailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddEmailMutation, AddEmailMutationVariables>(AddEmailDocument, options);
}
export type AddEmailMutationHookResult = ReturnType<typeof useAddEmailMutation>;
export type AddEmailMutationResult = Apollo.MutationResult<AddEmailMutation>;
export type AddEmailMutationOptions = Apollo.BaseMutationOptions<AddEmailMutation, AddEmailMutationVariables>;
export const AddCallDocument = gql`
  mutation AddCall($contactId: ID!, $input: CallInput!) {
    addCall(contactId: $contactId, input: $input) {
      id
      comment
      title
      createdAt
      scheduledFor
      createdByUser {
        id
        firstName
        lastName
      }
      participants {
        id
        collaborationId
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddCallMutationFn = Apollo.MutationFunction<AddCallMutation, AddCallMutationVariables>;

/**
 * __useAddCallMutation__
 *
 * To run a mutation, you first call `useAddCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCallMutation, { data, loading, error }] = useAddCallMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCallMutation(baseOptions?: Apollo.MutationHookOptions<AddCallMutation, AddCallMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCallMutation, AddCallMutationVariables>(AddCallDocument, options);
}
export type AddCallMutationHookResult = ReturnType<typeof useAddCallMutation>;
export type AddCallMutationResult = Apollo.MutationResult<AddCallMutation>;
export type AddCallMutationOptions = Apollo.BaseMutationOptions<AddCallMutation, AddCallMutationVariables>;
export const AddAppointmentDocument = gql`
  mutation AddAppointment($contactId: ID!, $input: AppointmentInput!) {
    addAppointment(contactId: $contactId, input: $input) {
      id
      comment
      ... on Appointment {
        subject
        startsAt
        endsAt
        subject
      }
      title
      createdAt
      createdByUser {
        id
        firstName
        lastName
      }
      participants {
        id
        collaborationId
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddAppointmentMutationFn = Apollo.MutationFunction<AddAppointmentMutation, AddAppointmentMutationVariables>;

/**
 * __useAddAppointmentMutation__
 *
 * To run a mutation, you first call `useAddAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppointmentMutation, { data, loading, error }] = useAddAppointmentMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<AddAppointmentMutation, AddAppointmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAppointmentMutation, AddAppointmentMutationVariables>(AddAppointmentDocument, options);
}
export type AddAppointmentMutationHookResult = ReturnType<typeof useAddAppointmentMutation>;
export type AddAppointmentMutationResult = Apollo.MutationResult<AddAppointmentMutation>;
export type AddAppointmentMutationOptions = Apollo.BaseMutationOptions<AddAppointmentMutation, AddAppointmentMutationVariables>;
export const AddWebinarDocument = gql`
  mutation AddWebinar($contactId: ID!, $input: WebinarInput!) {
    addWebinar(contactId: $contactId, input: $input) {
      id
      comment
      title
      createdAt
      ... on Webinar {
        startsAt
        endsAt
      }
      createdByUser {
        id
        firstName
        lastName
      }
      participants {
        id
        collaborationId
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddWebinarMutationFn = Apollo.MutationFunction<AddWebinarMutation, AddWebinarMutationVariables>;

/**
 * __useAddWebinarMutation__
 *
 * To run a mutation, you first call `useAddWebinarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWebinarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWebinarMutation, { data, loading, error }] = useAddWebinarMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWebinarMutation(baseOptions?: Apollo.MutationHookOptions<AddWebinarMutation, AddWebinarMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddWebinarMutation, AddWebinarMutationVariables>(AddWebinarDocument, options);
}
export type AddWebinarMutationHookResult = ReturnType<typeof useAddWebinarMutation>;
export type AddWebinarMutationResult = Apollo.MutationResult<AddWebinarMutation>;
export type AddWebinarMutationOptions = Apollo.BaseMutationOptions<AddWebinarMutation, AddWebinarMutationVariables>;
export const AddEventDocument = gql`
  mutation AddEvent($contactId: ID!, $input: EventInput!) {
    addEvent(contactId: $contactId, input: $input) {
      id
      comment
      title
      createdAt
      ... on Event {
        startsAt
        endsAt
      }
      createdByUser {
        id
        firstName
        lastName
      }
      participants {
        id
        collaborationId
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddEventMutationFn = Apollo.MutationFunction<AddEventMutation, AddEventMutationVariables>;

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutation, { data, loading, error }] = useAddEventMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventMutation(baseOptions?: Apollo.MutationHookOptions<AddEventMutation, AddEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddEventMutation, AddEventMutationVariables>(AddEventDocument, options);
}
export type AddEventMutationHookResult = ReturnType<typeof useAddEventMutation>;
export type AddEventMutationResult = Apollo.MutationResult<AddEventMutation>;
export type AddEventMutationOptions = Apollo.BaseMutationOptions<AddEventMutation, AddEventMutationVariables>;
export const AddChatDocument = gql`
  mutation AddChat($contactId: ID!, $input: ChatInput!) {
    addChat(contactId: $contactId, input: $input) {
      id
      comment
      title
      createdAt
      scheduledFor
      createdByUser {
        id
        firstName
        lastName
      }
      participants {
        id
        collaborationId
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddChatMutationFn = Apollo.MutationFunction<AddChatMutation, AddChatMutationVariables>;

/**
 * __useAddChatMutation__
 *
 * To run a mutation, you first call `useAddChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChatMutation, { data, loading, error }] = useAddChatMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChatMutation(baseOptions?: Apollo.MutationHookOptions<AddChatMutation, AddChatMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddChatMutation, AddChatMutationVariables>(AddChatDocument, options);
}
export type AddChatMutationHookResult = ReturnType<typeof useAddChatMutation>;
export type AddChatMutationResult = Apollo.MutationResult<AddChatMutation>;
export type AddChatMutationOptions = Apollo.BaseMutationOptions<AddChatMutation, AddChatMutationVariables>;
export const AddTaskDocument = gql`
  mutation AddTask($contactId: ID!, $input: TaskInput!) {
    addTask(contactId: $contactId, input: $input) {
      id
      comment
      ... on Task {
        deadline
        subject
      }
      title
      createdAt
      createdByUser {
        id
        firstName
        lastName
      }
      participants {
        id
        collaborationId
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddTaskMutationFn = Apollo.MutationFunction<AddTaskMutation, AddTaskMutationVariables>;

/**
 * __useAddTaskMutation__
 *
 * To run a mutation, you first call `useAddTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskMutation, { data, loading, error }] = useAddTaskMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTaskMutation(baseOptions?: Apollo.MutationHookOptions<AddTaskMutation, AddTaskMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTaskMutation, AddTaskMutationVariables>(AddTaskDocument, options);
}
export type AddTaskMutationHookResult = ReturnType<typeof useAddTaskMutation>;
export type AddTaskMutationResult = Apollo.MutationResult<AddTaskMutation>;
export type AddTaskMutationOptions = Apollo.BaseMutationOptions<AddTaskMutation, AddTaskMutationVariables>;
export const DeleteMediaResortsDocument = gql`
  mutation DeleteMediaResorts($ids: [ID!]) {
    deleteMediaResorts(ids: $ids)
  }
`;
export type DeleteMediaResortsMutationFn = Apollo.MutationFunction<DeleteMediaResortsMutation, DeleteMediaResortsMutationVariables>;

/**
 * __useDeleteMediaResortsMutation__
 *
 * To run a mutation, you first call `useDeleteMediaResortsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaResortsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaResortsMutation, { data, loading, error }] = useDeleteMediaResortsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMediaResortsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMediaResortsMutation, DeleteMediaResortsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMediaResortsMutation, DeleteMediaResortsMutationVariables>(DeleteMediaResortsDocument, options);
}
export type DeleteMediaResortsMutationHookResult = ReturnType<typeof useDeleteMediaResortsMutation>;
export type DeleteMediaResortsMutationResult = Apollo.MutationResult<DeleteMediaResortsMutation>;
export type DeleteMediaResortsMutationOptions = Apollo.BaseMutationOptions<DeleteMediaResortsMutation, DeleteMediaResortsMutationVariables>;
export const RemoveTopicsDocument = gql`
  mutation RemoveTopics($ids: [ID!]) {
    removeTopics(ids: $ids)
  }
`;
export type RemoveTopicsMutationFn = Apollo.MutationFunction<RemoveTopicsMutation, RemoveTopicsMutationVariables>;

/**
 * __useRemoveTopicsMutation__
 *
 * To run a mutation, you first call `useRemoveTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTopicsMutation, { data, loading, error }] = useRemoveTopicsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveTopicsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTopicsMutation, RemoveTopicsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTopicsMutation, RemoveTopicsMutationVariables>(RemoveTopicsDocument, options);
}
export type RemoveTopicsMutationHookResult = ReturnType<typeof useRemoveTopicsMutation>;
export type RemoveTopicsMutationResult = Apollo.MutationResult<RemoveTopicsMutation>;
export type RemoveTopicsMutationOptions = Apollo.BaseMutationOptions<RemoveTopicsMutation, RemoveTopicsMutationVariables>;
export const DeleteBlacklistsDocument = gql`
  mutation DeleteBlacklists($ids: [ID!]) {
    deleteBlacklists(ids: $ids)
  }
`;
export type DeleteBlacklistsMutationFn = Apollo.MutationFunction<DeleteBlacklistsMutation, DeleteBlacklistsMutationVariables>;

/**
 * __useDeleteBlacklistsMutation__
 *
 * To run a mutation, you first call `useDeleteBlacklistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlacklistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlacklistsMutation, { data, loading, error }] = useDeleteBlacklistsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteBlacklistsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlacklistsMutation, DeleteBlacklistsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBlacklistsMutation, DeleteBlacklistsMutationVariables>(DeleteBlacklistsDocument, options);
}
export type DeleteBlacklistsMutationHookResult = ReturnType<typeof useDeleteBlacklistsMutation>;
export type DeleteBlacklistsMutationResult = Apollo.MutationResult<DeleteBlacklistsMutation>;
export type DeleteBlacklistsMutationOptions = Apollo.BaseMutationOptions<DeleteBlacklistsMutation, DeleteBlacklistsMutationVariables>;
export const FetchContactProfileDocument = gql`
  query FetchContactProfile($id: ID!) {
    contact(id: $id) {
      id
      salutation
      firstName
      lastName
      isUser
      profilePictureUrl
      isGloballySignedOut
      dataSourceStatus {
        name
        value
      }
      preferredLanguages {
        id
        name
        dataSourceKey
      }
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;

/**
 * __useFetchContactProfileQuery__
 *
 * To run a query within a React component, call `useFetchContactProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchContactProfileQuery(baseOptions: Apollo.QueryHookOptions<FetchContactProfileQuery, FetchContactProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactProfileQuery, FetchContactProfileQueryVariables>(FetchContactProfileDocument, options);
}
export function useFetchContactProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchContactProfileQuery, FetchContactProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactProfileQuery, FetchContactProfileQueryVariables>(FetchContactProfileDocument, options);
}
export type FetchContactProfileQueryHookResult = ReturnType<typeof useFetchContactProfileQuery>;
export type FetchContactProfileLazyQueryHookResult = ReturnType<typeof useFetchContactProfileLazyQuery>;
export type FetchContactProfileQueryResult = Apollo.QueryResult<FetchContactProfileQuery, FetchContactProfileQueryVariables>;
export const FetchContactAvatarDetailsByContactIdDocument = gql`
  query FetchContactAvatarDetailsByContactId($id: ID!) {
    contact(id: $id) {
      firstName
      lastName
      profilePictureUrl
    }
  }
`;

/**
 * __useFetchContactAvatarDetailsByContactIdQuery__
 *
 * To run a query within a React component, call `useFetchContactAvatarDetailsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactAvatarDetailsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactAvatarDetailsByContactIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchContactAvatarDetailsByContactIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactAvatarDetailsByContactIdQuery, FetchContactAvatarDetailsByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactAvatarDetailsByContactIdQuery, FetchContactAvatarDetailsByContactIdQueryVariables>(
    FetchContactAvatarDetailsByContactIdDocument,
    options
  );
}
export function useFetchContactAvatarDetailsByContactIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactAvatarDetailsByContactIdQuery, FetchContactAvatarDetailsByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactAvatarDetailsByContactIdQuery, FetchContactAvatarDetailsByContactIdQueryVariables>(
    FetchContactAvatarDetailsByContactIdDocument,
    options
  );
}
export type FetchContactAvatarDetailsByContactIdQueryHookResult = ReturnType<typeof useFetchContactAvatarDetailsByContactIdQuery>;
export type FetchContactAvatarDetailsByContactIdLazyQueryHookResult = ReturnType<typeof useFetchContactAvatarDetailsByContactIdLazyQuery>;
export type FetchContactAvatarDetailsByContactIdQueryResult = Apollo.QueryResult<
  FetchContactAvatarDetailsByContactIdQuery,
  FetchContactAvatarDetailsByContactIdQueryVariables
>;
export const FetchListsByContactIdDocument = gql`
  query FetchListsByContactId($contactId: ID!) {
    listsByContactId(contactId: $contactId) {
      id
      name
    }
  }
`;

/**
 * __useFetchListsByContactIdQuery__
 *
 * To run a query within a React component, call `useFetchListsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchListsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchListsByContactIdQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchListsByContactIdQuery(baseOptions: Apollo.QueryHookOptions<FetchListsByContactIdQuery, FetchListsByContactIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchListsByContactIdQuery, FetchListsByContactIdQueryVariables>(FetchListsByContactIdDocument, options);
}
export function useFetchListsByContactIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchListsByContactIdQuery, FetchListsByContactIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchListsByContactIdQuery, FetchListsByContactIdQueryVariables>(FetchListsByContactIdDocument, options);
}
export type FetchListsByContactIdQueryHookResult = ReturnType<typeof useFetchListsByContactIdQuery>;
export type FetchListsByContactIdLazyQueryHookResult = ReturnType<typeof useFetchListsByContactIdLazyQuery>;
export type FetchListsByContactIdQueryResult = Apollo.QueryResult<FetchListsByContactIdQuery, FetchListsByContactIdQueryVariables>;
export const FetchRecipientsByContactIdDocument = gql`
  query FetchRecipientsByContactId($contactId: ID!) {
    recipientsByContactId(contactId: $contactId) {
      ... on Recipient {
        id
        sendingId
        collaborationId
        isOpened
        linksClicked
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          isGloballySignedOut
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
      ... on RecipientFromList {
        id
        sendingId
        listId
        collaborationId
        isOpened
        linksClicked
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          isGloballySignedOut
          tags {
            id
            name
          }
          topics {
            topicId
            topicName
            dataSourceKey
          }
          mediaResorts {
            mediaResortId
            mediaResortName
            dataSourceKey
          }
          comments {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
            replies {
              id
              author {
                teamsId
                name
                aadObjectId
              }
              content
              createdAt
              mentions {
                teamsId
                name
                aadObjectId
              }
            }
          }
          blacklists {
            id
            name
          }
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
      }
    }
  }
`;

/**
 * __useFetchRecipientsByContactIdQuery__
 *
 * To run a query within a React component, call `useFetchRecipientsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecipientsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecipientsByContactIdQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchRecipientsByContactIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchRecipientsByContactIdQuery, FetchRecipientsByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchRecipientsByContactIdQuery, FetchRecipientsByContactIdQueryVariables>(FetchRecipientsByContactIdDocument, options);
}
export function useFetchRecipientsByContactIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchRecipientsByContactIdQuery, FetchRecipientsByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchRecipientsByContactIdQuery, FetchRecipientsByContactIdQueryVariables>(FetchRecipientsByContactIdDocument, options);
}
export type FetchRecipientsByContactIdQueryHookResult = ReturnType<typeof useFetchRecipientsByContactIdQuery>;
export type FetchRecipientsByContactIdLazyQueryHookResult = ReturnType<typeof useFetchRecipientsByContactIdLazyQuery>;
export type FetchRecipientsByContactIdQueryResult = Apollo.QueryResult<FetchRecipientsByContactIdQuery, FetchRecipientsByContactIdQueryVariables>;
export const FetchContactsByIdsDocument = gql`
  query fetchContactsByIds($ids: [ID!]!) {
    contactsByIds(ids: $ids) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;

/**
 * __useFetchContactsByIdsQuery__
 *
 * To run a query within a React component, call `useFetchContactsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFetchContactsByIdsQuery(baseOptions: Apollo.QueryHookOptions<FetchContactsByIdsQuery, FetchContactsByIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByIdsQuery, FetchContactsByIdsQueryVariables>(FetchContactsByIdsDocument, options);
}
export function useFetchContactsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsByIdsQuery, FetchContactsByIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByIdsQuery, FetchContactsByIdsQueryVariables>(FetchContactsByIdsDocument, options);
}
export type FetchContactsByIdsQueryHookResult = ReturnType<typeof useFetchContactsByIdsQuery>;
export type FetchContactsByIdsLazyQueryHookResult = ReturnType<typeof useFetchContactsByIdsLazyQuery>;
export type FetchContactsByIdsQueryResult = Apollo.QueryResult<FetchContactsByIdsQuery, FetchContactsByIdsQueryVariables>;
export const FetchAllTopicsDocument = gql`
  query fetchAllTopics {
    topics {
      id
      name
      dataSourceKey
    }
  }
`;

/**
 * __useFetchAllTopicsQuery__
 *
 * To run a query within a React component, call `useFetchAllTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllTopicsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllTopicsQuery, FetchAllTopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllTopicsQuery, FetchAllTopicsQueryVariables>(FetchAllTopicsDocument, options);
}
export function useFetchAllTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllTopicsQuery, FetchAllTopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllTopicsQuery, FetchAllTopicsQueryVariables>(FetchAllTopicsDocument, options);
}
export type FetchAllTopicsQueryHookResult = ReturnType<typeof useFetchAllTopicsQuery>;
export type FetchAllTopicsLazyQueryHookResult = ReturnType<typeof useFetchAllTopicsLazyQuery>;
export type FetchAllTopicsQueryResult = Apollo.QueryResult<FetchAllTopicsQuery, FetchAllTopicsQueryVariables>;
export const FetchAllMediaResortsDocument = gql`
  query fetchAllMediaResorts {
    mediaResorts {
      id
      name
      dataSourceKey
    }
  }
`;

/**
 * __useFetchAllMediaResortsQuery__
 *
 * To run a query within a React component, call `useFetchAllMediaResortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllMediaResortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllMediaResortsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllMediaResortsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllMediaResortsQuery, FetchAllMediaResortsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllMediaResortsQuery, FetchAllMediaResortsQueryVariables>(FetchAllMediaResortsDocument, options);
}
export function useFetchAllMediaResortsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllMediaResortsQuery, FetchAllMediaResortsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllMediaResortsQuery, FetchAllMediaResortsQueryVariables>(FetchAllMediaResortsDocument, options);
}
export type FetchAllMediaResortsQueryHookResult = ReturnType<typeof useFetchAllMediaResortsQuery>;
export type FetchAllMediaResortsLazyQueryHookResult = ReturnType<typeof useFetchAllMediaResortsLazyQuery>;
export type FetchAllMediaResortsQueryResult = Apollo.QueryResult<FetchAllMediaResortsQuery, FetchAllMediaResortsQueryVariables>;
export const FetchSelectedContactActivitiesDocument = gql`
  query fetchSelectedContactActivities($contactId: ID!) {
    activitiesByContactId(contactId: $contactId) {
      id
      comment
      title
      createdAt
      createdByUser {
        id
        firstName
        lastName
      }
      ... on Email {
        scheduledFor
      }
      ... on Call {
        scheduledFor
      }
      ... on Chat {
        scheduledFor
      }
      ... on Appointment {
        subject
        startsAt
        endsAt
      }
      ... on Webinar {
        startsAt
        endsAt
      }
      ... on Event {
        startsAt
        endsAt
      }
      ... on Task {
        deadline
      }
      ... on ContactsSending {
        id
        imageAsBase64
        isFavourite
        blacklist {
          id
          name
        }
        title
        subject
        htmlBody
        jsonBody
        originalHtmlBody
        mjmlBody
        status {
          name
          value
        }
        createdAt
        lastModifiedAt
        respondToEmailAddress
        lastModifiedByUser {
          id
          firstName
          lastName
        }
        createdByUser {
          id
          firstName
          lastName
        }
        sender {
          id
          displayName
          emailAddress
          userId
        }
        senderDisplayName
        senderEmailAddress
        deliveredAt
        recipientsIds
        numberOfRecipients
        distributionDuration
        tags {
          id
          name
        }
        campaign {
          name
        }
      }
    }
  }
`;

/**
 * __useFetchSelectedContactActivitiesQuery__
 *
 * To run a query within a React component, call `useFetchSelectedContactActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSelectedContactActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSelectedContactActivitiesQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchSelectedContactActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchSelectedContactActivitiesQuery, FetchSelectedContactActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSelectedContactActivitiesQuery, FetchSelectedContactActivitiesQueryVariables>(FetchSelectedContactActivitiesDocument, options);
}
export function useFetchSelectedContactActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSelectedContactActivitiesQuery, FetchSelectedContactActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSelectedContactActivitiesQuery, FetchSelectedContactActivitiesQueryVariables>(
    FetchSelectedContactActivitiesDocument,
    options
  );
}
export type FetchSelectedContactActivitiesQueryHookResult = ReturnType<typeof useFetchSelectedContactActivitiesQuery>;
export type FetchSelectedContactActivitiesLazyQueryHookResult = ReturnType<typeof useFetchSelectedContactActivitiesLazyQuery>;
export type FetchSelectedContactActivitiesQueryResult = Apollo.QueryResult<FetchSelectedContactActivitiesQuery, FetchSelectedContactActivitiesQueryVariables>;
export const FetchPagedSelectedContactActivitiesDocument = gql`
  query fetchPagedSelectedContactActivities($contactId: ID!, $skip: Int!, $take: Int!, $filterItems: [ContactsFilterItemInput!]!) {
    activitiesByContactIdPaged(contactId: $contactId, skip: $skip, take: $take, filterItems: $filterItems) {
      totalCount
      activities {
        id
        comment
        title
        createdAt
        createdByUser {
          id
          firstName
          lastName
        }
        ... on Email {
          scheduledFor
        }
        ... on Call {
          scheduledFor
        }
        ... on Chat {
          scheduledFor
        }
        ... on Appointment {
          subject
          startsAt
          endsAt
        }
        ... on Webinar {
          startsAt
          endsAt
        }
        ... on Event {
          startsAt
          endsAt
        }
        ... on Task {
          deadline
        }
        ... on ContactsSending {
          id
          imageAsBase64
          isFavourite
          blacklist {
            id
            name
          }
          title
          subject
          htmlBody
          jsonBody
          originalHtmlBody
          mjmlBody
          status {
            name
            value
          }
          createdAt
          lastModifiedAt
          respondToEmailAddress
          lastModifiedByUser {
            id
            firstName
            lastName
          }
          createdByUser {
            id
            firstName
            lastName
          }
          sender {
            id
            displayName
            emailAddress
            userId
          }
          senderDisplayName
          senderEmailAddress
          deliveredAt
          recipientsIds
          numberOfRecipients
          distributionDuration
          tags {
            id
            name
          }
          campaign {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useFetchPagedSelectedContactActivitiesQuery__
 *
 * To run a query within a React component, call `useFetchPagedSelectedContactActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPagedSelectedContactActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPagedSelectedContactActivitiesQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      filterItems: // value for 'filterItems'
 *   },
 * });
 */
export function useFetchPagedSelectedContactActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPagedSelectedContactActivitiesQuery, FetchPagedSelectedContactActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPagedSelectedContactActivitiesQuery, FetchPagedSelectedContactActivitiesQueryVariables>(
    FetchPagedSelectedContactActivitiesDocument,
    options
  );
}
export function useFetchPagedSelectedContactActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPagedSelectedContactActivitiesQuery, FetchPagedSelectedContactActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPagedSelectedContactActivitiesQuery, FetchPagedSelectedContactActivitiesQueryVariables>(
    FetchPagedSelectedContactActivitiesDocument,
    options
  );
}
export type FetchPagedSelectedContactActivitiesQueryHookResult = ReturnType<typeof useFetchPagedSelectedContactActivitiesQuery>;
export type FetchPagedSelectedContactActivitiesLazyQueryHookResult = ReturnType<typeof useFetchPagedSelectedContactActivitiesLazyQuery>;
export type FetchPagedSelectedContactActivitiesQueryResult = Apollo.QueryResult<
  FetchPagedSelectedContactActivitiesQuery,
  FetchPagedSelectedContactActivitiesQueryVariables
>;
export const FetchAllJobTitlesDocument = gql`
  query fetchAllJobTitles {
    jobTitles {
      id
      dataSourceKey
      name
      mediumType {
        name
        value
      }
    }
  }
`;

/**
 * __useFetchAllJobTitlesQuery__
 *
 * To run a query within a React component, call `useFetchAllJobTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllJobTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllJobTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllJobTitlesQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllJobTitlesQuery, FetchAllJobTitlesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllJobTitlesQuery, FetchAllJobTitlesQueryVariables>(FetchAllJobTitlesDocument, options);
}
export function useFetchAllJobTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllJobTitlesQuery, FetchAllJobTitlesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllJobTitlesQuery, FetchAllJobTitlesQueryVariables>(FetchAllJobTitlesDocument, options);
}
export type FetchAllJobTitlesQueryHookResult = ReturnType<typeof useFetchAllJobTitlesQuery>;
export type FetchAllJobTitlesLazyQueryHookResult = ReturnType<typeof useFetchAllJobTitlesLazyQuery>;
export type FetchAllJobTitlesQueryResult = Apollo.QueryResult<FetchAllJobTitlesQuery, FetchAllJobTitlesQueryVariables>;
export const FetchContactsProfilePanelDataDocument = gql`
  query FetchContactsProfilePanelData($id: ID!) {
    contact(id: $id) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      isUser
      dataSourceStatus {
        name
        value
      }
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      ownContactData {
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;

/**
 * __useFetchContactsProfilePanelDataQuery__
 *
 * To run a query within a React component, call `useFetchContactsProfilePanelDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsProfilePanelDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsProfilePanelDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchContactsProfilePanelDataQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsProfilePanelDataQuery, FetchContactsProfilePanelDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsProfilePanelDataQuery, FetchContactsProfilePanelDataQueryVariables>(FetchContactsProfilePanelDataDocument, options);
}
export function useFetchContactsProfilePanelDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsProfilePanelDataQuery, FetchContactsProfilePanelDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsProfilePanelDataQuery, FetchContactsProfilePanelDataQueryVariables>(FetchContactsProfilePanelDataDocument, options);
}
export type FetchContactsProfilePanelDataQueryHookResult = ReturnType<typeof useFetchContactsProfilePanelDataQuery>;
export type FetchContactsProfilePanelDataLazyQueryHookResult = ReturnType<typeof useFetchContactsProfilePanelDataLazyQuery>;
export type FetchContactsProfilePanelDataQueryResult = Apollo.QueryResult<FetchContactsProfilePanelDataQuery, FetchContactsProfilePanelDataQueryVariables>;
export const FetchActivityEmailAddressDocument = gql`
  query fetchActivityEmailAddress {
    activityEmailAddress
  }
`;

/**
 * __useFetchActivityEmailAddressQuery__
 *
 * To run a query within a React component, call `useFetchActivityEmailAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchActivityEmailAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchActivityEmailAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchActivityEmailAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchActivityEmailAddressQuery, FetchActivityEmailAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchActivityEmailAddressQuery, FetchActivityEmailAddressQueryVariables>(FetchActivityEmailAddressDocument, options);
}
export function useFetchActivityEmailAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchActivityEmailAddressQuery, FetchActivityEmailAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchActivityEmailAddressQuery, FetchActivityEmailAddressQueryVariables>(FetchActivityEmailAddressDocument, options);
}
export type FetchActivityEmailAddressQueryHookResult = ReturnType<typeof useFetchActivityEmailAddressQuery>;
export type FetchActivityEmailAddressLazyQueryHookResult = ReturnType<typeof useFetchActivityEmailAddressLazyQuery>;
export type FetchActivityEmailAddressQueryResult = Apollo.QueryResult<FetchActivityEmailAddressQuery, FetchActivityEmailAddressQueryVariables>;
export const FetchUserByAadObjectIdDocument = gql`
  query fetchUserByAadObjectId {
    userByAadObjectId {
      id
      firstName
      lastName
    }
  }
`;

/**
 * __useFetchUserByAadObjectIdQuery__
 *
 * To run a query within a React component, call `useFetchUserByAadObjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserByAadObjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserByAadObjectIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserByAadObjectIdQuery(baseOptions?: Apollo.QueryHookOptions<FetchUserByAadObjectIdQuery, FetchUserByAadObjectIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchUserByAadObjectIdQuery, FetchUserByAadObjectIdQueryVariables>(FetchUserByAadObjectIdDocument, options);
}
export function useFetchUserByAadObjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchUserByAadObjectIdQuery, FetchUserByAadObjectIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchUserByAadObjectIdQuery, FetchUserByAadObjectIdQueryVariables>(FetchUserByAadObjectIdDocument, options);
}
export type FetchUserByAadObjectIdQueryHookResult = ReturnType<typeof useFetchUserByAadObjectIdQuery>;
export type FetchUserByAadObjectIdLazyQueryHookResult = ReturnType<typeof useFetchUserByAadObjectIdLazyQuery>;
export type FetchUserByAadObjectIdQueryResult = Apollo.QueryResult<FetchUserByAadObjectIdQuery, FetchUserByAadObjectIdQueryVariables>;
export const FetchMediaOutletsByNameAndCategoryAndNotContainedInContactDocument = gql`
  query FetchMediaOutletsByNameAndCategoryAndNotContainedInContact($skip: Int!, $take: Int!, $name: String!, $category: String!, $contactId: ID!) {
    mediaOutletsByNameAndCategoryAndNotContainedInContact(skip: $skip, take: $take, name: $name, category: $category, contactId: $contactId) {
      totalCount
      mediaOutlets {
        id
        name
        profilePictureUrl
        description
        audience {
          id
          key
        }
        category {
          id
          name
        }
        websiteUrl
        frequency {
          id
          key
        }
        mediaType
        publicationType
        readership
        circulation
        contactData {
          street
          poBox
          postalCode
          country
          city
          websiteUrl
          emailAddress
          landlinePhoneNumber
          fax
          alternativeFax
        }
        mediumSupportTypes {
          id
          key
        }
        distributions {
          key
        }
        mediaOutletContacts {
          id
          firstName
          lastName
          profilePictureUrl
        }
        tags {
          id
          name
        }
        preferredLanguages {
          id
          name
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        userComments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery,
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery,
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryVariables
  >(FetchMediaOutletsByNameAndCategoryAndNotContainedInContactDocument, options);
}
export function useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery,
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery,
    FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryVariables
  >(FetchMediaOutletsByNameAndCategoryAndNotContainedInContactDocument, options);
}
export type FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryHookResult = ReturnType<
  typeof useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery
>;
export type FetchMediaOutletsByNameAndCategoryAndNotContainedInContactLazyQueryHookResult = ReturnType<
  typeof useFetchMediaOutletsByNameAndCategoryAndNotContainedInContactLazyQuery
>;
export type FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryResult = Apollo.QueryResult<
  FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQuery,
  FetchMediaOutletsByNameAndCategoryAndNotContainedInContactQueryVariables
>;
export const FetchCompaniesByNameAndCategoryAndNotContainedInContactDocument = gql`
  query FetchCompaniesByNameAndCategoryAndNotContainedInContact($skip: Int!, $take: Int!, $name: String!, $category: String!, $contactId: ID!) {
    companiesByNameAndCategoryAndNotContainedInContact(skip: $skip, take: $take, name: $name, category: $category, contactId: $contactId) {
      totalCount
      companies {
        id
        name
        profilePictureUrl
        category {
          id
          name
        }
        parentCompany
        contactData {
          street
          poBox
          postalCode
          country
          city
          websiteUrl
          emailAddress
          landlinePhoneNumber
          fax
          alternativeFax
        }
        companyContacts {
          id
          firstName
          lastName
          profilePictureUrl
        }
        director
        competitors
        createdAt
        profit
        description
        databaseType
        registrationNumber
        comments
        tags {
          id
          name
        }
        userComments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchCompaniesByNameAndCategoryAndNotContainedInContactQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesByNameAndCategoryAndNotContainedInContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesByNameAndCategoryAndNotContainedInContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesByNameAndCategoryAndNotContainedInContactQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchCompaniesByNameAndCategoryAndNotContainedInContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery,
    FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery, FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryVariables>(
    FetchCompaniesByNameAndCategoryAndNotContainedInContactDocument,
    options
  );
}
export function useFetchCompaniesByNameAndCategoryAndNotContainedInContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery,
    FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery,
    FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryVariables
  >(FetchCompaniesByNameAndCategoryAndNotContainedInContactDocument, options);
}
export type FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryHookResult = ReturnType<
  typeof useFetchCompaniesByNameAndCategoryAndNotContainedInContactQuery
>;
export type FetchCompaniesByNameAndCategoryAndNotContainedInContactLazyQueryHookResult = ReturnType<
  typeof useFetchCompaniesByNameAndCategoryAndNotContainedInContactLazyQuery
>;
export type FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryResult = Apollo.QueryResult<
  FetchCompaniesByNameAndCategoryAndNotContainedInContactQuery,
  FetchCompaniesByNameAndCategoryAndNotContainedInContactQueryVariables
>;
export const FetchSendingsByContactIdDocument = gql`
  query fetchSendingsByContactId($contactId: String!) {
    sendingsByContactId(contactId: $contactId) {
      id
      title
      createdAt
      recipientsIds
      status {
        name
        value
      }
    }
  }
`;

/**
 * __useFetchSendingsByContactIdQuery__
 *
 * To run a query within a React component, call `useFetchSendingsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSendingsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSendingsByContactIdQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchSendingsByContactIdQuery(baseOptions: Apollo.QueryHookOptions<FetchSendingsByContactIdQuery, FetchSendingsByContactIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSendingsByContactIdQuery, FetchSendingsByContactIdQueryVariables>(FetchSendingsByContactIdDocument, options);
}
export function useFetchSendingsByContactIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSendingsByContactIdQuery, FetchSendingsByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSendingsByContactIdQuery, FetchSendingsByContactIdQueryVariables>(FetchSendingsByContactIdDocument, options);
}
export type FetchSendingsByContactIdQueryHookResult = ReturnType<typeof useFetchSendingsByContactIdQuery>;
export type FetchSendingsByContactIdLazyQueryHookResult = ReturnType<typeof useFetchSendingsByContactIdLazyQuery>;
export type FetchSendingsByContactIdQueryResult = Apollo.QueryResult<FetchSendingsByContactIdQuery, FetchSendingsByContactIdQueryVariables>;
export const AddSavedSearchDocument = gql`
  mutation AddSavedSearch($savedSearch: ContactsSavedSearchInput!) {
    saveSearch(input: $savedSearch) {
      id
      searchText
      filterItems {
        fieldName
        value
      }
      name
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type AddSavedSearchMutationFn = Apollo.MutationFunction<AddSavedSearchMutation, AddSavedSearchMutationVariables>;

/**
 * __useAddSavedSearchMutation__
 *
 * To run a mutation, you first call `useAddSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSavedSearchMutation, { data, loading, error }] = useAddSavedSearchMutation({
 *   variables: {
 *      savedSearch: // value for 'savedSearch'
 *   },
 * });
 */
export function useAddSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<AddSavedSearchMutation, AddSavedSearchMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSavedSearchMutation, AddSavedSearchMutationVariables>(AddSavedSearchDocument, options);
}
export type AddSavedSearchMutationHookResult = ReturnType<typeof useAddSavedSearchMutation>;
export type AddSavedSearchMutationResult = Apollo.MutationResult<AddSavedSearchMutation>;
export type AddSavedSearchMutationOptions = Apollo.BaseMutationOptions<AddSavedSearchMutation, AddSavedSearchMutationVariables>;
export const UpdateSavedSearchDocument = gql`
  mutation UpdateSavedSearch($id: ID!, $savedSearch: ContactsSavedSearchInput!) {
    updateSavedSearch(id: $id, input: $savedSearch) {
      id
      name
      searchText
      filterItems {
        fieldName
        value
      }
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type UpdateSavedSearchMutationFn = Apollo.MutationFunction<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>;

/**
 * __useUpdateSavedSearchMutation__
 *
 * To run a mutation, you first call `useUpdateSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedSearchMutation, { data, loading, error }] = useUpdateSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      savedSearch: // value for 'savedSearch'
 *   },
 * });
 */
export function useUpdateSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>(UpdateSavedSearchDocument, options);
}
export type UpdateSavedSearchMutationHookResult = ReturnType<typeof useUpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationResult = Apollo.MutationResult<UpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationOptions = Apollo.BaseMutationOptions<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>;
export const UpdateSavedSearchesDocument = gql`
  mutation UpdateSavedSearches($savedSearches: [ContactsSavedSearchWithIdInput!]!) {
    updateSavedSearches(input: $savedSearches) {
      id
      name
      priority
      searchText
      filterItems {
        fieldName
        value
      }
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type UpdateSavedSearchesMutationFn = Apollo.MutationFunction<UpdateSavedSearchesMutation, UpdateSavedSearchesMutationVariables>;

/**
 * __useUpdateSavedSearchesMutation__
 *
 * To run a mutation, you first call `useUpdateSavedSearchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedSearchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedSearchesMutation, { data, loading, error }] = useUpdateSavedSearchesMutation({
 *   variables: {
 *      savedSearches: // value for 'savedSearches'
 *   },
 * });
 */
export function useUpdateSavedSearchesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSavedSearchesMutation, UpdateSavedSearchesMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSavedSearchesMutation, UpdateSavedSearchesMutationVariables>(UpdateSavedSearchesDocument, options);
}
export type UpdateSavedSearchesMutationHookResult = ReturnType<typeof useUpdateSavedSearchesMutation>;
export type UpdateSavedSearchesMutationResult = Apollo.MutationResult<UpdateSavedSearchesMutation>;
export type UpdateSavedSearchesMutationOptions = Apollo.BaseMutationOptions<UpdateSavedSearchesMutation, UpdateSavedSearchesMutationVariables>;
export const RemoveSavedSearchDocument = gql`
  mutation RemoveSavedSearch($id: ID!) {
    removeSavedSearch(id: $id) {
      id
      name
      searchText
      filterItems {
        fieldName
        value
      }
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type RemoveSavedSearchMutationFn = Apollo.MutationFunction<RemoveSavedSearchMutation, RemoveSavedSearchMutationVariables>;

/**
 * __useRemoveSavedSearchMutation__
 *
 * To run a mutation, you first call `useRemoveSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSavedSearchMutation, { data, loading, error }] = useRemoveSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSavedSearchMutation, RemoveSavedSearchMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveSavedSearchMutation, RemoveSavedSearchMutationVariables>(RemoveSavedSearchDocument, options);
}
export type RemoveSavedSearchMutationHookResult = ReturnType<typeof useRemoveSavedSearchMutation>;
export type RemoveSavedSearchMutationResult = Apollo.MutationResult<RemoveSavedSearchMutation>;
export type RemoveSavedSearchMutationOptions = Apollo.BaseMutationOptions<RemoveSavedSearchMutation, RemoveSavedSearchMutationVariables>;
export const FetchPagedSavedSearchesDocument = gql`
  query FetchPagedSavedSearches($skip: Int!, $take: Int!, $searchText: String!, $filterItems: [ContactsFilterItemInput!]!) {
    savedSearches(skip: $skip, take: $take, searchText: $searchText, filterItems: $filterItems) {
      totalCount
      savedSearches {
        id
        name
        searchText
        priority
        count
        filterItems {
          id
          fieldName
          value
        }
        createdAt
        lastModifiedAt
        createdBy
      }
    }
  }
`;

/**
 * __useFetchPagedSavedSearchesQuery__
 *
 * To run a query within a React component, call `useFetchPagedSavedSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPagedSavedSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPagedSavedSearchesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *   },
 * });
 */
export function useFetchPagedSavedSearchesQuery(baseOptions: Apollo.QueryHookOptions<FetchPagedSavedSearchesQuery, FetchPagedSavedSearchesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPagedSavedSearchesQuery, FetchPagedSavedSearchesQueryVariables>(FetchPagedSavedSearchesDocument, options);
}
export function useFetchPagedSavedSearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPagedSavedSearchesQuery, FetchPagedSavedSearchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPagedSavedSearchesQuery, FetchPagedSavedSearchesQueryVariables>(FetchPagedSavedSearchesDocument, options);
}
export type FetchPagedSavedSearchesQueryHookResult = ReturnType<typeof useFetchPagedSavedSearchesQuery>;
export type FetchPagedSavedSearchesLazyQueryHookResult = ReturnType<typeof useFetchPagedSavedSearchesLazyQuery>;
export type FetchPagedSavedSearchesQueryResult = Apollo.QueryResult<FetchPagedSavedSearchesQuery, FetchPagedSavedSearchesQueryVariables>;
export const FetchSavedSearchByIdDocument = gql`
  query FetchSavedSearchById($id: ID!) {
    savedSearch(id: $id) {
      id
      name
      searchText
      priority
      count
      filterItems {
        id
        fieldName
        value
      }
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;

/**
 * __useFetchSavedSearchByIdQuery__
 *
 * To run a query within a React component, call `useFetchSavedSearchByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSavedSearchByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSavedSearchByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchSavedSearchByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchSavedSearchByIdQuery, FetchSavedSearchByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSavedSearchByIdQuery, FetchSavedSearchByIdQueryVariables>(FetchSavedSearchByIdDocument, options);
}
export function useFetchSavedSearchByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSavedSearchByIdQuery, FetchSavedSearchByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSavedSearchByIdQuery, FetchSavedSearchByIdQueryVariables>(FetchSavedSearchByIdDocument, options);
}
export type FetchSavedSearchByIdQueryHookResult = ReturnType<typeof useFetchSavedSearchByIdQuery>;
export type FetchSavedSearchByIdLazyQueryHookResult = ReturnType<typeof useFetchSavedSearchByIdLazyQuery>;
export type FetchSavedSearchByIdQueryResult = Apollo.QueryResult<FetchSavedSearchByIdQuery, FetchSavedSearchByIdQueryVariables>;
export const FetchSavedSearchesByIdsDocument = gql`
  query FetchSavedSearchesByIds($ids: [ID!]!) {
    savedSearchesByIds(ids: $ids) {
      id
      name
      searchText
      priority
      count
      filterItems {
        fieldName
        value
      }
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;

/**
 * __useFetchSavedSearchesByIdsQuery__
 *
 * To run a query within a React component, call `useFetchSavedSearchesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSavedSearchesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSavedSearchesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFetchSavedSearchesByIdsQuery(baseOptions: Apollo.QueryHookOptions<FetchSavedSearchesByIdsQuery, FetchSavedSearchesByIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSavedSearchesByIdsQuery, FetchSavedSearchesByIdsQueryVariables>(FetchSavedSearchesByIdsDocument, options);
}
export function useFetchSavedSearchesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSavedSearchesByIdsQuery, FetchSavedSearchesByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSavedSearchesByIdsQuery, FetchSavedSearchesByIdsQueryVariables>(FetchSavedSearchesByIdsDocument, options);
}
export type FetchSavedSearchesByIdsQueryHookResult = ReturnType<typeof useFetchSavedSearchesByIdsQuery>;
export type FetchSavedSearchesByIdsLazyQueryHookResult = ReturnType<typeof useFetchSavedSearchesByIdsLazyQuery>;
export type FetchSavedSearchesByIdsQueryResult = Apollo.QueryResult<FetchSavedSearchesByIdsQuery, FetchSavedSearchesByIdsQueryVariables>;
export const AddContactDocument = gql`
  mutation AddContact($input: ContactInput!) {
    addContact(input: $input) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      preferredLanguages {
        id
        name
        dataSourceKey
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;
export type AddContactMutationFn = Apollo.MutationFunction<AddContactMutation, AddContactMutationVariables>;

/**
 * __useAddContactMutation__
 *
 * To run a mutation, you first call `useAddContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactMutation, { data, loading, error }] = useAddContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactMutation(baseOptions?: Apollo.MutationHookOptions<AddContactMutation, AddContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddContactMutation, AddContactMutationVariables>(AddContactDocument, options);
}
export type AddContactMutationHookResult = ReturnType<typeof useAddContactMutation>;
export type AddContactMutationResult = Apollo.MutationResult<AddContactMutation>;
export type AddContactMutationOptions = Apollo.BaseMutationOptions<AddContactMutation, AddContactMutationVariables>;
export const RemoveContactsDocument = gql`
  mutation RemoveContacts($ids: [ID!]!) {
    removeContacts(ids: $ids) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      isGloballySignedOut
      tags {
        id
        name
      }
      topics {
        topicId
        topicName
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      comments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
      blacklists {
        id
        name
      }
      preferredLanguages {
        id
        name
        dataSourceKey
      }
      ownContactData {
        address
        country
        city
        postalCode
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
      }
      collaborations {
        id
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        address
        country
        city
        fax
        landlinePhoneNumber {
          value
          isPrimary
        }
        mobilePhoneNumber {
          value
          isPrimary
        }
        emailAddress
        blogUrl
        websiteUrl
        twitterProfileUrl
        instagramProfileUrl
        linkedInProfileUrl
        youtubeProfileUrl
        facebookProfileUrl
        isPrimary
        postalCode
      }
    }
  }
`;
export type RemoveContactsMutationFn = Apollo.MutationFunction<RemoveContactsMutation, RemoveContactsMutationVariables>;

/**
 * __useRemoveContactsMutation__
 *
 * To run a mutation, you first call `useRemoveContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactsMutation, { data, loading, error }] = useRemoveContactsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveContactsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactsMutation, RemoveContactsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContactsMutation, RemoveContactsMutationVariables>(RemoveContactsDocument, options);
}
export type RemoveContactsMutationHookResult = ReturnType<typeof useRemoveContactsMutation>;
export type RemoveContactsMutationResult = Apollo.MutationResult<RemoveContactsMutation>;
export type RemoveContactsMutationOptions = Apollo.BaseMutationOptions<RemoveContactsMutation, RemoveContactsMutationVariables>;
export const AddMediaResortDocument = gql`
  mutation AddMediaResort($input: MediaResortInput!) {
    addMediaResort(input: $input) {
      id
      name
      dataSourceKey
    }
  }
`;
export type AddMediaResortMutationFn = Apollo.MutationFunction<AddMediaResortMutation, AddMediaResortMutationVariables>;

/**
 * __useAddMediaResortMutation__
 *
 * To run a mutation, you first call `useAddMediaResortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaResortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaResortMutation, { data, loading, error }] = useAddMediaResortMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMediaResortMutation(baseOptions?: Apollo.MutationHookOptions<AddMediaResortMutation, AddMediaResortMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMediaResortMutation, AddMediaResortMutationVariables>(AddMediaResortDocument, options);
}
export type AddMediaResortMutationHookResult = ReturnType<typeof useAddMediaResortMutation>;
export type AddMediaResortMutationResult = Apollo.MutationResult<AddMediaResortMutation>;
export type AddMediaResortMutationOptions = Apollo.BaseMutationOptions<AddMediaResortMutation, AddMediaResortMutationVariables>;
export const AddTopicDocument = gql`
  mutation AddTopic($input: TopicInput!) {
    addTopic(input: $input) {
      id
      name
      dataSourceKey
    }
  }
`;
export type AddTopicMutationFn = Apollo.MutationFunction<AddTopicMutation, AddTopicMutationVariables>;

/**
 * __useAddTopicMutation__
 *
 * To run a mutation, you first call `useAddTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTopicMutation, { data, loading, error }] = useAddTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTopicMutation(baseOptions?: Apollo.MutationHookOptions<AddTopicMutation, AddTopicMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTopicMutation, AddTopicMutationVariables>(AddTopicDocument, options);
}
export type AddTopicMutationHookResult = ReturnType<typeof useAddTopicMutation>;
export type AddTopicMutationResult = Apollo.MutationResult<AddTopicMutation>;
export type AddTopicMutationOptions = Apollo.BaseMutationOptions<AddTopicMutation, AddTopicMutationVariables>;
export const AddMediaResortsToContactsDocument = gql`
  mutation AddMediaResortsToContacts($contactsIds: [ID!], $mediaResortsIds: [ID!]) {
    addMediaResortsToContacts(contactsIds: $contactsIds, mediaResortsIds: $mediaResortsIds)
  }
`;
export type AddMediaResortsToContactsMutationFn = Apollo.MutationFunction<AddMediaResortsToContactsMutation, AddMediaResortsToContactsMutationVariables>;

/**
 * __useAddMediaResortsToContactsMutation__
 *
 * To run a mutation, you first call `useAddMediaResortsToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaResortsToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaResortsToContactsMutation, { data, loading, error }] = useAddMediaResortsToContactsMutation({
 *   variables: {
 *      contactsIds: // value for 'contactsIds'
 *      mediaResortsIds: // value for 'mediaResortsIds'
 *   },
 * });
 */
export function useAddMediaResortsToContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddMediaResortsToContactsMutation, AddMediaResortsToContactsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMediaResortsToContactsMutation, AddMediaResortsToContactsMutationVariables>(AddMediaResortsToContactsDocument, options);
}
export type AddMediaResortsToContactsMutationHookResult = ReturnType<typeof useAddMediaResortsToContactsMutation>;
export type AddMediaResortsToContactsMutationResult = Apollo.MutationResult<AddMediaResortsToContactsMutation>;
export type AddMediaResortsToContactsMutationOptions = Apollo.BaseMutationOptions<
  AddMediaResortsToContactsMutation,
  AddMediaResortsToContactsMutationVariables
>;
export const AddTopicsToContactsDocument = gql`
  mutation AddTopicsToContacts($contactsIds: [ID!], $topicsIds: [ID!]) {
    addTopicsToContacts(contactsIds: $contactsIds, topicsIds: $topicsIds)
  }
`;
export type AddTopicsToContactsMutationFn = Apollo.MutationFunction<AddTopicsToContactsMutation, AddTopicsToContactsMutationVariables>;

/**
 * __useAddTopicsToContactsMutation__
 *
 * To run a mutation, you first call `useAddTopicsToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTopicsToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTopicsToContactsMutation, { data, loading, error }] = useAddTopicsToContactsMutation({
 *   variables: {
 *      contactsIds: // value for 'contactsIds'
 *      topicsIds: // value for 'topicsIds'
 *   },
 * });
 */
export function useAddTopicsToContactsMutation(baseOptions?: Apollo.MutationHookOptions<AddTopicsToContactsMutation, AddTopicsToContactsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTopicsToContactsMutation, AddTopicsToContactsMutationVariables>(AddTopicsToContactsDocument, options);
}
export type AddTopicsToContactsMutationHookResult = ReturnType<typeof useAddTopicsToContactsMutation>;
export type AddTopicsToContactsMutationResult = Apollo.MutationResult<AddTopicsToContactsMutation>;
export type AddTopicsToContactsMutationOptions = Apollo.BaseMutationOptions<AddTopicsToContactsMutation, AddTopicsToContactsMutationVariables>;
export const AddListsToContactsDocument = gql`
  mutation AddListsToContacts($contactsIds: [ID!], $listsIds: [ID!]) {
    addListsToContacts(contactsIds: $contactsIds, listsIds: $listsIds)
  }
`;
export type AddListsToContactsMutationFn = Apollo.MutationFunction<AddListsToContactsMutation, AddListsToContactsMutationVariables>;

/**
 * __useAddListsToContactsMutation__
 *
 * To run a mutation, you first call `useAddListsToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddListsToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addListsToContactsMutation, { data, loading, error }] = useAddListsToContactsMutation({
 *   variables: {
 *      contactsIds: // value for 'contactsIds'
 *      listsIds: // value for 'listsIds'
 *   },
 * });
 */
export function useAddListsToContactsMutation(baseOptions?: Apollo.MutationHookOptions<AddListsToContactsMutation, AddListsToContactsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddListsToContactsMutation, AddListsToContactsMutationVariables>(AddListsToContactsDocument, options);
}
export type AddListsToContactsMutationHookResult = ReturnType<typeof useAddListsToContactsMutation>;
export type AddListsToContactsMutationResult = Apollo.MutationResult<AddListsToContactsMutation>;
export type AddListsToContactsMutationOptions = Apollo.BaseMutationOptions<AddListsToContactsMutation, AddListsToContactsMutationVariables>;
export const AddJobTitleDocument = gql`
  mutation addJobTitle($input: JobTitleInput!) {
    addJobTitle(input: $input) {
      id
      name
      dataSourceKey
      mediumType {
        name
        value
      }
    }
  }
`;
export type AddJobTitleMutationFn = Apollo.MutationFunction<AddJobTitleMutation, AddJobTitleMutationVariables>;

/**
 * __useAddJobTitleMutation__
 *
 * To run a mutation, you first call `useAddJobTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobTitleMutation, { data, loading, error }] = useAddJobTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddJobTitleMutation(baseOptions?: Apollo.MutationHookOptions<AddJobTitleMutation, AddJobTitleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddJobTitleMutation, AddJobTitleMutationVariables>(AddJobTitleDocument, options);
}
export type AddJobTitleMutationHookResult = ReturnType<typeof useAddJobTitleMutation>;
export type AddJobTitleMutationResult = Apollo.MutationResult<AddJobTitleMutation>;
export type AddJobTitleMutationOptions = Apollo.BaseMutationOptions<AddJobTitleMutation, AddJobTitleMutationVariables>;
export const RemoveJobTitleDocument = gql`
  mutation removeJobTitle($jobTitleId: ID!) {
    removeJobTitle(jobTitleId: $jobTitleId)
  }
`;
export type RemoveJobTitleMutationFn = Apollo.MutationFunction<RemoveJobTitleMutation, RemoveJobTitleMutationVariables>;

/**
 * __useRemoveJobTitleMutation__
 *
 * To run a mutation, you first call `useRemoveJobTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJobTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJobTitleMutation, { data, loading, error }] = useRemoveJobTitleMutation({
 *   variables: {
 *      jobTitleId: // value for 'jobTitleId'
 *   },
 * });
 */
export function useRemoveJobTitleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveJobTitleMutation, RemoveJobTitleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveJobTitleMutation, RemoveJobTitleMutationVariables>(RemoveJobTitleDocument, options);
}
export type RemoveJobTitleMutationHookResult = ReturnType<typeof useRemoveJobTitleMutation>;
export type RemoveJobTitleMutationResult = Apollo.MutationResult<RemoveJobTitleMutation>;
export type RemoveJobTitleMutationOptions = Apollo.BaseMutationOptions<RemoveJobTitleMutation, RemoveJobTitleMutationVariables>;
export const FetchContactsBySearchTextDocument = gql`
  query FetchContactsBySearchText(
    $searchText: String!
    $filterItems: [ContactsFilterItemInput!]!
    $skip: Int!
    $take: Int!
    $sortingInput: ContactsSortingInput!
  ) {
    contacts(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take, sortingInput: $sortingInput) {
      totalCount
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        dataSourceKey
        isGloballySignedOut
        isUser
        preferredLanguages {
          id
          name
          dataSourceKey
        }
        tags {
          id
          name
        }
        topics {
          topicId
          topicName
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        comments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
        blacklists {
          id
          name
        }
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsBySearchTextQuery__
 *
 * To run a query within a React component, call `useFetchContactsBySearchTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsBySearchTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsBySearchTextQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortingInput: // value for 'sortingInput'
 *   },
 * });
 */
export function useFetchContactsBySearchTextQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsBySearchTextQuery, FetchContactsBySearchTextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsBySearchTextQuery, FetchContactsBySearchTextQueryVariables>(FetchContactsBySearchTextDocument, options);
}
export function useFetchContactsBySearchTextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsBySearchTextQuery, FetchContactsBySearchTextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsBySearchTextQuery, FetchContactsBySearchTextQueryVariables>(FetchContactsBySearchTextDocument, options);
}
export type FetchContactsBySearchTextQueryHookResult = ReturnType<typeof useFetchContactsBySearchTextQuery>;
export type FetchContactsBySearchTextLazyQueryHookResult = ReturnType<typeof useFetchContactsBySearchTextLazyQuery>;
export type FetchContactsBySearchTextQueryResult = Apollo.QueryResult<FetchContactsBySearchTextQuery, FetchContactsBySearchTextQueryVariables>;
export const FetchContactSearchSuggestionsDocument = gql`
  query FetchContactSearchSuggestions($searchText: String!) {
    searchSuggestions(searchText: $searchText) {
      searchCategoryName {
        name
        value
      }
      filterItemFieldName
      suggestionsList {
        text
        imageUrl
        subtext
      }
    }
  }
`;

/**
 * __useFetchContactSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useFetchContactSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactSearchSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useFetchContactSearchSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactSearchSuggestionsQuery, FetchContactSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactSearchSuggestionsQuery, FetchContactSearchSuggestionsQueryVariables>(FetchContactSearchSuggestionsDocument, options);
}
export function useFetchContactSearchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactSearchSuggestionsQuery, FetchContactSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactSearchSuggestionsQuery, FetchContactSearchSuggestionsQueryVariables>(FetchContactSearchSuggestionsDocument, options);
}
export type FetchContactSearchSuggestionsQueryHookResult = ReturnType<typeof useFetchContactSearchSuggestionsQuery>;
export type FetchContactSearchSuggestionsLazyQueryHookResult = ReturnType<typeof useFetchContactSearchSuggestionsLazyQuery>;
export type FetchContactSearchSuggestionsQueryResult = Apollo.QueryResult<FetchContactSearchSuggestionsQuery, FetchContactSearchSuggestionsQueryVariables>;
export const FetchContactAvatarDetailsDocument = gql`
  query FetchContactAvatarDetails(
    $searchText: String!
    $filterItems: [ContactsFilterItemInput!]!
    $skip: Int!
    $take: Int!
    $sortingInput: ContactsSortingInput!
  ) {
    contacts(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take, sortingInput: $sortingInput) {
      totalCount
      contacts {
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;

/**
 * __useFetchContactAvatarDetailsQuery__
 *
 * To run a query within a React component, call `useFetchContactAvatarDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactAvatarDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactAvatarDetailsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortingInput: // value for 'sortingInput'
 *   },
 * });
 */
export function useFetchContactAvatarDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactAvatarDetailsQuery, FetchContactAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactAvatarDetailsQuery, FetchContactAvatarDetailsQueryVariables>(FetchContactAvatarDetailsDocument, options);
}
export function useFetchContactAvatarDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactAvatarDetailsQuery, FetchContactAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactAvatarDetailsQuery, FetchContactAvatarDetailsQueryVariables>(FetchContactAvatarDetailsDocument, options);
}
export type FetchContactAvatarDetailsQueryHookResult = ReturnType<typeof useFetchContactAvatarDetailsQuery>;
export type FetchContactAvatarDetailsLazyQueryHookResult = ReturnType<typeof useFetchContactAvatarDetailsLazyQuery>;
export type FetchContactAvatarDetailsQueryResult = Apollo.QueryResult<FetchContactAvatarDetailsQuery, FetchContactAvatarDetailsQueryVariables>;
export const FetchBulkContactsAvatarDetailsDocument = gql`
  query FetchBulkContactsAvatarDetails($contactQueryInput: [ContactsQueryInput!]!) {
    contactsAvatarDetails(contactsQueryInput: $contactQueryInput) {
      savedSearchId
      pageOfContacts {
        totalCount
        contacts {
          id
          firstName
          lastName
          profilePictureUrl
        }
      }
    }
  }
`;

/**
 * __useFetchBulkContactsAvatarDetailsQuery__
 *
 * To run a query within a React component, call `useFetchBulkContactsAvatarDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBulkContactsAvatarDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBulkContactsAvatarDetailsQuery({
 *   variables: {
 *      contactQueryInput: // value for 'contactQueryInput'
 *   },
 * });
 */
export function useFetchBulkContactsAvatarDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchBulkContactsAvatarDetailsQuery, FetchBulkContactsAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchBulkContactsAvatarDetailsQuery, FetchBulkContactsAvatarDetailsQueryVariables>(FetchBulkContactsAvatarDetailsDocument, options);
}
export function useFetchBulkContactsAvatarDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchBulkContactsAvatarDetailsQuery, FetchBulkContactsAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchBulkContactsAvatarDetailsQuery, FetchBulkContactsAvatarDetailsQueryVariables>(
    FetchBulkContactsAvatarDetailsDocument,
    options
  );
}
export type FetchBulkContactsAvatarDetailsQueryHookResult = ReturnType<typeof useFetchBulkContactsAvatarDetailsQuery>;
export type FetchBulkContactsAvatarDetailsLazyQueryHookResult = ReturnType<typeof useFetchBulkContactsAvatarDetailsLazyQuery>;
export type FetchBulkContactsAvatarDetailsQueryResult = Apollo.QueryResult<FetchBulkContactsAvatarDetailsQuery, FetchBulkContactsAvatarDetailsQueryVariables>;
export const ContactNameFilterSuggestionsDocument = gql`
  query contactNameFilterSuggestions($searchText: String!, $take: Int!) {
    contactNameFilterSuggestions(searchText: $searchText, take: $take)
  }
`;

/**
 * __useContactNameFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useContactNameFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactNameFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactNameFilterSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useContactNameFilterSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<ContactNameFilterSuggestionsQuery, ContactNameFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactNameFilterSuggestionsQuery, ContactNameFilterSuggestionsQueryVariables>(ContactNameFilterSuggestionsDocument, options);
}
export function useContactNameFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactNameFilterSuggestionsQuery, ContactNameFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactNameFilterSuggestionsQuery, ContactNameFilterSuggestionsQueryVariables>(ContactNameFilterSuggestionsDocument, options);
}
export type ContactNameFilterSuggestionsQueryHookResult = ReturnType<typeof useContactNameFilterSuggestionsQuery>;
export type ContactNameFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useContactNameFilterSuggestionsLazyQuery>;
export type ContactNameFilterSuggestionsQueryResult = Apollo.QueryResult<ContactNameFilterSuggestionsQuery, ContactNameFilterSuggestionsQueryVariables>;
export const RoleFilterSuggestionsDocument = gql`
  query roleFilterSuggestions($searchText: String!, $take: Int!) {
    roleFilterSuggestions(searchText: $searchText, take: $take)
  }
`;

/**
 * __useRoleFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useRoleFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleFilterSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useRoleFilterSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<RoleFilterSuggestionsQuery, RoleFilterSuggestionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleFilterSuggestionsQuery, RoleFilterSuggestionsQueryVariables>(RoleFilterSuggestionsDocument, options);
}
export function useRoleFilterSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleFilterSuggestionsQuery, RoleFilterSuggestionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleFilterSuggestionsQuery, RoleFilterSuggestionsQueryVariables>(RoleFilterSuggestionsDocument, options);
}
export type RoleFilterSuggestionsQueryHookResult = ReturnType<typeof useRoleFilterSuggestionsQuery>;
export type RoleFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useRoleFilterSuggestionsLazyQuery>;
export type RoleFilterSuggestionsQueryResult = Apollo.QueryResult<RoleFilterSuggestionsQuery, RoleFilterSuggestionsQueryVariables>;
export const MediumNameFilterSuggestionsDocument = gql`
  query mediumNameFilterSuggestions($searchText: String!, $take: Int!) {
    mediumNameFilterSuggestions(searchText: $searchText, take: $take)
  }
`;

/**
 * __useMediumNameFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useMediumNameFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediumNameFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediumNameFilterSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediumNameFilterSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<MediumNameFilterSuggestionsQuery, MediumNameFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediumNameFilterSuggestionsQuery, MediumNameFilterSuggestionsQueryVariables>(MediumNameFilterSuggestionsDocument, options);
}
export function useMediumNameFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediumNameFilterSuggestionsQuery, MediumNameFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediumNameFilterSuggestionsQuery, MediumNameFilterSuggestionsQueryVariables>(MediumNameFilterSuggestionsDocument, options);
}
export type MediumNameFilterSuggestionsQueryHookResult = ReturnType<typeof useMediumNameFilterSuggestionsQuery>;
export type MediumNameFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useMediumNameFilterSuggestionsLazyQuery>;
export type MediumNameFilterSuggestionsQueryResult = Apollo.QueryResult<MediumNameFilterSuggestionsQuery, MediumNameFilterSuggestionsQueryVariables>;
export const ResortFilterSuggestionsDocument = gql`
  query resortFilterSuggestions($searchText: String!, $take: Int!) {
    resortFilterSuggestions(searchText: $searchText, take: $take)
  }
`;

/**
 * __useResortFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useResortFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResortFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResortFilterSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useResortFilterSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<ResortFilterSuggestionsQuery, ResortFilterSuggestionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResortFilterSuggestionsQuery, ResortFilterSuggestionsQueryVariables>(ResortFilterSuggestionsDocument, options);
}
export function useResortFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResortFilterSuggestionsQuery, ResortFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResortFilterSuggestionsQuery, ResortFilterSuggestionsQueryVariables>(ResortFilterSuggestionsDocument, options);
}
export type ResortFilterSuggestionsQueryHookResult = ReturnType<typeof useResortFilterSuggestionsQuery>;
export type ResortFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useResortFilterSuggestionsLazyQuery>;
export type ResortFilterSuggestionsQueryResult = Apollo.QueryResult<ResortFilterSuggestionsQuery, ResortFilterSuggestionsQueryVariables>;
export const LanguageFilterSuggestionsDocument = gql`
  query languageFilterSuggestions($searchText: String!, $take: Int!) {
    languageFilterSuggestions(searchText: $searchText, take: $take)
  }
`;

/**
 * __useLanguageFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useLanguageFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageFilterSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useLanguageFilterSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<LanguageFilterSuggestionsQuery, LanguageFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LanguageFilterSuggestionsQuery, LanguageFilterSuggestionsQueryVariables>(LanguageFilterSuggestionsDocument, options);
}
export function useLanguageFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LanguageFilterSuggestionsQuery, LanguageFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LanguageFilterSuggestionsQuery, LanguageFilterSuggestionsQueryVariables>(LanguageFilterSuggestionsDocument, options);
}
export type LanguageFilterSuggestionsQueryHookResult = ReturnType<typeof useLanguageFilterSuggestionsQuery>;
export type LanguageFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useLanguageFilterSuggestionsLazyQuery>;
export type LanguageFilterSuggestionsQueryResult = Apollo.QueryResult<LanguageFilterSuggestionsQuery, LanguageFilterSuggestionsQueryVariables>;
export const TagNameFilterSuggestionsDocument = gql`
  query tagNameFilterSuggestions($searchText: String!, $take: Int!) {
    tagNameFilterSuggestions(searchText: $searchText, take: $take)
  }
`;

/**
 * __useTagNameFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useTagNameFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagNameFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagNameFilterSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useTagNameFilterSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<TagNameFilterSuggestionsQuery, TagNameFilterSuggestionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagNameFilterSuggestionsQuery, TagNameFilterSuggestionsQueryVariables>(TagNameFilterSuggestionsDocument, options);
}
export function useTagNameFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagNameFilterSuggestionsQuery, TagNameFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagNameFilterSuggestionsQuery, TagNameFilterSuggestionsQueryVariables>(TagNameFilterSuggestionsDocument, options);
}
export type TagNameFilterSuggestionsQueryHookResult = ReturnType<typeof useTagNameFilterSuggestionsQuery>;
export type TagNameFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useTagNameFilterSuggestionsLazyQuery>;
export type TagNameFilterSuggestionsQueryResult = Apollo.QueryResult<TagNameFilterSuggestionsQuery, TagNameFilterSuggestionsQueryVariables>;
export const CountryFilterSuggestionsDocument = gql`
  query countryFilterSuggestions($searchText: String!, $take: Int!) {
    countryFilterSuggestions(searchText: $searchText, take: $take)
  }
`;

/**
 * __useCountryFilterSuggestionsQuery__
 *
 * To run a query within a React component, call `useCountryFilterSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryFilterSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryFilterSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCountryFilterSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<CountryFilterSuggestionsQuery, CountryFilterSuggestionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountryFilterSuggestionsQuery, CountryFilterSuggestionsQueryVariables>(CountryFilterSuggestionsDocument, options);
}
export function useCountryFilterSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountryFilterSuggestionsQuery, CountryFilterSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountryFilterSuggestionsQuery, CountryFilterSuggestionsQueryVariables>(CountryFilterSuggestionsDocument, options);
}
export type CountryFilterSuggestionsQueryHookResult = ReturnType<typeof useCountryFilterSuggestionsQuery>;
export type CountryFilterSuggestionsLazyQueryHookResult = ReturnType<typeof useCountryFilterSuggestionsLazyQuery>;
export type CountryFilterSuggestionsQueryResult = Apollo.QueryResult<CountryFilterSuggestionsQuery, CountryFilterSuggestionsQueryVariables>;
export const AddTagToListsDocument = gql`
  mutation AddTagToLists($listIds: [ID!]!, $input: ContactsTagInput!) {
    addTagToLists(listIds: $listIds, input: $input) {
      id
      name
    }
  }
`;
export type AddTagToListsMutationFn = Apollo.MutationFunction<AddTagToListsMutation, AddTagToListsMutationVariables>;

/**
 * __useAddTagToListsMutation__
 *
 * To run a mutation, you first call `useAddTagToListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToListsMutation, { data, loading, error }] = useAddTagToListsMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTagToListsMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToListsMutation, AddTagToListsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTagToListsMutation, AddTagToListsMutationVariables>(AddTagToListsDocument, options);
}
export type AddTagToListsMutationHookResult = ReturnType<typeof useAddTagToListsMutation>;
export type AddTagToListsMutationResult = Apollo.MutationResult<AddTagToListsMutation>;
export type AddTagToListsMutationOptions = Apollo.BaseMutationOptions<AddTagToListsMutation, AddTagToListsMutationVariables>;
export const RemoveTagFromListsDocument = gql`
  mutation RemoveTagFromLists($listIds: [ID!]!, $input: ContactsTagInput!) {
    removeTagFromLists(listIds: $listIds, input: $input) {
      id
      name
    }
  }
`;
export type RemoveTagFromListsMutationFn = Apollo.MutationFunction<RemoveTagFromListsMutation, RemoveTagFromListsMutationVariables>;

/**
 * __useRemoveTagFromListsMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromListsMutation, { data, loading, error }] = useRemoveTagFromListsMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTagFromListsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagFromListsMutation, RemoveTagFromListsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTagFromListsMutation, RemoveTagFromListsMutationVariables>(RemoveTagFromListsDocument, options);
}
export type RemoveTagFromListsMutationHookResult = ReturnType<typeof useRemoveTagFromListsMutation>;
export type RemoveTagFromListsMutationResult = Apollo.MutationResult<RemoveTagFromListsMutation>;
export type RemoveTagFromListsMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromListsMutation, RemoveTagFromListsMutationVariables>;
export const UpdateListProfilePictureDocument = gql`
  mutation UpdateListProfilePicture($listId: ID!, $input: ContactsProfilePictureInput!) {
    updateListProfilePicture(listId: $listId, input: $input)
  }
`;
export type UpdateListProfilePictureMutationFn = Apollo.MutationFunction<UpdateListProfilePictureMutation, UpdateListProfilePictureMutationVariables>;

/**
 * __useUpdateListProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateListProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListProfilePictureMutation, { data, loading, error }] = useUpdateListProfilePictureMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateListProfilePictureMutation, UpdateListProfilePictureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateListProfilePictureMutation, UpdateListProfilePictureMutationVariables>(UpdateListProfilePictureDocument, options);
}
export type UpdateListProfilePictureMutationHookResult = ReturnType<typeof useUpdateListProfilePictureMutation>;
export type UpdateListProfilePictureMutationResult = Apollo.MutationResult<UpdateListProfilePictureMutation>;
export type UpdateListProfilePictureMutationOptions = Apollo.BaseMutationOptions<UpdateListProfilePictureMutation, UpdateListProfilePictureMutationVariables>;
export const RemoveListProfilePictureDocument = gql`
  mutation RemoveListProfilePicture($listId: ID!) {
    removeListProfilePicture(listId: $listId)
  }
`;
export type RemoveListProfilePictureMutationFn = Apollo.MutationFunction<RemoveListProfilePictureMutation, RemoveListProfilePictureMutationVariables>;

/**
 * __useRemoveListProfilePictureMutation__
 *
 * To run a mutation, you first call `useRemoveListProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveListProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeListProfilePictureMutation, { data, loading, error }] = useRemoveListProfilePictureMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRemoveListProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveListProfilePictureMutation, RemoveListProfilePictureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveListProfilePictureMutation, RemoveListProfilePictureMutationVariables>(RemoveListProfilePictureDocument, options);
}
export type RemoveListProfilePictureMutationHookResult = ReturnType<typeof useRemoveListProfilePictureMutation>;
export type RemoveListProfilePictureMutationResult = Apollo.MutationResult<RemoveListProfilePictureMutation>;
export type RemoveListProfilePictureMutationOptions = Apollo.BaseMutationOptions<RemoveListProfilePictureMutation, RemoveListProfilePictureMutationVariables>;
export const AddContactsSavedSearchesToListDocument = gql`
  mutation AddContactsSavedSearchesToList($listId: ID!, $savedSearchesIds: [ID!]!) {
    addContactsSavedSearchesToList(listId: $listId, savedSearchesIds: $savedSearchesIds) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      linkedSavedSearches {
        savedSearch {
          id
          name
          lastModifiedAt
          createdAt
        }
        totalCountOfContacts
        shouldAutoUpdate
      }
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type AddContactsSavedSearchesToListMutationFn = Apollo.MutationFunction<
  AddContactsSavedSearchesToListMutation,
  AddContactsSavedSearchesToListMutationVariables
>;

/**
 * __useAddContactsSavedSearchesToListMutation__
 *
 * To run a mutation, you first call `useAddContactsSavedSearchesToListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactsSavedSearchesToListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactsSavedSearchesToListMutation, { data, loading, error }] = useAddContactsSavedSearchesToListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      savedSearchesIds: // value for 'savedSearchesIds'
 *   },
 * });
 */
export function useAddContactsSavedSearchesToListMutation(
  baseOptions?: Apollo.MutationHookOptions<AddContactsSavedSearchesToListMutation, AddContactsSavedSearchesToListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddContactsSavedSearchesToListMutation, AddContactsSavedSearchesToListMutationVariables>(
    AddContactsSavedSearchesToListDocument,
    options
  );
}
export type AddContactsSavedSearchesToListMutationHookResult = ReturnType<typeof useAddContactsSavedSearchesToListMutation>;
export type AddContactsSavedSearchesToListMutationResult = Apollo.MutationResult<AddContactsSavedSearchesToListMutation>;
export type AddContactsSavedSearchesToListMutationOptions = Apollo.BaseMutationOptions<
  AddContactsSavedSearchesToListMutation,
  AddContactsSavedSearchesToListMutationVariables
>;
export const RemoveContactsSavedSearchesFromListDocument = gql`
  mutation RemoveContactsSavedSearchesFromList($listId: ID!, $savedSearchesIds: [ID!]!) {
    removeContactsSavedSearchesFromList(listId: $listId, savedSearchesIds: $savedSearchesIds) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      linkedSavedSearches {
        savedSearch {
          id
          name
          lastModifiedAt
          createdAt
        }
        totalCountOfContacts
        shouldAutoUpdate
      }
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type RemoveContactsSavedSearchesFromListMutationFn = Apollo.MutationFunction<
  RemoveContactsSavedSearchesFromListMutation,
  RemoveContactsSavedSearchesFromListMutationVariables
>;

/**
 * __useRemoveContactsSavedSearchesFromListMutation__
 *
 * To run a mutation, you first call `useRemoveContactsSavedSearchesFromListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactsSavedSearchesFromListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactsSavedSearchesFromListMutation, { data, loading, error }] = useRemoveContactsSavedSearchesFromListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      savedSearchesIds: // value for 'savedSearchesIds'
 *   },
 * });
 */
export function useRemoveContactsSavedSearchesFromListMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContactsSavedSearchesFromListMutation, RemoveContactsSavedSearchesFromListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContactsSavedSearchesFromListMutation, RemoveContactsSavedSearchesFromListMutationVariables>(
    RemoveContactsSavedSearchesFromListDocument,
    options
  );
}
export type RemoveContactsSavedSearchesFromListMutationHookResult = ReturnType<typeof useRemoveContactsSavedSearchesFromListMutation>;
export type RemoveContactsSavedSearchesFromListMutationResult = Apollo.MutationResult<RemoveContactsSavedSearchesFromListMutation>;
export type RemoveContactsSavedSearchesFromListMutationOptions = Apollo.BaseMutationOptions<
  RemoveContactsSavedSearchesFromListMutation,
  RemoveContactsSavedSearchesFromListMutationVariables
>;
export const RefreshLinkedSavedSearchesDocument = gql`
  mutation RefreshLinkedSavedSearches($listId: ID!, $savedSearchesIds: [ID!]!) {
    refreshLinkedSavedSearches(listId: $listId, savedSearchesIds: $savedSearchesIds) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      linkedSavedSearches {
        savedSearch {
          id
          name
          lastModifiedAt
          createdAt
        }
        totalCountOfContacts
        shouldAutoUpdate
      }
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type RefreshLinkedSavedSearchesMutationFn = Apollo.MutationFunction<RefreshLinkedSavedSearchesMutation, RefreshLinkedSavedSearchesMutationVariables>;

/**
 * __useRefreshLinkedSavedSearchesMutation__
 *
 * To run a mutation, you first call `useRefreshLinkedSavedSearchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshLinkedSavedSearchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshLinkedSavedSearchesMutation, { data, loading, error }] = useRefreshLinkedSavedSearchesMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      savedSearchesIds: // value for 'savedSearchesIds'
 *   },
 * });
 */
export function useRefreshLinkedSavedSearchesMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshLinkedSavedSearchesMutation, RefreshLinkedSavedSearchesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshLinkedSavedSearchesMutation, RefreshLinkedSavedSearchesMutationVariables>(RefreshLinkedSavedSearchesDocument, options);
}
export type RefreshLinkedSavedSearchesMutationHookResult = ReturnType<typeof useRefreshLinkedSavedSearchesMutation>;
export type RefreshLinkedSavedSearchesMutationResult = Apollo.MutationResult<RefreshLinkedSavedSearchesMutation>;
export type RefreshLinkedSavedSearchesMutationOptions = Apollo.BaseMutationOptions<
  RefreshLinkedSavedSearchesMutation,
  RefreshLinkedSavedSearchesMutationVariables
>;
export const UpdateContactsSavedSearchDocument = gql`
  mutation UpdateContactsSavedSearch($listId: ID!, $savedSearchId: ID!, $shouldAutoUpdate: Boolean!) {
    updateContactsSavedSearch(listId: $listId, savedSearchId: $savedSearchId, shouldAutoUpdate: $shouldAutoUpdate) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      linkedSavedSearches {
        savedSearch {
          id
          name
          lastModifiedAt
          createdAt
        }
        totalCountOfContacts
        shouldAutoUpdate
      }
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type UpdateContactsSavedSearchMutationFn = Apollo.MutationFunction<UpdateContactsSavedSearchMutation, UpdateContactsSavedSearchMutationVariables>;

/**
 * __useUpdateContactsSavedSearchMutation__
 *
 * To run a mutation, you first call `useUpdateContactsSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactsSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactsSavedSearchMutation, { data, loading, error }] = useUpdateContactsSavedSearchMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      savedSearchId: // value for 'savedSearchId'
 *      shouldAutoUpdate: // value for 'shouldAutoUpdate'
 *   },
 * });
 */
export function useUpdateContactsSavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactsSavedSearchMutation, UpdateContactsSavedSearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactsSavedSearchMutation, UpdateContactsSavedSearchMutationVariables>(UpdateContactsSavedSearchDocument, options);
}
export type UpdateContactsSavedSearchMutationHookResult = ReturnType<typeof useUpdateContactsSavedSearchMutation>;
export type UpdateContactsSavedSearchMutationResult = Apollo.MutationResult<UpdateContactsSavedSearchMutation>;
export type UpdateContactsSavedSearchMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactsSavedSearchMutation,
  UpdateContactsSavedSearchMutationVariables
>;
export const UpdateListDocument = gql`
  mutation UpdateList($listId: ID!, $input: ContactsListInputType!) {
    updateList(listId: $listId, input: $input) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      linkedSavedSearches {
        savedSearch {
          id
          name
          lastModifiedAt
          createdAt
        }
        totalCountOfContacts
        shouldAutoUpdate
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type UpdateListMutationFn = Apollo.MutationFunction<UpdateListMutation, UpdateListMutationVariables>;

/**
 * __useUpdateListMutation__
 *
 * To run a mutation, you first call `useUpdateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListMutation, { data, loading, error }] = useUpdateListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListMutation, UpdateListMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateListMutation, UpdateListMutationVariables>(UpdateListDocument, options);
}
export type UpdateListMutationHookResult = ReturnType<typeof useUpdateListMutation>;
export type UpdateListMutationResult = Apollo.MutationResult<UpdateListMutation>;
export type UpdateListMutationOptions = Apollo.BaseMutationOptions<UpdateListMutation, UpdateListMutationVariables>;
export const RemoveContactsFromListDocument = gql`
  mutation RemoveContactsFromList($listId: ID!, $contactsIds: [ID!]!) {
    removeContactsFromList(listId: $listId, contactsIds: $contactsIds) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        dataSourceKey
        isGloballySignedOut
        isUser
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;
export type RemoveContactsFromListMutationFn = Apollo.MutationFunction<RemoveContactsFromListMutation, RemoveContactsFromListMutationVariables>;

/**
 * __useRemoveContactsFromListMutation__
 *
 * To run a mutation, you first call `useRemoveContactsFromListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactsFromListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactsFromListMutation, { data, loading, error }] = useRemoveContactsFromListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      contactsIds: // value for 'contactsIds'
 *   },
 * });
 */
export function useRemoveContactsFromListMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContactsFromListMutation, RemoveContactsFromListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContactsFromListMutation, RemoveContactsFromListMutationVariables>(RemoveContactsFromListDocument, options);
}
export type RemoveContactsFromListMutationHookResult = ReturnType<typeof useRemoveContactsFromListMutation>;
export type RemoveContactsFromListMutationResult = Apollo.MutationResult<RemoveContactsFromListMutation>;
export type RemoveContactsFromListMutationOptions = Apollo.BaseMutationOptions<RemoveContactsFromListMutation, RemoveContactsFromListMutationVariables>;
export const AddAadContactsDocument = gql`
  mutation AddAadContacts($input: [AadContactInput!]!) {
    addAadContacts(input: $input) {
      id
      salutation
      firstName
      lastName
      profilePictureUrl
      ownContactData {
        isPrimary
        emailAddress
      }
      collaborations {
        id
        isPrimary
        emailAddress
        medium {
          id
          name
          type {
            name
            value
          }
        }
        jobDescription
        jobTitle {
          id
          name
          dataSourceKey
          mediumType {
            name
            value
          }
        }
        emailAddress
        isPrimary
      }
    }
  }
`;
export type AddAadContactsMutationFn = Apollo.MutationFunction<AddAadContactsMutation, AddAadContactsMutationVariables>;

/**
 * __useAddAadContactsMutation__
 *
 * To run a mutation, you first call `useAddAadContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAadContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAadContactsMutation, { data, loading, error }] = useAddAadContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAadContactsMutation(baseOptions?: Apollo.MutationHookOptions<AddAadContactsMutation, AddAadContactsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAadContactsMutation, AddAadContactsMutationVariables>(AddAadContactsDocument, options);
}
export type AddAadContactsMutationHookResult = ReturnType<typeof useAddAadContactsMutation>;
export type AddAadContactsMutationResult = Apollo.MutationResult<AddAadContactsMutation>;
export type AddAadContactsMutationOptions = Apollo.BaseMutationOptions<AddAadContactsMutation, AddAadContactsMutationVariables>;
export const AddContactsToListDocument = gql`
  mutation AddContactsToList($listId: ID!, $input: [ListContactInput!]!) {
    addContactsToList(listId: $listId, input: $input) {
      id
      listId
      mailingAddressCompanyId
      contact {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        dataSourceKey
        isGloballySignedOut
        isUser
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;
export type AddContactsToListMutationFn = Apollo.MutationFunction<AddContactsToListMutation, AddContactsToListMutationVariables>;

/**
 * __useAddContactsToListMutation__
 *
 * To run a mutation, you first call `useAddContactsToListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactsToListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactsToListMutation, { data, loading, error }] = useAddContactsToListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactsToListMutation(baseOptions?: Apollo.MutationHookOptions<AddContactsToListMutation, AddContactsToListMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddContactsToListMutation, AddContactsToListMutationVariables>(AddContactsToListDocument, options);
}
export type AddContactsToListMutationHookResult = ReturnType<typeof useAddContactsToListMutation>;
export type AddContactsToListMutationResult = Apollo.MutationResult<AddContactsToListMutation>;
export type AddContactsToListMutationOptions = Apollo.BaseMutationOptions<AddContactsToListMutation, AddContactsToListMutationVariables>;
export const UpdateContactInListDocument = gql`
  mutation UpdateContactInList($listContactId: ID!, $input: ListContactInput!) {
    updateListContact(listContactId: $listContactId, input: $input) {
      id
      contact {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        dataSourceKey
        isGloballySignedOut
        isUser
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
      listId
      isExcluded
      mailingAddressCompanyId
      createdAt
      createdBy
      lastModifiedAt
      lastModifiedBy
    }
  }
`;
export type UpdateContactInListMutationFn = Apollo.MutationFunction<UpdateContactInListMutation, UpdateContactInListMutationVariables>;

/**
 * __useUpdateContactInListMutation__
 *
 * To run a mutation, you first call `useUpdateContactInListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactInListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactInListMutation, { data, loading, error }] = useUpdateContactInListMutation({
 *   variables: {
 *      listContactId: // value for 'listContactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactInListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactInListMutation, UpdateContactInListMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactInListMutation, UpdateContactInListMutationVariables>(UpdateContactInListDocument, options);
}
export type UpdateContactInListMutationHookResult = ReturnType<typeof useUpdateContactInListMutation>;
export type UpdateContactInListMutationResult = Apollo.MutationResult<UpdateContactInListMutation>;
export type UpdateContactInListMutationOptions = Apollo.BaseMutationOptions<UpdateContactInListMutation, UpdateContactInListMutationVariables>;
export const AddAadContactsToListDocument = gql`
  mutation AddAadContactsToList($listId: ID!, $input: [AadContactInput!]!) {
    addAadContactsToList(listId: $listId, input: $input)
  }
`;
export type AddAadContactsToListMutationFn = Apollo.MutationFunction<AddAadContactsToListMutation, AddAadContactsToListMutationVariables>;

/**
 * __useAddAadContactsToListMutation__
 *
 * To run a mutation, you first call `useAddAadContactsToListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAadContactsToListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAadContactsToListMutation, { data, loading, error }] = useAddAadContactsToListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAadContactsToListMutation(baseOptions?: Apollo.MutationHookOptions<AddAadContactsToListMutation, AddAadContactsToListMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAadContactsToListMutation, AddAadContactsToListMutationVariables>(AddAadContactsToListDocument, options);
}
export type AddAadContactsToListMutationHookResult = ReturnType<typeof useAddAadContactsToListMutation>;
export type AddAadContactsToListMutationResult = Apollo.MutationResult<AddAadContactsToListMutation>;
export type AddAadContactsToListMutationOptions = Apollo.BaseMutationOptions<AddAadContactsToListMutation, AddAadContactsToListMutationVariables>;
export const FetchListDocument = gql`
  query FetchList($id: ID!) {
    list(id: $id) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      mediumNames
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      linkedSavedSearches {
        savedSearch {
          id
          name
          lastModifiedAt
          createdAt
        }
        totalCountOfContacts
        shouldAutoUpdate
      }
    }
  }
`;

/**
 * __useFetchListQuery__
 *
 * To run a query within a React component, call `useFetchListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchListQuery(baseOptions: Apollo.QueryHookOptions<FetchListQuery, FetchListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchListQuery, FetchListQueryVariables>(FetchListDocument, options);
}
export function useFetchListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchListQuery, FetchListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchListQuery, FetchListQueryVariables>(FetchListDocument, options);
}
export type FetchListQueryHookResult = ReturnType<typeof useFetchListQuery>;
export type FetchListLazyQueryHookResult = ReturnType<typeof useFetchListLazyQuery>;
export type FetchListQueryResult = Apollo.QueryResult<FetchListQuery, FetchListQueryVariables>;
export const FetchContactsByListIdAndSearchTextDocument = gql`
  query FetchContactsByListIdAndSearchText($skip: Int!, $take: Int!, $searchText: String!, $listId: ID!) {
    contactsByListIdAndSearchText(skip: $skip, take: $take, searchText: $searchText, listId: $listId) {
      totalCount
      listContacts {
        id
        listId
        contact {
          id
          salutation
          firstName
          lastName
          profilePictureUrl
          dataSourceKey
          isGloballySignedOut
          isUser
          ownContactData {
            address
            country
            city
            postalCode
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
          }
          collaborations {
            id
            medium {
              id
              name
              type {
                name
                value
              }
            }
            jobDescription
            jobTitle {
              id
              name
              dataSourceKey
              mediumType {
                name
                value
              }
            }
            address
            country
            city
            fax
            landlinePhoneNumber {
              value
              isPrimary
            }
            mobilePhoneNumber {
              value
              isPrimary
            }
            emailAddress
            blogUrl
            websiteUrl
            twitterProfileUrl
            instagramProfileUrl
            linkedInProfileUrl
            youtubeProfileUrl
            facebookProfileUrl
            isPrimary
            postalCode
          }
        }
        mailingAddressCompanyId
      }
    }
  }
`;

/**
 * __useFetchContactsByListIdAndSearchTextQuery__
 *
 * To run a query within a React component, call `useFetchContactsByListIdAndSearchTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByListIdAndSearchTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByListIdAndSearchTextQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchText: // value for 'searchText'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchContactsByListIdAndSearchTextQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsByListIdAndSearchTextQuery, FetchContactsByListIdAndSearchTextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByListIdAndSearchTextQuery, FetchContactsByListIdAndSearchTextQueryVariables>(
    FetchContactsByListIdAndSearchTextDocument,
    options
  );
}
export function useFetchContactsByListIdAndSearchTextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsByListIdAndSearchTextQuery, FetchContactsByListIdAndSearchTextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByListIdAndSearchTextQuery, FetchContactsByListIdAndSearchTextQueryVariables>(
    FetchContactsByListIdAndSearchTextDocument,
    options
  );
}
export type FetchContactsByListIdAndSearchTextQueryHookResult = ReturnType<typeof useFetchContactsByListIdAndSearchTextQuery>;
export type FetchContactsByListIdAndSearchTextLazyQueryHookResult = ReturnType<typeof useFetchContactsByListIdAndSearchTextLazyQuery>;
export type FetchContactsByListIdAndSearchTextQueryResult = Apollo.QueryResult<
  FetchContactsByListIdAndSearchTextQuery,
  FetchContactsByListIdAndSearchTextQueryVariables
>;
export const FetchContactsByIdsPagedDocument = gql`
  query FetchContactsByIdsPaged($skip: Int!, $take: Int!, $contactsIds: [ID]!) {
    contactsByIdsPaged(skip: $skip, take: $take, contactsIds: $contactsIds) {
      totalCount
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        isGloballySignedOut
        tags {
          id
          name
        }
        topics {
          topicId
          topicName
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        comments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
        blacklists {
          id
          name
        }
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsByIdsPagedQuery__
 *
 * To run a query within a React component, call `useFetchContactsByIdsPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByIdsPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByIdsPagedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      contactsIds: // value for 'contactsIds'
 *   },
 * });
 */
export function useFetchContactsByIdsPagedQuery(baseOptions: Apollo.QueryHookOptions<FetchContactsByIdsPagedQuery, FetchContactsByIdsPagedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByIdsPagedQuery, FetchContactsByIdsPagedQueryVariables>(FetchContactsByIdsPagedDocument, options);
}
export function useFetchContactsByIdsPagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsByIdsPagedQuery, FetchContactsByIdsPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByIdsPagedQuery, FetchContactsByIdsPagedQueryVariables>(FetchContactsByIdsPagedDocument, options);
}
export type FetchContactsByIdsPagedQueryHookResult = ReturnType<typeof useFetchContactsByIdsPagedQuery>;
export type FetchContactsByIdsPagedLazyQueryHookResult = ReturnType<typeof useFetchContactsByIdsPagedLazyQuery>;
export type FetchContactsByIdsPagedQueryResult = Apollo.QueryResult<FetchContactsByIdsPagedQuery, FetchContactsByIdsPagedQueryVariables>;
export const FetchContactsBySearchTextAndNotContainedInListDocument = gql`
  query FetchContactsBySearchTextAndNotContainedInList($skip: Int!, $take: Int!, $searchText: String!, $listId: ID!) {
    contactsBySearchTextAndNotContainedInList(skip: $skip, take: $take, searchText: $searchText, listId: $listId) {
      totalCount
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        isGloballySignedOut
        tags {
          id
          name
        }
        topics {
          topicId
          topicName
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        comments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
        blacklists {
          id
          name
        }
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsBySearchTextAndNotContainedInListQuery__
 *
 * To run a query within a React component, call `useFetchContactsBySearchTextAndNotContainedInListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsBySearchTextAndNotContainedInListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsBySearchTextAndNotContainedInListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchText: // value for 'searchText'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchContactsBySearchTextAndNotContainedInListQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsBySearchTextAndNotContainedInListQuery, FetchContactsBySearchTextAndNotContainedInListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsBySearchTextAndNotContainedInListQuery, FetchContactsBySearchTextAndNotContainedInListQueryVariables>(
    FetchContactsBySearchTextAndNotContainedInListDocument,
    options
  );
}
export function useFetchContactsBySearchTextAndNotContainedInListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsBySearchTextAndNotContainedInListQuery, FetchContactsBySearchTextAndNotContainedInListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsBySearchTextAndNotContainedInListQuery, FetchContactsBySearchTextAndNotContainedInListQueryVariables>(
    FetchContactsBySearchTextAndNotContainedInListDocument,
    options
  );
}
export type FetchContactsBySearchTextAndNotContainedInListQueryHookResult = ReturnType<typeof useFetchContactsBySearchTextAndNotContainedInListQuery>;
export type FetchContactsBySearchTextAndNotContainedInListLazyQueryHookResult = ReturnType<typeof useFetchContactsBySearchTextAndNotContainedInListLazyQuery>;
export type FetchContactsBySearchTextAndNotContainedInListQueryResult = Apollo.QueryResult<
  FetchContactsBySearchTextAndNotContainedInListQuery,
  FetchContactsBySearchTextAndNotContainedInListQueryVariables
>;
export const FetchAadContactsDocument = gql`
  query FetchAADContacts($searchText: String!, $take: Int!) {
    aadContacts(searchText: $searchText, take: $take) {
      objectId
      contactId
      firstName
      lastName
      email
      defaultCompany {
        id
        name
      }
    }
  }
`;

/**
 * __useFetchAadContactsQuery__
 *
 * To run a query within a React component, call `useFetchAadContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAadContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAadContactsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFetchAadContactsQuery(baseOptions: Apollo.QueryHookOptions<FetchAadContactsQuery, FetchAadContactsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAadContactsQuery, FetchAadContactsQueryVariables>(FetchAadContactsDocument, options);
}
export function useFetchAadContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAadContactsQuery, FetchAadContactsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAadContactsQuery, FetchAadContactsQueryVariables>(FetchAadContactsDocument, options);
}
export type FetchAadContactsQueryHookResult = ReturnType<typeof useFetchAadContactsQuery>;
export type FetchAadContactsLazyQueryHookResult = ReturnType<typeof useFetchAadContactsLazyQuery>;
export type FetchAadContactsQueryResult = Apollo.QueryResult<FetchAadContactsQuery, FetchAadContactsQueryVariables>;
export const FetchAadGroupsDocument = gql`
  query FetchAADGroups($searchText: String!, $take: Int!) {
    aadGroups(searchText: $searchText, take: $take) {
      objectId
      name
      members {
        objectId
        contactId
        firstName
        lastName
        email
        defaultCompany {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useFetchAadGroupsQuery__
 *
 * To run a query within a React component, call `useFetchAadGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAadGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAadGroupsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFetchAadGroupsQuery(baseOptions: Apollo.QueryHookOptions<FetchAadGroupsQuery, FetchAadGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAadGroupsQuery, FetchAadGroupsQueryVariables>(FetchAadGroupsDocument, options);
}
export function useFetchAadGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAadGroupsQuery, FetchAadGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAadGroupsQuery, FetchAadGroupsQueryVariables>(FetchAadGroupsDocument, options);
}
export type FetchAadGroupsQueryHookResult = ReturnType<typeof useFetchAadGroupsQuery>;
export type FetchAadGroupsLazyQueryHookResult = ReturnType<typeof useFetchAadGroupsLazyQuery>;
export type FetchAadGroupsQueryResult = Apollo.QueryResult<FetchAadGroupsQuery, FetchAadGroupsQueryVariables>;
export const FetchAadGroupMembersDocument = gql`
  query FetchAADGroupMembers($groupId: String!) {
    aadGroupMembers(groupId: $groupId) {
      objectId
      contactId
      firstName
      lastName
      email
      defaultCompany {
        id
        name
      }
    }
  }
`;

/**
 * __useFetchAadGroupMembersQuery__
 *
 * To run a query within a React component, call `useFetchAadGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAadGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAadGroupMembersQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useFetchAadGroupMembersQuery(baseOptions: Apollo.QueryHookOptions<FetchAadGroupMembersQuery, FetchAadGroupMembersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAadGroupMembersQuery, FetchAadGroupMembersQueryVariables>(FetchAadGroupMembersDocument, options);
}
export function useFetchAadGroupMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAadGroupMembersQuery, FetchAadGroupMembersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAadGroupMembersQuery, FetchAadGroupMembersQueryVariables>(FetchAadGroupMembersDocument, options);
}
export type FetchAadGroupMembersQueryHookResult = ReturnType<typeof useFetchAadGroupMembersQuery>;
export type FetchAadGroupMembersLazyQueryHookResult = ReturnType<typeof useFetchAadGroupMembersLazyQuery>;
export type FetchAadGroupMembersQueryResult = Apollo.QueryResult<FetchAadGroupMembersQuery, FetchAadGroupMembersQueryVariables>;
export const FetchListsByNameCreatorAndDateDocument = gql`
  query FetchListsByNameCreatorAndDate($name: String!, $createdBy: String!, $dateFrom: DateTime!, $dateTo: DateTime!) {
    listsByNameCreatorAndDate(name: $name, createdBy: $createdBy, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;

/**
 * __useFetchListsByNameCreatorAndDateQuery__
 *
 * To run a query within a React component, call `useFetchListsByNameCreatorAndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchListsByNameCreatorAndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchListsByNameCreatorAndDateQuery({
 *   variables: {
 *      name: // value for 'name'
 *      createdBy: // value for 'createdBy'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useFetchListsByNameCreatorAndDateQuery(
  baseOptions: Apollo.QueryHookOptions<FetchListsByNameCreatorAndDateQuery, FetchListsByNameCreatorAndDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchListsByNameCreatorAndDateQuery, FetchListsByNameCreatorAndDateQueryVariables>(FetchListsByNameCreatorAndDateDocument, options);
}
export function useFetchListsByNameCreatorAndDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchListsByNameCreatorAndDateQuery, FetchListsByNameCreatorAndDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchListsByNameCreatorAndDateQuery, FetchListsByNameCreatorAndDateQueryVariables>(
    FetchListsByNameCreatorAndDateDocument,
    options
  );
}
export type FetchListsByNameCreatorAndDateQueryHookResult = ReturnType<typeof useFetchListsByNameCreatorAndDateQuery>;
export type FetchListsByNameCreatorAndDateLazyQueryHookResult = ReturnType<typeof useFetchListsByNameCreatorAndDateLazyQuery>;
export type FetchListsByNameCreatorAndDateQueryResult = Apollo.QueryResult<FetchListsByNameCreatorAndDateQuery, FetchListsByNameCreatorAndDateQueryVariables>;
export const FetchSendingOverviewsByListIdDocument = gql`
  query FetchSendingOverviewsByListId($listId: ID!) {
    sendingOverviewsByListId(listId: $listId) {
      sendingId
      sendingStatus
      numberOfDeliveredRecipients
      totalNumberOfRecipients
      totalNumberOfOpenings
      totalNumberOfClickedLinks
      totalNumberOfFailedDeliveries
      numberOfOpenedSendings
      numberOfUnsubscribedRecipients
      distributionDurationSeconds
      deliveredPercentage
      openingsRatePercentage
      clickRatePercentage
      title
      createdAt
    }
  }
`;

/**
 * __useFetchSendingOverviewsByListIdQuery__
 *
 * To run a query within a React component, call `useFetchSendingOverviewsByListIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSendingOverviewsByListIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSendingOverviewsByListIdQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchSendingOverviewsByListIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchSendingOverviewsByListIdQuery, FetchSendingOverviewsByListIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSendingOverviewsByListIdQuery, FetchSendingOverviewsByListIdQueryVariables>(FetchSendingOverviewsByListIdDocument, options);
}
export function useFetchSendingOverviewsByListIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSendingOverviewsByListIdQuery, FetchSendingOverviewsByListIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchSendingOverviewsByListIdQuery, FetchSendingOverviewsByListIdQueryVariables>(FetchSendingOverviewsByListIdDocument, options);
}
export type FetchSendingOverviewsByListIdQueryHookResult = ReturnType<typeof useFetchSendingOverviewsByListIdQuery>;
export type FetchSendingOverviewsByListIdLazyQueryHookResult = ReturnType<typeof useFetchSendingOverviewsByListIdLazyQuery>;
export type FetchSendingOverviewsByListIdQueryResult = Apollo.QueryResult<FetchSendingOverviewsByListIdQuery, FetchSendingOverviewsByListIdQueryVariables>;
export const FetchLinkedSavedSearchesByListIdDocument = gql`
  query FetchLinkedSavedSearchesByListId($listId: ID!) {
    linkedSavedSearchesByListId(listId: $listId) {
      savedSearch {
        id
        name
        lastModifiedAt
        createdAt
      }
      totalCountOfContacts
      shouldAutoUpdate
    }
  }
`;

/**
 * __useFetchLinkedSavedSearchesByListIdQuery__
 *
 * To run a query within a React component, call `useFetchLinkedSavedSearchesByListIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLinkedSavedSearchesByListIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLinkedSavedSearchesByListIdQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchLinkedSavedSearchesByListIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchLinkedSavedSearchesByListIdQuery, FetchLinkedSavedSearchesByListIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchLinkedSavedSearchesByListIdQuery, FetchLinkedSavedSearchesByListIdQueryVariables>(
    FetchLinkedSavedSearchesByListIdDocument,
    options
  );
}
export function useFetchLinkedSavedSearchesByListIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchLinkedSavedSearchesByListIdQuery, FetchLinkedSavedSearchesByListIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchLinkedSavedSearchesByListIdQuery, FetchLinkedSavedSearchesByListIdQueryVariables>(
    FetchLinkedSavedSearchesByListIdDocument,
    options
  );
}
export type FetchLinkedSavedSearchesByListIdQueryHookResult = ReturnType<typeof useFetchLinkedSavedSearchesByListIdQuery>;
export type FetchLinkedSavedSearchesByListIdLazyQueryHookResult = ReturnType<typeof useFetchLinkedSavedSearchesByListIdLazyQuery>;
export type FetchLinkedSavedSearchesByListIdQueryResult = Apollo.QueryResult<
  FetchLinkedSavedSearchesByListIdQuery,
  FetchLinkedSavedSearchesByListIdQueryVariables
>;
export const FetchContactsSavedSearchesResultsDocument = gql`
  query FetchContactsSavedSearchesResults($contactsQueryInput: [ContactsQueryInput!]!) {
    contactsSavedSearchesResults(contactsQueryInput: $contactsQueryInput) {
      savedSearchId
      contacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;

/**
 * __useFetchContactsSavedSearchesResultsQuery__
 *
 * To run a query within a React component, call `useFetchContactsSavedSearchesResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsSavedSearchesResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsSavedSearchesResultsQuery({
 *   variables: {
 *      contactsQueryInput: // value for 'contactsQueryInput'
 *   },
 * });
 */
export function useFetchContactsSavedSearchesResultsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsSavedSearchesResultsQuery, FetchContactsSavedSearchesResultsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsSavedSearchesResultsQuery, FetchContactsSavedSearchesResultsQueryVariables>(
    FetchContactsSavedSearchesResultsDocument,
    options
  );
}
export function useFetchContactsSavedSearchesResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsSavedSearchesResultsQuery, FetchContactsSavedSearchesResultsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsSavedSearchesResultsQuery, FetchContactsSavedSearchesResultsQueryVariables>(
    FetchContactsSavedSearchesResultsDocument,
    options
  );
}
export type FetchContactsSavedSearchesResultsQueryHookResult = ReturnType<typeof useFetchContactsSavedSearchesResultsQuery>;
export type FetchContactsSavedSearchesResultsLazyQueryHookResult = ReturnType<typeof useFetchContactsSavedSearchesResultsLazyQuery>;
export type FetchContactsSavedSearchesResultsQueryResult = Apollo.QueryResult<
  FetchContactsSavedSearchesResultsQuery,
  FetchContactsSavedSearchesResultsQueryVariables
>;
export const FetchContactInListByListIdDocument = gql`
  query FetchContactInListByListId($listId: ID!) {
    listContactsByListId(listId: $listId) {
      id
      contact {
        id
        firstName
        lastName
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
      listId
      isExcluded
      mailingAddressCompanyId
      createdAt
      createdBy
      lastModifiedAt
      lastModifiedBy
    }
  }
`;

/**
 * __useFetchContactInListByListIdQuery__
 *
 * To run a query within a React component, call `useFetchContactInListByListIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactInListByListIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactInListByListIdQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchContactInListByListIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactInListByListIdQuery, FetchContactInListByListIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactInListByListIdQuery, FetchContactInListByListIdQueryVariables>(FetchContactInListByListIdDocument, options);
}
export function useFetchContactInListByListIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactInListByListIdQuery, FetchContactInListByListIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactInListByListIdQuery, FetchContactInListByListIdQueryVariables>(FetchContactInListByListIdDocument, options);
}
export type FetchContactInListByListIdQueryHookResult = ReturnType<typeof useFetchContactInListByListIdQuery>;
export type FetchContactInListByListIdLazyQueryHookResult = ReturnType<typeof useFetchContactInListByListIdLazyQuery>;
export type FetchContactInListByListIdQueryResult = Apollo.QueryResult<FetchContactInListByListIdQuery, FetchContactInListByListIdQueryVariables>;
export const FetchListsByNameDocument = gql`
  query FetchListsByName($name: String!, $skip: Int!, $take: Int!) {
    listsByName(name: $name, skip: $skip, take: $take) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;

/**
 * __useFetchListsByNameQuery__
 *
 * To run a query within a React component, call `useFetchListsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchListsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchListsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFetchListsByNameQuery(baseOptions: Apollo.QueryHookOptions<FetchListsByNameQuery, FetchListsByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchListsByNameQuery, FetchListsByNameQueryVariables>(FetchListsByNameDocument, options);
}
export function useFetchListsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchListsByNameQuery, FetchListsByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchListsByNameQuery, FetchListsByNameQueryVariables>(FetchListsByNameDocument, options);
}
export type FetchListsByNameQueryHookResult = ReturnType<typeof useFetchListsByNameQuery>;
export type FetchListsByNameLazyQueryHookResult = ReturnType<typeof useFetchListsByNameLazyQuery>;
export type FetchListsByNameQueryResult = Apollo.QueryResult<FetchListsByNameQuery, FetchListsByNameQueryVariables>;
export const FetchContactsByListIdDocument = gql`
  query FetchContactsByListId($listId: ID!) {
    contactsByListId(listId: $listId) {
      id
      listId
      mailingAddressCompanyId
      contact {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsByListIdQuery__
 *
 * To run a query within a React component, call `useFetchContactsByListIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByListIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByListIdQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchContactsByListIdQuery(baseOptions: Apollo.QueryHookOptions<FetchContactsByListIdQuery, FetchContactsByListIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByListIdQuery, FetchContactsByListIdQueryVariables>(FetchContactsByListIdDocument, options);
}
export function useFetchContactsByListIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsByListIdQuery, FetchContactsByListIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByListIdQuery, FetchContactsByListIdQueryVariables>(FetchContactsByListIdDocument, options);
}
export type FetchContactsByListIdQueryHookResult = ReturnType<typeof useFetchContactsByListIdQuery>;
export type FetchContactsByListIdLazyQueryHookResult = ReturnType<typeof useFetchContactsByListIdLazyQuery>;
export type FetchContactsByListIdQueryResult = Apollo.QueryResult<FetchContactsByListIdQuery, FetchContactsByListIdQueryVariables>;
export const ExportContactsFromListToCsvDocument = gql`
  query ExportContactsFromListToCsv($listId: ID!, $csvHeader: CsvHeader!) {
    exportContactsFromListToCsv(listId: $listId, csvHeader: $csvHeader)
  }
`;

/**
 * __useExportContactsFromListToCsvQuery__
 *
 * To run a query within a React component, call `useExportContactsFromListToCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportContactsFromListToCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportContactsFromListToCsvQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *      csvHeader: // value for 'csvHeader'
 *   },
 * });
 */
export function useExportContactsFromListToCsvQuery(
  baseOptions: Apollo.QueryHookOptions<ExportContactsFromListToCsvQuery, ExportContactsFromListToCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportContactsFromListToCsvQuery, ExportContactsFromListToCsvQueryVariables>(ExportContactsFromListToCsvDocument, options);
}
export function useExportContactsFromListToCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportContactsFromListToCsvQuery, ExportContactsFromListToCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportContactsFromListToCsvQuery, ExportContactsFromListToCsvQueryVariables>(ExportContactsFromListToCsvDocument, options);
}
export type ExportContactsFromListToCsvQueryHookResult = ReturnType<typeof useExportContactsFromListToCsvQuery>;
export type ExportContactsFromListToCsvLazyQueryHookResult = ReturnType<typeof useExportContactsFromListToCsvLazyQuery>;
export type ExportContactsFromListToCsvQueryResult = Apollo.QueryResult<ExportContactsFromListToCsvQuery, ExportContactsFromListToCsvQueryVariables>;
export const ExportVCardsForContactsFromListDocument = gql`
  query exportVCardsForContactsFromList($listId: ID!) {
    exportVCardsForContactsFromList(listId: $listId)
  }
`;

/**
 * __useExportVCardsForContactsFromListQuery__
 *
 * To run a query within a React component, call `useExportVCardsForContactsFromListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportVCardsForContactsFromListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportVCardsForContactsFromListQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useExportVCardsForContactsFromListQuery(
  baseOptions: Apollo.QueryHookOptions<ExportVCardsForContactsFromListQuery, ExportVCardsForContactsFromListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportVCardsForContactsFromListQuery, ExportVCardsForContactsFromListQueryVariables>(ExportVCardsForContactsFromListDocument, options);
}
export function useExportVCardsForContactsFromListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportVCardsForContactsFromListQuery, ExportVCardsForContactsFromListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportVCardsForContactsFromListQuery, ExportVCardsForContactsFromListQueryVariables>(
    ExportVCardsForContactsFromListDocument,
    options
  );
}
export type ExportVCardsForContactsFromListQueryHookResult = ReturnType<typeof useExportVCardsForContactsFromListQuery>;
export type ExportVCardsForContactsFromListLazyQueryHookResult = ReturnType<typeof useExportVCardsForContactsFromListLazyQuery>;
export type ExportVCardsForContactsFromListQueryResult = Apollo.QueryResult<
  ExportVCardsForContactsFromListQuery,
  ExportVCardsForContactsFromListQueryVariables
>;
export const FetchNumberOfDesynchronizedContactsDocument = gql`
  query FetchNumberOfDesynchronizedContacts($listId: ID!) {
    numberOfDesynchronizedContactsInList(listId: $listId)
  }
`;

/**
 * __useFetchNumberOfDesynchronizedContactsQuery__
 *
 * To run a query within a React component, call `useFetchNumberOfDesynchronizedContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNumberOfDesynchronizedContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNumberOfDesynchronizedContactsQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchNumberOfDesynchronizedContactsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchNumberOfDesynchronizedContactsQuery, FetchNumberOfDesynchronizedContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchNumberOfDesynchronizedContactsQuery, FetchNumberOfDesynchronizedContactsQueryVariables>(
    FetchNumberOfDesynchronizedContactsDocument,
    options
  );
}
export function useFetchNumberOfDesynchronizedContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchNumberOfDesynchronizedContactsQuery, FetchNumberOfDesynchronizedContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchNumberOfDesynchronizedContactsQuery, FetchNumberOfDesynchronizedContactsQueryVariables>(
    FetchNumberOfDesynchronizedContactsDocument,
    options
  );
}
export type FetchNumberOfDesynchronizedContactsQueryHookResult = ReturnType<typeof useFetchNumberOfDesynchronizedContactsQuery>;
export type FetchNumberOfDesynchronizedContactsLazyQueryHookResult = ReturnType<typeof useFetchNumberOfDesynchronizedContactsLazyQuery>;
export type FetchNumberOfDesynchronizedContactsQueryResult = Apollo.QueryResult<
  FetchNumberOfDesynchronizedContactsQuery,
  FetchNumberOfDesynchronizedContactsQueryVariables
>;
export const FetchMediumNamesByListIdDocument = gql`
  query FetchMediumNamesByListId($listId: ID!) {
    mediumNamesByListId(listId: $listId)
  }
`;

/**
 * __useFetchMediumNamesByListIdQuery__
 *
 * To run a query within a React component, call `useFetchMediumNamesByListIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediumNamesByListIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediumNamesByListIdQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useFetchMediumNamesByListIdQuery(baseOptions: Apollo.QueryHookOptions<FetchMediumNamesByListIdQuery, FetchMediumNamesByListIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediumNamesByListIdQuery, FetchMediumNamesByListIdQueryVariables>(FetchMediumNamesByListIdDocument, options);
}
export function useFetchMediumNamesByListIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMediumNamesByListIdQuery, FetchMediumNamesByListIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediumNamesByListIdQuery, FetchMediumNamesByListIdQueryVariables>(FetchMediumNamesByListIdDocument, options);
}
export type FetchMediumNamesByListIdQueryHookResult = ReturnType<typeof useFetchMediumNamesByListIdQuery>;
export type FetchMediumNamesByListIdLazyQueryHookResult = ReturnType<typeof useFetchMediumNamesByListIdLazyQuery>;
export type FetchMediumNamesByListIdQueryResult = Apollo.QueryResult<FetchMediumNamesByListIdQuery, FetchMediumNamesByListIdQueryVariables>;
export const AddListDocument = gql`
  mutation AddList($input: ContactsListInputType!) {
    addList(input: $input) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type AddListMutationFn = Apollo.MutationFunction<AddListMutation, AddListMutationVariables>;

/**
 * __useAddListMutation__
 *
 * To run a mutation, you first call `useAddListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addListMutation, { data, loading, error }] = useAddListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddListMutation(baseOptions?: Apollo.MutationHookOptions<AddListMutation, AddListMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddListMutation, AddListMutationVariables>(AddListDocument, options);
}
export type AddListMutationHookResult = ReturnType<typeof useAddListMutation>;
export type AddListMutationResult = Apollo.MutationResult<AddListMutation>;
export type AddListMutationOptions = Apollo.BaseMutationOptions<AddListMutation, AddListMutationVariables>;
export const RemoveListDocument = gql`
  mutation RemoveList($id: ID!) {
    removeList(id: $id) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type RemoveListMutationFn = Apollo.MutationFunction<RemoveListMutation, RemoveListMutationVariables>;

/**
 * __useRemoveListMutation__
 *
 * To run a mutation, you first call `useRemoveListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeListMutation, { data, loading, error }] = useRemoveListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveListMutation, RemoveListMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveListMutation, RemoveListMutationVariables>(RemoveListDocument, options);
}
export type RemoveListMutationHookResult = ReturnType<typeof useRemoveListMutation>;
export type RemoveListMutationResult = Apollo.MutationResult<RemoveListMutation>;
export type RemoveListMutationOptions = Apollo.BaseMutationOptions<RemoveListMutation, RemoveListMutationVariables>;
export const DuplicateListsDocument = gql`
  mutation DuplicateLists($listsIds: [ID!]!) {
    duplicateLists(listsIds: $listsIds) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type DuplicateListsMutationFn = Apollo.MutationFunction<DuplicateListsMutation, DuplicateListsMutationVariables>;

/**
 * __useDuplicateListsMutation__
 *
 * To run a mutation, you first call `useDuplicateListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateListsMutation, { data, loading, error }] = useDuplicateListsMutation({
 *   variables: {
 *      listsIds: // value for 'listsIds'
 *   },
 * });
 */
export function useDuplicateListsMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateListsMutation, DuplicateListsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateListsMutation, DuplicateListsMutationVariables>(DuplicateListsDocument, options);
}
export type DuplicateListsMutationHookResult = ReturnType<typeof useDuplicateListsMutation>;
export type DuplicateListsMutationResult = Apollo.MutationResult<DuplicateListsMutation>;
export type DuplicateListsMutationOptions = Apollo.BaseMutationOptions<DuplicateListsMutation, DuplicateListsMutationVariables>;
export const RemoveListsDocument = gql`
  mutation RemoveLists($ids: [ID!]!) {
    removeLists(ids: $ids) {
      id
      name
      imageUrl
      description
      createdAt
      lastModifiedAt
      campaign
      createdBy
      lastModifiedBy
      numberOfContacts
      tags {
        id
        name
      }
      createdByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
      lastModifiedByContact {
        id
        firstName
        lastName
        profilePictureUrl
      }
    }
  }
`;
export type RemoveListsMutationFn = Apollo.MutationFunction<RemoveListsMutation, RemoveListsMutationVariables>;

/**
 * __useRemoveListsMutation__
 *
 * To run a mutation, you first call `useRemoveListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeListsMutation, { data, loading, error }] = useRemoveListsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveListsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveListsMutation, RemoveListsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveListsMutation, RemoveListsMutationVariables>(RemoveListsDocument, options);
}
export type RemoveListsMutationHookResult = ReturnType<typeof useRemoveListsMutation>;
export type RemoveListsMutationResult = Apollo.MutationResult<RemoveListsMutation>;
export type RemoveListsMutationOptions = Apollo.BaseMutationOptions<RemoveListsMutation, RemoveListsMutationVariables>;
export const BulkRefreshLinkedSavedSearchesDocument = gql`
  mutation BulkRefreshLinkedSavedSearches($listsIds: [ID!]!) {
    bulkRefreshLinkedSavedSearches(listsIds: $listsIds) {
      id
      name
      numberOfContacts
    }
  }
`;
export type BulkRefreshLinkedSavedSearchesMutationFn = Apollo.MutationFunction<
  BulkRefreshLinkedSavedSearchesMutation,
  BulkRefreshLinkedSavedSearchesMutationVariables
>;

/**
 * __useBulkRefreshLinkedSavedSearchesMutation__
 *
 * To run a mutation, you first call `useBulkRefreshLinkedSavedSearchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkRefreshLinkedSavedSearchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkRefreshLinkedSavedSearchesMutation, { data, loading, error }] = useBulkRefreshLinkedSavedSearchesMutation({
 *   variables: {
 *      listsIds: // value for 'listsIds'
 *   },
 * });
 */
export function useBulkRefreshLinkedSavedSearchesMutation(
  baseOptions?: Apollo.MutationHookOptions<BulkRefreshLinkedSavedSearchesMutation, BulkRefreshLinkedSavedSearchesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkRefreshLinkedSavedSearchesMutation, BulkRefreshLinkedSavedSearchesMutationVariables>(
    BulkRefreshLinkedSavedSearchesDocument,
    options
  );
}
export type BulkRefreshLinkedSavedSearchesMutationHookResult = ReturnType<typeof useBulkRefreshLinkedSavedSearchesMutation>;
export type BulkRefreshLinkedSavedSearchesMutationResult = Apollo.MutationResult<BulkRefreshLinkedSavedSearchesMutation>;
export type BulkRefreshLinkedSavedSearchesMutationOptions = Apollo.BaseMutationOptions<
  BulkRefreshLinkedSavedSearchesMutation,
  BulkRefreshLinkedSavedSearchesMutationVariables
>;
export const FetchListsByQueryParamsPagedDocument = gql`
  query FetchListsByQueryParamsPaged(
    $searchText: String!
    $filterItems: [ContactsFilterItemInput!]!
    $skip: Int!
    $take: Int!
    $sortingInput: ListsSortingInput!
  ) {
    lists(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take, sortingInput: $sortingInput) {
      totalCount
      lists {
        id
        name
        imageUrl
        description
        createdAt
        lastModifiedAt
        campaign
        createdBy
        lastModifiedBy
        numberOfContacts
        mediumNames
        tags {
          id
          name
        }
        createdByContact {
          id
          firstName
          lastName
          profilePictureUrl
        }
        lastModifiedByContact {
          id
          firstName
          lastName
          profilePictureUrl
        }
      }
    }
  }
`;

/**
 * __useFetchListsByQueryParamsPagedQuery__
 *
 * To run a query within a React component, call `useFetchListsByQueryParamsPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchListsByQueryParamsPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchListsByQueryParamsPagedQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortingInput: // value for 'sortingInput'
 *   },
 * });
 */
export function useFetchListsByQueryParamsPagedQuery(
  baseOptions: Apollo.QueryHookOptions<FetchListsByQueryParamsPagedQuery, FetchListsByQueryParamsPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchListsByQueryParamsPagedQuery, FetchListsByQueryParamsPagedQueryVariables>(FetchListsByQueryParamsPagedDocument, options);
}
export function useFetchListsByQueryParamsPagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchListsByQueryParamsPagedQuery, FetchListsByQueryParamsPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchListsByQueryParamsPagedQuery, FetchListsByQueryParamsPagedQueryVariables>(FetchListsByQueryParamsPagedDocument, options);
}
export type FetchListsByQueryParamsPagedQueryHookResult = ReturnType<typeof useFetchListsByQueryParamsPagedQuery>;
export type FetchListsByQueryParamsPagedLazyQueryHookResult = ReturnType<typeof useFetchListsByQueryParamsPagedLazyQuery>;
export type FetchListsByQueryParamsPagedQueryResult = Apollo.QueryResult<FetchListsByQueryParamsPagedQuery, FetchListsByQueryParamsPagedQueryVariables>;
export const FetchListsSearchSuggestionsDocument = gql`
  query FetchListsSearchSuggestions($searchText: String!) {
    searchListsSuggestions(searchText: $searchText) {
      searchCategoryName {
        name
        value
      }
      filterItemFieldName
      suggestionsList {
        text
        imageUrl
        subtext
      }
    }
  }
`;

/**
 * __useFetchListsSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useFetchListsSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchListsSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchListsSearchSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useFetchListsSearchSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchListsSearchSuggestionsQuery, FetchListsSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchListsSearchSuggestionsQuery, FetchListsSearchSuggestionsQueryVariables>(FetchListsSearchSuggestionsDocument, options);
}
export function useFetchListsSearchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchListsSearchSuggestionsQuery, FetchListsSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchListsSearchSuggestionsQuery, FetchListsSearchSuggestionsQueryVariables>(FetchListsSearchSuggestionsDocument, options);
}
export type FetchListsSearchSuggestionsQueryHookResult = ReturnType<typeof useFetchListsSearchSuggestionsQuery>;
export type FetchListsSearchSuggestionsLazyQueryHookResult = ReturnType<typeof useFetchListsSearchSuggestionsLazyQuery>;
export type FetchListsSearchSuggestionsQueryResult = Apollo.QueryResult<FetchListsSearchSuggestionsQuery, FetchListsSearchSuggestionsQueryVariables>;
export const ListSuggestionsByNameDocument = gql`
  query ListSuggestionsByName($name: String!, $take: Int!) {
    listsSuggestionsByName(name: $name, take: $take)
  }
`;

/**
 * __useListSuggestionsByNameQuery__
 *
 * To run a query within a React component, call `useListSuggestionsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuggestionsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuggestionsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListSuggestionsByNameQuery(baseOptions: Apollo.QueryHookOptions<ListSuggestionsByNameQuery, ListSuggestionsByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSuggestionsByNameQuery, ListSuggestionsByNameQueryVariables>(ListSuggestionsByNameDocument, options);
}
export function useListSuggestionsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSuggestionsByNameQuery, ListSuggestionsByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSuggestionsByNameQuery, ListSuggestionsByNameQueryVariables>(ListSuggestionsByNameDocument, options);
}
export type ListSuggestionsByNameQueryHookResult = ReturnType<typeof useListSuggestionsByNameQuery>;
export type ListSuggestionsByNameLazyQueryHookResult = ReturnType<typeof useListSuggestionsByNameLazyQuery>;
export type ListSuggestionsByNameQueryResult = Apollo.QueryResult<ListSuggestionsByNameQuery, ListSuggestionsByNameQueryVariables>;
export const ListSuggestionsByContactNameDocument = gql`
  query ListSuggestionsByContactName($name: String!, $take: Int!) {
    listsSuggestionsByContactName(name: $name, take: $take)
  }
`;

/**
 * __useListSuggestionsByContactNameQuery__
 *
 * To run a query within a React component, call `useListSuggestionsByContactNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuggestionsByContactNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuggestionsByContactNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListSuggestionsByContactNameQuery(
  baseOptions: Apollo.QueryHookOptions<ListSuggestionsByContactNameQuery, ListSuggestionsByContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSuggestionsByContactNameQuery, ListSuggestionsByContactNameQueryVariables>(ListSuggestionsByContactNameDocument, options);
}
export function useListSuggestionsByContactNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSuggestionsByContactNameQuery, ListSuggestionsByContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSuggestionsByContactNameQuery, ListSuggestionsByContactNameQueryVariables>(ListSuggestionsByContactNameDocument, options);
}
export type ListSuggestionsByContactNameQueryHookResult = ReturnType<typeof useListSuggestionsByContactNameQuery>;
export type ListSuggestionsByContactNameLazyQueryHookResult = ReturnType<typeof useListSuggestionsByContactNameLazyQuery>;
export type ListSuggestionsByContactNameQueryResult = Apollo.QueryResult<ListSuggestionsByContactNameQuery, ListSuggestionsByContactNameQueryVariables>;
export const ListSuggestionsByMediumNameDocument = gql`
  query ListSuggestionsByMediumName($name: String!, $take: Int!) {
    listsSuggestionsByMediumName(name: $name, take: $take)
  }
`;

/**
 * __useListSuggestionsByMediumNameQuery__
 *
 * To run a query within a React component, call `useListSuggestionsByMediumNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuggestionsByMediumNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuggestionsByMediumNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListSuggestionsByMediumNameQuery(
  baseOptions: Apollo.QueryHookOptions<ListSuggestionsByMediumNameQuery, ListSuggestionsByMediumNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSuggestionsByMediumNameQuery, ListSuggestionsByMediumNameQueryVariables>(ListSuggestionsByMediumNameDocument, options);
}
export function useListSuggestionsByMediumNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSuggestionsByMediumNameQuery, ListSuggestionsByMediumNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSuggestionsByMediumNameQuery, ListSuggestionsByMediumNameQueryVariables>(ListSuggestionsByMediumNameDocument, options);
}
export type ListSuggestionsByMediumNameQueryHookResult = ReturnType<typeof useListSuggestionsByMediumNameQuery>;
export type ListSuggestionsByMediumNameLazyQueryHookResult = ReturnType<typeof useListSuggestionsByMediumNameLazyQuery>;
export type ListSuggestionsByMediumNameQueryResult = Apollo.QueryResult<ListSuggestionsByMediumNameQuery, ListSuggestionsByMediumNameQueryVariables>;
export const ListSuggestionsByTagNameDocument = gql`
  query ListSuggestionsByTagName($name: String!, $take: Int!) {
    listsSuggestionsByTagName(name: $name, take: $take)
  }
`;

/**
 * __useListSuggestionsByTagNameQuery__
 *
 * To run a query within a React component, call `useListSuggestionsByTagNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuggestionsByTagNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuggestionsByTagNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListSuggestionsByTagNameQuery(baseOptions: Apollo.QueryHookOptions<ListSuggestionsByTagNameQuery, ListSuggestionsByTagNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSuggestionsByTagNameQuery, ListSuggestionsByTagNameQueryVariables>(ListSuggestionsByTagNameDocument, options);
}
export function useListSuggestionsByTagNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSuggestionsByTagNameQuery, ListSuggestionsByTagNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSuggestionsByTagNameQuery, ListSuggestionsByTagNameQueryVariables>(ListSuggestionsByTagNameDocument, options);
}
export type ListSuggestionsByTagNameQueryHookResult = ReturnType<typeof useListSuggestionsByTagNameQuery>;
export type ListSuggestionsByTagNameLazyQueryHookResult = ReturnType<typeof useListSuggestionsByTagNameLazyQuery>;
export type ListSuggestionsByTagNameQueryResult = Apollo.QueryResult<ListSuggestionsByTagNameQuery, ListSuggestionsByTagNameQueryVariables>;
export const ListSuggestionsByCreatedByDocument = gql`
  query ListSuggestionsByCreatedBy($name: String!, $take: Int!) {
    listsSuggestionsByCreatedBy(name: $name, take: $take)
  }
`;

/**
 * __useListSuggestionsByCreatedByQuery__
 *
 * To run a query within a React component, call `useListSuggestionsByCreatedByQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuggestionsByCreatedByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuggestionsByCreatedByQuery({
 *   variables: {
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListSuggestionsByCreatedByQuery(
  baseOptions: Apollo.QueryHookOptions<ListSuggestionsByCreatedByQuery, ListSuggestionsByCreatedByQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSuggestionsByCreatedByQuery, ListSuggestionsByCreatedByQueryVariables>(ListSuggestionsByCreatedByDocument, options);
}
export function useListSuggestionsByCreatedByLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSuggestionsByCreatedByQuery, ListSuggestionsByCreatedByQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSuggestionsByCreatedByQuery, ListSuggestionsByCreatedByQueryVariables>(ListSuggestionsByCreatedByDocument, options);
}
export type ListSuggestionsByCreatedByQueryHookResult = ReturnType<typeof useListSuggestionsByCreatedByQuery>;
export type ListSuggestionsByCreatedByLazyQueryHookResult = ReturnType<typeof useListSuggestionsByCreatedByLazyQuery>;
export type ListSuggestionsByCreatedByQueryResult = Apollo.QueryResult<ListSuggestionsByCreatedByQuery, ListSuggestionsByCreatedByQueryVariables>;
export const AddCommentToMediaOutletDocument = gql`
  mutation AddCommentToMediaOutlet($mediaOutletId: ID!, $input: MediaOutletsCommentInput!) {
    addCommentToMediaOutlet(mediaOutletId: $mediaOutletId, input: $input) {
      id
      author {
        teamsId
        name
        aadObjectId
      }
      content
      createdAt
      mentions {
        teamsId
        name
        aadObjectId
      }
      replies {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
      }
    }
  }
`;
export type AddCommentToMediaOutletMutationFn = Apollo.MutationFunction<AddCommentToMediaOutletMutation, AddCommentToMediaOutletMutationVariables>;

/**
 * __useAddCommentToMediaOutletMutation__
 *
 * To run a mutation, you first call `useAddCommentToMediaOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentToMediaOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentToMediaOutletMutation, { data, loading, error }] = useAddCommentToMediaOutletMutation({
 *   variables: {
 *      mediaOutletId: // value for 'mediaOutletId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentToMediaOutletMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCommentToMediaOutletMutation, AddCommentToMediaOutletMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCommentToMediaOutletMutation, AddCommentToMediaOutletMutationVariables>(AddCommentToMediaOutletDocument, options);
}
export type AddCommentToMediaOutletMutationHookResult = ReturnType<typeof useAddCommentToMediaOutletMutation>;
export type AddCommentToMediaOutletMutationResult = Apollo.MutationResult<AddCommentToMediaOutletMutation>;
export type AddCommentToMediaOutletMutationOptions = Apollo.BaseMutationOptions<AddCommentToMediaOutletMutation, AddCommentToMediaOutletMutationVariables>;
export const AddTagToMediaOutletsDocument = gql`
  mutation AddTagToMediaOutlets($mediaOutletIds: [ID!]!, $input: MediaOutletsTagInput!) {
    addTagToMediaOutlets(mediaOutletIds: $mediaOutletIds, input: $input) {
      id
      name
    }
  }
`;
export type AddTagToMediaOutletsMutationFn = Apollo.MutationFunction<AddTagToMediaOutletsMutation, AddTagToMediaOutletsMutationVariables>;

/**
 * __useAddTagToMediaOutletsMutation__
 *
 * To run a mutation, you first call `useAddTagToMediaOutletsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToMediaOutletsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToMediaOutletsMutation, { data, loading, error }] = useAddTagToMediaOutletsMutation({
 *   variables: {
 *      mediaOutletIds: // value for 'mediaOutletIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTagToMediaOutletsMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToMediaOutletsMutation, AddTagToMediaOutletsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTagToMediaOutletsMutation, AddTagToMediaOutletsMutationVariables>(AddTagToMediaOutletsDocument, options);
}
export type AddTagToMediaOutletsMutationHookResult = ReturnType<typeof useAddTagToMediaOutletsMutation>;
export type AddTagToMediaOutletsMutationResult = Apollo.MutationResult<AddTagToMediaOutletsMutation>;
export type AddTagToMediaOutletsMutationOptions = Apollo.BaseMutationOptions<AddTagToMediaOutletsMutation, AddTagToMediaOutletsMutationVariables>;
export const RemoveTagFromMediaOutletsDocument = gql`
  mutation RemoveTagFromMediaOutlets($mediaOutletIds: [ID!]!, $input: MediaOutletsTagInput!) {
    removeTagFromMediaOutlets(mediaOutletIds: $mediaOutletIds, input: $input) {
      id
      name
    }
  }
`;
export type RemoveTagFromMediaOutletsMutationFn = Apollo.MutationFunction<RemoveTagFromMediaOutletsMutation, RemoveTagFromMediaOutletsMutationVariables>;

/**
 * __useRemoveTagFromMediaOutletsMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromMediaOutletsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromMediaOutletsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromMediaOutletsMutation, { data, loading, error }] = useRemoveTagFromMediaOutletsMutation({
 *   variables: {
 *      mediaOutletIds: // value for 'mediaOutletIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTagFromMediaOutletsMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTagFromMediaOutletsMutation, RemoveTagFromMediaOutletsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTagFromMediaOutletsMutation, RemoveTagFromMediaOutletsMutationVariables>(RemoveTagFromMediaOutletsDocument, options);
}
export type RemoveTagFromMediaOutletsMutationHookResult = ReturnType<typeof useRemoveTagFromMediaOutletsMutation>;
export type RemoveTagFromMediaOutletsMutationResult = Apollo.MutationResult<RemoveTagFromMediaOutletsMutation>;
export type RemoveTagFromMediaOutletsMutationOptions = Apollo.BaseMutationOptions<
  RemoveTagFromMediaOutletsMutation,
  RemoveTagFromMediaOutletsMutationVariables
>;
export const UpdateMediaOutletProfilePictureDocument = gql`
  mutation UpdateMediaOutletProfilePicture($mediaOutletId: ID!, $input: MediaOutletsProfilePictureInput!) {
    updateMediaOutletProfilePicture(mediaOutletId: $mediaOutletId, input: $input)
  }
`;
export type UpdateMediaOutletProfilePictureMutationFn = Apollo.MutationFunction<
  UpdateMediaOutletProfilePictureMutation,
  UpdateMediaOutletProfilePictureMutationVariables
>;

/**
 * __useUpdateMediaOutletProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateMediaOutletProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaOutletProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaOutletProfilePictureMutation, { data, loading, error }] = useUpdateMediaOutletProfilePictureMutation({
 *   variables: {
 *      mediaOutletId: // value for 'mediaOutletId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaOutletProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaOutletProfilePictureMutation, UpdateMediaOutletProfilePictureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaOutletProfilePictureMutation, UpdateMediaOutletProfilePictureMutationVariables>(
    UpdateMediaOutletProfilePictureDocument,
    options
  );
}
export type UpdateMediaOutletProfilePictureMutationHookResult = ReturnType<typeof useUpdateMediaOutletProfilePictureMutation>;
export type UpdateMediaOutletProfilePictureMutationResult = Apollo.MutationResult<UpdateMediaOutletProfilePictureMutation>;
export type UpdateMediaOutletProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaOutletProfilePictureMutation,
  UpdateMediaOutletProfilePictureMutationVariables
>;
export const RemoveMediaOutletProfilePictureDocument = gql`
  mutation RemoveMediaOutletProfilePicture($mediaOutletId: ID!) {
    removeMediaOutletProfilePicture(mediaOutletId: $mediaOutletId)
  }
`;
export type RemoveMediaOutletProfilePictureMutationFn = Apollo.MutationFunction<
  RemoveMediaOutletProfilePictureMutation,
  RemoveMediaOutletProfilePictureMutationVariables
>;

/**
 * __useRemoveMediaOutletProfilePictureMutation__
 *
 * To run a mutation, you first call `useRemoveMediaOutletProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaOutletProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaOutletProfilePictureMutation, { data, loading, error }] = useRemoveMediaOutletProfilePictureMutation({
 *   variables: {
 *      mediaOutletId: // value for 'mediaOutletId'
 *   },
 * });
 */
export function useRemoveMediaOutletProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMediaOutletProfilePictureMutation, RemoveMediaOutletProfilePictureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediaOutletProfilePictureMutation, RemoveMediaOutletProfilePictureMutationVariables>(
    RemoveMediaOutletProfilePictureDocument,
    options
  );
}
export type RemoveMediaOutletProfilePictureMutationHookResult = ReturnType<typeof useRemoveMediaOutletProfilePictureMutation>;
export type RemoveMediaOutletProfilePictureMutationResult = Apollo.MutationResult<RemoveMediaOutletProfilePictureMutation>;
export type RemoveMediaOutletProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  RemoveMediaOutletProfilePictureMutation,
  RemoveMediaOutletProfilePictureMutationVariables
>;
export const AddMediaResortToMediaOutletDocument = gql`
  mutation AddMediaResortToMediaOutlet($mediaOutletId: ID!, $mediaResortId: ID!) {
    addMediaResortToMediaOutlet(mediaOutletId: $mediaOutletId, mediaResortId: $mediaResortId) {
      id
      name
      dataSourceKey
    }
  }
`;
export type AddMediaResortToMediaOutletMutationFn = Apollo.MutationFunction<AddMediaResortToMediaOutletMutation, AddMediaResortToMediaOutletMutationVariables>;

/**
 * __useAddMediaResortToMediaOutletMutation__
 *
 * To run a mutation, you first call `useAddMediaResortToMediaOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaResortToMediaOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaResortToMediaOutletMutation, { data, loading, error }] = useAddMediaResortToMediaOutletMutation({
 *   variables: {
 *      mediaOutletId: // value for 'mediaOutletId'
 *      mediaResortId: // value for 'mediaResortId'
 *   },
 * });
 */
export function useAddMediaResortToMediaOutletMutation(
  baseOptions?: Apollo.MutationHookOptions<AddMediaResortToMediaOutletMutation, AddMediaResortToMediaOutletMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMediaResortToMediaOutletMutation, AddMediaResortToMediaOutletMutationVariables>(AddMediaResortToMediaOutletDocument, options);
}
export type AddMediaResortToMediaOutletMutationHookResult = ReturnType<typeof useAddMediaResortToMediaOutletMutation>;
export type AddMediaResortToMediaOutletMutationResult = Apollo.MutationResult<AddMediaResortToMediaOutletMutation>;
export type AddMediaResortToMediaOutletMutationOptions = Apollo.BaseMutationOptions<
  AddMediaResortToMediaOutletMutation,
  AddMediaResortToMediaOutletMutationVariables
>;
export const RemoveMediaResortFromMediaOutletDocument = gql`
  mutation RemoveMediaResortFromMediaOutlet($mediaOutletId: ID!, $mediaResortId: ID!) {
    removeMediaResortFromMediaOutlet(mediaOutletId: $mediaOutletId, mediaResortId: $mediaResortId) {
      id
      name
      dataSourceKey
    }
  }
`;
export type RemoveMediaResortFromMediaOutletMutationFn = Apollo.MutationFunction<
  RemoveMediaResortFromMediaOutletMutation,
  RemoveMediaResortFromMediaOutletMutationVariables
>;

/**
 * __useRemoveMediaResortFromMediaOutletMutation__
 *
 * To run a mutation, you first call `useRemoveMediaResortFromMediaOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaResortFromMediaOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaResortFromMediaOutletMutation, { data, loading, error }] = useRemoveMediaResortFromMediaOutletMutation({
 *   variables: {
 *      mediaOutletId: // value for 'mediaOutletId'
 *      mediaResortId: // value for 'mediaResortId'
 *   },
 * });
 */
export function useRemoveMediaResortFromMediaOutletMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMediaResortFromMediaOutletMutation, RemoveMediaResortFromMediaOutletMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediaResortFromMediaOutletMutation, RemoveMediaResortFromMediaOutletMutationVariables>(
    RemoveMediaResortFromMediaOutletDocument,
    options
  );
}
export type RemoveMediaResortFromMediaOutletMutationHookResult = ReturnType<typeof useRemoveMediaResortFromMediaOutletMutation>;
export type RemoveMediaResortFromMediaOutletMutationResult = Apollo.MutationResult<RemoveMediaResortFromMediaOutletMutation>;
export type RemoveMediaResortFromMediaOutletMutationOptions = Apollo.BaseMutationOptions<
  RemoveMediaResortFromMediaOutletMutation,
  RemoveMediaResortFromMediaOutletMutationVariables
>;
export const UpdateMediaOutletDocument = gql`
  mutation UpdateMediaOutlet($id: ID!, $input: MediaOutletInput!) {
    updateMediaOutlet(id: $id, input: $input) {
      id
      name
      dataSourceKey
      profilePictureUrl
      description
      wemfArea
      audience {
        id
        key
      }
      category {
        id
        name
      }
      websiteUrl
      geographicalCoverage {
        id
        name
      }
      frequency {
        id
        key
      }
      distributions {
        key
      }
      mediaType
      publicationType
      readership
      circulation
      mediumSupportTypes {
        id
        key
      }
      comments
      preferredLanguages {
        id
        name
        dataSourceKey
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      tags {
        id
        name
      }
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      mediaOutletContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;
export type UpdateMediaOutletMutationFn = Apollo.MutationFunction<UpdateMediaOutletMutation, UpdateMediaOutletMutationVariables>;

/**
 * __useUpdateMediaOutletMutation__
 *
 * To run a mutation, you first call `useUpdateMediaOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaOutletMutation, { data, loading, error }] = useUpdateMediaOutletMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaOutletMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaOutletMutation, UpdateMediaOutletMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaOutletMutation, UpdateMediaOutletMutationVariables>(UpdateMediaOutletDocument, options);
}
export type UpdateMediaOutletMutationHookResult = ReturnType<typeof useUpdateMediaOutletMutation>;
export type UpdateMediaOutletMutationResult = Apollo.MutationResult<UpdateMediaOutletMutation>;
export type UpdateMediaOutletMutationOptions = Apollo.BaseMutationOptions<UpdateMediaOutletMutation, UpdateMediaOutletMutationVariables>;
export const RemoveMediaOutletDocument = gql`
  mutation RemoveMediaOutlet($id: ID!) {
    removeMediaOutlet(id: $id) {
      id
    }
  }
`;
export type RemoveMediaOutletMutationFn = Apollo.MutationFunction<RemoveMediaOutletMutation, RemoveMediaOutletMutationVariables>;

/**
 * __useRemoveMediaOutletMutation__
 *
 * To run a mutation, you first call `useRemoveMediaOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaOutletMutation, { data, loading, error }] = useRemoveMediaOutletMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMediaOutletMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMediaOutletMutation, RemoveMediaOutletMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediaOutletMutation, RemoveMediaOutletMutationVariables>(RemoveMediaOutletDocument, options);
}
export type RemoveMediaOutletMutationHookResult = ReturnType<typeof useRemoveMediaOutletMutation>;
export type RemoveMediaOutletMutationResult = Apollo.MutationResult<RemoveMediaOutletMutation>;
export type RemoveMediaOutletMutationOptions = Apollo.BaseMutationOptions<RemoveMediaOutletMutation, RemoveMediaOutletMutationVariables>;
export const FetchContactsByMediaOutletIdNameAndRoleDocument = gql`
  query FetchContactsByMediaOutletIdNameAndRole($skip: Int!, $take: Int!, $name: String!, $role: String!, $mediumId: ID!) {
    contactsByMediumIdNameAndRole(skip: $skip, take: $take, name: $name, role: $role, mediumId: $mediumId) {
      totalCount
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        isGloballySignedOut
        tags {
          id
          name
        }
        topics {
          topicId
          topicName
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        comments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
        blacklists {
          id
          name
        }
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsByMediaOutletIdNameAndRoleQuery__
 *
 * To run a query within a React component, call `useFetchContactsByMediaOutletIdNameAndRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByMediaOutletIdNameAndRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByMediaOutletIdNameAndRoleQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      mediumId: // value for 'mediumId'
 *   },
 * });
 */
export function useFetchContactsByMediaOutletIdNameAndRoleQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactsByMediaOutletIdNameAndRoleQuery, FetchContactsByMediaOutletIdNameAndRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByMediaOutletIdNameAndRoleQuery, FetchContactsByMediaOutletIdNameAndRoleQueryVariables>(
    FetchContactsByMediaOutletIdNameAndRoleDocument,
    options
  );
}
export function useFetchContactsByMediaOutletIdNameAndRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactsByMediaOutletIdNameAndRoleQuery, FetchContactsByMediaOutletIdNameAndRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByMediaOutletIdNameAndRoleQuery, FetchContactsByMediaOutletIdNameAndRoleQueryVariables>(
    FetchContactsByMediaOutletIdNameAndRoleDocument,
    options
  );
}
export type FetchContactsByMediaOutletIdNameAndRoleQueryHookResult = ReturnType<typeof useFetchContactsByMediaOutletIdNameAndRoleQuery>;
export type FetchContactsByMediaOutletIdNameAndRoleLazyQueryHookResult = ReturnType<typeof useFetchContactsByMediaOutletIdNameAndRoleLazyQuery>;
export type FetchContactsByMediaOutletIdNameAndRoleQueryResult = Apollo.QueryResult<
  FetchContactsByMediaOutletIdNameAndRoleQuery,
  FetchContactsByMediaOutletIdNameAndRoleQueryVariables
>;
export const FetchContactsByNameAndRoleAndNotContainedInMediaOutletDocument = gql`
  query FetchContactsByNameAndRoleAndNotContainedInMediaOutlet($skip: Int!, $take: Int!, $name: String!, $role: String!, $mediumId: ID!) {
    contactsByNameAndRoleAndNotContainedInMedium(skip: $skip, take: $take, name: $name, role: $role, mediumId: $mediumId) {
      totalCount
      contacts {
        id
        salutation
        firstName
        lastName
        profilePictureUrl
        isGloballySignedOut
        tags {
          id
          name
        }
        topics {
          topicId
          topicName
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        comments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
        blacklists {
          id
          name
        }
        ownContactData {
          address
          country
          city
          postalCode
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
        }
        collaborations {
          id
          medium {
            id
            name
            type {
              name
              value
            }
          }
          jobDescription
          jobTitle {
            id
            name
            dataSourceKey
            mediumType {
              name
              value
            }
          }
          address
          country
          city
          fax
          landlinePhoneNumber {
            value
            isPrimary
          }
          mobilePhoneNumber {
            value
            isPrimary
          }
          emailAddress
          blogUrl
          websiteUrl
          twitterProfileUrl
          instagramProfileUrl
          linkedInProfileUrl
          youtubeProfileUrl
          facebookProfileUrl
          isPrimary
          postalCode
        }
      }
    }
  }
`;

/**
 * __useFetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery__
 *
 * To run a query within a React component, call `useFetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      mediumId: // value for 'mediumId'
 *   },
 * });
 */
export function useFetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery,
    FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery, FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryVariables>(
    FetchContactsByNameAndRoleAndNotContainedInMediaOutletDocument,
    options
  );
}
export function useFetchContactsByNameAndRoleAndNotContainedInMediaOutletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery,
    FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery, FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryVariables>(
    FetchContactsByNameAndRoleAndNotContainedInMediaOutletDocument,
    options
  );
}
export type FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryHookResult = ReturnType<
  typeof useFetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery
>;
export type FetchContactsByNameAndRoleAndNotContainedInMediaOutletLazyQueryHookResult = ReturnType<
  typeof useFetchContactsByNameAndRoleAndNotContainedInMediaOutletLazyQuery
>;
export type FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryResult = Apollo.QueryResult<
  FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery,
  FetchContactsByNameAndRoleAndNotContainedInMediaOutletQueryVariables
>;
export const AddMediaOutletSavedSearchDocument = gql`
  mutation AddMediaOutletSavedSearch($savedMediaOutletSearch: MediaOutletsSavedSearchInput!) {
    saveMediaOutletSearch(input: $savedMediaOutletSearch) {
      id
      searchText
      filterItems {
        fieldName
        value
      }
      name
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type AddMediaOutletSavedSearchMutationFn = Apollo.MutationFunction<AddMediaOutletSavedSearchMutation, AddMediaOutletSavedSearchMutationVariables>;

/**
 * __useAddMediaOutletSavedSearchMutation__
 *
 * To run a mutation, you first call `useAddMediaOutletSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaOutletSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaOutletSavedSearchMutation, { data, loading, error }] = useAddMediaOutletSavedSearchMutation({
 *   variables: {
 *      savedMediaOutletSearch: // value for 'savedMediaOutletSearch'
 *   },
 * });
 */
export function useAddMediaOutletSavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<AddMediaOutletSavedSearchMutation, AddMediaOutletSavedSearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMediaOutletSavedSearchMutation, AddMediaOutletSavedSearchMutationVariables>(AddMediaOutletSavedSearchDocument, options);
}
export type AddMediaOutletSavedSearchMutationHookResult = ReturnType<typeof useAddMediaOutletSavedSearchMutation>;
export type AddMediaOutletSavedSearchMutationResult = Apollo.MutationResult<AddMediaOutletSavedSearchMutation>;
export type AddMediaOutletSavedSearchMutationOptions = Apollo.BaseMutationOptions<
  AddMediaOutletSavedSearchMutation,
  AddMediaOutletSavedSearchMutationVariables
>;
export const UpdateMediaOutletSavedSearchDocument = gql`
  mutation UpdateMediaOutletSavedSearch($id: ID!, $savedMediaOutletSearch: MediaOutletsSavedSearchInput!) {
    updateMediaOutletSavedSearch(id: $id, input: $savedMediaOutletSearch) {
      id
      name
      searchText
      filterItems {
        fieldName
        value
      }
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type UpdateMediaOutletSavedSearchMutationFn = Apollo.MutationFunction<
  UpdateMediaOutletSavedSearchMutation,
  UpdateMediaOutletSavedSearchMutationVariables
>;

/**
 * __useUpdateMediaOutletSavedSearchMutation__
 *
 * To run a mutation, you first call `useUpdateMediaOutletSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaOutletSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaOutletSavedSearchMutation, { data, loading, error }] = useUpdateMediaOutletSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      savedMediaOutletSearch: // value for 'savedMediaOutletSearch'
 *   },
 * });
 */
export function useUpdateMediaOutletSavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaOutletSavedSearchMutation, UpdateMediaOutletSavedSearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaOutletSavedSearchMutation, UpdateMediaOutletSavedSearchMutationVariables>(UpdateMediaOutletSavedSearchDocument, options);
}
export type UpdateMediaOutletSavedSearchMutationHookResult = ReturnType<typeof useUpdateMediaOutletSavedSearchMutation>;
export type UpdateMediaOutletSavedSearchMutationResult = Apollo.MutationResult<UpdateMediaOutletSavedSearchMutation>;
export type UpdateMediaOutletSavedSearchMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaOutletSavedSearchMutation,
  UpdateMediaOutletSavedSearchMutationVariables
>;
export const UpdateMediaOutletSavedSearchesDocument = gql`
  mutation UpdateMediaOutletSavedSearches($savedMediaOutletSearches: [MediaOutletsSavedSearchWithIdInput!]!) {
    updateMediaOutletSavedSearches(input: $savedMediaOutletSearches) {
      id
      name
      priority
      searchText
      filterItems {
        fieldName
        value
      }
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type UpdateMediaOutletSavedSearchesMutationFn = Apollo.MutationFunction<
  UpdateMediaOutletSavedSearchesMutation,
  UpdateMediaOutletSavedSearchesMutationVariables
>;

/**
 * __useUpdateMediaOutletSavedSearchesMutation__
 *
 * To run a mutation, you first call `useUpdateMediaOutletSavedSearchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaOutletSavedSearchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaOutletSavedSearchesMutation, { data, loading, error }] = useUpdateMediaOutletSavedSearchesMutation({
 *   variables: {
 *      savedMediaOutletSearches: // value for 'savedMediaOutletSearches'
 *   },
 * });
 */
export function useUpdateMediaOutletSavedSearchesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaOutletSavedSearchesMutation, UpdateMediaOutletSavedSearchesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaOutletSavedSearchesMutation, UpdateMediaOutletSavedSearchesMutationVariables>(
    UpdateMediaOutletSavedSearchesDocument,
    options
  );
}
export type UpdateMediaOutletSavedSearchesMutationHookResult = ReturnType<typeof useUpdateMediaOutletSavedSearchesMutation>;
export type UpdateMediaOutletSavedSearchesMutationResult = Apollo.MutationResult<UpdateMediaOutletSavedSearchesMutation>;
export type UpdateMediaOutletSavedSearchesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaOutletSavedSearchesMutation,
  UpdateMediaOutletSavedSearchesMutationVariables
>;
export const RemoveMediaOutletSavedSearchDocument = gql`
  mutation RemoveMediaOutletSavedSearch($id: ID!) {
    removeMediaOutletSavedSearch(id: $id) {
      id
      name
      searchText
      filterItems {
        fieldName
        value
      }
      priority
      count
      createdAt
      lastModifiedAt
      createdBy
    }
  }
`;
export type RemoveMediaOutletSavedSearchMutationFn = Apollo.MutationFunction<
  RemoveMediaOutletSavedSearchMutation,
  RemoveMediaOutletSavedSearchMutationVariables
>;

/**
 * __useRemoveMediaOutletSavedSearchMutation__
 *
 * To run a mutation, you first call `useRemoveMediaOutletSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaOutletSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaOutletSavedSearchMutation, { data, loading, error }] = useRemoveMediaOutletSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMediaOutletSavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMediaOutletSavedSearchMutation, RemoveMediaOutletSavedSearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediaOutletSavedSearchMutation, RemoveMediaOutletSavedSearchMutationVariables>(RemoveMediaOutletSavedSearchDocument, options);
}
export type RemoveMediaOutletSavedSearchMutationHookResult = ReturnType<typeof useRemoveMediaOutletSavedSearchMutation>;
export type RemoveMediaOutletSavedSearchMutationResult = Apollo.MutationResult<RemoveMediaOutletSavedSearchMutation>;
export type RemoveMediaOutletSavedSearchMutationOptions = Apollo.BaseMutationOptions<
  RemoveMediaOutletSavedSearchMutation,
  RemoveMediaOutletSavedSearchMutationVariables
>;
export const FetchMediaOutletsSavedSearchesPagedDocument = gql`
  query FetchMediaOutletsSavedSearchesPaged($searchText: String!, $filterItems: [MediaOutletsFilterItemInput!]!, $take: Int!, $skip: Int!) {
    mediaOutletSavedSearches(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take) {
      totalCount
      savedSearches {
        id
        name
        searchText
        priority
        count
        filterItems {
          fieldName
          value
        }
        createdAt
        lastModifiedAt
        createdBy
      }
    }
  }
`;

/**
 * __useFetchMediaOutletsSavedSearchesPagedQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletsSavedSearchesPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletsSavedSearchesPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletsSavedSearchesPagedQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFetchMediaOutletsSavedSearchesPagedQuery(
  baseOptions: Apollo.QueryHookOptions<FetchMediaOutletsSavedSearchesPagedQuery, FetchMediaOutletsSavedSearchesPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediaOutletsSavedSearchesPagedQuery, FetchMediaOutletsSavedSearchesPagedQueryVariables>(
    FetchMediaOutletsSavedSearchesPagedDocument,
    options
  );
}
export function useFetchMediaOutletsSavedSearchesPagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMediaOutletsSavedSearchesPagedQuery, FetchMediaOutletsSavedSearchesPagedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediaOutletsSavedSearchesPagedQuery, FetchMediaOutletsSavedSearchesPagedQueryVariables>(
    FetchMediaOutletsSavedSearchesPagedDocument,
    options
  );
}
export type FetchMediaOutletsSavedSearchesPagedQueryHookResult = ReturnType<typeof useFetchMediaOutletsSavedSearchesPagedQuery>;
export type FetchMediaOutletsSavedSearchesPagedLazyQueryHookResult = ReturnType<typeof useFetchMediaOutletsSavedSearchesPagedLazyQuery>;
export type FetchMediaOutletsSavedSearchesPagedQueryResult = Apollo.QueryResult<
  FetchMediaOutletsSavedSearchesPagedQuery,
  FetchMediaOutletsSavedSearchesPagedQueryVariables
>;
export const FetchMediaOutletsAvatarDetailsDocument = gql`
  query FetchMediaOutletsAvatarDetails($mediaOutletAvatarDetails: [MediaOutletsAvatarDetailsInput!]!) {
    mediaOutletsAvatarDetails(mediaOutletsAvatarDetailsInput: $mediaOutletAvatarDetails) {
      savedSearchId
      pageOfMediaOutlets {
        totalCount
        mediaOutlets {
          id
          name
          profilePictureUrl
        }
      }
    }
  }
`;

/**
 * __useFetchMediaOutletsAvatarDetailsQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletsAvatarDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletsAvatarDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletsAvatarDetailsQuery({
 *   variables: {
 *      mediaOutletAvatarDetails: // value for 'mediaOutletAvatarDetails'
 *   },
 * });
 */
export function useFetchMediaOutletsAvatarDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchMediaOutletsAvatarDetailsQuery, FetchMediaOutletsAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediaOutletsAvatarDetailsQuery, FetchMediaOutletsAvatarDetailsQueryVariables>(FetchMediaOutletsAvatarDetailsDocument, options);
}
export function useFetchMediaOutletsAvatarDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMediaOutletsAvatarDetailsQuery, FetchMediaOutletsAvatarDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediaOutletsAvatarDetailsQuery, FetchMediaOutletsAvatarDetailsQueryVariables>(
    FetchMediaOutletsAvatarDetailsDocument,
    options
  );
}
export type FetchMediaOutletsAvatarDetailsQueryHookResult = ReturnType<typeof useFetchMediaOutletsAvatarDetailsQuery>;
export type FetchMediaOutletsAvatarDetailsLazyQueryHookResult = ReturnType<typeof useFetchMediaOutletsAvatarDetailsLazyQuery>;
export type FetchMediaOutletsAvatarDetailsQueryResult = Apollo.QueryResult<FetchMediaOutletsAvatarDetailsQuery, FetchMediaOutletsAvatarDetailsQueryVariables>;
export const AddMediaOutletDocument = gql`
  mutation AddMediaOutlet($mediaOutlet: MediaOutletInput!) {
    addMediaOutlet(input: $mediaOutlet) {
      id
      name
      dataSourceKey
      description
      audience {
        id
        key
      }
      category {
        id
        name
      }
      websiteUrl
      frequency {
        id
        key
      }
      geographicalCoverage {
        id
        name
      }
      mediaType
      publicationType
      readership
      circulation
      mediumSupportTypes {
        id
        key
      }
      distributions {
        key
      }
      comments
      preferredLanguages {
        id
        name
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      tags {
        id
        name
      }
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      mediaOutletContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;
export type AddMediaOutletMutationFn = Apollo.MutationFunction<AddMediaOutletMutation, AddMediaOutletMutationVariables>;

/**
 * __useAddMediaOutletMutation__
 *
 * To run a mutation, you first call `useAddMediaOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaOutletMutation, { data, loading, error }] = useAddMediaOutletMutation({
 *   variables: {
 *      mediaOutlet: // value for 'mediaOutlet'
 *   },
 * });
 */
export function useAddMediaOutletMutation(baseOptions?: Apollo.MutationHookOptions<AddMediaOutletMutation, AddMediaOutletMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMediaOutletMutation, AddMediaOutletMutationVariables>(AddMediaOutletDocument, options);
}
export type AddMediaOutletMutationHookResult = ReturnType<typeof useAddMediaOutletMutation>;
export type AddMediaOutletMutationResult = Apollo.MutationResult<AddMediaOutletMutation>;
export type AddMediaOutletMutationOptions = Apollo.BaseMutationOptions<AddMediaOutletMutation, AddMediaOutletMutationVariables>;
export const TransformMediaOutletIntoCompanyDocument = gql`
  mutation TransformMediaOutletIntoCompany($id: ID!) {
    transformMediaOutletIntoCompany(id: $id) {
      id
    }
  }
`;
export type TransformMediaOutletIntoCompanyMutationFn = Apollo.MutationFunction<
  TransformMediaOutletIntoCompanyMutation,
  TransformMediaOutletIntoCompanyMutationVariables
>;

/**
 * __useTransformMediaOutletIntoCompanyMutation__
 *
 * To run a mutation, you first call `useTransformMediaOutletIntoCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransformMediaOutletIntoCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transformMediaOutletIntoCompanyMutation, { data, loading, error }] = useTransformMediaOutletIntoCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransformMediaOutletIntoCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<TransformMediaOutletIntoCompanyMutation, TransformMediaOutletIntoCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransformMediaOutletIntoCompanyMutation, TransformMediaOutletIntoCompanyMutationVariables>(
    TransformMediaOutletIntoCompanyDocument,
    options
  );
}
export type TransformMediaOutletIntoCompanyMutationHookResult = ReturnType<typeof useTransformMediaOutletIntoCompanyMutation>;
export type TransformMediaOutletIntoCompanyMutationResult = Apollo.MutationResult<TransformMediaOutletIntoCompanyMutation>;
export type TransformMediaOutletIntoCompanyMutationOptions = Apollo.BaseMutationOptions<
  TransformMediaOutletIntoCompanyMutation,
  TransformMediaOutletIntoCompanyMutationVariables
>;
export const RemoveMediaOutletsDocument = gql`
  mutation RemoveMediaOutlets($ids: [ID!]!) {
    removeMediaOutlets(ids: $ids) {
      id
      name
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
    }
  }
`;
export type RemoveMediaOutletsMutationFn = Apollo.MutationFunction<RemoveMediaOutletsMutation, RemoveMediaOutletsMutationVariables>;

/**
 * __useRemoveMediaOutletsMutation__
 *
 * To run a mutation, you first call `useRemoveMediaOutletsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaOutletsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaOutletsMutation, { data, loading, error }] = useRemoveMediaOutletsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveMediaOutletsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMediaOutletsMutation, RemoveMediaOutletsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediaOutletsMutation, RemoveMediaOutletsMutationVariables>(RemoveMediaOutletsDocument, options);
}
export type RemoveMediaOutletsMutationHookResult = ReturnType<typeof useRemoveMediaOutletsMutation>;
export type RemoveMediaOutletsMutationResult = Apollo.MutationResult<RemoveMediaOutletsMutation>;
export type RemoveMediaOutletsMutationOptions = Apollo.BaseMutationOptions<RemoveMediaOutletsMutation, RemoveMediaOutletsMutationVariables>;
export const UpdateBulkMediaOutletsDocument = gql`
  mutation UpdateBulkMediaOutlets($ids: [ID!]!, $mediaResortsIds: [ID!]!) {
    updateBulkMediaOutlets(ids: $ids, mediaResortsIds: $mediaResortsIds) {
      id
      name
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
    }
  }
`;
export type UpdateBulkMediaOutletsMutationFn = Apollo.MutationFunction<UpdateBulkMediaOutletsMutation, UpdateBulkMediaOutletsMutationVariables>;

/**
 * __useUpdateBulkMediaOutletsMutation__
 *
 * To run a mutation, you first call `useUpdateBulkMediaOutletsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkMediaOutletsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkMediaOutletsMutation, { data, loading, error }] = useUpdateBulkMediaOutletsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      mediaResortsIds: // value for 'mediaResortsIds'
 *   },
 * });
 */
export function useUpdateBulkMediaOutletsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBulkMediaOutletsMutation, UpdateBulkMediaOutletsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBulkMediaOutletsMutation, UpdateBulkMediaOutletsMutationVariables>(UpdateBulkMediaOutletsDocument, options);
}
export type UpdateBulkMediaOutletsMutationHookResult = ReturnType<typeof useUpdateBulkMediaOutletsMutation>;
export type UpdateBulkMediaOutletsMutationResult = Apollo.MutationResult<UpdateBulkMediaOutletsMutation>;
export type UpdateBulkMediaOutletsMutationOptions = Apollo.BaseMutationOptions<UpdateBulkMediaOutletsMutation, UpdateBulkMediaOutletsMutationVariables>;
export const AddMediumCategoryDocument = gql`
  mutation AddMediumCategory($input: MediaOutletsMediumCategoryInput!) {
    addMediumCategory(input: $input) {
      id
      name
      type {
        name
        value
      }
    }
  }
`;
export type AddMediumCategoryMutationFn = Apollo.MutationFunction<AddMediumCategoryMutation, AddMediumCategoryMutationVariables>;

/**
 * __useAddMediumCategoryMutation__
 *
 * To run a mutation, you first call `useAddMediumCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediumCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediumCategoryMutation, { data, loading, error }] = useAddMediumCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMediumCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddMediumCategoryMutation, AddMediumCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMediumCategoryMutation, AddMediumCategoryMutationVariables>(AddMediumCategoryDocument, options);
}
export type AddMediumCategoryMutationHookResult = ReturnType<typeof useAddMediumCategoryMutation>;
export type AddMediumCategoryMutationResult = Apollo.MutationResult<AddMediumCategoryMutation>;
export type AddMediumCategoryMutationOptions = Apollo.BaseMutationOptions<AddMediumCategoryMutation, AddMediumCategoryMutationVariables>;
export const RemoveMediumCategoryDocument = gql`
  mutation RemoveMediumCategory($mediumCategoryId: ID!) {
    removeMediumCategory(mediumCategoryId: $mediumCategoryId)
  }
`;
export type RemoveMediumCategoryMutationFn = Apollo.MutationFunction<RemoveMediumCategoryMutation, RemoveMediumCategoryMutationVariables>;

/**
 * __useRemoveMediumCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveMediumCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediumCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediumCategoryMutation, { data, loading, error }] = useRemoveMediumCategoryMutation({
 *   variables: {
 *      mediumCategoryId: // value for 'mediumCategoryId'
 *   },
 * });
 */
export function useRemoveMediumCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMediumCategoryMutation, RemoveMediumCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediumCategoryMutation, RemoveMediumCategoryMutationVariables>(RemoveMediumCategoryDocument, options);
}
export type RemoveMediumCategoryMutationHookResult = ReturnType<typeof useRemoveMediumCategoryMutation>;
export type RemoveMediumCategoryMutationResult = Apollo.MutationResult<RemoveMediumCategoryMutation>;
export type RemoveMediumCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveMediumCategoryMutation, RemoveMediumCategoryMutationVariables>;
export const FetchMediaOutletByIdDocument = gql`
  query FetchMediaOutletById($id: ID!) {
    mediaOutlet(id: $id) {
      id
      name
      dataSourceKey
      profilePictureUrl
      description
      audience {
        id
        key
      }
      geographicalCoverage {
        id
        name
      }
      category {
        id
        name
      }
      websiteUrl
      frequency {
        id
        key
      }
      mediaType
      publicationType
      readership
      circulation
      mediumSupportTypes {
        id
        key
      }
      distributions {
        key
      }
      comments
      preferredLanguages {
        id
        name
        dataSourceKey
      }
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      mediaOutletContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      tags {
        id
        name
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchMediaOutletByIdQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchMediaOutletByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchMediaOutletByIdQuery, FetchMediaOutletByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediaOutletByIdQuery, FetchMediaOutletByIdQueryVariables>(FetchMediaOutletByIdDocument, options);
}
export function useFetchMediaOutletByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMediaOutletByIdQuery, FetchMediaOutletByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediaOutletByIdQuery, FetchMediaOutletByIdQueryVariables>(FetchMediaOutletByIdDocument, options);
}
export type FetchMediaOutletByIdQueryHookResult = ReturnType<typeof useFetchMediaOutletByIdQuery>;
export type FetchMediaOutletByIdLazyQueryHookResult = ReturnType<typeof useFetchMediaOutletByIdLazyQuery>;
export type FetchMediaOutletByIdQueryResult = Apollo.QueryResult<FetchMediaOutletByIdQuery, FetchMediaOutletByIdQueryVariables>;
export const FetchMediaOutletsByContactIdDocument = gql`
  query FetchMediaOutletsByContactId($contactId: ID!) {
    mediaOutletsByContactId(contactId: $contactId) {
      id
      name
      dataSourceKey
      profilePictureUrl
      description
      audience {
        id
        key
      }
      category {
        id
        name
      }
      websiteUrl
      frequency {
        id
        key
      }
      mediaType
      publicationType
      readership
      circulation
      mediumSupportTypes {
        id
        key
      }
      distributions {
        key
      }
      comments
      preferredLanguages {
        id
        name
        dataSourceKey
      }
      contactData {
        street
        poBox
        postalCode
        country
        city
        websiteUrl
        emailAddress
        landlinePhoneNumber
        fax
        alternativeFax
      }
      mediaOutletContacts {
        id
        firstName
        lastName
        profilePictureUrl
      }
      tags {
        id
        name
      }
      mediaResorts {
        mediaResortId
        mediaResortName
        dataSourceKey
      }
      userComments {
        id
        author {
          teamsId
          name
          aadObjectId
        }
        content
        createdAt
        mentions {
          teamsId
          name
          aadObjectId
        }
        replies {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
        }
      }
    }
  }
`;

/**
 * __useFetchMediaOutletsByContactIdQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletsByContactIdQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFetchMediaOutletsByContactIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchMediaOutletsByContactIdQuery, FetchMediaOutletsByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediaOutletsByContactIdQuery, FetchMediaOutletsByContactIdQueryVariables>(FetchMediaOutletsByContactIdDocument, options);
}
export function useFetchMediaOutletsByContactIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMediaOutletsByContactIdQuery, FetchMediaOutletsByContactIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediaOutletsByContactIdQuery, FetchMediaOutletsByContactIdQueryVariables>(FetchMediaOutletsByContactIdDocument, options);
}
export type FetchMediaOutletsByContactIdQueryHookResult = ReturnType<typeof useFetchMediaOutletsByContactIdQuery>;
export type FetchMediaOutletsByContactIdLazyQueryHookResult = ReturnType<typeof useFetchMediaOutletsByContactIdLazyQuery>;
export type FetchMediaOutletsByContactIdQueryResult = Apollo.QueryResult<FetchMediaOutletsByContactIdQuery, FetchMediaOutletsByContactIdQueryVariables>;
export const FetchMediaOutletsByQueryParamsDocument = gql`
  query FetchMediaOutletsByQueryParams(
    $searchText: String!
    $filterItems: [MediaOutletsFilterItemInput!]!
    $skip: Int!
    $take: Int!
    $sortingInput: MediaOutletsSortingInput!
  ) {
    mediaOutlets(searchText: $searchText, filterItems: $filterItems, skip: $skip, take: $take, sortingInput: $sortingInput) {
      totalCount
      mediaOutlets {
        id
        name
        dataSourceKey
        profilePictureUrl
        description
        audience {
          id
          key
        }
        category {
          id
          name
        }
        websiteUrl
        frequency {
          id
          key
        }
        mediaType
        publicationType
        readership
        circulation
        contactData {
          street
          poBox
          postalCode
          country
          city
          websiteUrl
          emailAddress
          landlinePhoneNumber
          fax
          alternativeFax
        }
        mediumSupportTypes {
          id
          key
        }
        distributions {
          key
        }
        mediaOutletContacts {
          id
          firstName
          lastName
          profilePictureUrl
        }
        tags {
          id
          name
        }
        preferredLanguages {
          id
          name
          dataSourceKey
        }
        mediaResorts {
          mediaResortId
          mediaResortName
          dataSourceKey
        }
        userComments {
          id
          author {
            teamsId
            name
            aadObjectId
          }
          content
          createdAt
          mentions {
            teamsId
            name
            aadObjectId
          }
          replies {
            id
            author {
              teamsId
              name
              aadObjectId
            }
            content
            createdAt
            mentions {
              teamsId
              name
              aadObjectId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFetchMediaOutletsByQueryParamsQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletsByQueryParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletsByQueryParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletsByQueryParamsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filterItems: // value for 'filterItems'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortingInput: // value for 'sortingInput'
 *   },
 * });
 */
export function useFetchMediaOutletsByQueryParamsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchMediaOutletsByQueryParamsQuery, FetchMediaOutletsByQueryParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediaOutletsByQueryParamsQuery, FetchMediaOutletsByQueryParamsQueryVariables>(FetchMediaOutletsByQueryParamsDocument, options);
}
export function useFetchMediaOutletsByQueryParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMediaOutletsByQueryParamsQuery, FetchMediaOutletsByQueryParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediaOutletsByQueryParamsQuery, FetchMediaOutletsByQueryParamsQueryVariables>(
    FetchMediaOutletsByQueryParamsDocument,
    options
  );
}
export type FetchMediaOutletsByQueryParamsQueryHookResult = ReturnType<typeof useFetchMediaOutletsByQueryParamsQuery>;
export type FetchMediaOutletsByQueryParamsLazyQueryHookResult = ReturnType<typeof useFetchMediaOutletsByQueryParamsLazyQuery>;
export type FetchMediaOutletsByQueryParamsQueryResult = Apollo.QueryResult<FetchMediaOutletsByQueryParamsQuery, FetchMediaOutletsByQueryParamsQueryVariables>;
export const FetchMediaOutletSearchSuggestionsDocument = gql`
  query FetchMediaOutletSearchSuggestions($searchText: String!, $language: String!) {
    searchMediaOutletSuggestions(searchText: $searchText, language: $language) {
      searchCategoryName {
        name
        value
      }
      filterItemFieldName
      suggestionsList {
        text
        imageUrl
      }
    }
  }
`;

/**
 * __useFetchMediaOutletSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletSearchSuggestionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useFetchMediaOutletSearchSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchMediaOutletSearchSuggestionsQuery, FetchMediaOutletSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediaOutletSearchSuggestionsQuery, FetchMediaOutletSearchSuggestionsQueryVariables>(
    FetchMediaOutletSearchSuggestionsDocument,
    options
  );
}
export function useFetchMediaOutletSearchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMediaOutletSearchSuggestionsQuery, FetchMediaOutletSearchSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediaOutletSearchSuggestionsQuery, FetchMediaOutletSearchSuggestionsQueryVariables>(
    FetchMediaOutletSearchSuggestionsDocument,
    options
  );
}
export type FetchMediaOutletSearchSuggestionsQueryHookResult = ReturnType<typeof useFetchMediaOutletSearchSuggestionsQuery>;
export type FetchMediaOutletSearchSuggestionsLazyQueryHookResult = ReturnType<typeof useFetchMediaOutletSearchSuggestionsLazyQuery>;
export type FetchMediaOutletSearchSuggestionsQueryResult = Apollo.QueryResult<
  FetchMediaOutletSearchSuggestionsQuery,
  FetchMediaOutletSearchSuggestionsQueryVariables
>;
export const MediaOutletSuggestionsByNameDocument = gql`
  query mediaOutletSuggestionsByName($name: String!, $take: Int!) {
    mediaOutletSuggestionsByName(name: $name, take: $take)
  }
`;

/**
 * __useMediaOutletSuggestionsByNameQuery__
 *
 * To run a query within a React component, call `useMediaOutletSuggestionsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaOutletSuggestionsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaOutletSuggestionsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediaOutletSuggestionsByNameQuery(
  baseOptions: Apollo.QueryHookOptions<MediaOutletSuggestionsByNameQuery, MediaOutletSuggestionsByNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaOutletSuggestionsByNameQuery, MediaOutletSuggestionsByNameQueryVariables>(MediaOutletSuggestionsByNameDocument, options);
}
export function useMediaOutletSuggestionsByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaOutletSuggestionsByNameQuery, MediaOutletSuggestionsByNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaOutletSuggestionsByNameQuery, MediaOutletSuggestionsByNameQueryVariables>(MediaOutletSuggestionsByNameDocument, options);
}
export type MediaOutletSuggestionsByNameQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByNameQuery>;
export type MediaOutletSuggestionsByNameLazyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByNameLazyQuery>;
export type MediaOutletSuggestionsByNameQueryResult = Apollo.QueryResult<MediaOutletSuggestionsByNameQuery, MediaOutletSuggestionsByNameQueryVariables>;
export const MediaOutletSuggestionsByContactNameDocument = gql`
  query mediaOutletSuggestionsByContactName($contactName: String!, $take: Int!) {
    mediaOutletSuggestionsByContactName(contactName: $contactName, take: $take)
  }
`;

/**
 * __useMediaOutletSuggestionsByContactNameQuery__
 *
 * To run a query within a React component, call `useMediaOutletSuggestionsByContactNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaOutletSuggestionsByContactNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaOutletSuggestionsByContactNameQuery({
 *   variables: {
 *      contactName: // value for 'contactName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediaOutletSuggestionsByContactNameQuery(
  baseOptions: Apollo.QueryHookOptions<MediaOutletSuggestionsByContactNameQuery, MediaOutletSuggestionsByContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaOutletSuggestionsByContactNameQuery, MediaOutletSuggestionsByContactNameQueryVariables>(
    MediaOutletSuggestionsByContactNameDocument,
    options
  );
}
export function useMediaOutletSuggestionsByContactNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaOutletSuggestionsByContactNameQuery, MediaOutletSuggestionsByContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaOutletSuggestionsByContactNameQuery, MediaOutletSuggestionsByContactNameQueryVariables>(
    MediaOutletSuggestionsByContactNameDocument,
    options
  );
}
export type MediaOutletSuggestionsByContactNameQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByContactNameQuery>;
export type MediaOutletSuggestionsByContactNameLazyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByContactNameLazyQuery>;
export type MediaOutletSuggestionsByContactNameQueryResult = Apollo.QueryResult<
  MediaOutletSuggestionsByContactNameQuery,
  MediaOutletSuggestionsByContactNameQueryVariables
>;
export const MediaOutletSuggestionsByTagNameDocument = gql`
  query mediaOutletSuggestionsByTagName($tagName: String!, $take: Int!) {
    mediaOutletSuggestionsByTagName(tagName: $tagName, take: $take)
  }
`;

/**
 * __useMediaOutletSuggestionsByTagNameQuery__
 *
 * To run a query within a React component, call `useMediaOutletSuggestionsByTagNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaOutletSuggestionsByTagNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaOutletSuggestionsByTagNameQuery({
 *   variables: {
 *      tagName: // value for 'tagName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediaOutletSuggestionsByTagNameQuery(
  baseOptions: Apollo.QueryHookOptions<MediaOutletSuggestionsByTagNameQuery, MediaOutletSuggestionsByTagNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaOutletSuggestionsByTagNameQuery, MediaOutletSuggestionsByTagNameQueryVariables>(MediaOutletSuggestionsByTagNameDocument, options);
}
export function useMediaOutletSuggestionsByTagNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaOutletSuggestionsByTagNameQuery, MediaOutletSuggestionsByTagNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaOutletSuggestionsByTagNameQuery, MediaOutletSuggestionsByTagNameQueryVariables>(
    MediaOutletSuggestionsByTagNameDocument,
    options
  );
}
export type MediaOutletSuggestionsByTagNameQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByTagNameQuery>;
export type MediaOutletSuggestionsByTagNameLazyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByTagNameLazyQuery>;
export type MediaOutletSuggestionsByTagNameQueryResult = Apollo.QueryResult<
  MediaOutletSuggestionsByTagNameQuery,
  MediaOutletSuggestionsByTagNameQueryVariables
>;
export const MediaOutletSuggestionsByCategoryDocument = gql`
  query mediaOutletSuggestionsByCategory($category: String!, $take: Int!) {
    mediaOutletSuggestionsByCategory(category: $category, take: $take)
  }
`;

/**
 * __useMediaOutletSuggestionsByCategoryQuery__
 *
 * To run a query within a React component, call `useMediaOutletSuggestionsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaOutletSuggestionsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaOutletSuggestionsByCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediaOutletSuggestionsByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<MediaOutletSuggestionsByCategoryQuery, MediaOutletSuggestionsByCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaOutletSuggestionsByCategoryQuery, MediaOutletSuggestionsByCategoryQueryVariables>(
    MediaOutletSuggestionsByCategoryDocument,
    options
  );
}
export function useMediaOutletSuggestionsByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaOutletSuggestionsByCategoryQuery, MediaOutletSuggestionsByCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaOutletSuggestionsByCategoryQuery, MediaOutletSuggestionsByCategoryQueryVariables>(
    MediaOutletSuggestionsByCategoryDocument,
    options
  );
}
export type MediaOutletSuggestionsByCategoryQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByCategoryQuery>;
export type MediaOutletSuggestionsByCategoryLazyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByCategoryLazyQuery>;
export type MediaOutletSuggestionsByCategoryQueryResult = Apollo.QueryResult<
  MediaOutletSuggestionsByCategoryQuery,
  MediaOutletSuggestionsByCategoryQueryVariables
>;
export const MediaOutletSuggestionsByLanguageDocument = gql`
  query mediaOutletSuggestionsByLanguage($language: String!, $take: Int!) {
    mediaOutletSuggestionsByLanguage(language: $language, take: $take)
  }
`;

/**
 * __useMediaOutletSuggestionsByLanguageQuery__
 *
 * To run a query within a React component, call `useMediaOutletSuggestionsByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaOutletSuggestionsByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaOutletSuggestionsByLanguageQuery({
 *   variables: {
 *      language: // value for 'language'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediaOutletSuggestionsByLanguageQuery(
  baseOptions: Apollo.QueryHookOptions<MediaOutletSuggestionsByLanguageQuery, MediaOutletSuggestionsByLanguageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaOutletSuggestionsByLanguageQuery, MediaOutletSuggestionsByLanguageQueryVariables>(
    MediaOutletSuggestionsByLanguageDocument,
    options
  );
}
export function useMediaOutletSuggestionsByLanguageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaOutletSuggestionsByLanguageQuery, MediaOutletSuggestionsByLanguageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaOutletSuggestionsByLanguageQuery, MediaOutletSuggestionsByLanguageQueryVariables>(
    MediaOutletSuggestionsByLanguageDocument,
    options
  );
}
export type MediaOutletSuggestionsByLanguageQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByLanguageQuery>;
export type MediaOutletSuggestionsByLanguageLazyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByLanguageLazyQuery>;
export type MediaOutletSuggestionsByLanguageQueryResult = Apollo.QueryResult<
  MediaOutletSuggestionsByLanguageQuery,
  MediaOutletSuggestionsByLanguageQueryVariables
>;
export const MediaOutletSuggestionsByMediaTypeDocument = gql`
  query mediaOutletSuggestionsByMediaType($mediaType: String!, $take: Int!) {
    mediaOutletSuggestionsByMediaType(mediaType: $mediaType, take: $take)
  }
`;

/**
 * __useMediaOutletSuggestionsByMediaTypeQuery__
 *
 * To run a query within a React component, call `useMediaOutletSuggestionsByMediaTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaOutletSuggestionsByMediaTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaOutletSuggestionsByMediaTypeQuery({
 *   variables: {
 *      mediaType: // value for 'mediaType'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediaOutletSuggestionsByMediaTypeQuery(
  baseOptions: Apollo.QueryHookOptions<MediaOutletSuggestionsByMediaTypeQuery, MediaOutletSuggestionsByMediaTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaOutletSuggestionsByMediaTypeQuery, MediaOutletSuggestionsByMediaTypeQueryVariables>(
    MediaOutletSuggestionsByMediaTypeDocument,
    options
  );
}
export function useMediaOutletSuggestionsByMediaTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaOutletSuggestionsByMediaTypeQuery, MediaOutletSuggestionsByMediaTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaOutletSuggestionsByMediaTypeQuery, MediaOutletSuggestionsByMediaTypeQueryVariables>(
    MediaOutletSuggestionsByMediaTypeDocument,
    options
  );
}
export type MediaOutletSuggestionsByMediaTypeQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByMediaTypeQuery>;
export type MediaOutletSuggestionsByMediaTypeLazyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByMediaTypeLazyQuery>;
export type MediaOutletSuggestionsByMediaTypeQueryResult = Apollo.QueryResult<
  MediaOutletSuggestionsByMediaTypeQuery,
  MediaOutletSuggestionsByMediaTypeQueryVariables
>;
export const MediaOutletSuggestionsByFrequencyDocument = gql`
  query mediaOutletSuggestionsByFrequency($frequency: String!, $take: Int!) {
    mediaOutletSuggestionsByFrequency(frequency: $frequency, take: $take)
  }
`;

/**
 * __useMediaOutletSuggestionsByFrequencyQuery__
 *
 * To run a query within a React component, call `useMediaOutletSuggestionsByFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaOutletSuggestionsByFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaOutletSuggestionsByFrequencyQuery({
 *   variables: {
 *      frequency: // value for 'frequency'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMediaOutletSuggestionsByFrequencyQuery(
  baseOptions: Apollo.QueryHookOptions<MediaOutletSuggestionsByFrequencyQuery, MediaOutletSuggestionsByFrequencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaOutletSuggestionsByFrequencyQuery, MediaOutletSuggestionsByFrequencyQueryVariables>(
    MediaOutletSuggestionsByFrequencyDocument,
    options
  );
}
export function useMediaOutletSuggestionsByFrequencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaOutletSuggestionsByFrequencyQuery, MediaOutletSuggestionsByFrequencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaOutletSuggestionsByFrequencyQuery, MediaOutletSuggestionsByFrequencyQueryVariables>(
    MediaOutletSuggestionsByFrequencyDocument,
    options
  );
}
export type MediaOutletSuggestionsByFrequencyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByFrequencyQuery>;
export type MediaOutletSuggestionsByFrequencyLazyQueryHookResult = ReturnType<typeof useMediaOutletSuggestionsByFrequencyLazyQuery>;
export type MediaOutletSuggestionsByFrequencyQueryResult = Apollo.QueryResult<
  MediaOutletSuggestionsByFrequencyQuery,
  MediaOutletSuggestionsByFrequencyQueryVariables
>;
export const FetchMediaOutletsDataDocument = gql`
  query fetchMediaOutletsData {
    mediaOutletCategories {
      id
      name
      type {
        name
        value
      }
    }
  }
`;

/**
 * __useFetchMediaOutletsDataQuery__
 *
 * To run a query within a React component, call `useFetchMediaOutletsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMediaOutletsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMediaOutletsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMediaOutletsDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchMediaOutletsDataQuery, FetchMediaOutletsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMediaOutletsDataQuery, FetchMediaOutletsDataQueryVariables>(FetchMediaOutletsDataDocument, options);
}
export function useFetchMediaOutletsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMediaOutletsDataQuery, FetchMediaOutletsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMediaOutletsDataQuery, FetchMediaOutletsDataQueryVariables>(FetchMediaOutletsDataDocument, options);
}
export type FetchMediaOutletsDataQueryHookResult = ReturnType<typeof useFetchMediaOutletsDataQuery>;
export type FetchMediaOutletsDataLazyQueryHookResult = ReturnType<typeof useFetchMediaOutletsDataLazyQuery>;
export type FetchMediaOutletsDataQueryResult = Apollo.QueryResult<FetchMediaOutletsDataQuery, FetchMediaOutletsDataQueryVariables>;
export const FetchReferenceListsLocalizationQueryDocument = gql`
  query FetchReferenceListsLocalizationQuery {
    mediaOutletAudiences {
      id
      key
      labels {
        language {
          name
          value
        }
        value
      }
    }
    mediaOutletMediumSupportTypes {
      id
      key
      labels {
        language {
          name
          value
        }
        value
      }
    }
    mediaOutletMediumPublishingFrequency {
      id
      key
      labels {
        language {
          name
          value
        }
        value
      }
    }
  }
`;

/**
 * __useFetchReferenceListsLocalizationQueryQuery__
 *
 * To run a query within a React component, call `useFetchReferenceListsLocalizationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceListsLocalizationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceListsLocalizationQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchReferenceListsLocalizationQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchReferenceListsLocalizationQueryQuery, FetchReferenceListsLocalizationQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchReferenceListsLocalizationQueryQuery, FetchReferenceListsLocalizationQueryQueryVariables>(
    FetchReferenceListsLocalizationQueryDocument,
    options
  );
}
export function useFetchReferenceListsLocalizationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchReferenceListsLocalizationQueryQuery, FetchReferenceListsLocalizationQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchReferenceListsLocalizationQueryQuery, FetchReferenceListsLocalizationQueryQueryVariables>(
    FetchReferenceListsLocalizationQueryDocument,
    options
  );
}
export type FetchReferenceListsLocalizationQueryQueryHookResult = ReturnType<typeof useFetchReferenceListsLocalizationQueryQuery>;
export type FetchReferenceListsLocalizationQueryLazyQueryHookResult = ReturnType<typeof useFetchReferenceListsLocalizationQueryLazyQuery>;
export type FetchReferenceListsLocalizationQueryQueryResult = Apollo.QueryResult<
  FetchReferenceListsLocalizationQueryQuery,
  FetchReferenceListsLocalizationQueryQueryVariables
>;
