import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { DefaultButton, Dialog, DialogFooter, IDialogContentProps, Icon, PrimaryButton } from '@fluentui/react';
import { useIntl } from 'app/i18n';
import { useAppDispatch } from 'app/common';

interface TransformMediumDialogProps {
  opened: boolean;
  dismiss: () => void;
  dialogProps: IDialogContentProps;
  transformMediumRequested: ActionCreatorWithoutPayload;
  warningMessages?: string[];
  deleteButtonDisabled?: boolean;
}

export const TransformMediumDialog = ({
  opened,
  dismiss,
  dialogProps,
  transformMediumRequested,
  warningMessages,
  deleteButtonDisabled = false
}: TransformMediumDialogProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const onTransformItem = () => {
    dispatch(transformMediumRequested());
    dismiss();
  };

  return (
    <>
      <Dialog hidden={!opened} onDismiss={dismiss} dialogContentProps={dialogProps} modalProps={dialogModalProps}>
        {warningMessages?.map((message) => (
          <>
            <span className="orange-text" key={message}>
              <Icon iconName="Warning" />
              {`${formatMessage({ id: 'labels.warning' })}: `}
            </span>
            {message}
            <br />
          </>
        ))}
        <div className="profile-photo-dialog">
          <DialogFooter className="dialog-footer">
            <PrimaryButton
              className="dialog-purple-btn"
              disabled={deleteButtonDisabled}
              onClick={onTransformItem}
              text={formatMessage({ id: 'buttons.yes' })}
            />
            <DefaultButton className="dialog-outline-btn" onClick={dismiss} text={formatMessage({ id: 'buttons.no' })} />
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};
