import { client } from 'app/common/graphql/graphql-gateway.client';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { safe } from 'app/common';
import {
  addTemplate,
  deleteTemplate,
  deleteTemplateRequested,
  getTemplates,
  incrementPageNumber,
  PageOfTemplates,
  selectFilterItems,
  selectPageNumber,
  selectPageSize,
  selectSearchSuggestionsText,
  selectSearchText,
  selectTemplateToDeleteId,
  selectTemplateToUpdate,
  setTotalNumberOfTemplates,
  Template,
  templateAdded,
  templateDeleted,
  templatesReceived,
  templatesRequested,
  templateSuggestionsReceived,
  templateSuggestionsRequested,
  templateUpdated,
  updateTemplate,
  updateTemplateRequested
} from 'app/pages/my-activities/templates';
import { addTemplateRequested, resetTemplateToAdd, selectTemplateToAdd, updateSelectedTemplate } from 'app/pages/my-activities/sending-wizard';

function* getTemplatesFlow() {
  const pageNumber = yield select(selectPageNumber);
  const pageSize = yield select(selectPageSize);
  const filterItems = yield select(selectFilterItems);
  const searchText = yield select(selectSearchText);

  const pageOfTemplates: PageOfTemplates = yield call(getTemplates, client, pageNumber, pageSize, filterItems, searchText);

  yield put(templatesReceived(pageOfTemplates.templates));
  yield put(setTotalNumberOfTemplates(pageOfTemplates.totalCount));
}

export function* getTemplatesRequestedWatcher() {
  yield takeEvery(templatesRequested.type, safe(getTemplatesFlow));
  yield takeEvery(incrementPageNumber.type, safe(getTemplatesFlow));
}

function* templateSuggestionsFlow() {
  const searchText = yield select(selectSearchSuggestionsText);

  const pageOfTemplates: PageOfTemplates = yield call(getTemplates, client, 1, 10, [], searchText);

  yield put(templateSuggestionsReceived(pageOfTemplates.templates));
}

export function* templatesSuggestionsRequestedWatcher() {
  yield takeEvery(templateSuggestionsRequested.type, safe(templateSuggestionsFlow));
}

function* addTemplateFlow() {
  const templateToAdd: Template = yield select(selectTemplateToAdd);

  const template: Template = yield call(addTemplate, client, templateToAdd);
  yield put(templateAdded(template));
  yield put(updateSelectedTemplate(template));
  yield put(resetTemplateToAdd());
}

export function* addTemplateRequestedWatcher() {
  yield takeEvery(addTemplateRequested.type, safe(addTemplateFlow));
}

function* deleteTemplateFlow() {
  const templateToDeleteId: string = yield select(selectTemplateToDeleteId);

  yield call(deleteTemplate, client, templateToDeleteId);
  yield put(templateDeleted());
}

export function* deleteTemplateRequestedWatcher() {
  yield takeEvery(deleteTemplateRequested.type, safe(deleteTemplateFlow));
}

function* updateTemplateFlow() {
  const templateToUpdate: Template = yield select(selectTemplateToUpdate);

  const updatedTemplate: Template = yield call(updateTemplate, client, templateToUpdate);
  yield put(templateUpdated(updatedTemplate));
}

export function* updateTemplateRequestedWatcher() {
  yield takeEvery(updateTemplateRequested.type, safe(updateTemplateFlow));
}
