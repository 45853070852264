import { EditableDatePickerField, EditableInputNumberField, EditableInputTextField, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { Company } from 'app/pages/my-audience/companies';
import { selectCompanyContactsTotalCount, selectSelectedCompany, updateCompanyRequested } from 'app/pages/my-audience/company-profile';
import { Permission, useAuthContext } from 'auth';
import { cloneDeep } from 'lodash';

export const AdditionalInformation = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedCompany = useAppSelector<Company>(selectSelectedCompany);
  const totalCountOfContacts = useAppSelector<number>(selectCompanyContactsTotalCount);
  let selectedCompanyLocal = cloneDeep(selectedCompany);

  const { hasAllPermissions } = useAuthContext();
  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const onSaveChanges = (fieldName: string, updatedValue: string | number | Date) => {
    selectedCompanyLocal = { ...selectedCompanyLocal, [fieldName]: updatedValue };
    dispatch(updateCompanyRequested(selectedCompanyLocal));
  };

  return (
    <div className="editable-fields-wrap">
      <div className="editable-fields-content">
        <div className="editable-fields-row">
          <span className="editable-fields-row-label">{`${formatMessage({ id: 'companies-additional-labels.employees' })}`}:</span>
          <div className="editable-fields-row-content">
            <span className="editable-fields-value">{totalCountOfContacts}</span>
          </div>
        </div>
        <EditableInputTextField
          value={selectedCompany.parentCompany}
          fieldName="parentCompany"
          onChange={onSaveChanges}
          label={formatMessage({ id: 'companies-additional-labels.parent-company' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.registrationNumber}
          fieldName="registrationNumber"
          onChange={onSaveChanges}
          label={formatMessage({ id: 'companies-additional-labels.reg-number' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableDatePickerField
          value={selectedCompany.foundationDate}
          fieldName="foundationDate"
          onChange={onSaveChanges}
          label={formatMessage({ id: 'companies-additional-labels.foundation-date' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.description}
          fieldName="description"
          onChange={onSaveChanges}
          label={formatMessage({ id: 'companies-additional-labels.description' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.director}
          fieldName="director"
          onChange={onSaveChanges}
          label={formatMessage({ id: 'companies-additional-labels.director' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputNumberField
          value={selectedCompany.profit}
          fieldName="profit"
          onSaveChanges={onSaveChanges}
          label={formatMessage({ id: 'companies-additional-labels.profit' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
      </div>
    </div>
  );
};
