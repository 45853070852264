import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddMediaOutletFinish,
  AddMediaOutletFooter,
  AddMediaOutletProfileInfo,
  AddMediaOutletSteps,
  AddMediaOutletWizardSteps,
  closeAddMediaWizard,
  SearchMediaOutlet,
  selectAddMediaOutletCurrentStep,
  selectIsAddMediaWizardOpened
} from 'app/pages/my-audience/contact-profile';

export const AddMediaOutletWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isAddMediaWizardOpened = useAppSelector<boolean>(selectIsAddMediaWizardOpened);
  const currentStep = useAppSelector<AddMediaOutletWizardSteps>(selectAddMediaOutletCurrentStep);

  const handleDismissButtonClick = () => {
    dispatch(closeAddMediaWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isAddMediaWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-medium-wizard-header' }, { mediumType: formatMessage({ id: 'labels.media-outlet' }) })}
      customWidth="750px"
    >
      <div className="wizard-panel-main">
        <AddMediaOutletSteps />
        {currentStep === AddMediaOutletWizardSteps.SearchMediaOutlet && <SearchMediaOutlet />}
        {currentStep === AddMediaOutletWizardSteps.ProfileInformation && <AddMediaOutletProfileInfo />}
        {currentStep === AddMediaOutletWizardSteps.Finish && <AddMediaOutletFinish />}
        <AddMediaOutletFooter />
      </div>
    </WizardWrap>
  );
};
