import * as Sentry from '@sentry/react';
import { ActionMatchingPattern } from '@redux-saga/types';
import { MessageData, getErrorArgs, getErrorCode, setErrorData } from 'app/common';
import { IntlMessage, NestedKeys } from 'app/i18n';
import { ActionPattern, put } from 'redux-saga/effects';

type AugureErrorType = { message: string };

type CallbackType = <P extends ActionPattern>(action: ActionMatchingPattern<P>) => any;

type SafelyHandleErrorProps = {
  performAction: CallbackType;
  onCatch?: CallbackType;
  onFinally?: CallbackType;
};

export const safe = <P extends ActionPattern>(callback: (action: ActionMatchingPattern<P>) => any) =>
  function* (action: ActionMatchingPattern<P>) {
    try {
      yield* callback(action);
    } catch (error) {
      yield logError(error, callback.name);
    }
  };

export const safelyHandleError = <P extends ActionPattern>({ performAction, onCatch, onFinally }: SafelyHandleErrorProps) =>
  function* (action: ActionMatchingPattern<P>) {
    try {
      yield* performAction(action);
    } catch (error) {
      if (onCatch) {
        yield* onCatch(action);
      }
      yield logError(error, performAction.name);
    } finally {
      if (onFinally) {
        yield* onFinally(action);
      }
    }
  };

function* logError(error: AugureErrorType, callbackName: string) {
  // TODO: This is for debugging purposes only, should be removed in production environment
  console.error(error);

  const errorCode: string = getErrorCode(error.message);
  const errorArgs: string[] = getErrorArgs(error.message);

  yield put(setErrorData(new MessageData(errorCode as NestedKeys<IntlMessage>, errorArgs)));

  Sentry.setExtra('Path', callbackName);
  Sentry.captureException(error);
}
