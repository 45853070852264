import { useAppDispatch, useAppSelector } from 'app/common';
import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { CreateContactFromCompanyWizardStep, goToCreateContactStep, selectCompaniesContactWizardStep } from 'app/pages/my-audience/wizard-create-company';
import { useIntl } from 'app/i18n';

interface CreateContactFromCompanyStepsProps {
  isStepValid?: boolean;
  onStepClick?: () => void;
}

export const CreateContactFromCompanySteps = ({ isStepValid = true, onStepClick }: CreateContactFromCompanyStepsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const step = useAppSelector<CreateContactFromCompanyWizardStep>(selectCompaniesContactWizardStep);

  const handleStepButtonClick = (step: CreateContactFromCompanyWizardStep) => {
    if (onStepClick !== undefined) {
      onStepClick();
    }
    dispatch(goToCreateContactStep(step));
  };

  const styleBreadcrumbStatus = (stepToCompareWith: CreateContactFromCompanyWizardStep): string => {
    const buttonClass = 'wizard-panel-breadcrumb-button';
    if (step === stepToCompareWith) return `${buttonClass} active`;
    if (stepToCompareWith < step) return `${buttonClass} completed`;
    return buttonClass;
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactFromCompanyWizardStep.CreateNew)}
            content={formatMessage({ id: 'add-menu-create-company.contact-step-info' })}
            title="Create New"
            onClick={() => handleStepButtonClick(CreateContactFromCompanyWizardStep.CreateNew)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateContactFromCompanyWizardStep.ReviewAndFinish)}
            content={formatMessage({ id: 'add-menu-create-company.contact-step-finish' })}
            title="Review & Finish"
            onClick={() => handleStepButtonClick(CreateContactFromCompanyWizardStep.ReviewAndFinish)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default CreateContactFromCompanySteps;
