import { Button, EditIcon } from '@fluentui/react-northstar';
import { printData, useAppSelector } from 'app/common';
import { selectLocalizedAudiences, selectLocalizedFrequencies } from 'app/localization';
import { LocalizedListValue } from 'app/pages/my-audience/media-outlets';
import { FormattedMessage, useIntl } from 'app/i18n';
import { MediaOutletAddressInformation, CreateMediaOutletWizardStep, MediaOutletGeneralInformation } from 'app/pages/my-audience/wizard-create-media-outlet';

interface ReviewMediaOutletInformationProps {
  onEdit: (stepNumber: number) => void;
  generalInfo: MediaOutletGeneralInformation;
  addressInfo: MediaOutletAddressInformation;
}

export const ReviewMediaOutletInformation = ({ onEdit, generalInfo, addressInfo }: ReviewMediaOutletInformationProps) => {
  const { formatMessage } = useIntl();

  const localizedAudiences = useAppSelector<LocalizedListValue[]>(selectLocalizedAudiences);
  const localizedFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);

  const selectedFrequency = localizedFrequencies.find((f) => f.key === generalInfo.frequencyKey)?.label;
  const selectedAudience = localizedAudiences.find((f) => f.key === generalInfo.audienceKey)?.label;

  return (
    <>
      <div className="wizard-panel-finish-main">
        <div className="wizard-panel-finish-main-intro">
          <h6 className="wizard-panel-finish-title">
            <FormattedMessage id={'add-menu-create-mo.additional-finish-info'} />
          </h6>
          <Button
            icon={<EditIcon />}
            text
            content={formatMessage({ id: 'buttons.edit' })}
            onClick={() => onEdit(CreateMediaOutletWizardStep.GeneralInformation)}
          />
        </div>
        <div className="wizard-panel-finish-main-content">
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.wizard-name-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(generalInfo.name)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.wizard-description-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(generalInfo.description)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.wizard-audience-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(selectedAudience)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.wizard-category-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(generalInfo.category?.name)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.wizard-frequency-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(selectedFrequency)}</span>
          </div>
        </div>
      </div>
      <div className="wizard-panel-finish-main">
        <div className="wizard-panel-finish-main-intro">
          <h6 className="wizard-panel-finish-title">
            <FormattedMessage id={'add-menu-create-mo.additional-finish-address'} />
          </h6>
          <Button
            icon={<EditIcon />}
            text
            content={formatMessage({ id: 'buttons.edit' })}
            onClick={() => onEdit(CreateMediaOutletWizardStep.AddressInformation)}
          />
        </div>
        <div className="wizard-panel-finish-main-content">
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-address-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.address)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-po-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.poBox)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-postal-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.postalCode)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-city-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.city)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-country-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.country)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-website-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.websiteUrl)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-email-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.emailAddress)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-phone-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.phoneNumber)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.mo-fax-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(addressInfo.fax)}</span>
          </div>
        </div>
      </div>
    </>
  );
};
