export const COMPANY_FILTER = {
  NAME: 'name',
  CONTACT_NAME: 'contactName',
  TAG: 'tag',
  COUNTRY: 'country',
  REGISTRATION_NUMBER: 'registrationNumber',
  DATABASE_TYPE: 'databaseType',
  CITY: 'city',
  POSTAL_CODE: 'postalCode',
  PARENT_COMPANY: 'parentCompany',
  CREATED_BY: 'createdBy',
  PHONE_NUMBER: 'phoneNumber'
};
