import { PreviewTop } from 'app/pages/my-activities/sending-wizard/components/preview/PreviewTop';
import { PreviewTemplate } from 'app/pages/my-activities/sending-wizard/components/preview/PreviewTemplate';
import {
  resetSelectedSending,
  ErrorDialog,
  goToNextStep,
  goToPreviousStep,
  openMissingFieldErrorDialog,
  openScheduleSendDialog,
  openSendDialog,
  selectEmailBodyInEditor,
  selectSelectedSending,
  selectSendingToAddSetup,
  SendDialog,
  SendingSetup,
  SendingWizardFooter,
  SendingWizardSteps,
  updateSendingRequested,
  ScheduleSendDialog,
  startLoadingImage,
  selectSelectedSendingRecipients
} from 'app/pages/my-activities/sending-wizard';
import { useAppDispatch, useAppSelector } from 'app/common';
import { EmailBody, Recipient, Sending } from 'app/pages/my-activities/sendings';
import { useIntl } from 'app/i18n';
import { useEffect } from 'react';

export const PreviewAndSend = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const sendingSetup = useAppSelector<SendingSetup>(selectSendingToAddSetup);
  const selectedSending = useAppSelector<Sending>(selectSelectedSending);
  const sendingBody = useAppSelector<EmailBody>(selectEmailBodyInEditor);
  const recipients = useAppSelector<Recipient[]>(selectSelectedSendingRecipients);

  useEffect(() => {
    dispatch(startLoadingImage());
  }, [dispatch]);

  const handleSaveAndCloseButtonClick = () => {
    dispatch(updateSendingRequested(selectedSending));
    dispatch(resetSelectedSending());
  };

  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };

  const onSendClick = () => {
    if (sendingSetup.title === '')
      dispatch(
        openMissingFieldErrorDialog(formatMessage({ id: 'error-messages.missing-field' }, { fieldName: formatMessage({ id: 'emailing-wizard.labels.title' }) }))
      );
    if (sendingSetup.subject === '')
      dispatch(
        openMissingFieldErrorDialog(
          formatMessage({ id: 'error-messages.missing-field' }, { fieldName: formatMessage({ id: 'emailing-wizard.labels.subject' }) })
        )
      );
    if (!sendingSetup.sender)
      dispatch(
        openMissingFieldErrorDialog(
          formatMessage({ id: 'error-messages.missing-field' }, { fieldName: formatMessage({ id: 'emailing-wizard.labels.sender-email-address' }) })
        )
      );
    if (selectedSending.numberOfRecipients === 0)
      dispatch(
        openMissingFieldErrorDialog(
          formatMessage({ id: 'error-messages.missing-field' }, { fieldName: formatMessage({ id: 'emailing-wizard.labels.recipients' }) })
        )
      );
    if (recipients.some((recipient) => recipient.emailAddress?.length === 0))
      dispatch(openMissingFieldErrorDialog(formatMessage({ id: 'emailing-wizard-preview-and-send-step.recipients-without-email-address-on-send' })));

    const emailBody = sendingBody.htmlBody;
    if (emailBody === undefined || !emailBody.includes('https://[unsubscribe]'))
      dispatch(openMissingFieldErrorDialog(formatMessage({ id: 'emailing-wizard.labels.unsubscribe-link-msg' })));
  };

  const onSendImmediately = () => {
    dispatch(openSendDialog());
  };

  const onScheduleSend = () => {
    dispatch(openScheduleSendDialog());
  };
  return (
    <>
      <SendingWizardSteps areFieldsValid={true} />
      <div className="wizard-panel-form-wrap wizard-preview-panel">
        <PreviewTop />
        <PreviewTemplate />
        <SendingWizardFooter
          onSaveAndCloseButtonClick={handleSaveAndCloseButtonClick}
          onNextButtonClick={handleNextButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onSendClick={onSendClick}
          onScheduleSend={onScheduleSend}
          onSendImmediately={onSendImmediately}
        />
      </div>
      <SendDialog />
      <ScheduleSendDialog />
      <ErrorDialog />
    </>
  );
};
