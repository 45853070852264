import { selectIsDeleteEntitiesDialogOpen, UndoableDialog, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';

interface DeleteEntitiesDialogProps {
  entityNameInSingular: string;
  entityNameInPlural: string;
  numberOfSelectedEntities: number;
  onExecuteAction: () => void;
  onExecuteInStore: () => void;
  onRevertAction: () => void;
  onCancel: () => void;
  additionalInfoExternal?: string;
  additionalInfoUsers?: string;
  warningMessages?: string[];
  deleteButtonDisabled?: boolean;
}

export const DeleteEntitiesDialog = ({
  entityNameInSingular,
  entityNameInPlural,
  numberOfSelectedEntities,
  onExecuteAction,
  onExecuteInStore,
  onRevertAction,
  onCancel,
  additionalInfoExternal,
  additionalInfoUsers,
  warningMessages,
  deleteButtonDisabled = false
}: DeleteEntitiesDialogProps) => {
  const { formatMessage } = useIntl();
  const isDialogOpen = useAppSelector<boolean>(selectIsDeleteEntitiesDialogOpen);
  const dialogText = `${additionalInfoUsers ?? ''} ${additionalInfoExternal ?? ''}`.trim();

  return (
    <UndoableDialog
      opened={isDialogOpen}
      dialogTitle={formatMessage(
        { id: 'delete-companies-dialog.are-you-sure' },
        { count: numberOfSelectedEntities, entityName: numberOfSelectedEntities > 1 ? entityNameInPlural : entityNameInSingular }
      )}
      dialogText={dialogText}
      alertMessage={formatMessage(
        { id: 'delete-companies-dialog.undo-delete' },
        { entityName: numberOfSelectedEntities > 1 ? entityNameInPlural : entityNameInSingular }
      )}
      onExecuteAction={onExecuteAction}
      onExecuteInStore={onExecuteInStore}
      onRevertAction={onRevertAction}
      onClose={onCancel}
      warningMessages={warningMessages}
      deleteButtonDisabled={deleteButtonDisabled}
    />
  );
};
