import { useAppSelector } from 'app/common/hooks';
import { Icon } from '@fluentui/react';
import { Button, ButtonProps, ComponentEventHandler } from '@fluentui/react-northstar';
import { CreateContactWizardSteps, selectCreateContactCurrentStep, selectIsCreateContactFinishButtonDisabled } from 'app/pages/my-audience/company-profile';
import { useIntl } from 'app/i18n';

interface CreateContactWizardFooterProps {
  onCancelButtonClick: ComponentEventHandler<ButtonProps>;
  onBackButtonClick?: ComponentEventHandler<ButtonProps>;
  onNextButtonClick?: ComponentEventHandler<ButtonProps>;
  onFinishButtonClick?: ComponentEventHandler<ButtonProps>;
  isNextButtonDisabled?: boolean;
}

export const CreateContactWizardFooter = ({
  onBackButtonClick,
  onNextButtonClick,
  onFinishButtonClick,
  onCancelButtonClick,
  isNextButtonDisabled
}: CreateContactWizardFooterProps) => {
  const currentStep = useAppSelector<CreateContactWizardSteps>(selectCreateContactCurrentStep);
  const isFinishButtonDisabled = useAppSelector<boolean>(selectIsCreateContactFinishButtonDisabled);
  const { formatMessage } = useIntl();

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCancelButtonClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > CreateContactWizardSteps.CreateNew && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={onBackButtonClick} />
        )}
        {currentStep < CreateContactWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={onNextButtonClick} disabled={isNextButtonDisabled} />
        )}
        {currentStep === CreateContactWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.finish' })} primary onClick={onFinishButtonClick} disabled={isFinishButtonDisabled} />
        )}
      </div>
    </div>
  );
};
