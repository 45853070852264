import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  selectMediaOutletsTotalCount,
  selectMediaOutletsPageSize,
  setPageNumber,
  pageOfMediaOutletsRequested,
  selectIsLoadingMediaOutlets,
  setPageSize
} from 'app/pages/my-audience/contact-profile';

export const CollaborationTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfMediaOutlets = useAppSelector<number>(selectMediaOutletsTotalCount);
  const pageSize = useAppSelector<number>(selectMediaOutletsPageSize);
  const isLoadingMediaOutlets = useAppSelector<boolean>(selectIsLoadingMediaOutlets);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
    dispatch(pageOfMediaOutletsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setPageSize(pageSize));
    dispatch(pageOfMediaOutletsRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfMediaOutlets}
      isLoadingData={isLoadingMediaOutlets}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
