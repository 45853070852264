import { Collaboration } from 'app/pages/my-audience/contacts/contacts.model';

export enum CreateContactFromCompanyWizardStep {
  CreateNew = 1,
  ReviewAndFinish
}

export enum CreateCompanyWizardStep {
  GeneralInfo = 1,
  AddressInfo,
  ContactTab,
  AdditionalInfo,
  Finish
}

export type CollaborationForm = {
  jobTitle: string;
  jobDescription: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
  emailAddress: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
};

export class GeneralInfo {
  constructor(public name: string, public parentCompany: string, public comment: string) {}
}

export class AddressInfo {
  constructor(
    public email: string,
    public phoneNumber: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public poBox: string,
    public fax: string,
    public website: string,
    public country: string
  ) {}
}

export class AdditionalContactInfo {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public jobTitle: string,
    public jobDescription: string,
    public email: string,
    public phoneNumber: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country: string
  ) {}
}

export class ContactInfo {
  constructor(public title: string, public firstName: string, public lastName: string) {}
}
