import { Button, ButtonProps, ComponentEventHandler } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react';
import { useAppSelector } from 'app/common';
import {
  CreateMediaOutletWizardStep,
  selectCreateMediaOutletCurrentStep,
  selectIsCreateMediaOutletFinishButtonDisabled,
  selectIsLoadingAddingMediaOutlet
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { useIntl } from 'app/i18n';

interface CreateMediaOutletFooterProps {
  onCancelButtonClick: ComponentEventHandler<ButtonProps>;
  onBackButtonClick?: ComponentEventHandler<ButtonProps>;
  onNextButtonClick?: ComponentEventHandler<ButtonProps>;
  onFinishButtonClick?: ComponentEventHandler<ButtonProps>;
  isNextButtonDisabled?: boolean;
}

export const CreateMediaOutletFooter = ({
  onBackButtonClick,
  onNextButtonClick,
  onFinishButtonClick,
  onCancelButtonClick,
  isNextButtonDisabled
}: CreateMediaOutletFooterProps) => {
  const currentStep = useAppSelector<CreateMediaOutletWizardStep>(selectCreateMediaOutletCurrentStep);
  const isFinishButtonDisabled = useAppSelector<boolean>(selectIsCreateMediaOutletFinishButtonDisabled);
  const isLoadingAddingMediaOutlet = useAppSelector(selectIsLoadingAddingMediaOutlet);
  const { formatMessage } = useIntl();
  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCancelButtonClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > CreateMediaOutletWizardStep.GeneralInformation && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={onBackButtonClick} />
        )}
        {currentStep < CreateMediaOutletWizardStep.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={onNextButtonClick} disabled={isNextButtonDisabled} />
        )}
        {currentStep === CreateMediaOutletWizardStep.Finish && (
          <Button
            content={formatMessage({ id: 'buttons.finish' })}
            primary
            onClick={onFinishButtonClick}
            disabled={isFinishButtonDisabled}
            loading={isLoadingAddingMediaOutlet}
          />
        )}
      </div>
    </div>
  );
};
