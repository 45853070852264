import { Skeleton } from '@fluentui/react-northstar';

export const ShimmerCards = () => {
  return (
    <Skeleton animation="pulse" className="shimmer-wrap">
      <div className="shimmer-cards-wrap">
        <Skeleton.Shape />
        <Skeleton.Shape />
        <Skeleton.Shape />
        <Skeleton.Shape />
        <Skeleton.Shape />
        <Skeleton.Shape />
      </div>
    </Skeleton>
  );
};
