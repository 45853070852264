import { useNavigate } from 'react-router-dom';
import { closeSavedSearchesPanel, searchMediaOutletsRequested, setFilters, selectIsSavedSearchesPanelOpen } from 'app/pages/my-audience/media-outlets';
import { FilterItem, useAppDispatch, useAppSelector } from 'app/common';
import { SavedSearch, SavedSearchPanel, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import {
  selectIsLoadingMediaOutletSavedSearches,
  selectMediaOutletSavedSearches,
  selectMediaOutletSavedSearchToRemove,
  updateMediaOutletSavedSearchesRequested,
  updateMediaOutletSavedSearchRequested,
  setMediaOutletSavedSearchToRemove,
  removeMediaOutletSavedSearchRequested,
  selectIsDeleteDialogOpened,
  closeDeleteDialog,
  selectTotalCountOfSavedSearches,
  selectSearchText,
  selectFilterItem,
  selectPageNumber,
  firstPageOfSavedSearchesRequested,
  mediaOutletsSavedSearchesRequested
} from 'app/pages/my-audience/media-outlets-saved-searches';

export const MediaOutletsSavedSearchesPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoadingMediaOutletSavedSearches = useAppSelector<boolean>(selectIsLoadingMediaOutletSavedSearches);
  const isDeleteDialogOpened = useAppSelector<boolean>(selectIsDeleteDialogOpened);
  const totalCountOfSavedSearches = useAppSelector<number>(selectTotalCountOfSavedSearches);
  const searchText = useAppSelector<string>(selectSearchText);
  const filterItem = useAppSelector<FilterItem>(selectFilterItem);
  const pageNumber = useAppSelector<number>(selectPageNumber);
  const mediaOutletSavedSearches = useAppSelector<SavedSearch[]>(selectMediaOutletSavedSearches);
  const savedSearchToRemove = useAppSelector<SavedSearch>(selectMediaOutletSavedSearchToRemove);
  const isSavedSearchesPanelOpen = useAppSelector<boolean>(selectIsSavedSearchesPanelOpen);

  const handleSearchSavedSearches = (searchText: string) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleFilterSavedSearches = (filterItem: FilterItem) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleResetFilterSavedSearches = () => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: {} as FilterItem
      })
    );
  };

  const handleLoadMore = () => {
    dispatch(
      mediaOutletsSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem,
        pageNumber: pageNumber + 1
      })
    );
  };

  const onUpdateMediaOutletSavedSearches = (reorderedSavedSearches: SavedSearch[]) => {
    dispatch(
      updateMediaOutletSavedSearchesRequested(
        reorderedSavedSearches.map((ss) => new SavedSearchWithIdInput(ss.id, ss.name, ss.searchText, ss.filterItems, ss.priority, ss.count))
      )
    );
  };

  const onUpdateMediaOutletSavedSearch = (updatedSavedSearch: SavedSearch) => {
    dispatch(updateMediaOutletSavedSearchRequested(updatedSavedSearch));
  };

  const onSetMediaOutletSavedSearchToRemove = (savedSearchToRemove: SavedSearch) => {
    dispatch(setMediaOutletSavedSearchToRemove(savedSearchToRemove));
  };

  const onSearchMediaOutletSavedSearch = (savedSearch: SavedSearch) => {
    dispatch(setFilters(savedSearch.filterItems));
    dispatch(searchMediaOutletsRequested(savedSearch.searchText));
    navigate(`/my-audience/media-outlets/results/${savedSearch.searchText ?? '*'}`);
  };

  const onRemoveMediaOutletSavedSearch = () => {
    dispatch(removeMediaOutletSavedSearchRequested(savedSearchToRemove));
  };

  const onCloseDeleteDialog = () => {
    dispatch(closeDeleteDialog());
  };

  const onDismissSavedSearchesPanel = () => {
    dispatch(closeSavedSearchesPanel());
    navigate(-1);
  };

  return (
    <SavedSearchPanel
      isOpen={isSavedSearchesPanelOpen}
      dismissPanel={onDismissSavedSearchesPanel}
      isLoadingSavedSearches={isLoadingMediaOutletSavedSearches}
      savedSearches={mediaOutletSavedSearches}
      totalCount={totalCountOfSavedSearches}
      onSearchSavedSearches={handleSearchSavedSearches}
      onFilterSavedSearches={handleFilterSavedSearches}
      onResetFilter={handleResetFilterSavedSearches}
      onHandleLoadMore={handleLoadMore}
      onSavedSearchClick={onSearchMediaOutletSavedSearch}
      onReorderSavedSearches={onUpdateMediaOutletSavedSearches}
      onSetSavedSearchToRemove={onSetMediaOutletSavedSearchToRemove}
      onUpdateSavedSearch={onUpdateMediaOutletSavedSearch}
      isDeleteDialogOpened={isDeleteDialogOpened}
      onRemoveSavedSearch={onRemoveMediaOutletSavedSearch}
      onCloseDeleteDialog={onCloseDeleteDialog}
      searchText={searchText}
    />
  );
};
