import React, { useState } from 'react';
import { Menu } from '@fluentui/react-northstar';

import { useAppSelector } from 'app/common';
import { selectSelectedSendingStatus } from 'app/pages/my-activities/sending-wizard';
import { SendingStatus } from 'app/pages/my-activities/sendings';
import { useIntl } from 'app/i18n';

interface NavigationMenuProps {
  onSelectMenuItem: any;
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ onSelectMenuItem }) => {
  const { formatMessage } = useIntl();
  const selectedSendingStatus = useAppSelector(selectSelectedSendingStatus);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const getIsActiveTabClass = (index: number) => (activeIndex === index ? 'active' : '');

  const items = [SendingStatus.WaitingForDistribution, SendingStatus.Draft].includes(selectedSendingStatus)
    ? [
        {
          key: 'audience',
          content: formatMessage({ id: 'sending-profile.audience-tab' }),
          className: `profile-panel-tabs-item ${getIsActiveTabClass(0)}`,
          index: 0
        },
        {
          key: 'preview',
          content: formatMessage({ id: 'sending-profile.preview-tab' }),
          className: `profile-panel-tabs-item ${getIsActiveTabClass(1)}`,
          index: 1
        },
        {
          key: 'log',
          content: formatMessage({ id: 'sending-profile.log-tab' }),
          className: `profile-panel-tabs-item ${getIsActiveTabClass(2)}`,
          index: 2
        }
      ]
    : [
        {
          key: 'report',
          content: formatMessage({ id: 'sending-profile.report-tab' }),
          className: `profile-panel-tabs-item ${getIsActiveTabClass(0)}`,
          index: 0
        },
        {
          key: 'preview',
          content: formatMessage({ id: 'sending-profile.preview-tab' }),
          className: `profile-panel-tabs-item ${getIsActiveTabClass(1)}`,
          index: 1
        },
        {
          key: 'log',
          content: formatMessage({ id: 'sending-profile.log-tab' }),
          className: `profile-panel-tabs-item ${getIsActiveTabClass(2)}`,
          index: 2
        }
      ];

  const onItemClickHandler = (event, item) => {
    setActiveIndex(item.index);
    onSelectMenuItem(items.find((i) => i.index === item.index).key);
  };

  return (
    <div className="profile-panel-tabs">
      <Menu onItemClick={(event, item) => onItemClickHandler(event, item)} items={items} underlined primary activeIndex={activeIndex} />
    </div>
  );
};
