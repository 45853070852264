import { selectSelectedMediaOutlet, updateMediaOutletRequested } from 'app/pages/my-audience/media-outlet-profile';
import {
  fieldContainsNonEmptyValue,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  isValidUrlField,
  EditableDropdownField,
  EditableInputTextField,
  isPhoneNumberValidOrEmpty,
  ensureHttpProtocol
} from 'app/common';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { MediaOutlet } from 'app/pages/my-audience/media-outlets';
import { getNames } from 'country-list';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';

export const ContactData = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);

  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);

  const handleOnChange = (fieldName: string, updatedValue: string) => {
    const selectedMediaOutletLocal = { ...selectedMediaOutlet, contactData: { ...selectedMediaOutlet.contactData, [fieldName]: updatedValue } };
    dispatch(updateMediaOutletRequested(selectedMediaOutletLocal));
  };

  const onSaveUrl = (fieldName: string, url: string) => {
    const selectedMediaOutletLocal = { ...selectedMediaOutlet, contactData: { ...selectedMediaOutlet.contactData, [fieldName]: ensureHttpProtocol(url) } };
    dispatch(updateMediaOutletRequested(selectedMediaOutletLocal));
  };

  return (
    <div className="editable-fields-wrap">
      <div className="editable-fields-content">
        <EditableInputTextField
          value={selectedMediaOutlet.contactData.street}
          fieldName="street"
          onChange={handleOnChange}
          label={formatMessage({ id: 'mo-contact-data-labels.address' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.contactData.postalCode}
          fieldName="postalCode"
          onChange={handleOnChange}
          isLink={false}
          label={formatMessage({ id: 'mo-contact-data-labels.zip' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-postal-code' })}
          isFieldValid={isNumberValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.contactData.city}
          fieldName="city"
          onChange={handleOnChange}
          isLink={false}
          label={formatMessage({ id: 'mo-contact-data-labels.city' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableDropdownField
          value={selectedMediaOutlet.contactData.country}
          fieldName="country"
          onSaveChanges={handleOnChange}
          suggestions={getNames()}
          label={formatMessage({ id: 'mo-contact-data-labels.country' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.contactData.landlinePhoneNumber}
          fieldName="landlinePhoneNumber"
          onChange={handleOnChange}
          label={formatMessage({ id: 'mo-contact-data-labels.phone-number' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-phone-number' })}
          isLink={fieldContainsNonEmptyValue(selectedMediaOutlet.contactData.landlinePhoneNumber)}
          linkUrl={'tel:+' + selectedMediaOutlet.contactData.landlinePhoneNumber}
          isFieldValid={isPhoneNumberValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.contactData.fax}
          fieldName="fax"
          onChange={handleOnChange}
          label={formatMessage({ id: 'mo-contact-data-labels.fax' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.contactData.emailAddress}
          fieldName="emailAddress"
          onChange={handleOnChange}
          label={formatMessage({ id: 'mo-contact-data-labels.email' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-email-address' })}
          isLink={fieldContainsNonEmptyValue(selectedMediaOutlet.contactData.emailAddress)}
          linkUrl={'mailto:' + selectedMediaOutlet.contactData.emailAddress}
          isFieldValid={isEmailAddressValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.contactData.websiteUrl}
          fieldName="websiteUrl"
          onChange={onSaveUrl}
          label={formatMessage({ id: 'mo-contact-data-labels.website' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
          isLink={fieldContainsNonEmptyValue(selectedMediaOutlet.contactData.websiteUrl)}
          linkUrl={selectedMediaOutlet.contactData.websiteUrl}
          isFieldValid={isValidUrlField}
          disabled={!hasPermissionToEdit}
        />
      </div>
    </div>
  );
};
