import { Button, Input, SearchIcon } from '@fluentui/react-northstar';
import { useDebouncedCallback } from 'use-debounce';
import { Icon } from '@fluentui/react';
import { SelectedEntitiesLabel, debounceTimeInMilliseconds, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfContactsRequested,
  openCreateContactWizard,
  setSearchContactsText,
  selectCollaborationDetails
} from 'app/pages/my-audience/wizard-create-company';
import { useIntl } from 'app/i18n';
import { useEffect } from 'react';

export const CollaborationTableToolbar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const collaborationDetails = useAppSelector(selectCollaborationDetails);

  useEffect(() => {
    return () => {
      dispatch(setSearchContactsText(''));
    };
  }, [dispatch]);

  const onInputChange = useDebouncedCallback((searchText: string) => {
    dispatch(setSearchContactsText(searchText));
    dispatch(pageOfContactsRequested());
  }, debounceTimeInMilliseconds);

  return (
    <>
      <div className="wizard-panel-toolbar">
        <div className="wizard-panel-toolbar-create">
          <Button
            text
            icon={<Icon iconName="Add" />}
            content={formatMessage({ id: 'add-menu-create-company.collaboration-add-btn' })}
            onClick={() => {
              dispatch(openCreateContactWizard());
            }}
          />
        </div>
        <SelectedEntitiesLabel counter={collaborationDetails.length} />
        <div className="wizard-panel-toolbar-filters">
          <div className="wizard-panel-toolbar-input">
            <Input
              clearable
              icon={<SearchIcon />}
              placeholder={formatMessage({ id: 'add-menu-create-company.collaboration-search-label' })}
              autoComplete="off"
              onChange={(_, data) => onInputChange(data.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
