import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateMediaOutletAddressInfo,
  CreateMediaOutletWizardSteps,
  CreateMediaOutletFinish,
  closeCreateMediaWizard,
  selectCreateMediaOutletCurrentStep,
  selectIsIsCreateMediaWizardOpened,
  CreateMediaOutletProfileInfo,
  CreateMediaOutletGeneralInfo,
  CreateMediaOutletFooter,
  CreateMediaOutletSteps
} from 'app/pages/my-audience/contact-profile';

export const CreateMediaOutletWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isCreateContactWizardOpened = useAppSelector<boolean>(selectIsIsCreateMediaWizardOpened);
  const currentStep = useAppSelector<CreateMediaOutletWizardSteps>(selectCreateMediaOutletCurrentStep);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateMediaWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'create-medium-wizard-header' }, { mediumType: formatMessage({ id: 'labels.media-outlet' }) })}
      customWidth="750px"
    >
      <div className="wizard-panel-main">
        <CreateMediaOutletSteps />
        {currentStep === CreateMediaOutletWizardSteps.GeneralInformation && <CreateMediaOutletGeneralInfo />}
        {currentStep === CreateMediaOutletWizardSteps.AddressInfrormation && <CreateMediaOutletAddressInfo />}
        {currentStep === CreateMediaOutletWizardSteps.ProfileInformation && <CreateMediaOutletProfileInfo />}
        {currentStep === CreateMediaOutletWizardSteps.Finish && <CreateMediaOutletFinish />}
        <CreateMediaOutletFooter />
      </div>
    </WizardWrap>
  );
};
