import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { getNames } from 'country-list';
import {
  addJobTitleRequested,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regEmail,
  regNumbers,
  selectMediaOutletJobTitles,
  useAppDispatch,
  useAppSelector,
  WizardIntroInfo,
  isPhoneNumberValidOrEmpty
} from 'app/common';
import {
  createNewMediaOutlet,
  selectSelectedContact,
  setCollaborationProfileInfo,
  selectCollaborationInfoToAdd,
  AddMediaProfileInformationForm,
  setIsAddMediaOutletWizardProfileInfoStepValid
} from 'app/pages/my-audience/contact-profile';
import { ControlledCreateDropdown, ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { JobTitle, MediumType } from 'app/pages/my-audience/contacts';

export const AddMediaOutletProfileInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedContact = useAppSelector(selectSelectedContact);
  const mediaOutletJobTitles = useAppSelector(selectMediaOutletJobTitles);
  const mediaOutletProfileInfo = useAppSelector(selectCollaborationInfoToAdd);
  const jobTitles = mediaOutletJobTitles.map((jobTitle) => jobTitle.name);

  const { handleSubmit, control, watch } = useForm<AddMediaProfileInformationForm, any>({
    defaultValues: {
      jobTitle: mediaOutletProfileInfo.jobTitle?.name,
      jobDescription: mediaOutletProfileInfo.jobDescription,
      resort: mediaOutletProfileInfo.resort,
      address: mediaOutletProfileInfo.address,
      city: mediaOutletProfileInfo.city,
      postalCode: mediaOutletProfileInfo.postalCode,
      country: mediaOutletProfileInfo.country,
      emailAddress: mediaOutletProfileInfo.emailAddress,
      phoneNumber: mediaOutletProfileInfo.phoneNumber,
      mobilePhoneNumber: mediaOutletProfileInfo.mobilePhoneNumber
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const submitForm = useMemo(
    () =>
      handleSubmit(
        (data) => {
          const newMediaOutletProfileInfo = createNewMediaOutlet(data, mediaOutletJobTitles);
          dispatch(setCollaborationProfileInfo(newMediaOutletProfileInfo));
        },
        (error) => {
          Sentry.setExtra('Path', AddMediaOutletProfileInfo.name);
          Sentry.captureException(error);
        }
      ),
    [dispatch, handleSubmit, mediaOutletJobTitles]
  );

  useEffect(() => {
    return () => {
      submitForm();
    };
  }, [submitForm]);

  const handleFieldsValidation = () => {
    const watchEmailAddress = watch('emailAddress');
    const watchPostalCode = watch('postalCode');
    const watchPhoneNumber = watch('phoneNumber');
    const watchMobilePhoneNumber = watch('mobilePhoneNumber');

    return (
      isEmailAddressValidOrEmpty(watchEmailAddress) &&
      isNumberValidOrEmpty(watchPostalCode) &&
      isPhoneNumberValidOrEmpty(watchMobilePhoneNumber) &&
      isPhoneNumberValidOrEmpty(watchPhoneNumber)
    );
  };

  const areFieldsValid = handleFieldsValidation();

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', MediumType.MediaOutlet);

    dispatch(addJobTitleRequested(jobTitle));
  };

  useEffect(() => {
    dispatch(setIsAddMediaOutletWizardProfileInfoStepValid(areFieldsValid));
  }, [dispatch, areFieldsValid]);

  return (
    <div className="wizard-panel-form-wrap">
      <WizardIntroInfo
        title={formatMessage({ id: 'wizard-profile-information-step.title' })}
        description={formatMessage({ id: 'wizard-profile-information-step.description' })}
        info={
          <span className="wizard-panel-description text-bold">
            {selectedContact.firstName} {selectedContact.lastName}
          </span>
        }
      />
      <div className="wizard-panel-form">
        <div className="custom-dropdown">
          <ControlledCreateDropdown
            label={
              <span className="dropdown-label">
                <FormattedMessage id={'add-menu-create-contact.additional-job-title-label'} />
              </span>
            }
            name="jobTitle"
            items={jobTitles}
            control={control}
            placeholder={formatMessage({ id: 'add-menu-create-contact.additional-job-title-placeholder' })}
            defaultValue={mediaOutletProfileInfo.jobTitle?.name}
            createNewEntityMessage={formatMessage({ id: 'aria-labels.create-job-title' })}
            onCreateNewEntityClick={handleCreateNewJobTitle}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'contact-data-labels.job-description' })} control={control} name="jobDescription" />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'contact-data-labels.resort' })} control={control} name="resort" />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'contact-data-labels.address' })} control={control} name="address" />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            name="emailAddress"
            label={formatMessage({ id: 'contact-data-labels.email-address' })}
            control={control}
            rules={{
              pattern: {
                value: regEmail,
                message: formatMessage({ id: 'error-messages.not-valid-email-address' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            name="phoneNumber"
            label={formatMessage({ id: 'contact-data-labels.phone-number' })}
            control={control}
            rules={{
              pattern: {
                value: regPhoneNumber,
                message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            label={formatMessage({ id: 'contact-data-labels.mobile-phone-number' })}
            control={control}
            name="mobilePhoneNumber"
            rules={{
              pattern: {
                value: regPhoneNumber,
                message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput
            label={formatMessage({ id: 'contact-data-labels.postal-code' })}
            control={control}
            name="postalCode"
            rules={{
              pattern: {
                value: regNumbers,
                message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
              }
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput label={formatMessage({ id: 'contact-data-labels.city' })} control={control} name="city" />
        </div>
        <div className="wizard-panel-dropdown">
          <ControlledDropdown
            label={
              <span className="dropdown-label">
                <FormattedMessage id={'contact-data-labels.country'} />
              </span>
            }
            name="country"
            items={getNames()}
            control={control}
            placeholder={formatMessage({ id: 'dropdown-placeholders.country' })}
            defaultValue={mediaOutletProfileInfo.country}
          />
        </div>
      </div>
    </div>
  );
};
