import {
  CommandBarButton,
  DetailsListLayoutMode,
  DetailsRow,
  Icon,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  SelectionMode,
  ShimmeredDetailsList,
  Selection
} from '@fluentui/react';
import { Button, Checkbox } from '@fluentui/react-northstar';
import { EmptyMessage, useAppDispatch, useAppSelector } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useIntl } from 'app/i18n';
import { Collaboration, Contact } from 'app/pages/my-audience/contacts';
import { ListContact } from 'app/pages/my-audience/lists';
import {
  AddContactsFromListTableToolbar,
  removeContactsFromAddToList,
  selectContactsToAddToList,
  selectWizardContactsIdsToEdit,
  setContactsToAddToList,
  setWizardContactsIdsToEdit,
  setWizardSelectedContacts
} from 'app/pages/my-audience/lists-profile';
import { useMemo, useState } from 'react';

export const AddContactFromListTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const listContacts = useAppSelector<ListContact[]>(selectContactsToAddToList);
  const contactsIdsToEdit = useAppSelector<string[]>(selectWizardContactsIdsToEdit);

  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedContacts: Contact[] = selection.getSelection().map((selection) => selection['contact']);
          dispatch(setWizardSelectedContacts(selectedContacts));
        },
        selectionMode: SelectionMode.multiple
      }),
    []
  );

  const header = [
    {
      key: 'add-contact-key-name',
      name: formatMessage({ id: 'list-wizards.add-contact-table-name' }),
      fieldName: 'name',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-title',
      name: formatMessage({ id: 'list-wizards.add-contact-table-job' }),
      fieldName: 'jobTitle',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-email',
      name: formatMessage({ id: 'list-wizards.add-contact-table-email' }),
      fieldName: 'email',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-contact-data',
      name: formatMessage({ id: 'list-wizards.add-contact-table-data' }),
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    { key: 'add-contact-key-hover-action', name: '', fieldName: 'hoverActions', isResizable: false, minWidth: 60, maxWidth: 60 }
  ];

  const handleClickOnEditButton = (contactId: string) => {
    dispatch(setWizardContactsIdsToEdit([contactId]));
  };

  const removeContactsFromTable = (contactIds: string[]) => {
    dispatch(removeContactsFromAddToList(contactIds));
    dispatch(setWizardSelectedContacts([]));
    selection.setAllSelected(false);
  };

  const handleClickOnRemoveButton = (contact: Contact) => {
    removeContactsFromTable([contact.id]);
  };

  const handleEditContactData = (contactIndex: number, selectedContactDataMediumId: string, contactId: string) => {
    const contactToUpdate = listContacts[contactIndex];
    contactToUpdate.mediumId = selectedContactDataMediumId;

    const updatedContacts = [...listContacts.slice(0, contactIndex), contactToUpdate, ...listContacts.slice(contactIndex + 1, listContacts.length)];

    dispatch(setContactsToAddToList(updatedContacts));
    dispatch(setWizardContactsIdsToEdit(contactsIdsToEdit.filter((id) => id !== contactId)));
  };

  const rows = [
    ...listContacts.map((listContact, index) => {
      const selectedContactData =
        listContact.mediumId === '' ? listContact.contact.ownContactData : listContact.contact.collaborations.find((c) => c.medium.id === listContact.mediumId);

      const contactDataDropdown = [
        ...listContact.contact.collaborations.map((collaboration) => ({
          key: collaboration.id,
          text: collaboration.medium.name,
          onClick: () => handleEditContactData(index, collaboration.medium.id, listContact.contact.id)
        })),
        {
          key: listContact.contact.id,
          text: formatMessage({ id: 'list-wizards.add-contact-own-btn' }),
          onClick: () => handleEditContactData(index, '', listContact.contact.id)
        }
      ];

      return {
        key: `${index}`,
        name: `${listContact.contact.firstName} ${listContact.contact.lastName}`,
        jobTitle: (selectedContactData as Collaboration)?.jobTitle?.name ?? '',
        email: selectedContactData?.emailAddress,
        contactData: !contactsIdsToEdit.includes(listContact.contact.id) ? (
          listContact.mediumId !== '' ? (
            (selectedContactData as Collaboration)?.medium?.name
          ) : (
            formatMessage({ id: 'list-wizards.add-contact-own-btn' })
          )
        ) : (
          <div key={`contact-data-dropdown-${index}`}>
            <CommandBarButton text={formatMessage({ id: 'dropdown-placeholders.collaboration' })} menuProps={{ items: contactDataDropdown }} />
          </div>
        ),
        hoverActions: (
          <>
            {hoverIndex === index && (
              <div className="table-actions">
                <Button text icon={<Icon iconName="Edit" />} onClick={() => handleClickOnEditButton(listContact.contact.id)} />
                <Button text icon={<Icon iconName="Clear" />} onClick={() => handleClickOnRemoveButton(listContact.contact)} />
              </div>
            )}
          </>
        ),
        contact: listContact.contact
      };
    })
  ];

  return (
    <div className="table-wrap table-wrap--wizard">
      <AddContactsFromListTableToolbar removeContactsFromTable={removeContactsFromTable} />
      {listContacts.length > 0 ? (
        <div className="table-wrap table--list">
          <MarqueeSelection selection={selection} className="table" isDraggingConstrainedToRoot={true}>
            <ShimmeredDetailsList
              items={rows}
              enableShimmer={!rows}
              columns={header}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              checkboxVisibility={1}
              onRenderRow={(rowProps: IDetailsRowProps) => (
                <div
                  onMouseEnter={() => {
                    setHoverIndex(rowProps.itemIndex);
                  }}
                >
                  <DetailsRow
                    key={rowProps.item.id}
                    {...rowProps}
                    onRenderCheck={() => (
                      <div className="row-header-wrap">
                        {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
                          <Checkbox checked={selection.getSelection().includes(rowProps.item)} />
                        ) : (
                          <ProfilePicture name={rowProps.item.name} imageUrl={rowProps.item.profilePictureUrl} size="small" />
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            />
          </MarqueeSelection>
        </div>
      ) : (
        <EmptyMessage
          srcValue="/wizard-empty-info.svg"
          textValueOptional={formatMessage({ id: 'list-wizards.add-contact-table-no-results-title' })}
          textValue={formatMessage({ id: 'list-wizards.add-contact-table-no-results-subtitle' })}
        />
      )}
    </div>
  );
};
