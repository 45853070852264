import { SendingStatus } from 'app/pages/my-activities/sendings';
import { IntlMessage, NestedKeys } from 'app/i18n';

type SendingsFilterItem = {
  fieldName: NestedKeys<IntlMessage>;
  value: string;
};

export const SENDING_STATUS_FILTER_ITEMS: SendingsFilterItem[] = [
  {
    fieldName: 'emailings-table.filters.draft',
    value: SendingStatus.Draft.toString()
  },
  {
    fieldName: 'emailings-table.filters.waiting-for-distribution',
    value: SendingStatus.WaitingForDistribution.toString()
  },
  {
    fieldName: 'emailings-table.filters.ongoing-distribution',
    value: SendingStatus.OngoingDistribution.toString()
  },
  {
    fieldName: 'emailings-table.filters.incomplete-distribution',
    value: SendingStatus.IncompleteDistribution.toString()
  },
  {
    fieldName: 'emailings-table.filters.successful-distribution',
    value: SendingStatus.SuccessfulDistribution.toString()
  }
];

export const getSendingStatusLocalizationFromStatusValue = (value: string): NestedKeys<IntlMessage> => {
  switch (value) {
    case SendingStatus.Draft.toString():
      return 'emailings-table.filters.draft';
    case SendingStatus.WaitingForDistribution.toString():
      return 'emailings-table.filters.waiting-for-distribution';
    case SendingStatus.OngoingDistribution.toString():
      return 'emailings-table.filters.ongoing-distribution';
    case SendingStatus.IncompleteDistribution.toString():
      return 'emailings-table.filters.incomplete-distribution';
    case SendingStatus.SuccessfulDistribution.toString():
      return 'emailings-table.filters.successful-distribution';
  }
};
