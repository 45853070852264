import { useAppDispatch, useAppSelector } from 'app/common';
import {
  AddGeneralContactInfo,
  CreateContactWizardStep,
  selectIsCreateContactWizardOpened,
  selectCreateContactCurrentStep,
  closeCreateContactWizard,
  FinishCreateContact
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';

export const CreateContactFromMediaOutletWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const isCreateContactWizardOpened = useAppSelector<boolean>(selectIsCreateContactWizardOpened);
  const currentStep = useAppSelector<CreateContactWizardStep>(selectCreateContactCurrentStep);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-menu-create-mo.contact-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateContactWizardStep.GeneralInformation && <AddGeneralContactInfo />}
        {currentStep === CreateContactWizardStep.Finish && <FinishCreateContact />}
      </div>
    </WizardWrap>
  );
};
