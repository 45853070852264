import { Button } from '@fluentui/react-northstar';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from 'app/common';
import { closeMissingFieldErrorDialog, selectIsMissingFieldErrorDialogVisible, selectErrorDialogMessage } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';

export const ErrorDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isDialogOpened = useAppSelector<boolean>(selectIsMissingFieldErrorDialogVisible);
  const errorDialogMessage = useAppSelector<string>(selectErrorDialogMessage);

  const dialogContentProps = {
    type: DialogType.normal,
    title: errorDialogMessage
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const onCloseDialog = () => {
    dispatch(closeMissingFieldErrorDialog());
  };

  return (
    <>
      <Dialog hidden={!isDialogOpened} onDismiss={onCloseDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
        <div className="profile-photo-dialog">
          <DialogFooter>
            <Button primary content={formatMessage({ id: 'buttons.close' })} onClick={onCloseDialog} />
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};
