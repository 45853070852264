import { useAppSelector, Banner } from 'app/common';
import { selectIsSearchAnimationActive } from 'app/pages/my-audience/companies';
import { CompaniesSavedSearches } from 'app/pages/my-audience/companies-saved-searches';
import { CompaniesSearch } from 'app/pages/my-audience/company-search-results';

export const Companies = () => {
  const isSearchAnimationActive = useAppSelector<boolean>(selectIsSearchAnimationActive);

  return (
    <div className={`container ${isSearchAnimationActive ? 'animation--active' : ''}`}>
      <Banner imageSrc={'/company-banner-image.svg'} altText="Search companies" modifier="company" />
      <div className="main-content">
        <div className="search-input-container">
          <CompaniesSearch />
        </div>
        <div className="saved-searches">
          <CompaniesSavedSearches />
        </div>
      </div>
    </div>
  );
};
