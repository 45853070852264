import { Loader, Tooltip } from '@fluentui/react-northstar';
import { printData, useAppDispatch, useAppSelector } from 'app/common';
import {
  selectIsLoadingSendingAudience,
  selectSendingAudience,
  selectSendingToAddSetup,
  sendingAudienceRequested,
  SendingSetup,
  setCurrentStep
} from 'app/pages/my-activities/sending-wizard';
import { FormattedMessage, useIntl } from 'app/i18n';
import { Icon } from '@fluentui/react';
import { useEffect } from 'react';
import { SendingAudience } from 'app/common/graphql/generated/graphql-gateway';

export const PreviewTop = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const selectedSendingSetup = useAppSelector<SendingSetup>(selectSendingToAddSetup);
  const audience = useAppSelector<SendingAudience>(selectSendingAudience);
  const isLoadingSendingAudience = useAppSelector<boolean>(selectIsLoadingSendingAudience);

  useEffect(() => {
    dispatch(sendingAudienceRequested());
  }, [dispatch]);

  const recipientNames = !!audience.numberOfRecipients
    ? audience.namesOfFirstPageOfContacts.map((recipient) => (
        <div className="wizard-panel-preview-content-tooltip" key={recipient}>
          {recipient}
        </div>
      ))
    : null;

  const goToStep = (step: number) => {
    dispatch(setCurrentStep(step));
  };

  const respondAddress = selectedSendingSetup.respondToEmailAddress ? (
    <span className="wizard-panel-preview-content-text link" onClick={() => goToStep(1)}>
      {printData(selectedSendingSetup.respondToEmailAddress)}
    </span>
  ) : (
    <span className="wizard-panel-preview-content-text error-text">
      <Icon iconName="InfoSolid" />
      <FormattedMessage id="emailing-wizard-preview-and-send-step.no-respond-address" />
    </span>
  );

  const subject = selectedSendingSetup.subject ? (
    <span className="wizard-panel-preview-content-text">{printData(selectedSendingSetup.subject)}</span>
  ) : (
    <span className="wizard-panel-preview-content-text error-text">
      <Icon iconName="InfoSolid" />
      <FormattedMessage id="emailing-wizard-preview-and-send-step.no-subject" />
    </span>
  );

  const recipientsStats = (
    <>
      <Tooltip
        subtle={false}
        pointing
        content={recipientNames}
        position="below"
        trigger={
          <span className="wizard-panel-preview-content-text link tooltipText" onClick={() => goToStep(2)}>
            <FormattedMessage id={'emailing-wizard-preview-and-send-step.recipients-counter'} values={{ counter: audience?.numberOfRecipients ?? 0 }} />
          </span>
        }
      />
      {!!audience?.numberOfRecipientsWithoutEmailAddress && (
        <Tooltip
          subtle={false}
          pointing
          content={formatMessage(
            { id: 'emailing-wizard-preview-and-send-step.recipients-without-email-address' },
            { counter: audience.numberOfRecipientsWithoutEmailAddress }
          )}
          position="below"
          trigger={<Icon iconName="Warning" className="tooltipText orange-text" />}
        />
      )}
    </>
  );

  const recipientsLink = (
    <div className="wizard-panel-preview-content">{isLoadingSendingAudience ? <Loader size="small" labelPosition="below" /> : recipientsStats}</div>
  );

  return (
    <div className="wizard-panel-preview-wrap">
      <div className="wizard-panel-preview-intro">
        <div className="wizard-panel-preview-row">
          <div className="wizard-panel-preview-col">
            <span className="wizard-panel-preview-label">
              <FormattedMessage id={'emailing-wizard.labels.from'} />:
            </span>
            <span className="wizard-panel-preview-content-text">{printData(selectedSendingSetup.sender?.emailAddress)}</span>
          </div>
          <div className="wizard-panel-preview-col">
            <span className="wizard-panel-preview-label">
              <FormattedMessage id={'emailing-wizard.labels.respond-address'} />:
            </span>
            {respondAddress}
          </div>
          <div className="wizard-panel-preview-col">
            <span className="wizard-panel-preview-label">
              <FormattedMessage id={'emailing-wizard.labels.to'} />:
            </span>
            {recipientsLink}
          </div>
          <div className="wizard-panel-preview-col">
            <span className="wizard-panel-preview-label">
              <FormattedMessage id={'emailing-wizard.labels.subject'} />:
            </span>
            {subject}
          </div>
        </div>
        <div className="wizard-panel-preview-row">
          <div className="wizard-panel-preview-col">
            <span className="wizard-panel-preview-label">
              <FormattedMessage id={'emailing-wizard.labels.title'} />:
            </span>
            <span className="wizard-panel-preview-content-text">{printData(selectedSendingSetup.title)}</span>
          </div>
          <div className="wizard-panel-preview-col">
            <span className="wizard-panel-preview-label">
              <FormattedMessage id={'emailing-wizard.labels.blacklist'} />:
            </span>
            <span className="wizard-panel-preview-content-text"> {printData(selectedSendingSetup.blacklist?.name)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
