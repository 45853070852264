import { PillData, UndoablePills, Tag, useAppDispatch, useAppSelector } from 'app/common';
import { addedTagToList, removedTagsFromLists, selectListTagsHeaderValue, selectSelectedList } from 'app/pages/my-audience/lists-profile';
import {
  addNewTagRequested,
  addTagToListsRequested,
  deleteTagsRequested,
  filterTags,
  removeTagFromListsRequested,
  selectFilteredTagsInPanel,
  selectAllTags,
  addedTagToListsSearchResults,
  List,
  removedTagsFromListsSearchResults
} from 'app/pages/my-audience/lists';
import { useIntl } from 'app/i18n';

interface ListTagsProps {
  disabled?: boolean;
}

export const ListTags = ({ disabled = false }: ListTagsProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const selectedList = useAppSelector<List>(selectSelectedList);
  const selectedListsIds = [selectedList.id];
  const selectedTags = useAppSelector<Tag[]>(selectListTagsHeaderValue);
  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);

  const onAddNewTag = (text: string) => dispatch(addNewTagRequested({ selectedListsIds, text }));

  const addTagToListsInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addedTagToList(tag));
    dispatch(addedTagToListsSearchResults({ selectedListsIds, tag }));
  };

  const removeTagFromListsInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removedTagsFromLists([id]));
    dispatch(removedTagsFromListsSearchResults({ selectedListsIds, tags: [tag] }));
  };

  const onAddTagToLists = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addTagToListsRequested({ selectedListsIds, tag }));
  };

  const onDeleteTags = (tagIds: string[]) => {
    const tags = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tags));
  };

  const onFilterTags = (text: string) => {
    dispatch(filterTags(text));
  };

  const onRemoveTagFromLists = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removeTagFromListsRequested({ selectedListsIds, tag }));
  };

  return (
    <UndoablePills
      entityName={formatMessage({ id: 'labels.tag' })}
      selectedValues={selectedTags?.map((t) => new PillData(t.id, t.name, true))}
      dropdownValues={filteredTags?.map((t) => new PillData(t.id, t.name, true))}
      onAddValue={onAddNewTag}
      onSelectValue={onAddTagToLists}
      onDeselectValue={onRemoveTagFromLists}
      onDeleteValues={onDeleteTags}
      filterValues={onFilterTags}
      enabledDelete={true}
      onSelectValueInStore={addTagToListsInStore}
      onUndoSelectValueInStore={removeTagFromListsInStore}
      onDeselectValueInStore={removeTagFromListsInStore}
      onUndoDeselectValueInStore={addTagToListsInStore}
      disabled={disabled}
    />
  );
};
