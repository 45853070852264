import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Checkbox, Icon } from '@fluentui/react';
import { Button, MenuButton } from '@fluentui/react-northstar';
import {
  addJobTitleRequested,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regEmail,
  regNumbers,
  selectAllJobTitles,
  useAppDispatch,
  useAppSelector,
  isPhoneNumberValidOrEmpty
} from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { ControlledDropdown, ControlledInput, ControlledCreateDropdown } from 'app/pages/my-audience/common';
import { Collaboration, CollaborationDetails, JobTitle } from 'app/pages/my-audience/contacts';
import { setCollaboration } from 'app/pages/my-audience/wizard-create-contact';
import { getNames } from 'country-list';
import 'sass/shared/custom-accordion/CustomAccordion.scss';

interface AdditionalInformationFormProps {
  collaborationDetails: CollaborationDetails;
  removeCollaboration: (mediumId: string) => void;
  updateCollaboration: (areFieldsValid: boolean, collaboration: Collaboration, useDifferentAddress: boolean) => void;
}

export type AddAdditionalInformationForm = {
  jobTitle: string;
  jobDescription: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
  emailAddress: string;
  country: string;
  city: string;
  postalCode: string;
  address: string;
};

export const AdditionalInformationForm = ({ collaborationDetails, removeCollaboration, updateCollaboration }: AdditionalInformationFormProps) => {
  const dispatch = useAppDispatch();
  const collaboration = collaborationDetails.collaboration;
  const allJobTitles = useAppSelector<JobTitle[]>(selectAllJobTitles);
  const jobTitles = allJobTitles.filter((job) => job.mediumType === collaboration.medium.type);
  const { formatMessage } = useIntl();
  const [isUpdatingCollaboration, setIsUpdatingCollaboration] = useState(false);
  const [isUsingDifferentAddress, setIsUsingDifferentAddress] = useState(collaborationDetails.useDifferentAddress);

  const actionItem = [
    {
      key: 'remove-collaboration',
      onClick: () => removeCollaboration(collaborationDetails.collaboration.medium.id),
      content: (
        <span className="custom-accordion-head-actions-item">
          <Icon iconName="Delete" />
          <span className="custom-accordion-head-actions-item-text">
            <FormattedMessage id={'buttons.remove'} />
          </span>
        </span>
      )
    }
  ];

  const { control, watch } = useForm<AddAdditionalInformationForm, any>({
    defaultValues: {
      jobTitle: collaboration?.jobTitle?.name,
      jobDescription: collaboration?.jobDescription,
      city: collaboration?.city,
      country: collaboration?.country,
      emailAddress: collaboration?.emailAddress,
      landlinePhoneNumber: collaboration?.landlinePhoneNumber?.value,
      mobilePhoneNumber: collaboration?.mobilePhoneNumber?.value,
      postalCode: collaboration?.postalCode,
      address: collaboration?.address
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  watch((data) => handleFieldsUpdate(data));

  const handleFieldsUpdate = (data) => {
    const newCollaboration: Collaboration = setCollaboration(collaboration, data, jobTitles);

    updateCollaboration(handleFieldsValidation(), newCollaboration, isUsingDifferentAddress);
  };

  const handleFieldsValidation = () =>
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('landlinePhoneNumber')) &&
    isPhoneNumberValidOrEmpty(watch('mobilePhoneNumber'));

  const toggleUpdateContactInfoForm = () => {
    setIsUpdatingCollaboration((state) => !state);
  };

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', collaboration.medium.type);

    dispatch(addJobTitleRequested(jobTitle));
  };

  return (
    <>
      <div className="custom-accordion" key="sending-overview-title">
        <div className="custom-accordion-head" onClick={toggleUpdateContactInfoForm}>
          <div className="custom-accordion-head-content">
            <span className="custom-accordion-head-icon">
              <Icon iconName="ChevronDown" className={isUpdatingCollaboration && ' expanded'} />
            </span>
            <span className="custom-accordion-head-icon-title">{collaboration?.medium.name}</span>
          </div>
          <div className="custom-accordion-head-actions">
            <MenuButton
              trigger={<Button onClick={(e) => e.stopPropagation()} text iconOnly icon={<Icon iconName="MoreVertical" />} />}
              menu={actionItem}
              on="click"
            />
          </div>
        </div>
      </div>
      {isUpdatingCollaboration && (
        <>
          <div className="wizard-panel-form">
            <div className="custom-dropdown">
              <ControlledCreateDropdown
                label={
                  <span className="dropdown-label">
                    <FormattedMessage id={'add-menu-create-contact.additional-job-title-label'} />
                  </span>
                }
                name="jobTitle"
                items={jobTitles.map((job) => job.name)}
                control={control}
                placeholder={formatMessage({ id: 'add-menu-create-contact.additional-job-title-placeholder' })}
                defaultValue={collaboration?.jobTitle?.name}
                createNewEntityMessage={formatMessage({ id: 'aria-labels.create-job-title' })}
                onCreateNewEntityClick={handleCreateNewJobTitle}
              />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput label={formatMessage({ id: 'add-menu-create-contact.additional-job-desc-label' })} control={control} name="jobDescription" />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput
                label={formatMessage({ id: 'add-menu-create-contact.additional-phone-label' })}
                control={control}
                rules={{
                  pattern: {
                    value: regPhoneNumber,
                    message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                  }
                }}
                name="landlinePhoneNumber"
              />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput
                label={formatMessage({ id: 'add-menu-create-contact.additional-mobile-phone-label' })}
                control={control}
                rules={{
                  pattern: {
                    value: regPhoneNumber,
                    message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                  }
                }}
                name="mobilePhoneNumber"
              />
            </div>
            <div className="wizard-panel-input">
              <ControlledInput
                name="emailAddress"
                label={formatMessage({ id: 'add-menu-create-contact.additional-email-label' })}
                control={control}
                rules={{
                  pattern: {
                    value: regEmail,
                    message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                  }
                }}
              />
            </div>
            <div className="wizard-panel-checkbox">
              <Checkbox
                checked={isUsingDifferentAddress}
                onChange={() => setIsUsingDifferentAddress((prevState) => !prevState)}
                label={formatMessage(
                  { id: 'add-menu-create-contact.additional-checkout-msg' },
                  {
                    entity:
                      collaboration?.medium.type === 1
                        ? formatMessage({ id: 'add-menu-create-contact.additional-checkout-media' })
                        : formatMessage({ id: 'add-menu-create-contact.additional-checkout-company' })
                  }
                )}
              />
            </div>
          </div>
          {isUsingDifferentAddress && (
            <div className="wizard-panel-form">
              <div className="wizard-panel-dropdown">
                <ControlledDropdown
                  label={
                    <span className="dropdown-label">
                      <FormattedMessage id={'add-menu-create-contact.additional-checkout-country'} />
                    </span>
                  }
                  name="country"
                  items={getNames()}
                  control={control}
                  placeholder={formatMessage({ id: 'add-menu-create-contact.additional-checkout-country-placeholder' })}
                  defaultValue={collaboration?.country}
                />
              </div>
              <div className="wizard-panel-input">
                <ControlledInput label={formatMessage({ id: 'add-menu-create-contact.additional-checkout-city' })} control={control} name="city" />
              </div>
              <div className="wizard-panel-input">
                <ControlledInput
                  label={formatMessage({ id: 'add-menu-create-contact.additional-checkout-zip' })}
                  control={control}
                  rules={{
                    pattern: {
                      value: regNumbers,
                      message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                    }
                  }}
                  name="postalCode"
                />
              </div>
              <div className="wizard-panel-input">
                <ControlledInput label={formatMessage({ id: 'add-menu-create-contact.additional-finish-address' })} control={control} name="address" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
