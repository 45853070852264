import { ShimmeredDetailsList } from '@fluentui/react';
import { TablePaging, printData, printDateLocalized, useAppDispatch, useAppSelector } from 'app/common';
import { SendingLog } from 'app/common/graphql/generated/graphql-gateway';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import {
  selectIsLoadingSendingLogs,
  selectSelectedSending,
  selectSendingLogsPageNumber,
  selectSendingLogsPageSize,
  sendingLogsRequested,
  setSendingsLogPageNumber,
  setSendingsLogPageSize
} from 'app/pages/my-activities/sending-wizard';
import { DateTime } from 'luxon';
import { useEffect } from 'react';

export const LogTab = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const sending = useAppSelector(selectSelectedSending);
  const isLoadingLogs = useAppSelector(selectIsLoadingSendingLogs);
  const pageNumber = useAppSelector(selectSendingLogsPageNumber);
  const pageSize = useAppSelector(selectSendingLogsPageSize);
  const locale = useAppSelector<string>(selectLocale);

  const headers = [
    {
      key: 'userName',
      name: formatMessage({ id: 'sending-profile.log.header-user-name' }),
      fieldName: 'userName',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200,
      className: 'purple'
    },
    {
      key: 'creationDate',
      name: formatMessage({ id: 'sending-profile.log.header-creation-date' }),
      fieldName: 'creationDate',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200
    },
    {
      key: 'description',
      name: formatMessage({ id: 'sending-profile.log.header-description' }),
      fieldName: 'description',
      isResizable: true,
      minWidth: 100,
      maxWidth: 200
    }
  ];

  useEffect(() => {
    dispatch(sendingLogsRequested(sending));
  }, [dispatch, pageNumber, pageSize, sending]);

  const rows =
    sending.logs?.map((log: SendingLog) => ({
      description: printData(log.description),
      userName: log.userName,
      subject: log.subject,
      creationDate: printDateLocalized(new Date(log.creationDate), DateTime.DATETIME_MED, locale)
    })) ?? [];

  const handlePageClick = (pageNumber: number) => {
    dispatch(setSendingsLogPageNumber(pageNumber));
  };

  const handlePageSizeClick = (pageSize: number) => {
    dispatch(setSendingsLogPageSize(pageSize));
  };

  return (
    <div className="log-tab">
      <>
        <ShimmeredDetailsList items={rows} columns={headers} selectionMode={0} enableShimmer={isLoadingLogs} />
        <TablePaging
          totalCount={sending.totalNumberOfLogs}
          pageSize={pageSize}
          onPageClick={handlePageClick}
          onUpdatePageSize={handlePageSizeClick}
          isLoadingData={isLoadingLogs}
          page={pageNumber}
        />
      </>
    </div>
  );
};
