import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { Accordion, Button, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { Panel } from '@fluentui/react';
import {
  selectIsOnMobile,
  FilterItem,
  useAppDispatch,
  useAppSelector,
  useControlledFilterDropDown,
  ControlledFilterDropDown,
  FilterItemType,
  useResetFilterQueriesOnPanelClose
} from 'app/common';
import {
  addContactsFilter,
  removeContactsFilter,
  selectFilterItems,
  clearAllFilters,
  firstPageOfContactsRequested,
  selectIsFilterPanelOpen,
  closeFiltersPanel,
  startLoadingContactNameSuggestions,
  contactNameFilterSuggestionsRequested,
  selectIsLoadingContactNameSuggestions,
  selectContactNameSuggestions,
  roleFilterSuggestionsRequested,
  startLoadingRoleSuggestions,
  selectIsLoadingRoleSuggestions,
  selectRoleSuggestions,
  mediumNameFilterSuggestionsRequested,
  startLoadingMediumNameSuggestions,
  selectMediumNameSuggestions,
  selectIsLoadingMediumNameSuggestions,
  startLoadingResortSuggestions,
  selectResortSuggestions,
  selectIsLoadingResortSuggestions,
  resortFilterSuggestionsRequested,
  languageFilterSuggestionsRequested,
  startLoadingLanguageSuggestions,
  selectLanguageSuggestions,
  selectIsLoadingLanguageSuggestions,
  tagNameFilterSuggestionsRequested,
  startLoadingTagNameSuggestions,
  selectTagNameSuggestions,
  selectIsLoadingTagNameSuggestions,
  startLoadingCountrySuggestions,
  countryFilterSuggestionsRequested,
  selectCountrySuggestions,
  selectIsLoadingCountrySuggestions
} from 'app/pages/my-audience/contacts';
import { CONTACT_FILTER } from 'app/pages/my-audience/contact-filter-search/filter-fields';
import { useIntl } from 'app/i18n';
import 'app/pages/my-audience/common/components/Filter/filter-panel.scss';

export const ContactFiltersPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const ref = useRef(null);

  const isFilterPanelOpen = useAppSelector(selectIsFilterPanelOpen);
  const filterItems = useAppSelector(selectFilterItems);
  const isOnMobile = useAppSelector(selectIsOnMobile);

  const contactNameControl = useControlledFilterDropDown(contactNameFilterSuggestionsRequested, startLoadingContactNameSuggestions);
  const roleControl = useControlledFilterDropDown(roleFilterSuggestionsRequested, startLoadingRoleSuggestions);
  const mediumNameControl = useControlledFilterDropDown(mediumNameFilterSuggestionsRequested, startLoadingMediumNameSuggestions);
  const resortControl = useControlledFilterDropDown(resortFilterSuggestionsRequested, startLoadingResortSuggestions);
  const languageControl = useControlledFilterDropDown(languageFilterSuggestionsRequested, startLoadingLanguageSuggestions);
  const tagNameControl = useControlledFilterDropDown(tagNameFilterSuggestionsRequested, startLoadingTagNameSuggestions);
  const countryControl = useControlledFilterDropDown(countryFilterSuggestionsRequested, startLoadingCountrySuggestions);

  const { resetQueries } = useResetFilterQueriesOnPanelClose(
    [contactNameControl, roleControl, mediumNameControl, resortControl, languageControl, tagNameControl, countryControl],
    isFilterPanelOpen
  );

  const handleDismissFilterPanel = () => {
    dispatch(closeFiltersPanel());
    navigate(-1);
  };

  const handleFilterAdd = ({ fieldName, value }: FilterItemType) => {
    dispatch(addContactsFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfContactsRequested());
  };

  const handleFilterRemoval = ({ fieldName, value }: FilterItemType) => {
    dispatch(removeContactsFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfContactsRequested());
  };

  const handleClearAll = () => {
    dispatch(clearAllFilters());
    dispatch(firstPageOfContactsRequested());

    resetQueries();
  };

  useOnClickOutside(ref, handleDismissFilterPanel);

  const filters = [
    {
      key: 'item-contact-names',
      name: CONTACT_FILTER.NAME,
      displayName: formatMessage({ id: 'filters.names' }),
      placeholder: formatMessage({ id: 'filters.filter-by-name' }),
      suggestionsSelector: selectContactNameSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingContactNameSuggestions,
      control: contactNameControl
    },
    {
      key: 'item-contacts-roles',
      name: CONTACT_FILTER.ROLE,
      displayName: formatMessage({ id: 'filters.roles' }),
      placeholder: formatMessage({ id: 'filters.filter-by-role' }),
      suggestionsSelector: selectRoleSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingRoleSuggestions,
      control: roleControl
    },
    {
      key: 'item-contacts-medium',
      name: CONTACT_FILTER.MEDIUM,
      displayName: formatMessage({ id: 'filters.mediums' }),
      placeholder: formatMessage({ id: 'filters.filter-by-medium' }),
      suggestionsSelector: selectMediumNameSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingMediumNameSuggestions,
      control: mediumNameControl
    },
    {
      key: 'item-contacts-resorts',
      name: CONTACT_FILTER.RESORTS,
      displayName: formatMessage({ id: 'filters.resorts' }),
      placeholder: formatMessage({ id: 'filters.filter-by-resort' }),
      suggestionsSelector: selectResortSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingResortSuggestions,
      control: resortControl
    },
    {
      key: 'item-contacts-languages',
      name: CONTACT_FILTER.LANGUAGE,
      displayName: formatMessage({ id: 'filters.languages' }),
      placeholder: formatMessage({ id: 'filters.filter-by-language' }),
      suggestionsSelector: selectLanguageSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingLanguageSuggestions,
      control: languageControl
    },
    {
      key: 'item-contacts-tags',
      name: CONTACT_FILTER.TAG,
      displayName: formatMessage({ id: 'filters.tags' }),
      placeholder: formatMessage({ id: 'filters.filter-by-tag' }),
      suggestionsSelector: selectTagNameSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingTagNameSuggestions,
      control: tagNameControl
    },
    {
      key: 'item-contacts-countries',
      name: CONTACT_FILTER.COUNTRY,
      displayName: formatMessage({ id: 'filters.country' }),
      placeholder: formatMessage({ id: 'filters.filter-by-country' }),
      suggestionsSelector: selectCountrySuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingCountrySuggestions,
      control: countryControl
    }
  ];

  const panels = filters.map(({ key, name, displayName, placeholder, control, suggestionsSelector, isLoadingSuggestionsSelector }) => ({
    title: (
      <div className="accordion-head-box" key={`${key}-title`}>
        <span className="accordion-heading">{displayName}</span>
        <ChevronDownMediumIcon />
      </div>
    ),
    content: {
      key: `${key}-content`,
      content: (
        <ControlledFilterDropDown
          name={name}
          placeholder={placeholder}
          onFilterAdd={handleFilterAdd}
          onFilterRemoval={handleFilterRemoval}
          suggestionsSelector={suggestionsSelector}
          isLoadingSuggestionsSelector={isLoadingSuggestionsSelector}
          items={filterItems.filter((filterItem) => filterItem.fieldName === name)}
          {...control}
        />
      )
    },
    className: 'filter-accordion-item'
  }));

  return (
    <div ref={ref}>
      <Panel
        isBlocking={false}
        customWidth="340px"
        className="filter-panel"
        headerText={formatMessage({ id: 'filters.filters' })}
        isOpen={isFilterPanelOpen}
        onDismiss={handleDismissFilterPanel}
      >
        <div className="filter-panel-accordions">
          <Accordion panels={panels} />
        </div>
        <div className="filter-panel-actions-wrap">
          <form>
            {filterItems.length > 0 && (
              <div className="filter-panel-actions-holder">
                <div className="filter-panel-actions">
                  <Button className="filter-panel-actions-clear" text content={formatMessage({ id: 'buttons.clear' })} onClick={handleClearAll} />
                </div>
                {isOnMobile && (
                  <div className="filter-panel-actions">
                    <Button
                      className="filter-panel-actions-apply"
                      primary
                      content={formatMessage({ id: 'buttons.apply' })}
                      onClick={handleDismissFilterPanel}
                    />
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </Panel>
    </div>
  );
};
