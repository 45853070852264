export interface HighlightedTextProps {
  textLine: string;
  term: string;
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({ textLine, term }) => {
  const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(`(${escapeRegExp(term)})`, 'gi');
  const parts = textLine.split(regex);

  return <>{parts.map((part, index) => (regex.test(part) ? <b key={index}>{part}</b> : part))}</>;
};
