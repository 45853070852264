import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DialogType } from '@fluentui/react';
import { ShimmerTable, TransformMediumDialog, useAppDispatch, useAppSelector, useMediaOutletHeaderLocalization, getLanguagesRequested } from 'app/common';
import {
  firstPageOfMediaOutletsRequested,
  getAllMediaOutletsTagsRequested,
  MediaOutlet,
  selectIsLoadingSearchResults,
  transformMediaOutletIntoCompanyRequested
} from 'app/pages/my-audience/media-outlets';
import {
  MediaOutletPanel,
  selectIsTransformMediaOutletDialogOpened,
  selectSelectedMediaOutlet,
  setIsTransformMediaOutletDialogOpened
} from 'app/pages/my-audience/media-outlet-profile';
import { MediaOutletFiltersPanel } from 'app/pages/my-audience/media-outlets-filter-search/MediaOutletFiltersPanel';
import { MediaOutletsSavedSearchesPanel } from 'app/pages/my-audience/media-outlets-saved-searches';
import {
  MediaOutletsActionBar,
  MediaOutletsExportDialog,
  MediaOutletsFilterPills,
  MediaOutletsSearchResultsIntro,
  MediaOutletsTable
} from 'app/pages/my-audience/media-outlet-search-results';

export const MediaOutletsSearchResults = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useMediaOutletHeaderLocalization();

  const isTransformMediaOutletDialogOpened = useAppSelector<boolean>(selectIsTransformMediaOutletDialogOpened);
  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);
  const isLoadingSearchResults = useAppSelector<boolean>(selectIsLoadingSearchResults);

  useEffect(() => {
    dispatch(firstPageOfMediaOutletsRequested());
    dispatch(getAllMediaOutletsTagsRequested());
    dispatch(getLanguagesRequested());
  }, [dispatch]);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'transform-media-outlet-dialog.title' }, { name: selectedMediaOutlet?.name }),
    subText: formatMessage({ id: 'transform-media-outlet-dialog.text' })
  };

  const onDismissTransformDialog = () => {
    dispatch(setIsTransformMediaOutletDialogOpened(false));
  };

  const getTransformingMediumWarningsMessages = () => {
    const messages = [];
    const isMediaOutletExternal = !!selectedMediaOutlet?.dataSourceKey;
    if (isMediaOutletExternal) {
      messages.push(formatMessage({ id: 'delete-media-outlet-dialog.external-db-message' }, { name: `${selectedMediaOutlet?.name}` }));
    }

    return messages;
  };

  return (
    <>
      <div className="search-results-wrap">
        <MediaOutletsSearchResultsIntro />
        <div className="search-results-content">
          <MediaOutletsFilterPills />
          <MediaOutletsActionBar />
          <MediaOutletsTable />
          {isLoadingSearchResults ? <ShimmerTable /> : <></>}
        </div>
      </div>
      <Routes>
        <Route path="saved-searches/*" element={<MediaOutletsSavedSearchesPanel />} />
        <Route path="media-outlet-info/:selectedMediaOutletTableId/*" element={<MediaOutletPanel />} />
        <Route path="filter-media-outlets/*" element={<MediaOutletFiltersPanel />} />
      </Routes>
      {isTransformMediaOutletDialogOpened && (
        <TransformMediumDialog
          dialogProps={dialogContentProps}
          opened={isTransformMediaOutletDialogOpened}
          dismiss={onDismissTransformDialog}
          transformMediumRequested={transformMediaOutletIntoCompanyRequested}
          warningMessages={getTransformingMediumWarningsMessages()}
          deleteButtonDisabled={!!selectedMediaOutlet?.dataSourceKey}
        />
      )}
      <MediaOutletsExportDialog />
    </>
  );
};
