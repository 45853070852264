import { IntlMessage, NestedKeys } from 'app/i18n';
import type { ReactNode } from 'react';
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message';

type FormattedMessageProps = ReactIntlFormattedMessageProps<Record<string, ReactNode>> & {
  id?: NestedKeys<IntlMessage>;
};

export const FormattedMessage = ({ id, ...rest }: FormattedMessageProps) => {
  return <ReactIntlFormattedMessage id={id} {...rest} />;
};
