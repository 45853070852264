import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { selectCompanyJobTitles, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addCompanyCollaborationRequested,
  AddCompanyWizardSteps,
  closeAddCompanyWizard,
  goToNextStepAddCompany,
  goToPreviousStepAddCompany,
  selectAddCompanyCurrentStep,
  selectCompanyToAdd,
  selectIsAddCompanyWizardFinishButtonDisabled,
  selectIsAddCompanyWizardProfileInfoStepValid
} from 'app/pages/my-audience/contact-profile';

export const AddCompanyFooter = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector(selectAddCompanyCurrentStep);
  const isFinishButtonDisabled = useAppSelector(selectIsAddCompanyWizardFinishButtonDisabled);
  const isAddCompanyWizardProfileInfoStepValid = useAppSelector(selectIsAddCompanyWizardProfileInfoStepValid);
  const companyToAdd = useAppSelector(selectCompanyToAdd);
  const companyJobTitles = useAppSelector(selectCompanyJobTitles);

  const handleCancelButtonClick = () => dispatch(closeAddCompanyWizard());

  const handleBackButtonClick = () => dispatch(goToPreviousStepAddCompany());

  const handleNextButtonClick = () => dispatch(goToNextStepAddCompany());

  const handleFinishButtonClick = () => dispatch(addCompanyCollaborationRequested(companyJobTitles));

  const isNextButtonDisabled = () => {
    if (currentStep === AddCompanyWizardSteps.SearchCompany) return !companyToAdd?.id;
    if (currentStep === AddCompanyWizardSteps.ProfileInformation) return !companyToAdd?.id || !isAddCompanyWizardProfileInfoStepValid;
  };

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleCancelButtonClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > AddCompanyWizardSteps.SearchCompany && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={handleBackButtonClick} />
        )}
        {currentStep < AddCompanyWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={handleNextButtonClick} disabled={isNextButtonDisabled()} />
        )}
        {currentStep === AddCompanyWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.finish' })} primary onClick={handleFinishButtonClick} disabled={isFinishButtonDisabled} />
        )}
      </div>
    </div>
  );
};
