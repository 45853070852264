import { useState } from 'react';
import { Icon } from '@fluentui/react';
import { AddIcon, Button, FilterIcon, Input, MenuButton, MenuItem, SearchIcon, Tooltip } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';
import { selectIsOnDesktop, useAppSelector } from 'app/common';

export interface PanelToolbarProps {
  onOpenWizard: () => void;
  onSearchByNameAndRole: (item: string, role: string) => void;
  inputValue: string;
  filterItems: string[];
  disabled?: boolean;
  buttonMsg?: string;
}

export const PanelToolbar = ({ onOpenWizard, onSearchByNameAndRole, inputValue, filterItems, disabled, buttonMsg }: PanelToolbarProps) => {
  const { formatMessage } = useIntl();
  const defaultMsg = formatMessage({ id: 'companies-contact-top.add' });
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const [menuItems, setMenuItems] = useState(() =>
    filterItems.map((filterItemValue, index) => ({
      key: index,
      content: filterItemValue,
      isActive: false,
      onClick: () => onSearchByNameAndRole(inputValue, filterItemValue)
    }))
  );

  const onClearFilters = () => {
    setMenuItems((items) => items.map((item) => ({ ...item, isActive: false })));
    onSearchByNameAndRole(inputValue, '');
  };

  const onSelectedFilter = (index: number, inputValue: string, filterItemValue: string) => {
    setMenuItems((items) => items.map((item, i) => ({ ...item, isActive: i === index })));
    onSearchByNameAndRole(inputValue, filterItemValue);
  };

  return (
    <div className="accordion-toolbar">
      {isOnDesktop && (
        <div className="accordion-toolbar-button">
          <Button content={buttonMsg ? buttonMsg : defaultMsg} icon={<AddIcon />} onClick={onOpenWizard} disabled={disabled} />
        </div>
      )}
      <div className="accordion-toolbar-search">
        <Input
          clearable
          icon={<SearchIcon />}
          placeholder={formatMessage({ id: 'companies-contact-top.input-placeholder' })}
          onChange={(_, data) => onSearchByNameAndRole(data.value, menuItems.find((item) => item.isActive)?.content ?? '')}
        />
        <div className="toolbar-filter">
          {!!menuItems?.length ? (
            <MenuButton
              menu={
                <>
                  {menuItems.map(({ isActive, content, key }) => (
                    <MenuItem
                      className={`menu-item ${isActive ? 'menu-item__active' : ''}`}
                      key={key}
                      content={content}
                      onClick={() => onSelectedFilter(key, inputValue, content)}
                    />
                  ))}
                  <MenuItem onClick={onClearFilters} content={formatMessage({ id: 'buttons.clear-all' })} icon={<Icon iconName="Delete" />} />
                </>
              }
              trigger={<Button text content={formatMessage({ id: 'buttons.filter' })} icon={<FilterIcon />} />}
            />
          ) : (
            <Tooltip
              subtle={false}
              pointing
              position="above"
              content={formatMessage({ id: 'companies-contact-top.tooltip-msg' })}
              trigger={
                <MenuButton
                  trigger={
                    <Button text disabled className="table-toolbar-action-button" content={formatMessage({ id: 'filters.filter' })} icon={<FilterIcon />} />
                  }
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
