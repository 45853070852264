export const getFirstAndLastDaysOfTheCurrentMonth = (): [Date, Date] => {
  const currentDate = new Date();
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);

  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  lastDay.setHours(23, 59, 59, 999);

  return [firstDay, lastDay];
};
