import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  selectCompaniesTotalCount,
  selectCompaniesPageSize,
  pageOfCompaniesRequested,
  selectIsLoadingCompanies,
  setCompaniesPageSize,
  setCompaniesPageNumber
} from 'app/pages/my-audience/contact-profile';

export const CompaniesTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfCompanies = useAppSelector<number>(selectCompaniesTotalCount);
  const pageSize = useAppSelector<number>(selectCompaniesPageSize);
  const isLoadingCompanies = useAppSelector<boolean>(selectIsLoadingCompanies);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setCompaniesPageNumber(pageNumber));
    dispatch(pageOfCompaniesRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setCompaniesPageNumber(1));
    dispatch(setCompaniesPageSize(pageSize));
    dispatch(pageOfCompaniesRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfCompanies}
      isLoadingData={isLoadingCompanies}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
