import { Language, MediumCategory, ensureHttpProtocol } from 'app/common';
import { Company } from 'app/pages/my-audience/companies';
import { Collaboration, Contact, JobTitle, MediumType, OwnContactData, PhoneNumber } from 'app/pages/my-audience/contacts';
import { GeographicalCoverage, LocalizedListValue, MediaOutlet, MediumContactData } from 'app/pages/my-audience/media-outlets';
import { ContactInfo } from 'app/pages/my-audience/wizard-create-company';
import {
  AddAdditionalInformationForm,
  AddGeneralContactInfoForm,
  CollaborationTableData,
  CompanyAddressInfo,
  CompanyGeneralInfo,
  ContactGeneralInfo,
  CreateCompanyAddressInfoForm,
  CreateCompanyGeneralInfoForm,
  CreateMediaOutletAddressInfoForm,
  CreateMediaOutletGeneralInfoForm,
  MediaOutletAddressInfo,
  MediaOutletGeneralInfo
} from 'app/pages/my-audience/wizard-create-contact';
import humanizeString from 'humanize-string';

export const setContactGeneralInfo = (data: AddGeneralContactInfoForm, languages: Language[]) => {
  const language = languages.find((language) => language.name === data.language);

  return new ContactGeneralInfo(
    data.salutation,
    data.lastName,
    data.firstName,
    language,
    data.emailAddress,
    data.phoneNumber,
    data.mobilePhoneNumber,
    data.address,
    data.postalCode,
    data.city,
    data.country,
    ensureHttpProtocol(data.blog),
    ensureHttpProtocol(data.websiteUrl),
    ensureHttpProtocol(data.twitterUrl),
    ensureHttpProtocol(data.instagramUrl),
    ensureHttpProtocol(data.linkedinUrl),
    ensureHttpProtocol(data.youtubeUrl),
    ensureHttpProtocol(data.facebookUrl)
  );
};

export const setMediaOutletGeneralInfo = (
  data: CreateMediaOutletGeneralInfoForm,
  audiences: LocalizedListValue[],
  frequencies: LocalizedListValue[],
  categories: MediumCategory[]
) => {
  const audience = audiences.find((a) => a.label === data.audience);
  const category = categories.find((c) => c.name === data.category);
  const frequency = frequencies.find((f) => f.label === data.frequency);

  return new MediaOutletGeneralInfo(data.name, data.description, audience?.key, category, frequency?.key);
};

export const setMediaOutletAddressInfo = (data: CreateMediaOutletAddressInfoForm) => {
  return new MediaOutletAddressInfo(
    data.address,
    data.poBox,
    data.postalCode,
    data.city,
    data.country,
    ensureHttpProtocol(data.websiteUrl),
    data.emailAddress,
    data.phoneNumber,
    data.fax
  );
};

export const setCompanyGeneralInfo = (data: CreateCompanyGeneralInfoForm): CompanyGeneralInfo => {
  return new CompanyGeneralInfo(data.name, data.parentCompany, data.comment);
};

export const setCompanyAddressInfo = (data: CreateCompanyAddressInfoForm) => {
  return new CompanyAddressInfo(
    data.address,
    data.poBox,
    data.postalCode,
    data.city,
    data.country,
    ensureHttpProtocol(data.websiteUrl),
    data.emailAddress,
    data.phoneNumber,
    data.fax
  );
};

export const createCollaborationTableData = (mediaOutlets: MediaOutlet[], companies: Company[]): CollaborationTableData[] => {
  return [
    ...companies.map(
      (company) =>
        new CollaborationTableData(
          company.id,
          company.id,
          MediumType.Company,
          humanizeString(MediumType[MediumType.Company]),
          company.name,
          company.contactData.postalCode,
          company.contactData.city,
          company.contactData.country
        )
    ),
    ...mediaOutlets.map(
      (mediaOutlet) =>
        new CollaborationTableData(
          mediaOutlet.id,
          mediaOutlet.id,
          MediumType.MediaOutlet,
          humanizeString(MediumType[MediumType.MediaOutlet]),
          mediaOutlet.name,
          mediaOutlet.contactData.postalCode,
          mediaOutlet.contactData.city,
          mediaOutlet.contactData.country
        )
    )
  ];
};

export const createCompany = (generalInfo: CompanyGeneralInfo, addressInfo: CompanyAddressInfo): Company => {
  const contactData = new MediumContactData(
    addressInfo.address,
    addressInfo.poBox,
    addressInfo.postalCode,
    addressInfo.country,
    addressInfo.city,
    ensureHttpProtocol(addressInfo.websiteUrl),
    addressInfo.emailAddress,
    addressInfo.phoneNumber,
    addressInfo.fax,
    ''
  );
  return new Company('', generalInfo.name, '', generalInfo.parentCompany, contactData, '', '', [], [], [], [], new Date(), [], 0, '', '', {} as MediumCategory);
};

export const createMediaOutlet = (generalInfo: MediaOutletGeneralInfo, addressInfo: MediaOutletAddressInfo): MediaOutlet => {
  const contactData = new MediumContactData(
    addressInfo.address,
    addressInfo.poBox,
    addressInfo.postalCode,
    addressInfo.country,
    addressInfo.city,
    ensureHttpProtocol(addressInfo.websiteUrl),
    addressInfo.emailAddress,
    addressInfo.phoneNumber,
    addressInfo.fax,
    ''
  );
  return new MediaOutlet(
    '',
    generalInfo.name,
    '',
    generalInfo.description,
    generalInfo.audienceKey,
    generalInfo.category,
    generalInfo.frequencyKey,
    {} as GeographicalCoverage,
    [],
    [],
    [],
    contactData,
    [],
    [],
    [],
    [],
    '',
    '',
    '',
    '',
    0,
    0
  );
};

export const createContact = (contactInfoToAdd: ContactInfo) =>
  new Contact('', contactInfoToAdd.title, contactInfoToAdd.firstName, contactInfoToAdd.lastName, '', false, [], [], [], [], [], [], [], {} as OwnContactData);

export const setCollaboration = (collaboration: Collaboration, formData: AddAdditionalInformationForm, jobTitles: JobTitle[]): Collaboration => {
  const jobTitle = jobTitles.find((jt) => jt.name === formData.jobTitle);

  return {
    ...collaboration,
    jobTitle: jobTitle,
    jobDescription: formData.jobDescription,
    country: formData.country,
    landlinePhoneNumber: new PhoneNumber(formData.landlinePhoneNumber, false),
    mobilePhoneNumber: new PhoneNumber(formData.mobilePhoneNumber, false),
    emailAddress: formData.emailAddress,
    city: formData.city,
    postalCode: formData.postalCode,
    address: formData.address
  };
};
