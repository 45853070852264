import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfContactsRequested,
  selectPageSize,
  selectTotalCountOfContacts,
  setPageNumber,
  selectIsLoadingContacts,
  setPageSize
} from 'app/pages/my-audience/wizard-create-company';
import { resetCollaborationsTable } from 'app/pages/my-audience/wizard-create-company';

export const CollaborationTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfContacts = useAppSelector<number>(selectTotalCountOfContacts);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingContacts);
  const pageSize = useAppSelector<number>(selectPageSize);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
    dispatch(pageOfContactsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setPageSize(pageSize));
    dispatch(resetCollaborationsTable());
    dispatch(pageOfContactsRequested());
  };

  return (
    <TablePaging
      isLoadingData={isLoadingContacts}
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfContacts}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
