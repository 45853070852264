import { useMemo } from 'react';
import { Selection, SelectionMode } from '@fluentui/react';
import { WizardWrap, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { CollaborationWithContactInfo, Contact } from 'app/pages/my-audience/contacts';
import {
  AddAdditionalInfo,
  AddAddressInfo,
  AddGeneralInfo,
  AddressInfo,
  Collaboration,
  CreateCompanyWizardStep,
  FinishCreateCompany,
  closeCreateCompanyWizard,
  selectAddressInfoToAdd,
  selectCollaborationDetails,
  selectCreateCompanyCurrentStep,
  selectIsCreateCompanyWizardOpened,
  setCollaborationsToAddPerPage
} from 'app/pages/my-audience/wizard-create-company';
import { ContactsTableData, createCollaborationDetails } from 'app/pages/my-audience/wizard-create-media-outlet';

export const CreateCompanyWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const isCreateCompanyWizardOpened = useAppSelector<boolean>(selectIsCreateCompanyWizardOpened);
  const currentStep = useAppSelector<CreateCompanyWizardStep>(selectCreateCompanyCurrentStep);
  const collaborationDetails = useAppSelector<CollaborationWithContactInfo[]>(selectCollaborationDetails);
  const companyAddress = useAppSelector<AddressInfo>(selectAddressInfoToAdd);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedItems = selection.getSelection() as Contact[];

          if (selectedItems.includes(undefined)) {
            return;
          }

          const collaborations = [];

          selectedItems.forEach((si) => {
            const existingCollaboration = collaborationDetails.find((c) => c.contactId === si.id);
            if (existingCollaboration !== undefined) {
              collaborations.push(existingCollaboration);
              return;
            }

            collaborations.push(createCollaborationDetails({ id: si.id, firstName: si.firstName, lastName: si.lastName } as ContactsTableData, companyAddress));
          });

          dispatch(setCollaborationsToAddPerPage(collaborations));
        },
        selectionMode: SelectionMode.multiple
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateCompanyWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-menu-create-company.wizard-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateCompanyWizardStep.GeneralInfo && <AddGeneralInfo />}
        {currentStep === CreateCompanyWizardStep.AddressInfo && <AddAddressInfo />}
        {currentStep === CreateCompanyWizardStep.ContactTab && <Collaboration selection={selection} />}
        {currentStep === CreateCompanyWizardStep.AdditionalInfo && <AddAdditionalInfo />}
        {currentStep === CreateCompanyWizardStep.Finish && <FinishCreateCompany />}
      </div>
    </WizardWrap>
  );
};

export default CreateCompanyWizard;
