import { Selection } from '@fluentui/react';
import { useAppDispatch, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateCompanyWizard,
  CreateContactTableToolbar,
  CreateMediaOutletWizard,
  CollaborationTable,
  CollaborationTablePaging,
  CreateContactSteps,
  CreateContactFooter,
  goToNextStep
} from 'app/pages/my-audience/wizard-create-contact';

type AddContactCollaborationInfoProps = {
  collaborationSelection: Selection;
};

export const AddContactCollaborationInfo = ({ collaborationSelection }: AddContactCollaborationInfoProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  return (
    <>
      <CreateContactSteps isStepValid={true} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.add-colaboration-title' })} description="" />
        <CreateContactTableToolbar />
        <CollaborationTable selection={collaborationSelection} />
        <CollaborationTablePaging />
        <CreateMediaOutletWizard />
        <CreateCompanyWizard />
        <CreateContactFooter handleNextButtonClick={handleNextButtonClick} />
      </div>
    </>
  );
};
