import { Panel } from '@fluentui/react';
import { ArrowLeftIcon, Button } from '@fluentui/react-northstar';

interface WizardWrapProps {
  children: JSX.Element;
  isWizardOpened: boolean;
  onDismissButtonClick: () => void;
  headerTitle?: string;
  customWidth?: string;
  classModifier?: string;
  hasBackArrow?: boolean;
  onArrowButtonClick?: () => void;
  isLightDismiss: boolean;
  isBlocking?: boolean;
}

export const WizardWrap = ({
  children,
  isWizardOpened,
  onDismissButtonClick,
  headerTitle,
  customWidth,
  classModifier,
  hasBackArrow,
  onArrowButtonClick,
  isLightDismiss,
  isBlocking = true
}: WizardWrapProps) => {
  return (
    <Panel
      isLightDismiss={isLightDismiss}
      customWidth={customWidth}
      className={`wizard-panel-wrap wizard-panel-wrap--${classModifier}`}
      headerText={headerTitle}
      isOpen={isWizardOpened}
      onDismiss={onDismissButtonClick}
      closeButtonAriaLabel="Close"
      isBlocking={isBlocking}
    >
      {hasBackArrow && (
        <div className="wizard-arrow-button">
          <Button content={<ArrowLeftIcon />} iconOnly onClick={onArrowButtonClick} text />
        </div>
      )}
      {children}
    </Panel>
  );
};
