import { useAppDispatch, useAppSelector } from 'app/common';
import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import {
  addContactRequested,
  closeCreateContactWizard,
  CreateContactWizardStep,
  goToNextStep,
  goToPreviousStep,
  pageOfMediaOutletsAndCompaniesRequested,
  selectAreAdditionalInfoValid,
  selectAreContactInfoValid,
  selectCurrentStep,
  selectIsFinishButtonDisabled
} from 'app/pages/my-audience/wizard-create-contact';
import { useIntl } from 'app/i18n';

interface CreateContactFooterProps {
  handleNextButtonClick?: () => void;
}

export const CreateContactFooter = ({ handleNextButtonClick }: CreateContactFooterProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const currentStep = useAppSelector<CreateContactWizardStep>(selectCurrentStep);
  const isFinishButtonDisabled = useAppSelector<boolean>(selectIsFinishButtonDisabled);
  const areGeneralInfoValid = useAppSelector<boolean>(selectAreContactInfoValid);
  const areAdditionalInfoValid = useAppSelector<boolean>(selectAreAdditionalInfoValid);

  const handleCancelClick = () => dispatch(closeCreateContactWizard());

  const handleFinishClick = () => dispatch(addContactRequested());

  const handleBackButtonClick = () => {
    if (currentStep === CreateContactWizardStep.AdditionalInfo) {
      dispatch(pageOfMediaOutletsAndCompaniesRequested());
    }
    dispatch(goToPreviousStep());
  };

  const handleNextClick = () => (handleNextButtonClick ? handleNextButtonClick() : dispatch(goToNextStep()));

  const isNextButtonDisabled = () => {
    switch (currentStep) {
      case CreateContactWizardStep.GeneralInfo:
        return !areGeneralInfoValid;
      case CreateContactWizardStep.AdditionalInfo:
        return !areAdditionalInfoValid;
      default:
        return false;
    }
  };

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleCancelClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > CreateContactWizardStep.GeneralInfo && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={handleBackButtonClick} />
        )}
        {currentStep < CreateContactWizardStep.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={handleNextClick} disabled={isNextButtonDisabled()} />
        )}
        {currentStep === CreateContactWizardStep.Finish && (
          <Button content={formatMessage({ id: 'buttons.finish' })} primary onClick={handleFinishClick} disabled={isFinishButtonDisabled} />
        )}
      </div>
    </div>
  );
};
