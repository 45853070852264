import { FormEvent, useState } from 'react';
import { Button, CloseIcon, Dropdown, DropdownProps, Pill } from '@fluentui/react-northstar';
import { FilterItem, FilterItemType } from 'app/common';
import { useIntl } from 'react-intl';
import { SENDING_FILTER } from 'app/pages/my-activities/sending-filter-search';

interface FilterDropdownProps {
  name: string;
  allFilterItems: FilterItem[];
  activeFilterItems: FilterItem[];
  placeholder: string;
  onFilterAdd: ({ fieldName, value }: FilterItemType) => void;
  onFilterRemoval: ({ fieldName, value }: FilterItemType) => void;
}

export const FilterDropdown = ({ name, allFilterItems, activeFilterItems, placeholder, onFilterAdd, onFilterRemoval }: FilterDropdownProps) => {
  const { formatMessage } = useIntl();
  const [selectedValue, setSelectedValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const loadingMessage = formatMessage({ id: 'filters.loading' });
  const noResultsMessage = formatMessage({ id: 'filters.no-results' });

  const handleInputChange = ({ target }: FormEvent<HTMLInputElement>) => {
    setSearchQuery((target as HTMLInputElement).value);
  };

  const handleOnChange = (_, { value }: DropdownProps) => {
    setSelectedValue('');
    if (value) onFilterAdd({ fieldName: name, value: `${value}` });
  };

  const a11ySelectionMessage = {
    onAdd: (name: string): string => `${name} selected.`,
    onRemove: (name: string): string => `${name} has been removed.`
  };

  const filteredItems = searchQuery === '' ? allFilterItems : allFilterItems.filter((item) => item.fieldName.toLowerCase().includes(searchQuery.toLowerCase()));

  const handleRemoveFilter = (filterItem: FilterItem) => {
    const fieldName = filterItem.fieldName === formatMessage({ id: 'emailings-table.filters.favorites' }) ? SENDING_FILTER.FAVOURITE : name;
    onFilterRemoval({ fieldName, value: filterItem.value });
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);

  return (
    <div className="filter-panel-dropdown-wrap">
      <Dropdown
        className="filter-panel-dropdown"
        search
        clearable={true}
        items={filteredItems.map((item) => item.fieldName)}
        value={selectedValue}
        searchQuery={searchQuery}
        loadingMessage={loadingMessage}
        noResultsMessage={noResultsMessage}
        placeholder={placeholder}
        onInput={handleInputChange}
        onChange={handleOnChange}
        getA11ySelectionMessage={a11ySelectionMessage}
        onBlur={handleBlur}
        open={isOpen}
        onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
      />
      <div className="filter-panel-pills">
        {activeFilterItems.map(({ fieldName, value }: FilterItem) => (
          <Pill key={fieldName} className="filter-panel-pill">
            {fieldName}
            <Button icon={<CloseIcon />} iconOnly text onClick={() => handleRemoveFilter({ fieldName, value })} />
          </Pill>
        ))}
      </div>
    </div>
  );
};
