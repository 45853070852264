import { useState } from 'react';
import { Person } from '@microsoft/mgt-react';
import { useDebouncedCallback } from 'use-debounce';
import { Avatar, Input, List, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import {
  AadPickerSuggestion,
  PickerSuggestionType,
  debounceTimeInMilliseconds,
  getInitials,
  selectIsInTeams,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { AadContact, Group } from 'app/pages/my-audience/contacts';
import {
  addContactsFromCompanyInStore,
  getPeoplePickerSuggestionsRequested,
  selectPeoplePickerSuggestions,
  selectIsLoadingSuggestions,
  SuggestionsShimmer,
  selectIsPeoplePickerSuggestionEmpty
} from 'app/pages/my-activities/sending-wizard';
import emptyImage from 'assets/images/empty-search.png';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddContactFromCompanySearch = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const peoplePickerSuggestions = useAppSelector<AadPickerSuggestion[]>(selectPeoplePickerSuggestions);
  const isSearchResultEmpty = useAppSelector<boolean>(selectIsPeoplePickerSuggestionEmpty);
  const isLoadingContactSuggestions = useAppSelector<boolean>(selectIsLoadingSuggestions);
  const isInTeams = useAppSelector<boolean>(selectIsInTeams);

  const [searchText, setSearchText] = useState('');

  const onInputChange = (text: string) => {
    setSearchText(text);
    getSuggestions();
  };

  const getSuggestions = useDebouncedCallback(() => {
    dispatch(getPeoplePickerSuggestionsRequested(searchText));
  }, debounceTimeInMilliseconds);

  const handleContactSelected = (selectedIndex: number) => {
    const contactToAdd = peoplePickerSuggestions[selectedIndex];

    const contactsToAdd: AadContact[] = [];
    if (contactToAdd.type === PickerSuggestionType.People) {
      contactsToAdd.push(contactToAdd.value as AadContact);
    } else {
      (contactToAdd.value as Group).members.forEach((member) => {
        contactsToAdd.push(member);
      });
    }

    dispatch(addContactsFromCompanyInStore(contactsToAdd));
    setSearchText('');
  };

  const getEmailAddressFromAadSuggestion = (suggestion: AadPickerSuggestion): string => {
    if (suggestion.type === PickerSuggestionType.Group) return '';

    return (suggestion.value as AadContact).email;
  };

  return (
    <div className="wizard-search">
      <Input
        className="profile-panel-comments-send-input"
        icon={<SearchIcon />}
        autoComplete="off"
        placeholder={formatMessage({ id: 'add-contacts-from-company-wizard.search-input-placeholder' })}
        value={searchText}
        onChange={(e) => onInputChange((e.target as HTMLTextAreaElement).value)}
      />
      {searchText !== '' && !isLoadingContactSuggestions && (
        <div className="dropdown-segments-wrap">
          <div className="dropdown-segments">
            <Segment key={'dropdown-names'} className="dropdown-segment">
              <List
                onSelectedIndexChange={(_, newProps) => {
                  if (newProps) handleContactSelected(newProps.selectedIndex);
                }}
                selectable
                items={peoplePickerSuggestions.slice(0, 6).map((suggestion, index) => {
                  return {
                    key: index,
                    header: suggestion.name,
                    content: getEmailAddressFromAadSuggestion(suggestion),
                    media: isInTeams ? (
                      <Person userId={suggestion.objectId} avatarType="photo" avatarSize="auto" />
                    ) : (
                      <Avatar getInitials={getInitials} name={suggestion.name} />
                    )
                  };
                })}
              />
            </Segment>
          </div>
        </div>
      )}
      {searchText !== '' && isLoadingContactSuggestions && <SuggestionsShimmer />}
      {isSearchResultEmpty && searchText && (
        <Segment className="empty-search">
          <div className="empty-search-wrap">
            <img className="empty-search-image" src={emptyImage} alt={formatMessage({ id: 'empty-search-suggestions-message.title' })} />
            <Text className="empty-search-text text-bold">
              <FormattedMessage id={'empty-search-suggestions-message.title'} />
            </Text>
            <Text className="empty-search-text">
              <FormattedMessage id={'empty-search-suggestions-message.description'} />
            </Text>
          </div>
        </Segment>
      )}
    </div>
  );
};
