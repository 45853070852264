import { useState } from 'react';
import { Modal, TextField } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { addAiContentReportMessageRequested, selectIsReportAIContentModalOpen, setIsReportAIContentModalOpen } from 'app/pages/my-activities/sending-wizard';

export const ReportContent = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [text, setText] = useState<string>('');

  const isModalOpen = useAppSelector<boolean>(selectIsReportAIContentModalOpen);

  const handleDismiss = () => {
    dispatch(setIsReportAIContentModalOpen(false));
  };

  const handleReport = () => {
    dispatch(addAiContentReportMessageRequested(text));
    setText('');
  };

  const handleTextChange = (newValue?: string) => {
    setText(newValue || '');
  };

  return (
    <Modal isBlocking={false} isOpen={isModalOpen} onDismiss={handleDismiss}>
      <div className="report-ai-modal">
        <div className="report-ai-modal-main">
          <h1>{formatMessage({ id: 'emailing-wizard.report-ai-content-modal.title' })}</h1>
          <p className="message">{formatMessage({ id: 'emailing-wizard.report-ai-content-modal.text' })}</p>
          <p className="descriptor-text">{formatMessage({ id: 'emailing-wizard.report-ai-content-modal.descriptor-text' })}</p>
          <TextField borderless resizable={false} multiline className="text-field" value={text} onChange={(_, newValue) => handleTextChange(newValue)} />
        </div>
        <div className="report-ai-modal-footer">
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleDismiss} />
          <Button primary content={formatMessage({ id: 'buttons.report' })} onClick={handleReport} />
        </div>
      </div>
    </Modal>
  );
};
