import { Tooltip } from '@fluentui/react-northstar';
import { FilterItem, useReferenceListLocalization, selectIsOnDesktop, useAppSelector } from 'app/common';
import { FilterPillTooltip } from './FilterPillTooltip';
import { FilterPillItem } from './FilterPillItem';

type FilterItemProps = {
  filterItems: FilterItem[];
  onOpenFilterPanel: () => void;
  onUndoFilter: (filter: FilterItem) => void;
};

const DESKTOP_NUMBER_OF_PILLS = 7;
const TOUCH_NUMBER_OF_PILLS = 3;

export const FilterItemsTooltip = ({ filterItems, onOpenFilterPanel, onUndoFilter }: FilterItemProps) => {
  const { localizeFilterItem } = useReferenceListLocalization();
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const numberOfPills = isOnDesktop ? DESKTOP_NUMBER_OF_PILLS : TOUCH_NUMBER_OF_PILLS;

  const filterItemsToDisplay = filterItems.slice(numberOfPills);
  const numberOfOmittedTooltips = filterItems.length - numberOfPills;

  return (
    <>
      {filterItems.slice(0, numberOfPills).map((item) => (
        <>
          <Tooltip
            subtle={false}
            pointing
            key={item.fieldName}
            content={localizeFilterItem(item)}
            align="bottom"
            position="below"
            trigger={<FilterPillItem item={item} onOpenFilterPanel={onOpenFilterPanel} onUndoFilter={onUndoFilter} />}
          />
          {numberOfOmittedTooltips > 0 && (
            <Tooltip
              subtle={false}
              pointing
              align="bottom"
              position="below"
              content={<FilterPillTooltip filterItems={filterItemsToDisplay} />}
              trigger={
                <div className="filter-pills-number-wrap">
                  <span className="filter-pills-number">+{numberOfOmittedTooltips}</span>
                </div>
              }
            />
          )}
        </>
      ))}
    </>
  );
};
