import { FilterItem, SearchSuggestionCategory, Search, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  clearAllFilters,
  mediaOutletSuggestionsRequested,
  resetMediaOutletSuggestions,
  selectSearchSuggestions,
  selectSearchSuggestionText,
  selectIsLoadingSearchSuggestions,
  selectSearchText,
  setFilterItems,
  setSearchText,
  startSearchAnimation,
  stopSearchAnimation,
  firstPageOfMediaOutletsRequested
} from 'app/pages/my-audience/media-outlets';

interface MediaOutletsSearchProps {
  onOutsideClick?: () => void;
}

export const MediaOutletsSearch = ({ onOutsideClick }: MediaOutletsSearchProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const searchSuggestionsCategories = useAppSelector<SearchSuggestionCategory[]>(selectSearchSuggestions);
  const searchSuggestionsText = useAppSelector<string>(selectSearchSuggestionText);
  const isLoadingSearchSuggestions = useAppSelector<boolean>(selectIsLoadingSearchSuggestions);
  const searchText = useAppSelector<string>(selectSearchText);

  const handleClearAllFilters = () => {
    dispatch(clearAllFilters());
  };

  const handleSetSearchText = (searchText: string) => {
    dispatch(setSearchText(searchText));
  };

  const handleSetFilterItems = (filterItems: FilterItem[]) => {
    dispatch(setFilterItems(filterItems));
  };

  const handleResetSuggestions = () => {
    dispatch(resetMediaOutletSuggestions());
  };

  const handleSearchSuggestions = (searchSuggestionsText: string) => {
    dispatch(mediaOutletSuggestionsRequested(searchSuggestionsText));
  };

  const handleOnAnimationStart = () => {
    dispatch(startSearchAnimation());
  };

  const handleOnAnimationStop = () => {
    dispatch(stopSearchAnimation());
  };

  const handleSearchMediaOutlets = () => {
    dispatch(firstPageOfMediaOutletsRequested());
  };

  return (
    <Search
      searchSuggestionsCategories={searchSuggestionsCategories}
      searchSuggestionsText={searchSuggestionsText}
      onClearAllFilters={handleClearAllFilters}
      onResetSuggestions={handleResetSuggestions}
      onSearchSuggestions={handleSearchSuggestions}
      searchText={searchText}
      isLoadingSearchSuggestions={isLoadingSearchSuggestions}
      onAnimationStart={handleOnAnimationStart}
      onAnimationStop={handleOnAnimationStop}
      onSetSearchText={handleSetSearchText}
      onSetFilterItems={handleSetFilterItems}
      onSearch={handleSearchMediaOutlets}
      viewAllMessage={formatMessage({ id: 'search.view-all-media-outlets' })}
      onOutsideClick={onOutsideClick}
    />
  );
};
