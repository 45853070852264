import { Button } from '@fluentui/react-northstar';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import 'app/common/components/table/table-paging.scss';

interface PagingButtonProps {
  content: ReactElement<any, any> | string;
  disabled: boolean;
  onClick: () => void;
  size: 'small' | 'medium';
  key: number;
  primary: boolean;
}

export interface LocalizationMessage {
  nextButton: string;
  previousButton: string;
}

interface PaginationProps {
  totalCount: number;
  pageSize: number;
  onPageClick: (pageNumber: number) => void;
  isLoadingData: boolean;
  localization: LocalizationMessage;
  page?: number;
}

export const Pagination = ({ totalCount, pageSize, onPageClick, isLoadingData, localization, page = 1 }: PaginationProps) => {
  const tableThreshold = 5;
  const numberOfPages = Math.ceil(totalCount / pageSize);
  const [selectedPage, setSelectedPage] = useState<number>(page);

  const handlePageClick = useCallback(
    (pageNumber: number) => {
      setSelectedPage(pageNumber);
      onPageClick(pageNumber);
    },
    [onPageClick]
  );

  const renderPagingButton = useCallback(
    (buttons: PagingButtonProps[], i: number) =>
      buttons.push({
        content: <div className={i === selectedPage ? 'selected' : ''}>{i.toString()}</div>,
        disabled: false,
        onClick: () => handlePageClick(i),
        size: 'small',
        key: i,
        primary: selectedPage === i
      }),
    [handlePageClick, selectedPage]
  );

  const getPages = useCallback(() => {
    let areNextDotsRendered = false;
    let arePreviousDotsRendered = false;

    const buttons: PagingButtonProps[] = [];
    buttons.push({
      content: `< ${localization.previousButton}`,
      disabled: selectedPage === 1 || isLoadingData,
      onClick: () => handlePageClick(selectedPage - 1),
      size: 'medium',
      key: 0,
      primary: false
    });

    if (numberOfPages < tableThreshold) {
      for (let i = 1; i <= numberOfPages; i++) {
        renderPagingButton(buttons, i);
      }
    } else {
      for (let i = 1; i <= numberOfPages; i++) {
        if (i > 2 && i !== selectedPage && (i < selectedPage - 1 || i > selectedPage + 1) && i < numberOfPages) {
          if (i > selectedPage) {
            if (areNextDotsRendered) continue;
            buttons.push({
              content: '...',
              disabled: true,
              onClick: () => handlePageClick(i),
              size: 'small',
              key: i,
              primary: selectedPage === i
            });
            areNextDotsRendered = true;
          } else {
            if (arePreviousDotsRendered) continue;
            buttons.push({
              content: '...',
              disabled: true,
              onClick: () => handlePageClick(i),
              size: 'small',
              key: i,
              primary: selectedPage === i
            });
            arePreviousDotsRendered = true;
          }
        } else {
          renderPagingButton(buttons, i);
        }
      }
    }

    buttons.push({
      content: `${localization.nextButton} >`,
      disabled: selectedPage === numberOfPages || isLoadingData,
      onClick: () => handlePageClick(selectedPage + 1),
      size: 'medium',
      key: numberOfPages + 1,
      primary: false
    });
    return buttons;
  }, [handlePageClick, isLoadingData, localization.nextButton, localization.previousButton, numberOfPages, renderPagingButton, selectedPage]);

  const buttons = useMemo(
    () =>
      getPages().map(({ content, size, disabled, onClick, primary, key }) => ({
        size,
        content,
        key,
        iconOnly: true,
        text: true,
        disabled,
        onClick,
        primary
      })),
    [getPages]
  );

  return (
    <>
      {numberOfPages > 0 && (
        <div className="table-paging">
          <div className="paging-buttons">
            <Button.Group buttons={buttons} />
          </div>
        </div>
      )}
    </>
  );
};
