import { Skeleton } from '@fluentui/react-northstar';

export const ShimmerProfilePanelList = () => {
  return (
    <Skeleton animation="pulse" className="shimmer-wrap">
      <div className="shimmer-panel-wrap">
        <div className="shimmer-panel-intro">
          <div className="shimmer-panel-intro-avatar">
            <Skeleton.Avatar size="largest" />
          </div>
          <div className="shimmer-panel-intro-content">
            <Skeleton.Line />
            <Skeleton.Line />
          </div>
        </div>
        <div className="shimmer-panel-tabs">
          <Skeleton.Line width="20%" />
          <Skeleton.Line width="20%" />
        </div>
        <div className="shimmer-panel-content">
          <div className="shimmer-panel-accordion">
            <div className="shimmer-panel-accordion-intro">
              <Skeleton.Line />
            </div>
            <div className="shimmer-panel-accordion-charts-info">
              <Skeleton.Line width="30%" />
              <Skeleton.Line width="15%" />
              <Skeleton.Line width="30%" />
              <Skeleton.Line width="15%" />
            </div>
            <div className="shimmer-panel-accordion-charts">
              <div className="shimmer-panel-accordion-chart">
                <Skeleton.Avatar size="largest" />
                <Skeleton.Line />
              </div>
              <div className="shimmer-panel-accordion-chart">
                <Skeleton.Avatar size="largest" />
                <Skeleton.Line />
              </div>
              <div className="shimmer-panel-accordion-chart">
                <Skeleton.Avatar size="largest" />
                <Skeleton.Line />
              </div>
            </div>
            <div className="shimmer-panel-accordion-charts-info">
              <Skeleton.Line width="30%" />
              <Skeleton.Line width="15%" />
              <Skeleton.Line width="30%" />
              <Skeleton.Line width="15%" />
            </div>
          </div>
          <div className="shimmer-panel-accordion">
            <div className="shimmer-panel-accordion-intro">
              <Skeleton.Line />
            </div>
          </div>
        </div>
      </div>
    </Skeleton>
  );
};
