import { Icon } from '@fluentui/react';
import { Tooltip } from '@fluentui/react-northstar';

interface WarningMessageProps {
  warningText: string;
}

export const WarningMessage = ({ warningText }: WarningMessageProps) => {
  return (
    <Tooltip
      subtle={false}
      position="below"
      trigger={
        <span className="warning-text">
          <Icon iconName="Warning" />
          {warningText}
        </span>
      }
      content={warningText}
    />
  );
};
