import { useMemo } from 'react';
import { useIntl } from 'app/i18n';
import { Button } from '@fluentui/react-northstar';
import { MediumCategory, EditableDropdownField, EditableInputNumberField, useAppDispatch, useAppSelector, MediumCategoryType } from 'app/common';
import { selectLocalizedAudiencesValues, selectLocalizedFrequencies, selectLocalizedFrequenciesValues, selectLocalizedAudiences } from 'app/localization';
import { hideMoreEditableFileds, selectSelectedMediaOutlet, updateMediaOutletRequested } from 'app/pages/my-audience/media-outlet-profile';
import { LocalizedListValue, MediaOutlet, addMediumCategoryRequested, selectCategories } from 'app/pages/my-audience/media-outlets';
import { useAuthContext, Permission } from 'auth';

export const OverviewMoreFields = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);

  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);
  const allFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);
  const allAudiences = useAppSelector<LocalizedListValue[]>(selectLocalizedAudiences);
  const allCategories = useAppSelector<MediumCategory[]>(selectCategories);

  const frequenciesValues = useAppSelector<string[]>(selectLocalizedFrequenciesValues);
  const audiencesValues = useAppSelector<string[]>(selectLocalizedAudiencesValues);

  const selectedFrequency = useMemo(
    () => allFrequencies.find((f) => selectedMediaOutlet.frequencyKey === f.key),
    [allFrequencies, selectedMediaOutlet.frequencyKey]
  );

  const selectedAudience = useMemo(() => allAudiences.find((a) => selectedMediaOutlet.audienceKey === a.key), [allAudiences, selectedMediaOutlet.audienceKey]);

  const handleOnChange = (fieldName: string, updatedValue: number | string | MediumCategory) => {
    const selectedMediaOutletLocal = { ...selectedMediaOutlet, [fieldName]: updatedValue };
    dispatch(updateMediaOutletRequested(selectedMediaOutletLocal));
  };

  const handleShowLessButtonClick = () => {
    dispatch(hideMoreEditableFileds());
  };

  const handleCreateNewCategory = (categoryName: string) => {
    dispatch(addMediumCategoryRequested(categoryName));
  };

  return (
    <>
      <EditableDropdownField
        value={selectedFrequency?.label}
        fieldName="frequencyKey"
        suggestions={frequenciesValues}
        onSaveChanges={(name, updatedValue) => handleOnChange(name, allFrequencies.find((f) => f.label === updatedValue).key)}
        label={formatMessage({ id: 'mo-overview-labels.frequancy' })}
        disabled={!hasPermissionToEdit}
      />
      <EditableDropdownField
        value={selectedAudience?.label}
        fieldName="audienceKey"
        suggestions={audiencesValues}
        onSaveChanges={(name, updatedValue) => handleOnChange(name, allAudiences.find((a) => a.label === updatedValue).key)}
        label={formatMessage({ id: 'mo-overview-labels.audience' })}
        disabled={!hasPermissionToEdit}
      />
      <EditableDropdownField
        value={selectedMediaOutlet.category?.name}
        fieldName="category"
        suggestions={allCategories.map((c) => c.name)}
        onSaveChanges={(name, updatedValue) =>
          handleOnChange(
            name,
            allCategories.find((c) => c.name === updatedValue)
          )
        }
        label={formatMessage({ id: 'mo-overview-labels.category' })}
        disabled={!hasPermissionToEdit}
        createNewEntityMessage={formatMessage({ id: 'aria-labels.click-to-add-new-category' })}
        onCreateNew={handleCreateNewCategory}
      />
      <EditableInputNumberField
        value={selectedMediaOutlet.circulation}
        fieldName="circulation"
        onSaveChanges={handleOnChange}
        label={formatMessage({ id: 'mo-overview-labels.circulation' })}
        isLink={false}
        disabled={!hasPermissionToEdit}
      />
      <div className="editable-fields-button">
        <Button onClick={handleShowLessButtonClick} text content={formatMessage({ id: 'buttons.show-less' })} />
      </div>
    </>
  );
};
