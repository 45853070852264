import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import {
  AddContactFromCompany,
  closeAddContactFromCompanyWizard,
  closeProfilePanel,
  selectIsAddContactFromCompanyWizardOpened
} from 'app/pages/my-audience/lists-profile';

export const AddContactFromCompanyWizard = () => {
  const dispatch = useAppDispatch();

  const isAddContactFromCompanyOpened = useAppSelector<boolean>(selectIsAddContactFromCompanyWizardOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeAddContactFromCompanyWizard());
    dispatch(closeProfilePanel());
  };

  const handleArrowButtonClick = () => {
    dispatch(closeAddContactFromCompanyWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isAddContactFromCompanyOpened}
      onDismissButtonClick={handleDismissButtonClick}
      customWidth="700px"
      classModifier="narrow"
      hasBackArrow={true}
      onArrowButtonClick={handleArrowButtonClick}
    >
      <div className="wizard-panel-main">
        <AddContactFromCompany />
      </div>
    </WizardWrap>
  );
};
