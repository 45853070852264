import * as Sentry from '@sentry/react';
import {
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regEmail,
  useAppDispatch,
  useAppSelector,
  WizardIntroInfo,
  isPhoneNumberValidOrEmpty,
  isValidUrlField,
  regUrl
} from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { Contact, OwnContactData } from 'app/pages/my-audience/contacts';
import {
  AddOwnContactDataForm,
  closeCreateContactWizard,
  CreateContactSteps,
  CreateContactWizardFooter,
  createOwnContactData,
  goToNextStepCreateWizard,
  goToPreviousStepCreateWizard,
  selectContactToAdd,
  selectOwnContactDataToAdd,
  setOwnContactDataToAdd
} from 'app/pages/my-audience/media-outlet-profile';
import { getNames } from 'country-list';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const AddOwnContactData = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactToAdd = useAppSelector<Contact>(selectContactToAdd);
  const ownContactDataToAdd = useAppSelector<OwnContactData>(selectOwnContactDataToAdd);

  const { handleSubmit, control, watch } = useForm<AddOwnContactDataForm, any>({
    defaultValues: {
      emailAddress: ownContactDataToAdd?.emailAddress,
      landlinePhoneNumber: ownContactDataToAdd?.landlinePhoneNumber?.value,
      mobilePhoneNumber: ownContactDataToAdd?.mobilePhoneNumber?.value,
      address: ownContactDataToAdd?.address,
      city: ownContactDataToAdd?.city,
      postalCode: ownContactDataToAdd?.postalCode,
      country: ownContactDataToAdd?.country,
      blog: ownContactDataToAdd?.blogUrl,
      websiteUrl: ownContactDataToAdd?.websiteUrl,
      twitterUrl: ownContactDataToAdd?.twitterProfileUrl,
      instagramUrl: ownContactDataToAdd?.instagramProfileUrl,
      linkedinUrl: ownContactDataToAdd?.linkedInProfileUrl,
      youtubeUrl: ownContactDataToAdd?.youtubeProfileUrl,
      facebookUrl: ownContactDataToAdd?.facebookProfileUrl
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleNextButtonClick = () => dispatch(goToNextStepCreateWizard());

  const areFieldsValid =
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('landlinePhoneNumber')) &&
    isPhoneNumberValidOrEmpty(watch('mobilePhoneNumber')) &&
    isValidUrlField(watch('websiteUrl')) &&
    isValidUrlField(watch('twitterUrl')) &&
    isValidUrlField(watch('instagramUrl')) &&
    isValidUrlField(watch('linkedinUrl')) &&
    isValidUrlField(watch('youtubeUrl')) &&
    isValidUrlField(watch('blog')) &&
    isValidUrlField(watch('facebookUrl'));

  const handleBackButtonClick = () => dispatch(goToPreviousStepCreateWizard());

  const handleCancelButtonClick = () => dispatch(closeCreateContactWizard());

  const submitData = useCallback(() => {
    handleSubmit(
      (data) => {
        const ownContactData: OwnContactData = createOwnContactData(data);

        dispatch(setOwnContactDataToAdd(ownContactData));
      },
      (error) => {
        Sentry.setExtra('Path', AddOwnContactData.name);
        Sentry.captureException(error);
      }
    )();
  }, [dispatch, handleSubmit]);

  useEffect(() => {
    return () => {
      submitData();
    };
  }, [submitData]);

  return (
    <>
      <CreateContactSteps areFieldsValid={areFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntroInfo
          title={formatMessage({ id: 'mo-create-contact.wizard-own-contact-title' })}
          description={formatMessage({ id: 'mo-create-contact.wizard-own-contact-subtitle' })}
          info={
            <span className="wizard-panel-description text-bold">
              {contactToAdd.firstName} {contactToAdd.lastName}
            </span>
          }
        />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput
              name="emailAddress"
              label={formatMessage({ id: 'mo-create-contact.wizard-email-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="landlinePhoneNumber"
              label={formatMessage({ id: 'mo-create-contact.wizard-phone-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-mobile-phone-label' })}
              control={control}
              name="mobilePhoneNumber"
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'mo-create-contact.wizard-address-label' })} control={control} name="address" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'mo-create-contact.wizard-city-label' })} control={control} name="city" />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'mo-create-contact.wizard-country-label'} />
                </span>
              }
              name="country"
              items={getNames()}
              control={control}
              placeholder={formatMessage({ id: 'mo-create-contact.wizard-country-placeholder' })}
              defaultValue={ownContactDataToAdd.country}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'mo-create-contact.wizard-postal-label' })} control={control} name="postalCode" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-blog-url' })}
              control={control}
              name="blog"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-website-url' })}
              control={control}
              name="websiteUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-twitter-url' })}
              control={control}
              name="twitterUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-insta-url' })}
              control={control}
              name="instagramUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-ln-url' })}
              control={control}
              name="linkedinUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-youtube-url' })}
              control={control}
              name="youtubeUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'mo-create-contact.wizard-facebook-url' })}
              control={control}
              name="facebookUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
        </div>
        <CreateContactWizardFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areFieldsValid}
        />
      </div>
    </>
  );
};
