import { DetailsRow, IColumn, IconButton, ShimmeredDetailsList } from '@fluentui/react';
import { useAppDispatch, useAppSelector, getAllJobTitlesRequested, WizardEmptyInfo, selectIsOnMobile } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useIntl } from 'app/i18n';
import { Contact } from 'app/pages/my-audience/contacts';
import {
  EditCollaborationWizard,
  openEditCollaborationWizard,
  openRemoveCollaborationDialog,
  pageOfMediaOutletContactsRequested,
  RemoveCollaborationDialog,
  selectIsCollaborationsListOutdated,
  selectIsEditCollaborationWizardOpened,
  selectIsLoadingMediaOutletContacts,
  selectMediaOutletContacts,
  selectMediaOutletContactsTotalCount,
  selectSelectedMediaOutletId,
  setSelectedContactForEditing
} from 'app/pages/my-audience/media-outlet-profile';

import { Permission, useAuthContext } from 'auth';
import { useEffect } from 'react';
interface IContactWithActionsRow {
  key: string;
  profilePictureUrl: JSX.Element;
  name: string;
  role: string;
  emailAddress: string;
  phoneNumber: string;
  menu: JSX.Element;
}

export const ContactsTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);

  const selectedMediaOutletId = useAppSelector<string>(selectSelectedMediaOutletId);
  const contacts = useAppSelector<Contact[]>(selectMediaOutletContacts);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingMediaOutletContacts);
  const totalCountOfContacts = useAppSelector<number>(selectMediaOutletContactsTotalCount);
  const isEditCollaborationWizardOpened = useAppSelector<boolean>(selectIsEditCollaborationWizardOpened);
  const isCollaborationsListOutdated = useAppSelector<boolean>(selectIsCollaborationsListOutdated);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);

  useEffect(() => {
    if (isCollaborationsListOutdated) dispatch(pageOfMediaOutletContactsRequested());
  }, [dispatch, isCollaborationsListOutdated]);

  const columns: IColumn[] = [
    {
      key: 'contact-without-mediaoutlet-profilePictureUrl',
      fieldName: 'profilePictureUrl',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isRowHeader: false
    },
    {
      key: 'contact-without-mediaoutlet-name',
      fieldName: 'name',
      name: formatMessage({ id: 'mo-contact-table.name' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-mediaoutlet-role',
      fieldName: 'role',
      name: formatMessage({ id: 'mo-contact-table.job' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-mediaoutlet-emailAddress',
      fieldName: 'emailAddress',
      name: formatMessage({ id: 'mo-contact-table.email' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-mediaoutlet-phoneNumber',
      fieldName: 'phoneNumber',
      name: formatMessage({ id: 'mo-contact-table.phone' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-mediaoutlet-menu',
      fieldName: 'menu',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isRowHeader: false
    }
  ];

  const handleEditCollaborationMenuItem = () => {
    dispatch(getAllJobTitlesRequested());
    dispatch(openEditCollaborationWizard());
  };

  const handleRemoveCollaborationMenuItem = () => {
    dispatch(openRemoveCollaborationDialog());
  };

  const actionItems = {
    shouldFocusOnMount: true,
    className: 'panel-table-popup-list',
    items: [
      {
        key: 'edit-collaboration',
        text: formatMessage({ id: 'mo-contact-table.edit' }),
        iconProps: {
          iconName: 'Edit',
          className: ''
        },
        onClick: () => handleEditCollaborationMenuItem(),
        disabled: !hasPermissionToEdit
      },
      {
        key: 'remove-collaboration',
        iconProps: {
          iconName: 'Delete',
          className: 'danger'
        },
        text: formatMessage({ id: 'mo-contact-table.remove' }),
        onClick: () => handleRemoveCollaborationMenuItem(),
        disabled: !hasPermissionToEdit
      }
    ]
  };

  const menuIconProps = {
    iconName: 'MoreVertical',
    className: 'panel-table-popup-trigger'
  };

  const handleRowClick = (index: number) => {
    dispatch(setSelectedContactForEditing(contacts[index]));
  };

  const contactHasCollaborationWithEmptyId = (contact: Contact) => contact.collaborations.some((c) => !c.id);

  const contactRow: IContactWithActionsRow[] = contacts.map((contact) => {
    const mediaOutletCollaboration = contact.collaborations.find((cd) => cd.medium.id === selectedMediaOutletId);

    return {
      key: contact.id,
      profilePictureUrl: <ProfilePicture name={`${contact.firstName} ${contact.lastName}`} imageUrl={contact.profilePictureUrl} size="small" />,
      name: `${contact.firstName} ${contact.lastName}`,
      role: mediaOutletCollaboration?.jobTitle?.name,
      emailAddress: mediaOutletCollaboration?.emailAddress,
      phoneNumber: mediaOutletCollaboration?.landlinePhoneNumber?.isPrimary
        ? mediaOutletCollaboration?.landlinePhoneNumber?.value
        : mediaOutletCollaboration?.mobilePhoneNumber?.value,
      menu: isOnMobile ? <></> : <IconButton menuIconProps={menuIconProps} menuProps={actionItems} disabled={contactHasCollaborationWithEmptyId(contact)} />
    };
  });

  return (
    <>
      <div className="wizard-panel-table contact-table">
        <ShimmeredDetailsList
          className="panel-table"
          items={contactRow}
          enableShimmer={isLoadingContacts}
          columns={columns}
          checkboxVisibility={2}
          onRenderRow={(props) => (
            <div onClickCapture={() => handleRowClick(props.itemIndex)}>
              <DetailsRow {...props} />
            </div>
          )}
        />
      </div>
      <RemoveCollaborationDialog />
      {totalCountOfContacts === 0 && !isLoadingContacts && (
        <WizardEmptyInfo
          message={formatMessage({ id: 'no-contacts-selected-message.title' })}
          description={formatMessage({ id: 'no-contacts-selected-message.description' })}
        />
      )}
      {isEditCollaborationWizardOpened && <EditCollaborationWizard />}
    </>
  );
};
