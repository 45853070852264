import { useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { List } from 'app/pages/my-audience/lists';
import { selectSelectedList } from 'app/pages/my-audience/lists-profile';

export const ListOverviewChartInfo = () => {
  const { formatMessage } = useIntl();
  const list = useAppSelector<List>(selectSelectedList);

  return (
    <div className="list-overview-info chart-info">
      <div className="list-overview-info-column">
        <div className="list-overview-info-content">
          <span className="list-overview-info-label">{formatMessage({ id: 'list-profile-overview.number-of-contacts' })}:</span>
          <span className="list-overview-info-description">{list.numberOfContacts}</span>
        </div>
      </div>
      <div className="list-overview-info-column">
        <div className="list-overview-info-content">
          <span className="list-overview-info-label">{formatMessage({ id: 'list-profile-overview.number-of-mediums' })}:</span>
          <span className="list-overview-info-description">{list.mediumNames?.length}</span>
        </div>
      </div>
    </div>
  );
};
