import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { isTextInputValid, maximumCompanyNameLength, regAtLeastOneCharacter, useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { ControlledInput, ControlledTextAreaInput } from 'app/pages/my-audience/common';
import {
  selectCompanyGeneralInfoToAdd,
  setCompanyGeneralInfoToAdd,
  setCreateGeneralInfo as setCreateCompanyGeneralInfo,
  setIsCreateCompanyGeneralInfoStepValid
} from 'app/pages/my-audience/contact-profile';
import { FormattedMessage, useIntl } from 'app/i18n';

export type CreateCompanyGeneralInfoForm = {
  name: string;
  categoryName: string;
  parentCompany: string;
  comment: string;
};

export const CreateCompanyGeneralInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const companyGeneralInfoToAdd = useAppSelector(selectCompanyGeneralInfoToAdd);

  const { handleSubmit, control, watch } = useForm<CreateCompanyGeneralInfoForm, any>({
    defaultValues: {
      name: companyGeneralInfoToAdd.name,
      categoryName: companyGeneralInfoToAdd.categoryName,
      parentCompany: companyGeneralInfoToAdd.parentCompany,
      comment: companyGeneralInfoToAdd.comment
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const watchName = watch('name');
  const isGeneralInfoStepValid = isTextInputValid({ inputValue: watchName, maxInputLength: maximumCompanyNameLength });

  useEffect(() => {
    dispatch(setIsCreateCompanyGeneralInfoStepValid(isGeneralInfoStepValid));
  }, [dispatch, isGeneralInfoStepValid]);

  const submitForm = useMemo(
    () =>
      handleSubmit(
        (data) => {
          const newCompanyProfileInfo = setCreateCompanyGeneralInfo(data);
          dispatch(setCompanyGeneralInfoToAdd(newCompanyProfileInfo));
        },
        (error) => {
          Sentry.setExtra('Path', CreateCompanyGeneralInfo.name);
          Sentry.captureException(error);
        }
      ),
    [dispatch, handleSubmit]
  );

  useEffect(() => {
    return () => {
      submitForm();
    };
  }, [submitForm]);

  return (
    <div className="wizard-panel-form-wrap">
      <WizardIntro title={formatMessage({ id: 'add-medium-wizard-steps.general-information' })} description="" />
      <div className="wizard-panel-form">
        <div className="wizard-panel-input">
          <ControlledInput
            label={
              <span className="required-label">
                <FormattedMessage id={'company-labels.name'} />
                <span>*</span>
              </span>
            }
            control={control}
            name="name"
            rules={{
              pattern: {
                value: regAtLeastOneCharacter,
                message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: formatMessage({ id: 'labels.company' }) })
              },
              maxLength: {
                message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumCompanyNameLength }),
                value: maximumCompanyNameLength
              },
              required: formatMessage({ id: 'error-messages.required-name' }, { entity: formatMessage({ id: 'labels.company' }) })
            }}
          />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput name="categoryName" label={formatMessage({ id: 'company-labels.category' })} control={control} />
        </div>
        <div className="wizard-panel-input">
          <ControlledInput name="parentCompany" label={formatMessage({ id: 'company-labels.parent-company' })} control={control} />
        </div>
        <div className="wizard-panel-textarea">
          <ControlledTextAreaInput name="comment" label={formatMessage({ id: 'company-labels.comment' })} control={control} />
        </div>
      </div>
    </div>
  );
};
