import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { AvatarDetailsInput, AvatarDetailsWithSavedSearchId, MessageData, ProfilePicture, safe, safelyHandleError, setAlertData, Tag } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { fetchTagsByEntityType } from 'app/common/services/common.service';
import { AadContact, ExportContactCsvHeader, fetchBulkContactAvatarDetails, Group, PageOfContacts } from 'app/pages/my-audience/contacts';
import { fetchPagedContactSavedSearches } from 'app/pages/my-audience/contacts-saved-searches';
import {
  LinkedSavedSearch,
  List,
  ListContact,
  PageOfListContacts,
  updatedProfilePictureSearchResults,
  updateList,
  updateListSearchResults,
  updateListSearchResultsContactsCount
} from 'app/pages/my-audience/lists';
import {
  addAadContactsToListRequested,
  addContactsFromExistingListsToListRequested,
  addContactsSavedSearchesToListRequested,
  addContactsToListRequested,
  addedAadContactsToList,
  addedContactsFromExistingListsToList,
  addedContactsSavedSearchesToList,
  addedContactsToList,
  allTagsReceived,
  closeAddContactFromCompanyWizard,
  closeAddContactFromListWizard,
  closeAddContactsWizard,
  contactInListUpdated,
  contactsByListIdReceived,
  contactsSavedSearchesReceived,
  exportContactsReceived,
  exportContactsRequested,
  exportContactsVCardsReceived,
  exportContactsVCardsRequested,
  firstPageOfWizardContactsReceived,
  firstPageOfWizardContactsRequested,
  getAllTagsRequested,
  getContactsByListIdRequested,
  pageOfContactsSavedSearchesRequested,
  getListsByNameRequested,
  getPickerSuggestionsRequested,
  getSelectedListRequested,
  getSelectedListSendingOverviewsRequested,
  listsByNameReceived,
  nextPageOfWizardContactsReceived,
  nextPageOfWizardContactsRequested,
  numberOfDesynchronizedContactsReceived,
  numberOfDesynchronizedContactsRequested,
  pageOfListContactsReceived,
  pageOfListContactsRequested,
  pickerSuggestionsReceived,
  profilePictureUpdated,
  removeContactsFromListRequested,
  removeContactsSavedSearchesFromListRequested,
  removedContactsFromList,
  removedContactsSavedSearchesFromList,
  removeProfilePictureRequested,
  selectAADContactsToAddToList,
  selectAADSearchText,
  selectContactsIdsToRemoveFromList,
  selectContactsSavedSearchesIdsToRemoveFromList,
  selectContactsSavedSearchesPageNumber,
  selectContactsSavedSearchesPageSize,
  selectContactsSavedSearchesSearchText,
  selectContactsSavedSearchesToAddToList,
  selectContactsToAddToList,
  selectCsvHeader,
  selectedListReceived,
  selectImageToUpload,
  selectLinkedSavedSearchToUpdate,
  selectListContactsPageNumber,
  selectListContactsPageSize,
  selectListContactsSearchText,
  selectListContactToUpdate,
  selectListSearchText,
  selectListToUpdate,
  selectSelectedLinkedSavedSearches,
  selectSelectedListId,
  selectSelectedListInWizardId,
  selectWizardContactsPageNumber,
  selectWizardContactsPageSize,
  selectWizardContactsSearchText,
  setExportedContactsFlag,
  setListContactsQueryParams,
  setTotalCountOfWizardContacts,
  updateContactInListRequested,
  updatedLinkedSavedSearch,
  updatedList,
  updateLinkedSavedSearchRequested,
  updateListRequested,
  updateProfilePictureRequested,
  refreshLinkedSavedSearchesRequested,
  getSelectedListSendingOverviewsReceived,
  setLoadingAddingAadContactsToList,
  mediumNamesByListIdRequested,
  mediumNamesByListIdReceived,
  getLinkedSavedSearchesByListIdRequested,
  linkedSavedSearchesByListIdReceived,
  closeProfilePanel,
  setSavedSearchesTotalCount
} from 'app/pages/my-audience/lists-profile';
import {
  addAadContactsToList,
  addContactsSavedSearchesToList,
  addContactsToList,
  createContactVCards,
  exportContacts,
  fetchAADContactsBySearchText,
  fetchAADGroupsBySearchText,
  fetchContactsByListId,
  fetchContactsBySearchTextAndNotContainedInList,
  fetchLinkedSavedSearchesByListId,
  fetchList,
  fetchListContactsByListIdAndSearchText,
  fetchListsByName,
  fetchMediumNamesByListId,
  fetchNumberOfDesynchronizedContacts,
  fetchSendingOverviewByListId,
  refreshLinkedSavedSearches,
  removeContactsFromList,
  removeContactsSavedSearchesFromList,
  removeProfilePicture,
  updateContactInList,
  updateContactsSavedSearch,
  updateProfilePicture
} from 'app/pages/my-audience/lists-profile/services/list-profile.service';
import { PageOfSavedSearches, SavedSearch } from 'app/pages/my-audience/saved-searches';
import { SendingOverview } from 'app/common/graphql/generated/graphql-gateway';

function* updateListFlow() {
  const listToUpdate: List = yield select(selectListToUpdate);

  const list: List = yield call(updateList, client, listToUpdate.id, listToUpdate);
  yield put(updatedList(list));
  yield put(updateListSearchResults(list));
}

function* updateListFlowHandleError() {
  yield put(closeProfilePanel());
}

export function* updateListRequestWatcher() {
  yield takeEvery(updateListRequested.type, safelyHandleError({ performAction: updateListFlow, onCatch: updateListFlowHandleError }));
}

function* fetchAllListTagsFlow() {
  const allTags: Tag[] = yield call(fetchTagsByEntityType, client, 'List');
  yield put(allTagsReceived(allTags));
}

export function* getAllTagsRequestedWatcher() {
  yield takeEvery(getAllTagsRequested.type, safe(fetchAllListTagsFlow));
}

function* updateProfilePictureFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const imageToUpload: ProfilePicture = yield select(selectImageToUpload);

  const imageUrl: string = yield call(updateProfilePicture, client, selectedListId, imageToUpload);
  yield put(profilePictureUpdated(imageUrl));
  yield put(updatedProfilePictureSearchResults({ listId: selectedListId, imageUrl: imageUrl }));
}

export function* updateListProfilePictureRequestedWatcher() {
  yield takeEvery(updateProfilePictureRequested.type, safe(updateProfilePictureFlow));
}

function* removeProfilePictureFlow() {
  const selectedListId: string = yield select(selectSelectedListId);

  yield call(removeProfilePicture, client, selectedListId);
  yield put(profilePictureUpdated(''));
  yield put(updatedProfilePictureSearchResults({ listId: selectedListId, imageUrl: '' }));
}

export function* removeListProfilePictureRequestedWatcher() {
  yield takeEvery(removeProfilePictureRequested.type, safe(removeProfilePictureFlow));
}

function* fetchSelectedListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const selectedList: List = yield call(fetchList, client, selectedListId);
  yield put(selectedListReceived(selectedList));
  yield put(mediumNamesByListIdRequested(selectedListId));
  yield put(numberOfDesynchronizedContactsRequested());
  yield put(getLinkedSavedSearchesByListIdRequested(selectedListId));
}

export function* getSelectedListRequestedWatcher() {
  yield takeEvery(getSelectedListRequested.type, safe(fetchSelectedListFlow));
}

function* fetchSelectedListSendingActivitiesFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const sendingOverviews: SendingOverview[] = yield call(fetchSendingOverviewByListId, client, selectedListId);
  yield put(getSelectedListSendingOverviewsReceived(sendingOverviews));
}

export function* getSelectedListSendingActivitiesRequestedWatcher() {
  yield takeEvery(getSelectedListSendingOverviewsRequested.type, safe(fetchSelectedListSendingActivitiesFlow));
}

function* fetchLinkedSavedSearchesByListIdFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const linkedSavedSearches: LinkedSavedSearch[] = yield call(fetchLinkedSavedSearchesByListId, client, selectedListId);
  yield put(linkedSavedSearchesByListIdReceived(linkedSavedSearches));
}

export function* getLinkedSavedSearchesByListIdRequestedWatcher() {
  yield takeEvery(getLinkedSavedSearchesByListIdRequested.type, safe(fetchLinkedSavedSearchesByListIdFlow));
}

function* fetchListContactsFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const pageNumber: number = yield select(selectListContactsPageNumber);
  const pageSize: number = yield select(selectListContactsPageSize);
  const searchText: string = yield select(selectListContactsSearchText);

  const pageOfContacts: PageOfListContacts = yield call(fetchListContactsByListIdAndSearchText, client, pageNumber, pageSize, searchText, selectedListId);
  yield put(pageOfListContactsReceived(pageOfContacts));
  yield put(updateListSearchResultsContactsCount({ listId: selectedListId, contactsCount: pageOfContacts.totalCount }));
}

export function* getListContactsRequestedWatcher() {
  yield takeEvery(pageOfListContactsRequested.type, safe(fetchListContactsFlow));
}

function* fetchWizardContactsFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const pageNumber: number = yield select(selectWizardContactsPageNumber);
  const pageSize: number = yield select(selectWizardContactsPageSize);
  const searchText: string = yield select(selectWizardContactsSearchText);

  const pageOfContacts: PageOfContacts = yield call(fetchContactsBySearchTextAndNotContainedInList, client, pageNumber, pageSize, searchText, selectedListId);
  if (pageNumber === 1) {
    yield put(firstPageOfWizardContactsReceived(pageOfContacts.contacts));
  } else {
    yield put(nextPageOfWizardContactsReceived(pageOfContacts.contacts));
  }

  yield put(setTotalCountOfWizardContacts(pageOfContacts.totalCount));
}

export function* getWizardContactsRequestedWatcher() {
  yield takeEvery(firstPageOfWizardContactsRequested.type, safe(fetchWizardContactsFlow));
  yield takeEvery(nextPageOfWizardContactsRequested.type, safe(fetchWizardContactsFlow));
}

function* addContactsToListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const contactsToAdd: ListContact[] = yield select(selectContactsToAddToList);
  const pageNumber: number = yield select(selectListContactsPageNumber);
  const pageSize: number = yield select(selectListContactsPageSize);
  const searchText: string = yield select(selectListContactsSearchText);

  yield call(addContactsToList, client, selectedListId, contactsToAdd);
  const pageOfContacts: PageOfListContacts = yield call(fetchListContactsByListIdAndSearchText, client, pageNumber, pageSize, searchText, selectedListId);

  yield put(pageOfListContactsReceived(pageOfContacts));
  yield put(addedContactsToList());
  yield put(closeAddContactsWizard());
  yield put(numberOfDesynchronizedContactsRequested());
  yield put(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfListContactsRequested());
}

export function* addContactsToListRequestWatcher() {
  yield takeEvery(addContactsToListRequested.type, safe(addContactsToListFlow));
}

function* removeContactsFromListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const contactsIdsToRemove: string[] = yield select(selectContactsIdsToRemoveFromList);

  yield call(removeContactsFromList, client, selectedListId, contactsIdsToRemove);
  yield put(removedContactsFromList());
  yield put(numberOfDesynchronizedContactsRequested());
  yield put(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfListContactsRequested());
  yield put(setAlertData(new MessageData('alert-contacts-removed-from-list-counter', { counter: contactsIdsToRemove.length })));
}

export function* removeContactsFromListRequestWatcher() {
  yield takeEvery(removeContactsFromListRequested.type, safe(removeContactsFromListFlow));
}

function* fetchPickerSuggestionsFlow() {
  const aadSearchText: string = yield select(selectAADSearchText);

  const contacts: AadContact[] = yield call(fetchAADContactsBySearchText, client, aadSearchText);
  const groups: Group[] = yield call(fetchAADGroupsBySearchText, client, aadSearchText);
  yield put(pickerSuggestionsReceived({ contacts: contacts, groups: groups }));
}

export function* getPickerSuggestionsRequestWatcher() {
  yield takeLatest(getPickerSuggestionsRequested.type, safe(fetchPickerSuggestionsFlow));
}

function* addAadContactsToListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const aadContactsToAdd: AadContact[] = yield select(selectAADContactsToAddToList);

  yield call(addAadContactsToList, client, selectedListId, aadContactsToAdd);

  yield put(addedAadContactsToList());
  yield put(closeAddContactFromCompanyWizard());
  yield put(numberOfDesynchronizedContactsRequested());
  yield put(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfListContactsRequested());
}

function* resetAddingAadContactsLoading() {
  yield put(setLoadingAddingAadContactsToList(false));
}

export function* addAadContactsToListRequestWatcher() {
  yield takeEvery(addAadContactsToListRequested.type, safelyHandleError({ performAction: addAadContactsToListFlow, onFinally: resetAddingAadContactsLoading }));
}

function* fetchListsByNameFlow() {
  const listSearchText: string = yield select(selectListSearchText);
  const lists: List[] = yield call(fetchListsByName, client, listSearchText);
  yield put(listsByNameReceived(lists));
}

export function* getListsByNameRequestedWatcher() {
  yield takeLatest(getListsByNameRequested.type, safe(fetchListsByNameFlow));
}

function* addContactsFromExistingListsToListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const contactsToAdd: ListContact[] = yield select(selectContactsToAddToList);

  yield call(addContactsToList, client, selectedListId, contactsToAdd);

  yield put(addedContactsFromExistingListsToList());
  yield put(closeAddContactFromListWizard());
  yield put(numberOfDesynchronizedContactsRequested());
  yield put(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfListContactsRequested());
}

export function* addContactsFromExistingListsToListRequestWatcher() {
  yield takeEvery(addContactsFromExistingListsToListRequested.type, safe(addContactsFromExistingListsToListFlow));
}

function* addContactsSavedSearchesToListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const contactsSavedSearchesToAdd: SavedSearch[] = yield select(selectContactsSavedSearchesToAddToList);

  const list: List = yield call(
    addContactsSavedSearchesToList,
    client,
    selectedListId,
    contactsSavedSearchesToAdd.map((css) => css.id)
  );

  yield put(addedContactsSavedSearchesToList(list));
  yield put(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfListContactsRequested());
}

export function* addContactsSavedSearchesToListRequestWatcher() {
  yield takeEvery(addContactsSavedSearchesToListRequested.type, safe(addContactsSavedSearchesToListFlow));
}

function* removeContactsSavedSearchesFromListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const contactsSavedSearchesIdsToRemove: string[] = yield select(selectContactsSavedSearchesIdsToRemoveFromList);

  const list: List = yield call(removeContactsSavedSearchesFromList, client, selectedListId, contactsSavedSearchesIdsToRemove);
  yield put(removedContactsSavedSearchesFromList(list));
  yield put(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfListContactsRequested());
}

export function* removeContactsSavedSearchesFromListRequestWatcher() {
  yield takeEvery(removeContactsSavedSearchesFromListRequested.type, safe(removeContactsSavedSearchesFromListFlow));
}

function* updateContactsSavedSearchFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const contactsSavedSearchToUpdate: LinkedSavedSearch = yield select(selectLinkedSavedSearchToUpdate);

  const list: List = yield call(
    updateContactsSavedSearch,
    client,
    selectedListId,
    contactsSavedSearchToUpdate.savedSearch.id,
    contactsSavedSearchToUpdate.shouldAutoUpdate
  );
  yield put(updatedLinkedSavedSearch(list));
}

export function* updateContactsSavedSearchRequestedWatcher() {
  yield takeEvery(updateLinkedSavedSearchRequested.type, safe(updateContactsSavedSearchFlow));
}

function* refreshLinkedSavedSearchFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const selectedSavedSearches: LinkedSavedSearch[] = yield select(selectSelectedLinkedSavedSearches);

  yield call(
    refreshLinkedSavedSearches,
    client,
    selectedListId,
    selectedSavedSearches.map((ss) => ss.savedSearch.id)
  );

  yield put(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
  yield put(pageOfListContactsRequested());
}

export function* refreshLinkedSavedSearchRequestedWatcher() {
  yield takeEvery(refreshLinkedSavedSearchesRequested.type, safe(refreshLinkedSavedSearchFlow));
}

function* updateContactInListFlow() {
  const contactInListToUpdate: ListContact = yield select(selectListContactToUpdate);
  const updatedContactInList: ListContact = yield call(updateContactInList, client, contactInListToUpdate.id, contactInListToUpdate);

  yield put(contactInListUpdated(updatedContactInList));
}

export function* updateContactInListRequestedWatcher() {
  yield takeEvery(updateContactInListRequested.type, safe(updateContactInListFlow));
}

function* exportContactsFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const csvHeader: ExportContactCsvHeader = yield select(selectCsvHeader);
  const exportedContactsByteArray: Uint8Array = yield call(exportContacts, client, selectedListId, csvHeader);

  yield put(exportContactsReceived(exportedContactsByteArray));
  yield put(setExportedContactsFlag(true));
}

export function* exportContactsRequestedWatcher() {
  yield takeEvery(exportContactsRequested.type, safe(exportContactsFlow));
}

function* exportContactsVCardsFlow() {
  const selectedListId: string = yield select(selectSelectedListId);
  const exportedContactsByteArray: Uint8Array = yield call(createContactVCards, client, selectedListId);

  yield put(exportContactsVCardsReceived(exportedContactsByteArray));
  yield put(setExportedContactsFlag(true));
}

export function* exportContactsVCardsRequestedWatcher() {
  yield takeEvery(exportContactsVCardsRequested.type, safe(exportContactsVCardsFlow));
}

function* fetchContactsSavedSearchesFlow() {
  const searchText: string = yield select(selectContactsSavedSearchesSearchText);
  const pageNumber: number = yield select(selectContactsSavedSearchesPageNumber);
  const pageSize: number = yield select(selectContactsSavedSearchesPageSize);

  const pageOfSavedSearches: PageOfSavedSearches = yield call(fetchPagedContactSavedSearches, client, (pageNumber - 1) * pageSize, pageSize, searchText, []);

  const savedSearches = pageOfSavedSearches.savedSearches;

  const contactsAvatarDetailsInputs = savedSearches.map(
    (savedSearch) => new AvatarDetailsInput(savedSearch.id, savedSearch.searchText, savedSearch.filterItems)
  );
  const contactsAvatarDetails: AvatarDetailsWithSavedSearchId[] = yield call(fetchBulkContactAvatarDetails, client, contactsAvatarDetailsInputs);

  contactsAvatarDetails.forEach((contactAvatarDetails) => {
    const savedSearch = savedSearches.find((s) => s.id === contactAvatarDetails.savedSearchId);
    savedSearch.avatarDetails = contactAvatarDetails.avatarDetails;
    savedSearch.resultsLength = contactAvatarDetails.totalCount;
  });

  yield put(contactsSavedSearchesReceived(savedSearches));
  yield put(setSavedSearchesTotalCount(pageOfSavedSearches.totalCount));
}

export function* getContactsSavedSearchesRequestedWatcher() {
  yield takeLatest(pageOfContactsSavedSearchesRequested.type, safe(fetchContactsSavedSearchesFlow));
}

function* fetchNumberOfDesynchronizedContactsInListFlow() {
  const selectedListId: string = yield select(selectSelectedListId);

  const numberOfContacts: number = yield call(fetchNumberOfDesynchronizedContacts, client, selectedListId);

  yield put(numberOfDesynchronizedContactsReceived(numberOfContacts));
}

export function* getNumberOfDesynchronizedContactsInListRequestedWatcher() {
  yield takeEvery(numberOfDesynchronizedContactsRequested.type, safe(fetchNumberOfDesynchronizedContactsInListFlow));
}

function* fetchMediumNamesByListIdFlow() {
  const listId: string = yield select(selectSelectedListId);
  const mediumNames: string[] = yield call(fetchMediumNamesByListId, client, listId);

  yield put(mediumNamesByListIdReceived({ listId, mediumNames }));
}

export function* getMediumNamesByListIdRequestedWatcher() {
  yield takeLatest(mediumNamesByListIdRequested.type, safe(fetchMediumNamesByListIdFlow));
}

export function* getContactsByListIdsRequestWatcher() {
  yield takeEvery(getContactsByListIdRequested.type, safe(fetchContactsByListIdFlow));
}

function* fetchContactsByListIdFlow() {
  const listId: string = yield select(selectSelectedListInWizardId);

  const contacts: ListContact[] = yield call(fetchContactsByListId, client, listId);

  yield put(contactsByListIdReceived(contacts));
}
