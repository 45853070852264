import { DialogType, Dialog, DialogFooter } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import {
  closeRemoveComapnyDialog,
  removeCollaborationRequested,
  selectIsRemoveCompanyDialogVisible,
  selectCollaborationToRemove
} from 'app/pages/my-audience/contact-profile';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const RemoveCompanyDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isRemoveCompanyDialogVisible = useAppSelector<boolean>(selectIsRemoveCompanyDialogVisible);
  const contactDataToRemove = useAppSelector<Collaboration>(selectCollaborationToRemove);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'remove-collaboration-dialog.title' }),
    subText: formatMessage({ id: 'remove-collaboration-dialog.text' }, { mediumName: contactDataToRemove?.medium?.name })
  };

  const dialogModalProps = {
    isBlocking: true,
    className: 'dialog-wrap'
  };

  const handleRemoveButtonClick = () => {
    dispatch(removeCollaborationRequested());
    dispatch(closeRemoveComapnyDialog());
  };

  const handleDissmisButtonClick = () => {
    dispatch(closeRemoveComapnyDialog());
  };

  return (
    <Dialog hidden={!isRemoveCompanyDialogVisible} onDismiss={handleDissmisButtonClick} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="dialog-footer">
        <DialogFooter>
          <Button tinted onClick={handleDissmisButtonClick} content={formatMessage({ id: 'buttons.cancel' })} />
          <Button primary onClick={handleRemoveButtonClick} content={formatMessage({ id: 'buttons.remove' })} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
