import axios from 'axios';
import Bee from '@mailupinc/bee-plugin';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createContact, FilterItem, Tag } from 'app/common';
import { createRecipient, createSending } from 'app/common/factories/sendings.factory';
import {
  AddRecipientsFromListsToSendingDocument,
  AddRecipientsFromListsToSendingMutation,
  AddRecipientsToSendingDocument,
  AddRecipientsToSendingMutation,
  AddTagToSendingsDocument,
  AddTagToSendingsMutation,
  FetchSendersByUserAadIdDocument,
  FetchSendersByUserAadIdQuery,
  FetchSendingByIdDocument,
  FetchSendingByIdQuery,
  RemoveRecipientsFromSendingDocument,
  RemoveRecipientsFromSendingMutation,
  RemoveTagFromSendingsDocument,
  RemoveTagFromSendingsMutation,
  ScheduleSendingDistributionDocument,
  ScheduleSendingDistributionMutation,
  SendSendingDocument,
  SendSendingMutation,
  UpdateSendingRecipientContactDataDocument,
  UpdateSendingRecipientContactDataMutation,
  Recipient as GqlRecipient,
  ContactsSending as GqlSending,
  FetchRecipientsBySendingIdAndContactNameQuery,
  FetchRecipientsBySendingIdAndContactNameDocument,
  RecipientInput,
  RecipientFromListInput,
  FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery,
  FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsDocument,
  FetchSendingLogsBySendingIdQuery,
  FetchSendingLogsBySendingIdDocument,
  PageOfSendingLog,
  FetchSendingsForEditorByQueryParamsQuery,
  FetchSendingsForEditorByQueryParamsDocument,
  IsOpenAiAddonEnabledQuery,
  IsOpenAiAddonEnabledDocument,
  SendingAudienceBySendingIdQuery,
  SendingAudience,
  SendingAudienceBySendingIdDocument,
  ContactsTagInput,
  SendingOverviewBySendingIdQuery,
  SendingOverviewBySendingIdDocument,
  SendingOverview,
  AddSendingFromTemplateMutation,
  AddSendingFromTemplateDocument,
  AiContentReport,
  AddHarmfulContentReportMutation,
  AddHarmfulContentReportDocument,
  AadContactInput,
  AddAadContactsToSendingDocument,
  AddAadContactsToSendingMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { DefaultSendingsSortingInput, PageOfSendings, Recipient, RecipientFromList, Sender, Sending } from 'app/pages/my-activities/sendings';
import { AadContact, Contact } from 'app/pages/my-audience/contacts';
import { PageOfRecipients } from 'app/pages/my-activities/sending-wizard';

export const fetchSendersByUserAadEmailAddress = async (client: ApolloClient<NormalizedCacheObject>): Promise<Sender[]> => {
  const response = await client.query<FetchSendersByUserAadIdQuery>({
    query: FetchSendersByUserAadIdDocument
  });

  return response.data.sendersBySharedWithUserAadObjectId.map((sender) => new Sender(sender.id, sender.emailAddress, sender.displayName, sender.userId));
};

export const fetchRecipientsBySendingIdAndContactName = async (
  client: ApolloClient<NormalizedCacheObject>,
  sendingId: string,
  contactName: string,
  skip: number,
  take: number
): Promise<PageOfRecipients> => {
  const response = await client.query<FetchRecipientsBySendingIdAndContactNameQuery>({
    query: FetchRecipientsBySendingIdAndContactNameDocument,
    variables: {
      sendingId,
      contactName,
      skip,
      take
    }
  });

  return {
    recipients: response.data.recipientsBySendingIdAndContactName.activityParticipants.map((recipient) => createRecipient(recipient as GqlRecipient)),
    totalCountOfRecipients: response.data.recipientsBySendingIdAndContactName.totalCount
  };
};

export const fetchContactsByNameOrEmailAddressNotContainedInSendingRecipients = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  sendingId: string,
  take: number
): Promise<Contact[]> => {
  const response = await client.query<FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsQuery>({
    query: FetchContactsByNameOrEmailAddressAndNotContainedInSendingParticipantsDocument,
    variables: {
      searchText: searchText,
      sendingId,
      take
    }
  });

  return response.data.contactsByNameOrEmailAddressAndNotContainedInSendingParticipants.map(createContact);
};

export const addRecipientsToSending = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string, recipients: Recipient[]): Promise<Recipient[]> => {
  const recipientsInput: RecipientInput[] = recipients.map((recipient) => ({
    id: '',
    sendingId: recipient.sendingId,
    contact: recipient.contact?.id
      ? {
          id: recipient.contact.id,
          lastName: recipient.contact.lastName,
          firstName: recipient.contact.firstName,
          tags: [],
          topics: [],
          mediaResorts: [],
          collaborations: [],
          comments: [],
          blacklists: [],
          preferredLanguages: []
        }
      : null,
    collaborationId: recipient.collaborationId,
    emailAddress: recipient.emailAddress
  }));

  const response = await client.query<AddRecipientsToSendingMutation>({
    query: AddRecipientsToSendingDocument,
    variables: {
      sendingId: sendingId,
      recipients: recipientsInput
    }
  });

  return response.data.addRecipientsToSending.map((recipient) => createRecipient(recipient));
};

export const addSendingFromTemplate = async (client: ApolloClient<NormalizedCacheObject>, templateId: string, defaultSendingName: string): Promise<Sending> => {
  const response = await client.mutate<AddSendingFromTemplateMutation>({
    mutation: AddSendingFromTemplateDocument,
    variables: {
      templateId,
      sendingName: defaultSendingName
    }
  });

  return createSending(response.data.addSendingFromTemplate);
};

export const removeRecipientsFromSending = async (
  client: ApolloClient<NormalizedCacheObject>,
  sendingId: string,
  recipientsIds: string[]
): Promise<string[]> => {
  const response = await client.query<RemoveRecipientsFromSendingMutation>({
    query: RemoveRecipientsFromSendingDocument,
    variables: {
      sendingId: sendingId,
      recipientsIds: recipientsIds
    }
  });

  return response.data.removeRecipientsFromSending;
};

export const updateRecipientContactData = async (
  client: ApolloClient<NormalizedCacheObject>,
  sendingId: string,
  recipientId: string,
  collaborationId: string,
  emailAddress: string
): Promise<Recipient> => {
  const response = await client.query<UpdateSendingRecipientContactDataMutation>({
    query: UpdateSendingRecipientContactDataDocument,
    variables: {
      sendingId: sendingId,
      recipientId: recipientId,
      collaborationId: collaborationId,
      emailAddress: emailAddress
    }
  });

  return createRecipient(response.data.updateSendingRecipientContactData);
};

export const fetchBeeToken = async () => {
  const bee = new Bee();
  return await bee.getToken(process.env.REACT_APP_BEE_CLIENT_ID, process.env.REACT_APP_BEE_CLIENT_SECRET);
};

export const convertTemplateHtmlToJpeg = async (html: string): Promise<any> => {
  const response = await axios.post(`${process.env.REACT_APP_CONTACTS_URL}/api/beefree`, {
    Html: html,
    Width: 800,
    Height: 800,
    FileType: 'jpg'
  });

  return response.data;
};

export const addRecipientsFromListToSending = async (
  client: ApolloClient<NormalizedCacheObject>,
  sendingId: string,
  recipients: RecipientFromList[]
): Promise<Recipient[]> => {
  const recipientsInput: RecipientFromListInput[] = recipients.map((recipient) => ({
    id: '',
    sendingId: recipient.sendingId,
    listId: recipient.listId,
    contact: {
      id: recipient.contact.id,
      lastName: recipient.contact.lastName,
      firstName: recipient.contact.firstName,
      tags: [],
      topics: [],
      mediaResorts: [],
      collaborations: [],
      comments: [],
      blacklists: [],
      preferredLanguages: []
    },
    collaborationId: recipient.collaborationId,
    emailAddress: recipient.emailAddress
  }));

  const response = await client.query<AddRecipientsFromListsToSendingMutation>({
    query: AddRecipientsFromListsToSendingDocument,
    variables: {
      sendingId: sendingId,
      recipientsFromLists: recipientsInput
    }
  });

  return response.data.addRecipientsFromListsToSending.map((recipient) => createRecipient(recipient));
};

export const fetchSendingById = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<Sending> => {
  const response = await client.query<FetchSendingByIdQuery>({
    query: FetchSendingByIdDocument,
    variables: {
      sendingId: id
    }
  });

  return createSending(response.data.contactSendingById as GqlSending);
};

export const fetchSendingLogsBySendingId = async (
  client: ApolloClient<NormalizedCacheObject>,
  sendingId: string,
  skip: number,
  take: number
): Promise<PageOfSendingLog> => {
  const response = await client.query<FetchSendingLogsBySendingIdQuery>({
    query: FetchSendingLogsBySendingIdDocument,
    variables: {
      sendingId,
      skip,
      take
    }
  });

  return response.data.sendingLogs;
};

export const addTagToSending = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string, tag: Tag): Promise<Tag> => {
  const input: ContactsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<AddTagToSendingsMutation>({
    mutation: AddTagToSendingsDocument,
    variables: {
      sendingIds: [sendingId],
      input
    }
  });

  return new Tag(response.data.addTagToSendings.id, response.data.addTagToSendings.name, '');
};

export const removeTagFromSending = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string, tag: Tag): Promise<Tag> => {
  const input: ContactsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<RemoveTagFromSendingsMutation>({
    mutation: RemoveTagFromSendingsDocument,
    variables: {
      sendingIds: [sendingId],
      input
    }
  });

  return new Tag(response.data.removeTagFromSendings.id, response.data.removeTagFromSendings.name, '');
};

export const sendSending = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string): Promise<Sending> => {
  const response = await client.mutate<SendSendingMutation>({
    mutation: SendSendingDocument,
    variables: {
      sendingId: sendingId
    }
  });

  return createSending(response.data.sendSending as GqlSending);
};

export const scheduleSending = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string, distributionDateTime: Date): Promise<Sending> => {
  const response = await client.mutate<ScheduleSendingDistributionMutation>({
    mutation: ScheduleSendingDistributionDocument,
    variables: {
      sendingId: sendingId,
      distributionDateTime: distributionDateTime
    }
  });

  return createSending(response.data.scheduleSendingDistribution as GqlSending);
};

export const fetchSendingsForEditorByQueryParams = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  filterItems: FilterItem[],
  searchText: string
): Promise<PageOfSendings> => {
  const response = await client.query<FetchSendingsForEditorByQueryParamsQuery>({
    query: FetchSendingsForEditorByQueryParamsDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      filterItems: filterItems,
      searchText: searchText,
      sortingInput: new DefaultSendingsSortingInput()
    }
  });

  return new PageOfSendings(
    response.data.sendings.totalCount,
    response.data.sendings.sendings.map((sending) => {
      return createSending(sending as GqlSending);
    })
  );
};

export const isOpenAiAddonEnabled = async (client: ApolloClient<NormalizedCacheObject>): Promise<boolean> => {
  const response = await client.query<IsOpenAiAddonEnabledQuery>({
    query: IsOpenAiAddonEnabledDocument
  });

  return response.data.isOpenAiAddonEnabled;
};

export const getSendingsAudience = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string, pageSize: number): Promise<SendingAudience> => {
  const response = await client.query<SendingAudienceBySendingIdQuery>({
    query: SendingAudienceBySendingIdDocument,
    variables: {
      sendingId,
      pageSize
    }
  });

  return response.data.sendingAudienceById;
};

export const getSendingOverviewBySendingId = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string): Promise<SendingOverview> => {
  const response = await client.query<SendingOverviewBySendingIdQuery>({
    query: SendingOverviewBySendingIdDocument,
    variables: {
      sendingId
    }
  });

  return response.data.sendingOverviewBySendingId;
};

export const addAiContentReport = async (client: ApolloClient<NormalizedCacheObject>, message: string): Promise<AiContentReport> => {
  const response = await client.query<AddHarmfulContentReportMutation>({
    query: AddHarmfulContentReportDocument,
    variables: {
      message
    }
  });

  return response.data.addHarmfulContentReport;
};

export const addAadContactsToSending = async (client: ApolloClient<NormalizedCacheObject>, sendingId: string, contacts: AadContact[]): Promise<string[]> => {
  const aadContactsInput: AadContactInput[] = contacts.map(({ objectId, firstName, lastName, email }: AadContact) => {
    const input: AadContactInput = {
      objectId,
      firstName,
      lastName,
      email
    };
    return input;
  });

  const response = await client.query<AddAadContactsToSendingMutation>({
    query: AddAadContactsToSendingDocument,
    variables: {
      sendingId: sendingId,
      input: aadContactsInput
    }
  });

  return response.data.addAadContactsToSending;
};
