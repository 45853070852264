import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { printMultipleValues, useAppDispatch, useAppSelector } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { CollaborationDetails } from 'app/pages/my-audience/contacts';
import {
  ContactGeneralInfo,
  CreateContactFooter,
  CreateContactSteps,
  ReviewCollaborationInformation,
  ReviewContactInformation,
  goToStep,
  selectCollaborationsToAdd,
  selectContactInfoToAdd
} from 'app/pages/my-audience/wizard-create-contact';

export const CreateContactFinish = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactToAdd = useAppSelector<ContactGeneralInfo>(selectContactInfoToAdd);
  const collaborationsToAdd = useAppSelector<CollaborationDetails[]>(selectCollaborationsToAdd);

  const editContact = () => {
    dispatch(goToStep(1));
  };

  const editCollaboration = () => {
    dispatch(goToStep(2));
  };

  return (
    <>
      <CreateContactSteps isStepValid={true} />
      <div className="wizard-panel-finish-wrap">
        <div className="wizard-panel-intro">
          <h5 className="wizard-panel-title">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-title'} />
          </h5>
        </div>
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <ReviewContactInformation editContact={editContact} contact={contactToAdd} printMultipleValues={printMultipleValues} />
            <div className="wizard-panel-finish-content">
              <div className="wizard-panel-finish-main-intro">
                <h5 className="wizard-panel-finish-title">
                  {' '}
                  <FormattedMessage id={'add-menu-create-contact.additional-finish-collaboration'} />
                </h5>
                <div className="custom-accordion-head-actions">
                  <Button icon={<Icon iconName="Edit" />} text content={formatMessage({ id: 'buttons.edit' })} onClick={editCollaboration} />
                </div>
              </div>
              {collaborationsToAdd.map((collaborationDetails, i) => (
                <ReviewCollaborationInformation collaboration={collaborationDetails.collaboration} printMultipleValues={printMultipleValues} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <CreateContactFooter />
    </>
  );
};
