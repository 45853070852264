import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { Input, PopupIcon } from '@fluentui/react-northstar';
import { useOnClickOutside } from 'usehooks-ts';
import {
  closeSearchDropdown,
  openSearchDropdown,
  openSearchPopupOnMobile,
  SearchSuggestionCategory,
  selectIsOnMobile,
  selectIsSearchDropdownOpen,
  selectIsSearchPopupOnMobileOpen,
  setSelectedTableRows,
  useAppDispatch,
  useAppSelector,
  NewBackButton,
  closeSearchPopupOnMobile,
  ShimmerSearch,
  SearchSuggestionsList,
  FilterItem,
  NEED_HELP_LINKS
} from 'app/common';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import 'app/common/components/search/search.scss';

interface SearchProps {
  searchSuggestionsCategories: SearchSuggestionCategory[];
  onResetSuggestions: () => void;
  onSearchSuggestions: (searchSuggestionText: string) => void;
  onClearAllFilters: () => void;
  onAnimationStart: () => void;
  onAnimationStop: () => void;
  searchSuggestionsText: string;
  searchText: string;
  isLoadingSearchSuggestions: boolean;
  onSetSearchText: (searchText: string) => void;
  onSetFilterItems: (filterItems: FilterItem[]) => void;
  onSearch: () => void;
  viewAllMessage: string;
  onOutsideClick?: () => void;
}

export const Search = ({
  searchSuggestionsCategories,
  onResetSuggestions,
  onSearchSuggestions,
  onClearAllFilters,
  onAnimationStart,
  onAnimationStop,
  searchSuggestionsText,
  searchText,
  isLoadingSearchSuggestions,
  onSetSearchText,
  onSetFilterItems,
  onSearch,
  viewAllMessage,
  onOutsideClick
}: SearchProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setInputValue(searchText === '*' ? '' : searchText);
  }, [searchText]);

  // TODO: Check if first two selectors should be in HOC when this component is used by some other entity
  const isSearchPopupOnMobileOpen = useAppSelector(selectIsSearchPopupOnMobileOpen);
  const isSearchDropdownOpen = useAppSelector(selectIsSearchDropdownOpen);
  const isOnMobile = useAppSelector(selectIsOnMobile);
  const locale = useAppSelector(selectLocale);

  const searchWrapDivRef = useRef(null);
  const [inputValue, setInputValue] = useState(searchText === '*' ? '' : searchText);
  const [needHelpUrl, setNeedHelpUrl] = useState('');
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const getNeedHelpLinkForLocale = (locale: string) => {
    switch (locale) {
      case NEED_HELP_LINKS.EN.LANG:
        return NEED_HELP_LINKS.EN.URL;
      case NEED_HELP_LINKS.DE.LANG:
        return NEED_HELP_LINKS.DE.URL;
      case NEED_HELP_LINKS.FR.LANG:
        return NEED_HELP_LINKS.FR.URL;
      default:
        return NEED_HELP_LINKS.EN.URL;
    }
  };

  useEffect(() => {
    setNeedHelpUrl(getNeedHelpLinkForLocale(locale));
  }, [locale, formatMessage]);

  const goToSearchResults = () => {
    onSetSearchText(inputValue);

    if (isOnMobile) {
      dispatch(closeSearchPopupOnMobile());
    } else {
      dispatch(closeSearchDropdown());
    }

    onAnimationStop();
    onResetSuggestions();
    onSearch();
    dispatch(setSelectedTableRows([]));

    const activePathName = location.pathname.slice(1).split('/')[0] + '/' + location.pathname.slice(1).split('/')[1];
    navigate(`/${activePathName}/results/${inputValue ? inputValue : '*'}`);
  };

  const onChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    const targetElement = event.target as HTMLTextAreaElement;
    const inputValue = targetElement.value;

    setInputValue(inputValue ?? '');

    if (!inputValue) {
      onResetSuggestions();
      dispatch(closeSearchDropdown());
      dispatch(closeSearchPopupOnMobile());
      onAnimationStop();
      return;
    }

    onSearchSuggestions(inputValue);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      goToSearchResults();
      return;
    }

    if (isOnMobile) {
      dispatch(openSearchPopupOnMobile());
    } else {
      onAnimationStart();
      dispatch(openSearchDropdown());
    }
  };

  const onKeyDown = () => {
    if (isOnMobile) {
      dispatch(openSearchPopupOnMobile());
    } else {
      onAnimationStart();
      dispatch(openSearchDropdown());
    }
  };

  const handleClearButton = () => {
    setInputValue('');
    onSetSearchText('');
    dispatch(closeSearchDropdown());
    dispatch(closeSearchPopupOnMobile());

    onAnimationStop();
    onResetSuggestions();
    onSearch();
    dispatch(setSelectedTableRows([]));
  };

  const handleClickOutside = () => {
    onAnimationStop();
    dispatch(closeSearchDropdown());
    dispatch(closeSearchPopupOnMobile());
    onResetSuggestions();

    if (onOutsideClick) {
      onOutsideClick();
    }
  };

  useOnClickOutside(searchWrapDivRef, handleClickOutside);

  return (
    <div className={`search-wrap ${isSearchPopupOnMobileOpen && 'search-popup--active'}`} ref={searchWrapDivRef}>
      <div className="search">
        <div className="search-input">
          <Input
            icon={
              inputValue ? (
                <Icon className="icon-cancel" iconName="Cancel" onClick={handleClearButton} />
              ) : (
                <Icon iconName="Search" onClick={goToSearchResults} />
              )
            }
            placeholder={formatMessage({ id: 'search.input-placeholder' })}
            autoComplete="off"
            value={inputValue}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={`dropdown ${isSearchDropdownOpen && 'dropdown--active'}`}>
          <div className="dropdown-wrap">
            {isLoadingSearchSuggestions ? (
              <ShimmerSearch />
            ) : (
              <SearchSuggestionsList
                searchSuggestionsCategories={searchSuggestionsCategories}
                searchSuggestionsText={searchSuggestionsText}
                onClearAllFilters={onClearAllFilters}
                onResetSuggestions={onResetSuggestions}
                onSetFilterItems={onSetFilterItems}
                onSetSearchText={onSetSearchText}
                onSearch={onSearch}
                viewAllMessage={viewAllMessage}
              />
            )}
          </div>
        </div>
      </div>
      <a className="search-help" href={needHelpUrl} target="_blank" rel="noreferrer">
        {formatMessage({ id: 'search.help-link' })} <PopupIcon />
      </a>
      <div className="search-popup" style={{ maxHeight: height }}>
        <NewBackButton>{formatMessage({ id: 'search.back-button' })}</NewBackButton>
        <div className="search-popup-segments">
          <SearchSuggestionsList
            searchSuggestionsCategories={searchSuggestionsCategories}
            searchSuggestionsText={searchSuggestionsText}
            onClearAllFilters={onClearAllFilters}
            onResetSuggestions={onResetSuggestions}
            onSetFilterItems={onSetFilterItems}
            onSetSearchText={onSetSearchText}
            onSearch={onSearch}
            viewAllMessage={viewAllMessage}
          />
        </div>
      </div>
    </div>
  );
};
