import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddOwnContactData,
  closeCreateContactWizard,
  CreateContactFinish,
  CreateContactProfileInformation,
  CreateContactWizardSteps,
  CreateNewContact,
  selectCreateContactCurrentStep,
  selectIsCreateContactWizardOpened
} from 'app/pages/my-audience/company-profile';

export const CreateContactWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector<CreateContactWizardSteps>(selectCreateContactCurrentStep);

  const isCreateContactWizardOpened = useAppSelector<boolean>(selectIsCreateContactWizardOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'companies-create-contact.wizard-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateContactWizardSteps.CreateNew && <CreateNewContact />}
        {currentStep === CreateContactWizardSteps.OwnContactData && <AddOwnContactData />}
        {currentStep === CreateContactWizardSteps.ProfileInformation && <CreateContactProfileInformation />}
        {currentStep === CreateContactWizardSteps.Finish && <CreateContactFinish />}
      </div>
    </WizardWrap>
  );
};
