import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/redux/store';

export interface WizardCreateListState {
  isCreateListWizardOpened: boolean;
  listNameToAdd: string;
  listAddedId: string;
  isFinishButtonDisabled: boolean;
}

const initialState: WizardCreateListState = {
  isCreateListWizardOpened: false,
  listNameToAdd: '',
  listAddedId: '',
  isFinishButtonDisabled: false
};

export type ListAddedIdSelector = (state: RootState) => string;
export const selectListAddedId: ListAddedIdSelector = createSelector(
  [(state: RootState) => state.wizardCreateList.listAddedId],
  (listAddedId: string) => listAddedId
);

export type IsCreateListWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateListWizardOpened: IsCreateListWizardOpenedSelector = createSelector(
  [(state: RootState) => state.wizardCreateList.isCreateListWizardOpened],
  (isCreateListWizardOpened: boolean) => isCreateListWizardOpened
);

export type ListNameToAddSelector = (state: RootState) => string;
export const selectListNameToAdd: ListNameToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateList.listNameToAdd],
  (listNameToAdd: string) => listNameToAdd
);

export type IsFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsFinishButtonDisabled: IsFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.wizardCreateList.isFinishButtonDisabled],
  (isFinishButtonDisabled: boolean) => isFinishButtonDisabled
);

const wizardCreateListSlice = createSlice({
  name: 'wizardCreateList',
  initialState,
  reducers: {
    openCreateListWizard: (state) => {
      state.isCreateListWizardOpened = true;
      state.isFinishButtonDisabled = false;
    },
    closeCreateListWizard: (state) => {
      state.isCreateListWizardOpened = false;
    },
    setListNameToAdd: (state, action: PayloadAction<string>) => {
      state.listNameToAdd = action.payload;
    },
    restartListWizard: (state) => {
      state.listNameToAdd = '';
    },
    setListAddedId: (state, action: PayloadAction<string>) => {
      state.listAddedId = action.payload;
    },
    disableFinishButton: (state) => {
      state.isFinishButtonDisabled = true;
    }
  }
});

export const { openCreateListWizard, closeCreateListWizard, setListNameToAdd, restartListWizard, setListAddedId, disableFinishButton } =
  wizardCreateListSlice.actions;

export default wizardCreateListSlice.reducer;
