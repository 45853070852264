import { DialogType, Dialog, DialogFooter } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  closeRemoveMediaOutletDialog,
  removeCollaborationRequested,
  selectIsRemoveMediaOutletDialogVisible,
  selectCollaborationToRemove
} from 'app/pages/my-audience/contact-profile';
import { Collaboration } from 'app/pages/my-audience/contacts';

export const RemoveMediaOutletDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isRemoveMediaOutletDialogVisible = useAppSelector<boolean>(selectIsRemoveMediaOutletDialogVisible);
  const contactDataToRemove = useAppSelector<Collaboration>(selectCollaborationToRemove);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'remove-collaboration-dialog.title' }),
    subText: formatMessage({ id: 'remove-collaboration-dialog.text' }, { mediumName: contactDataToRemove?.medium?.name })
  };

  const dialogModalProps = {
    isBlocking: false,
    classname: 'dialog-wrap'
  };
  const handleRemoveButtonClick = () => {
    dispatch(removeCollaborationRequested());
    dispatch(closeRemoveMediaOutletDialog());
  };

  const handleDismissButtonClick = () => {
    dispatch(closeRemoveMediaOutletDialog());
  };

  return (
    <Dialog
      hidden={!isRemoveMediaOutletDialogVisible}
      onDismiss={handleDismissButtonClick}
      dialogContentProps={dialogContentProps}
      modalProps={dialogModalProps}
    >
      <div className="dialog-footer">
        <DialogFooter>
          <Button tinted onClick={handleDismissButtonClick} content={formatMessage({ id: 'buttons.cancel' })} />
          <Button primary onClick={handleRemoveButtonClick} content={formatMessage({ id: 'buttons.remove' })} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
