import { useState } from 'react';
import { DateTime } from 'luxon';
import { Dialog, DialogFooter } from '@fluentui/react';
import { Button, Input } from '@fluentui/react-northstar';
import { selectSignedInUser, useAppDispatch, useAppSelector, User } from 'app/common';
import { addTemplateRequested, closeSaveTemplateDialog, selectIsSaveTemplateDialogVisible } from 'app/pages/my-activities/sending-wizard';
import { Template } from 'app/pages/my-activities/templates';
import { FormattedMessage, useIntl } from 'app/i18n';

export const SaveTemplateDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isDialogVisible = useAppSelector<boolean>(selectIsSaveTemplateDialogVisible);
  const signedInUser = useAppSelector<User>(selectSignedInUser);

  const [title, setTitle] = useState<string>();

  const onDismissDialog = () => {
    dispatch(closeSaveTemplateDialog());
  };

  const onSaveTemplate = () => {
    const templateToAdd = new Template('', title, '', '', '', signedInUser.id, DateTime.utc().toJSDate(), DateTime.utc().toJSDate(), signedInUser.id);
    dispatch(addTemplateRequested(templateToAdd));
    onDismissDialog();
  };

  return (
    <>
      <Dialog hidden={!isDialogVisible} onDismiss={onDismissDialog}>
        <div>
          <div>
            <h1>
              <FormattedMessage id={'emailing-wizard-email-step.save-template-dialog-title'} />
            </h1>
            <Input type="string" value={title} onChange={(e) => setTitle((e.target as HTMLTextAreaElement).value)} />
          </div>
          <DialogFooter>
            <Button secondary content={formatMessage({ id: 'buttons.cancel' })} onClick={onDismissDialog} />
            <Button primary content={formatMessage({ id: 'buttons.save' })} onClick={onSaveTemplate} />
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};
