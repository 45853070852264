import { Avatar, SizeValue } from '@fluentui/react-northstar';
import 'sass/shared/profile-panel/ProfilePanel.scss';
import { getInitials } from 'app/common/validation';

interface ProfilePictureProps {
  size: SizeValue;
  name: string;
  imageUrl?: string;
}
export const ProfilePicture = ({ name, imageUrl, size }: ProfilePictureProps) =>
  imageUrl ? <Avatar className="profile-panel-avatar" image={imageUrl} size={size} /> : <Avatar name={name} getInitials={getInitials} size={size} />;
