import { Button } from '@fluentui/react-northstar';
import * as Sentry from '@sentry/react';
import {
  addJobTitleRequested,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regNumbers,
  selectMediaOutletJobTitles,
  useAppDispatch,
  useAppSelector,
  WizardIntroInfo,
  WizardWrap,
  isPhoneNumberValidOrEmpty,
  regEmail
} from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { FormattedMessage, useIntl } from 'app/i18n';
import { ControlledCreateDropdown, ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { Collaboration, Contact, JobTitle } from 'app/pages/my-audience/contacts';
import {
  closeEditCollaborationWizard,
  createCollaborationFromEditing,
  EditProfileInformationForm,
  resetSelectedContactForEditing,
  selectCollaborationForEditing,
  selectIsEditCollaborationWizardOpened,
  selectSelectedContactForEditing,
  updateCollaborationRequested
} from 'app/pages/my-audience/media-outlet-profile';
import { getNames } from 'country-list';
import { useForm } from 'react-hook-form';

export const EditCollaborationWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const mediaOutletJobTitles = useAppSelector<JobTitle[]>(selectMediaOutletJobTitles);
  const contact = useAppSelector<Contact>(selectSelectedContactForEditing);
  const isEditCollaborationWizardOpened = useAppSelector<boolean>(selectIsEditCollaborationWizardOpened);
  const collaboration = useAppSelector<Collaboration>(selectCollaborationForEditing);

  const jobTitles: string[] = mediaOutletJobTitles.map((jt) => jt.name);

  const { handleSubmit, control, watch } = useForm<EditProfileInformationForm, any>({
    defaultValues: {
      address: collaboration.address,
      jobDescription: collaboration.jobDescription,
      mobilePhoneNumber: collaboration.mobilePhoneNumber?.value || '',
      landlinePhoneNumber: collaboration.landlinePhoneNumber?.value || '',
      postalCode: collaboration.postalCode,
      emailAddress: collaboration.emailAddress,
      city: collaboration.city
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleSaveButtonClick = () => {
    handleSubmit(
      (data) => {
        const selectedJobTitle = mediaOutletJobTitles.find((jt) => jt.name === data.jobTitle);
        const updatedCollaboration = createCollaborationFromEditing(data, collaboration, selectedJobTitle);

        dispatch(updateCollaborationRequested({ contact: contact, collaboration: updatedCollaboration }));
        dispatch(closeEditCollaborationWizard());
      },
      (error) => {
        Sentry.setExtra('Path', EditCollaborationWizard.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleDismissButtonClick = () => {
    dispatch(closeEditCollaborationWizard());
    dispatch(resetSelectedContactForEditing());
  };

  const areProfileInfoFieldsValid =
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('landlinePhoneNumber')) &&
    isPhoneNumberValidOrEmpty(watch('mobilePhoneNumber'));

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', collaboration.medium.type);

    dispatch(addJobTitleRequested(jobTitle));
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isEditCollaborationWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'mo-edit-collaboration.mo-edit-header' })}
      customWidth="500px"
      classModifier="edit"
    >
      <div className="wizard-panel">
        <div className="wizard-panel-content">
          <div className="wizard-panel-main">
            <div className="wizard-panel-form-wrap">
              <WizardIntroInfo
                title={formatMessage({ id: 'mo-edit-collaboration.mo-edit-title' })}
                info={
                  <div className="wizard-panel-intro-info">
                    <ProfilePicture name={`${contact.firstName} ${contact.lastName}`} imageUrl={contact.profilePictureUrl} size="medium" />
                    <span className="wizard-panel-intro-info-text">
                      {contact.firstName} {contact.lastName}
                    </span>
                  </div>
                }
              />
              <div className="wizard-panel-form">
                <div className="custom-dropdown">
                  <ControlledCreateDropdown
                    label={
                      <span className="dropdown-label">
                        <FormattedMessage id={'add-menu-create-contact.additional-job-title-label'} />
                      </span>
                    }
                    name="jobTitle"
                    items={jobTitles}
                    control={control}
                    placeholder={formatMessage({ id: 'add-menu-create-contact.additional-job-title-placeholder' })}
                    defaultValue={collaboration?.jobTitle?.name}
                    createNewEntityMessage={formatMessage({ id: 'aria-labels.create-job-title' })}
                    onCreateNewEntityClick={handleCreateNewJobTitle}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput name="jobDescription" label={formatMessage({ id: 'contact-data-labels.job-description' })} control={control} />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput name="address" label={formatMessage({ id: 'contact-data-labels.address' })} control={control} />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="emailAddress"
                    label={formatMessage({ id: 'contact-data-labels.email-address' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regEmail,
                        message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="landlinePhoneNumber"
                    label={formatMessage({ id: 'contact-data-labels.phone-number' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regPhoneNumber,
                        message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="mobilePhoneNumber"
                    label={formatMessage({ id: 'contact-data-labels.mobile-phone-number' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regPhoneNumber,
                        message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="postalCode"
                    label={formatMessage({ id: 'mo-contact-data-labels.zip' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regNumbers,
                        message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput name="city" label={formatMessage({ id: 'contact-data-labels.city' })} control={control} />
                </div>
                <div className="wizard-panel-dropdown">
                  <ControlledDropdown
                    name="country"
                    label={<span className="dropdown-label">{formatMessage({ id: 'mo-contact-data-labels.country' })}</span>}
                    items={getNames()}
                    control={control}
                    defaultValue={collaboration.country}
                    placeholder={formatMessage({ id: 'dropdown-placeholders.country' })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
          <div className="wizard-panel-bottom-button-cancel">
            <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleDismissButtonClick} />
          </div>
          <div className="wizard-panel-bottom-actions">
            <Button content={formatMessage({ id: 'buttons.save' })} primary onClick={handleSaveButtonClick} disabled={!areProfileInfoFieldsValid} />
          </div>
        </div>
      </div>
    </WizardWrap>
  );
};
