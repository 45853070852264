import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterItem } from 'app/common';
import { Template } from 'app/pages/my-activities/templates';
import { RootState } from 'app/redux/store';

export interface TemplatesState {
  templates: Template[];
  pageNumber: number;
  pageSize: number;
  totalNumberOfTemplates: number;
  isLoadingTemplates: boolean;
  filterItems: FilterItem[];
  isFiltersPanelOpened: boolean;
  searchText: string;
  searchSuggestionsText: string;
  searchSuggestions: Template[];
  templateToDeleteId: string;
  templateToUpdate: Template;
}

const initialState: TemplatesState = {
  templates: [],
  pageNumber: 1,
  pageSize: 10,
  totalNumberOfTemplates: 0,
  isLoadingTemplates: false,
  filterItems: [],
  isFiltersPanelOpened: false,
  searchText: '',
  searchSuggestionsText: '',
  searchSuggestions: [],
  templateToDeleteId: '',
  templateToUpdate: {} as Template
};

export type TemplatesSelector = (state: RootState) => Template[];
export const selectTemplates: TemplatesSelector = createSelector([(state: RootState) => state.templates.templates], (templates: Template[]) => templates);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector([(state: RootState) => state.templates.pageNumber], (pageNumber: number) => pageNumber);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.templates.pageSize], (pageSize: number) => pageSize);

export type FilterItemsSelector = (state: RootState) => FilterItem[];
export const selectFilterItems: FilterItemsSelector = createSelector(
  [(state: RootState) => state.templates.filterItems],
  (filterItems: FilterItem[]) => filterItems
);

export type isFiltersPanelOpened = (state: RootState) => boolean;
export const selectIsFiltersPanelOpened: isFiltersPanelOpened = createSelector(
  [(state: RootState) => state.templates.isFiltersPanelOpened],
  (isFiltersPanelOpened: boolean) => isFiltersPanelOpened
);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector([(state: RootState) => state.templates.searchText], (searchText: string) => searchText);

export type SearchSuggestionsTextSelector = (state: RootState) => string;
export const selectSearchSuggestionsText: SearchSuggestionsTextSelector = createSelector(
  [(state: RootState) => state.templates.searchSuggestionsText],
  (searchSuggestionsText: string) => searchSuggestionsText
);

export type SearchSuggestionsSelector = (state: RootState) => Template[];
export const selectSearchSuggestions: SearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.templates.searchSuggestions],
  (searchSuggestions: Template[]) => searchSuggestions
);

export type TemplateToDeleteIdSelector = (state: RootState) => string;
export const selectTemplateToDeleteId: TemplateToDeleteIdSelector = createSelector(
  [(state: RootState) => state.templates.templateToDeleteId],
  (templateToDeleteId: string) => templateToDeleteId
);

export type TemplateToEditSelector = (state: RootState) => Template;
export const selectTemplateToUpdate: TemplateToEditSelector = createSelector(
  [(state: RootState) => state.templates.templateToUpdate],
  (templateToUpdate: Template) => templateToUpdate
);

export type IsLoadingTemplatesSelector = (state: RootState) => boolean;
export const selectIsLoadingTemplates: IsLoadingTemplatesSelector = createSelector(
  [(state: RootState) => state.templates.isLoadingTemplates],
  (isLoadingTemplates: boolean) => isLoadingTemplates
);

export type TemplatesTotalCountSelector = (state: RootState) => number;
export const selectTemplatesTotalCount: TemplatesTotalCountSelector = createSelector(
  [(state: RootState) => state.templates.totalNumberOfTemplates],
  (totalNumberOfTemplates: number) => totalNumberOfTemplates
);

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplatesPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      state.pageNumber = 1;
    },
    setTemplatesPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    templatesRequested: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.totalNumberOfTemplates = 0;
      state.templates = [];
      state.isLoadingTemplates = true;
    },
    templatesReceived: (state, action: PayloadAction<Template[]>) => {
      state.templates = [...action.payload];
      state.isLoadingTemplates = false;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    incrementPageNumber: (state) => {
      state.pageNumber++;
    },
    setTotalNumberOfTemplates: (state, action: PayloadAction<number>) => {
      state.totalNumberOfTemplates = action.payload;
    },
    addTemplatesFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = [...state.filterItems, action.payload];
    },
    removeTemplatesFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = state.filterItems.filter((item) => item.fieldName !== action.payload.fieldName || item.value !== action.payload.value);
    },
    addSingleFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = state.filterItems.filter((item) => item.fieldName !== action.payload.fieldName);
      state.filterItems = [...state.filterItems, action.payload];
    },
    removeAllFiltersWithFieldName: (state, action: PayloadAction<string>) => {
      state.filterItems = state.filterItems.filter((item) => item.fieldName !== action.payload);
    },
    clearAllFilters: (state) => {
      state.filterItems = [];
      state.searchSuggestions = [];
      state.searchSuggestionsText = '';
    },
    openFiltersPanel: (state) => {
      state.isFiltersPanelOpened = true;
    },
    closeFiltersPanel: (state) => {
      state.isFiltersPanelOpened = false;
    },
    templateSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.searchSuggestionsText = action.payload;
    },
    templateSuggestionsReceived: (state, action: PayloadAction<Template[]>) => {
      state.searchSuggestions = action.payload;
    },
    templateAdded: (state, action: PayloadAction<Template>) => {
      state.templates = [...state.templates, action.payload];
    },
    deleteTemplateRequested: (state, action: PayloadAction<string>) => {
      state.templateToDeleteId = action.payload;
    },
    templateDeleted: (state) => {
      state.templates = state.templates.filter((template) => template.id !== state.templateToDeleteId);
      state.templateToDeleteId = '';
      state.totalNumberOfTemplates = state.totalNumberOfTemplates - 1;
    },
    updateTemplateRequested: (state, action: PayloadAction<Template>) => {
      state.templateToUpdate = action.payload;
    },
    templateUpdated: (state, action: PayloadAction<Template>) => {
      const templateToUpdateIndex = state.templates.findIndex((template) => template.id === action.payload.id);
      state.templates[templateToUpdateIndex] = action.payload;
      state.templateToUpdate = {} as Template;
    }
  }
});

export const {
  templatesRequested,
  templatesReceived,
  incrementPageNumber,
  setTotalNumberOfTemplates,
  addTemplatesFilter,
  removeTemplatesFilter,
  clearAllFilters,
  openFiltersPanel,
  closeFiltersPanel,
  templateSuggestionsRequested,
  templateSuggestionsReceived,
  addSingleFilter,
  removeAllFiltersWithFieldName,
  templateAdded,
  deleteTemplateRequested,
  templateDeleted,
  updateTemplateRequested,
  templateUpdated,
  setTemplatesPageNumber,
  setTemplatesPageSize,
  setPageNumber
} = templatesSlice.actions;

export default templatesSlice.reducer;
