import { app } from '@microsoft/teams-js';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/redux/store';
import { BottomPopupItems, CommentUser, DataSource, User, SearchResultTableData, UserInfo, MessageData, Language } from 'app/common';
import { JobTitle, MediumType } from 'app/pages/my-audience/contacts';

export interface CommonState {
  audienceCurrentUrl: string;
  activitiesCurrentUrl: string;
  isUndoAlertVisible: boolean;
  isAlertNotificationVisible: boolean;
  isBottomPopupVisible: boolean;
  isColumnSelectionPopupVisible: boolean;
  undoAlertMessage: string;
  alertNotificationMessage: string;
  alertData: MessageData;
  searchResultsColumnNumber: number;
  isOnTouch: boolean;
  isOnMobile: boolean;
  isOnTablet: boolean;
  isOnDesktop: boolean;
  toggleSearch: boolean;
  toggleSearchPopup: boolean;
  mentionSuggestions: CommentUser[];
  mentionSuggestionsSearchText: string;
  lastInteractionWithEmailAdress: string;
  signedInUserEmailAddress: string;
  signedInUserUsername: string;
  signedInUserOid: string;
  tenantId: string;
  isMobileColumnSelectionPopupVisible: boolean;
  bottomPopupItems: BottomPopupItems[];
  canceledUndoTimer: boolean;
  isFilterPanelOpen: boolean;
  isLoadingAllJobTitles: boolean;
  allJobTitles: JobTitle[];
  context: app.Context;
  errorData: MessageData;
  signedInUser: User;
  dataSources: DataSource[];
  ownDataSource: DataSource;
  selectedTableRows: any[];
  searchResultsTableRows: any[];
  isExportDialogVisible: boolean;
  isNavigationCollapsed: boolean;
  areEditAndCopyActionsVisible: boolean;
  editableFieldId: number;
  isSearchPopupOnMobileOpen: boolean;
  isSearchDropdownOpen: boolean;
  areEditAndDeleteIconsOnCardVisible: boolean;
  savedSearchCardKey: string;
  isCardEditing: boolean;
  allowClickOnCard: boolean;
  savedSearchCardPanelKey: string;
  isPanelCardEditing: boolean;
  allowClickOnPanelCard: boolean;
  isEditedSavedSearchPanelCard: boolean;
  panelCardActivClass: string;
  isEditedSavedSearchCard: boolean;
  cardActiveClass: string;
  isInputTextFieldTextEditing: boolean;
  isEditableOverlayPopupVisible: boolean;
  isDropdownEditing: boolean;
  editableFieldActionsId: number;
  isDeleteEntitiesDialogOpen: boolean;
  isDatePickerFieldEditing: boolean;
  isUserRegistered: boolean;
  isLoadingSignedInUser: boolean;
  isInTeams: boolean;
  isFlatFileOpen: boolean;
  languages: Language[];
  jobTitleToAdd: JobTitle;
  jobTitleIdToRemove: string;
}

const initialState: CommonState = {
  audienceCurrentUrl: '',
  activitiesCurrentUrl: '',
  isUndoAlertVisible: false,
  isAlertNotificationVisible: false,
  isBottomPopupVisible: false,
  isColumnSelectionPopupVisible: false,
  undoAlertMessage: '',
  alertNotificationMessage: '',
  alertData: {} as MessageData,
  searchResultsColumnNumber: 6,
  isOnTouch: false,
  isOnTablet: false,
  isOnMobile: false,
  isOnDesktop: false,
  toggleSearch: false,
  toggleSearchPopup: false,
  mentionSuggestions: [],
  mentionSuggestionsSearchText: '',
  lastInteractionWithEmailAdress: '',
  signedInUserEmailAddress: '',
  signedInUserUsername: '',
  signedInUserOid: '',
  tenantId: '',
  isMobileColumnSelectionPopupVisible: false,
  bottomPopupItems: [],
  canceledUndoTimer: false,
  isFilterPanelOpen: false,
  isLoadingAllJobTitles: true,
  allJobTitles: [],
  context: undefined,
  errorData: {} as MessageData,
  signedInUser: null,
  selectedTableRows: [],
  searchResultsTableRows: [],
  dataSources: [],
  ownDataSource: {} as DataSource,
  isExportDialogVisible: false,
  isNavigationCollapsed: false,
  areEditAndCopyActionsVisible: false,
  editableFieldId: 0,
  isSearchPopupOnMobileOpen: false,
  isSearchDropdownOpen: false,
  areEditAndDeleteIconsOnCardVisible: false,
  savedSearchCardKey: '',
  isCardEditing: false,
  allowClickOnCard: true,
  savedSearchCardPanelKey: '',
  isPanelCardEditing: false,
  allowClickOnPanelCard: true,
  isEditedSavedSearchPanelCard: false,
  panelCardActivClass: '',
  isEditedSavedSearchCard: false,
  cardActiveClass: '',
  isInputTextFieldTextEditing: false,
  isEditableOverlayPopupVisible: false,
  isDropdownEditing: false,
  editableFieldActionsId: 0,
  isDatePickerFieldEditing: false,
  isDeleteEntitiesDialogOpen: false,
  isUserRegistered: true,
  isLoadingSignedInUser: false,
  isInTeams: false,
  isFlatFileOpen: false,
  languages: [],
  jobTitleToAdd: {} as JobTitle,
  jobTitleIdToRemove: ''
};

export type SignedInUserSelector = (state: RootState) => User;
export const selectSignedInUser: SignedInUserSelector = createSelector([(state: RootState) => state.common.signedInUser], (signedInUser: User) => signedInUser);

export type IsDeleteEntitiesDialogOpen = (state: RootState) => boolean;
export const selectIsDeleteEntitiesDialogOpen: IsDeleteEntitiesDialogOpen = createSelector(
  [(state: RootState) => state.common.isDeleteEntitiesDialogOpen],
  (isDeleteEntitiesDialogOpen: boolean) => isDeleteEntitiesDialogOpen
);

export type BottomPopupItemsSelector = (state: RootState) => BottomPopupItems[];
export const selectBottomPopupItems: BottomPopupItemsSelector = createSelector(
  [(state: RootState) => state.common.bottomPopupItems],
  (bottomPopupItems: BottomPopupItems[]) => bottomPopupItems
);

export type IsTableMobileColumnSelectionPopupVisibleSelector = (state: RootState) => boolean;
export const selectIsTableMobileColumnSelectionPopupVisible: IsTableMobileColumnSelectionPopupVisibleSelector = createSelector(
  [(state: RootState) => state.common.isMobileColumnSelectionPopupVisible],
  (isMobileColumnSelectionPopupVisible: boolean) => isMobileColumnSelectionPopupVisible
);

export type SearchResultsColumnNumberSelector = (state: RootState) => number;
export const selectSearchResultsColumnNumber: SearchResultsColumnNumberSelector = createSelector(
  [(state: RootState) => state.common.searchResultsColumnNumber],
  (searchResultsColumnNumber: number) => searchResultsColumnNumber
);

export type EditableFieldIdSelector = (state: RootState) => number;
export const selectEditableFieldId: EditableFieldIdSelector = createSelector(
  [(state: RootState) => state.common.editableFieldId],
  (editableFieldId: number) => editableFieldId
);

export type IsOnMobileSelector = (state: RootState) => boolean;
export const selectIsOnMobile: IsOnMobileSelector = createSelector([(state: RootState) => state.common.isOnMobile], (isOnMobile: boolean) => isOnMobile);

export type IsOnTabletSelector = (state: RootState) => boolean;
export const selectIsOnTablet: IsOnTabletSelector = createSelector([(state: RootState) => state.common.isOnTablet], (isOnTablet: boolean) => isOnTablet);

export type IsOnTouchSelector = (state: RootState) => boolean;
export const selectIsOnTouch: IsOnTouchSelector = createSelector([(state: RootState) => state.common.isOnTouch], (isOnTouch: boolean) => isOnTouch);

export type IsOnDesktopSelector = (state: RootState) => boolean;
export const selectIsOnDesktop: IsOnDesktopSelector = createSelector([(state: RootState) => state.common.isOnDesktop], (isOnDesktop: boolean) => isOnDesktop);

export type IsSearchPopupOnMobileOpenSelector = (state: RootState) => boolean;
export const selectIsSearchPopupOnMobileOpen: IsSearchPopupOnMobileOpenSelector = createSelector(
  [(state: RootState) => state.common.isSearchPopupOnMobileOpen],
  (isSearchPopupOnMobileOpen: boolean) => isSearchPopupOnMobileOpen
);

export type IsSearchDropdownOpenSelector = (state: RootState) => boolean;
export const selectIsSearchDropdownOpen: IsSearchDropdownOpenSelector = createSelector(
  [(state: RootState) => state.common.isSearchDropdownOpen],
  (isSearchDropdownOpen: boolean) => isSearchDropdownOpen
);

export type UndoAlertMessageSelector = (state: RootState) => string;
export const selectUndoAlertMessage: UndoAlertMessageSelector = createSelector(
  [(state: RootState) => state.common.undoAlertMessage],
  (undoAlertMessage: string) => undoAlertMessage
);

export type AlertNotificationMessageSelector = (state: RootState) => string;
export const selectAlertNotificationMessage: AlertNotificationMessageSelector = createSelector(
  [(state: RootState) => state.common.alertNotificationMessage],
  (alertNotificationMessage: string) => alertNotificationMessage
);

export type AlertDataSelector = (state: RootState) => MessageData;
export const selectAlertData: AlertDataSelector = createSelector([(state: RootState) => state.common.alertData], (alertData: MessageData) => alertData);

export type IsUndoAlertVisibleSelector = (state: RootState) => boolean;
export const selectIsUndoAlertVisible: IsUndoAlertVisibleSelector = createSelector(
  [(state: RootState) => state.common.isUndoAlertVisible],
  (isUndoAlertVisible: boolean) => isUndoAlertVisible
);

export type AreEditAndCopyActionsVisibleSelector = (state: RootState) => boolean;
export const selectAreEditAndCopyActionsVisible: AreEditAndCopyActionsVisibleSelector = createSelector(
  [(state: RootState) => state.common.areEditAndCopyActionsVisible],
  (areEditAndCopyActionsVisible: boolean) => areEditAndCopyActionsVisible
);

export type IsAlertNotificationVisibleSelector = (state: RootState) => boolean;
export const selectIsAlertNotificationVisible: IsAlertNotificationVisibleSelector = createSelector(
  [(state: RootState) => state.common.isAlertNotificationVisible],
  (isAlertNotificationVisible: boolean) => isAlertNotificationVisible
);

export type IsBottomPopupVisibleSelector = (state: RootState) => boolean;
export const selectIsBottomPopupVisible: IsBottomPopupVisibleSelector = createSelector(
  [(state: RootState) => state.common.isBottomPopupVisible],
  (isBottomPopupVisible: boolean) => isBottomPopupVisible
);

export type IsTableColumnSelectionPopupVisibleSelector = (state: RootState) => boolean;
export const selectIsTableColumnSelectionPopupVisible: IsTableColumnSelectionPopupVisibleSelector = createSelector(
  [(state: RootState) => state.common.isColumnSelectionPopupVisible],
  (isColumnSelectionPopupVisible: boolean) => isColumnSelectionPopupVisible
);

export type AudienceCurrentUrlSelector = (state: RootState) => string;
export const selectAudienceCurrentUrl: AudienceCurrentUrlSelector = createSelector(
  [(state: RootState) => state.common.audienceCurrentUrl],
  (audienceCurrentUrl: string) => audienceCurrentUrl
);

export type ActivitiesCurrentUrlSelector = (state: RootState) => string;
export const selectActivitiesCurrentUrl: ActivitiesCurrentUrlSelector = createSelector(
  [(state: RootState) => state.common.activitiesCurrentUrl],
  (activitiesCurrentUrl: string) => activitiesCurrentUrl
);

export type MentionSuggestionsSelector = (state: RootState) => CommentUser[];
export const selectMentionSuggestions: MentionSuggestionsSelector = createSelector(
  [(state: RootState) => state.common.mentionSuggestions],
  (mentionSuggestions: CommentUser[]) => mentionSuggestions
);

export type MentionSuggestionsSearchTextSelector = (state: RootState) => string;
export const selectMentionSuggestionsSearchText: MentionSuggestionsSearchTextSelector = createSelector(
  [(state: RootState) => state.common.mentionSuggestionsSearchText],
  (mentionSuggestionsSearchText: string) => mentionSuggestionsSearchText
);

export type SignedInUserEmailAddressSelector = (state: RootState) => string;
export const selectSignedInUserEmailAddress: SignedInUserEmailAddressSelector = createSelector(
  [(state: RootState) => state.common.signedInUserEmailAddress],
  (signedInUserEmailAddress) => signedInUserEmailAddress
);

export type LastInteractionWithEmailAdressSelector = (state: RootState) => string;
export const selectLastInteractionWithEmailAdress: LastInteractionWithEmailAdressSelector = createSelector(
  [(state: RootState) => state.common.lastInteractionWithEmailAdress],
  (lastInteractionWithEmailAdress) => lastInteractionWithEmailAdress
);

export type SignedInUserUsernameSelector = (state: RootState) => string;
export const selectSignedInUserUsername: SignedInUserUsernameSelector = createSelector(
  [(state: RootState) => state.common.signedInUserUsername],
  (signedInUserUsername) => signedInUserUsername
);

export type SignedInUserOidSelector = (state: RootState) => string;
export const selectSignedInUserOid: SignedInUserOidSelector = createSelector(
  [(state: RootState) => state.common.signedInUserOid],
  (signedInUserOid: string) => signedInUserOid
);

export type AllJobTitlesSelector = (state: RootState) => JobTitle[];
export const selectAllJobTitles: AllJobTitlesSelector = createSelector(
  [(state: RootState) => state.common.allJobTitles],
  (allJobTitles: JobTitle[]) => allJobTitles
);

export type LanguagesSelector = (state: RootState) => Language[];
export const selectLanguages: LanguagesSelector = createSelector([(state: RootState) => state.common.languages], (languages: Language[]) => languages);

export type MediaOutletJobTitlesSelector = (state: RootState) => JobTitle[];
export const selectMediaOutletJobTitles: MediaOutletJobTitlesSelector = createSelector(
  [(state: RootState) => state.common.allJobTitles],
  (allJobTitles: JobTitle[]) => allJobTitles.filter((jobTitle) => jobTitle.mediumType === MediumType.MediaOutlet)
);

export type CompanyJobTitlesSelector = (state: RootState) => JobTitle[];
export const selectCompanyJobTitles: CompanyJobTitlesSelector = createSelector([(state: RootState) => state.common.allJobTitles], (allJobTitles: JobTitle[]) =>
  allJobTitles.filter((jobTitle) => jobTitle.mediumType === MediumType.Company)
);

export type SignedInSelector = (state: RootState) => boolean;
export const selectIsSignedIn: SignedInSelector = createSelector([(state: RootState) => state.common.signedInUser], (user: User) => !!user);

export type ContextSelector = (state: RootState) => app.Context;
export const selectContext: ContextSelector = createSelector([(state: RootState) => state.common.context], (context: app.Context) => context);

export type ErrorDataSelector = (state: RootState) => MessageData;
export const selectErrorData: ErrorDataSelector = createSelector([(state: RootState) => state.common.errorData], (errorData: MessageData) => errorData);

export type SelectedTableRowsSelector = (state: RootState) => SearchResultTableData;
export const selectSelectedTableRows: SelectedTableRowsSelector = createSelector(
  [(state: RootState) => state.common.selectedTableRows],
  (selectedTableRows: any[]) => selectedTableRows
);

export type SelectedTableRowsCountSelector = (state: RootState) => number;
export const selectedTableRowsCountSelector: SelectedTableRowsCountSelector = createSelector(
  [(state: RootState) => state.common.selectedTableRows],
  (selectedTableRows: any[]) => selectedTableRows.length
);

export type SelectedTableRowsIdsSelector = (state: RootState) => string[];
export const selectSelectedTableRowsIdsSelector: SelectedTableRowsIdsSelector = createSelector(
  [(state: RootState) => state.common.selectedTableRows],
  (selectedTableRows: any[]) => selectedTableRows.map((row) => row.id)
);

export type IsExportDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsExportDialogVisible: IsExportDialogVisibleSelector = createSelector(
  [(state: RootState) => state.common.isExportDialogVisible],
  (isExportDialogVisible: boolean) => isExportDialogVisible
);

export type IsNavigationCollapsedSelector = (state: RootState) => boolean;
export const selectIsNavigationCollapsed: IsNavigationCollapsedSelector = createSelector(
  [(state: RootState) => state.common.isNavigationCollapsed],
  (isNavigationCollapsed: boolean) => isNavigationCollapsed
);

export type AreEditAndDeleteIconsVisibleSelector = (state: RootState) => boolean;
export const selectAreEditAndDeleteIconsVisible: AreEditAndDeleteIconsVisibleSelector = createSelector(
  [(state: RootState) => state.common.areEditAndDeleteIconsOnCardVisible],
  (areEditAndDeleteIconsOnCardVisible: boolean) => areEditAndDeleteIconsOnCardVisible
);

export type SavedSearchCardKeySlector = (state: RootState) => string;
export const selectSavedSearchCardKey: SavedSearchCardKeySlector = createSelector(
  [(state: RootState) => state.common.savedSearchCardKey],
  (savedSearchCardKey: string) => savedSearchCardKey
);

export type IsCardEditingSlector = (state: RootState) => boolean;
export const selectIsCardEditing: IsCardEditingSlector = createSelector(
  [(state: RootState) => state.common.isCardEditing],
  (isCardEditing: boolean) => isCardEditing
);

export type SavedSearchCardPanelKeySlector = (state: RootState) => string;
export const selectSavedSearchCardPanelKey: SavedSearchCardPanelKeySlector = createSelector(
  [(state: RootState) => state.common.savedSearchCardPanelKey],
  (savedSearchCardPanelKey: string) => savedSearchCardPanelKey
);

export type IsPanelCardEditingSlector = (state: RootState) => boolean;
export const selectIsPanelCardEditing: IsPanelCardEditingSlector = createSelector(
  [(state: RootState) => state.common.isPanelCardEditing],
  (isPanelCardEditing: boolean) => isPanelCardEditing
);

export type IsEditedSavedSearchPanelCardSelector = (state: RootState) => boolean;
export const selectIsEditedSavedSearchPanelCard: IsEditedSavedSearchPanelCardSelector = createSelector(
  [(state: RootState) => state.common.isEditedSavedSearchPanelCard],
  (isEditedSavedSearchPanelCard: boolean) => isEditedSavedSearchPanelCard
);

export type PanelCardActivClassSelector = (state: RootState) => string;
export const selectPanelCardActiveClass: PanelCardActivClassSelector = createSelector(
  [(state: RootState) => state.common.panelCardActivClass],
  (panelCardActivClass: string) => panelCardActivClass
);

export type IsEditedSavedSearchCardSelector = (state: RootState) => boolean;
export const selectIsEditedSavedSearchCard: IsEditedSavedSearchCardSelector = createSelector(
  [(state: RootState) => state.common.isEditedSavedSearchCard],
  (isEditedSavedSearchCard: boolean) => isEditedSavedSearchCard
);

export type CardActiveClassSelector = (state: RootState) => string;
export const selectCardActiveClass: CardActiveClassSelector = createSelector(
  [(state: RootState) => state.common.cardActiveClass],
  (cardActiveClass: string) => cardActiveClass
);

export type IsInputFieldEditingSelector = (state: RootState) => boolean;
export const selectIsInputFieldEditing: IsInputFieldEditingSelector = createSelector(
  [(state: RootState) => state.common.isInputTextFieldTextEditing],
  (isInputTextFieldTextEditing: boolean) => isInputTextFieldTextEditing
);

export type IsEditableOverlayPopupVisibleSelector = (state: RootState) => boolean;
export const selectIsEditableOverlayPopupVisible: IsEditableOverlayPopupVisibleSelector = createSelector(
  [(state: RootState) => state.common.isEditableOverlayPopupVisible],
  (isEditableOverlayPopupVisible: boolean) => isEditableOverlayPopupVisible
);

export type IsDropdownEditingSelector = (state: RootState) => boolean;
export const selectIsDropdownEditing: IsDropdownEditingSelector = createSelector(
  [(state: RootState) => state.common.isDropdownEditing],
  (isDropdownEditing: boolean) => isDropdownEditing
);

export type EditableFieldActionsIdSelector = (state: RootState) => number;
export const selectEditableFieldActionsId: EditableFieldActionsIdSelector = createSelector(
  [(state: RootState) => state.common.editableFieldActionsId],
  (editableFieldActionsId: number) => editableFieldActionsId
);

export type IsDatePickerFieldEditingSelector = (state: RootState) => boolean;
export const selectIsDatePickerFieldEditing: IsDatePickerFieldEditingSelector = createSelector(
  [(state: RootState) => state.common.isDatePickerFieldEditing],
  (isDatePickerFieldEditing: boolean) => isDatePickerFieldEditing
);

export type IsUserRegisteredSelector = (state: RootState) => boolean;
export const selectIsUserRegistered: IsUserRegisteredSelector = createSelector(
  [(state: RootState) => state.common.isUserRegistered],
  (isUserRegistered: boolean) => isUserRegistered
);

export type IsLoadingSignedInUserSelector = (state: RootState) => boolean;
export const selectIsLoadingSignedInUser: IsLoadingSignedInUserSelector = createSelector(
  [(state: RootState) => state.common.isLoadingSignedInUser],
  (isLoadingSignedInUser: boolean) => isLoadingSignedInUser
);

export type IsInTeamsSelector = (state: RootState) => boolean;
export const selectIsInTeams: IsInTeamsSelector = createSelector(
  (state: RootState) => state.common.isInTeams,
  (isInTeams: boolean) => isInTeams
);

export type IsFlatFileOpenSelector = (state: RootState) => boolean;
export const selectIsFlatfileOpen: IsFlatFileOpenSelector = createSelector(
  (state: RootState) => state.common.isFlatFileOpen,
  (isFlatFileOpen: boolean) => isFlatFileOpen
);

export type TenantIdSelector = (state: RootState) => string;
export const selectTenantId: TenantIdSelector = createSelector(
  (state: RootState) => state.common.tenantId,
  (tenantId: string) => tenantId
);

export type JobTitleToAddSelector = (state: RootState) => JobTitle;
export const selectJobTitleToAdd: JobTitleToAddSelector = createSelector(
  (state: RootState) => state.common.jobTitleToAdd,
  (jobTitleToAdd: JobTitle) => jobTitleToAdd
);

export type JobTitleIdToRemoveSelector = (state: RootState) => string;
export const selectJobTitleIdToRemove: JobTitleIdToRemoveSelector = createSelector(
  (state: RootState) => state.common.jobTitleIdToRemove,
  (jobTitleIdToRemove: string) => jobTitleIdToRemove
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setAudienceCurrentUrl: (state, action: PayloadAction<string>) => {
      state.audienceCurrentUrl = action.payload;
    },
    setActivitiesCurrentUrl: (state, action: PayloadAction<string>) => {
      state.activitiesCurrentUrl = action.payload;
    },
    openBottomPopup: (state, action: PayloadAction<BottomPopupItems[]>) => {
      state.isBottomPopupVisible = true;
      state.bottomPopupItems = action.payload;
    },
    closeBottomPopup: (state) => {
      state.isBottomPopupVisible = false;
    },
    openTableColumnSelectionPopup: (state) => {
      state.isColumnSelectionPopupVisible = true;
    },
    closeTableColumnSelectionPopup: (state) => {
      state.isColumnSelectionPopupVisible = false;
    },
    showUndoAlert: (state, action: PayloadAction<string>) => {
      state.isUndoAlertVisible = true;
      state.undoAlertMessage = action.payload;
    },
    showAlertNotification: (state, action: PayloadAction<string>) => {
      state.isAlertNotificationVisible = true;
      state.alertNotificationMessage = action.payload;
    },
    setAlertData: (state, action: PayloadAction<MessageData>) => {
      state.isAlertNotificationVisible = true;
      state.alertData = action.payload;
    },
    hideAlertNotification: (state) => {
      state.isAlertNotificationVisible = false;
      state.alertNotificationMessage = '';
      state.alertData = {} as MessageData;
    },
    setEditableFieldId: (state, action: PayloadAction<number>) => {
      state.editableFieldId = action.payload;
    },
    setEditableFieldActionsId: (state, action: PayloadAction<number>) => {
      state.editableFieldActionsId = action.payload;
    },
    showEditAndCopyActions: (state) => {
      state.areEditAndCopyActionsVisible = true;
    },
    hideEditAndCopyActions: (state) => {
      state.areEditAndCopyActionsVisible = false;
    },
    hideUndoAlert: (state) => {
      state.isUndoAlertVisible = false;
      state.canceledUndoTimer = false;
    },
    setIsOnTouch: (state, action: PayloadAction<boolean>) => {
      state.isOnTouch = action.payload;
      state.isOnDesktop = false;
      state.isOnMobile = false;
      state.isOnTablet = false;
    },
    setIsOnDesktop: (state, action: PayloadAction<boolean>) => {
      state.isOnDesktop = action.payload;
      state.searchResultsColumnNumber = 6;
      state.isOnMobile = false;
      state.isOnTablet = false;
      state.isOnTouch = false;
    },
    setIsOnTablet: (state, action: PayloadAction<boolean>) => {
      state.isOnTablet = action.payload;
      state.searchResultsColumnNumber = 3;
      state.isOnMobile = false;
      state.isOnDesktop = false;
    },
    setIsOnMobile: (state, action: PayloadAction<boolean>) => {
      state.isOnMobile = action.payload;
      state.searchResultsColumnNumber = 2;
      state.isOnDesktop = false;
      state.isOnTablet = false;
    },
    toggleSearch: (state) => {
      state.toggleSearch = !state.toggleSearch;
    },
    toggleSearchPopup: (state) => {
      state.toggleSearchPopup = !state.toggleSearchPopup;
    },
    mentionSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.mentionSuggestionsSearchText = action.payload;
    },
    mentionSuggestionsReceived: (state, action: PayloadAction<CommentUser[]>) => {
      state.mentionSuggestions = action.payload;
    },
    resetMentionSuggestions: (state) => {
      state.mentionSuggestions = [];
    },
    openTableMobileColumnSelectionPopup: (state) => {
      state.isMobileColumnSelectionPopupVisible = true;
    },
    closeTableMobileColumnSelectionPopup: (state) => {
      state.isMobileColumnSelectionPopupVisible = false;
    },
    cancelUndoTimerToCompletePreviousAction: (state, action: PayloadAction<boolean>) => {
      state.canceledUndoTimer = action.payload;
    },
    openFilterPanel: (state) => {
      state.isFilterPanelOpen = true;
    },
    closeFilterPanel: (state) => {
      state.isFilterPanelOpen = false;
    },
    getAllJobTitlesRequested: (state) => {
      state.isLoadingAllJobTitles = true;
    },
    allJobTitlesReceived: (state, action: PayloadAction<JobTitle[]>) => {
      state.allJobTitles = action.payload;
      state.isLoadingAllJobTitles = false;
    },
    addJobTitleRequested: (state, action: PayloadAction<JobTitle>) => {
      state.jobTitleToAdd = action.payload;
    },
    jobTitleAdded: (state, action: PayloadAction<JobTitle>) => {
      state.allJobTitles = [...state.allJobTitles, action.payload];
    },
    removeJobTitleRequested: (state, action: PayloadAction<string>) => {
      state.jobTitleIdToRemove = action.payload;
    },
    jobTitleRemoved: (state, action: PayloadAction<string>) => {
      state.allJobTitles = state.allJobTitles.filter((jt) => jt.id !== action.payload);
    },
    setLastInteractionWithEmailAddress: (state, action: PayloadAction<string>) => {
      state.lastInteractionWithEmailAdress = action.payload;
    },
    setSignedInUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.signedInUserOid = action.payload.oid;
      state.signedInUserEmailAddress = action.payload.emailAddress;
      state.signedInUserUsername = action.payload.name;
    },
    authenticateUserRequested: (state) => {
      state.isLoadingSignedInUser = true;
    },
    setContext: (state, action: PayloadAction<app.Context>) => {
      state.context = action.payload;
    },
    setErrorData: (state, action: PayloadAction<MessageData>) => {
      state.errorData = action.payload;
    },
    resetErrorData: (state) => {
      state.errorData = {} as MessageData;
    },
    setSignedInUser: (state, action: PayloadAction<User>) => {
      state.signedInUser = action.payload;
    },
    setTenantId: (state, action: PayloadAction<string>) => {
      state.tenantId = action.payload;
    },
    signOut: (state) => {
      state.signedInUser = null;
      state.signedInUserOid = null;
      state.signedInUserEmailAddress = null;
      state.signedInUserUsername = null;
      state.tenantId = '';
    },
    setSelectedTableRows: (state, action: PayloadAction<SearchResultTableData>) => {
      state.selectedTableRows = action.payload;
    },
    setSearchResultTableRows: (state, action: PayloadAction<any[]>) => {
      state.searchResultsTableRows = action.payload;
    },
    dataSourcesRequested: () => {},
    dataSourcesReceived: (state, action: PayloadAction<DataSource[]>) => {
      state.dataSources = action.payload;
      state.ownDataSource = state.dataSources.find((d) => !d.key);
    },
    openExportDialog: (state) => {
      state.isExportDialogVisible = true;
    },
    closeExportDialog: (state) => {
      state.isExportDialogVisible = false;
    },
    toggleNavigationMenu: (state) => {
      state.isNavigationCollapsed = !state.isNavigationCollapsed;
    },
    openSearchPopupOnMobile: (state) => {
      state.isSearchPopupOnMobileOpen = true;
    },
    closeSearchPopupOnMobile: (state) => {
      state.isSearchPopupOnMobileOpen = false;
    },
    openSearchDropdown: (state) => {
      state.isSearchDropdownOpen = true;
    },
    closeSearchDropdown: (state) => {
      state.isSearchDropdownOpen = false;
    },
    showEditAndDeleteIconsOnCards: (state, action: PayloadAction<string>) => {
      state.areEditAndDeleteIconsOnCardVisible = true;
      state.savedSearchCardKey = action.payload;
    },
    hideEditAndDeleteIconsOnCards: (state, action: PayloadAction<string>) => {
      state.areEditAndDeleteIconsOnCardVisible = false;
      state.savedSearchCardKey = '';
    },
    showCardEditInput: (state, action: PayloadAction<string>) => {
      state.isCardEditing = true;
      state.savedSearchCardKey = action.payload;
      state.areEditAndDeleteIconsOnCardVisible = false;
      state.cardActiveClass = 'active';
    },
    hideCardEditInput: (state) => {
      state.isCardEditing = false;
      state.areEditAndDeleteIconsOnCardVisible = true;
    },
    disallowGoToResultsOnClickCard: (state) => {
      state.allowClickOnCard = false;
    },
    allowGoToResultsOnClickCard: (state) => {
      state.allowClickOnCard = true;
    },
    showEditAndDeleteIconsOnPanelCards: (state, action: PayloadAction<string>) => {
      state.savedSearchCardPanelKey = action.payload;
    },
    hideEditAndDeleteIconsOnPanelCards: (state, action: PayloadAction<string>) => {
      state.savedSearchCardPanelKey = '';
    },
    showCardPanelEditInput: (state, action: PayloadAction<string>) => {
      state.isPanelCardEditing = true;
      state.savedSearchCardPanelKey = action.payload;
      state.panelCardActivClass = 'active';
    },
    hideCardPanelEditInput: (state) => {
      state.isPanelCardEditing = false;
    },
    disallowGoToResultsOnClickPanelCard: (state) => {
      state.allowClickOnPanelCard = false;
    },
    allowGoToResultsOnClickPanelCard: (state) => {
      state.allowClickOnPanelCard = true;
    },
    showActiveClassOnPanelCard: (state) => {
      state.isEditedSavedSearchPanelCard = true;
    },
    hideActiveClassOnPanelCard: (state) => {
      state.isEditedSavedSearchPanelCard = false;
    },
    showActiveClassOnCard: (state) => {
      state.isEditedSavedSearchCard = true;
    },
    hideActiveClassOnCard: (state) => {
      state.isEditedSavedSearchCard = false;
    },
    showEditInputFieldText: (state) => {
      state.isInputTextFieldTextEditing = true;
    },
    hideEditInputFieldText: (state) => {
      state.isInputTextFieldTextEditing = false;
    },
    showEditableOverlayBottomPopup: (state) => {
      state.isEditableOverlayPopupVisible = true;
    },
    hideEditableOverlayBottomPopup: (state) => {
      state.isEditableOverlayPopupVisible = false;
    },
    showDropdownEditActions: (state) => {
      state.isDropdownEditing = true;
    },
    hideDropdownEditActions: (state) => {
      state.isDropdownEditing = false;
    },
    showEditDatePickerField: (state) => {
      state.isDatePickerFieldEditing = true;
    },
    hideEditDatePickerField: (state) => {
      state.isDatePickerFieldEditing = false;
    },
    openDeleteEntitiesDialog: (state) => {
      state.isDeleteEntitiesDialogOpen = true;
    },
    closeDeleteEntitiesDialog: (state) => {
      state.isDeleteEntitiesDialogOpen = false;
    },
    setUserIsNotRegistered: (state) => {
      state.isUserRegistered = false;
      state.isLoadingSignedInUser = false;
    },
    startLoadingSignedInUser: (state) => {
      state.isLoadingSignedInUser = true;
    },
    setIsInTeams: (state, action: PayloadAction<boolean>) => {
      state.isInTeams = action.payload;
    },
    openFlatFile: (state) => {
      state.isFlatFileOpen = true;
    },
    closeFlatfile: (state) => {
      state.isFlatFileOpen = false;
    },
    getLanguagesRequested: () => {},
    languagesReceived: (state, action: PayloadAction<Language[]>) => {
      state.languages = action.payload;
    }
  }
});

export const {
  setAudienceCurrentUrl,
  setActivitiesCurrentUrl,
  openBottomPopup,
  closeBottomPopup,
  setIsOnTouch,
  setIsOnTablet,
  setIsOnMobile,
  setIsOnDesktop,
  mentionSuggestionsReceived,
  mentionSuggestionsRequested,
  resetMentionSuggestions,
  openTableMobileColumnSelectionPopup,
  closeTableMobileColumnSelectionPopup,
  showUndoAlert,
  showAlertNotification,
  setAlertData,
  hideAlertNotification,
  hideUndoAlert,
  openFilterPanel,
  closeFilterPanel,
  openTableColumnSelectionPopup,
  closeTableColumnSelectionPopup,
  getAllJobTitlesRequested,
  allJobTitlesReceived,
  setContext,
  setErrorData,
  resetErrorData,
  setSignedInUser,
  setTenantId,
  setSignedInUserInfo,
  setSelectedTableRows,
  dataSourcesRequested,
  dataSourcesReceived,
  openExportDialog,
  closeExportDialog,
  toggleNavigationMenu,
  showEditAndCopyActions,
  hideEditAndCopyActions,
  setEditableFieldId,
  openSearchPopupOnMobile,
  closeSearchPopupOnMobile,
  openSearchDropdown,
  closeSearchDropdown,
  showEditAndDeleteIconsOnCards,
  hideEditAndDeleteIconsOnCards,
  showCardEditInput,
  hideCardEditInput,
  disallowGoToResultsOnClickCard,
  allowGoToResultsOnClickCard,
  showEditAndDeleteIconsOnPanelCards,
  hideEditAndDeleteIconsOnPanelCards,
  showCardPanelEditInput,
  hideCardPanelEditInput,
  disallowGoToResultsOnClickPanelCard,
  allowGoToResultsOnClickPanelCard,
  showActiveClassOnPanelCard,
  hideActiveClassOnPanelCard,
  showActiveClassOnCard,
  hideActiveClassOnCard,
  showEditInputFieldText,
  hideEditInputFieldText,
  showEditableOverlayBottomPopup,
  hideEditableOverlayBottomPopup,
  showDropdownEditActions,
  hideDropdownEditActions,
  setEditableFieldActionsId,
  openDeleteEntitiesDialog,
  closeDeleteEntitiesDialog,
  showEditDatePickerField,
  hideEditDatePickerField,
  setUserIsNotRegistered,
  startLoadingSignedInUser,
  signOut,
  setIsInTeams,
  openFlatFile,
  closeFlatfile,
  setLastInteractionWithEmailAddress,
  getLanguagesRequested,
  languagesReceived,
  addJobTitleRequested,
  jobTitleAdded,
  removeJobTitleRequested,
  jobTitleRemoved
} = commonSlice.actions;

export default commonSlice.reducer;
