import { LongTitle, maximumSearchTextLength, selectIsOnDesktop, selectIsOnMobile, selectIsOnTouch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectCountOfLoadedCompanies, selectSearchText, selectTotalCountOfCompanies } from 'app/pages/my-audience/companies';

export const CompaniesSearchResultsIntroText = () => {
  const { formatMessage } = useIntl();
  const countOfLoadedEntities = useAppSelector<number>(selectCountOfLoadedCompanies);
  const totalCountOfCompanies = useAppSelector<number>(selectTotalCountOfCompanies);
  const searchText = useAppSelector<string>(selectSearchText);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);
  return (
    <div className="search-results-text mo-search-results-text">
      {isOnDesktop && (
        <span>
          {formatMessage({ id: 'search-results-table.intro.number-of-results' }, { visibleCount: countOfLoadedEntities, totalCount: totalCountOfCompanies })}
          <span className="searched-text">
            &quot;
            <LongTitle value={searchText} maximumLength={maximumSearchTextLength} />
            &quot;
          </span>
        </span>
      )}

      {isOnMobile && <span>{formatMessage({ id: 'search-results-table.intro.number-mobile' }, { totalCount: totalCountOfCompanies })}</span>}
    </div>
  );
};
