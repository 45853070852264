import { SavedSearchesTable, SavedSearchesTableActionBar, LinkToSavedSearchPanel } from 'app/pages/my-audience/lists-profile';

export const LinkToSavedSearchesAccordionContent = () => {
  return (
    <>
      <SavedSearchesTableActionBar />
      <SavedSearchesTable />
      <LinkToSavedSearchPanel />
    </>
  );
};
