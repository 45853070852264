import { PillData, UndoablePills, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addMediaResortToMediaOutletRequested,
  addMediaResortAndAssignToMediaOutletRequested,
  removeMediaResortFromMediaOutletRequested,
  selectSelectedMediaOutlet,
  MediaResort,
  selectAllMediaResorts,
  filterMediaResorts,
  selectFilteredMediaResorts,
  removeMediaResortsRequested,
  addMediaResortToMediaOutletInStore,
  undoAddMediaResortToMediaOutletInStore
} from 'app/pages/my-audience/media-outlet-profile';
import { addMediaResortToMediaOutletInTable, MediaOutlet, undoAddMediaResortToMediaOutletInTable } from 'app/pages/my-audience/media-outlets';
import { Permission, useAuthContext } from 'auth';

export const MediaResorts = () => {
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);
  const { formatMessage } = useIntl();
  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);
  const allMediaResorts = useAppSelector<MediaResort[]>(selectAllMediaResorts);
  const filteredMediaResorts = useAppSelector<MediaResort[]>(selectFilteredMediaResorts);

  const handleCreateMediaResort = (mediaResortName: string) =>
    dispatch(addMediaResortAndAssignToMediaOutletRequested(new MediaResort('', mediaResortName, '')));

  const addMediaResortToMediaOutletInStoreHandler = (id: string) => {
    const mediaResort = allMediaResorts.find((c) => c.id === id);
    dispatch(addMediaResortToMediaOutletInStore(mediaResort));
    dispatch(addMediaResortToMediaOutletInTable({ mediaOutletIds: [selectedMediaOutlet.id], mediaResorts: [mediaResort] }));
  };

  const removeMediaResortFromMediaOutletInStore = (id: string) => {
    const mediaResort = allMediaResorts.find((c) => c.id === id);
    dispatch(undoAddMediaResortToMediaOutletInStore(mediaResort));
    dispatch(undoAddMediaResortToMediaOutletInTable({ mediaOutletId: selectedMediaOutlet.id, mediaResort: mediaResort }));
  };

  const handleAddMediaResortToMediaOutlet = (id: string) => {
    const mediaResort = allMediaResorts.find((c) => c.id === id);
    dispatch(addMediaResortToMediaOutletRequested(mediaResort));
  };

  const handleRemoveMediaResortFromMediaOutlet = (id: string) => {
    const mediaResort = allMediaResorts.find((c) => c.id === id);
    dispatch(removeMediaResortFromMediaOutletRequested(mediaResort));
  };

  const handleFilterMediaResorts = (mediaResortName: string) => {
    dispatch(filterMediaResorts(mediaResortName));
  };

  const handleDeleteMediaResorts = (ids: string[]) => {
    const mediaResorts = allMediaResorts.filter((t) => ids.includes(t.id));
    dispatch(removeMediaResortsRequested(mediaResorts));
  };

  return (
    <div className="profile-panel-topic">
      <UndoablePills
        entityName={formatMessage({ id: 'mo-profile-tab-accordion.media-resort' })}
        selectedValues={selectedMediaOutlet.mediaResorts.map((t) => new PillData(t.id, t.name, t.dataSourceKey === ''))}
        dropdownValues={filteredMediaResorts.map((t) => new PillData(t.id, t.name, t.dataSourceKey === ''))}
        onAddValue={handleCreateMediaResort}
        onSelectValue={handleAddMediaResortToMediaOutlet}
        onDeselectValue={handleRemoveMediaResortFromMediaOutlet}
        onDeleteValues={handleDeleteMediaResorts}
        filterValues={handleFilterMediaResorts}
        enabledDelete={true}
        onSelectValueInStore={addMediaResortToMediaOutletInStoreHandler}
        onUndoSelectValueInStore={removeMediaResortFromMediaOutletInStore}
        onDeselectValueInStore={removeMediaResortFromMediaOutletInStore}
        onUndoDeselectValueInStore={addMediaResortToMediaOutletInStoreHandler}
        disabled={!hasPermissionToEdit}
      />
    </div>
  );
};
