import { NumberWrap, useAppDispatch, useAppSelector, useContactLimitationWarning, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddContactsFooter,
  AddContactsSearch,
  AddContactsTable,
  addContactsToListRequested,
  selectContactsToAddToList,
  selectListContactsTotalCount
} from 'app/pages/my-audience/lists-profile';

export const AddContacts = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { checkForContactNumberLimit } = useContactLimitationWarning();
  const contactsToAdd = useAppSelector(selectContactsToAddToList);
  const numberOfContactsInList = useAppSelector(selectListContactsTotalCount);

  const totalNumberOfContacts = numberOfContactsInList + contactsToAdd.length;

  const handleAddButtonClick = () => {
    checkForContactNumberLimit({ totalNumberOfContacts, successCallback: addContactsToList });
  };

  const addContactsToList = () => {
    dispatch(addContactsToListRequested());
  };

  return (
    <div className="wizard-content">
      <WizardIntro title={formatMessage({ id: 'list-wizards.add-contact-title' })} description={formatMessage({ id: 'list-wizards.add-contact-subtitle' })} />
      <div className="wizard-content-main">
        <NumberWrap numberValue="1">
          <AddContactsSearch />
        </NumberWrap>
        <NumberWrap numberValue="2">
          <AddContactsTable />
        </NumberWrap>
      </div>
      <AddContactsFooter onAddButtonClick={handleAddButtonClick} />
    </div>
  );
};
