import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { IColumn } from '@fluentui/react';
import { NewTable, TableHeader, useAppDispatch, useAppSelector } from 'app/common';
import { selectLocale } from 'app/localization';
import {
  List,
  ListSearchResultTableData,
  ListsSortOrder,
  ListsSortingInput,
  firstPageOfListsRequested,
  nextPageOfListsRequested,
  selectIsLoadingSearchResults,
  selectLists,
  selectSearchResultTableHeader,
  selectTotalCountOfLists,
  setSearchResultTableHeader,
  setSortingInput
} from 'app/pages/my-audience/lists';
import {
  getAllTagsRequested,
  getSelectedListRequested,
  getSelectedListSendingOverviewsRequested,
  openProfilePanel,
  pageOfListContactsRequested,
  resetListContactsTable,
  setListContactsQueryParams,
  setPanelActiveIndex,
  setSelectedContacts
} from 'app/pages/my-audience/lists-profile';
import { useIntl } from 'app/i18n';

export const ListsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { formatMessage } = useIntl();

  const isLoadingSearchResults = useAppSelector<boolean>(selectIsLoadingSearchResults);
  const header = useAppSelector<TableHeader[]>(selectSearchResultTableHeader);
  const totalCountOfLists = useAppSelector<number>(selectTotalCountOfLists);
  const lists = useAppSelector<List[]>(selectLists);
  const locale = useAppSelector<string>(selectLocale);

  const createListSearchResultTableData = (list: List): ListSearchResultTableData => {
    const createdAt = DateTime.fromJSDate(list.createdAt).setLocale(locale);
    const lastModifiedAt = DateTime.fromJSDate(list.lastModifiedAt).setLocale(locale);

    return new ListSearchResultTableData(
      list.id,
      list.id,
      list.name,
      list.imageUrl,
      list.numberOfContacts,
      list.mediumNames?.join(', '),
      list.createdByContact ? `${list.createdByContact?.firstName} ${list.createdByContact?.lastName}` : formatMessage({ id: 'error-messages.unknown-user' }),
      list.tags
        .map((tag) => tag.name)
        .filter(Boolean)
        .join(', '),
      `${createdAt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at ${createdAt.toLocaleString(DateTime.TIME_WITH_SECONDS)}`,
      list.campaign,
      `${lastModifiedAt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at ${lastModifiedAt.toLocaleString(DateTime.TIME_WITH_SECONDS)}`
    );
  };

  const tableRows = lists.map((list) => createListSearchResultTableData(list));

  const handleOpenProfile = (id: string) => {
    dispatch(setPanelActiveIndex(0));
    dispatch(openProfilePanel());
    dispatch(getSelectedListRequested(id));
    dispatch(getAllTagsRequested());
    dispatch(setListContactsQueryParams({ pageNumber: 1, searchText: '' }));
    dispatch(pageOfListContactsRequested());
    dispatch(setSelectedContacts([]));
    dispatch(getSelectedListSendingOverviewsRequested());
    dispatch(resetListContactsTable());

    navigate(`/my-audience/lists/results/${params.listsQuery}/list-info/${id}/profile`);
  };

  const handleLoadMoreLists = () => {
    dispatch(nextPageOfListsRequested());
  };

  const handleColumnClicked = (selectedColumn: IColumn) => {
    // For new sorting order take opposite of current state.
    const sortOrder: ListsSortOrder = selectedColumn.isSortedDescending ? ListsSortOrder.Asc : ListsSortOrder.Desc;
    const mediaOutletsSortingInput = new ListsSortingInput(selectedColumn.fieldName, sortOrder);
    dispatch(setSortingInput(mediaOutletsSortingInput));

    const index = header.findIndex((column) => selectedColumn.fieldName === column.fieldName);
    const changedHeader = { ...header[index], isSortedDescending: !selectedColumn.isSortedDescending, isSorted: true };

    const headerCopy = header.map((column: IColumn) => {
      return { ...column, isSorted: false, isSortedDescending: true };
    });

    headerCopy.splice(index, 1, changedHeader);
    dispatch(setSearchResultTableHeader(headerCopy));
    dispatch(firstPageOfListsRequested());
  };

  const handleUpdateTableHeader = (updatedHeader: TableHeader[]) => {
    dispatch(setSearchResultTableHeader(updatedHeader));
  };

  return (
    <NewTable
      isLoadingSearchResults={isLoadingSearchResults}
      fixedColumnIndex={0}
      onOpenProfile={handleOpenProfile}
      header={header}
      tableRows={tableRows}
      totalCountOfEntities={totalCountOfLists}
      handleLoadMore={handleLoadMoreLists}
      onColumnClicked={handleColumnClicked}
      onUpdateTableHeader={handleUpdateTableHeader}
    />
  );
};
