import { PillData, UndoablePills } from 'app/common';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import {
  addedTopicToContact,
  addTopicRequested,
  addTopicToContactRequested,
  deleteTopicsRequested,
  filterTopics,
  removedTopicFromContact,
  removeTopicFromContactRequested,
  selectAllTopics,
  selectFilteredTopics,
  selectSelectedContact
} from 'app/pages/my-audience/contact-profile';
import { Contact, Topic } from 'app/pages/my-audience/contacts';
import { Permission, useAuthContext } from 'auth';

export const Topics = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const filteredTopics = useAppSelector<Topic[]>(selectFilteredTopics);
  const allTopics = useAppSelector<Topic[]>(selectAllTopics);

  const handleCreateTopic = (topicName: string) => dispatch(addTopicRequested(new Topic('', topicName, '')));

  const addTopicToContactInStore = (id: string) => {
    const topic = allTopics.find((t) => t.id === id);
    dispatch(addedTopicToContact(topic));
  };
  const removeTopicFromContactInStore = (id: string) => {
    const topic = allTopics.find((t) => t.id === id);
    dispatch(removedTopicFromContact(topic));
  };

  const handleDeleteTopics = (ids: string[]) => {
    const topics = allTopics.filter((t) => ids.includes(t.id));
    dispatch(deleteTopicsRequested(topics));
  };

  const handleAddTopicToContact = (id: string) => {
    const topic = allTopics.find((t) => t.id === id);
    dispatch(addTopicToContactRequested(topic));
  };

  const handleRemoveTopicFromContact = (id: string) => {
    const topic = allTopics.find((t) => t.id === id);
    dispatch(removeTopicFromContactRequested(topic));
  };

  const handleFilterTopics = (topicName: string) => {
    dispatch(filterTopics(topicName));
  };

  return (
    <UndoablePills
      entityName={formatMessage({ id: 'topics-and-media-resorts-accordion-item.topic-label' })}
      selectedValues={selectedContact.topics.map((t) => new PillData(t.id, t.name, t.dataSourceKey === ''))}
      dropdownValues={filteredTopics.map((t) => new PillData(t.id, t.name, t.dataSourceKey === ''))}
      onAddValue={handleCreateTopic}
      onSelectValue={handleAddTopicToContact}
      onDeselectValue={handleRemoveTopicFromContact}
      onDeleteValues={handleDeleteTopics}
      filterValues={handleFilterTopics}
      enabledDelete={true}
      onDeselectValueInStore={removeTopicFromContactInStore}
      onSelectValueInStore={addTopicToContactInStore}
      onUndoDeselectValueInStore={addTopicToContactInStore}
      onUndoSelectValueInStore={removeTopicFromContactInStore}
      disabled={!hasPermissionToEdit}
    />
  );
};
