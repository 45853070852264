import { Button, Popup } from '@fluentui/react-northstar';
import {
  DisableUnauthorized,
  hideUndoAlert,
  PillData,
  PillsPopupContent,
  SearchResultTableData,
  selectSelectedTableRows,
  showUndoAlert,
  Tag,
  undoQueue,
  useAppDispatch,
  useAppSelector,
  useSelectedTags
} from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addedTagToCompaniesSearchResults,
  addNewTagRequested,
  addTagToCompaniesInStore,
  addTagToCompaniesRequested,
  Company,
  deleteTagsRequested,
  filterTags,
  removedTagsFromCompaniesSearchResults,
  removeTagFromCompaniesInStore,
  removeTagFromCompaniesRequested,
  selectAllTags,
  selectCompanies,
  selectFilteredTagsInPanel
} from 'app/pages/my-audience/companies';
import { Permission } from 'auth';

export const CompaniesTagsPopup = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedCompaniesTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const selectedCompaniesIds = selectedCompaniesTableRows.map((company) => company.id);
  const allCompanies = useAppSelector<Company[]>(selectCompanies);
  const selectedTags = useSelectedTags(selectedCompaniesTableRows, allCompanies);

  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);

  const onSelectValueHandler = (id: string) => {
    selectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => onAddTagToCompanies(id),
      undoAction: () => deselectValueInStore(id)
    });

    dispatch(showUndoAlert(formatMessage({ id: 'search-results-table.undo-select-tag' })));
  };

  const onDeselectValueHandler = (id: string) => {
    deselectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => onRemoveTagFromCompanies(id),
      undoAction: () => selectValueInStore(id)
    });

    dispatch(showUndoAlert(formatMessage({ id: 'search-results-table.undo-deselect-tag' })));
  };

  const deselectValueInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removedTagsFromCompaniesSearchResults({ selectedCompaniesIds, tags: [tag] }));
    dispatch(removeTagFromCompaniesInStore({ selectedCompaniesIds, tag }));
  };

  const selectValueInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addedTagToCompaniesSearchResults({ selectedCompaniesIds, tag }));
    dispatch(addTagToCompaniesInStore({ selectedCompaniesIds, tag }));
  };

  const onAddNewTag = (text: string) => {
    dispatch(addNewTagRequested({ selectedCompaniesIds, text }));
  };

  const onAddTagToCompanies = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addTagToCompaniesRequested({ selectedCompaniesIds, tag }));
    dispatch(hideUndoAlert());
  };

  const onDeleteTags = (tagIds: string[]) => {
    const tags = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tags));
  };

  const onFilterTags = (text: string) => {
    dispatch(filterTags(text));
  };

  const onRemoveTagFromCompanies = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removeTagFromCompaniesRequested({ selectedCompaniesIds, tag }));
    dispatch(hideUndoAlert());
  };

  return (
    <Popup
      trigger={
        <div>
          <DisableUnauthorized permissions={[Permission.ManageCompanies, Permission.ManageContacts]} requireAllPermissions={true}>
            <Button text content={formatMessage({ id: 'search-results-table.add-update-tag' })} />
          </DisableUnauthorized>
        </div>
      }
      key="tags"
      inline={true}
      content={
        <PillsPopupContent
          entityName={formatMessage({ id: 'labels.tag' })}
          selectedValues={selectedTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          dropdownValues={filteredTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          onAddValue={onAddNewTag}
          onSelectValue={onSelectValueHandler}
          onDeselectValue={onDeselectValueHandler}
          onDeleteValues={onDeleteTags}
          filterValues={onFilterTags}
          enabledDelete={true}
        />
      }
    />
  );
};
