import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addMediaOutletAndCollaborationRequested,
  closeCreateMediaWizard,
  CreateMediaOutletWizardSteps,
  goToNextStepCreateMedia,
  goToPreviousStepCreateMedia,
  selectCreateMediaOutletCurrentStep,
  selectIsCreateMediaOutletAddressInfoStepValid,
  selectIsCreateMediaOutletGeneralInfoStepValid,
  selectIsCreateMediaOutletProfileInfoStepValid,
  selectIsCreateMediaOutletWizardFinishButtonDisabled
} from 'app/pages/my-audience/contact-profile';

export const CreateMediaOutletFooter = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector(selectCreateMediaOutletCurrentStep);
  const isFinishButtonDisabled = useAppSelector(selectIsCreateMediaOutletWizardFinishButtonDisabled);

  const isCreateMediaOutletGeneralInfoStepValid = useAppSelector(selectIsCreateMediaOutletGeneralInfoStepValid);
  const isCreateMediaOutletAddressInfoStepValid = useAppSelector(selectIsCreateMediaOutletAddressInfoStepValid);
  const isCreateMediaOutletProfileInfoStepValid = useAppSelector(selectIsCreateMediaOutletProfileInfoStepValid);

  const handleCancelButtonClick = () => dispatch(closeCreateMediaWizard());

  const handleBackButtonClick = () => dispatch(goToPreviousStepCreateMedia());

  const handleNextButtonClick = () => dispatch(goToNextStepCreateMedia());

  const handleFinishButtonClick = () => dispatch(addMediaOutletAndCollaborationRequested());

  const isNextButtonDisabled = () => {
    if (currentStep === CreateMediaOutletWizardSteps.GeneralInformation) return !isCreateMediaOutletGeneralInfoStepValid;
    else if (currentStep === CreateMediaOutletWizardSteps.AddressInfrormation)
      return !isCreateMediaOutletGeneralInfoStepValid || !isCreateMediaOutletAddressInfoStepValid;
    else if (currentStep === CreateMediaOutletWizardSteps.ProfileInformation)
      return !isCreateMediaOutletGeneralInfoStepValid || !isCreateMediaOutletAddressInfoStepValid || !isCreateMediaOutletProfileInfoStepValid;
  };

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleCancelButtonClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > CreateMediaOutletWizardSteps.GeneralInformation && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={handleBackButtonClick} />
        )}
        {currentStep < CreateMediaOutletWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={handleNextButtonClick} disabled={isNextButtonDisabled()} />
        )}
        {currentStep === CreateMediaOutletWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.finish' })} primary onClick={handleFinishButtonClick} disabled={isFinishButtonDisabled} />
        )}
      </div>
    </div>
  );
};
