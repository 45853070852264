import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DialogType } from '@fluentui/react';
import { ShimmerTable, TransformMediumDialog, defaultCompanyId, useAppDispatch, useAppSelector, useCompaniesHeaderLocalization } from 'app/common';
import {
  Company,
  firstPageOfCompaniesRequested,
  getAllCompaniesTagsRequested,
  selectIsLoadingSearchResults,
  selectIsTransformCompanyDialogOpen,
  setIsTransformCompanyDialogOpen,
  transformCompanyIntoMediaOutletRequested
} from 'app/pages/my-audience/companies';
import { CompanyFiltersPanel } from 'app/pages/my-audience/companies-filter-search/CompanyFiltersPanel';
import { CompaniesSavedSearchesPanel } from 'app/pages/my-audience/companies-saved-searches';
import { CompanyPanel, selectSelectedCompany } from 'app/pages/my-audience/company-profile';
import {
  CompaniesActionBar,
  CompaniesExportDialog,
  CompaniesFilterPills,
  CompaniesSearchResultsIntro,
  CompaniesTable
} from 'app/pages/my-audience/company-search-results';

export const CompaniesSearchResults = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useCompaniesHeaderLocalization();

  const isTransformCompanyDialogOpen = useAppSelector<boolean>(selectIsTransformCompanyDialogOpen);
  const selectedCompany = useAppSelector<Company>(selectSelectedCompany);
  const isLoadingSearchResults = useAppSelector<boolean>(selectIsLoadingSearchResults);

  useEffect(() => {
    dispatch(firstPageOfCompaniesRequested());
    dispatch(getAllCompaniesTagsRequested());
  }, [dispatch]);

  const onDismissTransformDialog = () => {
    dispatch(setIsTransformCompanyDialogOpen(false));
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'transform-company-dialog.title' }, { name: selectedCompany?.name }),
    subText: formatMessage({ id: 'transform-company-dialog.text' })
  };

  const getTransformingMediumWarningsMessages = () => {
    const messages = [];

    if (selectedCompany?.id === defaultCompanyId) {
      messages.push(formatMessage({ id: 'delete-company-dialog.delete-default-company-text' }, { name: `${selectedCompany?.name}` }));
    }

    const companyIsExternal = !!selectedCompany?.dataSourceKey;
    if (companyIsExternal) {
      messages.push(formatMessage({ id: 'delete-company-dialog.external-db-message' }, { name: `${selectedCompany?.name}` }));
    }

    return messages;
  };

  return (
    <>
      <div className="search-results-wrap">
        <CompaniesSearchResultsIntro />
        <div className="search-results-content">
          <CompaniesFilterPills />
          <CompaniesActionBar />
          <CompaniesTable />
          {isLoadingSearchResults ? <ShimmerTable /> : <></>}
        </div>
      </div>
      <Routes>
        <Route path="saved-searches/*" element={<CompaniesSavedSearchesPanel />} />
        <Route path="company-info/:selectedCompanyTableId/*" element={<CompanyPanel />} />
        <Route path="filter-companies/*" element={<CompanyFiltersPanel />} />
      </Routes>
      {isTransformCompanyDialogOpen && (
        <TransformMediumDialog
          dialogProps={dialogContentProps}
          opened={isTransformCompanyDialogOpen}
          dismiss={onDismissTransformDialog}
          transformMediumRequested={transformCompanyIntoMediaOutletRequested}
          warningMessages={getTransformingMediumWarningsMessages()}
          deleteButtonDisabled={selectedCompany?.id === defaultCompanyId || !!selectedCompany?.dataSourceKey}
        />
      )}
      <CompaniesExportDialog />
    </>
  );
};
