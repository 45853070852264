import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { selectCompanyJobTitles, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addCompanyAndCollaborationRequested,
  closeCreateCompanyWizard,
  CreateCompanyWizardSteps,
  goToNextStepCreateCompany,
  goToPreviousStepCreateCompany,
  selectCreateCompanyCurrentStep,
  selectIsCreateCompanyAddressInfoStepValid,
  selectIsCreateCompanyGeneralInfoStepValid,
  selectIsCreateCompanyProfileInfoStepValid,
  selectIsCreateCompanyWizardFinishButtonDisabled
} from 'app/pages/my-audience/contact-profile';

export const CreateCompanyFooter = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector(selectCreateCompanyCurrentStep);
  const isCreateCompanyWizardFinishButtonDisabled = useAppSelector(selectIsCreateCompanyWizardFinishButtonDisabled);
  const companyJobTitles = useAppSelector(selectCompanyJobTitles);

  const isCreateCompanyGeneralInfoStepValid = useAppSelector(selectIsCreateCompanyGeneralInfoStepValid);
  const isCreateCompanyAddressInfoStepValid = useAppSelector(selectIsCreateCompanyAddressInfoStepValid);
  const isCreateCompanyProfileInfoStepValid = useAppSelector(selectIsCreateCompanyProfileInfoStepValid);

  const handleNextButtonClick = () => dispatch(goToNextStepCreateCompany());

  const handleCancelButtonClick = () => dispatch(closeCreateCompanyWizard());

  const handleBackButtonClick = () => dispatch(goToPreviousStepCreateCompany());

  const handleFinishButtonClick = () => dispatch(addCompanyAndCollaborationRequested(companyJobTitles));

  const isNextButtonDisabled = () => {
    if (currentStep === CreateCompanyWizardSteps.GeneralInformation) return !isCreateCompanyGeneralInfoStepValid;
    else if (currentStep === CreateCompanyWizardSteps.AddressInfrormation) return !isCreateCompanyGeneralInfoStepValid || !isCreateCompanyAddressInfoStepValid;
    else if (currentStep === CreateCompanyWizardSteps.ProfileInformation)
      return !isCreateCompanyGeneralInfoStepValid || !isCreateCompanyAddressInfoStepValid || !isCreateCompanyProfileInfoStepValid;
  };

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleCancelButtonClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > CreateCompanyWizardSteps.GeneralInformation && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={handleBackButtonClick} />
        )}
        {currentStep < CreateCompanyWizardSteps.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={handleNextButtonClick} disabled={isNextButtonDisabled()} />
        )}
        {currentStep === CreateCompanyWizardSteps.Finish && (
          <Button
            content={formatMessage({ id: 'buttons.finish' })}
            primary
            onClick={handleFinishButtonClick}
            disabled={isCreateCompanyWizardFinishButtonDisabled}
          />
        )}
      </div>
    </div>
  );
};
