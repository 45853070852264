import { User } from 'app/common';
import { ContactActivity } from 'app/pages/my-audience/contact-profile';
import { ActivityParticipant, ActivityTypes, Appointment, Call, Chat, Email, Event, Task, Webinar } from 'app/pages/my-audience/contacts';
import { DateTime } from 'luxon';

export interface ActivityFactoryProps {
  activityType: ActivityTypes;
  activity: ContactActivity;
  activityParticipants: ActivityParticipant[];
  signedInUser: User;
}

export const createActivity = ({ activityType, activity, activityParticipants, signedInUser }: ActivityFactoryProps) => {
  switch (activityType) {
    case ActivityTypes.Email:
      return new Email(
        activity.id,
        activity.subject,
        activity.comment,
        activity.title,
        activityParticipants,
        DateTime.utc().toJSDate(),
        signedInUser,
        activity.inputDate
      );
    case ActivityTypes.Call:
      return new Call(activity.id, activity.comment, activity.title, activityParticipants, activity.inputDate, signedInUser, activity.inputDate);
    case ActivityTypes.Appointment:
      return new Appointment(
        activity.id,
        activity.subject,
        activity.comment,
        activity.title,
        activityParticipants,
        DateTime.utc().toJSDate(),
        signedInUser,
        activity.inputDate,
        activity.inputDate
      );
    case ActivityTypes.Webinar:
      return new Webinar(
        activity.id,
        activity.comment,
        activity.title,
        activityParticipants,
        DateTime.utc().toJSDate(),
        signedInUser,
        activity.inputDate,
        activity.inputDate
      );
    case ActivityTypes.Event:
      return new Event(
        activity.id,
        activity.comment,
        activity.title,
        activityParticipants,
        DateTime.utc().toJSDate(),
        signedInUser,
        activity.inputDate,
        activity.inputDate
      );
    case ActivityTypes.Chat:
      return new Chat(activity.id, activity.comment, activity.title, activityParticipants, DateTime.utc().toJSDate(), signedInUser, activity.inputDate);
    case ActivityTypes.Task:
      return new Task(
        activity.id,
        activity.subject,
        activity.comment,
        activity.title,
        activityParticipants,
        DateTime.utc().toJSDate(),
        signedInUser,
        activity.inputDate
      );
  }
};
