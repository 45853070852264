import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfContactsRequested,
  selectContactsPageSize,
  selectContactsTotalCount,
  selectIsLoadingContacts,
  setPageNumber,
  setPageSize
} from 'app/pages/my-audience/media-outlet-profile';

export const ContactsTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfContacts = useAppSelector<number>(selectContactsTotalCount);
  const pageSize = useAppSelector<number>(selectContactsPageSize);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingContacts);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
    dispatch(pageOfContactsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setPageSize(pageSize));
    dispatch(pageOfContactsRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfContacts}
      isLoadingData={isLoadingContacts}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
