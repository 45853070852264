import 'app/common/components/banner/Banner.scss';
export interface BannerProps {
  imageSrc: string;
  altText: string;
  modifier: string;
}

export const Banner = ({ imageSrc, altText, modifier }: BannerProps) => {
  return (
    <div className={`banner banner--${modifier}`}>
      <div className="banner-image-wrap">
        <img className="banner-image" src={imageSrc} alt={altText} />
      </div>
    </div>
  );
};
