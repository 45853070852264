import { useRef } from 'react';
import JSZip from 'jszip';
import { ExportDialog, downloadZipFromBase64, selectIsExportDialogVisible, selectSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import { Collaboration, OwnContactData, selectContacts, selectExportHeader } from 'app/pages/my-audience/contacts';
import { createExportModelFromTableRows } from 'app/pages/my-audience/common';
import { useIntl } from 'app/i18n';
import { showVcardAttachment } from 'app/pages/my-audience/contact-profile';
import { createContactVCard } from 'app/common/factories/contact-v-card.factory';

export const ContactsExportDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const ref = useRef(null);
  const csvHeader = useAppSelector(selectExportHeader);
  const isExportDialogVisible = useAppSelector(selectIsExportDialogVisible);
  const selectedTableRows = useAppSelector(selectSelectedTableRows);
  const contactsStore = useAppSelector(selectContacts);

  const onExportVCards = () => {
    const zip = new JSZip();
    let numberOfContactsWithTheSameName = 0;

    for (let i = 0; i < selectedTableRows.length; i++) {
      const contact = contactsStore.find((c) => c.id === selectedTableRows[i].id);
      const primaryCollaboration = contact.collaborations.find((collaboration) => collaboration.isPrimary);
      const contactData: Collaboration | OwnContactData = primaryCollaboration ? primaryCollaboration : contact.ownContactData;

      const contactVCard = createContactVCard(contact, contactData);

      const contactName = `${contact.firstName}${contact.lastName}`;
      let vcfFileName = `${contactName}.vcf`;
      if (zip.file(vcfFileName)) {
        vcfFileName = `${contactName}(${numberOfContactsWithTheSameName}).vcf`;
        numberOfContactsWithTheSameName++;
      }

      zip.file(vcfFileName, contactVCard.toString());
    }

    zip.generateAsync({ type: 'base64' }).then((content) => {
      downloadZipFromBase64(content, 'contacts.zip');
      dispatch(showVcardAttachment());
    });
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a ref={ref} href="/" style={{ display: 'none' }} />
      <ExportDialog
        fileName={formatMessage({ id: 'labels.contacts' })}
        isDialogVisible={isExportDialogVisible}
        dataToExport={createExportModelFromTableRows(csvHeader, selectedTableRows)}
        onExportVCards={onExportVCards}
        showFileTypesDropdown={true}
      />
    </>
  );
};
