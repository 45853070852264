import { combineReducers, configureStore } from '@reduxjs/toolkit';
import commonReducer from 'app/common/common.slice';
import sendingWizardReducer from 'app/pages/my-activities/sending-wizard/sending-wizard.slice';
import sendingsReducer from 'app/pages/my-activities/sendings/sendings.slice';
import companiesSavedSearchesReducer from 'app/pages/my-audience/companies-saved-searches/companies-saved-searches.slice';
import companiesReducer from 'app/pages/my-audience/companies/companies.slice';
import companyProfileReducer from 'app/pages/my-audience/company-profile/company-profile.slice';
import contactProfileReducer from 'app/pages/my-audience/contact-profile/contact-profile.slice';
import contactsSavedSearchesReducer from 'app/pages/my-audience/contacts-saved-searches/contacts-saved-searches.slice';
import contactsReducer from 'app/pages/my-audience/contacts/contacts.slice';
import listProfileReducer from 'app/pages/my-audience/lists-profile/lists-profile.slice';
import localizationReducer from 'app/localization/localization.slice';
import listsReducer from 'app/pages/my-audience/lists/lists.slice';
import mediaOutletProfileReducer from 'app/pages/my-audience/media-outlet-profile/media-outlet-profile.slice';
import mediaOutletsSavedSearchesReducer from 'app/pages/my-audience/media-outlets-saved-searches/media-outlets-saved-searches.slice';
import mediaOutletsReducer from 'app/pages/my-audience/media-outlets/media-outlets.slice';
import wizardCreateListReducer from 'app/pages/my-audience/wizard-create-list/wizard-create-list.slice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'app/redux/saga';
import wizardCreateContactReducer from 'app/pages/my-audience/wizard-create-contact/wizard-create-contact.slice';
import wizardCreateCompanyReducer from 'app/pages/my-audience/wizard-create-company/wizard-create-company.slice';
import wizardCreateMediaOutletReducer from 'app/pages/my-audience/wizard-create-media-outlet/wizard-create-media-outlet.slice';
import templatesReducer from 'app/pages/my-activities/templates/templates.slice';

const reducer = combineReducers({
  contacts: contactsReducer,
  contactsSavedSearches: contactsSavedSearchesReducer,
  mediaOutletsSavedSearches: mediaOutletsSavedSearchesReducer,
  companiesSavedSearches: companiesSavedSearchesReducer,
  contactProfile: contactProfileReducer,
  mediaOutlets: mediaOutletsReducer,
  companies: companiesReducer,
  mediaOutletProfile: mediaOutletProfileReducer,
  companyProfile: companyProfileReducer,
  common: commonReducer,
  lists: listsReducer,
  listProfile: listProfileReducer,
  sendings: sendingsReducer,
  sendingWizard: sendingWizardReducer,
  localization: localizationReducer,
  wizardCreateContact: wizardCreateContactReducer,
  wizardCreateMediaOutlet: wizardCreateMediaOutletReducer,
  wizardCreateCompany: wizardCreateCompanyReducer,
  wizardCreateList: wizardCreateListReducer,
  templates: templatesReducer
});

const sagaMiddleware = createSagaMiddleware();

const config = {
  key: 'root',
  version: 3,
  storage: storage('augure-in-teams'),
  stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer<ReturnType<typeof reducer>>(config, reducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
  devTools: true
});
export const persistor = persistStore(store, null, () => {
  store.getState();
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
