import { Loader } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { selectIsImageLoading } from 'app/pages/my-activities/sending-wizard';
import { SendingImagePreview } from 'app/pages/my-activities/sending-wizard/components/preview/SendingImagePreview';

export const PreviewTemplate = () => {
  const isImageLoading = useAppSelector(selectIsImageLoading);

  return (
    <div className="wizard-panel-preview-main">
      <div className="wizard-panel-preview-image-wrap">
        <>
          {isImageLoading && <Loader size="large" labelPosition="below" />}
          <SendingImagePreview />
        </>
      </div>
    </div>
  );
};
