import {
  BottomPopupItems,
  closeBottomPopup,
  selectBottomPopupItems,
  selectIsBottomPopupVisible,
  selectIsOnTouch,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { useRef } from 'react';
import { Overlay } from '@fluentui/react';
import { useOnClickOutside } from 'usehooks-ts';
import 'app/common/components/bottom-popup.scss';

export interface IBottomPopupProps {
  isPopupVisible: boolean;
  items: BottomPopupItems[];
  hideBottomPopup: () => void;
}

export const BottomPopup = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const popupBottomItems = useAppSelector<BottomPopupItems[]>(selectBottomPopupItems);
  const isPopupVisible = useAppSelector<boolean>(selectIsBottomPopupVisible);

  const handleOnOutsideClick = () => {
    dispatch(closeBottomPopup());
  };

  useOnClickOutside(ref, handleOnOutsideClick);

  return (
    <>
      {isOnTouch && isPopupVisible ? (
        <Overlay isDarkThemed={true} className="bottom-popup-overlay" onClick={handleOnOutsideClick}>
          <div className="bottom-popup-wrap">
            <ul className="bottom-popup-list" ref={ref}>
              {popupBottomItems.map((popupBottomDetails) => (
                <li className={popupBottomDetails.className} onClick={popupBottomDetails.onClick} key={popupBottomDetails.key}>
                  <span className="bottom-popup-content" onClick={() => dispatch(closeBottomPopup())}>
                    <span className="bottom-popup-icon">{popupBottomDetails.icon}</span>
                    <span className="bottom-popup-title">{popupBottomDetails.title}</span>
                  </span>
                </li>
              ))}
            </ul>
            <span className="bottom-popup-line" aria-hidden="true"></span>
          </div>
        </Overlay>
      ) : (
        <></>
      )}
    </>
  );
};
