import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { createNewBlankEmailRequested, createSendingFromTemplateRequested } from 'app/pages/my-activities/sending-wizard';
import { selectTemplates, setTemplatesPageSize, Template, TemplateCard, TemplateNewCard, templatesRequested } from 'app/pages/my-activities/templates';
import 'app/pages/my-activities/templates/components/Templates.scss';
import { useIntl } from 'app/i18n';

export const TemplatesPreview: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const templates = useAppSelector<Template[]>(selectTemplates);
  const templatesPreviewCardsPageSize = 5;

  const onAddNewSendingClick = () => {
    dispatch(createNewBlankEmailRequested(formatMessage({ id: 'emailing-wizard.message-with-no-title-text' })));
    navigate(`/my-activities/e-mailings/emailing-wizard`);
  };

  const onViewMoreTemplates = () => {
    dispatch(setTemplatesPageSize(20));
    dispatch(templatesRequested(''));
    navigate(`/my-activities/templates`);
  };

  const onChooseTemplate = (template: Template) => {
    dispatch(createSendingFromTemplateRequested({ template, defaultSendingName: formatMessage({ id: 'emailing-wizard.message-with-no-title-text' }) }));
    navigate(`/my-activities/e-mailings/emailing-wizard`);
  };

  return (
    <div className="templates">
      <div className="templates-cards">
        <TemplateNewCard onAddNewSendingClick={onAddNewSendingClick} />
        {templates.slice(0, templatesPreviewCardsPageSize).map((template) => (
          <TemplateCard key={template.id} template={template} onChooseTemplate={onChooseTemplate} />
        ))}
      </div>
      <div className="templates-button-wrap">
        <Button text content={formatMessage({ id: 'templates-preview.more-templates' })} className="templates-button" onClick={onViewMoreTemplates} />
      </div>
    </div>
  );
};
