import { Button } from '@fluentui/react-northstar';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';

interface CreateSendingDialogProps {
  isDialogVisible: boolean;
  dialogMessage: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  onCreateSending: () => void;
  onCloseDialog: () => void;
}

export const CreateSendingDialog = ({
  isDialogVisible,
  dialogMessage,
  onCreateSending,
  onCloseDialog,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Yes'
}: CreateSendingDialogProps) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: dialogMessage
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const onCreateSendingClick = () => {
    onCreateSending();
    onCloseDialog();
  };

  return (
    <Dialog hidden={!isDialogVisible} onDismiss={onCloseDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="profile-photo-dialog">
        <DialogFooter>
          <Button primary content={confirmButtonLabel} onClick={onCreateSendingClick} />
          <Button content={cancelButtonLabel} onClick={onCloseDialog} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
