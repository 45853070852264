import { Avatar, List, Segment } from '@fluentui/react-northstar';
import { Person } from '@microsoft/mgt-react';
import { selectContext, selectMentionSuggestions, CommentUser, useAppSelector } from 'app/common';

export interface CommentMentionSuggestionsProps {
  selectSuggestion(suggestion: CommentUser): void;
}

export const CommentMentionSuggestions = ({ selectSuggestion }: CommentMentionSuggestionsProps) => {
  const teamsContext = useAppSelector(selectContext);

  const suggestions = useAppSelector<CommentUser[]>(selectMentionSuggestions);

  const onSelectSuggestion = (selectedIndex: number) => {
    selectSuggestion(suggestions[selectedIndex]);
  };

  return (
    <>
      {suggestions.length > 0 && (
        <Segment
          key="dropDown-names"
          color="grey"
          styles={{
            visibility: 'visible',
            'border-top-width': ' 0px',
            'text-align': 'left',
            display: 'block'
          }}
        >
          <List
            onSelectedIndexChange={(e, newProps) => {
              if (newProps) onSelectSuggestion(newProps.selectedIndex);
            }}
            selectable
            items={suggestions.slice(0, 4).map((suggestion, index) => ({
              key: { index },
              header: suggestion.name,
              media:
                teamsContext?.app.host.clientType === 'web' || teamsContext?.app.host.clientType === 'desktop' ? (
                  <Person userId={suggestion.aadObjectId} avatarType="photo" avatarSize="auto" />
                ) : (
                  <Avatar name={suggestion.name} />
                )
            }))}
          />
        </Segment>
      )}
    </>
  );
};
