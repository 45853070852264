import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import {
  addCommentMentionRequested,
  addCommentToMediaOutletRequested,
  selectCommentMentions,
  selectSelectedMediaOutletComments
} from 'app/pages/my-audience/media-outlet-profile';
import { CommentUser, Comment, resetMentionSuggestions, mentionSuggestionsRequested, Comments } from 'app/common';
import { Permission, useAuthContext } from 'auth';

export const MediaOutletComments = () => {
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuthContext();
  const hasPermissionToComment = hasPermission(Permission.ManageMediaOutlets);

  const comments = useAppSelector<Comment[]>(selectSelectedMediaOutletComments);
  const mentions = useAppSelector<CommentUser[]>(selectCommentMentions);

  const onAddComment = (comment: Comment) => {
    dispatch(addCommentToMediaOutletRequested(comment));
  };

  const onAddCommentMention = (mention: CommentUser) => {
    dispatch(addCommentMentionRequested(mention));
  };

  const onMentionChange = async (mention: string) => {
    if (!mention) {
      dispatch(resetMentionSuggestions());
      return;
    }
    dispatch(mentionSuggestionsRequested(mention));
  };

  return (
    <Comments
      comments={comments}
      mentions={mentions}
      addComment={onAddComment}
      addCommentMention={onAddCommentMention}
      mentionChange={onMentionChange}
      disabled={!hasPermissionToComment}
    />
  );
};
