import { useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { Accordion, Button, ChevronDownMediumIcon, MenuButton } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import {
  CompanyInfo,
  selectCompanyActiveClass,
  selectCompanyActiveIndex,
  resetCompanyActiveClass,
  openRemoveComapnyDialog,
  RemoveCompanyDialog,
  setCollaborationToRemove,
  setCompanyActiveIndex
} from 'app/pages/my-audience/contact-profile';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { FormattedMessage, useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';

interface CompaniesPreviewProps {
  companyCollaborations: Collaboration[];
}

export const CompaniesPreview = ({ companyCollaborations }: CompaniesPreviewProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const companyAccActiveIndex = useAppSelector<number[]>(selectCompanyActiveIndex);
  const companyAccActiveClass = useAppSelector<string>(selectCompanyActiveClass);

  useEffect(() => {
    if (companyAccActiveClass === 'active') {
      setTimeout(() => {
        dispatch(resetCompanyActiveClass());
      }, 5000);
    }
  }, [dispatch, companyAccActiveClass]);

  const handleOpenDialog = () => {
    dispatch(openRemoveComapnyDialog());
  };

  const actionItem = [
    {
      key: 'remove-collaboration',
      content: (
        <Button
          className="accordion-head-box-multiple-popup-item"
          icon={<Icon iconName="Delete" />}
          iconPosition="before"
          content={formatMessage({ id: 'media-outlet-and-company-tabs.remove-collaboration-button' })}
          onClick={handleOpenDialog}
          disabled={!hasPermissionToEdit}
        />
      )
    }
  ];

  const onCollaborationClick = (collaboration: Collaboration) => {
    dispatch(setCollaborationToRemove(collaboration));
  };

  return (
    <div className="editable-fields-accordion-wrap">
      <Accordion
        activeIndex={companyAccActiveIndex}
        panels={companyCollaborations.map((c, i) => ({
          title: (
            <div key={'contact-company-title-' + i}>
              <div
                className={
                  i === companyCollaborations.length - 1 ? 'editable-fields-accordion-head ' + companyAccActiveClass : 'editable-fields-accordion-head'
                }
              >
                <div className="editable-fields-accordion-content" onClick={() => dispatch(setCompanyActiveIndex(i))}>
                  <ChevronDownMediumIcon />
                  <span className="editable-fields-accordion-heading">
                    {c.jobTitle.name} {c.jobTitle.name ? formatMessage({ id: 'media-outlet-and-company-tabs.accordion-header' }) : ''} {c.medium.name}
                  </span>
                </div>
                <div className="editable-fields-accordion-action">
                  <MenuButton
                    trigger={<Button text className="editable-fields-accordion-action-trigger" icon={<Icon iconName="MoreVertical" />} />}
                    menu={actionItem}
                    on="click"
                    onClick={() => onCollaborationClick(c)}
                    position="below"
                  />
                </div>
              </div>
            </div>
          ),
          content: {
            key: 'contact-company-content-' + i,
            content: <CompanyInfo companyInfo={c} />
          }
        }))}
      />
      <RemoveCompanyDialog />
    </div>
  );
};
