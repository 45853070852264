import { MediaOutletInput } from 'app/common/graphql/generated/graphql-gateway';
import { Medium } from 'app/pages/my-audience/contact-profile';
import { Collaboration, Contact, JobTitle, MediumType, OwnContactData, PhoneNumber } from 'app/pages/my-audience/contacts';
import { MediaOutlet } from 'app/pages/my-audience/media-outlets';
import {
  AddOwnContactDataForm,
  AddProfileInformationCreateWizardForm,
  AddProfileInformationForm,
  CreateContactForm,
  EditProfileInformationForm
} from 'app/pages/my-audience/media-outlet-profile';
import { Language, ensureHttpProtocol } from 'app/common';

export const createCollaborationFromEditing = (data: EditProfileInformationForm, collaboration: Collaboration, jobTitle: JobTitle): Collaboration => {
  return new Collaboration(
    collaboration.id,
    collaboration.medium,
    jobTitle,
    data.jobDescription,
    data.address,
    data.country,
    data.landlinePhoneNumber !== '' ? new PhoneNumber(data.landlinePhoneNumber, collaboration.landlinePhoneNumber.isPrimary) : null,
    data.mobilePhoneNumber !== '' ? new PhoneNumber(data.mobilePhoneNumber, collaboration.mobilePhoneNumber.isPrimary) : null,
    data.emailAddress,
    ensureHttpProtocol(collaboration.blogUrl),
    ensureHttpProtocol(collaboration.websiteUrl),
    ensureHttpProtocol(collaboration.twitterProfileUrl),
    ensureHttpProtocol(collaboration.instagramProfileUrl),
    ensureHttpProtocol(collaboration.linkedInProfileUrl),
    ensureHttpProtocol(collaboration.youtubeProfileUrl),
    ensureHttpProtocol(collaboration.facebookProfileUrl),
    collaboration.isPrimary,
    data.city,
    data.postalCode
  );
};

export const createCollaboration = (data: AddProfileInformationForm, jobTitle: JobTitle, mediaOutlet: MediaOutlet): Collaboration => {
  return new Collaboration(
    '',
    new Medium(mediaOutlet.id, mediaOutlet.name, MediumType.MediaOutlet),
    jobTitle,
    data.jobDescription,
    data.address,
    data.country,
    data.landlinePhoneNumber ? new PhoneNumber(data.landlinePhoneNumber, false) : null,
    data.mobilePhoneNumber ? new PhoneNumber(data.mobilePhoneNumber, false) : null,
    data.emailAddress,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false,
    data.city,
    data.postalCode
  );
};

export const createNewContact = (data: CreateContactForm) => {
  return new Contact('', data.salutation, data.firstName, data.lastName, '', false, [], [], [], [], [], [], []);
};

export const createCollaborationNewContact = (data: AddProfileInformationCreateWizardForm, jobTitle: JobTitle, mediaOutlet: MediaOutlet): Collaboration => {
  return new Collaboration(
    '',
    new Medium(mediaOutlet.id, mediaOutlet.name, MediumType.MediaOutlet),
    jobTitle,
    data.jobDescription,
    data.address,
    data.country,
    data.landlinePhoneNumber ? new PhoneNumber(data.landlinePhoneNumber, false) : { value: '', isPrimary: false },
    data.mobilePhoneNumber ? new PhoneNumber(data.mobilePhoneNumber, false) : { value: '', isPrimary: false },
    data.emailAddress,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false,
    data.city,
    data.postalCode
  );
};

export const createOwnContactData = (data: AddOwnContactDataForm): OwnContactData => {
  return new OwnContactData(
    data.address,
    data.country,
    new PhoneNumber(data.landlinePhoneNumber, false),
    new PhoneNumber(data.mobilePhoneNumber, false),
    data.emailAddress,
    ensureHttpProtocol(data.blog),
    ensureHttpProtocol(data.websiteUrl),
    ensureHttpProtocol(data.twitterUrl),
    ensureHttpProtocol(data.instagramUrl),
    ensureHttpProtocol(data.linkedinUrl),
    ensureHttpProtocol(data.youtubeUrl),
    ensureHttpProtocol(data.facebookUrl),
    true,
    data.city,
    data.postalCode,
    ''
  );
};

export const createMediaOutletInputType = (mediaOutlet: MediaOutlet): MediaOutletInput => {
  const mediaOutletInput: MediaOutletInput = {
    name: mediaOutlet.name,
    audience: mediaOutlet.audienceKey
      ? {
          key: mediaOutlet.audienceKey
        }
      : null,
    category: mediaOutlet.category,
    circulation: mediaOutlet.circulation,
    comments: mediaOutlet.comments,
    contactData: mediaOutlet.contactData,
    dataSourceKey: mediaOutlet.dataSourceKey,
    description: mediaOutlet.description,
    frequency: mediaOutlet.frequencyKey
      ? {
          key: mediaOutlet.frequencyKey ?? ''
        }
      : null,
    geographicalCoverage: mediaOutlet.geographicalCoverage?.id
      ? {
          id: mediaOutlet.geographicalCoverage.id,
          name: mediaOutlet.geographicalCoverage.name
        }
      : null,
    mediaType: mediaOutlet.mediaType,
    mediumSupportTypes: mediaOutlet.mediaSupportTypesKeys.map((mst) => ({
      key: mst
    })),
    number: mediaOutlet.number,
    preferredLanguages: mediaOutlet.preferredLanguages.map((language) => new Language(language.id, language.name)),
    profilePictureUrl: mediaOutlet.profilePictureUrl,
    publicationType: mediaOutlet.publicationType,
    readership: mediaOutlet.readership,
    wemfArea: mediaOutlet.wemfArea
  };

  return mediaOutletInput;
};
