import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AadPickerSuggestion, Comment, CommentUser, EntitiesPerPage, PickerSuggestionType, ProfilePicture, TableHeader, Tag, TagProjection } from 'app/common';
import { SendingOverview } from 'app/common/graphql/generated/graphql-gateway';
import { AadContact, Contact, ExportContactCsvHeader, Group } from 'app/pages/my-audience/contacts';
import { LinkedSavedSearch, List, ListContact, PageOfListContacts } from 'app/pages/my-audience/lists';
import { SavedSearch } from 'app/pages/my-audience/saved-searches';
import { RootState } from 'app/redux/store';
import { cloneDeep } from 'lodash';
import { mapEntitiesPerPageToList } from 'app/pages/my-audience/common';

export interface ListProfileState {
  isProfilePanelOpened: boolean;
  selectedListId: string;
  selectedList: List;
  listContacts: ListContact[];
  isLoadingListContacts: boolean;
  listContactsPageNumber: number;
  listContactsPageSize: number;
  listContactsSearchText: string;
  listContactToUpdate: ListContact;
  contactsToAddToList: ListContact[];
  listContactsTotalCount: number;
  selectedLinkedSavedSearchesIds: string[];
  commentToAdd: Comment;
  commentToRemove: Comment;
  commentMentions: CommentUser[];
  allTags: Tag[];
  filteredTags: Tag[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToList: Tag;
  tagToRemoveFromList: Tag | TagProjection;
  isLoadingAllTags: boolean;
  imageToUpload: ProfilePicture;
  profilePicturePreviewUrl: string;
  isLoadingSelectedList: boolean;
  listToUpdate: List;
  panelActiveIndex: number;
  isRemoveListDialogOpened: boolean;
  isDeleteTagDialogOpened: boolean;
  listPreviousValue: List;
  wizardContacts: Contact[];
  isLoadingWizardContacts: boolean;
  wizardContactsPageNumber: number;
  wizardContactsPageSize: number;
  wizardContactsSearchText: string;
  wizardContactsTotalCount: number;
  contactsIdsToRemoveFromList: string[];
  selectedContacts: Contact[];
  selectedContactsPerPage: EntitiesPerPage<Contact>;
  pickerSuggestions: AadPickerSuggestion[];
  aadSearchText: string;
  suggestedAADContacts: AadContact[];
  suggestedAADGroups: Group[];
  aadContactsToAdd: AadContact[];
  searchedLists: List[];
  listSearchText: string;
  listsToAddContactsFrom: List[];
  contactsSavedSearches: SavedSearch[];
  contactsSavedSearchesSearchText: string;
  isLoadingContactsSavedSearches: boolean;
  savedSearchesTotalCount: number;
  contactsSavedSearchesPageNumber: number;
  contactsSavedSearchesPageSize: number;
  contactsSavedSearchesToAddToList: SavedSearch[];
  contactsSavedSearchesIdsToRemoveFromList: string[];
  allSendingOverviews: SendingOverview[];
  filteredSendingOverviews: SendingOverview[];
  linkedSavedSearchToUpdate: LinkedSavedSearch;
  tableHeader: TableHeader[];
  isChangeImageDialogOpened: boolean;
  isAddContactsWizardOpened: boolean;
  isAddContactFromListWizardOpened: boolean;
  isAddContactFromCompanyWizardOpened: boolean;
  selectedWizardContacts: Contact[];
  isRemoveContactDialogOpened: boolean;
  contactsIdsToEdit: string[];
  wizardContactsIdsToEdit: string[];
  selectedAadContacts: AadContact[];
  selectedListInWizardId: string;
  exportedContactsByteArray: Uint8Array;
  exportedContacts: boolean;
  csvHeader: ExportContactCsvHeader;
  exportFileName: string;
  isExportContactsDialogVisible: boolean;
  isAddSavedSearchLinkPanelOpened: boolean;
  numberOfDesynchronizedContacts: number;
  loadingAddingAadContactsToList: boolean;
  contactsIdsToFetch: string[];
}

const initialState: ListProfileState = {
  isProfilePanelOpened: false,
  selectedListId: '',
  selectedList: {} as List,
  commentToAdd: {} as Comment,
  commentToRemove: {} as Comment,
  commentMentions: [],
  allTags: [],
  filteredTags: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToList: {} as Tag,
  tagToRemoveFromList: {} as Tag,
  isLoadingAllTags: false,
  imageToUpload: {} as ProfilePicture,
  profilePicturePreviewUrl: '',
  isLoadingSelectedList: true,
  listToUpdate: {} as List,
  panelActiveIndex: 0,
  isRemoveListDialogOpened: false,
  listContacts: [],
  isLoadingListContacts: true,
  listContactsPageNumber: 1,
  listContactsPageSize: 15,
  listContactsSearchText: '',
  wizardContacts: [],
  isLoadingWizardContacts: true,
  wizardContactsPageNumber: 1,
  wizardContactsPageSize: 15,
  wizardContactsSearchText: '',
  wizardContactsTotalCount: 0,
  contactsToAddToList: [],
  contactsIdsToRemoveFromList: [],
  selectedContacts: [],
  selectedContactsPerPage: {} as EntitiesPerPage<Contact>,
  pickerSuggestions: [],
  aadSearchText: '',
  suggestedAADContacts: [],
  suggestedAADGroups: [],
  aadContactsToAdd: [],
  isDeleteTagDialogOpened: false,
  listPreviousValue: {} as List,
  searchedLists: [],
  listSearchText: '',
  listsToAddContactsFrom: [],
  contactsSavedSearches: [],
  contactsSavedSearchesSearchText: '',
  contactsSavedSearchesPageNumber: 1,
  contactsSavedSearchesPageSize: 10,
  isLoadingContactsSavedSearches: false,
  contactsSavedSearchesToAddToList: [],
  contactsSavedSearchesIdsToRemoveFromList: [],
  selectedLinkedSavedSearchesIds: [],
  savedSearchesTotalCount: 0,
  allSendingOverviews: [],
  filteredSendingOverviews: [],
  linkedSavedSearchToUpdate: {} as LinkedSavedSearch,
  tableHeader: [],
  isChangeImageDialogOpened: false,
  isAddContactsWizardOpened: false,
  isAddContactFromListWizardOpened: false,
  isAddContactFromCompanyWizardOpened: false,
  selectedWizardContacts: [],
  isRemoveContactDialogOpened: false,
  contactsIdsToEdit: [],
  wizardContactsIdsToEdit: [],
  selectedAadContacts: [],
  selectedListInWizardId: '',
  exportedContactsByteArray: new Uint8Array(),
  exportedContacts: false,
  exportFileName: '',
  isExportContactsDialogVisible: false,
  isAddSavedSearchLinkPanelOpened: false,
  csvHeader: {} as ExportContactCsvHeader,
  numberOfDesynchronizedContacts: 0,
  loadingAddingAadContactsToList: false,
  contactsIdsToFetch: [],
  listContactToUpdate: {} as ListContact,
  listContactsTotalCount: 0
};
export type IsDeleteTagDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsDeleteTagDialogOpened: IsDeleteTagDialogOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isDeleteTagDialogOpened],
  (isDeleteTagDialogOpened: boolean) => isDeleteTagDialogOpened
);

export type IsExportContactsDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsExportContactsDialogVisible: IsExportContactsDialogVisibleSelector = createSelector(
  [(state: RootState) => state.listProfile.isExportContactsDialogVisible],
  (isExportContactsDialogVisible: boolean) => isExportContactsDialogVisible
);

export type ExportedContactsFlagSelector = (state: RootState) => boolean;
export const selectExportedContactsFlag: ExportedContactsFlagSelector = createSelector(
  [(state: RootState) => state.listProfile.exportedContacts],
  (exportedContacts: boolean) => exportedContacts
);

export type ExportedContactsByteArraySelector = (state: RootState) => Uint8Array;
export const selectExportedContactsByteArray: ExportedContactsByteArraySelector = createSelector(
  [(state: RootState) => state.listProfile.exportedContactsByteArray],
  (exportedContactsByteArray: Uint8Array) => exportedContactsByteArray
);

export type NumberOfDesynchronizedContactsSelector = (state: RootState) => number;
export const selectNumberOfDesynchronizedContacts: NumberOfDesynchronizedContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.numberOfDesynchronizedContacts],
  (numberOfDesynchronizedContacts: number) => numberOfDesynchronizedContacts
);

export type IsRemoveListDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsRemoveListDialogOpened: IsRemoveListDialogOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isRemoveListDialogOpened],
  (isRemoveListDialogOpened: boolean) => isRemoveListDialogOpened
);

export type PanelActiveIndexSelector = (state: RootState) => number;
export const selectPanelActiveIndex: PanelActiveIndexSelector = createSelector(
  [(state: RootState) => state.listProfile.panelActiveIndex],
  (panelActiveIndex: number) => panelActiveIndex
);

export type IsProfilePanelOpenedSelector = (state: RootState) => boolean;
export const selectIsProfilePanelOpened: IsProfilePanelOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isProfilePanelOpened],
  (isProfilePanelOpened: boolean) => isProfilePanelOpened
);

export type SelectedListSelector = (state: RootState) => List;
export const selectSelectedList: SelectedListSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedList],
  (selectedList: List) => selectedList
);

export type SelectedListIdSelector = (state: RootState) => string;
export const selectSelectedListId: SelectedListIdSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedListId],
  (selectedListId: string) => selectedListId
);

export type CommentToAddToListSelector = (state: RootState) => Comment;
export const selectCommentToAddToList: CommentToAddToListSelector = createSelector(
  [(state: RootState) => state.listProfile.commentToAdd],
  (commentToAdd: Comment) => commentToAdd
);

export type CommentToRemoveFromListSelector = (state: RootState) => Comment;
export const selectCommentToRemoveFromList: CommentToRemoveFromListSelector = createSelector(
  [(state: RootState) => state.listProfile.commentToRemove],
  (commentToRemove: Comment) => commentToRemove
);

export type CommentMentionsSelector = (state: RootState) => CommentUser[];
export const selectCommentMentions: CommentMentionsSelector = createSelector(
  [(state: RootState) => state.listProfile.commentMentions],
  (commentMentions: CommentUser[]) => commentMentions
);

export type AllTagsSelector = (state: RootState) => Tag[];
export const selectAllTags: AllTagsSelector = createSelector([(state: RootState) => state.listProfile.allTags], (allTags: Tag[]) => allTags);

export type FilteredTagsSelector = (state: RootState) => Tag[];
export const selectFilteredTags: FilteredTagsSelector = createSelector(
  [(state: RootState) => state.listProfile.filteredTags],
  (filteredTags: Tag[]) => filteredTags
);

export type AllTagsValuesSelector = (state: RootState) => string[];
export const selectAllTagsValues: AllTagsValuesSelector = createSelector([(state: RootState) => state.listProfile.allTags], (allTags: Tag[]) =>
  allTags.map((tag) => tag?.name)
);

export type SelectedListTagsHeaderValueSelector = (state: RootState) => Tag[];
export const selectListTagsHeaderValue: SelectedListTagsHeaderValueSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedList.tags],
  (tags: Tag[]) => tags
);

export type tagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: tagNameToCreateSelector = createSelector(
  [(state: RootState) => state.listProfile.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type TagsToDeleteSelector = (state: RootState) => Tag[];
export const selectTagsToDelete: TagsToDeleteSelector = createSelector(
  [(state: RootState) => state.listProfile.tagsToDelete],
  (tagsToDelete: Tag[]) => tagsToDelete
);

export type tagToAddToListSelector = (state: RootState) => Tag;
export const selectTagToAddToList: tagToAddToListSelector = createSelector(
  [(state: RootState) => state.listProfile.tagToAddToList],
  (tagToAddToList: Tag) => tagToAddToList
);

export type TagToRemoveFromListSelector = (state: RootState) => Tag;
export const selectTagToRemoveFromList: TagToRemoveFromListSelector = createSelector(
  [(state: RootState) => state.listProfile.tagToRemoveFromList],
  (tagToRemoveFromList: Tag) => tagToRemoveFromList
);

export type ImageToUploadSelector = (state: RootState) => ProfilePicture;
export const selectImageToUpload: ImageToUploadSelector = createSelector(
  [(state: RootState) => state.listProfile.imageToUpload],
  (imageToUpload: ProfilePicture) => imageToUpload
);

export type ProfilePicturePreviewUrlSelector = (state: RootState) => string;
export const selectProfilePicturePreviewUrl: ProfilePicturePreviewUrlSelector = createSelector(
  [(state: RootState) => state.listProfile.profilePicturePreviewUrl],
  (profilePicturePreviewUrl: string) => profilePicturePreviewUrl
);

export type SelectedListProfilePictureUrlSelector = (state: RootState) => string;
export const selectSelectedListProfilePictureUrl: SelectedListProfilePictureUrlSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedList.imageUrl],
  (imageUrl: string) => imageUrl
);

export type IsLoadingListSelector = (state: RootState) => boolean;
export const selectIsLoadingSelectedList: IsLoadingListSelector = createSelector(
  [(state: RootState) => state.listProfile.isLoadingSelectedList],
  (isLoadingSelectedList: boolean) => isLoadingSelectedList
);

export type ListToUpdateSelector = (state: RootState) => List;
export const selectListToUpdate: ListToUpdateSelector = createSelector(
  [(state: RootState) => state.listProfile.listToUpdate],
  (listToUpdate: List) => listToUpdate
);

export type ListContactsSelector = (state: RootState) => ListContact[];
export const selectListContacts: ListContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.listContacts],
  (listContacts: ListContact[]) => listContacts
);

export type ListContactToUpdateSelector = (state: RootState) => ListContact;
export const selectListContactToUpdate: ListContactToUpdateSelector = createSelector(
  [(state: RootState) => state.listProfile.listContactToUpdate],
  (listContactToUpdate: ListContact) => listContactToUpdate
);

export type IsLoadingListContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingListContacts: IsLoadingListContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.isLoadingListContacts],
  (isLoadingListContacts: boolean) => isLoadingListContacts
);

export type ListContactsPageNumberSelector = (state: RootState) => number;
export const selectListContactsPageNumber: ListContactsPageNumberSelector = createSelector(
  [(state: RootState) => state.listProfile.listContactsPageNumber],
  (listContactsPageNumber: number) => listContactsPageNumber
);

export type ListContactsPageSizeSelector = (state: RootState) => number;
export const selectListContactsPageSize: ListContactsPageSizeSelector = createSelector(
  [(state: RootState) => state.listProfile.listContactsPageSize],
  (listContactsPageSize: number) => listContactsPageSize
);

export type ListContactsSearchTextSelector = (state: RootState) => string;
export const selectListContactsSearchText: ListContactsSearchTextSelector = createSelector(
  [(state: RootState) => state.listProfile.listContactsSearchText],
  (listContactsSearchText: string) => listContactsSearchText
);

export type ListContactsTotalCountSelector = (state: RootState) => number;
export const selectListContactsTotalCount: ListContactsTotalCountSelector = createSelector(
  [(state: RootState) => state.listProfile.listContactsTotalCount],
  (listContactsTotalCount: number) => listContactsTotalCount
);

export type WizardContactsSelector = (state: RootState) => Contact[];
export const selectWizardContacts: WizardContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.wizardContacts],
  (wizardContacts: Contact[]) => wizardContacts
);

export type IsLoadingWizardContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingWizardContacts: IsLoadingWizardContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.isLoadingWizardContacts],
  (isLoadingWizardContacts: boolean) => isLoadingWizardContacts
);

export type WizardContactsPageNumberSelector = (state: RootState) => number;
export const selectWizardContactsPageNumber: WizardContactsPageNumberSelector = createSelector(
  [(state: RootState) => state.listProfile.wizardContactsPageNumber],
  (wizardContactsPageNumber: number) => wizardContactsPageNumber
);

export type WizardContactsPageSizeSelector = (state: RootState) => number;
export const selectWizardContactsPageSize: WizardContactsPageSizeSelector = createSelector(
  [(state: RootState) => state.listProfile.wizardContactsPageSize],
  (wizardContactsPageSize: number) => wizardContactsPageSize
);

export type WizardContactsSearchTextSelector = (state: RootState) => string;
export const selectWizardContactsSearchText: WizardContactsSearchTextSelector = createSelector(
  [(state: RootState) => state.listProfile.wizardContactsSearchText],
  (wizardContactsSearchText: string) => wizardContactsSearchText
);

export type WizardContactsTotalCountSelector = (state: RootState) => number;
export const selectWizardContactsTotalCount: WizardContactsTotalCountSelector = createSelector(
  [(state: RootState) => state.listProfile.wizardContactsTotalCount],
  (wizardContactsTotalCount: number) => wizardContactsTotalCount
);

export type ContactsToAddToListSelector = (state: RootState) => ListContact[];
export const selectContactsToAddToList: ContactsToAddToListSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsToAddToList],
  (contactsToAddToList: ListContact[]) => contactsToAddToList
);

export type ContactsIdsToRemoveFromListSelector = (state: RootState) => string[];
export const selectContactsIdsToRemoveFromList: ContactsIdsToRemoveFromListSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsIdsToRemoveFromList],
  (contactsIdsToRemoveFromList: string[]) => contactsIdsToRemoveFromList
);

export type SelectedContactsSelector = (state: RootState) => Contact[];
export const selectSelectedContacts: SelectedContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedContacts],
  (selectedContacts: Contact[]) => selectedContacts
);

export type SelectedContactsPerPageSelector = (state: RootState) => EntitiesPerPage<Contact>;
export const selectSelectedContactsPerPage: SelectedContactsPerPageSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedContactsPerPage],
  (selectedContactsPerPage: EntitiesPerPage<Contact>) => selectedContactsPerPage
);

export type SelectedContactsLengthSelector = (state: RootState) => number;
export const selectSelectedContactsLength: SelectedContactsLengthSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedContacts],
  (selectedContacts: Contact[]) => selectedContacts.length
);

export type SelectedContactsIdsSelector = (state: RootState) => string[];
export const selectSelectedContactsIds: SelectedContactsIdsSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedContacts],
  (selectedContacts: Contact[]) => selectedContacts.map((c) => c.id)
);

export type PickerSuggestionsSelector = (state: RootState) => AadPickerSuggestion[];
export const selectPickerSuggestions: PickerSuggestionsSelector = createSelector(
  [(state: RootState) => state.listProfile.pickerSuggestions],
  (pickerSuggestions: AadPickerSuggestion[]) => pickerSuggestions
);

export type AADSearchTextSelector = (state: RootState) => string;
export const selectAADSearchText: AADSearchTextSelector = createSelector(
  [(state: RootState) => state.listProfile.aadSearchText],
  (aadSearchText: string) => aadSearchText
);

export type SuggestedAADContactsSelector = (state: RootState) => AadContact[];
export const selectSuggestedAADContacts: SuggestedAADContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.suggestedAADContacts],
  (suggestedAADContacts: AadContact[]) => suggestedAADContacts
);

export type SuggestedAADGroupsSelector = (state: RootState) => Group[];
export const selectSuggestedAADGroups: SuggestedAADGroupsSelector = createSelector(
  [(state: RootState) => state.listProfile.suggestedAADGroups],
  (suggestedAADGroups: Group[]) => suggestedAADGroups
);

export type AADContactsToAddSelector = (state: RootState) => AadContact[];
export const selectAADContactsToAddToList: AADContactsToAddSelector = createSelector(
  [(state: RootState) => state.listProfile.aadContactsToAdd],
  (aadContactsToAdd: AadContact[]) => aadContactsToAdd
);

export type SearchedListsSelector = (state: RootState) => List[];
export const selectSearchedLists: SearchedListsSelector = createSelector(
  [(state: RootState) => state.listProfile.searchedLists],
  (searchedLists: List[]) => searchedLists
);

export type ListSearchTextSelector = (state: RootState) => string;
export const selectListSearchText: ListSearchTextSelector = createSelector(
  [(state: RootState) => state.listProfile.listSearchText],
  (listSearchText: string) => listSearchText
);

export type ListsToAddContactsFromSelector = (state: RootState) => List[];
export const selectListsToAddContactsFrom: ListsToAddContactsFromSelector = createSelector(
  [(state: RootState) => state.listProfile.listsToAddContactsFrom],
  (listsToAddContactsFrom: List[]) => listsToAddContactsFrom
);

export type SelectedListInWizardIdSelector = (state: RootState) => string;
export const selectSelectedListInWizardId: SelectedListInWizardIdSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedListInWizardId],
  (selectedListInWizardId: string) => selectedListInWizardId
);

export type ContactsSavedSearchesSelector = (state: RootState) => SavedSearch[];
export const selectContactsSavedSearches: ContactsSavedSearchesSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsSavedSearches],
  (contactsSavedSearches: SavedSearch[]) => contactsSavedSearches
);

export type ContactsSavedSearchesToAddToListSelector = (state: RootState) => SavedSearch[];
export const selectContactsSavedSearchesToAddToList: ContactsSavedSearchesToAddToListSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsSavedSearchesToAddToList],
  (contactsSavedSearchesToAddToList: SavedSearch[]) => contactsSavedSearchesToAddToList
);

export type ContactsSavedSearchesIdsToRemoveFromListSelector = (state: RootState) => string[];
export const selectContactsSavedSearchesIdsToRemoveFromList: ContactsSavedSearchesIdsToRemoveFromListSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsSavedSearchesIdsToRemoveFromList],
  (contactsSavedSearchesIdsToRemoveFromList: string[]) => contactsSavedSearchesIdsToRemoveFromList
);

export type SelectedLinkedSavedSearchesSelector = (state: RootState) => string[];
export const selectSelectedLinkedSavedSearches: SelectedLinkedSavedSearchesSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedLinkedSavedSearchesIds],
  (selectedLinkedSavedSearches: string[]) => selectedLinkedSavedSearches
);

export type SelectedLinkedSavedSearchesIdsSelector = (state: RootState) => string[];
export const selectSelectedLinkedSavedSearchesIds: SelectedLinkedSavedSearchesIdsSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedLinkedSavedSearchesIds],
  (selectedLinkedSavedSearchesIds: string[]) => selectedLinkedSavedSearchesIds
);

export type SelectedLinkedSavedSearchesLengthSelector = (state: RootState) => number;
export const selectSelectedLinkedSavedSearchesLength: SelectedLinkedSavedSearchesLengthSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedLinkedSavedSearchesIds],
  (selectedLinkedSavedSearches: string[]) => selectedLinkedSavedSearches.length
);

export type ContactsSavedSearchesSearchTextSelector = (state: RootState) => string;
export const selectContactsSavedSearchesSearchText: ContactsSavedSearchesSearchTextSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsSavedSearchesSearchText],
  (contactsSavedSearchesSearchText: string) => contactsSavedSearchesSearchText
);

export type ContactsSavedSearchesPageNumberSelector = (state: RootState) => number;
export const selectContactsSavedSearchesPageNumber: ContactsSavedSearchesPageNumberSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsSavedSearchesPageNumber],
  (contactsSavedSearchesPageNumber: number) => contactsSavedSearchesPageNumber
);

export type ContactsSavedSearchesPageSizeSelector = (state: RootState) => number;
export const selectContactsSavedSearchesPageSize: ContactsSavedSearchesPageSizeSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsSavedSearchesPageSize],
  (contactsSavedSearchesPageSize: number) => contactsSavedSearchesPageSize
);

export type AllSendingOverviewsSelector = (state: RootState) => SendingOverview[];
export const selectAllSendingOverviews: AllSendingOverviewsSelector = createSelector(
  [(state: RootState) => state.listProfile.allSendingOverviews],
  (sendingOverviews: SendingOverview[]) => sendingOverviews
);

export type FilteredSendingOverviewsSelector = (state: RootState) => SendingOverview[];
export const selectFilteredSendingOverviews: FilteredSendingOverviewsSelector = createSelector(
  [(state: RootState) => state.listProfile.filteredSendingOverviews],
  (sendingOverviews: SendingOverview[]) => sendingOverviews
);

export type LinkedSavedSearchToUpdateSelector = (state: RootState) => LinkedSavedSearch;
export const selectLinkedSavedSearchToUpdate: LinkedSavedSearchToUpdateSelector = createSelector(
  [(state: RootState) => state.listProfile.linkedSavedSearchToUpdate],
  (linkedSavedSearchToUpdate: LinkedSavedSearch) => linkedSavedSearchToUpdate
);

export type IsChangeImageDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsChangeImageDialogOpened: IsChangeImageDialogOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isChangeImageDialogOpened],
  (isChangeImageDialogOpened: boolean) => isChangeImageDialogOpened
);

export type IsAddContactWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsAddContactWizardOpened: IsAddContactWizardOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isAddContactsWizardOpened],
  (isAddContactsWizardOpened: boolean) => isAddContactsWizardOpened
);

export type IsAddContactFromListOpenedSelector = (state: RootState) => boolean;
export const selectIsAddContactFromListOpened: IsAddContactFromListOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isAddContactFromListWizardOpened],
  (isAddContactFromListWizardOpened: boolean) => isAddContactFromListWizardOpened
);

export type IsAddContactFromCompanyWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsAddContactFromCompanyWizardOpened: IsAddContactFromCompanyWizardOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isAddContactFromCompanyWizardOpened],
  (isAddContactFromCompanyWizardOpened: boolean) => isAddContactFromCompanyWizardOpened
);

export type SelectedWizardContactsSelector = (state: RootState) => Contact[];
export const selectSelectedWizardContacts: SelectedWizardContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedWizardContacts],
  (selectedWizardContacts: Contact[]) => selectedWizardContacts
);

export type SelectedWizardContactsLengthSelector = (state: RootState) => number;
export const selectSelectedWizardContactsLength: SelectedWizardContactsLengthSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedWizardContacts],
  (selectedWizardContacts: Contact[]) => selectedWizardContacts.length
);

export type SelectedWizardContactsIdsSelector = (state: RootState) => string[];
export const selectSelectedWizardContactsIds: SelectedWizardContactsIdsSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedWizardContacts],
  (selectedWizardContacts: Contact[]) => selectedWizardContacts.map((c) => c.id)
);

export type IsRemoveContactDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsRemoveContactDialogOpened: IsRemoveContactDialogOpenedSelector = createSelector(
  [(state: RootState) => state.listProfile.isRemoveContactDialogOpened],
  (isRemoveContactDialogOpened: boolean) => isRemoveContactDialogOpened
);

export type ContactsIdsToEditSelector = (state: RootState) => string[];
export const selectContactsIdsToEdit: ContactsIdsToEditSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsIdsToEdit],
  (contactsIdsToEdit: string[]) => contactsIdsToEdit
);

export type WizardContactsIdsToEditSelector = (state: RootState) => string[];
export const selectWizardContactsIdsToEdit: WizardContactsIdsToEditSelector = createSelector(
  [(state: RootState) => state.listProfile.wizardContactsIdsToEdit],
  (wizardContactsIdsToEdit: string[]) => wizardContactsIdsToEdit
);

export type SelectedAadContactsSelector = (state: RootState) => AadContact[];
export const selectSelectedAadContacts: SelectedAadContactsSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedAadContacts],
  (selectedAadContacts: AadContact[]) => selectedAadContacts
);

export type SelectedAadContactsIdsSelector = (state: RootState) => string[];
export const selectSelectedAadContactsIds: SelectedAadContactsIdsSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedAadContacts],
  (selectedAadContacts: AadContact[]) => selectedAadContacts.map((c) => c.objectId)
);

export type SelectedAadContactsLengthSelector = (state: RootState) => number;
export const selectSelectedAadContactsLength: SelectedAadContactsLengthSelector = createSelector(
  [(state: RootState) => state.listProfile.selectedAadContacts],
  (selectedAadContacts: AadContact[]) => selectedAadContacts.length
);

export type CsvHeaderSelector = (state: RootState) => ExportContactCsvHeader;
export const selectCsvHeader: CsvHeaderSelector = createSelector(
  [(state: RootState) => state.listProfile.csvHeader],
  (csvHeader: ExportContactCsvHeader) => csvHeader
);

export type ExportFileNameSelector = (state: RootState) => string;
export const selectExportFileName: ExportFileNameSelector = createSelector(
  [(state: RootState) => state.listProfile.exportFileName],
  (exportFileName: string) => exportFileName
);

export type IsAddSavedSearchLinkPanelOpen = (state: RootState) => boolean;
export const selectIsAddSavedSearchesPanelOpened: IsAddSavedSearchLinkPanelOpen = createSelector(
  [(state: RootState) => state.listProfile.isAddSavedSearchLinkPanelOpened],
  (isAddSavedSearchLinkPanelOpened: boolean) => isAddSavedSearchLinkPanelOpened
);

export type LoadingAddingAadContactToListSelector = (state: RootState) => boolean;
export const selectLoadingAddingAadContactToList: LoadingAddingAadContactToListSelector = createSelector(
  [(state: RootState) => state.listProfile.loadingAddingAadContactsToList],
  (loadingAddingAadContactsToList: boolean) => loadingAddingAadContactsToList
);

export type ContactsIdsToFetchSelector = (state: RootState) => string[];
export const selectContactsIdsToFetch: ContactsIdsToFetchSelector = createSelector(
  [(state: RootState) => state.listProfile.contactsIdsToFetch],
  (contactsIdsToFetch: string[]) => contactsIdsToFetch
);

export type IsLoadingContactsSavedSearchesSelector = (state: RootState) => boolean;
export const selectIsLoadingContactsSavedSearches: IsLoadingContactsSavedSearchesSelector = createSelector(
  [(state: RootState) => state.listProfile.isLoadingContactsSavedSearches],
  (isLoadingContactsSavedSearches: boolean) => isLoadingContactsSavedSearches
);

export type SavedSearchesTotalCountSelector = (state: RootState) => number;
export const selectSavedSearchesTotalCount: SavedSearchesTotalCountSelector = createSelector(
  [(state: RootState) => state.listProfile.savedSearchesTotalCount],
  (savedSearchesTotalCount: number) => savedSearchesTotalCount
);

const listProfileSlice = createSlice({
  name: 'list-profile',
  initialState,
  reducers: {
    openProfilePanel: (state) => {
      state.isProfilePanelOpened = true;
    },
    closeProfilePanel: (state) => {
      state.isProfilePanelOpened = false;
      state.selectedListId = '';
    },
    getSelectedListRequested: (state, action: PayloadAction<string>) => {
      state.isLoadingSelectedList = true;
      state.selectedListId = action.payload;
      state.allSendingOverviews = [];
      state.filteredSendingOverviews = [];
    },
    selectedListReceived: (state, action: PayloadAction<List>) => {
      state.selectedList = action.payload;
      state.profilePicturePreviewUrl = state.selectedList.imageUrl;
      state.isLoadingSelectedList = false;
    },
    getSelectedListSendingOverviewsRequested: () => {},
    getSelectedListSendingOverviewsReceived: (state, action: PayloadAction<SendingOverview[]>) => {
      state.allSendingOverviews = action.payload;
      state.filteredSendingOverviews = action.payload;
    },
    addCommentToListRequested: (state, action: PayloadAction<Comment>) => {
      state.commentToAdd = action.payload;
    },
    addedCommentToList: (state) => {
      state.commentToAdd = {} as Comment;
      state.commentMentions = [];
    },
    removeCommentFromListRequested: (state, action: PayloadAction<Comment>) => {
      state.commentToRemove = action.payload;
    },
    removedCommentFromList: (state) => {
      state.commentToRemove = {} as Comment;
    },
    addCommentMentionRequested: (state, action: PayloadAction<CommentUser>) => {
      state.commentMentions = [...state.commentMentions, action.payload];
    },
    getAllTagsRequested: (state) => {
      state.isLoadingAllTags = true;
    },
    allTagsReceived: (state, action: PayloadAction<Tag[]>) => {
      state.allTags = action.payload;
      state.filteredTags = action.payload;
      state.isLoadingAllTags = false;
    },
    filterTags: (state, action: PayloadAction<string>) => {
      state.filteredTags = state.allTags.filter((tag) => tag.name.includes(action.payload));
    },
    addTagToListRequested: (state, action: PayloadAction<Tag>) => {
      state.tagToAddToList = action.payload;
    },
    addedTagToList: (state, action: PayloadAction<Tag>) => {
      state.selectedList.tags = [...state.selectedList.tags, action.payload];
      state.tagToAddToList = {} as Tag;
    },
    removeTagFromListRequested: (state, action: PayloadAction<Tag>) => {
      state.tagToRemoveFromList = action.payload;
    },
    removedTagFromList: (state, action: PayloadAction<Tag>) => {
      state.selectedList.tags = state.selectedList.tags.filter((tag) => tag.id !== action.payload.id);
      state.tagToRemoveFromList = {} as Tag;
    },
    removedTagsFromLists: (state, action: PayloadAction<string[]>) => {
      state.tagToRemoveFromList = {} as TagProjection;
      if (!state.selectedList.tags) return;
      state.selectedList.tags = state.selectedList.tags.filter((tag) => !action.payload.includes(tag.id));
    },
    removeProfilePictureRequested: (state) => {
      state.imageToUpload = {} as ProfilePicture;
    },
    updateProfilePictureRequested: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
    },
    profilePictureUpdated: (state, action: PayloadAction<string>) => {
      state.selectedList = { ...state.selectedList, imageUrl: action.payload };
      state.profilePicturePreviewUrl = action.payload;
    },
    removeProfilePicturePreview: (state) => {
      state.profilePicturePreviewUrl = '';
      state.imageToUpload = {} as ProfilePicture;
    },
    changeProfilePictureToUpload: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
      state.profilePicturePreviewUrl = state.selectedList.imageUrl;
    },
    updateListRequested: (state, action: PayloadAction<List>) => {
      state.selectedList = action.payload;
      state.listToUpdate = action.payload;
    },
    updatedList: (state, action: PayloadAction<List>) => {
      state.selectedList = action.payload;
      state.listToUpdate = {} as List;
    },
    setPanelActiveIndex: (state, action: PayloadAction<number>) => {
      state.panelActiveIndex = action.payload;
    },
    setIsRemoveListDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.isRemoveListDialogOpened = action.payload;
    },
    setIsDeleteTagDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.isDeleteTagDialogOpened = action.payload;
    },
    removeTagFromListInStore: (state, action: PayloadAction<Tag>) => {
      state.listPreviousValue = cloneDeep(state.listPreviousValue);
      state.selectedList.tags = state.selectedList.tags.filter((tag) => tag.id !== action.payload.id);
    },
    resetSelectedListAfterUndo: (state) => {
      state.selectedList = cloneDeep(state.listPreviousValue);
    },
    setListContactsQueryParams: (state, action: PayloadAction<{ pageNumber: number; searchText: string }>) => {
      state.listContactsPageNumber = action.payload.pageNumber;
      state.listContactsSearchText = action.payload.searchText;
    },
    pageOfListContactsRequested: (state) => {
      state.isLoadingListContacts = true;
    },
    pageOfListContactsReceived: (state, action: PayloadAction<PageOfListContacts>) => {
      state.listContacts = action.payload.listContacts;
      state.listContactsTotalCount = action.payload.totalCount;
      state.isLoadingListContacts = false;
    },
    setListContactsPageNumber: (state, action: PayloadAction<number>) => {
      state.listContactsPageNumber = action.payload;
    },
    setListContactsPageSize: (state, action: PayloadAction<number>) => {
      state.listContactsPageSize = action.payload;
      state.listContactsPageNumber = 1;
    },
    firstPageOfWizardContactsRequested: (state, action: PayloadAction<string>) => {
      state.isLoadingWizardContacts = true;
      state.wizardContactsPageNumber = 1;
      state.wizardContactsTotalCount = 0;
      state.wizardContacts = [];
      state.wizardContactsSearchText = action.payload;
    },
    firstPageOfWizardContactsReceived: (state, action: PayloadAction<Contact[]>) => {
      state.wizardContacts = action.payload;
      state.isLoadingWizardContacts = false;
    },
    nextPageOfWizardContactsRequested: (state) => {
      state.isLoadingWizardContacts = true;
      state.wizardContactsPageNumber = state.wizardContactsPageNumber + 1;
    },
    nextPageOfWizardContactsReceived: (state, action: PayloadAction<Contact[]>) => {
      state.wizardContacts = [...state.wizardContacts, ...action.payload];
      state.isLoadingWizardContacts = false;
    },
    setTotalCountOfWizardContacts: (state, action: PayloadAction<number>) => {
      state.wizardContactsTotalCount = action.payload;
    },
    setContactsToAddToList: (state, action: PayloadAction<ListContact[]>) => {
      state.contactsToAddToList = [
        ...state.contactsToAddToList,
        ...action.payload.filter((cw) => !state.contactsToAddToList.find((c) => c.contact.id === cw.contact.id))
      ];
    },
    removeContactsFromAddToList: (state, action: PayloadAction<string[]>) => {
      state.contactsToAddToList = state.contactsToAddToList.filter((listContact) => !action.payload.includes(listContact.contact.id));
    },
    resetContactsToAddToList: (state) => {
      state.contactsToAddToList = [];
    },
    addContactsToListRequested: () => {},
    addedContactsToList: (state) => {
      state.contactsToAddToList = [];
    },
    removeContactsFromListRequested: (state, action: PayloadAction<string[]>) => {
      state.contactsIdsToRemoveFromList = action.payload;
    },
    removedContactsFromList: (state) => {
      state.contactsIdsToRemoveFromList = [];
      state.selectedContacts = [];
    },
    setSelectedContacts: (state, action: PayloadAction<Contact[]>) => {
      state.selectedContactsPerPage[state.listContactsPageNumber] = action.payload;
      state.selectedContacts = mapEntitiesPerPageToList<Contact>(state.selectedContactsPerPage);
    },
    getPickerSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.aadSearchText = action.payload;
    },
    pickerSuggestionsReceived: (state, action: PayloadAction<{ contacts: AadContact[]; groups: Group[] }>) => {
      state.suggestedAADContacts = action.payload.contacts;
      state.suggestedAADGroups = action.payload.groups;

      const contactsPickerSuggestions: AadPickerSuggestion[] = action.payload.contacts.map((c) => {
        return { objectId: c.objectId, name: `${c.firstName} ${c.lastName}`, type: PickerSuggestionType.People, value: c };
      });
      const groupsPickerSuggestions = action.payload.groups.map((g) => {
        return { objectId: g.objectId, name: g.name, type: PickerSuggestionType.Group, value: g };
      });
      state.pickerSuggestions = [...contactsPickerSuggestions, ...groupsPickerSuggestions].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    setAadContactsToAddToList: (state, action: PayloadAction<AadContact[]>) => {
      state.aadContactsToAdd = [
        ...state.aadContactsToAdd,
        ...action.payload.filter((newContact) => !state.aadContactsToAdd.find((contact) => contact.objectId === newContact.objectId))
      ];
    },
    removeAadContactsFromContactsToAdd: (state, action: PayloadAction<string[]>) => {
      state.aadContactsToAdd = state.aadContactsToAdd.filter((aadContact) => !action.payload.includes(aadContact.objectId));
    },
    resetAadContactsToAddToList: (state) => {
      state.aadContactsToAdd = [];
    },
    addAadContactsToListRequested: (state) => {
      state.loadingAddingAadContactsToList = true;
    },
    addedAadContactsToList: (state) => {
      state.aadContactsToAdd = [];
      state.loadingAddingAadContactsToList = false;
    },
    addedNewSearchList: (state, action: PayloadAction<List>) => {
      state.searchedLists = [...state.searchedLists, action.payload];
    },
    getListsByNameRequested: (state, action: PayloadAction<string>) => {
      state.listSearchText = action.payload;
    },
    listsByNameReceived: (state, action: PayloadAction<List[]>) => {
      state.searchedLists = action.payload;
    },
    setListsToAddContactsFrom: (state, action: PayloadAction<List[]>) => {
      state.listsToAddContactsFrom = action.payload;
    },
    addContactsFromExistingListsToListRequested: () => {},
    addedContactsFromExistingListsToList: (state) => {
      state.listsToAddContactsFrom = [];
      state.contactsToAddToList = [];
    },
    setContactsSavedSearchesPageNumber: (state, action: PayloadAction<number>) => {
      state.contactsSavedSearchesPageNumber = action.payload;
    },
    setContactsSavedSearchesPageSize: (state, action: PayloadAction<number>) => {
      state.contactsSavedSearchesPageSize = action.payload;
    },
    resetAddSavedSearchesQuery: (state) => {
      state.contactsSavedSearchesPageSize = 10;
      state.contactsSavedSearchesPageNumber = 1;
      state.contactsSavedSearchesSearchText = '';
    },
    setAddContactsSavedSearchesQuery: (state, action: PayloadAction<string>) => {
      state.contactsSavedSearchesSearchText = action.payload;
    },
    pageOfContactsSavedSearchesRequested: (state) => {
      state.isLoadingContactsSavedSearches = true;
    },
    contactsSavedSearchesReceived: (state, action: PayloadAction<SavedSearch[]>) => {
      state.contactsSavedSearches = action.payload;
      state.isLoadingContactsSavedSearches = false;
    },
    setSavedSearchesTotalCount: (state, action: PayloadAction<number>) => {
      state.savedSearchesTotalCount = action.payload;
    },
    addContactsSavedSearchesToListRequested: (state, action: PayloadAction<SavedSearch[]>) => {
      state.contactsSavedSearchesToAddToList = action.payload;
    },
    addedContactsSavedSearchesToList: (state, action: PayloadAction<List>) => {
      state.selectedList = { ...state.selectedList, linkedSavedSearches: action.payload.linkedSavedSearches };
      state.contactsSavedSearchesToAddToList = [];
      state.selectedLinkedSavedSearchesIds = [];
    },
    removeContactsSavedSearchesFromListRequested: (state, action: PayloadAction<string[]>) => {
      state.contactsSavedSearchesIdsToRemoveFromList = action.payload;
    },
    removedContactsSavedSearchesFromList: (state, action: PayloadAction<List>) => {
      state.selectedList = { ...state.selectedList, linkedSavedSearches: action.payload.linkedSavedSearches };
      state.contactsSavedSearchesIdsToRemoveFromList = [];
      state.selectedLinkedSavedSearchesIds = [];
    },
    setSelectedLinkedSavedSearches: (state, action: PayloadAction<string[]>) => {
      state.selectedLinkedSavedSearchesIds = action.payload;
    },
    selectList: (state, action: PayloadAction<List>) => {
      state.selectedList = action.payload;
    },
    updateLinkedSavedSearchRequested: (state, action: PayloadAction<LinkedSavedSearch>) => {
      state.linkedSavedSearchToUpdate = action.payload;
    },
    updatedLinkedSavedSearch: (state, action: PayloadAction<List>) => {
      state.selectedList = action.payload;
      state.selectedLinkedSavedSearchesIds = [];
    },
    setSearchResultTableHeader: (state, action: PayloadAction<TableHeader[]>) => {
      state.tableHeader = action.payload;
    },
    openChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = true;
    },
    closeChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = false;
    },
    openAddContactsWizard: (state) => {
      state.isAddContactsWizardOpened = true;
    },
    closeAddContactsWizard: (state) => {
      state.isAddContactsWizardOpened = false;
      state.contactsToAddToList = [];
      state.selectedWizardContacts = [];
    },
    openAddContactFromListWizard: (state) => {
      state.isAddContactFromListWizardOpened = true;
    },
    closeAddContactFromListWizard: (state) => {
      state.isAddContactFromListWizardOpened = false;
      state.contactsToAddToList = [];
      state.selectedWizardContacts = [];
    },
    openAddContactFromCompanyWizard: (state) => {
      state.isAddContactFromCompanyWizardOpened = true;
    },
    closeAddContactFromCompanyWizard: (state) => {
      state.isAddContactFromCompanyWizardOpened = false;
      state.aadContactsToAdd = [];
      state.selectedAadContacts = [];
    },
    updateContactInListRequested: (state, action: PayloadAction<ListContact>) => {
      state.listContactToUpdate = action.payload;
    },
    contactInListUpdated: (state, action: PayloadAction<ListContact>) => {
      const index = state.listContacts.findIndex((c) => c.id === action.payload.id);
      state.listContacts[index] = { ...state.listContacts[index], mediumId: action.payload.mediumId };
      state.listContactToUpdate = {} as ListContact;
    },
    setWizardSelectedContacts: (state, action: PayloadAction<Contact[]>) => {
      state.selectedWizardContacts = action.payload;
    },
    openRemoveContactDialog: (state) => {
      state.isRemoveContactDialogOpened = true;
    },
    closeRemoveContactDialog: (state) => {
      state.isRemoveContactDialogOpened = false;
    },
    setContactsIdsToEdit: (state, action: PayloadAction<string[]>) => {
      state.contactsIdsToEdit = action.payload;
    },
    setWizardContactsIdsToEdit: (state, action: PayloadAction<string[]>) => {
      state.wizardContactsIdsToEdit = action.payload;
    },
    setAadSelectedContacts: (state, action: PayloadAction<AadContact[]>) => {
      state.selectedAadContacts = action.payload;
    },
    getContactsByListIdRequested: (state, action: PayloadAction<string>) => {
      state.selectedListInWizardId = action.payload;
    },
    contactsByListIdReceived: (state, action: PayloadAction<ListContact[]>) => {
      state.contactsToAddToList = [
        ...state.contactsToAddToList,
        ...action.payload
          .filter((cw) => !state.contactsToAddToList.find((c) => c.contact.id === cw.contact.id))
          .map((lc) => ({ ...lc, listId: state.selectedListId }))
      ];
    },
    searchSendings: (state, action: PayloadAction<string>) => {
      const searchText = action.payload;
      state.filteredSendingOverviews = state.allSendingOverviews.filter((sendingOverview) =>
        sendingOverview.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );
    },
    filterSendingsByDate: (state, action: PayloadAction<{ startDate: Date; endDate: Date }>) => {
      const { startDate, endDate } = action.payload;

      state.filteredSendingOverviews = state.allSendingOverviews.filter((s) => {
        const createdAtDate = new Date(Date.parse(s.createdAt));
        return createdAtDate >= startDate && createdAtDate <= endDate;
      });
    },
    resetSendingsFilter: (state) => {
      state.filteredSendingOverviews = [...state.allSendingOverviews];
    },
    exportContactsRequested: (state, action: PayloadAction<{ csvHeader: ExportContactCsvHeader; fileName: string }>) => {
      state.csvHeader = action.payload.csvHeader;
      state.exportFileName = action.payload.fileName;
    },
    exportContactsReceived: (state, action: PayloadAction<Uint8Array>) => {
      state.exportedContactsByteArray = action.payload;
    },
    setExportedContactsFlag: (state, action: PayloadAction<boolean>) => {
      state.exportedContacts = action.payload;
    },
    exportContactsVCardsRequested: (state, action: PayloadAction<string>) => {
      state.exportFileName = action.payload;
    },
    exportContactsVCardsReceived: (state, action: PayloadAction<Uint8Array>) => {
      state.exportedContactsByteArray = action.payload;
    },
    showExportContactsDialog: (state) => {
      state.isExportContactsDialogVisible = true;
    },
    hideExportContactsDialog: (state) => {
      state.isExportContactsDialogVisible = false;
    },
    openAddSavedSearchesPanel: (state) => {
      state.isAddSavedSearchLinkPanelOpened = true;
    },
    closeAddSavedSearchesPanel: (state) => {
      state.isAddSavedSearchLinkPanelOpened = false;
    },
    numberOfDesynchronizedContactsRequested: (state) => {
      state.numberOfDesynchronizedContacts = 0;
    },
    mediumNamesByListIdRequested: (state, action: PayloadAction<string>) => {
      state.selectedListId = action.payload;
    },
    mediumNamesByListIdReceived: (state, action: PayloadAction<{ listId: string; mediumNames: string[] }>) => {
      const { listId, mediumNames } = action.payload;
      if (listId !== state.selectedList.id) {
        return;
      }
      state.selectedList = { ...state.selectedList, mediumNames };
    },
    numberOfDesynchronizedContactsReceived: (state, action: PayloadAction<number>) => {
      state.numberOfDesynchronizedContacts = action.payload;
    },
    refreshLinkedSavedSearchesRequested: () => {},
    resetListContactsTable: (state) => {
      state.selectedContacts = [];
      state.selectedContactsPerPage = {} as EntitiesPerPage<Contact>;
      state.listContactsPageNumber = 1;
    },
    setLoadingAddingAadContactsToList: (state, action: PayloadAction<boolean>) => {
      state.loadingAddingAadContactsToList = action.payload;
    },
    getLinkedSavedSearchesByListIdRequested: (state, action: PayloadAction<string>) => {
      state.selectedListId = action.payload;
    },
    linkedSavedSearchesByListIdReceived: (state, action: PayloadAction<LinkedSavedSearch[]>) => {
      state.selectedList.linkedSavedSearches = action.payload;
    },
    updateListContactForTheCorrespondingContact: (state, action: PayloadAction<Contact>) => {
      const contact = action.payload;
      state.listContacts = state.listContacts.map((listContact) => {
        if (listContact.contact?.id === contact.id) {
          return { ...listContact, contact };
        } else {
          return listContact;
        }
      });
    }
  }
});

export const {
  openProfilePanel,
  closeProfilePanel,
  getSelectedListRequested,
  addCommentMentionRequested,
  addedCommentToList,
  addCommentToListRequested,
  removeCommentFromListRequested,
  removedCommentFromList,
  getAllTagsRequested,
  allTagsReceived,
  filterTags,
  addTagToListRequested,
  addedTagToList,
  removeTagFromListRequested,
  removedTagFromList,
  removedTagsFromLists,
  updateProfilePictureRequested,
  profilePictureUpdated,
  removeProfilePicturePreview,
  changeProfilePictureToUpload,
  removeProfilePictureRequested,
  selectedListReceived,
  updateListRequested,
  updatedList,
  setPanelActiveIndex,
  setIsRemoveListDialogOpened,
  setIsDeleteTagDialogOpened,
  resetSelectedListAfterUndo,
  removeTagFromListInStore,
  firstPageOfWizardContactsReceived,
  firstPageOfWizardContactsRequested,
  nextPageOfWizardContactsReceived,
  nextPageOfWizardContactsRequested,
  setTotalCountOfWizardContacts,
  addContactsToListRequested,
  addedContactsToList,
  removeContactsFromListRequested,
  removedContactsFromList,
  setSelectedContacts,
  getPickerSuggestionsRequested,
  pickerSuggestionsReceived,
  addAadContactsToListRequested,
  addedAadContactsToList,
  addedNewSearchList,
  getListsByNameRequested,
  listsByNameReceived,
  setListsToAddContactsFrom,
  addContactsFromExistingListsToListRequested,
  addedContactsFromExistingListsToList,
  addContactsSavedSearchesToListRequested,
  addedContactsSavedSearchesToList,
  contactsSavedSearchesReceived,
  pageOfContactsSavedSearchesRequested,
  removeContactsSavedSearchesFromListRequested,
  removedContactsSavedSearchesFromList,
  setContactsSavedSearchesPageNumber,
  getSelectedListSendingOverviewsRequested,
  selectList,
  updateLinkedSavedSearchRequested,
  updatedLinkedSavedSearch,
  setSearchResultTableHeader,
  openChangeImageDialog,
  closeChangeImageDialog,
  closeAddContactsWizard,
  openAddContactsWizard,
  openAddContactFromListWizard,
  closeAddContactFromListWizard,
  openAddContactFromCompanyWizard,
  closeAddContactFromCompanyWizard,
  contactInListUpdated,
  updateContactInListRequested,
  setContactsToAddToList,
  removeContactsFromAddToList,
  resetContactsToAddToList,
  setWizardSelectedContacts,
  closeRemoveContactDialog,
  openRemoveContactDialog,
  setContactsIdsToEdit,
  setWizardContactsIdsToEdit,
  resetAadContactsToAddToList,
  setAadContactsToAddToList,
  removeAadContactsFromContactsToAdd,
  setAadSelectedContacts,
  contactsByListIdReceived,
  getContactsByListIdRequested,
  searchSendings,
  filterSendingsByDate,
  resetSendingsFilter,
  exportContactsRequested,
  exportContactsReceived,
  setExportedContactsFlag,
  exportContactsVCardsRequested,
  exportContactsVCardsReceived,
  showExportContactsDialog,
  hideExportContactsDialog,
  openAddSavedSearchesPanel,
  closeAddSavedSearchesPanel,
  numberOfDesynchronizedContactsRequested,
  mediumNamesByListIdRequested,
  mediumNamesByListIdReceived,
  numberOfDesynchronizedContactsReceived,
  pageOfListContactsReceived,
  pageOfListContactsRequested,
  setListContactsPageNumber,
  setListContactsQueryParams,
  setListContactsPageSize,
  setSelectedLinkedSavedSearches,
  refreshLinkedSavedSearchesRequested,
  getSelectedListSendingOverviewsReceived,
  resetListContactsTable,
  setLoadingAddingAadContactsToList,
  getLinkedSavedSearchesByListIdRequested,
  linkedSavedSearchesByListIdReceived,
  updateListContactForTheCorrespondingContact,
  setSavedSearchesTotalCount,
  setAddContactsSavedSearchesQuery,
  setContactsSavedSearchesPageSize,
  resetAddSavedSearchesQuery
} = listProfileSlice.actions;

export default listProfileSlice.reducer;
