import { Template as GQLTemplate } from 'app/common/graphql/generated/graphql-gateway';
import { Template } from 'app/pages/my-activities/templates';

export const createTemplate = (gqlTemplate: GQLTemplate) => {
  return new Template(
    gqlTemplate.id,
    gqlTemplate.title,
    gqlTemplate.imageAsBase64,
    gqlTemplate.jsonBody,
    gqlTemplate.htmlBody,
    gqlTemplate.lastModifiedBy,
    new Date(gqlTemplate.lastModifiedAt),
    new Date(gqlTemplate.createdAt),
    gqlTemplate.createdBy
  );
};
