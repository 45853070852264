import { Dropdown } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';

interface ControlledCreateDropdownProps {
  label: JSX.Element;
  name: string;
  control: Control<any>;
  items: string[];
  placeholder: string;
  defaultValue: string;
  createNewEntityMessage: string;
  onCreateNewEntityClick: (name: string) => void;
}

export const ControlledCreateDropdown = ({
  label,
  name,
  control,
  items,
  placeholder,
  defaultValue,
  createNewEntityMessage,
  onCreateNewEntityClick
}: ControlledCreateDropdownProps) => {
  const { formatMessage } = useIntl();
  const [searchQuery, setSearchQuery] = useState(defaultValue);

  const handleCreateNewEntity = () => {
    onCreateNewEntityClick(searchQuery);
    setSearchQuery('');
  };

  const noResultsMessage = searchQuery ? (
    <div onClick={handleCreateNewEntity}>{createNewEntityMessage}</div>
  ) : (
    formatMessage({ id: 'emailing-wizard-setup-step.messages.no-results-found' })
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <>
          {label}
          <Dropdown
            search
            inline
            placeholder={placeholder}
            defaultValue={defaultValue}
            items={items}
            onSearchQueryChange={(_, props) => setSearchQuery(props.searchQuery)}
            onChange={(_, option) => {
              onChange(option.value);
            }}
            noResultsMessage={noResultsMessage}
            onBlur={onBlur}
            error={!value && !!error}
            searchQuery={searchQuery}
          />
          <span className="error-message">{error && error.message}</span>
        </>
      )}
    />
  );
};
