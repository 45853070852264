import { Icon } from '@fluentui/react';
import { useDebouncedCallback } from 'use-debounce';
import { Button, Input, MenuButton, SearchIcon } from '@fluentui/react-northstar';
import { debounceTimeInMilliseconds, useAppDispatch, useAppSelector } from 'app/common';
import {
  openAddContactWizard,
  openAddFromCompanyPanel,
  openAddFromListPanel,
  pageOfRecipientsRequested,
  removeRecipientsFromSendingRequested,
  selectSelectedRecipientsIds,
  setEditingRecipientsIds,
  setRecipientsQueryParams
} from 'app/pages/my-activities/sending-wizard';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AudienceToolbar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedRecipientsIds = useAppSelector<string[]>(selectSelectedRecipientsIds);
  const isSelected = selectedRecipientsIds.length > 0;
  const filterResults = (searchText: string) => {
    dispatch(setRecipientsQueryParams({ pageNumber: 1, searchText: searchText }));
    dispatch(pageOfRecipientsRequested());
  };

  const onInputChange = useDebouncedCallback(filterResults, debounceTimeInMilliseconds);

  const menuItems = [
    {
      key: 0,
      content: formatMessage({ id: 'add-contacts-menu-items.contacts' }),
      onClick: () => {
        dispatch(openAddContactWizard());
      }
    },
    {
      key: 1,
      content: formatMessage({ id: 'add-contacts-menu-items.contacts-from-company' }),
      onClick: () => {
        dispatch(openAddFromCompanyPanel());
      }
    },
    {
      key: 2,
      content: formatMessage({ id: 'add-contacts-menu-items.contacts-from-lists' }),
      onClick: () => {
        dispatch(openAddFromListPanel());
      }
    }
  ];

  const onRemoveRecipients = () => {
    dispatch(removeRecipientsFromSendingRequested(selectedRecipientsIds));
  };

  const onEditRecipientsCollaboration = () => {
    dispatch(setEditingRecipientsIds(selectedRecipientsIds));
  };

  return (
    <>
      <div className="wizard-panel-toolbar wizard-panel-toolbar-audience">
        <div className="wizard-panel-toolbar-create">
          <MenuButton
            trigger={
              <Button
                text
                icon={<Icon iconName="Add" />}
                content={formatMessage({ id: 'emailing-wizard-audience-step.add-contacts-button' })}
                aria-label={formatMessage({ id: 'aria-labels.click-button' })}
              />
            }
            menu={menuItems}
            on="click"
            className="action-bar-button"
          />
        </div>
        <div className={isSelected ? 'action-bar-buttons__active action-bar-buttons' : 'action-bar-buttons'}>
          <div className="action-bar-button remove-button">
            <Button text content={formatMessage({ id: 'mo-contact-table.edit' })} icon={<Icon iconName="Edit" />} onClick={onEditRecipientsCollaboration} />
          </div>
        </div>
        <div className={isSelected ? 'action-bar-buttons__active action-bar-buttons' : 'action-bar-buttons'}>
          <div className="action-bar-button remove-button">
            <Button text content={formatMessage({ id: 'buttons.remove' })} icon={<Icon iconName="Cancel" />} onClick={onRemoveRecipients} />
          </div>
        </div>
        <div className="wizard-panel-toolbar-filters">
          {isSelected && (
            <div className="action-bar-wrap">
              <span className="action-bar-selected">
                <FormattedMessage id={'emailing-wizard-audience-step.table-action-bar-items-selected'} values={{ counter: selectedRecipientsIds.length }} />
              </span>
            </div>
          )}
          <div className="wizard-panel-toolbar-input">
            <Input
              icon={<SearchIcon />}
              placeholder={formatMessage({ id: 'emailing-wizard-audience-step.search-input-placeholder' })}
              autoComplete="off"
              onChange={(input) => onInputChange((input.target as HTMLTextAreaElement).value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
