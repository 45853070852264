import Bee from '@mailupinc/bee-plugin';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AadPickerSuggestion, EntitiesPerPage, PickerSuggestionType, Tag, TagProjection } from 'app/common';
import { SendingAudience, SendingLog, SendingOverview } from 'app/common/graphql/generated/graphql-gateway';
import { PageOfRecipients, SendingSetup, SendingWizardStep, wizardHasNoData } from 'app/pages/my-activities/sending-wizard';
import { EmailBody, Recipient, RecipientFromList, Sender, Sending, SendingStatus, UpdateSendingRecipientInput } from 'app/pages/my-activities/sendings';
import { Template } from 'app/pages/my-activities/templates';
import { mapEntitiesPerPageToList } from 'app/pages/my-audience/common';
import { AadContact, Blacklist, Contact, Group } from 'app/pages/my-audience/contacts';
import { List } from 'app/pages/my-audience/lists';
import { RootState } from 'app/redux/store';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

export interface SendingWizardState {
  selectedSending: Sending;
  selectedTemplate: Template;
  defaultSendingName: string;
  updatedTemplate: Template;
  selectedSendingRecipients: Recipient[];
  setupHasData: boolean;
  audienceHasData: boolean;
  emailHasData: boolean;
  senders: Sender[];
  isAddContactWizardOpened: boolean;
  addContactStep: number;
  recipientsToAdd: Recipient[];
  recipientIdsToRemove: string[];
  contactSuggestions: Contact[];
  searchContactsText: string;
  recipientToUpdate: UpdateSendingRecipientInput;
  beeToken: string;
  isAddFromCompanyPanelOpened: boolean;
  aadSearchText: string;
  peoplePickerSuggestions: AadPickerSuggestion[];
  isPeoplePickerSuggestionsEmpty: boolean;
  contactsFromCompanyToAdd: AadContact[];
  image: string;
  isAddFromListPanelOpened: boolean;
  selectedListId: string;
  contactsFromListToAdd: RecipientFromList[];
  imageLoading: boolean;
  isOpenedFromTemplate: boolean;
  changeInEditorHappenedAfterOpenedFromTemplate: boolean;
  isDismissWizardDialogVisible: boolean;
  isTemplatesPopupVisible: boolean;
  beeInstance: Bee;
  isSaveTemplateDialogVisible: boolean;
  emailBodyInEditor: EmailBody;
  sendingToAddSetup: SendingSetup;
  templateToAdd: Template;
  isProfilePanelOpen: boolean;
  selectedSendingId: string;
  tagToAddToSending: TagProjection;
  tagToRemoveFromSending: TagProjection;
  tagNameToCreate: string;
  sendingPreviousValue: Sending;
  isLoadingSendingProfile: boolean;
  emailPanelActiveIndex: number;
  isDeleteRecipientDialogOpened: boolean;
  blacklistFromPreviewTab: Blacklist;
  lists: List[];
  isMissingFieldErrorDialogVisible: boolean;
  errorDialogMessage: string;
  isScheduleSendDialogVisible: boolean;
  isSendDialogVisible: boolean;
  sendingScheduledAt: DateTime;
  selectedListsIds: string[];
  sendingWizardCurrentStep: SendingWizardStep;
  updatedSending: Sending;
  addFromListCurrentStep: number;
  listsSearchText: string;
  recipientsPageSize: number;
  recipientsTotalCount: number;
  recipientsPageNumber: number;
  recipientsSearchText: string;
  isLoadingRecipients: boolean;
  isLoadingContactSuggestions: boolean;
  searchContactsByNameOrEmailPageSize: number;
  isLoadingSendingLogs: boolean;
  sendingLogsPageNumber: number;
  sendingLogsPageSize: number;
  newSending: Sending;
  sendings: Sending[];
  sendingsSearchText: string;
  sendingsPageNumber: number;
  sendingsPageSize: number;
  totalCountOfSendings: number;
  isLoadingSendings: boolean;
  selectedRecipientsIds: string[];
  selectedRecipientsIdsPerPage: EntitiesPerPage<string>;
  contactsIdsToAdd: string[];
  isOpenAiEnabled: boolean;
  editingRecipientsIds: string[];
  sendingAudience: SendingAudience;
  isLoadingSendingAudience: boolean;
  isSavingInProgress: boolean;
  shouldNavigateToSendingWizard: boolean;
  selectedSendingOverview: SendingOverview;
  isReportAIContentModalOpen: boolean;
  aiContentReportMessage: string;
}

const initialState: SendingWizardState = {
  selectedSending: {} as Sending,
  selectedTemplate: {} as Template,
  defaultSendingName: '',
  updatedTemplate: {} as Template,
  selectedSendingRecipients: [],
  setupHasData: false,
  audienceHasData: false,
  emailHasData: false,
  senders: [],
  isAddContactWizardOpened: false,
  addContactStep: 1,
  recipientsToAdd: [],
  recipientIdsToRemove: [],
  contactSuggestions: [],
  searchContactsText: '',
  recipientToUpdate: {} as UpdateSendingRecipientInput,
  beeToken: '',
  isAddFromCompanyPanelOpened: false,
  aadSearchText: '',
  peoplePickerSuggestions: [],
  isPeoplePickerSuggestionsEmpty: false,
  contactsFromCompanyToAdd: [],
  image: '',
  isAddFromListPanelOpened: false,
  selectedListId: '',
  contactsFromListToAdd: [],
  imageLoading: true,
  isOpenedFromTemplate: false,
  changeInEditorHappenedAfterOpenedFromTemplate: false,
  isDismissWizardDialogVisible: false,
  isTemplatesPopupVisible: false,
  beeInstance: {} as Bee,
  isSaveTemplateDialogVisible: false,
  emailBodyInEditor: {} as EmailBody,
  sendingToAddSetup: {} as SendingSetup,
  templateToAdd: {} as Template,
  isProfilePanelOpen: false,
  selectedSendingId: '',
  tagToAddToSending: {} as TagProjection,
  tagToRemoveFromSending: {} as TagProjection,
  tagNameToCreate: '',
  sendingPreviousValue: {} as Sending,
  isLoadingSendingProfile: false,
  emailPanelActiveIndex: 0,
  isDeleteRecipientDialogOpened: false,
  blacklistFromPreviewTab: {} as Blacklist,
  lists: [],
  isMissingFieldErrorDialogVisible: false,
  errorDialogMessage: '',
  isScheduleSendDialogVisible: false,
  isSendDialogVisible: false,
  sendingScheduledAt: {} as DateTime,
  selectedListsIds: [],
  sendingWizardCurrentStep: SendingWizardStep.Setup,
  updatedSending: {} as Sending,
  addFromListCurrentStep: 1,
  listsSearchText: '',
  recipientsPageSize: 10,
  recipientsTotalCount: 0,
  recipientsPageNumber: 1,
  recipientsSearchText: '',
  isLoadingRecipients: false,
  isLoadingContactSuggestions: false,
  searchContactsByNameOrEmailPageSize: 15,
  isLoadingSendingLogs: false,
  sendingLogsPageNumber: 1,
  sendingLogsPageSize: 10,
  newSending: {} as Sending,
  sendings: [],
  sendingsSearchText: '',
  sendingsPageNumber: 1,
  sendingsPageSize: 5,
  totalCountOfSendings: 0,
  isLoadingSendings: true,
  selectedRecipientsIds: [],
  selectedRecipientsIdsPerPage: {} as EntitiesPerPage<string>,
  contactsIdsToAdd: [],
  isOpenAiEnabled: false,
  editingRecipientsIds: [],
  sendingAudience: {},
  isLoadingSendingAudience: false,
  isSavingInProgress: false,
  shouldNavigateToSendingWizard: false,
  selectedSendingOverview: {} as SendingOverview,
  isReportAIContentModalOpen: false,
  aiContentReportMessage: ''
};

export type RecipientsTotalCountSelector = (state: RootState) => number;
export const selectRecipientsTotalCount: RecipientsTotalCountSelector = createSelector(
  [(state: RootState) => state.sendingWizard.recipientsTotalCount],
  (recipientsTotalCount: number) => recipientsTotalCount
);

export type IsOpenAiEnabledSelector = (state: RootState) => boolean;
export const selectIsOpenAiEnabled: IsOpenAiEnabledSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isOpenAiEnabled],
  (isOpenAiEnabled: boolean) => isOpenAiEnabled
);

export type SelectedSendingRecipientsSelector = (state: RootState) => Recipient[];
export const selectSelectedSendingRecipients: SelectedSendingRecipientsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSendingRecipients],
  (selectedSendingRecipients: Recipient[]) => selectedSendingRecipients
);

export type SelectedRecipientsIdsSelector = (state: RootState) => string[];
export const selectSelectedRecipientsIds: SelectedRecipientsIdsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedRecipientsIds],
  (selectedRecipientsIds: string[]) => selectedRecipientsIds
);

export type SelectedRecipientsIdsPerPageSelector = (state: RootState) => EntitiesPerPage<string>;
export const selectSelectedRecipientsIdsPerPage: SelectedRecipientsIdsPerPageSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedRecipientsIdsPerPage],
  (selectedRecipientsIdsPerPage: EntitiesPerPage<string>) => selectedRecipientsIdsPerPage
);

export type EditingRecipientsIdsSelector = (state: RootState) => string[];
export const selectEditingRecipientsIds: EditingRecipientsIdsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.editingRecipientsIds],
  (editingRecipientsIds: string[]) => editingRecipientsIds
);

export type IsLoadingSendingLogsSelector = (state: RootState) => boolean;
export const selectIsLoadingSendingLogs: IsLoadingSendingLogsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isLoadingSendingLogs],
  (isLoadingSendingLogs: boolean) => isLoadingSendingLogs
);

export type SendingLogsPageNumberSelector = (state: RootState) => number;
export const selectSendingLogsPageNumber: SendingLogsPageNumberSelector = createSelector(
  [(state: RootState) => state.sendingWizard.sendingLogsPageNumber],
  (sendingLogsPageNumber: number) => sendingLogsPageNumber
);

export type SendingLogsPageSizeSelector = (state: RootState) => number;
export const selectSendingLogsPageSize: SendingLogsPageSizeSelector = createSelector(
  [(state: RootState) => state.sendingWizard.sendingLogsPageSize],
  (sendingLogsPageSize: number) => sendingLogsPageSize
);

export type IsLoadingRecipientsSelector = (state: RootState) => boolean;
export const selectIsLoadingRecipients: IsLoadingRecipientsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isLoadingRecipients],
  (isLoadingRecipients: boolean) => isLoadingRecipients
);

export type IsLoadingContactSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingSuggestions: IsLoadingContactSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isLoadingContactSuggestions],
  (isLoadingContactSuggestions: boolean) => isLoadingContactSuggestions
);

export type RecipientsPageNumberSelector = (state: RootState) => number;
export const selectRecipientsPageNumber: RecipientsPageNumberSelector = createSelector(
  [(state: RootState) => state.sendingWizard.recipientsPageNumber],
  (recipientsPageNumber: number) => recipientsPageNumber
);

export type RecipientsSearchTextSelector = (state: RootState) => string;
export const selectRecipientsSearchText: RecipientsSearchTextSelector = createSelector(
  [(state: RootState) => state.sendingWizard.recipientsSearchText],
  (recipientsSearchText: string) => recipientsSearchText
);

export type SelectedListsIdsSelector = (state: RootState) => string[];
export const selectSelectedListsIds: SelectedListsIdsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedListsIds],
  (selectedListsIds: string[]) => selectedListsIds
);

export type SendingAudienceSelector = (state: RootState) => SendingAudience;
export const selectSendingAudience: SendingAudienceSelector = createSelector(
  (state: RootState) => state.sendingWizard.sendingAudience,
  (sendingAudience) => sendingAudience
);

export type IsLoadingSendingAudienceSelector = (state: RootState) => boolean;
export const selectIsLoadingSendingAudience: IsLoadingSendingAudienceSelector = createSelector(
  (state: RootState) => state.sendingWizard.isLoadingSendingAudience,
  (isLoading) => isLoading
);

export type RecipientsPageSizeSelector = (state: RootState) => number;
export const selectRecipientsPageSize: RecipientsPageSizeSelector = createSelector(
  [(state: RootState) => state.sendingWizard.recipientsPageSize],
  (recipientsPageSize: number) => recipientsPageSize
);

export type SendingWizardStepSelector = (state: RootState) => SendingWizardStep;
export const selectSendingWizardStep: SendingWizardStepSelector = createSelector(
  [(state: RootState) => state.sendingWizard.sendingWizardCurrentStep],
  (sendingWizardCurrentStep: SendingWizardStep) => sendingWizardCurrentStep
);

export type RecipientIdsToRemoveSelector = (state: RootState) => string[];
export const selectRecipientIdsToRemove: RecipientIdsToRemoveSelector = createSelector(
  [(state: RootState) => state.sendingWizard.recipientIdsToRemove],
  (recipientIdsToRemove: string[]) => recipientIdsToRemove
);

export type ListsSelector = (state: RootState) => List[];
export const selectLists: ListsSelector = createSelector([(state: RootState) => state.sendingWizard.lists], (lists: List[]) => lists);

export type SelectedListIdSelector = (state: RootState) => string;
export const selectSelectedListId: SelectedListIdSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedListId],
  (selectedListId: string) => selectedListId
);

export type ContactsFromListToAddSelector = (state: RootState) => RecipientFromList[];
export const selectContactsFromListToAdd: ContactsFromListToAddSelector = createSelector(
  [(state: RootState) => state.sendingWizard.contactsFromListToAdd],
  (contactsFromListToAdd: RecipientFromList[]) => contactsFromListToAdd
);

export type ContactsSuggestionsSelector = (state: RootState) => Contact[];
export const selectContactsSuggestions: ContactsSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.contactSuggestions],
  (contactSuggestions: Contact[]) => contactSuggestions
);

export type ContactSearchTextSelector = (state: RootState) => string;
export const selectContactSearchText: ContactSearchTextSelector = createSelector(
  [(state: RootState) => state.sendingWizard.searchContactsText],
  (searchContactsText: string) => searchContactsText
);

export type RecipientsToAddSelector = (state: RootState) => Recipient[];
export const selectRecipientsToAdd: RecipientsToAddSelector = createSelector(
  [(state: RootState) => state.sendingWizard.recipientsToAdd],
  (recipientsToAdd: Recipient[]) => recipientsToAdd
);

export type IsAddContactWizardPanelOpenedSelector = (state: RootState) => boolean;
export const selectIsAddContactWizardPanelOpened: IsAddContactWizardPanelOpenedSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isAddContactWizardOpened],
  (isAddContactWizardOpened: boolean) => isAddContactWizardOpened
);

export type AddContactStepSelector = (state: RootState) => number;
export const selectAddContactStep: AddContactStepSelector = createSelector(
  [(state: RootState) => state.sendingWizard.addContactStep],
  (addContactStep: number) => addContactStep
);

export type IsAddFromCompanyPanelOpenedSelector = (state: RootState) => boolean;
export const selectIsAddFromCompanyPanelOpened: IsAddFromCompanyPanelOpenedSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isAddFromCompanyPanelOpened],
  (isAddFromCompanyPanelOpened: boolean) => isAddFromCompanyPanelOpened
);

export type IsAddFromListPanelOpenedSelector = (state: RootState) => boolean;
export const selectIsAddFromListPanelOpened: IsAddFromListPanelOpenedSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isAddFromListPanelOpened],
  (isAddFromListPanelOpened: boolean) => isAddFromListPanelOpened
);

export type SetupHasDataSelector = (state: RootState) => boolean;
export const selectSetupHasData: SetupHasDataSelector = createSelector(
  [(state: RootState) => state.sendingWizard.setupHasData],
  (setupHasData: boolean) => setupHasData
);

export type AudienceHasDataSelector = (state: RootState) => boolean;
export const selectAudienceHasData: AudienceHasDataSelector = createSelector(
  [(state: RootState) => state.sendingWizard.audienceHasData],
  (audienceHasData: boolean) => audienceHasData
);

export type EmailHasDataSelector = (state: RootState) => boolean;
export const selectEmailHasData: EmailHasDataSelector = createSelector(
  [(state: RootState) => state.sendingWizard.emailHasData],
  (emailHasData: boolean) => emailHasData
);

export type SelectedSendingSelector = (state: RootState) => Sending;
export const selectSelectedSending: SelectedSendingSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSending],
  (selectedSending: Sending) => selectedSending
);

export type SelectSelectedTemplate = (state: RootState) => Template;
export const selectSelectedTemplate: SelectSelectedTemplate = createSelector(
  [(state: RootState) => state.sendingWizard.selectedTemplate],
  (selectedTemplate: Template) => selectedTemplate
);

export type SelectDefaultSendingName = (state: RootState) => string;
export const selectDefaultSendingName: SelectDefaultSendingName = createSelector(
  [(state: RootState) => state.sendingWizard.defaultSendingName],
  (defaultSendingName: string) => defaultSendingName
);

export type SelectUpdatedTemplate = (state: RootState) => Template;
export const selectUpdatedTemplate: SelectUpdatedTemplate = createSelector(
  [(state: RootState) => state.sendingWizard.updatedTemplate],
  (updatedTemplate: Template) => updatedTemplate
);

export type SelectedSendingStatusSelector = (state: RootState) => SendingStatus;
export const selectSelectedSendingStatus: SelectedSendingStatusSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSending.status],
  (status: SendingStatus) => status
);

export type SelectedSendingNumberOfRecipients = (state: RootState) => number;
export const selectSelectedSendingNumberOfRecipients: SelectedSendingNumberOfRecipients = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSending.numberOfRecipients],
  (numberOfRecipients: number) => numberOfRecipients
);

export type SelectedSendingJsonBodySelector = (state: RootState) => string;
export const selectSelectedSendingJsonBody: SelectedSendingJsonBodySelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSending.jsonBody],
  (jsonBody: string) => jsonBody
);

export type SendersSelector = (state: RootState) => Sender[];
export const selectSenders: SendersSelector = createSelector([(state: RootState) => state.sendingWizard.senders], (senders: Sender[]) => senders);

export type RecipientToUpdateSelector = (state: RootState) => UpdateSendingRecipientInput;
export const selectRecipientToUpdate: RecipientToUpdateSelector = createSelector(
  [(state: RootState) => state.sendingWizard.recipientToUpdate],
  (recipientToUpdate: UpdateSendingRecipientInput) => recipientToUpdate
);

export type BeeTokenSelector = (state: RootState) => string;
export const selectBeeToken: BeeTokenSelector = createSelector([(state: RootState) => state.sendingWizard.beeToken], (beeToken: string) => beeToken);

export type AadSearchTextSelector = (state: RootState) => string;
export const selectAadSearchText: AadSearchTextSelector = createSelector(
  [(state: RootState) => state.sendingWizard.aadSearchText],
  (aadSearchText: string) => aadSearchText
);

export type PeoplePickerSuggestionsSelector = (state: RootState) => AadPickerSuggestion[];
export const selectPeoplePickerSuggestions: PeoplePickerSuggestionsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.peoplePickerSuggestions],
  (peoplePickerSuggestions: AadPickerSuggestion[]) => peoplePickerSuggestions
);

export type IsPeoplePickerSuggestionsEmptySelector = (state: RootState) => boolean;
export const selectIsPeoplePickerSuggestionEmpty: IsPeoplePickerSuggestionsEmptySelector = createSelector(
  (state: RootState) => state.sendingWizard.isPeoplePickerSuggestionsEmpty,
  (isEmpty: boolean) => isEmpty
);

export type ContactsFromCompanyToAddSelector = (state: RootState) => AadContact[];
export const selectContactsFromCompanyToAdd: ContactsFromCompanyToAddSelector = createSelector(
  [(state: RootState) => state.sendingWizard.contactsFromCompanyToAdd],
  (contactsFromCompanyToAdd: AadContact[]) => contactsFromCompanyToAdd
);
export type ImageSelector = (state: RootState) => string;
export const selectImageFromTemplate: ImageSelector = createSelector([(state: RootState) => state.sendingWizard.image], (image: string) => image);

export type ImageIsLoadingSelector = (state: RootState) => boolean;
export const selectIsImageLoading: ImageIsLoadingSelector = createSelector(
  [(state: RootState) => state.sendingWizard.imageLoading],
  (imageLoading: boolean) => imageLoading
);

export type OpenedFromTemplateSelector = (state: RootState) => boolean;
export const selectIsOpenedFromTemplate: OpenedFromTemplateSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isOpenedFromTemplate],
  (isOpenedFromTemplate: boolean) => isOpenedFromTemplate
);

export type ChangeInEditorHappenedAfterOpenedFromTemplateSelector = (state: RootState) => boolean;
export const selectChangeInEditorHappenedAfterOpenedFromTemplate: ChangeInEditorHappenedAfterOpenedFromTemplateSelector = createSelector(
  [(state: RootState) => state.sendingWizard.changeInEditorHappenedAfterOpenedFromTemplate],
  (changeInEditorHappenedAfterOpenedFromTemplate: boolean) => changeInEditorHappenedAfterOpenedFromTemplate
);

export type IsDismissWizardDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsDismissWizardDialogVisible: IsDismissWizardDialogVisibleSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isDismissWizardDialogVisible],
  (isDismissWizardDialogVisible: boolean) => isDismissWizardDialogVisible
);

export type IsTemplatesPopupVisibleSelector = (state: RootState) => boolean;
export const selectIsTemplatesPopupVisible: IsTemplatesPopupVisibleSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isTemplatesPopupVisible],
  (isTemplatesPopupVisible: boolean) => isTemplatesPopupVisible
);

export type IsSaveTemplateDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsSaveTemplateDialogVisible: IsSaveTemplateDialogVisibleSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isSaveTemplateDialogVisible],
  (isSaveTemplateDialogVisible: boolean) => isSaveTemplateDialogVisible
);

export type BeeInstanceSelector = (state: RootState) => Bee;
export const selectBeeInstance: BeeInstanceSelector = createSelector(
  [(state: RootState) => state.sendingWizard.beeInstance],
  (beeInstance: Bee) => beeInstance
);

export type EmailBodyInEditorSelector = (state: RootState) => EmailBody;
export const selectEmailBodyInEditor: EmailBodyInEditorSelector = createSelector(
  [(state: RootState) => state.sendingWizard.emailBodyInEditor],
  (emailBodyInEditor: EmailBody) => emailBodyInEditor
);

export type SendingToAddSetup = (state: RootState) => SendingSetup;
export const selectSendingToAddSetup: SendingToAddSetup = createSelector(
  [(state: RootState) => state.sendingWizard.sendingToAddSetup],
  (sendingToAddSetup: SendingSetup) => sendingToAddSetup
);

export type TemplateToAddSelector = (state: RootState) => Template;
export const selectTemplateToAdd: TemplateToAddSelector = createSelector(
  [(state: RootState) => state.sendingWizard.templateToAdd],
  (templateToAdd: Template) => templateToAdd
);

export type IsProfilePanelOpenSelector = (state: RootState) => boolean;
export const selectIsProfilePanelOpen: IsProfilePanelOpenSelector = (state: RootState) => state.sendingWizard.isProfilePanelOpen;

export type SelectedSendingIdSelector = (state: RootState) => string;
export const selectSelectedSendingId: SelectedSendingIdSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSendingId],
  (selectedSendingId: string) => selectedSendingId
);

export type TagToAddToSendingSelector = (state: RootState) => Tag;
export const selectTagToAddToSending: TagToAddToSendingSelector = createSelector(
  [(state: RootState) => state.sendingWizard.tagToAddToSending],
  (tagToAddToSending: Tag) => tagToAddToSending
);

export type TagToRemoveFromSendingSelector = (state: RootState) => Tag;
export const selectTagToRemoveFromSending: TagToRemoveFromSendingSelector = createSelector(
  [(state: RootState) => state.sendingWizard.tagToRemoveFromSending],
  (tagToRemoveFromSending: Tag) => tagToRemoveFromSending
);

export type SelectedSendingTagsHeaderValueSelector = (state: RootState) => Tag[];
export const selectSendingTagsHeaderValue: SelectedSendingTagsHeaderValueSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSending.tags],
  (tags: Tag[]) => tags
);

export type SelectedSendingTagsProjectionsSelector = (state: RootState) => TagProjection[];
export const selectSendingTagsProjectionsValue: SelectedSendingTagsProjectionsSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSending.tags],
  (tags: TagProjection[]) => tags?.map((tag) => new TagProjection(tag.id, tag.name))
);

export type IsLoadingSendingProfileSelector = (state: RootState) => boolean;
export const selectIsLoadingSendingProfile: IsLoadingSendingProfileSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isLoadingSendingProfile],
  (isLoadingSendingProfile: boolean) => isLoadingSendingProfile
);

export type EmailPanelActiveIndexSelector = (state: RootState) => number;
export const selectEmailPanelActiveIndex: EmailPanelActiveIndexSelector = createSelector(
  [(state: RootState) => state.sendingWizard.emailPanelActiveIndex],
  (emailPanelActiveIndex: number) => emailPanelActiveIndex
);

export type IsDeleteRecipientDialogOpenSelector = (state: RootState) => boolean;
export const selectIsDeleteRecipientDialogOpen: IsDeleteRecipientDialogOpenSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isDeleteRecipientDialogOpened],
  (isDeleteRecipientDialogOpened: boolean) => isDeleteRecipientDialogOpened
);

export type BlacklistFromPreviewTabSelector = (state: RootState) => Blacklist;
export const selectBlacklistFromPreviewTab: BlacklistFromPreviewTabSelector = createSelector(
  [(state: RootState) => state.sendingWizard.blacklistFromPreviewTab],
  (blacklistFromPreviewTab: Blacklist) => blacklistFromPreviewTab
);

export type IsMissingFieldErrorDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsMissingFieldErrorDialogVisible: IsMissingFieldErrorDialogVisibleSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isMissingFieldErrorDialogVisible],
  (isMissingFieldErrorDialogVisible: boolean) => isMissingFieldErrorDialogVisible
);

export type IsSendDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsSendDialogVisible: IsSendDialogVisibleSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isSendDialogVisible],
  (isSendDialogVisible: boolean) => isSendDialogVisible
);

export type IsScheduleSendDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsScheduleSendDialogVisible: IsScheduleSendDialogVisibleSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isScheduleSendDialogVisible],
  (isScheduleSendDialogVisible: boolean) => isScheduleSendDialogVisible
);

export type ErrorDialogMessageSelector = (state: RootState) => string;
export const selectErrorDialogMessage: ErrorDialogMessageSelector = createSelector(
  [(state: RootState) => state.sendingWizard.errorDialogMessage],
  (errorDialogMessage: string) => errorDialogMessage
);

export type SendingScheduledAtSelector = (state: RootState) => DateTime;
export const selectSendingScheduledAt: SendingScheduledAtSelector = createSelector(
  [(state: RootState) => state.sendingWizard.sendingScheduledAt],
  (sendingScheduledAt: DateTime) => sendingScheduledAt
);

export type UpdatedSendingSelector = (state: RootState) => Sending;
export const selectUpdatedSending: UpdatedSendingSelector = createSelector(
  [(state: RootState) => state.sendingWizard.updatedSending],
  (updatedSending: Sending) => updatedSending
);

export type AddFromListCurrentStepSelector = (state: RootState) => number;
export const selectAddFromListCurrentStep: AddFromListCurrentStepSelector = createSelector(
  [(state: RootState) => state.sendingWizard.addFromListCurrentStep],
  (addFromListCurrentStep: number) => addFromListCurrentStep
);

export type ListsSearchTextSelector = (state: RootState) => string;
export const selectListsSearchText: ListsSearchTextSelector = createSelector(
  [(state: RootState) => state.sendingWizard.listsSearchText],
  (listsSearchText: string) => listsSearchText
);

export type SendingWizardHasNoData = (state: RootState) => boolean;
export const selectSendingWizardHasNoData: SendingWizardHasNoData = createSelector(
  [
    (state: RootState) => state.sendingWizard.isOpenedFromTemplate,
    (state: RootState) => state.sendingWizard.changeInEditorHappenedAfterOpenedFromTemplate,
    (state: RootState) => state.sendingWizard.sendingToAddSetup,
    (state: RootState) => state.sendingWizard.selectedSending
  ],
  (isOpenedFromTemplate: boolean, changeInEditorHappenedAfterOpenedFromTemplate: boolean, sendingSetup: SendingSetup, selectedSending: Sending) =>
    wizardHasNoData(isOpenedFromTemplate, changeInEditorHappenedAfterOpenedFromTemplate, sendingSetup, selectedSending)
);

export type SearchContactsByNameOrEmailPageSizeSelector = (state: RootState) => number;
export const selectSearchContactsByNameOrEmailPageSize: SearchContactsByNameOrEmailPageSizeSelector = createSelector(
  (state: RootState) => state.sendingWizard.searchContactsByNameOrEmailPageSize,
  (searchContactsByNameOrEmailPageSize: number) => searchContactsByNameOrEmailPageSize
);

export type NewSendingSelector = (state: RootState) => Sending;
export const selectNewSending: NewSendingSelector = createSelector(
  (state: RootState) => state.sendingWizard.newSending,
  (newSending: Sending) => newSending
);

export type SendingsSelector = (state: RootState) => Sending[];
export const selectSendings: SendingsSelector = createSelector(
  (state: RootState) => state.sendingWizard.sendings,
  (sendings: Sending[]) => sendings
);

export type SendingsSearchTextSelector = (state: RootState) => string;
export const selectSendingsSearchText: SendingsSearchTextSelector = createSelector(
  (state: RootState) => state.sendingWizard.sendingsSearchText,
  (sendingsSearchText: string) => sendingsSearchText
);

export type SendingsPageNumberSelector = (state: RootState) => number;
export const selectSendingsPageNumber: SendingsPageNumberSelector = createSelector(
  (state: RootState) => state.sendingWizard.sendingsPageNumber,
  (sendingsPageNumber: number) => sendingsPageNumber
);

export type SendingsPageSizeSelector = (state: RootState) => number;
export const selectSendingsPageSize: SendingsPageSizeSelector = createSelector(
  (state: RootState) => state.sendingWizard.sendingsPageSize,
  (sendingsPageSize: number) => sendingsPageSize
);

export type TotalCountOfSendingsSelector = (state: RootState) => number;
export const selectTotalCountOfSendings: TotalCountOfSendingsSelector = createSelector(
  (state: RootState) => state.sendingWizard.totalCountOfSendings,
  (totalCountOfSendings: number) => totalCountOfSendings
);

export type IsLoadingSendingsSelector = (state: RootState) => boolean;
export const selectIsLoadingSendings: IsLoadingSendingsSelector = createSelector(
  (state: RootState) => state.sendingWizard.isLoadingSendings,
  (isLoadingSendings: boolean) => isLoadingSendings
);

export type ContactsIdsToAddSelector = (state: RootState) => string[];
export const selectContactsIdsToAdd: ContactsIdsToAddSelector = createSelector(
  (state: RootState) => state.sendingWizard.contactsIdsToAdd,
  (contactsIdsToAdd: string[]) => contactsIdsToAdd
);

export type IsSavingInProgressSelector = (state: RootState) => boolean;
export const selectIsSavingInProgress: IsSavingInProgressSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isSavingInProgress],
  (isSavingInProgress: boolean) => isSavingInProgress
);

export type NavigateToSendingWizardSelector = (state: RootState) => boolean;
export const selectShouldNavigateToSendingWizard: NavigateToSendingWizardSelector = createSelector(
  (state: RootState) => state.sendingWizard.shouldNavigateToSendingWizard,
  (shouldNavigate: boolean) => shouldNavigate
);

export type SelectedSendingOverviewSelector = (state: RootState) => SendingOverview;
export const selectSelectedSendingOverview: SelectedSendingOverviewSelector = createSelector(
  [(state: RootState) => state.sendingWizard.selectedSendingOverview],
  (selectedSendingOverview: SendingOverview) => selectedSendingOverview
);

export type IsReportAIContentModalOpenSelector = (state: RootState) => boolean;
export const selectIsReportAIContentModalOpen: IsReportAIContentModalOpenSelector = createSelector(
  [(state: RootState) => state.sendingWizard.isReportAIContentModalOpen],
  (isReportAIContentModalOpen: boolean) => isReportAIContentModalOpen
);

export type AiContentReportMessageSelector = (state: RootState) => string;
export const selectAiContentReportMessage: AiContentReportMessageSelector = createSelector(
  [(state: RootState) => state.sendingWizard.aiContentReportMessage],
  (aiContentReportMessage: string) => aiContentReportMessage
);

const sendingWizardSlice = createSlice({
  name: 'sendingWizard',
  initialState,
  reducers: {
    resetSelectedSending: (state) => {
      state.emailBodyInEditor = {} as EmailBody;
      state.changeInEditorHappenedAfterOpenedFromTemplate = false;
      state.isOpenedFromTemplate = false;
      state.sendingToAddSetup = {} as SendingSetup;
      state.selectedSending = {} as Sending;
      state.selectedSendingRecipients = [];
      state.editingRecipientsIds = [];
      state.setupHasData = false;
      state.sendingWizardCurrentStep = SendingWizardStep.Setup;
      state.selectedTemplate = {} as Template;
      state.selectedSendingOverview = {} as SendingOverview;
      state.shouldNavigateToSendingWizard = false;
    },
    navigateToSendingWizardReceived: (state) => {
      state.shouldNavigateToSendingWizard = false;
    },
    updateSelectedSendingSetup: (state, action: PayloadAction<SendingSetup>) => {
      state.sendingToAddSetup = action.payload;
    },
    getSendersRequested: () => {},
    sendersReceived: (state, action: PayloadAction<Sender[]>) => {
      state.senders = action.payload;
    },
    openAddContactWizard: (state) => {
      state.isAddContactWizardOpened = true;
      state.recipientsToAdd = [];
    },
    closeAddContactWizard: (state) => {
      state.isAddContactWizardOpened = false;
      state.contactSuggestions = [];
    },
    startLoadingContactsByNameOrEmailNotContainedInSendingRecipients: (state) => {
      state.isLoadingContactSuggestions = true;
    },
    getContactsByNameOrEmailNotContainedInSendingRecipientsRequested: (state, action: PayloadAction<string>) => {
      state.searchContactsText = action.payload;
    },
    sendingLogsRequested: (state, action: PayloadAction<Sending>) => {
      state.isLoadingSendingLogs = true;
      state.selectedSending = action.payload;
    },
    sendingLogsReceived: (state, action: PayloadAction<{ logs: SendingLog[]; totalNumberOfLogs: number }>) => {
      state.isLoadingSendingLogs = false;
      state.selectedSending.logs = action.payload.logs;
      state.selectedSending.totalNumberOfLogs = action.payload.totalNumberOfLogs;
    },
    contactsByNameOrEmailReceived: (state, action: PayloadAction<Contact[]>) => {
      state.contactSuggestions = action.payload;
      state.isLoadingContactSuggestions = false;
    },
    addRecipientToSendingInStore: (state, action: PayloadAction<Recipient>) => {
      state.contactSuggestions = [];

      if (state.recipientsToAdd.some((recipient) => recipient.contact.id === action.payload.contact.id)) return;
      state.recipientsToAdd = [...state.recipientsToAdd, action.payload];
    },
    removeRecipientFromSendingInStore: (state, action: PayloadAction<Recipient>) => {
      const recipientToRemoveIndex = state.recipientsToAdd.findIndex(
        (r) => r.contact.id === action.payload.contact.id && r.collaborationId === action.payload.collaborationId
      );

      state.recipientsToAdd.splice(recipientToRemoveIndex, 1);
    },
    addRecipientsToSendingRequested: (state, action: PayloadAction<Recipient[]>) => {
      state.recipientsToAdd = action.payload;
    },
    recipientsAddedToSending: (state, action: PayloadAction<Recipient[]>) => {
      state.selectedSendingRecipients = [...state.selectedSendingRecipients, ...action.payload];
      state.selectedSending.numberOfRecipients += action.payload.length;
      state.recipientsToAdd = [];
      state.contactsFromCompanyToAdd = [];
    },
    removeRecipientsFromSendingRequested: (state, action: PayloadAction<string[]>) => {
      state.recipientIdsToRemove = action.payload;
    },
    recipientsRemovedFromSending: (state, action: PayloadAction<string[]>) => {
      state.selectedSendingRecipients = state.selectedSendingRecipients.filter((recipient) => !action.payload.includes(recipient.id));
      state.recipientsTotalCount -= action.payload.length;
      state.recipientIdsToRemove = [];
      state.selectedRecipientsIds = [];
      state.selectedRecipientsIdsPerPage = {} as EntitiesPerPage<string>;
    },
    updateSendingRecipientContactDataRequested: (state, action: PayloadAction<UpdateSendingRecipientInput>) => {
      state.recipientToUpdate = action.payload;
    },
    sendingRecipientContactDataUpdated: (state, action: PayloadAction<Recipient>) => {
      const recipientToUpdateIndex = state.selectedSendingRecipients.findIndex((r) => r.id === action.payload.id);
      state.selectedSendingRecipients.splice(recipientToUpdateIndex, 1, action.payload);
    },
    beeTokenRequested: () => {},
    beeTokenReceived: (state, action: PayloadAction<any>) => {
      state.beeToken = action.payload;
      state.beeInstance = new Bee(action.payload);
    },
    openAddFromCompanyPanel: (state) => {
      state.isAddFromCompanyPanelOpened = true;
      state.peoplePickerSuggestions = [];
      state.isPeoplePickerSuggestionsEmpty = false;
    },
    closeAddFromCompanyPanel: (state) => {
      state.isAddFromCompanyPanelOpened = false;
      state.contactsFromCompanyToAdd = [];
      state.peoplePickerSuggestions = [];
      state.isPeoplePickerSuggestionsEmpty = false;
    },
    getPeoplePickerSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.aadSearchText = action.payload;
      state.isLoadingContactSuggestions = true;
    },
    peoplePickerSuggestionsReceived: (state, action: PayloadAction<{ contacts: AadContact[]; groups: Group[] }>) => {
      const contactsPickerSuggestions = action.payload.contacts.map((c) => {
        return { objectId: c.objectId, name: `${c.firstName} ${c.lastName}`, type: PickerSuggestionType.People, value: c };
      });
      const groupsPickerSuggestions = action.payload.groups.map((g) => {
        return { objectId: g.objectId, name: g.name, type: PickerSuggestionType.Group, value: g };
      });
      state.peoplePickerSuggestions = [...contactsPickerSuggestions, ...groupsPickerSuggestions].sort((a, b) => (a.name > b.name ? 1 : 0));
      state.isLoadingContactSuggestions = false;
      state.isPeoplePickerSuggestionsEmpty = state.peoplePickerSuggestions.length === 0;
    },
    addContactsFromCompanyInStore: (state, action: PayloadAction<AadContact[]>) => {
      if (state.contactsFromCompanyToAdd.some((contact) => action.payload.some((aad) => aad.contactId === contact.contactId))) return;
      state.contactsFromCompanyToAdd = [...state.contactsFromCompanyToAdd, ...action.payload];
    },
    removeContactFromCompanyInStore: (state, action: PayloadAction<string>) => {
      state.contactsFromCompanyToAdd = state.contactsFromCompanyToAdd.filter((contact) => contact.objectId !== action.payload);
    },
    saveSelectedSendingEmailBody: (state) => {
      state.selectedSending = {
        ...state.selectedSending,
        jsonBody: state.emailBodyInEditor.jsonBody,
        htmlBody: state.emailBodyInEditor.htmlBody
      };
    },
    chooseTemplate: (state, action: PayloadAction<Template>) => {
      state.emailBodyInEditor = { htmlBody: action.payload.htmlBody, jsonBody: action.payload.jsonBody };
      state.selectedSending = {
        ...state.selectedSending,
        jsonBody: action.payload.jsonBody,
        htmlBody: action.payload.htmlBody
      };
      state.selectedTemplate = action.payload;
    },
    updateTemplateRequested: (state, action: PayloadAction<Template>) => {
      state.updatedTemplate = action.payload;
    },
    templateAsImageRequested: () => {},
    templateAsImageReceived: (state, action: PayloadAction<string>) => {
      state.image = action.payload;
    },
    imageLoaded: (state) => {
      state.imageLoading = false;
    },
    startLoadingImage: (state) => {
      state.imageLoading = true;
    },
    openAddFromListPanel: (state) => {
      state.isAddFromListPanelOpened = true;
      state.contactsFromListToAdd = [];
    },
    closeAddFromListPanel: (state) => {
      state.isAddFromListPanelOpened = false;
      state.contactsFromListToAdd = [];
      state.lists = [];
    },
    getContactsByListIdRequested: (state, action: PayloadAction<string>) => {
      state.selectedListId = action.payload;
      state.isLoadingContactSuggestions = true;
    },
    contactsByListIdReceived: (state, action: PayloadAction<RecipientFromList[]>) => {
      state.isLoadingContactSuggestions = false;
      state.contactsFromListToAdd = [
        ...state.contactsFromListToAdd,
        ...action.payload.filter((recipient) => !state.contactsFromListToAdd.map((c) => c.contact.id).includes(recipient.contact.id))
      ];
    },
    addContactsFromListsRequested: () => {},
    changeInEditorHappenedAfterOpenedFromTemplate: (state) => {
      state.changeInEditorHappenedAfterOpenedFromTemplate = true;
    },
    closeDismissWizardDialog: (state) => {
      state.isDismissWizardDialogVisible = false;
    },
    openTemplatesPopup: (state) => {
      state.isTemplatesPopupVisible = true;
    },
    closeTemplatesPopup: (state) => {
      state.isTemplatesPopupVisible = false;
    },
    openSaveTemplateDialog: (state) => {
      state.isSaveTemplateDialogVisible = true;
    },
    closeSaveTemplateDialog: (state) => {
      state.isSaveTemplateDialogVisible = false;
    },
    saveEmailBodyInStore: (state, action: PayloadAction<{ json: string; html: string }>) => {
      state.emailBodyInEditor = { htmlBody: action.payload.html, jsonBody: action.payload.json };
      state.templateToAdd = {
        ...state.templateToAdd,
        jsonBody: action.payload.json,
        htmlBody: action.payload.html
      };
    },
    addTemplateRequested: (state, action: PayloadAction<Template>) => {
      state.templateToAdd = { ...action.payload, htmlBody: state.emailBodyInEditor.htmlBody, jsonBody: state.emailBodyInEditor.jsonBody };
    },
    updateSelectedTemplate: (state, action: PayloadAction<Template>) => {
      state.selectedTemplate = action.payload;
    },
    resetTemplateToAdd: (state) => {
      state.templateToAdd = {} as Template;
    },
    getSelectedSendingRequested: (state, action: PayloadAction<string>) => {
      state.selectedSendingId = action.payload;
      state.isLoadingSendingProfile = true;
      state.sendingLogsPageNumber = 1;
      state.sendingLogsPageSize = 10;
    },
    selectedSendingReceived: (state, action: PayloadAction<Sending>) => {
      state.selectedSending = action.payload;
      state.emailBodyInEditor = {
        jsonBody: state.selectedSending.jsonBody,
        htmlBody: state.selectedSending.htmlBody
      };
      state.setupHasData = !!action.payload.title;
      state.sendingToAddSetup = new SendingSetup(
        action.payload.title,
        action.payload.sender,
        action.payload.senderDisplayName,
        action.payload.respondToEmailAddress,
        action.payload.blacklist,
        action.payload.subject
      );
      state.image = action.payload.imageAsBase64;
      state.emailBodyInEditor = { jsonBody: action.payload.jsonBody, htmlBody: action.payload.htmlBody };
      state.isLoadingSendingProfile = false;
    },
    openProfilePanel: (state) => {
      state.isProfilePanelOpen = true;
    },
    closeProfilePanel: (state) => {
      state.isProfilePanelOpen = false;
      state.selectedSendingRecipients = [];
      state.editingRecipientsIds = [];
      state.emailBodyInEditor = {} as EmailBody;
      state.sendingAudience = {} as SendingAudience;
    },
    addNewTagToSelectedItemRequested: (state, action: PayloadAction<TagProjection>) => {
      state.tagToAddToSending = action.payload;
    },
    addTagToSendingRequested: (state, action: PayloadAction<TagProjection>) => {
      state.tagToAddToSending = action.payload;
    },
    tagAddedToSending: (state, action: PayloadAction<Tag>) => {
      state.selectedSending.tags = [...state.selectedSending.tags, action.payload];
      state.tagToAddToSending = {} as TagProjection;
    },
    removeTagFromSendingRequested: (state, action: PayloadAction<TagProjection>) => {
      state.tagToRemoveFromSending = action.payload;
    },
    setSendingsLogPageNumber: (state, action: PayloadAction<number>) => {
      state.sendingLogsPageNumber = action.payload;
    },
    setSendingsLogPageSize: (state, action: PayloadAction<number>) => {
      state.sendingLogsPageSize = action.payload;
      state.sendingLogsPageNumber = 1;
    },
    removeTagFromSendingInStore: (state, action: PayloadAction<TagProjection>) => {
      state.sendingPreviousValue = cloneDeep(state.selectedSending);
      state.selectedSending.tags = state.selectedSending.tags.filter((tag) => tag.id !== action.payload.id);
    },
    resetSelectedSendingAfterUndo: (state) => {
      state.selectedSending = cloneDeep(state.sendingPreviousValue);
    },
    sendingAudienceRequested: (state) => {
      state.isLoadingSendingAudience = true;
    },
    updateSendingRecipientForTheCorrespondingContact: (state, action: PayloadAction<Contact>) => {
      const contact = action.payload;
      state.selectedSendingRecipients = state.selectedSendingRecipients.map((recipient) => {
        if (recipient.contact?.id === contact.id) {
          return { ...recipient, emailAddress: contact.primaryEmailAddress() };
        } else {
          return recipient;
        }
      });
    },
    sendingAudienceReceived: (state, action: PayloadAction<SendingAudience>) => {
      state.isLoadingSendingAudience = false;
      state.sendingAudience = action.payload;
    },
    loadingAudienceThrewAnError: (state) => {
      state.sendingAudience = {};
      state.isLoadingSendingAudience = false;
    },
    selectedSendingUpdatedFromSync: (state, action: PayloadAction<Sending>) => {
      state.selectedSending = new Sending(
        action.payload.id,
        action.payload.title,
        action.payload.imageAsBase64,
        action.payload.status,
        state.selectedSending.sender,
        state.selectedSending.senderEmailAddress,
        state.selectedSending.senderDisplayName,
        action.payload.subject,
        state.selectedSending.respondToEmailAddress,
        action.payload.htmlBody,
        state.selectedSending.originalHtmlBody,
        state.selectedSending.jsonBody,
        state.selectedSending.mjmlBody,
        action.payload.numberOfRecipients,
        action.payload.createdAt,
        action.payload.createdBy,
        action.payload.lastModifiedAt,
        state.selectedSending.lastModifiedByUser,
        action.payload.isFavourite,
        state.selectedSending.blacklist,
        state.selectedSending.mediums,
        state.selectedSending.lists,
        state.selectedSending.contacts,
        state.selectedSending.distributionDuration,
        state.selectedSending.comment,
        state.selectedSending.recipients,
        state.selectedSending.mailPreviewLink,
        action.payload.deliveredAt,
        action.payload.sendingScheduledAt,
        state.selectedSending.tags,
        action.payload.logs,
        action.payload.totalNumberOfLogs
      );
    },
    setEmailPanelActiveIndex: (state, action: PayloadAction<number>) => {
      state.emailPanelActiveIndex = action.payload;
    },
    openDeleteRecipientDialog: (state) => {
      state.isDeleteRecipientDialogOpened = true;
    },
    closeDeleteRecipientDialog: (state) => {
      state.isDeleteRecipientDialogOpened = false;
    },
    blacklistFromPreviewTabRequested: (state) => {
      state.blacklistFromPreviewTab = {} as Blacklist;
    },
    blacklistFromPreviewTabReceived: (state, action: PayloadAction<Blacklist>) => {
      state.blacklistFromPreviewTab = action.payload;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.selectedSending.tags = [...state.selectedSending.tags, action.payload];
      state.tagNameToCreate = '';
    },
    addedTagToSending: (state, action: PayloadAction<Tag>) => {
      state.selectedSending.tags = [...state.selectedSending.tags, action.payload];
      state.tagToAddToSending = {} as TagProjection;
    },
    removedTagsFromSending: (state, action: PayloadAction<string[]>) => {
      state.tagToRemoveFromSending = {} as TagProjection;
      const tagIds = action.payload;
      state.selectedSending.tags = state.selectedSending.tags.filter((tag) => !tagIds.includes(tag.id));
    },
    updateRecipientToAdd: (state, action: PayloadAction<{ recipientIndex: number; collaborationId: string; emailAddress: string }>) => {
      state.recipientsToAdd[action.payload.recipientIndex].collaborationId = action.payload.collaborationId;
      state.recipientsToAdd[action.payload.recipientIndex].emailAddress = action.payload.emailAddress;
    },
    updateRecipientFromList: (state, action: PayloadAction<{ recipientIndex: number; collaborationId: string; emailAddress: string }>) => {
      state.contactsFromListToAdd[action.payload.recipientIndex].collaborationId = action.payload.collaborationId;
      state.contactsFromListToAdd[action.payload.recipientIndex].emailAddress = action.payload.emailAddress;
    },
    removeRecipientFromListInStore: (state, action: PayloadAction<RecipientFromList>) => {
      const recipientToRemoveIndex = state.contactsFromListToAdd.findIndex(
        (c) =>
          c.contact.id === action.payload.contact.id && c.collaborationId === action.payload.collaborationId && c.emailAddress === action.payload.emailAddress
      );
      state.contactsFromListToAdd.splice(recipientToRemoveIndex, 1);
    },
    openMissingFieldErrorDialog: (state, action: PayloadAction<string>) => {
      state.isMissingFieldErrorDialogVisible = true;
      state.errorDialogMessage = action.payload;
    },
    closeMissingFieldErrorDialog: (state) => {
      state.isMissingFieldErrorDialogVisible = false;
    },
    openSendDialog: (state) => {
      state.isSendDialogVisible = true;
    },
    closeSendDialog: (state) => {
      state.isSendDialogVisible = false;
    },
    openScheduleSendDialog: (state) => {
      state.isScheduleSendDialogVisible = true;
    },
    closeScheduleSendDialog: (state) => {
      state.isScheduleSendDialogVisible = false;
    },
    sendSendingRequested: () => {},
    scheduleSendingRequested: (state, action: PayloadAction<DateTime>) => {
      state.sendingScheduledAt = action.payload;
    },
    createNewBlankEmailRequested: (state, action: PayloadAction<string>) => {
      state.isLoadingSendingProfile = true;
      state.sendingWizardCurrentStep = 1;
      state.image = '';
      state.emailBodyInEditor = {} as EmailBody;
      state.newSending.title = action.payload;
    },
    newBlankEmailCreated: (state, action: PayloadAction<Sending>) => {
      state.isLoadingSendingProfile = false;
      state.selectedSending = action.payload;
      state.selectedSendingId = action.payload.id;
      state.sendingToAddSetup = action.payload as SendingSetup;
      state.shouldNavigateToSendingWizard = true;
    },
    createSendingFromTemplateRequested: (state, action: PayloadAction<{ template: Template; defaultSendingName: string }>) => {
      const { template, defaultSendingName } = action.payload;
      state.isLoadingSendingProfile = true;
      state.selectedTemplate = template;
      state.defaultSendingName = defaultSendingName;
    },
    sendingFromTemplateCreated: (state, action: PayloadAction<Sending>) => {
      state.selectedSendingId = action.payload.id;
      state.selectedSending = action.payload;
      state.emailBodyInEditor = {
        jsonBody: state.selectedSending.jsonBody,
        htmlBody: state.selectedSending.htmlBody
      };
      state.selectedSendingRecipients = action.payload.recipients;
      state.setupHasData = !!action.payload.title;
      state.sendingWizardCurrentStep = 3;
      state.isOpenedFromTemplate = true;
      state.sendingToAddSetup = new SendingSetup(
        action.payload.title,
        action.payload.sender,
        action.payload.senderDisplayName,
        action.payload.respondToEmailAddress,
        action.payload.blacklist,
        action.payload.subject
      );
      state.isLoadingSendingProfile = false;
    },
    createNewEmailWithRecipientsRequested: (state, action: PayloadAction<{ contactsIds: string[]; sendingTitle: string }>) => {
      state.isLoadingSendingProfile = true;
      state.recipientsTotalCount = 0;
      state.contactsIdsToAdd = action.payload.contactsIds;
      state.newSending.title = action.payload.sendingTitle;
      state.selectedSendingRecipients = [];
    },
    newEmailWithRecipientsCreated: (state, action: PayloadAction<Sending>) => {
      state.sendingWizardCurrentStep = 2;
      state.selectedSending = action.payload;
      state.sendingToAddSetup = action.payload as SendingSetup;
      state.selectedSendingId = action.payload.id;
      state.recipientsToAdd = [];
      state.isLoadingSendingProfile = false;
      state.shouldNavigateToSendingWizard = true;
    },
    createNewEmailWithRecipientsFromListRequested: (state, action: PayloadAction<{ selectedListIds: string[]; sendingTitle: string }>) => {
      state.selectedSendingRecipients = [];
      state.recipientsTotalCount = 0;
      state.isLoadingSendingProfile = true;
      state.selectedListsIds = action.payload.selectedListIds;
      state.newSending.title = action.payload.sendingTitle;
    },
    newEmailWithRecipientsFromListCreated: (state, action: PayloadAction<Sending>) => {
      state.selectedSendingId = action.payload.id;
      state.selectedSending = action.payload;
      state.sendingWizardCurrentStep = 2;
      state.sendingToAddSetup = action.payload as SendingSetup;
      state.contactsFromListToAdd = [];
      state.isLoadingSendingProfile = false;
      state.selectedListsIds = [];
      state.shouldNavigateToSendingWizard = true;
    },
    setCurrentStep: (state, action: PayloadAction<SendingWizardStep>) => {
      state.sendingWizardCurrentStep = action.payload;
    },
    goToNextStep: (state) => {
      state.sendingWizardCurrentStep = state.sendingWizardCurrentStep + 1;
    },
    goToPreviousStep: (state) => {
      state.sendingWizardCurrentStep = state.sendingWizardCurrentStep - 1;
    },
    goToAddContactStep: (state, action: PayloadAction<number>) => {
      state.addContactStep = action.payload;
    },
    updateSendingRequested: (state, action: PayloadAction<Sending>) => {
      state.updatedSending = action.payload;
    },
    gotoAddFromListStep: (state, action: PayloadAction<number>) => {
      state.addFromListCurrentStep = action.payload;
    },
    getListsByNameRequested: (state, action: PayloadAction<string>) => {
      state.listsSearchText = action.payload;
    },
    listsByNameReceived: (state, action: PayloadAction<List[]>) => {
      state.isLoadingContactSuggestions = false;
      state.lists = action.payload;
    },
    pageOfRecipientsRequested: (state) => {
      state.isLoadingRecipients = true;
    },
    setRecipientsPageSize: (state, action: PayloadAction<number>) => {
      state.recipientsPageSize = action.payload;
      state.recipientsPageNumber = 1;
    },
    pageOfRecipientsReceived: (state, action: PayloadAction<PageOfRecipients>) => {
      state.selectedSendingRecipients = action.payload.recipients;
      state.recipientsTotalCount = action.payload.totalCountOfRecipients;
      state.isLoadingRecipients = false;
    },
    setRecipientsQueryParams: (state, action: PayloadAction<{ pageNumber: number; searchText: string }>) => {
      state.recipientsPageNumber = action.payload.pageNumber;
      state.recipientsSearchText = action.payload.searchText;
    },
    setRecipientsPageNumber: (state, action: PayloadAction<number>) => {
      state.recipientsPageNumber = action.payload;
    },
    sendingWizardUpdated: (state, action: PayloadAction<Sending>) => {
      state.selectedSending = action.payload;
    },
    pageOfSendingsForEditorRequested: (state, action: PayloadAction<{ searchText: string; pageNumber: number; pageSize: number }>) => {
      state.sendingsSearchText = action.payload.searchText;
      state.sendingsPageNumber = action.payload.pageNumber;
      state.sendingsPageSize = action.payload.pageSize;
      state.isLoadingSendings = true;
    },
    pageOfSendingsForEditorReceived: (state, action: PayloadAction<{ sendings: Sending[]; totalCount: number }>) => {
      state.sendings = action.payload.sendings;
      state.totalCountOfSendings = action.payload.totalCount;
      state.isLoadingSendings = false;
    },
    setSelectedRecipientsIdsIfNotLoading: (state, action: PayloadAction<string[]>) => {
      if (state.isLoadingRecipients) return;
      state.selectedRecipientsIdsPerPage[state.recipientsPageNumber] = action.payload;
      state.selectedRecipientsIds = mapEntitiesPerPageToList<string>(state.selectedRecipientsIdsPerPage);
    },
    isOpenAiEnabledRequested: () => {},
    openAiEnabledReceived: (state, action: PayloadAction<boolean>) => {
      state.isOpenAiEnabled = action.payload;
    },
    setEditingRecipientsIds: (state, action: PayloadAction<string[]>) => {
      state.editingRecipientsIds = action.payload;
    },
    setIsSavingInProgress: (state, action: PayloadAction<boolean>) => {
      state.isSavingInProgress = action.payload;
    },
    getSelectedSendingOverviewRequested: () => {},
    selectedSendingOverviewReceived: (state, action: PayloadAction<SendingOverview>) => {
      state.selectedSendingOverview = action.payload;
    },
    resetAudienceTable: (state) => {
      state.selectedRecipientsIds = [];
      state.selectedRecipientsIdsPerPage = {} as EntitiesPerPage<string>;
      state.recipientsPageNumber = 1;
    },
    setSelectedRecipientsIds: (state, action: PayloadAction<string[]>) => {
      if (state.isLoadingRecipients) return;
      state.selectedRecipientsIdsPerPage[state.recipientsPageNumber] = action.payload;
      state.selectedRecipientsIds = mapEntitiesPerPageToList<string>(state.selectedRecipientsIdsPerPage);
    },
    setIsReportAIContentModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isReportAIContentModalOpen = action.payload;
    },
    addAiContentReportMessageRequested: (state, action: PayloadAction<string>) => {
      state.aiContentReportMessage = action.payload;
    },
    addAadContactsToSendingRequested: () => {},
    addAadContactsToSendingReceived: () => {}
  }
});

export const {
  templateAsImageRequested,
  templateAsImageReceived,
  resetSelectedSending,
  navigateToSendingWizardReceived,
  getSendersRequested,
  sendersReceived,
  openAddContactWizard,
  closeAddContactWizard,
  startLoadingContactsByNameOrEmailNotContainedInSendingRecipients,
  getContactsByNameOrEmailNotContainedInSendingRecipientsRequested,
  contactsByNameOrEmailReceived,
  addRecipientToSendingInStore,
  removeRecipientFromSendingInStore,
  addRecipientsToSendingRequested,
  recipientsAddedToSending,
  sendingLogsRequested,
  sendingLogsReceived,
  removeRecipientsFromSendingRequested,
  recipientsRemovedFromSending,
  updateSendingRecipientContactDataRequested,
  sendingRecipientContactDataUpdated,
  beeTokenRequested,
  beeTokenReceived,
  openAddFromCompanyPanel,
  closeAddFromCompanyPanel,
  getPeoplePickerSuggestionsRequested,
  peoplePickerSuggestionsReceived,
  addContactsFromCompanyInStore,
  removeContactFromCompanyInStore,
  openAddFromListPanel,
  closeAddFromListPanel,
  addContactsFromListsRequested,
  changeInEditorHappenedAfterOpenedFromTemplate,
  closeDismissWizardDialog,
  openTemplatesPopup,
  closeTemplatesPopup,
  openSaveTemplateDialog,
  closeSaveTemplateDialog,
  saveSelectedSendingEmailBody,
  chooseTemplate,
  updateSelectedSendingSetup,
  saveEmailBodyInStore,
  resetTemplateToAdd,
  openProfilePanel,
  closeProfilePanel,
  addTagToSendingRequested,
  removeTagFromSendingRequested,
  setSendingsLogPageNumber,
  setSendingsLogPageSize,
  tagAddedToSending,
  removeTagFromSendingInStore,
  resetSelectedSendingAfterUndo,
  addNewTagToSelectedItemRequested,
  selectedSendingUpdatedFromSync,
  setEmailPanelActiveIndex,
  getSelectedSendingRequested,
  selectedSendingReceived,
  openDeleteRecipientDialog,
  closeDeleteRecipientDialog,
  blacklistFromPreviewTabRequested,
  blacklistFromPreviewTabReceived,
  updateRecipientToAdd,
  openMissingFieldErrorDialog,
  closeMissingFieldErrorDialog,
  openSendDialog,
  closeSendDialog,
  openScheduleSendDialog,
  closeScheduleSendDialog,
  sendSendingRequested,
  scheduleSendingRequested,
  createNewBlankEmailRequested,
  newBlankEmailCreated,
  createNewEmailWithRecipientsRequested,
  newEmailWithRecipientsCreated,
  updateRecipientFromList,
  removeRecipientFromListInStore,
  createNewEmailWithRecipientsFromListRequested,
  newEmailWithRecipientsFromListCreated,
  addedNewTag,
  addedTagToSending,
  removedTagsFromSending,
  setCurrentStep,
  goToNextStep,
  goToPreviousStep,
  goToAddContactStep,
  updateSendingRequested,
  gotoAddFromListStep,
  getListsByNameRequested,
  listsByNameReceived,
  getContactsByListIdRequested,
  contactsByListIdReceived,
  pageOfRecipientsRequested,
  pageOfRecipientsReceived,
  sendingAudienceRequested,
  updateSendingRecipientForTheCorrespondingContact,
  sendingAudienceReceived,
  loadingAudienceThrewAnError,
  setRecipientsQueryParams,
  setRecipientsPageNumber,
  setRecipientsPageSize,
  sendingWizardUpdated,
  createSendingFromTemplateRequested,
  sendingFromTemplateCreated,
  addTemplateRequested,
  updateSelectedTemplate,
  updateTemplateRequested,
  pageOfSendingsForEditorReceived,
  pageOfSendingsForEditorRequested,
  setSelectedRecipientsIdsIfNotLoading,
  isOpenAiEnabledRequested,
  openAiEnabledReceived,
  imageLoaded,
  startLoadingImage,
  setEditingRecipientsIds,
  setIsSavingInProgress,
  getSelectedSendingOverviewRequested,
  selectedSendingOverviewReceived,
  resetAudienceTable,
  setSelectedRecipientsIds,
  setIsReportAIContentModalOpen,
  addAiContentReportMessageRequested,
  addAadContactsToSendingRequested,
  addAadContactsToSendingReceived
} = sendingWizardSlice.actions;

export default sendingWizardSlice.reducer;
