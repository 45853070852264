import { Timeout } from 'managed-timeout';
import { PendingAction, UndoableAction, defaultTimeoutInMilliseconds } from 'app/common';

export class UndoQueue {
  private pendingActions: PendingAction[] = [];

  waitAndExecute(action: UndoableAction): void {
    this.executeAllPendingActions();
    const timeout = new Timeout(action.executeAction, defaultTimeoutInMilliseconds);
    this.pendingActions.push({ timeout: timeout, undoAction: action.undoAction });
  }

  cancelAndRevertLatestAction(): void {
    const latestAction = this.pendingActions.pop();
    latestAction.timeout.cancel();
    latestAction.undoAction();
  }

  executeAllPendingActions(): void {
    this.pendingActions.forEach((pa) => pa.timeout.isPending && pa.timeout.execute());
    this.pendingActions = [];
  }

  hasPendingActions(): boolean {
    return this.pendingActions.length > 0;
  }
}

export const undoQueue = new UndoQueue();
