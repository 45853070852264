import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import {
  AddCompanyWizardSteps,
  goToStepAddCompany,
  selectAddCompanyCurrentStep,
  selectCompanyToAdd,
  selectIsAddCompanyWizardProfileInfoStepValid
} from 'app/pages/my-audience/contact-profile';

export const AddCompanySteps = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const step = useAppSelector<AddCompanyWizardSteps>(selectAddCompanyCurrentStep);
  const isAddCompanyWizardProfileInfoStepValid = useAppSelector(selectIsAddCompanyWizardProfileInfoStepValid);
  const companyToAdd = useAppSelector(selectCompanyToAdd);

  const styleBreadcrumbStatus = (stepToCompareWith: AddCompanyWizardSteps): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: AddCompanyWizardSteps) => {
    dispatch(goToStepAddCompany(step));
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddCompanyWizardSteps.SearchCompany)}
            content={formatMessage({ id: 'add-medium-wizard-steps.search-medium' }, { mediumType: formatMessage({ id: 'labels.company' }) })}
            onClick={() => handleStepButtonClick(AddCompanyWizardSteps.SearchCompany)}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddCompanyWizardSteps.ProfileInformation)}
            content={formatMessage({ id: 'add-medium-wizard-steps.profile-information' })}
            onClick={() => handleStepButtonClick(AddCompanyWizardSteps.ProfileInformation)}
            disabled={!companyToAdd?.id}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddCompanyWizardSteps.Finish)}
            content={formatMessage({ id: 'add-medium-wizard-steps.finish' })}
            onClick={() => handleStepButtonClick(AddCompanyWizardSteps.Finish)}
            disabled={!companyToAdd?.id || !isAddCompanyWizardProfileInfoStepValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
