import { Icon } from '@fluentui/react';
import { Button, Dropdown, DropdownProps, EditIcon, Tooltip } from '@fluentui/react-northstar';
import {
  CopyToClipboardTooltip,
  isStringEmpty,
  hideDropdownEditActions,
  hideEditAndCopyActions,
  selectAreEditAndCopyActionsVisible,
  selectEditableFieldActionsId,
  selectEditableFieldId,
  selectIsDropdownEditing,
  setEditableFieldActionsId,
  setEditableFieldId,
  showDropdownEditActions,
  showEditAndCopyActions,
  useAppDispatch,
  useAppSelector,
  printData
} from 'app/common';
import { useIntl } from 'app/i18n';
import _uniqueId from 'lodash/uniqueId';
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

interface EditableDropdownFieldProps {
  value: string;
  fieldName: string;
  suggestions: string[];
  onSaveChanges: (name: string, updatedValue: string) => void;
  label: string;
  onCreateNew?: (name: string) => void;
  createNewEntityMessage?: string;
  disabled?: boolean;
  defaultValueIfEmpty?: string;
}

export const EditableDropdownField = ({
  value,
  fieldName,
  onSaveChanges,
  suggestions,
  label,
  onCreateNew,
  createNewEntityMessage,
  defaultValueIfEmpty,
  disabled = false
}: EditableDropdownFieldProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const [editableFieldId] = useState(parseInt(_uniqueId()));
  const [editableFieldActionsId] = useState(parseInt(_uniqueId()));

  const areEditAndCopyActionsVisble = useAppSelector<boolean>(selectAreEditAndCopyActionsVisible);
  const selectorEditableFieldId = useAppSelector<number>(selectEditableFieldId);
  const selectorEditableActionsId = useAppSelector<number>(selectEditableFieldActionsId);
  const isDropdownEditing = useAppSelector<boolean>(selectIsDropdownEditing);

  const [updatedValue, setUpdatedValue] = useState(value);

  const handleOnCreateNewEntity = () => {
    onCreateNew(updatedValue);
    setUpdatedValue('');
  };

  const noResultsMessage = createNewEntityMessage ? (
    <div onClick={handleOnCreateNewEntity}>{createNewEntityMessage}</div>
  ) : (
    formatMessage({ id: 'information-messages.no-matches' })
  );

  useEffect(() => {
    setUpdatedValue(value);
  }, [value, selectorEditableActionsId]);

  const handleCancelChanges = () => {
    if (isDropdownEditing) {
      setUpdatedValue(value);
      dispatch(hideDropdownEditActions());
    }
  };
  useOnClickOutside(ref, handleCancelChanges);

  const handleKeyboardEvents = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSaveChanges(fieldName, updatedValue);
      dispatch(hideDropdownEditActions());
    } else if (event.key === 'Escape') {
      handleCancelChanges();
      setUpdatedValue(value);
    }
  };

  const handleMouseEnter = () => {
    dispatch(setEditableFieldId(editableFieldId));
    dispatch(showEditAndCopyActions());
  };

  const handleMouseLeave = () => {
    dispatch(hideEditAndCopyActions());
  };

  const handleEditButtonClick = () => {
    if (disabled) return;
    dispatch(setEditableFieldActionsId(editableFieldActionsId));
    dispatch(showDropdownEditActions());
  };

  const handleSaveChanges = () => {
    dispatch(hideDropdownEditActions());

    if (!updatedValue) {
      return;
    }

    onSaveChanges(fieldName, updatedValue);
    setUpdatedValue(updatedValue);
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);
  return (
    <div className="editable-fields-row" ref={ref}>
      <span className="editable-fields-row-label">{label ? label + ':' : ''}</span>
      <div className="editable-fields-row-content editable-fields-row-content--dropdown" onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isDropdownEditing && editableFieldActionsId === selectorEditableActionsId ? (
          <div className="editable-fields-row-content-dropdown">
            <Dropdown
              search
              checkable
              items={suggestions}
              value={updatedValue}
              noResultsMessage={noResultsMessage}
              onBlur={handleBlur}
              open={isOpen}
              onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
              position="below"
              onSearchQueryChange={(_, dropdownData) => setUpdatedValue(dropdownData.searchQuery)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyboardEvents(e)}
              getA11ySelectionMessage={{
                onAdd: (item: string) => {
                  setUpdatedValue(item.toString());
                  return `${item} has been selected.`;
                }
              }}
            />
            <div className="editable-fields-input-actions">
              <Button onClick={handleCancelChanges} iconOnly content={<Icon iconName="Cancel" />} text />
              <Button onClick={handleSaveChanges} iconOnly content={<Icon iconName="CheckMark" />} text />
            </div>
          </div>
        ) : (
          <>
            <span className={isStringEmpty(updatedValue) ? 'editable-fields-value editable-fields-empty' : 'editable-fields-value'}>
              {printData(updatedValue, defaultValueIfEmpty)}
            </span>
            {areEditAndCopyActionsVisble && editableFieldId === selectorEditableFieldId ? (
              <div className="editable-fields-actions">
                <Tooltip
                  subtle={false}
                  pointing
                  trigger={<Button disabled={disabled} icon={<EditIcon />} onClick={handleEditButtonClick} iconOnly text size="small" />}
                  content={formatMessage({ id: 'button-tooltips.edit' })}
                  position="below"
                />
                <CopyToClipboardTooltip textToCopy={updatedValue} label={label} props={{ position: 'below' }} />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};
