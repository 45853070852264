import { useLocation, useNavigate } from 'react-router';
import { Icon } from '@fluentui/react';
import { Segment, List, Text } from '@fluentui/react-northstar';
import {
  FilterItem,
  HighlightedText,
  SearchCategoryName,
  SearchSuggestionCategory,
  closeSearchPopupOnMobile,
  selectIsOnMobile,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useIntl as useIntlTyped } from 'app/i18n';
import { useIntl } from 'react-intl';
import emptyImage from 'assets/images/empty-search.png';

interface SearchSuggestionListProps {
  searchSuggestionsCategories: SearchSuggestionCategory[];
  searchSuggestionsText: string;
  onClearAllFilters: () => void;
  onResetSuggestions: () => void;
  onSetFilterItems: (filterItems: FilterItem[]) => void;
  onSetSearchText: (searchText: string) => void;
  onSearch: () => void;
  viewAllMessage: string;
}

export const SearchSuggestionsList = ({
  searchSuggestionsCategories,
  searchSuggestionsText,
  onResetSuggestions,
  onSetFilterItems,
  onSetSearchText,
  onClearAllFilters,
  onSearch,
  viewAllMessage
}: SearchSuggestionListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage: formatMessageTyped } = useIntlTyped();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const isOnMobile = useAppSelector(selectIsOnMobile);

  const onSelectedItem = (selectedIndex: number, categoryName: string, filterFieldName: string) => {
    onClearAllFilters();
    const newInputValue = searchSuggestionsCategories?.find((suggestion) => SearchCategoryName[suggestion.name] === categoryName).listItems[selectedIndex]
      ?.text;
    onSetFilterItems([new FilterItem(filterFieldName, newInputValue)]);
    onSetSearchText(newInputValue);

    navigateToResultsPage(newInputValue);
  };

  const onSelectCategory = (searchCategoryName: string, filterItemName: string) => {
    onClearAllFilters();
    const filterValues = searchSuggestionsCategories?.find((suggestion) => SearchCategoryName[suggestion.name] === searchCategoryName).listItems;

    onSetFilterItems(filterValues.map((filterValue) => new FilterItem(filterItemName, filterValue.text)));
    onSetSearchText('');

    navigateToResultsPage(searchSuggestionsText);
  };

  const navigateToResultsPage = (searchText: string) => {
    onResetSuggestions();

    if (location.pathname.includes('results')) {
      if (location.pathname.includes('contacts')) {
        navigate(`/my-audience/contacts/results/${searchText}`);
      } else if (location.pathname.includes('media-outlets')) {
        navigate(`/my-audience/media-outlets/results/${searchText}`);
      } else if (location.pathname.includes('companies')) {
        navigate(`/my-audience/companies/results/${searchText}`);
      } else if (location.pathname.includes('lists')) {
        navigate(`/my-audience/lists/results/${searchText}`);
      }

      onSearch();
      return;
    }

    if (isOnMobile) {
      dispatch(closeSearchPopupOnMobile());
    }
    navigate(`${location.pathname}/results/${searchText}`);
  };

  const onSearchClick = (searchText: string) => {
    onClearAllFilters();
    onSetSearchText(searchText);
    navigateToResultsPage(searchText);
  };

  const getLabelFromCategory = (categoryName: SearchCategoryName) => {
    return formatMessage({ id: `search-suggestion-categories.${SearchCategoryName[categoryName]}` });
  };

  return (
    <>
      {!!searchSuggestionsCategories?.length && (
        <Segment className="dropdown-show-all-results-wrap">
          <div className="dropdown-show-all-results" onClick={() => onSearchClick(searchSuggestionsText)}>
            <div className="dropdown-search-icon-wrap">
              <Icon iconName="Search" className="dropdown-search-icon" />
            </div>
            <Text className="dropdown-show-all-results-text text-bold">{searchSuggestionsText}</Text>
            <Text className="dropdown-show-all-results-text">{formatMessageTyped({ id: 'search-suggestions.dropdown-view-all-results' })}</Text>
            <a className="search-help search-help--segments" href="https://augure.com/" target="_blank" rel="noreferrer">
              {formatMessageTyped({ id: 'search-suggestions.search-help' })}
            </a>
          </div>
        </Segment>
      )}
      <div className="dropdown-segments-wrap">
        <div className="dropdown-segments">
          {searchSuggestionsCategories
            ?.filter((suggestions) => Boolean(suggestions?.listItems?.length))
            .map((suggestionCategory, index) => (
              <Segment key={`dropdown-${index}`} className="dropdown-segment">
                <div className="dropdown-intro">
                  <Text
                    className="dropdown-intro-text"
                    content={`${getLabelFromCategory(suggestionCategory.name)} ${
                      suggestionCategory?.listItems?.length > 4 ? '(+' + (suggestionCategory?.listItems?.length - 4).toString() + ')' : ''
                    }`}
                  />
                  <div
                    className="dropdown-intro-link"
                    onClick={() => onSelectCategory(SearchCategoryName[suggestionCategory.name], suggestionCategory.filterItemFieldName)}
                  >
                    {viewAllMessage}
                  </div>
                </div>
                <List
                  onSelectedIndexChange={(e, newProps) => {
                    if (newProps) onSelectedItem(newProps.selectedIndex, SearchCategoryName[suggestionCategory.name], suggestionCategory.filterItemFieldName);
                  }}
                  selectable
                  items={suggestionCategory.listItems.slice(0, 4).map((listItem, index) => ({
                    key: `dropDown-list_item${index}`,
                    header: <HighlightedText textLine={listItem.text} term={searchSuggestionsText} />,
                    content: listItem.subText || '',
                    media: <ProfilePicture name={listItem.text} imageUrl={listItem.profilePictureUrl} size="small" />
                  }))}
                />
              </Segment>
            ))}
          {!searchSuggestionsCategories?.length && searchSuggestionsText && (
            <Segment className="empty-search">
              <div className="empty-search-wrap">
                <img className="empty-search-image" src={emptyImage} alt={formatMessageTyped({ id: 'search-suggestions.empty-search-img-alt' })} />
                <Text className="empty-search-text text-bold">{formatMessageTyped({ id: 'search-suggestions.empty-search-text' })}</Text>
                <Text className="empty-search-text">{formatMessageTyped({ id: 'search-suggestions.empty-search-description' })}</Text>
              </div>
            </Segment>
          )}
        </div>
      </div>
    </>
  );
};
