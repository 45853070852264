import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/redux/store';
import { DEFAULT_LOCALE, getLocale } from 'app/localization';
import { Audience, Frequency, MediaOutletReferenceLists, MediumSupportType, LocalizedListValue } from 'app/pages/my-audience/media-outlets';

export interface LocalizationState {
  fetchError: string;
  locale: string;
  frequencies: Frequency[];
  audiences: Audience[];
  mediumSupportTypes: MediumSupportType[];
}

const initialState: LocalizationState = {
  fetchError: null,
  locale: DEFAULT_LOCALE,
  frequencies: [],
  audiences: [],
  mediumSupportTypes: []
};

// Common
export type LocaleSelector = (state: RootState) => string;
export const selectLocale: LocaleSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) => state.locale
);

export type FetchLocalizationErrorSelector = (state: RootState) => string;
export const selectFetchLocalizationError: FetchLocalizationErrorSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) => state.fetchError
);

// Authorized

export type LocalizedAudiencesSelector = (state: RootState) => LocalizedListValue[];
export const selectLocalizedAudiences: LocalizedAudiencesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) =>
    state.audiences.map((audience) => new LocalizedListValue(audience.id, audience.key, audience.labels.find((l) => l.language.name === state.locale)?.value))
);

export type LocalizedAudiencesValuesSelector = (state: RootState) => string[];
export const selectLocalizedAudiencesValues: LocalizedAudiencesValuesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) => state.audiences.map((audience) => audience.labels.find((l) => l.language.name === state.locale)?.value)
);

export type LocalizedFrequenciesSelector = (state: RootState) => LocalizedListValue[];
export const selectLocalizedFrequencies: LocalizedFrequenciesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) =>
    state.frequencies.map(
      (frequency) => new LocalizedListValue(frequency.id, frequency.key, frequency.labels.find((l) => l.language.name === state.locale)?.value)
    )
);

export type FrequenciesValuesSelector = (state: RootState) => Frequency[];
export const selectFrequencies: FrequenciesValuesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) => state.frequencies
);

export type LocalizedFrequenciesValuesSelector = (state: RootState) => string[];
export const selectLocalizedFrequenciesValues: LocalizedFrequenciesValuesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) => state.frequencies.map((frequency) => frequency.labels.find((l) => l.language.name === state.locale)?.value)
);

export type LocalizedMediumSupportTypesSelector = (state: RootState) => LocalizedListValue[];
export const selectLocalizedMediumSupportTypes: LocalizedMediumSupportTypesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) =>
    state.mediumSupportTypes.map((mst) => new LocalizedListValue(mst.id, mst.key, mst.labels.find((l) => l.language.name === state.locale)?.value))
);

export type MediumSupportTypesValuesSelector = (state: RootState) => MediumSupportType[];
export const selectMediumSupportTypes: MediumSupportTypesValuesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) => state.mediumSupportTypes
);

export type LocalizedMediumSupportTypesValuesSelector = (state: RootState) => string[];
export const selectLocalizedMediumSupportTypesValues: LocalizedMediumSupportTypesValuesSelector = createSelector(
  (state: RootState) => state.localization,
  (state: LocalizationState) => state.mediumSupportTypes.map((mst) => mst.labels.find((l) => l.language.name === state.locale).value)
);

const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = getLocale(action.payload);
    },
    setFetchLocalizationError: (state, action: PayloadAction<string>) => {
      state.fetchError = action.payload;
    },
    referenceListLocalizationRequested: () => {},
    referenceListLocalizationReceived: (state, action: PayloadAction<MediaOutletReferenceLists>) => {
      state.audiences = action.payload.audiences;
      state.frequencies = action.payload.frequencies;
      state.mediumSupportTypes = action.payload.mediumSupportTypes;
    }
  }
});

export const { setLocale, setFetchLocalizationError, referenceListLocalizationRequested, referenceListLocalizationReceived } = localizationSlice.actions;

export default localizationSlice.reducer;
