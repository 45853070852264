import { Contact, ContactSearchResultTableData } from 'app/pages/my-audience/contacts';

export const createContactSearchResultTableData = (contact: Contact): ContactSearchResultTableData => {
  return new ContactSearchResultTableData(
    contact.id,
    contact.id,
    `${contact.firstName} ${contact.lastName}`,
    contact.profilePictureUrl,
    contact.collaborations
      .map((cd) => cd.jobTitle.name)
      .filter(Boolean)
      .join(', '),
    contact.collaborations
      .map((cd) => cd.medium.name)
      .filter(Boolean)
      .join(', '),
    contact.mediaResorts
      .map((mr) => mr.name)
      .filter(Boolean)
      .join(', '),
    contact.tags
      .map((tag) => tag.name)
      .filter(Boolean)
      .join(', '),
    contact.preferredLanguages
      .map((language) => language.name)
      .filter(Boolean)
      .join(', '),
    contact.ownContactData?.country || '',
    contact.dataSourceKey,
    contact.isUser
  );
};
