import { PillData, UndoablePills, WizardEmptyInfo, useAppDispatch, useAppSelector } from 'app/common';
import {
  addedListToContact,
  addListFromContactPanelRequested,
  addListToContactRequested,
  getListsByNameRequested,
  nextPageOfListsRequested,
  removedListFromContact,
  removeListFromContactRequested,
  selectFilteredLists,
  selectSelectedContactLists,
  undoListToRemove
} from 'app/pages/my-audience/contact-profile';
import { List } from 'app/pages/my-audience/lists';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';

export const Lists = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const filteredLists = useAppSelector<List[]>(selectFilteredLists);
  const selectedContactsLists = useAppSelector<List[]>(selectSelectedContactLists);

  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageLists);

  const handleSelectValueInStore = (id: string) => {
    const listToAdd = filteredLists.find((l) => l.id === id);
    dispatch(addedListToContact(listToAdd));
  };

  const handleDeselectValueInStore = (id: string) => {
    dispatch(removedListFromContact(id));
  };

  const handleAddListToContact = (id: string) => {
    const listToAdd = filteredLists.find((l) => l.id === id);

    dispatch(addListToContactRequested(listToAdd));
  };

  const handleCreateList = (listName: string) => {
    dispatch(addListFromContactPanelRequested({ name: listName } as List));
  };

  const handleRemoveListFromContact = (id: string) => {
    const listToRemove = selectedContactsLists.find((l) => l.id === id);
    dispatch(removeListFromContactRequested(listToRemove.id));
  };

  const handleFilterLists = (searchQuery: string) => dispatch(getListsByNameRequested(searchQuery));

  const handleUndoDeselectValueInStore = () => dispatch(undoListToRemove());

  const onLoadMoreCallback = (searchQuery: string) => {
    dispatch(nextPageOfListsRequested(searchQuery));
  };

  return (
    <div className="profile-panel-topic profile-panel-topic__list">
      <UndoablePills
        entityName={formatMessage({ id: 'labels.list' })}
        selectedValues={selectedContactsLists.map((l) => new PillData(l.id, l.name, true))}
        dropdownValues={filteredLists.map((l) => new PillData(l.id, l.name, true))}
        onAddValue={handleCreateList}
        onSelectValue={handleAddListToContact}
        onDeselectValue={handleRemoveListFromContact}
        filterValues={handleFilterLists}
        enabledDelete={false}
        onDeselectValueInStore={handleDeselectValueInStore}
        onSelectValueInStore={handleSelectValueInStore}
        onUndoDeselectValueInStore={handleUndoDeselectValueInStore}
        onUndoSelectValueInStore={handleDeselectValueInStore}
        disabled={!hasPermissionToEdit}
        onLoadMoreCallback={onLoadMoreCallback}
      />
      {selectedContactsLists.length === 0 && (
        <WizardEmptyInfo
          message={formatMessage({ id: 'list-accordion-item.empty-info-msg' })}
          description={formatMessage({ id: 'list-accordion-item.empty-info-text' })}
        />
      )}
    </div>
  );
};
