import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  MyAudience,
  ConsentPopup,
  MainNavigation,
  selectIsNavigationCollapsed,
  useAppSelector,
  selectIsOnDesktop,
  selectIsInTeams,
  useAppDispatch
} from 'app/common';
import { beeTokenRequested, isOpenAiEnabledRequested } from 'app/pages/my-activities/sending-wizard';
import { MyActivities } from 'app/pages/my-activities/sendings-overview/MyActivities';
import AuthContextProvider from 'auth/auth-context-provider';

export const AuthorizedRoutes = () => {
  const dispatch = useAppDispatch();

  const isNavigationCollapsed = useAppSelector(selectIsNavigationCollapsed);
  const isOnDesktop = useAppSelector(selectIsOnDesktop);
  const isInTeams = useAppSelector(selectIsInTeams);

  useEffect(() => {
    dispatch(beeTokenRequested());
    dispatch(isOpenAiEnabledRequested());
  }, [dispatch]);

  return (
    <AuthContextProvider>
      <div className={isInTeams ? 'container in-teams' : 'container portal'}>
        <div className={isNavigationCollapsed ? 'wrapper collapsed' : 'wrapper'}>
          {isOnDesktop && !isInTeams && <MainNavigation />}
          <Routes>
            <Route path="/" element={<Navigate to="my-audience" />} />
            <Route path="/my-audience/*" element={<MyAudience />} />
            <Route path="/my-activities/*" element={<MyActivities />} />
            <Route path="/auth" element={<ConsentPopup />} />
          </Routes>
        </div>
      </div>
    </AuthContextProvider>
  );
};
