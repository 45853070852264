import { useEffect } from 'react';

import { FilterItem, useAppDispatch } from 'app/common';
import { firstPageOfSavedSearchesRequested } from 'app/pages/my-audience/media-outlets-saved-searches';
import { MediaOutlets, setQueryParams } from 'app/pages/my-audience/media-outlets';

export const MediaOutletsWrap = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setQueryParams({ filterItems: [], searchText: '' }));
    dispatch(firstPageOfSavedSearchesRequested({ searchText: '', filterItem: {} as FilterItem }));
  }, [dispatch]);

  return <MediaOutlets />;
};
