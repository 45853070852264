import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfWizardContactsRequested,
  selectWizardContactsPageSize,
  selectWizardContactsTotalCount,
  selectIsLoadingWizardContacts,
  setPageNumber,
  setPageSize
} from 'app/pages/my-audience/company-profile';

export const ContactsTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfContacts = useAppSelector<number>(selectWizardContactsTotalCount);
  const pageSize = useAppSelector<number>(selectWizardContactsPageSize);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingWizardContacts);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
    dispatch(pageOfWizardContactsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setPageSize(pageSize));
    dispatch(pageOfWizardContactsRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfContacts}
      isLoadingData={isLoadingContacts}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
