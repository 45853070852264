import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AddListDocument,
  AddListMutation,
  DuplicateListsDocument,
  DuplicateListsMutation,
  FetchListsByQueryParamsPagedDocument,
  FetchListsByQueryParamsPagedQuery,
  FetchListsSearchSuggestionsDocument,
  FetchListsSearchSuggestionsQuery,
  ListSuggestionsByContactNameDocument,
  ListSuggestionsByContactNameQuery,
  ListSuggestionsByCreatedByDocument,
  ListSuggestionsByCreatedByQuery,
  ListSuggestionsByMediumNameDocument,
  ListSuggestionsByMediumNameQuery,
  ListSuggestionsByNameDocument,
  ListSuggestionsByNameQuery,
  ListSuggestionsByTagNameDocument,
  ListSuggestionsByTagNameQuery,
  RemoveListDocument,
  RemoveListMutation,
  UpdateListDocument,
  UpdateListMutation,
  RemoveListsMutation,
  RemoveListsDocument,
  RemoveTagFromListsMutation,
  RemoveTagFromListsDocument,
  AddTagToListsMutation,
  AddTagToListsDocument,
  ContactsTagInput,
  ContactsListInputType,
  BulkRefreshLinkedSavedSearchesMutation,
  BulkRefreshLinkedSavedSearchesDocument
} from 'app/common/graphql/generated/graphql-gateway';
import { IListsSortingInput, List, PageOfLists } from 'app/pages/my-audience/lists';
import { createList, createSearchSuggestionCategory, FilterItem, SearchSuggestionCategory, Tag, TagProjection } from 'app/common';

export const fetchListsByQueryParamsPaged = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  filterItems: FilterItem[],
  pageNumber: number,
  pageSize: number,
  sortingInput: IListsSortingInput
): Promise<PageOfLists> => {
  const response = await client.query<FetchListsByQueryParamsPagedQuery>({
    query: FetchListsByQueryParamsPagedDocument,
    variables: {
      searchText: searchText,
      filterItems: filterItems,
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      sortingInput: {
        listsSortOrder: sortingInput.listsSortOrder ?? 'ASC',
        listsSortOption: sortingInput.listsSortOption ?? 'LIST_NAME'
      }
    }
  });

  return new PageOfLists(
    response.data.lists.totalCount,
    response.data.lists.lists.map((list) => createList(list))
  );
};

export const fetchListSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string): Promise<SearchSuggestionCategory[]> => {
  const response = await client.query<FetchListsSearchSuggestionsQuery>({
    query: FetchListsSearchSuggestionsDocument,
    variables: {
      searchText: searchText
    }
  });

  return response.data.searchListsSuggestions.map((ssc) => createSearchSuggestionCategory(ssc));
};

export const removeList = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<string> => {
  const response = await client.mutate<RemoveListMutation>({
    mutation: RemoveListDocument,
    variables: {
      id
    }
  });
  return response.data.removeList.id;
};

export const updateList = async (client: ApolloClient<NormalizedCacheObject>, listId: string, list: List): Promise<List> => {
  const input: ContactsListInputType = {
    name: list.name,
    imageUrl: list.imageUrl,
    description: list.description
  };

  const response = await client.mutate<UpdateListMutation>({
    mutation: UpdateListDocument,
    variables: {
      listId,
      input
    }
  });

  return createList(response.data.updateList);
};

export const addList = async (client: ApolloClient<NormalizedCacheObject>, nameOfNewList: string): Promise<List> => {
  const input: ContactsListInputType = {
    name: nameOfNewList
  };
  const response = await client.mutate<AddListMutation>({
    mutation: AddListDocument,
    variables: {
      input
    }
  });

  return createList(response.data.addList);
};

export const copyLists = async (client: ApolloClient<NormalizedCacheObject>, listsIds: string[], userId: string): Promise<List[]> => {
  const response = await client.mutate<DuplicateListsMutation>({
    mutation: DuplicateListsDocument,
    variables: {
      listsIds,
      userId
    }
  });

  return response.data.duplicateLists.map((list) => createList(list));
};

export const fetchListSuggestionsByName = async (client: ApolloClient<NormalizedCacheObject>, name: string, take: number): Promise<string[]> => {
  const response = await client.query<ListSuggestionsByNameQuery>({
    query: ListSuggestionsByNameDocument,
    variables: {
      name,
      take
    }
  });

  return response.data.listsSuggestionsByName;
};

export const fetchListsSuggestionsByContactName = async (client: ApolloClient<NormalizedCacheObject>, name: string, take: number): Promise<string[]> => {
  const response = await client.query<ListSuggestionsByContactNameQuery>({
    query: ListSuggestionsByContactNameDocument,
    variables: {
      name,
      take
    }
  });

  return response.data.listsSuggestionsByContactName;
};

export const fetchListsSuggestionsByMediumName = async (client: ApolloClient<NormalizedCacheObject>, name: string, take: number): Promise<string[]> => {
  const response = await client.query<ListSuggestionsByMediumNameQuery>({
    query: ListSuggestionsByMediumNameDocument,
    variables: {
      name,
      take
    }
  });

  return response.data.listsSuggestionsByMediumName;
};

export const fetchListSuggestionsByTagName = async (client: ApolloClient<NormalizedCacheObject>, name: string, take: number): Promise<string[]> => {
  const response = await client.query<ListSuggestionsByTagNameQuery>({
    query: ListSuggestionsByTagNameDocument,
    variables: {
      name,
      take
    }
  });

  return response.data.listsSuggestionsByTagName;
};

export const fetchListSuggestionsByCreatedBy = async (client: ApolloClient<NormalizedCacheObject>, name: string, take: number): Promise<string[]> => {
  const response = await client.query<ListSuggestionsByCreatedByQuery>({
    query: ListSuggestionsByCreatedByDocument,
    variables: {
      name,
      take
    }
  });

  return response.data.listsSuggestionsByCreatedBy;
};

export const removeListsByIds = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<List[]> => {
  const response = await client.mutate<RemoveListsMutation>({
    mutation: RemoveListsDocument,
    variables: {
      ids
    }
  });

  return response.data.removeLists.map((c) => createList(c));
};

export const addTagToLists = async (client: ApolloClient<NormalizedCacheObject>, listIds: string[], tag: Tag): Promise<TagProjection> => {
  const tagInput: ContactsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<AddTagToListsMutation>({
    mutation: AddTagToListsDocument,
    variables: {
      listIds: listIds,
      input: tagInput
    }
  });

  return new TagProjection(response.data.addTagToLists.id, response.data.addTagToLists.name);
};

export const removeTagFromLists = async (client: ApolloClient<NormalizedCacheObject>, listIds: string[], tag: Tag): Promise<TagProjection> => {
  const tagInput: ContactsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<RemoveTagFromListsMutation>({
    mutation: RemoveTagFromListsDocument,
    variables: {
      listIds: listIds,
      input: tagInput
    }
  });

  return new TagProjection(response.data.removeTagFromLists.id, response.data.removeTagFromLists.name);
};

export const bulkRefreshLinkedSavedSearches = async (client: ApolloClient<NormalizedCacheObject>, listsIds: string[]): Promise<List[]> => {
  const response = await client.mutate<BulkRefreshLinkedSavedSearchesMutation>({
    mutation: BulkRefreshLinkedSavedSearchesDocument,
    variables: {
      listsIds
    }
  });

  return response.data.bulkRefreshLinkedSavedSearches.map((list) => createList(list));
};
