import { useCallback, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Icon } from '@fluentui/react';
import { Loader } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';
import {
  ProfilePanelWrap,
  ThreeDotMenu,
  ThreeDotMenuItems,
  User,
  openBottomPopup,
  selectIsOnDesktop,
  selectIsOnTouch,
  selectSignedInUser,
  useAppDispatch,
  useAppSelector,
  getSendingStatusLocalizationKey
} from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { AudienceTab, LogTab, NavigationMenu, PreviewTab, ReportTab, SendingTags } from 'app/pages/my-activities/sending-profile';
import { selectIsLoadingSendingProfile, selectSelectedSending, setEmailPanelActiveIndex } from 'app/pages/my-activities/sending-wizard';
import { Sending, SendingStatus, openRemoveSendingsDialog } from 'app/pages/my-activities/sendings';
import { Permission, useAuthContext } from 'auth';
import 'sass/shared/profile-panel/ProfilePanel.scss';

interface SendingPanelProps {
  opened: boolean;
  dismissPanel: () => void;
  editSending: (sendingId: string) => void;
  setAsFavourite: (sendingId: string) => void;
  duplicateSending: (sendingId: string) => void;
}

export const SendingPanel = ({ opened, editSending, dismissPanel, setAsFavourite, duplicateSending }: SendingPanelProps) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const selectedSending = useAppSelector<Sending>(selectSelectedSending);
  const isLoadingSendingProfile = useAppSelector<boolean>(selectIsLoadingSendingProfile);

  const signedInUser = useAppSelector<User>(selectSignedInUser);

  const signedInUserCreatedSending = selectedSending?.createdBy?.id === signedInUser.id;
  const canUpdateSending = signedInUserCreatedSending && hasPermission(Permission.ManageEmailings);

  const onSelectMenuItem = useCallback(
    (path: string) => {
      const route = path.toLowerCase();
      if (route.includes('report')) {
        dispatch(setEmailPanelActiveIndex(0));
        navigate(`report`, { replace: true });
      } else if (route.includes('audience')) {
        dispatch(setEmailPanelActiveIndex(0));
        navigate(`audience`, { replace: true });
      } else if (route.includes('preview')) {
        dispatch(setEmailPanelActiveIndex(1));
        navigate(`preview`, { replace: true });
      } else if (route.includes('log')) {
        dispatch(setEmailPanelActiveIndex(2));
        navigate(`log`, { replace: true });
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    onSelectMenuItem(location.pathname);
  }, [location.pathname, onSelectMenuItem]);

  const onDuplicateSending = () => {
    duplicateSending(selectedSending.id);
  };

  const onDeleteSending = () => {
    navigate('/my-activities/e-mailings');
    dispatch(openRemoveSendingsDialog());
  };

  const threeDotMenuItems: ThreeDotMenuItems[] = [
    ...([SendingStatus.Draft, SendingStatus.WaitingForDistribution].includes(selectedSending?.status) && isOnDesktop
      ? [
          {
            key: 'edit-icon-menu',
            title: formatMessage({ id: 'sending-profile.three-dots.edit' }),
            className: 'profile-panel-menu-item',
            icon: <Icon iconName="Edit" />,
            onClick: () => editSending(selectedSending.id),
            disabled: !canUpdateSending
          }
        ]
      : []),
    {
      key: 'favourite-icon-menu',
      title: selectedSending?.isFavourite
        ? formatMessage({ id: 'emailings-table.remove-favorite' })
        : formatMessage({ id: 'sending-profile.three-dots.mark-as-favourite' }),
      className: 'profile-panel-menu-item',
      icon: <Icon className="row-icon-star" iconName="FavoriteStar" />,
      onClick: () => setAsFavourite(selectedSending.id),
      disabled: !canUpdateSending
    },
    {
      key: 'duplicate-icon-menu',
      title: formatMessage({ id: 'sending-profile.three-dots.duplicate' }),
      className: 'profile-panel-menu-item',
      icon: <Icon iconName="Copy" />,
      onClick: () => onDuplicateSending(),
      disabled: !canUpdateSending
    },
    {
      key: 'delete-icon-menu',
      title: formatMessage({ id: 'sending-profile.three-dots.delete' }),
      className: 'profile-panel-menu-item danger',
      icon: <Icon iconName="Delete" />,
      onClick: () => onDeleteSending(),
      disabled: !canUpdateSending
    }
  ];

  const openMobileBottomPopup = () => {
    dispatch(openBottomPopup(threeDotMenuItems));
  };

  const routeToAudienceTab = () => {
    dispatch(setEmailPanelActiveIndex(0));
    navigate(`audience`, { replace: true });
  };

  const deliveredAt = selectedSending?.deliveredAt ? DateTime.fromJSDate(selectedSending?.deliveredAt) : DateTime.utc();

  return (
    <ProfilePanelWrap isProfilePanelOpened={opened} onDismissButtonClick={dismissPanel} width="700px">
      {isLoadingSendingProfile ? (
        <Loader className="profile-panel-loader" size="medium" labelPosition="below" label="Loading..." />
      ) : (
        <div className="profile-panel">
          <div className="profile-panel-intro sending-profile-panel">
            <div className="profile-panel-image panel-avatar-holder">
              <div className={'profile-panel-avatar-wrap'}>
                <ProfilePicture name={selectedSending?.title} imageUrl={selectedSending?.imageAsBase64} size={'largest'} />
              </div>
            </div>
            <div className="profile-panel-content">
              <div className="profile-panel-information panel-intro-info">
                <h4 className="panel-intro-title">{`${selectedSending?.title}`}</h4>
                <span className="panel-info-description">
                  {`${selectedSending?.status ? formatMessage({ id: getSendingStatusLocalizationKey(selectedSending.status) }) : ''}`}
                  {selectedSending?.status === SendingStatus.SuccessfulDistribution &&
                    `: ${deliveredAt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at ${deliveredAt.toLocaleString(DateTime.TIME_WITH_SECONDS)}`}
                </span>
              </div>
              <SendingTags disabled={!canUpdateSending} />
            </div>
            <div className="profile-panel-menu">
              {isOnDesktop && <ThreeDotMenu threeDotMenuItems={threeDotMenuItems} />}
              {isOnTouch && (
                <div className="bottom-popup-icon-wrap" onClick={openMobileBottomPopup}>
                  <button className="bottom-popup-icon">
                    <Icon iconName="MoreVertical" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <NavigationMenu onSelectMenuItem={onSelectMenuItem} />
          <div className="profile-panel-main">
            <Routes>
              <Route path="audience" element={<AudienceTab disabled={!canUpdateSending} />} />
              <Route path="report" element={<ReportTab />} />
              <Route path="preview" element={<PreviewTab routeToAudienceTab={routeToAudienceTab} />} />
              <Route path="log" element={<LogTab />} />
            </Routes>
          </div>
        </div>
      )}
    </ProfilePanelWrap>
  );
};
