import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { Accordion, Button, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { Panel } from '@fluentui/react';
import {
  ControlledFilterDropDown,
  FilterItem,
  FilterItemType,
  selectIsOnMobile,
  useAppDispatch,
  useAppSelector,
  useControlledFilterDropDown,
  useResetFilterQueriesOnPanelClose
} from 'app/common';
import {
  addMediaOutletsFilter,
  categoryFilterSuggestionsRequested,
  clearAllFilters,
  closeFiltersPanel,
  contactNameFilterSuggestionsRequested,
  firstPageOfMediaOutletsRequested,
  languageFilterSuggestionsRequested,
  mediaOutletNameFilterSuggestionsRequested,
  removeMediaOutletsFilter,
  selectCategoryFilterSuggestions,
  selectContactNameFilterSuggestions,
  selectFilterItems,
  selectIsFiltersPanelOpened,
  selectIsLoadingCategorySuggestions,
  selectIsLoadingContactNameSuggestions,
  selectIsLoadingLanguageSuggestions,
  selectIsLoadingMediaOutletNameSuggestions,
  selectIsLoadingTagNameSuggestions,
  selectLanguageFilterSuggestions,
  selectMediaOutletNameFilterSuggestions,
  selectTagNameFilterSuggestions,
  startLoadingCategorySuggestions,
  startLoadingContactNameSuggestions,
  startLoadingLanguageSuggestions,
  startLoadingMediaOutletNameSuggestions,
  startLoadingTagNameSuggestions,
  tagNameFilterSuggestionsRequested
} from 'app/pages/my-audience/media-outlets';
import { MEDIA_OUTLET_FILTER } from 'app/pages/my-audience/media-outlets-filter-search/filter-fields';
import { useIntl } from 'app/i18n';
import { selectLocalizedFrequenciesValues, selectLocalizedMediumSupportTypesValues } from 'app/localization';

export const MediaOutletFiltersPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const isFilterPanelOpen = useAppSelector(selectIsFiltersPanelOpened);
  const filterItems = useAppSelector(selectFilterItems);
  const isOnMobile = useAppSelector(selectIsOnMobile);

  const nameControl = useControlledFilterDropDown(mediaOutletNameFilterSuggestionsRequested, startLoadingMediaOutletNameSuggestions);
  const contactNameControl = useControlledFilterDropDown(contactNameFilterSuggestionsRequested, startLoadingContactNameSuggestions);
  const tagNameControl = useControlledFilterDropDown(tagNameFilterSuggestionsRequested, startLoadingTagNameSuggestions);
  const mediaSupportTypeControl = useControlledFilterDropDown();
  const publishingFrequencyControl = useControlledFilterDropDown();
  const categoryControl = useControlledFilterDropDown(categoryFilterSuggestionsRequested, startLoadingCategorySuggestions);
  const languageControl = useControlledFilterDropDown(languageFilterSuggestionsRequested, startLoadingLanguageSuggestions);

  const { resetQueries } = useResetFilterQueriesOnPanelClose(
    [nameControl, contactNameControl, tagNameControl, mediaSupportTypeControl, publishingFrequencyControl, tagNameControl, categoryControl, languageControl],
    isFilterPanelOpen
  );

  const handleFilterAdd = ({ fieldName, value }: FilterItemType) => {
    dispatch(addMediaOutletsFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfMediaOutletsRequested());
  };

  const handleFilterRemoval = ({ fieldName, value }: FilterItemType) => {
    dispatch(removeMediaOutletsFilter(new FilterItem(fieldName, value)));
    dispatch(firstPageOfMediaOutletsRequested());
  };

  const handleClearAll = () => {
    dispatch(clearAllFilters());
    dispatch(firstPageOfMediaOutletsRequested());

    resetQueries();
  };

  const handleDismissFilterPanel = () => {
    dispatch(closeFiltersPanel());
    navigate(-1);
  };

  useOnClickOutside(ref, handleDismissFilterPanel);

  const filters = [
    {
      key: 'item-mo-names',
      name: MEDIA_OUTLET_FILTER.NAME,
      displayName: formatMessage({ id: 'filters.names' }),
      placeholder: formatMessage({ id: 'filters.filter-by-name' }),
      suggestionsSelector: selectMediaOutletNameFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingMediaOutletNameSuggestions,
      control: nameControl
    },
    {
      key: 'item-mo-contacts',
      name: MEDIA_OUTLET_FILTER.CONTACT_NAME,
      displayName: formatMessage({ id: 'filters.contacts' }),
      placeholder: formatMessage({ id: 'filters.filter-by-contact-name' }),
      suggestionsSelector: selectContactNameFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingContactNameSuggestions,
      control: contactNameControl
    },
    {
      key: 'item-mo-tags',
      name: MEDIA_OUTLET_FILTER.TAG,
      displayName: formatMessage({ id: 'filters.tags' }),
      placeholder: formatMessage({ id: 'filters.filter-by-tag' }),
      suggestionsSelector: selectTagNameFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingTagNameSuggestions,
      control: tagNameControl
    },
    {
      key: 'item-mo-categories',
      name: MEDIA_OUTLET_FILTER.CATEGORY,
      displayName: formatMessage({ id: 'media-outlet-labels.category' }),
      placeholder: formatMessage({ id: 'filters.filter-by-category' }),
      suggestionsSelector: selectCategoryFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingCategorySuggestions,
      control: categoryControl
    },
    {
      key: 'item-mo-support-types',
      name: MEDIA_OUTLET_FILTER.TYPE,
      displayName: formatMessage({ id: 'media-outlet-labels.media-support-types' }),
      placeholder: formatMessage({ id: 'filters.filter-by-media-support-types' }),
      suggestionsSelector: selectLocalizedMediumSupportTypesValues,
      isLoadingSuggestionsSelector: (_) => false,
      handleItemSelected: (filterItem: FilterItem) => handleFilterAdd(filterItem),
      control: mediaSupportTypeControl
    },
    {
      key: 'item-mo-pub-freq',
      name: MEDIA_OUTLET_FILTER.FREQUENCY,
      displayName: formatMessage({ id: 'media-outlet-labels.frequency' }),
      placeholder: formatMessage({ id: 'filters.filter-by-frequencies' }),
      suggestionsSelector: selectLocalizedFrequenciesValues,
      isLoadingSuggestionsSelector: (_) => false,
      handleItemSelected: (filterItem: FilterItem) => handleFilterAdd(filterItem),
      control: publishingFrequencyControl
    },
    {
      key: 'item-mo-languages',
      name: MEDIA_OUTLET_FILTER.LANGUAGE,
      displayName: formatMessage({ id: 'filters.languages' }),
      placeholder: formatMessage({ id: 'filters.filter-by-languages' }),
      suggestionsSelector: selectLanguageFilterSuggestions,
      isLoadingSuggestionsSelector: selectIsLoadingLanguageSuggestions,
      control: languageControl
    }
  ];

  const panels = filters.map(({ key, name, displayName, placeholder, control, suggestionsSelector, isLoadingSuggestionsSelector, handleItemSelected }) => ({
    title: (
      <div className="accordion-head-box" key={`${key}-title`}>
        <span className="accordion-heading">{displayName}</span>
        <ChevronDownMediumIcon />
      </div>
    ),
    content: {
      key: `${key}-content`,
      content: (
        <ControlledFilterDropDown
          name={name}
          placeholder={placeholder}
          onFilterAdd={handleItemSelected ?? handleFilterAdd}
          onFilterRemoval={handleFilterRemoval}
          suggestionsSelector={suggestionsSelector}
          isLoadingSuggestionsSelector={isLoadingSuggestionsSelector}
          items={filterItems.filter((filterItem) => filterItem.fieldName === name)}
          {...control}
        />
      )
    },
    className: 'filter-accordion-item'
  }));

  return (
    <div ref={ref}>
      <Panel
        isBlocking={false}
        customWidth="340px"
        className="filter-panel"
        headerText={formatMessage({ id: 'filters.filters' })}
        isOpen={isFilterPanelOpen}
        onDismiss={handleDismissFilterPanel}
      >
        <div className="filter-panel-accordions">
          <Accordion panels={panels} />
        </div>
        <div className="filter-panel-actions-wrap">
          {filterItems.length > 0 && (
            <div className="filter-panel-actions-holder">
              <div className="filter-panel-actions">
                <Button className="filter-panel-actions-clear" text content={formatMessage({ id: 'filters.clear' })} onClick={handleClearAll} />
              </div>
              {isOnMobile && (
                <div className="filter-panel-actions">
                  <Button className="filter-panel-actions-apply" primary content={formatMessage({ id: 'buttons.apply' })} onClick={handleDismissFilterPanel} />
                </div>
              )}
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};
