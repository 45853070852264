export const SENDING_FILTER = {
  TITLE: 'title',
  CREATED_BY: 'createdBy',
  CREATED_AT: 'createdAt',
  RECIPIENT_NAME_OR_EMAIL: 'emailOrName',
  MEDIUM_NAME: 'mediumName',
  CAMPAIGN: 'campaign',
  SUBJECT: 'emailSubject',
  TAG: 'tag',
  FAVOURITE: 'isFavourite',
  STATUS: 'sendingStatus'
};
