import { DoughnutWrapper, useAppSelector } from 'app/common';
import { SendingsOverviewViewModel, selectSendingsOverview } from 'app/pages/my-audience/contact-profile';
import { FormattedMessage } from 'app/i18n';
interface SendingsOverviewProps {
  onClickOnChart: () => void;
}

export const SendingsOverview = ({ onClickOnChart }: SendingsOverviewProps) => {
  const sendingsOverview = useAppSelector<SendingsOverviewViewModel>(selectSendingsOverview);

  const opensPercentage = sendingsOverview.totalSent && Math.round((100 / sendingsOverview.totalSent) * sendingsOverview.opens);
  const clicksPercentage = sendingsOverview.totalSent && Math.round((100 / sendingsOverview.totalSent) * sendingsOverview.clicks);
  const totalPercentage = sendingsOverview.totalSent && Math.round((100 / sendingsOverview.totalSent) * sendingsOverview.totalSent);

  return (
    <div className="profile-panel-chart-wrap">
      <div className="profile-panel-chart-column">
        <div className="profile-panel-chart">
          <DoughnutWrapper percentage={opensPercentage} onClick={onClickOnChart} />
          <span className="profile-panel-chart-number">{opensPercentage}%</span>
        </div>
        <div className="profile-panel-chart-info">
          <span className="profile-panel-chart-rate-number">{sendingsOverview.opens}</span>
          <span className="profile-panel-chart-title">
            <FormattedMessage id={'contacts-overview-accordion-item.opens-rate-label'} />
          </span>
        </div>
      </div>
      <div className="profile-panel-chart-column">
        <div className="profile-panel-chart">
          <DoughnutWrapper percentage={clicksPercentage} onClick={onClickOnChart} />
          <span className="profile-panel-chart-number">{clicksPercentage}%</span>
        </div>
        <div className="profile-panel-chart-info">
          <span className="profile-panel-chart-rate-number">{sendingsOverview.clicks}</span>
          <span className="profile-panel-chart-title">
            <FormattedMessage id={'contacts-overview-accordion-item.clicks-rate-label'} />
          </span>
        </div>
      </div>
      <div className="profile-panel-chart-column">
        <div className="profile-panel-chart">
          <DoughnutWrapper percentage={totalPercentage} onClick={onClickOnChart} />
          <span className="profile-panel-chart-number">{totalPercentage}%</span>
        </div>
        <div className="profile-panel-chart-info">
          <span className="profile-panel-chart-rate-number">{sendingsOverview.totalSent}</span>
          <span className="profile-panel-chart-title">
            <FormattedMessage id={'contacts-overview-accordion-item.total-sent-label'} />
          </span>
        </div>
      </div>
    </div>
  );
};
