import { Button, Popup } from '@fluentui/react-northstar';
import {
  DisableUnauthorized,
  hideUndoAlert,
  PillData,
  PillsPopupContent,
  SearchResultTableData,
  selectSelectedTableRows,
  showUndoAlert,
  Tag,
  undoQueue,
  useAppDispatch,
  useAppSelector,
  useSelectedTags
} from 'app/common';
import { useIntl } from 'app/i18n';
import {
  List,
  addNewTagRequested,
  addTagToListsInStore,
  addTagToListsRequested,
  addedTagToListsSearchResults,
  deleteTagsRequested,
  filterTags,
  removeTagFromListsInStore,
  removeTagFromListsRequested,
  removedTagsFromListsSearchResults,
  selectAllTags,
  selectFilteredTagsInPanel,
  selectLists
} from 'app/pages/my-audience/lists';
import { Permission } from 'auth';

export const ListsTagsPopup = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedListsTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const selectedListsIds = selectedListsTableRows.map((company) => company.id);
  const allLists = useAppSelector<List[]>(selectLists);
  const selectedTags = useSelectedTags(selectedListsTableRows, allLists);

  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);

  const onSelectValueHandler = (id: string) => {
    selectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => onAddTagToLists(id),
      undoAction: () => deselectValueInStore(id)
    });

    dispatch(showUndoAlert(formatMessage({ id: 'search-results-table.undo-select-tag' })));
  };

  const onDeselectValueHandler = (id: string) => {
    deselectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => onRemoveTagFromLists(id),
      undoAction: () => selectValueInStore(id)
    });

    dispatch(showUndoAlert(formatMessage({ id: 'search-results-table.undo-deselect-tag' })));
  };

  const deselectValueInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removedTagsFromListsSearchResults({ selectedListsIds, tags: [tag] }));
    dispatch(removeTagFromListsInStore({ selectedListsIds, tag }));
  };

  const selectValueInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addedTagToListsSearchResults({ selectedListsIds, tag }));
    dispatch(addTagToListsInStore({ selectedListsIds, tag }));
  };

  const onAddNewTag = (text: string) => {
    dispatch(addNewTagRequested({ selectedListsIds, text }));
  };

  const onAddTagToLists = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addTagToListsRequested({ selectedListsIds, tag }));
    dispatch(hideUndoAlert());
  };

  const onDeleteTags = (tagIds: string[]) => {
    const tags = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tags));
  };

  const onFilterTags = (text: string) => {
    dispatch(filterTags(text));
  };

  const onRemoveTagFromLists = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removeTagFromListsRequested({ selectedListsIds, tag }));
    dispatch(hideUndoAlert());
  };

  return (
    <Popup
      trigger={
        <div>
          <DisableUnauthorized permissions={[Permission.ManageLists]}>
            <Button text content={formatMessage({ id: 'search-results-table.add-update-tag' })} />
          </DisableUnauthorized>
        </div>
      }
      key="tags"
      inline={true}
      content={
        <PillsPopupContent
          entityName={formatMessage({ id: 'labels.tag' })}
          selectedValues={selectedTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          dropdownValues={filteredTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          onAddValue={onAddNewTag}
          onSelectValue={onSelectValueHandler}
          onDeselectValue={onDeselectValueHandler}
          onDeleteValues={onDeleteTags}
          filterValues={onFilterTags}
          enabledDelete={true}
        />
      }
    />
  );
};
