import { useMemo, useState } from 'react';
import {
  CommandBarButton,
  DetailsListLayoutMode,
  DetailsRow,
  Icon,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import { Button, Checkbox } from '@fluentui/react-northstar';
import { EmptyMessage, useAppDispatch, useAppSelector } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { Recipient } from 'app/pages/my-activities/sendings';
import { removeRecipientFromSendingInStore, selectRecipientsToAdd, updateRecipientToAdd } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';
import { WarningMessage } from 'app/common/components/warning-message/WarningMessage';

export const AddContactsTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const recipientsToAdd = useAppSelector<Recipient[]>(selectRecipientsToAdd);

  const [editingRecipientIndex, setRecipientIndexToEdit] = useState<number>();
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        selectionMode: SelectionMode.multiple
      }),
    []
  );

  const onNewContactDataSelected = (recipient: Recipient, recipientIndex: number, selectedMediumName: string) => {
    const collaboration = recipient.contact.collaborations.find((c) => c.medium.name === selectedMediumName);
    const emailAddress = collaboration ? collaboration.emailAddress : recipient.contact.ownContactData.emailAddress;

    dispatch(
      updateRecipientToAdd({
        recipientIndex: recipientIndex,
        collaborationId: collaboration !== undefined ? collaboration.id : '',
        emailAddress: emailAddress
      })
    );

    setRecipientIndexToEdit(-1);
  };

  const removeContact = (recipient: Recipient) => {
    dispatch(removeRecipientFromSendingInStore(recipient));
  };

  const header = [
    { key: 'add-contact-key-name', name: formatMessage({ id: 'contact-data-labels.name' }), fieldName: 'name', isResizable: true, minWidth: 80, maxWidth: 100 },
    {
      key: 'add-contact-key-title',
      name: formatMessage({ id: 'contact-data-labels.job-title' }),
      fieldName: 'jobTitle',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-email',
      name: formatMessage({ id: 'contact-data-labels.email-address' }),
      fieldName: 'email',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-contact-data',
      name: formatMessage({ id: 'labels.contact-data' }),
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    { key: 'add-contact-key-hover-action', name: '', fieldName: 'hoverActions', isResizable: false, minWidth: 60, maxWidth: 60 }
  ];

  const rows = [
    ...recipientsToAdd?.map((recipient, index) => {
      if (!recipient.contact?.id) {
        return {
          key: recipient.emailAddress,
          name: '',
          jobTitle: '',
          email: recipient.emailAddress,
          contactData: '',
          hoverActions: (
            <>
              {hoverIndex === index && (
                <div className="table-actions">
                  <Button text icon={<Icon iconName="Clear" />} onClick={() => removeContact(recipient)} />
                </div>
              )}
            </>
          )
        };
      }

      const primaryCollaboration = recipient.contact.collaborations.find((c) => c.id === recipient.collaborationId);
      const contactDataDropdown = [
        ...recipient.contact.collaborations.map((collaboration) => ({
          key: collaboration.id,
          text: collaboration.medium.name,
          onClick: () => onNewContactDataSelected(recipient, index, collaboration.medium.name)
        })),
        {
          key: recipient.contact.id,
          text: formatMessage({ id: 'labels.own-contact-data' }),
          onClick: () => onNewContactDataSelected(recipient, index, '')
        }
      ];

      const maybeEmailAddress = recipient.emailAddress || (
        <WarningMessage warningText={formatMessage({ id: 'add-contacts-wizard.contact-has-no-email-address' })} />
      );

      return {
        key: recipient.contact.id,
        name: `${recipient.contact.firstName} ${recipient.contact.lastName}`,
        jobTitle: primaryCollaboration?.jobTitle?.name,
        email: maybeEmailAddress,
        contactData:
          editingRecipientIndex === index ? (
            <div key={`contact-data-dropdown-${recipient.contact.id}`}>
              <CommandBarButton text={formatMessage({ id: 'dropdown-placeholders.collaboration' })} menuProps={{ items: contactDataDropdown }} />
            </div>
          ) : (
            primaryCollaboration?.medium?.name || formatMessage({ id: 'labels.own-contact-data' })
          ),
        hoverActions: (
          <>
            {hoverIndex === index && (
              <div className="table-actions">
                <Button text icon={<Icon iconName="Edit" />} onClick={() => setRecipientIndexToEdit(index)} />
                <Button text icon={<Icon iconName="Clear" />} onClick={() => removeContact(recipient)} />
              </div>
            )}
          </>
        )
      };
    })
  ];

  return (
    <div className="table-wrap table-wrap--wizard">
      {rows.length > 0 ? (
        <div className="table-wrap table--list">
          <MarqueeSelection selection={selection} className="table" isDraggingConstrainedToRoot={true}>
            <ShimmeredDetailsList
              items={rows}
              enableShimmer={!rows}
              columns={header}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={formatMessage({ id: 'aria-labels.toggle-selection' })}
              ariaLabelForSelectAllCheckbox={formatMessage({ id: 'aria-labels.toggle-selection-for-all-items' })}
              checkButtonAriaLabel={formatMessage({ id: 'aria-labels.select-row' })}
              checkboxVisibility={2}
              onRenderRow={(rowProps: IDetailsRowProps) => (
                <div
                  onMouseEnter={() => {
                    setHoverIndex(rowProps.itemIndex);
                  }}
                >
                  <DetailsRow
                    key={rowProps.item.id}
                    {...rowProps}
                    onRenderCheck={() => (
                      <div className="row-header-wrap">
                        {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
                          <Checkbox checked={selection.getSelection().includes(rowProps.item)} />
                        ) : (
                          <ProfilePicture name={rowProps.item.name} imageUrl={rowProps.item.profilePictureUrl} size="small" />
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            />
          </MarqueeSelection>
        </div>
      ) : (
        <EmptyMessage
          srcValue="/wizard-empty-info.svg"
          textValueOptional={formatMessage({ id: 'no-contacts-selected-message.title' })}
          textValue={formatMessage({ id: 'no-contacts-selected-message.description' })}
        />
      )}
    </div>
  );
};
