import { Icon } from '@fluentui/react';
import {
  ChangePhotoDialog,
  defaultCompanyId,
  isTextInputValid,
  maximumCompanyNameLength,
  EditableInputTextField,
  selectIsOnDesktop,
  ThreeDotMenu,
  ThreeDotMenuItems,
  useAppDispatch,
  useAppSelector,
  openBottomPopup
} from 'app/common';
import { ProfilePanelAvatar } from 'app/pages/my-audience/common';
import { Company, selectIsRemoveCompanyDialogOpen, setIsRemoveCompanyDialogOpen, setIsTransformCompanyDialogOpen } from 'app/pages/my-audience/companies';
import {
  changeProfilePictureToUpload,
  closeChangeImageDialog,
  CompanyPanelQuickActions,
  CompanyTags,
  DeleteCompanyDialog,
  openChangeImageDialog,
  removeProfilePicturePreview,
  removeProfilePictureRequested,
  selectImageToUpload,
  selectIsChangeImageDialogOpened,
  selectProfilePicturePreviewUrl,
  selectSelectedCompany,
  updateCompanyRequested,
  updateProfilePictureRequested
} from 'app/pages/my-audience/company-profile';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';
import { Button } from '@fluentui/react-northstar';

export const CompanyProfilePanelIntro = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedCompany = useAppSelector<Company>(selectSelectedCompany);
  const isRemoveCompanyDialogOpen = useAppSelector<boolean>(selectIsRemoveCompanyDialogOpen);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const { hasAllPermissions } = useAuthContext();
  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);
  const hasPermissionToTransformIntoMediaOutlet = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts, Permission.ManageMediaOutlets]);

  const onDeleteCompany = () => {
    dispatch(setIsRemoveCompanyDialogOpen(true));
  };

  const threeDotMenuItems: ThreeDotMenuItems[] = [
    {
      key: 'transform-into-media-outlet',
      title: formatMessage({ id: 'company-profile-panel.transform' }),
      className: 'panel-intro-menu-item',
      icon: <Icon iconName="ContactCard" />,
      onClick: () => dispatch(setIsTransformCompanyDialogOpen(true)),
      disabled: !hasPermissionToTransformIntoMediaOutlet
    },
    {
      key: 'delete-company',
      title: formatMessage({ id: 'company-profile-panel.delete' }),
      className: 'panel-intro-menu-item danger',
      icon: <Icon iconName="Delete" />,
      onClick: onDeleteCompany,
      disabled: !hasPermissionToEdit
    }
  ];

  const handleOnAvatarClick = () => {
    dispatch(openChangeImageDialog());
  };

  const onSaveChanges = (fieldName, updatedValue) => {
    const updatedList = { ...selectedCompany, [fieldName]: updatedValue };
    dispatch(updateCompanyRequested(updatedList));
  };

  const companyIsExternal = !!selectedCompany.dataSourceKey;

  const getWarningMessages = () => {
    const messages = [];

    if (selectedCompany.id === defaultCompanyId) {
      messages.push(formatMessage({ id: 'delete-company-dialog.delete-default-company-text' }, { name: `${selectedCompany?.name}` }));
    }

    if (companyIsExternal) {
      messages.push(formatMessage({ id: 'delete-company-dialog.external-db-message' }, { name: `${selectedCompany?.name}` }));
    }

    return messages;
  };

  return (
    <div className="panel-intro">
      <ProfilePanelAvatar
        onClick={handleOnAvatarClick}
        lastName={selectedCompany.name}
        firstName=""
        imageUrl={selectedCompany.profilePictureUrl}
        disabled={!hasPermissionToEdit}
      />
      <div className="panel-intro-content editable">
        <div className="panel-intro-title">
          <EditableInputTextField
            value={selectedCompany.name}
            fieldName="name"
            onChange={(fieldName, updatedValue) => onSaveChanges(fieldName, updatedValue)}
            isLink={false}
            isFieldValid={(value: string) => isTextInputValid({ inputValue: value, maxInputLength: maximumCompanyNameLength })}
            isEditDisabled={companyIsExternal}
            editDisabledMessageKey={'button-tooltips.external-database-company'}
            disabled={!hasPermissionToEdit}
          />
        </div>
        <CompanyTags disabled={!hasPermissionToEdit} />
        <CompanyPanelQuickActions />
      </div>
      <div className="panel-intro-menu">{isOnDesktop && <ThreeDotMenu threeDotMenuItems={threeDotMenuItems} />}</div>
      {!isOnDesktop && (
        <div className="panel-intro-menu">
          <Button
            iconOnly
            icon={
              <Icon
                iconName={'MoreVertical'}
                onClick={() => {
                  dispatch(openBottomPopup(threeDotMenuItems.map((item) => ({ ...item, className: 'bottom-popup-items' }))));
                }}
              />
            }
          ></Button>
        </div>
      )}
      <ChangePhotoDialog
        selectImageToUpload={selectImageToUpload}
        selectProfilePicturePreviewUrl={selectProfilePicturePreviewUrl}
        selectIsDialogOpened={selectIsChangeImageDialogOpened}
        name={selectedCompany.name}
        changeProfilePictureToUpload={changeProfilePictureToUpload}
        removeProfilePictureRequested={removeProfilePictureRequested}
        updateProfilePictureRequested={updateProfilePictureRequested}
        removeProfilePicturePreview={removeProfilePicturePreview}
        closeDialog={closeChangeImageDialog}
      />
      <DeleteCompanyDialog
        selectedCompany={selectedCompany}
        opened={isRemoveCompanyDialogOpen}
        dialogTitle={formatMessage({ id: 'delete-company-dialog.are-you-sure' }, { name: selectedCompany?.name })}
        undoAlertMessage={formatMessage({ id: 'delete-company-dialog.company-deleted' }, { name: selectedCompany?.name })}
        warningMessages={getWarningMessages()}
      />
    </div>
  );
};
