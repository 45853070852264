import { FormattedMessage } from 'app/i18n';

export interface EmptySendingPreviewProps {
  onImageLoaded?: () => void;
}

export const EmptySendingPreview = ({ onImageLoaded }: EmptySendingPreviewProps) => {
  return (
    <>
      <img src="/empty-emailing.png" className="sending-image-preview empty" onLoad={onImageLoaded} alt="Empty Message Notification" />
      <div className="table-results-empty-text-wrap">
        <span className="table-results-empty-text">
          <FormattedMessage id={'emailing-wizard-preview-and-send-step.empty-img-title'} />
        </span>
        <span className="table-results-empty-text">
          <FormattedMessage id={'emailing-wizard-preview-and-send-step.empty-img-subtitle'} />
        </span>
      </div>
    </>
  );
};
