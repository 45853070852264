import { useNavigate, useParams } from 'react-router-dom';
import { FilterItem, SearchResultTableData, selectSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import { FilterPills } from 'app/pages/my-audience/common';
import { firstPageOfContactsRequested, openFiltersPanel, removeContactsFilter, selectFilterItems } from 'app/pages/my-audience/contacts';
import { selectIsProfilePanelOpened } from 'app/pages/my-audience/contact-profile';

export const ContactsFilterPills = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const filterItems = useAppSelector<FilterItem[]>(selectFilterItems);
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isContactProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const areFiltersVisible = selectedTableRows.length === 0 || isContactProfileOpened;

  const handleOpenFiltersPanel = () => {
    dispatch(openFiltersPanel());
    navigate(`/my-audience/contacts/results/${params.contactsQuery}/filter-contacts`);
  };

  const handleRemoveFilter = (filterItem: FilterItem) => dispatch(removeContactsFilter(filterItem));

  const handleSearchContacts = () => dispatch(firstPageOfContactsRequested());

  return (
    areFiltersVisible && (
      <FilterPills filterItems={filterItems} onOpenFiltersPanel={handleOpenFiltersPanel} onRemoveFilter={handleRemoveFilter} onSearch={handleSearchContacts} />
    )
  );
};
