import {
  fetchDataSourcesRequestedWatcher,
  getAllJobTitlesRequestedWatcher,
  mentionSuggestionsRequestedWatcher,
  getLanguagesRequestedWatcher,
  removeJobTitleRequestedWatcher,
  addJobTitleRequestedWatcher
} from 'app/common';
import {
  addAadContactsToSendingRequestedWatcher,
  addContactsFromListsRequestedWatcher,
  addRecipientsToSendingRequestedWatcher,
  addTagToSendingRequestedWatcher,
  createEmailFromTemplateRequestedWatcher,
  createNewBlankEmailRequestedWatcher,
  createNewEmailWithRecipientsFromListRequestedWatcher,
  createNewEmailWithRecipientsRequestedWatcher,
  fetchBeeTokenWatcher,
  fetchSendingLogsRequestedWatcher,
  fetchBlacklistFromPreviewTabWatcher,
  fetchListsByNameRequestedWatcher,
  fetchRecipientsBySendingIdAndContactNameRequestedWatcher,
  fetchTemplateAsImageWatcher,
  getContactsByListsIdsRequestWatcher,
  getContactSuggestionsRequestedWatcher,
  getPeoplePickerSuggestionsRequestWatcher,
  getSelectedSendingRequestedWatcher,
  getSendersRequestedWatcher,
  removeRecipientsFromSendingRequestedWatcher,
  removeTagFromSendingRequestedWatcher,
  scheduleSendingRequestedWatcher,
  sendSendingRequestedWatcher,
  updateRecipientContactDataRequestedWatcher,
  updateSendingFromWizardRequestedWatcher,
  updateTemplateBodyRequestedWatcher,
  fetchSendingsForEditorRequestedWatcher,
  fetchSendingAudienceWatcher,
  isOpenAiAddonEnabledRequestedWatcher,
  getSelectedSendingOverviewRequestedWatcher,
  addAiContentReportMessageRequestedWatcher
} from 'app/pages/my-activities/sending-wizard';
import {
  addSendingRequestedWatcher,
  addSendingsRequestedWatcher,
  duplicateSendingRequestedWatcher,
  duplicateSendingsRequestedWatcher,
  fetchSendingsByCreatedByWatcher,
  fetchSendingsByTitleWatcher,
  fetchSendingsCampaignWatcher,
  fetchSendingsEmailSubjectWatcher,
  fetchSendingsRecipientMediumNameWatcher,
  fetchSendingsRequestedWatcher,
  removeSendingRequestedWatcher,
  removeSendingsRequestedWatcher,
  updateSendingRequestedWatcher,
  updateSendingsRequestedWatcher,
  getAllSendingTagsRequestedWatcher,
  deleteTagsFromSendingsRequestedWatcher,
  addSendingTagRequestedWatcher,
  fetchSendingsRecipientEmailOrNameWatcher,
  fetchSendingsTagWatcher
} from 'app/pages/my-activities/sendings';
import {
  deleteTemplateRequestedWatcher,
  getTemplatesRequestedWatcher,
  templatesSuggestionsRequestedWatcher,
  updateTemplateRequestedWatcher
} from 'app/pages/my-activities/templates';
import { addTemplateRequestedWatcher } from 'app/pages/my-activities/templates/templates.saga';
import { fetchAllBlacklistsFlowWatcher } from 'app/pages/my-audience/common/common.saga';
import {
  cityFilterSuggestionsRequestedWatcher,
  companiesSuggestionsRequestedWatcher,
  companyNameFilterSuggestionsRequestedWatcher,
  contactNameFilterSuggestionsRequestedWatcher,
  countryFilterSuggestionsRequestedWatcher,
  databaseTypeFilterSuggestionsRequestedWatcher,
  fetchCompaniesPagedWatcher,
  postalCodeFilterSuggestionsRequestedWatcher,
  registrationNumberFilterSuggestionsRequestedWatcher,
  removeCompaniesByIdsWatcher,
  removeCompanyRequestedWatcher,
  tagNameFilterSuggestionsRequestedWatcher,
  transformCompanyIntoMediaOutletRequestedWatcher,
  addTagToCompaniesRequestedWatcher,
  removeTagFromCompaniesRequestedWatcher,
  deleteTagsFromCompaniesRequestedWatcher,
  addCompanyTagRequestedWatcher,
  getAllCompanyTagsRequestedWatcher
} from 'app/pages/my-audience/companies';
import {
  addCompanySavedSearchRequestedWatcher,
  fetchPagedCompanySavedSearchesWatcher,
  removeCompanySavedSearchRequestWatcher,
  updateCompanySavedSearchesRequestWatcher,
  updateCompanySavedSearchRequestWatcher,
  loadNextCompanySavedSearchAfterDeleteRequestWatcher
} from 'app/pages/my-audience/companies-saved-searches';
import {
  addCommentToCompanyRequestedWatcher,
  addContactCompanyRequestedWatcher,
  addContributionCompanyRequestedWatcher,
  fetchSelectedCompanyRequestedWatcher,
  getAllCompanyCategoriesRequestedWatcher,
  getCompanyContactsRequestedWatcher,
  getWizardContactsRequestedWatcher,
  removeCompanyProfilePictureRequestedWatcher,
  removeContributionCompanyRequestedWatcher,
  updateCompanyProfilePictureRequestedWatcher,
  updateCompanyRequestedWatcher,
  updateContributionCompanyRequestedWatcher
} from 'app/pages/my-audience/company-profile';
import {
  addActivityRequestedWatcher,
  addBlacklistToContactRequestedWatcher,
  addCollaborationRequestedWatcher,
  addCommentToContactRequestedWatcher,
  addCompanyAndCollaborationRequestedWatcher,
  addListRequestedWatcher,
  addListToContactRequestedWatcher,
  addMediaOutletAndCollaborationRequestedWatcher,
  addMediaResortToContactRequestedWatcher,
  addNewBlacklistRequestedWatcher,
  addTopicRequestedWatcher,
  addTopicToContactRequestedWatcher,
  fetchActivityEmailAddressRequestedWatcher,
  fetchContactProfilePanelDataFlowWatcher,
  selectedContactInfoReceivedWatcher,
  getAllMediaResortsRequestedWatcher as contactsMediaResortsWatcher,
  getAllTopicsRequestedWatcher,
  getCompaniesRequestedWatcher,
  getMediaOutletsRequestedWatcher,
  getSelectedContactActivitiesRequestedWatcher,
  selectedContactSendingActivitiesRequestedWatcher,
  getSelectedContactInfoRequestedWatcher,
  removeBlacklistFromContactRequestedWatcher,
  removeCollaborationRequestedWatcher,
  removeCommentFromContactRequestedWatcher,
  removeListFromContactRequestedWatcher,
  removeMediaResortFromContactRequestedWatcher,
  removeMediaResortsRequestedWatcher,
  removeProfilePictureRequestedWatcher,
  removeTopicFromContactRequestedWatcher,
  removeTopicsRequestedWatcher,
  updateCollaborationRequestedWatcher,
  updateContactRequestedWatcher,
  updateOwnContactDataRequestedWatcher,
  updateProfilePictureRequestedWatcher,
  updatePrimaryCollaborationRequestedWatcher,
  updatePrimaryOwnContactDataRequestedWatcher,
  fetchContactRecipientsRequestedWatcher,
  addListFromContactPanelRequestedWatcher,
  removeBlacklistsRequestedWatcher,
  getContactProfileListsByNameRequestedWatcher,
  getContactProfileListsNextPageRequestedWatcher,
  addMediaResortAndAssignToContactRequested,
  getContactsListsRequestedWatcher,
  addMediaResortAndAssignToContactWatcher
} from 'app/pages/my-audience/contact-profile';
import {
  addContactTagRequestedWatcher,
  createMediaResortWatcher,
  addTagToContactsRequestedWatcher,
  contactNameSuggestionsRequestedWatcher,
  contactSuggestionsRequestedWatcher,
  deleteTagsRequestedWatcher,
  fetchContactsPagedWatcher,
  getAllContactTagsRequestedWatcher,
  languageSuggestionsRequestedWatcher,
  mediumNameSuggestionsRequestedWatcher,
  removeContactRequestedWatcher,
  removeContactsByIdsWatcher,
  removeTagFromContactRequestedWatcher,
  resortSuggestionsRequestedWatcher,
  roleSuggestionsRequestedWatcher,
  tagNameSuggestionsRequestedWatcher,
  countrySuggestionsRequestedWatcher,
  updateContactsRequestedWatcher,
  createTopicRequestedWatcher,
  createListRequestedWatcher
} from 'app/pages/my-audience/contacts';
import {
  addContactSavedSearchRequestedWatcher,
  fetchPagedContactSavedSearchesWatcher,
  removeContactSavedSearchRequestWatcher,
  updateContactSavedSearchesRequestWatcher,
  updateContactSavedSearchRequestWatcher,
  loadNextContactSavedSearchAfterDeleteRequestWatcher
} from 'app/pages/my-audience/contacts-saved-searches';
import {
  copyListsRequestedWatcher,
  createdByContactsSuggestionsRequestedWatcher,
  fetchListsPagedWatcher,
  listContactFilterSuggestionsRequestedWatcher,
  listNameFilterSuggestionsRequestedWatcher,
  listSuggestionsRequestedWatcher,
  mediumsSuggestionsRequestedWatcher,
  removeListRequestedWatcher,
  removeListsByIdsWatcher,
  tagsSuggestionsRequestedWatcher,
  addTagToListsRequestedWatcher,
  removeTagFromListsRequestedWatcher,
  getAllListTagsRequestedWatcher,
  addListTagRequestedWatcher,
  deleteTagsFromListsRequestedWatcher,
  getListContactsFilterSuggestionsRequestedWatcher
} from 'app/pages/my-audience/lists';
import {
  addAadContactsToListRequestWatcher,
  addContactsFromExistingListsToListRequestWatcher,
  addContactsToListRequestWatcher,
  getWizardContactsRequestedWatcher as getListWizardContactsRequestedWatcher,
  getListsByNameRequestedWatcher,
  getPickerSuggestionsRequestWatcher,
  getListContactsRequestedWatcher,
  getSelectedListRequestedWatcher,
  getSelectedListSendingActivitiesRequestedWatcher,
  removeContactsFromListRequestWatcher,
  removeListProfilePictureRequestedWatcher,
  updateListProfilePictureRequestedWatcher,
  updateListRequestWatcher,
  updateContactInListRequestedWatcher,
  exportContactsRequestedWatcher,
  exportContactsVCardsRequestedWatcher,
  getContactsSavedSearchesRequestedWatcher,
  getNumberOfDesynchronizedContactsInListRequestedWatcher,
  getContactsByListIdsRequestWatcher,
  addContactsSavedSearchesToListRequestWatcher,
  removeContactsSavedSearchesFromListRequestWatcher,
  updateContactsSavedSearchRequestedWatcher,
  refreshLinkedSavedSearchRequestedWatcher,
  getMediumNamesByListIdRequestedWatcher,
  getLinkedSavedSearchesByListIdRequestedWatcher
} from 'app/pages/my-audience/lists-profile';
import {
  addMediaResortToMediaOutletRequestedWatcher,
  addCommentToMediaOutletRequestedWatcher,
  addContactRequestedWatcher,
  addContributionRequestedWatcher,
  getMediaOutletWizardContactsRequestedWatcher,
  getMediaOutletContactsRequestedWatcher,
  getSelectedMediaOutletRequestedWatcher,
  removeMediaResortFromMediaOutletRequestedWatcher,
  removeContributionRequestedWatcher,
  removeMediaResortsRequestWatcher,
  removeMediaOutletProfilePictureRequestedWatcher,
  updateMediaOutletCollaborationRequestedWatcher,
  updateMediaOutletProfilePictureRequestedWatcher,
  updateMediaOutletRequestedWatcher,
  getAllMediaResortsRequestedWatcher as mediaOutletsMediaResortsWatcher,
  addNewMediaResortRequestedWatcher as addMediaOutletMediaResortWatcher
} from 'app/pages/my-audience/media-outlet-profile';
import {
  addMediaOutletTagRequestedWatcher,
  addMediumCategoryRequestedWatcher,
  addTagToMediaOutletsRequestedWatcher,
  categoryFilterSuggestionsRequestedWatcher,
  contactFilterSuggestionsRequestedWatcher,
  createMediaResortsWatcher,
  deleteTagsFromMediaOutletsRequestedWatcher,
  fetchMediaOutletPanelDataRequestedWatcher,
  fetchMediaOutletsPagedWatcher,
  fetchReferenceListsLocalizationRequestedWatcher,
  getAllMediaOutletTagsRequestedWatcher,
  languageFilterSuggestionsRequestedWatcher,
  mediaOutletNameFilterSuggestionsRequestedWatcher,
  mediaOutletSuggestionsRequestedWatcher,
  removeMediaOutletRequestedWatcher,
  removeMediaOutletsByIdsWatcher,
  removeMediumCategoryRequestedWatcher,
  removeTagFromMediaOutletsRequestedWatcher,
  tagFilterSuggestionsRequestedWatcher,
  transformMediaOutletIntoCompanyRequestedWatcher,
  updateMediaOutletsRequestedWatcher
} from 'app/pages/my-audience/media-outlets';
import {
  addMediaOutletSavedSearchRequestedWatcher,
  fetchPagedMediaOutletsSavedSearchesWatcher,
  removeMediaOutletSavedSearchRequestWatcher,
  updateMediaOutletSavedSearchesRequestWatcher,
  updateMediaOutletSavedSearchRequestWatcher,
  loadNextMediaOutletSavedSearchAfterDeleteRequestWatcher
} from 'app/pages/my-audience/media-outlets-saved-searches';
import {
  addCollaborationCompanyWizardRequestedWatcher,
  addCompanyWizardWatcher,
  addContactFromCompanyWizardRequestedWatcher,
  getContactSuggestionsCompaniesRequestedWatcher
} from 'app/pages/my-audience/wizard-create-company';
import {
  addCompanyFromContactWizardWatcher,
  addContactFromWizardRequestedWatcher,
  addMediaOutletFromContactWizardWizardWatcher,
  mediaOutletsAndCompaniesRequestedWatcher
} from 'app/pages/my-audience/wizard-create-contact/wizard-create-contact.saga';
import {
  addContactFromMediaOutletWizardRequestedWatcher,
  addMediaOutletWizardWatcher,
  getContactSuggestionsMediaOutletRequestedWatcher
} from 'app/pages/my-audience/wizard-create-media-outlet/wizard-create-media-outlet.saga';
import { fork } from 'redux-saga/effects';

export default function* rootSaga() {
  yield fork(addActivityRequestedWatcher);
  yield fork(addBlacklistToContactRequestedWatcher);
  yield fork(addCollaborationRequestedWatcher);
  yield fork(addMediaResortToMediaOutletRequestedWatcher);
  yield fork(addCommentToCompanyRequestedWatcher);
  yield fork(addCommentToContactRequestedWatcher);
  yield fork(addCommentToMediaOutletRequestedWatcher);
  yield fork(addCompanySavedSearchRequestedWatcher);
  yield fork(addContactRequestedWatcher);
  yield fork(addContactSavedSearchRequestedWatcher);
  yield fork(addContributionRequestedWatcher);
  yield fork(addListToContactRequestedWatcher);
  yield fork(addMediaOutletSavedSearchRequestedWatcher);
  yield fork(addMediaResortToContactRequestedWatcher);
  yield fork(addTagToCompaniesRequestedWatcher);
  yield fork(addTagToContactsRequestedWatcher);
  yield fork(addTopicToContactRequestedWatcher);
  yield fork(companiesSuggestionsRequestedWatcher);
  yield fork(contactSuggestionsRequestedWatcher);
  yield fork(fetchPagedCompanySavedSearchesWatcher);
  yield fork(getAllCompanyTagsRequestedWatcher);
  yield fork(getAllJobTitlesRequestedWatcher);
  yield fork(mediaOutletsMediaResortsWatcher);
  yield fork(contactsMediaResortsWatcher);
  yield fork(fetchPagedMediaOutletsSavedSearchesWatcher);
  yield fork(getAllTopicsRequestedWatcher);
  yield fork(getSelectedContactInfoRequestedWatcher);
  yield fork(getMediaOutletContactsRequestedWatcher);
  yield fork(getSelectedMediaOutletRequestedWatcher);
  yield fork(mediaOutletSuggestionsRequestedWatcher);
  yield fork(mentionSuggestionsRequestedWatcher);
  yield fork(removeBlacklistFromContactRequestedWatcher);
  yield fork(removeMediaResortFromMediaOutletRequestedWatcher);
  yield fork(removeCommentFromContactRequestedWatcher);
  yield fork(removeCompanyProfilePictureRequestedWatcher);
  yield fork(removeCompanyRequestedWatcher);
  yield fork(removeCompanySavedSearchRequestWatcher);
  yield fork(removeContactRequestedWatcher);
  yield fork(removeContactSavedSearchRequestWatcher);
  yield fork(removeContributionRequestedWatcher);
  yield fork(removeListFromContactRequestedWatcher);
  yield fork(removeListRequestedWatcher);
  yield fork(removeMediaResortsRequestWatcher);
  yield fork(removeMediaOutletProfilePictureRequestedWatcher);
  yield fork(removeMediaOutletRequestedWatcher);
  yield fork(removeMediaOutletSavedSearchRequestWatcher);
  yield fork(removeMediaResortFromContactRequestedWatcher);
  yield fork(removeProfilePictureRequestedWatcher);
  yield fork(removeTagFromCompaniesRequestedWatcher);
  yield fork(removeTagFromContactRequestedWatcher);
  yield fork(removeTopicFromContactRequestedWatcher);
  yield fork(transformCompanyIntoMediaOutletRequestedWatcher);
  yield fork(transformMediaOutletIntoCompanyRequestedWatcher);
  yield fork(updateCollaborationRequestedWatcher);
  yield fork(updateCompanyProfilePictureRequestedWatcher);
  yield fork(updateCompanySavedSearchRequestWatcher);
  yield fork(loadNextCompanySavedSearchAfterDeleteRequestWatcher);
  yield fork(updateCompanySavedSearchesRequestWatcher);
  yield fork(updateContactRequestedWatcher);
  yield fork(updateContactSavedSearchRequestWatcher);
  yield fork(loadNextContactSavedSearchAfterDeleteRequestWatcher);
  yield fork(updateContactSavedSearchesRequestWatcher);
  yield fork(updateMediaOutletCollaborationRequestedWatcher);
  yield fork(updateMediaOutletProfilePictureRequestedWatcher);
  yield fork(updateMediaOutletRequestedWatcher);
  yield fork(updateMediaOutletSavedSearchRequestWatcher);
  yield fork(loadNextMediaOutletSavedSearchAfterDeleteRequestWatcher);
  yield fork(updateMediaOutletSavedSearchesRequestWatcher);
  yield fork(updateProfilePictureRequestedWatcher);
  yield fork(updateCompanyRequestedWatcher);
  yield fork(fetchSelectedCompanyRequestedWatcher);
  yield fork(removeCollaborationRequestedWatcher);
  yield fork(addMediaOutletAndCollaborationRequestedWatcher);
  yield fork(addCompanyAndCollaborationRequestedWatcher);
  yield fork(getCompanyContactsRequestedWatcher);
  yield fork(getWizardContactsRequestedWatcher);
  yield fork(addContactCompanyRequestedWatcher);
  yield fork(updateContributionCompanyRequestedWatcher);
  yield fork(removeContributionCompanyRequestedWatcher);
  yield fork(addContributionCompanyRequestedWatcher);
  yield fork(listSuggestionsRequestedWatcher);
  yield fork(updateOwnContactDataRequestedWatcher);
  yield fork(getAllListTagsRequestedWatcher);
  yield fork(addListTagRequestedWatcher);
  yield fork(deleteTagsFromListsRequestedWatcher);
  yield fork(addTagToListsRequestedWatcher);
  yield fork(removeTagFromListsRequestedWatcher);
  yield fork(updateListProfilePictureRequestedWatcher);
  yield fork(removeListProfilePictureRequestedWatcher);
  yield fork(updateListRequestWatcher);
  yield fork(getSelectedListRequestedWatcher);
  yield fork(addListRequestedWatcher);
  yield fork(getListContactsRequestedWatcher);
  yield fork(getListWizardContactsRequestedWatcher);
  yield fork(addContactsToListRequestWatcher);
  yield fork(removeContactsFromListRequestWatcher);
  yield fork(getPickerSuggestionsRequestWatcher);
  yield fork(addAadContactsToListRequestWatcher);
  yield fork(getListsByNameRequestedWatcher);
  yield fork(addContactsFromExistingListsToListRequestWatcher);
  yield fork(fetchReferenceListsLocalizationRequestedWatcher);
  yield fork(fetchContactProfilePanelDataFlowWatcher);
  yield fork(addContactsSavedSearchesToListRequestWatcher);
  yield fork(removeContactsSavedSearchesFromListRequestWatcher);
  yield fork(getSelectedContactActivitiesRequestedWatcher);
  yield fork(selectedContactSendingActivitiesRequestedWatcher);
  yield fork(getAllContactTagsRequestedWatcher);
  yield fork(addContactTagRequestedWatcher);
  yield fork(deleteTagsRequestedWatcher);
  yield fork(deleteTagsFromCompaniesRequestedWatcher);
  yield fork(updateContactsSavedSearchRequestedWatcher);
  yield fork(refreshLinkedSavedSearchRequestedWatcher);
  yield fork(addContactFromCompanyWizardRequestedWatcher);
  yield fork(getContactSuggestionsCompaniesRequestedWatcher);
  yield fork(addCompanyWizardWatcher);
  yield fork(addCollaborationCompanyWizardRequestedWatcher);
  yield fork(getContactSuggestionsMediaOutletRequestedWatcher);
  yield fork(addContactFromMediaOutletWizardRequestedWatcher);
  yield fork(addContactFromWizardRequestedWatcher);
  yield fork(addMediaOutletWizardWatcher);
  yield fork(fetchAllBlacklistsFlowWatcher);
  yield fork(addNewBlacklistRequestedWatcher);
  yield fork(getSelectedListSendingActivitiesRequestedWatcher);
  yield fork(getLinkedSavedSearchesByListIdRequestedWatcher);
  yield fork(createdByContactsSuggestionsRequestedWatcher);
  yield fork(tagsSuggestionsRequestedWatcher);
  yield fork(mediumsSuggestionsRequestedWatcher);
  yield fork(fetchDataSourcesRequestedWatcher);
  yield fork(copyListsRequestedWatcher);
  yield fork(companyNameFilterSuggestionsRequestedWatcher);
  yield fork(contactNameFilterSuggestionsRequestedWatcher);
  yield fork(tagNameFilterSuggestionsRequestedWatcher);
  yield fork(countryFilterSuggestionsRequestedWatcher);
  yield fork(registrationNumberFilterSuggestionsRequestedWatcher);
  yield fork(databaseTypeFilterSuggestionsRequestedWatcher);
  yield fork(cityFilterSuggestionsRequestedWatcher);
  yield fork(postalCodeFilterSuggestionsRequestedWatcher);
  yield fork(fetchCompaniesPagedWatcher);
  yield fork(languageFilterSuggestionsRequestedWatcher);
  yield fork(categoryFilterSuggestionsRequestedWatcher);
  yield fork(tagFilterSuggestionsRequestedWatcher);
  yield fork(contactFilterSuggestionsRequestedWatcher);
  yield fork(mediaOutletNameFilterSuggestionsRequestedWatcher);
  yield fork(fetchMediaOutletsPagedWatcher);
  yield fork(fetchContactsPagedWatcher);
  yield fork(fetchPagedContactSavedSearchesWatcher);
  yield fork(contactNameSuggestionsRequestedWatcher);
  yield fork(roleSuggestionsRequestedWatcher);
  yield fork(mediumNameSuggestionsRequestedWatcher);
  yield fork(resortSuggestionsRequestedWatcher);
  yield fork(languageSuggestionsRequestedWatcher);
  yield fork(tagNameSuggestionsRequestedWatcher);
  yield fork(countrySuggestionsRequestedWatcher);
  yield fork(fetchListsPagedWatcher);
  yield fork(listNameFilterSuggestionsRequestedWatcher);
  yield fork(listContactFilterSuggestionsRequestedWatcher);
  yield fork(getMediaOutletsRequestedWatcher);
  yield fork(getCompaniesRequestedWatcher);
  yield fork(addMediaResortAndAssignToContactWatcher);
  yield fork(getAllCompanyCategoriesRequestedWatcher);
  yield fork(removeCompaniesByIdsWatcher);
  yield fork(removeMediaOutletsByIdsWatcher);
  yield fork(removeListsByIdsWatcher);
  yield fork(removeContactsByIdsWatcher);
  yield fork(updateContactInListRequestedWatcher);
  yield fork(updateMediaOutletsRequestedWatcher);
  yield fork(updateContactsRequestedWatcher);
  yield fork(createMediaResortWatcher);
  yield fork(addTopicRequestedWatcher);
  yield fork(getAllMediaOutletTagsRequestedWatcher);
  yield fork(addTagToMediaOutletsRequestedWatcher);
  yield fork(removeTagFromMediaOutletsRequestedWatcher);
  yield fork(deleteTagsFromMediaOutletsRequestedWatcher);
  yield fork(addMediaOutletTagRequestedWatcher);
  yield fork(addCompanyTagRequestedWatcher);
  yield fork(mediaOutletsAndCompaniesRequestedWatcher);
  yield fork(fetchMediaOutletPanelDataRequestedWatcher);
  yield fork(addCompanyFromContactWizardWatcher);
  yield fork(addMediaOutletFromContactWizardWizardWatcher);
  yield fork(selectedContactInfoReceivedWatcher);
  yield fork(getMediaOutletWizardContactsRequestedWatcher);
  yield fork(removeMediaResortsRequestedWatcher);
  yield fork(removeTopicsRequestedWatcher);
  yield fork(updatePrimaryOwnContactDataRequestedWatcher);
  yield fork(updatePrimaryCollaborationRequestedWatcher);
  yield fork(fetchContactRecipientsRequestedWatcher);
  yield fork(addListFromContactPanelRequestedWatcher);
  yield fork(exportContactsRequestedWatcher);
  yield fork(exportContactsVCardsRequestedWatcher);
  yield fork(getContactsSavedSearchesRequestedWatcher);
  yield fork(getNumberOfDesynchronizedContactsInListRequestedWatcher);
  yield fork(getMediumNamesByListIdRequestedWatcher);
  yield fork(removeBlacklistsRequestedWatcher);
  yield fork(getLanguagesRequestedWatcher);
  yield fork(getContactsByListIdsRequestWatcher);
  yield fork(addMediaOutletMediaResortWatcher);
  yield fork(addMediaResortAndAssignToContactRequested);
  yield fork(getContactsListsRequestedWatcher);
  yield fork(getListContactsFilterSuggestionsRequestedWatcher);
  yield fork(createTopicRequestedWatcher);
  yield fork(createListRequestedWatcher);
  yield fork(removeJobTitleRequestedWatcher);
  yield fork(addJobTitleRequestedWatcher);
  yield fork(removeMediumCategoryRequestedWatcher);
  yield fork(addMediumCategoryRequestedWatcher);
  yield fork(createMediaResortsWatcher);

  // Activities
  yield fork(addContactsFromListsRequestedWatcher);
  yield fork(addRecipientsToSendingRequestedWatcher);
  yield fork(addSendingRequestedWatcher);
  yield fork(addSendingsRequestedWatcher);
  yield fork(addSendingTagRequestedWatcher);
  yield fork(addTagToSendingRequestedWatcher);
  yield fork(addTemplateRequestedWatcher);
  yield fork(createNewBlankEmailRequestedWatcher);
  yield fork(createNewEmailWithRecipientsFromListRequestedWatcher);
  yield fork(createNewEmailWithRecipientsRequestedWatcher);
  yield fork(deleteTagsFromSendingsRequestedWatcher);
  yield fork(deleteTemplateRequestedWatcher);
  yield fork(duplicateSendingRequestedWatcher);
  yield fork(duplicateSendingsRequestedWatcher);
  yield fork(fetchActivityEmailAddressRequestedWatcher);
  yield fork(fetchBeeTokenWatcher);
  yield fork(fetchSendingLogsRequestedWatcher);
  yield fork(fetchBlacklistFromPreviewTabWatcher);
  yield fork(fetchListsByNameRequestedWatcher);
  yield fork(fetchRecipientsBySendingIdAndContactNameRequestedWatcher);
  yield fork(fetchSendingsByCreatedByWatcher);
  yield fork(fetchSendingsByTitleWatcher);
  yield fork(fetchSendingsCampaignWatcher);
  yield fork(fetchSendingsEmailSubjectWatcher);
  yield fork(fetchSendingsRecipientEmailOrNameWatcher);
  yield fork(fetchSendingsRecipientMediumNameWatcher);
  yield fork(fetchSendingsRequestedWatcher);
  yield fork(fetchSendingsTagWatcher);
  yield fork(fetchTemplateAsImageWatcher);
  yield fork(getAllSendingTagsRequestedWatcher);
  yield fork(getContactsByListsIdsRequestWatcher);
  yield fork(getContactSuggestionsRequestedWatcher);
  yield fork(getPeoplePickerSuggestionsRequestWatcher);
  yield fork(getSelectedSendingRequestedWatcher);
  yield fork(getSendersRequestedWatcher);
  yield fork(getTemplatesRequestedWatcher);
  yield fork(removeRecipientsFromSendingRequestedWatcher);
  yield fork(removeSendingRequestedWatcher);
  yield fork(removeSendingsRequestedWatcher);
  yield fork(removeTagFromSendingRequestedWatcher);
  yield fork(scheduleSendingRequestedWatcher);
  yield fork(sendSendingRequestedWatcher);
  yield fork(templatesSuggestionsRequestedWatcher);
  yield fork(updateRecipientContactDataRequestedWatcher);
  yield fork(updateSendingFromWizardRequestedWatcher);
  yield fork(updateSendingRequestedWatcher);
  yield fork(updateSendingsRequestedWatcher);
  yield fork(updateTemplateRequestedWatcher);
  yield fork(createEmailFromTemplateRequestedWatcher);
  yield fork(updateTemplateBodyRequestedWatcher);
  yield fork(fetchSendingsForEditorRequestedWatcher);
  yield fork(fetchSendingAudienceWatcher);
  yield fork(addAadContactsToSendingRequestedWatcher);
  yield fork(isOpenAiAddonEnabledRequestedWatcher);
  yield fork(getContactProfileListsByNameRequestedWatcher);
  yield fork(getContactProfileListsNextPageRequestedWatcher);
  yield fork(getSelectedSendingOverviewRequestedWatcher);
  yield fork(addAiContentReportMessageRequestedWatcher);
}
