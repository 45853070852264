import { FilterItem } from 'app/common';

export class PageOfSavedSearches {
  constructor(public totalCount: number, public savedSearches: SavedSearch[]) {}
}

export class SavedSearch {
  constructor(
    public id: string,
    public name: string,
    public searchText: string,
    public filterItems: FilterItem[],
    public priority: number,
    public count: number,
    public createdAt: Date,
    public lastModifiedAt: Date,
    public createdById: string,
    public avatarDetails?: AvatarDetails[],
    public resultsLength?: number
  ) {}
}

export class SavedSearchInput {
  constructor(public name: string, public searchText: string, public filterItems: FilterItem[]) {}
}

export class SavedSearchWithIdInput {
  constructor(
    public id: string,
    public name: string,
    public searchText: string,
    public filterItems: FilterItem[],
    public priority: number,
    public count: number
  ) {}
}

export class AvatarDetails {
  constructor(public name: string, public profilePictureUrl: string) {}
}

export class SavedSearchTableData {
  constructor(public key: number, public name: string, public createdAt: string, public lastModifiedAt: string, public count: number) {}
}

export class SelectedListSavedSearchTableData {
  constructor(
    public key: number,
    public id: string,
    public name: string,
    public createdAt: string,
    public lastModifiedAt: string,
    public count: number,
    public shouldAutoUpdate: JSX.Element
  ) {}
}
