import { FilterItem, useAppDispatch, useAppSelector } from 'app/common';
import { FilterPills } from 'app/pages/my-audience/common';
import {
  firstPageOfSendingsRequested,
  openFilterPanel,
  removeSendingsFilter,
  selectFilterItems,
  selectSelectedSendingsIdsCount
} from 'app/pages/my-activities/sendings';
import { selectIsProfilePanelOpen } from 'app/pages/my-activities/sending-wizard';

export const SendingsFilterPills = () => {
  const dispatch = useAppDispatch();

  const filterItems = useAppSelector<FilterItem[]>(selectFilterItems);
  const selectedSendingsIdsCount = useAppSelector<number>(selectSelectedSendingsIdsCount);
  const isSendingProfilePanelOpened = useAppSelector<boolean>(selectIsProfilePanelOpen);

  const areFiltersVisible = () => {
    if (selectedSendingsIdsCount > 0 && isSendingProfilePanelOpened && filterItems.length > 0) return true;

    if (!isSendingProfilePanelOpened && selectedSendingsIdsCount === 0 && filterItems.length > 0) return true;

    return false;
  };

  const handleOpenFiltersPanel = () => {
    dispatch(openFilterPanel());
  };

  const handleRemoveFilter = (filterItem: FilterItem) => dispatch(removeSendingsFilter(filterItem));

  const handleSearchContacts = () => dispatch(firstPageOfSendingsRequested());

  return (
    areFiltersVisible() && (
      <div className="sending-filter-pills-wrap">
        <FilterPills
          filterItems={filterItems}
          onOpenFiltersPanel={handleOpenFiltersPanel}
          onRemoveFilter={handleRemoveFilter}
          onSearch={handleSearchContacts}
        />
      </div>
    )
  );
};
