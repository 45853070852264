import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton } from '@fluentui/react';
import { showUndoAlert, undoQueue, useAppDispatch } from 'app/common';
import { useIntl } from 'app/i18n';

interface UndoableDialogProps {
  opened: boolean;
  dialogTitle: string;
  dialogText?: string;
  alertMessage: string;
  onExecuteAction: () => void;
  onExecuteInStore: () => void;
  onRevertAction: () => void;
  onClose: () => void;
  warningMessages?: string[];
  deleteButtonDisabled?: boolean;
}

export const UndoableDialog = ({
  opened,
  dialogTitle,
  dialogText,
  alertMessage,
  onExecuteAction,
  onExecuteInStore,
  onRevertAction,
  onClose,
  warningMessages,
  deleteButtonDisabled = false
}: UndoableDialogProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const dialogContentProps = {
    type: DialogType.normal,
    title: dialogTitle,
    subText: dialogText
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };
  const onChangeHandler = () => {
    onExecuteInStore();

    undoQueue.waitAndExecute({
      executeAction: onExecuteAction,
      undoAction: onRevertAction
    });
    dispatch(showUndoAlert(alertMessage));

    onClose();
  };

  return (
    <Dialog hidden={!opened} onDismiss={onClose} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      {warningMessages?.map((message) => (
        <div key={message}>
          <span className="orange-text">
            <Icon iconName="Warning" />
            {`${formatMessage({ id: 'labels.warning' })}: `}
          </span>
          {message}
          <br />
        </div>
      ))}
      <div className="profile-photo-dialog delete-contact-dialog">
        <DialogFooter className="dialog-footer">
          {deleteButtonDisabled ? (
            <PrimaryButton className="dialog-purple-btn" onClick={onClose} text={formatMessage({ id: 'buttons.okay' })} />
          ) : (
            <div>
              <PrimaryButton
                className="dialog-purple-btn"
                disabled={deleteButtonDisabled}
                onClick={onChangeHandler}
                text={formatMessage({ id: 'buttons.yes' })}
              />
              <DefaultButton className="dialog-outline-btn" onClick={onClose} text={formatMessage({ id: 'buttons.no' })} />
            </div>
          )}
        </DialogFooter>
      </div>
    </Dialog>
  );
};
