import { Icon } from '@fluentui/react';
import { Tooltip } from '@fluentui/react-northstar';

export interface NavMenuItemProps {
  name: string;
  icon: string;
  tooltip: string;
  isNavigationCollapsed: boolean;
}

export const NavMenuItem = ({ isNavigationCollapsed, name, icon, tooltip }: NavMenuItemProps) => {
  return isNavigationCollapsed ? (
    <Tooltip
      pointing
      position="before"
      subtle={false}
      content={tooltip}
      trigger={
        <div className="custom-item">
          <div className="custom-item-icon">
            <Icon iconName={icon} />
          </div>
          <span className="custom-item-text">{name}</span>
        </div>
      }
    />
  ) : (
    <div className="custom-item">
      <div className="custom-item-icon">
        <Icon iconName={icon} />
      </div>
      <span className="custom-item-text">{name}</span>
    </div>
  );
};
