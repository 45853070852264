import { maximumTextInputLength, regPhoneNumber, regAtLeastOneCharacter, regEmail, regNumbers, regUrl } from 'app/common';

interface InputTextProps {
  inputValue: string;
  minInputLength?: number;
  maxInputLength?: number;
}

export const isTextInputValid = ({ inputValue, minInputLength = 1, maxInputLength = maximumTextInputLength }: InputTextProps) => {
  const inputValueLength = inputValue?.trim().length;
  return inputValue?.match(regAtLeastOneCharacter) !== null && inputValueLength >= minInputLength && inputValueLength <= maxInputLength;
};

export const getInitials = (name: string | undefined) => name?.replaceAll(' ', '').slice(0, 2);

export const isEmailAddressValidOrEmpty = (email: string) => isEmailAddressValid(email) || isStringEmpty(email);

export const isEmailAddressValid = (email: string) => email?.match(regEmail) !== null;

export const isNumberValidOrEmpty = (value: string) => value?.match(regNumbers) !== null || isStringEmpty(value);

export const isPhoneNumberValidOrEmpty = (value: string) => value?.match(regPhoneNumber) !== null || isStringEmpty(value);

export const isStringEmpty = (fieldValue: string) => {
  return fieldValue === '' || fieldValue === undefined;
};

export const isValidUrlField = (field: string) => {
  return field?.match(regUrl) !== null || isStringEmpty(field);
};

export const fieldContainsNonEmptyValue = (field: string) => field && field !== '/';
