import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regAtLeastOneCharacter,
  regEmail,
  regNumbers,
  useAppDispatch,
  useAppSelector,
  WizardIntro,
  maximumContactLastNameLength,
  isTextInputValid,
  Language,
  selectLanguages,
  regPhoneNumber,
  isPhoneNumberValidOrEmpty,
  regUrl,
  isValidUrlField
} from 'app/common';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import {
  AddGeneralContactInfoForm,
  addGeneralContactInformation,
  ContactGeneralInfo,
  CreateContactFooter,
  CreateContactSteps,
  goToNextStep,
  pageOfMediaOutletsAndCompaniesRequested,
  selectContactInfoToAdd,
  setAreGeneralContactInfoValid,
  setContactGeneralInfo
} from 'app/pages/my-audience/wizard-create-contact';
import { getNames } from 'country-list';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddGeneralContactInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactGeneralInfo = useAppSelector<ContactGeneralInfo>(selectContactInfoToAdd);
  const allLanguages = useAppSelector<Language[]>(selectLanguages);

  const { handleSubmit, control, watch } = useForm<AddGeneralContactInfoForm, any>({
    defaultValues: {
      salutation: contactGeneralInfo.salutation,
      lastName: contactGeneralInfo.lastName,
      firstName: contactGeneralInfo.firstName,
      language: contactGeneralInfo.language?.name,
      emailAddress: contactGeneralInfo.emailAddress,
      phoneNumber: contactGeneralInfo.phoneNumber,
      mobilePhoneNumber: contactGeneralInfo.mobilePhoneNumber,
      address: contactGeneralInfo.address,
      postalCode: contactGeneralInfo.postalCode,
      city: contactGeneralInfo.city,
      country: contactGeneralInfo.country,
      blog: contactGeneralInfo.blog,
      websiteUrl: contactGeneralInfo.websiteUrl,
      twitterUrl: contactGeneralInfo.twitterUrl,
      instagramUrl: contactGeneralInfo.instagramUrl,
      linkedinUrl: contactGeneralInfo.linkedinUrl,
      youtubeUrl: contactGeneralInfo.youtubeUrl,
      facebookUrl: contactGeneralInfo.facebookUrl
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const areGeneralInfoValid =
    isTextInputValid({ inputValue: watch('lastName'), maxInputLength: maximumContactLastNameLength }) &&
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('phoneNumber')) &&
    isPhoneNumberValidOrEmpty(watch('mobilePhoneNumber')) &&
    isValidUrlField(watch('websiteUrl')) &&
    isValidUrlField(watch('twitterUrl')) &&
    isValidUrlField(watch('instagramUrl')) &&
    isValidUrlField(watch('linkedinUrl')) &&
    isValidUrlField(watch('youtubeUrl')) &&
    isValidUrlField(watch('blog')) &&
    isValidUrlField(watch('facebookUrl'));

  useEffect(() => {
    dispatch(setAreGeneralContactInfoValid(areGeneralInfoValid));
  }, [areGeneralInfoValid, dispatch]);

  const handleNextButtonClick = () => {
    submitData();
    dispatch(pageOfMediaOutletsAndCompaniesRequested());
    dispatch(goToNextStep());
  };

  const submitData = () => {
    handleSubmit(
      (data) => {
        const newContactGeneralInfo: ContactGeneralInfo = setContactGeneralInfo(data, allLanguages);
        dispatch(addGeneralContactInformation(newContactGeneralInfo));
      },
      (error) => {
        Sentry.setExtra('Path', AddGeneralContactInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  return (
    <>
      <CreateContactSteps onStepClick={submitData} isStepValid={areGeneralInfoValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.wizard-add-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-contact.wizard-salutation-label' })} control={control} name="salutation" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'add-menu-create-contact.wizard-lastname-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="lastName"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage(
                    { id: 'error-messages.not-valid-name' },
                    { entity: formatMessage({ id: 'add-menu-create-contact.wizard-lastname-label' }) }
                  )
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumContactLastNameLength }),
                  value: maximumContactLastNameLength
                },
                required: formatMessage(
                  { id: 'error-messages.required-filed' },
                  { fieldName: formatMessage({ id: 'add-menu-create-contact.wizard-lastname-label' }) }
                )
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-contact.wizard-name-label' })} control={control} name="firstName" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="emailAddress"
              label={formatMessage({ id: 'add-menu-create-contact.wizard-email-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  {' '}
                  <FormattedMessage id={'add-menu-create-contact.wizard-lang-label'} />
                </span>
              }
              name="language"
              items={allLanguages.map((l) => l.name)}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.wizard-choose-lang-label' })}
              defaultValue={contactGeneralInfo.language?.name}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-phone-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
              name="phoneNumber"
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-mobile-phone-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
              name="mobilePhoneNumber"
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-contact.wizard-address-label' })} control={control} name="address" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-postal-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regNumbers,
                  message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                }
              }}
              name="postalCode"
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-contact.wizard-city-label' })} control={control} name="city" />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  {' '}
                  <FormattedMessage id={'add-menu-create-contact.wizard-country-label'} />
                </span>
              }
              name="country"
              items={getNames()}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.wizard-country-placeholder' })}
              defaultValue={contactGeneralInfo.country}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-blog-label' })}
              control={control}
              name="blog"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-website-label' })}
              control={control}
              name="websiteUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-twitter-label' })}
              control={control}
              name="twitterUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-insta-label' })}
              control={control}
              name="instagramUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-ln-label' })}
              control={control}
              name="linkedinUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-youtube-label' })}
              control={control}
              name="youtubeUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-contact.wizard-facebook-label' })}
              control={control}
              name="facebookUrl"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
        </div>
        <CreateContactFooter handleNextButtonClick={handleNextButtonClick} />
      </div>
    </>
  );
};
