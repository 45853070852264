import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { DialogType } from '@fluentui/react';
import { Contact } from 'app/pages/my-audience/contacts';
import {
  closeRemoveCollaborationDialog,
  removeContributionFromStore,
  removeContributionRequested,
  selectIsRemoveCollaborationDialogOpened,
  selectSelectedContactForEditing,
  undoRemoveContributionFromStore
} from 'app/pages/my-audience/company-profile';
import { hideUndoAlert, showAlertNotification, UndoableDialog } from 'app/common';
import { useIntl } from 'app/i18n';

export const RemoveCollaborationDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isRemoveCollaborationDialogOpened = useAppSelector<boolean>(selectIsRemoveCollaborationDialogOpened);
  const contactToRemove = useAppSelector<Contact>(selectSelectedContactForEditing);

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Delete contact',
    subText: `Are you sure you want to delete contact ${contactToRemove.firstName} ${contactToRemove.lastName} from the list?`
  };

  const handleRemoveCollaboration = () => {
    dispatch(removeContributionFromStore(contactToRemove));
    dispatch(closeRemoveCollaborationDialog());
  };

  const onExecuteAction = () => {
    dispatch(removeContributionRequested(contactToRemove));
    dispatch(hideUndoAlert());
    dispatch(showAlertNotification(formatMessage({ id: 'companies-delete-popup.companies-delete-success-msg' })));
  };

  const onUndo = () => {
    dispatch(undoRemoveContributionFromStore(contactToRemove));
  };

  const handleDismissButtonClick = () => {
    dispatch(closeRemoveCollaborationDialog());
  };

  return (
    <UndoableDialog
      opened={isRemoveCollaborationDialogOpened}
      dialogTitle={formatMessage({ id: 'companies-delete-popup.companies-delete-title' })}
      dialogText={formatMessage(
        { id: 'companies-delete-popup.companies-delete-subtitle' },
        { contactName: `${contactToRemove.firstName} ${contactToRemove.lastName}` }
      )}
      alertMessage={formatMessage(
        { id: 'companies-delete-popup.companies-delete-undo' },
        { contactName: `${contactToRemove.firstName} ${contactToRemove.lastName}` }
      )}
      onExecuteAction={onExecuteAction}
      onExecuteInStore={handleRemoveCollaboration}
      onRevertAction={onUndo}
      onClose={handleDismissButtonClick}
    />
  );
};
