import { Route, Routes } from 'react-router-dom';
import { ConsentPopup, SignIn } from 'app/common';

export const UnauthorizedRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/auth" element={<ConsentPopup />} />
        <Route path="/*" element={<SignIn />} />
      </Routes>
    </>
  );
};
