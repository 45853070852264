import { Selection } from '@fluentui/react';
import { useAppDispatch, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateNewContactWizard,
  CreateCompanyFooter,
  closeCreateCompanyWizard,
  goToPreviousStep,
  goToNextStep,
  CreateCompanySteps,
  CollaborationTableToolbar,
  CollaborationTable,
  CollaborationTablePaging
} from 'app/pages/my-audience/wizard-create-company';

interface CollaborationProps {
  selection: Selection;
}

export const Collaboration = ({ selection }: CollaborationProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };
  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };
  const handleNextButtonClick = () => {
    dispatch(goToNextStep());
  };

  return (
    <>
      <CreateCompanySteps />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-company.collaboration-title' })} description="" />
        <CollaborationTableToolbar />
        <CollaborationTable selection={selection} />
        <CollaborationTablePaging />
        <CreateNewContactWizard />
      </div>
      <CreateCompanyFooter onCancelButtonClick={handleCancelButtonClick} onBackButtonClick={handleBackButtonClick} onNextButtonClick={handleNextButtonClick} />
    </>
  );
};
