import { useReactiveVar } from '@apollo/client';
import { Provider } from '@fluentui/react-northstar';
import { CacheService, Providers, TeamsHelper } from '@microsoft/mgt-element';
import { TeamsMsal2Provider } from '@microsoft/mgt-teams-msal2-provider';
import * as microsoftTeams from '@microsoft/teams-js';
import {
  AlertWarning,
  AppRoutes,
  BackButtonHandler,
  ContentOrSpinner,
  selectIsOnMobile,
  setContext,
  setIsInTeams,
  setIsOnDesktop,
  setIsOnMobile,
  setIsOnTablet,
  setIsOnTouch,
  signOut,
  undoQueue,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import 'app/common/components/app/App.scss';
import { isInTeamsApollo, unauthorizedApollo } from 'app/common/graphql/graphql-gateway.client';
import { setLocale, DEFAULT_LOCALE, selectLocale } from 'app/localization';
import { teamsMsal2ProviderConfiguration } from 'auth';
import { useTeams } from 'msteams-react-base-component';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { flattenMessages, translationsLocale } from 'app/i18n';
import { useLocation } from 'react-router-dom';

const setDeviceSize = (dispatch: ReturnType<typeof useAppDispatch>, width: number): void => {
  if (width < 1024) dispatch(setIsOnTouch(true));
  if (width < 767.98) dispatch(setIsOnMobile(true));
  if (width < 1024 && width > 767.98) dispatch(setIsOnTablet(true));
  if (width > 767.98) dispatch(setIsOnDesktop(true));
};

export const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locale = useAppSelector<string>(selectLocale);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);
  const minimumSupportedWidth = 768;

  const calculateDeviceWidth = () => {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  };

  // useTeams calls microsoftTeams.app.initialize() for us
  // IMPORTANT: do not use it in other components, it is just for base component!
  const [{ theme, themeString, context, inTeams }] = useTeams();
  const [isTeamsCheckDone, setIsTeamsCheckDone] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(calculateDeviceWidth());
  const currentPath = location.pathname;
  const showSizeNotSupportedScreen = deviceWidth < minimumSupportedWidth && !currentPath.endsWith('/auth');

  const isApolloUnauthorized = useReactiveVar(unauthorizedApollo);
  useEffect(() => {
    if (isApolloUnauthorized) dispatch(signOut());
  }, [isApolloUnauthorized, dispatch]);

  useEffect(() => {
    const width = calculateDeviceWidth();

    unauthorizedApollo(false);
    setDeviceSize(dispatch, width);

    const resizeHandler = (e) => {
      if (isOnMobile) {
        return;
      }

      const width = calculateDeviceWidth();
      setDeviceSize(dispatch, width);
      setDeviceWidth(width);

      console.log(e);
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (inTeams === undefined) return;

    setIsTeamsCheckDone(true);
    isInTeamsApollo(inTeams);
    dispatch(setIsInTeams(inTeams));
    dispatch(setContext(context));
    dispatch(setLocale(context?.app?.locale));
  }, [inTeams, context, dispatch]);

  useEffect(() => {
    document.documentElement.classList.remove(themeString === 'dark' ? 'theme--default' : 'theme--dark');
    document.documentElement.classList.add(themeString === 'dark' ? 'theme--dark' : 'theme--default');
  }, [themeString]);

  useEffect(() => {
    if (!inTeams) return;

    // TODO: this part with TeamsMsal2Provider should be moved to authorized part of application.
    // Currently we are hacking TeamsMsal2Provider and using provided token to authorize user in
    // our platform but it should use it ONLY to let MGT components access Microsoft Graph Api
    TeamsMsal2Provider.microsoftTeamsLib = microsoftTeams;
    TeamsHelper.microsoftTeamsLib = microsoftTeams;
    Providers.globalProvider = new TeamsMsal2Provider(teamsMsal2ProviderConfiguration);
    CacheService.config.isEnabled = true;
  }, [inTeams]);

  useEffect(() => {
    if (undoQueue.hasPendingActions()) {
      undoQueue.executeAllPendingActions();
    }
  }, [location.pathname]);

  return (
    <ContentOrSpinner ready={isTeamsCheckDone}>
      <Provider theme={theme}>
        <IntlProvider locale={locale ?? DEFAULT_LOCALE} defaultLocale={DEFAULT_LOCALE} messages={flattenMessages(translationsLocale(locale ?? DEFAULT_LOCALE))}>
          <AppRoutes />
          <AlertWarning />
          <BackButtonHandler inTeams={inTeams} />
        </IntlProvider>
      </Provider>
    </ContentOrSpinner>
  );
};
