import { DetailsRow, IconButton, ShimmeredDetailsList } from '@fluentui/react';
import { getAllJobTitlesRequested, selectIsOnMobile, useAppDispatch, useAppSelector, WizardEmptyInfo } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { IColumn } from '@fluentui/react';
import { useIntl } from 'app/i18n';
import {
  EditCollaborationWizard,
  openEditCollaborationWizard,
  openRemoveCollaborationDialog,
  pageOfCompanyContactsRequested,
  RemoveCollaborationDialog,
  selectCompanyContacts,
  selectCompanyContactsTotalCount,
  selectIsCollaborationsListOutdated,
  selectIsEditCollaborationWizardOpened,
  selectIsLoadingCompanyContacts,
  selectSelectedCompanyId,
  selectSelectedContactForEditing,
  setContactToRemove,
  setSelectedContactForEditing
} from 'app/pages/my-audience/company-profile';
import { Contact } from 'app/pages/my-audience/contacts';
import { useEffect } from 'react';

interface IContactWithActionsRow {
  key: string;
  profilePictureUrl: JSX.Element;
  name: string;
  role: string;
  emailAddress: string;
  phoneNumber: string;
  menu: JSX.Element;
}

interface CollaborationsTableProps {
  actionItemsDisabled?: boolean;
}

export const CollaborationsTable = ({ actionItemsDisabled = false }: CollaborationsTableProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contacts = useAppSelector<Contact[]>(selectCompanyContacts);
  const selectedCompanyId = useAppSelector<string>(selectSelectedCompanyId);
  const isEditCollaborationWizardOpened = useAppSelector<boolean>(selectIsEditCollaborationWizardOpened);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingCompanyContacts);
  const totalCountOfContacts = useAppSelector<number>(selectCompanyContactsTotalCount);
  const selectedContactForEditing = useAppSelector<Contact>(selectSelectedContactForEditing);
  const isCollaborationsListOutdated = useAppSelector<boolean>(selectIsCollaborationsListOutdated);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);

  useEffect(() => {
    if (isCollaborationsListOutdated) dispatch(pageOfCompanyContactsRequested());
  }, [dispatch, isCollaborationsListOutdated]);

  const columns: IColumn[] = [
    {
      key: 'contact-without-company-email-profile-picture-url',
      fieldName: 'profilePictureUrl',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isRowHeader: false
    },
    {
      key: 'contact-without-company-email-name',
      fieldName: 'name',
      name: formatMessage({ id: 'companies-contact-table.name' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-company-email-role',
      fieldName: 'role',
      name: formatMessage({ id: 'companies-contact-table.job' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-company-email-address',
      fieldName: 'emailAddress',
      name: formatMessage({ id: 'companies-contact-table.email' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-company-phone-number',
      fieldName: 'phoneNumber',
      name: formatMessage({ id: 'companies-contact-table.phone' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'menu',
      fieldName: 'menu',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isRowHeader: false
    }
  ];

  const actionItems = {
    shouldFocusOnMount: true,
    className: 'panel-table-popup-list',
    items: [
      {
        key: 'edit-collaboration',
        text: formatMessage({ id: 'companies-contact-table.edit' }),
        iconProps: {
          iconName: 'Edit',
          className: ''
        },
        disabled: actionItemsDisabled,
        onClick: () => handleEditCollaborationMenuItem()
      },
      {
        key: 'remove-collaboration',
        text: formatMessage({ id: 'companies-contact-table.remove' }),
        iconProps: {
          iconName: 'Delete',
          className: 'danger'
        },
        disabled: actionItemsDisabled,
        onClick: () => handleRemoveCollaborationMenuItem(selectedContactForEditing)
      }
    ]
  };

  const menuIconProps = {
    iconName: 'MoreVertical',
    className: 'panel-table-popup-trigger'
  };

  const contactHasCollaborationWithEmptyId = (contact: Contact) => contact.collaborations.some((c) => !c.id);

  const contactRows: IContactWithActionsRow[] = contacts.map((contact) => {
    const companyCollaboration = contact.collaborations.find((cd) => cd.medium.id === selectedCompanyId);
    return {
      key: contact.id,
      profilePictureUrl: <ProfilePicture name={`${contact.firstName} ${contact.lastName}`} imageUrl={contact.profilePictureUrl} size="small" />,
      name: `${contact.firstName} ${contact.lastName}`,
      role: companyCollaboration?.jobTitle?.name,
      emailAddress: companyCollaboration?.emailAddress,
      phoneNumber: companyCollaboration?.landlinePhoneNumber?.isPrimary
        ? companyCollaboration?.landlinePhoneNumber?.value
        : companyCollaboration?.mobilePhoneNumber?.value,
      menu: isOnMobile ? <></> : <IconButton menuIconProps={menuIconProps} menuProps={actionItems} disabled={contactHasCollaborationWithEmptyId(contact)} />
    };
  });

  const handleRemoveCollaborationMenuItem = (contact: Contact) => {
    dispatch(openRemoveCollaborationDialog());
    dispatch(setContactToRemove(contact));
  };

  const handleEditCollaborationMenuItem = () => {
    dispatch(getAllJobTitlesRequested());
    dispatch(openEditCollaborationWizard());
  };

  const handleRowClick = (index: number) => {
    dispatch(setSelectedContactForEditing(contacts[index]));
  };

  return (
    <>
      <div className="wizard-panel-table">
        <ShimmeredDetailsList
          className="panel-table"
          items={contactRows}
          enableShimmer={isLoadingContacts}
          columns={columns}
          checkboxVisibility={2}
          onRenderRow={(props) => (
            <div onClickCapture={() => handleRowClick(props.itemIndex)}>
              <DetailsRow {...props} />
            </div>
          )}
        />
      </div>
      <RemoveCollaborationDialog />
      {totalCountOfContacts === 0 && !isLoadingContacts && (
        <WizardEmptyInfo
          message={formatMessage({ id: 'no-contacts-selected-message.title' })}
          description={formatMessage({ id: 'no-contacts-selected-message.description' })}
        />
      )}
      {isEditCollaborationWizardOpened && <EditCollaborationWizard />}
    </>
  );
};
