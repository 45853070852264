import { Icon } from '@fluentui/react';
import { useDebouncedCallback } from 'use-debounce';
import { Button, FilterIcon, Input, MenuButton, MenuItem, SearchIcon, Tooltip } from '@fluentui/react-northstar';
import { MediumCategory, debounceTimeInMilliseconds, useAppDispatch, useAppSelector } from 'app/common';
import { selectAllCompanyCategories } from 'app/pages/my-audience/company-profile';
import {
  openCreateCompanyWizard,
  pageOfCompaniesRequested,
  selectCompaniesSearchText,
  setCompaniesPageNumber,
  setSearchCompaniesTextAndFitlerItem
} from 'app/pages/my-audience/contact-profile';
import { useIntl } from 'app/i18n';
import { useState } from 'react';

export const CreateCompanyToolbar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const searchText = useAppSelector<string>(selectCompaniesSearchText);
  const filterItems = useAppSelector<MediumCategory[]>(selectAllCompanyCategories);

  const [menuItems, setMenuItems] = useState(() =>
    filterItems.map((filterItem) => {
      return {
        id: filterItem.id,
        content: filterItem.name,
        isActive: false
      };
    })
  );

  const handleFilters = (filterItemValue: string) => {
    dispatch(setCompaniesPageNumber(1));
    dispatch(setSearchCompaniesTextAndFitlerItem({ searchText: searchText, category: filterItemValue }));
    dispatch(pageOfCompaniesRequested());
  };

  const handleOpenWizard = () => {
    dispatch(openCreateCompanyWizard());
  };

  const handleInputChange = useDebouncedCallback((searchText: string) => {
    dispatch(setCompaniesPageNumber(1));
    dispatch(setSearchCompaniesTextAndFitlerItem({ searchText: searchText, category: '' }));
    dispatch(pageOfCompaniesRequested());
  }, debounceTimeInMilliseconds);

  const onClearFilters = () => {
    handleFilters('');
    setMenuItems((items) => items.map((item) => ({ ...item, isActive: false })));
  };

  const onSelectedFilter = (selectedFilterId: string, filterItemValue: string) => {
    setMenuItems((items) => items.map((item) => ({ ...item, isActive: item.id === selectedFilterId })));
    handleFilters(filterItemValue);
  };

  return (
    <div className="wizard-panel-toolbar">
      <div className="wizard-panel-toolbar-create">
        <Button
          className="wizard-panel-button-create"
          icon={<Icon iconName="Add" />}
          content={formatMessage({ id: 'wizard-search-medium-step.create-new-button' })}
          onClick={handleOpenWizard}
        />
      </div>
      <div className="wizard-panel-toolbar-filters">
        <div className="wizard-panel-toolbar-input">
          <Input
            clearable
            icon={<SearchIcon />}
            placeholder={formatMessage({ id: 'wizard-search-medium-step.search-input-placeholder' })}
            autoComplete="off"
            onChange={(_, data) => handleInputChange(data.value)}
          />
        </div>
        <div className="toolbar-filter">
          {menuItems?.length ? (
            <MenuButton
              menu={
                <>
                  {menuItems.map(({ isActive, content, id }) => (
                    <MenuItem
                      className={`menu-item ${isActive ? 'menu-item__active' : ''}`}
                      key={id}
                      content={content}
                      onClick={() => onSelectedFilter(id, content)}
                    />
                  ))}
                  <MenuItem onClick={onClearFilters} content={formatMessage({ id: 'buttons.clear-all' })} icon={<Icon iconName="Delete" />} />
                </>
              }
              trigger={<Button text content={formatMessage({ id: 'buttons.filter' })} icon={<FilterIcon />} />}
            />
          ) : (
            <Tooltip
              subtle={false}
              pointing
              position="above"
              content={formatMessage({ id: 'companies-contact-top.tooltip-msg' })}
              trigger={
                <MenuButton
                  trigger={
                    <Button text disabled className="table-toolbar-action-button" content={formatMessage({ id: 'filters.filter' })} icon={<FilterIcon />} />
                  }
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
