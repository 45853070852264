import { Button } from '@fluentui/react-northstar';
import circlesDecoration from 'assets/images/augure-decoration-circles.png';
import { useIntl } from 'app/i18n';
import augureLogo from 'assets/images/augure-logo-light-version.png';
import licenceNotFoundImage from 'assets/images/licence-not-found.png';
import 'app/common/components/sign-in/licence-not-found.scss';

export const MissingConsent = () => {
  const { formatMessage } = useIntl();
  const contactUsEmailAddress = process.env.REACT_APP_CONTACT_US_EMAIL_ADDRESS;

  return (
    <div className="login-wrap">
      <div className="login-actions-col">
        <div className="login-content">
          <div className="login-logo login-logo__no-user">
            <img src={augureLogo} alt={formatMessage({ id: 'admin-consent.description' })} />
          </div>
        </div>
        <img src={circlesDecoration} alt={'Admin consent is missing'} className="login-image-decoration" />
      </div>
      <div className="login-avatar-col">
        <div className="login-not-found__user">
          <div className="login-not-found__text">
            <img src={licenceNotFoundImage} alt={formatMessage({ id: 'admin-consent.description' })} />
            <h3>{formatMessage({ id: 'admin-consent.title' })}</h3>
            <p>{formatMessage({ id: 'admin-consent.description' })}</p>
            <a href={`mailto:${contactUsEmailAddress}`} target="_blank" rel="noreferrer">
              <Button className="login-not-found__button" content={formatMessage({ id: 'licence-not-found-page.button' })} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
