import { pages } from '@microsoft/teams-js';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/store';
import {
  FilterItem,
  SearchResultTableData,
  Tag,
  Taggable,
  undoQueue,
  selectIsInTeams,
  selectSearchResultsColumnNumber,
  EntitiesPerPage,
  getSendingStatusLocalizationFromStatusValue,
  showAlertNotification,
  MAXIMUM_NUMBER_OF_CONTACTS,
  printDateLocalized
} from 'app/common';
import { selectFrequencies, selectLocale, selectMediumSupportTypes } from 'app/localization';
import { MEDIA_OUTLET_FILTER } from 'app/pages/my-audience/media-outlets-filter-search/filter-fields';
import { buildLocalizedSearchResultsColumns as mediaOutletHeaderBuilder } from 'app/pages/my-audience/media-outlet-search-results';
import { buildLocalizedSearchResultsColumns as companiesHeaderBuilder } from 'app/pages/my-audience/company-search-results';
import { setSearchResultTableHeader as setMediaOutletsTableHeader } from 'app/pages/my-audience/media-outlets';
import { setSearchResultTableHeader as setCompaniesTableHeader } from 'app/pages/my-audience/companies';
import { useIntl } from 'app/i18n';
import { audienceHeader } from 'app/pages/my-activities/sending-profile';
import { IObjectWithKey, Selection } from '@fluentui/react';
import { navigateToSendingWizardReceived, selectSelectedSendingId, selectShouldNavigateToSendingWizard } from 'app/pages/my-activities/sending-wizard';
import { SENDING_FILTER } from 'app/pages/my-activities/sending-filter-search';
import { DateTime } from 'luxon';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

export const useSelectedTags = (selectedRows: SearchResultTableData, allTaggables: Taggable[]) => {
  return useMemo(() => {
    const taggables = selectedRows.map((row) => allTaggables.find((taggable) => taggable.id === row.id)).filter((t) => !!t);
    const taggablesTags = taggables.reduce((agg, curr) => [...agg, ...curr.tags], []);
    const tags = taggablesTags.reduce((agg: Tag[], curr: Tag) => (agg.some((tag) => tag.id === curr.id) ? agg : [...agg, curr]), []);
    return tags.filter((tag: Tag) => taggables.every((taggable) => taggable.tags.some((t) => t.id === tag.id)));
  }, [selectedRows, allTaggables]);
};

export const useActiveMenuItemClass = (layoutPath: string) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (menuItem: string) => (currentPath.startsWith(`${layoutPath}/${menuItem}`) ? 'active' : '');
};

export const useOnClickOutside = (ref: RefObject<HTMLInputElement>, handler: (e: MouseEvent) => void) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      const targetClassList = target?.classList;

      const isClickInsideTheElement = ref.current.contains(target);
      const isUndoButtonClicked = targetClassList?.contains('undo-alert');
      const isDismissAlertClicked = targetClassList?.contains('ui-alert__dismissaction');

      if (isDismissAlertClicked) {
        undoQueue.executeAllPendingActions();
      }

      if (!ref.current || isClickInsideTheElement || isUndoButtonClicked || isDismissAlertClicked) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler, undoQueue]);
};

export type FilterInputControl = {
  searchQuery: string;
  resetQuery: () => void;
  onQueryChange: (value: string) => void;
  onSuggestionsRequested: () => PayloadAction<string>;
};

export const useControlledFilterDropDown = (
  handleSuggestionsRequested?: (value: string) => PayloadAction<string>,
  searchStarted?: () => Action
): FilterInputControl => {
  const dispatch = useAppDispatch();
  const [searchQuery, setQuery] = useState('');

  const onSuggestionsRequested = () => handleSuggestionsRequested && dispatch(handleSuggestionsRequested(searchQuery));

  const onQueryChange = (value: string) => {
    setQuery(value);
    searchStarted && dispatch(searchStarted());
  };

  const resetQuery = () => setQuery('');

  return { searchQuery, resetQuery, onQueryChange, onSuggestionsRequested };
};

export const useLongPress = (longPressTimeout: number) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const [isLongPress, setIsLongPress] = useState<boolean>(false);

  const startPressTimer = () => {
    setIsLongPress(false);
    timerRef.current = setTimeout(() => {
      setIsLongPress(true);
    }, longPressTimeout);
  };

  return {
    isLongPress,
    longPressHandlers: {
      onMouseDown: () => startPressTimer(),
      onMouseUp: () => clearTimeout(timerRef.current),
      onTouchStart: () => startPressTimer(),
      onTouchEnd: () => clearTimeout(timerRef.current)
    }
  };
};

export const useReferenceListLocalization = () => {
  const locale = useAppSelector(selectLocale);
  const { formatMessage } = useIntl();
  const frequencies = useAppSelector(selectFrequencies);
  const mediaTypes = useAppSelector(selectMediumSupportTypes);

  const getLocalizedFrequency = (frequencyValue: string): string => {
    const targetFrequency = frequencies.find((frequency) => frequency.labels.some((label) => label.value === frequencyValue));
    const localizedLabel = targetFrequency.labels.find((label) => label.language.name === locale);

    return localizedLabel.value;
  };
  const getLocalizedMediaType = (mediaTypeValue: string): string => {
    const targetMediaType = mediaTypes.find((mediaType) => mediaType.labels.some((label) => label.value === mediaTypeValue));
    const localizedLabel = targetMediaType.labels.find((label) => label.language.name === locale);

    return localizedLabel.value;
  };

  const localizeFilterItem = ({ fieldName, value }: FilterItem): string => {
    switch (fieldName) {
      case MEDIA_OUTLET_FILTER.FREQUENCY:
        return getLocalizedFrequency(value);
      case MEDIA_OUTLET_FILTER.TYPE:
        return getLocalizedMediaType(value);
      case SENDING_FILTER.STATUS:
        return formatMessage({ id: getSendingStatusLocalizationFromStatusValue(value) });
      case SENDING_FILTER.CREATED_AT:
        const values = value.split(' - ');
        if (values.length > 1) {
          const printValue = `${printDateLocalized(new Date(values[0]), DateTime.DATE_MED, locale)} - ${printDateLocalized(
            new Date(values[1]),
            DateTime.DATE_MED,
            locale
          )}`;

          return printValue;
        }

        return printDateLocalized(new Date(value), DateTime.DATE_MED, locale);
      default:
        return value;
    }
  };

  return { localizeFilterItem };
};

export const useMediaOutletHeaderLocalization = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const language = useAppSelector(selectLocale);
  const columnNumber = useAppSelector<number>(selectSearchResultsColumnNumber);
  const mediaOutletLocalization = useMemo(
    () => ({
      nameLabel: 'media-outlet-labels.name',
      categoryLabel: 'media-outlet-labels.category',
      frequencyLabel: 'media-outlet-labels.frequency',
      mediaTypeLabel: 'media-outlet-labels.media-support-types',
      columnsLabel: 'media-outlet-labels.media-resorts',
      tagsLabel: 'labels.tags',
      contactsLabel: 'labels.contacts',
      languagesLabel: 'media-outlet-labels.preferred-languages',
      addressLabel: 'medium-address-labels.address',
      postalCodeLabel: 'medium-address-labels.postal-code',
      cityLabel: 'medium-address-labels.city',
      faxLabel: 'medium-address-labels.fax',
      websiteLabel: 'medium-address-labels.website',
      poBoxLabel: 'medium-address-labels.p-o-box',
      sortAscending: 'search-results-table.sort-ascending',
      sortDescendingAriaLabel: 'search-results-table.sort-descending'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  useEffect(() => {
    Object.keys(mediaOutletLocalization)?.forEach((key) => (mediaOutletLocalization[key] = formatMessage({ id: mediaOutletLocalization[key] })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaOutletLocalization]);

  useEffect(() => {
    const tableHeader = mediaOutletHeaderBuilder(mediaOutletLocalization).map((header, i) => ({ ...header, isColumnVisible: i < columnNumber }));
    dispatch(setMediaOutletsTableHeader(tableHeader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnNumber, mediaOutletLocalization]);

  return { formatMessage };
};

export const useCompaniesHeaderLocalization = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const language = useAppSelector(selectLocale);
  const columnNumber = useAppSelector<number>(selectSearchResultsColumnNumber);
  const companiesLocalization = useMemo(
    () => ({
      nameLabel: 'company-labels.name',
      countryLabel: 'medium-address-labels.country',
      phoneLabel: 'medium-address-labels.phone-number',
      tagsLabel: 'labels.tags',
      registrationNumberLabel: 'companies-additional-labels.reg-number',
      addressLabel: 'companies-contact-data-labels.address',
      postalCodeLabel: 'medium-address-labels.postal-code',
      cityLabel: 'medium-address-labels.city',
      faxLabel: 'medium-address-labels.fax',
      websiteLabel: 'medium-address-labels.website',
      poBoxLabel: 'medium-address-labels.p-o-box',
      sortAscendingLabel: 'search-results-table.sort-ascending',
      sortDescendingAriaLabel: 'search-results-table.sort-descending'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  useEffect(() => {
    Object.keys(companiesLocalization)?.forEach((key) => (companiesLocalization[key] = formatMessage({ id: companiesLocalization[key] })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesLocalization]);

  useEffect(() => {
    const tableHeader = companiesHeaderBuilder(companiesLocalization).map((header, i) => ({ ...header, isColumnVisible: i < columnNumber }));
    dispatch(setCompaniesTableHeader(tableHeader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnNumber, companiesLocalization]);

  return { formatMessage };
};

export const useAudienceHeader = () => {
  const { formatMessage } = useIntl();

  const header = audienceHeader({
    name: formatMessage({ id: 'contacts-table.name' }),
    email: formatMessage({ id: 'contacts-table.email' }),
    contactData: formatMessage({ id: 'contacts-table.contact-data' })
  });

  return { header };
};

type InfiniteScrollProps = {
  observerTarget: React.MutableRefObject<HTMLDivElement>;
  onLoadMoreCallback: () => void;
  entitiesIds: string[];
};

export const useInfiniteScroll = ({ observerTarget, onLoadMoreCallback, entitiesIds }: InfiniteScrollProps) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          onLoadMoreCallback();
          observer.unobserve(entry.target);
        }
      },
      { rootMargin: '100px' }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, JSON.stringify(entitiesIds)]);
};

export const useDeepLinkNavigation = () => {
  const isInTeams = useAppSelector<boolean>(selectIsInTeams);
  const navigate = useNavigate();
  const selectedSendingId = useAppSelector(selectSelectedSendingId);
  const shouldNavigateToSendingWizard = useAppSelector(selectShouldNavigateToSendingWizard);
  const navigationUrl = '/my-activities/e-mailings';

  useEffect(() => {
    if (!shouldNavigateToSendingWizard) return;

    if (isInTeams) {
      if (pages.currentApp.isSupported()) {
        pages.currentApp.navigateTo({ pageId: process.env.REACT_APP_MY_ACTIVITIES_PAGE_ID, subPageId: navigationUrl });
      } else {
        // Remove after teams fully switches to New Teams
        pages.navigateToApp({
          appId: process.env.REACT_APP_TEAMS_APP_ID,
          pageId: process.env.REACT_APP_MY_ACTIVITIES_PAGE_ID,
          subPageId: navigationUrl
        });
      }
    } else {
      navigate(navigationUrl);
    }
  }, [shouldNavigateToSendingWizard, selectedSendingId, isInTeams]);
};

export const useSendingWizardRedirection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shouldNavigateToSendingWizard = useAppSelector(selectShouldNavigateToSendingWizard);

  useEffect(() => {
    if (!shouldNavigateToSendingWizard) return;

    dispatch(navigateToSendingWizardReceived());
    navigate('/my-activities/e-mailings/emailing-wizard');
  }, [shouldNavigateToSendingWizard]);
};

type PersistentSelectionType<T> = {
  tableRows: object[];
  entitiesPerPage: EntitiesPerPage<T>;
  selection: Selection<IObjectWithKey>;
  currentPageNumber: number;
  getEntityId: <T>(entity: T) => string;
};

export const usePersistentSelection = <T>({ tableRows, entitiesPerPage, selection, currentPageNumber, getEntityId }: PersistentSelectionType<T>) => {
  useEffect(() => {
    selection.setChangeEvents(false);
    const entitiesSelectedOnPage = entitiesPerPage[currentPageNumber] || [];

    if (entitiesSelectedOnPage.length < tableRows.length) {
      selection.setAllSelected(false);
    }
    if (!entitiesSelectedOnPage.length) {
      selection.setChangeEvents(true);
      return;
    }

    entitiesSelectedOnPage.forEach((entity) => selection.setKeySelected(getEntityId(entity), true, false));
    selection.setChangeEvents(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows]);
};

export const useComponentLocationContext = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isInSendingsWizard, setIsInSendingsWizard] = useState(false);
  const [isInListProfile, setIsInListProfile] = useState(false);

  useEffect(() => {
    setIsInSendingsWizard(currentPath.includes('/my-activities/e-mailings/emailing-wizard'));
    setIsInListProfile(currentPath.includes('/my-audience/lists/results'));
  }, [currentPath]);

  const isOutsideContactsPage = isInListProfile || isInSendingsWizard;

  const getSelectedMenuItemFromPathName = () => {
    const pathNames = currentPath.split('/');
    return pathNames[pathNames.length - 1];
  };

  return { isOutsideContactsPage, isInListProfile, isInSendingsWizard, getSelectedMenuItemFromPathName };
};

export const useResetFilterQueriesOnPanelClose = (filterInputControls: FilterInputControl[], isFilterPanelOpen: boolean) => {
  const resetQueries = () => {
    filterInputControls.forEach((filterInputControl) => filterInputControl.resetQuery());
  };

  useEffect(() => {
    if (isFilterPanelOpen) return;
    resetQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterPanelOpen]);

  return {
    resetQueries
  };
};

type UseContactLimitationWarningProps = {
  totalNumberOfContacts: number;
  successCallback: () => void;
};

export const useContactLimitationWarning = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const checkForContactNumberLimit = ({ totalNumberOfContacts, successCallback }: UseContactLimitationWarningProps) => {
    const isWithinPermittedBoundary = totalNumberOfContacts <= MAXIMUM_NUMBER_OF_CONTACTS;
    if (isWithinPermittedBoundary) {
      successCallback();
      return;
    }
    dispatch(
      showAlertNotification(formatMessage({ id: 'alert-messages.maximum-contact-limit-exceeded' }, { maximumNumberOfContacts: MAXIMUM_NUMBER_OF_CONTACTS }))
    );
  };

  return { checkForContactNumberLimit };
};
