import { Icon } from '@fluentui/react';
import { Button, MenuButton, SendIcon } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  resetSelectedSending,
  selectIsSavingInProgress,
  selectSendingWizardHasNoData,
  selectSendingWizardStep,
  SendingWizardStep
} from 'app/pages/my-activities/sending-wizard';
import { useNavigate } from 'react-router-dom';

interface SendingWizardFooterProps {
  onSaveAndCloseButtonClick: () => void;
  onBackButtonClick?: () => void;
  onNextButtonClick?: () => void;
  onSendImmediately?: () => void;
  onScheduleSend?: () => void;
  onSendClick?: () => void;
  isNextButtonDisabled?: boolean;
  isBackButtonDisabled?: boolean;
  isSaveAndCloseButtonDisabled?: boolean;
}

export const SendingWizardFooter = ({
  onBackButtonClick,
  onNextButtonClick,
  onSendImmediately,
  onScheduleSend,
  onSendClick,
  onSaveAndCloseButtonClick,
  isNextButtonDisabled = false,
  isBackButtonDisabled = false,
  isSaveAndCloseButtonDisabled = false
}: SendingWizardFooterProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector<SendingWizardStep>(selectSendingWizardStep);
  const wizardHasNoData = useAppSelector<boolean>(selectSendingWizardHasNoData);
  const isSavingInProgress = useAppSelector<boolean>(selectIsSavingInProgress);

  const onCancelButtonClick = () => {
    dispatch(resetSelectedSending());
    navigate(`/my-activities/e-mailings`);
  };

  const handleSaveAndCloseButtonClick = () => {
    navigate(`/my-activities/e-mailings`);
    onSaveAndCloseButtonClick();
  };

  return (
    <div className="wizard-panel-bottom">
      <div className="wizard-panel-bottom-button-cancel">
        {wizardHasNoData ? (
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCancelButtonClick} />
        ) : (
          <Button
            content={formatMessage({ id: 'buttons.save-and-close' })}
            onClick={handleSaveAndCloseButtonClick}
            disabled={isSaveAndCloseButtonDisabled}
            loading={isSaveAndCloseButtonDisabled && isSavingInProgress}
          />
        )}
      </div>
      <div className="wizard-panel-bottom-actions-wrap">
        <div className="wizard-panel-bottom-actions">
          {currentStep !== SendingWizardStep.Setup && (
            <Button
              icon={<Icon iconName="ChevronLeft" />}
              content={formatMessage({ id: 'buttons.back' })}
              onClick={onBackButtonClick}
              disabled={isBackButtonDisabled}
              loading={isBackButtonDisabled && isSavingInProgress}
            />
          )}
          {currentStep !== SendingWizardStep.PreviewAndSend && (
            <Button
              content={formatMessage({ id: 'buttons.next' })}
              primary
              onClick={onNextButtonClick}
              disabled={isNextButtonDisabled}
              loading={isNextButtonDisabled && isSavingInProgress}
            />
          )}
          {currentStep === SendingWizardStep.PreviewAndSend && (
            <MenuButton
              trigger={
                <Button
                  icon={<SendIcon />}
                  content={formatMessage({ id: 'buttons.send' })}
                  aria-label={formatMessage({ id: 'aria-labels.click-button' })}
                  onClick={onSendClick}
                />
              }
              menu={[
                {
                  key: 'send-immediately',
                  content: formatMessage({ id: 'emailing-wizard-preview-and-send-step.send-menu-items.send-immediately' }),
                  onClick: () => onSendImmediately()
                },
                {
                  key: 'schedule-send',
                  content: formatMessage({ id: 'emailing-wizard-preview-and-send-step.send-menu-items.schedule' }),
                  onClick: () => onScheduleSend()
                }
              ]}
              on="click"
            />
          )}
        </div>
      </div>
    </div>
  );
};
