import { SendingStatus } from 'app/pages/my-activities/sendings';
import { IntlMessage, NestedKeys } from 'app/i18n';

export const getSendingStatusLocalizationKey = (status: SendingStatus): NestedKeys<IntlMessage> => {
  switch (status) {
    case SendingStatus.Draft:
      return 'emailings-table.filters.draft';
    case SendingStatus.IncompleteDistribution:
      return 'emailings-table.filters.incomplete-distribution';
    case SendingStatus.OngoingDistribution:
      return 'emailings-table.filters.ongoing-distribution';
    case SendingStatus.SuccessfulDistribution:
      return 'emailings-table.filters.successful-distribution';
    case SendingStatus.WaitingForDistribution:
      return 'emailings-table.filters.waiting-for-distribution';
  }
};
