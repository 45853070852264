import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import { AddContactWizardSteps, goToStepAddContact, selectAddContactCurrentStep, selectSelectedContact } from 'app/pages/my-audience/company-profile';
import { Contact } from 'app/pages/my-audience/contacts';

interface AddContactStepsProps {
  areFieldsValid?: boolean;
}

export const AddContactSteps = ({ areFieldsValid }: AddContactStepsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactToAdd = useAppSelector<Contact>(selectSelectedContact);
  const step = useAppSelector<AddContactWizardSteps>(selectAddContactCurrentStep);
  const isContactSelected = Object.keys(contactToAdd).length > 0;

  const styleBreadcrumbStatus = (stepToCompareWith: AddContactWizardSteps): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: AddContactWizardSteps) => {
    dispatch(goToStepAddContact(step));
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddContactWizardSteps.SearchContacts)}
            content={formatMessage({ id: 'companies-add-contact.wizard-step-search' })}
            onClick={() => handleStepButtonClick(AddContactWizardSteps.SearchContacts)}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddContactWizardSteps.ProfileInformation)}
            content={formatMessage({ id: 'companies-add-contact.wizard-step-profile' })}
            onClick={() => handleStepButtonClick(AddContactWizardSteps.ProfileInformation)}
            disabled={!isContactSelected}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(AddContactWizardSteps.Finish)}
            content={formatMessage({ id: 'companies-add-contact.wizard-step-finish' })}
            onClick={() => handleStepButtonClick(AddContactWizardSteps.Finish)}
            disabled={!areFieldsValid || !isContactSelected}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
