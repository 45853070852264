import { selectSelectedCompany, updateCompanyRequested } from 'app/pages/my-audience/company-profile';
import {
  fieldContainsNonEmptyValue,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  isValidUrlField,
  EditableDropdownField,
  EditableInputTextField,
  isPhoneNumberValidOrEmpty,
  ensureHttpProtocol
} from 'app/common';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { Company } from 'app/pages/my-audience/companies';
import { getNames } from 'country-list';
import { cloneDeep } from 'lodash';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';

export const ContactData = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedCompany = useAppSelector<Company>(selectSelectedCompany);
  let selectedCompanyLocal = cloneDeep(selectedCompany);

  const { hasAllPermissions } = useAuthContext();
  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const handleOnChange = (fieldName: string, updatedValue: string) => {
    selectedCompanyLocal = { ...selectedCompanyLocal, contactData: { ...selectedCompanyLocal.contactData, [fieldName]: updatedValue } };
    dispatch(updateCompanyRequested(selectedCompanyLocal));
  };

  const onSaveUrl = (fieldName: string, url: string) => {
    selectedCompanyLocal = { ...selectedCompanyLocal, contactData: { ...selectedCompanyLocal.contactData, [fieldName]: ensureHttpProtocol(url) } };
    dispatch(updateCompanyRequested(selectedCompanyLocal));
  };

  return (
    <div className="editable-fields-wrap">
      <div className="editable-fields-content">
        <EditableInputTextField
          value={selectedCompany.contactData?.street}
          fieldName="street"
          onChange={handleOnChange}
          isLink={false}
          label={formatMessage({ id: 'companies-contact-data-labels.address' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.contactData?.postalCode}
          fieldName="postalCode"
          onChange={handleOnChange}
          isLink={false}
          label={formatMessage({ id: 'companies-contact-data-labels.zip' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-postal-code' })}
          isFieldValid={isNumberValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.contactData?.city}
          fieldName="city"
          onChange={handleOnChange}
          isLink={false}
          label={formatMessage({ id: 'companies-contact-data-labels.city' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableDropdownField
          value={selectedCompany.contactData?.country}
          fieldName="country"
          suggestions={getNames()}
          onSaveChanges={handleOnChange}
          label="Country"
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.contactData?.landlinePhoneNumber}
          fieldName="landlinePhoneNumber"
          onChange={handleOnChange}
          isLink={fieldContainsNonEmptyValue(selectedCompany.contactData?.landlinePhoneNumber)}
          label={formatMessage({ id: 'companies-contact-data-labels.phone-number' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-phone-number' })}
          linkUrl={'tel:+' + selectedCompany.contactData?.landlinePhoneNumber}
          isFieldValid={isPhoneNumberValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.contactData?.fax}
          fieldName="fax"
          onChange={handleOnChange}
          isLink={false}
          label={formatMessage({ id: 'companies-contact-data-labels.fax' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.contactData?.emailAddress}
          fieldName="emailAddress"
          onChange={handleOnChange}
          isLink={fieldContainsNonEmptyValue(selectedCompany.contactData?.emailAddress)}
          label={formatMessage({ id: 'companies-contact-data-labels.email' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-email-address' })}
          linkUrl={'mailto:' + selectedCompany.contactData?.emailAddress}
          isFieldValid={isEmailAddressValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedCompany.contactData?.websiteUrl}
          fieldName="websiteUrl"
          onChange={onSaveUrl}
          isLink={fieldContainsNonEmptyValue(selectedCompany.contactData?.websiteUrl)}
          label={formatMessage({ id: 'companies-contact-data-labels.website' })}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-website' })}
          linkUrl={selectedCompany.contactData?.websiteUrl}
          isFieldValid={isValidUrlField}
          disabled={!hasPermissionToEdit}
        />
      </div>
    </div>
  );
};
