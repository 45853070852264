import { useEvent } from '@flatfile/react';
import { closeFlatfile, useAppDispatch } from 'app/common';

const CloseImportWindowOnUploadEvent = () => {
  const dispatch = useAppDispatch();

  useEvent(
    'job:outcome-acknowledged',
    {
      operation: `sheetSubmitAction-Contacts`,
      status: 'complete'
    },
    () => {
      dispatch(closeFlatfile());
    }
  );
};
export default CloseImportWindowOnUploadEvent;
