import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfCompanyContactsRequested,
  selectCompanyContactsPageSize,
  selectCompanyContactsTotalCount,
  selectIsLoadingCompanyContacts,
  setCompanyContactsPageNumber,
  setCompanyContactsPageSize
} from 'app/pages/my-audience/company-profile';

export const ContactsPaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfContacts = useAppSelector<number>(selectCompanyContactsTotalCount);
  const pageSize = useAppSelector<number>(selectCompanyContactsPageSize);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingCompanyContacts);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setCompanyContactsPageNumber(pageNumber));
    dispatch(pageOfCompanyContactsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setCompanyContactsPageSize(pageSize));
    dispatch(pageOfCompanyContactsRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfContacts}
      isLoadingData={isLoadingContacts}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
