import { FormattedMessage } from 'app/i18n';
import { MediaResorts, Topics } from 'app/pages/my-audience/contact-profile';

export const TopicsAndMediaResorts = () => {
  return (
    <div className="profile-panel-topic">
      <div className="profile-panel-topic-column">
        <h5 className="profile-panel-topic-title">
          <FormattedMessage id={'topics-and-media-resorts-accordion-item.media-resorts-label'} />
        </h5>
        <MediaResorts />
      </div>
      <div className="profile-panel-topic-column">
        <h5 className="profile-panel-topic-title">
          <FormattedMessage id={'topics-and-media-resorts-accordion-item.topics-label'} />
        </h5>
        <Topics />
      </div>
    </div>
  );
};
