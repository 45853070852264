import axios from 'axios';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CommentUser, DataSource, Language, Tag, TagCreationInput, User, httpClient } from 'app/common';
import {
  CreateAndAssignTagDocument,
  CreateAndAssignTagMutation,
  DeleteTagsDocument,
  DeleteTagsMutation,
  FetchAllLanguagesDocument,
  FetchAllLanguagesQuery,
  FetchBlacklistByIdDocument,
  FetchBlacklistByIdQuery,
  FetchDataSourcesDocument,
  FetchDataSourcesQuery,
  FetchTagsByEntityTypeDocument,
  FetchTagsByEntityTypeQuery,
  FetchUserByAadObjectIdDocument,
  FetchUserByAadObjectIdQuery
} from 'app/common/graphql/generated/graphql-gateway';
import { Blacklist } from 'app/pages/my-audience/common';

export const fetchMentionSuggestions = async (searchText: string): Promise<CommentUser[]> => {
  const encodedName = encodeURIComponent(searchText);
  return httpClient
    .get(`${process.env.REACT_APP_BOT_URL}/api/users?name=${encodedName}`)
    .then((response) => response.data as CommentUser[])
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const fetchTagsByEntityType = async (client: ApolloClient<NormalizedCacheObject>, entityType: string): Promise<Tag[]> => {
  const response = await client.query<FetchTagsByEntityTypeQuery>({
    query: FetchTagsByEntityTypeDocument,
    variables: {
      entityType: entityType
    }
  });

  return response.data.tags.map((t) => new Tag(t.id, t.name, t.hexColor));
};

export const createAndAssignTag = async (client: ApolloClient<NormalizedCacheObject>, tagCreationInput: TagCreationInput): Promise<Tag> => {
  const response = await client.mutate<CreateAndAssignTagMutation>({
    mutation: CreateAndAssignTagDocument,
    variables: {
      input: tagCreationInput
    }
  });

  return new Tag(response.data.createAndAssignTag.id, response.data.createAndAssignTag.name, response.data.createAndAssignTag.hexColor);
};

export const deleteTags = async (client: ApolloClient<NormalizedCacheObject>, tagIds: string[]): Promise<void> => {
  await client.mutate<DeleteTagsMutation>({
    mutation: DeleteTagsDocument,
    variables: {
      tagIds: tagIds
    }
  });
};

export const fetchUserByAadId = async (client: ApolloClient<NormalizedCacheObject>): Promise<User> => {
  const response = await client.query<FetchUserByAadObjectIdQuery>({
    query: FetchUserByAadObjectIdDocument
  });

  //TODO Revisit field aadObjectId on User model and remove if it is unused
  const user = response.data.userByAadObjectId;
  return user && new User(user.id, '', user.firstName, user.lastName);
};

export const fetchDataSources = async (client: ApolloClient<NormalizedCacheObject>): Promise<DataSource[]> => {
  const response = await client.query<FetchDataSourcesQuery>({
    query: FetchDataSourcesDocument
  });

  return response.data.dataSources.map((d) => new DataSource(d.id, d.key));
};

export const fetchBlacklistById = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<Blacklist> => {
  const response = await client.query<FetchBlacklistByIdQuery>({
    query: FetchBlacklistByIdDocument,
    variables: {
      id: id
    }
  });

  return new Blacklist(response.data.blacklistById.id, response.data.blacklistById.name);
};

export const authenticateUser = async (idToken: string): Promise<string> => {
  return axios
    .get(`${process.env.REACT_APP_USERS_URL}/api/auth/access-token`, {
      headers: {
        authorization: `Bearer ${idToken}`
      }
    })
    .then((response) => {
      return response.data.accessToken;
    });
};

export const getUserPermissions = async (): Promise<string[]> => {
  return httpClient.get(`${process.env.REACT_APP_USERS_URL}/api/users/whoami`).then((response) => {
    return response.data.permissions;
  });
};

export const fetchLanguages = async (client: ApolloClient<NormalizedCacheObject>): Promise<Language[]> => {
  const response = await client.query<FetchAllLanguagesQuery>({
    query: FetchAllLanguagesDocument
  });

  return response.data.languages.map((language) => new Language(language.id, language.name));
};
