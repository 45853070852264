import { Alert } from '@fluentui/react-northstar';
import {
  MessageData,
  defaultTimeoutInMilliseconds,
  hideAlertNotification,
  selectAlertData,
  selectAlertNotificationMessage,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { useIntl } from 'app/i18n';
import { useEffect, useMemo } from 'react';

interface AlertNotificationProps {
  isAlertNotificationVisible: boolean;
}

export const AlertNotification = ({ isAlertNotificationVisible }: AlertNotificationProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const alertMessage = useAppSelector<string>(selectAlertNotificationMessage);
  const alertData = useAppSelector<MessageData>(selectAlertData);

  const alertMessageFromData = useMemo((): string => {
    if (!alertData.localizationKey) return null;

    return alertData.args ? formatMessage({ id: alertData.localizationKey }, alertData.args) : formatMessage({ id: alertData.localizationKey });
  }, [alertData, formatMessage]);

  const onDismissAlert = () => {
    dispatch(hideAlertNotification());
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideAlertNotification());
    }, defaultTimeoutInMilliseconds);
  }, [dispatch]);

  return (
    <div className="alert-dialog">
      <Alert
        content={<span className="alert-dialog-content">{alertMessage || alertMessageFromData}</span>}
        variables={{
          urgent: true
        }}
        dismissible
        visible={isAlertNotificationVisible}
        onVisibleChange={onDismissAlert}
      />
    </div>
  );
};
