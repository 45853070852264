import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { GridContextProvider, swap } from 'react-grid-dnd';
import { Button, Input, MenuButton } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react';
import { Interval } from 'luxon';
import { FilterIcon } from '@fluentui/react-icons-northstar';
import { useOnClickOutside } from 'usehooks-ts';
import { useDebouncedCallback } from 'use-debounce';
import {
  BottomPopupItems,
  debounceTimeInMilliseconds,
  FilterItem,
  openBottomPopup,
  selectIsOnDesktop,
  selectIsOnMobile,
  selectIsOnTablet,
  useAppDispatch,
  useAppSelector,
  DateRange
} from 'app/common';
import { SavedSearch, GridCard } from 'app/pages/my-audience/saved-searches';
import emptyImage from 'assets/images/empty-search2.svg';
import { useIntl } from 'app/i18n';
import 'app/pages/my-audience/saved-searches/components/saved-searches.scss';
import { FilterItemType, generateTimeZoneInterval } from 'app/common/factories/time-zone';

export interface SavedSearchesGridProps {
  isLoadingSavedSearches: boolean;
  pageSize: number;
  savedSearches: SavedSearch[];
  totalCount: number;
  onSearchSavedSearches?: (searchText: string) => void;
  onFilterSavedSearches?: (filterItem: FilterItem) => void;
  onResetFilter?: () => void;
  onLoadMore?: () => void;
  onSavedSearchClick: (savedSearch: SavedSearch) => void;
  onReorderSavedSearches: (reorderedSavedSearches: SavedSearch[]) => void;
  onSetSavedSearchToRemove: (savedSearchToRemove: SavedSearch) => void;
  onUpdateSavedSearch: (updatedSavedSearch: SavedSearch) => void;
  searchText?: string;
}

export const SavedSearchesGrid = ({
  isLoadingSavedSearches,
  pageSize,
  savedSearches,
  totalCount,
  onUpdateSavedSearch,
  onSavedSearchClick,
  onSearchSavedSearches,
  onFilterSavedSearches,
  onResetFilter,
  onLoadMore,
  onReorderSavedSearches,
  onSetSavedSearchToRemove
}: SavedSearchesGridProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const ref = useRef(null);

  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);
  const isOnTablet = useAppSelector<boolean>(selectIsOnTablet);

  const [localSavedSearches, setLocalSavedSearches] = useState(savedSearches);
  const [isSearchInputExpanded, setIsSearchInputExpanded] = useState(false);

  useEffect(() => {
    setLocalSavedSearches(savedSearches);
  }, [savedSearches]);

  const handleExpandedInput = () => {
    setIsSearchInputExpanded(false);
  };
  useOnClickOutside(ref, handleExpandedInput);

  const handleReorderSavedSearches = (sourceId: string, sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }

    const reorderedSavedSearches = swap([...localSavedSearches], sourceIndex, targetIndex).map((savedSearch: SavedSearch, index: number) => ({
      ...savedSearch,
      priority: index
    }));

    setLocalSavedSearches(reorderedSavedSearches);
    onReorderSavedSearches(reorderedSavedSearches);
  };

  const createCreatedAtFilterItem = (interval: Interval): FilterItem => {
    const dateRange = DateRange.parse(interval);
    return new FilterItem('createdAt', `${dateRange.startDate} - ${dateRange.endDate}`);
  };

  const applyFilter = (filter: FilterItemType) => {
    const timeInterval = generateTimeZoneInterval(filter);
    const filterInterval = createCreatedAtFilterItem(timeInterval);
    onFilterSavedSearches(filterInterval);
  };

  const bottomPopupItems: BottomPopupItems[] = [
    {
      key: 'show-all-saved-search',
      title: formatMessage({ id: 'saved-searches.show-all-filter-item' }),
      className: 'bottom-popup-items',
      onClick: onResetFilter,
      icon: null
    },
    {
      key: 'show-last-7-days-saved-search',
      title: formatMessage({ id: 'saved-searches.last-7-days-filter-item' }),
      className: 'bottom-popup-items',
      onClick: () => applyFilter({ amount: 7, timeFrame: 'days' }),
      icon: null
    },
    {
      key: 'show-last-30-days-saved-search',
      title: formatMessage({ id: 'saved-searches.last-30-days-filter-item' }),
      className: 'bottom-popup-items',
      onClick: () => applyFilter({ amount: 1, timeFrame: 'months' }),
      icon: null
    },
    {
      key: 'show-last-6-m-saved-search',
      title: formatMessage({ id: 'saved-searches.last-6-months-filter-item' }),
      className: 'bottom-popup-items',
      onClick: () => applyFilter({ amount: 6, timeFrame: 'months' }),
      icon: null
    },
    {
      key: 'show-last-12-m-saved-search',
      title: formatMessage({ id: 'saved-searches.last-12-months-filter-item' }),
      className: 'bottom-popup-items',
      onClick: () => applyFilter({ amount: 1, timeFrame: 'years' }),
      icon: null
    }
  ];

  const filterItems = [
    {
      key: 'showAllFilterItem',
      content: formatMessage({ id: 'saved-searches.show-all-filter-item' }),
      onClick: onResetFilter
    },
    {
      key: 'last7DaysFilterItem',
      content: formatMessage({ id: 'saved-searches.last-7-days-filter-item' }),
      onClick: () => applyFilter({ amount: 7, timeFrame: 'days' })
    },
    {
      key: 'last30DaysFilterItem',
      content: formatMessage({ id: 'saved-searches.last-30-days-filter-item' }),
      onClick: () => applyFilter({ amount: 1, timeFrame: 'months' })
    },
    {
      key: 'last6MonthsFilterItem',
      content: formatMessage({ id: 'saved-searches.last-6-months-filter-item' }),
      onClick: () => applyFilter({ amount: 6, timeFrame: 'months' })
    },
    {
      key: 'last12MonthsFilterItem',
      content: formatMessage({ id: 'saved-searches.last-12-months-filter-item' }),
      onClick: () => applyFilter({ amount: 1, timeFrame: 'years' })
    }
  ];

  const handleSearchSavedSearches = (event: SyntheticEvent<HTMLElement>) => {
    const inputSearchText = (event.target as HTMLTextAreaElement).value;
    onSearchSavedSearches(inputSearchText ?? '');
  };

  const handleSearchSavedSearchesInputChange = useDebouncedCallback(handleSearchSavedSearches, debounceTimeInMilliseconds);

  const handleOpenBottomPopupItemsClick = () => {
    dispatch(openBottomPopup(bottomPopupItems));
  };

  return (
    <>
      <div className="saved-searches-wrap">
        <div className="saved-searches-intro">
          <h2 className="saved-searches-title">{formatMessage({ id: 'saved-searches.title' })}</h2>
          <div className="saved-searches-inputs">
            <div className="saved-searches-filter">
              {(isOnDesktop || isOnTablet) && (
                <MenuButton
                  trigger={<Button className="filter-button" icon={<FilterIcon />} content={formatMessage({ id: 'saved-searches.filter-button-label' })} />}
                  menu={filterItems}
                  on="click"
                />
              )}
              {isOnMobile && (
                <div className="saved-searches-filter-icon" onClick={handleOpenBottomPopupItemsClick}>
                  <FilterIcon /> <span>{formatMessage({ id: 'saved-searches.filter-button-label' })}</span>
                </div>
              )}
            </div>
            <div className="saved-searches-find" ref={ref}>
              <Input
                clearable
                className={isSearchInputExpanded ? 'saved-searches-search-input expanded' : 'saved-searches-search-input'}
                icon={<Icon iconName="Search" />}
                placeholder={formatMessage({ id: 'saved-searches.find-input-placeholder' })}
                onClick={() => setIsSearchInputExpanded(true)}
                onChange={handleSearchSavedSearchesInputChange}
              />
            </div>
          </div>
        </div>
        {totalCount > 0 && (
          <>
            <div className="saved-searches-content">
              <GridContextProvider onChange={handleReorderSavedSearches}>
                <GridCard
                  pageSize={pageSize}
                  savedSearches={localSavedSearches}
                  onUpdateSavedSearch={onUpdateSavedSearch}
                  onSetSavedSearchToRemove={onSetSavedSearchToRemove}
                  onSavedSearchClick={onSavedSearchClick}
                />
              </GridContextProvider>
              {totalCount - savedSearches.length > 0 && !isLoadingSavedSearches && (
                <div className="saved-searches-button">
                  <Button content={formatMessage({ id: 'saved-searches.load-more' })} onClick={onLoadMore} className="button-load-more" />
                </div>
              )}
              <div className="card-background-wrap">
                {localSavedSearches.map((savedSearch) => (
                  <span key={savedSearch.id} className="card-background" aria-hidden="true" />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {totalCount === 0 && !isLoadingSavedSearches && (
        <div className="saved-searches-empty">
          <img src={emptyImage} className="saved-searches-empty-img" alt="" />
          <span className="saved-searches-empty-text text-bold ">{formatMessage({ id: 'saved-searches.empty-message' })}</span>
          <span className="saved-searches-empty-text">{formatMessage({ id: 'saved-searches.empty-hint-message' })}</span>
        </div>
      )}
    </>
  );
};
