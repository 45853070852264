import { Icon } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Button } from '@fluentui/react-northstar';
import { DropdownList, isTextInputValid, maximumTextInputLength, PillData, regAtLeastOneCharacter, undoQueue } from 'app/common';
import { useIntl } from 'app/i18n';
import { ControlledInput } from 'app/pages/my-audience/common';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

interface TagsPopupContentProps {
  entityName: string;
  selectedValues: PillData[];
  dropdownValues: PillData[];
  onAddValue: (text: string) => void;
  onSelectValue: (value: string) => void;
  onDeselectValue: (value: string) => void;
  onDeleteValues?: (value: string[]) => void;
  filterValues: (searchText: string) => void;
  enabledDelete: boolean;
  minInputLength?: number;
  maxInputLength?: number;
  onLoadMoreCallback?: (searchQuery: string) => void;
}

export const PillsPopupContent = ({
  selectedValues,
  dropdownValues,
  onAddValue,
  onSelectValue,
  onDeselectValue,
  onDeleteValues,
  entityName,
  filterValues,
  enabledDelete,
  minInputLength = 1,
  maxInputLength = maximumTextInputLength,
  onLoadMoreCallback
}: TagsPopupContentProps) => {
  const { formatMessage } = useIntl();

  const [inputValue, setInputValue] = useState<string>('');
  const [valuesToDelete, setValuesToDelete] = useState<PillData[]>([]);
  const [isDeletingTags, { setTrue: showDeleteTags, setFalse: hideDeleteTags }] = useBoolean(false);

  const valueExists = !!dropdownValues.length;
  const checkedItems = isDeletingTags ? valuesToDelete : selectedValues;
  const showSettings = enabledDelete && valueExists && !isDeletingTags;
  const showAddButton = !valueExists && !isDeletingTags;
  const showDeleteButton = enabledDelete && isDeletingTags;
  const {
    control,
    watch,
    formState: { isValid },
    reset
  } = useForm({
    defaultValues: {
      name: inputValue
    },
    reValidateMode: 'onChange',
    mode: 'all'
  });

  watch((data) => handleInputChange(data.name));

  const onSelectedCheckboxChange = (isChecked: boolean, value: PillData) => {
    if (isDeletingTags) {
      isChecked ? setValuesToDelete([...valuesToDelete, value]) : setValuesToDelete(valuesToDelete.filter((v) => v !== value));
      return;
    }

    isChecked ? onSelectValue(value.id) : onDeselectValue(value.id);
  };

  const handleAdd = () => {
    setInputValue('');
    reset();
    onAddValue(inputValue);
  };

  const handleDelete = () => {
    onDeleteValues(valuesToDelete.map((v) => v.id));
    setValuesToDelete([]);
  };

  const handleDeleteValue = (value: string) => onDeleteValues([value]);

  const handleInputChange = (inputText: string) => {
    isTextInputValid({ inputValue: watch('name'), minInputLength: minInputLength, maxInputLength: maxInputLength });
    setInputValue(inputText);
    filterValues(inputText);
  };

  const handleEdit = () => {
    if (undoQueue.hasPendingActions()) {
      undoQueue.executeAllPendingActions();
    }

    showDeleteTags();
  };

  const handleCancelEdit = () => hideDeleteTags();

  const handleLoadMore = () => {
    if (onLoadMoreCallback) {
      onLoadMoreCallback(inputValue);
    }
  };

  return (
    <div className="tags-wrap">
      <div className="tags-intro">
        {!isDeletingTags ? (
          <h6 className="tags-intro-title">{formatMessage({ id: 'pills.popup.title' }, { entity: entityName })}</h6>
        ) : (
          <div className="tags-intro-title">
            <Button content={formatMessage({ id: 'buttons.back' })} text icon={<Icon iconName="Back" />} onClick={handleCancelEdit} />
          </div>
        )}
        <ControlledInput
          className="tags-intro-input"
          control={control}
          name="name"
          rules={{
            pattern: {
              value: regAtLeastOneCharacter,
              message: formatMessage({ id: 'error-messages.not-valid-name' }, { entity: entityName })
            },
            maxLength: {
              message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maxInputLength }),
              value: maxInputLength
            },
            required: formatMessage({ id: 'error-messages.required-name' }, { entity: entityName })
          }}
        />
      </div>
      <DropdownList
        showDeleteButton={isDeletingTags}
        dropdownValues={dropdownValues}
        selectedValues={checkedItems}
        entityName={entityName}
        onSelectedCheckboxChange={onSelectedCheckboxChange}
        onDelete={handleDeleteValue}
        isValid={isValid}
        onLoadMoreCallback={handleLoadMore}
      />
      <div className="tags-actions">
        {showAddButton && isValid && (
          <Button content={formatMessage({ id: 'pills.popup.create-entity-button' }, { entity: entityName, value: inputValue })} text onClick={handleAdd} />
        )}
        {showSettings && (
          <Button
            content={formatMessage({ id: 'pills.popup.edit-entity-button' }, { entity: entityName })}
            text
            icon={<Icon iconName="Settings" />}
            onClick={handleEdit}
          />
        )}
        {showDeleteButton && (
          <Button
            className="tags-button-delete"
            content={formatMessage({ id: 'pills.popup.delete-selected-button' })}
            onClick={handleDelete}
            text
            icon={<Icon iconName="Delete" />}
            disabled={!valuesToDelete.length}
          />
        )}
      </div>
    </div>
  );
};
