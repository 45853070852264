import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateMediaOutletAddressInfo,
  CreateMediaOutletGeneralInfo,
  closeCreateMediaOutletWizard,
  CreateMediaOutletFromContactStep,
  CreateMediaOutletFinish,
  selectMediaOutletCurrentStep,
  selectIsCreateMediaOutletWizardOpened
} from 'app/pages/my-audience/wizard-create-contact';

export const CreateMediaOutletWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isAddMediaOutletCollaborationWizardOpened = useAppSelector<boolean>(selectIsCreateMediaOutletWizardOpened);
  const currentStep = useAppSelector<CreateMediaOutletFromContactStep>(selectMediaOutletCurrentStep);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isAddMediaOutletCollaborationWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-menu-create-contact.mo-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateMediaOutletFromContactStep.GeneralInfo && <CreateMediaOutletGeneralInfo />}
        {currentStep === CreateMediaOutletFromContactStep.AddressInfo && <CreateMediaOutletAddressInfo />}
        {currentStep === CreateMediaOutletFromContactStep.Finish && <CreateMediaOutletFinish />}
      </div>
    </WizardWrap>
  );
};
