import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfContactsSavedSearchesRequested,
  selectContactsSavedSearchesPageSize,
  selectIsLoadingContactsSavedSearches,
  selectSavedSearchesTotalCount,
  setContactsSavedSearchesPageNumber,
  setContactsSavedSearchesPageSize
} from 'app/pages/my-audience/lists-profile';

export const AddSavedSearchesTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfSavedSearches = useAppSelector<number>(selectSavedSearchesTotalCount);
  const isLoadingSavedSearches = useAppSelector<boolean>(selectIsLoadingContactsSavedSearches);

  const pageSize = useAppSelector<number>(selectContactsSavedSearchesPageSize);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setContactsSavedSearchesPageNumber(pageNumber));
    dispatch(pageOfContactsSavedSearchesRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setContactsSavedSearchesPageSize(pageSize));
    dispatch(setContactsSavedSearchesPageNumber(1));
    dispatch(pageOfContactsSavedSearchesRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfSavedSearches}
      isLoadingData={isLoadingSavedSearches}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
