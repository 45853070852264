import { FilterItem, Search, SearchSuggestionCategory, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  clearAllFilters,
  selectIsLoadingSearchSuggestions,
  selectSearchText,
  setFilters,
  setSearchText,
  startSearchAnimation,
  stopSearchAnimation,
  selectContactsSearchSuggestionsText,
  selectContactSuggestions,
  resetContactSuggestions,
  contactSuggestionsRequested,
  firstPageOfContactsRequested
} from 'app/pages/my-audience/contacts';

interface ContactsSearchProps {
  onOutsideClick?: () => void;
}

export const ContactsSearch = ({ onOutsideClick }: ContactsSearchProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const searchSuggestionsCategories = useAppSelector<SearchSuggestionCategory[]>(selectContactSuggestions);
  const searchSuggestionsText = useAppSelector<string>(selectContactsSearchSuggestionsText);
  const isLoadingSearchSuggestions = useAppSelector<boolean>(selectIsLoadingSearchSuggestions);
  const searchText = useAppSelector<string>(selectSearchText);

  const handleClearAllFilters = () => {
    dispatch(clearAllFilters());
  };

  const handleSetSearchText = (searchText: string) => {
    dispatch(setSearchText(searchText));
  };

  const handleSetFilterItems = (filterItems: FilterItem[]) => {
    dispatch(setFilters(filterItems));
  };

  const handleResetSuggestions = () => {
    dispatch(resetContactSuggestions());
  };

  const handleSearchSuggestions = (searchSuggestionsText: string) => {
    dispatch(contactSuggestionsRequested(searchSuggestionsText));
  };

  const handleOnAnimationStart = () => {
    dispatch(startSearchAnimation());
  };

  const handleOnAnimationStop = () => {
    dispatch(stopSearchAnimation());
  };

  const handleSearchContacts = () => {
    dispatch(firstPageOfContactsRequested());
  };

  return (
    <Search
      searchSuggestionsCategories={searchSuggestionsCategories}
      searchSuggestionsText={searchSuggestionsText}
      onClearAllFilters={handleClearAllFilters}
      onResetSuggestions={handleResetSuggestions}
      onSearchSuggestions={handleSearchSuggestions}
      searchText={searchText}
      isLoadingSearchSuggestions={isLoadingSearchSuggestions}
      onAnimationStart={handleOnAnimationStart}
      onAnimationStop={handleOnAnimationStop}
      onSetSearchText={handleSetSearchText}
      onSetFilterItems={handleSetFilterItems}
      onSearch={handleSearchContacts}
      viewAllMessage={formatMessage({ id: 'search.view-all-contacts' })}
      onOutsideClick={onOutsideClick}
    />
  );
};
