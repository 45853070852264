import { Icon } from '@fluentui/react';
import { Text } from '@fluentui/react-northstar';
import { useAppDispatch, closeSearchPopupOnMobile } from 'app/common';

export const NewBackButton = ({ children = 'Augure' }) => {
  const dispatch = useAppDispatch();

  const onBackButtonClick = () => {
    dispatch(closeSearchPopupOnMobile());
  };

  return (
    <div className="back-button-wrap" onClick={onBackButtonClick}>
      <button className="back-button">
        <Icon iconName="ArrowUpRight" />
        <Text className="back-button-text">{children}</Text>
      </button>
    </div>
  );
};
