import { ExportDialog, SearchResultTableData, selectIsExportDialogVisible, selectSelectedTableRows, TableHeader, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { createExportModelFromTableRows } from 'app/pages/my-audience/common';
import { selectExportHeader } from 'app/pages/my-audience/lists';

export const ListsExportDialog = () => {
  const { formatMessage } = useIntl();

  const isExportDialogVisible = useAppSelector<boolean>(selectIsExportDialogVisible);
  const csvHeader = useAppSelector<TableHeader[]>(selectExportHeader);
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);

  return (
    <ExportDialog
      isDialogVisible={isExportDialogVisible}
      fileName={formatMessage({ id: 'labels.lists' })}
      dataToExport={createExportModelFromTableRows(csvHeader, selectedTableRows)}
      showFileTypesDropdown={false}
    />
  );
};
