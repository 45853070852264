import { DefaultOptions, from, HttpLink, InMemoryCache, makeVar, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getTokensSilentInBrowser, getTokensSilentInTeams } from 'auth';
import { ApolloClientProxy } from 'app/common/graphql/ApolloClientProxy';

export const unauthorizedApollo = makeVar(false);
export const isInTeamsApollo = makeVar(false);

const notifyUnauthorized = () => unauthorizedApollo(true);
const getAccessTokensAsync = () => (isInTeamsApollo() ? getTokensSilentInTeams() : getTokensSilentInBrowser());

const authLink = setContext(async (_, { headers }) => {
  const tokens = await getAccessTokensAsync();

  if (!tokens) {
    notifyUnauthorized();
    return;
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${tokens.accessToken}`,
      graphApiAccessToken: tokens.graphApiAccessToken
    }
  };
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const APOLLO_UNAUTHORIZED = 'HC0006';
const checkRetryResponse = ({ errors }: any) => {
  if (errors && errors[0].extensions.code === APOLLO_UNAUTHORIZED) notifyUnauthorized();
};
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors && graphQLErrors[0]?.extensions?.code === APOLLO_UNAUTHORIZED) {
    return new Observable((observer) => {
      getAccessTokensAsync()
        .then((tokens) => {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${tokens.accessToken}`,
              graphApiAccessToken: tokens.graphApiAccessToken
            }
          }));
        })
        .then(() =>
          forward(operation).subscribe({
            next: (response) => {
              checkRetryResponse(response);
              observer.next(response);
            },
            error: (networkError) => {
              checkRetryResponse(networkError?.result);
              observer.error(networkError);
            },
            complete: () => observer.complete()
          })
        )
        .catch((error) => {
          notifyUnauthorized();
          observer.next(error);
        });
    });
  }
});

export const client = new ApolloClientProxy({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});
