import { maximumSearchTextLength, useAppSelector, LongTitle, selectIsOnDesktop, selectIsOnMobile } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectCountOfLoadedLists, selectSearchText, selectTotalCountOfLists } from 'app/pages/my-audience/lists';

export const ListsSearchResultsIntroText = () => {
  const { formatMessage } = useIntl();

  const countOfLoadedEntities = useAppSelector<number>(selectCountOfLoadedLists);
  const totalCountOfLists = useAppSelector<number>(selectTotalCountOfLists);
  const searchText = useAppSelector<string>(selectSearchText);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);
  return (
    <div className="search-results-text">
      {isOnDesktop && (
        <span>
          {formatMessage({ id: 'search-results-table.intro.number-of-results' }, { visibleCount: countOfLoadedEntities, totalCount: totalCountOfLists })}
          <span className="searched-text">
            &quot;
            <LongTitle value={searchText} maximumLength={maximumSearchTextLength} />
            &quot;
          </span>
        </span>
      )}
      {isOnMobile && <span>{formatMessage({ id: 'search-results-table.intro.number-mobile' }, { totalCount: totalCountOfLists })}</span>}
    </div>
  );
};
