import { useMemo } from 'react';
import { Button } from '@fluentui/react-northstar';
import { Language, EditableInputTextField, EditableMultipleSelectionField, selectLanguages, useAppDispatch, useAppSelector } from 'app/common';
import { LocalizedListValue, MediaOutlet, PreferredLanguage } from 'app/pages/my-audience/media-outlets';
import {
  selectSelectedMediaOutlet,
  updateMediaOutletRequested,
  selectAreMoreEditableFieldsVisible,
  showMoreEditableFileds,
  OverviewMoreFields
} from 'app/pages/my-audience/media-outlet-profile';
import { selectLocalizedMediumSupportTypes } from 'app/localization';
import { useIntl } from 'app/i18n';
import { useAuthContext, Permission } from 'auth';

export const Overview = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);

  const areMoreEditableFieldsVisible = useAppSelector<boolean>(selectAreMoreEditableFieldsVisible);
  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);
  const localizedMediumSupportTypes = useAppSelector<LocalizedListValue[]>(selectLocalizedMediumSupportTypes);
  const allLanguages = useAppSelector<Language[]>(selectLanguages);

  const selectedMediaSupportTypes = useMemo(
    () =>
      localizedMediumSupportTypes.filter((mst) => selectedMediaOutlet.mediaSupportTypesKeys.includes(mst.key)).map((mst) => ({ id: mst.id, value: mst.label })),
    [localizedMediumSupportTypes, selectedMediaOutlet.mediaSupportTypesKeys]
  );

  const handleOnChange = (fieldName: string, updatedValue: string | number) => {
    const selectedMediaOutletLocal = { ...selectedMediaOutlet, [fieldName]: updatedValue };
    dispatch(updateMediaOutletRequested(selectedMediaOutletLocal));
  };

  const onSavePreferredLanguages = (itemsIds: string[]) => {
    const chosenLanguages = allLanguages.filter((lang) => itemsIds.includes(lang.id));
    const selectedMediaOutletLocal = {
      ...selectedMediaOutlet,
      preferredLanguages: chosenLanguages.map((lang) => new PreferredLanguage(lang.id, lang.name, ''))
    };
    dispatch(updateMediaOutletRequested(selectedMediaOutletLocal));
  };

  const onSaveMediaSupportTypes = (itemsIds: string[]) => {
    const selectedValues = localizedMediumSupportTypes.reduce((selectedItems, mst) => [...selectedItems, ...(itemsIds.includes(mst.id) ? [mst.key] : [])], []);
    const selectedMediaOutletLocal = { ...selectedMediaOutlet, mediaSupportTypesKeys: selectedValues };
    dispatch(updateMediaOutletRequested(selectedMediaOutletLocal));
  };

  const handleShowMoreButtonClick = () => {
    dispatch(showMoreEditableFileds());
  };

  return (
    <div className="editable-fields-wrap">
      <div className="editable-fields-content">
        <EditableMultipleSelectionField
          items={selectedMediaSupportTypes.map((mst) => ({ key: mst.id, content: mst.value }))}
          suggestions={localizedMediumSupportTypes.map((mst) => ({ key: mst.id, content: mst.label }))}
          label={formatMessage({ id: 'mo-overview-labels.media-support' })}
          onSave={onSaveMediaSupportTypes}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.mediaType}
          fieldName="mediaType"
          onChange={handleOnChange}
          isLink={false}
          label={formatMessage({ id: 'mo-overview-labels.media-type' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.publicationType}
          fieldName="publicationType"
          onChange={handleOnChange}
          label={formatMessage({ id: 'mo-overview-labels.publication' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableMultipleSelectionField
          items={selectedMediaOutlet.preferredLanguages.map((language) => ({ key: language.id, content: language.name }))}
          onSave={onSavePreferredLanguages}
          suggestions={allLanguages.map((item) => ({ key: item.id, content: item.name }))}
          label={formatMessage({ id: 'mo-overview-labels.languages' })}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.wemfArea}
          fieldName="wemfArea"
          onChange={handleOnChange}
          label={formatMessage({ id: 'mo-overview-labels.wemf' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={selectedMediaOutlet.readership}
          fieldName="readership"
          onChange={handleOnChange}
          label={formatMessage({ id: 'mo-overview-labels.readership' })}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        {!areMoreEditableFieldsVisible ? (
          <div className="editable-fields-button">
            <Button text content={formatMessage({ id: 'buttons.show-more' })} onClick={handleShowMoreButtonClick} />
          </div>
        ) : (
          <OverviewMoreFields />
        )}
      </div>
    </div>
  );
};
