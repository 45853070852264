import { useMemo } from 'react';
import { Selection, SelectionMode } from '@fluentui/react';
import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import {
  AddContactCollaborationInfo,
  AddGeneralContactInfo,
  AdditionalContactInformation,
  closeCreateContactWizard,
  CreateContactWizardStep,
  CreateContactFinish,
  selectCurrentStep,
  selectIsCreateContactWizardOpened,
  CollaborationTableData,
  setCollaborationsToAddPerPage,
  CreateContactSteps,
  selectCollaborationsToAdd
} from 'app/pages/my-audience/wizard-create-contact';
import { Medium } from 'app/pages/my-audience/contact-profile';
import { Collaboration, CollaborationDetails } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const CreateContactWizard = () => {
  const dispatch = useAppDispatch();
  const isCreateContactWizardOpened = useAppSelector<boolean>(selectIsCreateContactWizardOpened);
  const currentStep = useAppSelector<CreateContactWizardStep>(selectCurrentStep);
  const collaborationDetails = useAppSelector<CollaborationDetails[]>(selectCollaborationsToAdd);

  const { formatMessage } = useIntl();

  const collaborationSelection = useMemo(() => {
    const mapCollectionTableDataToCollection = (selectedMedium: CollaborationTableData) => ({
      collaboration: {
        medium: new Medium(selectedMedium.id, selectedMedium.name, selectedMedium.mediumType),
        city: selectedMedium.city,
        postalCode: selectedMedium.zip,
        country: selectedMedium.country
      } as Collaboration,
      useDifferentAddress: false
    });

    return new Selection({
      onSelectionChanged: () => {
        const selectedItems = collaborationSelection.getSelection() as CollaborationTableData[];

        if (selectedItems.includes(undefined)) {
          return;
        }

        const collaborations = [];

        selectedItems.forEach((si) => {
          const existingCollaboration = collaborationDetails.find((c) => c.collaboration.medium.id === si.id);
          if (existingCollaboration !== undefined) {
            collaborations.push(existingCollaboration);
            return;
          }

          collaborations.push(mapCollectionTableDataToCollection(si));
        });

        dispatch(setCollaborationsToAddPerPage(collaborations));
      },
      selectionMode: SelectionMode.multiple
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDismissButtonClick = () => dispatch(closeCreateContactWizard());

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-menu-create-contact.wizard-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        <CreateContactSteps />
        {currentStep === CreateContactWizardStep.GeneralInfo && <AddGeneralContactInfo />}
        {currentStep === CreateContactWizardStep.Collaboration && <AddContactCollaborationInfo collaborationSelection={collaborationSelection} />}
        {currentStep === CreateContactWizardStep.AdditionalInfo && <AdditionalContactInformation />}
        {currentStep === CreateContactWizardStep.Finish && <CreateContactFinish />}
      </div>
    </WizardWrap>
  );
};
