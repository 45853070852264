import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { MessageData, safe, safelyHandleError, setAlertData } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import {
  addContact,
  CollaborationWithContactId,
  CollaborationWithContactInfo,
  Contact,
  ContactsSortingInput,
  fetchContactsByQueryParamsPaged,
  MediumType,
  PageOfContacts
} from 'app/pages/my-audience/contacts';
import { addMediaOutlet, MediaOutlet, firstPageOfMediaOutletsRequested } from 'app/pages/my-audience/media-outlets';
import {
  addContactRequested,
  addedContact,
  addMediaOutletRequested,
  closeCreateMediaOutletWizard,
  pageOfContactsReceived,
  pageOfContactsRequested,
  selectCollaborationsDetails,
  selectContactSearchText,
  selectContactToAdd,
  selectMediaOutletToAdd,
  selectPageNumber,
  selectPageSize,
  setIsCreateMediaOutletFinishButtonDisabled,
  setIsLoadingAddingMediaOutlet
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { addCollaborations } from 'app/pages/my-audience/contact-profile';

export function* getContactSuggestionsMediaOutletRequestedWatcher() {
  yield takeLatest(pageOfContactsRequested.type, safe(getContactSuggestionsRequestedFlow));
}

function* getContactSuggestionsRequestedFlow() {
  const searchText = yield select(selectContactSearchText);

  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);

  const pageOfContacts: PageOfContacts = yield call(
    fetchContactsByQueryParamsPaged,
    client,
    searchText,
    [],
    pageNumber,
    pageSize,
    new ContactsSortingInput('name', 'ASC')
  );

  yield put(pageOfContactsReceived({ contacts: pageOfContacts.contacts, totalCountOfContacts: pageOfContacts.totalCount }));
}

function* addContactFlow() {
  const contactToAdd: Contact = yield select(selectContactToAdd);

  const contact = yield call(addContact, client, contactToAdd);
  yield put(addedContact(contact));
}

export function* addContactFromMediaOutletWizardRequestedWatcher() {
  yield takeEvery(addContactRequested.type, safe(addContactFlow));
}

function* addMediaOutletFlow() {
  const mediaOutletToAdd: MediaOutlet = yield select(selectMediaOutletToAdd);
  const collaborationsDetails: CollaborationWithContactInfo[] = yield select(selectCollaborationsDetails);

  const collaborationsWithContactIds = [];

  const mediaOutlet: MediaOutlet = yield call(addMediaOutlet, client, mediaOutletToAdd);

  for (let i = 0; i < collaborationsDetails.length; i++) {
    const newCollaboration = {
      ...collaborationsDetails[i].collaboration,
      medium: {
        id: mediaOutlet.id,
        name: mediaOutlet.name,
        type: MediumType.MediaOutlet
      }
    };

    collaborationsWithContactIds.push(new CollaborationWithContactId(collaborationsDetails[i].contactId, newCollaboration));
  }

  if (collaborationsWithContactIds.length) {
    yield call(addCollaborations, client, collaborationsWithContactIds);
  }

  yield put(setIsLoadingAddingMediaOutlet(false));
  yield put(closeCreateMediaOutletWizard());
  yield put(firstPageOfMediaOutletsRequested());
  yield put(setAlertData(new MessageData('alert-media-outlet-added')));
}

function* resetAddMediaOutletState() {
  yield put(setIsLoadingAddingMediaOutlet(false));
  yield put(setIsCreateMediaOutletFinishButtonDisabled(false));
}

export function* addMediaOutletWizardWatcher() {
  yield takeEvery(addMediaOutletRequested.type, safelyHandleError({ performAction: addMediaOutletFlow, onCatch: resetAddMediaOutletState }));
}
