import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Comment, CommentUser, MediumCategory, ProfilePicture, Tag, TagProjection } from 'app/common';
import { createContactsTableRows, CreateContactTableData } from 'app/pages/my-audience/common';
import { Company } from 'app/pages/my-audience/companies';
import { AddContactWizardSteps, CreateContactWizardSteps } from 'app/pages/my-audience/company-profile';
import { AddCollaboration, Collaboration, Contact, OwnContactData } from 'app/pages/my-audience/contacts';
import { RootState } from 'app/redux/store';
import { cloneDeep } from 'lodash';

export interface CompanyProfileState {
  isProfilePanelOpened: boolean;
  selectedCompany: Company;
  selectedCompanyId: string;
  isLoadingSelectedCompany: boolean;
  panelActiveIndex: number;
  commentToAdd: Comment;
  commentMentions: CommentUser[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToCompany: TagProjection;
  tagToRemoveFromCompany: TagProjection;
  isLoadingAllTags: boolean;
  imageToUpload: ProfilePicture;
  profilePicturePreviewUrl: string;
  companyToUpdate: Company;
  companyContacts: Contact[];
  isLoadingCompanyContacts: boolean;
  isLoadingWizardContacts: boolean;
  wizardContacts: Contact[];
  contactToAdd: Contact;
  collaborationToAdd: Collaboration;
  selectedContactForEditing: Contact;
  selectedContact: Contact;
  contactToRemove: Contact;
  contactToUpdate: Contact;
  oldRole: string;
  collaborationToUpdate: Collaboration;
  companyPreviousValue: Company;
  allCategories: MediumCategory[];
  isChangeImageDialogOpened: boolean;
  companyContactsPageNumber: number;
  companyContactsPageSize: number;
  companyContactsSearchText: string;
  companyContactsRoleFilter: string;
  companyContactsTotalCount: number;
  wizardContactsPageNumber: number;
  wizardContactsPageSize: number;
  wizardContactsSearchText: string;
  wizardContactsRoleFilter: string;
  wizardContactsTotalCount: number;
  isAddContectWizardOpened: boolean;
  addContactCurrentStep: AddContactWizardSteps.SearchContacts;
  isRemoveCollaborationDialogOpened: boolean;
  isEditCollaborationWizardOpened: boolean;
  isCreateContactWizardOpened: boolean;
  isCreateContactFinishButtonDisabled: boolean;
  isAddContactFinishButtonDisabled: boolean;
  createContactCurrentStep: CreateContactWizardSteps.CreateNew;
  ownContactDataToAdd: OwnContactData;
  isCollaborationsListOutdated: boolean;
}

const initialState: CompanyProfileState = {
  isProfilePanelOpened: false,
  selectedCompany: {} as Company,
  selectedCompanyId: '',
  isLoadingSelectedCompany: true,
  panelActiveIndex: 0,
  commentToAdd: {} as Comment,
  commentMentions: [],
  isLoadingAllTags: false,
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToCompany: {} as TagProjection,
  tagToRemoveFromCompany: {} as TagProjection,
  imageToUpload: {} as ProfilePicture,
  profilePicturePreviewUrl: '',
  companyToUpdate: {} as Company,
  companyContacts: [],
  isLoadingCompanyContacts: true,
  isLoadingWizardContacts: true,
  wizardContacts: [],
  contactToAdd: {} as Contact,
  collaborationToAdd: {} as Collaboration,
  selectedContactForEditing: {} as Contact,
  selectedContact: {} as Contact,
  contactToRemove: {} as Contact,
  contactToUpdate: {} as Contact,
  oldRole: '',
  collaborationToUpdate: {} as Collaboration,
  companyPreviousValue: {} as Company,
  allCategories: [],
  isChangeImageDialogOpened: false,
  companyContactsRoleFilter: '',
  companyContactsPageNumber: 1,
  companyContactsPageSize: 10,
  companyContactsSearchText: '',
  companyContactsTotalCount: 0,
  wizardContactsRoleFilter: '',
  wizardContactsPageNumber: 1,
  wizardContactsPageSize: 10,
  wizardContactsSearchText: '',
  wizardContactsTotalCount: 0,
  isAddContectWizardOpened: false,
  addContactCurrentStep: AddContactWizardSteps.SearchContacts,
  isRemoveCollaborationDialogOpened: false,
  isEditCollaborationWizardOpened: false,
  isCreateContactWizardOpened: false,
  isCreateContactFinishButtonDisabled: false,
  isAddContactFinishButtonDisabled: false,
  createContactCurrentStep: CreateContactWizardSteps.CreateNew,
  ownContactDataToAdd: {} as OwnContactData,
  isCollaborationsListOutdated: false
};

export type SelectedCompanySelector = (state: RootState) => Company;
export const selectSelectedCompany: SelectedCompanySelector = createSelector(
  [(state: RootState) => state.companyProfile.selectedCompany],
  (selectedCompany: Company) => selectedCompany
);

export type ContactsTableDataSelector = (state: RootState) => CreateContactTableData[];
export const selectContactsTableData: ContactsTableDataSelector = createSelector(
  [(state: RootState) => state.companyProfile.wizardContacts],
  (contacts: Contact[]) => createContactsTableRows(contacts)
);

export type IsProfilePanelOpenedSelector = (state: RootState) => boolean;
export const selectIsProfilePanelOpened: IsProfilePanelOpenedSelector = createSelector(
  [(state: RootState) => state.companyProfile.isProfilePanelOpened],
  (isProfilePanelOpened: boolean) => isProfilePanelOpened
);

export type SelectedCompanyCommentsSelector = (state: RootState) => Comment[];
export const selectSelectedCompanyComments: SelectedCompanyCommentsSelector = createSelector(
  [(state: RootState) => state.companyProfile.selectedCompany.userComments],
  (userComments: Comment[]) => userComments
);

export type IsLoadingCompanySelector = (state: RootState) => boolean;
export const selectIsLoadingSelectedCompany: IsLoadingCompanySelector = createSelector(
  [(state: RootState) => state.companyProfile.isLoadingSelectedCompany],
  (isLoadingSelectedCompany: boolean) => isLoadingSelectedCompany
);

export type PanelActiveIndexSelector = (state: RootState) => number;
export const selectPanelActiveIndex: PanelActiveIndexSelector = createSelector(
  [(state: RootState) => state.companyProfile.panelActiveIndex],
  (panelActiveIndex: number) => panelActiveIndex
);

export type CommentToAddToCompanySelector = (state: RootState) => Comment;
export const selectCommentToAddToCompany: CommentToAddToCompanySelector = createSelector(
  [(state: RootState) => state.companyProfile.commentToAdd],
  (commentToAdd: Comment) => commentToAdd
);

export type CommentMentionsSelector = (state: RootState) => CommentUser[];
export const selectCommentMentions: CommentMentionsSelector = createSelector(
  [(state: RootState) => state.companyProfile.commentMentions],
  (commentMentions: CommentUser[]) => commentMentions
);

export type SelectedCompanyIdSelector = (state: RootState) => string;
export const selectSelectedCompanyId: SelectedCompanyIdSelector = createSelector(
  [(state: RootState) => state.companyProfile.selectedCompanyId],
  (selectedCompanyId: string) => selectedCompanyId
);

export type SelectedCompanyTagsHeaderValueSelector = (state: RootState) => Tag[];
export const selectCompanyTagsHeaderValue: SelectedCompanyTagsHeaderValueSelector = createSelector(
  [(state: RootState) => state.companyProfile.selectedCompany.tags],
  (tags: Tag[]) => tags
);

export type TagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: TagNameToCreateSelector = createSelector(
  [(state: RootState) => state.companyProfile.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type TagsToDeleteSelector = (state: RootState) => Tag[];
export const selectTagsToDelete: TagsToDeleteSelector = createSelector(
  [(state: RootState) => state.companyProfile.tagsToDelete],
  (tagsToDelete: Tag[]) => tagsToDelete
);

export type TagToAddToCompanySelector = (state: RootState) => TagProjection;
export const selectTagToAddToCompany: TagToAddToCompanySelector = createSelector(
  [(state: RootState) => state.companyProfile.tagToAddToCompany],
  (tagToAddToCompany: TagProjection) => tagToAddToCompany
);

export type TagToRemoveFromCompanySelector = (state: RootState) => TagProjection;
export const selectTagToRemoveFromCompany: TagToRemoveFromCompanySelector = createSelector(
  [(state: RootState) => state.companyProfile.tagToRemoveFromCompany],
  (tagToRemoveFromCompany: TagProjection) => tagToRemoveFromCompany
);

export type ImageToUploadSelector = (state: RootState) => ProfilePicture;
export const selectImageToUpload: ImageToUploadSelector = createSelector(
  [(state: RootState) => state.companyProfile.imageToUpload],
  (imageToUpload: ProfilePicture) => imageToUpload
);

export type ProfilePicturePreviewUrlSelector = (state: RootState) => string;
export const selectProfilePicturePreviewUrl: ProfilePicturePreviewUrlSelector = createSelector(
  [(state: RootState) => state.companyProfile.profilePicturePreviewUrl],
  (profilePicturePreviewUrl: string) => profilePicturePreviewUrl
);

export type CompanyToUpdateSelector = (state: RootState) => Company;
export const selectCompanyToUpdate: CompanyToUpdateSelector = createSelector(
  [(state: RootState) => state.companyProfile.companyToUpdate],
  (companyToUpdate: Company) => companyToUpdate
);

export type ContactToAddSelector = (state: RootState) => Contact;
export const selectContactToAdd: ContactToAddSelector = createSelector(
  [(state: RootState) => state.companyProfile.contactToAdd],
  (contactToAdd: Contact) => contactToAdd
);

export type OwnContactDataToAddSelector = (state: RootState) => OwnContactData;
export const selectOwnContactDataToAdd: OwnContactDataToAddSelector = createSelector(
  [(state: RootState) => state.companyProfile.ownContactDataToAdd],
  (ownContactDataToAdd: OwnContactData) => ownContactDataToAdd
);

export type CollaborationToAddSelector = (state: RootState) => Collaboration;
export const selectCollaborationToAdd: CollaborationToAddSelector = createSelector(
  [(state: RootState) => state.companyProfile.collaborationToAdd],
  (collaborationToAdd: Collaboration) => collaborationToAdd
);

export type IsLoadingCompanyContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingCompanyContacts: IsLoadingCompanyContactsSelector = createSelector(
  [(state: RootState) => state.companyProfile.isLoadingCompanyContacts],
  (isLoadingCompanyContacts: boolean) => isLoadingCompanyContacts
);

export type CompanyContactsSelector = (state: RootState) => Contact[];
export const selectCompanyContacts: CompanyContactsSelector = createSelector(
  [(state: RootState) => state.companyProfile.companyContacts],
  (companyContacts: Contact[]) => companyContacts
);

export type SelectedContactForEditingSelector = (state: RootState) => Contact;
export const selectSelectedContactForEditing: SelectedContactForEditingSelector = createSelector(
  [(state: RootState) => state.companyProfile.selectedContactForEditing],
  (selectedContactForEditing: Contact) => selectedContactForEditing
);

export type ContactToRemoveSelector = (state: RootState) => Contact;
export const selectContactToRemove: ContactToRemoveSelector = createSelector(
  [(state: RootState) => state.companyProfile.contactToRemove],
  (contactToRemove: Contact) => contactToRemove
);

export type CompanyContactsPageNumberSelector = (state: RootState) => number;
export const selectCompanyContactsPageNumber: CompanyContactsPageNumberSelector = createSelector(
  [(state: RootState) => state.companyProfile.companyContactsPageNumber],
  (companyContactsPageNumber: number) => companyContactsPageNumber
);

export type CompanyContactsPageSizeSelector = (state: RootState) => number;
export const selectCompanyContactsPageSize: CompanyContactsPageSizeSelector = createSelector(
  [(state: RootState) => state.companyProfile.companyContactsPageSize],
  (companyContactsPageSize: number) => companyContactsPageSize
);

export type CompanyContactsRoleFilterSelector = (state: RootState) => string;
export const selectCompanyContactsRoleFilter: CompanyContactsRoleFilterSelector = createSelector(
  [(state: RootState) => state.companyProfile.companyContactsRoleFilter],
  (companyContactsRoleFilter: string) => companyContactsRoleFilter
);

export type CompanyContactsSearchTextSelector = (state: RootState) => string;
export const selectCompanyContactsSearchText: CompanyContactsSearchTextSelector = createSelector(
  [(state: RootState) => state.companyProfile.companyContactsSearchText],
  (companyContactsSearchText: string) => companyContactsSearchText
);

export type CompanyContactsTotalCountSelector = (state: RootState) => number;
export const selectCompanyContactsTotalCount: CompanyContactsTotalCountSelector = createSelector(
  [(state: RootState) => state.companyProfile.companyContactsTotalCount],
  (companyContactsTotalCount: number) => companyContactsTotalCount
);

export type IsLoadingWizardContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingWizardContacts: IsLoadingWizardContactsSelector = createSelector(
  [(state: RootState) => state.companyProfile.isLoadingWizardContacts],
  (isLoadingWizardContacts: boolean) => isLoadingWizardContacts
);

export type WizardContactsSelector = (state: RootState) => Contact[];
export const selectWizardContacts: WizardContactsSelector = createSelector(
  [(state: RootState) => state.companyProfile.wizardContacts],
  (wizardContacts: Contact[]) => wizardContacts
);

export type WizardContactsPageNumberSelector = (state: RootState) => number;
export const selectWizardContactsPageNumber: WizardContactsPageNumberSelector = createSelector(
  [(state: RootState) => state.companyProfile.wizardContactsPageNumber],
  (wizardContactsPageNumber: number) => wizardContactsPageNumber
);

export type WizardContactsPageSizeSelector = (state: RootState) => number;
export const selectWizardContactsPageSize: WizardContactsPageSizeSelector = createSelector(
  [(state: RootState) => state.companyProfile.wizardContactsPageSize],
  (wizardContactsPageSize: number) => wizardContactsPageSize
);

export type WizardContactsRoleFilterSelector = (state: RootState) => string;
export const selectWizardContactsRoleFilter: WizardContactsRoleFilterSelector = createSelector(
  [(state: RootState) => state.companyProfile.wizardContactsRoleFilter],
  (wizardContactsRoleFilter: string) => wizardContactsRoleFilter
);

export type WizardContactsSearchTextSelector = (state: RootState) => string;
export const selectWizardContactsSearchText: WizardContactsSearchTextSelector = createSelector(
  [(state: RootState) => state.companyProfile.wizardContactsSearchText],
  (wizardContactsSearchText: string) => wizardContactsSearchText
);

export type WizardContactsTotalCountSelector = (state: RootState) => number;
export const selectWizardContactsTotalCount: WizardContactsTotalCountSelector = createSelector(
  [(state: RootState) => state.companyProfile.wizardContactsTotalCount],
  (wizardContactsTotalCount: number) => wizardContactsTotalCount
);

export type SelectedContactSelector = (state: RootState) => Contact;
export const selectSelectedContact: SelectedContactSelector = createSelector(
  [(state: RootState) => state.companyProfile.selectedContact],
  (selectedContact: Contact) => selectedContact
);

export type SelectedCollaborationForEditingSelector = (state: RootState) => Collaboration;
export const selectSelectedCollaborationForEditing: SelectedCollaborationForEditingSelector = createSelector(
  [(state: RootState) => state.companyProfile.selectedContactForEditing.collaborations, (state: RootState) => state.companyProfile.selectedCompanyId],
  (collaborations: Collaboration[], selectedCompanyId: string) => collaborations.find((cd) => cd.medium.id === selectedCompanyId)
);

export type ContactToUpdateSelector = (state: RootState) => Contact;
export const selectContactToUpdate: ContactToUpdateSelector = createSelector(
  [(state: RootState) => state.companyProfile.contactToUpdate],
  (contactToUpdate: Contact) => contactToUpdate
);

export type CollaborationToUpdateSelector = (state: RootState) => Collaboration;
export const selectCollaborationToUpdate: CollaborationToUpdateSelector = createSelector(
  [(state: RootState) => state.companyProfile.collaborationToUpdate],
  (collaborationToUpdate: Collaboration) => collaborationToUpdate
);

export type AllCompanyCategoriesSelector = (state: RootState) => MediumCategory[];
export const selectAllCompanyCategories: AllCompanyCategoriesSelector = createSelector(
  [(state: RootState) => state.companyProfile.allCategories],
  (allCategories: MediumCategory[]) => allCategories
);

export type AllCompanyCategoriesNamesSelector = (state: RootState) => string[];
export const selectAllCompanyCategoriesNames: AllCompanyCategoriesNamesSelector = createSelector(
  [(state: RootState) => state.companyProfile.allCategories],
  (allCategories: MediumCategory[]) => allCategories.map((c) => c.name)
);

export type IsChangeImageDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsChangeImageDialogOpened: IsChangeImageDialogOpenedSelector = createSelector(
  [(state: RootState) => state.companyProfile.isChangeImageDialogOpened],
  (isChangeImageDialogOpened: boolean) => isChangeImageDialogOpened
);

export type IsAddContectWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsAddContectWizardOpened: IsAddContectWizardOpenedSelector = createSelector(
  [(state: RootState) => state.companyProfile.isAddContectWizardOpened],
  (isAddContectWizardOpened: boolean) => isAddContectWizardOpened
);

export type AddContactCurrentStepSelector = (state: RootState) => AddContactWizardSteps;
export const selectAddContactCurrentStep: AddContactCurrentStepSelector = createSelector(
  [(state: RootState) => state.companyProfile.addContactCurrentStep],
  (addContactCurrentStep: AddContactWizardSteps) => addContactCurrentStep
);

export type IsRemoveCollaborationDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsRemoveCollaborationDialogOpened: IsRemoveCollaborationDialogOpenedSelector = createSelector(
  [(state: RootState) => state.companyProfile.isRemoveCollaborationDialogOpened],
  (isRemoveCollaborationDialogOpened: boolean) => isRemoveCollaborationDialogOpened
);

export type IsEditCollaborationWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsEditCollaborationWizardOpened: IsEditCollaborationWizardOpenedSelector = createSelector(
  [(state: RootState) => state.companyProfile.isEditCollaborationWizardOpened],
  (isEditCollaborationWizardOpened: boolean) => isEditCollaborationWizardOpened
);

export type IsCreateContactWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateContactWizardOpened: IsCreateContactWizardOpenedSelector = createSelector(
  [(state: RootState) => state.companyProfile.isCreateContactWizardOpened],
  (isCreateContactWizardOpened: boolean) => isCreateContactWizardOpened
);

export type CreateContactCurrentStepSelector = (state: RootState) => CreateContactWizardSteps;
export const selectCreateContactCurrentStep: CreateContactCurrentStepSelector = createSelector(
  [(state: RootState) => state.companyProfile.createContactCurrentStep],
  (createContactCurrentStep: CreateContactWizardSteps) => createContactCurrentStep
);

export type IsCreateContactFinishButtonDisabled = (state: RootState) => boolean;
export const selectIsCreateContactFinishButtonDisabled: IsCreateContactFinishButtonDisabled = createSelector(
  [(state: RootState) => state.companyProfile.isCreateContactFinishButtonDisabled],
  (isCreateContactFinishButtonDisabled: boolean) => isCreateContactFinishButtonDisabled
);

export type IsAddContactFinishButtonDisabled = (state: RootState) => boolean;
export const selectIsAddContactFinishButtonDisabled: IsAddContactFinishButtonDisabled = createSelector(
  [(state: RootState) => state.companyProfile.isAddContactFinishButtonDisabled],
  (isAddContactFinishButtonDisabled: boolean) => isAddContactFinishButtonDisabled
);

export type IsCollaborationsListOutdatedSelector = (state: RootState) => boolean;
export const selectIsCollaborationsListOutdated: IsCollaborationsListOutdatedSelector = createSelector(
  [(state: RootState) => state.companyProfile.isCollaborationsListOutdated],
  (isCollaborationsListOutdated: boolean) => isCollaborationsListOutdated
);

const companyProfileSlice = createSlice({
  name: 'company-profile',
  initialState,
  reducers: {
    openProfilePanel: (state) => {
      state.isProfilePanelOpened = true;
    },
    closeProfilePanel: (state) => {
      state.isProfilePanelOpened = false;
    },
    fetchSelectedCompanyRequested: (state, action: PayloadAction<string>) => {
      state.isLoadingSelectedCompany = true;
      state.selectedCompanyId = action.payload;
      state.profilePicturePreviewUrl = state.selectedCompany.profilePictureUrl;
    },
    resetCompanyCollaboration: (state) => {
      state.selectedCompany = {} as Company;
      state.profilePicturePreviewUrl = '';
      state.isLoadingSelectedCompany = false;
    },
    getSelectedCompanyRequested: (state, action: PayloadAction<string>) => {
      state.selectedCompanyId = action.payload;
      state.isLoadingSelectedCompany = true;
    },
    selectedCompanyReceived: (state, action: PayloadAction<Company>) => {
      state.selectedCompany = action.payload;
      state.isLoadingSelectedCompany = false;
      state.profilePicturePreviewUrl = action.payload.profilePictureUrl;
    },
    resetToInitialState: (state) => {
      state = initialState;
    },
    setPanelActiveIndex: (state, action: PayloadAction<number>) => {
      state.panelActiveIndex = action.payload;
    },
    addCommentToCompanyRequested: (state, action: PayloadAction<Comment>) => {
      state.commentToAdd = action.payload;
    },
    addedCommentToCompany: (state, action: PayloadAction<Comment>) => {
      state.selectedCompany.userComments = [...state.selectedCompany.userComments, action.payload];
      state.commentToAdd = {} as Comment;
      state.commentMentions = [];
    },
    addCommentMentionRequested: (state, action: PayloadAction<CommentUser>) => {
      if (state.commentMentions.length > 0) {
        state.commentMentions = [...state.commentMentions, action.payload];
      } else {
        state.commentMentions = [action.payload];
      }
    },
    getAllTagsRequested: (state) => {
      state.isLoadingAllTags = true;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.selectedCompany.tags = [...state.selectedCompany.tags, action.payload];
      state.tagNameToCreate = '';
    },
    tagsDeleted: (state, action: PayloadAction<string[]>) => {
      state.selectedCompany.tags = state.selectedCompany.tags.filter((tag) => !action.payload.includes(tag.id));
      state.tagsToDelete = [];
    },
    addedTagToCompany: (state, action: PayloadAction<TagProjection>) => {
      state.tagToAddToCompany = {} as TagProjection;
      if (!state.selectedCompany.tags?.every((tag) => tag.id !== action.payload.id)) return;
      state.selectedCompany.tags = [...state.selectedCompany.tags, action.payload];
    },
    removeTagFromCompanyInStore: (state, action: PayloadAction<TagProjection>) => {
      state.companyPreviousValue = cloneDeep(state.selectedCompany);
      state.selectedCompany.tags = state.selectedCompany.tags.filter((tag) => tag.id !== action.payload.id);
    },
    removedTagsFromCompanies: (state, action: PayloadAction<string[]>) => {
      state.tagToRemoveFromCompany = {} as TagProjection;
      if (!state.selectedCompany.tags) return;
      state.selectedCompany = { ...state.selectedCompany, tags: state.selectedCompany.tags.filter((tag) => !action.payload.includes(tag.id)) };
    },
    removeProfilePictureRequested: (state) => {
      state.imageToUpload = {} as ProfilePicture;
    },
    updateProfilePictureRequested: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
    },
    profilePictureUpdated: (state, action: PayloadAction<string>) => {
      state.selectedCompany = { ...state.selectedCompany, profilePictureUrl: action.payload };
      state.profilePicturePreviewUrl = action.payload;
      state.imageToUpload = {} as ProfilePicture;
    },
    removeProfilePicturePreview: (state) => {
      state.profilePicturePreviewUrl = '';
      state.imageToUpload = {} as ProfilePicture;
    },
    changeProfilePictureToUpload: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
      state.profilePicturePreviewUrl = state.selectedCompany.profilePictureUrl;
    },
    updateCompanyRequested: (state, action: PayloadAction<Company>) => {
      state.companyToUpdate = action.payload;
      state.selectedCompany = action.payload;
    },
    updatedCompany: (state, action: PayloadAction<Company>) => {
      state.selectedCompany = action.payload;
      state.companyToUpdate = {} as Company;
    },
    resetSelectedCompany: (state) => {
      state.selectedCompany = {} as Company;
    },
    pageOfCompanyContactsRequested: (state) => {
      state.isLoadingCompanyContacts = true;
      state.companyContactsTotalCount = 0;
    },
    pageOfCompanyContactsReceived: (state, action: PayloadAction<{ contacts: Contact[]; totalCountOfContacts: number }>) => {
      state.isLoadingCompanyContacts = false;
      state.companyContacts = action.payload.contacts;
      state.companyContactsTotalCount = action.payload.totalCountOfContacts;
      state.isCollaborationsListOutdated = false;
    },
    setSearchCompanyContactsTextAndFilterItem: (state, action: PayloadAction<{ searchText: string; role: string }>) => {
      state.companyContactsSearchText = action.payload.searchText;
      state.companyContactsRoleFilter = action.payload.role;
    },
    setCompanyContactsPageNumber: (state, action: PayloadAction<number>) => {
      state.companyContactsPageNumber = action.payload;
    },
    setCompanyContactsPageSize: (state, action: PayloadAction<number>) => {
      state.companyContactsPageSize = action.payload;
      state.companyContactsPageNumber = 1;
    },
    setTotalCountOfCompanyContacts: (state, action: PayloadAction<number>) => {
      state.companyContactsTotalCount = action.payload;
    },
    pageOfWizardContactsRequested: (state) => {
      state.isLoadingWizardContacts = true;
      state.wizardContactsTotalCount = 0;
    },
    pageOfWizardContactsReceived: (state, action: PayloadAction<{ contacts: Contact[]; totalCountOfContacts: number }>) => {
      state.isLoadingWizardContacts = false;
      state.wizardContacts = action.payload.contacts.filter((contact) => !state.companyContacts.find((companyContact) => companyContact.id === contact.id));
      state.wizardContactsTotalCount = action.payload.totalCountOfContacts;
    },
    setSearchWizardContactsTextAndFilterItem: (state, action: PayloadAction<{ searchText: string; role: string }>) => {
      state.wizardContactsSearchText = action.payload.searchText;
      state.wizardContactsRoleFilter = action.payload.role;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.wizardContactsPageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.wizardContactsPageSize = action.payload;
      state.wizardContactsPageNumber = 1;
    },
    setTotalCountOfWizardContacts: (state, action: PayloadAction<number>) => {
      state.wizardContactsTotalCount = action.payload;
    },
    addedContact: (state, action: PayloadAction<Contact>) => {
      state.companyContacts = [...state.companyContacts, action.payload];
      state.contactToAdd = {} as Contact;
      state.collaborationToAdd = {} as Collaboration;
      state.ownContactDataToAdd = {} as OwnContactData;
      state.companyContactsTotalCount += 1;
    },
    addContactRequested: (state, action: PayloadAction<Contact>) => {
      state.contactToAdd = action.payload;
    },
    setSelectedContactForEditing: (state, action: PayloadAction<Contact>) => {
      state.selectedContactForEditing = action.payload;
    },
    setSelectedContact: (state, action: PayloadAction<string>) => {
      state.selectedContact = state.wizardContacts.find((c) => c.id === action.payload);
    },
    setContactToRemove: (state, action: PayloadAction<Contact>) => {
      state.contactToRemove = action.payload;
    },
    removeContributionRequested: (state, action: PayloadAction<Contact>) => {
      state.contactToRemove = action.payload;
    },
    removedContribution: (state) => {
      state.contactToRemove = {} as Contact;
      state.companyContactsTotalCount -= 1;
    },
    removeContributionFromStore: (state, action: PayloadAction<Contact>) => {
      state.companyContacts = state.companyContacts.filter((c) => c.id !== action.payload.id);
    },
    undoRemoveContributionFromStore: (state, action: PayloadAction<Contact>) => {
      state.companyContacts = [...state.companyContacts, action.payload];
    },
    resetSelectedContactForEditing: (state) => {
      state.selectedContactForEditing = {} as Contact;
    },
    setContactToAdd: (state, action: PayloadAction<Contact>) => {
      state.contactToAdd = action.payload;
    },
    resetContactToAdd: (state) => {
      state.contactToAdd = {} as Contact;
      state.collaborationToAdd = {} as Collaboration;
      state.ownContactDataToAdd = {} as OwnContactData;
      state.selectedContact = {} as Contact;
    },
    setCollaborationToAdd: (state, action: PayloadAction<Collaboration>) => {
      state.collaborationToAdd = action.payload;
    },
    setOwnContactDataToAdd: (state, action: PayloadAction<OwnContactData>) => {
      state.ownContactDataToAdd = action.payload;
    },
    addContributionRequested: (state, action: PayloadAction<AddCollaboration>) => {
      state.collaborationToAdd = action.payload.collaboration;
      state.selectedContact = action.payload.contact;
    },
    updateCollaborationRequested: (state, action: PayloadAction<{ contact: Contact; collaboration: Collaboration }>) => {
      state.contactToUpdate = action.payload.contact;
      state.collaborationToUpdate = action.payload.collaboration;

      const contactIndex = state.companyContacts.findIndex((contact) => contact.id === state.contactToUpdate.id);
      const collaborationIndex = state.companyContacts[contactIndex].collaborations.findIndex((cd) => cd.medium.id === state.selectedCompanyId);
      state.oldRole = state.companyContacts[contactIndex].collaborations[collaborationIndex].jobTitle?.name;
    },
    updateCollaborationReceived: (state, action: PayloadAction<Contact>) => {
      const contactIndex = state.companyContacts.findIndex((contact) => contact.id === state.contactToUpdate.id);
      state.companyContacts[contactIndex] = action.payload;
      state.selectedContactForEditing = {} as Contact;
    },
    addedContribution: (state) => {
      state.collaborationToAdd = {} as Collaboration;
      state.selectedContact = {} as Contact;
      state.isCollaborationsListOutdated = true;
    },
    addContributionToStore: (state, action: PayloadAction<Contact>) => {
      state.companyContacts = [...state.companyContacts, action.payload];
      state.selectedContact = action.payload;
      state.companyContactsTotalCount += 1;
    },
    undoAddContributionToStore: (state, action: PayloadAction<string>) => {
      state.companyContacts = state.companyContacts.filter((c) => c.id !== action.payload);
      state.companyContactsTotalCount -= 1;
      state.collaborationToAdd = {} as Collaboration;
      state.selectedContact = {} as Contact;
    },
    resetSelectedCompanyAfterUndo: (state) => {
      state.selectedCompany = cloneDeep(state.selectedCompany);
    },
    getAllCompanyCategoriesRequested: () => {},
    allCompanyCategoriesReceived: (state, action: PayloadAction<MediumCategory[]>) => {
      state.allCategories = action.payload;
    },
    openChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = true;
    },
    closeChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = false;
    },
    openAddContactWizard: (state) => {
      state.collaborationToAdd = {} as Collaboration;
      state.isAddContectWizardOpened = true;
      state.addContactCurrentStep = AddContactWizardSteps.SearchContacts;
      state.isAddContactFinishButtonDisabled = false;
      state.selectedContact = {} as Contact;
    },
    closeAddContactWizard: (state) => {
      state.isAddContectWizardOpened = false;
      state.addContactCurrentStep = AddContactWizardSteps.SearchContacts;
    },
    openRemoveCollaborationDialog: (state) => {
      state.isRemoveCollaborationDialogOpened = true;
    },
    closeRemoveCollaborationDialog: (state) => {
      state.isRemoveCollaborationDialogOpened = false;
    },
    openEditCollaborationWizard: (state) => {
      state.isEditCollaborationWizardOpened = true;
    },
    closeEditCollaborationWizard: (state) => {
      state.isEditCollaborationWizardOpened = false;
    },
    openCreateContactWizard: (state) => {
      state.contactToAdd = {} as Contact;
      state.isCreateContactWizardOpened = true;
      state.createContactCurrentStep = CreateContactWizardSteps.CreateNew;
      state.isCreateContactFinishButtonDisabled = false;
    },
    closeCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = false;
    },
    goToStepAddContact: (state, action: PayloadAction<number>) => {
      state.addContactCurrentStep = action.payload;
    },
    goToNextStepAddContact: (state) => {
      state.addContactCurrentStep = state.addContactCurrentStep + 1;
    },
    goToPreviousStepAddContact: (state) => {
      state.addContactCurrentStep = state.addContactCurrentStep - 1;
    },
    goToStepCreateContact: (state, action: PayloadAction<number>) => {
      state.createContactCurrentStep = action.payload;
    },
    goToNextStepCreateContact: (state) => {
      state.createContactCurrentStep = state.createContactCurrentStep + 1;
    },
    goToPreviousStepCreateContact: (state) => {
      state.createContactCurrentStep = state.createContactCurrentStep - 1;
    },
    disableCreateContactFinishButton: (state) => {
      state.isCreateContactFinishButtonDisabled = true;
    },
    disableAddContactFinishButton: (state) => {
      state.isAddContactFinishButtonDisabled = true;
    }
  }
});

export const {
  openProfilePanel,
  closeProfilePanel,
  resetCompanyCollaboration,
  resetToInitialState,
  setPanelActiveIndex,
  addCommentMentionRequested,
  addCommentToCompanyRequested,
  addedCommentToCompany,
  changeProfilePictureToUpload,
  removeProfilePicturePreview,
  profilePictureUpdated,
  updateProfilePictureRequested,
  removeProfilePictureRequested,
  removedTagsFromCompanies,
  addedTagToCompany,
  addedNewTag,
  getAllTagsRequested,
  updateCompanyRequested,
  updatedCompany,
  selectedCompanyReceived,
  fetchSelectedCompanyRequested,
  resetSelectedCompany,
  addedContact,
  addContactRequested,
  setSelectedContactForEditing,
  setSelectedContact,
  setContactToRemove,
  removeContributionRequested,
  removedContribution,
  removeContributionFromStore,
  undoRemoveContributionFromStore,
  resetSelectedContactForEditing,
  setContactToAdd,
  resetContactToAdd,
  setCollaborationToAdd,
  addContributionRequested,
  updateCollaborationRequested,
  updateCollaborationReceived,
  addedContribution,
  addContributionToStore,
  undoAddContributionToStore,
  resetSelectedCompanyAfterUndo,
  removeTagFromCompanyInStore,
  tagsDeleted,
  allCompanyCategoriesReceived,
  getAllCompanyCategoriesRequested,
  getSelectedCompanyRequested,
  openChangeImageDialog,
  closeChangeImageDialog,
  setTotalCountOfCompanyContacts,
  setTotalCountOfWizardContacts,
  openAddContactWizard,
  closeAddContactWizard,
  openRemoveCollaborationDialog,
  closeRemoveCollaborationDialog,
  openEditCollaborationWizard,
  closeEditCollaborationWizard,
  openCreateContactWizard,
  closeCreateContactWizard,
  goToStepAddContact,
  goToNextStepAddContact,
  goToPreviousStepAddContact,
  goToStepCreateContact,
  goToNextStepCreateContact,
  goToPreviousStepCreateContact,
  disableCreateContactFinishButton,
  pageOfWizardContactsReceived,
  pageOfWizardContactsRequested,
  setPageNumber,
  setSearchWizardContactsTextAndFilterItem,
  disableAddContactFinishButton,
  pageOfCompanyContactsReceived,
  pageOfCompanyContactsRequested,
  setCompanyContactsPageNumber,
  setSearchCompanyContactsTextAndFilterItem,
  setCompanyContactsPageSize,
  setPageSize,
  setOwnContactDataToAdd
} = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
