import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ProfilePanelWrap, useAppDispatch, useAppSelector } from 'app/common';
import {
  MediaOutletComments,
  ContactsTab,
  MediaOutletProfilePanelIntro,
  MediaOutletPivotItems,
  ProfileAccordion,
  selectIsLoadingSelectedMediaOutlet,
  selectIsProfilePanelOpened,
  closeProfilePanel
} from 'app/pages/my-audience/media-outlet-profile';

export const MediaOutletPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isProfilePanelOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const isLoadingSelectedMediaOutlet = useAppSelector<boolean>(selectIsLoadingSelectedMediaOutlet);

  const handleDismissButtonClick = () => {
    dispatch(closeProfilePanel());
    navigate(`/my-audience/media-outlets/results/${params.mediaOutletsQuery}`);
  };

  return (
    <ProfilePanelWrap isProfilePanelOpened={isProfilePanelOpened} onDismissButtonClick={handleDismissButtonClick} classModifier="media-outlet">
      {!isLoadingSelectedMediaOutlet && (
        <div className="profile-panel-container">
          <MediaOutletProfilePanelIntro />
          <MediaOutletPivotItems />
          <div className="profile-panel-main">
            <Routes>
              <Route path="profile" element={<ProfileAccordion />} />
              <Route path="contacts" element={<ContactsTab />} />
              <Route path="comments" element={<MediaOutletComments />} />
            </Routes>
          </div>
        </div>
      )}
    </ProfilePanelWrap>
  );
};
