import React, { SyntheticEvent } from 'react';
import { Checkbox, CheckboxProps, ShorthandValue, TextProps } from '@fluentui/react-northstar';
import { TableHeader, selectIsOnDesktop, useAppSelector } from 'app/common';

interface NewShowOrHideColumnsPopupProps {
  tableHeader: TableHeader[];
  onUpdateTableHeader: (updatedHeader: TableHeader[]) => void;
  fixedColumnIndex: number;
}

export const NewShowOrHideColumnsPopup = ({ tableHeader, onUpdateTableHeader, fixedColumnIndex }: NewShowOrHideColumnsPopupProps) => {
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const updateVisibleItemsInTableHeader = (index: number, fieldName: ShorthandValue<TextProps>, isChecked: boolean | 'mixed') => {
    const changedHeader = { ...tableHeader[index], isColumnVisible: isChecked !== 'mixed' ? isChecked : false };
    const tableHeaderCopy = [...tableHeader];
    tableHeaderCopy.splice(index, 1, changedHeader);
    const filteredTableHeader = tableHeaderCopy.filter((header) => header.fieldName !== 'add');
    onUpdateTableHeader(filteredTableHeader);
  };

  return (
    <div className="popup-custom-wrap">
      {isOnDesktop && (
        <div className="popup-custom">
          {tableHeader.map(
            (item: TableHeader, index: number) =>
              item.fieldName !== 'add' &&
              index !== fixedColumnIndex && (
                <Checkbox
                  key={index}
                  label={item.name}
                  checked={item.isColumnVisible}
                  onChange={(_: SyntheticEvent<HTMLElement>, checkboxProps: CheckboxProps) =>
                    updateVisibleItemsInTableHeader(index, checkboxProps.label, checkboxProps.checked)
                  }
                />
              )
          )}
        </div>
      )}
    </div>
  );
};
