import { ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

interface LongLabelProps {
  value: string;
  maximumLength: number;
}

export const LongTitle = ({ value, maximumLength }: LongLabelProps) => {
  const tooltipId = useId('tooltip');

  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
  const calloutProps = { gapSpace: 0 };

  const shorterValue = value.length > maximumLength ? `${value.substring(0, maximumLength)}...` : value;

  return (
    <TooltipHost content={value} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
      <div aria-describedby={tooltipId}>{shorterValue}</div>
    </TooltipHost>
  );
};
