import { useRef, useState } from 'react';
import { Icon } from '@fluentui/react';
import { Button, FilterIcon, Input, Pill } from '@fluentui/react-northstar';
import { FilterItem, debounceTimeInMilliseconds, useAppDispatch, useAppSelector, selectIsOnMobile } from 'app/common';
import { useDebouncedCallback } from 'use-debounce';
import {
  firstPageOfSendingsRequested,
  selectFilterItems,
  selectSelectedSendingsIdsCount,
  selectSearchText,
  SendingStatus,
  setSearchText
} from 'app/pages/my-activities/sendings';
import { useIntl } from 'app/i18n';
import './TableFilters.scss';
import { selectIsProfilePanelOpen } from 'app/pages/my-activities/sending-wizard';
import { useOnClickOutside } from 'usehooks-ts';

interface TableFilterProps {
  filterSendingsByStatus: (status: SendingStatus) => void;
  resetFilters: () => void;
  showFavorites: () => void;
  openFiltersPanel: () => void;
}

export const TableQuickFilters = ({ filterSendingsByStatus, resetFilters, showFavorites, openFiltersPanel }: TableFilterProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const ref = useRef(null);
  const [expandedFilterInput, setExpandedFilterInput] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const selectedSendingsIdsCount = useAppSelector<number>(selectSelectedSendingsIdsCount);
  const regularFilterItems = useAppSelector<FilterItem[]>(selectFilterItems);
  const isSendingProfilePanelOpened = useAppSelector<boolean>(selectIsProfilePanelOpen);
  const areFiltersVisible = (!selectedSendingsIdsCount || isSendingProfilePanelOpened) && regularFilterItems.length === 0;
  const searchText = useAppSelector(selectSearchText);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);

  const handleExpandedInput = () => {
    setExpandedFilterInput(false);
  };
  useOnClickOutside(ref, handleExpandedInput);

  const quickFilterItems = [
    {
      name: formatMessage({ id: 'emailings-table.filters.all' }),
      icon: 'Clock',
      key: 'quick-filter-all',
      onClick: () => resetFilters(),
      class: `table-filter-pill table-filter-pill--big ${activeIndex === 0 ? 'active' : ''}`
    },
    {
      name: formatMessage({ id: 'emailings-table.filters.favorites' }),
      icon: 'FavoriteStar',
      key: 'quick-filter-star',
      onClick: () => showFavorites(),
      class: `table-filter-pill table-filter-pill--big ${activeIndex === 1 ? 'active' : ''}`
    },
    {
      name: formatMessage({ id: 'emailings-table.filters.draft' }),
      icon: 'MailAlert',
      key: 'quick-filter-draft',
      onClick: () => filterSendingsByStatus(SendingStatus.Draft),
      class: `table-filter-pill ${activeIndex === 2 ? 'active' : ''}`
    },
    {
      name: formatMessage({ id: 'emailings-table.filters.successful-distribution' }),
      icon: 'MailForward',
      key: 'quick-filter-sent',
      onClick: () => filterSendingsByStatus(SendingStatus.SuccessfulDistribution),
      class: `table-filter-pill ${activeIndex === 3 ? 'active' : ''}`
    },
    {
      name: formatMessage({ id: 'emailings-table.filters.ongoing-distribution' }),
      icon: 'MailForward',
      key: 'quick-filter-being-sent',
      onClick: () => filterSendingsByStatus(SendingStatus.OngoingDistribution),
      class: `table-filter-pill ${activeIndex === 4 ? 'active' : ''}`
    },
    {
      name: formatMessage({ id: 'emailings-table.filters.waiting-for-distribution' }),
      icon: 'MailTentative',
      key: 'quick-filter-being-pending',
      onClick: () => filterSendingsByStatus(SendingStatus.WaitingForDistribution),
      class: `table-filter-pill ${activeIndex === 5 ? 'active' : ''}`
    }
  ];

  const onInputChange = (searchText = '') => {
    dispatch(setSearchText(searchText));
    fetchSendingsBySearchText();
  };

  const fetchSendingsBySearchText = useDebouncedCallback(() => dispatch(firstPageOfSendingsRequested()), debounceTimeInMilliseconds);

  return (
    <>
      {areFiltersVisible && (
        <div className={`table-filters-wrap ${isSendingProfilePanelOpened ? 'hidden' : ''}`}>
          <div className="table-filters-item">
            {!isOnMobile && (
              <div className="table-filters-quick-filter">
                {quickFilterItems.map((item, index) => (
                  <div className="table-filter-pill-wrap" key={item.key + '-wrap'} onClick={() => setActiveIndex(index)}>
                    <Pill className={item.class} key={item.key} selectable icon={<Icon iconName={item.icon} />} onClick={item.onClick}>
                      {item.name}
                    </Pill>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="table-filters-item">
            <div className="table-filters-button-wrap">
              <Button
                tinted
                title={formatMessage({ id: 'filters.filter' })}
                content={formatMessage({ id: 'filters.filter' })}
                icon={<FilterIcon />}
                className="table-filters-button"
                onClick={openFiltersPanel}
              />
            </div>
            <div className="table-filters-input-wrap" ref={ref}>
              <Input
                clearable
                value={searchText}
                onChange={(_, data) => onInputChange(data.value)}
                icon={<Icon iconName="Search" />}
                onClick={() => setExpandedFilterInput(true)}
                placeholder={formatMessage({ id: 'buttons.find' })}
                className={expandedFilterInput ? 'table-filters-input expanded' : 'table-filters-input'}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
