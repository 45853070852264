import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectSelectedAadContactsIds, selectSelectedAadContactsLength } from 'app/pages/my-audience/lists-profile';

interface AddContactsFromCompanyTableToolbarProps {
  removeContactsFromTable: (contactIds: string[]) => void;
}

export const AddContactsFromCompanyTableToolbar = ({ removeContactsFromTable }: AddContactsFromCompanyTableToolbarProps) => {
  const { formatMessage } = useIntl();

  const selectedContactsIds = useAppSelector<string[]>(selectSelectedAadContactsIds);
  const selectedContactsLength = useAppSelector<number>(selectSelectedAadContactsLength);

  const handleClickOnRemoveButton = () => {
    removeContactsFromTable(selectedContactsIds);
  };

  return (
    <div className="table-toolbar">
      <div className="table-toolbar-column">
        <div className="table-toolbar-content">
          <Button
            disabled={selectedContactsLength === 0}
            content={formatMessage({ id: 'list-wizards.add-contact-remove-button' })}
            text
            icon={<Icon iconName="Clear" />}
            className="table-toolbar-action-button"
            onClick={handleClickOnRemoveButton}
          />
        </div>
      </div>
      {selectedContactsLength !== 0 && (
        <div className="table-toolbar-column">
          <span className="table-toolbar-text">
            {selectedContactsLength === 1 ? selectedContactsLength + ' contact' : selectedContactsLength + ' contacts'}
          </span>
        </div>
      )}
    </div>
  );
};
