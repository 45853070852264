import { Collaboration, Contact, OwnContactData } from 'app/pages/my-audience/contacts';
import { selectSelectedContactData, selectSelectedContact } from 'app/pages/my-audience/contact-profile';
import { LongTitle, maximumProfileNameLength, printData, useAppSelector } from 'app/common';

export const ContactProfileIntroInfo = () => {
  const selectedContactData = useAppSelector<Collaboration | OwnContactData>(selectSelectedContactData);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);

  return (
    <div className="panel-intro-info">
      <h4 className="panel-intro-title">
        <LongTitle value={printData(`${selectedContact.firstName} ${selectedContact.lastName}`)} maximumLength={maximumProfileNameLength} />
      </h4>
      {selectedContactData instanceof Collaboration && (
        <span className="panel-info-description">
          {selectedContactData?.jobTitle?.name
            ? `${selectedContactData?.jobTitle?.name} at ${selectedContactData?.medium?.name}`
            : selectedContactData?.medium?.name}
        </span>
      )}
    </div>
  );
};
