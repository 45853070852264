import { Button, Input, SearchIcon } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react';
import { DisableUnauthorized, SelectedEntitiesLabel, debounceTimeInMilliseconds, useAppDispatch, useAppSelector } from 'app/common';
import {
  openCreateContactWizard,
  pageOfContactsRequested,
  selectCollaborationsDetails,
  setSearchContactsText
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { useDebouncedCallback } from 'use-debounce';
import { useIntl } from 'app/i18n';
import { Permission } from 'auth';
import { useEffect } from 'react';

export const CollaborationTableToolbar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const collaborationDetails = useAppSelector(selectCollaborationsDetails);

  useEffect(() => {
    return () => {
      dispatch(setSearchContactsText(''));
    };
  }, [dispatch]);

  const handleInputChange = useDebouncedCallback((searchText: string) => {
    dispatch(setSearchContactsText(searchText));
    dispatch(pageOfContactsRequested());
  }, debounceTimeInMilliseconds);

  return (
    <>
      <div className="wizard-panel-toolbar">
        <div className="wizard-panel-toolbar-create">
          <DisableUnauthorized permissions={[Permission.ManageContacts, Permission.ManageCompanies]} requireAllPermissions={true}>
            <Button
              text
              icon={<Icon iconName="Add" />}
              content={formatMessage({ id: 'add-menu-create-mo.collaboration-add-btn' })}
              onClick={() => {
                dispatch(openCreateContactWizard());
              }}
            />
          </DisableUnauthorized>
        </div>
        <SelectedEntitiesLabel counter={collaborationDetails.length} />
        <div className="wizard-panel-toolbar-filters">
          <div className="wizard-panel-toolbar-input">
            <Input
              clearable
              icon={<SearchIcon />}
              placeholder={formatMessage({ id: 'add-menu-create-mo.collaboration-search-label' })}
              autoComplete="off"
              onChange={(_, data) => handleInputChange(data.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
