import React from 'react';
import { Permission, useAuthContext } from 'auth';

interface Props {
  permissions: Permission[];
  requireAllPermissions?: boolean;
  children: React.ReactElement<{ disabled: boolean }>;
}

export const DisableUnauthorized = ({ permissions, children, requireAllPermissions = false }: Props) => {
  const { hasAllPermissions, hasAnyPermissions } = useAuthContext();
  const hasPermissions = requireAllPermissions ? hasAllPermissions : hasAnyPermissions;
  const isDisabled = children.props.disabled || !hasPermissions(permissions);

  return { ...children, props: { ...children.props, disabled: isDisabled } };
};
