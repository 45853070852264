import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { Menu, MenuItemProps, ShorthandValue } from '@fluentui/react-northstar';
import {
  CreateMenu,
  FilterItem,
  MAIN_ROUTES,
  MyAudienceTabs,
  NavMenuItem,
  selectIsNavigationCollapsed,
  selectIsOnDesktop,
  useAppDispatch,
  useAppSelector,
  useActiveMenuItemClass,
  getAllJobTitlesRequested,
  openFlatFile
} from 'app/common';
import { firstPageOfSavedSearchesRequested as companiesFirstPageOfSavedSearchesRequested } from 'app/pages/my-audience/companies-saved-searches';
import { firstPageOfSavedSearchesRequested as contactsFirstPageOfSavedSearchesRequested } from 'app/pages/my-audience/contacts-saved-searches';
import { firstPageOfSavedSearchesRequested as mediaOutletsFirstPageOfSavedSearchesRequested } from 'app/pages/my-audience/media-outlets-saved-searches';
import { firstPageOfMediaOutletsRequested } from 'app/pages/my-audience/media-outlets';
import { openCreateCompanyWizard } from 'app/pages/my-audience/wizard-create-company';
import { openCreateContactWizard } from 'app/pages/my-audience/wizard-create-contact';
import { openCreateListWizard } from 'app/pages/my-audience/wizard-create-list';
import { openCreateMediaOutletWizard } from 'app/pages/my-audience/wizard-create-media-outlet';
import { firstPageOfListsRequested, setQueryParams } from 'app/pages/my-audience/lists';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';
import 'app/common/components/navigation/Navigation.scss';

export const AudienceNavigation = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeMenuItemClass = useActiveMenuItemClass(MAIN_ROUTES.AUDIENCE_OVERVIEW_PATH);

  const { hasPermission, hasAllPermissions } = useAuthContext();
  const hasPermissionToCreateMediaOutlets = hasPermission(Permission.ManageMediaOutlets);
  const hasPermissionToCreateCompaniesAndContacts = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);
  const hasPermissionToCreateLists = hasPermission(Permission.ManageLists);

  const isNavigationCollapsed = useAppSelector<boolean>(selectIsNavigationCollapsed);
  const isOnDesktop = useAppSelector(selectIsOnDesktop);

  const handleCreateMediaOutletMenuClick = () => {
    dispatch(getAllJobTitlesRequested());
    dispatch(openCreateMediaOutletWizard());
    dispatch(firstPageOfMediaOutletsRequested());
  };

  const handleCreateListMenuClick = () => {
    dispatch(openCreateListWizard());
  };

  const handleCreateCompanyMenuClick = () => {
    dispatch(getAllJobTitlesRequested());
    dispatch(openCreateCompanyWizard());
  };

  const handleCreateContactMenuClick = () => {
    dispatch(getAllJobTitlesRequested());
    dispatch(openCreateContactWizard());
  };

  const handleOpenFlatFileImportWindow = () => {
    dispatch(openFlatFile());
  };

  const items: ShorthandValue<MenuItemProps>[] = [
    {
      key: 'contacts',
      content: (
        <NavMenuItem
          name={formatMessage({ id: 'labels.contacts' })}
          icon="ConnectContacts"
          tooltip={formatMessage({ id: 'labels.contacts' })}
          isNavigationCollapsed={isNavigationCollapsed}
        />
      ),
      className: `item-link ${activeMenuItemClass('contacts')}`
    },
    {
      key: 'media-outlets',
      content: (
        <NavMenuItem
          name={formatMessage({ id: 'labels.media-outlets' })}
          icon="News"
          tooltip={formatMessage({ id: 'labels.media-outlets' })}
          isNavigationCollapsed={isNavigationCollapsed}
        />
      ),
      className: `item-link ${activeMenuItemClass('media-outlets')}`
    },
    {
      key: 'companies',
      content: (
        <NavMenuItem
          name={formatMessage({ id: 'labels.companies' })}
          icon="CityNext"
          tooltip={formatMessage({ id: 'labels.companies' })}
          isNavigationCollapsed={isNavigationCollapsed}
        />
      ),
      className: `item-link ${activeMenuItemClass('companies')}`
    },
    {
      key: 'lists',
      content: (
        <NavMenuItem
          name={formatMessage({ id: 'labels.lists' })}
          icon="ListMirrored"
          tooltip={formatMessage({ id: 'labels.lists' })}
          isNavigationCollapsed={isNavigationCollapsed}
        />
      ),
      className: `item-link ${activeMenuItemClass('lists')}`
    }
  ];

  const onMenuItemClick = (_: SyntheticEvent<HTMLElement>, data: MenuItemProps) => {
    switch (data.index) {
      case MyAudienceTabs.Contacts:
        navigate('contacts');
        dispatch(contactsFirstPageOfSavedSearchesRequested({ searchText: '', filterItem: {} as FilterItem }));
        return;
      case MyAudienceTabs.MediaOutlets:
        navigate('media-outlets');
        dispatch(mediaOutletsFirstPageOfSavedSearchesRequested({ searchText: '', filterItem: {} as FilterItem }));
        return;
      case MyAudienceTabs.Companies:
        navigate('companies');
        dispatch(companiesFirstPageOfSavedSearchesRequested({ searchText: '', filterItem: {} as FilterItem }));
        return;
      case MyAudienceTabs.Lists:
        dispatch(setQueryParams({ filterItems: [], searchText: '' }));
        dispatch(firstPageOfListsRequested());
        navigate('lists/results/*');
        return;
    }
  };

  const createMenuContactsSubMenu = () => {
    return {
      key: 'add-contact-menu-item',
      on: 'hover',
      icon: <Icon iconName="ConnectContacts" />,
      content: formatMessage({ id: 'labels.contacts' }),
      menu: [
        {
          key: 'add-contact-menu-item-create-contact-item',
          content: formatMessage({ id: 'add-menu.create-contact' }),
          onClick: handleCreateContactMenuClick,
          disabled: !hasPermissionToCreateCompaniesAndContacts
        },
        {
          key: 'add-contact-menu-item-import-item',
          content: <button onClick={handleOpenFlatFileImportWindow}>{formatMessage({ id: 'add-menu.import-contacts' })}</button>,
          disabled: !hasPermissionToCreateCompaniesAndContacts
        }
      ]
    };
  };

  const createMenuMediaOutletsSubMenu = () => {
    return {
      key: 'add-media-outlet-menu-item',
      on: 'hover',
      content: formatMessage({ id: 'labels.media-outlets' }),
      icon: <Icon iconName="News" />,
      menu: [
        {
          key: 'add-media-outlet-menu-item-create',
          content: formatMessage({ id: 'add-menu.create-media-outlet' }),
          onClick: handleCreateMediaOutletMenuClick,
          disabled: !hasPermissionToCreateMediaOutlets
        }
      ]
    };
  };

  const createMenuCompaniesSubMenu = () => {
    return {
      key: 'add-company-menu-item',
      on: 'hover',
      content: formatMessage({ id: 'labels.companies' }),
      icon: <Icon iconName="CityNext" />,
      menu: [
        {
          key: 'add-company-menu-item-create',
          content: formatMessage({ id: 'add-menu.create-company' }),
          onClick: handleCreateCompanyMenuClick,
          disabled: !hasPermissionToCreateCompaniesAndContacts
        }
      ]
    };
  };

  const createMenuListsSubMenu = () => {
    return {
      key: 'add-list-menu-item',
      on: 'hover',
      content: formatMessage({ id: 'labels.lists' }),
      icon: <Icon iconName="ListMirrored" />,
      menu: [
        {
          key: 'add-list-menu-item-create',
          content: formatMessage({ id: 'add-menu.create-list' }),
          onClick: handleCreateListMenuClick,
          disabled: !hasPermissionToCreateLists
        }
      ]
    };
  };

  const createMenuItems = [createMenuContactsSubMenu(), createMenuMediaOutletsSubMenu(), createMenuCompaniesSubMenu(), createMenuListsSubMenu()];

  return (
    <>
      <div className="navigation-items">
        <Menu items={items} onItemClick={onMenuItemClick} vertical pointing />
      </div>
      <div className="navigation-menu-dropdown">
        {isOnDesktop && <CreateMenu menuItems={createMenuItems} collapsedSidebar={isNavigationCollapsed} content={formatMessage({ id: 'buttons.add' })} />}
      </div>
    </>
  );
};
