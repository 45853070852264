import { useAppSelector, Banner } from 'app/common';
import { ContactsSavedSearches } from 'app/pages/my-audience/contacts-saved-searches';
import { selectIsSearchAnimationActive, ContactsSearch } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const Contacts = () => {
  const { formatMessage } = useIntl();

  const isSearchAnimationActive = useAppSelector(selectIsSearchAnimationActive);

  return (
    <div className={`container ${isSearchAnimationActive && 'animation--active'}`}>
      <Banner imageSrc="/contact-banner-image.svg" altText={formatMessage({ id: 'contacts-search-results-table.search-contacts' })} modifier="contacts" />
      <div className="main-content">
        <div className="search-input-container">
          <ContactsSearch key="contacts-contact-search" />
        </div>
        <div className="saved-searches">
          <ContactsSavedSearches />
        </div>
      </div>
    </div>
  );
};
