import { Dialog, DialogType } from '@fluentui/react';
import { useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import { selectSelectedActivity } from 'app/pages/my-audience/contact-profile';
import { Activity, ActivityTypes, Email } from 'app/pages/my-audience/contacts';
import { DateTime } from 'luxon';

const dialogModalProps = {
  isBlocking: true,
  styles: { main: { maxWidth: 450 } }
};

interface ActivityDialogProps {
  opened: boolean;
  dismiss: () => void;
}

export const ActivityDetailsDialog = ({ opened, dismiss }: ActivityDialogProps) => {
  const { formatMessage } = useIntl();

  const selectedActivity = useAppSelector<Activity>(selectSelectedActivity);

  const getLocalizedActivityType = (activityType: ActivityTypes): string => {
    if (activityType === ActivityTypes.Event) {
      return formatMessage({ id: 'contacts-activity-types-labels.event' });
    } else if (activityType === ActivityTypes.Task) {
      return formatMessage({ id: 'contacts-activity-types-labels.task' });
    } else if (activityType === ActivityTypes.Email) {
      return formatMessage({ id: 'contacts-activity-types-labels.email' });
    } else if (activityType === ActivityTypes.Sending) {
      return formatMessage({ id: 'contacts-activity-types-labels.sending' });
    } else if (activityType === ActivityTypes.Call) {
      return formatMessage({ id: 'contacts-activity-types-labels.call' });
    } else if (activityType === ActivityTypes.Webinar) {
      return formatMessage({ id: 'contacts-activity-types-labels.webinar' });
    } else if (activityType === ActivityTypes.Chat) {
      return formatMessage({ id: 'contacts-activity-types-labels.chat' });
    } else {
      return formatMessage({ id: 'contacts-activity-types-labels.meeting' });
    }
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: getLocalizedActivityType(selectedActivity.type)
  };

  const onCloseDialog = () => {
    dismiss();
  };

  return (
    <>
      <Dialog hidden={!opened} onDismiss={onCloseDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
        <p>
          {formatMessage({ id: 'contacts-activity-details-dialog.title-label' })}: <b>{selectedActivity?.title}</b>
        </p>
        {(selectedActivity.type === ActivityTypes.Email ||
          selectedActivity.type === ActivityTypes.Sending ||
          selectedActivity.type === ActivityTypes.Appointment ||
          selectedActivity.type === ActivityTypes.Task) && (
          <>
            {formatMessage({ id: 'contacts-activity-details-dialog.subject-label' })}: <b>{(selectedActivity as Email)?.subject}</b>
          </>
        )}
        <p>
          {formatMessage({ id: 'contacts-activity-details-dialog.date-label' })}:{' '}
          <b>{DateTime.fromJSDate(selectedActivity.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}</b>
        </p>
        {(selectedActivity.type === ActivityTypes.Email || selectedActivity.type === ActivityTypes.Call || selectedActivity.type === ActivityTypes.Chat) && (
          <p>
            {formatMessage({ id: 'contacts-activity-details-dialog.scheduled-for-label' })}:{' '}
            <b>{DateTime.fromJSDate((selectedActivity as Email).scheduledFor).toLocaleString(DateTime.DATETIME_SHORT)}</b>
          </p>
        )}
        <p>
          {formatMessage({ id: 'contacts-activity-details-dialog.user-label' })}:{' '}
          <b>{`${selectedActivity?.createdBy?.firstName} ${selectedActivity?.createdBy?.lastName}`}</b>
        </p>
        <p>
          {formatMessage({ id: 'contacts-activity-details-dialog.comment-label' })}: <b>{selectedActivity?.comment}</b>
        </p>
      </Dialog>
    </>
  );
};
