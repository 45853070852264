import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Comment, CommentUser, createContact, createMediaOutlet, ProfilePicture } from 'app/common';
import {
  AddCommentToMediaOutletDocument,
  AddCommentToMediaOutletMutation,
  Contact as GqlContact,
  FetchContactsByMediaOutletIdNameAndRoleDocument,
  FetchContactsByMediaOutletIdNameAndRoleQuery,
  FetchContactsByNameAndRoleAndNotContainedInMediaOutletDocument,
  FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery,
  FetchMediaOutletByIdDocument,
  FetchMediaOutletByIdQuery,
  MediaOutlet as GqlMediaOutlet,
  MediaOutlet as QglMediaOutlet,
  MediaOutletInput,
  MediaOutletsCommentInput,
  MediaOutletsProfilePictureInput,
  RemoveMediaOutletDocument,
  RemoveMediaOutletMutation,
  RemoveMediaOutletProfilePictureDocument,
  RemoveMediaOutletProfilePictureMutation,
  UpdateMediaOutletDocument,
  UpdateMediaOutletMutation,
  UpdateMediaOutletProfilePictureDocument,
  UpdateMediaOutletProfilePictureMutation,
  FetchAllMediaResortsQuery,
  FetchAllMediaResortsDocument,
  AddMediaResortMutation,
  AddMediaResortDocument,
  MediaResortInput,
  AddMediaResortToMediaOutletMutation,
  AddMediaResortToMediaOutletDocument,
  RemoveMediaResortFromMediaOutletMutation,
  RemoveMediaResortFromMediaOutletDocument,
  DeleteMediaResortsMutation,
  DeleteMediaResortsDocument
} from 'app/common/graphql/generated/graphql-gateway';
import { PageOfContacts } from 'app/pages/my-audience/contacts';
import { MediaResort, createMediaOutletInputType } from 'app/pages/my-audience/media-outlet-profile';
import { MediaOutlet } from 'app/pages/my-audience/media-outlets';

export const fetchSelectedMediaOutletProfile = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<MediaOutlet> => {
  const response = await client.query<FetchMediaOutletByIdQuery>({
    query: FetchMediaOutletByIdDocument,
    variables: {
      id: id
    }
  });

  return createMediaOutlet(response.data.mediaOutlet as GqlMediaOutlet);
};

export const addCommentToMediaOutlet = async (client: ApolloClient<NormalizedCacheObject>, mediaOutletId: string, comment: Comment): Promise<Comment> => {
  const input: MediaOutletsCommentInput = {
    author: {
      teamsId: comment.author.teamsId,
      name: comment.author.name,
      aadObjectId: comment.author.aadObjectId
    },
    content: comment.content,
    createdAt: comment.createdAt,
    replies: comment?.replies,
    mentions: comment?.mentions.map((m) => {
      return { teamsId: m.teamsId, name: m.name, aadObjectId: m.aadObjectId };
    })
  };

  const response = await client.mutate<AddCommentToMediaOutletMutation>({
    mutation: AddCommentToMediaOutletDocument,
    variables: {
      mediaOutletId,
      input
    }
  });

  return new Comment(
    response.data.addCommentToMediaOutlet.id,
    new CommentUser(
      response.data.addCommentToMediaOutlet?.author?.teamsId,
      response.data.addCommentToMediaOutlet?.author?.name,
      response.data.addCommentToMediaOutlet?.author?.aadObjectId
    ),
    response.data.addCommentToMediaOutlet.content,
    new Date(response.data.addCommentToMediaOutlet.createdAt),
    response.data.addCommentToMediaOutlet?.replies.map(
      (reply) =>
        new Comment(
          reply.id,
          new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
          reply.content,
          new Date(reply.createdAt),
          [],
          reply.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
        )
    ),
    response.data.addCommentToMediaOutlet?.mentions.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
  );
};

export const updateProfilePicture = async (
  client: ApolloClient<NormalizedCacheObject>,
  mediaOutletId: string,
  profilePicture: ProfilePicture
): Promise<string> => {
  const input: MediaOutletsProfilePictureInput = {
    mimeType: profilePicture.mimeType,
    data: profilePicture.data,
    fileExtension: profilePicture.fileExtension
  };

  const response = await client.mutate<UpdateMediaOutletProfilePictureMutation>({
    mutation: UpdateMediaOutletProfilePictureDocument,
    variables: {
      mediaOutletId,
      input
    }
  });

  return response.data.updateMediaOutletProfilePicture;
};

export const removeProfilePicture = async (client: ApolloClient<NormalizedCacheObject>, mediaOutletId: string): Promise<string> => {
  const response = await client.mutate<RemoveMediaOutletProfilePictureMutation>({
    mutation: RemoveMediaOutletProfilePictureDocument,
    variables: {
      mediaOutletId
    }
  });

  return response.data.removeMediaOutletProfilePicture;
};

export const fetchAllMediaResorts = async (client: ApolloClient<NormalizedCacheObject>): Promise<MediaResort[]> => {
  const response = await client.query<FetchAllMediaResortsQuery>({
    query: FetchAllMediaResortsDocument
  });

  return response.data.mediaResorts.map((mr) => new MediaResort(mr.id, mr.name, mr.dataSourceKey));
};

export const addMediaResort = async (client: ApolloClient<NormalizedCacheObject>, mediaResort: MediaResort): Promise<MediaResort> => {
  const input: MediaResortInput = {
    name: mediaResort.name
  };

  const response = await client.mutate<AddMediaResortMutation>({
    mutation: AddMediaResortDocument,
    variables: {
      input
    }
  });

  return new MediaResort(response.data.addMediaResort.id, response.data.addMediaResort.name, response.data.addMediaResort.dataSourceKey);
};

export const removeMediaResorts = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<string[]> => {
  const response = await client.mutate<DeleteMediaResortsMutation>({
    mutation: DeleteMediaResortsDocument,
    variables: {
      ids
    }
  });

  return response.data.deleteMediaResorts;
};

export const addMediaResortToMediaOutlet = async (
  client: ApolloClient<NormalizedCacheObject>,
  mediaOutletId: string,
  mediaResortId: string
): Promise<MediaResort> => {
  const response = await client.mutate<AddMediaResortToMediaOutletMutation>({
    mutation: AddMediaResortToMediaOutletDocument,
    variables: {
      mediaOutletId,
      mediaResortId
    }
  });

  return new MediaResort(
    response.data.addMediaResortToMediaOutlet.id,
    response.data.addMediaResortToMediaOutlet.name,
    response.data.addMediaResortToMediaOutlet.dataSourceKey
  );
};

export const removeMediaResortFromMediaOutlet = async (
  client: ApolloClient<NormalizedCacheObject>,
  mediaOutletId: string,
  mediaResortId: string
): Promise<MediaResort> => {
  const response = await client.mutate<RemoveMediaResortFromMediaOutletMutation>({
    mutation: RemoveMediaResortFromMediaOutletDocument,
    variables: {
      mediaOutletId,
      mediaResortId
    }
  });

  return new MediaResort(
    response.data.removeMediaResortFromMediaOutlet.id,
    response.data.removeMediaResortFromMediaOutlet.name,
    response.data.removeMediaResortFromMediaOutlet.dataSourceKey
  );
};

export const updateMediaOutlet = async (client: ApolloClient<NormalizedCacheObject>, id: string, mediaOutlet: MediaOutlet): Promise<MediaOutlet> => {
  const mediaOutletInput: MediaOutletInput = createMediaOutletInputType(mediaOutlet);
  const response = await client.mutate<UpdateMediaOutletMutation>({
    mutation: UpdateMediaOutletDocument,
    variables: {
      id: id,
      input: mediaOutletInput
    }
  });

  return createMediaOutlet(response.data.updateMediaOutlet as QglMediaOutlet);
};

export const fetchMediaOutletContacts = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  name: string,
  role: string,
  mediaOutletId: string
): Promise<PageOfContacts> => {
  const response = await client.query<FetchContactsByMediaOutletIdNameAndRoleQuery>({
    query: FetchContactsByMediaOutletIdNameAndRoleDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      name: name,
      role: role,
      mediumId: mediaOutletId
    }
  });

  return new PageOfContacts(
    response.data.contactsByMediumIdNameAndRole.totalCount,
    response.data.contactsByMediumIdNameAndRole.contacts.map((contact) => createContact(contact as GqlContact))
  );
};

export const removeMediaOutlet = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<string> => {
  const response = await client.mutate<RemoveMediaOutletMutation>({
    mutation: RemoveMediaOutletDocument,
    variables: {
      id
    }
  });

  return response.data.removeMediaOutlet.id;
};

export const fetchContactsByNameAndRoleAndNotContainedInMediaOutlet = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  name: string,
  role: string,
  mediaOutletId: string
): Promise<PageOfContacts> => {
  const response = await client.query<FetchContactsByNameAndRoleAndNotContainedInMediaOutletQuery>({
    query: FetchContactsByNameAndRoleAndNotContainedInMediaOutletDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      name: name,
      role: role,
      mediumId: mediaOutletId
    }
  });

  return new PageOfContacts(
    response.data.contactsByNameAndRoleAndNotContainedInMedium.totalCount,
    response.data.contactsByNameAndRoleAndNotContainedInMedium.contacts.map((contact) => createContact(contact as GqlContact))
  );
};
