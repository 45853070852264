import { Skeleton } from '@fluentui/react-northstar';

export const ShimmerSearch = () => {
  return (
    <Skeleton animation="pulse" className="shimmer-wrap">
      <div className="shimmer-search-wrap">
        <div className="shimmer-search-intro">
          <Skeleton.Line />
        </div>
        <div className="shimmer-search">
          <div className="shimmer-search-description">
            <Skeleton.Line />
            <Skeleton.Line />
          </div>
          <div className="shimmer-search-content-wrap">
            <div className="shimmer-search-content">
              <Skeleton.Avatar size="smaller" />
              <Skeleton.Line width="120px" />
            </div>
            <div className="shimmer-search-content">
              <Skeleton.Avatar size="smaller" />
              <Skeleton.Line width="150px" />
            </div>
            <div className="shimmer-search-content">
              <Skeleton.Avatar size="smaller" />
              <Skeleton.Line width="200px" />
            </div>
            <div className="shimmer-search-content">
              <Skeleton.Avatar size="smaller" />
              <Skeleton.Line width="120px" />
            </div>
          </div>
        </div>
        <div className="shimmer-search">
          <div className="shimmer-search-description">
            <Skeleton.Line />
            <Skeleton.Line />
          </div>
          <div className="shimmer-search-content-wrap">
            <div className="shimmer-search-content">
              <Skeleton.Avatar size="smaller" />
              <Skeleton.Line width="120px" />
            </div>
            <div className="shimmer-search-content">
              <Skeleton.Avatar size="smaller" />
              <Skeleton.Line width="200px" />
            </div>
          </div>
        </div>
      </div>
    </Skeleton>
  );
};
