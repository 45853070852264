import { TableHeader } from 'app/common';

export const buildLocalizedSearchResultsColumns = (
  nameLabel: string,
  roleLabel: string,
  mediumLabel: string,
  resortLabel: string,
  tagsLabel: string,
  preferredLanguagesLabel: string,
  sortAscendingAriaLabel: string,
  sortDescendingAriaLabel: string,
  countryLabel: string
): TableHeader[] => [
  {
    key: 'column0',
    name: nameLabel,
    fieldName: 'name',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isRowHeader: true,
    isSorted: true,
    isSortedDescending: false,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
    isColumnVisible: true
  },
  {
    key: 'column1',
    name: roleLabel,
    fieldName: 'role',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isRowHeader: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
    isColumnVisible: true
  },
  {
    key: 'column2',
    name: mediumLabel,
    fieldName: 'mediumName',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
    isColumnVisible: true
  },
  {
    key: 'column3',
    name: resortLabel,
    fieldName: 'resort',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
    isColumnVisible: true
  },
  {
    key: 'column4',
    name: tagsLabel,
    fieldName: 'tags',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isRowHeader: true,
    isColumnVisible: false
  },
  {
    key: 'column5',
    name: countryLabel,
    fieldName: 'country',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isRowHeader: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
    isColumnVisible: false
  },
  {
    key: 'column6',
    name: preferredLanguagesLabel,
    fieldName: 'preferredLanguages',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    isRowHeader: true,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
    isColumnVisible: false
  }
];
