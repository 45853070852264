import { TableHeader } from 'app/common';

type CompaniesTableHeader = {
  nameLabel: string;
  countryLabel: string;
  phoneLabel: string;
  tagsLabel: string;
  registrationNumberLabel: string;
  addressLabel: string;
  postalCodeLabel: string;
  cityLabel: string;
  faxLabel: string;
  websiteLabel: string;
  poBoxLabel: string;
  sortAscendingLabel: string;
  sortDescendingAriaLabel: string;
};

export const buildLocalizedSearchResultsColumns = ({
  nameLabel,
  countryLabel,
  phoneLabel,
  tagsLabel,
  registrationNumberLabel,
  addressLabel,
  postalCodeLabel,
  cityLabel,
  faxLabel,
  websiteLabel,
  poBoxLabel,
  sortAscendingLabel,
  sortDescendingAriaLabel
}: CompaniesTableHeader): TableHeader[] => {
  return [
    {
      key: 'column0',
      name: nameLabel,
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column2',
      name: countryLabel,
      fieldName: 'country',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column3',
      name: phoneLabel,
      fieldName: 'phoneNumber',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column6',
      name: tagsLabel,
      fieldName: 'tags',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column7',
      name: registrationNumberLabel,
      fieldName: 'registrationNumber',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column9',
      name: addressLabel,
      fieldName: 'street',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column10',
      name: postalCodeLabel,
      fieldName: 'postalCode',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column11',
      name: cityLabel,
      fieldName: 'city',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column12',
      name: faxLabel,
      fieldName: 'fax',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column13',
      name: websiteLabel,
      fieldName: 'website',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column14',
      name: poBoxLabel,
      fieldName: 'poBox',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscendingLabel,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    }
  ];
};
