import {
  DetailsListLayoutMode,
  DetailsRow,
  Icon,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import { Button, Checkbox } from '@fluentui/react-northstar';
import { EmptyMessage, useAppDispatch, useAppSelector } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useIntl } from 'app/i18n';
import { AadContact } from 'app/pages/my-audience/contacts';
import {
  AddContactsFromCompanyTableToolbar,
  removeAadContactsFromContactsToAdd,
  selectAADContactsToAddToList,
  setAadSelectedContacts
} from 'app/pages/my-audience/lists-profile';
import { useMemo, useState } from 'react';

export const AddContactFromCompanyTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const aadContactsToAdd = useAppSelector<AadContact[]>(selectAADContactsToAddToList);
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  const header = [
    {
      key: 'add-contact-key-name',
      name: formatMessage({ id: 'list-wizards.add-contact-table-name' }),
      fieldName: 'name',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-email',
      name: formatMessage({ id: 'list-wizards.add-contact-table-email' }),
      fieldName: 'email',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: 'add-contact-key-contact-data',
      name: formatMessage({ id: 'list-wizards.add-contact-table-data' }),
      fieldName: 'contactData',
      isResizable: true,
      minWidth: 80,
      maxWidth: 100
    },
    { key: 'add-contact-key-hover-action', name: '', fieldName: 'hoverActions', isResizable: false, minWidth: 60, maxWidth: 60 }
  ];

  const removeCompanyContactsFromTable = (contactIds: string[]) => {
    dispatch(removeAadContactsFromContactsToAdd(contactIds));
    dispatch(setAadSelectedContacts([]));
    selection.setAllSelected(false);
  };

  const handleClickOnRemoveButton = (aadContact: AadContact) => {
    removeCompanyContactsFromTable([aadContact.objectId]);
  };

  const rows = [
    ...aadContactsToAdd.map((aadContact, index) => {
      return {
        key: `${index}`,
        name: `${aadContact.firstName} ${aadContact.lastName}`,
        email: aadContact.email,
        contactData: aadContact.defaultCompany?.name,
        hoverActions: (
          <>
            {hoverIndex === index && (
              <div className="table-actions">
                <Button text icon={<Icon iconName="Clear" />} onClick={() => handleClickOnRemoveButton(aadContact)} />
              </div>
            )}
          </>
        ),
        contact: aadContact
      };
    })
  ];

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedContacts: AadContact[] = selection.getSelection().map((selection) => selection['contact']);
          dispatch(setAadSelectedContacts(selectedContacts));
        },
        selectionMode: SelectionMode.multiple
      }),
    []
  );

  return (
    <div className="table-wrap table-wrap--wizard">
      <AddContactsFromCompanyTableToolbar removeContactsFromTable={removeCompanyContactsFromTable} />
      {aadContactsToAdd.length > 0 ? (
        <div className="table-wrap table--list">
          <MarqueeSelection selection={selection} className="table" isDraggingConstrainedToRoot={true}>
            <ShimmeredDetailsList
              items={rows}
              enableShimmer={!rows}
              columns={header}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              checkboxVisibility={1}
              onRenderRow={(rowProps: IDetailsRowProps) => (
                <div
                  onMouseEnter={() => {
                    setHoverIndex(rowProps.itemIndex);
                  }}
                >
                  <DetailsRow
                    key={rowProps.item.id}
                    {...rowProps}
                    onRenderCheck={() => (
                      <div className="row-header-wrap">
                        {hoverIndex === rowProps.itemIndex || selection.getSelection().includes(rowProps.item) ? (
                          <Checkbox checked={selection.getSelection().includes(rowProps.item)} />
                        ) : (
                          <ProfilePicture name={rowProps.item.name} imageUrl={rowProps.item.profilePictureUrl} size="small" />
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            />
          </MarqueeSelection>
        </div>
      ) : (
        <EmptyMessage
          srcValue="/wizard-empty-info.svg"
          textValueOptional={formatMessage({ id: 'list-wizards.add-contact-table-no-results-title' })}
          textValue={formatMessage({ id: 'list-wizards.add-contact-table-no-results-subtitle' })}
        />
      )}
    </div>
  );
};
