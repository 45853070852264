import { Button, ButtonProps, ComponentEventHandler } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';

interface AddContactFromCompanyFooterProps {
  onAddButtonClick: ComponentEventHandler<ButtonProps>;
}

export const AddContactFromCompanyFooter = ({ onAddButtonClick }: AddContactFromCompanyFooterProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel" aria-hidden="true"></div>
      <div className="wizard-panel-bottom-actions">
        <Button content={formatMessage({ id: 'buttons.add' })} primary onClick={onAddButtonClick} />
      </div>
    </div>
  );
};
