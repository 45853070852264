import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertNotification,
  BottomPopup,
  ConsentPopup,
  hideUndoAlert,
  MAIN_ROUTES,
  selectAudienceCurrentUrl,
  selectIsAlertNotificationVisible,
  selectIsOnDesktop,
  selectIsUndoAlertVisible,
  UndoAlert,
  undoQueue,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { CompaniesWrap } from 'app/pages/my-audience/companies';
import { CompaniesSearchResults } from 'app/pages/my-audience/company-search-results';
import { ContactsWrap } from 'app/pages/my-audience/contacts';
import { ContactsSearchResults } from 'app/pages/my-audience/contacts-search-results';
import { ListsSearchResults } from 'app/pages/my-audience/lists-search-results';
import { MediaOutletsSearchResults } from 'app/pages/my-audience/media-outlet-search-results';
import { MediaOutletsWrap } from 'app/pages/my-audience/media-outlets';
import { AudienceNavigation } from 'app/pages/my-audience/navigation/AudienceNavigation';
import { CreateCompanyWizard } from 'app/pages/my-audience/wizard-create-company';
import { CreateContactWizard } from 'app/pages/my-audience/wizard-create-contact';
import { AddListDialog } from 'app/pages/my-audience/wizard-create-list';
import { CreateMediaOutletWizard } from 'app/pages/my-audience/wizard-create-media-outlet';
import { ExportNotification } from 'app/pages/my-audience/common';
import { useBeforeunload } from 'react-beforeunload';
import { useEffect } from 'react';
import { useIntl } from 'app/i18n';

const AUDIENCE_OVERVIEW_PATH = MAIN_ROUTES.AUDIENCE_OVERVIEW_PATH;

export const AudienceOverview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const isUndoAlertVisible = useAppSelector(selectIsUndoAlertVisible);
  const audienceSavedUrl = useAppSelector(selectAudienceCurrentUrl);
  const isAlertNotificationVisible = useAppSelector(selectIsAlertNotificationVisible);
  const isOnDesktop = useAppSelector(selectIsOnDesktop);

  useBeforeunload((_: BeforeUnloadEvent) => {
    dispatch(hideUndoAlert());
    undoQueue.executeAllPendingActions();
  });

  useEffect(() => {
    if (audienceSavedUrl && audienceSavedUrl !== AUDIENCE_OVERVIEW_PATH && location.pathname === AUDIENCE_OVERVIEW_PATH) navigate(audienceSavedUrl);
  }, [audienceSavedUrl, location, navigate]);

  return (
    <>
      {/* TODO: Change this inline style, exists for demo */}
      <main className="main" style={{ marginTop: !isOnDesktop && '-90px' }}>
        <nav className="navigation">
          <AudienceNavigation />
        </nav>
        <div className="content">
          {isUndoAlertVisible && (
            <UndoAlert
              isUndoAlertVisible={isUndoAlertVisible}
              undoQueue={undoQueue}
              buttonText={formatMessage({ id: 'audience-overview.undo-alert-button' })}
            />
          )}
          {isAlertNotificationVisible && <AlertNotification isAlertNotificationVisible={isAlertNotificationVisible} />}
          <ExportNotification />
          <Routes>
            <Route path="/contacts/*" element={<ContactsWrap />} />
            <Route path="/contacts/results/:contactsQuery/*" element={<ContactsSearchResults />} />
            <Route path="/media-outlets/*" element={<MediaOutletsWrap />} />
            <Route path="/media-outlets/results/:mediaOutletsQuery/*" element={<MediaOutletsSearchResults />} />
            <Route path="/companies/*" element={<CompaniesWrap />} />
            <Route path="/companies/results/:companiesQuery/*" element={<CompaniesSearchResults />} />
            <Route path="/lists/results/:listsQuery/*" element={<ListsSearchResults />} />
            <Route path="/auth" element={<ConsentPopup />} />
            <Route path="*" element={<Navigate to={'contacts'} />} />
          </Routes>
        </div>
        <BottomPopup />
      </main>
      <CreateMediaOutletWizard />
      <CreateContactWizard />
      <CreateCompanyWizard />
      <AddListDialog />
    </>
  );
};
