import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import {
  BottomPopupItems,
  FilterItem,
  openBottomPopup,
  selectIsOnDesktop,
  selectIsOnMobile,
  selectIsOnTouch,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { openSavedSearchesPanel } from 'app/pages/my-audience/companies';
import {
  addCompanySavedSearchRequested,
  firstPageOfSavedSearchesRequested,
  selectIsSaveSearchButtonDisabled
} from 'app/pages/my-audience/companies-saved-searches';
import { CompaniesSearch, CompaniesSearchResultsIntroText } from 'app/pages/my-audience/company-search-results';
import { useIntl } from 'app/i18n';
import { useState } from 'react';

export const CompaniesSearchResultsIntro = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { formatMessage } = useIntl();

  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);
  const isSaveSearchButtonDisabled = useAppSelector<boolean>(selectIsSaveSearchButtonDisabled);

  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);

  const handleCreateNewSavedSearch = () => {
    dispatch(addCompanySavedSearchRequested());
    dispatch(openSavedSearchesPanel());
    navigate(`/my-audience/companies/results/${params.companiesQuery}/saved-searches`);
  };

  const onOpenSavedSearchesPanel = () => {
    dispatch(firstPageOfSavedSearchesRequested({ searchText: '', filterItem: {} as FilterItem }));
    dispatch(openSavedSearchesPanel());
    navigate(`/my-audience/companies/results/${params.companiesQuery}/saved-searches`);
  };

  const bottomPopupItems: BottomPopupItems[] = [
    {
      key: 'save-search',
      title: formatMessage({ id: 'search-results-table.intro.save-search' }),
      className: 'bottom-popup-items',
      onClick: handleCreateNewSavedSearch,
      icon: <Icon iconName="SingleBookmark" />
    },
    {
      key: 'saved-searches',
      title: formatMessage({ id: 'search-results-table.intro.view-saved-searches' }),
      className: 'bottom-popup-items',
      onClick: onOpenSavedSearchesPanel,
      icon: <Icon iconName="SearchBookmark" />
    }
  ];

  const handleClickMoreOptions = () => {
    dispatch(openBottomPopup(bottomPopupItems));
  };

  const onSearchOutsideClick = () => {
    setIsSearchBarVisible(false);
  };

  return (
    <div className={'search-results-intro'}>
      <CompaniesSearchResultsIntroText />
      <div className="search-results-component">
        {!isOnTouch && <CompaniesSearch />}
        {isOnTouch && isSearchBarVisible && <CompaniesSearch onOutsideClick={onSearchOutsideClick} />}
        {isOnDesktop && (
          <div className="search-results-buttons">
            <Button className="search-results-button button-save-search" primary onClick={handleCreateNewSavedSearch} disabled={isSaveSearchButtonDisabled}>
              <Icon iconName="SingleBookmark" /> {formatMessage({ id: 'search-results-table.intro.save-search' })}
            </Button>
            <Button className="search-results-button button-saved-search" primary onClick={onOpenSavedSearchesPanel}>
              <Icon iconName="View" /> {formatMessage({ id: 'search-results-table.intro.view-saved-searches' })}
            </Button>
          </div>
        )}
        <div className="search-results-more-wrap">
          {isOnMobile && !isSearchBarVisible && (
            <Button className="button-search" onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
              <Icon iconName="Search" />
            </Button>
          )}
          {isOnTouch && (
            <Button className="button-more" onClick={handleClickMoreOptions}>
              <Icon iconName="MoreVertical" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
