import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectSendingWizardStep, SendingWizardStep, setCurrentStep, templateAsImageRequested } from 'app/pages/my-activities/sending-wizard';

export interface SendingWizardStepsProps {
  areFieldsValid?: boolean;
  submitForm?: () => void;
}

export const SendingWizardSteps = ({ areFieldsValid, submitForm }: SendingWizardStepsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const step = useAppSelector<SendingWizardStep>(selectSendingWizardStep);

  const styleBreadcrumbStatus = (stepToCompareWith: SendingWizardStep): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (newStep: SendingWizardStep) => {
    if (newStep === SendingWizardStep.PreviewAndSend || step === SendingWizardStep.Email) {
      dispatch(templateAsImageRequested());
    }

    dispatch(setCurrentStep(newStep));

    if (submitForm) {
      submitForm();
    }
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(SendingWizardStep.Setup)}
            content={formatMessage({ id: 'emailing-wizard.steps.setup' })}
            title={formatMessage({ id: 'emailing-wizard.steps.setup' })}
            onClick={() => handleStepButtonClick(SendingWizardStep.Setup)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(SendingWizardStep.Audience)}
            content={formatMessage({ id: 'emailing-wizard.steps.audience' })}
            title={formatMessage({ id: 'emailing-wizard.steps.audience' })}
            onClick={() => handleStepButtonClick(SendingWizardStep.Audience)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(SendingWizardStep.Email)}
            content={formatMessage({ id: 'emailing-wizard.steps.email' })}
            title={formatMessage({ id: 'emailing-wizard.steps.email' })}
            onClick={() => handleStepButtonClick(SendingWizardStep.Email)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(SendingWizardStep.PreviewAndSend)}
            content={formatMessage({ id: 'emailing-wizard.steps.preview-and-send' })}
            title={formatMessage({ id: 'emailing-wizard.steps.preview-and-send' })}
            onClick={() => handleStepButtonClick(SendingWizardStep.PreviewAndSend)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
