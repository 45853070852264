import { useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import 'app/pages/my-activities/sending-profile/components/preview-tab.scss';
import { selectBlacklistFromPreviewTab, selectSelectedSending } from 'app/pages/my-activities/sending-wizard';
import { Sending, SendingStatus } from 'app/pages/my-activities/sendings';
import { Blacklist } from 'app/pages/my-audience/contacts';
import { DateTime } from 'luxon';

interface PreviewTabDetailsProps {
  routeToAudienceTab: () => void;
}

export const PreviewTabDetails = ({ routeToAudienceTab }: PreviewTabDetailsProps) => {
  const { formatMessage } = useIntl();
  const locale = useAppSelector<string>(selectLocale);
  const sending = useAppSelector<Sending>(selectSelectedSending);
  const blacklist = useAppSelector<Blacklist>(selectBlacklistFromPreviewTab);

  const getDeliveryDateAndTime = () => {
    if (sending.status === SendingStatus.Draft) return '/';

    const scheduledAt = DateTime.fromJSDate(sending.sendingScheduledAt);
    return `${scheduledAt.setLocale(locale).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at ${scheduledAt.toLocaleString(DateTime.TIME_WITH_SECONDS)}`;
  };

  return (
    <div className="preview-tab">
      <div className="preview-tab-details-row">
        <span className="preview-tab-details-row-item">{formatMessage({ id: 'sending-profile.preview.mail-preview-link' })}</span>
        <a className="preview-tab-details-row-item" href={sending.mailPreviewLink} target="_blank" rel="noreferrer">
          {sending.mailPreviewLink}
        </a>
      </div>
      <div className="preview-tab-details-row">
        <span className="preview-tab-details-row-item">{formatMessage({ id: 'sending-profile.preview.delivery-date-time' })}</span>
        <span className="preview-tab-details-row-item">{getDeliveryDateAndTime()}</span>
      </div>
      <div className="preview-tab-details-row">
        <span className="preview-tab-details-row-item">{formatMessage({ id: 'sending-profile.preview.sent-from' })}</span>
        <span className="preview-tab-details-row-item">{sending.sender?.emailAddress}</span>
      </div>
      <div className="preview-tab-details-row">
        <span className="preview-tab-details-row-item">{formatMessage({ id: 'sending-profile.preview.subject' })}</span>
        <span className="preview-tab-details-row-item">{sending.subject}</span>
      </div>
      {(sending.status === SendingStatus.Draft || sending.status === SendingStatus.WaitingForDistribution) && (
        <div className="preview-tab-details-row">
          <span className="preview-tab-details-row-item">{formatMessage({ id: 'sending-profile.preview.recipients' })}</span>
          <span className="preview-tab-details-row-item link" onClick={routeToAudienceTab}>
            {formatMessage({ id: 'sending-profile.preview.number-of-recipients' }, { counter: sending.recipients.length })}
          </span>
        </div>
      )}
      <div className="preview-tab-details-row">
        <span className="preview-tab-details-row-item">{formatMessage({ id: 'sending-profile.preview.blacklist' })}</span>
        <span className="preview-tab-details-row-item">{blacklist.name}</span>
      </div>
    </div>
  );
};
