import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { DetailsListLayoutMode, IObjectWithKey, MarqueeSelection, Selection, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import { Button, Input, SearchIcon } from '@fluentui/react-northstar';
import { SavedSearchesTableHeader, useAppDispatch, useAppSelector, useContactLimitationWarning } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddSavedSearchesTablePaging,
  addContactsSavedSearchesToListRequested,
  pageOfContactsSavedSearchesRequested,
  selectContactsSavedSearches,
  selectIsLoadingContactsSavedSearches,
  selectSelectedList,
  setAddContactsSavedSearchesQuery
} from 'app/pages/my-audience/lists-profile';
import { SavedSearch, SavedSearchTableData } from 'app/pages/my-audience/saved-searches';
import { List } from 'app/pages/my-audience/lists';

interface AddSavedSearchesProps {
  onClosePanel: () => void;
}

export const AddSavedSearches = ({ onClosePanel }: AddSavedSearchesProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { checkForContactNumberLimit } = useContactLimitationWarning();
  const savedSearches = useAppSelector<SavedSearch[]>(selectContactsSavedSearches);
  const selectedList = useAppSelector<List>(selectSelectedList);
  const isLoadingSavedSearches = useAppSelector<boolean>(selectIsLoadingContactsSavedSearches);

  const [searchText, setSearchText] = useState('');

  const tableHeader: SavedSearchesTableHeader[] = [
    {
      key: 'column0',
      name: formatMessage({ id: 'list-profile-saved-searches.name' }),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 180,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column1',
      name: formatMessage({ id: 'list-profile-saved-searches.creation-date' }),
      fieldName: 'createdAt',
      minWidth: 100,
      maxWidth: 180,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column2',
      name: formatMessage({ id: 'list-profile-saved-searches.last-update' }),
      fieldName: 'lastModifiedAt',
      minWidth: 100,
      maxWidth: 180,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column3',
      name: formatMessage({ id: 'list-profile-saved-searches.number-of-contacts' }),
      fieldName: 'count',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column5',
      name: '',
      fieldName: '',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    }
  ];

  const createSavedSearchesTableData = (savedSearches: SavedSearch[]): SavedSearchTableData[] => {
    return savedSearches.map(
      (savedSearch, i) =>
        new SavedSearchTableData(
          i,
          savedSearch.name,
          DateTime.fromJSDate(savedSearch.createdAt).toLocaleString(DateTime.DATE_SHORT),
          DateTime.fromJSDate(savedSearch.lastModifiedAt).toLocaleString(DateTime.DATE_SHORT),
          savedSearch.resultsLength
        )
    );
  };

  useEffect(() => {
    setRows(createSavedSearchesTableData(savedSearches));
  }, [savedSearches]);

  const [rows, setRows] = useState(createSavedSearchesTableData(savedSearches));

  const onAddSavedSearches = () => {
    const savedSearchesToAdd: SavedSearch[] = [];
    selectedRowIndexes.forEach((index) => {
      savedSearchesToAdd.push(savedSearches[index]);
    });
    const numberOfNewlySelectedContacts = savedSearchesToAdd.reduce((acc, savedSearch) => (acc += savedSearch.resultsLength), 0);

    const totalNumberOfContacts = selectedList.numberOfContacts + numberOfNewlySelectedContacts;

    checkForContactNumberLimit({ totalNumberOfContacts, successCallback: addContactsAndClosePanel });
  };

  const addContactsAndClosePanel = () => {
    const savedSearchesToAdd: SavedSearch[] = [];
    selectedRowIndexes.forEach((index) => {
      savedSearchesToAdd.push(savedSearches[index]);
    });
    dispatch(addContactsSavedSearchesToListRequested(savedSearchesToAdd));
    onClosePanel();
  };

  const onSearchSavedSearches = () => {
    dispatch(setAddContactsSavedSearchesQuery(searchText));
    dispatch(pageOfContactsSavedSearchesRequested());
  };

  const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedTableRows = selection.getSelection();
          setSelectedRowIndexes(selectedTableRows.map((row) => row.key));
        },
        selectionMode: SelectionMode.multiple
      }),
    [dispatch]
  );

  return (
    <>
      <div className="action-bar-wrap saved-search-wrap">
        <div className="action-bar-buttons">
          <div className="action-bar-button">
            <Input
              placeholder={formatMessage({ id: 'list-profile-saved-searches.enter-your-search' })}
              type="text"
              value={searchText}
              onChange={(event) => setSearchText((event.target as HTMLTextAreaElement).value)}
              icon={<SearchIcon />}
              onKeyDown={(event) => {
                if (event.key === 'Enter') onSearchSavedSearches();
              }}
            />
          </div>
          <div>
            <Button primary content={formatMessage({ id: 'list-profile-saved-searches.search' })} onClick={onSearchSavedSearches} />
          </div>
        </div>
      </div>
      {isLoadingSavedSearches || savedSearches.length > 0 ? (
        <div className="wizard-panel-wrap saved-search-table">
          <MarqueeSelection selection={selection} className="table-results">
            <ShimmeredDetailsList
              items={rows}
              enableShimmer={isLoadingSavedSearches}
              columns={tableHeader.filter((column) => column.isColumnVisible === true)}
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={formatMessage({ id: 'aria-labels.toggle-selection' })}
              ariaLabelForSelectAllCheckbox={formatMessage({ id: 'aria-labels.toggle-selection-for-all-items' })}
              checkButtonAriaLabel={formatMessage({ id: 'aria-labels.select-row' })}
              checkboxVisibility={1}
            />
          </MarqueeSelection>
        </div>
      ) : (
        <div className="empty-saved-search">{formatMessage({ id: 'list-profile-saved-searches.no-available-searches' })}</div>
      )}
      <AddSavedSearchesTablePaging />
      <div className="wizard-panel-wrap saved-search-panel">
        <div className="wizard-panel-bottom">
          <Button tinted content={formatMessage({ id: 'buttons.cancel' })} onClick={onClosePanel} />
          <Button primary content={formatMessage({ id: 'buttons.add' })} onClick={onAddSavedSearches} />
        </div>
      </div>
    </>
  );
};
