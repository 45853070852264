import { Pivot, PivotItem } from '@fluentui/react';
import { useAppSelector, Comment } from 'app/common';
import { useNavigate } from 'react-router-dom';
import { selectSelectedCompanyComments } from 'app/pages/my-audience/company-profile';
import { useIntl } from 'app/i18n';

export const CompanyProfilePivotItems = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const comments = useAppSelector<Comment[]>(selectSelectedCompanyComments);

  const handleTabClick = (relativePath: string) => {
    navigate(relativePath, { replace: true });
  };

  return (
    <div className="profile-panel-tabs">
      <Pivot onLinkClick={(e) => handleTabClick(e.props.itemKey)}>
        <PivotItem itemKey="profile" headerText={formatMessage({ id: 'compianies-profile-tabs.profile' })}></PivotItem>
        <PivotItem itemKey="contacts" headerText={formatMessage({ id: 'compianies-profile-tabs.contacts' })}></PivotItem>
        <PivotItem itemKey="comments" headerText={formatMessage({ id: 'compianies-profile-tabs.comments' }, { count: comments.length })}></PivotItem>
      </Pivot>
    </div>
  );
};
