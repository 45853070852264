import { Label } from '@fluentui/react';
import { DatePicker, DateRange, FilterItemType } from 'app/common';
import { useIntl } from 'app/i18n';
import { SENDING_FILTER } from 'app/pages/my-activities/sending-filter-search/filter-fields';
import { Interval } from 'luxon';
import { useState } from 'react';

interface CreatedAtFilterProps {
  onFilter: ({ fieldName, value }: FilterItemType) => void;
}

export const CreatedAtFilter = ({ onFilter }: CreatedAtFilterProps) => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const { formatMessage } = useIntl();

  const onFromDateChanged = (selectedDate: Date) => {
    setStartDate(selectedDate);
    if (!endDate) return;

    const int = Interval.fromDateTimes(selectedDate, endDate);
    if (!int.isValid) {
      setStartDate(endDate);
      return;
    }

    addFilter(int);
  };

  const onToDateChanged = (selectedDate: Date) => {
    setEndDate(new Date(selectedDate.setHours(23, 59, 59, 999)));
    if (!startDate) return;

    const int = Interval.fromDateTimes(startDate, selectedDate);
    if (!int.isValid) {
      setEndDate(startDate);
      return;
    }

    addFilter(int);
  };

  const addFilter = (interval: Interval) => {
    const dateRange = DateRange.parse(interval);
    onFilter({ fieldName: SENDING_FILTER.CREATED_AT, value: `${dateRange.startDate} - ${dateRange.endDate}` });
  };

  return (
    <div className="date-wrap">
      <div className="accordion-head-box">
        <Label>{formatMessage({ id: 'filter-by-date-popup.from-label' })}</Label>
        <DatePicker onSelectDate={(selectedDate) => onFromDateChanged(selectedDate)} value={startDate} />
        <Label>{formatMessage({ id: 'filter-by-date-popup.to-label' })}</Label>
        <DatePicker onSelectDate={(selectedDate) => onToDateChanged(selectedDate)} value={endDate} />
      </div>
    </div>
  );
};
