import {
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  EditableDropdownField,
  EditableInputTextField,
  selectCompanyJobTitles,
  useAppDispatch,
  useAppSelector,
  addJobTitleRequested,
  isPhoneNumberValidOrEmpty
} from 'app/common';
import { useIntl } from 'app/i18n';
import { changeSelectedContactData, updateCollaborationRequested } from 'app/pages/my-audience/contact-profile';
import { Collaboration, JobTitle, MediumType, PhoneNumber } from 'app/pages/my-audience/contacts';
import { getNames } from 'country-list';
import { Permission, useAuthContext } from 'auth';

export interface CompanyInfoProps {
  companyInfo: Collaboration;
}

export const CompanyInfo: React.FC<CompanyInfoProps> = ({ companyInfo }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);
  const companyJobTitles = useAppSelector<JobTitle[]>(selectCompanyJobTitles);

  const onUpdateCompany = (collaboration: Collaboration) => {
    dispatch(updateCollaborationRequested(collaboration));
  };

  const onSaveChanges = (name: string, updatedValue: string) => {
    let updatedCompanyInfo;
    if (name === 'landlinePhoneNumber') {
      const number = new PhoneNumber(updatedValue, companyInfo.landlinePhoneNumber.isPrimary);
      updatedCompanyInfo = { ...companyInfo, [name]: number };
    } else if (name === 'mobilePhoneNumber') {
      const number = new PhoneNumber(updatedValue, companyInfo.mobilePhoneNumber.isPrimary);
      updatedCompanyInfo = { ...companyInfo, [name]: number };
    } else {
      updatedCompanyInfo = { ...companyInfo, [name]: updatedValue };
    }

    dispatch(changeSelectedContactData(updatedCompanyInfo.medium.name));
    onUpdateCompany(updatedCompanyInfo);
  };

  const onSaveJobTitleChanges = (_: string, updatedValue: string) => {
    const selectedJobTitle = companyJobTitles.find((jobTitle) => jobTitle.name === updatedValue);
    const updatedContactData = { ...companyInfo, jobTitle: selectedJobTitle };
    onUpdateCompany(updatedContactData);
  };

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', MediumType.Company);

    dispatch(addJobTitleRequested(jobTitle));
  };

  return (
    <div className="editable-fields-wrap">
      <div className="editable-fields-content">
        <EditableDropdownField
          value={companyInfo.jobTitle?.name ?? ''}
          fieldName="jobTitle"
          suggestions={companyJobTitles.map((jobTitle) => jobTitle.name)}
          onSaveChanges={onSaveJobTitleChanges}
          label={`${formatMessage({ id: 'contact-data-labels.role' })}`}
          disabled={!hasPermissionToEdit}
          createNewEntityMessage={formatMessage({ id: 'aria-labels.create-job-title' })}
          onCreateNew={handleCreateNewJobTitle}
        />
        <EditableInputTextField
          value={companyInfo.address}
          fieldName="address"
          onChange={onSaveChanges}
          label={`${formatMessage({ id: 'contact-data-labels.address' })}`}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={companyInfo.postalCode}
          fieldName="postalCode"
          onChange={onSaveChanges}
          label={`${formatMessage({ id: 'contact-data-labels.postal-code' })}`}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-postal-code' })}
          isLink={false}
          isFieldValid={isNumberValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={companyInfo.city}
          fieldName="city"
          onChange={onSaveChanges}
          label={`${formatMessage({ id: 'contact-data-labels.city' })}`}
          isLink={false}
          disabled={!hasPermissionToEdit}
        />
        <EditableDropdownField
          value={companyInfo.country}
          fieldName="country"
          suggestions={getNames()}
          onSaveChanges={onSaveChanges}
          label={`${formatMessage({ id: 'contact-data-labels.country' })}`}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={companyInfo.landlinePhoneNumber?.value}
          fieldName="landlinePhoneNumber"
          onChange={onSaveChanges}
          label={`${formatMessage({ id: 'contact-data-labels.phone-number' })}`}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-phone-number' })}
          isLink={true}
          isFieldValid={isPhoneNumberValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={companyInfo.mobilePhoneNumber?.value}
          fieldName="mobilePhoneNumber"
          onChange={onSaveChanges}
          label={`${formatMessage({ id: 'contact-data-labels.mobile' })}`}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-phone-number' })}
          isLink={true}
          isFieldValid={isPhoneNumberValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
        <EditableInputTextField
          value={companyInfo.emailAddress}
          fieldName="emailAddress"
          onChange={onSaveChanges}
          label={`${formatMessage({ id: 'contact-data-labels.email-address' })}`}
          errorMsg={formatMessage({ id: 'error-messages.not-valid-email-address' })}
          isLink={true}
          isFieldValid={isEmailAddressValidOrEmpty}
          disabled={!hasPermissionToEdit}
        />
      </div>
    </div>
  );
};
