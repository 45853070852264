import { Dropdown, DropdownProps } from '@fluentui/react-northstar';
import { useState } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface ControlledMultipleDropdownProps {
  label: JSX.Element;
  name: string;
  control: Control<any>;
  placeholder: string;
  items: string[];
  rules?: RegisterOptions;
  defaultValues?: string[];
}

export const ControlledMultipleDropdown = ({ label, name, control, items, placeholder, rules, defaultValues }: ControlledMultipleDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBlur = () => setIsOpen(false);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {label}
          <Dropdown
            multiple
            clearable
            checkable
            placeholder={placeholder}
            items={items}
            onChange={(_, option) => {
              onChange(option.value);
            }}
            onBlur={handleBlur}
            open={isOpen}
            onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen(props.open || false)}
            error={!value && !!error}
            defaultValue={defaultValues ?? []}
          />
          <span className="error-message">{error && error.message}</span>
        </>
      )}
    />
  );
};
