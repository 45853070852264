import { Skeleton } from '@fluentui/react-northstar';

export const ShimmerTable = () => {
  return (
    <Skeleton animation="pulse" className="shimmer-wrap">
      <div className="shimmer-table">
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
        <div className="shimmer-row">
          <div className="shimmer-avatar">
            <Skeleton.Avatar size="smallest" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
          <div className="shimmer-line">
            <Skeleton.Text size="smaller" />
          </div>
        </div>
      </div>
    </Skeleton>
  );
};
