import { useState } from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button, Dropdown } from '@fluentui/react-northstar';
import { SearchResultTableData, selectSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import {
  closeUpdateDialog,
  MediaResort,
  selectIsUpdateDialogVisible,
  selectListsToAddToContact,
  selectMediaResortsToAddToContact,
  selectTopicsToAddToContact,
  Topic,
  updateContactsRequested
} from 'app/pages/my-audience/contacts';
import { BulkUpdateLists, BulkUpdateMediaResorts, BulkUpdateTopics } from 'app/pages/my-audience/contacts-search-results';
import { useIntl } from 'app/i18n';
import { List } from 'app/pages/my-audience/lists';

export const UpdateContactsDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isVisible = useAppSelector<boolean>(selectIsUpdateDialogVisible);

  const topicsToAddToContact = useAppSelector<Topic[]>(selectTopicsToAddToContact);
  const mediaResortsToAddToContact = useAppSelector<MediaResort[]>(selectMediaResortsToAddToContact);
  const listsToAddToContact = useAppSelector<List[]>(selectListsToAddToContact);

  const [showResortDropdown, setShowResortDropdown] = useState(false);
  const [showTopicDropdown, setShowTopicDropdown] = useState(false);
  const [showListDropdown, setShowListDropdown] = useState(false);

  const BULK_EDIT = {
    LISTS: formatMessage({ id: 'labels.lists' }),
    RESORTS: formatMessage({ id: 'contact-data-labels.resorts' }),
    TOPICS: formatMessage({ id: 'contact-data-labels.topics' })
  };

  const dismiss = () => {
    dispatch(closeUpdateDialog());
    setShowListDropdown(false);
    setShowTopicDropdown(false);
    setShowResortDropdown(false);
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'update-contacts-dialog.title' }, { count: selectedTableRows.length })
  };

  const dialogModalProps = {
    isBlocking: false,
    className: 'dialog-wrap--update contact-update'
  };

  const dropdownValues = Object.values(BULK_EDIT);

  const handleSelectWhatToUpdate = (value: string) => {
    switch (value) {
      case BULK_EDIT.LISTS: {
        setShowListDropdown(value === BULK_EDIT.LISTS);
        setShowResortDropdown(false);
        setShowTopicDropdown(false);
        break;
      }
      case BULK_EDIT.RESORTS: {
        setShowResortDropdown(value === BULK_EDIT.RESORTS);
        setShowListDropdown(false);
        setShowTopicDropdown(false);
        break;
      }
      case BULK_EDIT.TOPICS: {
        setShowTopicDropdown(value === BULK_EDIT.TOPICS);
        setShowListDropdown(false);
        setShowResortDropdown(false);
        break;
      }
    }
  };

  const handleUpdateContacts = () => {
    dispatch(
      updateContactsRequested({
        listsIds: listsToAddToContact.map((list) => list.id),
        mediaResortsIds: mediaResortsToAddToContact.map((mr) => mr.id),
        topicsIds: topicsToAddToContact.map((t) => t.id)
      })
    );

    dispatch(closeUpdateDialog());
    setShowListDropdown(false);
    setShowTopicDropdown(false);
    setShowResortDropdown(false);
  };

  return (
    <Dialog hidden={!isVisible} onDismiss={dismiss} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="dialog-wrap">
        <Dropdown
          items={dropdownValues}
          checkable
          placeholder={formatMessage({ id: 'update-contacts-dropdown.placeholder' })}
          getA11ySelectionMessage={{
            onAdd: (item) => formatMessage({ id: 'update-contacts-dropdown.a11y' }, { item: item.toString() })
          }}
          onChange={(_, { value }) => handleSelectWhatToUpdate(value.toString())}
        />
        <br></br>
        {showTopicDropdown && <BulkUpdateTopics />}
        {showResortDropdown && <BulkUpdateMediaResorts />}
        {showListDropdown && <BulkUpdateLists />}
        <DialogFooter>
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={dismiss}></Button>
          <Button primary onClick={handleUpdateContacts} content={formatMessage({ id: 'buttons.update' })}></Button>
        </DialogFooter>
      </div>
    </Dialog>
  );
};
