import { PillData, UndoablePills } from 'app/common';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import {
  addedMediaResortToContact,
  addMediaResortAndAssignToContactRequested,
  addMediaResortToContactRequested,
  deleteMediaResortsRequested,
  filterMediaResorts,
  removedMediaResortFromContact,
  removeMediaResortFromContactRequested,
  selectAllMediaResorts,
  selectFilteredMediaResorts,
  selectSelectedContact
} from 'app/pages/my-audience/contact-profile';
import { useIntl } from 'react-intl';
import { Contact, MediaResort, addResortInTable, removeResortInTable } from 'app/pages/my-audience/contacts';
import { Permission, useAuthContext } from 'auth';

export const MediaResorts = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const filteredMediaResorts = useAppSelector<MediaResort[]>(selectFilteredMediaResorts);
  const allMediaResorts = useAppSelector<MediaResort[]>(selectAllMediaResorts);

  const handleCreateMediaResort = (mediaResortName: string) => {
    dispatch(addMediaResortAndAssignToContactRequested(new MediaResort('', mediaResortName, '')));
  };

  const addMediaResortInStore = (id: string) => {
    const mediaResort = allMediaResorts.find((mr) => mr.id === id);
    dispatch(addedMediaResortToContact(mediaResort));
    dispatch(addResortInTable({ contactIds: [selectedContact.id], resorts: [mediaResort] }));
  };

  const removeMediaResortInStore = (id: string) => {
    const mediaResort = allMediaResorts.find((mr) => mr.id === id);
    dispatch(removedMediaResortFromContact(mediaResort));
    dispatch(removeResortInTable({ contactIds: [selectedContact.id], resorts: [mediaResort] }));
  };

  const handleDeleteMediaResorts = (ids: string[]) => {
    const mediaResorts = allMediaResorts.filter((t) => ids.includes(t.id));
    dispatch(deleteMediaResortsRequested(mediaResorts));
  };

  const handleAddMediaResortToContact = (id: string) => {
    const mediaResort = allMediaResorts.find((mr) => mr.id === id);
    dispatch(addMediaResortToContactRequested(mediaResort));
  };

  const handleRemoveMediaResortFromContact = (id: string) => {
    const mediaResort = allMediaResorts.find((mr) => mr.id === id);
    dispatch(removeMediaResortFromContactRequested(mediaResort));
  };

  const handleFilterMediaResorts = (mediaResortName: string) => {
    dispatch(filterMediaResorts(mediaResortName));
  };

  return (
    <UndoablePills
      entityName={formatMessage({ id: 'topics-and-media-resorts-accordion-item.media-resort-label' })}
      selectedValues={selectedContact.mediaResorts.map((mr) => new PillData(mr.id, mr.name, mr.dataSourceKey === ''))}
      dropdownValues={filteredMediaResorts.map((mr) => new PillData(mr.id, mr.name, mr.dataSourceKey === ''))}
      onAddValue={handleCreateMediaResort}
      onSelectValue={handleAddMediaResortToContact}
      onDeselectValue={handleRemoveMediaResortFromContact}
      onDeleteValues={handleDeleteMediaResorts}
      filterValues={handleFilterMediaResorts}
      enabledDelete={true}
      onDeselectValueInStore={removeMediaResortInStore}
      onSelectValueInStore={addMediaResortInStore}
      onUndoDeselectValueInStore={addMediaResortInStore}
      onUndoSelectValueInStore={removeMediaResortInStore}
      disabled={!hasPermissionToEdit}
    />
  );
};
