import { Avatar, Input, List, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import { debounceTimeInMilliseconds, getInitials, isEmailAddressValid, useAppDispatch, useAppSelector } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import {
  addRecipientToSendingInStore,
  getContactsByNameOrEmailNotContainedInSendingRecipientsRequested,
  selectContactsSuggestions,
  selectIsLoadingSuggestions,
  selectSelectedSending,
  startLoadingContactsByNameOrEmailNotContainedInSendingRecipients,
  SuggestionsShimmer
} from 'app/pages/my-activities/sending-wizard';
import { Recipient, Sending } from 'app/pages/my-activities/sendings';
import { Contact } from 'app/pages/my-audience/contacts';
import emptyImage from 'assets/images/empty-search.png';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const AddContactsSearch = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const contactSuggestions = useAppSelector<Contact[]>(selectContactsSuggestions);
  const selectedSending = useAppSelector<Sending>(selectSelectedSending);
  const isLoadingContactSuggestions = useAppSelector<boolean>(selectIsLoadingSuggestions);
  const [searchText, setSearchText] = useState('');

  const isInputValidEmailAddress = isEmailAddressValid(searchText);

  const handleGetContacts = useDebouncedCallback(
    () => dispatch(getContactsByNameOrEmailNotContainedInSendingRecipientsRequested(searchText)),
    debounceTimeInMilliseconds
  );

  const handleInputChange = (searchText: string) => {
    setSearchText(searchText);
    dispatch(startLoadingContactsByNameOrEmailNotContainedInSendingRecipients());
    handleGetContacts();
  };

  const handleContactSelected = (index: number) => {
    if (contactSuggestions.length === 0 && isInputValidEmailAddress) {
      const recipientToAdd = new Recipient('', selectedSending.id, null, '', searchText, false, 0);
      dispatch(addRecipientToSendingInStore(recipientToAdd));
      setSearchText('');

      return;
    }

    const contactToAdd: Contact = contactSuggestions[index];

    const recipientToAdd = new Recipient(
      '',
      selectedSending.id,
      contactToAdd,
      contactToAdd.collaborations.find((c) => c.isPrimary) != null ? contactToAdd.collaborations.find((c) => c.isPrimary).id : '',
      contactToAdd.primaryEmailAddress(),
      false,
      0
    );

    dispatch(addRecipientToSendingInStore(recipientToAdd));
    setSearchText('');
  };

  return (
    <div className="wizard-search">
      <Input
        className="profile-panel-comments-send-input"
        icon={<SearchIcon />}
        autoComplete="off"
        placeholder={formatMessage({ id: 'add-contacts-wizard.search-input-placeholder' })}
        value={searchText}
        onChange={(e) => handleInputChange((e.target as HTMLTextAreaElement).value)}
      />

      {searchText !== '' && !isLoadingContactSuggestions && (
        <div className="dropdown-segments-wrap">
          <div className="dropdown-segments">
            <Segment key={'dropdown-email-address'} className="dropdown-segment">
              <List
                onSelectedIndexChange={(_, newProps) => newProps && handleContactSelected(newProps.selectedIndex)}
                selectable
                items={
                  contactSuggestions.length === 0 && isInputValidEmailAddress
                    ? [
                        {
                          key: `new-email-address`,
                          header: searchText,
                          media: <Avatar name={searchText} getInitials={getInitials} />
                        }
                      ]
                    : contactSuggestions.map((contact) => ({
                        key: contact.id,
                        header: `${contact.firstName} ${contact.lastName}`,
                        content: contact.primaryEmailAddress(),
                        media: <Avatar name={`${contact.firstName} ${contact.lastName}`} getInitials={getInitials} />
                      }))
                }
              />
            </Segment>
          </div>
        </div>
      )}
      {searchText && isLoadingContactSuggestions && <SuggestionsShimmer />}
      {contactSuggestions?.length === 0 && searchText && !isInputValidEmailAddress && (
        <Segment key={'dropdown-empty-search'} className="empty-search">
          <div className="empty-search-wrap">
            <img className="empty-search-image" src={emptyImage} alt={formatMessage({ id: 'empty-search-suggestions-message.title' })} />
            <Text className="empty-search-text text-bold">
              <FormattedMessage id={'empty-search-suggestions-message.title'} />
            </Text>
            <Text className="empty-search-text">
              <FormattedMessage id={'empty-search-suggestions-message.description'} />
            </Text>
          </div>
        </Segment>
      )}
    </div>
  );
};
