import { useState } from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button, Dropdown } from '@fluentui/react-northstar';
import { SearchResultTableData, selectSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import { MediaResort } from 'app/pages/my-audience/media-outlet-profile';
import {
  closeUpdateDialog,
  MediaOutlet,
  selectMediaResortsForBulkUpdate,
  selectIsUpdateDialogVisible,
  selectMediaOutlets,
  updateMediaOutletsRequested
} from 'app/pages/my-audience/media-outlets';
import { BulkUpdateMediaResorts } from 'app/pages/my-audience/media-outlet-search-results';
import { useIntl } from 'app/i18n';

export const UpdateMediaOutletsDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isVisible = useAppSelector<boolean>(selectIsUpdateDialogVisible);

  const mediaResortsToAdd = useAppSelector<MediaResort[]>(selectMediaResortsForBulkUpdate);
  const mediaOutletsStore = useAppSelector<MediaOutlet[]>(selectMediaOutlets);

  const [showColumnDropdown, setShowColumnDropdown] = useState(false);

  const BULK_EDIT = {
    RESORTS: formatMessage({ id: 'contact-data-labels.resorts' })
  };

  const dropdownValues = Object.values(BULK_EDIT);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'update-contacts-dialog.title' }, { count: selectedTableRows.length })
  };

  const dialogModalProps = {
    isBlocking: false,
    className: 'dialog-wrap--update contact-update'
  };

  const handleSelectWhatToUpdate = (value: string) => {
    if (value !== BULK_EDIT.RESORTS) {
      return;
    }

    setShowColumnDropdown(true);
  };

  const onCloseDialog = () => {
    dispatch(closeUpdateDialog());
  };

  const handleUpdateMediaOutlets = () => {
    const selectedMediaOutlets = mediaOutletsStore.filter((mediaOutlet) => selectedTableRows.map((mediaOutlet) => mediaOutlet.id).includes(mediaOutlet.id));
    dispatch(
      updateMediaOutletsRequested({
        mediaOutlets: selectedMediaOutlets,
        mediaResortsIds: mediaResortsToAdd.map((mediaResort) => mediaResort.id)
      })
    );
    dispatch(closeUpdateDialog());
    setShowColumnDropdown(false);
  };

  return (
    <Dialog hidden={!isVisible} onDismiss={onCloseDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="dialog-wrap">
        <Dropdown
          items={dropdownValues}
          checkable
          placeholder={formatMessage({ id: 'bulk-update-media-outlets-dialog.select-what-to-update' })}
          onChange={(_, { value }) => handleSelectWhatToUpdate(value.toString())}
        />
        <br></br>
        {showColumnDropdown && <BulkUpdateMediaResorts />}
        <DialogFooter>
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCloseDialog}></Button>
          <Button primary onClick={handleUpdateMediaOutlets} content={formatMessage({ id: 'buttons.update' })}></Button>
        </DialogFooter>
      </div>
    </Dialog>
  );
};
