import { Tag } from 'app/common';
import { ActivityUser, Recipient, RecipientFromList, Sender, Sending, SendingStatus } from 'app/pages/my-activities/sendings';
import { Medium } from 'app/pages/my-audience/contact-profile';
import { Blacklist, Collaboration, Contact, JobTitle, OwnContactData, PhoneNumber } from 'app/pages/my-audience/contacts';

export const createSending = (gqlSending): Sending => {
  return new Sending(
    gqlSending.id,
    gqlSending.title,
    gqlSending.imageAsBase64,
    SendingStatus[gqlSending.status.name as keyof typeof SendingStatus],
    new Sender(gqlSending.sender?.id, gqlSending.sender?.emailAddress, gqlSending.sender?.displayName, gqlSending.sender?.userId),
    gqlSending.senderEmailAddress,
    gqlSending.senderDisplayName,
    gqlSending.subject,
    gqlSending.respondToEmailAddress,
    gqlSending.htmlBody,
    gqlSending.originalHtmlBody,
    gqlSending.jsonBody,
    gqlSending.mjmlBody,
    gqlSending.numberOfRecipients,
    new Date(gqlSending.createdAt),
    gqlSending.createdByUser
      ? new ActivityUser(gqlSending.createdByUser.id, gqlSending.createdByUser.firstName, gqlSending.createdByUser.lastName, '')
      : ({} as ActivityUser),
    new Date(gqlSending.lastModifiedAt),
    gqlSending.lastModifiedByUser
      ? new ActivityUser(
          gqlSending.lastModifiedByUser.id,
          gqlSending.lastModifiedByUser.firstName,
          gqlSending.lastModifiedByUser.lastName,
          gqlSending.lastModifiedByUser.profilePictureUrl
        )
      : new ActivityUser('', 'Removed', 'User', ''),
    gqlSending.isFavourite,
    new Blacklist(gqlSending?.blacklist?.id, gqlSending?.blacklist?.name),
    [],
    [],
    [],
    gqlSending.distributionDuration,
    gqlSending.comment,
    gqlSending.participants?.map((recipient) => {
      if (recipient.__typename === 'Recipient') {
        return new Recipient(
          recipient.id,
          recipient.sendingId,
          new Contact(
            recipient.contact.id,
            recipient.contact.salutation,
            recipient.contact.firstName,
            recipient.contact.lastName,
            recipient.contact.profilePictureUrl,
            recipient.contact.isGloballySignedOut,
            [],
            [],
            [],
            [],
            recipient.contact.collaborations.map(
              (node) =>
                new Collaboration(
                  node.id,
                  new Medium(node.medium?.id, node.medium?.name, node.medium?.type?.value),
                  new JobTitle(node.jobTitle?.id, node.jobTitle?.name, node.jobTitle?.dataSourceKey, node.jobTitle?.mediumType?.value),
                  node.jobDescription,
                  node.address,
                  node.country,
                  new PhoneNumber(node?.landlinePhoneNumber?.value, true),
                  new PhoneNumber(node?.mobilePhoneNumber?.value, false),
                  node.emailAddress,
                  node.blogUrl,
                  node.websiteUrl,
                  node.twitterProfileUrl,
                  node.instagramProfileUrl,
                  node.linkedInProfileUrl,
                  node.youtubeProfileUrl,
                  node.facebookProfileUrl,
                  node.isPrimary,
                  node.city,
                  node.postalCode
                )
            ),
            [],
            [],
            recipient.contact.ownContactData
              ? new OwnContactData(
                  recipient.contact.ownContactData?.address,
                  recipient.contact.ownContactData?.country,
                  new PhoneNumber(
                    recipient.contact.ownContactData?.landlinePhoneNumber?.value,
                    recipient.contact.ownContactData?.landlinePhoneNumber?.isPrimary
                  ),
                  new PhoneNumber(recipient.contact.ownContactData?.mobilePhoneNumber?.value, recipient.contact.ownContactData?.mobilePhoneNumber?.isPrimary),
                  recipient.contact.ownContactData?.emailAddress,
                  recipient.contact.ownContactData?.blogUrl,
                  recipient.contact.ownContactData?.websiteUrl,
                  recipient.contact.ownContactData?.twitterProfileUrl,
                  recipient.contact.ownContactData?.instagramProfileUrl,
                  recipient.contact.ownContactData?.linkedInProfileUrl,
                  recipient.contact.ownContactData?.youtubeProfileUrl,
                  recipient.contact.ownContactData?.facebookProfileUrl,
                  recipient.contact.ownContactData?.isPrimary,
                  recipient.contact.ownContactData?.city,
                  recipient.contact.ownContactData?.postalCode,
                  recipient.contact.ownContactData?.fax
                )
              : ({} as OwnContactData)
          ),
          recipient.collaborationId,
          recipient.emailAddress,
          recipient.isOpened,
          recipient.linksClicked
        );
      } else
        return new RecipientFromList(
          recipient.id,
          recipient.sendingId,
          new Contact(
            recipient.contact.id,
            recipient.contact.salutation,
            recipient.contact.firstName,
            recipient.contact.lastName,
            recipient.contact.profilePictureUrl,
            recipient.contact.isGloballySignedOut,
            [],
            [],
            [],
            [],
            recipient.contact.collaborations.map(
              (node) =>
                new Collaboration(
                  node.id,
                  new Medium(node.medium?.id, node.medium?.name, node.medium?.type?.value),
                  new JobTitle(node.jobTitle?.id, node.jobTitle?.name, node.jobTitle?.dataSourceKey, node.jobTitle?.mediumType?.value),
                  node.jobDescription,
                  node.address,
                  node.country,
                  new PhoneNumber(node?.landlinePhoneNumber?.value, true),
                  new PhoneNumber(node?.mobilePhoneNumber?.value, false),
                  node.emailAddress,
                  node.blogUrl,
                  node.websiteUrl,
                  node.twitterProfileUrl,
                  node.instagramProfileUrl,
                  node.linkedInProfileUrl,
                  node.youtubeProfileUrl,
                  node.facebookProfileUrl,
                  node.isPrimary,
                  node.city,
                  node.postalCode
                )
            ),
            [],
            [],
            recipient.contact.ownContactData
              ? new OwnContactData(
                  recipient.contact.ownContactData?.address,
                  recipient.contact.ownContactData?.country,
                  new PhoneNumber(
                    recipient.contact.ownContactData?.landlinePhoneNumber?.value,
                    recipient.contact.ownContactData?.landlinePhoneNumber?.isPrimary
                  ),
                  new PhoneNumber(recipient.contact.ownContactData?.mobilePhoneNumber?.value, recipient.contact.ownContactData?.mobilePhoneNumber?.isPrimary),
                  recipient.contact.ownContactData?.emailAddress,
                  recipient.contact.ownContactData?.blogUrl,
                  recipient.contact.ownContactData?.websiteUrl,
                  recipient.contact.ownContactData?.twitterProfileUrl,
                  recipient.contact.ownContactData?.instagramProfileUrl,
                  recipient.contact.ownContactData?.linkedInProfileUrl,
                  recipient.contact.ownContactData?.youtubeProfileUrl,
                  recipient.contact.ownContactData?.facebookProfileUrl,
                  recipient.contact.ownContactData?.isPrimary,
                  recipient.contact.ownContactData?.city,
                  recipient.contact.ownContactData?.postalCode,
                  recipient.contact.ownContactData?.fax
                )
              : ({} as OwnContactData)
          ),
          recipient.collaborationId,
          (recipient as RecipientFromList).listId,
          recipient.emailAddress,
          recipient.isOpened,
          recipient.linksClicked
        );
    }),
    gqlSending.mailPreviewLink,
    gqlSending.deliveredAt ? new Date(gqlSending.deliveredAt) : gqlSending.deliveredAt,
    gqlSending.sendingScheduledAt ? new Date(gqlSending.sendingScheduledAt) : gqlSending.sendingScheduledAt,
    gqlSending.tags?.map((t) => new Tag(t.id, t.name, '')),
    gqlSending.sendingLogs
  );
};

export const createRecipient = (recipient): Recipient => {
  return recipient.__typename === 'Recipient'
    ? new Recipient(
        recipient.id,
        recipient.sendingId,
        recipient.contact?.id
          ? new Contact(
              recipient.contact.id,
              recipient.contact.salutation,
              recipient.contact.firstName,
              recipient.contact.lastName,
              recipient.contact.profilePictureUrl,
              recipient.contact.isGloballySignedOut,
              [],
              [],
              [],
              recipient.contact.collaborations.map(
                (node) =>
                  new Collaboration(
                    node.id,
                    new Medium(node.medium?.id, node.medium?.name, node.medium?.type?.value),
                    new JobTitle(node.jobTitle?.id, node.jobTitle?.name, node.jobTitle?.dataSourceKey, node.jobTitle?.mediumType?.value),
                    node.jobDescription,
                    node.address,
                    node.country,
                    new PhoneNumber(node?.landlinePhoneNumber?.value, node?.landlinePhoneNumber?.isPrimary),
                    new PhoneNumber(node?.mobilePhoneNumber?.value, node?.mobilePhoneNumber?.isPrimary),
                    node.emailAddress,
                    node.blogUrl,
                    node.websiteUrl,
                    node.twitterProfileUrl,
                    node.instagramProfileUrl,
                    node.linkedInProfileUrl,
                    node.youtubeProfileUrl,
                    node.facebookProfileUrl,
                    node.isPrimary,
                    node.city,
                    node.postalCode
                  )
              ),
              [],
              [],
              [],
              recipient.contact.ownContactData
                ? new OwnContactData(
                    recipient.contact.ownContactData?.address,
                    recipient.contact.ownContactData?.country,
                    new PhoneNumber(
                      recipient.contact.ownContactData?.landlinePhoneNumber?.value,
                      recipient.contact.ownContactData?.landlinePhoneNumber?.isPrimary
                    ),
                    new PhoneNumber(recipient.contact.ownContactData?.mobilePhoneNumber?.value, recipient.contact.ownContactData?.mobilePhoneNumber?.isPrimary),
                    recipient.contact.ownContactData?.emailAddress,
                    recipient.contact.ownContactData?.blogUrl,
                    recipient.contact.ownContactData?.websiteUrl,
                    recipient.contact.ownContactData?.twitterProfileUrl,
                    recipient.contact.ownContactData?.instagramProfileUrl,
                    recipient.contact.ownContactData?.linkedInProfileUrl,
                    recipient.contact.ownContactData?.youtubeProfileUrl,
                    recipient.contact.ownContactData?.facebookProfileUrl,
                    recipient.contact.ownContactData?.isPrimary,
                    recipient.contact.ownContactData?.city,
                    recipient.contact.ownContactData?.postalCode,
                    recipient.contact.ownContactData?.fax
                  )
                : ({} as OwnContactData)
            )
          : ({} as Contact),
        recipient.collaborationId,
        recipient.emailAddress,
        recipient.isOpened,
        recipient.linksClicked
      )
    : new RecipientFromList(
        recipient.id,
        recipient.sendingId,
        recipient.contact?.id
          ? new Contact(
              recipient.contact.id,
              recipient.contact.salutation,
              recipient.contact.firstName,
              recipient.contact.lastName,
              recipient.contact.profilePictureUrl,
              recipient.contact.isGloballySignedOut,
              [],
              [],
              [],
              recipient.contact.collaborations.map(
                (node) =>
                  new Collaboration(
                    node.id,
                    new Medium(node.medium?.id, node.medium?.name, node.medium?.type?.value),
                    new JobTitle(node.jobTitle?.id, node.jobTitle?.name, node.jobTitle?.dataSourceKey, node.jobTitle?.mediumType?.value),
                    node.jobDescription,
                    node.address,
                    node.country,
                    new PhoneNumber(node?.landlinePhoneNumber?.value, node?.landlinePhoneNumber?.isPrimary),
                    new PhoneNumber(node?.mobilePhoneNumber?.value, node?.mobilePhoneNumber?.isPrimary),
                    node.emailAddress,
                    node.blogUrl,
                    node.websiteUrl,
                    node.twitterProfileUrl,
                    node.instagramProfileUrl,
                    node.linkedInProfileUrl,
                    node.youtubeProfileUrl,
                    node.facebookProfileUrl,
                    node.isPrimary,
                    node.city,
                    node.postalCode
                  )
              ),
              [],
              [],
              [],
              recipient.contact.ownContactData
                ? new OwnContactData(
                    recipient.contact.ownContactData?.address,
                    recipient.contact.ownContactData?.country,
                    new PhoneNumber(
                      recipient.contact.ownContactData?.landlinePhoneNumber?.value,
                      recipient.contact.ownContactData?.landlinePhoneNumber?.isPrimary
                    ),
                    new PhoneNumber(recipient.contact.ownContactData?.mobilePhoneNumber?.value, recipient.contact.ownContactData?.mobilePhoneNumber?.isPrimary),
                    recipient.contact.ownContactData?.emailAddress,
                    recipient.contact.ownContactData?.blogUrl,
                    recipient.contact.ownContactData?.websiteUrl,
                    recipient.contact.ownContactData?.twitterProfileUrl,
                    recipient.contact.ownContactData?.instagramProfileUrl,
                    recipient.contact.ownContactData?.linkedInProfileUrl,
                    recipient.contact.ownContactData?.youtubeProfileUrl,
                    recipient.contact.ownContactData?.facebookProfileUrl,
                    recipient.contact.ownContactData?.isPrimary,
                    recipient.contact.ownContactData?.city,
                    recipient.contact.ownContactData?.postalCode,
                    recipient.contact.ownContactData?.fax
                  )
                : ({} as OwnContactData)
            )
          : ({} as Contact),
        recipient.collaborationId,
        recipient.listId,
        recipient.emailAddress,
        recipient.isOpened,
        recipient.linksClicked
      );
};
