import { Text, Image } from '@fluentui/react-northstar';
import 'app/common/components/empty-message/EmptyMessage.scss';

interface EmptyMessageProps {
  textValue: string;
  srcValue: string;
  textValueOptional?: string;
}

export const EmptyMessage = ({ textValue, srcValue, textValueOptional }: EmptyMessageProps) => {
  return (
    <div className="empty-message">
      <div className="empty-message-image">
        <Image src={srcValue} />
      </div>
      <div className="empty-message-text">
        <Text content={textValueOptional} />
        <Text content={textValue} />
      </div>
    </div>
  );
};
