import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddCompanyProfileInfo,
  AddCompanyWizardSteps,
  AddCompanyFinish,
  SearchCompany,
  selectAddCompanyCurrentStep,
  selectIsAddCompanyWizardOpened,
  closeAddCompanyWizard,
  AddCompanySteps,
  AddCompanyFooter
} from 'app/pages/my-audience/contact-profile';

export const AddCompanyWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentStep = useAppSelector(selectAddCompanyCurrentStep);
  const isAddCompanyWizardOpened = useAppSelector(selectIsAddCompanyWizardOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeAddCompanyWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isAddCompanyWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-medium-wizard-header' }, { mediumType: formatMessage({ id: 'labels.company' }) })}
      customWidth="750px"
    >
      <div className="wizard-panel-main">
        <AddCompanySteps />
        {currentStep === AddCompanyWizardSteps.SearchCompany && <SearchCompany />}
        {currentStep === AddCompanyWizardSteps.ProfileInformation && <AddCompanyProfileInfo />}
        {currentStep === AddCompanyWizardSteps.Finish && <AddCompanyFinish />}
        <AddCompanyFooter />
      </div>
    </WizardWrap>
  );
};
