import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AddSavedSearchDocument,
  AddSavedSearchMutation,
  ContactsSavedSearchInput,
  ContactsSavedSearchWithIdInput,
  FetchPagedSavedSearchesDocument,
  FetchPagedSavedSearchesQuery,
  FetchSavedSearchByIdDocument,
  FetchSavedSearchByIdQuery,
  FetchSavedSearchesByIdsDocument,
  FetchSavedSearchesByIdsQuery,
  RemoveSavedSearchDocument,
  RemoveSavedSearchMutation,
  UpdateSavedSearchDocument,
  UpdateSavedSearchesDocument,
  UpdateSavedSearchesMutation,
  UpdateSavedSearchMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { FilterItem } from 'app/common';
import { PageOfSavedSearches, SavedSearch, SavedSearchInput, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';

export const fetchPagedContactSavedSearches = async (
  client: ApolloClient<NormalizedCacheObject>,
  skip: number,
  take: number,
  searchText: string,
  filterItems: FilterItem[]
): Promise<PageOfSavedSearches> => {
  const response = await client.query<FetchPagedSavedSearchesQuery>({
    query: FetchPagedSavedSearchesDocument,
    variables: {
      skip,
      take,
      searchText: searchText,
      filterItems: filterItems
    }
  });
  const savedSearches = response.data.savedSearches || { totalCount: 0, savedSearches: [] };

  return new PageOfSavedSearches(
    savedSearches.totalCount,
    savedSearches.savedSearches.map(
      (savedSearch) =>
        new SavedSearch(
          savedSearch.id,
          savedSearch.name,
          savedSearch.searchText,
          savedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
          savedSearch.priority,
          savedSearch.count,
          new Date(savedSearch.createdAt),
          new Date(savedSearch.lastModifiedAt),
          savedSearch.createdBy
        )
    )
  );
};

export const fetchSavedSearchById = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<SavedSearch> => {
  const response = await client.query<FetchSavedSearchByIdQuery>({
    query: FetchSavedSearchByIdDocument,
    variables: {
      id
    }
  });

  return new SavedSearch(
    response.data.savedSearch.id,
    response.data.savedSearch.name,
    response.data.savedSearch.searchText,
    response.data.savedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.savedSearch.priority,
    response.data.savedSearch.count,
    new Date(response.data.savedSearch.createdAt),
    new Date(response.data.savedSearch.lastModifiedAt),
    response.data.savedSearch.createdBy
  );
};

export const fetchSavedSearchesByIds = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<SavedSearch[]> => {
  const response = await client.query<FetchSavedSearchesByIdsQuery>({
    query: FetchSavedSearchesByIdsDocument,
    variables: {
      ids
    }
  });

  return response.data.savedSearchesByIds?.map(
    (savedSearch) =>
      new SavedSearch(
        savedSearch.id,
        savedSearch.name,
        savedSearch.searchText,
        savedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
        savedSearch.priority,
        savedSearch.count,
        new Date(savedSearch.createdAt),
        new Date(savedSearch.lastModifiedAt),
        savedSearch.createdBy
      )
  );
};

export const updateContactSavedSearch = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  updatedSavedSearch: SavedSearchInput
): Promise<SavedSearch> => {
  const savedSearch: ContactsSavedSearchInput = {
    name: updatedSavedSearch.name,
    filterItems: updatedSavedSearch.filterItems,
    searchText: updatedSavedSearch.searchText
  };

  const response = await client.mutate<UpdateSavedSearchMutation>({
    mutation: UpdateSavedSearchDocument,
    variables: {
      id,
      savedSearch
    }
  });

  return new SavedSearch(
    response.data.updateSavedSearch.id,
    response.data.updateSavedSearch.name,
    response.data.updateSavedSearch.searchText,
    response.data.updateSavedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.updateSavedSearch.priority,
    response.data.updateSavedSearch.count,
    new Date(response.data.updateSavedSearch.createdAt),
    new Date(response.data.updateSavedSearch.lastModifiedAt),
    response.data.updateSavedSearch.createdBy
  );
};

export const updateContactSavedSearches = async (
  client: ApolloClient<NormalizedCacheObject>,
  updatedSavedSearches: SavedSearchWithIdInput[]
): Promise<SavedSearch[]> => {
  const savedSearches: ContactsSavedSearchWithIdInput[] = updatedSavedSearches.map((ss) => ({
    id: ss.id,
    name: ss.name,
    count: ss.count,
    filterItems: ss.filterItems,
    priority: ss.priority,
    searchText: ss.searchText
  }));

  const response = await client.mutate<UpdateSavedSearchesMutation>({
    mutation: UpdateSavedSearchesDocument,
    variables: {
      savedSearches
    }
  });

  return response.data.updateSavedSearches.map(
    (node) =>
      new SavedSearch(
        node.id,
        node.name,
        node.searchText,
        node.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
        node.priority,
        node.count,
        new Date(node.createdAt),
        new Date(node.lastModifiedAt),
        node.createdBy
      )
  );
};

export const addContactSavedSearch = async (client: ApolloClient<NormalizedCacheObject>, savedSearchToAdd: SavedSearchInput): Promise<SavedSearch> => {
  const savedSearch: ContactsSavedSearchInput = {
    name: savedSearchToAdd.name,
    filterItems: savedSearchToAdd.filterItems,
    searchText: savedSearchToAdd.searchText
  };

  const response = await client.mutate<AddSavedSearchMutation>({
    mutation: AddSavedSearchDocument,
    variables: {
      savedSearch
    }
  });

  return new SavedSearch(
    response.data.saveSearch.id,
    response.data.saveSearch.name,
    response.data.saveSearch.searchText,
    response.data.saveSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.saveSearch.priority,
    response.data.saveSearch.count,
    new Date(response.data.saveSearch.createdAt),
    new Date(response.data.saveSearch.lastModifiedAt),
    response.data.saveSearch.createdBy
  );
};

export const removeContactSavedSearch = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<SavedSearch> => {
  const response = await client.mutate<RemoveSavedSearchMutation>({
    mutation: RemoveSavedSearchDocument,
    variables: {
      id
    }
  });

  return new SavedSearch(
    response.data.removeSavedSearch.id,
    response.data.removeSavedSearch.name,
    response.data.removeSavedSearch.searchText,
    response.data.removeSavedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.removeSavedSearch.priority,
    response.data.removeSavedSearch.count,
    new Date(response.data.removeSavedSearch.createdAt),
    new Date(response.data.removeSavedSearch.lastModifiedAt),
    response.data.removeSavedSearch.createdBy
  );
};
