import { DetailsListLayoutMode, MarqueeSelection, Selection, ShimmeredDetailsList } from '@fluentui/react';
import { TableHeader, useAppSelector, usePersistentSelection } from 'app/common';
import {
  CollaborationTableData,
  selectSelectedCollaborationsToAddPerPage,
  selectCollaborationTableData,
  selectIsLoadingMediaOutletsAndCompanies,
  selectPageNumber
} from 'app/pages/my-audience/wizard-create-contact';
import { useIntl } from 'app/i18n';
import { CollaborationDetails } from 'app/pages/my-audience/contacts';

type CollaborationTableProps = {
  selection: Selection;
};

export const CollaborationTable = ({ selection }: CollaborationTableProps) => {
  const isLoadingMedia = useAppSelector<boolean>(selectIsLoadingMediaOutletsAndCompanies);
  const { formatMessage } = useIntl();

  const tableRows = useAppSelector<CollaborationTableData[]>(selectCollaborationTableData);
  const selectedCollaborationsToAddPerPage = useAppSelector(selectSelectedCollaborationsToAddPerPage);
  const page = useAppSelector<number>(selectPageNumber);

  const getMediumId = <T,>(entity: T): string => {
    const collaborationDetails = entity as CollaborationDetails;
    return collaborationDetails.collaboration.medium.id;
  };

  usePersistentSelection({
    tableRows,
    entitiesPerPage: selectedCollaborationsToAddPerPage,
    selection,
    currentPageNumber: page,
    getEntityId: getMediumId
  });

  const collaborationTableHeader: TableHeader[] = [
    {
      key: 'column0',
      name: formatMessage({ id: 'add-menu-create-contact.type-column' }),
      fieldName: 'mediumTypeName',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column1',
      name: formatMessage({ id: 'add-menu-create-contact.name-column' }),
      fieldName: 'name',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column2',
      name: formatMessage({ id: 'add-menu-create-contact.zip-column' }),
      fieldName: 'postalCode',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column3',
      name: formatMessage({ id: 'add-menu-create-contact.city-column' }),
      fieldName: 'city',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    },
    {
      key: 'column4',
      name: formatMessage({ id: 'add-menu-create-contact.country-column' }),
      fieldName: 'country',
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isColumnVisible: true
    }
  ];
  return (
    <div className="wizard-panel-table">
      <MarqueeSelection selection={selection} isDraggingConstrainedToRoot={true}>
        <ShimmeredDetailsList
          checkboxVisibility={1}
          items={tableRows}
          enableShimmer={isLoadingMedia}
          columns={collaborationTableHeader}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selection={selection}
          selectionPreservedOnEmptyClick={false}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="select row"
        />
      </MarqueeSelection>
    </div>
  );
};
