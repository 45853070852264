import { useEffect } from 'react';
import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { useAppDispatch } from 'app/common';
import { PreviewTabDetails } from 'app/pages/my-activities/sending-profile/components/PreviewTabDetails';
import { PreviewTabPreview } from 'app/pages/my-activities/sending-profile/components/PreviewTabPreview';
import { blacklistFromPreviewTabRequested } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';

export interface PreviewTabProps {
  routeToAudienceTab: () => void;
}

export const PreviewTab = ({ routeToAudienceTab }: PreviewTabProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(blacklistFromPreviewTabRequested());
  }, [dispatch]);

  const panels = [
    {
      title: (
        <div className="accordion-head-box" key="preview-tab-details">
          <span className="accordion-heading">{formatMessage({ id: 'sending-profile.preview.details' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: {
        key: 'preview-tab-details-content',
        content: <PreviewTabDetails routeToAudienceTab={routeToAudienceTab} />
      }
    },
    {
      title: (
        <div className="accordion-head-box" key="preview-tab-preview">
          <span className="accordion-heading">{formatMessage({ id: 'sending-profile.preview-tab' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'preview-tab-preview-content', content: <PreviewTabPreview /> }
    }
  ];

  return (
    <div className="email-report-tab">
      <Accordion defaultActiveIndex={[0]} panels={panels} />
    </div>
  );
};
