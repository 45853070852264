import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { ListOverview, Sendings } from 'app/pages/my-audience/lists-profile';
import { useIntl } from 'app/i18n';

export const ProfileAccordion = () => {
  const { formatMessage } = useIntl();

  const panels = [
    {
      title: (
        <div className="accordion-head-box" key="list-overview-title">
          <span className="accordion-heading">{formatMessage({ id: 'list-profile.overview' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'list-overview-content', content: <ListOverview /> }
    },
    {
      title: (
        <div className="accordion-head-box" key="sendings-overview-title">
          <span className="accordion-heading">{formatMessage({ id: 'list-profile.sendings' })}</span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'sendings-overview-content', content: <Sendings /> }
    }
  ];

  return (
    <div className="profile-panel-content">
      <Accordion defaultActiveIndex={[0]} panels={panels} />
    </div>
  );
};
