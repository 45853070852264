import { FilterItem, getFilterLocalizationKey, useReferenceListLocalization } from 'app/common';
import { useIntl } from 'react-intl';

type FilterPillTooltipProps = {
  filterItems: FilterItem[];
};

export const FilterPillTooltip = ({ filterItems }: FilterPillTooltipProps) => {
  const { formatMessage } = useIntl();
  const { localizeFilterItem } = useReferenceListLocalization();

  return (
    <div className="filter-pills-tooltip-wrap">
      {filterItems.map((item) => (
        <div className="filter-pills-tooltip" key={item.fieldName}>
          <span className="filter-pills-tooltip-field-name">{formatMessage({ id: getFilterLocalizationKey(item.fieldName) || 'filters.filters' })}:</span>
          <span className="filter-pills-tooltip-name">{localizeFilterItem(item)}</span>
        </div>
      ))}
    </div>
  );
};
