import { useNavigate } from 'react-router-dom';
import { FilterItem, useAppDispatch, useAppSelector } from 'app/common';
import { SavedSearches, SavedSearch, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import { firstPageOfContactsRequested, setQueryParams } from 'app/pages/my-audience/contacts';
import {
  selectIsLoadingContactSavedSearches,
  selectContactSavedSearches,
  selectContactSavedSearchToRemove,
  updateContactSavedSearchesRequested,
  updateContactSavedSearchRequested,
  setContactSavedSearchToRemove,
  removeContactSavedSearchRequested,
  selectIsDeleteDialogOpened,
  closeDeleteDialog,
  selectTotalCountOfSavedSearches,
  selectFilterItem,
  firstPageOfSavedSearchesRequested,
  contactsSavedSearchesRequested,
  selectPageSize,
  selectPageNumber,
  selectSearchText
} from 'app/pages/my-audience/contacts-saved-searches';

export const ContactsSavedSearches = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const contactSavedSearches = useAppSelector<SavedSearch[]>(selectContactSavedSearches);
  const isLoadingContactSavedSearches = useAppSelector<boolean>(selectIsLoadingContactSavedSearches);
  const totalCountOfSavedSearches = useAppSelector<number>(selectTotalCountOfSavedSearches);
  const searchText = useAppSelector<string>(selectSearchText);
  const filterItem = useAppSelector<FilterItem>(selectFilterItem);
  const pageNumber = useAppSelector<number>(selectPageNumber);
  const pageSize = useAppSelector<number>(selectPageSize);
  const isDeleteDialogOpened = useAppSelector<boolean>(selectIsDeleteDialogOpened);
  const savedSearchToRemove = useAppSelector<SavedSearch>(selectContactSavedSearchToRemove);

  const onUpdateContactSavedSearches = (reorderedSavedSearches: SavedSearch[]) => {
    dispatch(
      updateContactSavedSearchesRequested(
        reorderedSavedSearches.map((ss) => new SavedSearchWithIdInput(ss.id, ss.name, ss.searchText, ss.filterItems, ss.priority, ss.count))
      )
    );
  };

  const handleSearchSavedSearches = (searchText: string) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleFilterSavedSearches = (filterItem: FilterItem) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleResetFilterSavedSearches = () => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: {} as FilterItem
      })
    );
  };

  const onCloseDeleteDialog = () => {
    dispatch(closeDeleteDialog());
  };

  const handleLoadMore = () => {
    dispatch(
      contactsSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem,
        pageNumber: pageNumber + 1
      })
    );
  };

  const onSearchContactSavedSearch = (savedSearch: SavedSearch) => {
    dispatch(setQueryParams({ searchText: savedSearch.searchText, filterItems: savedSearch.filterItems }));
    dispatch(firstPageOfContactsRequested());
    navigate(`/my-audience/contacts/results/${savedSearch.searchText ?? '*'}`);
  };

  const onSetContactSavedSearchToRemove = (savedSearchToRemove: SavedSearch) => {
    dispatch(setContactSavedSearchToRemove(savedSearchToRemove));
  };

  const onUpdateContactSavedSearch = (updatedSavedSearch: SavedSearch) => {
    dispatch(updateContactSavedSearchRequested(updatedSavedSearch));
  };

  const onRemoveContactSavedSearch = () => {
    dispatch(removeContactSavedSearchRequested(savedSearchToRemove));
  };

  return (
    <SavedSearches
      isLoadingSavedSearches={isLoadingContactSavedSearches}
      pageSize={pageSize}
      savedSearches={contactSavedSearches}
      totalCount={totalCountOfSavedSearches}
      onSearchSavedSearches={handleSearchSavedSearches}
      onFilterSavedSearches={handleFilterSavedSearches}
      onResetFilter={handleResetFilterSavedSearches}
      onHandleLoadMore={handleLoadMore}
      onSavedSearchClick={onSearchContactSavedSearch}
      onReorderSavedSearches={onUpdateContactSavedSearches}
      onSetSavedSearchToRemove={onSetContactSavedSearchToRemove}
      onUpdateSavedSearch={onUpdateContactSavedSearch}
      isDeleteDialogOpened={isDeleteDialogOpened}
      onRemoveSavedSearch={onRemoveContactSavedSearch}
      onCloseDeleteDialog={onCloseDeleteDialog}
      searchText={searchText}
    />
  );
};
