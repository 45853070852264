import { Icon } from '@fluentui/react';
import { Button, ButtonProps, ComponentEventHandler } from '@fluentui/react-northstar';
import { useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateCompanyFromContactStep,
  selectCompanyCurrentStep,
  selectIsCreateCompanyWizardFinishButtonDisabled
} from 'app/pages/my-audience/wizard-create-contact';

interface CreateCompanyFooterProps {
  onCancelButtonClick: ComponentEventHandler<ButtonProps>;
  onBackButtonClick?: ComponentEventHandler<ButtonProps>;
  onNextButtonClick?: ComponentEventHandler<ButtonProps>;
  onFinishButtonClick?: ComponentEventHandler<ButtonProps>;
  isNextButtonDisabled?: boolean;
}

export const CreateCompanyFooter = ({
  onBackButtonClick,
  onNextButtonClick,
  onFinishButtonClick,
  onCancelButtonClick,
  isNextButtonDisabled
}: CreateCompanyFooterProps) => {
  const currentStep = useAppSelector<CreateCompanyFromContactStep>(selectCompanyCurrentStep);
  const isFinishButtonDisabled = useAppSelector<boolean>(selectIsCreateCompanyWizardFinishButtonDisabled);
  const { formatMessage } = useIntl();
  return (
    <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
      <div className="wizard-panel-bottom-button-cancel">
        <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCancelButtonClick} />
      </div>
      <div className="wizard-panel-bottom-actions">
        {currentStep > CreateCompanyFromContactStep.GeneralInfo && (
          <Button icon={<Icon iconName="ChevronLeft" />} content={formatMessage({ id: 'buttons.back' })} onClick={onBackButtonClick} />
        )}
        {currentStep < CreateCompanyFromContactStep.Finish && (
          <Button content={formatMessage({ id: 'buttons.next' })} primary onClick={onNextButtonClick} disabled={isNextButtonDisabled} />
        )}
        {currentStep === CreateCompanyFromContactStep.Finish && (
          <Button content={formatMessage({ id: 'buttons.finish' })} primary onClick={onFinishButtonClick} disabled={isFinishButtonDisabled} />
        )}
      </div>
    </div>
  );
};
