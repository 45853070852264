import { ContactPanel } from 'app/pages/my-audience/contact-profile';
import {
  ContactsTable,
  AddContactsWizard,
  AddContactsFromListsWizard,
  AddContactFromCompanyWizard,
  ContactsTablePaging
} from 'app/pages/my-audience/lists-profile';

export const ContactsAccordionContent = () => {
  return (
    <>
      <ContactsTable />
      <ContactsTablePaging />
      <AddContactsWizard />
      <AddContactsFromListsWizard />
      <AddContactFromCompanyWizard />
      <ContactPanel />
    </>
  );
};
