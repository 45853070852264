import { Button, EditIcon } from '@fluentui/react-northstar';
import { printData } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { ContactGeneralInfo } from 'app/pages/my-audience/wizard-create-contact';

interface ReviewContactInformationProps {
  editContact: () => void;
  contact: ContactGeneralInfo;
  printMultipleValues: (values: string[]) => string;
}

export const ReviewContactInformation = ({ editContact, contact, printMultipleValues }: ReviewContactInformationProps) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <div className="wizard-panel-finish-main-intro">
        <h6 className="wizard-panel-finish-title">
          <FormattedMessage id={'add-menu-create-contact.additional-finish-info'} />
        </h6>
        <Button icon={<EditIcon />} text content={formatMessage({ id: 'buttons.edit' })} onClick={editContact} />
      </div>
      <div className="wizard-panel-finish-main-content">
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-title-label'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.salutation)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-name'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printMultipleValues([contact.firstName, contact.lastName])}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-address'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.address)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-zip'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printMultipleValues([contact.postalCode, contact.city])}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-language'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.language?.name)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-country'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.country)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-phone'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.phoneNumber)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-email'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.emailAddress)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-blog'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.blog)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-website'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.websiteUrl)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-twitter'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.twitterUrl)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-insta'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.instagramUrl)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-ln'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.linkedinUrl)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-youtube'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.youtubeUrl)}</span>
        </div>
        <div className="wizard-panel-finish-main-box">
          <span className="wizard-panel-finish-main-label">
            <FormattedMessage id={'add-menu-create-contact.additional-finish-facebook'} />
          </span>
          <span className="wizard-panel-finish-main-input">{printData(contact.facebookUrl)}</span>
        </div>
      </div>
    </>
  );
};
