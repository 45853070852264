import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, WizardEmptyInfo } from 'app/common';
import { getAllCompanyCategoriesRequested } from 'app/pages/my-audience/company-profile';
import {
  AddCompanyToolbar,
  AddCompanyWizard,
  CompaniesPreview,
  openAddCompanyWizard,
  pageOfCompaniesRequested,
  selectCompanyCollaborations,
  setSearchCompaniesTextAndFitlerItem
} from 'app/pages/my-audience/contact-profile';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const Companies = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const companyCollaborations = useAppSelector<Collaboration[]>(selectCompanyCollaborations);
  const [collaborations, setCollaborations] = useState<Collaboration[]>(companyCollaborations);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setCollaborations(companyCollaborations);
  }, [companyCollaborations]);

  const searchByName = (inputSearchText: string) => {
    setInputValue(inputSearchText);

    if (!inputSearchText) {
      setCollaborations(companyCollaborations);
      return;
    }

    setCollaborations(companyCollaborations.filter((c) => c.medium.name.toLocaleLowerCase().includes(inputSearchText.toLocaleLowerCase())));
  };

  const handleOpenWizard = () => {
    dispatch(setSearchCompaniesTextAndFitlerItem({ searchText: '', category: '' }));
    dispatch(pageOfCompaniesRequested());
    dispatch(getAllCompanyCategoriesRequested());
    dispatch(openAddCompanyWizard());
  };

  const addCompanyButtonContent = formatMessage({ id: 'media-outlet-and-company-tabs.add-company-button' });

  return (
    <div className="profile-panel-media-wrap">
      <div className="profile-panel-media-container">
        <AddCompanyToolbar
          searchByName={searchByName}
          searchedItemLength={collaborations?.length}
          openWizard={handleOpenWizard}
          buttonText={addCompanyButtonContent}
          inputValue={inputValue}
        />
        {collaborations?.length > 0 ? (
          <CompaniesPreview companyCollaborations={collaborations} />
        ) : (
          <WizardEmptyInfo
            message={formatMessage({ id: 'media-outlet-and-company-tabs.no-results-message.title' })}
            description={formatMessage({ id: 'media-outlet-and-company-tabs.no-results-message.text' }, { addButton: addCompanyButtonContent })}
          />
        )}
      </div>
      <AddCompanyWizard />
    </div>
  );
};
