import { PillData, useAppDispatch, useAppSelector, Pills } from 'app/common';
import { addListForBulkUpdate, createListRequested, removeListForBulkUpdate, selectListsToAddToContact } from 'app/pages/my-audience/contacts';
import { getListsByNameRequested, selectSearchedLists } from 'app/pages/my-audience/lists-profile';
import { List } from 'app/pages/my-audience/lists/lists.model';
import { useIntl } from 'app/i18n';

export const BulkUpdateLists = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const allLists = useAppSelector<List[]>(selectSearchedLists);
  const listsToAddToContact = useAppSelector<List[]>(selectListsToAddToContact);

  const handleCreateList = (listName: string) => {
    dispatch(createListRequested(listName));
  };

  const handleSelectList = (listId: string) => {
    const listToAdd = allLists.find((list) => list.id === listId);

    dispatch(addListForBulkUpdate(listToAdd));
  };

  const handleDeselectList = (listId: string) => {
    const listsToRemove = listsToAddToContact.find((list) => list.id === listId);

    dispatch(removeListForBulkUpdate(listsToRemove));
  };

  const handleFilterLists = (searchText: string) => {
    dispatch(getListsByNameRequested(searchText));
  };

  return (
    <div className="profile-panel-topic-tags-dropdown">
      <Pills
        entityName={formatMessage({ id: 'labels.list' })}
        selectedValues={listsToAddToContact.map((t) => new PillData(t.id, t.name, true))}
        dropdownValues={allLists.map((t) => new PillData(t.id, t.name, true))}
        onAddValue={handleCreateList}
        onSelectValue={handleSelectList}
        onDeselectValue={handleDeselectList}
        filterValues={handleFilterLists}
        enabledDelete={false}
        disabled={false}
      />
    </div>
  );
};
