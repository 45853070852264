import {
  ActionBar,
  closeDeleteEntitiesDialog,
  defaultCompanyId,
  DeleteEntitiesDialog,
  hideUndoAlert,
  openDeleteEntitiesDialog,
  openExportDialog,
  SearchResultTableData,
  selectSelectedTableRows,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CompanySearchResultTableData,
  removeCompaniesByIdsRequested,
  removeCompaniesFromStore,
  undoRemoveCompaniesFromStore
} from 'app/pages/my-audience/companies';
import { CompaniesTagsPopup } from 'app/pages/my-audience/company-search-results/components/CompaniesTagsPopup';
import { Permission, useAuthContext } from 'auth';
import { selectIsProfilePanelOpened } from 'app/pages/my-audience/company-profile';

export const CompaniesActionBar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isCompanyProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const isActionBarVisible = !!selectedTableRows.length && !isCompanyProfileOpened;

  const { hasAllPermissions } = useAuthContext();
  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const onExport = () => {
    dispatch(openExportDialog());
  };

  const calculateCompaniesToDelete = () =>
    (selectedTableRows as CompanySearchResultTableData[]).filter((row) => !row.dataSourceKey && row.id !== defaultCompanyId);

  const onDelete = () => {
    dispatch(openDeleteEntitiesDialog());
  };

  const onDeleteFromStore = () => {
    dispatch(removeCompaniesFromStore(calculateCompaniesToDelete().map((row) => row.id)));
  };

  const onConfirmDelete = () => {
    const companiesToDelete = calculateCompaniesToDelete().map((row) => row.id);
    if (companiesToDelete.length > 0) {
      dispatch(removeCompaniesByIdsRequested(companiesToDelete));
    } else {
      dispatch(undoRemoveCompaniesFromStore());
    }
    dispatch(hideUndoAlert());
  };

  const onRevertAction = () => {
    dispatch(undoRemoveCompaniesFromStore());
  };

  const onCancelDelete = () => {
    dispatch(closeDeleteEntitiesDialog());
  };

  const getWarningMessages = () => {
    const messages = [];

    const isDefaultCompanySelected = (selectedTableRows as CompanySearchResultTableData[]).filter((row) => row.id === defaultCompanyId).length > 0;
    if (isDefaultCompanySelected) {
      messages.push(formatMessage({ id: 'delete-companies-dialog.default-company' }));
    }

    const externalSourceCompaniesLength = (selectedTableRows as CompanySearchResultTableData[]).filter((row) => row.dataSourceKey).length;
    if (externalSourceCompaniesLength > 0) {
      messages.push(formatMessage({ id: 'delete-companies-dialog.external-source' }, { counter: externalSourceCompaniesLength }));
    }

    return messages;
  };

  const isDeleteButtonEnabled = () => calculateCompaniesToDelete().length > 0;

  return (
    <>
      <ActionBar
        isVisible={isActionBarVisible}
        onExport={onExport}
        onDelete={onDelete}
        showUpdateButton={false}
        tagsContent={<CompaniesTagsPopup />}
        disabled={!hasPermissionToEdit}
      />
      <DeleteEntitiesDialog
        entityNameInSingular={formatMessage({ id: 'labels.company' })}
        entityNameInPlural={formatMessage({ id: 'labels.companies' })}
        numberOfSelectedEntities={calculateCompaniesToDelete().length}
        onExecuteAction={onConfirmDelete}
        onExecuteInStore={onDeleteFromStore}
        onRevertAction={onRevertAction}
        onCancel={onCancelDelete}
        warningMessages={getWarningMessages()}
        deleteButtonDisabled={!isDeleteButtonEnabled()}
      />
    </>
  );
};
