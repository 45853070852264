import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import {
  CreateCompanyAddressInfo,
  CreateCompanyGeneralInfo,
  closeCreateCompanyWizard,
  CreateCompanyFooter,
  CreateCompanyProfileInfo,
  CreateCompanySteps,
  CreateCompanyWizardSteps,
  FinishCreateCompany,
  selectCreateCompanyCurrentStep,
  selectIsCreateCompanyWizardOpened
} from 'app/pages/my-audience/contact-profile';

export const CreateCompanyWizard = () => {
  const dispatch = useAppDispatch();

  const currentStep = useAppSelector<CreateCompanyWizardSteps>(selectCreateCompanyCurrentStep);
  const isCreateCompanyWizardOpened = useAppSelector(selectIsCreateCompanyWizardOpened);

  const handleOnDismissButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateCompanyWizardOpened}
      onDismissButtonClick={handleOnDismissButtonClick}
      headerTitle="Create Company"
      customWidth="750px"
    >
      <div className="wizard-panel-main">
        <CreateCompanySteps />
        {currentStep === CreateCompanyWizardSteps.GeneralInformation && <CreateCompanyGeneralInfo />}
        {currentStep === CreateCompanyWizardSteps.AddressInfrormation && <CreateCompanyAddressInfo />}
        {currentStep === CreateCompanyWizardSteps.ProfileInformation && <CreateCompanyProfileInfo />}
        {currentStep === CreateCompanyWizardSteps.Finish && <FinishCreateCompany />}
        <CreateCompanyFooter />
      </div>
    </WizardWrap>
  );
};
