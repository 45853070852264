import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { regPhoneNumber, regEmail, regNumbers, useAppDispatch, useAppSelector, WizardIntro, isValidUrlField, regUrl, ensureHttpProtocol } from 'app/common';
import { getNames } from 'country-list';
import {
  AddressInfo,
  selectAddressInfoToAdd,
  setAddressInfoToAdd,
  goToPreviousStep,
  goToNextStep,
  closeCreateCompanyWizard,
  CreateCompanyFooter,
  CreateCompanySteps,
  pageOfContactsRequested
} from 'app/pages/my-audience/wizard-create-company';
import { ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { isEmailAddressValidOrEmpty, isNumberValidOrEmpty, isPhoneNumberValidOrEmpty } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddAddressInfo = () => {
  const addressInfo = useAppSelector<AddressInfo>(selectAddressInfoToAdd);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { handleSubmit, control, watch } = useForm<AddressInfo, any>({
    defaultValues: {
      address: addressInfo.address,
      city: addressInfo.city,
      country: addressInfo.country,
      email: addressInfo.email,
      fax: addressInfo.fax,
      phoneNumber: addressInfo.phoneNumber,
      poBox: addressInfo.poBox,
      postalCode: addressInfo.postalCode,
      website: addressInfo.website
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };
  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };
  const handleNextButtonClick = () => {
    submitData();
    dispatch(pageOfContactsRequested());
    dispatch(goToNextStep());
  };

  const submitData = () => {
    handleSubmit(
      (addressInfoToSubmit: AddressInfo) => {
        dispatch(setAddressInfoToAdd({ ...addressInfoToSubmit, website: ensureHttpProtocol(addressInfoToSubmit.website) }));
      },
      (error) => {
        Sentry.setExtra('Path', AddAddressInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleFieldsValidation = () =>
    isEmailAddressValidOrEmpty(watch('email')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('phoneNumber')) &&
    isValidUrlField(watch('website'));

  const areFieldsValid = handleFieldsValidation();

  return (
    <>
      <CreateCompanySteps onStepClick={submitData} isStepValid={areFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-company.address-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.address-label' })} control={control} name="address" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.po-label' })} control={control} name="poBox" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-company.postal-label' })}
              control={control}
              name="postalCode"
              rules={{
                pattern: {
                  value: regNumbers,
                  message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.city-label' })} control={control} name="city" />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={
                <span className="dropdown-label">
                  {' '}
                  <FormattedMessage id={'add-menu-create-company.country-label'} />
                </span>
              }
              name="country"
              items={getNames()}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-company.country-placeholder' })}
              defaultValue={addressInfo.country}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-company.website-label' })}
              control={control}
              name="website"
              rules={{
                pattern: {
                  value: regUrl,
                  message: formatMessage({ id: 'error-messages.not-valid-website' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="email"
              label={formatMessage({ id: 'add-menu-create-company.email-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'add-menu-create-company.phone-label' })}
              control={control}
              name="phoneNumber"
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.fax-label' })} control={control} name="fax" />
          </div>
        </div>
        <CreateCompanyFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areFieldsValid}
        />
      </div>
    </>
  );
};
