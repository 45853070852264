import { Avatar, ShorthandCollection, Table, TableRowProps } from '@fluentui/react-northstar';
import { ShimmerTable, useAppDispatch, useAppSelector, WizardEmptyInfo } from 'app/common';
import { useIntl } from 'app/i18n';
import { Company } from 'app/pages/my-audience/companies';
import {
  goToNextStepAddCompany,
  selectCompanies,
  selectCompaniesTotalCount,
  selectIsLoadingCompanies,
  setExistingCompany
} from 'app/pages/my-audience/contact-profile';
import { getInitials } from 'app/common/validation';

export const AddCompanyTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isLoadingCompanies = useAppSelector<boolean>(selectIsLoadingCompanies);
  const companies = useAppSelector<Company[]>(selectCompanies);
  const totalCountOfCompanies = useAppSelector<number>(selectCompaniesTotalCount);

  const header = {
    items: [
      '',
      formatMessage({ id: 'company-labels.name' }),
      formatMessage({ id: 'company-labels.parent-company' }),
      formatMessage({ id: 'medium-address-labels.website' })
    ]
  };
  const getInitials = (name) => name.slice(0, 2);
  const rows: ShorthandCollection<TableRowProps> = companies.map((company, i) => ({
    key: 'company-wizard-' + company.name,
    items: [
      { content: <Avatar image={company.profilePictureUrl} getInitials={getInitials} name={company.name} /> },
      { content: company.name, key: 'company-name-' + company.name + i },
      { content: company.parentCompany, key: 'company-parent-company-' + company.parentCompany + i },
      { content: company.contactData.websiteUrl, key: 'company-website-' + company.contactData.websiteUrl + i }
    ],
    onClick: () => {
      dispatch(setExistingCompany(company));
      dispatch(goToNextStepAddCompany());
    }
  }));

  return (
    <div className="wizard-panel-table">
      <Table header={header} rows={rows} aria-label="Static table" />
      {isLoadingCompanies && <ShimmerTable />}
      {totalCountOfCompanies === 0 && (
        <WizardEmptyInfo
          message={formatMessage({ id: 'wizard-companies-empty-search.message' })}
          description={formatMessage({ id: 'wizard-companies-empty-search.description' })}
        />
      )}
    </div>
  );
};
