import { useNavigate } from 'react-router-dom';
import { Pivot, PivotItem } from '@fluentui/react';
import { useIntl } from 'app/i18n';

export const MediaOutletPivotItems = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleTabClick = (relativePath: string) => {
    navigate(relativePath, { replace: true });
  };

  return (
    <div className="profile-panel-tabs">
      <Pivot onLinkClick={(e) => handleTabClick(e.props.itemKey.toLowerCase())}>
        <PivotItem itemKey="Profile" headerText={formatMessage({ id: 'mo-profile-tabs.profile' })}></PivotItem>
        <PivotItem itemKey="Contacts" headerText={formatMessage({ id: 'mo-profile-tabs.contacts' })}></PivotItem>
        <PivotItem itemKey="Comments" headerText={formatMessage({ id: 'mo-profile-tabs.comments' })}></PivotItem>
      </Pivot>
    </div>
  );
};
