import { useNavigate, useParams } from 'react-router-dom';
import { FilterItem, SearchResultTableData, selectSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import { FilterPills } from 'app/pages/my-audience/common';
import { selectFilterItems, openFiltersPanel, removeMediaOutletsFilter, firstPageOfMediaOutletsRequested } from 'app/pages/my-audience/media-outlets';
import { selectIsProfilePanelOpened } from 'app/pages/my-audience/media-outlet-profile';

export const MediaOutletsFilterPills = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const filterItems = useAppSelector<FilterItem[]>(selectFilterItems);
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isMediaOutletProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const areFiltersVisible = selectedTableRows.length === 0 || isMediaOutletProfileOpened;

  const handleOpenFiltersPanel = () => {
    dispatch(openFiltersPanel());
    navigate(`/my-audience/media-outlets/results/${params.mediaOutletsQuery}/filter-media-outlets`);
  };

  const handleRemoveFilter = (filterItem: FilterItem) => {
    dispatch(removeMediaOutletsFilter(filterItem));
  };

  const handleSearchMediaOutlets = () => {
    dispatch(firstPageOfMediaOutletsRequested());
  };

  return (
    areFiltersVisible && (
      <FilterPills
        filterItems={filterItems}
        onOpenFiltersPanel={handleOpenFiltersPanel}
        onRemoveFilter={handleRemoveFilter}
        onSearch={handleSearchMediaOutlets}
      />
    )
  );
};
