import {
  ActionBar,
  hideUndoAlert,
  openExportDialog,
  SearchResultTableData,
  selectSelectedTableRows,
  useAppDispatch,
  useAppSelector,
  closeDeleteEntitiesDialog,
  openDeleteEntitiesDialog
} from 'app/common';
import {
  MediaOutletSearchResultTableData,
  openUpdateDialog,
  removeMediaOutletsByIdsRequested,
  removeMediaOutletsFromStore,
  undoRemoveMediaOutletsFromStore
} from 'app/pages/my-audience/media-outlets';
import { MediaOutletsTagsPopup } from 'app/pages/my-audience/media-outlet-search-results/components/MediaOutletsTagsPopup';
import { DeleteEntitiesDialog } from 'app/common/components/DeleteEntitiesDialog';
import { UpdateMediaOutletsDialog } from 'app/pages/my-audience/media-outlet-search-results/components/UpdateMediaOutletsDialog';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';
import { getAllMediaResortsRequested, selectIsProfilePanelOpened } from 'app/pages/my-audience/media-outlet-profile';

export const MediaOutletsActionBar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isMediaOutletProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const isActionBarVisible = !!selectedTableRows.length && !isMediaOutletProfileOpened;

  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);

  const onExport = () => {
    dispatch(openExportDialog());
  };

  const onUpdate = () => {
    dispatch(openUpdateDialog());
    dispatch(getAllMediaResortsRequested());
  };

  const onDelete = () => {
    dispatch(openDeleteEntitiesDialog());
  };

  const onConfirmDelete = () => {
    const mediaOutletsToDelete = calculateMediaOutletsToDelete();
    if (mediaOutletsToDelete.length > 0) {
      dispatch(removeMediaOutletsByIdsRequested(mediaOutletsToDelete.map((row) => row.id)));
    }
    dispatch(hideUndoAlert());
  };

  const onDeleteFromStore = () => {
    dispatch(removeMediaOutletsFromStore(calculateMediaOutletsToDelete().map((row) => row.id)));
  };

  const onRevertAction = () => {
    dispatch(undoRemoveMediaOutletsFromStore());
  };

  const onCancelDelete = () => {
    dispatch(closeDeleteEntitiesDialog());
  };

  const getWarningMessages = () => {
    const messages = [];

    const externalSourceMediaOutletsLength = (selectedTableRows as MediaOutletSearchResultTableData[]).filter((row) => row.dataSourceKey).length;
    if (externalSourceMediaOutletsLength > 0) {
      messages.push(formatMessage({ id: 'delete-media-outlets-dialog.external-source' }, { counter: externalSourceMediaOutletsLength }));
    }

    return messages;
  };

  const calculateMediaOutletsToDelete = () => (selectedTableRows as MediaOutletSearchResultTableData[]).filter((row) => !row.dataSourceKey);

  const isDeleteButtonEnabled = () => calculateMediaOutletsToDelete().length > 0;

  return (
    <>
      <ActionBar
        isVisible={isActionBarVisible}
        onExport={onExport}
        onDelete={onDelete}
        onUpdate={onUpdate}
        showUpdateButton={true}
        tagsContent={<MediaOutletsTagsPopup />}
        disabled={!hasPermissionToEdit}
      />
      <DeleteEntitiesDialog
        entityNameInSingular={formatMessage({ id: 'labels.media-outlet' })}
        entityNameInPlural={formatMessage({ id: 'labels.media-outlets' })}
        numberOfSelectedEntities={calculateMediaOutletsToDelete().length}
        onExecuteAction={onConfirmDelete}
        onExecuteInStore={onDeleteFromStore}
        onRevertAction={onRevertAction}
        onCancel={onCancelDelete}
        warningMessages={getWarningMessages()}
        deleteButtonDisabled={!isDeleteButtonEnabled()}
      />
      <UpdateMediaOutletsDialog />
    </>
  );
};
