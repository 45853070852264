import { hideUndoAlert, UndoableDialog, useAppDispatch } from 'app/common';
import { List, removeListFromStore, removeListRequested, undoRemoveListFromStore } from 'app/pages/my-audience/lists';
import { closeProfilePanel, setIsRemoveListDialogOpened } from 'app/pages/my-audience/lists-profile';

interface DeleteListDialogProps {
  opened: boolean;
  dialogTitle: string;
  undoAlertMessage: string;
  selectedList: List;
  dialogText?: string;
}

export const DeleteListDialog = ({ opened, dialogTitle, dialogText, undoAlertMessage, selectedList }: DeleteListDialogProps) => {
  const dispatch = useAppDispatch();

  const onCloseDialog = () => {
    dispatch(setIsRemoveListDialogOpened(false));
  };

  const onExecuteAction = () => {
    dispatch(removeListRequested(selectedList));
    dispatch(hideUndoAlert());
  };

  const onExecuteInStore = () => {
    dispatch(removeListFromStore(selectedList));
    dispatch(closeProfilePanel());
  };

  const onRevertAction = () => {
    dispatch(undoRemoveListFromStore(selectedList));
  };

  return (
    <UndoableDialog
      opened={opened}
      dialogTitle={dialogTitle}
      dialogText={dialogText}
      alertMessage={undoAlertMessage}
      onExecuteAction={onExecuteAction}
      onExecuteInStore={onExecuteInStore}
      onRevertAction={onRevertAction}
      onClose={onCloseDialog}
    />
  );
};
