import { PillData, useAppDispatch, useAppSelector, Pills } from 'app/common';
import { deleteMediaResortsRequested, filterMediaResorts, selectFilteredMediaResorts } from 'app/pages/my-audience/contact-profile';
import {
  addMediaResortForBulkUpdate,
  createMediaResortRequested,
  MediaResort,
  removeMediaResortForBulkUpdate,
  selectMediaResortsToAddToContact
} from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const BulkUpdateMediaResorts = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const allMediaResorts = useAppSelector<MediaResort[]>(selectFilteredMediaResorts);
  const mediaResortsToAddToContact = useAppSelector<MediaResort[]>(selectMediaResortsToAddToContact);

  const handleCreateMediaResort = (mediaResortName: string) => {
    const newMediaResort = new MediaResort('', mediaResortName, '');

    dispatch(createMediaResortRequested(newMediaResort));
  };

  const handleSelectMediaResort = (mediaResortId: string) => {
    const mediaResortToAdd = allMediaResorts.find((mediaResort) => mediaResort.id === mediaResortId);

    dispatch(addMediaResortForBulkUpdate(mediaResortToAdd));
  };

  const handleDeselectMediaResort = (mediaResortId: string) => {
    const mediaResortToRemove = mediaResortsToAddToContact.find((mediaResort) => mediaResort.id === mediaResortId);

    dispatch(removeMediaResortForBulkUpdate(mediaResortToRemove));
  };

  const handleDeleteMediaResorts = (mediaResortsIds: string[]) => {
    const mediaResortsToDelete = allMediaResorts.filter((mediaResort) => mediaResortsIds.includes(mediaResort.id));

    dispatch(deleteMediaResortsRequested(mediaResortsToDelete));
  };

  const handleFilterMediaResorts = (searchText: string) => {
    dispatch(filterMediaResorts(searchText));
  };

  return (
    <div className="profile-panel-topic-tags-dropdown">
      <Pills
        entityName={formatMessage({ id: 'labels.media-resort' })}
        selectedValues={mediaResortsToAddToContact.map((t) => new PillData(t.id, t.name, true))}
        dropdownValues={allMediaResorts.map((t) => new PillData(t.id, t.name, true))}
        onAddValue={handleCreateMediaResort}
        onSelectValue={handleSelectMediaResort}
        onDeselectValue={handleDeselectMediaResort}
        onDeleteValues={handleDeleteMediaResorts}
        filterValues={handleFilterMediaResorts}
        enabledDelete={true}
        disabled={false}
      />
    </div>
  );
};
