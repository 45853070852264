import { ITableData, MediumCategory } from 'app/common';
import { Collaboration } from 'app/pages/my-audience/contacts/contacts.model';

export enum CreateMediaOutletWizardStep {
  GeneralInformation = 1,
  AddressInformation,
  Collaboration,
  CollaborationDetails,
  Finish
}

export enum CreateContactWizardStep {
  GeneralInformation = 1,
  Finish
}

export type AddGeneralMediaOutletInfoForm = {
  name: string;
  description: string;
  audience: string;
  category: string;
  frequency: string;
};

export type AddAddressInfoForm = {
  address: string;
  poBox: string;
  postalCode: string;
  city: string;
  country: string;
  website: string;
  emailAddress: string;
  phoneNumber: string;
  fax: string;
};

export type CollaborationForm = {
  jobTitle: string;
  jobDescription: string;
  landlinePhoneNumber: string;
  mobilePhoneNumber: string;
  emailAddress: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
};

export type ContactForm = {
  title: string;
  firstName: string;
  lastName: string;
};

export class MediaOutletGeneralInformation {
  constructor(public name: string, public description: string, public audienceKey: string, public category: MediumCategory, public frequencyKey: string) {}
}

export class MediaOutletAddressInformation {
  constructor(
    public emailAddress: string,
    public phoneNumber: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public poBox: string,
    public fax: string,
    public websiteUrl: string,
    public country: string
  ) {}
}

export class AdditionalInfo {
  constructor(
    public contactId: string,
    public contactFirstName: string,
    public contactLastName: string,
    public jobTitle: string,
    public jobDescription: string,
    public email: string,
    public phoneNumber: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country: string
  ) {}
}

export class ContactInfo {
  constructor(public title: string, public firstName: string, public lastName: string) {}
}

export class CollaborationWithContactId {
  constructor(public contactId: string, public collaboration: Collaboration) {}
}

export class ContactsTableData implements ITableData {
  constructor(
    public id: string,
    public key,
    public name: string,
    public firstName: string,
    public lastName: string,
    public jobTitle: string,
    public emailAddress: string,
    public landlinePhoneNumber: string
  ) {}
}
