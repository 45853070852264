import { ApolloCache, ApolloClient, ApolloQueryResult, DefaultContext, FetchResult, MutationOptions, OperationVariables, QueryOptions } from '@apollo/client';
import { GraphQLQueryError } from './GraphQLQueryError';

export class ApolloClientProxy<TCacheShape> extends ApolloClient<TCacheShape> {
  async query<T = any, TVariables = OperationVariables>(options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>> {
    const response = await super.query(options);
    if (response.error || response.errors?.length) throw new GraphQLQueryError(response.error, response.errors);
    return response;
  }

  async mutate<TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>>(
    options: MutationOptions<TData, TVariables, TContext, ApolloCache<any>>
  ): Promise<FetchResult<TData, Record<string, any>, Record<string, any>>> {
    const response = await super.mutate(options);
    if (response.errors?.length) throw new GraphQLQueryError(null, response.errors);
    return response;
  }
}
