import { Button } from '@fluentui/react-northstar';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from 'app/common';
import {
  closeSendDialog,
  resetSelectedSending,
  selectIsSendDialogVisible,
  selectSelectedSending,
  sendSendingRequested
} from 'app/pages/my-activities/sending-wizard';
import { Sending } from 'app/pages/my-activities/sendings';
import { FormattedMessage, useIntl } from 'app/i18n';
import { useNavigate } from 'react-router-dom';

export const SendDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const isDialogOpened = useAppSelector<boolean>(selectIsSendDialogVisible);
  const selectedSending = useAppSelector<Sending>(selectSelectedSending);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'emailing-wizard-preview-and-send-step.send-dialog.title' })
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } }
  };

  const onCloseDialog = () => {
    dispatch(closeSendDialog());
  };

  const onSend = () => {
    dispatch(sendSendingRequested());
    dispatch(closeSendDialog());
    dispatch(resetSelectedSending());
    navigate(`/my-activities/e-mailings`);
  };

  return (
    <>
      <Dialog hidden={!isDialogOpened} onDismiss={onCloseDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
        <div className="profile-photo-dialog">
          <p>
            <FormattedMessage
              id={'emailing-wizard-preview-and-send-step.send-dialog.description'}
              values={{ recipientsCounter: selectedSending.numberOfRecipients }}
            />
          </p>
          <DialogFooter>
            <Button primary content={formatMessage({ id: 'buttons.send' })} onClick={onSend} />
            <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCloseDialog} />
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};
