import { useNavigate, useParams } from 'react-router-dom';
import { FilterItem, SearchResultTableData, selectSelectedTableRows, useAppDispatch, useAppSelector } from 'app/common';
import { FilterPills } from 'app/pages/my-audience/common';
import { firstPageOfCompaniesRequested, openFiltersPanel, removeCompaniesFilter, selectFilterItems } from 'app/pages/my-audience/companies';
import { selectIsProfilePanelOpened } from 'app/pages/my-audience/company-profile';

export const CompaniesFilterPills = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const filterItems = useAppSelector<FilterItem[]>(selectFilterItems);
  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isCompanyProfileOpened = useAppSelector<boolean>(selectIsProfilePanelOpened);
  const areFiltersVisible = selectedTableRows.length === 0 || isCompanyProfileOpened;

  const handleOpenFiltersPanel = () => {
    dispatch(openFiltersPanel());
    navigate(`/my-audience/companies/results/${params.companiesQuery}/filter-companies`);
  };

  const handleRemoveFilter = (filterItem: FilterItem) => {
    dispatch(removeCompaniesFilter(filterItem));
  };

  const handleSearchCompanies = () => {
    dispatch(firstPageOfCompaniesRequested());
  };

  return (
    <>
      {areFiltersVisible ? (
        <FilterPills
          filterItems={filterItems}
          onOpenFiltersPanel={handleOpenFiltersPanel}
          onRemoveFilter={handleRemoveFilter}
          onSearch={handleSearchCompanies}
        />
      ) : (
        <></>
      )}
    </>
  );
};
