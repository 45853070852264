import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateMediaOutletWizardStep,
  goToStep,
  pageOfContactsRequested,
  selectCreateMediaOutletCurrentStep
} from 'app/pages/my-audience/wizard-create-media-outlet';

export interface CreateMediaOutletStepsProps {
  isStepValid?: boolean;
  onStepClick?: () => void;
}

export const CreateMediaOutletSteps: React.FC<CreateMediaOutletStepsProps> = ({ isStepValid, onStepClick }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const step = useAppSelector<CreateMediaOutletWizardStep>(selectCreateMediaOutletCurrentStep);

  const handleStepButtonClick = (step: CreateMediaOutletWizardStep) => {
    if (onStepClick !== undefined) {
      onStepClick();
    }

    if (step === CreateMediaOutletWizardStep.Collaboration) {
      dispatch(pageOfContactsRequested());
    }

    dispatch(goToStep(step));
  };

  const styleBreadcrumbStatus = (stepToCompareWith: CreateMediaOutletWizardStep): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleCollaborationClick = () => {
    dispatch(pageOfContactsRequested());
    handleStepButtonClick(CreateMediaOutletWizardStep.Collaboration);
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletWizardStep.GeneralInformation)}
            content={formatMessage({ id: 'add-menu-create-mo.wizard-step-general' })}
            title="Information"
            onClick={() => handleStepButtonClick(CreateMediaOutletWizardStep.GeneralInformation)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletWizardStep.AddressInformation)}
            content={formatMessage({ id: 'add-menu-create-mo.wizard-step-address' })}
            title="Address"
            onClick={() => handleStepButtonClick(CreateMediaOutletWizardStep.AddressInformation)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletWizardStep.Collaboration)}
            content={formatMessage({ id: 'add-menu-create-mo.wizard-step-collaboration' })}
            title="Collaboration"
            onClick={handleCollaborationClick}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletWizardStep.CollaborationDetails)}
            content={formatMessage({ id: 'add-menu-create-mo.wizard-step-info' })}
            title="Profile information"
            onClick={() => handleStepButtonClick(CreateMediaOutletWizardStep.CollaborationDetails)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletWizardStep.Finish)}
            content={formatMessage({ id: 'add-menu-create-mo.wizard-step-finish' })}
            title="Finish"
            onClick={() => handleStepButtonClick(CreateMediaOutletWizardStep.Finish)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
