import { debounceTimeInMilliseconds, selectCompanyJobTitles, useAppDispatch, useAppSelector, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import { PanelToolbar } from 'app/pages/my-audience/common/components/panel-toolbar/PanelToolbar';
import {
  CreateContactWizard,
  AddContactSteps,
  AllContactTable,
  ContactsTablePaging,
  AddContactWizardFooter,
  selectSelectedContact,
  goToPreviousStepAddContact,
  goToNextStepAddContact,
  closeAddContactWizard,
  setPageNumber,
  openCreateContactWizard,
  setSearchWizardContactsTextAndFilterItem,
  pageOfWizardContactsRequested,
  resetContactToAdd
} from 'app/pages/my-audience/company-profile';
import { Contact, JobTitle } from 'app/pages/my-audience/contacts';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAuthContext, Permission } from 'auth';

export const AddContact = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToCreateContact = hasAllPermissions([Permission.ManageContacts, Permission.ManageCompanies]);

  const contactToAdd = useAppSelector<Contact>(selectSelectedContact);
  const isContactSelected = Object.keys(contactToAdd).length > 0;

  const companyJobTitles = useAppSelector<JobTitle[]>(selectCompanyJobTitles);
  const [inputValue, setInputValue] = useState('');

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStepAddContact());
  };

  const handleNextButtonClick = () => {
    dispatch(goToNextStepAddContact());
  };

  const handleCancelButtonClick = () => {
    dispatch(closeAddContactWizard());
  };

  const handleSearchByNameAndRole = useDebouncedCallback((searchText: string, role: string) => {
    dispatch(setPageNumber(1));
    dispatch(setSearchWizardContactsTextAndFilterItem({ searchText, role }));
    dispatch(pageOfWizardContactsRequested());
    setInputValue(searchText);
  }, debounceTimeInMilliseconds);

  const handleOpenWizard = () => {
    dispatch(resetContactToAdd());
    dispatch(openCreateContactWizard());
  };

  return (
    <>
      <AddContactSteps />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'companies-add-contact.wizard-search-title' })} description="" />
        <PanelToolbar
          filterItems={companyJobTitles.map((jobTitle) => jobTitle.name)}
          onSearchByNameAndRole={handleSearchByNameAndRole}
          onOpenWizard={handleOpenWizard}
          inputValue={inputValue}
          disabled={!hasPermissionToCreateContact}
          buttonMsg={formatMessage({ id: 'companies-add-contact.create' })}
        />
        <AllContactTable />
        <ContactsTablePaging />
        <CreateContactWizard />
        <AddContactWizardFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!isContactSelected}
        />
      </div>
    </>
  );
};
