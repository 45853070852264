import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { SavedSearchPanel, SavedSearch, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import { closeSavedSearchesPanel, firstPageOfContactsRequested, selectIsSavedSearchesPanelOpen, setQueryParams } from 'app/pages/my-audience/contacts';
import {
  selectIsLoadingContactSavedSearches,
  selectContactSavedSearches,
  selectContactSavedSearchToRemove,
  updateContactSavedSearchesRequested,
  updateContactSavedSearchRequested,
  setContactSavedSearchToRemove,
  removeContactSavedSearchRequested,
  selectIsDeleteDialogOpened,
  closeDeleteDialog,
  selectTotalCountOfSavedSearches,
  selectFilterItem,
  firstPageOfSavedSearchesRequested,
  contactsSavedSearchesRequested,
  selectSearchText,
  selectPageNumber
} from 'app/pages/my-audience/contacts-saved-searches';
import { FilterItem } from 'app/common';

export const ContactsSavedSearchesPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoadingContactSavedSearches = useAppSelector<boolean>(selectIsLoadingContactSavedSearches);
  const isDeleteDialogOpened = useAppSelector<boolean>(selectIsDeleteDialogOpened);
  const totalCountOfSavedSearches = useAppSelector<number>(selectTotalCountOfSavedSearches);
  const searchText = useAppSelector<string>(selectSearchText);
  const filterItem = useAppSelector<FilterItem>(selectFilterItem);
  const pageNumber = useAppSelector<number>(selectPageNumber);
  const contactSavedSearches = useAppSelector<SavedSearch[]>(selectContactSavedSearches);
  const savedSearchToRemove = useAppSelector<SavedSearch>(selectContactSavedSearchToRemove);
  const isSavedSearchesPanelOpen = useAppSelector<boolean>(selectIsSavedSearchesPanelOpen);

  const onUpdateContactSavedSearches = (reorderedSavedSearches: SavedSearch[]) => {
    // Update handles only reordering
    if (reorderedSavedSearches.length === totalCountOfSavedSearches) {
      dispatch(
        updateContactSavedSearchesRequested(
          reorderedSavedSearches.map((ss) => new SavedSearchWithIdInput(ss.id, ss.name, ss.searchText, ss.filterItems, ss.priority, ss.count))
        )
      );
    }
  };

  const onCloseDeleteDialog = () => {
    dispatch(closeDeleteDialog());
  };

  const onDismissSavedSearchesPanel = () => {
    dispatch(closeSavedSearchesPanel());
    navigate(-1);
  };

  const handleSearchSavedSearches = (searchText: string) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleFilterSavedSearches = (filterItem: FilterItem) => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem
      })
    );
  };

  const handleResetFilterSavedSearches = () => {
    dispatch(
      firstPageOfSavedSearchesRequested({
        searchText: searchText,
        filterItem: {} as FilterItem
      })
    );
  };

  const handleLoadMore = () => {
    dispatch(
      contactsSavedSearchesRequested({
        searchText: searchText,
        filterItem: filterItem,
        pageNumber: pageNumber + 1
      })
    );
  };

  const onSearchCompanySavedSearch = (savedSearch: SavedSearch) => {
    dispatch(setQueryParams({ searchText: savedSearch.searchText, filterItems: savedSearch.filterItems }));
    dispatch(firstPageOfContactsRequested());
    navigate(`/my-audience/contacts/results/${savedSearch.searchText ?? '*'}`);
  };

  const onSetCompanySavedSearchToRemove = (savedSearchToRemove: SavedSearch) => {
    dispatch(setContactSavedSearchToRemove(savedSearchToRemove));
  };

  const onUpdateContactSavedSearch = (updatedSavedSearch: SavedSearch) => {
    dispatch(updateContactSavedSearchRequested(updatedSavedSearch));
  };

  const onRemoveContactSavedSearch = () => {
    dispatch(removeContactSavedSearchRequested(savedSearchToRemove));
  };

  return (
    <SavedSearchPanel
      isOpen={isSavedSearchesPanelOpen}
      dismissPanel={onDismissSavedSearchesPanel}
      isLoadingSavedSearches={isLoadingContactSavedSearches}
      savedSearches={contactSavedSearches}
      totalCount={totalCountOfSavedSearches}
      onSearchSavedSearches={handleSearchSavedSearches}
      onFilterSavedSearches={handleFilterSavedSearches}
      onResetFilter={handleResetFilterSavedSearches}
      onHandleLoadMore={handleLoadMore}
      onSavedSearchClick={onSearchCompanySavedSearch}
      onReorderSavedSearches={onUpdateContactSavedSearches}
      onSetSavedSearchToRemove={onSetCompanySavedSearchToRemove}
      onUpdateSavedSearch={onUpdateContactSavedSearch}
      isDeleteDialogOpened={isDeleteDialogOpened}
      onRemoveSavedSearch={onRemoveContactSavedSearch}
      onCloseDeleteDialog={onCloseDeleteDialog}
      searchText={searchText}
    />
  );
};
