export class Blacklist {
  constructor(public id: string, public name: string) {}
}

export class CreateContactTableData {
  constructor(
    public key: string,
    public profilePictureUrl: JSX.Element,
    public name: string,
    public role: string,
    public emailAddress: string,
    public phoneNumber: string
  ) {}
}
