import { Menu } from '@fluentui/react-northstar';

interface NavigationMenuProps {
  onSelectMenuItem: any;
  activeIndex: number;
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ onSelectMenuItem, activeIndex }) => {
  const onMenuItemClick = (e) => {
    onSelectMenuItem(e.target.lastChild.textContent);
  };

  const items = [
    {
      key: 'profile',
      content: 'Profile',
      className: `profile-panel-tabs-item ${activeIndex === 0 ? 'active' : ''}`
    },
    {
      key: 'contacts',
      content: 'Contacts',
      className: `profile-panel-tabs-item ${activeIndex === 1 ? 'active' : ''}`
    }
  ];

  return (
    <div className="profile-panel-tabs">
      <Menu onItemClick={onMenuItemClick} items={items} underlined primary activeIndex={activeIndex} />
    </div>
  );
};
