import { defaultCompanyId, hideUndoAlert, UndoableDialog, useAppDispatch } from 'app/common';
import {
  Company,
  removeCompanyFromStore,
  removeCompanyRequested,
  setIsRemoveCompanyDialogOpen,
  undoRemoveCompanyFromStore
} from 'app/pages/my-audience/companies';
import { closeProfilePanel } from 'app/pages/my-audience/company-profile';

interface DeleteCompanyDialogProps {
  opened: boolean;
  dialogTitle: string;
  undoAlertMessage: string;
  selectedCompany: Company;
  dialogText?: string;
  warningMessages?: string[];
}

export const DeleteCompanyDialog = ({ opened, dialogTitle, dialogText, undoAlertMessage, selectedCompany, warningMessages }: DeleteCompanyDialogProps) => {
  const dispatch = useAppDispatch();
  const isCompanyExternal = !!selectedCompany.dataSourceKey;
  const isDefaultCompany = selectedCompany.id === defaultCompanyId;

  const onCloseDialog = () => {
    dispatch(setIsRemoveCompanyDialogOpen(false));
  };

  const onExecuteAction = () => {
    dispatch(removeCompanyRequested(selectedCompany));
    dispatch(hideUndoAlert());
  };

  const onExecuteInStore = () => {
    dispatch(removeCompanyFromStore(selectedCompany));
    dispatch(closeProfilePanel());
  };

  const onRevertAction = () => {
    dispatch(undoRemoveCompanyFromStore(selectedCompany));
  };

  const printTitle = () => {
    if (isCompanyExternal || isDefaultCompany) return;
    return dialogTitle;
  };

  return (
    <UndoableDialog
      opened={opened}
      dialogTitle={printTitle()}
      dialogText={dialogText}
      alertMessage={undoAlertMessage}
      onExecuteAction={onExecuteAction}
      onExecuteInStore={onExecuteInStore}
      onRevertAction={onRevertAction}
      onClose={onCloseDialog}
      warningMessages={warningMessages}
      deleteButtonDisabled={isCompanyExternal || isDefaultCompany}
    />
  );
};
