import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddContactWizardStep,
  AddContact,
  AddProfileInformation,
  AddContactFinish,
  closeAddContactWizard,
  selectIsAddContactWizardOpened,
  selectCurrentStep
} from 'app/pages/my-audience/media-outlet-profile';

export const AddContactWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isAddContactWizardOpened = useAppSelector<boolean>(selectIsAddContactWizardOpened);
  const currentStep = useAppSelector<AddContactWizardStep>(selectCurrentStep);

  const handleDismissButtonClick = () => {
    dispatch(closeAddContactWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isAddContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'mo-add-contact.wizard-header-title' })}
      customWidth="750px"
    >
      <div className="wizard-panel-main">
        {currentStep === AddContactWizardStep.SearchContact && <AddContact />}
        {currentStep === AddContactWizardStep.ProfileInformation && <AddProfileInformation />}
        {currentStep === AddContactWizardStep.Finish && <AddContactFinish />}
      </div>
    </WizardWrap>
  );
};
