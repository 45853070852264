import { Icon } from '@fluentui/react';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';
import 'app/pages/my-activities/templates/components/TemplateNewCard.scss';

interface TemplateNewCardProps {
  onAddNewSendingClick: () => void;
}

export const TemplateNewCard = ({ onAddNewSendingClick }: TemplateNewCardProps) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();

  const canCreateTemplate = hasPermission(Permission.ManageEmailings);

  const handleOnClick = () => {
    if (!canCreateTemplate) {
      return;
    }

    onAddNewSendingClick();
  };

  return (
    <div className="template-new-card">
      <div className="template-new-card-text">{formatMessage({ id: 'templates-preview.new-blank-email' })}</div>
      <div className="template-new-card-content" onClick={handleOnClick}>
        <div className="template-new-card-icon">
          <Icon iconName="Add" />
        </div>
      </div>
    </div>
  );
};
