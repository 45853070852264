import { useEffect } from 'react';

import { FilterItem, useAppDispatch } from 'app/common';
import { Companies, setQueryParams } from 'app/pages/my-audience/companies';
import { firstPageOfSavedSearchesRequested } from 'app/pages/my-audience/companies-saved-searches';

export const CompaniesWrap = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setQueryParams({ filterItems: [], searchText: '' }));
    dispatch(firstPageOfSavedSearchesRequested({ searchText: '', filterItem: {} as FilterItem }));
  }, [dispatch]);

  return <Companies />;
};
