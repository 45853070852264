import { Comment, ITableData, MediumCategory, TagProjection } from 'app/common';
import { MediumContactData } from 'app/pages/my-audience/media-outlets';
import { CompaniesSortOption as GQLCompaniesSortOption, CompaniesSortOrder as GQLCompaniesSortOrder } from 'app/common/graphql/generated/graphql-gateway';

export class Company {
  constructor(
    public id: string,
    public name: string,
    public profilePictureUrl: string,
    public parentCompany: string,
    public contactData: MediumContactData,
    public databaseType: string,
    public registrationNumber: string,
    public tags: TagProjection[],
    public comments: string[],
    public companyContacts: CompanyContact[],
    public userComments: Comment[],
    public foundationDate: Date,
    public competitors: string[],
    public profit: number,
    public director: string,
    public description: string,
    public category: MediumCategory,
    public dataSourceKey?: string
  ) {}
}

export class CompanyContact {
  constructor(public id: string, public firstName: string, public lastName: string, public profilePictureUrl: string) {}
}

export class CompanyUpdateModel {
  constructor(
    public name: string,
    public profilePictureUrl: string,
    public parentCompany: string,
    public contactData: MediumContactData,
    public databaseType: string,
    public registrationNumber: string,
    public comments: string[],
    public competitors: string[],
    public profit: number,
    public director: string,
    public description: string,
    public category: MediumCategory,
    public foundationDate: Date
  ) {}
}

export class CompanySearchResultTableData implements ITableData {
  constructor(
    public id: string,
    public key: string,
    public profilePictureUrl: string,
    public name: string,
    public companyType: string,
    public country: string,
    public phoneNumber: string,
    public databaseType: string,
    public tags: string,
    public registrationNumber: string,
    public street: string,
    public postalCode: string,
    public city: string,
    public fax: string,
    public website: string,
    public poBox: string,
    public type: string,
    public dataSourceKey: string
  ) {}
}

export class PageOfCompanies {
  constructor(public totalCount: number, public companies: Company[]) {}
}

export enum CompaniesSortOption {
  CompanyName = GQLCompaniesSortOption.CompanyName,
  MediumCategoryName = GQLCompaniesSortOption.MediumCategoryName,
  Country = GQLCompaniesSortOption.Country,
  MobilePhoneNumber = GQLCompaniesSortOption.MobilePhoneNumber,
  DatabaseType = GQLCompaniesSortOption.DatabaseType,
  Tags = GQLCompaniesSortOption.Tags,
  RegistrationNumber = GQLCompaniesSortOption.RegistrationNumber,
  Address = GQLCompaniesSortOption.Address,
  PostalCode = GQLCompaniesSortOption.PostalCode,
  City = GQLCompaniesSortOption.City,
  Fax = GQLCompaniesSortOption.Fax,
  Website = GQLCompaniesSortOption.Website,
  PoBox = GQLCompaniesSortOption.PoBox,
  CreatedAt = GQLCompaniesSortOption.CreatedAt
}

export enum CompaniesSortOrder {
  Desc = GQLCompaniesSortOrder.Desc,
  Asc = GQLCompaniesSortOrder.Asc
}

export interface ICompaniesSortingInput {
  sortOption: CompaniesSortOption;
  sortOrder: CompaniesSortOrder;
}

export class DefaultCompaniesSortingInput implements ICompaniesSortingInput {
  sortOption: CompaniesSortOption = CompaniesSortOption.CompanyName;
  sortOrder: CompaniesSortOrder = CompaniesSortOrder.Asc;
}

export class CompaniesSortingInput implements ICompaniesSortingInput {
  sortOption: CompaniesSortOption;
  sortOrder: CompaniesSortOrder;

  constructor(sortOptionParam: string, sortOrderParam: CompaniesSortOrder) {
    this.sortOption = this.determineSortingOption(sortOptionParam);
    this.sortOrder = sortOrderParam;
  }

  private determineSortingOption = (sortOption: string): CompaniesSortOption => {
    switch (sortOption) {
      case 'name':
        return CompaniesSortOption.CompanyName;

      case 'companyType':
        return CompaniesSortOption.MediumCategoryName;

      case 'country':
        return CompaniesSortOption.Country;

      case 'phoneNumber':
        return CompaniesSortOption.MobilePhoneNumber;

      case 'databaseType':
        return CompaniesSortOption.DatabaseType;

      case 'tags':
        return CompaniesSortOption.Tags;

      case 'registrationNumber':
        return CompaniesSortOption.RegistrationNumber;

      case 'street':
        return CompaniesSortOption.Address;

      case 'postalCode':
        return CompaniesSortOption.PostalCode;

      case 'city':
        return CompaniesSortOption.City;

      case 'fax':
        return CompaniesSortOption.Fax;

      case 'website':
        return CompaniesSortOption.Website;

      case 'poBox':
        return CompaniesSortOption.PoBox;

      case 'createdAt':
        return CompaniesSortOption.CreatedAt;

      default:
        return CompaniesSortOption.CompanyName;
    }
  };
}
