import { isStringEmpty, printData, WizardFinishIntro, WizardIntro } from 'app/common';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { FormattedMessage, useIntl } from 'app/i18n';
import {
  addCompanyRequested,
  closeCreateCompanyWizard,
  CompanyAddressInfo,
  CompanyGeneralInfo,
  CreateCompanyFooter,
  CreateCompanyFromContactStep,
  CreateCompanySteps,
  disableCreateCompanyWizardFinishButton,
  goToCompanyStep,
  goToPreviousCompanyStep,
  selectCompanyAddressInfo,
  selectCompanyGeneralInfo
} from 'app/pages/my-audience/wizard-create-contact';

export const CreateCompanyFinish = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const generalInfoToAdd = useAppSelector<CompanyGeneralInfo>(selectCompanyGeneralInfo);
  const addressInfoToAdd = useAppSelector<CompanyAddressInfo>(selectCompanyAddressInfo);

  const handleCancelButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousCompanyStep());
  };

  const handleEdit = (step: CreateCompanyFromContactStep) => {
    dispatch(goToCompanyStep(step));
  };

  const handleFinishButtonClick = () => {
    dispatch(disableCreateCompanyWizardFinishButton());
    dispatch(addCompanyRequested());
    dispatch(closeCreateCompanyWizard());
  };

  return (
    <>
      <CreateCompanySteps />
      <div className="wizard-panel-finish-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.company-finish-title' })} description="" />
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                title={formatMessage({ id: 'add-menu-create-contact.company-finish-general' })}
                onEditButtonClick={() => handleEdit(CreateCompanyFromContactStep.GeneralInfo)}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-name-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.name)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-parent-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.parentCompany)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-comment-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.comment)}</span>
                </div>
              </div>
            </div>
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                title={formatMessage({ id: 'add-menu-create-contact.company-finish-address' })}
                onEditButtonClick={() => handleEdit(CreateCompanyFromContactStep.Address)}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-address-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.address)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-po-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.poBox)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-postal-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.postalCode)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-city-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.city)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-country-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.country)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-website-label'} />
                  </span>
                  {!isStringEmpty(addressInfoToAdd.websiteUrl) ? (
                    <a href={addressInfoToAdd.websiteUrl} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                      {printData(addressInfoToAdd.websiteUrl)}
                    </a>
                  ) : (
                    <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.websiteUrl)}</span>
                  )}
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-email-label'} />
                  </span>
                  {!isStringEmpty(addressInfoToAdd.emailAddress) ? (
                    <a href={'mailto:' + addressInfoToAdd.emailAddress} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                      {printData(addressInfoToAdd.emailAddress)}
                    </a>
                  ) : (
                    <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.emailAddress)}</span>
                  )}
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-phone-label'} />
                  </span>
                  {!isStringEmpty(addressInfoToAdd.phoneNumber) ? (
                    <a href={'tel:+' + addressInfoToAdd.phoneNumber} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                      {printData(addressInfoToAdd.phoneNumber)}
                    </a>
                  ) : (
                    <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.phoneNumber)}</span>
                  )}
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.company-fax-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.fax)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateCompanyFooter
        onCancelButtonClick={handleCancelButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onFinishButtonClick={handleFinishButtonClick}
      />
    </>
  );
};
