import { WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import { AddCompanyTable, CreateCompanyToolbar, CreateCompanyWizard, CompaniesTablePaging } from 'app/pages/my-audience/contact-profile';

export const SearchCompany = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="wizard-panel-form-wrap">
      <WizardIntro
        title={formatMessage({ id: 'add-medium-wizard-steps.search-medium' }, { mediumType: formatMessage({ id: 'labels.company' }) })}
        description=""
      />
      <CreateCompanyToolbar />
      <AddCompanyTable />
      <CompaniesTablePaging />
      <CreateCompanyWizard />
    </div>
  );
};
