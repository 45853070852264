import { FilterItem, Search, SearchSuggestionCategory, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  clearAllFilters,
  companiesSuggestionsRequested,
  firstPageOfCompaniesRequested,
  resetCompaniesSuggestions,
  selectCompaniesSearchSuggestions,
  selectCompaniesSearchSuggestionsText,
  selectIsLoadingSearchSuggestions,
  selectSearchText,
  setFilterItems,
  setSearchText,
  startSearchAnimation,
  stopSearchAnimation
} from 'app/pages/my-audience/companies';

interface CompaniesSearchProps {
  onOutsideClick?: () => void;
}

export const CompaniesSearch = ({ onOutsideClick }: CompaniesSearchProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const searchSuggestionsCategories = useAppSelector<SearchSuggestionCategory[]>(selectCompaniesSearchSuggestions);
  const searchSuggestionsText = useAppSelector<string>(selectCompaniesSearchSuggestionsText);
  const isLoadingSearchSuggestions = useAppSelector<boolean>(selectIsLoadingSearchSuggestions);
  const searchText = useAppSelector<string>(selectSearchText);

  const handleClearAllFilters = () => {
    dispatch(clearAllFilters());
  };

  const handleSetSearchText = (searchText: string) => {
    dispatch(setSearchText(searchText));
  };

  const handleSetFilterItems = (filterItems: FilterItem[]) => {
    dispatch(setFilterItems(filterItems));
  };

  const handleResetSuggestions = () => {
    dispatch(resetCompaniesSuggestions());
  };

  const handleSearchSuggestions = (searchSuggestionsText: string) => {
    dispatch(companiesSuggestionsRequested(searchSuggestionsText));
  };

  const handleOnAnimationStart = () => {
    dispatch(startSearchAnimation());
  };

  const handleOnAnimationStop = () => {
    dispatch(stopSearchAnimation());
  };

  const handleSearchCompanies = () => {
    dispatch(firstPageOfCompaniesRequested());
  };

  return (
    <Search
      searchSuggestionsCategories={searchSuggestionsCategories}
      searchSuggestionsText={searchSuggestionsText}
      onClearAllFilters={handleClearAllFilters}
      onResetSuggestions={handleResetSuggestions}
      onSearchSuggestions={handleSearchSuggestions}
      searchText={searchText}
      isLoadingSearchSuggestions={isLoadingSearchSuggestions}
      onAnimationStart={handleOnAnimationStart}
      onAnimationStop={handleOnAnimationStop}
      onSetSearchText={handleSetSearchText}
      onSetFilterItems={handleSetFilterItems}
      onSearch={handleSearchCompanies}
      viewAllMessage={formatMessage({ id: 'search.view-all-companies' })}
      onOutsideClick={onOutsideClick}
    />
  );
};
