import { useState } from 'react';
import { Button } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react';
import {
  SearchResultTableData,
  selectIsOnTouch,
  selectSelectedTableRows,
  useAppDispatch,
  useAppSelector,
  CreateSendingDialog,
  useDeepLinkNavigation,
  selectIsOnMobile
} from 'app/common';
import { ListsSearch, ListsSearchResultsIntroText } from 'app/pages/my-audience/lists-search-results';
import { createNewBlankEmailRequested, createNewEmailWithRecipientsFromListRequested } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';

export const ListsSearchResultsIntro = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  useDeepLinkNavigation();

  const selectedTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);

  const [isCreateSendingDialogVisible, setIsCreateSendingDialogVisible] = useState<boolean>(false);
  const [isCreateEmailingButtonDisabled, setIsCreateEmailingButtonDisabled] = useState<boolean>(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);

  const onOpenCreateSendingDialog = () => {
    setIsCreateSendingDialogVisible(true);
  };

  const onCloseCreateSendingDialog = () => {
    setIsCreateSendingDialogVisible(false);
  };

  const onCreateSending = () => {
    setIsCreateEmailingButtonDisabled(true);

    if (selectedTableRows.length === 0) {
      dispatch(createNewBlankEmailRequested(formatMessage({ id: 'emailing-wizard.message-with-no-title-text' })));
    } else {
      dispatch(
        createNewEmailWithRecipientsFromListRequested({
          selectedListIds: selectedTableRows.map((r) => r.id),
          sendingTitle: formatMessage({ id: 'emailing-wizard.message-with-no-title-text' })
        })
      );
    }
  };

  const onSearchOutsideClick = () => {
    setIsSearchBarVisible(false);
  };

  return (
    <div className="search-results-intro">
      <div className="search-results-top">
        <ListsSearchResultsIntroText />
        <div className="search-result-actions">
          <div className="search-results-buttons">
            {!isOnTouch && (
              <div className="add-emailing-button">
                <Button primary onClick={onOpenCreateSendingDialog} disabled={isCreateEmailingButtonDisabled}>
                  <Icon iconName="Add" />{' '}
                  {selectedTableRows.length === 0
                    ? formatMessage({ id: 'search-results-header.create-emailing-button' })
                    : formatMessage({ id: 'search-results-header.send-emailing-button' }, { count: selectedTableRows.length })}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="search-results-component">
        {!isOnTouch && <ListsSearch />} {isOnTouch && isSearchBarVisible && <ListsSearch onOutsideClick={onSearchOutsideClick} />}
        <div className="search-results-more-wrap">
          {isOnMobile && !isSearchBarVisible && (
            <Button className="button-search" onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
              <Icon iconName="Search" />
            </Button>
          )}
        </div>
      </div>
      <CreateSendingDialog
        isDialogVisible={isCreateSendingDialogVisible}
        onCloseDialog={onCloseCreateSendingDialog}
        dialogMessage={
          selectedTableRows.length === 0
            ? formatMessage({ id: 'lists-search-results-table.create-emailing-message-no-recipients' })
            : formatMessage({ id: 'lists-search-results-table.create-emailing-message-with-recipients' })
        }
        onCreateSending={onCreateSending}
      />
    </div>
  );
};
