import { FormattedMessage } from 'app/i18n';

type SelectedEntitiesLabelProps = {
  counter: number;
  alwaysDisplay?: boolean;
};

export const SelectedEntitiesLabel = ({ counter, alwaysDisplay = false }: SelectedEntitiesLabelProps) => {
  if (counter === 0 && !alwaysDisplay) {
    return null;
  }

  return (
    <div className="table-toolbar-column">
      <span className="table-toolbar-text">
        <FormattedMessage id={'selected-entities-counter.selected'} values={{ counter }} />
      </span>
    </div>
  );
};
