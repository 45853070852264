import { useDispatch } from 'react-redux';
import { Icon } from '@fluentui/react';
import {
  ChangePhotoDialog,
  isTextInputValid,
  maximumMediaOutletNameLength,
  EditableInputTextField,
  selectIsOnDesktop,
  ThreeDotMenu,
  ThreeDotMenuItems,
  useAppSelector,
  openBottomPopup
} from 'app/common';
import { MediaOutlet } from 'app/pages/my-audience/media-outlets';
import {
  MediaOutletQuickActions,
  MediaOutletTags,
  openChangeImageDialog,
  selectSelectedMediaOutlet,
  setIsRemoveMediaOutletDialogOpened,
  setIsTransformMediaOutletDialogOpened,
  DeleteMediaOutletDialog,
  selectIsRemoveMediaOutletDialogOpened,
  updateMediaOutletRequested,
  selectImageToUpload,
  selectProfilePicturePreviewUrl,
  selectIsChangeImageDialogOpened,
  changeProfilePictureToUpload,
  removeProfilePictureRequested,
  updateProfilePictureRequested,
  removeProfilePicturePreview,
  closeChangeImageDialog
} from 'app/pages/my-audience/media-outlet-profile';
import { ProfilePanelAvatar } from 'app/pages/my-audience/common/components/profile-panel-avatar/ProfilePanelAvatar';
import { useIntl } from 'app/i18n';
import { useAuthContext, Permission } from 'auth';
import { Button } from '@fluentui/react-northstar';

export const MediaOutletProfilePanelIntro = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { hasPermission, hasAllPermissions } = useAuthContext();

  const selectedMediaOutlet = useAppSelector<MediaOutlet>(selectSelectedMediaOutlet);
  const isRemoveMediaOutletDialogOpened = useAppSelector<boolean>(selectIsRemoveMediaOutletDialogOpened);

  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const hasPermissionToEdit = hasPermission(Permission.ManageMediaOutlets);
  const hasPermissionToTransformIntoCompany = hasAllPermissions([Permission.ManageMediaOutlets, Permission.ManageCompanies, Permission.ManageContacts]);

  const handleDeleteMediaOutlet = () => {
    dispatch(setIsRemoveMediaOutletDialogOpened(true));
  };

  const handleTransforMediaOutletDialogOpen = () => {
    dispatch(setIsTransformMediaOutletDialogOpened(true));
  };

  const threeDotMenuItems: ThreeDotMenuItems[] = [
    {
      key: 'transform-into-company',
      title: formatMessage({ id: 'mo-profile-panel.transform' }),
      className: 'panel-intro-menu-item',
      icon: <Icon iconName="ContactCard" />,
      onClick: handleTransforMediaOutletDialogOpen,
      disabled: !hasPermissionToTransformIntoCompany
    },
    {
      key: 'delete-media-outlet',
      title: formatMessage({ id: 'mo-profile-panel.delete' }),
      className: 'panel-intro-menu-item danger',
      icon: <Icon iconName="Delete" />,
      onClick: handleDeleteMediaOutlet,
      disabled: !hasPermissionToEdit
    }
  ];

  const handleOnAvatarClick = () => {
    dispatch(openChangeImageDialog());
  };

  const onSaveChanges = (fieldName, updatedValue) => {
    const updatedList = { ...selectedMediaOutlet, [fieldName]: updatedValue };
    dispatch(updateMediaOutletRequested(updatedList));
  };

  const mediaOutletIsExternal = !!selectedMediaOutlet.dataSourceKey;

  const getWarningMessages = () => {
    const messages = [];

    if (mediaOutletIsExternal) {
      messages.push(formatMessage({ id: 'delete-media-outlet-dialog.external-db-message' }, { name: `${selectedMediaOutlet?.name}` }));
    }

    return messages;
  };

  return (
    <>
      <div className="panel-intro">
        <ProfilePanelAvatar
          onClick={handleOnAvatarClick}
          lastName={selectedMediaOutlet.name}
          firstName=""
          imageUrl={selectedMediaOutlet.profilePictureUrl}
          disabled={!hasPermissionToEdit}
        />
        <div className="panel-intro-content editable">
          <div className="panel-intro-title">
            <EditableInputTextField
              value={selectedMediaOutlet.name}
              fieldName="name"
              onChange={(fieldName, updatedValue) => onSaveChanges(fieldName, updatedValue)}
              isLink={false}
              isFieldValid={(value: string) => isTextInputValid({ inputValue: value, maxInputLength: maximumMediaOutletNameLength })}
              isEditDisabled={mediaOutletIsExternal}
              editDisabledMessageKey={'button-tooltips.external-database-media-outlet'}
              disabled={!hasPermissionToEdit}
            />
          </div>
          <MediaOutletTags />
          <MediaOutletQuickActions />
          <div className="panel-intro-menu">{isOnDesktop && <ThreeDotMenu threeDotMenuItems={threeDotMenuItems} />}</div>
          {!isOnDesktop && (
            <div className="panel-intro-menu">
              <Button
                iconOnly
                icon={
                  <Icon
                    iconName={'MoreVertical'}
                    onClick={() => {
                      dispatch(openBottomPopup(threeDotMenuItems.map((item) => ({ ...item, className: 'bottom-popup-items' }))));
                    }}
                  />
                }
              ></Button>
            </div>
          )}
        </div>
      </div>
      <ChangePhotoDialog
        selectImageToUpload={selectImageToUpload}
        selectProfilePicturePreviewUrl={selectProfilePicturePreviewUrl}
        selectIsDialogOpened={selectIsChangeImageDialogOpened}
        name={selectedMediaOutlet.name}
        changeProfilePictureToUpload={changeProfilePictureToUpload}
        removeProfilePictureRequested={removeProfilePictureRequested}
        updateProfilePictureRequested={updateProfilePictureRequested}
        removeProfilePicturePreview={removeProfilePicturePreview}
        closeDialog={closeChangeImageDialog}
      />
      <DeleteMediaOutletDialog
        selectedMediaOutlet={selectedMediaOutlet}
        opened={isRemoveMediaOutletDialogOpened}
        dialogTitle={formatMessage({ id: 'delete-media-outlet-dialog.are-you-sure' }, { name: selectedMediaOutlet?.name })}
        undoAlertMessage={formatMessage({ id: 'delete-media-outlet-dialog.media-outlet-deleted' }, { name: selectedMediaOutlet?.name })}
        warningMessages={getWarningMessages()}
      />
    </>
  );
};
