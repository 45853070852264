import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { Comment, CommentUser, FilterItem, MediumCategory, ProfilePicture, Tag, TagProjection } from 'app/common';
import { Recipient, Sending, SendingStatus } from 'app/pages/my-activities/sendings';
import { Company } from 'app/pages/my-audience/companies';
import {
  AddCompanyWizardSteps,
  AddMediaOutletWizardSteps,
  CompanyAddressInfo,
  CompanyGeneralInfo,
  CompanyProfileInfo,
  ContactUpdateModel,
  createActivityCardsData,
  CreateCompanyWizardSteps,
  createMediaOutlet,
  CreateMediaOutletWizardSteps,
  MediaOutletAddressInfo,
  MediaOutletCollaborationProfileInfo,
  MediaOutletGeneralInfo,
  Medium,
  SendingsOverviewViewModel
} from 'app/pages/my-audience/contact-profile';
import {
  Activity,
  ActivityCardModel,
  ActivityTypes,
  Appointment,
  Blacklist,
  Call,
  Chat,
  Collaboration,
  Contact,
  ContactData,
  Email,
  Event,
  JobTitle,
  MediaResort,
  MediumType,
  OwnContactData,
  PhoneNumber,
  Task,
  Topic,
  Webinar
} from 'app/pages/my-audience/contacts';
import { List } from 'app/pages/my-audience/lists';
import { MediaOutlet, MediumContactData } from 'app/pages/my-audience/media-outlets';
import { RootState } from 'app/redux/store';
import { cloneDeep } from 'lodash';
export interface ContactProfileState {
  selectedContactId: string;
  contactPreviousValue: Contact;
  contactDataPreviousValue: Collaboration | OwnContactData;
  selectedContactData: Collaboration | OwnContactData;
  selectedContact: Contact;
  selectedCollaborationToUpdate: Collaboration;
  selectedOwnContactDataToUpdate: OwnContactData;
  selectedContactToUpdate: ContactUpdateModel;
  selectedContactHasTeamsEnabled: boolean;
  isProfilePanelOpened: boolean;
  contactPanelActiveIndex: number;
  isLoadingContactProfileInfo: boolean;
  areMoreEditableFieldsVisible: boolean;
  collaborationToAdd: Collaboration;
  collaborationToRemove: Collaboration;
  isRemoveMediaOutletDialogVisible: boolean;
  isRemoveCompanyDialogVisible: boolean;
  collaborationProfileInfo: MediaOutletCollaborationProfileInfo;
  profilePicturePreviewUrl: string;
  imageToUpload: ProfilePicture;
  isChangeImageDialogOpened: boolean;
  isNumberDialogOpened: boolean;
  isRemoveContactDialogOpened: boolean;
  selectedContactActivities: Activity[];
  selectedContactRecipients: Recipient[];
  filteredActivities: Activity[];
  activityTypeToLog: ActivityTypes;
  localizedActivityTypeToLog: string;
  activityToLog: Email | Call | Appointment | Webinar | Event | Chat | Task;
  sendingsOverview: SendingsOverviewViewModel;
  selectedActivity: Activity;
  selectedSending: Sending;
  isActivityDetailsDialogOpened: boolean;
  isAddActivityDialogOpened: boolean;
  isLoadingActivities: boolean;
  commentToRemove: Comment;
  commentToAdd: Comment;
  commentMentions: CommentUser[];
  contactVCardStringValue: string;
  isVcardAttachmentVisible: boolean;
  isQRCodeDialogOpened: boolean;
  mediaOutletActiveIndex: number[];
  mediaOutletActiveClass: string;
  companyActiveIndex: number[];
  companyActiveClass: string;
  activityEmailAddress: string;
  companyGeneralInfoToAdd: CompanyGeneralInfo;
  companyAddressInfoToAdd: CompanyAddressInfo;
  companyProfileInfoToAdd: CompanyProfileInfo;
  isAddCompanyWizardFinishButtonDisabled: boolean;
  isAddCompanyWizardProfileInfoStepValid: boolean;
  isCreateCompanyWizardFinishButtonDisabled: boolean;
  isCreateCompanyGeneralInfoStepValid: boolean;
  isCreateCompanyAddressInfoStepValid: boolean;
  isCreateCompanyProfileInfoStepValid: boolean;
  isCreateMediaOutletGeneralInfoStepValid: boolean;
  isCreateMediaOutletAddressInfoStepValid: boolean;
  isCreateMediaOutletProfileInfoStepValid: boolean;
  isAddCompanyWizardOpened: boolean;
  addCompanyCurrentStep: AddCompanyWizardSteps;
  createCompanyCurrentStep: CreateCompanyWizardSteps;
  isCreateCompanyWizardOpened: boolean;
  isAddMediaWizardOpened: boolean;
  addMediaOutletCurrentStep: AddMediaOutletWizardSteps;
  existingMediaOutletToAddId: string;
  mediaOutletToAdd: MediaOutlet;
  isAddMediaOutletWizardFinishButtonDisabled: boolean;
  isAddMediaOutletWizardProfileInfoStepValid: boolean;
  mediaOutlets: MediaOutlet[];
  mediaOutletsTotalCount: number;
  isLoadingMediaOutlets: boolean;
  mediaOutletsPageNumber: number;
  mediaOutletsPageSize: number;
  mediaOutletsSearchText: string;
  mediaOutletsCategoryFilter: string;
  mediaOutletGeneralInfoToAdd: MediaOutletGeneralInfo;
  mediaOutletAddressInfoToAdd: MediaOutletAddressInfo;
  isCreateMediaOutletWizardFinishButtonDisabled: boolean;
  createMediaOutletCurrentStep: CreateMediaOutletWizardSteps;
  isCreateMediaWizardOpened: boolean;
  companies: Company[];
  companiesPageNumber: number;
  companiesPageSize: number;
  companiesSearchText: string;
  companiesCategoryFilter: string;
  companiesTotalCount: number;
  isLoadingCompanies: boolean;
  companyToAdd: Company;
  isLoadingAllTags: boolean;
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToContact: TagProjection;
  tagToRemoveFromContact: TagProjection;
  isLoadingAllTopics: boolean;
  allTopics: Topic[];
  filteredTopics: Topic[];
  topicToAddToContact: Topic;
  topicToRemoveFromContact: Topic;
  topicToAdd: Topic;
  topicsToDelete: Topic[];
  mediaResortsToDelete: MediaResort[];
  allMediaResorts: MediaResort[];
  filteredMediaResorts: MediaResort[];
  mediaResortToAddToContact: MediaResort;
  mediaResortToRemoveFromContact: MediaResort;
  isLoadingAllMediaResorts: boolean;
  mediaResortToAdd: MediaResort;
  filteredLists: List[];
  listToAdd: List;
  listToRemove: List;
  listToAddToContact: List;
  listIdToRemoveFromContact: string;
  isLoadingLists: boolean;
  allBlacklists: Blacklist[];
  blacklistToAdd: string;
  blacklistToRemove: Blacklist;
  selectedContactBlacklists: Blacklist[];
  filteredBlacklists: Blacklist[];
  blacklistToAddToContact: Blacklist;
  blacklistToRemoveFromContact: Blacklist;
  isLoadingAllBlacklists: boolean;
  blacklistIdsToRemove: string[];
  activitiesPageNumber: number;
  activitiesPageSize: number;
  totalCountOfActivities: number;
  loadedActivitiesCount: number;
  isLoadingMoreActivities: boolean;
  activitiesFilterItems: FilterItem[];
  selectedContactSendingActivities: Sending[];
  listsSearchText: string;
  listsPageNumber: number;
  listsPageSize: number;
  selectedContactLists: List[];
  shouldNavigateToNewlyCreatedList: boolean;
  isChoosingContactDataAsPrimary: boolean;
  isChoosingPhoneNumberDefaultState: boolean;
  emailAddressToCheckTeamsAccount: string;
}

const initialState: ContactProfileState = {
  selectedContactId: '',
  selectedContact: {} as Contact,
  selectedContactHasTeamsEnabled: false,
  selectedContactActivities: [],
  selectedContactRecipients: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToContact: {} as TagProjection,
  tagToRemoveFromContact: {} as TagProjection,
  allTopics: [],
  filteredTopics: [],
  topicToAddToContact: {} as Topic,
  topicToRemoveFromContact: {} as Topic,
  mediaResortsToDelete: [],
  allMediaResorts: [],
  filteredMediaResorts: [],
  mediaResortToAddToContact: {} as MediaResort,
  mediaResortToRemoveFromContact: {} as MediaResort,
  filteredLists: [],
  listToAdd: {} as List,
  listToRemove: {} as List,
  listToAddToContact: {} as List,
  listIdToRemoveFromContact: '',
  isLoadingAllTags: true,
  isLoadingAllTopics: true,
  isLoadingAllMediaResorts: true,
  isLoadingLists: true,
  sendingsOverview: {} as SendingsOverviewViewModel,
  selectedContactData: {} as Collaboration,
  selectedCollaborationToUpdate: {} as Collaboration,
  selectedContactToUpdate: {} as ContactUpdateModel,
  selectedOwnContactDataToUpdate: {} as OwnContactData,
  isLoadingContactProfileInfo: true,
  imageToUpload: {} as ProfilePicture,
  profilePicturePreviewUrl: '',
  contactVCardStringValue: '',
  isProfilePanelOpened: false,
  collaborationToAdd: {} as Collaboration,
  collaborationToRemove: {} as Collaboration,
  isVcardAttachmentVisible: false,
  filteredActivities: [],
  activityTypeToLog: ActivityTypes.Email,
  localizedActivityTypeToLog: '',
  activityToLog: {} as Activity,
  selectedActivity: {} as Activity,
  selectedSending: {} as Sending,
  commentToAdd: {} as Comment,
  commentToRemove: {} as Comment,
  commentMentions: [],
  allBlacklists: [],
  blacklistToAdd: '',
  blacklistToRemove: {} as Blacklist,
  blacklistToAddToContact: {} as Blacklist,
  blacklistToRemoveFromContact: {} as Blacklist,
  selectedContactBlacklists: [],
  filteredBlacklists: [],
  isLoadingAllBlacklists: true,
  contactPanelActiveIndex: 0,
  isRemoveContactDialogOpened: false,
  contactPreviousValue: {} as Contact,
  contactDataPreviousValue: {} as OwnContactData,
  mediaOutletActiveIndex: [0],
  mediaOutletActiveClass: '',
  companyActiveIndex: [0],
  companyActiveClass: '',
  activityEmailAddress: '',
  companyGeneralInfoToAdd: {} as CompanyGeneralInfo,
  companyAddressInfoToAdd: {} as CompanyAddressInfo,
  companyProfileInfoToAdd: {} as CompanyProfileInfo,
  mediaOutletGeneralInfoToAdd: {} as MediaOutletGeneralInfo,
  mediaOutletAddressInfoToAdd: {} as MediaOutletAddressInfo,
  collaborationProfileInfo: {} as MediaOutletCollaborationProfileInfo,
  isActivityDetailsDialogOpened: false,
  isAddActivityDialogOpened: false,
  isLoadingActivities: false,
  isLoadingMoreActivities: false,
  addMediaOutletCurrentStep: AddMediaOutletWizardSteps.SearchMediaOutlet,
  isAddMediaWizardOpened: false,
  createMediaOutletCurrentStep: CreateMediaOutletWizardSteps.GeneralInformation,
  isCreateMediaWizardOpened: false,
  isAddCompanyWizardOpened: false,
  addCompanyCurrentStep: AddCompanyWizardSteps.SearchCompany,
  createCompanyCurrentStep: CreateCompanyWizardSteps.GeneralInformation,
  isCreateCompanyWizardOpened: false,
  mediaOutlets: [],
  mediaOutletsCategoryFilter: '',
  mediaOutletsPageNumber: 1,
  mediaOutletsPageSize: 10,
  mediaOutletsSearchText: '',
  mediaOutletsTotalCount: 0,
  isLoadingMediaOutlets: true,
  companies: [],
  companiesCategoryFilter: '',
  companiesPageNumber: 1,
  companiesPageSize: 10,
  companiesSearchText: '',
  companiesTotalCount: 0,
  isLoadingCompanies: true,
  mediaOutletToAdd: {} as MediaOutlet,
  existingMediaOutletToAddId: '',
  companyToAdd: {} as Company,
  isChangeImageDialogOpened: false,
  isNumberDialogOpened: false,
  isQRCodeDialogOpened: false,
  areMoreEditableFieldsVisible: false,
  isRemoveMediaOutletDialogVisible: false,
  isRemoveCompanyDialogVisible: false,
  isAddCompanyWizardFinishButtonDisabled: false,
  isAddCompanyWizardProfileInfoStepValid: false,
  isAddMediaOutletWizardFinishButtonDisabled: false,
  isAddMediaOutletWizardProfileInfoStepValid: false,
  isCreateCompanyWizardFinishButtonDisabled: false,
  isCreateCompanyGeneralInfoStepValid: false,
  isCreateCompanyAddressInfoStepValid: false,
  isCreateCompanyProfileInfoStepValid: false,
  isCreateMediaOutletWizardFinishButtonDisabled: false,
  isCreateMediaOutletGeneralInfoStepValid: false,
  isCreateMediaOutletAddressInfoStepValid: false,
  isCreateMediaOutletProfileInfoStepValid: false,
  mediaResortToAdd: {} as MediaResort,
  topicToAdd: {} as Topic,
  topicsToDelete: [],
  blacklistIdsToRemove: [],
  activitiesPageNumber: 1,
  activitiesPageSize: 5,
  totalCountOfActivities: 0,
  loadedActivitiesCount: 0,
  activitiesFilterItems: [],
  selectedContactSendingActivities: [],
  listsSearchText: '',
  listsPageNumber: 1,
  listsPageSize: 10,
  selectedContactLists: [],
  shouldNavigateToNewlyCreatedList: false,
  isChoosingContactDataAsPrimary: false,
  isChoosingPhoneNumberDefaultState: false,
  emailAddressToCheckTeamsAccount: ''
};

export type IsRemoveContactDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsRemoveContactDialogOpened: IsRemoveContactDialogOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isRemoveContactDialogOpened],
  (isRemoveContactDialogOpened: boolean) => isRemoveContactDialogOpened
);

export type FilteredListsSelector = (state: RootState) => List[];
export const selectFilteredLists: FilteredListsSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredLists],
  (filteredLists: List[]) => filteredLists
);

export type MediaOutletActiveIndexSelector = (state: RootState) => number[];
export const selectMediaOutletActiveIndex: MediaOutletActiveIndexSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletActiveIndex],
  (mediaOutletActiveIndex: number[]) => mediaOutletActiveIndex
);

export type CompanyActiveIndexSelector = (state: RootState) => number[];
export const selectCompanyActiveIndex: CompanyActiveIndexSelector = createSelector(
  [(state: RootState) => state.contactProfile.companyActiveIndex],
  (companyActiveIndex: number[]) => companyActiveIndex
);

export type MediaOutletActiveClassSelector = (state: RootState) => string;
export const selectMediaOutletActiveClass: MediaOutletActiveClassSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletActiveClass],
  (mediaOutletActiveClass: string) => mediaOutletActiveClass
);

export type CompanyActiveClassSelector = (state: RootState) => string;
export const selectCompanyActiveClass: CompanyActiveClassSelector = createSelector(
  [(state: RootState) => state.contactProfile.companyActiveClass],
  (companyActiveClass: string) => companyActiveClass
);

export type SelectedContactIdSelector = (state: RootState) => string;
export const selectSelectedContactId: SelectedContactIdSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactId],
  (selectedContactId: string) => selectedContactId
);

export type SelectedContactBlacklistsSelector = (state: RootState) => Blacklist[];
export const selectSelectedContactBlacklists: SelectedContactBlacklistsSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactBlacklists],
  (selectedContactBlacklists: Blacklist[]) => selectedContactBlacklists
);

export type FilteredBlacklistsSelector = (state: RootState) => Blacklist[];
export const selectFilteredBlacklists: FilteredBlacklistsSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredBlacklists],
  (filteredBlacklists: Blacklist[]) => filteredBlacklists
);

export type SelectedContactSelector = (state: RootState) => Contact;
export const selectSelectedContact: SelectedContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContact],
  (selectedContact: Contact) => selectedContact
);

export type SelectedContactHasTeamsEnabled = (state: RootState) => boolean;
export const selectSelectedContactHasTeamsEnabled: SelectedContactHasTeamsEnabled = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactHasTeamsEnabled],
  (selectedContactHasTeamsEnabled: boolean) => selectedContactHasTeamsEnabled
);

export type SelectedPrimaryContactDataSelector = (state: RootState) => ContactData;
export const selectSelectedPrimaryContactData: SelectedPrimaryContactDataSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContact.collaborations],
  (collaborations: Collaboration[]) => collaborations.find((item) => item.isPrimary)
);

export type SelectedContactTagsHeaderValueSelector = (state: RootState) => Tag[];
export const selectContactTagsHeaderValue: SelectedContactTagsHeaderValueSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContact.tags],
  (tags: Tag[]) => tags
);

export type tagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: tagNameToCreateSelector = createSelector(
  [(state: RootState) => state.contactProfile.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type AllMediaResortsNamesSelector = (state: RootState) => string[];
export const selectAllMediaResortsNames: AllMediaResortsNamesSelector = createSelector(
  [(state: RootState) => state.contactProfile.allMediaResorts],
  (allMediaResorts: MediaResort[]) => allMediaResorts.map((mr) => mr.name)
);

export type AllMediaResortsSelector = (state: RootState) => MediaResort[];
export const selectAllMediaResorts: AllMediaResortsSelector = createSelector(
  [(state: RootState) => state.contactProfile.allMediaResorts],
  (allMediaResorts: MediaResort[]) => allMediaResorts
);

export type FilteredMediaResortsSelector = (state: RootState) => MediaResort[];
export const selectFilteredMediaResorts: FilteredMediaResortsSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredMediaResorts],
  (filteredMediaResorts: MediaResort[]) => filteredMediaResorts
);

export type MediaResortToAddToContactSelector = (state: RootState) => MediaResort;
export const selectMediaResortToAddToContact: MediaResortToAddToContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaResortToAddToContact],
  (mediaResortToAddToContact: MediaResort) => mediaResortToAddToContact
);

export type MediaResortToRemoveFromContactSelector = (state: RootState) => MediaResort;
export const selectMediaResortToRemoveFromContact: MediaResortToRemoveFromContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaResortToRemoveFromContact],
  (mediaResortToRemoveFromContact: MediaResort) => mediaResortToRemoveFromContact
);

export type AllTopicsNamesSelector = (state: RootState) => string[];
export const selectAllTopicsNames: AllTopicsNamesSelector = createSelector([(state: RootState) => state.contactProfile.allTopics], (allTopics: Topic[]) =>
  allTopics.map((topic) => topic.name)
);

export type AllTopicsSelector = (state: RootState) => Topic[];
export const selectAllTopics: AllTopicsSelector = createSelector([(state: RootState) => state.contactProfile.allTopics], (allTopics: Topic[]) => allTopics);

export type FilteredTopicsSelector = (state: RootState) => Topic[];
export const selectFilteredTopics: FilteredTopicsSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredTopics],
  (filteredTopics: Topic[]) => filteredTopics
);

export type TopicToAddToContactSelector = (state: RootState) => Topic;
export const selectTopicToAddToContact: TopicToAddToContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.topicToAddToContact],
  (topicToAddToContact: Topic) => topicToAddToContact
);

export type TopicToRemoveFromContactSelector = (state: RootState) => Topic;
export const selectTopicToRemoveFromContact: TopicToRemoveFromContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.topicToRemoveFromContact],
  (topicToRemoveFromContact: Topic) => topicToRemoveFromContact
);

export type SendingsOverviewSelector = (state: RootState) => SendingsOverviewViewModel;
export const selectSendingsOverview: SendingsOverviewSelector = createSelector(
  [(state: RootState) => state.contactProfile.sendingsOverview],
  (sendingsOverview: SendingsOverviewViewModel) => sendingsOverview
);

export type SelectedContactDataSelector = (state: RootState) => Collaboration | OwnContactData;
export const selectSelectedContactData: SelectedContactDataSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactData],
  (selectedContactData: Collaboration | OwnContactData) => selectedContactData
);

export type SelectedMediumIdSelector = (state: RootState) => string;
export const selectSelectedMediumId: SelectedMediumIdSelector = createSelector(
  [(state: RootState) => (state.contactProfile.selectedContactData as Collaboration).medium],
  (medium: Medium) => medium.id
);

export type SelectedJobTitleSelector = (state: RootState) => string;
export const selectSelectedRole: SelectedJobTitleSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactData as Collaboration],
  (selectedContactData: Collaboration) => selectedContactData.jobTitle.name
);

export type SelectedCollaborationToUpdateSelector = (state: RootState) => Collaboration;
export const selectSelectedCollaborationToUpdate: SelectedCollaborationToUpdateSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedCollaborationToUpdate],
  (selectedCollaborationToUpdate: Collaboration) => selectedCollaborationToUpdate
);

export type SelectedOwnContactDataToUpdateSelector = (state: RootState) => OwnContactData;
export const selectSelectedOwnContactDataToUpdate: SelectedOwnContactDataToUpdateSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedOwnContactDataToUpdate],
  (selectedOwnContactDataToUpdate: OwnContactData) => selectedOwnContactDataToUpdate
);

export type SelectedContactToUpdateSelector = (state: RootState) => ContactUpdateModel;
export const selectSelectedContactToUpdate: SelectedContactToUpdateSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactToUpdate],
  (selectedContactToUpdate: ContactUpdateModel) => selectedContactToUpdate
);

export type IsLoadingContactProfileInfoSelector = (state: RootState) => boolean;
export const selectIsLoadingContactProfileInfo: IsLoadingContactProfileInfoSelector = createSelector(
  [(state: RootState) => state.contactProfile.isLoadingContactProfileInfo],
  (isLoadingContactProfileInfo: boolean) => isLoadingContactProfileInfo
);

export type ImageToUploadSelector = (state: RootState) => ProfilePicture;
export const selectImageToUpload: ImageToUploadSelector = createSelector(
  [(state: RootState) => state.contactProfile.imageToUpload],
  (imageToUpload: ProfilePicture) => imageToUpload
);

export type ProfilePicturePreviewUrlSelector = (state: RootState) => string;
export const selectProfilePicturePreviewUrl: ProfilePicturePreviewUrlSelector = createSelector(
  [(state: RootState) => state.contactProfile.profilePicturePreviewUrl],
  (profilePicturePreviewUrl: string) => profilePicturePreviewUrl
);

export type ListToAddSelector = (state: RootState) => List;
export const selectListToAdd: ListToAddSelector = createSelector([(state: RootState) => state.contactProfile.listToAdd], (listToAdd: List) => listToAdd);

export type MediaResortToAddSelector = (state: RootState) => MediaResort;
export const selectMediaResortToAdd: MediaResortToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaResortToAdd],
  (mediaResortToAdd: MediaResort) => mediaResortToAdd
);

export type TopicToAddSelector = (state: RootState) => Topic;
export const selectTopicToAdd: TopicToAddSelector = createSelector([(state: RootState) => state.contactProfile.topicToAdd], (topicToAdd: Topic) => topicToAdd);

export type TopicsToDeleteSelector = (state: RootState) => Topic[];
export const selectTopicsToDelete: TopicsToDeleteSelector = createSelector(
  [(state: RootState) => state.contactProfile.topicsToDelete],
  (topicsToDelete: Topic[]) => topicsToDelete
);

export type MediaResortsToDeleteSelector = (state: RootState) => MediaResort[];
export const selectMediaResortsToDelete: MediaResortsToDeleteSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaResortsToDelete],
  (mediaResortsToDelete: MediaResort[]) => mediaResortsToDelete
);

export type ListToRemoveSelector = (state: RootState) => List;
export const selectListToRemove: ListToRemoveSelector = createSelector(
  [(state: RootState) => state.contactProfile.listToRemove],
  (listToRemove: List) => listToRemove
);

export type ListToAddToContactSelector = (state: RootState) => List;
export const selectListToAddToContact: ListToAddToContactSelector = createSelector(
  (state: RootState) => state.contactProfile.listToAddToContact,
  (listToAddToContact: List) => listToAddToContact
);

export type ListIdToRemoveFromContactSelector = (state: RootState) => string;
export const selectListIdToRemoveFromContact: ListIdToRemoveFromContactSelector = createSelector(
  (state: RootState) => state.contactProfile.listIdToRemoveFromContact,
  (listIdToRemoveFromContact: string) => listIdToRemoveFromContact
);

export type ContactVCardStringValueSelector = (state: RootState) => string;
export const selectContactVCardStringValue: ContactVCardStringValueSelector = createSelector(
  [(state: RootState) => state.contactProfile.contactVCardStringValue],
  (contactVCardStringValue: string) => contactVCardStringValue
);

export type IsProfilePanelOpenedSelector = (state: RootState) => boolean;
export const selectIsProfilePanelOpened: IsProfilePanelOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isProfilePanelOpened],
  (isProfilePanelOpened: boolean) => isProfilePanelOpened
);

export type CollaborationToAddSelector = (state: RootState) => Collaboration;
export const selectCollaborationToAdd: CollaborationToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.collaborationToAdd],
  (collaborationToAdd: Collaboration) => collaborationToAdd
);

export type CollaborationToRemoveSelector = (state: RootState) => Collaboration;
export const selectCollaborationToRemove: CollaborationToRemoveSelector = createSelector(
  [(state: RootState) => state.contactProfile.collaborationToRemove],
  (collaborationToRemove: Collaboration) => collaborationToRemove
);

export type IsVcardAttachmentVisibleSelector = (state: RootState) => boolean;
export const selectIsVcardAttachmentVisible: IsVcardAttachmentVisibleSelector = createSelector(
  [(state: RootState) => state.contactProfile.isVcardAttachmentVisible],
  (isVcardAttachmentVisible: boolean) => isVcardAttachmentVisible
);

export type FilteredActivitiesSelector = (state: RootState) => Activity[];
export const selectFilteredActivities: FilteredActivitiesSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredActivities],
  (filteredActivities: Activity[]) => filteredActivities
);

export type FilteredActivitiesCardsSelector = (state: RootState) => ActivityCardModel[];
export const selectFilteredActivitiesCards: FilteredActivitiesCardsSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredActivities],
  (filteredActivities: Activity[]) => createActivityCardsData(filteredActivities)
);

export type FilteredPastActivitiesCardsSelector = (state: RootState) => ActivityCardModel[];
export const selectFilteredPastActivitiesCards: FilteredPastActivitiesCardsSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredActivities],
  (filteredActivities: Activity[]) => {
    return createActivityCardsData(filteredActivities.filter((a) => DateTime.fromJSDate(a.date).toMillis() < Date.now()));
  }
);

export type FilteredUpcomingActivitiesCardsSelector = (state: RootState) => ActivityCardModel[];
export const selectFilteredUpcomingActivitiesCards: FilteredUpcomingActivitiesCardsSelector = createSelector(
  [(state: RootState) => state.contactProfile.filteredActivities],
  (filteredActivities: Activity[]) => createActivityCardsData(filteredActivities.filter((a) => DateTime.fromJSDate(a.date).toMillis() > Date.now()))
);

export type ActivityTypeToLogSelector = (state: RootState) => ActivityTypes;
export const selectActivityTypeToLog: ActivityTypeToLogSelector = createSelector(
  [(state: RootState) => state.contactProfile.activityTypeToLog],
  (activityTypeToLog: ActivityTypes) => activityTypeToLog
);

export type LocalizedActivityTypeToLogSelector = (state: RootState) => string;
export const selectLocalizedActivityTypeToLog: LocalizedActivityTypeToLogSelector = createSelector(
  [(state: RootState) => state.contactProfile.localizedActivityTypeToLog],
  (localizedActivityTypeToLog: string) => localizedActivityTypeToLog
);

export type ActivityToLogSelector = (state: RootState) => Activity;
export const selectActivityToLog: ActivityToLogSelector = createSelector(
  [(state: RootState) => state.contactProfile.activityToLog],
  (activityToLog: Activity) => activityToLog
);

export type SelectedContactCommentsSelector = (state: RootState) => Comment[];
export const selectSelectedContactComments: SelectedContactCommentsSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContact.comments],
  (comments: Comment[]) => comments
);

export type SelectedContactListsSelector = (state: RootState) => List[];
export const selectSelectedContactLists: SelectedContactListsSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactLists],
  (lists: List[]) => lists
);

export type CommentToAddToContactSelector = (state: RootState) => Comment;
export const selectCommentToAddToContact: CommentToAddToContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.commentToAdd],
  (commentToAdd: Comment) => commentToAdd
);

export type CommentToRemoveFromContactSelector = (state: RootState) => Comment;
export const selectCommentToRemoveFromContact: CommentToRemoveFromContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.commentToRemove],
  (commentToRemove: Comment) => commentToRemove
);

export type CommentMentionsSelector = (state: RootState) => CommentUser[];
export const selectCommentMentions: CommentMentionsSelector = createSelector(
  [(state: RootState) => state.contactProfile.commentMentions],
  (commentMentions: CommentUser[]) => commentMentions
);

export type SelectedActivitySelector = (state: RootState) => Activity;
export const selectSelectedActivity: SelectedActivitySelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedActivity],
  (selectedActivity: Activity) => selectedActivity
);

export type SelectedSendingSelector = (state: RootState) => Sending;
export const selectSelectedSending: SelectedSendingSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedSending],
  (selectedSending: Sending) => selectedSending
);

export type AllBlacklistsValuesSelector = (state: RootState) => Blacklist[];
export const selectAllBlacklistsValues: AllBlacklistsValuesSelector = createSelector(
  [(state: RootState) => state.contactProfile.allBlacklists],
  (allBlacklists: Blacklist[]) => allBlacklists
);

export type AllBlacklistsNamesSelector = (state: RootState) => string[];
export const selectAllBlacklistsNames: AllBlacklistsNamesSelector = createSelector(
  [(state: RootState) => state.contactProfile.allBlacklists],
  (allBlacklists: Blacklist[]) => allBlacklists.map((bl) => bl.name)
);

export type BlacklistToAddSelector = (state: RootState) => string;
export const selectBlacklistToAdd: BlacklistToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.blacklistToAdd],
  (blacklistToAdd: string) => blacklistToAdd
);

export type BlacklistToRemoveSelector = (state: RootState) => Blacklist;
export const selectBlacklistToRemove: BlacklistToRemoveSelector = createSelector(
  [(state: RootState) => state.contactProfile.blacklistToRemove],
  (blacklistToRemove: Blacklist) => blacklistToRemove
);

export type BlacklistToAddToContactSelector = (state: RootState) => Blacklist;
export const selectBlacklistToAddToContact: BlacklistToAddToContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.blacklistToAddToContact],
  (blacklistToAddToContact: Blacklist) => blacklistToAddToContact
);

export type BlacklistToRemoveFromContactSelector = (state: RootState) => Blacklist;
export const selectBlacklistToRemoveFromContact: BlacklistToRemoveFromContactSelector = createSelector(
  [(state: RootState) => state.contactProfile.blacklistToRemoveFromContact],
  (blacklistToRemoveFromContact: Blacklist) => blacklistToRemoveFromContact
);

export type MediaOutletToAddSelector = (state: RootState) => MediaOutlet;
export const selectMediaOutletToAdd: MediaOutletToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletToAdd],
  (mediaOutletToAdd: MediaOutlet) => mediaOutletToAdd
);

export type ExistingMediaOutletToAddIdSelector = (state: RootState) => string;
export const selectExistingMediaOutletToAddId: ExistingMediaOutletToAddIdSelector = createSelector(
  [(state: RootState) => state.contactProfile.existingMediaOutletToAddId],
  (existingMediaOutletToAddId: string) => existingMediaOutletToAddId
);

export type CompanyToAddSelector = (state: RootState) => Company;
export const selectCompanyToAdd: CompanyToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.companyToAdd],
  (companyToAdd: Company) => companyToAdd
);

export type CompanyCollaborationsSelector = (state: RootState) => Collaboration[];
export const selectCompanyCollaborations: CompanyCollaborationsSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContact],
  (selectedContact: Contact) => selectedContact.collaborations.filter((c) => c.medium.type === MediumType.Company)
);

export type MediaOutletCollaborationsSelector = (state: RootState) => Collaboration[];
export const selectMediaOutletCollaborations: MediaOutletCollaborationsSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContact],
  (selectedContact: Contact) => selectedContact.collaborations.filter((c) => c.medium.type === MediumType.MediaOutlet)
);

export type SelectedContactActivitiesSelector = (state: RootState) => Activity[];
export const selectSelectedContactActivities: SelectedContactActivitiesSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactActivities],
  (selectedContactActivities: Activity[]) => selectedContactActivities
);

export type SelectedContactSendingActivitiesSelector = (state: RootState) => Sending[];
export const selectSelectedContactSendingActivities: SelectedContactSendingActivitiesSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactSendingActivities],
  (selectedContactSendingActivities: Sending[]) => selectedContactSendingActivities
);

export type SelectedContactRecipientsSelector = (state: RootState) => Recipient[];
export const selectSelectedContactRecipients: SelectedContactRecipientsSelector = createSelector(
  [(state: RootState) => state.contactProfile.selectedContactRecipients],
  (selectedContactRecipients: Recipient[]) => selectedContactRecipients
);

export type ActivityEmailAddressSelector = (state: RootState) => string;
export const selectActivityEmailAddress: ActivityEmailAddressSelector = createSelector(
  [(state: RootState) => state.contactProfile.activityEmailAddress],
  (activityEmailAddress: string) => activityEmailAddress
);

export type CompanyGeneralInfoToAddSelector = (state: RootState) => CompanyGeneralInfo;
export const selectCompanyGeneralInfoToAdd: CompanyGeneralInfoToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.companyGeneralInfoToAdd],
  (companyGeneralInfoToAdd: CompanyGeneralInfo) => companyGeneralInfoToAdd
);

export type CompanyAddressInfoToAddSelector = (state: RootState) => CompanyAddressInfo;
export const selectCompanyAddressInfoToAdd: CompanyAddressInfoToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.companyAddressInfoToAdd],
  (companyAddressInfoToAdd: CompanyAddressInfo) => companyAddressInfoToAdd
);

export type CompanyProfileInfoToAddSelector = (state: RootState) => CompanyProfileInfo;
export const selectCompanyProfileInfoToAdd: CompanyProfileInfoToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.companyProfileInfoToAdd],
  (companyProfileInfoToAdd: CompanyProfileInfo) => companyProfileInfoToAdd
);

export type MediaOutletGeneralInfoToAddSelector = (state: RootState) => MediaOutletGeneralInfo;
export const selectMediaOutletGeneralInfoToAdd: MediaOutletGeneralInfoToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletGeneralInfoToAdd],
  (mediaOutletGeneralInfoToAdd: MediaOutletGeneralInfo) => mediaOutletGeneralInfoToAdd
);

export type MediaOutletAddressInfoToAddSelector = (state: RootState) => MediaOutletAddressInfo;
export const selectMediaOutletAddressInfoToAdd: MediaOutletAddressInfoToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletAddressInfoToAdd],
  (mediaOutletAddressInfoToAdd: MediaOutletAddressInfo) => mediaOutletAddressInfoToAdd
);

export type CollaborationInfoToAddSelector = (state: RootState) => MediaOutletCollaborationProfileInfo;
export const selectCollaborationInfoToAdd: CollaborationInfoToAddSelector = createSelector(
  [(state: RootState) => state.contactProfile.collaborationProfileInfo],
  (collaborationProfileInfo: MediaOutletCollaborationProfileInfo) => collaborationProfileInfo
);

export type IsActivityDetailsDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsActivityDetailsDialogOpened: IsActivityDetailsDialogOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isActivityDetailsDialogOpened],
  (isActivityDetailsDialogOpened: boolean) => isActivityDetailsDialogOpened
);

export type IsAddActivityDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsAddActivityDialogOpened: IsAddActivityDialogOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isAddActivityDialogOpened],
  (isAddActivityDialogOpened: boolean) => isAddActivityDialogOpened
);

export type IsLoadingActivitiesSelector = (state: RootState) => boolean;
export const selectIsLoadingActivities: IsLoadingActivitiesSelector = createSelector(
  [(state: RootState) => state.contactProfile.isLoadingActivities],
  (isLoadingActivities: boolean) => isLoadingActivities
);

export type IsLoadingMoreActivitiesSelector = (state: RootState) => boolean;
export const selectIsLoadingMoreActivities: IsLoadingMoreActivitiesSelector = createSelector(
  [(state: RootState) => state.contactProfile.isLoadingMoreActivities],
  (isLoadingMoreActivities: boolean) => isLoadingMoreActivities
);

export type AddMediaOutletCurrentStepSelector = (state: RootState) => AddMediaOutletWizardSteps;
export const selectAddMediaOutletCurrentStep: AddMediaOutletCurrentStepSelector = createSelector(
  [(state: RootState) => state.contactProfile.addMediaOutletCurrentStep],
  (addMediaOutletCurrentStep: AddMediaOutletWizardSteps) => addMediaOutletCurrentStep
);

export type IsAddMediaWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsAddMediaWizardOpened: IsAddMediaWizardOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isAddMediaWizardOpened],
  (isAddMediaWizardOpened: boolean) => isAddMediaWizardOpened
);

export type CreateMediaOutletCurrentStepSelector = (state: RootState) => CreateMediaOutletWizardSteps;
export const selectCreateMediaOutletCurrentStep: CreateMediaOutletCurrentStepSelector = createSelector(
  [(state: RootState) => state.contactProfile.createMediaOutletCurrentStep],
  (createMediaOutletCurrentStep: CreateMediaOutletWizardSteps) => createMediaOutletCurrentStep
);

export type IsCreateMediaWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsIsCreateMediaWizardOpened: IsCreateMediaWizardOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateMediaWizardOpened],
  (isCreateMediaWizardOpened: boolean) => isCreateMediaWizardOpened
);

export type IsAddCompanyWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsAddCompanyWizardOpened: IsAddCompanyWizardOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isAddCompanyWizardOpened],
  (isAddCompanyWizardOpened: boolean) => isAddCompanyWizardOpened
);

export type AddCompanyCurrentStepSelector = (state: RootState) => AddCompanyWizardSteps;
export const selectAddCompanyCurrentStep: AddCompanyCurrentStepSelector = createSelector(
  [(state: RootState) => state.contactProfile.addCompanyCurrentStep],
  (addCompanyCurrentStep: AddCompanyWizardSteps) => addCompanyCurrentStep
);

export type IsCreateCompanyWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyWizardOpened: IsCreateCompanyWizardOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateCompanyWizardOpened],
  (isCreateCompanyWizardOpened: boolean) => isCreateCompanyWizardOpened
);

export type CreateCompanyCurrentStepSelector = (state: RootState) => CreateCompanyWizardSteps;
export const selectCreateCompanyCurrentStep: CreateCompanyCurrentStepSelector = createSelector(
  [(state: RootState) => state.contactProfile.createCompanyCurrentStep],
  (createCompanyCurrentStep: CreateCompanyWizardSteps) => createCompanyCurrentStep
);

export type MediaOutletsSelector = (state: RootState) => MediaOutlet[];
export const selectMediaOutlets: MediaOutletsSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutlets],
  (mediaOutlets: MediaOutlet[]) => mediaOutlets
);

export type MediaOutletsTotalCountSelector = (state: RootState) => number;
export const selectMediaOutletsTotalCount: MediaOutletsTotalCountSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletsTotalCount],
  (mediaOutletsTotalCount: number) => mediaOutletsTotalCount
);

export type MediaOutletsPageNumberSelector = (state: RootState) => number;
export const selectMediaOutletsPageNumber: MediaOutletsPageNumberSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletsPageNumber],
  (mediaOutletsPageNumber: number) => mediaOutletsPageNumber
);

export type MediaOutletsPageSizeSelector = (state: RootState) => number;
export const selectMediaOutletsPageSize: MediaOutletsPageSizeSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletsPageSize],
  (mediaOutletsPageSize: number) => mediaOutletsPageSize
);

export type MediaOutletsSearchTextSelector = (state: RootState) => string;
export const selectMediaOutletsSearchText: MediaOutletsSearchTextSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletsSearchText],
  (mediaOutletsSearchText: string) => mediaOutletsSearchText
);

export type MediaOutletsCategoryFilterSelector = (state: RootState) => string;
export const selectMediaOutletsCategoryFilter: MediaOutletsCategoryFilterSelector = createSelector(
  [(state: RootState) => state.contactProfile.mediaOutletsCategoryFilter],
  (mediaOutletsCategoryFilter: string) => mediaOutletsCategoryFilter
);

export type IsLoadingMediaOutletsSelector = (state: RootState) => boolean;
export const selectIsLoadingMediaOutlets: IsLoadingMediaOutletsSelector = createSelector(
  [(state: RootState) => state.contactProfile.isLoadingMediaOutlets],
  (isLoadingMediaOutlets: boolean) => isLoadingMediaOutlets
);

export type CompaniesSelector = (state: RootState) => Company[];
export const selectCompanies: CompaniesSelector = createSelector([(state: RootState) => state.contactProfile.companies], (companies: Company[]) => companies);

export type CompaniesTotalCountSelector = (state: RootState) => number;
export const selectCompaniesTotalCount: CompaniesTotalCountSelector = createSelector(
  [(state: RootState) => state.contactProfile.companiesTotalCount],
  (companiesTotalCount: number) => companiesTotalCount
);

export type CompaniesPageNumberSelector = (state: RootState) => number;
export const selectCompaniesPageNumber: CompaniesPageNumberSelector = createSelector(
  [(state: RootState) => state.contactProfile.companiesPageNumber],
  (companiesPageNumber: number) => companiesPageNumber
);

export type CompaniesPageSizeSelector = (state: RootState) => number;
export const selectCompaniesPageSize: CompaniesPageSizeSelector = createSelector(
  [(state: RootState) => state.contactProfile.companiesPageSize],
  (companiesPageSize: number) => companiesPageSize
);

export type CompaniesSearchTextSelector = (state: RootState) => string;
export const selectCompaniesSearchText: CompaniesSearchTextSelector = createSelector(
  [(state: RootState) => state.contactProfile.companiesSearchText],
  (companiesSearchText: string) => companiesSearchText
);

export type CompaniesCategoryFilterSelector = (state: RootState) => string;
export const selectCompaniesCategoryFilter: CompaniesCategoryFilterSelector = createSelector(
  [(state: RootState) => state.contactProfile.companiesCategoryFilter],
  (companiesCategoryFilter: string) => companiesCategoryFilter
);

export type IsLoadingCompaniesSelector = (state: RootState) => boolean;
export const selectIsLoadingCompanies: IsLoadingCompaniesSelector = createSelector(
  [(state: RootState) => state.contactProfile.isLoadingCompanies],
  (isLoadingCompanies: boolean) => isLoadingCompanies
);

export type IsChangeImageDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsChangeImageDialogOpened: IsChangeImageDialogOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isChangeImageDialogOpened],
  (isChangeImageDialogOpened: boolean) => isChangeImageDialogOpened
);

export type IsNumberDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsNumberDialogOpened: IsNumberDialogOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isNumberDialogOpened],
  (isNumberDialogOpened: boolean) => isNumberDialogOpened
);

export type IsQRCodeDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsQRCodeDialogOpened: IsQRCodeDialogOpenedSelector = createSelector(
  [(state: RootState) => state.contactProfile.isQRCodeDialogOpened],
  (isQRCodeDialogOpened: boolean) => isQRCodeDialogOpened
);

export type AreMoreEditableFieldsVisibleSelector = (state: RootState) => boolean;
export const selectAreMoreEditableFieldsVisible: AreMoreEditableFieldsVisibleSelector = createSelector(
  [(state: RootState) => state.contactProfile.areMoreEditableFieldsVisible],
  (areMoreEditableFieldsVisible: boolean) => areMoreEditableFieldsVisible
);

export type IsRemoveMediaOutletDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsRemoveMediaOutletDialogVisible: IsRemoveMediaOutletDialogVisibleSelector = createSelector(
  [(state: RootState) => state.contactProfile.isRemoveMediaOutletDialogVisible],
  (isRemoveMediaOutletDialogVisible: boolean) => isRemoveMediaOutletDialogVisible
);

export type IsRemoveCompanyDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsRemoveCompanyDialogVisible: IsRemoveCompanyDialogVisibleSelector = createSelector(
  [(state: RootState) => state.contactProfile.isRemoveCompanyDialogVisible],
  (isRemoveCompanyDialogVisible: boolean) => isRemoveCompanyDialogVisible
);

export type IsAddCompanyWizardFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsAddCompanyWizardFinishButtonDisabled: IsAddCompanyWizardFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.contactProfile.isAddCompanyWizardFinishButtonDisabled],
  (isAddCompanyWizardFinishButtonDisabled: boolean) => isAddCompanyWizardFinishButtonDisabled
);

export type IsCreateCompanyGeneralInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyGeneralInfoStepValid: IsCreateCompanyGeneralInfoStepValidSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateCompanyGeneralInfoStepValid],
  (isAddCompanyGeneralInfoStepValid: boolean) => isAddCompanyGeneralInfoStepValid
);

export type IsCreateCompanyAddressInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyAddressInfoStepValid: IsCreateCompanyAddressInfoStepValidSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateCompanyAddressInfoStepValid],
  (isCreateCompanyAddressInfoStepValid: boolean) => isCreateCompanyAddressInfoStepValid
);

export type IsCreateCompanyProfileInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyProfileInfoStepValid: IsCreateCompanyProfileInfoStepValidSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateCompanyProfileInfoStepValid],
  (isCreateCompanyProfileInfoStepValid: boolean) => isCreateCompanyProfileInfoStepValid
);

export type IsCreateMediaOutletGeneralInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletGeneralInfoStepValid: IsCreateMediaOutletGeneralInfoStepValidSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateMediaOutletGeneralInfoStepValid],
  (isCreateMediaOutletGeneralInfoStepValid: boolean) => isCreateMediaOutletGeneralInfoStepValid
);

export type IsCreateMediaOutletAddressInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletAddressInfoStepValid: IsCreateMediaOutletAddressInfoStepValidSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateMediaOutletAddressInfoStepValid],
  (isCreateMediaOutletAddressInfoStepValid: boolean) => isCreateMediaOutletAddressInfoStepValid
);

export type IsCreateMediaOutletProfileInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletProfileInfoStepValid: IsCreateMediaOutletProfileInfoStepValidSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateMediaOutletProfileInfoStepValid],
  (isCreateMediaOutletProfileInfoStepValid: boolean) => isCreateMediaOutletProfileInfoStepValid
);

export type IsAddCompanyWizardProfileInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsAddCompanyWizardProfileInfoStepValid: IsAddCompanyWizardProfileInfoStepValidSelector = createSelector(
  (state: RootState) => state.contactProfile.isAddCompanyWizardProfileInfoStepValid,
  (isAddCompanyWizardProfileInfoStepValid: boolean) => isAddCompanyWizardProfileInfoStepValid
);

export type IsAddMediaOutletWizardFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsAddMediaOutletWizardFinishButtonDisabled: IsAddMediaOutletWizardFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.contactProfile.isAddMediaOutletWizardFinishButtonDisabled],
  (isAddMediaOutletWizardFinishButtonDisabled: boolean) => isAddMediaOutletWizardFinishButtonDisabled
);

export type IsAddMediaOutletWizardProfileInfoStepValidSelector = (state: RootState) => boolean;
export const selectIsAddMediaOutletWizardProfileInfoStepValid: IsAddMediaOutletWizardProfileInfoStepValidSelector = createSelector(
  (state: RootState) => state.contactProfile.isAddMediaOutletWizardProfileInfoStepValid,
  (isAddMediaOutletWizardProfileInfoStepValid: boolean) => isAddMediaOutletWizardProfileInfoStepValid
);

export type IsCreateCompanyWizardFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyWizardFinishButtonDisabled: IsCreateCompanyWizardFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateCompanyWizardFinishButtonDisabled],
  (isCreateCompanyWizardFinishButtonDisabled: boolean) => isCreateCompanyWizardFinishButtonDisabled
);

export type IsCreateMediaOutletWizardFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletWizardFinishButtonDisabled: IsCreateMediaOutletWizardFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.contactProfile.isCreateMediaOutletWizardFinishButtonDisabled],
  (isCreateMediaOutletWizardFinishButtonDisabled: boolean) => isCreateMediaOutletWizardFinishButtonDisabled
);

export type BlacklistIdsToRemoveSelector = (state: RootState) => string[];
export const selectBlacklistIdsToRemove: BlacklistIdsToRemoveSelector = createSelector(
  [(state: RootState) => state.contactProfile.blacklistIdsToRemove],
  (blacklistIdsToRemove: string[]) => blacklistIdsToRemove
);

export type ActivitiesPageNumberSelector = (state: RootState) => number;
export const selectActivitiesPageNumber: ActivitiesPageNumberSelector = createSelector(
  [(state: RootState) => state.contactProfile.activitiesPageNumber],
  (activitiesPageNumber: number) => activitiesPageNumber
);

export type ActivitiesPageSizeSelector = (state: RootState) => number;
export const selectActivitiesPageSize: ActivitiesPageSizeSelector = createSelector(
  [(state: RootState) => state.contactProfile.activitiesPageSize],
  (activitiesPageSize: number) => activitiesPageSize
);

export type TotalCountOfActivitiesSelector = (state: RootState) => number;
export const selectTotalCountOfActivities: TotalCountOfActivitiesSelector = createSelector(
  [(state: RootState) => state.contactProfile.totalCountOfActivities],
  (totalCountOfActivities: number) => totalCountOfActivities
);

export type LoadedActivitiesCountSelector = (state: RootState) => number;
export const selectLoadedActivitiesCount: LoadedActivitiesCountSelector = createSelector(
  [(state: RootState) => state.contactProfile.loadedActivitiesCount],
  (loadedActivitiesCount: number) => loadedActivitiesCount
);

export type FilterItemsSelector = (state: RootState) => FilterItem[];
export const selectActivitiesFilterItems: FilterItemsSelector = createSelector(
  [(state: RootState) => state.contactProfile.activitiesFilterItems],
  (activitiesFilterItems: FilterItem[]) => activitiesFilterItems
);

export type ListsSearchTextSelector = (state: RootState) => string;
export const selectListsSearchText: ListsSearchTextSelector = createSelector(
  [(state: RootState) => state.contactProfile.listsSearchText],
  (listsSearchText: string) => listsSearchText
);

export type ListsPageNumberSelector = (state: RootState) => number;
export const selectListsPageNumber: ListsPageNumberSelector = createSelector(
  (state: RootState) => state.contactProfile.listsPageNumber,
  (pageNumber: number) => pageNumber
);

export type ListsPageSizeSelector = (state: RootState) => number;
export const selectListsPageSize: ListsPageSizeSelector = createSelector(
  (state: RootState) => state.contactProfile.listsPageSize,
  (listsPageSize: number) => listsPageSize
);

export type ShouldNavigateToNewlyCreatedListSelector = (state: RootState) => boolean;
export const selectShouldNavigateToNewlyCreatedList: ShouldNavigateToNewlyCreatedListSelector = createSelector(
  (state: RootState) => state.contactProfile.shouldNavigateToNewlyCreatedList,
  (shouldNavigateToNewlyCreatedList: boolean) => shouldNavigateToNewlyCreatedList
);

export type IsChoosingContactDataAsPrimarySelector = (state: RootState) => boolean;
export const selectIsChoosingContactDataAsPrimary: IsChoosingContactDataAsPrimarySelector = createSelector(
  (state: RootState) => state.contactProfile.isChoosingContactDataAsPrimary,
  (isChoosingContactDataAsPrimary: boolean) => isChoosingContactDataAsPrimary
);

export type IsChoosingPhoneNumberDefaultStateSelector = (state: RootState) => boolean;
export const selectIsChoosingPhoneNumberDefaultState: IsChoosingPhoneNumberDefaultStateSelector = createSelector(
  (state: RootState) => state.contactProfile.isChoosingPhoneNumberDefaultState,
  (isChoosingPhoneNumberDefaultState: boolean) => isChoosingPhoneNumberDefaultState
);

export type EmailAddressToCheckTeamsAccountSelector = (state: RootState) => string;
export const selectEmailAddressToCheckTeamsAccount: EmailAddressToCheckTeamsAccountSelector = createSelector(
  (state: RootState) => state.contactProfile.emailAddressToCheckTeamsAccount,
  (emailAddressToCheckTeamsAccount: string) => emailAddressToCheckTeamsAccount
);

const contactProfileSlice = createSlice({
  name: 'contact-profile',
  initialState,
  reducers: {
    getSelectedContactInfoRequested: (state, action: PayloadAction<string>) => {
      state.selectedContactId = action.payload;
      state.isLoadingContactProfileInfo = true;
    },
    selectedContactInfoReceived: (state, action: PayloadAction<Contact>) => {
      state.selectedContact = action.payload;
      state.selectedContactData =
        action.payload.ownContactData && action.payload.ownContactData.isPrimary
          ? action.payload.ownContactData
          : action.payload.collaborations?.find((item) => item.isPrimary);

      state.isLoadingContactProfileInfo = false;
      state.profilePicturePreviewUrl = state.selectedContact.profilePictureUrl;
      state.selectedContactBlacklists = [...action.payload.blacklists];
      state.filteredBlacklists = state.selectedContact.blacklists.map((bl) => state.allBlacklists.find((b) => b.id === bl.id));
      state.selectedContactHasTeamsEnabled = false;
    },
    resetSelectedContactInfo: (state) => {
      state = initialState;
    },
    getAllTopicsRequested: (state) => {
      state.isLoadingAllTopics = true;
    },
    allTopicsReceived: (state, action: PayloadAction<Topic[]>) => {
      state.allTopics = action.payload;
      state.filteredTopics = action.payload;
      state.isLoadingAllTopics = false;
    },
    getAllMediaResortsRequested: (state) => {
      state.isLoadingAllMediaResorts = true;
    },
    allMediaResortsReceived: (state, action: PayloadAction<MediaResort[]>) => {
      state.allMediaResorts = action.payload;
      state.filteredMediaResorts = action.payload;
      state.isLoadingAllMediaResorts = false;
    },
    getListsByNameRequested: (state, action: PayloadAction<string>) => {
      state.listsPageNumber = 1;
      state.isLoadingLists = true;
      state.listsSearchText = action.payload;
    },
    listsByNameReceived: (state, action: PayloadAction<List[]>) => {
      state.filteredLists = action.payload;
      state.isLoadingLists = false;
    },
    addListRequested: (state, action: PayloadAction<List>) => {
      state.listToAdd = action.payload;
    },
    addListFromContactPanelRequested: (state, action: PayloadAction<List>) => {
      state.listToAdd = action.payload;
    },
    addedList: (state, action: PayloadAction<List>) => {
      state.filteredLists = [...state.filteredLists, action.payload];
      state.listToAdd = {} as List;
    },
    addMediaResortAndAssignToContactRequested: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortToAdd = action.payload;
    },
    addedMediaResort: (state, action: PayloadAction<MediaResort>) => {
      state.allMediaResorts = [...state.allMediaResorts, action.payload];
      state.filteredMediaResorts = [...state.filteredMediaResorts, action.payload];
      state.mediaResortToAdd = {} as MediaResort;
    },
    deleteTopicsRequested: (state, action: PayloadAction<Topic[]>) => {
      state.topicsToDelete = action.payload;
    },
    deletedTopics: (state, action: PayloadAction<string[]>) => {
      state.allTopics = state.allTopics.filter((t) => !action.payload.includes(t.id));
      state.filteredTopics = state.filteredTopics.filter((t) => !action.payload.includes(t.id));
      state.topicsToDelete = [];
      state.selectedContact = {
        ...state.selectedContact,
        topics: state.selectedContact.topics.filter((topic) => !action.payload.includes(topic.id))
      };
    },
    nextPageOfListsRequested: (state, action: PayloadAction<string>) => {
      state.listsPageNumber += 1;
      state.isLoadingLists = true;
      state.listsSearchText = action.payload;
    },
    nextPageOfListsReceived: (state, action: PayloadAction<List[]>) => {
      state.filteredLists = [...state.filteredLists, ...action.payload];
      state.isLoadingLists = false;
    },
    deleteMediaResortsRequested: (state, action: PayloadAction<MediaResort[]>) => {
      state.mediaResortsToDelete = action.payload;
    },
    deletedMediaResorts: (state, action: PayloadAction<string[]>) => {
      state.allMediaResorts = state.allMediaResorts.filter((t) => !action.payload.includes(t.id));
      state.filteredMediaResorts = state.filteredMediaResorts.filter((t) => !action.payload.includes(t.id));
      state.mediaResortsToDelete = [];
      state.selectedContact = {
        ...state.selectedContact,
        mediaResorts: state.selectedContact.mediaResorts.filter((mediaResort) => !action.payload.includes(mediaResort.id))
      };
    },
    addTopicRequested: (state, action: PayloadAction<Topic>) => {
      state.topicToAdd = action.payload;
    },
    addedTopic: (state, action: PayloadAction<Topic>) => {
      state.allTopics = [...state.allTopics, action.payload];
      state.filteredTopics = [...state.filteredTopics, action.payload];
      state.topicToAdd = {} as Topic;
    },
    addedTagToContact: (state, action: PayloadAction<TagProjection>) => {
      state.tagToAddToContact = {} as TagProjection;
      if (!state.selectedContact.tags?.every((tag) => tag.id !== action.payload.id)) return;
      state.selectedContact.tags = [...state.selectedContact.tags, action.payload];
    },
    removeTagFromContactInStore: (state, action: PayloadAction<TagProjection>) => {
      state.contactPreviousValue = cloneDeep(state.selectedContact);
      state.selectedContact.tags = state.selectedContact.tags.filter((tag) => tag.name !== action.payload.name);
    },
    removedTagsFromContact: (state, action: PayloadAction<TagProjection[]>) => {
      state.tagToRemoveFromContact = {} as TagProjection;
      if (!state.selectedContact.tags) return;
      const tagIds = action.payload.map((t) => t.id);
      state.selectedContact.tags = state.selectedContact.tags.filter((tag) => !tagIds.includes(tag.id));
    },
    addTopicToContactRequested: (state, action: PayloadAction<Topic>) => {
      state.topicToAddToContact = action.payload;
    },
    addedTopicToContact: (state, action: PayloadAction<Topic>) => {
      state.selectedContact = { ...state.selectedContact, topics: [...state.selectedContact.topics, action.payload] };
      state.topicToAddToContact = {} as Topic;
    },
    removeTopicFromContactRequested: (state, action: PayloadAction<Topic>) => {
      state.topicToRemoveFromContact = action.payload;
    },
    removedTopicFromContact: (state, action: PayloadAction<Topic>) => {
      state.selectedContact = {
        ...state.selectedContact,
        topics: state.selectedContact.topics.filter((topic) => topic.name !== action.payload.name)
      };
      state.topicToRemoveFromContact = {} as Topic;
    },
    addMediaResortToContactRequested: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortToAddToContact = action.payload;
    },
    addedMediaResortToContact: (state, action: PayloadAction<MediaResort>) => {
      state.selectedContact = { ...state.selectedContact, mediaResorts: [...state.selectedContact.mediaResorts, action.payload] };
      state.mediaResortToAddToContact = {} as MediaResort;
    },
    removeMediaResortFromContactRequested: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortToRemoveFromContact = action.payload;
    },
    removedMediaResortFromContact: (state, action: PayloadAction<MediaResort>) => {
      state.selectedContact = {
        ...state.selectedContact,
        mediaResorts: state.selectedContact.mediaResorts.filter((mediaResort) => mediaResort.name !== action.payload.name)
      };
      state.mediaResortToRemoveFromContact = {} as MediaResort;
    },
    addListToContactRequested: (state, action: PayloadAction<List>) => {
      state.listToAddToContact = action.payload;
    },
    addedListToContact: (state, action: PayloadAction<List>) => {
      state.selectedContactLists = [...state.selectedContactLists, action.payload];
      state.listToAddToContact = {} as List;
    },
    selectedContactListsRequested: (state) => {
      state.selectedContactLists = [];
    },
    selectedContactsListsReceived: (state, action: PayloadAction<List[]>) => {
      state.selectedContactLists = action.payload;
    },
    removeListFromContactRequested: (state, action: PayloadAction<string>) => {
      state.listIdToRemoveFromContact = action.payload;
    },
    removedListFromContact: (state, action: PayloadAction<string>) => {
      state.listToRemove = state.selectedContactLists.find((list) => list.id === action.payload);
      state.selectedContactLists = state.selectedContactLists.filter((list) => list.id !== action.payload);
      state.listIdToRemoveFromContact = '';
    },
    removeProfilePictureRequested: (state) => {
      state.imageToUpload = {} as ProfilePicture;
    },
    updateProfilePictureRequested: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
    },
    profilePictureUpdated: (state, action: PayloadAction<string>) => {
      state.selectedContact = { ...state.selectedContact, profilePictureUrl: action.payload };
      state.profilePicturePreviewUrl = action.payload;
    },
    removeProfilePicturePreview: (state) => {
      state.profilePicturePreviewUrl = '';
      state.imageToUpload = {} as ProfilePicture;
    },
    changeProfilePictureToUpload: (state, action: PayloadAction<ProfilePicture>) => {
      state.imageToUpload = action.payload;
      state.profilePicturePreviewUrl = state.selectedContact.profilePictureUrl;
    },
    changeSelectedContactData: (state, action: PayloadAction<string>) => {
      if (action.payload.includes('Own contact data')) {
        state.selectedContactData = state.selectedContact.ownContactData;
      } else {
        state.selectedContactData = state.selectedContact.collaborations.find((c) => c.id === action.payload);
      }
    },
    updateOwnContactDataInStore: (state, action: PayloadAction<OwnContactData>) => {
      state.contactDataPreviousValue = state.selectedContactData ? cloneDeep(state.selectedContactData) : ({} as OwnContactData);
      state.contactPreviousValue = state.selectedContact ? cloneDeep(state.selectedContact) : ({} as Contact);
      state.selectedContactData = action.payload;
      state.selectedContact = { ...state.selectedContact, ownContactData: action.payload };
    },
    updateCollaborationInStore: (state, action: PayloadAction<Collaboration>) => {
      state.contactPreviousValue = state.selectedContact ? cloneDeep(state.selectedContact) : ({} as Contact);
      state.contactDataPreviousValue = state.selectedContactData ? cloneDeep(state.selectedContactData) : ({} as Collaboration);
      state.selectedContactData = action.payload;
      const index = state.selectedContact.collaborations.findIndex((c) => c.medium.id === action.payload.medium.id);
      state.selectedContact = { ...state.selectedContact, collaborations: state.selectedContact.collaborations.splice(index, 1, action.payload) };
    },
    updateCollaborationRequested: (state, action: PayloadAction<Collaboration>) => {
      state.selectedCollaborationToUpdate = action.payload;
    },
    updatePrimaryCollaborationRequested: (state, action: PayloadAction<Collaboration>) => {
      state.selectedCollaborationToUpdate = action.payload;
    },
    primaryCollaborationUpdated: (state, action: PayloadAction<Contact>) => {
      state.selectedContactData = action.payload.collaborations.find((c) => c.id === (state.selectedContactData as Collaboration).id);
      state.selectedContact.ownContactData = { ...state.selectedContact.ownContactData, isPrimary: false };
      state.selectedContact.collaborations = [
        { ...state.selectedContact.collaborations.find((c) => c.id === (state.selectedContactData as Collaboration).id), isPrimary: true },
        ...state.selectedContact.collaborations.filter((c) => c.id !== (state.selectedContactData as Collaboration).id).map((c) => ({ ...c, isPrimary: false }))
      ];
    },
    updatePrimaryOwnContactDataRequested: (state, action: PayloadAction<OwnContactData>) => {
      state.selectedOwnContactDataToUpdate = action.payload;
    },
    primaryOwnContactDataUpdated: (state, action: PayloadAction<Contact>) => {
      state.selectedContactData = action.payload.ownContactData;
      state.selectedContact.ownContactData = { ...state.selectedContact.ownContactData, isPrimary: true };
      state.selectedContact.collaborations = state.selectedContact.collaborations.map((c) => ({ ...c, isPrimary: false }));
    },
    updatedCollaboration: (state, action: PayloadAction<Contact>) => {
      const { collaborations, ownContactData } = action.payload;
      const collaborationIndex = state.selectedContact.collaborations.map((c) => c.id).indexOf(state.selectedCollaborationToUpdate.id);
      const updatedCollaboration = collaborations.find((c) => c.id === state.selectedCollaborationToUpdate.id);

      const updatedCollaborations = [...state.selectedContact.collaborations];
      updatedCollaborations[collaborationIndex] = updatedCollaboration;

      state.selectedContact = { ...state.selectedContact, collaborations: updatedCollaborations, ownContactData };
      state.selectedContactData = state.selectedContact.collaborations.find(
        (collaboration) => collaboration.id === state.selectedCollaborationToUpdate.id
      ) as Collaboration;
      state.selectedCollaborationToUpdate = {} as Collaboration;
    },
    updateOwnContactDataRequested: (state, action: PayloadAction<OwnContactData>) => {
      state.selectedOwnContactDataToUpdate = action.payload;
    },
    updatedOwnContactData: (state, action: PayloadAction<Contact>) => {
      state.selectedContact = { ...state.selectedContact, collaborations: action.payload.collaborations, ownContactData: action.payload.ownContactData };
      state.selectedContactData = state.selectedContact.ownContactData as OwnContactData;
      state.selectedOwnContactDataToUpdate = {} as OwnContactData;
    },
    updateContactInStore: (state, action: PayloadAction<ContactUpdateModel>) => {
      state.contactPreviousValue = state.selectedContact ? cloneDeep(state.selectedContact) : ({} as Contact);
      state.contactDataPreviousValue = state.selectedContactData ? cloneDeep(state.selectedContactData) : ({} as Collaboration);
      state.selectedContactToUpdate = action.payload;
      state.selectedContact.salutation = action.payload.salutation;
      state.selectedContact.firstName = action.payload.firstName;
      state.selectedContact.lastName = action.payload.lastName;
      state.selectedContact.isGloballySignedOut = action.payload.isGloballySignedOut;
    },
    updateContactRequested: (state, action: PayloadAction<ContactUpdateModel>) => {
      state.selectedContactToUpdate = action.payload;
    },
    updatedContact: (state, action: PayloadAction<ContactUpdateModel>) => {
      state.selectedContactToUpdate = {} as ContactUpdateModel;
      state.selectedContact = {
        ...state.selectedContact,
        salutation: action.payload.salutation,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isGloballySignedOut: action.payload.isGloballySignedOut
      };
    },
    createContactVCardStringValue: (state, action: PayloadAction<string>) => {
      state.contactVCardStringValue = action.payload;
    },
    openProfilePanel: (state) => {
      state.isProfilePanelOpened = true;
      state.mediaOutletActiveIndex = [0];
      state.companyActiveIndex = [0];
    },
    closeProfilePanel: (state) => {
      state.isProfilePanelOpened = false;
    },
    setCollaborationToAdd: (state, action: PayloadAction<Collaboration>) => {
      state.collaborationToAdd = action.payload;
    },
    resetCollaborationToAdd: (state) => {
      state.collaborationToAdd = {} as Collaboration;
    },
    resetMediaOutletToAdd: (state) => {
      state.mediaOutletToAdd = {} as MediaOutlet;
    },
    addCompanyCollaborationRequested: (state, action: PayloadAction<JobTitle[]>) => {
      state.isAddCompanyWizardFinishButtonDisabled = true;
      const jobTitle = state.companyProfileInfoToAdd.jobTitle
        ? action.payload.find((jobTitle) => jobTitle.name === state.companyProfileInfoToAdd.jobTitle)
        : null;
      state.collaborationToAdd = new Collaboration(
        '',
        new Medium(state.companyToAdd.id, state.companyToAdd.name, MediumType.Company),
        jobTitle,
        state.companyProfileInfoToAdd.jobDescription ?? '',
        state.companyProfileInfoToAdd.address ?? '',
        state.companyProfileInfoToAdd.country ?? '',
        state.companyProfileInfoToAdd.phoneNumber ? new PhoneNumber(state.companyProfileInfoToAdd.phoneNumber, false) : null,
        state.companyProfileInfoToAdd.mobilePhoneNumber ? new PhoneNumber(state.companyProfileInfoToAdd.mobilePhoneNumber, false) : null,
        state.companyProfileInfoToAdd.emailAddress ?? '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        false,
        state.companyProfileInfoToAdd.city ?? '',
        state.companyProfileInfoToAdd.postalCode ?? ''
      );
    },
    addMediaOutletCollaborationRequested: (state) => {
      state.isAddMediaOutletWizardFinishButtonDisabled = true;

      state.collaborationToAdd = new Collaboration(
        '',
        new Medium(state.existingMediaOutletToAddId, state.mediaOutletToAdd.name, MediumType.MediaOutlet),
        state.collaborationProfileInfo.jobTitle,
        state.collaborationProfileInfo.jobDescription ?? '',
        state.collaborationProfileInfo.address ?? '',
        state.collaborationProfileInfo.country ?? '',
        state.collaborationProfileInfo.phoneNumber ? new PhoneNumber(state.collaborationProfileInfo.phoneNumber, false) : null,
        state.collaborationProfileInfo.mobilePhoneNumber ? new PhoneNumber(state.collaborationProfileInfo.mobilePhoneNumber, false) : null,
        state.collaborationProfileInfo.emailAddress ?? '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        false,
        state.collaborationProfileInfo.city ?? '',
        state.collaborationProfileInfo.postalCode ?? ''
      );
    },
    addedCollaboration: (state, action: PayloadAction<Collaboration>) => {
      state.collaborationToAdd = {} as Collaboration;
      state.selectedContact = { ...state.selectedContact, collaborations: [...state.selectedContact.collaborations, action.payload] };
      if (action.payload.medium.type === MediumType.MediaOutlet) {
        state.mediaOutletActiveIndex = [state.selectedContact.collaborations.filter((c) => c.medium.type === MediumType.MediaOutlet).length - 1];
        state.mediaOutletActiveClass = 'active';
      }
      if (action.payload.medium.type === MediumType.Company) {
        state.mediaOutletActiveIndex = [state.selectedContact.collaborations.filter((c) => c.medium.type === MediumType.Company).length - 1];
        state.companyActiveClass = 'active';
      }
    },

    setMediaOutletActiveIndex: (state, action: PayloadAction<number>) => {
      if (state.mediaOutletActiveIndex && action.payload === state.mediaOutletActiveIndex[0]) {
        state.mediaOutletActiveIndex = [];
      } else {
        state.mediaOutletActiveIndex = [action.payload];
      }
    },
    setCompanyActiveIndex: (state, action: PayloadAction<number>) => {
      if (state.companyActiveIndex && action.payload === state.companyActiveIndex[0]) {
        state.companyActiveIndex = [];
      } else {
        state.companyActiveIndex = [action.payload];
      }
    },
    resetMediaOutletActiveClass: (state) => {
      state.mediaOutletActiveClass = '';
    },
    resetCompanyActiveClass: (state) => {
      state.companyActiveClass = '';
    },
    addMediaOutletAndCollaborationRequested: (state) => {
      state.isCreateMediaOutletWizardFinishButtonDisabled = true;
      state.mediaOutletToAdd = createMediaOutlet(state.mediaOutletGeneralInfoToAdd, state.mediaOutletAddressInfoToAdd);
      state.collaborationToAdd = new Collaboration(
        '',
        new Medium(state.existingMediaOutletToAddId, state.mediaOutletToAdd.name, MediumType.MediaOutlet),
        state.collaborationProfileInfo.jobTitle,
        state.collaborationProfileInfo.jobDescription ?? '',
        state.collaborationProfileInfo.address ?? '',
        state.collaborationProfileInfo.country ?? '',
        state.collaborationProfileInfo.phoneNumber ? new PhoneNumber(state.collaborationProfileInfo.phoneNumber, false) : null,
        state.collaborationProfileInfo.mobilePhoneNumber ? new PhoneNumber(state.collaborationProfileInfo.mobilePhoneNumber, false) : null,
        state.collaborationProfileInfo.emailAddress ?? '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        false,
        state.collaborationProfileInfo.city ?? '',
        state.collaborationProfileInfo.postalCode ?? ''
      );
    },
    addedMediaOutletAndCollaboration: (state, action: PayloadAction<Collaboration>) => {
      state.isCreateMediaOutletWizardFinishButtonDisabled = true;
      state.selectedContact = { ...state.selectedContact, collaborations: [...state.selectedContact.collaborations, action.payload] };
      state.collaborationToAdd = {} as Collaboration;
      state.mediaOutletToAdd = {} as MediaOutlet;
      state.mediaOutletGeneralInfoToAdd = {} as MediaOutletGeneralInfo;
      state.mediaOutletAddressInfoToAdd = {} as MediaOutletAddressInfo;
      state.collaborationProfileInfo = {} as MediaOutletCollaborationProfileInfo;
      state.mediaOutletActiveIndex = [state.selectedContact.collaborations.filter((c) => c.medium.type === MediumType.MediaOutlet).length - 1];
      state.mediaOutletActiveClass = 'active';
    },
    addCompanyAndCollaborationRequested: (state, action: PayloadAction<JobTitle[]>) => {
      state.isCreateCompanyWizardFinishButtonDisabled = true;

      state.companyToAdd = new Company(
        '',
        state.companyGeneralInfoToAdd.name ?? '',
        '',
        state.companyGeneralInfoToAdd.parentCompany ?? '',
        new MediumContactData(
          state.companyAddressInfoToAdd.address ?? '',
          state.companyAddressInfoToAdd.poBox ?? '',
          state.companyAddressInfoToAdd.postalCode ?? '',
          state.companyAddressInfoToAdd.country ?? '',
          state.companyAddressInfoToAdd.city ?? '',
          state.companyAddressInfoToAdd.websiteUrl ?? '',
          state.companyAddressInfoToAdd.emailAddress ?? '',
          state.companyAddressInfoToAdd.phoneNumber ?? '',
          state.companyAddressInfoToAdd.fax ?? '',
          ''
        ),
        '',
        '',
        [],
        state.companyGeneralInfoToAdd.comment ? [state.companyGeneralInfoToAdd.comment] : [],
        [],
        [],
        DateTime.utc().toJSDate(),
        [],
        0,
        '',
        '',
        {
          name: state.companyGeneralInfoToAdd.categoryName ?? ''
        } as MediumCategory
      );
      const jobTitle = state.companyProfileInfoToAdd.jobTitle
        ? action.payload.find((jobTitle) => jobTitle.name === state.companyProfileInfoToAdd.jobTitle)
        : null;
      state.collaborationToAdd = new Collaboration(
        '',
        new Medium(state.companyToAdd.id, state.companyToAdd.name, MediumType.Company),
        jobTitle,
        state.companyProfileInfoToAdd.jobDescription ?? '',
        state.companyProfileInfoToAdd.address ?? '',
        state.companyProfileInfoToAdd.country ?? '',
        state.companyProfileInfoToAdd.phoneNumber ? new PhoneNumber(state.companyProfileInfoToAdd.phoneNumber, false) : null,
        state.companyProfileInfoToAdd.mobilePhoneNumber ? new PhoneNumber(state.companyProfileInfoToAdd.mobilePhoneNumber, false) : null,
        state.companyProfileInfoToAdd.emailAddress ?? '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        false,
        state.companyProfileInfoToAdd.city ?? '',
        state.companyProfileInfoToAdd.postalCode ?? ''
      );
    },
    addedCompanyAndCollaboration: (state, action: PayloadAction<Collaboration>) => {
      state.selectedContact = { ...state.selectedContact, collaborations: [...state.selectedContact.collaborations, action.payload] };
      state.collaborationToAdd = {} as Collaboration;
      state.companyToAdd = {} as Company;
      state.companyGeneralInfoToAdd = {} as CompanyGeneralInfo;
      state.companyAddressInfoToAdd = {} as CompanyAddressInfo;
      state.companyProfileInfoToAdd = {} as CompanyProfileInfo;
      state.companyActiveIndex = [state.selectedContact.collaborations.filter((c) => c.medium.type === MediumType.Company).length - 1];
      state.companyActiveClass = 'active';
    },
    setCollaborationToRemove: (state, action: PayloadAction<Collaboration>) => {
      state.collaborationToRemove = action.payload;
    },
    removeCollaborationRequested: () => {},
    removedCollaboration: (state, action: PayloadAction<Collaboration>) => {
      state.collaborationToRemove = {} as Collaboration;
      state.selectedContact = {
        ...state.selectedContact,
        collaborations: state.selectedContact.collaborations?.filter((collaboration) => collaboration.medium.id !== action.payload.medium.id)
      };
      state.companyActiveIndex = [];
      state.mediaOutletActiveIndex = [];
    },
    showVcardAttachment: (state) => {
      state.isVcardAttachmentVisible = true;
    },
    hideVcardAttachment: (state) => {
      state.isVcardAttachmentVisible = false;
    },
    setActivityTypeToLog: (state, action: PayloadAction<{ localizedActivityType: string; activityType: ActivityTypes }>) => {
      state.localizedActivityTypeToLog = action.payload.localizedActivityType;
      state.activityTypeToLog = action.payload.activityType;
    },
    addActivityRequested: (state, action: PayloadAction<Email | Call | Appointment | Webinar | Event | Chat | Task>) => {
      state.activityToLog = action.payload;
    },
    addCommentToContactRequested: (state, action: PayloadAction<Comment>) => {
      state.commentToAdd = action.payload;
    },
    addedCommentToContact: (state, action: PayloadAction<Comment>) => {
      state.selectedContact.comments = [...state.selectedContact.comments, action.payload];
      state.commentToAdd = {} as Comment;
      state.commentMentions = [];
    },
    removeCommentFromContactRequested: (state, action: PayloadAction<Comment>) => {
      state.commentToRemove = action.payload;
    },
    removedCommentFromContact: (state, action: PayloadAction<Comment>) => {
      state.selectedContact.comments = state.selectedContact.comments.filter((value) => value.id !== action.payload.id);
      state.commentToRemove = {} as Comment;
    },
    addCommentMentionRequested: (state, action: PayloadAction<CommentUser>) => {
      state.commentMentions = [...state.commentMentions, action.payload];
    },
    setSelectedActivity: (state, action: PayloadAction<string>) => {
      state.selectedActivity = state.selectedContactActivities.find((a) => a.id === action.payload);
    },
    setSelectedSending: (state, action: PayloadAction<Sending>) => {
      state.selectedSending = action.payload;
    },
    getAllBlacklistsRequested: (state) => {
      state.isLoadingAllBlacklists = true;
    },
    allBlacklistsReceived: (state, action: PayloadAction<Blacklist[]>) => {
      state.allBlacklists = action.payload;
      state.isLoadingAllBlacklists = false;
    },
    addNewBlacklistRequested: (state, action: PayloadAction<string>) => {
      state.blacklistToAdd = action.payload;
    },
    addedNewBlacklist: (state, action: PayloadAction<Blacklist>) => {
      state.allBlacklists = [...state.allBlacklists, action.payload];
      state.filteredBlacklists = state.allBlacklists;
      state.blacklistToAdd = '';
    },
    removeBlacklistRequested: (state, action: PayloadAction<Blacklist>) => {
      state.blacklistToRemove = action.payload;
    },
    blacklistRemoved: (state, action: PayloadAction<Blacklist>) => {
      state.allBlacklists = state.allBlacklists.filter((bl) => bl.name !== action.payload.name);
      state.blacklistToRemove = {} as Blacklist;
    },
    addBlacklistToContactRequested: (state, action: PayloadAction<Blacklist>) => {
      state.blacklistToAddToContact = action.payload;
    },
    addedBlacklistToContact: (state, action: PayloadAction<Blacklist>) => {
      state.selectedContactBlacklists = [...state.selectedContactBlacklists, action.payload];
      state.selectedContact.blacklists = [...state.selectedContactBlacklists];
      state.blacklistToAddToContact = {} as Blacklist;
    },
    removeBlacklistFromContactRequested: (state, action: PayloadAction<Blacklist>) => {
      state.blacklistToRemoveFromContact = action.payload;
    },
    removedBlacklistFromContact: (state, action: PayloadAction<Blacklist>) => {
      state.selectedContactBlacklists = state.selectedContactBlacklists.filter((bl) => bl.name !== action.payload.name);
      state.selectedContact.blacklists = [...state.selectedContactBlacklists];
      state.blacklistToRemoveFromContact = {} as Blacklist;
    },
    resetSelectedContactAfterUndo: (state) => {
      state.selectedContact = cloneDeep(state.contactPreviousValue);
      state.selectedContactData = cloneDeep(state.contactDataPreviousValue);
    },
    setIsRemoveContactDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.isRemoveContactDialogOpened = action.payload;
    },
    contactProfilePanelDataRequested: (state, action: PayloadAction<string>) => {
      state.selectedContactId = action.payload;
      state.isLoadingContactProfileInfo = true;
    },
    firstPageOfSelectedContactActivitiesRequested: (state, action?: PayloadAction<number>) => {
      const minimumPageSize = action.payload ?? 3;
      state.isLoadingActivities = true;
      state.isLoadingMoreActivities = false;
      state.activitiesPageNumber = 1;
      state.activitiesPageSize = minimumPageSize <= 3 ? 3 : minimumPageSize;
      state.totalCountOfActivities = 0;
      state.loadedActivitiesCount = 0;
      state.sendingsOverview.totalSent = 0;
      state.sendingsOverview.opens = 0;
      state.sendingsOverview.clicks = 0;
      state.selectedContactActivities = [];
    },
    nextPageOfSelectedContactActivitiesRequested: (state) => {
      state.isLoadingMoreActivities = true;
      state.activitiesPageNumber = state.activitiesPageNumber + 1;
    },
    firstPageOfSelectedContactActivitiesReceived: (state, action: PayloadAction<Activity[]>) => {
      state.selectedContactActivities = action.payload;
      state.filteredActivities = [...action.payload];
      state.loadedActivitiesCount = action.payload.length;
      state.isLoadingActivities = false;
    },
    nextPageOfSelectedContactActivitiesReceived: (state, action: PayloadAction<Activity[]>) => {
      action.payload?.forEach((activity) => {
        if (!state.selectedContactActivities.some((ac) => ac.id === activity.id)) {
          state.selectedContactActivities.push(activity);
          state.loadedActivitiesCount += 1;
        }
      });
      state.filteredActivities = state.selectedContactActivities;
      state.isLoadingMoreActivities = false;
    },
    fetchAllLoadedActivitiesReceived: (state, action: PayloadAction<Activity[]>) => {
      state.selectedContactActivities = action.payload;
      state.filteredActivities = [...action.payload];
      state.loadedActivitiesCount = action.payload.length;
      state.isLoadingActivities = false;
    },
    setTotalCountOfActivities: (state, action: PayloadAction<number>) => {
      state.totalCountOfActivities = action.payload;
    },
    selectedContactRecipientsRequested: () => {},
    selectedContactRecipientsReceived: (state, action: PayloadAction<Recipient[]>) => {
      state.selectedContactRecipients = action.payload;
      state.sendingsOverview.opens = action.payload.reduce((totalOpens, recipient) => (recipient.isOpened ? totalOpens + 1 : totalOpens), 0);
      state.sendingsOverview.clicks = action.payload.reduce((totalLinksClicked, recipient) => totalLinksClicked + recipient.linksClicked, 0);
    },
    activityEmailAddressRequested: () => {},
    activityEmailAddressReceived: (state, action: PayloadAction<string>) => {
      state.activityEmailAddress = action.payload;
    },
    setCompanyGeneralInfoToAdd: (state, action: PayloadAction<CompanyGeneralInfo>) => {
      state.companyGeneralInfoToAdd = action.payload;
    },
    setCompanyAddressInfoToAdd: (state, action: PayloadAction<CompanyAddressInfo>) => {
      state.companyAddressInfoToAdd = action.payload;
    },
    setCompanyProfileInfoToAdd: (state, action: PayloadAction<CompanyProfileInfo>) => {
      state.companyProfileInfoToAdd = action.payload;
    },
    setMediaOutletGeneralInfoToAdd: (state, action: PayloadAction<MediaOutletGeneralInfo>) => {
      state.mediaOutletGeneralInfoToAdd = action.payload;
    },
    setMediaOutletAddressInfoToAdd: (state, action: PayloadAction<MediaOutletAddressInfo>) => {
      state.mediaOutletAddressInfoToAdd = action.payload;
    },
    setCollaborationProfileInfo: (state, action: PayloadAction<MediaOutletCollaborationProfileInfo>) => {
      state.collaborationProfileInfo = action.payload;
    },
    openActivityDetailsDialog: (state) => {
      state.isActivityDetailsDialogOpened = true;
    },
    closeActivityDetailsDialog: (state) => {
      state.isActivityDetailsDialogOpened = false;
    },
    openAddActivityDialog: (state) => {
      state.isAddActivityDialogOpened = true;
    },
    closeAddActivityDialog: (state) => {
      state.isAddActivityDialogOpened = false;
    },
    goToStepAddMedia: (state, action: PayloadAction<number>) => {
      if (state.addMediaOutletCurrentStep === AddMediaOutletWizardSteps.Finish) state.isAddMediaOutletWizardFinishButtonDisabled = false;
      state.addMediaOutletCurrentStep = action.payload;
    },
    goToNextStepAddMedia: (state) => {
      state.addMediaOutletCurrentStep = state.addMediaOutletCurrentStep + 1;
    },
    goToPreviousStepAddMedia: (state) => {
      if (state.addMediaOutletCurrentStep === AddMediaOutletWizardSteps.Finish) state.isAddMediaOutletWizardFinishButtonDisabled = false;
      state.addMediaOutletCurrentStep = state.addMediaOutletCurrentStep - 1;
    },
    openAddMediaWizard: (state) => {
      state.isAddMediaWizardOpened = true;
      state.isAddMediaOutletWizardFinishButtonDisabled = false;
      state.isAddMediaOutletWizardProfileInfoStepValid = false;
      state.addMediaOutletCurrentStep = AddMediaOutletWizardSteps.SearchMediaOutlet;
      state.collaborationProfileInfo = {} as MediaOutletCollaborationProfileInfo;
    },
    setIsAddMediaOutletWizardProfileInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isAddMediaOutletWizardProfileInfoStepValid = action.payload;
    },
    closeAddMediaWizard: (state) => {
      state.isAddMediaWizardOpened = false;
      state.mediaOutletsPageNumber = 1;
      state.mediaOutletsSearchText = '';
      state.mediaOutletsCategoryFilter = '';
      state.addMediaOutletCurrentStep = AddMediaOutletWizardSteps.SearchMediaOutlet;
      state.collaborationProfileInfo = {} as MediaOutletCollaborationProfileInfo;
      state.existingMediaOutletToAddId = '';
    },
    goToStepCreateMedia: (state, action: PayloadAction<number>) => {
      state.createMediaOutletCurrentStep = action.payload;
    },
    goToNextStepCreateMedia: (state) => {
      state.createMediaOutletCurrentStep = state.createMediaOutletCurrentStep + 1;
    },
    goToPreviousStepCreateMedia: (state) => {
      if (state.createMediaOutletCurrentStep === CreateMediaOutletWizardSteps.Finish) state.isCreateMediaOutletWizardFinishButtonDisabled = false;
      state.createMediaOutletCurrentStep = state.createMediaOutletCurrentStep - 1;
    },
    openCreateMediaWizard: (state) => {
      state.isCreateMediaWizardOpened = true;
      state.createMediaOutletCurrentStep = CreateMediaOutletWizardSteps.GeneralInformation;
      state.mediaOutletGeneralInfoToAdd = {} as MediaOutletGeneralInfo;
      state.mediaOutletAddressInfoToAdd = {} as MediaOutletAddressInfo;
      state.collaborationProfileInfo = {} as MediaOutletCollaborationProfileInfo;
      state.isCreateMediaOutletWizardFinishButtonDisabled = false;
    },
    closeCreateMediaWizard: (state) => {
      state.isCreateMediaWizardOpened = false;
      state.mediaOutletGeneralInfoToAdd = {} as MediaOutletGeneralInfo;
      state.mediaOutletAddressInfoToAdd = {} as MediaOutletAddressInfo;
      state.collaborationProfileInfo = {} as MediaOutletCollaborationProfileInfo;
    },
    openAddCompanyWizard: (state) => {
      state.isAddCompanyWizardOpened = true;
      state.isAddCompanyWizardFinishButtonDisabled = false;
      state.isAddCompanyWizardProfileInfoStepValid = false;
      state.addCompanyCurrentStep = AddCompanyWizardSteps.SearchCompany;
      state.companyProfileInfoToAdd = {} as CompanyProfileInfo;
    },
    setIsAddCompanyWizardProfileInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isAddCompanyWizardProfileInfoStepValid = action.payload;
    },
    setIsCreateCompanyGeneralInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isCreateCompanyGeneralInfoStepValid = action.payload;
    },
    setIsCreateCompanyAddressInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isCreateCompanyAddressInfoStepValid = action.payload;
    },
    setIsCreateCompanyProfileInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isCreateCompanyProfileInfoStepValid = action.payload;
    },
    setIsCreateMediaOutletGeneralInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isCreateMediaOutletGeneralInfoStepValid = action.payload;
    },
    setIsCreateMediaOutletAddressInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isCreateMediaOutletAddressInfoStepValid = action.payload;
    },
    setIsCreateMediaOutletProfileInfoStepValid: (state, action: PayloadAction<boolean>) => {
      state.isCreateMediaOutletProfileInfoStepValid = action.payload;
    },
    closeAddCompanyWizard: (state) => {
      state.isAddCompanyWizardOpened = false;
      state.companiesPageNumber = 1;
      state.companiesSearchText = '';
      state.companiesCategoryFilter = '';
      state.addCompanyCurrentStep = AddCompanyWizardSteps.SearchCompany;
      state.companyProfileInfoToAdd = {} as CompanyProfileInfo;
    },
    goToStepAddCompany: (state, action: PayloadAction<number>) => {
      if (state.addCompanyCurrentStep === AddCompanyWizardSteps.Finish) state.isAddCompanyWizardFinishButtonDisabled = false;
      state.addCompanyCurrentStep = action.payload;
    },
    goToNextStepAddCompany: (state) => {
      state.addCompanyCurrentStep = state.addCompanyCurrentStep + 1;
    },
    goToPreviousStepAddCompany: (state) => {
      if (state.addCompanyCurrentStep === AddCompanyWizardSteps.Finish) state.isAddCompanyWizardFinishButtonDisabled = false;
      state.addCompanyCurrentStep = state.addCompanyCurrentStep - 1;
    },
    openCreateCompanyWizard: (state) => {
      state.isCreateCompanyWizardOpened = true;
      state.createCompanyCurrentStep = CreateCompanyWizardSteps.GeneralInformation;
      state.isCreateCompanyWizardFinishButtonDisabled = false;
      state.isCreateCompanyGeneralInfoStepValid = false;
      state.companyGeneralInfoToAdd = {} as CompanyGeneralInfo;
      state.companyAddressInfoToAdd = {} as CompanyAddressInfo;
      state.companyProfileInfoToAdd = {} as CompanyProfileInfo;
    },
    closeCreateCompanyWizard: (state) => {
      state.isCreateCompanyWizardOpened = false;
    },
    goToStepCreateCompany: (state, action: PayloadAction<number>) => {
      if (state.createCompanyCurrentStep === CreateCompanyWizardSteps.Finish) state.isCreateCompanyWizardFinishButtonDisabled = false;
      state.createCompanyCurrentStep = action.payload;
    },
    goToNextStepCreateCompany: (state) => {
      state.createCompanyCurrentStep = state.createCompanyCurrentStep + 1;
    },
    goToPreviousStepCreateCompany: (state) => {
      if (state.createCompanyCurrentStep === CreateCompanyWizardSteps.Finish) state.isCreateCompanyWizardFinishButtonDisabled = false;
      state.createCompanyCurrentStep = state.createCompanyCurrentStep - 1;
    },
    pageOfMediaOutletsRequested: (state) => {
      state.isLoadingMediaOutlets = true;
      state.mediaOutlets = [];
      state.mediaOutletsTotalCount = 0;
    },
    pageOfCompaniesRequested: (state) => {
      state.isLoadingCompanies = true;
      state.companies = [];
      state.companiesTotalCount = 0;
    },
    setSearchMediaOutletsTextAndFitlerItem: (state, action: PayloadAction<{ searchText: string; category: string }>) => {
      state.mediaOutletsSearchText = action.payload.searchText;
      state.mediaOutletsCategoryFilter = action.payload.category;
    },
    setSearchCompaniesTextAndFitlerItem: (state, action: PayloadAction<{ searchText: string; category: string }>) => {
      state.companiesSearchText = action.payload.searchText;
      state.companiesCategoryFilter = action.payload.category;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.mediaOutletsPageNumber = action.payload;
    },
    setCompaniesPageNumber: (state, action: PayloadAction<number>) => {
      state.companiesPageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.mediaOutletsPageSize = action.payload;
    },
    setCompaniesPageSize: (state, action: PayloadAction<number>) => {
      state.companiesPageSize = action.payload;
      state.companiesPageNumber = 1;
    },
    pageOfMediaOutletsReceived: (state, action: PayloadAction<{ mediaOutlets: MediaOutlet[]; totalCountOfMediaOutlets: number }>) => {
      state.isLoadingMediaOutlets = false;
      state.mediaOutlets = action.payload.mediaOutlets;
      state.mediaOutletsTotalCount = action.payload.totalCountOfMediaOutlets;
    },
    pageOfCompaniesReceived: (state, action: PayloadAction<{ companies: Company[]; totalCountOfCompanies: number }>) => {
      state.isLoadingCompanies = false;
      state.companies = action.payload.companies;
      state.companiesTotalCount = action.payload.totalCountOfCompanies;
    },
    setTotalCountOfMediaOutlets: (state, action: PayloadAction<number>) => {
      state.mediaOutletsTotalCount = action.payload;
    },
    setTotalCountOfCompanies: (state, action: PayloadAction<number>) => {
      state.companiesTotalCount = action.payload;
    },
    setExistingMediaOutlet: (state, action: PayloadAction<string>) => {
      state.mediaOutletToAdd = state.mediaOutlets.find((mo) => mo.id === action.payload);
      state.existingMediaOutletToAddId = action.payload;
    },
    setExistingCompany: (state, action: PayloadAction<Company>) => {
      state.companyToAdd = action.payload;
    },
    openChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = true;
    },
    closeChangeImageDialog: (state) => {
      state.isChangeImageDialogOpened = false;
    },
    openNumberDialog: (state) => {
      state.isNumberDialogOpened = true;
    },
    closeNumberDialog: (state) => {
      state.isNumberDialogOpened = false;
    },
    openQRCodeDialog: (state) => {
      state.isQRCodeDialogOpened = true;
    },
    closeQRCodeDialog: (state) => {
      state.isQRCodeDialogOpened = false;
    },
    showMoreEditableFields: (state) => {
      state.areMoreEditableFieldsVisible = true;
    },
    hideMoreEditableFields: (state) => {
      state.areMoreEditableFieldsVisible = false;
    },
    openRemoveMediaOutletDialog: (state) => {
      state.isRemoveMediaOutletDialogVisible = true;
    },
    closeRemoveMediaOutletDialog: (state) => {
      state.isRemoveMediaOutletDialogVisible = false;
    },
    openRemoveComapnyDialog: (state) => {
      state.isRemoveCompanyDialogVisible = true;
    },
    closeRemoveComapnyDialog: (state) => {
      state.isRemoveCompanyDialogVisible = false;
    },
    filterTopics: (state, action: PayloadAction<string>) => {
      state.filteredTopics = state.allTopics.filter((t) => t.name.includes(action.payload));
    },
    filterMediaResorts: (state, action: PayloadAction<string>) => {
      state.filteredMediaResorts = state.allMediaResorts.filter((t) => t.name.includes(action.payload));
    },
    fetchEmailAddressHasTeamsAccountRequested: (state, action: PayloadAction<string>) => {
      state.emailAddressToCheckTeamsAccount = action.payload;
    },
    fetchEmailAddressHasTeamsAccountReceived: (state, action: PayloadAction<boolean>) => {
      state.selectedContactHasTeamsEnabled = action.payload;
    },
    filterBlacklists: (state, action: PayloadAction<string>) => {
      state.filteredBlacklists = state.allBlacklists.filter((b) => b.name.includes(action.payload));
    },
    removeBlacklistsRequested: (state, action: PayloadAction<string[]>) => {
      state.blacklistIdsToRemove = action.payload;
    },
    blacklistsRemoved: (state, action: PayloadAction<string[]>) => {
      state.selectedContactBlacklists = state.selectedContactBlacklists.filter((b) => !action.payload.includes(b.id));
      state.allBlacklists = state.allBlacklists.filter((b) => !action.payload.includes(b.id));
      state.filteredBlacklists = state.filteredBlacklists.filter((b) => !action.payload.includes(b.id));
    },
    addActivitiesFilter: (state, action: PayloadAction<FilterItem>) => {
      state.activitiesFilterItems = [...state.activitiesFilterItems, action.payload];
    },
    removeActivitiesFilter: (state, action: PayloadAction<FilterItem>) => {
      state.activitiesFilterItems = state.activitiesFilterItems.filter(
        (item) => item.fieldName !== action.payload.fieldName || item.value !== action.payload.value
      );
    },
    clearAllActivitiesFilters: (state) => {
      state.activitiesFilterItems = [];
    },
    selectedContactSendingActivitiesRequested: (state) => {
      state.selectedContactSendingActivities = [];
    },
    selectedContactSendingActivitiesReceived: (state, action: PayloadAction<Sending[]>) => {
      state.selectedContactSendingActivities = action.payload;
      state.sendingsOverview.totalSent = state.selectedContactSendingActivities?.filter((sending) => sending.status !== SendingStatus.Draft).length ?? 0;
    },
    addedNewList: (state, action: PayloadAction<List>) => {
      state.filteredLists = [...state.filteredLists, action.payload];
      state.listToAdd = {} as List;
    },
    undoListToRemove: (state) => {
      state.selectedContactLists = [...state.selectedContactLists, state.listToRemove];
      state.listToRemove = {} as List;
    },
    enableNavigationToNewlyCreatedList: (state) => {
      state.shouldNavigateToNewlyCreatedList = true;
    },
    disableNavigationToNewlyCreatedList: (state) => {
      state.shouldNavigateToNewlyCreatedList = false;
    },
    updateContactPrimaryContactData: (state, action: PayloadAction<Contact>) => {
      state.selectedContactData = action.payload.ownContactData.isPrimary
        ? action.payload.ownContactData
        : action.payload.collaborations.find((c) => c.isPrimary);

      state.selectedContact = {
        ...state.selectedContact,
        ownContactData: { ...state.selectedContact.ownContactData, isPrimary: action.payload.ownContactData.isPrimary },
        collaborations: state.selectedContact.collaborations.map((c) => ({
          ...c,
          isPrimary: action.payload.collaborations.find((col) => col.id === c.id).isPrimary
        }))
      };
    },
    setIsChoosingContactDataAsPrimary: (state, action: PayloadAction<boolean>) => {
      state.isChoosingContactDataAsPrimary = action.payload;
    },
    setIsChoosingPhoneNumberDefaultState: (state, action: PayloadAction<boolean>) => {
      state.isChoosingPhoneNumberDefaultState = action.payload;
    }
  }
});

export const {
  getSelectedContactInfoRequested,
  selectedContactInfoReceived,
  getAllTopicsRequested,
  allTopicsReceived,
  updateContactPrimaryContactData,
  getAllMediaResortsRequested,
  allMediaResortsReceived,
  getListsByNameRequested,
  listsByNameReceived,
  removedTagsFromContact,
  addListRequested,
  addedList,
  addedTagToContact,
  addTopicToContactRequested,
  addedTopicToContact,
  removeTopicFromContactRequested,
  removedTopicFromContact,
  addMediaResortToContactRequested,
  addedMediaResortToContact,
  removeMediaResortFromContactRequested,
  removedMediaResortFromContact,
  addListToContactRequested,
  addedListToContact,
  removeListFromContactRequested,
  removedListFromContact,
  changeSelectedContactData,
  updateCollaborationRequested,
  updatedCollaboration,
  updateProfilePictureRequested,
  profilePictureUpdated,
  removeProfilePictureRequested,
  changeProfilePictureToUpload,
  resetSelectedContactInfo,
  updateContactRequested,
  updatedContact,
  removeProfilePicturePreview,
  createContactVCardStringValue,
  openProfilePanel,
  closeProfilePanel,
  addCompanyCollaborationRequested,
  addMediaOutletCollaborationRequested,
  addedCollaboration,
  removeCollaborationRequested,
  removedCollaboration,
  setCollaborationToAdd,
  resetCollaborationToAdd,
  showVcardAttachment,
  hideVcardAttachment,
  setActivityTypeToLog,
  setSelectedActivity,
  setSelectedSending,
  addCommentToContactRequested,
  addedCommentToContact,
  removeCommentFromContactRequested,
  removedCommentFromContact,
  addCommentMentionRequested,
  addBlacklistToContactRequested,
  addNewBlacklistRequested,
  addedBlacklistToContact,
  addedNewBlacklist,
  removeBlacklistFromContactRequested,
  removeBlacklistRequested,
  removedBlacklistFromContact,
  blacklistRemoved,
  allBlacklistsReceived,
  getAllBlacklistsRequested,
  updateOwnContactDataRequested,
  updatedOwnContactData,
  resetSelectedContactAfterUndo,
  setIsRemoveContactDialogOpened,
  addMediaOutletAndCollaborationRequested,
  addedMediaOutletAndCollaboration,
  addCompanyAndCollaborationRequested,
  addedCompanyAndCollaboration,
  updateContactInStore,
  updateCollaborationInStore,
  removeTagFromContactInStore,
  updateOwnContactDataInStore,
  addActivityRequested,
  contactProfilePanelDataRequested,
  resetMediaOutletActiveClass,
  resetCompanyActiveClass,
  setMediaOutletActiveIndex,
  setCompanyActiveIndex,
  resetMediaOutletToAdd,
  firstPageOfSelectedContactActivitiesReceived,
  firstPageOfSelectedContactActivitiesRequested,
  nextPageOfSelectedContactActivitiesRequested,
  nextPageOfSelectedContactActivitiesReceived,
  fetchAllLoadedActivitiesReceived,
  setTotalCountOfActivities,
  activityEmailAddressRequested,
  activityEmailAddressReceived,
  setCompanyGeneralInfoToAdd,
  setCompanyAddressInfoToAdd,
  setCompanyProfileInfoToAdd,
  setMediaOutletGeneralInfoToAdd,
  setMediaOutletAddressInfoToAdd,
  setCollaborationProfileInfo,
  openActivityDetailsDialog,
  closeActivityDetailsDialog,
  openAddActivityDialog,
  closeAddActivityDialog,
  goToStepAddMedia,
  goToNextStepAddMedia,
  goToPreviousStepAddMedia,
  openAddMediaWizard,
  setIsAddMediaOutletWizardProfileInfoStepValid,
  closeAddMediaWizard,
  goToStepCreateMedia,
  goToNextStepCreateMedia,
  goToPreviousStepCreateMedia,
  openCreateMediaWizard,
  closeCreateMediaWizard,
  goToStepAddCompany,
  goToNextStepAddCompany,
  goToPreviousStepAddCompany,
  openAddCompanyWizard,
  closeAddCompanyWizard,
  goToStepCreateCompany,
  goToNextStepCreateCompany,
  goToPreviousStepCreateCompany,
  openCreateCompanyWizard,
  closeCreateCompanyWizard,
  setTotalCountOfMediaOutlets,
  pageOfCompaniesReceived,
  pageOfCompaniesRequested,
  setTotalCountOfCompanies,
  setExistingMediaOutlet,
  setExistingCompany,
  openChangeImageDialog,
  closeChangeImageDialog,
  openNumberDialog,
  closeNumberDialog,
  openQRCodeDialog,
  closeQRCodeDialog,
  showMoreEditableFields,
  hideMoreEditableFields,
  openRemoveMediaOutletDialog,
  closeRemoveMediaOutletDialog,
  openRemoveComapnyDialog,
  closeRemoveComapnyDialog,
  addMediaResortAndAssignToContactRequested,
  addedMediaResort,
  addTopicRequested,
  addedTopic,
  pageOfMediaOutletsReceived,
  pageOfMediaOutletsRequested,
  setPageNumber,
  setSearchMediaOutletsTextAndFitlerItem,
  filterTopics,
  deleteMediaResortsRequested,
  deleteTopicsRequested,
  deletedMediaResorts,
  filterMediaResorts,
  deletedTopics,
  nextPageOfListsRequested,
  nextPageOfListsReceived,
  setPageSize,
  primaryCollaborationUpdated,
  primaryOwnContactDataUpdated,
  updatePrimaryCollaborationRequested,
  updatePrimaryOwnContactDataRequested,
  addListFromContactPanelRequested,
  setCollaborationToRemove,
  setCompaniesPageNumber,
  setCompaniesPageSize,
  setSearchCompaniesTextAndFitlerItem,
  selectedContactRecipientsRequested,
  selectedContactRecipientsReceived,
  setIsAddCompanyWizardProfileInfoStepValid,
  setIsCreateCompanyGeneralInfoStepValid,
  setIsCreateCompanyAddressInfoStepValid,
  setIsCreateCompanyProfileInfoStepValid,
  setIsCreateMediaOutletAddressInfoStepValid,
  setIsCreateMediaOutletGeneralInfoStepValid,
  setIsCreateMediaOutletProfileInfoStepValid,
  filterBlacklists,
  blacklistsRemoved,
  removeBlacklistsRequested,
  addActivitiesFilter,
  removeActivitiesFilter,
  clearAllActivitiesFilters,
  selectedContactSendingActivitiesRequested,
  selectedContactSendingActivitiesReceived,
  addedNewList,
  undoListToRemove,
  selectedContactListsRequested,
  selectedContactsListsReceived,
  enableNavigationToNewlyCreatedList,
  disableNavigationToNewlyCreatedList,
  setIsChoosingContactDataAsPrimary,
  setIsChoosingPhoneNumberDefaultState,
  fetchEmailAddressHasTeamsAccountReceived,
  fetchEmailAddressHasTeamsAccountRequested
} = contactProfileSlice.actions;

export default contactProfileSlice.reducer;
