export const getErrorCode = (errorMessage: string): string => {
  if (!errorMessage) {
    return '';
  }

  return errorMessage.split('?')[0];
};

export const getErrorArgs = (errorMessage: string): string[] => {
  if (!errorMessage || !errorMessage.includes('?')) {
    return [];
  }

  return errorMessage
    .split('?')[1]
    .split('&')
    .map((arg) => arg.split('=')[1]);
};
