import { useNavigate, useParams } from 'react-router-dom';
import { IColumn } from '@fluentui/react';
import { NewTable, TableHeader, getAllJobTitlesRequested, useAppDispatch, useAppSelector } from 'app/common';
import {
  CompaniesSortingInput,
  CompaniesSortOrder,
  CompanySearchResultTableData,
  firstPageOfCompaniesRequested,
  nextPageOfCompaniesRequested,
  selectIsLoadingSearchResults,
  selectSearchResultTableHeader,
  selectSearchResultTableRows,
  selectTotalCountOfCompanies,
  setSearchResultTableHeader,
  setSortingInput
} from 'app/pages/my-audience/companies';
import {
  fetchSelectedCompanyRequested,
  getAllTagsRequested,
  openProfilePanel,
  pageOfCompanyContactsRequested,
  setCompanyContactsPageNumber,
  setSearchCompanyContactsTextAndFilterItem
} from 'app/pages/my-audience/company-profile';

export const CompaniesTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const header = useAppSelector<TableHeader[]>(selectSearchResultTableHeader);
  const companySearchResultTableData = useAppSelector<CompanySearchResultTableData[]>(selectSearchResultTableRows);
  const totalCountOfCompanies = useAppSelector<number>(selectTotalCountOfCompanies);
  const isLoadingSearchResults = useAppSelector<boolean>(selectIsLoadingSearchResults);

  const handleOpenProfile = (companyId: string) => {
    dispatch(openProfilePanel());
    dispatch(fetchSelectedCompanyRequested(companyId));
    dispatch(getAllTagsRequested());
    dispatch(setCompanyContactsPageNumber(1));
    dispatch(setSearchCompanyContactsTextAndFilterItem({ searchText: '', role: '' }));
    dispatch(pageOfCompanyContactsRequested());
    dispatch(getAllJobTitlesRequested());
    navigate(`/my-audience/companies/results/${params.companiesQuery}/company-info/${companyId}/profile`);
  };

  const handleLoadMoreCompanies = () => {
    dispatch(nextPageOfCompaniesRequested());
  };

  const handleColumnClicked = (selectedColumn: IColumn): void => {
    const sortOrder: CompaniesSortOrder = selectedColumn.isSortedDescending ? CompaniesSortOrder.Asc : CompaniesSortOrder.Desc;
    const companiesSortingInput = new CompaniesSortingInput(selectedColumn.fieldName, sortOrder);
    dispatch(setSortingInput(companiesSortingInput));

    const index = header.findIndex((column) => selectedColumn.fieldName === column.fieldName);
    const changedHeader = { ...header[index], isSortedDescending: !selectedColumn.isSortedDescending, isSorted: true };

    const headerCopy = header.map((column: IColumn) => {
      return { ...column, isSorted: false, isSortedDescending: true };
    });

    headerCopy.splice(index, 1, changedHeader);
    dispatch(setSearchResultTableHeader(headerCopy));
    dispatch(firstPageOfCompaniesRequested());
  };

  const handleUpdateTableHeader = (updatedHeader: TableHeader[]) => {
    dispatch(setSearchResultTableHeader(updatedHeader));
  };

  return (
    <NewTable
      isLoadingSearchResults={isLoadingSearchResults}
      fixedColumnIndex={0}
      onOpenProfile={handleOpenProfile}
      header={header}
      tableRows={companySearchResultTableData}
      totalCountOfEntities={totalCountOfCompanies}
      handleLoadMore={handleLoadMoreCompanies}
      onColumnClicked={handleColumnClicked}
      onUpdateTableHeader={handleUpdateTableHeader}
    />
  );
};
