import { useState } from 'react';
import { Button } from '@fluentui/react-northstar';
import {
  LicenseNotFound,
  selectIsUserRegistered,
  setSignedInUserInfo,
  signOut,
  useAppDispatch,
  useAppSelector,
  selectIsInTeams,
  selectIsLoadingSignedInUser,
  startLoadingSignedInUser,
  setLastInteractionWithEmailAddress,
  buildAugureUserInfo,
  buildAadUserInfo,
  setSignedInUser,
  setUserIsNotRegistered,
  getTenantFromAccessToken,
  setTenantId
} from 'app/common';
import { performSingleSignOnInBrowser, performSingleSignOnInTeams } from 'auth';
import circlesDecoration from 'assets/images/augure-decoration-circles.png';
import backgroundAvatar from 'assets/images/augure-login-avatar.png';
import backgroundAvatarDark from 'assets/images/augure-login-avatar-dark.png';
import backgroundAvatarMobile from 'assets/images/augure-login-avatar-mobile.svg';
import backgroundAvatarMobileDark from 'assets/images/augure-login-avatar-mobile-dark.svg';
import augureLogo from 'assets/images/augure-logo-light-version.png';
import augureLogoDark from 'assets/images/augure-logo-dark-version.png';
import { useIntl } from 'app/i18n';
import { MissingConsent } from './MissingConsent';
import 'app/common/components/sign-in/SignIn.scss';

export const SignIn = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [showMissingConsentMessage, setShowMissingConsentMessage] = useState(false);

  const isLoadingSignedInUser = useAppSelector<boolean>(selectIsLoadingSignedInUser);
  const [triedToSignIn, setTriedToSignIn] = useState<boolean>(false);
  const inTeams = useAppSelector<boolean>(selectIsInTeams);
  const isUserRegistered = useAppSelector<boolean>(selectIsUserRegistered);

  const handleSuccess = ({ accessToken, graphApiAccessToken }) => {
    const user = buildAadUserInfo(graphApiAccessToken);
    dispatch(setSignedInUserInfo(user));
    dispatch(setLastInteractionWithEmailAddress(user.emailAddress));

    const signedInUser = buildAugureUserInfo(accessToken);
    const tenantId = getTenantFromAccessToken(accessToken);
    dispatch(setTenantId(tenantId));
    dispatch(setSignedInUser(signedInUser));
  };

  const setLastInteractionEmailAddress = (graphApiAccessToken: string) => {
    const user = buildAadUserInfo(graphApiAccessToken);
    dispatch(setLastInteractionWithEmailAddress(user.emailAddress));
  };

  const handleFailure = () => {
    dispatch(signOut());
    dispatch(setUserIsNotRegistered());
  };

  const adminConsentMissing = () => {
    setShowMissingConsentMessage(true);
  };

  const handleSingleSignOn = () =>
    inTeams
      ? performSingleSignOnInTeams(handleSuccess, handleFailure, setLastInteractionEmailAddress, adminConsentMissing)
      : performSingleSignOnInBrowser(handleSuccess, handleFailure, setLastInteractionEmailAddress);

  const onSignIn = () => {
    dispatch(startLoadingSignedInUser());
    handleSingleSignOn();
    setTriedToSignIn(true);
  };

  return triedToSignIn && !isUserRegistered && !isLoadingSignedInUser ? (
    <LicenseNotFound />
  ) : showMissingConsentMessage ? (
    <MissingConsent />
  ) : (
    <div className="login-wrap">
      <div className="login-actions-col">
        <div className="login-content">
          <div className="login-logo">
            <img src={augureLogo} alt={formatMessage({ id: 'sign-in-page.logo-alt' })} />
            <img src={augureLogoDark} className="login-logo-mobile" alt={formatMessage({ id: 'sign-in-page.logo-alt' })} />
          </div>
          <p className="login-text">{formatMessage({ id: 'sign-in-page.text' })}</p>
          <p className="login-text">{showMissingConsentMessage}</p>
          <span className="login-description">{formatMessage({ id: 'sign-in-page.description' })}</span>
          <img
            src={backgroundAvatarMobile}
            alt={formatMessage({ id: 'sign-in-page.right-decoration-alt' })}
            className="login-bg-image login-bg-image--mobile"
          />
          <img
            src={backgroundAvatarMobileDark}
            alt={formatMessage({ id: 'sign-in-page.right-decoration-alt' })}
            className="login-bg-image login-bg-image--mobile login-bg-image--mobile-dark"
          />
          <Button className="login-button" content={formatMessage({ id: 'sign-in-page.button' })} onClick={onSignIn} />
        </div>
        <img src={circlesDecoration} alt="Circle decoration" className="login-image-decoration" />
      </div>
      <div className="login-avatar-col">
        <div className="login-not-found">
          <img src={backgroundAvatar} alt={formatMessage({ id: 'sign-in-page.right-decoration-alt' })} className="login-bg-image" />
          <img src={backgroundAvatarDark} alt={formatMessage({ id: 'sign-in-page.right-decoration-alt' })} className="login-bg-image login-bg-image--dark" />
        </div>
      </div>
    </div>
  );
};
