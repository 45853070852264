import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { FetchAllBlacklistsDocument, FetchAllBlacklistsQuery } from 'app/common/graphql/generated/graphql-gateway';
import { Blacklist } from '../common.model';

export const fethcAllBlacklists = async (client: ApolloClient<NormalizedCacheObject>): Promise<Blacklist[]> => {
  const response = await client.query<FetchAllBlacklistsQuery>({
    query: FetchAllBlacklistsDocument
  });

  return response.data.blacklists.map((b) => new Blacklist(b.id, b.name));
};
