import { DatePicker as FluentUIDatePicker, DayOfWeek, IDatePickerStrings } from '@fluentui/react';
import { IDateGridStrings, formatMonthDayYear } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';

interface DatePickerProps {
  onSelectDate: (date: Date) => void;
  value?: Date;
}

export const DatePicker = ({ onSelectDate, value }: DatePickerProps) => {
  const { formatMessage } = useIntl();

  const DatePickerStrings: IDatePickerStrings = {
    months: [
      formatMessage({ id: 'date-picker-labels.months.january' }),
      formatMessage({ id: 'date-picker-labels.months.february' }),
      formatMessage({ id: 'date-picker-labels.months.march' }),
      formatMessage({ id: 'date-picker-labels.months.april' }),
      formatMessage({ id: 'date-picker-labels.months.may' }),
      formatMessage({ id: 'date-picker-labels.months.june' }),
      formatMessage({ id: 'date-picker-labels.months.july' }),
      formatMessage({ id: 'date-picker-labels.months.august' }),
      formatMessage({ id: 'date-picker-labels.months.september' }),
      formatMessage({ id: 'date-picker-labels.months.october' }),
      formatMessage({ id: 'date-picker-labels.months.november' }),
      formatMessage({ id: 'date-picker-labels.months.december' })
    ],
    shortMonths: [
      formatMessage({ id: 'date-picker-labels.short-months.jan' }),
      formatMessage({ id: 'date-picker-labels.short-months.feb' }),
      formatMessage({ id: 'date-picker-labels.short-months.mar' }),
      formatMessage({ id: 'date-picker-labels.short-months.apr' }),
      formatMessage({ id: 'date-picker-labels.short-months.may' }),
      formatMessage({ id: 'date-picker-labels.short-months.jun' }),
      formatMessage({ id: 'date-picker-labels.short-months.jul' }),
      formatMessage({ id: 'date-picker-labels.short-months.aug' }),
      formatMessage({ id: 'date-picker-labels.short-months.sep' }),
      formatMessage({ id: 'date-picker-labels.short-months.oct' }),
      formatMessage({ id: 'date-picker-labels.short-months.nov' }),
      formatMessage({ id: 'date-picker-labels.short-months.dec' })
    ],
    days: [
      formatMessage({ id: 'date-picker-labels.days.sunday' }),
      formatMessage({ id: 'date-picker-labels.days.monday' }),
      formatMessage({ id: 'date-picker-labels.days.tuesday' }),
      formatMessage({ id: 'date-picker-labels.days.wednesday' }),
      formatMessage({ id: 'date-picker-labels.days.thursday' }),
      formatMessage({ id: 'date-picker-labels.days.friday' }),
      formatMessage({ id: 'date-picker-labels.days.saturday' })
    ],
    shortDays: [
      formatMessage({ id: 'date-picker-labels.short-days.SU' }),
      formatMessage({ id: 'date-picker-labels.short-days.M' }),
      formatMessage({ id: 'date-picker-labels.short-days.T' }),
      formatMessage({ id: 'date-picker-labels.short-days.W' }),
      formatMessage({ id: 'date-picker-labels.short-days.T' }),
      formatMessage({ id: 'date-picker-labels.short-days.F' }),
      formatMessage({ id: 'date-picker-labels.short-days.S' })
    ],
    goToToday: formatMessage({ id: 'date-picker-labels.go-to-today' }),
    prevMonthAriaLabel: formatMessage({ id: 'date-picker-labels.previous-month-label' }),
    nextMonthAriaLabel: formatMessage({ id: 'date-picker-labels.next-month-label' }),
    prevYearAriaLabel: formatMessage({ id: 'date-picker-labels.previous-year-label' }),
    nextYearAriaLabel: formatMessage({ id: 'date-picker-labels.next-year-label' })
  };

  const ShortDateGridStrings: IDateGridStrings = {
    months: DatePickerStrings.shortMonths,
    shortMonths: DatePickerStrings.shortMonths,
    days: DatePickerStrings.shortDays,
    shortDays: DatePickerStrings.shortDays
  };

  return (
    <div className="activities-date-datepicker">
      <FluentUIDatePicker
        firstDayOfWeek={DayOfWeek.Monday}
        placeholder={formatMessage({ id: 'filter-by-date-popup.input-placeholder' })}
        ariaLabel={formatMessage({ id: 'filter-by-date-popup.input-placeholder' })}
        strings={DatePickerStrings}
        isMonthPickerVisible={false}
        onSelectDate={onSelectDate}
        value={value}
        formatDate={(value: Date) => formatMonthDayYear(value, ShortDateGridStrings)}
      />
    </div>
  );
};
