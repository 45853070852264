import { Button, EditIcon, Input, MenuButton, SearchIcon, TrashCanIcon } from '@fluentui/react-northstar';
import { selectIsOnDesktop, useAppDispatch, useAppSelector } from 'app/common';
import { Recipient, SendingStatus } from 'app/pages/my-activities/sendings';
import { Icon } from '@fluentui/react';
import {
  openAddContactWizard,
  openAddFromCompanyPanel,
  openAddFromListPanel,
  openDeleteRecipientDialog,
  pageOfRecipientsRequested,
  selectRecipientsTotalCount,
  selectSelectedSendingStatus,
  setRecipientsQueryParams
} from 'app/pages/my-activities/sending-wizard';
import { searchListsRequested } from 'app/pages/my-audience/lists';
import { AddContactFromCompanyWizard, AddContactsFromListsWizard, AddContactsWizard } from 'app/pages/my-activities/sending-wizard';
import { useIntl } from 'app/i18n';

interface AudienceTableActionBarProps {
  selectedRecipientsOnPage: Recipient[];
  selectedRecipientsCount: number;
  addSelectedRecipientsToEditingRecipientIds(): void;
  disabled: boolean;
}

export const AudienceTableActionBar: React.FC<AudienceTableActionBarProps> = ({
  selectedRecipientsOnPage,
  selectedRecipientsCount,
  addSelectedRecipientsToEditingRecipientIds,
  disabled
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const recipientsCount = useAppSelector<number>(selectRecipientsTotalCount);
  const selectedSendingStatus = useAppSelector<SendingStatus>(selectSelectedSendingStatus);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const menuItems = [
    {
      key: 0,
      content: formatMessage({ id: 'add-contacts-menu-items.contacts' }),
      onClick: () => {
        dispatch(openAddContactWizard());
      },
      disabled: disabled
    },
    {
      key: 1,
      content: formatMessage({ id: 'add-contacts-menu-items.contacts-from-company' }),
      onClick: () => {
        dispatch(openAddFromCompanyPanel());
      },
      disabled: disabled
    },
    {
      key: 2,
      content: formatMessage({ id: 'add-contacts-menu-items.contacts-from-lists' }),
      onClick: () => {
        dispatch(searchListsRequested('*'));
        dispatch(openAddFromListPanel());
      },
      disabled: disabled
    }
  ];

  const onFilterRecipients = (searchText: string) => {
    dispatch(setRecipientsQueryParams({ searchText: searchText, pageNumber: 1 }));
    dispatch(pageOfRecipientsRequested());
  };

  const onOpenDeleteRecipientDialog = () => {
    dispatch(openDeleteRecipientDialog());
  };

  const getRecipientsLabel = () => {
    if (selectedRecipientsCount) {
      return formatMessage({ id: 'sending-profile.table-action-bar-selected-recipients-counter' }, { counter: selectedRecipientsCount });
    }

    return formatMessage({ id: 'sending-profile.table-action-bar-recipients-counter' }, { counter: recipientsCount });
  };

  return (
    <div className="action-bar-wrap">
      <div className="action-bar-buttons">
        {[SendingStatus.WaitingForDistribution, SendingStatus.Draft].includes(selectedSendingStatus) && isOnDesktop && (
          <>
            <div className="action-bar-button">
              <MenuButton
                trigger={<Button text icon={<Icon iconName="Add" />} content={formatMessage({ id: 'buttons.add' })} aria-label="Click button" />}
                menu={menuItems}
                on="click"
                className="action-bar-button"
              />
            </div>
            <div className="action-bar-button">
              <Button
                disabled={selectedRecipientsCount === 0 || selectedRecipientsOnPage.every((recipient) => !recipient.contact?.id)}
                text
                content={formatMessage({ id: 'sending-profile.table-action-bar-edit' })}
                icon={<EditIcon />}
                onClick={addSelectedRecipientsToEditingRecipientIds}
              />
            </div>
            <div className="action-bar-button">
              <Button
                disabled={selectedRecipientsCount === 0}
                text
                content={formatMessage({ id: 'buttons.remove' })}
                icon={<TrashCanIcon />}
                onClick={onOpenDeleteRecipientDialog}
              />
            </div>
          </>
        )}
        <div className="action-bar-input">
          <div className="action-bar-button">
            <Button disabled text content={getRecipientsLabel()} />
          </div>
          <div className="action-bar-button">
            <Input
              placeholder={formatMessage({ id: 'buttons.search' })}
              type="text"
              icon={<SearchIcon />}
              onChange={(input) => onFilterRecipients((input.target as HTMLTextAreaElement).value)}
            />
          </div>
        </div>
      </div>
      <AddContactsWizard />
      <AddContactFromCompanyWizard />
      <AddContactsFromListsWizard />
    </div>
  );
};
