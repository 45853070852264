import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/redux/store';
import { SavedSearch, SavedSearchWithIdInput } from 'app/pages/my-audience/saved-searches';
import { FilterItem } from 'app/common';

export interface MediaOutletsSavedSearchesState {
  pageNumber: number;
  pageSize: number;
  searchText: string;
  filterItem: FilterItem;
  totalCountOfSavedSearches: number;
  mediaOutletSavedSearches: SavedSearch[];
  mediaOutletSavedSearchToUpdate: SavedSearch;
  mediaOutletSavedSearchesToUpdate: SavedSearchWithIdInput[];
  mediaOutletSavedSearchToRemove: SavedSearch;
  isLoadingMediaOutletSavedSearches: boolean;
  isDeleteDialogOpened: boolean;
  isSaveSearchButtonDisabled: boolean;
}

const initialState: MediaOutletsSavedSearchesState = {
  pageNumber: 0,
  pageSize: 6,
  searchText: '',
  filterItem: {} as FilterItem,
  totalCountOfSavedSearches: 0,
  mediaOutletSavedSearches: [],
  mediaOutletSavedSearchToUpdate: {} as SavedSearch,
  mediaOutletSavedSearchesToUpdate: [],
  mediaOutletSavedSearchToRemove: {} as SavedSearch,
  isLoadingMediaOutletSavedSearches: true,
  isDeleteDialogOpened: false,
  isSaveSearchButtonDisabled: false
};

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.pageNumber],
  (pageNumber: number) => pageNumber
);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.companiesSavedSearches.pageSize], (pageSize: number) => pageSize);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.searchText],
  (searchText: string) => searchText
);

export type FilterItemSelector = (state: RootState) => FilterItem;
export const selectFilterItem: FilterItemSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.filterItem],
  (filterItem: FilterItem) => filterItem
);

export type TotalCountOfSavedSearchesSelector = (state: RootState) => number;
export const selectTotalCountOfSavedSearches: TotalCountOfSavedSearchesSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.totalCountOfSavedSearches],
  (totalCountOfSavedSearches: number) => totalCountOfSavedSearches
);

export type MediaOutletSavedSearchesSelector = (state: RootState) => SavedSearch[];
export const selectMediaOutletSavedSearches: MediaOutletSavedSearchesSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.mediaOutletSavedSearches],
  (mediaOutletSavedSearches: SavedSearch[]) => mediaOutletSavedSearches
);

export type MediaOutletSavedSearchToUpdateSelector = (state: RootState) => SavedSearch;
export const selectMediaOutletSavedSearchToUpdate: MediaOutletSavedSearchToUpdateSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.mediaOutletSavedSearchToUpdate],
  (mediaOutletSavedSearchToUpdate: SavedSearch) => mediaOutletSavedSearchToUpdate
);

export type MediaOutletSavedSearchesToUpdateSelector = (state: RootState) => SavedSearchWithIdInput[];
export const selectMediaOutletSavedSearchesToUpdate: MediaOutletSavedSearchesToUpdateSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.mediaOutletSavedSearchesToUpdate],
  (mediaOutletSavedSearchesToUpdate: SavedSearchWithIdInput[]) => mediaOutletSavedSearchesToUpdate
);

export type MediaOutletSavedSearchToRemoveIdSelector = (state: RootState) => string;
export const selectMediaOutletSavedSearchToRemoveId: MediaOutletSavedSearchToRemoveIdSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.mediaOutletSavedSearchToRemove.id],
  (id: string) => id
);

export type MediaOutletSavedSearchToRemoveSelector = (state: RootState) => SavedSearch;
export const selectMediaOutletSavedSearchToRemove: MediaOutletSavedSearchToRemoveSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.mediaOutletSavedSearchToRemove],
  (mediaOutletSavedSearchToRemove: SavedSearch) => mediaOutletSavedSearchToRemove
);

export type IsLoadingMediaOutletSavedSearchesSelector = (state: RootState) => boolean;
export const selectIsLoadingMediaOutletSavedSearches: IsLoadingMediaOutletSavedSearchesSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.isLoadingMediaOutletSavedSearches],
  (isLoadingMediaOutletSavedSearches: boolean) => isLoadingMediaOutletSavedSearches
);

export type IsDeleteDialogOpenedSelector = (state: RootState) => boolean;
export const selectIsDeleteDialogOpened: IsDeleteDialogOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.isDeleteDialogOpened],
  (isDeleteDialogOpened: boolean) => isDeleteDialogOpened
);

export type IsSaveSearchButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsSaveSearchButtonDisabled: IsSaveSearchButtonDisabledSelector = createSelector(
  [(state: RootState) => state.mediaOutletsSavedSearches.isSaveSearchButtonDisabled],
  (isSaveSearchButtonDisabled: boolean) => isSaveSearchButtonDisabled
);

const mediaOutletsSavedSearchesSlice = createSlice({
  name: 'media-outlets-saved-searches',
  initialState,
  reducers: {
    setTotalCountOfSavedSearches: (state, action: PayloadAction<number>) => {
      state.totalCountOfSavedSearches = action.payload;
    },
    firstPageOfSavedSearchesRequested: (state, action: PayloadAction<{ searchText: string; filterItem: FilterItem }>) => {
      state.totalCountOfSavedSearches = 0;
      state.pageNumber = 1;
      state.mediaOutletSavedSearches = [];
      state.isLoadingMediaOutletSavedSearches = true;
      state.filterItem = action.payload.filterItem;
      state.searchText = action.payload.searchText;
    },
    firstPageOfSavedSearchesReceived: (state, action: PayloadAction<SavedSearch[]>) => {
      state.mediaOutletSavedSearches = [...action.payload];
      state.isLoadingMediaOutletSavedSearches = false;
    },
    mediaOutletsSavedSearchesRequested: (state, action: PayloadAction<{ searchText: string; filterItem: FilterItem; pageNumber: number }>) => {
      state.isLoadingMediaOutletSavedSearches = true;
      state.filterItem = action.payload.filterItem;
      state.pageNumber = action.payload.pageNumber;
      state.searchText = action.payload.searchText;
    },
    mediaOutletsSavedSearchesReceived: (state, action: PayloadAction<SavedSearch[]>) => {
      state.mediaOutletSavedSearches = [...state.mediaOutletSavedSearches, ...action.payload];
      state.isLoadingMediaOutletSavedSearches = false;
    },
    addMediaOutletSavedSearchRequested: (state) => {
      state.isSaveSearchButtonDisabled = true;
    },
    mediaOutletSavedSearchAdded: (state, action: PayloadAction<SavedSearch>) => {
      state.mediaOutletSavedSearches = [action.payload, ...state.mediaOutletSavedSearches];
      state.totalCountOfSavedSearches += 1;
      state.isSaveSearchButtonDisabled = false;
    },
    updateMediaOutletSavedSearchRequested: (state, action: PayloadAction<SavedSearch>) => {
      state.mediaOutletSavedSearchToUpdate = action.payload;
    },
    updatedMediaOutletSavedSearch: (state, action: PayloadAction<SavedSearch>) => {
      const index = state.mediaOutletSavedSearches.findIndex((savedSearch) => savedSearch.id === action.payload.id);
      state.mediaOutletSavedSearches = [...state.mediaOutletSavedSearches.slice(0, index), action.payload, ...state.mediaOutletSavedSearches.slice(index + 1)];
      state.mediaOutletSavedSearchToUpdate = {} as SavedSearch;
    },
    updateMediaOutletSavedSearchesRequested: (state, action: PayloadAction<SavedSearchWithIdInput[]>) => {
      state.mediaOutletSavedSearchesToUpdate = action.payload;
    },
    updatedMediaOutletSavedSearches: (state, action: PayloadAction<SavedSearch[]>) => {
      state.mediaOutletSavedSearches = [...action.payload];
      state.mediaOutletSavedSearchesToUpdate = [];
    },
    removeMediaOutletSavedSearchRequested: (state, action: PayloadAction<SavedSearch>) => {
      state.mediaOutletSavedSearchToRemove = action.payload;
    },
    removedMediaOutletSavedSearch: (state, action: PayloadAction<string>) => {
      state.mediaOutletSavedSearches = state.mediaOutletSavedSearches.filter((savedSearch) => savedSearch.id !== action.payload);
      state.mediaOutletSavedSearchToRemove = {} as SavedSearch;
      state.totalCountOfSavedSearches = state.totalCountOfSavedSearches - 1;
    },
    setMediaOutletSavedSearchToRemove: (state, action: PayloadAction<SavedSearch>) => {
      state.mediaOutletSavedSearchToRemove = action.payload;
      state.isDeleteDialogOpened = true;
    },
    loadNextMediaOutletSavedSearchAfterDeleteRequested: () => {},
    closeDeleteDialog: (state) => {
      state.mediaOutletSavedSearchToRemove = {} as SavedSearch;
      state.isDeleteDialogOpened = false;
    }
  }
});

export const {
  setTotalCountOfSavedSearches,
  firstPageOfSavedSearchesRequested,
  firstPageOfSavedSearchesReceived,
  mediaOutletsSavedSearchesRequested,
  mediaOutletsSavedSearchesReceived,
  addMediaOutletSavedSearchRequested,
  mediaOutletSavedSearchAdded,
  updateMediaOutletSavedSearchRequested,
  updatedMediaOutletSavedSearch,
  updateMediaOutletSavedSearchesRequested,
  updatedMediaOutletSavedSearches,
  removeMediaOutletSavedSearchRequested,
  removedMediaOutletSavedSearch,
  setMediaOutletSavedSearchToRemove,
  loadNextMediaOutletSavedSearchAfterDeleteRequested,
  closeDeleteDialog
} = mediaOutletsSavedSearchesSlice.actions;
export default mediaOutletsSavedSearchesSlice.reducer;
