import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { FilterItem, User, createSending, createUser } from 'app/common';
import {
  AddSendingDocument,
  AddSendingMutation,
  AddSendingWithContactsIdsDocument,
  AddSendingWithContactsIdsMutation,
  AddSendingWithRecipientsFromListsDocument,
  AddSendingWithRecipientsFromListsMutation,
  AddSendingsDocument,
  AddSendingsMutation,
  ContactsSendingInput,
  DuplicateSendingsDocument,
  DuplicateSendingsMutation,
  FetchSendingsByQueryParamsDocument,
  FetchSendingsByQueryParamsQuery,
  FetchUsersByIdsDocument,
  FetchUsersByIdsQuery,
  ContactsSending as GqlSending,
  RemoveSendingDocument,
  RemoveSendingMutation,
  RemoveSendingsDocument,
  RemoveSendingsMutation,
  SendingsCreatedByUserFilterSuggestionsDocument,
  SendingsCreatedByUserFilterSuggestionsQuery,
  SendingsFilterSuggestionsByCampaignNameDocument,
  SendingsFilterSuggestionsByCampaignNameQuery,
  SendingsFilterSuggestionsByEmailSubjectDocument,
  SendingsFilterSuggestionsByEmailSubjectQuery,
  SendingsFilterSuggestionsByRecipientEmailOrNameDocument,
  SendingsFilterSuggestionsByRecipientEmailOrNameQuery,
  SendingsFilterSuggestionsByRecipientMediumNameDocument,
  SendingsFilterSuggestionsByRecipientMediumNameQuery,
  SendingsFilterSuggestionsByTagNameDocument,
  SendingsFilterSuggestionsByTagNameQuery,
  SendingsTitleFilterSuggestionsDocument,
  SendingsTitleFilterSuggestionsQuery,
  UpdateSendingDocument,
  UpdateSendingMutation,
  UpdateSendingsDocument,
  UpdateSendingsMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { PageOfSendings, Sending } from 'app/pages/my-activities/sendings';
import { ISortingInput } from 'app/pages/my-audience/contacts';

export const fetchSendingsByQueryParams = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  filterItems: FilterItem[],
  searchText: string,
  sortingInput: ISortingInput
): Promise<PageOfSendings> => {
  const response = await client.query<FetchSendingsByQueryParamsQuery>({
    query: FetchSendingsByQueryParamsDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      filterItems: filterItems,
      searchText: searchText,
      sortingInput: {
        contactsSortOption: sortingInput.sortOption,
        sortOrder: sortingInput.sortOrder
      }
    }
  });

  return new PageOfSendings(
    response.data.sendings.totalCount,
    response.data.sendings.sendings.map((sending) => {
      return createSending(sending as GqlSending);
    })
  );
};

export const removeSending = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<string> => {
  const response = await client.mutate<RemoveSendingMutation>({
    mutation: RemoveSendingDocument,
    variables: {
      sendingId: id
    }
  });

  return response.data.removeContactSending.id;
};

export const removeSendings = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<string[]> => {
  const response = await client.mutate<RemoveSendingsMutation>({
    mutation: RemoveSendingsDocument,
    variables: {
      input: ids
    }
  });

  return response.data.removeContactSendings;
};

export const updateSending = async (client: ApolloClient<NormalizedCacheObject>, id: string, sending: Sending): Promise<Sending> => {
  const input: ContactsSendingInput = {
    id: sending.id,
    title: sending.title,
    subject: sending.subject,
    sender: {
      id: sending.sender?.id ? sending.sender.id : ''
    },
    senderEmailAddress: sending.senderEmailAddress,
    senderDisplayName: sending.senderDisplayName,
    respondToEmailAddress: sending.respondToEmailAddress ? sending.respondToEmailAddress : '',
    htmlBody: sending.htmlBody,
    jsonBody: sending.jsonBody,
    isFavourite: sending.isFavourite,
    blacklist: sending.blacklist?.id ? sending.blacklist : null
  };

  const response = await client.mutate<UpdateSendingMutation>({
    mutation: UpdateSendingDocument,
    variables: {
      sendingId: id,
      input
    }
  });

  return createSending(response.data.updateContactSending as GqlSending);
};

export const updateSendings = async (client: ApolloClient<NormalizedCacheObject>, sendings: Sending[]): Promise<Sending[]> => {
  const input: ContactsSendingInput[] = sendings.map((sending) => {
    const sendingInput: ContactsSendingInput = {
      id: sending.id,
      title: sending.title,
      subject: sending.subject,
      sender: {
        id: sending.sender?.id ? sending.sender.id : ''
      },
      senderEmailAddress: sending.senderEmailAddress,
      senderDisplayName: sending.senderDisplayName,
      respondToEmailAddress: sending.respondToEmailAddress ? sending.respondToEmailAddress : '',
      htmlBody: sending.htmlBody,
      jsonBody: sending.jsonBody,
      isFavourite: sending.isFavourite,
      blacklist: sending.blacklist
    };

    return sendingInput;
  });

  const response = await client.mutate<UpdateSendingsMutation>({
    mutation: UpdateSendingsDocument,
    variables: {
      input
    }
  });

  return response.data.updateContactSendings.map((sending) => createSending(sending as GqlSending));
};

export const duplicateSendings = async (client: ApolloClient<NormalizedCacheObject>, sendingsIds: string[], localizedTitlePart: string): Promise<Sending[]> => {
  const response = await client.mutate<DuplicateSendingsMutation>({
    mutation: DuplicateSendingsDocument,
    variables: {
      sendingsIds,
      localizedTitlePart
    }
  });

  return response.data.duplicateContactSendings.map((sending) => createSending(sending as GqlSending));
};

export const addSending = async (client: ApolloClient<NormalizedCacheObject>, sending: Sending): Promise<Sending> => {
  const input: ContactsSendingInput = {
    id: '',
    title: sending.title,
    subject: sending.subject,
    sender: {
      id: sending.sender?.id ? sending.sender.id : ''
    },
    senderEmailAddress: sending.senderEmailAddress,
    senderDisplayName: sending.senderDisplayName,
    respondToEmailAddress: sending.respondToEmailAddress ? sending.respondToEmailAddress : '',
    htmlBody: sending.htmlBody,
    jsonBody: sending.jsonBody,
    isFavourite: false,
    blacklist: sending.blacklist
  };

  const response = await client.mutate<AddSendingMutation>({
    mutation: AddSendingDocument,
    variables: {
      input
    }
  });

  return createSending(response.data.addContactSending as GqlSending);
};

export const addSendingWithRecipientsFromLists = async (
  client: ApolloClient<NormalizedCacheObject>,
  sending: Sending,
  listsIds: string[]
): Promise<Sending> => {
  const input: ContactsSendingInput = {
    id: '',
    title: sending.title,
    subject: sending.subject,
    isFavourite: sending.isFavourite
  };

  const response = await client.mutate<AddSendingWithRecipientsFromListsMutation>({
    mutation: AddSendingWithRecipientsFromListsDocument,
    variables: {
      input,
      listsIds
    }
  });

  return createSending(response.data.addSendingWithRecipientsFromLists as GqlSending);
};

export const addSendingWithContactsIds = async (client: ApolloClient<NormalizedCacheObject>, sending: Sending, contactsIds: string[]): Promise<Sending> => {
  const input: ContactsSendingInput = {
    id: '',
    title: sending.title,
    subject: sending.subject,
    isFavourite: sending.isFavourite
  };

  const response = await client.mutate<AddSendingWithContactsIdsMutation>({
    mutation: AddSendingWithContactsIdsDocument,
    variables: {
      input,
      contactsIds
    }
  });

  return createSending(response.data.addSendingWithContactsIds as GqlSending);
};

export const addSendings = async (client: ApolloClient<NormalizedCacheObject>, sendings: Sending[]): Promise<Sending[]> => {
  const input: ContactsSendingInput[] = sendings.map((sending) => ({
    id: '',
    title: sending.title,
    imageAsBase64: sending.imageAsBase64,
    subject: sending.subject,
    sender: {
      id: sending.sender?.id ?? ''
    },
    senderEmailAddress: sending.senderEmailAddress,
    senderDisplayName: sending.senderDisplayName,
    respondToEmailAddress: sending.respondToEmailAddress ? sending.respondToEmailAddress : '',
    htmlBody: sending.htmlBody,
    originalHtmlBody: sending.originalHtmlBody,
    jsonBody: sending.jsonBody,
    mjmlBody: sending.mjmlBody,
    isFavourite: sending.isFavourite,
    blacklist: sending.blacklist
  }));

  const response = await client.mutate<AddSendingsMutation>({
    mutation: AddSendingsDocument,
    variables: {
      input
    }
  });

  return response.data.addContactSendings.map((sending) => createSending(sending as GqlSending));
};

export const fetchUsersByIdsQuery = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<User[]> => {
  const response = await client.query<FetchUsersByIdsQuery>({
    query: FetchUsersByIdsDocument,
    variables: {
      ids: ids
    }
  });
  return response.data.usersByIds.map((user) => {
    return createUser(user);
  });
};

export const getTitleFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, title: string, take: number): Promise<string[]> => {
  const response = await client.query<SendingsTitleFilterSuggestionsQuery>({
    query: SendingsTitleFilterSuggestionsDocument,
    variables: {
      title,
      take
    }
  });

  return response.data.sendingsTitleFilterSuggestions;
};

export const getCreatedByFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, createdBy: string, take: number): Promise<string[]> => {
  const response = await client.query<SendingsCreatedByUserFilterSuggestionsQuery>({
    query: SendingsCreatedByUserFilterSuggestionsDocument,
    variables: {
      createdBy,
      take
    }
  });

  return response.data.sendingsCreatedByUserFilterSuggestions;
};

export const getCampaignNameFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, campaignName: string, take: number): Promise<string[]> => {
  const response = await client.query<SendingsFilterSuggestionsByCampaignNameQuery>({
    query: SendingsFilterSuggestionsByCampaignNameDocument,
    variables: {
      campaignName,
      take
    }
  });

  return response.data.sendingsFilterSuggestionsByCampaignName;
};

export const getEmailSubjectFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, emailSubject: string, take: number): Promise<string[]> => {
  const response = await client.query<SendingsFilterSuggestionsByEmailSubjectQuery>({
    query: SendingsFilterSuggestionsByEmailSubjectDocument,
    variables: {
      emailSubject,
      take
    }
  });

  return response.data.sendingsFilterSuggestionsByEmailSubject;
};

export const getTagNameFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, tagName: string, take: number): Promise<string[]> => {
  const response = await client.query<SendingsFilterSuggestionsByTagNameQuery>({
    query: SendingsFilterSuggestionsByTagNameDocument,
    variables: {
      tagName,
      take
    }
  });

  return response.data.sendingsFilterSuggestionsByTagName;
};

export const getRecipientMediumNameFilterSuggestions = async (
  client: ApolloClient<NormalizedCacheObject>,
  recipientMediumName: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<SendingsFilterSuggestionsByRecipientMediumNameQuery>({
    query: SendingsFilterSuggestionsByRecipientMediumNameDocument,
    variables: {
      recipientMediumName,
      take
    }
  });

  return response.data.sendingsFilterSuggestionsByRecipientMediumName;
};

export const getRecipientEmailOrNameFilterSuggestions = async (
  client: ApolloClient<NormalizedCacheObject>,
  recipientNameOrEmail: string,
  take: number
): Promise<string[]> => {
  const response = await client.query<SendingsFilterSuggestionsByRecipientEmailOrNameQuery>({
    query: SendingsFilterSuggestionsByRecipientEmailOrNameDocument,
    variables: {
      recipientNameOrEmail,
      take
    }
  });

  return response.data.sendingsFilterSuggestionsByRecipientEmailOrName;
};
