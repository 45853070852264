import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItemProps, ShorthandValue } from '@fluentui/react-northstar';
import { useAppSelector, NavMenuItem, selectIsNavigationCollapsed, MyActivitiesTab, useActiveMenuItemClass, MAIN_ROUTES } from 'app/common';
import 'app/common/components/navigation/Navigation.scss';

export const ActivitiesNavigation = () => {
  const navigate = useNavigate();
  const activeMenuItemClass = useActiveMenuItemClass(MAIN_ROUTES.ACTIVITIES_OVERVIEW_PATH);
  const isNavigationCollapsed = useAppSelector<boolean>(selectIsNavigationCollapsed);

  const items: ShorthandValue<MenuItemProps>[] = [
    {
      key: 'e-mailings',
      content: <NavMenuItem name="E-Mailings" icon="Mail" tooltip="E-Mailings" isNavigationCollapsed={isNavigationCollapsed} />,
      className: `item-link ${activeMenuItemClass('e-mailings')}`
    }
  ];

  const onMenuItemClick = (_: SyntheticEvent<HTMLElement>, data: MenuItemProps) => {
    if (data.index === MyActivitiesTab.Emailings) {
      navigate('e-mailings');
      return;
    }
  };

  return (
    <div className="navigation-items my-activities-nav">
      <Menu items={items} onItemClick={onMenuItemClick} vertical pointing />
    </div>
  );
};
