import { Pill, Button, CloseIcon, FilterIcon, Tooltip } from '@fluentui/react-northstar';
import { FilterItem, selectIsOnDesktop, useAppSelector, useReferenceListLocalization } from 'app/common';
import { getFilterLocalizationKey } from 'app/common/filterLocalization';
import { useIntl } from 'app/i18n';

interface FilterPillsProps {
  filterItems: FilterItem[];
  onRemoveFilter: (filterItem: FilterItem) => void;
  onSearch: () => void;
  onOpenFiltersPanel: () => void;
}

const DESKTOP_NUMBER_OF_PILLS = 7;
const TOUCH_NUMBER_OF_PILLS = 3;

export const FilterPills = ({ filterItems, onRemoveFilter, onSearch, onOpenFiltersPanel }: FilterPillsProps) => {
  const { formatMessage } = useIntl();
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const numberOfPills = isOnDesktop ? DESKTOP_NUMBER_OF_PILLS : TOUCH_NUMBER_OF_PILLS;
  const { localizeFilterItem } = useReferenceListLocalization();

  return (
    <div className="search-results-actions-wrap">
      <div className="filter-input-wrap">
        <div className="filter-pills-wrap">
          {filterItems.slice(0, numberOfPills).map((item, index) => (
            <Tooltip
              subtle={false}
              pointing
              key={item.fieldName + index}
              content={localizeFilterItem(item)}
              align="bottom"
              position="below"
              trigger={
                <Pill key={index + item.fieldName} className="filter-pill">
                  <span className="filter-pill-text" onClick={onOpenFiltersPanel}>
                    <span className="filter-pill-field-name">{formatMessage({ id: getFilterLocalizationKey(item.fieldName) || 'filters.filters' })}:</span>
                    <span className="filter-pill-name">{localizeFilterItem(item)}</span>
                  </span>
                  <Button
                    icon={<CloseIcon />}
                    iconOnly
                    text
                    onClick={() => {
                      onRemoveFilter(item);
                      onSearch();
                    }}
                  />
                </Pill>
              }
            />
          ))}
          {filterItems.length - numberOfPills > 0 && (
            <Tooltip
              subtle={false}
              pointing
              align="bottom"
              position="below"
              content={
                <div className="filter-pills-tooltip-wrap">
                  {filterItems.slice(numberOfPills).map((item) => (
                    <div className="filter-pills-tooltip" key={item.fieldName}>
                      <span className="filter-pills-tooltip-field-name">
                        {formatMessage({ id: getFilterLocalizationKey(item.fieldName) || 'filters.filters' })}:
                      </span>
                      <span className="filter-pills-tooltip-name">{localizeFilterItem(item)}</span>
                    </div>
                  ))}
                </div>
              }
              trigger={
                <div className="filter-pills-number-wrap">
                  <span className="filter-pills-number">+{filterItems.length - numberOfPills}</span>
                </div>
              }
            />
          )}
        </div>
        <div className="button-filter-wrap">
          <Button
            tinted
            iconOnly
            title={formatMessage({ id: 'filters.filter' })}
            content={formatMessage({ id: 'filters.filter' })}
            icon={<FilterIcon />}
            onClick={onOpenFiltersPanel}
            className="button-filter"
          />
        </div>
      </div>
    </div>
  );
};
