import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertNotification,
  BottomPopup,
  MAIN_ROUTES,
  selectActivitiesCurrentUrl,
  selectIsAlertNotificationVisible,
  selectIsUndoAlertVisible,
  UndoAlert,
  undoQueue,
  useAppSelector
} from 'app/common';
import { ActivitiesNavigation } from 'app/pages/my-activities/navigation/ActivitiesNavigation';
import { Sendings } from 'app/pages/my-activities/sendings';
import { AllTemplates } from 'app/pages/my-activities/templates';

const ACTIVITIES_OVERVIEW_PATH = MAIN_ROUTES.ACTIVITIES_OVERVIEW_PATH;

export const ActivitiesOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isUndoAlertVisible = useAppSelector<boolean>(selectIsUndoAlertVisible);
  const activitiesSavedUrl = useAppSelector<string>(selectActivitiesCurrentUrl);
  const isAlertNotificationVisible = useAppSelector<boolean>(selectIsAlertNotificationVisible);

  useEffect(() => {
    if (activitiesSavedUrl && activitiesSavedUrl !== ACTIVITIES_OVERVIEW_PATH && location.pathname === ACTIVITIES_OVERVIEW_PATH) navigate(activitiesSavedUrl);
  }, [activitiesSavedUrl, location, navigate]);

  return (
    <main className="main">
      <nav className="navigation">
        <ActivitiesNavigation />
      </nav>
      <div className="content">
        {isUndoAlertVisible && <UndoAlert isUndoAlertVisible={isUndoAlertVisible} undoQueue={undoQueue} />}
        {isAlertNotificationVisible && <AlertNotification isAlertNotificationVisible={isAlertNotificationVisible} />}
        <Routes>
          <Route path="/e-mailings/*" element={<Sendings />} />
          <Route path="/templates/*" element={<AllTemplates />} />
          <Route path="*" element={<Navigate to={'e-mailings'} />} />
        </Routes>
      </div>
      <BottomPopup />
    </main>
  );
};
