import {
  hideUndoAlert,
  printData,
  showAlertNotification,
  showUndoAlert,
  undoQueue,
  useAppDispatch,
  useAppSelector,
  WizardFinishIntro,
  WizardIntro
} from 'app/common';
import { useIntl } from 'app/i18n';
import { createContactWithCollaboration } from 'app/pages/my-audience/common';
import {
  AddContactSteps,
  AddContactWizardFooter,
  AddContactWizardSteps,
  addContributionRequested,
  addContributionToStore,
  closeAddContactWizard,
  disableAddContactFinishButton,
  goToPreviousStepAddContact,
  goToStepAddContact,
  selectCollaborationToAdd,
  selectOwnContactDataToAdd,
  selectSelectedContact,
  undoAddContributionToStore
} from 'app/pages/my-audience/company-profile';
import { OwnContactData } from 'app/pages/my-audience/contacts';

export const AddContactFinish = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const collaboration = useAppSelector(selectCollaborationToAdd);
  const contact = useAppSelector(selectSelectedContact);
  const ownContactDataToAdd = useAppSelector<OwnContactData>(selectOwnContactDataToAdd);

  const handleCancelButtonClick = () => {
    dispatch(closeAddContactWizard());
  };

  const addCollaborationToStore = () => {
    const contactToAdd = createContactWithCollaboration(contact, collaboration, ownContactDataToAdd);
    dispatch(disableAddContactFinishButton());
    dispatch(addContributionToStore(contactToAdd));
    dispatch(closeAddContactWizard());

    undoQueue.waitAndExecute({
      executeAction: addCollaboration,
      undoAction: revertCollaborationFromStore
    });
    dispatch(showUndoAlert(formatMessage({ id: 'companies-add-contact.undo-msg-alert' })));
  };

  const addCollaboration = () => {
    dispatch(hideUndoAlert());
    dispatch(addContributionRequested({ collaboration, contact }));
    dispatch(showAlertNotification(formatMessage({ id: 'companies-add-contact.success-msg-alert' })));
  };

  const revertCollaborationFromStore = () => {
    dispatch(undoAddContributionToStore(contact.id));
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStepAddContact());
  };

  const editStep = (step: AddContactWizardSteps) => {
    dispatch(goToStepAddContact(step));
  };

  return (
    <>
      <AddContactSteps />
      <div className="wizard-panel-finish-wrap">
        <WizardIntro title={formatMessage({ id: 'companies-add-contact.wizard-finish-title' })} description="" />
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                title={formatMessage({ id: 'companies-add-contact.wizard-contact-title' })}
                onEditButtonClick={() => editStep(AddContactWizardSteps.SearchContacts)}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-contact-salutation' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(contact.salutation)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-contact-name' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(contact.firstName)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-contact-lastname' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(contact.lastName)}</span>
                </div>
              </div>
            </div>
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                title={formatMessage({ id: 'companies-add-contact.wizard-personal-title' })}
                onEditButtonClick={() => editStep(AddContactWizardSteps.ProfileInformation)}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-role-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.jobTitle?.name)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-job-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.jobDescription)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-personal-phone-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.landlinePhoneNumber?.value)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-personal-mobile-phone-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.mobilePhoneNumber?.value)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-personal-email-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.emailAddress)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-personal-address-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.address)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-zip-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.postalCode)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-personal-city-label' })} </span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.city)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">{formatMessage({ id: 'companies-add-contact.wizard-personal-country-label' })}</span>
                  <span className="wizard-panel-finish-main-input">{printData(collaboration.country)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddContactWizardFooter
        onCancelButtonClick={handleCancelButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onFinishButtonClick={addCollaborationToStore}
      />
    </>
  );
};
