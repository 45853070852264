import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, WizardEmptyInfo } from 'app/common';
import {
  AddMediaOutletWizard,
  MediaOutletsPreview,
  selectMediaOutletCollaborations,
  openAddMediaWizard,
  AddMediaToolbar,
  pageOfMediaOutletsRequested
} from 'app/pages/my-audience/contact-profile';
import { Collaboration } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const MediaOutlets = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const mediaOutletCollaborations = useAppSelector<Collaboration[]>(selectMediaOutletCollaborations);

  const [collaborations, setCollaborations] = useState<Collaboration[]>(mediaOutletCollaborations);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setCollaborations(mediaOutletCollaborations);
  }, [mediaOutletCollaborations]);

  const searchByName = (inputSearchText: string) => {
    setInputValue(inputSearchText);

    inputSearchText !== ''
      ? setCollaborations(mediaOutletCollaborations.filter((c) => c.medium.name.toLocaleLowerCase().includes(inputSearchText.toLocaleLowerCase())))
      : setCollaborations(mediaOutletCollaborations);
  };

  const handleOpenWizard = () => {
    dispatch(pageOfMediaOutletsRequested());
    dispatch(openAddMediaWizard());
  };

  const addMediaOutletButtonContent = formatMessage({ id: 'media-outlet-and-company-tabs.add-media-button' });

  return (
    <div className="profile-panel-media-wrap">
      <div className="profile-panel-media-container">
        <AddMediaToolbar
          searchByName={searchByName}
          searchedItemLength={collaborations?.length}
          openWizard={handleOpenWizard}
          buttonText={addMediaOutletButtonContent}
          inputValue={inputValue}
        />
        {collaborations?.length > 0 ? (
          <MediaOutletsPreview mediaOutletCollaborations={collaborations} />
        ) : (
          <WizardEmptyInfo
            message={formatMessage({ id: 'media-outlet-and-company-tabs.no-results-message.title' })}
            description={formatMessage({ id: 'media-outlet-and-company-tabs.no-results-message.text' }, { addButton: addMediaOutletButtonContent })}
          />
        )}
      </div>
      <AddMediaOutletWizard />
    </div>
  );
};
