import { Button, RetryIcon, TrashCanIcon } from '@fluentui/react-northstar';
import { selectIsOnDesktop, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { List } from 'app/pages/my-audience/lists';
import {
  pageOfContactsSavedSearchesRequested,
  openAddSavedSearchesPanel,
  removeContactsSavedSearchesFromListRequested,
  selectSelectedLinkedSavedSearchesIds,
  selectSelectedLinkedSavedSearchesLength,
  selectSelectedList,
  refreshLinkedSavedSearchesRequested,
  resetAddSavedSearchesQuery
} from 'app/pages/my-audience/lists-profile';
import { Permission, useAuthContext } from 'auth';

export const SavedSearchesTableActionBar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedList = useAppSelector<List>(selectSelectedList);
  const selectedSavedSearchesIds = useAppSelector<string[]>(selectSelectedLinkedSavedSearchesIds);
  const selectedSavedSearchesLength = useAppSelector<number>(selectSelectedLinkedSavedSearchesLength);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const { hasPermission } = useAuthContext();
  const hasPermissionToEdit = hasPermission(Permission.ManageLists);

  const isActionDisabled = !selectedSavedSearchesLength || !hasPermissionToEdit;

  const onRemoveSavedSearchesFromList = () => {
    dispatch(removeContactsSavedSearchesFromListRequested(selectedSavedSearchesIds));
  };

  const onOpenAddSavedSearches = () => {
    dispatch(resetAddSavedSearchesQuery());
    dispatch(pageOfContactsSavedSearchesRequested());
    dispatch(openAddSavedSearchesPanel());
  };

  const onUpdateSelectedSavedSearches = () => {
    dispatch(refreshLinkedSavedSearchesRequested());
  };

  return (
    <div className="action-bar-wrap action-bar-table">
      <div className="action-bar-buttons">
        {isOnDesktop && (
          <Button
            text
            disabled={!hasPermissionToEdit}
            content={`+${formatMessage({ id: 'buttons.add' })}`}
            aria-label="Click button"
            onClick={onOpenAddSavedSearches}
          />
        )}
        {selectedList.linkedSavedSearches?.length > 0 && (
          <>
            <div className="action-bar-button">
              <Button
                disabled={isActionDisabled}
                text
                content={formatMessage({ id: 'list-profile-saved-searches.update-selected' })}
                icon={<RetryIcon />}
                onClick={() => onUpdateSelectedSavedSearches()}
              />
            </div>
            <div className="action-bar-button">
              <Button
                disabled={isActionDisabled}
                text
                content={formatMessage({ id: 'list-profile-saved-searches.remove-selected' })}
                icon={<TrashCanIcon />}
                onClick={onRemoveSavedSearchesFromList}
              />
            </div>
            {selectedSavedSearchesLength === 1 && (
              <div className="action-bar-button">
                <Button
                  disabled
                  text
                  content={selectedSavedSearchesLength + ` ${formatMessage({ id: 'list-profile-saved-searches.saved-search-selected' })}`}
                />
              </div>
            )}
            {selectedSavedSearchesLength > 1 && (
              <div className="action-bar-button">
                <Button
                  disabled
                  text
                  content={selectedSavedSearchesLength + ` ${formatMessage({ id: 'list-profile-saved-searches.saved-searches-selected' })}`}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
