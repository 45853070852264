import { Comment, CommentUser, MediumCategory, MediumCategoryType, TagProjection } from 'app/common';
import { MediaOutlet as GqlMediaOutlet } from 'app/common/graphql/generated/graphql-gateway';
import { MediumType, MediumTypeName } from 'app/pages/my-audience/contacts';
import { MediaResort } from 'app/pages/my-audience/media-outlet-profile';
import {
  MediumContactData as ContactData,
  GeographicalCoverage,
  LocalizedListValue,
  MediaOutlet,
  MediaOutletContact,
  MediaOutletSearchResultTableData,
  PreferredLanguage
} from 'app/pages/my-audience/media-outlets';

export const createMediaOutlet = (gqlMediaOutlet: GqlMediaOutlet): MediaOutlet => {
  return new MediaOutlet(
    gqlMediaOutlet.id,
    gqlMediaOutlet.name,
    gqlMediaOutlet.profilePictureUrl,
    gqlMediaOutlet.description,
    gqlMediaOutlet.audience?.key,
    gqlMediaOutlet.category?.id
      ? new MediumCategory(gqlMediaOutlet.category.id, gqlMediaOutlet.category.name, new MediumCategoryType(MediumTypeName(MediumType.MediaOutlet), 1))
      : ({} as MediumCategory),
    gqlMediaOutlet.frequency?.key,
    gqlMediaOutlet.geographicalCoverage?.id
      ? new GeographicalCoverage(gqlMediaOutlet.geographicalCoverage.id, gqlMediaOutlet.geographicalCoverage.name)
      : ({} as GeographicalCoverage),
    gqlMediaOutlet.mediumSupportTypes?.length ? gqlMediaOutlet.mediumSupportTypes.map((mst) => mst.key) : [],
    gqlMediaOutlet.comments,
    gqlMediaOutlet.preferredLanguages?.map((language) => new PreferredLanguage(language.id, language.name, language.dataSourceKey)),
    new ContactData(
      gqlMediaOutlet.contactData.street,
      gqlMediaOutlet.contactData.poBox,
      gqlMediaOutlet.contactData.postalCode,
      gqlMediaOutlet.contactData.country,
      gqlMediaOutlet.contactData.city,
      gqlMediaOutlet.contactData.websiteUrl,
      gqlMediaOutlet.contactData.emailAddress,
      gqlMediaOutlet.contactData.landlinePhoneNumber,
      gqlMediaOutlet.contactData.fax,
      gqlMediaOutlet.contactData.alternativeFax
    ),
    gqlMediaOutlet.mediaOutletContacts?.map((item) => new MediaOutletContact(item.id, item.firstName, item.lastName, item.profilePictureUrl)),
    gqlMediaOutlet.tags?.map((tag) => new TagProjection(tag.id, tag.name)),
    gqlMediaOutlet.mediaResorts?.map((mediaResort) => new MediaResort(mediaResort.mediaResortId, mediaResort.mediaResortName, mediaResort.dataSourceKey)),
    gqlMediaOutlet.userComments?.map(
      (comment) =>
        new Comment(
          comment.id,
          new CommentUser(comment?.author?.teamsId, comment?.author?.name, comment?.author?.aadObjectId),
          comment.content,
          new Date(comment.createdAt),
          comment.replies?.map(
            (reply) =>
              new Comment(
                reply.id,
                new CommentUser(reply.author?.teamsId, reply.author?.name, reply.author?.aadObjectId),
                reply.content,
                new Date(reply.createdAt),
                [],
                reply.mentions?.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
              )
          ),
          comment.mentions?.map((mention) => new CommentUser(mention.teamsId, mention.name, mention.aadObjectId))
        )
    ),
    gqlMediaOutlet.mediaType,
    gqlMediaOutlet.publicationType,
    gqlMediaOutlet.wemfArea ?? '',
    gqlMediaOutlet.readership,
    0,
    gqlMediaOutlet.circulation,
    gqlMediaOutlet.dataSourceKey
  );
};

export const createMediaOutletTableData = (
  mediaOutlets: MediaOutlet[],
  frequencies: LocalizedListValue[],
  mediumSupportTypes: LocalizedListValue[],
  mediaOutletLabel: string
): MediaOutletSearchResultTableData[] => {
  return mediaOutlets?.map(
    (mediaOutlet) =>
      new MediaOutletSearchResultTableData(
        mediaOutlet.id,
        mediaOutlet.id,
        mediaOutlet.profilePictureUrl,
        mediaOutlet.name,
        mediaOutlet.category.name,
        frequencies.find((f) => f.key === mediaOutlet.frequencyKey)?.label,
        mediumSupportTypes
          .reduce((agg, mst) => {
            if (mediaOutlet.mediaSupportTypesKeys.includes(mst.key)) agg.push(mst.label);
            return agg;
          }, [])
          .join(', '),
        mediaOutlet.mediaResorts?.map((mediaResort) => mediaResort.name).join(', '),
        mediaOutlet.dataSourceKey,
        mediaOutlet.tags
          ?.map((tag) => tag.name)
          .filter(Boolean)
          .join(', '),
        mediaOutlet.mediaOutletContacts
          ?.map((contact) => `${contact.firstName} ${contact.lastName}`)
          .filter(Boolean)
          .join(', '),
        mediaOutlet.preferredLanguages?.map((language) => language.name).join(', '),
        mediaOutlet.contactData.street,
        mediaOutlet.contactData.postalCode,
        mediaOutlet.contactData.city,
        mediaOutlet.contactData.country,
        mediaOutlet.contactData.fax,
        mediaOutlet.contactData.websiteUrl,
        mediaOutlet.contactData.poBox,
        mediaOutletLabel,
        mediaOutlet.dataSourceKey
      )
  );
};
