import { DetailsRow, IColumn, ShimmeredDetailsList } from '@fluentui/react';
import { useAppDispatch, useAppSelector, WizardEmptyInfo } from 'app/common';
import { useIntl } from 'app/i18n';
import { CreateContactTableData } from 'app/pages/my-audience/common';
import {
  goToNextStepAddContact,
  resetContactToAdd,
  selectContactsTableData,
  selectIsLoadingWizardContacts,
  selectWizardContactsTotalCount,
  setSelectedContact
} from 'app/pages/my-audience/company-profile';

export const AllContactTable = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactsTableColumns: IColumn[] = [
    {
      key: 'contact-without-company-email-profile-picture-url',
      fieldName: 'profilePictureUrl',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isRowHeader: false
    },
    {
      key: 'contact-without-company-email-name',
      fieldName: 'name',
      name: formatMessage({ id: 'companies-contact-table.name' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-company-email-role',
      fieldName: 'role',
      name: formatMessage({ id: 'companies-contact-table.job' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-company-email-address',
      fieldName: 'emailAddress',
      name: formatMessage({ id: 'companies-contact-table.email' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'contact-without-company-phone-number',
      fieldName: 'phoneNumber',
      name: formatMessage({ id: 'companies-contact-table.phone' }),
      minWidth: 70,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'menu',
      fieldName: 'menu',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isRowHeader: false
    }
  ];
  const tableRows = useAppSelector<CreateContactTableData[]>(selectContactsTableData);
  const isLoadingWizardContacts = useAppSelector<boolean>(selectIsLoadingWizardContacts);
  const totalCountOfContacts = useAppSelector<number>(selectWizardContactsTotalCount);

  const onRowClickWhenAddingContribution = (index: number) => {
    dispatch(resetContactToAdd());
    dispatch(setSelectedContact(tableRows[index].key));
    dispatch(goToNextStepAddContact());
  };

  return (
    <div className="wizard-panel-table add-contact">
      <ShimmeredDetailsList
        items={tableRows}
        enableShimmer={isLoadingWizardContacts}
        columns={contactsTableColumns}
        checkboxVisibility={2}
        onRenderRow={(props) => (
          <div onClickCapture={() => onRowClickWhenAddingContribution(props.itemIndex)}>
            <DetailsRow {...props} />
          </div>
        )}
      />
      {totalCountOfContacts === 0 && !isLoadingWizardContacts && (
        <WizardEmptyInfo
          message={formatMessage({ id: 'no-contacts-selected-message.title' })}
          description={formatMessage({ id: 'no-contacts-selected-message.description' })}
        />
      )}
    </div>
  );
};
