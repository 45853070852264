import { FilterItem, Search, SearchSuggestionCategory, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  clearAllFilters,
  firstPageOfListsRequested,
  listsSuggestionsRequested,
  resetListSuggestions,
  selectIsLoadingSearchSuggestions,
  selectListSearchSuggestions,
  selectListsSearchText,
  selectSearchListsSuggestionText,
  setFilterItems,
  setSearchText,
  startSearchAnimation,
  stopSearchAnimation
} from 'app/pages/my-audience/lists';

interface ListsSearchProps {
  onOutsideClick?: () => void;
}

export const ListsSearch = ({ onOutsideClick }: ListsSearchProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const searchSuggestionsCategories = useAppSelector<SearchSuggestionCategory[]>(selectListSearchSuggestions);
  const searchSuggestionsText = useAppSelector<string>(selectSearchListsSuggestionText);
  const isLoadingSearchSuggestions = useAppSelector<boolean>(selectIsLoadingSearchSuggestions);
  const searchText = useAppSelector<string>(selectListsSearchText);

  const handleClearAllFilters = () => {
    dispatch(clearAllFilters());
  };

  const handleResetSuggestions = () => {
    dispatch(resetListSuggestions());
  };

  const handleSearchSuggestions = (searchSuggestionText: string) => {
    dispatch(listsSuggestionsRequested(searchSuggestionText));
  };

  const handleOnAnimationStart = () => {
    dispatch(startSearchAnimation());
  };

  const handleOnAnimationStop = () => {
    dispatch(stopSearchAnimation());
  };

  const handleSetSearchText = (searchText: string) => {
    dispatch(setSearchText(searchText));
  };

  const handleSetFilterItems = (filterItems: FilterItem[]) => {
    dispatch(setFilterItems(filterItems));
  };

  const handleSearchLists = () => {
    dispatch(firstPageOfListsRequested());
  };

  return (
    <Search
      searchSuggestionsCategories={searchSuggestionsCategories}
      searchSuggestionsText={searchSuggestionsText}
      onClearAllFilters={handleClearAllFilters}
      onResetSuggestions={handleResetSuggestions}
      onSearchSuggestions={handleSearchSuggestions}
      searchText={searchText}
      isLoadingSearchSuggestions={isLoadingSearchSuggestions}
      onAnimationStart={handleOnAnimationStart}
      onAnimationStop={handleOnAnimationStop}
      onSetSearchText={handleSetSearchText}
      onSetFilterItems={handleSetFilterItems}
      onSearch={handleSearchLists}
      viewAllMessage={formatMessage({ id: 'search.view-all-lists' })}
      onOutsideClick={onOutsideClick}
    />
  );
};
