import { useAppDispatch, useAppSelector } from 'app/common';
import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { CreateMediaOutletFromContactStep, goToMediaOutletStep, selectMediaOutletCurrentStep } from 'app/pages/my-audience/wizard-create-contact';
import { useIntl } from 'app/i18n';

interface CreateMediaOutletStepsProps {
  areFieldsValid?: boolean;
}

export const CreateMediaOutletSteps = ({ areFieldsValid }: CreateMediaOutletStepsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const step = useAppSelector<CreateMediaOutletFromContactStep>(selectMediaOutletCurrentStep);

  const styleBreadcrumbStatus = (stepToCompareWith: CreateMediaOutletFromContactStep): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: CreateMediaOutletFromContactStep) => {
    dispatch(goToMediaOutletStep(step));
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletFromContactStep.GeneralInfo)}
            content={formatMessage({ id: 'add-menu-create-contact.mo-info-step' })}
            onClick={() => handleStepButtonClick(CreateMediaOutletFromContactStep.GeneralInfo)}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletFromContactStep.AddressInfo)}
            content={formatMessage({ id: 'add-menu-create-contact.mo-address-step' })}
            onClick={() => handleStepButtonClick(CreateMediaOutletFromContactStep.AddressInfo)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateMediaOutletFromContactStep.Finish)}
            content={formatMessage({ id: 'add-menu-create-contact.mo-finish-step' })}
            onClick={() => handleStepButtonClick(CreateMediaOutletFromContactStep.Finish)}
            disabled={!areFieldsValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
