import { isStringEmpty, printData, useAppDispatch, useAppSelector, WizardFinishIntro, WizardIntro } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { Company } from 'app/pages/my-audience/companies';
import { CompanyProfileInfo, goToStepAddCompany, selectCompanyProfileInfoToAdd, selectCompanyToAdd } from 'app/pages/my-audience/contact-profile';

export const AddCompanyFinish = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const companyToAdd = useAppSelector<Company>(selectCompanyToAdd);
  const profileInfoToAdd = useAppSelector<CompanyProfileInfo>(selectCompanyProfileInfoToAdd);

  const handleEditSearchCompanyButtonClick = () => {
    dispatch(goToStepAddCompany(1));
  };

  const handleEditProfileInfoButtonClick = () => {
    dispatch(goToStepAddCompany(2));
  };

  return (
    <div className="wizard-panel-finish-wrap">
      <WizardIntro title={formatMessage({ id: 'wizard-finish-step-title' })} description="" />
      <div className="wizard-panel-finish-main-container">
        <div className="wizard-panel-finish-main">
          <div className="wizard-panel-finish-content">
            <WizardFinishIntro title={formatMessage({ id: 'labels.company' })} onEditButtonClick={handleEditSearchCompanyButtonClick} />
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'company-labels.name'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(companyToAdd.name)}</span>
              </div>
            </div>
          </div>
          <div className="wizard-panel-finish-content">
            <WizardFinishIntro
              title={formatMessage({ id: 'add-medium-wizard-steps.profile-information' })}
              onEditButtonClick={handleEditProfileInfoButtonClick}
            />
            <div className="wizard-panel-finish-main-content">
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.job-title'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.jobTitle)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.job-description'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.jobDescription)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.resort'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.resort)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.phone-number'} />
                </span>
                {!isStringEmpty(profileInfoToAdd.phoneNumber) ? (
                  <a href={'tel:+' + profileInfoToAdd.phoneNumber} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(profileInfoToAdd.phoneNumber)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.phoneNumber)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.mobile-phone-number'} />
                </span>
                {!isStringEmpty(profileInfoToAdd.mobilePhoneNumber) ? (
                  <a href={'tel:+' + profileInfoToAdd.mobilePhoneNumber} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(profileInfoToAdd.mobilePhoneNumber)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.mobilePhoneNumber)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.email-address'} />
                </span>
                {!isStringEmpty(profileInfoToAdd.emailAddress) ? (
                  <a href={'mailto:' + profileInfoToAdd.emailAddress} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                    {printData(profileInfoToAdd.emailAddress)}
                  </a>
                ) : (
                  <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.emailAddress)}</span>
                )}
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.address'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.address)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.postal-code'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.postalCode)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.city'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.city)}</span>
              </div>
              <div className="wizard-panel-finish-main-box">
                <span className="wizard-panel-finish-main-label">
                  <FormattedMessage id={'contact-data-labels.country'} />
                </span>
                <span className="wizard-panel-finish-main-input">{printData(profileInfoToAdd.country)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
