import { Button, Popup } from '@fluentui/react-northstar';
import {
  Tag,
  useAppDispatch,
  useAppSelector,
  SearchResultTableData,
  selectSelectedTableRows,
  useSelectedTags,
  PillsPopupContent,
  PillData,
  undoQueue,
  showUndoAlert,
  hideUndoAlert,
  DisableUnauthorized
} from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addNewTagRequested,
  addTagToContactsRequested,
  deleteTagsRequested,
  filterTags,
  removeTagFromContactsRequested,
  selectFilteredTagsInPanel,
  selectContacts,
  Contact,
  selectAllTags,
  addTagToContactsInStore,
  removeTagFromContactsInStore,
  addedTagToContactsSearchResults,
  removedTagsFromContactsSearchResults
} from 'app/pages/my-audience/contacts';
import { Permission } from 'auth';

interface ContactsTagsPopupProps {
  disabled: boolean;
}

export const ContactsTagsPopup = ({ disabled }: ContactsTagsPopupProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedContactsTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const selectedContactIds = selectedContactsTableRows.map((contact) => contact.id);
  const allContacts = useAppSelector<Contact[]>(selectContacts);
  const selectedTags = useSelectedTags(selectedContactsTableRows, allContacts);
  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);
  const allTags = useAppSelector<Tag[]>(selectAllTags);

  const onAddNewTag = (text: string) => dispatch(addNewTagRequested({ selectedContactIds, text }));

  const onSelectValueHandler = (id: string) => {
    selectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => onAddTagToContacts(id),
      undoAction: () => deselectValueInStore(id)
    });

    dispatch(showUndoAlert('Undo selection of Tag'));
  };

  const onDeselectValueHandler = (id: string) => {
    deselectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => onRemoveTagFromContacts(id),
      undoAction: () => selectValueInStore(id)
    });

    dispatch(showUndoAlert('Undo deselection of Tag'));
  };

  const selectValueInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addedTagToContactsSearchResults({ contactIds: selectedContactIds, tag }));
    dispatch(addTagToContactsInStore({ selectedContactIds, tag }));
  };

  const deselectValueInStore = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removedTagsFromContactsSearchResults({ contactIds: selectedContactIds, tags: [tag] }));
    dispatch(removeTagFromContactsInStore({ selectedContactIds, tag }));
  };

  const onAddTagToContacts = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(addTagToContactsRequested({ selectedContactIds, tag }));
    dispatch(hideUndoAlert());
  };

  const onDeleteTags = (tagIds: string[]) => {
    const tags = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tags));
  };

  const onFilterTags = (text: string) => {
    dispatch(filterTags(text));
  };

  const onRemoveTagFromContacts = (id: string) => {
    const tag = allTags.find((t) => t.id === id);
    dispatch(removeTagFromContactsRequested({ selectedContactIds, tag }));
    dispatch(hideUndoAlert());
  };

  return (
    <Popup
      trigger={
        <div>
          <DisableUnauthorized permissions={[Permission.ManageContacts]}>
            <Button disabled={disabled} text content={formatMessage({ id: 'search-results-table.add-update-tag' })} />
          </DisableUnauthorized>
        </div>
      }
      key="tags"
      inline={true}
      position={'below'}
      content={
        <PillsPopupContent
          entityName={formatMessage({ id: 'labels.tag' })}
          selectedValues={selectedTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          dropdownValues={filteredTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          onAddValue={onAddNewTag}
          onSelectValue={onSelectValueHandler}
          onDeselectValue={onDeselectValueHandler}
          onDeleteValues={onDeleteTags}
          filterValues={onFilterTags}
          enabledDelete={true}
        />
      }
    />
  );
};
