import { MessageData, Comment, ProfilePicture, safe, setAlertData } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { addCollaboration, removeCollaboration, updateCollaboration } from 'app/pages/my-audience/contact-profile/services/contact-profile.service';
import { addContact, Collaboration, Contact, PageOfContacts } from 'app/pages/my-audience/contacts';
import {
  addMediaResort,
  addMediaResortToMediaOutlet,
  addMediaResortToMediaOutletRequested,
  addCommentToMediaOutlet,
  addCommentToMediaOutletRequested,
  addContactRequested,
  addContributionRequested,
  addedCommentToMediaOutlet,
  addedContact,
  addedContribution,
  addedNewMediaResort,
  addMediaResortAndAssignToMediaOutletRequested,
  allMediaResortsReceived,
  MediaResort,
  mediaResortsRemoved,
  fetchAllMediaResorts,
  fetchContactsByNameAndRoleAndNotContainedInMediaOutlet,
  fetchMediaOutletContacts,
  fetchSelectedMediaOutletProfile,
  getAllMediaResortsRequested,
  getSelectedMediaOutletRequested,
  pageOfContactsReceived,
  pageOfContactsRequested,
  pageOfMediaOutletContactsReceived,
  pageOfMediaOutletContactsRequested,
  profilePictureUpdated,
  removeMediaResorts,
  removeMediaResortFromMediaOutlet,
  removeMediaResortFromMediaOutletRequested,
  removeMediaResortsRequested,
  removeContributionRequested,
  removedContribution,
  removeProfilePicture,
  removeProfilePictureRequested,
  selectCollaborationToAdd,
  selectCollaborationToUpdate,
  selectMediaResortToAdd,
  selectMediaResortToAddToMediaOutlet,
  selectMediaResortsToRemove,
  selectMediaResortToRemoveFromMediaOutlet,
  selectCommentToAddToMediaOutlet,
  selectContactsPageNumber,
  selectContactsPageSize,
  selectContactsRoleFilter,
  selectContactsSearchText,
  selectContactToAdd,
  selectSelectedContactForEditing,
  selectContactToUpdate,
  selectedMediaOutletReceived,
  selectImageToUpload,
  selectMediaOutletContactsPageNumber,
  selectMediaOutletContactsPageSize,
  selectMediaOutletContactsRoleFilter,
  selectMediaOutletContactsSearchText,
  selectMediaOutletToUpdate,
  selectSelectedContact,
  selectSelectedMediaOutletId,
  updateCollaborationReceived,
  updateCollaborationRequested,
  updatedMediaOutlet,
  updateMediaOutlet,
  updateMediaOutletRequested,
  updateProfilePicture,
  updateProfilePictureRequested,
  addMediaResortToMediaOutletInStore
} from 'app/pages/my-audience/media-outlet-profile';
import {
  addMediaResortToMediaOutletInTable,
  addContactToMediaOutletInTable,
  MediaOutlet,
  updatedProfilePictureSearchResults,
  updateMediaOutlet as updateMediaOutletInTable,
  removeMediaResortsInTable,
  removedMediaResorts
} from 'app/pages/my-audience/media-outlets';
import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchSelectedMediaOutletFlow() {
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);
  const selectedMediaOutlet: MediaOutlet = yield call(fetchSelectedMediaOutletProfile, client, selectedMediaOutletId);
  yield put(selectedMediaOutletReceived(selectedMediaOutlet));
}

export function* getSelectedMediaOutletRequestedWatcher() {
  yield takeEvery(getSelectedMediaOutletRequested.type, safe(fetchSelectedMediaOutletFlow));
}

function* addCommentToMediaOutletFlow() {
  const commentToAdd: Comment = yield select(selectCommentToAddToMediaOutlet);
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);

  const comment: Comment = yield call(addCommentToMediaOutlet, client, selectedMediaOutletId, commentToAdd);
  yield put(addedCommentToMediaOutlet(comment));
}

export function* addCommentToMediaOutletRequestedWatcher() {
  yield takeEvery(addCommentToMediaOutletRequested.type, safe(addCommentToMediaOutletFlow));
}

function* updateProfilePictureFlow() {
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);
  const imageToUpload: ProfilePicture = yield select(selectImageToUpload);

  const imageUrl: string = yield call(updateProfilePicture, client, selectedMediaOutletId, imageToUpload);
  yield put(profilePictureUpdated(imageUrl));
  yield put(updatedProfilePictureSearchResults({ mediaOutletId: selectedMediaOutletId, imageUrl: imageUrl }));
}

export function* updateMediaOutletProfilePictureRequestedWatcher() {
  yield takeEvery(updateProfilePictureRequested.type, safe(updateProfilePictureFlow));
}

function* removeProfilePictureFlow() {
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);

  yield call(removeProfilePicture, client, selectedMediaOutletId);
  yield put(profilePictureUpdated(''));
  yield put(updatedProfilePictureSearchResults({ mediaOutletId: selectedMediaOutletId, imageUrl: '' }));
}

export function* removeMediaOutletProfilePictureRequestedWatcher() {
  yield takeEvery(removeProfilePictureRequested.type, safe(removeProfilePictureFlow));
}

function* fetchAllMediaResortsFlow() {
  const allMediaResorts: MediaResort[] = yield call(fetchAllMediaResorts, client);
  yield put(allMediaResortsReceived(allMediaResorts));
}

export function* getAllMediaResortsRequestedWatcher() {
  yield takeEvery(getAllMediaResortsRequested.type, safe(fetchAllMediaResortsFlow));
}

function* addMediaResortAndAssignToMediaOutletFlow() {
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);
  const mediaResortToAdd: MediaResort = yield select(selectMediaResortToAdd);

  const createdMediaResort: MediaResort = yield call(addMediaResort, client, mediaResortToAdd);

  yield put(addedNewMediaResort(createdMediaResort));
  yield put(addMediaResortToMediaOutletRequested(createdMediaResort));
  yield put(addMediaResortToMediaOutletInStore(createdMediaResort));
  yield put(addMediaResortToMediaOutletInTable({ mediaOutletIds: [selectedMediaOutletId], mediaResorts: [createdMediaResort] }));
}

export function* addNewMediaResortRequestedWatcher() {
  yield takeEvery(addMediaResortAndAssignToMediaOutletRequested.type, safe(addMediaResortAndAssignToMediaOutletFlow));
}

function* removeMediaResortsFlow() {
  const mediaResortsToRemove: MediaResort[] = yield select(selectMediaResortsToRemove);

  const removedMediaResortsIds: string[] = yield call(
    removeMediaResorts,
    client,
    mediaResortsToRemove.map((mr) => mr.id)
  );

  yield put(mediaResortsRemoved(removedMediaResortsIds));
  yield put(removedMediaResorts(removedMediaResortsIds));
  yield put(removeMediaResortsInTable(removedMediaResortsIds));
}

export function* removeMediaResortsRequestWatcher() {
  yield takeEvery(removeMediaResortsRequested.type, safe(removeMediaResortsFlow));
}

function* addMediaResortToMediaOutletFlow() {
  const mediaResortToAdd: MediaResort = yield select(selectMediaResortToAddToMediaOutlet);
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);

  yield call(addMediaResortToMediaOutlet, client, selectedMediaOutletId, mediaResortToAdd.id);
}

export function* addMediaResortToMediaOutletRequestedWatcher() {
  yield takeEvery(addMediaResortToMediaOutletRequested.type, safe(addMediaResortToMediaOutletFlow));
}

function* removeMediaResortFromMediaOutletFlow() {
  const mediaResortToRemove: MediaResort = yield select(selectMediaResortToRemoveFromMediaOutlet);
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);

  yield call(removeMediaResortFromMediaOutlet, client, selectedMediaOutletId, mediaResortToRemove.id);
}

export function* removeMediaResortFromMediaOutletRequestedWatcher() {
  yield takeEvery(removeMediaResortFromMediaOutletRequested.type, safe(removeMediaResortFromMediaOutletFlow));
}

function* updateMediaOutletFlow() {
  const id: string = yield select(selectSelectedMediaOutletId);
  const mediaOutletToUpdate: MediaOutlet = yield select(selectMediaOutletToUpdate);

  const mediaOutlet: MediaOutlet = yield call(updateMediaOutlet, client, id, mediaOutletToUpdate);
  yield put(updatedMediaOutlet(mediaOutlet));
  yield put(updateMediaOutletInTable(mediaOutlet));
  yield put(setAlertData(new MessageData('alert-media-outlet-updated')));
}

export function* updateMediaOutletRequestedWatcher() {
  yield takeEvery(updateMediaOutletRequested.type, safe(updateMediaOutletFlow));
}

function* fetchMediaOutletContactsFlow() {
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);
  const pageNumber: number = yield select(selectMediaOutletContactsPageNumber);
  const pageSize: number = yield select(selectMediaOutletContactsPageSize);
  const searchText: string = yield select(selectMediaOutletContactsSearchText);
  const role: string = yield select(selectMediaOutletContactsRoleFilter);

  const pageOfContacts: PageOfContacts = yield call(fetchMediaOutletContacts, client, pageNumber, pageSize, searchText, role, selectedMediaOutletId);

  yield put(pageOfMediaOutletContactsReceived({ contacts: pageOfContacts.contacts, totalCountOfContacts: pageOfContacts.totalCount }));
}

export function* getMediaOutletContactsRequestedWatcher() {
  yield takeEvery(pageOfMediaOutletContactsRequested.type, safe(fetchMediaOutletContactsFlow));
}

function* updateCollaborationFlow() {
  const contact: Contact = yield select(selectContactToUpdate);
  const collaborationToUpdate: Collaboration = yield select(selectCollaborationToUpdate);

  const updatedContact = yield call(updateCollaboration, client, contact.id, collaborationToUpdate);
  yield put(updateCollaborationReceived(updatedContact));
  yield put(setAlertData(new MessageData('alert-collaboration-updated')));
}

export function* updateMediaOutletCollaborationRequestedWatcher() {
  yield takeEvery(updateCollaborationRequested.type, safe(updateCollaborationFlow));
}

function* removeContributionFlow() {
  const mediaOutletId: string = yield select(selectSelectedMediaOutletId);
  const contactToRemove: Contact = yield select(selectSelectedContactForEditing);
  const collaborationToRemoveId: string = contactToRemove.collaborations.find((c) => c.medium.id === mediaOutletId)?.id;

  yield call(removeCollaboration, client, contactToRemove.id, collaborationToRemoveId);
  yield put(removedContribution());
}

export function* removeContributionRequestedWatcher() {
  yield takeEvery(removeContributionRequested.type, safe(removeContributionFlow));
}

function* addContributionFlow() {
  const collaborationToAdd: Collaboration = yield select(selectCollaborationToAdd);
  const contact: Contact = yield select(selectSelectedContact);

  yield call(addCollaboration, client, contact.id, collaborationToAdd);
  yield put(addedContribution());
}

export function* addContributionRequestedWatcher() {
  yield takeEvery(addContributionRequested.type, safe(addContributionFlow));
}

function* addContactFlow() {
  const contactToAdd: Contact = yield select(selectContactToAdd);
  const selectedMediaOutletId = yield select(selectSelectedMediaOutletId);

  const contact: Contact = yield call(addContact, client, contactToAdd);
  yield put(addedContact(contact));
  yield put(addContactToMediaOutletInTable({ mediaOutletId: selectedMediaOutletId, contact: contact }));
}

export function* addContactRequestedWatcher() {
  yield takeEvery(addContactRequested.type, safe(addContactFlow));
}

function* fetchWizardContactsFlow() {
  const selectedMediaOutletId: string = yield select(selectSelectedMediaOutletId);
  const pageNumber: number = yield select(selectContactsPageNumber);
  const pageSize: number = yield select(selectContactsPageSize);
  const searchText: string = yield select(selectContactsSearchText);
  const role: string = yield select(selectContactsRoleFilter);

  const pageOfContacts: PageOfContacts = yield call(
    fetchContactsByNameAndRoleAndNotContainedInMediaOutlet,
    client,
    pageNumber,
    pageSize,
    searchText,
    role,
    selectedMediaOutletId
  );

  yield put(pageOfContactsReceived({ contacts: pageOfContacts.contacts, totalCountOfContacts: pageOfContacts.totalCount }));
}

export function* getMediaOutletWizardContactsRequestedWatcher() {
  yield takeEvery(pageOfContactsRequested.type, safe(fetchWizardContactsFlow));
}
