import { useMemo } from 'react';
import { Selection, SelectionMode } from '@fluentui/react';
import { WizardWrap, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  AddAddressInfo,
  AddCollaboration,
  AddCollaborationDetails,
  AddGeneralInfo,
  ContactsTableData,
  CreateMediaOutletWizardStep,
  FinishCreateMediaOutlet,
  MediaOutletAddressInformation,
  closeCreateMediaOutletWizard,
  createCollaborationDetails,
  selectAddressInfoToAdd,
  selectCollaborationsDetails,
  selectCreateMediaOutletCurrentStep,
  selectIsCreateMediaOutletWizardOpened,
  setCollaborationsToAddPerPage
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';

export const CreateMediaOutletWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const isCreateMediaOutletWizardOpened = useAppSelector<boolean>(selectIsCreateMediaOutletWizardOpened);
  const currentStep = useAppSelector<CreateMediaOutletWizardStep>(selectCreateMediaOutletCurrentStep);
  const collaborationDetails = useAppSelector<CollaborationWithContactInfo[]>(selectCollaborationsDetails);
  const mediaOutletAddressInfo = useAppSelector<MediaOutletAddressInformation>(selectAddressInfoToAdd);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedItems = selection.getSelection() as ContactsTableData[];
          if (selectedItems.includes(undefined)) {
            return;
          }

          const collaborations = [];

          selectedItems.forEach((si) => {
            const existingCollaboration = collaborationDetails.find((c) => c.contactId === si.id);
            if (existingCollaboration !== undefined) {
              collaborations.push(existingCollaboration);
              return;
            }

            collaborations.push(createCollaborationDetails(si, mediaOutletAddressInfo));
          });

          dispatch(setCollaborationsToAddPerPage(collaborations));
        },
        selectionMode: SelectionMode.multiple
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateMediaOutletWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-menu-create-mo.wizard-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateMediaOutletWizardStep.GeneralInformation && <AddGeneralInfo />}
        {currentStep === CreateMediaOutletWizardStep.AddressInformation && <AddAddressInfo />}
        {currentStep === CreateMediaOutletWizardStep.Collaboration && <AddCollaboration selection={selection} />}
        {currentStep === CreateMediaOutletWizardStep.CollaborationDetails && <AddCollaborationDetails />}
        {currentStep === CreateMediaOutletWizardStep.Finish && <FinishCreateMediaOutlet />}
      </div>
    </WizardWrap>
  );
};
