import { useEffect, useMemo, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import InfiniteScroll from 'react-infinite-scroller';
import {
  DetailsHeader,
  DetailsListLayoutMode,
  IColumnReorderOptions,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import {
  closeTableColumnSelectionPopup,
  openTableColumnSelectionPopup,
  openTableMobileColumnSelectionPopup,
  TableHeader,
  selectIsOnTouch,
  selectIsTableColumnSelectionPopupVisible,
  selectIsTableMobileColumnSelectionPopupVisible,
  NewShowOrHideColumnsPopup,
  NewTablePopupMobile,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import {
  SendingsTableRow,
  EmailingTableRow,
  SendingStatus,
  SendingsActionBar,
  TableQuickFilters,
  openFilterPanel,
  setSelectedSendingsIds,
  addSendingStatusFilter,
  firstPageOfSendingsRequested,
  clearAllSendingFilters,
  RemoveSendingDialog,
  selectSelectedSendingsIds,
  setSortingInput,
  SendingSortingInput,
  selectIsDeletingSendingInProgress,
  SendingsFilterPills,
  addIsFavouriteFilter
} from 'app/pages/my-activities/sendings';
import 'app/pages/my-activities/sendings/components/SendingsTable.scss';
import { ContactsSortOrder } from 'app/common/graphql/generated/graphql-gateway';

interface SendingsTableProps {
  isLoadingSendings: boolean;
  fixedColumnIndex: number;
  onOpenProfile: (id: string) => void;
  header: TableHeader[];
  tableRows: EmailingTableRow[];
  totalCountOfEntities: number;
  onUpdateTableHeader: (updatedHeader: TableHeader[]) => void;
  handleLoadMore: () => void;
}

export const SendingsTable = ({
  isLoadingSendings,
  fixedColumnIndex,
  onOpenProfile,
  header,
  handleLoadMore,
  tableRows,
  totalCountOfEntities,
  onUpdateTableHeader
}: SendingsTableProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const isTableColumnSelectionPopupVisible = useAppSelector<boolean>(selectIsTableColumnSelectionPopupVisible);
  const isTableMobileColumnSelectionPopupVisible = useAppSelector<boolean>(selectIsTableMobileColumnSelectionPopupVisible);
  const selectedItems = useAppSelector<string[]>(selectSelectedSendingsIds);
  const isDeletingSendingInProgress = useAppSelector<boolean>(selectIsDeletingSendingInProgress);

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedTableRows = selection.getSelection() as EmailingTableRow[];
          dispatch(setSelectedSendingsIds(selectedTableRows.map((tableRow) => tableRow.sendingId)));
        },
        selectionMode: SelectionMode.multiple
      }),
    [dispatch]
  );

  useEffect(() => {
    if (selectedItems.length === 0) {
      selection.setAllSelected(false);
    }
  }, [selectedItems, selection]);

  const tableHeader: TableHeader[] =
    header.find((headerItem) => headerItem.fieldName === 'add') !== undefined
      ? [...header]
      : [...header].concat({
          key: 'column-dropdown',
          name: '',
          iconName: 'add',
          fieldName: 'add',
          minWidth: 40,
          maxWidth: 40,
          isRowHeader: false,
          isResizable: false,
          isIconOnly: true,
          onColumnClick: () => (isOnTouch ? dispatch(openTableMobileColumnSelectionPopup()) : dispatch(openTableColumnSelectionPopup())),
          isColumnVisible: true
        });

  const handleColumnReorder = (sourceIndex: number, targetIndex: number) => {
    const draggedItem = header[sourceIndex];
    const newColumns: TableHeader[] = [...header];
    newColumns.splice(sourceIndex, 1);
    newColumns.splice(targetIndex, 0, draggedItem);
    onUpdateTableHeader(newColumns);
  };

  const getColumnReorderOptions = (): IColumnReorderOptions => {
    return {
      frozenColumnCountFromStart: 2,
      frozenColumnCountFromEnd: 1,
      handleColumnReorder: handleColumnReorder
    };
  };

  const handleLoadMoreData = () => {
    if (!isLoadingSendings) handleLoadMore();
  };

  const handleClickOutside = () => {
    dispatch(closeTableColumnSelectionPopup());
  };

  useOnClickOutside(ref, handleClickOutside);

  const filterByStatus = (status: SendingStatus) => {
    dispatch(addSendingStatusFilter(status));
    dispatch(firstPageOfSendingsRequested());
  };

  const resetFilters = () => {
    dispatch(clearAllSendingFilters());
    dispatch(firstPageOfSendingsRequested());
  };

  const showFavorites = () => {
    dispatch(addIsFavouriteFilter(true));
    dispatch(firstPageOfSendingsRequested());
  };

  const onOpenFilterPanel = () => {
    dispatch(openFilterPanel());
  };

  const handleColumnHeaderClick = (_: React.MouseEvent<HTMLElement>, selectedColumn: TableHeader) => {
    if (selectedColumn.fieldName === 'add') return;

    const sortOrder = selectedColumn.isSortedDescending ? ContactsSortOrder.Asc : ContactsSortOrder.Desc;
    dispatch(setSortingInput(new SendingSortingInput(selectedColumn.sortOption, sortOrder)));

    const index = header.findIndex((column) => selectedColumn.fieldName === column.fieldName);
    const updatedHeaderItem = { ...header[index], isSortedDescending: !selectedColumn.isSortedDescending, isSorted: true };

    const updatedHeader: TableHeader[] = header.map((column: TableHeader) => {
      return { ...column, isSorted: false, isSortedDescending: true };
    });

    updatedHeader.splice(index, 1, updatedHeaderItem);
    onUpdateTableHeader(updatedHeader);
    dispatch(firstPageOfSendingsRequested());
  };

  const shouldLoadMoreSendings = totalCountOfEntities > tableRows.length && !isDeletingSendingInProgress;

  return (
    <div className="search-results-table">
      <div ref={ref}>
        {isTableColumnSelectionPopupVisible && (
          <NewShowOrHideColumnsPopup tableHeader={tableHeader} onUpdateTableHeader={onUpdateTableHeader} fixedColumnIndex={fixedColumnIndex} />
        )}
      </div>
      <TableQuickFilters
        filterSendingsByStatus={filterByStatus}
        resetFilters={resetFilters}
        showFavorites={showFavorites}
        openFiltersPanel={onOpenFilterPanel}
      />
      <SendingsFilterPills />
      {tableRows.length > 0 && (
        <>
          <SendingsActionBar />
          <MarqueeSelection selection={selection} className="table-results" isDraggingConstrainedToRoot={true}>
            <div id="scrollableDiv">
              <InfiniteScroll pageStart={0} initialLoad={false} loadMore={handleLoadMoreData} hasMore={shouldLoadMoreSendings} loader={false}>
                <ShimmeredDetailsList
                  items={tableRows}
                  onColumnHeaderClick={handleColumnHeaderClick}
                  enableShimmer={!tableRows}
                  columns={tableHeader.filter((column) => column.isColumnVisible === true)}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={selection}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  checkboxVisibility={1}
                  columnReorderOptions={getColumnReorderOptions()}
                  onRenderDetailsHeader={(props) => <DetailsHeader {...props}></DetailsHeader>}
                  onRenderRow={(rowProps: IDetailsRowProps) => <SendingsTableRow rowProps={rowProps} selection={selection} onOpenProfile={onOpenProfile} />}
                />
              </InfiniteScroll>
            </div>
          </MarqueeSelection>
        </>
      )}
      {isTableMobileColumnSelectionPopupVisible && isOnTouch && (
        <NewTablePopupMobile tableHeader={tableHeader} onUpdateTableHeader={onUpdateTableHeader} fixedColumnIndex={fixedColumnIndex} />
      )}
      <RemoveSendingDialog />
    </div>
  );
};
