import { hideUndoAlert, UndoableDialog, useAppDispatch } from 'app/common';
import { MediaOutlet, removeMediaOutletFromStore, removeMediaOutletRequested, undoRemoveMediaOutletFromStore } from 'app/pages/my-audience/media-outlets';
import { closeProfilePanel, setIsRemoveMediaOutletDialogOpened } from 'app/pages/my-audience/media-outlet-profile';

interface DeleteMediaOutletDialogProps {
  opened: boolean;
  dialogTitle: string;
  undoAlertMessage: string;
  selectedMediaOutlet: MediaOutlet;
  dialogText?: string;
  warningMessages?: string[];
}

export const DeleteMediaOutletDialog = ({
  opened,
  dialogTitle,
  dialogText,
  undoAlertMessage,
  selectedMediaOutlet,
  warningMessages
}: DeleteMediaOutletDialogProps) => {
  const dispatch = useAppDispatch();
  const isMediaOutletExternal = !!selectedMediaOutlet.dataSourceKey;

  const onCloseDialog = () => {
    dispatch(setIsRemoveMediaOutletDialogOpened(false));
  };

  const onExecuteAction = () => {
    dispatch(removeMediaOutletRequested(selectedMediaOutlet));
    dispatch(hideUndoAlert());
  };

  const onExecuteInStore = () => {
    dispatch(removeMediaOutletFromStore(selectedMediaOutlet));
    dispatch(closeProfilePanel());
  };

  const onRevertAction = () => {
    dispatch(undoRemoveMediaOutletFromStore(selectedMediaOutlet));
  };

  return (
    <UndoableDialog
      opened={opened}
      dialogTitle={!isMediaOutletExternal && dialogTitle}
      dialogText={dialogText}
      alertMessage={undoAlertMessage}
      onExecuteAction={onExecuteAction}
      onExecuteInStore={onExecuteInStore}
      onRevertAction={onRevertAction}
      onClose={onCloseDialog}
      warningMessages={warningMessages}
      deleteButtonDisabled={isMediaOutletExternal}
    />
  );
};
