import React from 'react';
import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { User, selectSignedInUser, useAppDispatch, useAppSelector } from 'app/common';
import {
  duplicateSendingsRequested,
  openRemoveSendingsDialog,
  selectSelectedSendingsIds,
  selectSendings,
  Sending,
  updateSendingsRequested
} from 'app/pages/my-activities/sendings';
import { useIntl } from 'app/i18n';
import { Permission, useAuthContext } from 'auth';
import './SendingsActionBar.scss';
import { selectIsProfilePanelOpen } from 'app/pages/my-activities/sending-wizard';

export const SendingsActionBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();

  const selectedSendingsIds = useAppSelector<string[]>(selectSelectedSendingsIds);
  const isSendingProfilePanelOpened = useAppSelector<boolean>(selectIsProfilePanelOpen);
  const areActionsVisible = !!selectedSendingsIds.length && !isSendingProfilePanelOpened;
  const sendingsStore = useAppSelector<Sending[]>(selectSendings);
  const signedInUser = useAppSelector<User>(selectSignedInUser);

  const isOwnerOfAllSelectedSendings = selectedSendingsIds.every((id) => {
    const sending = sendingsStore.find((sending) => sending.id === id);
    return sending?.createdBy?.id === signedInUser.id;
  });

  const canUpdateSendings = hasPermission(Permission.ManageEmailings) && isOwnerOfAllSelectedSendings;

  const shouldSelectedSendingsBeMarkedAsFavorites = selectedSendingsIds.some((sid) => !sendingsStore.find((s) => s.id === sid)?.isFavourite);

  const actionBarItems = [
    {
      icon: 'FavoriteStar',
      name: shouldSelectedSendingsBeMarkedAsFavorites
        ? formatMessage({ id: 'emailings-table.set-as-favorite' })
        : formatMessage({ id: 'emailings-table.remove-favorite' }),
      key: 'set-as-favorite',
      class: 'emailngs-action-bar-button',
      onClickItem: () => markSelectedSendingsAsFavorites(shouldSelectedSendingsBeMarkedAsFavorites)
    },
    {
      icon: 'Copy',
      name: formatMessage({ id: 'emailings-table.duplicate' }),
      key: 'duplicate',
      class: 'emailngs-action-bar-button',
      onClickItem: () => handleDuplicate()
    },
    {
      icon: 'Delete',
      name: formatMessage({ id: 'buttons.delete' }),
      key: 'delete',
      class: 'emailngs-action-bar-button danger',
      onClickItem: () => handleDelete()
    }
  ];

  const markSelectedSendingsAsFavorites = (isFavorite: boolean) => {
    const sendingsToUpdate = sendingsStore
      .filter((sending) => selectedSendingsIds.includes(sending.id))
      .map((sending) => ({ ...sending, isFavourite: isFavorite }));

    dispatch(updateSendingsRequested(sendingsToUpdate));
  };

  const handleDuplicate = () => {
    dispatch(duplicateSendingsRequested({ sendingIds: selectedSendingsIds, localizedTitlePart: formatMessage({ id: 'labels.copy' }) }));
  };

  const handleDelete = () => {
    dispatch(openRemoveSendingsDialog());
  };

  return (
    <>
      {areActionsVisible && (
        <div className="emailngs-action-bar">
          {actionBarItems.map((item) => (
            <Button
              key={item.key}
              text
              content={item.name}
              className={item.class}
              onClick={item.onClickItem}
              icon={<Icon iconName={item.icon} />}
              disabled={!canUpdateSendings}
            />
          ))}
        </div>
      )}
    </>
  );
};
