import { recordHook } from '@flatfile/plugin-record-hook';
import FlatfileListener from '@flatfile/listener';

export const setDefaultMediumTypeIfEmpty = (listener: FlatfileListener) => {
  listener.use(
    recordHook('Contacts', (record) => {
      const collaborationMediumName = record.get('collaboration_medium_name');
      const collaborationType = record.get('collaboration_medium_type');

      if (collaborationMediumName && !collaborationType) {
        record.compute('collaboration_medium_type', () => 'MediaOutlet');
      }
      return record;
    })
  );
};
