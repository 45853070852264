import { RootState } from 'app/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company, PageOfCompanies } from 'app/pages/my-audience/companies';
import { CollaborationDetails } from 'app/pages/my-audience/contacts';
import { MediaOutlet, PageOfMediaOutlets } from 'app/pages/my-audience/media-outlets';
import {
  CollaborationTableData,
  CompanyAddressInfo,
  CompanyGeneralInfo,
  ContactGeneralInfo,
  createCollaborationTableData,
  createCompany,
  CreateCompanyFromContactStep,
  CreateContactWizardStep,
  createMediaOutlet,
  CreateMediaOutletFromContactStep,
  MediaOutletAddressInfo,
  MediaOutletGeneralInfo
} from 'app/pages/my-audience/wizard-create-contact';
import { EntitiesPerPage } from 'app/common';
import { mapEntitiesPerPageToList } from 'app/pages/my-audience/common';

export interface WizardCreateContactState {
  generalContactInfoToAdd: ContactGeneralInfo;
  areGeneralContactInfoValid: boolean;
  areAdditionalInfoValid: boolean;
  isCreateContactWizardOpened: boolean;
  currentStep: CreateContactWizardStep;
  pageSize: number;
  pageNumber: number;
  companies: Company[];
  totalCountOfCompanies: number;
  isLoadingCompanies: boolean;
  mediaOutlets: MediaOutlet[];
  totalCountOfMediaOutlets: number;
  isLoadingMediaOutlets: boolean;
  collaborationTableData: CollaborationTableData[];
  selectedCollaborationsPerPage: EntitiesPerPage<CollaborationDetails>;
  collaborationsToAdd: CollaborationDetails[];
  mediaSearchText: string;
  mediaOutletCurrentStep: CreateMediaOutletFromContactStep;
  companyCurrentStep: CreateCompanyFromContactStep;
  isCreateMediaOutletWizardOpened: boolean;
  isCreateMediaOutletWizardFinishButtonDisabled: boolean;
  isCreateCompanyWizardOpened: boolean;
  isCreateCompanyWizardFinishButtonDisabled: boolean;
  isFinishButtonDisabled: boolean;
  companyGeneralInfo: CompanyGeneralInfo;
  companyAddressInfo: CompanyAddressInfo;
  mediaOutletGeneralInfo: MediaOutletGeneralInfo;
  mediaOutletAddressInfo: MediaOutletAddressInfo;
}

const initialState: WizardCreateContactState = {
  generalContactInfoToAdd: {} as ContactGeneralInfo,
  areGeneralContactInfoValid: false,
  areAdditionalInfoValid: true,
  isCreateContactWizardOpened: false,
  currentStep: CreateContactWizardStep.GeneralInfo,
  companies: [],
  isLoadingCompanies: false,
  mediaOutlets: [],
  isLoadingMediaOutlets: false,
  collaborationTableData: [],
  selectedCollaborationsPerPage: {} as EntitiesPerPage<CollaborationDetails>,
  collaborationsToAdd: [],
  mediaSearchText: '',
  mediaOutletCurrentStep: CreateMediaOutletFromContactStep.GeneralInfo,
  companyCurrentStep: CreateCompanyFromContactStep.GeneralInfo,
  isCreateMediaOutletWizardOpened: false,
  isCreateMediaOutletWizardFinishButtonDisabled: false,
  isCreateCompanyWizardOpened: false,
  isCreateCompanyWizardFinishButtonDisabled: false,
  isFinishButtonDisabled: false,
  companyGeneralInfo: {} as CompanyGeneralInfo,
  companyAddressInfo: {} as CompanyAddressInfo,
  mediaOutletAddressInfo: {} as MediaOutletAddressInfo,
  mediaOutletGeneralInfo: {} as MediaOutletGeneralInfo,
  pageSize: 10,
  pageNumber: 1,
  totalCountOfCompanies: 0,
  totalCountOfMediaOutlets: 0
};

export type CollaborationTableDataSelector = (state: RootState) => CollaborationTableData[];
export const selectCollaborationTableData: CollaborationTableDataSelector = createSelector(
  [
    (state: RootState) => state.wizardCreateContact.mediaOutlets,
    (state: RootState) => state.wizardCreateContact.companies,
    (state: RootState) => state.wizardCreateContact.pageSize,
    (state: RootState) => state.wizardCreateContact.pageNumber
  ],
  (mediaOutlets: MediaOutlet[], companies: Company[], pageSize: number, pageNumber: number) => {
    const halfOfElements = (pageSize * pageNumber) / 2;
    const companiesForCollaboration = companies.slice(0, Math.min(halfOfElements, companies.length));
    const mediaOutletsForCollaboration = mediaOutlets.slice(0, Math.min(pageSize * pageNumber - companiesForCollaboration.length, mediaOutlets.length));
    return createCollaborationTableData(mediaOutletsForCollaboration, companiesForCollaboration);
  }
);

export type MediaSearchTextSelector = (state: RootState) => string;
export const selectMediaSearchText: MediaSearchTextSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.mediaSearchText],
  (mediaSearchText: string) => mediaSearchText
);

export type TotalCountOfMediaOutletsAndCompaniesSelector = (state: RootState) => number;
export const selectTotalCountOfMediaOutletsAndCompaniesAndCompanies: TotalCountOfMediaOutletsAndCompaniesSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.totalCountOfMediaOutlets, (state: RootState) => state.wizardCreateContact.totalCountOfCompanies],
  (totalCountOfMediaOutlets: number, totalCountOfCompanies: number) => totalCountOfMediaOutlets + totalCountOfCompanies
);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.pageNumber],
  (pageNumber: number) => pageNumber
);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.wizardCreateContact.pageSize], (pageSize: number) => pageSize);

export type IsFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsFinishButtonDisabled: IsFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.isFinishButtonDisabled],
  (isFinishButtonDisabled: boolean) => isFinishButtonDisabled
);

export type IsLoadingMediaOutletsAndCompaniesSelector = (state: RootState) => boolean;
export const selectIsLoadingMediaOutletsAndCompanies: IsLoadingMediaOutletsAndCompaniesSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.isLoadingMediaOutlets, (state: RootState) => state.wizardCreateContact.isLoadingCompanies],
  (isLoadingMediaOutlets: boolean, isLoadingCompanies: boolean) => isLoadingMediaOutlets && isLoadingCompanies
);

export type SelectedCollaborationsPerPageSelector = (state: RootState) => EntitiesPerPage<CollaborationDetails>;
export const selectSelectedCollaborationsToAddPerPage: SelectedCollaborationsPerPageSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.selectedCollaborationsPerPage],
  (selectedCollaborationsPerPage: EntitiesPerPage<CollaborationDetails>) => selectedCollaborationsPerPage
);

export type CollaborationsToAddSelector = (state: RootState) => CollaborationDetails[];
export const selectCollaborationsToAdd: CollaborationsToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.collaborationsToAdd],
  (collaborationsToAdd: CollaborationDetails[]) => collaborationsToAdd
);

export type IsCreateContactWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateContactWizardOpened: IsCreateContactWizardOpenedSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.isCreateContactWizardOpened],
  (isCreateContactWizardOpened: boolean) => isCreateContactWizardOpened
);

export type IsCreateMediaOutletWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletWizardOpened: IsCreateMediaOutletWizardOpenedSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.isCreateMediaOutletWizardOpened],
  (isCreateMediaOutletWizardOpened: boolean) => isCreateMediaOutletWizardOpened
);

export type IsCreateCompanyWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyWizardOpened: IsCreateCompanyWizardOpenedSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.isCreateCompanyWizardOpened],
  (isCreateCompanyWizardOpened: boolean) => isCreateCompanyWizardOpened
);

export type ContactInfoToAddSelector = (state: RootState) => ContactGeneralInfo;
export const selectContactInfoToAdd: ContactInfoToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.generalContactInfoToAdd],
  (generalContactInfoToAdd: ContactGeneralInfo) => generalContactInfoToAdd
);

export type AreContactInfoValidSelector = (state: RootState) => boolean;
export const selectAreContactInfoValid: AreContactInfoValidSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.areGeneralContactInfoValid],
  (areGeneralContactInfoValid: boolean) => areGeneralContactInfoValid
);

export type AreAdditionalInfoValidSelector = (state: RootState) => boolean;
export const selectAreAdditionalInfoValid: AreAdditionalInfoValidSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.areAdditionalInfoValid],
  (areAdditionalInfoValid: boolean) => areAdditionalInfoValid
);

export type CurrentStepSelector = (state: RootState) => CreateContactWizardStep;
export const selectCurrentStep: CurrentStepSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.currentStep],
  (currentStep: CreateContactWizardStep) => currentStep
);

export type MediaOutletsSelector = (state: RootState) => MediaOutlet[];
export const selectMediaOutlets: MediaOutletsSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.mediaOutlets],
  (mediaOutlets: MediaOutlet[]) => mediaOutlets
);

export type MediaOutletCurrentStepSelector = (state: RootState) => CreateMediaOutletFromContactStep;
export const selectMediaOutletCurrentStep: MediaOutletCurrentStepSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.mediaOutletCurrentStep],
  (currentStep: CreateMediaOutletFromContactStep) => currentStep
);

export type CompanyCurrentStepSelector = (state: RootState) => CreateCompanyFromContactStep;
export const selectCompanyCurrentStep: CompanyCurrentStepSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.companyCurrentStep],
  (currentStep: CreateCompanyFromContactStep) => currentStep
);

export type CompanyGeneralInfoSelector = (state: RootState) => CompanyGeneralInfo;
export const selectCompanyGeneralInfo: CompanyGeneralInfoSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.companyGeneralInfo],
  (companyGeneralInfo: CompanyGeneralInfo) => companyGeneralInfo
);

export type CompanyAddressInfoSelector = (state: RootState) => CompanyAddressInfo;
export const selectCompanyAddressInfo: CompanyAddressInfoSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.companyAddressInfo],
  (companyAddressInfo: CompanyAddressInfo) => companyAddressInfo
);

export type CompanyToAddSelector = (state: RootState) => Company;
export const selectCompanyToAdd: CompanyToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.companyGeneralInfo, (state: RootState) => state.wizardCreateContact.companyAddressInfo],
  (companyGeneralInfo: CompanyGeneralInfo, companyAddressInfo: CompanyAddressInfo) => createCompany(companyGeneralInfo, companyAddressInfo)
);

export type MediaOutletGeneralInfoSelector = (state: RootState) => MediaOutletGeneralInfo;
export const selectMediaOutletGeneralInfo: MediaOutletGeneralInfoSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.mediaOutletGeneralInfo],
  (mediaOutletGeneralInfo: MediaOutletGeneralInfo) => mediaOutletGeneralInfo
);

export type MediaOutletAddressInfoSelector = (state: RootState) => MediaOutletAddressInfo;
export const selectMediaOutletAddressInfo: MediaOutletAddressInfoSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.mediaOutletAddressInfo],
  (mediaOutletAddressInfo: MediaOutletAddressInfo) => mediaOutletAddressInfo
);

export type MediaOutletToAddSelector = (state: RootState) => MediaOutlet;
export const selectMediaOutletToAdd: MediaOutletToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.mediaOutletGeneralInfo, (state: RootState) => state.wizardCreateContact.mediaOutletAddressInfo],
  (mediaOutletGeneralInfo: MediaOutletGeneralInfo, mediaOutletAddressInfo: MediaOutletAddressInfo) =>
    createMediaOutlet(mediaOutletGeneralInfo, mediaOutletAddressInfo)
);

export type IsCreateCompanyWizardFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyWizardFinishButtonDisabled: IsCreateCompanyWizardFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.isCreateCompanyWizardFinishButtonDisabled],
  (isCreateCompanyWizardFinishButtonDisabled: boolean) => isCreateCompanyWizardFinishButtonDisabled
);

export type IsCreateMediaOutletWizardFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletWizardFinishButtonDisabled: IsCreateMediaOutletWizardFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.wizardCreateContact.isCreateMediaOutletWizardFinishButtonDisabled],
  (isCreateMediaOutletWizardFinishButtonDisabled: boolean) => isCreateMediaOutletWizardFinishButtonDisabled
);

const wizardCreateContactSlice = createSlice({
  name: 'wizardCreateContact',
  initialState: initialState,
  reducers: {
    openCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = true;
      state.currentStep = CreateContactWizardStep.GeneralInfo;
    },
    closeCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = false;
      state.currentStep = CreateContactWizardStep.GeneralInfo;
      state.generalContactInfoToAdd = {} as ContactGeneralInfo;
      state.collaborationsToAdd = [];
      state.isFinishButtonDisabled = false;
      state.pageNumber = 1;
      state.selectedCollaborationsPerPage = {};
    },
    setSearchMediaText: (state, action: PayloadAction<string>) => {
      state.mediaSearchText = action.payload;
    },
    pageOfMediaOutletsAndCompaniesRequested: (state) => {
      state.isLoadingCompanies = true;
      state.isLoadingMediaOutlets = true;
      state.totalCountOfCompanies = 0;
      state.totalCountOfMediaOutlets = 0;
    },
    pageOfMediaOutletsAndCompaniesReceived: (state, action: PayloadAction<{ pageOfCompanies: PageOfCompanies; pageOfMediaOutlets: PageOfMediaOutlets }>) => {
      state.isLoadingCompanies = false;
      state.isLoadingMediaOutlets = false;
      state.totalCountOfCompanies = action.payload.pageOfCompanies.totalCount;
      state.companies = action.payload.pageOfCompanies.companies;
      state.totalCountOfMediaOutlets = action.payload.pageOfMediaOutlets.totalCount;
      state.mediaOutlets = action.payload.pageOfMediaOutlets.mediaOutlets;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      state.pageNumber = 1;
    },
    openCreateMediaOutletWizard: (state) => {
      state.isCreateMediaOutletWizardOpened = true;
      state.mediaOutletCurrentStep = CreateMediaOutletFromContactStep.GeneralInfo;
    },
    closeCreateMediaOutletWizard: (state) => {
      state.isCreateMediaOutletWizardOpened = false;
      state.mediaOutletAddressInfo = {} as MediaOutletAddressInfo;
      state.mediaOutletGeneralInfo = {} as MediaOutletGeneralInfo;
      state.isCreateMediaOutletWizardFinishButtonDisabled = false;
    },
    openCreateCompanyWizard: (state) => {
      state.isCreateCompanyWizardOpened = true;
      state.companyCurrentStep = CreateCompanyFromContactStep.GeneralInfo;
    },
    closeCreateCompanyWizard: (state) => {
      state.isCreateCompanyWizardOpened = false;
      state.companyGeneralInfo = {} as CompanyGeneralInfo;
      state.companyAddressInfo = {} as CompanyAddressInfo;
      state.isCreateCompanyWizardFinishButtonDisabled = false;
    },
    addContactRequested: (state) => {
      state.isFinishButtonDisabled = true;
    },
    addedContact: (state) => {
      state.generalContactInfoToAdd = {} as ContactGeneralInfo;
    },
    goToStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      state.pageNumber = 1;
    },
    goToNextStep: (state) => {
      state.currentStep = state.currentStep + 1;
      state.pageNumber = 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep = state.currentStep - 1;
      state.pageNumber = 1;
    },
    goToMediaOutletStep: (state, action: PayloadAction<number>) => {
      state.mediaOutletCurrentStep = action.payload;
    },
    goToNextMediaOutletStep: (state) => {
      state.mediaOutletCurrentStep = state.mediaOutletCurrentStep + 1;
    },
    goToPreviousMediaOutletStep: (state) => {
      state.mediaOutletCurrentStep = state.mediaOutletCurrentStep - 1;
    },
    goToCompanyStep: (state, action: PayloadAction<number>) => {
      state.companyCurrentStep = action.payload;
    },
    goToNextCompanyStep: (state) => {
      state.companyCurrentStep = state.companyCurrentStep + 1;
    },
    goToPreviousCompanyStep: (state) => {
      state.companyCurrentStep = state.companyCurrentStep - 1;
    },
    addGeneralContactInformation: (state, action: PayloadAction<ContactGeneralInfo>) => {
      state.generalContactInfoToAdd = action.payload;
    },
    setAreGeneralContactInfoValid: (state, action: PayloadAction<boolean>) => {
      state.areGeneralContactInfoValid = action.payload;
    },
    setAreAdditionalInfoValid: (state, action: PayloadAction<boolean>) => {
      state.areAdditionalInfoValid = action.payload;
    },
    setCollaborationsToAddPerPage: (state, action: PayloadAction<CollaborationDetails[]>) => {
      if (state.isLoadingMediaOutlets || state.isLoadingCompanies) return;
      state.selectedCollaborationsPerPage[state.pageNumber] = action.payload;
      state.collaborationsToAdd = mapEntitiesPerPageToList<CollaborationDetails>(state.selectedCollaborationsPerPage);
    },
    updateContactCollaboration: (state, action: PayloadAction<{ mediumId: string; collaboration: CollaborationDetails }>) => {
      state.collaborationsToAdd = state.collaborationsToAdd.map((collaborationToAdd) => {
        if (collaborationToAdd.collaboration.medium.id === action.payload.mediumId) {
          return action.payload.collaboration;
        }

        return collaborationToAdd;
      });
    },
    removeCollaborationToAdd: (state, action: PayloadAction<string>) => {
      state.collaborationsToAdd = state.collaborationsToAdd.filter((collaboration) => collaboration.collaboration.medium.id !== action.payload);
    },
    addCompanyGeneralInfo: (state, action: PayloadAction<CompanyGeneralInfo>) => {
      state.companyGeneralInfo = action.payload;
    },
    addCompanyAddressInfo: (state, action: PayloadAction<CompanyAddressInfo>) => {
      state.companyAddressInfo = action.payload;
    },
    addCompanyRequested: () => {},
    companyAdded: (state, action: PayloadAction<Company>) => {
      state.companies = [...state.companies, action.payload];
    },
    addMediaOutletGeneralInfo: (state, action: PayloadAction<MediaOutletGeneralInfo>) => {
      state.mediaOutletGeneralInfo = action.payload;
    },
    addMediaOutletAddressInfo: (state, action: PayloadAction<MediaOutletAddressInfo>) => {
      state.mediaOutletAddressInfo = action.payload;
    },
    addMediaOutletRequested: () => {},
    mediaOutletAdded: (state, action: PayloadAction<MediaOutlet>) => {
      state.mediaOutlets = [...state.mediaOutlets, action.payload];
    },
    disableCreateCompanyWizardFinishButton: (state) => {
      state.isCreateCompanyWizardFinishButtonDisabled = true;
    },
    disableCreateMediaOutletWizardFinishButton: (state) => {
      state.isCreateMediaOutletWizardFinishButtonDisabled = true;
    },
    resetCollaborationsTable: (state) => {
      state.selectedCollaborationsPerPage = {} as EntitiesPerPage<CollaborationDetails>;
      state.collaborationsToAdd = [];
      state.pageNumber = 1;
    }
  }
});

export const {
  openCreateContactWizard,
  closeCreateContactWizard,
  openCreateMediaOutletWizard,
  closeCreateMediaOutletWizard,
  openCreateCompanyWizard,
  closeCreateCompanyWizard,
  addContactRequested,
  addedContact,
  goToStep,
  goToNextStep,
  goToPreviousStep,
  goToMediaOutletStep,
  goToNextMediaOutletStep,
  goToPreviousMediaOutletStep,
  goToCompanyStep,
  goToNextCompanyStep,
  goToPreviousCompanyStep,
  pageOfMediaOutletsAndCompaniesRequested,
  addGeneralContactInformation,
  updateContactCollaboration,
  removeCollaborationToAdd,
  addCompanyGeneralInfo,
  addCompanyAddressInfo,
  addCompanyRequested,
  companyAdded,
  addMediaOutletGeneralInfo,
  addMediaOutletAddressInfo,
  addMediaOutletRequested,
  mediaOutletAdded,
  disableCreateCompanyWizardFinishButton,
  disableCreateMediaOutletWizardFinishButton,
  setSearchMediaText,
  pageOfMediaOutletsAndCompaniesReceived,
  setPageNumber,
  setAreGeneralContactInfoValid,
  setCollaborationsToAddPerPage,
  setPageSize,
  setAreAdditionalInfoValid,
  resetCollaborationsTable
} = wizardCreateContactSlice.actions;

export default wizardCreateContactSlice.reducer;
