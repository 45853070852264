import { Icon } from '@fluentui/react';
import { Button, EditIcon, Input, Tooltip } from '@fluentui/react-northstar';
import {
  CopyToClipboardTooltip,
  hideEditAndCopyActions,
  hideEditInputFieldText,
  selectAreEditAndCopyActionsVisible,
  selectEditableFieldActionsId,
  selectEditableFieldId,
  selectIsInputFieldEditing,
  setEditableFieldActionsId,
  setEditableFieldId,
  showEditAndCopyActions,
  showEditInputFieldText,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { useIntl } from 'app/i18n';
import _uniqueId from 'lodash/uniqueId';
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

interface EditableInputNumberFieldProps {
  value: number;
  fieldName: string;
  onSaveChanges: (name: string, updatedValue: number) => void;
  label?: string;
  isLink: boolean;
  href?: string;
  disabled?: boolean;
}

export const EditableInputNumberField = ({ value, fieldName, onSaveChanges, label, isLink, href, disabled = false }: EditableInputNumberFieldProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const [editableFieldId] = useState(parseInt(_uniqueId()));
  const [editableFieldActionsId] = useState(parseInt(_uniqueId()));

  const areEditAndCopyActionsVisble = useAppSelector<boolean>(selectAreEditAndCopyActionsVisible);
  const selectorEditableFieldId = useAppSelector<number>(selectEditableFieldId);
  const selectorEditableActionsId = useAppSelector<number>(selectEditableFieldActionsId);
  const isInputEditing = useAppSelector<boolean>(selectIsInputFieldEditing);

  const [updatedValue, setUpdatedValue] = useState(value);

  useEffect(() => {
    setUpdatedValue(value);
  }, [fieldName, value, selectorEditableActionsId]);

  const handleCancelChanges = () => {
    if (isInputEditing) {
      setUpdatedValue(value);
      dispatch(hideEditInputFieldText());
    }
  };
  useOnClickOutside(ref, handleCancelChanges);

  const handleKeyboardEvents = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSaveChanges();
    } else if (event.key === 'Escape') {
      handleCancelChanges();
    }
  };

  const handleMouseEnter = () => {
    dispatch(setEditableFieldId(editableFieldId));
    dispatch(showEditAndCopyActions());
  };

  const handleMouseLeave = () => {
    dispatch(hideEditAndCopyActions());
  };

  const handleEditButtonClick = () => {
    if (disabled) return;
    dispatch(setEditableFieldActionsId(editableFieldActionsId));
    dispatch(showEditInputFieldText());
  };

  const handleSaveChanges = () => {
    onSaveChanges(fieldName, updatedValue);
    dispatch(hideEditInputFieldText());
    setUpdatedValue(updatedValue);
  };

  return (
    <div className="editable-fields-row">
      <span className="editable-fields-row-label">{label ? label + ':' : ''}</span>
      <div className="editable-fields-row-content" ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isInputEditing && editableFieldActionsId === selectorEditableActionsId ? (
          <div className="editable-fields-input">
            <Input
              className="input-wrap"
              type="number"
              autoFocus
              icon={
                <div className="editable-fields-input-actions">
                  <Button onClick={handleCancelChanges} iconOnly content={<Icon iconName="Cancel" />} text />
                  <Button onClick={handleSaveChanges} iconOnly content={<Icon iconName="CheckMark" />} text />
                </div>
              }
              value={updatedValue}
              onChange={(e) => setUpdatedValue(+(e.target as HTMLTextAreaElement).value)}
              onKeyDown={(e) => handleKeyboardEvents(e)}
            />
          </div>
        ) : (
          <>
            {isLink ? (
              <a href={href} className="editable-fields-value link">
                {updatedValue}
              </a>
            ) : (
              <span className="editable-fields-value">{updatedValue}</span>
            )}
            {areEditAndCopyActionsVisble && editableFieldId === selectorEditableFieldId ? (
              <div className="editable-fields-actions">
                <Tooltip
                  subtle={false}
                  pointing
                  trigger={<Button disabled={disabled} icon={<EditIcon />} onClick={handleEditButtonClick} iconOnly text size="small" />}
                  content={formatMessage({ id: 'button-tooltips.edit' })}
                  position="below"
                />
                <CopyToClipboardTooltip textToCopy={updatedValue.toString()} label={label} props={{ position: 'below' }} />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};
