import { FormEvent, useState } from 'react';
import { Button, CloseIcon, Dropdown, DropdownProps, Pill } from '@fluentui/react-northstar';
import { useDebouncedCallback } from 'use-debounce';
import { debounceTimeInMilliseconds, FilterInputControl, FilterItem, FilterItemType, useAppSelector } from 'app/common';
import {
  selectRecipientMediumNameSuggestions,
  selectSendingRecipientNameOrEmailAddressSuggestions,
  selectIsLoadingRecipientMediumNameSuggestions,
  selectIsLoadingRecipientNameOrEmailAddressSuggestions
} from 'app/pages/my-activities/sendings';
import { SENDING_FILTER } from 'app/pages/my-activities/sending-filter-search/filter-fields';
import { useIntl } from 'app/i18n';

interface RecipientsFiltersProps {
  onFilterAdd: ({ fieldName, value }: FilterItemType) => void;
  onFilterRemoval: ({ fieldName, value }: FilterItemType) => void;
  activeRecipientNameOrEmailAddressFilters: FilterItem[];
  activeRecipientMediumNameFilters: FilterItem[];
  recipientNameOrEmailAddressControl: FilterInputControl;
  recipientMediumNameControl: FilterInputControl;
}

export const RecipientsFilters = ({
  onFilterAdd,
  onFilterRemoval,
  activeRecipientNameOrEmailAddressFilters,
  activeRecipientMediumNameFilters,
  recipientNameOrEmailAddressControl,
  recipientMediumNameControl
}: RecipientsFiltersProps) => {
  const { formatMessage } = useIntl();

  const mediumNameSuggestions = useAppSelector(selectRecipientMediumNameSuggestions);
  const nameOrEmailAddressSuggestions = useAppSelector(selectSendingRecipientNameOrEmailAddressSuggestions);
  const isLoadingMediumNameSuggestions = useAppSelector(selectIsLoadingRecipientMediumNameSuggestions);
  const isLoadingNameOrEmailAddressSuggestions = useAppSelector(selectIsLoadingRecipientNameOrEmailAddressSuggestions);

  const onNameOrEmailSuggestionsRequested = useDebouncedCallback(recipientNameOrEmailAddressControl.onSuggestionsRequested, debounceTimeInMilliseconds);
  const onMediumNameSuggestionsRequested = useDebouncedCallback(recipientMediumNameControl.onSuggestionsRequested, debounceTimeInMilliseconds);

  const [nameOrEmailValue, setNameOrEmailValue] = useState('');
  const [mediumNameValue, setMediumNameValue] = useState('');

  const onNameOrEmailInputChange = ({ target }: FormEvent<HTMLDivElement>) => {
    recipientNameOrEmailAddressControl.onQueryChange((target as HTMLInputElement).value);
    onNameOrEmailSuggestionsRequested();
  };

  const onMediumNameInputChange = ({ target }: FormEvent<HTMLInputElement>) => {
    recipientMediumNameControl.onQueryChange((target as HTMLInputElement).value);
    onMediumNameSuggestionsRequested();
  };

  const handleOnNameOrEmailChange = (_, { value }: DropdownProps) => {
    setNameOrEmailValue('');
    if (value) onFilterAdd({ fieldName: SENDING_FILTER.MEDIUM_NAME, value: `${value}` });
  };

  const handleOnMediumNameChange = (_, { value }: DropdownProps) => {
    setMediumNameValue('');
    if (value) onFilterAdd({ fieldName: SENDING_FILTER.RECIPIENT_NAME_OR_EMAIL, value: `${value}` });
  };

  const a11ySelectionMessage = {
    onAdd: (name: string): string => `${name} selected.`,
    onRemove: (name: string): string => `${name} has been removed.`
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const handleBlur1 = () => setIsOpen1(false);
  const handleBlur2 = () => setIsOpen2(false);

  return (
    <>
      <div className="filter-panel-dropdown-wrap">
        <Dropdown
          search
          clearable={true}
          value={mediumNameValue}
          searchQuery={recipientMediumNameControl.searchQuery}
          loadingMessage={formatMessage({ id: 'filters.loading' })}
          loading={isLoadingMediumNameSuggestions}
          className="filter-panel-dropdown"
          onInput={onMediumNameInputChange}
          onChange={handleOnNameOrEmailChange}
          items={mediumNameSuggestions}
          placeholder={formatMessage({ id: 'filters.choose-company-or-media-outlet' })}
          getA11ySelectionMessage={a11ySelectionMessage}
          noResultsMessage={formatMessage({ id: 'filters.no-results' })}
          onBlur={handleBlur1}
          open={isOpen1}
          onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen1(props.open || false)}
        />
        <div className="filter-panel-pills">
          {activeRecipientMediumNameFilters.map((filterItem: FilterItem, index: number) => (
            <Pill key={index} className="filter-panel-pill">
              {`${filterItem.value}`}
              <Button icon={<CloseIcon />} iconOnly text onClick={() => onFilterRemoval({ fieldName: filterItem.fieldName, value: filterItem.value })} />
            </Pill>
          ))}
        </div>
      </div>
      <div className="filter-panel-dropdown-wrap">
        <Dropdown
          search
          clearable={true}
          value={nameOrEmailValue}
          searchQuery={recipientNameOrEmailAddressControl.searchQuery}
          loadingMessage={formatMessage({ id: 'filters.loading' })}
          loading={isLoadingNameOrEmailAddressSuggestions}
          className="filter-panel-dropdown"
          onInput={onNameOrEmailInputChange}
          onChange={handleOnMediumNameChange}
          items={nameOrEmailAddressSuggestions}
          placeholder={formatMessage({ id: 'filters.filter-by-contact-name-or-email' })}
          getA11ySelectionMessage={a11ySelectionMessage}
          noResultsMessage={formatMessage({ id: 'filters.no-results' })}
          onBlur={handleBlur2}
          open={isOpen2}
          onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen2(props.open || false)}
        />
        <div className="filter-panel-pills">
          {activeRecipientNameOrEmailAddressFilters.map((filterItem: FilterItem, index: number) => (
            <Pill key={index} className="filter-panel-pill">
              {`${filterItem.value}`}
              <Button icon={<CloseIcon />} iconOnly text onClick={() => onFilterRemoval({ fieldName: filterItem.fieldName, value: filterItem.value })} />
            </Pill>
          ))}
        </div>
      </div>
    </>
  );
};
