import { RootState } from 'app/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterItem, MediumCategory, QueryParams, SearchSuggestionCategory, TableHeader, Tag, TagProjection } from 'app/common';
import { DefaultMediaOutletsSortingInput, IMediaOutletsSortingInput, MediaOutlet, MediaOutletPanelData } from 'app/pages/my-audience/media-outlets';
import { MediaResort } from 'app/pages/my-audience/media-outlet-profile';
import { Contact } from 'app/pages/my-audience/contacts';

export interface MediaOutletsState {
  searchText: string;
  totalCountOfMediaOutlets: number;
  mediaOutlets: MediaOutlet[];
  selectedMediaOutletIds: string[];
  previousMediaOutlets: MediaOutlet[];
  isLoadingSearchSuggestions: boolean;
  isLoadingSearchResults: boolean;
  pageNumber: number;
  pageSize: number;
  sortingInput: IMediaOutletsSortingInput;
  exportHeader: TableHeader[];
  isFiltersPanelOpened: boolean;
  filterSuggestionsSearchText: string;
  filterSuggestionsPageSize: number;
  mediaOutletNameSuggestions: string[];
  isLoadingMediaOutletNameSuggestions: boolean;
  contactNameSuggestions: string[];
  isLoadingContactNameSuggestions: boolean;
  tagNameSuggestions: string[];
  isLoadingTagNameSuggestions: boolean;
  categorySuggestions: string[];
  isLoadingCategorySuggestions: boolean;
  languageSuggestions: string[];
  isLoadingLanguageSuggestions: boolean;
  searchSuggestionsText: string;
  mediaOutletSearchSuggestions: SearchSuggestionCategory[];
  filterItems: FilterItem[];
  mediaOutletToDelete: MediaOutlet;
  searchResultsTableHeader: TableHeader[];
  isSavedSearchesPanelOpen: boolean;
  isSearchAnimationActive: boolean;
  mediaOutletsToRemoveIds: string[];
  isUpdateDialogVisible: boolean;
  selectedMediaOutlets: MediaOutlet[];
  mediaResortIdsForBulkUpdate: string[];
  mediaResortsForBulkUpdate: MediaResort[];
  allTags: Tag[];
  filteredTags: Tag[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToMediaOutlets: TagProjection;
  tagToRemoveFromMediaOutlets: TagProjection;
  categories: MediumCategory[];
  mediaResortToBulkAdd: MediaResort;
  mediumCategoryNameToAdd: string;
  mediumCategoryIdToRemove: string;
  mediaResortToCreate: MediaResort;
}

const initialState: MediaOutletsState = {
  totalCountOfMediaOutlets: 0,
  mediaOutlets: [],
  selectedMediaOutletIds: [],
  previousMediaOutlets: [],
  selectedMediaOutlets: [],
  searchText: '',
  searchSuggestionsText: '',
  mediaOutletSearchSuggestions: [],
  filterItems: [],
  sortingInput: new DefaultMediaOutletsSortingInput(),
  mediaOutletToDelete: {} as MediaOutlet,
  pageNumber: 1,
  pageSize: 30,
  isLoadingSearchSuggestions: false,
  isLoadingSearchResults: false,
  searchResultsTableHeader: [],
  exportHeader: [],
  isSavedSearchesPanelOpen: false,
  filterSuggestionsSearchText: '',
  filterSuggestionsPageSize: 10,
  isFiltersPanelOpened: false,
  mediaOutletNameSuggestions: [],
  isLoadingMediaOutletNameSuggestions: false,
  contactNameSuggestions: [],
  isLoadingContactNameSuggestions: false,
  tagNameSuggestions: [],
  isLoadingTagNameSuggestions: false,
  categorySuggestions: [],
  isLoadingCategorySuggestions: false,
  languageSuggestions: [],
  isLoadingLanguageSuggestions: false,
  isSearchAnimationActive: false,
  mediaOutletsToRemoveIds: [],
  isUpdateDialogVisible: false,
  mediaResortIdsForBulkUpdate: [],
  mediaResortsForBulkUpdate: [],
  allTags: [],
  filteredTags: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToMediaOutlets: {} as TagProjection,
  tagToRemoveFromMediaOutlets: {} as TagProjection,
  categories: [],
  mediaResortToBulkAdd: {} as MediaResort,
  mediumCategoryIdToRemove: '',
  mediumCategoryNameToAdd: '',
  mediaResortToCreate: {} as MediaResort
};

export type SelectedMediaOutletIdsSelector = (state: RootState) => string[];
export const selectSelectedMediaOutletIds: SelectedMediaOutletIdsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.selectedMediaOutletIds],
  (mediaOutletsIds: string[]) => mediaOutletsIds
);

export type AllMediaOutletCategoriesSelector = (state: RootState) => MediumCategory[];
export const selectCategories: AllMediaOutletCategoriesSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.categories],
  (categories: MediumCategory[]) => categories
);

export type MediaOutletsToRemoveIdsSelector = (state: RootState) => string[];
export const selectMediaOutletsToRemoveIds: MediaOutletsToRemoveIdsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaOutletsToRemoveIds],
  (mediaOutletsToRemoveIds: string[]) => mediaOutletsToRemoveIds
);

export type CountOfLoadedMediaOutletsSelector = (state: RootState) => number;
export const selectCountOfLoadedMediaOutlets: CountOfLoadedMediaOutletsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaOutlets],
  (mediaOutlets: MediaOutlet[]) => mediaOutlets.length
);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector([(state: RootState) => state.mediaOutlets.pageNumber], (pageNumber: number) => pageNumber);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.mediaOutlets.pageSize], (pageSize: number) => pageSize);

export type SortingInputSelector = (state: RootState) => IMediaOutletsSortingInput;
export const selectSortingInput: SortingInputSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.sortingInput],
  (sortingInput: IMediaOutletsSortingInput) => sortingInput
);

export type SearchSuggestionsSelector = (state: RootState) => SearchSuggestionCategory[];
export const selectSearchSuggestions: SearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaOutletSearchSuggestions],
  (mediaOutletSearchSuggestions: SearchSuggestionCategory[]) => mediaOutletSearchSuggestions
);

export type IsLoadingSearchSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchSuggestions: IsLoadingSearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isLoadingSearchSuggestions],
  (isLoadingSearchSuggestions: boolean) => isLoadingSearchSuggestions
);

export type IsLoadingSearchResultsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchResults: IsLoadingSearchResultsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isLoadingSearchResults],
  (isLoadingSearchResults: boolean) => isLoadingSearchResults
);

export type MediaOutletsSelector = (state: RootState) => MediaOutlet[];
export const selectMediaOutlets: MediaOutletsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaOutlets],
  (mediaOutlets: MediaOutlet[]) => mediaOutlets
);

export type SearchSuggestionTextSelector = (state: RootState) => string;
export const selectSearchSuggestionText: SearchSuggestionTextSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.searchSuggestionsText],
  (searchSuggestionsText: string) => searchSuggestionsText
);

export type FilterItemsSelector = (state: RootState) => FilterItem[];
export const selectFilterItems: FilterItemsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.filterItems],
  (filterItems: FilterItem[]) => filterItems
);

export type SearchResultsTableHeaderSelector = (state: RootState) => TableHeader[];
export const selectSearchResultTableHeader: SearchResultsTableHeaderSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.searchResultsTableHeader],
  (searchResultsTableHeader: TableHeader[]) => searchResultsTableHeader
);

export type ExportHeaderSelector = (state: RootState) => TableHeader[];
export const selectExportHeader: ExportHeaderSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.exportHeader],
  (exportHeader: TableHeader[]) => exportHeader
);

export type IsSavedSearchesPanelOpenSelector = (state: RootState) => boolean;
export const selectIsSavedSearchesPanelOpen: IsSavedSearchesPanelOpenSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isSavedSearchesPanelOpen],
  (isSavedSearchesPanelOpen: boolean) => isSavedSearchesPanelOpen
);

export type MediaOutletNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectMediaOutletNameFilterSuggestions: MediaOutletNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaOutletNameSuggestions],
  (mediaOutletNameSuggestions: string[]) => mediaOutletNameSuggestions
);

export type IsLoadingMediaOutletNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingMediaOutletNameSuggestions: IsLoadingMediaOutletNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isLoadingMediaOutletNameSuggestions],
  (isLoadingMediaOutletNameSuggestions: boolean) => isLoadingMediaOutletNameSuggestions
);

export type ContactNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectContactNameFilterSuggestions: ContactNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.contactNameSuggestions],
  (contactNameSuggestions: string[]) => contactNameSuggestions
);

export type IsLoadingContactNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingContactNameSuggestions: IsLoadingContactNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isLoadingContactNameSuggestions],
  (isLoadingContactNameSuggestions: boolean) => isLoadingContactNameSuggestions
);

export type TagNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectTagNameFilterSuggestions: TagNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.tagNameSuggestions],
  (tagNameSuggestions: string[]) => tagNameSuggestions
);

export type IsLoadingTagNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingTagNameSuggestions: IsLoadingTagNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isLoadingTagNameSuggestions],
  (isLoadingTagNameSuggestions: boolean) => isLoadingTagNameSuggestions
);

export type CategoryFilterSuggestionsSelector = (state: RootState) => string[];
export const selectCategoryFilterSuggestions: CategoryFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.categorySuggestions],
  (categorySuggestions: string[]) => categorySuggestions
);

export type IsLoadingCategorySuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingCategorySuggestions: IsLoadingCategorySuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isLoadingCategorySuggestions],
  (isLoadingCategorySuggestions: boolean) => isLoadingCategorySuggestions
);

export type LanguageFilterSuggestionsSelector = (state: RootState) => string[];
export const selectLanguageFilterSuggestions: LanguageFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.languageSuggestions],
  (languageSuggestions: string[]) => languageSuggestions
);

export type IsLoadingLanguageSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingLanguageSuggestions: IsLoadingLanguageSuggestionsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isLoadingLanguageSuggestions],
  (isLoadingLanguageSuggestions: boolean) => isLoadingLanguageSuggestions
);

export type FilterSuggestionsSearchTextSelector = (state: RootState) => string;
export const selectFilterSuggestionsSearchText: FilterSuggestionsSearchTextSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.filterSuggestionsSearchText],
  (filterSuggestionsSearchText: string) => filterSuggestionsSearchText
);

export type FilterSuggestionsPageSizeSelector = (state: RootState) => number;
export const selectFilterSuggestionsPageSize: FilterSuggestionsPageSizeSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.filterSuggestionsPageSize],
  (filterSuggestionsPageSize: number) => filterSuggestionsPageSize
);

export type IsFilersPanelOpenedSelector = (state: RootState) => boolean;
export const selectIsFiltersPanelOpened: IsFilersPanelOpenedSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isFiltersPanelOpened],
  (isFiltersPanelOpened: boolean) => isFiltersPanelOpened
);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector([(state: RootState) => state.mediaOutlets.searchText], (searchText: string) => searchText);

export type TotalCountOfMediaOutletsSelector = (state: RootState) => number;
export const selectTotalCountOfMediaOutlets: TotalCountOfMediaOutletsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.totalCountOfMediaOutlets],
  (totalCountOfMediaOutlets: number) => totalCountOfMediaOutlets
);

export type IsSearchAnimationActiveSelector = (state: RootState) => boolean;
export const selectIsSearchAnimationActive: IsSearchAnimationActiveSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isSearchAnimationActive],
  (isSearchAnimationActive: boolean) => isSearchAnimationActive
);

export type IsUpdateDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsUpdateDialogVisible: IsUpdateDialogVisibleSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.isUpdateDialogVisible],
  (isUpdateDialogVisible: boolean) => isUpdateDialogVisible
);

export type MediaResortsIdsForBulkUpdateSelector = (state: RootState) => string[];
export const selectMediaResortsIdsForBulkUpdate: MediaResortsIdsForBulkUpdateSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaResortIdsForBulkUpdate],
  (MediaResortsIdsForBulkUpdate: string[]) => MediaResortsIdsForBulkUpdate
);

export type MediaResortsForBulkUpdateSelector = (state: RootState) => MediaResort[];
export const selectMediaResortsForBulkUpdate: MediaResortsForBulkUpdateSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaResortsForBulkUpdate],
  (mediaResortsForBulkUpdate: MediaResort[]) => mediaResortsForBulkUpdate
);

export type SelectedMediaOutletsSelector = (state: RootState) => MediaOutlet[];
export const selectSelectedMediaOutlets: SelectedMediaOutletsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.selectedMediaOutlets],
  (selectedMediaOutlets: MediaOutlet[]) => selectedMediaOutlets
);

export type AllTagsSelector = (state: RootState) => Tag[];
export const selectAllTags: AllTagsSelector = createSelector([(state: RootState) => state.mediaOutlets.allTags], (allTags: Tag[]) => allTags);

export type FilteredTagsInPanelSelector = (state: RootState) => Tag[];
export const selectFilteredTagsInPanel: FilteredTagsInPanelSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.filteredTags],
  (filteredTags: Tag[]) => filteredTags
);

export type TagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: TagNameToCreateSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type TagsToDeleteSelector = (state: RootState) => Tag[];
export const selectTagsToDelete: TagsToDeleteSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.tagsToDelete],
  (tagsToDelete: Tag[]) => tagsToDelete
);

export type tagToAddToContactsSelector = (state: RootState) => TagProjection;
export const selectTagToAddToMediaOutlets: tagToAddToContactsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.tagToAddToMediaOutlets],
  (tagToAddToMediaOutlets: TagProjection) => tagToAddToMediaOutlets
);

export type TagToRemoveFromContactsSelector = (state: RootState) => TagProjection;
export const selectTagToRemoveFromMediaOutlets: TagToRemoveFromContactsSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.tagToRemoveFromMediaOutlets],
  (tagToRemoveFromMediaOutlets: TagProjection) => tagToRemoveFromMediaOutlets
);

export type MediaResortToBulkAddSelector = (state: RootState) => MediaResort;
export const selectMediaResortToBulkAdd: MediaResortToBulkAddSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaResortToBulkAdd],
  (mediaResortToBulkAdd: MediaResort) => mediaResortToBulkAdd
);

export type MediumCategoryNameToAddSelector = (state: RootState) => string;
export const selectMediumCategoryNameToAdd: MediumCategoryNameToAddSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediumCategoryNameToAdd],
  (mediumCategoryNameToAdd: string) => mediumCategoryNameToAdd
);

export type MediumCategoryIdToRemoveSelector = (state: RootState) => string;
export const selectMediumCategoryIdToRemove: MediumCategoryIdToRemoveSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediumCategoryIdToRemove],
  (mediumCategoryIdToRemove: string) => mediumCategoryIdToRemove
);

export type MediaResortToCreateSelector = (state: RootState) => MediaResort;
export const selectMediaResortToCreate: MediaResortToCreateSelector = createSelector(
  [(state: RootState) => state.mediaOutlets.mediaResortToCreate],
  (mediaResortToCreate: MediaResort) => mediaResortToCreate
);

const mediaOutletsSlice = createSlice({
  name: 'media-outlets',
  initialState,
  reducers: {
    setTotalCountOfMediaOutlets: (state, action: PayloadAction<number>) => {
      state.totalCountOfMediaOutlets = action.payload;
    },
    firstPageOfMediaOutletsRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = 1;
      state.totalCountOfMediaOutlets = 0;
      state.mediaOutlets = [];
    },
    firstPageOfMediaOutletsReceived: (state, action: PayloadAction<MediaOutlet[]>) => {
      state.isLoadingSearchResults = false;
      state.mediaOutlets = action.payload;

      const topMediaOutlets = state.mediaOutlets.slice(0, 5);
      state.mediaOutletNameSuggestions = Array.from(new Set(topMediaOutlets.map((mediaOutlet) => mediaOutlet.name).filter(Boolean)));
      state.contactNameSuggestions = Array.from(
        new Set(
          topMediaOutlets
            .flatMap((mediaOutlet) => mediaOutlet.mediaOutletContacts)
            .filter(Boolean)
            .map((c) => `${c.firstName} ${c.lastName}`)
        )
      );
      state.tagNameSuggestions = Array.from(new Set(topMediaOutlets.flatMap((mediaOutlet) => mediaOutlet.tags).map((tag) => tag.name)));
      state.languageSuggestions = Array.from(
        new Set(topMediaOutlets.flatMap((mediaOutlet) => mediaOutlet.preferredLanguages.map((language) => language.name)))
      );
      state.categorySuggestions = Array.from(new Set(state.categories.filter((category) => category?.name).map((category) => category.name)));
    },
    nextPageOfMediaOutletsRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = state.pageNumber + 1;
    },
    nextPageOfMediaOutletsReceived: (state, action: PayloadAction<MediaOutlet[]>) => {
      state.mediaOutlets = [...state.mediaOutlets, ...action.payload];
      state.isLoadingSearchResults = false;
    },
    searchMediaOutletsRequested: (state, action: PayloadAction<string>) => {
      state.mediaOutletSearchSuggestions = [];
      state.searchText = action.payload;
      state.searchSuggestionsText = '';
    },
    mediaOutletSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.searchSuggestionsText = action.payload;
      state.isLoadingSearchSuggestions = true;
    },
    mediaOutletSuggestionsReceived: (state, action: PayloadAction<SearchSuggestionCategory[]>) => {
      state.mediaOutletSearchSuggestions = action.payload;
      state.isLoadingSearchSuggestions = false;
    },
    resetMediaOutletSuggestions: (state) => {
      state.mediaOutletSearchSuggestions = [];
      state.searchSuggestionsText = '';
    },
    addMediaOutletsFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = [...state.filterItems, action.payload];
    },
    removeMediaOutletsFilter: (state, action: PayloadAction<FilterItem>) => {
      const index = state.filterItems.findIndex((fi) => fi.fieldName === action.payload.fieldName && fi.value === action.payload.value);
      state.filterItems.splice(index, 1);
    },
    clearAllFilters: (state) => {
      state.filterItems = [];
    },
    setFilters: (state, action: PayloadAction<FilterItem[]>) => {
      state.filterItems = action.payload;
    },
    removeMediaOutletRequested: (state, action: PayloadAction<MediaOutlet>) => {
      state.mediaOutletToDelete = action.payload;
    },
    removedMediaOutlet: (state, action: PayloadAction<string>) => {
      state.mediaOutlets = state.mediaOutlets.filter((mediaOutlet) => mediaOutlet.id !== action.payload);
    },
    transformMediaOutletIntoCompanyRequested: () => {},
    mediaOutletTransformedIntoCompany: (state, action: PayloadAction<string>) => {
      state.mediaOutlets = state.mediaOutlets.filter((mediaOutlet) => mediaOutlet.id !== action.payload);
      state.totalCountOfMediaOutlets--;
    },
    removeMediaOutletFromStore: (state, action: PayloadAction<MediaOutlet>) => {
      state.mediaOutlets = state.mediaOutlets.filter((mediaOutlet) => mediaOutlet.id !== action.payload.id);
    },
    undoRemoveMediaOutletFromStore: (state, action: PayloadAction<MediaOutlet>) => {
      state.mediaOutlets = [action.payload, ...state.mediaOutlets];
    },
    removeMediaOutletsFromStore: (state, action: PayloadAction<string[]>) => {
      state.previousMediaOutlets = state.mediaOutlets;
      state.mediaOutlets = state.mediaOutlets?.filter((mediaOutlet) => !action.payload.some((id) => id === mediaOutlet.id));
    },
    undoRemoveMediaOutletsFromStore: (state) => {
      state.mediaOutlets = state.previousMediaOutlets;
      state.previousMediaOutlets = [];
    },
    updateMediaOutlet: (state, action: PayloadAction<MediaOutlet>) => {
      const index = state.mediaOutlets.findIndex((mo) => mo.id === action.payload.id);
      const updatedMediaOutlets = [...state.mediaOutlets];
      updatedMediaOutlets[index] = action.payload;
      state.mediaOutlets = [...updatedMediaOutlets];
    },
    updatedProfilePictureSearchResults: (state, action: PayloadAction<{ mediaOutletId: string; imageUrl: string }>) => {
      const index = state.mediaOutlets.findIndex((c) => c.id === action.payload.mediaOutletId);
      state.mediaOutlets[index] = { ...state.mediaOutlets[index], profilePictureUrl: action.payload.imageUrl };
    },
    setSearchResultTableHeader: (state, action: PayloadAction<TableHeader[]>) => {
      state.searchResultsTableHeader = action.payload;
      state.exportHeader = action.payload.filter((headerItem) => headerItem.isColumnVisible && headerItem.fieldName !== 'add');
    },
    openSavedSearchesPanel: (state) => {
      state.isSavedSearchesPanelOpen = true;
    },
    closeSavedSearchesPanel: (state) => {
      state.isSavedSearchesPanelOpen = false;
    },
    startLoadingMediaOutletNameSuggestions: (state) => {
      state.isLoadingMediaOutletNameSuggestions = true;
      state.mediaOutletNameSuggestions = [];
    },
    mediaOutletNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    mediaOutletNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.mediaOutletNameSuggestions = action.payload;
      state.isLoadingMediaOutletNameSuggestions = false;
    },
    startLoadingContactNameSuggestions: (state) => {
      state.isLoadingContactNameSuggestions = true;
      state.contactNameSuggestions = [];
    },
    contactNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    contactNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.contactNameSuggestions = action.payload;
      state.isLoadingContactNameSuggestions = false;
    },
    startLoadingTagNameSuggestions: (state) => {
      state.isLoadingTagNameSuggestions = true;
      state.tagNameSuggestions = [];
    },
    tagNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    tagNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.tagNameSuggestions = action.payload;
      state.isLoadingTagNameSuggestions = false;
    },
    startLoadingCategorySuggestions: (state) => {
      state.isLoadingCategorySuggestions = true;
      state.categorySuggestions = [];
    },
    categoryFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    categoryFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.categorySuggestions = action.payload;
      state.isLoadingCategorySuggestions = false;
    },
    startLoadingLanguageSuggestions: (state) => {
      state.isLoadingLanguageSuggestions = true;
      state.languageSuggestions = [];
    },
    languageFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    languageFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.languageSuggestions = action.payload;
      state.isLoadingLanguageSuggestions = false;
    },
    openFiltersPanel: (state) => {
      state.isFiltersPanelOpened = true;
    },
    closeFiltersPanel: (state) => {
      state.isFiltersPanelOpened = false;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setFilterItems: (state, action: PayloadAction<FilterItem[]>) => {
      state.filterItems = action.payload;
    },
    startSearchAnimation: (state) => {
      state.isSearchAnimationActive = true;
    },
    stopSearchAnimation: (state) => {
      state.isSearchAnimationActive = false;
    },
    setQueryParams: (state, action: PayloadAction<QueryParams>) => {
      state.searchText = action.payload.searchText;
      state.filterItems = action.payload.filterItems;
    },
    setSortingInput: (state, action: PayloadAction<IMediaOutletsSortingInput>) => {
      state.sortingInput = action.payload;
    },
    addNewTagRequested: (state, action: PayloadAction<{ mediaOutletIds: string[]; text: string }>) => {
      state.selectedMediaOutletIds = action.payload.mediaOutletIds;
      state.tagNameToCreate = action.payload.text;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.allTags = [...state.allTags, action.payload];
      state.filteredTags = state.allTags;
      state.selectedMediaOutlets = state.selectedMediaOutlets.map((c) => {
        return !c.tags.includes(action.payload) ? (c = { ...c, tags: [...c.tags, action.payload] }) : c;
      });
      state.tagNameToCreate = '';
    },
    deleteTagsRequested: (state, action: PayloadAction<Tag[]>) => {
      state.tagsToDelete = action.payload;
    },
    tagsDeleted: (state, action: PayloadAction<string[]>) => {
      state.allTags = state.allTags.filter((tag) => !action.payload.includes(tag.id));
      state.filteredTags = state.filteredTags.filter((tag) => !action.payload.includes(tag.id));
      state.selectedMediaOutlets = state.selectedMediaOutlets.map((c) => (c = { ...c, tags: c.tags.filter((tag) => !action.payload.includes(tag.id)) }));
      state.tagsToDelete = [];
    },
    addTagToMediaOutletsRequested: (state, action: PayloadAction<{ mediaOutletIds: string[]; tag: Tag }>) => {
      state.selectedMediaOutletIds = action.payload.mediaOutletIds;
      state.tagToAddToMediaOutlets = action.payload.tag;
    },
    removeTagFromMediaOutletsInStore: (state, action: PayloadAction<TagProjection>) => {
      state.selectedMediaOutlets = state.selectedMediaOutlets.map((c) => (c = { ...c, tags: c.tags.filter((tag) => tag.id !== action.payload.id) }));
    },
    removeTagFromMediaOutletsRequested: (state, action: PayloadAction<{ mediaOutletIds: string[]; tag: Tag }>) => {
      state.selectedMediaOutletIds = action.payload.mediaOutletIds;
      state.tagToRemoveFromMediaOutlets = action.payload.tag;
    },
    filterTags: (state, action: PayloadAction<string>) => {
      state.filteredTags = state.allTags.filter((tag) => tag.name.includes(action.payload));
    },
    getAllMediaOutletsTagsRequested: () => {},
    allTagsReceived: (state, action: PayloadAction<Tag[]>) => {
      state.allTags = action.payload;
      state.filteredTags = action.payload;
    },
    addedTagToMediaOutletsSearchResults: (state, action: PayloadAction<{ mediaOutletIds: string[]; tag: Tag }>) => {
      const { mediaOutletIds, tag } = action.payload;
      state.mediaOutlets = state.mediaOutlets.map((mediaOutlet) => {
        if (!mediaOutletIds.includes(mediaOutlet.id) || mediaOutlet.tags.map((t) => t.id).includes(tag.id)) return mediaOutlet;

        return {
          ...mediaOutlet,
          tags: [...mediaOutlet.tags, tag]
        };
      });
    },
    removedTagFromMediaOutletSearchResults: (state, action: PayloadAction<{ mediaOutletId: string; tag: TagProjection }>) => {
      const { mediaOutletId, tag } = action.payload;
      const index = state.mediaOutlets.findIndex((c) => c.id === mediaOutletId);
      const updatedTags = state.mediaOutlets[index].tags.filter((t) => t.id !== tag.id);
      state.mediaOutlets[index] = { ...state.mediaOutlets[index], tags: [...updatedTags] };
    },
    removeMediaOutletsByIdsRequested: (state, action: PayloadAction<string[]>) => {
      state.mediaOutletsToRemoveIds = action.payload;
    },
    mediaOutletsByIdsRemoved: (state, action: PayloadAction<MediaOutlet[]>) => {
      state.mediaOutlets = state.mediaOutlets.filter((c) => !action.payload.map((p) => p.id).includes(c.id));
      state.mediaOutletsToRemoveIds = [];
      state.totalCountOfMediaOutlets = state.totalCountOfMediaOutlets - action.payload.length;
    },
    openUpdateDialog: (state) => {
      state.isUpdateDialogVisible = true;
    },
    closeUpdateDialog: (state) => {
      state.isUpdateDialogVisible = false;
      state.mediaResortIdsForBulkUpdate = [];
      state.mediaResortsForBulkUpdate = [];
    },
    updateMediaOutletsRequested: (state, action: PayloadAction<{ mediaOutlets: MediaOutlet[]; mediaResortsIds: string[] }>) => {
      state.selectedMediaOutlets = action.payload.mediaOutlets;
      state.mediaResortIdsForBulkUpdate = action.payload.mediaResortsIds;
    },
    addMediaResortForBulkUpdate: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortsForBulkUpdate = [...state.mediaResortsForBulkUpdate, action.payload];
    },
    removeMediaResortsForBulkUpdate: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortsForBulkUpdate = state.mediaResortsForBulkUpdate.filter((t) => t.id !== action.payload.id);
    },
    removedTagsFromMediaOutletsSearchResults: (state, action: PayloadAction<{ mediaOutletIds: string[]; tags: Tag[] }>) => {
      action.payload.mediaOutletIds.forEach((element) => {
        const index = state.mediaOutlets.findIndex((c) => c.id === element);
        action.payload.tags.forEach((tag) => {
          state.mediaOutlets[index] = {
            ...state.mediaOutlets[index],
            tags: state.mediaOutlets[index].tags.filter((t) => t.id !== tag.id)
          };
        });
      });
    },
    mediaOutletsUpdated: (state, action: PayloadAction<MediaOutlet[]>) => {
      state.mediaOutlets.map((currentMediaOutlet) => {
        const updatedMediaOutlet = action.payload.find((mo) => mo.id === currentMediaOutlet.id);
        return updatedMediaOutlet ? updatedMediaOutlet : currentMediaOutlet;
      });
    },
    mediaOutletPanelDataRequested: () => {},
    mediaOutletPanelDataReceived: (state, action: PayloadAction<MediaOutletPanelData>) => {
      state.categories = action.payload.categories;
    },
    removeColumnFromMediaOutletInTable: (state, action: PayloadAction<{ mediaOutletId: string; column: MediaResort }>) => {
      const updatedMediaOutletIndex = state.mediaOutlets.findIndex((mo) => mo.id === action.payload.mediaOutletId);
      state.mediaOutlets.splice(updatedMediaOutletIndex, 1, {
        ...state.mediaOutlets[updatedMediaOutletIndex],
        mediaResorts: state.mediaOutlets[updatedMediaOutletIndex].mediaResorts.filter((col) => col.id !== action.payload.column.id)
      });
    },
    undoRemoveColumnFromMediaOutletInTable: (state, action: PayloadAction<{ mediaOutletId: string; column: MediaResort }>) => {
      const updatedMediaOutletIndex = state.mediaOutlets.findIndex((mo) => mo.id === action.payload.mediaOutletId);
      state.mediaOutlets.splice(updatedMediaOutletIndex, 1, {
        ...state.mediaOutlets[updatedMediaOutletIndex],
        mediaResorts: state.mediaOutlets[updatedMediaOutletIndex].mediaResorts.filter((col) => col.id !== action.payload.column.id)
      });
    },
    removeMediaResortsFromMediaOutletInTable: (state, action: PayloadAction<{ mediaOutletId: string; mediaResorts: MediaResort[] }>) => {
      const updatedMediaOutletIndex = state.mediaOutlets.findIndex((mo) => mo.id === action.payload.mediaOutletId);
      state.mediaOutlets.splice(updatedMediaOutletIndex, 1, {
        ...state.mediaOutlets[updatedMediaOutletIndex],
        mediaResorts: state.mediaOutlets[updatedMediaOutletIndex].mediaResorts.filter((mr) => !action.payload.mediaResorts.map((c) => c.name).includes(mr.name))
      });
    },
    addMediaResortToMediaOutletInTable: (state, actions: PayloadAction<{ mediaOutletIds: string[]; mediaResorts: MediaResort[] }>) => {
      const { mediaOutletIds, mediaResorts } = actions.payload;
      mediaOutletIds.forEach((mediaOutletId) => {
        state.mediaOutlets = state.mediaOutlets.map((mo) => {
          if (mo.id !== mediaOutletId) {
            return mo;
          }
          const existingMediaResortsIds = mo.mediaResorts.map((m) => m.id);
          mo.mediaResorts = [...mo.mediaResorts, ...mediaResorts.filter((mo) => !existingMediaResortsIds.includes(mo.id))];
          return mo;
        });
      });
    },
    undoAddMediaResortToMediaOutletInTable: (state, action: PayloadAction<{ mediaOutletId: string; mediaResort: MediaResort }>) => {
      const updatedMediaOutletIndex = state.mediaOutlets.findIndex((mo) => mo.id === action.payload.mediaOutletId);
      const newMediaOutletMediaResorts = state.mediaOutlets[updatedMediaOutletIndex].mediaResorts.filter((c) => c.id !== action.payload.mediaResort.id);
      state.mediaOutlets.splice(updatedMediaOutletIndex, 1, {
        ...state.mediaOutlets[updatedMediaOutletIndex],
        mediaResorts: newMediaOutletMediaResorts
      });
    },
    addContactToMediaOutletInTable: (state, action: PayloadAction<{ mediaOutletId: string; contact: Contact }>) => {
      const updatedMediaOutletIndex = state.mediaOutlets.findIndex((mo) => mo.id === action.payload.mediaOutletId);
      const newMediaOutletContacts = [...state.mediaOutlets[updatedMediaOutletIndex].mediaOutletContacts, action.payload.contact];
      state.mediaOutlets.splice(updatedMediaOutletIndex, 1, {
        ...state.mediaOutlets[updatedMediaOutletIndex],
        mediaOutletContacts: newMediaOutletContacts
      });
    },
    removeContactFromMediaOutletInTable: (state, action: PayloadAction<{ mediaOutletId: string; contactId: string }>) => {
      const updatedMediaOutletIndex = state.mediaOutlets.findIndex((mo) => mo.id === action.payload.mediaOutletId);

      state.mediaOutlets.splice(updatedMediaOutletIndex, 1, {
        ...state.mediaOutlets[updatedMediaOutletIndex],
        mediaOutletContacts: state.mediaOutlets[updatedMediaOutletIndex].mediaOutletContacts.filter((contact) => contact.id !== action.payload.contactId)
      });
    },
    removeMediaResortsInTable: (state, action: PayloadAction<string[]>) => {
      const mediaResortsIds = action.payload;
      const idsOfMediaOutletsToUpdate = state.mediaOutlets
        .filter((mediaOutlet) => mediaOutlet.mediaResorts.some((mr) => mediaResortsIds.includes(mr.id)))
        .map((mediaOutlet) => mediaOutlet.id);

      idsOfMediaOutletsToUpdate.forEach((id) => {
        const mediaOutlet = state.mediaOutlets.find((mediaOutlet) => mediaOutlet.id === id);
        mediaOutlet.mediaResorts = mediaOutlet.mediaResorts.filter((mr) => !mediaResortsIds.includes(mr.id));
      });
    },
    addMediumCategoryRequested: (state, action: PayloadAction<string>) => {
      state.mediumCategoryNameToAdd = action.payload;
    },
    mediumCategoryAdded: (state, action: PayloadAction<MediumCategory>) => {
      state.categories = [...state.categories, action.payload];
    },
    removeMediumCategoryRequested: (state, action: PayloadAction<string>) => {
      state.mediumCategoryIdToRemove = action.payload;
    },
    mediumCategoryRemoved: (state, action: PayloadAction<string>) => {
      state.categories = state.categories.filter((category) => category.id !== action.payload);
    },
    createMediaResortRequested: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortToCreate = action.payload;
    },
    mediaResortCreated: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortsForBulkUpdate = [...state.mediaResortsForBulkUpdate, action.payload];
    },
    removedMediaResorts: (state, action: PayloadAction<string[]>) => {
      state.mediaResortsForBulkUpdate = state.mediaResortsForBulkUpdate.filter((mr) => !action.payload.includes(mr.id));
    }
  }
});

export const {
  removeMediaOutletFromStore,
  undoRemoveMediaOutletFromStore,
  removeMediaOutletsFromStore,
  undoRemoveMediaOutletsFromStore,
  mediaOutletSuggestionsRequested,
  mediaOutletSuggestionsReceived,
  resetMediaOutletSuggestions,
  searchMediaOutletsRequested,
  addMediaOutletsFilter,
  clearAllFilters,
  setFilters,
  removeMediaOutletsFilter,
  removeMediaOutletRequested,
  removedMediaOutlet,
  transformMediaOutletIntoCompanyRequested,
  mediaOutletTransformedIntoCompany,
  updateMediaOutlet,
  updatedProfilePictureSearchResults,
  openSavedSearchesPanel,
  closeSavedSearchesPanel,
  setSearchResultTableHeader,
  startLoadingMediaOutletNameSuggestions,
  mediaOutletNameFilterSuggestionsReceived,
  mediaOutletNameFilterSuggestionsRequested,
  startLoadingContactNameSuggestions,
  contactNameFilterSuggestionsReceived,
  contactNameFilterSuggestionsRequested,
  startLoadingTagNameSuggestions,
  tagNameFilterSuggestionsReceived,
  tagNameFilterSuggestionsRequested,
  startLoadingCategorySuggestions,
  categoryFilterSuggestionsReceived,
  categoryFilterSuggestionsRequested,
  startLoadingLanguageSuggestions,
  languageFilterSuggestionsReceived,
  languageFilterSuggestionsRequested,
  openFiltersPanel,
  closeFiltersPanel,
  setTotalCountOfMediaOutlets,
  firstPageOfMediaOutletsRequested,
  nextPageOfMediaOutletsRequested,
  nextPageOfMediaOutletsReceived,
  firstPageOfMediaOutletsReceived,
  setSearchText,
  setFilterItems,
  startSearchAnimation,
  stopSearchAnimation,
  setQueryParams,
  setSortingInput,
  removedTagFromMediaOutletSearchResults,
  removeMediaOutletsByIdsRequested,
  mediaOutletsByIdsRemoved,
  openUpdateDialog,
  closeUpdateDialog,
  updateMediaOutletsRequested,
  addMediaResortForBulkUpdate,
  removeMediaResortsForBulkUpdate,
  addNewTagRequested,
  addTagToMediaOutletsRequested,
  addedNewTag,
  allTagsReceived,
  deleteTagsRequested,
  filterTags,
  getAllMediaOutletsTagsRequested,
  removeTagFromMediaOutletsInStore,
  removeTagFromMediaOutletsRequested,
  tagsDeleted,
  addedTagToMediaOutletsSearchResults,
  removedTagsFromMediaOutletsSearchResults,
  mediaOutletsUpdated,
  mediaOutletPanelDataReceived,
  mediaOutletPanelDataRequested,
  removeColumnFromMediaOutletInTable,
  addMediaResortToMediaOutletInTable,
  undoAddMediaResortToMediaOutletInTable,
  removeMediaResortsFromMediaOutletInTable,
  addContactToMediaOutletInTable,
  removeContactFromMediaOutletInTable,
  removeMediaResortsInTable,
  addMediumCategoryRequested,
  mediumCategoryAdded,
  mediumCategoryRemoved,
  removeMediumCategoryRequested,
  createMediaResortRequested,
  mediaResortCreated,
  removedMediaResorts
} = mediaOutletsSlice.actions;

export default mediaOutletsSlice.reducer;
