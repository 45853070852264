import * as Sentry from '@sentry/react';
import { isTextInputValid, maximumContactLastNameLength, regAtLeastOneCharacter, useAppDispatch, useAppSelector } from 'app/common';
import { useForm } from 'react-hook-form';
import {
  ContactInfo,
  selectContactInfoToAdd,
  CreateContactFromCompanySteps,
  closeCreateContactWizard,
  goToPreviousContactStep,
  goToNextContactStep,
  CreateNewContactFooter,
  setContactInfoToAdd
} from 'app/pages/my-audience/wizard-create-company';
import { ControlledInput } from 'app/pages/my-audience/common';
import { FormattedMessage, useIntl } from 'app/i18n';

export const CreateNewContact = () => {
  const contactInfo = useAppSelector<ContactInfo>(selectContactInfoToAdd);
  const { formatMessage } = useIntl();
  const { handleSubmit, control, watch } = useForm<ContactInfo>({
    defaultValues: {
      title: contactInfo.title,
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });
  const dispatch = useAppDispatch();

  const handleCancelButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousContactStep());
  };

  const handleNextButtonClick = () => {
    submitData();
    dispatch(goToNextContactStep());
  };

  const submitData = () => {
    handleSubmit(
      (info: ContactInfo) => {
        dispatch(setContactInfoToAdd(info));
      },
      (error) => {
        Sentry.setExtra('Path', CreateNewContact.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleFieldsValidation = () => {
    return isTextInputValid({ inputValue: watch('lastName'), maxInputLength: maximumContactLastNameLength });
  };

  const areGeneralInfoValid = handleFieldsValidation();

  return (
    <>
      <CreateContactFromCompanySteps onStepClick={submitData} isStepValid={areGeneralInfoValid} />
      <div className="wizard-panel-form-wrap">
        <div className="wizard-panel-intro">
          <h5 className="wizard-panel-title">
            <FormattedMessage id={'add-menu-create-company.contact-title'} />
          </h5>
        </div>
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.contact-title-label' })} control={control} name="title" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-company.contact-first-name-label' })} control={control} name="firstName" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'add-menu-create-company.contact-last-name-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="lastName"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage(
                    { id: 'error-messages.not-valid-name' },
                    { entity: formatMessage({ id: 'add-menu-create-company.contact-last-name-label' }) }
                  )
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumContactLastNameLength }),
                  value: maximumContactLastNameLength
                },
                required: formatMessage(
                  { id: 'error-messages.required-name' },
                  { entity: formatMessage({ id: 'add-menu-create-company.contact-last-name-label' }) }
                )
              }}
            />
          </div>
        </div>
      </div>
      <CreateNewContactFooter
        onCancelButtonClick={handleCancelButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onNextButtonClick={handleNextButtonClick}
        isNextButtonDisabled={!areGeneralInfoValid}
      />
    </>
  );
};
