import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { Alert, Button } from '@fluentui/react-northstar';
import { hideUndoAlert, selectUndoAlertMessage, UndoQueue } from 'app/common';

interface UndoAlertProps {
  isUndoAlertVisible: boolean;
  undoQueue: UndoQueue;
  buttonText?: string;
}

export const UndoAlert: React.FC<UndoAlertProps> = ({ isUndoAlertVisible, undoQueue, buttonText = 'Undo' }) => {
  const dispatch = useAppDispatch();
  const alertMessage = useAppSelector<string>(selectUndoAlertMessage);

  const onUndoClick = () => {
    undoQueue.cancelAndRevertLatestAction();
    dispatch(hideUndoAlert());
  };

  const onDismissAlert = () => {
    undoQueue.executeAllPendingActions();
    dispatch(hideUndoAlert());
  };

  return (
    <div className="alert-dialog">
      <Alert
        content={
          <>
            <span className="alert-dialog-content">{alertMessage}</span>
            <Button content={buttonText} onClick={onUndoClick} className="alert-dialog-button undo-alert" />
          </>
        }
        variables={{ urgent: true }}
        dismissible
        visible={isUndoAlertVisible}
        onVisibleChange={onDismissAlert}
      />
    </div>
  );
};
