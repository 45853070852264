import { useRef } from 'react';
import { Icon } from '@fluentui/react';
import { Button, Checkbox } from '@fluentui/react-northstar';
import { PillData, useInfiniteScroll } from 'app/common';
import { useIntl } from 'app/i18n';

interface DropdownListProps {
  entityName: string;
  dropdownValues: PillData[];
  selectedValues: PillData[];
  showDeleteButton: boolean;
  onSelectedCheckboxChange: (isChecked: boolean, value: PillData) => void;
  onDelete: (value: string) => void;
  onLoadMoreCallback: () => void;
  isValid?: boolean;
}

export const DropdownList = ({
  dropdownValues,
  selectedValues,
  entityName,
  showDeleteButton,
  onSelectedCheckboxChange,
  onDelete,
  onLoadMoreCallback,
  isValid = true
}: DropdownListProps) => {
  const { formatMessage } = useIntl();
  const infiniteLoadRef = useRef(null);
  const numberOfElements = dropdownValues.length;

  const entitiesIds = dropdownValues.map((pill) => pill.id);

  useInfiniteScroll({ observerTarget: infiniteLoadRef, onLoadMoreCallback, entitiesIds });

  return (
    <div className="tags-content">
      <div className="tags-content-list">
        {numberOfElements ? (
          dropdownValues.map((data, index) => {
            const shouldAttachRefToTheElement = numberOfElements - 1 === index;
            const loadingProps = shouldAttachRefToTheElement ? { ref: infiniteLoadRef } : {};

            return (
              <div className="tags-checkbox" key={data.id} title={data.value} {...loadingProps}>
                <Checkbox
                  onChange={(_, props) => onSelectedCheckboxChange(props.checked, data)}
                  label={data.value}
                  checked={selectedValues?.map((v) => v.value).includes(data.value)}
                  disabled={!data.canBeUpdated}
                />
                {showDeleteButton && data.canBeUpdated && <Button onClick={() => onDelete(data.id)} iconOnly icon={<Icon iconName="Delete" />} text />}
              </div>
            );
          })
        ) : (
          <>
            <>
              <div style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'pills.popup.no-results-text' })}</div>
            </>
            {isValid && (
              <>
                <div>{formatMessage({ id: 'pills.popup.no-results-description' }, { entity: entityName })}</div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
