import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { DateTime } from 'luxon';
import { TableHeader, User, selectSignedInUser, useAppDispatch, useAppSelector, getSendingStatusLocalizationKey, selectIsOnMobile } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import {
  getSelectedSendingOverviewRequested,
  getSelectedSendingRequested,
  openProfilePanel,
  pageOfRecipientsRequested,
  resetAudienceTable,
  setRecipientsQueryParams
} from 'app/pages/my-activities/sending-wizard';
import {
  EmailingTableRow,
  nextPageOfSendingsRequested,
  selectIsLoadingSendings,
  selectSendings,
  selectSendingsTableHeader,
  selectTotalNumberOfSendings,
  Sending,
  SendingsTable,
  SendingStatus,
  setSendingsTableHeader,
  updateSendingRequested,
  SendingHoverActions,
  openRemoveSendingsDialog,
  duplicateSendingRequested
} from 'app/pages/my-activities/sendings';
import { Permission, useAuthContext } from 'auth';

export const SendingsTableWrap: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();

  const canManageEmailings = hasPermission(Permission.ManageEmailings);

  const locale = useAppSelector<string>(selectLocale);
  const header = useAppSelector<TableHeader[]>(selectSendingsTableHeader);
  const isLoadingSendings = useAppSelector<boolean>(selectIsLoadingSendings);
  const totalCountOfSendings = useAppSelector<number>(selectTotalNumberOfSendings);
  const sendingsStore = useAppSelector<Sending[]>(selectSendings);
  const signedInUser = useAppSelector<User>(selectSignedInUser);
  const isOnMobile = useAppSelector<boolean>(selectIsOnMobile);

  const [tableRows, setTableRows] = useState([]);

  const createSendingsTableData = useCallback(
    (sending: Sending) => {
      const createdAt = DateTime.fromJSDate(sending.createdAt).setLocale(locale);
      const lastModifiedAt = DateTime.fromJSDate(sending.lastModifiedAt).setLocale(locale);

      const signedInUserCreatedEmailing = signedInUser.id === sending.createdBy?.id;
      const canUpdateEmailing = canManageEmailings && signedInUserCreatedEmailing;

      return new EmailingTableRow(
        sending.id,
        formatMessage({ id: getSendingStatusLocalizationKey(sending.status) }),
        sending.status,
        sending.isFavourite,
        <ProfilePicture name={sending.title} imageUrl={sending.imageAsBase64} size="medium" />,
        (
          <>
            {isOnMobile ? (
              <span className="sendingStatus-title">
                {sending.isFavourite && <Icon className="row-icon-star" iconName="FavoriteStarFill" />}
                <span className="sendingStatus-title">{sending.title}</span>
              </span>
            ) : (
              <div className="sendingStatus">
                {sending.isFavourite && <Icon className="row-icon-star" iconName="FavoriteStarFill" />}
                <span className="sendingStatus-title">{sending.title}</span>
                <SendingHoverActions
                  sendingId={sending.id}
                  sendingStatus={sending.status}
                  isFavorite={sending.isFavourite}
                  onDeleteSending={handleDeleteSending}
                  onDuplicateSending={handleDuplicateSending}
                  onEditSending={handleEditSending}
                  onSetAsFavourite={handleSetAsFavourite}
                  disabled={!canUpdateEmailing}
                />
              </div>
            )}
          </>
        ),
        formatMessage(
          { id: 'emailings-table.date-at-time' },
          { date: createdAt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY), time: createdAt.toLocaleString(DateTime.TIME_WITH_SECONDS) }
        ),
        formatMessage(
          { id: 'emailings-table.date-at-time' },
          { date: lastModifiedAt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY), time: lastModifiedAt.toLocaleString(DateTime.TIME_WITH_SECONDS) }
        ),
        (
          <>
            <ProfilePicture
              name={`${sending.lastModifiedByUser.firstName} ${sending.lastModifiedByUser.lastName}`}
              imageUrl={sending.lastModifiedByUser?.profilePictureUrl}
              size="smallest"
            />
            {`${sending.lastModifiedByUser?.firstName} ${sending.lastModifiedByUser?.lastName}`}
          </>
        ),
        sending.numberOfRecipients?.toString() ?? '0',
        sending.blacklist.name,
        sending.subject
      );
    },
    [sendingsStore, locale]
  );

  useEffect(() => {
    setTableRows(sendingsStore.map((sending) => createSendingsTableData(sending)));
  }, [sendingsStore, locale]);

  const onRowClick = (sendingId: string) => {
    dispatch(resetAudienceTable());
    dispatch(getSelectedSendingRequested(sendingId));
    dispatch(getSelectedSendingOverviewRequested());
    dispatch(setRecipientsQueryParams({ pageNumber: 1, searchText: '' }));
    dispatch(pageOfRecipientsRequested());
    dispatch(openProfilePanel());

    const selectedSending = sendingsStore.find((sending) => sending.id === sendingId);
    if (selectedSending.status === SendingStatus.Draft || selectedSending.status === SendingStatus.WaitingForDistribution) {
      navigate(`/my-activities/e-mailings/emailing-info/${sendingId}/audience`);
    } else {
      navigate(`/my-activities/e-mailings/emailing-info/${sendingId}/report`);
    }
  };

  const handleSetAsFavourite = (sendingId: string) => {
    const sending = sendingsStore.find((s) => s.id === sendingId);
    const updatedSending = { ...sending, isFavourite: !sending.isFavourite };
    dispatch(updateSendingRequested(updatedSending));
  };

  const handleDeleteSending = () => {
    dispatch(openRemoveSendingsDialog());
  };

  const handleDuplicateSending = (sendingId: string) => {
    dispatch(duplicateSendingRequested({ sendingId: sendingId, localizedTitlePart: formatMessage({ id: 'labels.copy' }) }));
  };

  const handleEditSending = (sendingId: string) => {
    dispatch(resetAudienceTable());
    dispatch(getSelectedSendingRequested(sendingId));
    dispatch(pageOfRecipientsRequested());
    navigate(`/my-activities/e-mailings/emailing-wizard`);
  };

  const handleLoadMoreSendings = () => {
    dispatch(nextPageOfSendingsRequested());
  };

  const handleUpdateTableHeader = (updatedHeader: TableHeader[]) => {
    dispatch(setSendingsTableHeader(updatedHeader));
  };

  return (
    <SendingsTable
      tableRows={tableRows}
      handleLoadMore={handleLoadMoreSendings}
      header={header}
      isLoadingSendings={isLoadingSendings}
      onOpenProfile={onRowClick}
      onUpdateTableHeader={handleUpdateTableHeader}
      totalCountOfEntities={totalCountOfSendings}
      fixedColumnIndex={0}
    />
  );
};
