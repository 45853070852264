import { TableHeader } from 'app/common';

type MediaOutletsTableHeader = {
  nameLabel: string;
  categoryLabel: string;
  frequencyLabel: string;
  mediaTypeLabel: string;
  columnsLabel: string;
  tagsLabel: string;
  contactsLabel: string;
  languagesLabel: string;
  addressLabel: string;
  postalCodeLabel: string;
  cityLabel: string;
  faxLabel: string;
  websiteLabel: string;
  poBoxLabel: string;
  sortAscending: string;
  sortDescendingAriaLabel: string;
};

export const buildLocalizedSearchResultsColumns = ({
  nameLabel,
  categoryLabel,
  frequencyLabel,
  mediaTypeLabel,
  columnsLabel,
  tagsLabel,
  contactsLabel,
  languagesLabel,
  addressLabel,
  postalCodeLabel,
  cityLabel,
  faxLabel,
  websiteLabel,
  poBoxLabel,
  sortAscending,
  sortDescendingAriaLabel
}: MediaOutletsTableHeader): TableHeader[] => {
  return [
    {
      key: 'column1',
      name: nameLabel,
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column2',
      name: categoryLabel,
      fieldName: 'category',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column3',
      name: frequencyLabel,
      fieldName: 'frequency',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column4',
      name: mediaTypeLabel,
      fieldName: 'mediaType',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column5',
      name: columnsLabel,
      fieldName: 'columns',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column7',
      name: tagsLabel,
      fieldName: 'tags',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column8',
      name: contactsLabel,
      fieldName: 'contacts',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column9',
      name: languagesLabel,
      fieldName: 'languages',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column10',
      name: addressLabel,
      fieldName: 'address',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column11',
      name: postalCodeLabel,
      fieldName: 'postalCode',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column12',
      name: cityLabel,
      fieldName: 'city',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column13',
      name: faxLabel,
      fieldName: 'fax',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column14',
      name: websiteLabel,
      fieldName: 'website',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    },
    {
      key: 'column15',
      name: poBoxLabel,
      fieldName: 'poBox',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: sortAscending,
      sortDescendingAriaLabel: sortDescendingAriaLabel
    }
  ];
};
