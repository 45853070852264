import { useAppDispatch, useAppSelector } from 'app/common';
import {
  resetSelectedSending,
  Editor,
  goToNextStep,
  goToPreviousStep,
  LoadTemplates,
  MenuItems as EditorMenu,
  SaveTemplateDialog,
  selectEmailBodyInEditor,
  selectSelectedSending,
  SendingWizardFooter,
  SendingWizardSteps,
  templateAsImageRequested,
  updateSendingRequested,
  selectIsSavingInProgress
} from 'app/pages/my-activities/sending-wizard';
import { EmailBody, Sending } from 'app/pages/my-activities/sendings';

export const Email = () => {
  const dispatch = useAppDispatch();
  const selectedSending = useAppSelector<Sending>(selectSelectedSending);
  const emailBody = useAppSelector<EmailBody>(selectEmailBodyInEditor);
  const isSavingInProgress = useAppSelector<boolean>(selectIsSavingInProgress);

  const handleSaveAndCloseButtonClick = () => {
    dispatch(templateAsImageRequested());
    handleUpdateSending();
    dispatch(resetSelectedSending());
  };

  const handleUpdateSending = () => {
    const updatedSending: Sending = { ...selectedSending, jsonBody: emailBody.jsonBody, htmlBody: emailBody.htmlBody };
    dispatch(updateSendingRequested(updatedSending));
  };

  const handleNextButtonClick = () => {
    dispatch(templateAsImageRequested());
    handleUpdateSending();
    dispatch(goToNextStep());
  };

  const handleBackButtonClick = () => {
    dispatch(templateAsImageRequested());
    handleUpdateSending();
    dispatch(goToPreviousStep());
  };

  return (
    <>
      <SendingWizardSteps areFieldsValid={!isSavingInProgress} submitForm={handleUpdateSending} />
      <div className="wizard-panel-form-wrap wizard-panel-form-wrap--sendings">
        <EditorMenu />
        <Editor />
        <SendingWizardFooter
          onSaveAndCloseButtonClick={handleSaveAndCloseButtonClick}
          onNextButtonClick={handleNextButtonClick}
          onBackButtonClick={handleBackButtonClick}
          isNextButtonDisabled={isSavingInProgress}
          isBackButtonDisabled={isSavingInProgress}
          isSaveAndCloseButtonDisabled={isSavingInProgress}
        />
      </div>
      <SaveTemplateDialog />
      <LoadTemplates />
    </>
  );
};
