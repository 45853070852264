import 'chart.js/auto';
import { useAppSelector, DoughnutWrapper } from 'app/common';
import { selectAllSendingOverviews } from 'app/pages/my-audience/lists-profile';
import { useIntl } from 'app/i18n';
import { SendingOverview } from 'app/common/graphql/generated/graphql-gateway';
import { SendingStatus } from 'app/pages/my-activities/sendings';

const getOverviewStats = (sendingOverviews: SendingOverview[]) => {
  let clickedSendings = 0;
  let openedSendings = 0;
  let numberOfSentSendings = 0;

  sendingOverviews.forEach((overview) => {
    if (overview.totalNumberOfClickedLinks > 0) {
      clickedSendings += 1;
    }
    if (overview.totalNumberOfOpenings > 0) {
      openedSendings += 1;
    }
    if (overview.sendingStatus === SendingStatus.SuccessfulDistribution) {
      numberOfSentSendings += 1;
    }
  });

  return { clickedSendings, openedSendings, numberOfSentSendings };
};

export const ListOverviewCharts = () => {
  const { formatMessage } = useIntl();
  const overviews = useAppSelector<SendingOverview[]>(selectAllSendingOverviews);

  const { clickedSendings, openedSendings, numberOfSentSendings } = getOverviewStats(overviews);
  const opensPercentage = numberOfSentSendings && Math.round((100 / numberOfSentSendings) * openedSendings);
  const clicksPercentage = numberOfSentSendings && Math.round((100 / numberOfSentSendings) * clickedSendings);

  const totalNumberOfSendings = overviews.length;
  const sentPercentage = numberOfSentSendings && Math.round((100 / totalNumberOfSendings) * (totalNumberOfSendings - numberOfSentSendings));

  return (
    <div className="list-overview-info chart">
      <div className="list-overview-info-column chart-column">
        <div className="list-overview-info-chart">
          <DoughnutWrapper percentage={opensPercentage} />
          <span className="list-overview-info-chart-number">{opensPercentage}%</span>
        </div>
        <div className="list-overview-info-chart-content">
          <span className="list-overview-chart-number">{openedSendings}</span>
          <span className="list-overview-info-chart-label">{formatMessage({ id: 'list-profile-sendings.opens-rate' })}</span>
        </div>
      </div>
      <div className="list-overview-info-column chart-column">
        <div className="list-overview-info-chart">
          <DoughnutWrapper percentage={clicksPercentage} />
          <span className="list-overview-info-chart-number">{clicksPercentage}%</span>
        </div>
        <div className="list-overview-info-chart-content">
          <span className="list-overview-chart-number">{clickedSendings}</span>
          <span className="list-overview-info-chart-label">{formatMessage({ id: 'list-profile-sendings.clicks-rate' })}</span>
        </div>
      </div>
      <div className="list-overview-info-column chart-column">
        <div className="list-overview-info-chart">
          <DoughnutWrapper percentage={sentPercentage} />
          <span className="list-overview-info-chart-number">{sentPercentage}%</span>
        </div>
        <div className="list-overview-info-chart-content">
          <span className="list-overview-chart-number">{numberOfSentSendings}</span>
          <span className="list-overview-info-chart-label">{formatMessage({ id: 'list-profile-sendings.total-sent' })}</span>
        </div>
      </div>
    </div>
  );
};
