import { Icon } from '@fluentui/react';
import { Button, CloseIcon, Input, MenuButton, SearchIcon, Tooltip } from '@fluentui/react-northstar';
import { debounceTimeInMilliseconds, selectIsOnDesktop, useAppDispatch, useAppSelector } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import {
  firstPageOfWizardContactsRequested,
  openAddContactFromCompanyWizard,
  openAddContactFromListWizard,
  openAddContactsWizard,
  openRemoveContactDialog,
  pageOfListContactsRequested,
  selectSelectedContactsIds,
  selectSelectedContactsLength,
  setContactsIdsToEdit,
  setListContactsQueryParams
} from 'app/pages/my-audience/lists-profile';
import { useDebouncedCallback } from 'use-debounce';

interface ContactsTableToolbarProps {
  disabled?: boolean;
}

export const ContactsTableToolbar = ({ disabled = false }: ContactsTableToolbarProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedContactsIds = useAppSelector<string[]>(selectSelectedContactsIds);
  const selectedContactsLength = useAppSelector<number>(selectSelectedContactsLength);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const handleInputChange = useDebouncedCallback((searchText = '') => {
    dispatch(setListContactsQueryParams({ pageNumber: 1, searchText: searchText }));
    dispatch(pageOfListContactsRequested());
  }, debounceTimeInMilliseconds);

  const isActionDisabled = !selectedContactsLength || disabled;

  const menuItems = [
    {
      key: 'menu-item-default-contact',
      content: formatMessage({ id: 'list-profile-contacts-table-toolbar.contacts' }),
      onClick: () => {
        dispatch(openAddContactsWizard());
        dispatch(firstPageOfWizardContactsRequested(''));
      }
    },
    {
      key: 'menu-item-contact-from-company',
      content: formatMessage({ id: 'list-profile-contacts-table-toolbar.contacts-from-your-company' }),
      onClick: () => {
        dispatch(openAddContactFromCompanyWizard());
      }
    },
    {
      key: 'menu-item-contact-from-list',
      content: formatMessage({ id: 'list-profile-contacts-table-toolbar.contacts-from-other-lists' }),
      onClick: () => {
        dispatch(openAddContactFromListWizard());
      }
    }
  ];

  return (
    <div className="table-toolbar">
      <div className="table-toolbar-column">
        {isOnDesktop && (
          <MenuButton
            trigger={<Button disabled={disabled} content={formatMessage({ id: 'buttons.add' })} text icon={<Icon iconName="Add" />} />}
            menu={menuItems}
            on="click"
          />
        )}
        <div className="table-toolbar-content">
          {isOnDesktop && (
            <Button
              disabled={isActionDisabled}
              content={formatMessage({ id: 'buttons.edit' })}
              text
              icon={<Icon iconName="Edit" />}
              className="table-toolbar-action-button"
              onClick={() => dispatch(setContactsIdsToEdit(selectedContactsIds))}
            />
          )}

          <Button
            disabled={isActionDisabled}
            content={formatMessage({ id: 'buttons.remove' })}
            text
            icon={<CloseIcon />}
            className="table-toolbar-action-button"
            onClick={() => dispatch(openRemoveContactDialog())}
          />
        </div>
      </div>
      <div className="table-toolbar-column">
        {selectedContactsLength > 0 && (
          <Tooltip
            subtle={false}
            pointing
            position="below"
            content={<FormattedMessage id={'list-profile-contacts-table-toolbar.contacts-counter'} values={{ counter: selectedContactsLength }} />}
            trigger={
              <span className="table-toolbar-text">
                {<FormattedMessage id={'list-profile-contacts-table-toolbar.contacts-counter'} values={{ counter: selectedContactsLength }} />}
              </span>
            }
          />
        )}
        <div className="table-toolbar-input">
          <Input
            placeholder={formatMessage({ id: 'buttons.search' })}
            autoComplete="off"
            type="text"
            icon={<SearchIcon />}
            clearable
            onChange={(_, data) => handleInputChange(data.value)}
          />
        </div>
      </div>
    </div>
  );
};
