import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { App } from 'app/common/components/app/App';
import { gatewayClient } from 'app/common';
import { persistor, store } from 'app/redux/store';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import 'sass/main.scss';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});

initializeIcons();

ReactDOM.render(
  <PersistGate persistor={persistor}>
    <ApolloProvider client={gatewayClient}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  </PersistGate>,
  document.getElementById('root')
);
