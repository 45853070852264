import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/redux/store';
import {
  MediaOutletAddressInformation,
  ContactInfo,
  ContactsTableData,
  MediaOutletGeneralInformation,
  createContactsTableData,
  CreateMediaOutletWizardStep,
  createMediaOutlet,
  CreateContactWizardStep,
  setContact
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { Collaboration, CollaborationWithContactInfo, Contact } from 'app/pages/my-audience/contacts';
import { MediaOutlet } from 'app/pages/my-audience/media-outlets';
import { EntitiesPerPage } from 'app/common';
import { mapEntitiesPerPageToList } from 'app/pages/my-audience/common';

export interface WizardCreateMediaOutletState {
  contactInfoToAdd: ContactInfo;
  createContactCurrentStep: CreateContactWizardStep;
  isCreateContactWizardOpened: boolean;
  isCreateMediaOutletWizardOpened: boolean;
  currentStep: CreateMediaOutletWizardStep;
  generalInfoToAdd: MediaOutletGeneralInformation;
  isGeneralInfoValid: boolean;
  addressInfoToAdd: MediaOutletAddressInformation;
  searchContactsText: string;
  isLoadingContacts: boolean;
  totalCountOfContacts: number;
  pageNumber: number;
  pageSize: number;
  contactSuggestions: Contact[];
  selectedCollaborationDetailsPerPage: EntitiesPerPage<CollaborationWithContactInfo>;
  collaborationDetails: CollaborationWithContactInfo[];
  isCreateMediaOutletFinishButtonDisabled: boolean;
  isCreateContactFinishButtonDisabled: boolean;
  isLoadingAddingMediaOutlet: boolean;
}

const initialState: WizardCreateMediaOutletState = {
  contactSuggestions: [],
  isLoadingContacts: false,
  pageNumber: 1,
  pageSize: 10,
  totalCountOfContacts: 0,
  searchContactsText: '',
  isCreateMediaOutletWizardOpened: false,
  isCreateContactWizardOpened: false,
  contactInfoToAdd: {} as ContactInfo,
  createContactCurrentStep: CreateContactWizardStep.GeneralInformation,
  currentStep: CreateMediaOutletWizardStep.GeneralInformation,
  generalInfoToAdd: {} as MediaOutletGeneralInformation,
  isGeneralInfoValid: false,
  addressInfoToAdd: {} as MediaOutletAddressInformation,
  selectedCollaborationDetailsPerPage: {} as EntitiesPerPage<CollaborationWithContactInfo>,
  collaborationDetails: [],
  isCreateMediaOutletFinishButtonDisabled: false,
  isCreateContactFinishButtonDisabled: false,
  isLoadingAddingMediaOutlet: false
};

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.pageNumber],
  (pageNumber: number) => pageNumber
);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.wizardCreateMediaOutlet.pageSize], (pageSize: number) => pageSize);

export type IsLoadingContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingContacts: IsLoadingContactsSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.isLoadingContacts],
  (isLoadingContacts: boolean) => isLoadingContacts
);

export type TotalCountOfContactsSelector = (state: RootState) => number;
export const selectTotalCountOfContacts: TotalCountOfContactsSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.totalCountOfContacts],
  (totalCountOfContacts: number) => totalCountOfContacts
);

export type SelectedCollaborationsPerPageSelector = (state: RootState) => EntitiesPerPage<CollaborationWithContactInfo>;
export const selectSelectedCollaborationsToAddPerPage: SelectedCollaborationsPerPageSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.selectedCollaborationDetailsPerPage],
  (selectedCollaborationDetailsPerPage: EntitiesPerPage<CollaborationWithContactInfo>) => selectedCollaborationDetailsPerPage
);

export type CollaborationsDetailsSelector = (state: RootState) => CollaborationWithContactInfo[];
export const selectCollaborationsDetails: CollaborationsDetailsSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.collaborationDetails],
  (collaborationDetails: CollaborationWithContactInfo[]) => collaborationDetails
);

export type IsCreateMediaOutletFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletFinishButtonDisabled: IsCreateMediaOutletFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.isCreateMediaOutletFinishButtonDisabled],
  (isCreateMediaOutletFinishButtonDisabled: boolean) => isCreateMediaOutletFinishButtonDisabled
);

export type IsCreateContactFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsCreateContactFinishButtonDisabled: IsCreateContactFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.isCreateContactFinishButtonDisabled],
  (isCreateContactFinishButtonDisabled: boolean) => isCreateContactFinishButtonDisabled
);

export type MediaOutletToAddSelector = (state: RootState) => MediaOutlet;
export const selectMediaOutletToAdd: MediaOutletToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.generalInfoToAdd, (state: RootState) => state.wizardCreateMediaOutlet.addressInfoToAdd],
  (generalInfo: MediaOutletGeneralInformation, addressInfo: MediaOutletAddressInformation) => createMediaOutlet(generalInfo, addressInfo)
);

export type GeneralInfoValidSelector = (state: RootState) => boolean;
export const selectIsGeneralInfoValid: GeneralInfoValidSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.isGeneralInfoValid],
  (isGeneralInfoValid: boolean) => isGeneralInfoValid
);

export type AddressInfoToAddSelector = (state: RootState) => MediaOutletAddressInformation;
export const selectAddressInfoToAdd: AddressInfoToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.addressInfoToAdd],
  (addressInfoToAdd: MediaOutletAddressInformation) => addressInfoToAdd
);

export type GeneralInfoToAddSelector = (state: RootState) => MediaOutletGeneralInformation;
export const selectGeneralInfoToAdd: GeneralInfoToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.generalInfoToAdd],
  (generalInfoToAdd: MediaOutletGeneralInformation) => generalInfoToAdd
);

export type ContactToAddSelector = (state: RootState) => Contact;
export const selectContactToAdd: ContactToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.contactInfoToAdd],
  (contactInfoToAdd: ContactInfo) => setContact(contactInfoToAdd)
);

export type ContactInfoToAddSelector = (state: RootState) => ContactInfo;
export const selectContactInfoToAdd: ContactInfoToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.contactInfoToAdd],
  (contactInfoToAdd: ContactInfo) => contactInfoToAdd
);

export type ContactsSuggestionsSelector = (state: RootState) => Contact[];
export const selectContactsSuggestions: ContactsSuggestionsSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.contactSuggestions],
  (contactSuggestions: Contact[]) => contactSuggestions
);

export type ContactsTableDataSelector = (state: RootState) => ContactsTableData[];
export const selectContactsTableData: ContactsTableDataSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.contactSuggestions],
  (contactSuggestions: Contact[]) => createContactsTableData(contactSuggestions)
);

export type ContactSearchTextSelector = (state: RootState) => string;
export const selectContactSearchText: ContactSearchTextSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.searchContactsText],
  (searchContactsText: string) => searchContactsText
);

export type IsCreateMediaOutletWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateMediaOutletWizardOpened: IsCreateMediaOutletWizardOpenedSelector = (state: RootState) =>
  state.wizardCreateMediaOutlet.isCreateMediaOutletWizardOpened;

export type IsCreateContactWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateContactWizardOpened: IsCreateContactWizardOpenedSelector = (state: RootState) =>
  state.wizardCreateMediaOutlet.isCreateContactWizardOpened;

export type CreateMediaOutletCurrentStepSelector = (state: RootState) => CreateMediaOutletWizardStep;
export const selectCreateMediaOutletCurrentStep: CreateMediaOutletCurrentStepSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.currentStep],
  (currentStep: CreateMediaOutletWizardStep) => currentStep
);

export type CreateContactCurrentStepSelector = (state: RootState) => CreateContactWizardStep;
export const selectCreateContactCurrentStep: CreateContactCurrentStepSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.createContactCurrentStep],
  (createContactCurrentStep: CreateContactWizardStep) => createContactCurrentStep
);

export type IsLoadingAddingMediaOutletSelector = (state: RootState) => boolean;
export const selectIsLoadingAddingMediaOutlet: IsLoadingAddingMediaOutletSelector = createSelector(
  [(state: RootState) => state.wizardCreateMediaOutlet.isLoadingAddingMediaOutlet],
  (isLoadingAddingMediaOutlet: boolean) => isLoadingAddingMediaOutlet
);

const wizardCreateMediaOutletSlice = createSlice({
  name: 'wizardCreateMediaOutlet',
  initialState,
  reducers: {
    pageOfContactsRequested: (state) => {
      state.contactSuggestions = [];
      state.isLoadingContacts = true;
      state.totalCountOfContacts = 0;
    },
    setSearchContactsText: (state, action: PayloadAction<string>) => {
      state.searchContactsText = action.payload;
    },
    setIsGeneralInfoValid: (state, action: PayloadAction<boolean>) => {
      state.isGeneralInfoValid = action.payload;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      state.pageNumber = 1;
    },
    pageOfContactsReceived: (state, action: PayloadAction<{ contacts: Contact[]; totalCountOfContacts: number }>) => {
      state.contactSuggestions = action.payload.contacts;
      state.totalCountOfContacts = action.payload.totalCountOfContacts;
      state.isLoadingContacts = false;
    },
    openCreateMediaOutletWizard: (state) => {
      state.isCreateMediaOutletWizardOpened = true;
    },
    closeCreateMediaOutletWizard: (state) => {
      state.isCreateMediaOutletWizardOpened = false;
      state.currentStep = 1;
      state.isGeneralInfoValid = false;
      state.generalInfoToAdd = {} as MediaOutletGeneralInformation;
      state.addressInfoToAdd = {} as MediaOutletAddressInformation;
      state.selectedCollaborationDetailsPerPage = {} as EntitiesPerPage<CollaborationWithContactInfo>;
      state.collaborationDetails = [];
      state.searchContactsText = '';
      state.contactSuggestions = [];
      state.isCreateMediaOutletFinishButtonDisabled = false;
    },
    addContactRequested: (state) => {
      state.isCreateContactFinishButtonDisabled = true;
    },
    addedContact: (state, action: PayloadAction<Contact>) => {
      state.contactSuggestions = [...state.contactSuggestions, action.payload];
      state.isCreateContactFinishButtonDisabled = false;
    },
    addAddressInformation: (state, action: PayloadAction<MediaOutletAddressInformation>) => {
      state.addressInfoToAdd = action.payload;
    },
    addGeneralMediaOutletInformation: (state, action: PayloadAction<MediaOutletGeneralInformation>) => {
      state.generalInfoToAdd = action.payload;
    },
    openCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = true;
      state.isCreateContactFinishButtonDisabled = false;
    },
    closeCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = false;
      state.contactInfoToAdd = {} as ContactInfo;
      state.createContactCurrentStep = CreateContactWizardStep.GeneralInformation;
    },
    setContactInfoToAdd: (state, action: PayloadAction<ContactInfo>) => {
      state.contactInfoToAdd = action.payload;
    },
    addMediaOutletRequested: (state) => {
      state.isCreateMediaOutletFinishButtonDisabled = true;
      state.isLoadingAddingMediaOutlet = true;
    },
    goToStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      state.searchContactsText = '';
      state.pageNumber = 1;
    },
    goToNextStep: (state) => {
      state.currentStep = state.currentStep + 1;
      state.searchContactsText = '';
      state.pageNumber = 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep = state.currentStep - 1;
      state.searchContactsText = '';
    },
    goToCreateContactNextStep: (state) => {
      state.createContactCurrentStep = state.createContactCurrentStep + 1;
    },
    goToCreateContactPreviousStep: (state) => {
      state.createContactCurrentStep = state.createContactCurrentStep - 1;
    },
    goToCreateContactStep: (state, action: PayloadAction<number>) => {
      state.createContactCurrentStep = action.payload;
    },
    setCollaborationsToAddPerPage: (state, action: PayloadAction<CollaborationWithContactInfo[]>) => {
      if (state.isLoadingContacts) return;
      state.selectedCollaborationDetailsPerPage[state.pageNumber] = action.payload;
      state.collaborationDetails = mapEntitiesPerPageToList<CollaborationWithContactInfo>(state.selectedCollaborationDetailsPerPage);
    },
    updateCollaboration: (state, action: PayloadAction<{ collaboration: Collaboration; contactId: string; differentAddress: boolean }>) => {
      state.collaborationDetails = state.collaborationDetails.map((collaborationDetails) => {
        if (collaborationDetails.contactId === action.payload.contactId) {
          return { ...collaborationDetails, collaboration: action.payload.collaboration };
        }

        return collaborationDetails;
      });
    },
    removeCollaboration: (state, action: PayloadAction<string>) => {
      state.collaborationDetails = state.collaborationDetails.filter((collaborationDetails) => collaborationDetails.contactId !== action.payload);
    },
    setIsLoadingAddingMediaOutlet: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAddingMediaOutlet = action.payload;
    },
    resetCollaborationsTable: (state) => {
      state.selectedCollaborationDetailsPerPage = {} as EntitiesPerPage<CollaborationWithContactInfo>;
      state.collaborationDetails = [];
      state.pageNumber = 1;
    },
    setIsCreateMediaOutletFinishButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isCreateMediaOutletFinishButtonDisabled = action.payload;
    }
  }
});

export const {
  pageOfContactsRequested,
  pageOfContactsReceived,
  setIsGeneralInfoValid,
  openCreateMediaOutletWizard,
  closeCreateMediaOutletWizard,
  addedContact,
  addContactRequested,
  addAddressInformation,
  openCreateContactWizard,
  closeCreateContactWizard,
  setContactInfoToAdd,
  addGeneralMediaOutletInformation,
  addMediaOutletRequested,
  goToStep,
  goToNextStep,
  goToPreviousStep,
  updateCollaboration,
  removeCollaboration,
  goToCreateContactNextStep,
  goToCreateContactPreviousStep,
  goToCreateContactStep,
  setPageNumber,
  setPageSize,
  setSearchContactsText,
  setCollaborationsToAddPerPage,
  setIsLoadingAddingMediaOutlet,
  resetCollaborationsTable,
  setIsCreateMediaOutletFinishButtonDisabled
} = wizardCreateMediaOutletSlice.actions;

export default wizardCreateMediaOutletSlice.reducer;
