import { RootState } from 'app/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from 'app/pages/my-audience/companies';
import { Collaboration, Contact, CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';
import { AddressInfo, ContactInfo, CreateContactFromCompanyWizardStep, GeneralInfo } from 'app/pages/my-audience/wizard-create-company';
import { ContactsTableData, createContactsTableData, setContact } from 'app/pages/my-audience/wizard-create-media-outlet';
import { EntitiesPerPage } from 'app/common';
import { mapEntitiesPerPageToList } from 'app/pages/my-audience/common';

export interface WizardCreateCompanyState {
  isCreateCompanyWizardOpened: boolean;
  generalInfoToAdd: GeneralInfo;
  addressInfoToAdd: AddressInfo;
  contactInfoToAdd: ContactInfo;
  isCreateContactWizardOpened: boolean;
  contactSuggestions: Contact[];
  searchedContacts: Contact[];
  searchContactsText: string;
  contactToAdd: Contact;
  contactsToAdd: Contact[];
  contactAdded: Contact;
  collaborationToAdd: Collaboration;
  selectedContactId: string;
  companyToAdd: Company;
  selectedCollaborationDetailsPerPage: EntitiesPerPage<CollaborationWithContactInfo>;
  collaborationDetails: CollaborationWithContactInfo[];
  isFinishButtonDisabled: boolean;
  isCreateContactFinishButtonDisabled: boolean;
  currentStep: number;
  companiesContactWizardStep: CreateContactFromCompanyWizardStep;
  isLoadingContacts: boolean;
  totalCountOfContacts: number;
  pageNumber: number;
  pageSize: number;
  isLoadingAddingCompany: boolean;
}

const initialState: WizardCreateCompanyState = {
  isCreateCompanyWizardOpened: false,
  generalInfoToAdd: {} as GeneralInfo,
  addressInfoToAdd: {} as AddressInfo,
  contactInfoToAdd: {} as ContactInfo,
  isCreateContactWizardOpened: false,
  contactSuggestions: [],
  searchedContacts: [],
  searchContactsText: '',
  contactToAdd: {} as Contact,
  contactsToAdd: [],
  contactAdded: {} as Contact,
  collaborationToAdd: {} as Collaboration,
  selectedContactId: '',
  companyToAdd: {} as Company,
  selectedCollaborationDetailsPerPage: {} as EntitiesPerPage<CollaborationWithContactInfo>,
  collaborationDetails: [],
  isFinishButtonDisabled: false,
  isCreateContactFinishButtonDisabled: false,
  currentStep: 1,
  companiesContactWizardStep: 1,
  isLoadingContacts: false,
  totalCountOfContacts: 0,
  pageNumber: 1,
  pageSize: 10,
  isLoadingAddingCompany: false
};

export type IsCreateCompanyWizardOpenedSelector = (state: RootState) => boolean;
export const selectIsCreateCompanyWizardOpened: IsCreateCompanyWizardOpenedSelector = (state: RootState) =>
  state.wizardCreateCompany.isCreateCompanyWizardOpened;

export type GeneralInfoToAddSelector = (state: RootState) => GeneralInfo;
export const selectGeneralInfoToAdd: GeneralInfoToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.generalInfoToAdd],
  (generalInfoToAdd: GeneralInfo) => generalInfoToAdd
);

export type AddressInfoToAddSelector = (state: RootState) => AddressInfo;
export const selectAddressInfoToAdd: AddressInfoToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.addressInfoToAdd],
  (addressInfoToAdd: AddressInfo) => addressInfoToAdd
);

export type SearchedContactsSelector = (state: RootState) => Contact[];
export const selectSearchedContacts: SearchedContactsSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.searchedContacts],
  (searchedContacts: Contact[]) => searchedContacts
);

export type IsLoadingContactsSelector = (state: RootState) => boolean;
export const selectIsLoadingContacts: IsLoadingContactsSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.isLoadingContacts],
  (isLoadingContacts: boolean) => isLoadingContacts
);

export type TotalCountOfContactsSelector = (state: RootState) => number;
export const selectTotalCountOfContacts: TotalCountOfContactsSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.totalCountOfContacts],
  (totalCountOfContacts: number) => totalCountOfContacts
);

export type ContactInfoToAddSelector = (state: RootState) => ContactInfo;
export const selectContactInfoToAdd: ContactInfoToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.contactInfoToAdd],
  (contactInfoToAdd: ContactInfo) => contactInfoToAdd
);

export type IsCreateContactWizardOpenSelector = (state: RootState) => boolean;
export const selectIsCreateContactWizardOpen: IsCreateContactWizardOpenSelector = (state: RootState) => state.wizardCreateCompany.isCreateContactWizardOpened;

export type ContactsSuggestionsSelector = (state: RootState) => Contact[];
export const selectContactsSuggestions: ContactsSuggestionsSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.contactSuggestions],
  (contactSuggestions: Contact[]) => contactSuggestions
);

export type ContactsTableDataSelector = (state: RootState) => ContactsTableData[];
export const selectContactsTableData: ContactsTableDataSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.contactSuggestions],
  (contactSuggestions: Contact[]) => createContactsTableData(contactSuggestions)
);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.pageNumber],
  (pageNumber: number) => pageNumber
);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.wizardCreateCompany.pageSize], (pageSize: number) => pageSize);

export type ContactSearchTextSelector = (state: RootState) => string;
export const selectContactsSearchText: ContactSearchTextSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.searchContactsText],
  (searchContactsText: string) => searchContactsText
);

export type ContactToAddSelector = (state: RootState) => Contact;
export const selectContactToAdd: ContactToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.contactInfoToAdd],
  (contactInfoToAdd: ContactInfo) => setContact(contactInfoToAdd)
);

export type ContactAddedSelector = (state: RootState) => Contact;
export const selectContactAdded: ContactAddedSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.contactAdded],
  (contactAdded: Contact) => contactAdded
);

export type ContactsToAddSelector = (state: RootState) => Contact[];
export const selectContactsToAdd: ContactsToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.contactsToAdd],
  (contactsToAdd: Contact[]) => contactsToAdd
);

export type SelectedContactIdSelector = (state: RootState) => string;
export const selectSelectedContactId: SelectedContactIdSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.selectedContactId],
  (selectedContactId: string) => selectedContactId
);

export type SelectedCreateCompanyCurrentStep = (state: RootState) => number;
export const selectCreateCompanyCurrentStep: SelectedCreateCompanyCurrentStep = createSelector(
  [(state: RootState) => state.wizardCreateCompany.currentStep],
  (currentStep: number) => currentStep
);

export type SelectedCompaniesContactWizardStep = (state: RootState) => number;
export const selectCompaniesContactWizardStep: SelectedCreateCompanyCurrentStep = createSelector(
  [(state: RootState) => state.wizardCreateCompany.companiesContactWizardStep],
  (companiesContactWizardStep: number) => companiesContactWizardStep
);

export type CompanyToAddSelector = (state: RootState) => Company;
export const selectCompanyToAdd: CompanyToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.companyToAdd],
  (companyToAdd: Company) => companyToAdd
);

export type SelectedCollaborationsPerPageSelector = (state: RootState) => EntitiesPerPage<CollaborationWithContactInfo>;
export const selectSelectedCollaborationsToAddPerPage: SelectedCollaborationsPerPageSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.selectedCollaborationDetailsPerPage],
  (selectedCollaborationDetailsPerPage: EntitiesPerPage<CollaborationWithContactInfo>) => selectedCollaborationDetailsPerPage
);

export type CollaborationDetailsSelector = (state: RootState) => CollaborationWithContactInfo[];
export const selectCollaborationDetails: CollaborationDetailsSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.collaborationDetails],
  (collaborationDetails: CollaborationWithContactInfo[]) => collaborationDetails
);

export type CollaborationToAddSelector = (state: RootState) => Collaboration;
export const selectCollaborationToAdd: CollaborationToAddSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.collaborationToAdd],
  (collaborationToAdd: Collaboration) => collaborationToAdd
);

export type IsFinishButtonDisabledSelector = (state: RootState) => boolean;
export const selectIsFinishButtonDisabled: IsFinishButtonDisabledSelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.isFinishButtonDisabled],
  (isFinishButtonDisabled: boolean) => isFinishButtonDisabled
);

export type IsCreateContactFinishButtonDisabled = (state: RootState) => boolean;
export const selectIsCreateContactFinishButtonDisabled: IsCreateContactFinishButtonDisabled = createSelector(
  [(state: RootState) => state.wizardCreateCompany.isCreateContactFinishButtonDisabled],
  (isCreateContactFinishButtonDisabled: boolean) => isCreateContactFinishButtonDisabled
);

export type IsLoadingAddingCompanySelector = (state: RootState) => boolean;
export const selectIsLoadingAddingCompany: IsLoadingAddingCompanySelector = createSelector(
  [(state: RootState) => state.wizardCreateCompany.isLoadingAddingCompany],
  (isLoadingAddingCompany: boolean) => isLoadingAddingCompany
);

const WizardCreateCompanySlice = createSlice({
  name: 'wizardCreateCompany',
  initialState,
  reducers: {
    pageOfContactsRequested: (state) => {
      state.contactSuggestions = [];
      state.isLoadingContacts = true;
      state.totalCountOfContacts = 0;
    },
    setSearchContactsText: (state, action: PayloadAction<string>) => {
      state.searchContactsText = action.payload;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      state.pageNumber = 1;
    },
    pageOfContactsReceived: (state, action: PayloadAction<{ contacts: Contact[]; totalCountOfContacts: number }>) => {
      state.contactSuggestions = action.payload.contacts;
      state.totalCountOfContacts = action.payload.totalCountOfContacts;
      state.isLoadingContacts = false;
    },
    openCreateCompanyWizard: (state) => {
      state.isCreateCompanyWizardOpened = true;
    },
    closeCreateCompanyWizard: (state) => {
      state.currentStep = 1;
      state.isCreateCompanyWizardOpened = false;
      state.isFinishButtonDisabled = false;
      state.contactsToAdd = [];
      state.contactSuggestions = [];
      state.addressInfoToAdd = {} as AddressInfo;
      state.generalInfoToAdd = {} as GeneralInfo;
      state.companyToAdd = {} as Company;
      state.contactToAdd = {} as Contact;
      state.selectedCollaborationDetailsPerPage = {} as EntitiesPerPage<CollaborationWithContactInfo>;
      state.collaborationToAdd = {} as Collaboration;
      state.contactInfoToAdd = {} as ContactInfo;
      state.searchedContacts = [];
      state.selectedContactId = '';
      state.collaborationDetails = [];
    },
    setGeneralInfoToAdd: (state, action: PayloadAction<GeneralInfo>) => {
      state.generalInfoToAdd = action.payload;
    },
    setAddressInfoToAdd: (state, action: PayloadAction<AddressInfo>) => {
      state.addressInfoToAdd = action.payload;
    },
    setContactInfoToAdd: (state, action: PayloadAction<ContactInfo>) => {
      state.contactInfoToAdd = action.payload;
    },
    openCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = true;
      state.isCreateContactFinishButtonDisabled = false;
    },
    closeCreateContactWizard: (state) => {
      state.isCreateContactWizardOpened = false;
      state.contactInfoToAdd = {} as ContactInfo;
      state.companiesContactWizardStep = 1;
    },
    addContactToCompanyInStore: (state, action: PayloadAction<Contact>) => {
      state.contactsToAdd = [...state.contactsToAdd, action.payload];
      state.contactSuggestions = [];
    },
    removeContactFromCompanyInStore: (state, action: PayloadAction<string>) => {
      state.contactsToAdd = state.contactsToAdd.filter((contact) => contact.id !== action.payload);
    },
    goToStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      state.searchContactsText = '';
      state.pageNumber = 1;
    },
    goToNextStep: (state) => {
      state.currentStep = state.currentStep + 1;
      state.searchContactsText = '';
      state.pageNumber = 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep = state.currentStep - 1;
      state.searchContactsText = '';
      state.pageNumber = 1;
    },
    goToNextContactStep: (state) => {
      state.companiesContactWizardStep = state.companiesContactWizardStep + 1;
    },
    goToPreviousContactStep: (state) => {
      state.companiesContactWizardStep = state.companiesContactWizardStep - 1;
    },
    goToCreateContactStep: (state, action: PayloadAction<number>) => {
      state.companiesContactWizardStep = action.payload;
    },
    addContactRequested: (state) => {
      state.isCreateContactFinishButtonDisabled = true;
    },
    addedContact: (state, action: PayloadAction<Contact>) => {
      state.contactToAdd = {} as Contact;
      state.contactAdded = action.payload;
      state.isCreateContactFinishButtonDisabled = false;
    },
    setContactToAdd: (state, action: PayloadAction<Contact>) => {
      state.contactToAdd = action.payload;
    },
    resetAddedContact: (state) => {
      state.contactAdded = {} as Contact;
    },
    addContactToMediaOutletInStore: (state, action: PayloadAction<Contact>) => {
      state.contactsToAdd = [...state.contactsToAdd, action.payload];
      state.contactSuggestions = [];
    },
    removeContactFromMediaOutletInStore: (state, action: PayloadAction<string>) => {
      state.contactsToAdd = state.contactsToAdd.filter((contact) => contact.id !== action.payload);
    },
    addCollaborationRequested: (state, action: PayloadAction<Collaboration>) => {
      state.collaborationToAdd = action.payload;
    },
    addedCollaboration: (state, action: PayloadAction<Collaboration>) => {
      state.selectedContactId = '';
    },
    setSelectedContactId: (state, action: PayloadAction<string>) => {
      state.selectedContactId = action.payload;
    },
    addedCompany: (state) => {
      state.companyToAdd = {} as Company;
      state.isLoadingAddingCompany = false;
    },
    setCompanyToAdd: (state, action: PayloadAction<Company>) => {
      state.companyToAdd = action.payload;
    },
    addCompanyRequested: (state, action: PayloadAction<Company>) => {
      state.companyToAdd = action.payload;
      state.isLoadingAddingCompany = true;
    },
    setCollaborationsToAddPerPage: (state, action: PayloadAction<CollaborationWithContactInfo[]>) => {
      if (state.isLoadingContacts) return;
      state.selectedCollaborationDetailsPerPage[state.pageNumber] = action.payload;
      state.collaborationDetails = mapEntitiesPerPageToList<CollaborationWithContactInfo>(state.selectedCollaborationDetailsPerPage);
    },
    setIsFinishButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isFinishButtonDisabled = action.payload;
    },
    disableCreateContactFinishButton: (state) => {
      state.isCreateContactFinishButtonDisabled = true;
    },
    updateCollaboration: (state, action: PayloadAction<{ collaboration: Collaboration; contactId: string; useDifferentAddress: boolean }>) => {
      state.collaborationDetails = state.collaborationDetails.map((collaborationDetails) => {
        if (collaborationDetails.contactId === action.payload.contactId) {
          return { ...collaborationDetails, collaboration: action.payload.collaboration };
        }

        return collaborationDetails;
      });
    },
    removeCollaboration: (state, action: PayloadAction<string>) => {
      state.collaborationDetails = state.collaborationDetails.filter((collaborationDetails) => collaborationDetails.contactId !== action.payload);
    },
    setIsLoadingAddingCompany: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAddingCompany = action.payload;
    },
    resetCollaborationsTable: (state) => {
      state.selectedCollaborationDetailsPerPage = {} as EntitiesPerPage<CollaborationWithContactInfo>;
      state.collaborationDetails = [];
      state.pageNumber = 1;
    }
  }
});

export const {
  pageOfContactsRequested,
  pageOfContactsReceived,
  setSearchContactsText,
  setPageNumber,
  openCreateCompanyWizard,
  closeCreateCompanyWizard,
  setGeneralInfoToAdd,
  setAddressInfoToAdd,
  setContactInfoToAdd,
  openCreateContactWizard,
  closeCreateContactWizard,
  addContactToCompanyInStore,
  removeContactFromCompanyInStore,
  goToStep,
  goToNextStep,
  goToPreviousStep,
  goToNextContactStep,
  goToPreviousContactStep,
  goToCreateContactStep,
  addContactRequested,
  addedContact,
  setContactToAdd,
  resetAddedContact,
  addContactToMediaOutletInStore,
  removeContactFromMediaOutletInStore,
  addCollaborationRequested,
  addedCollaboration,
  setSelectedContactId,
  addCompanyRequested,
  setCompanyToAdd,
  addedCompany,
  setIsFinishButtonDisabled,
  disableCreateContactFinishButton,
  updateCollaboration,
  removeCollaboration,
  setPageSize,
  setCollaborationsToAddPerPage,
  setIsLoadingAddingCompany,
  resetCollaborationsTable
} = WizardCreateCompanySlice.actions;

export default WizardCreateCompanySlice.reducer;
