import { Avatar, Input, List as ListComponent, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useState } from 'react';
import { getContactsByListIdRequested, getListsByNameRequested, selectSearchedLists } from 'app/pages/my-audience/lists-profile';
import { List } from 'app/pages/my-audience/lists';
import emptyImage from 'assets/images/empty-search.png';
import { FormattedMessage, useIntl } from 'app/i18n';
import { getInitials } from 'app/common/validation';

export const AddContactFromListSearch = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const lists = useAppSelector<List[]>(selectSearchedLists);
  const [searchText, setSearchText] = useState('');

  const onInputChange = (searchText: string) => {
    setSearchText(searchText);
    dispatch(getListsByNameRequested(searchText));
  };

  const handleContactSelected = (selectedIndex: number) => {
    const selectedList: List = lists[selectedIndex];
    dispatch(getContactsByListIdRequested(selectedList.id));
    setSearchText('');
  };
  return (
    <div className="wizard-search">
      <Input
        className="profile-panel-comments-send-input"
        icon={<SearchIcon />}
        autoComplete="off"
        placeholder={formatMessage({ id: 'list-wizards.add-contact-from-list-search-input' })}
        value={searchText}
        onChange={(e) => onInputChange((e.target as HTMLTextAreaElement).value)}
      />
      {searchText !== '' && (
        <div className="dropdown-segments-wrap">
          <div className="dropdown-segments">
            <Segment key={'dropdown-names'} className="dropdown-segment">
              <ListComponent
                onSelectedIndexChange={(_, newProps) => {
                  if (newProps) handleContactSelected(newProps.selectedIndex);
                }}
                selectable
                items={lists.map((list, index) => ({
                  key: `${index}-${list.id}`,
                  header: `${list.name}`,
                  media: <Avatar getInitials={getInitials} name={list.name} />
                }))}
              />
            </Segment>
          </div>
        </div>
      )}
      {lists?.length === 0 && searchText && (
        <Segment className="empty-search">
          <div className="empty-search-wrap">
            <img className="empty-search-image" src={emptyImage} alt="No results found" />
            <Text className="empty-search-text text-bold">
              <FormattedMessage id={'list-wizards.add-contact-no-results-title'} />
            </Text>
            <Text className="empty-search-text">
              <FormattedMessage id={'list-wizards.add-contact-no-results-subtitle'} />
            </Text>
          </div>
        </Segment>
      )}
    </div>
  );
};
