import { MediumCategory, ensureHttpProtocol } from 'app/common';
import { Company } from 'app/pages/my-audience/companies';
import { Medium } from 'app/pages/my-audience/contact-profile';
import { Collaboration, Contact, JobTitle, OwnContactData, PhoneNumber } from 'app/pages/my-audience/contacts';
import { MediumContactData } from 'app/pages/my-audience/media-outlets';
import { AdditionalContactInfo, AddressInfo, CollaborationForm, ContactInfo, GeneralInfo } from 'app/pages/my-audience/wizard-create-company';

export interface CreateCompanyProps {
  generalInfo: GeneralInfo;
  addressInfo: AddressInfo;
}

export const createCompany = ({ generalInfo, addressInfo }: CreateCompanyProps) =>
  new Company(
    '',
    generalInfo.name,
    '',
    generalInfo.parentCompany ?? '',
    new MediumContactData(
      addressInfo.address,
      addressInfo.poBox,
      addressInfo.postalCode,
      addressInfo.country ?? '',
      addressInfo.city,
      ensureHttpProtocol(addressInfo.website),
      addressInfo.email,
      addressInfo.phoneNumber,
      addressInfo.fax,
      ''
    ),
    '',
    '',
    [],
    [],
    [],
    [],
    undefined,
    [],
    0,
    '',
    '',
    {} as MediumCategory
  );

export const createContact = (contactInfoToAdd: ContactInfo) =>
  new Contact('', contactInfoToAdd.title, contactInfoToAdd.firstName, contactInfoToAdd.lastName, '', false, [], [], [], [], [], [], [], {
    isPrimary: true
  } as OwnContactData);

export const createAdditionalContactInfo = (id: string) => new AdditionalContactInfo(id, '', '', '', '', '', '', '', '', '', '');

export const setCollaboration = (formData: CollaborationForm, jobTitles: JobTitle[]): Collaboration => {
  const jobTitle = jobTitles.find((jt) => jt.name === formData.jobTitle);
  return new Collaboration(
    '',
    {} as Medium,
    jobTitle,
    formData.jobDescription,
    formData.address,
    formData.country,
    new PhoneNumber(formData.landlinePhoneNumber, false),
    new PhoneNumber(formData.mobilePhoneNumber, false),
    formData.emailAddress,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false,
    formData.city,
    formData.postalCode
  );
};
