import { PillData, UndoablePills, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { Blacklist } from 'app/pages/my-audience/common';
import {
  addBlacklistToContactRequested,
  addNewBlacklistRequested,
  addedBlacklistToContact,
  filterBlacklists,
  removeBlacklistFromContactRequested,
  removeBlacklistsRequested,
  removedBlacklistFromContact,
  selectAllBlacklistsValues,
  selectFilteredBlacklists,
  selectSelectedContactBlacklists
} from 'app/pages/my-audience/contact-profile';
import { Permission, useAuthContext } from 'auth';

export const Blacklists = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthContext();

  const hasPermissionToEdit = hasPermission(Permission.ManageEmailings);

  const selectedContactBlacklists = useAppSelector<Blacklist[]>(selectSelectedContactBlacklists);
  const filteredBlacklists = useAppSelector<Blacklist[]>(selectFilteredBlacklists);
  const allBlacklists = useAppSelector<Blacklist[]>(selectAllBlacklistsValues);

  const handleCreateBlacklist = (name: string) => dispatch(addNewBlacklistRequested(name));

  const addBlacklistToContactInStore = (id: string) => {
    const blacklist = allBlacklists.find((t) => t.id === id);
    dispatch(addedBlacklistToContact(blacklist));
  };

  const removedBlacklistFromContactInStore = (id: string) => {
    const topic = allBlacklists.find((b) => b.id === id);
    dispatch(removedBlacklistFromContact(topic));
  };

  const handleDeleteBlacklists = (ids: string[]) => {
    const blacklistsIds: string[] = allBlacklists.reduce(
      (blacklistsIds, blackList) => [...blacklistsIds, ...(ids.includes(blackList.id) ? [blackList.id] : [])],
      []
    );

    dispatch(removeBlacklistsRequested(blacklistsIds));
  };

  const handleAddBlacklistToContact = (id: string) => {
    const blacklist = allBlacklists.find((b) => b.id === id);
    dispatch(addBlacklistToContactRequested(blacklist));
  };

  const handleRemoveTopicFromContact = (id: string) => {
    const blacklist = allBlacklists.find((b) => b.id === id);
    dispatch(removeBlacklistFromContactRequested(blacklist));
  };

  const handleFilterTopics = (topicName: string) => {
    dispatch(filterBlacklists(topicName));
  };

  return (
    <div className="profile-panel-blacklist">
      <UndoablePills
        entityName={formatMessage({ id: 'contacts-gdpr-accordion.blacklist' })}
        selectedValues={selectedContactBlacklists.map((b) => new PillData(b.id, b.name, true))}
        dropdownValues={filteredBlacklists.map((b) => new PillData(b.id, b.name, true))}
        onAddValue={handleCreateBlacklist}
        onSelectValue={handleAddBlacklistToContact}
        onDeselectValue={handleRemoveTopicFromContact}
        onDeleteValues={handleDeleteBlacklists}
        filterValues={handleFilterTopics}
        enabledDelete={true}
        onDeselectValueInStore={removedBlacklistFromContactInStore}
        onSelectValueInStore={addBlacklistToContactInStore}
        onUndoDeselectValueInStore={addBlacklistToContactInStore}
        onUndoSelectValueInStore={removedBlacklistFromContactInStore}
        disabled={!hasPermissionToEdit}
      />
    </div>
  );
};
