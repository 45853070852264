import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { CreateCompanyWizardStep, selectCreateCompanyCurrentStep, goToStep, pageOfContactsRequested } from 'app/pages/my-audience/wizard-create-company';

export interface CreateCompanyStepsProps {
  isStepValid?: boolean;
  onStepClick?: () => void;
}

export const CreateCompanySteps: React.FC<CreateCompanyStepsProps> = ({ isStepValid = true, onStepClick }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const step = useAppSelector<CreateCompanyWizardStep>(selectCreateCompanyCurrentStep);

  const handleStepButtonClick = (step: CreateCompanyWizardStep) => {
    if (onStepClick !== undefined) {
      onStepClick();
    }

    if (step === CreateCompanyWizardStep.ContactTab) {
      dispatch(pageOfContactsRequested());
    }

    dispatch(goToStep(step));
  };

  const styleBreadcrumbStatus = (stepToCompareWith: CreateCompanyWizardStep): string => {
    const buttonClass = 'wizard-panel-breadcrumb-button';
    if (step === stepToCompareWith) return `${buttonClass} active`;
    if (stepToCompareWith < step) return `${buttonClass} completed`;
    return buttonClass;
  };

  const handleCollaborationClick = () => {
    dispatch(pageOfContactsRequested());
    handleStepButtonClick(CreateCompanyWizardStep.ContactTab);
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardStep.GeneralInfo)}
            content={formatMessage({ id: 'add-menu-create-company.wizard-step-general' })}
            title="Information"
            onClick={() => handleStepButtonClick(CreateCompanyWizardStep.GeneralInfo)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardStep.AddressInfo)}
            content={formatMessage({ id: 'add-menu-create-company.wizard-step-address' })}
            title="Address"
            onClick={() => handleStepButtonClick(CreateCompanyWizardStep.AddressInfo)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardStep.ContactTab)}
            content={formatMessage({ id: 'add-menu-create-company.wizard-step-collaboration' })}
            title="Collaboration"
            onClick={handleCollaborationClick}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardStep.AdditionalInfo)}
            content={formatMessage({ id: 'add-menu-create-company.wizard-step-info' })}
            title="Add information"
            onClick={() => handleStepButtonClick(CreateCompanyWizardStep.AdditionalInfo)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardStep.Finish)}
            content={formatMessage({ id: 'add-menu-create-company.wizard-step-finish' })}
            title="Review & Finish"
            onClick={() => handleStepButtonClick(CreateCompanyWizardStep.Finish)}
            disabled={!isStepValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
