import { GridDropZone } from 'react-grid-dnd';
import { selectIsCardEditing, useAppSelector, useWindowSize } from 'app/common';
import { SavedSearch, SavedSearchItem } from 'app/pages/my-audience/saved-searches';

interface CardStyle {
  boxesPerRow: number;
  height: number;
}

interface SavedSearchesGridProps {
  pageSize: number;
  savedSearches: SavedSearch[];
  onUpdateSavedSearch: (updatedSavedSearch: SavedSearch) => void;
  onSetSavedSearchToRemove: (savedSearchToRemove: SavedSearch) => void;
  onSavedSearchClick: (savedSearch: SavedSearch) => void;
}

export const GridCard = ({ savedSearches, pageSize, onUpdateSavedSearch, onSetSavedSearchToRemove, onSavedSearchClick }: SavedSearchesGridProps) => {
  const [_, width] = useWindowSize();
  const style: CardStyle = width > 992 ? { boxesPerRow: 3, height: 250 } : width > 766 ? { boxesPerRow: 2, height: 370 } : { boxesPerRow: 1, height: 750 };
  const height = (savedSearches.length / pageSize) * style.height;
  const isEditingCard = useAppSelector(selectIsCardEditing);

  return (
    <>
      <GridDropZone
        id="savedSearches"
        boxesPerRow={style.boxesPerRow}
        rowHeight={120}
        className="saved-searches-cards"
        style={{ height }}
        disableDrag={isEditingCard}
      >
        {savedSearches.map((savedSearch) => (
          <SavedSearchItem
            isEditing={isEditingCard}
            key={savedSearch.id}
            savedSearch={savedSearch}
            onUpdateSavedSearch={onUpdateSavedSearch}
            onSetSavedSearchToRemove={onSetSavedSearchToRemove}
            onSavedSearchClick={onSavedSearchClick}
          />
        ))}
      </GridDropZone>
    </>
  );
};
