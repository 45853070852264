import { Breadcrumb, Button } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { useIntl } from 'app/i18n';
import {
  CreateCompanyWizardSteps,
  goToStepCreateCompany,
  selectCreateCompanyCurrentStep,
  selectIsCreateCompanyAddressInfoStepValid,
  selectIsCreateCompanyGeneralInfoStepValid,
  selectIsCreateCompanyProfileInfoStepValid
} from 'app/pages/my-audience/contact-profile';

export const CreateCompanySteps = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const step = useAppSelector<CreateCompanyWizardSteps>(selectCreateCompanyCurrentStep);
  const isCreateCompanyGeneralInfoStepValid = useAppSelector(selectIsCreateCompanyGeneralInfoStepValid);
  const isCreateCompanyAddressInfoStepValid = useAppSelector(selectIsCreateCompanyAddressInfoStepValid);
  const isCreateCompanyProfileInfoStepValid = useAppSelector(selectIsCreateCompanyProfileInfoStepValid);

  const styleBreadcrumbStatus = (stepToCompareWith: CreateCompanyWizardSteps): string => {
    if (step === stepToCompareWith) return 'wizard-panel-breadcrumb-button active';
    if (stepToCompareWith < step) return 'wizard-panel-breadcrumb-button completed';
    return 'wizard-panel-breadcrumb-button';
  };

  const handleStepButtonClick = (step: CreateCompanyWizardSteps) => {
    dispatch(goToStepCreateCompany(step));
  };

  return (
    <div className="wizard-panel-breadcrumbs-wrap">
      <Breadcrumb className="wizard-panel-breadcrumbs" aria-label="breadcrumb">
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardSteps.GeneralInformation)}
            content={formatMessage({ id: 'add-medium-wizard-steps.general-information' })}
            onClick={() => handleStepButtonClick(CreateCompanyWizardSteps.GeneralInformation)}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardSteps.AddressInfrormation)}
            content={formatMessage({ id: 'add-medium-wizard-steps.address-information' })}
            onClick={() => handleStepButtonClick(CreateCompanyWizardSteps.AddressInfrormation)}
            disabled={!isCreateCompanyGeneralInfoStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardSteps.ProfileInformation)}
            content={formatMessage({ id: 'add-medium-wizard-steps.profile-information' })}
            onClick={() => handleStepButtonClick(CreateCompanyWizardSteps.ProfileInformation)}
            disabled={!isCreateCompanyAddressInfoStepValid}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Button
            className={styleBreadcrumbStatus(CreateCompanyWizardSteps.Finish)}
            content={formatMessage({ id: 'add-medium-wizard-steps.finish' })}
            onClick={() => handleStepButtonClick(CreateCompanyWizardSteps.Finish)}
            disabled={!isCreateCompanyProfileInfoStepValid}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
