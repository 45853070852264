import { ensureHttpProtocol } from 'app/common';
import { Company } from 'app/pages/my-audience/companies';
import {
  AddProfileInformationForm,
  CreateContactForm,
  CreateContactProfileInformationForm,
  EditProfileInformationForm
} from 'app/pages/my-audience/company-profile';
import { Medium } from 'app/pages/my-audience/contact-profile';
import { Collaboration, Contact, JobTitle, MediumType, PhoneNumber } from 'app/pages/my-audience/contacts';

export const createCollaborationFromEditing = (data: EditProfileInformationForm, collaboration: Collaboration, jobTitle: JobTitle) => {
  return new Collaboration(
    collaboration.id,
    collaboration.medium,
    jobTitle,
    data.jobDescription,
    data.address,
    data.country,
    data.landlinePhoneNumber !== '' ? new PhoneNumber(data.landlinePhoneNumber, collaboration.landlinePhoneNumber.isPrimary) : null,
    data.mobilePhoneNumber !== '' ? new PhoneNumber(data.mobilePhoneNumber, collaboration.mobilePhoneNumber.isPrimary) : null,
    data.emailAddress,
    ensureHttpProtocol(collaboration.blogUrl),
    ensureHttpProtocol(collaboration.websiteUrl),
    ensureHttpProtocol(collaboration.twitterProfileUrl),
    ensureHttpProtocol(collaboration.instagramProfileUrl),
    ensureHttpProtocol(collaboration.linkedInProfileUrl),
    ensureHttpProtocol(collaboration.youtubeProfileUrl),
    ensureHttpProtocol(collaboration.facebookProfileUrl),
    collaboration.isPrimary,
    data.city,
    data.postalCode
  );
};

export const createCollaboration = (data: AddProfileInformationForm, jobTitle: JobTitle, company: Company) => {
  return new Collaboration(
    '',
    new Medium(company.id, company.name, MediumType.Company),
    jobTitle,
    data.jobDescription,
    data.address,
    data.country,
    data.landlinePhoneNumber ? new PhoneNumber(data.landlinePhoneNumber, false) : null,
    data.mobilePhoneNumber ? new PhoneNumber(data.mobilePhoneNumber, false) : null,
    data.emailAddress,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false,
    data.city,
    data.postalCode
  );
};

export const createNewContact = (data: CreateContactForm) => {
  return new Contact('', data.salutation, data.firstName, data.lastName, '', false, [], [], [], [], [], [], []);
};

export const createCollaborationOnCreateContactProfileInfo = (data: CreateContactProfileInformationForm, jobTitle: JobTitle, company: Company) => {
  return new Collaboration(
    '',
    new Medium(company.id, company.name, MediumType.Company),
    jobTitle,
    data.jobDescription,
    data.address,
    data.country,
    data.landlinePhoneNumber ? new PhoneNumber(data.landlinePhoneNumber, false) : { value: '', isPrimary: false },
    data.mobilePhoneNumber ? new PhoneNumber(data.mobilePhoneNumber, false) : { value: '', isPrimary: false },
    data.emailAddress,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false,
    data.city,
    data.postalCode
  );
};
