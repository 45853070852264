import { useIntl } from 'app/i18n';
import { UndoableDialog, hideUndoAlert, useAppDispatch, useAppSelector } from 'app/common';
import {
  closeRemoveSendingsDialog,
  removeSendingsFromStore,
  removeSendingsRequested,
  selectIsRemoveSendingsDialogOpened,
  selectSelectedSendingsIds,
  selectSelectedSendingsTitles,
  undoRemoveSendingsFromStore
} from 'app/pages/my-activities/sendings';
import { closeProfilePanel } from 'app/pages/my-activities/sending-wizard';

export const RemoveSendingDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const selectedSendingsTitles = useAppSelector<string[]>(selectSelectedSendingsTitles);
  const selectedSendingsIds = useAppSelector<string[]>(selectSelectedSendingsIds);
  const isRemoveSendingDialogOpened = useAppSelector<boolean>(selectIsRemoveSendingsDialogOpened);

  const onRemoveSendings = () => {
    dispatch(removeSendingsRequested(selectedSendingsIds));
    dispatch(hideUndoAlert());
    closeDialog();
  };

  const closeDialog = () => {
    dispatch(closeRemoveSendingsDialog());
  };

  const onDeleteInStore = () => {
    dispatch(removeSendingsFromStore(selectedSendingsIds));
    dispatch(closeProfilePanel());
  };

  const onUndoDeleteInStore = () => {
    dispatch(undoRemoveSendingsFromStore());
  };

  return (
    <UndoableDialog
      opened={isRemoveSendingDialogOpened}
      dialogTitle={`${formatMessage({ id: 'delete-emailing-dialog.title' })} ${selectedSendingsTitles.join(', ')}`}
      dialogText={formatMessage({ id: 'delete-emailing-dialog.subtext' })}
      alertMessage={formatMessage({ id: 'delete-emailings.message' }, { counter: selectedSendingsIds.length })}
      onExecuteAction={onRemoveSendings}
      onExecuteInStore={onDeleteInStore}
      onRevertAction={onUndoDeleteInStore}
      onClose={closeDialog}
    />
  );
};
