import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { AddContacts, closeAddContactsWizard, closeProfilePanel, selectIsAddContactWizardOpened } from 'app/pages/my-audience/lists-profile';

export const AddContactsWizard = () => {
  const dispatch = useAppDispatch();

  const isAddContactWizardOpened = useAppSelector<boolean>(selectIsAddContactWizardOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeAddContactsWizard());
    dispatch(closeProfilePanel());
  };

  const handleArrowButtonClick = () => {
    dispatch(closeAddContactsWizard());
  };

  return (
    <WizardWrap
      isWizardOpened={isAddContactWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      customWidth="750px"
      classModifier="narrow"
      hasBackArrow={true}
      onArrowButtonClick={handleArrowButtonClick}
      isLightDismiss={true}
    >
      <div className="wizard-panel-main">
        <AddContacts />
      </div>
    </WizardWrap>
  );
};
