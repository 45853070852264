export const defaultTimeoutInMilliseconds = 5000;
export const emptyUlid = '00000000000000000000000000';
export const defaultCompanyId = '01GE6STF19BEY69HN8A7K24G8K';

export const debounceTimeInMilliseconds = 250;

export const maximumSearchTextLength = 50;
export const maximumProfileNameLength = 30;
export const maximumTextInputLength = 255;

export const maximumCompanyNameLength = 255;
export const maximumSendingTitleLength = 255;
export const maximumMediaOutletNameLength = 255;
export const maximumContactLastNameLength = 50;
export const maximumListNameLength = 254;

export const SAGA_RETRY_COUNT = 5;
export const SAGA_RETRY_DELAY_MS = 200;

export const MAIN_ROUTES = {
  AUDIENCE_OVERVIEW_PATH: '/my-audience',
  ACTIVITIES_OVERVIEW_PATH: '/my-activities'
};

export const CHART_PURPLE_COLOR = '#6264A7';
export const CHART_LIGHT_PURPLE_COLOR = '#B8BAE2';
export const CHART_GREY_COLOR = '#DFDEDE';
export const CHART_CUTOUT = 35;

export const MAXIMUM_NUMBER_OF_CONTACTS = +process.env.REACT_APP_MAXIMUM_NUMBER_OF_CONTACTS;

const CHART_LAYOUT = { padding: { left: 25, right: 25, top: 0, bottom: 0 } };
const CHART_LEGEND = {
  legend: {
    display: false
  }
};

export const CHART_OPTIONS = {
  layout: CHART_LAYOUT,
  plugins: {
    ...CHART_LEGEND,
    tooltip: {
      enabled: false
    }
  }
};

export const ENABLE_TOOLTIP_CHART_OPTIONS = {
  layout: CHART_LAYOUT,
  plugins: {
    ...CHART_LEGEND
  }
};
