import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react';
import { useAppDispatch } from 'app/common';
import { createSendingFromTemplateRequested } from 'app/pages/my-activities/sending-wizard';
import { clearAllFilters, FiltersPanel, setTemplatesPageSize, TemplatesOverview, templatesRequested, Template } from 'app/pages/my-activities/templates';
import 'app/pages/my-activities/templates/components/Templates.scss';
import { useIntl } from 'app/i18n';

export const AllTemplates: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const onGoBack = (): void => {
    dispatch(clearAllFilters());
    dispatch(setTemplatesPageSize(10));
    dispatch(templatesRequested(''));
    navigate(-1);
  };

  const onChooseTemplate = (template: Template) => {
    dispatch(createSendingFromTemplateRequested({ template, defaultSendingName: formatMessage({ id: 'emailing-wizard.message-with-no-title-text' }) }));
    navigate(`/my-activities/e-mailings/emailing-wizard`);
  };

  return (
    <>
      <div className="templates">
        <Button text content={formatMessage({ id: 'all-templates.go-back' })} icon={<Icon iconName="Back" />} className="templates-button" onClick={onGoBack} />
        <TemplatesOverview onChooseTemplate={onChooseTemplate} />
      </div>
      <FiltersPanel />
    </>
  );
};
