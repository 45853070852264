import { Icon } from '@fluentui/react';
import { Button, Tooltip } from '@fluentui/react-northstar';
import { ThreeDotMenu, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import { SendingStatus, selectIsChoosingSendingAsFavorite, setIsChoosingSendingAsFavorite } from 'app/pages/my-activities/sendings';
import { SyntheticEvent } from 'react';

interface SendingHoverActionsProps {
  sendingId: string;
  sendingStatus: SendingStatus;
  isFavorite: boolean;
  disabled: boolean;
  onEditSending: (sendingId: string) => void;
  onSetAsFavourite: (sendingId: string) => void;
  onDuplicateSending: (sendingId: string) => void;
  onDeleteSending: (sendingId: string) => void;
}

export const SendingHoverActions = ({
  sendingId,
  sendingStatus,
  isFavorite,
  disabled,
  onEditSending,
  onDeleteSending,
  onDuplicateSending,
  onSetAsFavourite
}: SendingHoverActionsProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const isChoosingSendingAsFavorite = useAppSelector<boolean>(selectIsChoosingSendingAsFavorite);

  const handleDuplicateSending = (e: SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    onDuplicateSending(sendingId);
  };

  const handleDeleteSending = (e: SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    onDeleteSending(sendingId);
  };

  const handleEditSending = (e: SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    onEditSending(sendingId);
  };

  const handleSetAsFavourite = (e: SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    onSetAsFavourite(sendingId);
    dispatch(setIsChoosingSendingAsFavorite(true));
  };

  return (
    <div className="row-header-more-options">
      {sendingStatus === SendingStatus.Draft && (
        <Tooltip
          subtle={false}
          pointing
          key={sendingId + 'edit'}
          trigger={<Button key={sendingId} iconOnly onClick={handleEditSending} icon={<Icon iconName="Edit" />} disabled={disabled} />}
          content={formatMessage({ id: 'buttons.edit' })}
          position="below"
          align="center"
        />
      )}
      <Tooltip
        subtle={false}
        pointing
        key={sendingId + 'set-as-favorite'}
        trigger={
          <Button
            key={sendingId}
            iconOnly
            onClick={handleSetAsFavourite}
            icon={isFavorite ? <Icon iconName="FavoriteStarFill" /> : <Icon iconName="FavoriteStar" />}
            disabled={disabled || isChoosingSendingAsFavorite}
            className={isFavorite ? 'full' : 'default'}
          />
        }
        content={isFavorite ? formatMessage({ id: 'emailings-table.remove-favorite-single' }) : formatMessage({ id: 'emailings-table.set-as-favorite' })}
        position="below"
        align="center"
      />
      <ThreeDotMenu
        threeDotMenuItems={[
          {
            key: 'rename-template',
            title: formatMessage({ id: 'emailings-table.duplicate' }),
            className: 'profile-panel-menu-item',
            icon: <Icon iconName="Copy" />,
            onClick: handleDuplicateSending,
            disabled
          },
          {
            key: 'delete-template',
            title: formatMessage({ id: 'buttons.delete' }),
            className: 'profile-panel-menu-item danger',
            icon: <Icon iconName="Delete" />,
            onClick: handleDeleteSending,
            disabled
          }
        ]}
      />
    </div>
  );
};
