import { useAppSelector, selectLastInteractionWithEmailAdress } from 'app/common';
import circlesDecoration from 'assets/images/augure-decoration-circles.png';
import { useIntl } from 'app/i18n';
import augureLogo from 'assets/images/augure-logo-light-version.png';
import augureLogoDark from 'assets/images/augure-logo-dark-version.png';
import licenceNotFoundImage from 'assets/images/licence-not-found.png';
import 'app/common/components/sign-in/licence-not-found.scss';

export const LicenseNotFound = () => {
  const { formatMessage } = useIntl();
  const userEmailAddress = useAppSelector(selectLastInteractionWithEmailAdress);
  const contactUsEmailAddress = process.env.REACT_APP_CONTACT_US_EMAIL_ADDRESS;

  return (
    <div className="login-wrap license-not-found">
      <div className="login-actions-col">
        <div className="login-content">
          <div className="login-logo login-logo__no-user">
            <img src={augureLogo} alt={formatMessage({ id: 'licence-not-found-page.logo-alt' })} />
            <img src={augureLogoDark} className="login-logo-mobile" alt={formatMessage({ id: 'sign-in-page.logo-alt' })} />
          </div>
        </div>
        <div className="login-not-found__user login-not-found__user--mobile">
          <div className="login-not-found__text">
            <img src={licenceNotFoundImage} alt={formatMessage({ id: 'licence-not-found-page.image-alt' })} />
            <h3>{formatMessage({ id: 'licence-not-found-page.text' })}</h3>
            <p>
              {formatMessage({ id: 'licence-not-found-page.description' })} <b>{`${userEmailAddress}. `}</b>
              {formatMessage({ id: 'licence-not-found-page.contact-admin' }, { emailAddress: <b>{`${contactUsEmailAddress}`}</b> })}
            </p>
          </div>
        </div>
        <img src={circlesDecoration} alt={formatMessage({ id: 'licence-not-found-page.left-decoration-alt' })} className="login-image-decoration" />
      </div>
      <div className="login-avatar-col">
        <div className="login-not-found__user">
          <div className="login-not-found__text">
            <img src={licenceNotFoundImage} alt={formatMessage({ id: 'licence-not-found-page.image-alt' })} />
            <h3>{formatMessage({ id: 'licence-not-found-page.text' })}</h3>
            <p>
              {formatMessage({ id: 'licence-not-found-page.description' })} <b>{`${userEmailAddress}. `}</b>
              {formatMessage({ id: 'licence-not-found-page.contact-admin' }, { emailAddress: <b>{`${contactUsEmailAddress}`}</b> })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
