import { useState } from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button, Dropdown } from '@fluentui/react-northstar';
import { FileTypes } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

interface ExportContactsDialogProps {
  isDialogVisible: boolean;
  onDismiss: () => void;
  onExport: (fileType: FileTypes) => void;
}

export const ExportContactsDialog = ({ isDialogVisible, onDismiss, onExport }: ExportContactsDialogProps) => {
  const fileTypes = Object.keys(FileTypes);
  const [fileType, setFileType] = useState<string>('');
  const { formatMessage } = useIntl();

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 800 } }
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'export-contacts.choose-file-type' })
  };

  const handleSelectFileType = (data: string) => {
    setFileType(data);
  };

  const handleExport = () => {
    onExport(FileTypes[fileType]);
  };

  return (
    <Dialog hidden={!isDialogVisible} onDismiss={onDismiss} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div className="profile-photo-dialog">
        <Dropdown
          items={fileTypes}
          checkable
          placeholder={formatMessage({ id: 'export-contacts.choose-file-type' })}
          onChange={(_, { value }) => handleSelectFileType(value.toString())}
        />
        <DialogFooter>
          <Button
            primary
            onClick={handleExport}
            disabled={fileType !== FileTypes.csv && fileType !== FileTypes.vCard}
            content={formatMessage({ id: 'buttons.export' })}
          />
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onDismiss} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
