import 'sass/shared/profile-panel/ProfilePanel.scss';
import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { ActivitiesAccordionItem, SendingsAccordionItem } from 'app/pages/my-audience/contact-profile';
import { FormattedMessage } from 'app/i18n';

export const Activities = () => {
  const panels = [];

  panels.push({
    key: 'activities',
    title: (
      <div className="accordion-head-box" key="activities-title">
        <span className="accordion-heading">
          <FormattedMessage id={'contacts-activities-accordion.activities'} />
        </span>
        <ChevronDownMediumIcon />
      </div>
    ),
    content: { key: 'activities-content', content: <ActivitiesAccordionItem /> }
  });

  panels.push({
    key: 'sendings',
    title: (
      <div className="accordion-head-box" key="sendings-title">
        <span className="accordion-heading">
          <FormattedMessage id={'contacts-activities-accordion.sendings'} />
        </span>
        <ChevronDownMediumIcon />
      </div>
    ),
    content: { key: 'sendings-content', content: <SendingsAccordionItem /> }
  });

  return <Accordion defaultActiveIndex={[0]} panels={panels} />;
};
