import { useRef, useState } from 'react';
import { DirectionalHint } from '@fluentui/react';
import { Button, Dropdown, DropdownProps, Loader, MenuButton } from '@fluentui/react-northstar';
import { DateRange, EmptyMessage, FilterItem, selectIsOnDesktop, useAppDispatch, useAppSelector } from 'app/common';
import { FilterIcon } from '@fluentui/react-icons-northstar';
import {
  ActivityDetailsDialog,
  closeActivityDetailsDialog,
  closeAddActivityDialog,
  LogActivityDialog,
  openActivityDetailsDialog,
  openAddActivityDialog,
  selectFilteredPastActivitiesCards,
  selectFilteredUpcomingActivitiesCards,
  selectIsActivityDetailsDialogOpened,
  selectIsAddActivityDialogOpened,
  selectIsLoadingActivities,
  setActivityTypeToLog,
  setSelectedActivity,
  ActivitiesList,
  nextPageOfSelectedContactActivitiesRequested,
  addActivitiesFilter,
  removeActivitiesFilter,
  firstPageOfSelectedContactActivitiesRequested,
  selectTotalCountOfActivities,
  selectLoadedActivitiesCount,
  selectIsLoadingMoreActivities
} from 'app/pages/my-audience/contact-profile';
import { ActivityCardModel, ActivityTimelineTypes, ActivityTypes, FilterValuePair } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';
import { ContextualDateFiltersMenu } from 'app/common/components/date-filters-menu';
import { Interval } from 'luxon';
import { Permission, useAuthContext } from 'auth';

const ActivityFilters = {
  TIMELINE: 'timeline',
  ACTIVITY_TYPE: 'activityType',
  DATES: 'dates'
};

export const ActivitiesAccordionItem = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToEdit = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const isLoadingActivities = useAppSelector<boolean>(selectIsLoadingActivities);
  const isLoadingMoreActivities = useAppSelector<boolean>(selectIsLoadingMoreActivities);
  const pastActivities = useAppSelector<ActivityCardModel[]>(selectFilteredPastActivitiesCards);
  const upcomingActivities = useAppSelector<ActivityCardModel[]>(selectFilteredUpcomingActivitiesCards);
  const isActivityDetailDialogOpened = useAppSelector<boolean>(selectIsActivityDetailsDialogOpened);
  const totalCountOfActivities = useAppSelector<number>(selectTotalCountOfActivities);
  const loadedActivitiesCount = useAppSelector<number>(selectLoadedActivitiesCount);
  const isAddActivityDialogOpened = useAppSelector<boolean>(selectIsAddActivityDialogOpened);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  const [isLogActivityDropdownOpen, toggleLogActivityDropdown] = useState(false);
  const timelineFilter = useRef<FilterValuePair>(null);
  const activityTypeFilter = useRef<FilterValuePair>(null);
  const datesFilter = useRef<string>('');
  const [isActive, setActive] = useState<boolean>(false);

  const activityTypesDropDown = [
    { content: formatMessage({ id: 'contacts-activity-types-labels.upcoming-activities' }), key: 'timeline-type-0', type: ActivityTimelineTypes.Upcoming },
    { content: formatMessage({ id: 'contacts-activity-types-labels.past-activities' }), key: 'timeline-type-1', type: ActivityTimelineTypes.Past }
  ];

  const filterMenuItems = [
    { key: 'log-menu-item-0', content: formatMessage({ id: 'contacts-activity-types-labels.email' }), type: ActivityTypes.Email },
    { key: 'log-menu-item-1', content: formatMessage({ id: 'contacts-activity-types-labels.call' }), type: ActivityTypes.Call },
    { key: 'log-menu-item-2', content: formatMessage({ id: 'contacts-activity-types-labels.meeting' }), type: ActivityTypes.Appointment },
    { key: 'log-menu-item-3', content: formatMessage({ id: 'contacts-activity-types-labels.webinar' }), type: ActivityTypes.Webinar },
    { key: 'log-menu-item-4', content: formatMessage({ id: 'contacts-activity-types-labels.event' }), type: ActivityTypes.Event },
    { key: 'log-menu-item-5', content: formatMessage({ id: 'contacts-activity-types-labels.chat' }), type: ActivityTypes.Chat },
    { key: 'log-menu-item-6', content: formatMessage({ id: 'contacts-activity-types-labels.task' }), type: ActivityTypes.Task },
    { key: 'log-menu-item-7', content: formatMessage({ id: 'contacts-activity-types-labels.sending' }), type: ActivityTypes.Sending }
  ];

  const logMenuItems = filterMenuItems.filter((menuItem) => menuItem.type !== ActivityTypes.Sending);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const onTimelineTypeFilterChange = (_, item) => {
    if (!item.value) {
      dispatch(removeActivitiesFilter(createTimelineFilter(timelineFilter.current.value as string)));
      timelineFilter.current = null;
      dispatch(firstPageOfSelectedContactActivitiesRequested(loadedActivitiesCount));
      return;
    }

    if (timelineFilter.current) {
      dispatch(removeActivitiesFilter(createTimelineFilter(timelineFilter.current.value as string)));
    }

    timelineFilter.current = { label: item.value.content, value: createDatesIntervalBasedOnTimelineType(item.value.type) };
    dispatch(addActivitiesFilter(createTimelineFilter(timelineFilter.current.value as string)));
    dispatch(firstPageOfSelectedContactActivitiesRequested(loadedActivitiesCount));
  };

  const onActivityFilterChange = (_, item) => {
    if (!item.value) {
      dispatch(removeActivitiesFilter(new FilterItem(ActivityFilters.ACTIVITY_TYPE, ActivityTypes[activityTypeFilter.current.value])));
      activityTypeFilter.current = null;
      dispatch(firstPageOfSelectedContactActivitiesRequested(loadedActivitiesCount));
      return;
    }

    if (activityTypeFilter.current) {
      dispatch(removeActivitiesFilter(new FilterItem(ActivityFilters.ACTIVITY_TYPE, ActivityTypes[activityTypeFilter.current.value])));
    }

    activityTypeFilter.current = { label: item.value.content, value: item.value.type };
    dispatch(addActivitiesFilter(new FilterItem(ActivityFilters.ACTIVITY_TYPE, ActivityTypes[activityTypeFilter.current.value])));
    dispatch(firstPageOfSelectedContactActivitiesRequested(loadedActivitiesCount));
  };

  const logActivityClick = (_, item) => {
    dispatch(setActivityTypeToLog({ localizedActivityType: item.content, activityType: item.type }));
    dispatch(openAddActivityDialog());
  };

  const onActivityItemClick = (id: string) => {
    dispatch(setSelectedActivity(id));
    dispatch(openActivityDetailsDialog());
  };

  const dismissActivityDetailDialog = () => {
    dispatch(closeActivityDetailsDialog());
  };

  const dismissAddActivityDialog = () => {
    dispatch(closeAddActivityDialog());
  };

  const filterDates = (startDate: Date, endDate: Date) => {
    if (datesFilter.current) {
      const oldFilterItem = new FilterItem(ActivityFilters.DATES, datesFilter.current);
      dispatch(removeActivitiesFilter(oldFilterItem));
    }

    datesFilter.current = createDatesInterval(startDate, endDate);
    const datesFilterItem = new FilterItem(ActivityFilters.DATES, datesFilter.current);
    dispatch(addActivitiesFilter(datesFilterItem));
    dispatch(firstPageOfSelectedContactActivitiesRequested(loadedActivitiesCount));
  };

  const resetDatesFilter = () => {
    const datesFilterItem = new FilterItem(ActivityFilters.DATES, datesFilter.current);
    dispatch(removeActivitiesFilter(datesFilterItem));
    datesFilter.current = '';
    dispatch(firstPageOfSelectedContactActivitiesRequested(loadedActivitiesCount));
  };

  const createDatesInterval = (startDate: Date, endDate: Date): string => {
    const dateRange = DateRange.parse(Interval.fromDateTimes(startDate, endDate));
    return `${dateRange.startDate} - ${dateRange.endDate}`;
  };

  const handleLoadMore = () => {
    dispatch(nextPageOfSelectedContactActivitiesRequested());
  };

  const createDatesIntervalBasedOnTimelineType = (type: ActivityTimelineTypes): string => {
    switch (type) {
      case ActivityTimelineTypes.Upcoming:
        return createDatesInterval(new Date(Date.now()), new Date(86400000000000));
      case ActivityTimelineTypes.Past:
        return createDatesInterval(new Date(0), new Date(Date.now()));
      default:
        return '';
    }
  };

  const createTimelineFilter = (datesInterval: string) => {
    return new FilterItem(ActivityFilters.DATES, datesInterval);
  };

  const allLoadingActivities = isLoadingActivities || isLoadingMoreActivities;

  const showLoadMoreButton = loadedActivitiesCount < totalCountOfActivities && !allLoadingActivities;

  const showEmptyMessage = !upcomingActivities.concat(pastActivities).length && !allLoadingActivities;
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const handleBlur1 = () => setIsOpen1(false);
  const handleBlur2 = () => setIsOpen2(false);
  return (
    <div className="profile-panel-activities-wrap">
      <div className="profile-panel-activities-intro">
        <div className="profile-panel-activities-button">
          {isOnDesktop && (
            <MenuButton
              open={isLogActivityDropdownOpen}
              onOpenChange={(_, { open }) => toggleLogActivityDropdown(open)}
              trigger={<Button disabled={!hasPermissionToEdit} primary content={formatMessage({ id: 'contacts-add-activity.log-activity-button' })} />}
              menu={logMenuItems}
              onMenuItemClick={(event, item) => logActivityClick(event, item)}
            />
          )}
        </div>
        <div className="profile-panel-activites-dropdowns">
          <div className="button-filter-wrap">
            <Button
              tinted
              iconOnly
              title={formatMessage({ id: 'contacts-activities-filter.button-content' })}
              content={formatMessage({ id: 'contacts-activities-filter.button-content' })}
              onClick={handleToggle}
              icon={<FilterIcon />}
              className="button-filter"
            />
          </div>
          <div className={`profile-panel-activities-dropdown-wrap ${isActive ? 'active-dropdown' : ''}`}>
            <div className="profile-panel-activities-dropdown">
              <Dropdown
                fluid
                clearable
                items={activityTypesDropDown}
                placeholder={formatMessage({ id: 'contacts-activities-filter.menu-items.by-type' })}
                onChange={(event, item) => onTimelineTypeFilterChange(event, item)}
                value={timelineFilter.current?.label}
                onBlur={handleBlur1}
                open={isOpen1}
                onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen1(props.open || false)}
              />
            </div>
            <div className="profile-panel-activities-dropdown">
              <Dropdown
                fluid
                clearable
                items={filterMenuItems}
                placeholder={formatMessage({ id: 'contacts-activities-filter.menu-items.by-activity' })}
                onChange={(event, item) => onActivityFilterChange(event, item)}
                value={activityTypeFilter.current?.label}
                onBlur={handleBlur2}
                open={isOpen2}
                onOpenChange={(e: React.SyntheticEvent, props: DropdownProps) => setIsOpen2(props.open || false)}
              />
            </div>
            <div className="profile-panel-activities-dropdown">
              <ContextualDateFiltersMenu direction={DirectionalHint.bottomLeftEdge} onFilter={filterDates} onResetFilter={resetDatesFilter} />
            </div>
          </div>
        </div>
      </div>
      {isLoadingActivities ? (
        <Loader size="medium" labelPosition="below" label={formatMessage({ id: 'filters.loading' })} />
      ) : (
        <div>
          {upcomingActivities.length > 0 && (
            <ActivitiesList
              title={formatMessage({ id: 'contacts-activity-types-labels.upcoming-activities' })}
              activities={upcomingActivities}
              onCardClick={onActivityItemClick}
            />
          )}
          {pastActivities.length > 0 && (
            <ActivitiesList
              title={formatMessage({ id: 'contacts-activity-types-labels.past-activities' })}
              activities={pastActivities}
              onCardClick={onActivityItemClick}
            />
          )}
        </div>
      )}
      <div className="profile-panel-activities-button-container">
        {showLoadMoreButton && (
          <Button content={formatMessage({ id: 'labels.load-more' })} title={formatMessage({ id: 'labels.load-more' })} onClick={handleLoadMore} />
        )}
        {isLoadingMoreActivities && <Loader size="medium" labelPosition="below" label={formatMessage({ id: 'filters.loading-more' })} />}
        {showEmptyMessage && (
          <div className="contacts-activity-empty">
            <EmptyMessage
              srcValue="/wizard-empty-info2.svg"
              textValueOptional={formatMessage({ id: 'contacts-add-activity.log-no-msg' })}
              textValue={formatMessage({ id: 'contacts-add-activity.log-no-msg2' })}
            />
          </div>
        )}
      </div>
      {isAddActivityDialogOpened && <LogActivityDialog opened={isAddActivityDialogOpened} dismiss={dismissAddActivityDialog} />}
      {isActivityDetailDialogOpened && <ActivityDetailsDialog opened={isActivityDetailDialogOpened} dismiss={dismissActivityDetailDialog} />}
    </div>
  );
};
