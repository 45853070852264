import { Button } from '@fluentui/react-northstar';
import { DatePicker } from 'app/common';
import { getFirstAndLastDaysOfTheCurrentMonth, getFirstAndLastDaysOfTheCurrentWeek } from 'app/common/utils/dates';
import { FormattedMessage, useIntl } from 'app/i18n';
import { DateTime } from 'luxon';
import { useRef } from 'react';

interface DateFiltersMenuProps {
  isOnTouch: boolean;
  isOnDesktop: boolean;
  onFilter: (startDate: Date, endDate: Date) => void;
  onResetFilter: () => void;
  toggleOverlay: (value: boolean) => void;
}

export const DateFiltersMenu = ({ isOnTouch, isOnDesktop, onFilter, onResetFilter, toggleOverlay }: DateFiltersMenuProps) => {
  const { formatMessage } = useIntl();

  const startDate = useRef<Date>(new Date());
  const endDate = useRef<Date>(new Date());

  const applyDateFilter = () => {
    onFilter(startDate.current, endDate.current);
    if (isOnTouch) toggleOverlay(false);
  };

  const applyThisWeekFilter = () => {
    const [firstDay, lastDay] = getFirstAndLastDaysOfTheCurrentWeek();
    setDates(firstDay, lastDay);
    isOnDesktop && applyDateFilter();
  };

  const applyThisMonthFilter = () => {
    const [firstDay, lastDay] = getFirstAndLastDaysOfTheCurrentMonth();
    setDates(firstDay, lastDay);
    isOnDesktop && applyDateFilter();
  };

  const applyPastMonthFilter = () => {
    const today = DateTime.utc().toJSDate();
    const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    setDates(firstDay, today);
    isOnDesktop && applyDateFilter();
  };

  const applyPastThreeMonthFilter = () => {
    const today = DateTime.utc().toJSDate();
    const firstDay = new Date(today.getFullYear(), today.getMonth() - 3, 1);
    setDates(firstDay, today);
    isOnDesktop && applyDateFilter();
  };

  const resetDateFilters = () => {
    onResetFilter();
    if (isOnTouch) toggleOverlay(false);
  };

  const setStartDate = (date: Date) => {
    startDate.current = date;
  };

  const setEndDate = (date: Date) => {
    endDate.current = new Date(date.setHours(23, 59, 59, 999));
  };

  const setDates = (start: Date, end: Date) => {
    startDate.current = start;
    endDate.current = end;
  };

  return (
    <div className="e-items">
      <div className="activities-date-datepickers">
        <div className="activities-date-datepicker">
          <label className="activities-date-label">
            <FormattedMessage id={'filter-by-date-popup.from-label'} />
          </label>
          <DatePicker onSelectDate={setStartDate} />
        </div>
        <div className="activities-date-datepicker">
          <label className="activities-date-label">
            <FormattedMessage id={'filter-by-date-popup.to-label'} />
          </label>
          <DatePicker onSelectDate={setEndDate} />
        </div>
      </div>
      <div className="activities-date-buttons">
        <Button onClick={applyThisWeekFilter}>
          <FormattedMessage id={'filter-by-date-popup.buttons.this-week'} />
        </Button>
        <Button onClick={applyThisMonthFilter}>
          <FormattedMessage id={'filter-by-date-popup.buttons.this-month'} />
        </Button>
        <Button onClick={applyPastMonthFilter}>
          <FormattedMessage id={'filter-by-date-popup.buttons.past-month'} />
        </Button>
        <Button onClick={applyPastThreeMonthFilter}>
          <FormattedMessage id={'filter-by-date-popup.buttons.past-three-months'} />
        </Button>
      </div>
      <div className="activities-date-actions">
        <div className="activities-date-cancel-button">
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={resetDateFilters} />
        </div>
        <div className="activities-date-accept-button">
          <Button primary content={formatMessage({ id: 'buttons.apply' })} onClick={applyDateFilter} />
        </div>
      </div>
    </div>
  );
};
