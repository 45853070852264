import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  categoryFilterSuggestionsReceived,
  categoryFilterSuggestionsRequested,
  contactNameFilterSuggestionsReceived,
  contactNameFilterSuggestionsRequested,
  fetchMediaOutletsByQueryParamsPaged,
  fetchMediaOutletSuggestions,
  fetchMediaOutletSuggestionsByCategory,
  fetchMediaOutletSuggestionsByContactName,
  fetchMediaOutletSuggestionsByLanguage,
  fetchMediaOutletSuggestionsByName,
  fetchMediaOutletSuggestionsByTagName,
  firstPageOfMediaOutletsReceived,
  firstPageOfMediaOutletsRequested,
  languageFilterSuggestionsReceived,
  languageFilterSuggestionsRequested,
  MediaOutlet,
  mediaOutletNameFilterSuggestionsReceived,
  mediaOutletNameFilterSuggestionsRequested,
  mediaOutletsByIdsRemoved,
  MediaOutletsSortingInput,
  mediaOutletSuggestionsReceived,
  mediaOutletSuggestionsRequested,
  mediaOutletTransformedIntoCompany,
  nextPageOfMediaOutletsReceived,
  nextPageOfMediaOutletsRequested,
  PageOfMediaOutlets,
  removedMediaOutlet,
  removeMediaOutletRequested,
  removeMediaOutletsByIdsRequested,
  selectMediaResortsIdsForBulkUpdate,
  selectFilterSuggestionsPageSize,
  selectFilterSuggestionsSearchText,
  selectMediaOutletsToRemoveIds,
  selectPageNumber,
  selectPageSize,
  selectSearchSuggestionText,
  selectSearchText,
  selectSelectedMediaOutlets,
  selectSortingInput,
  setTotalCountOfMediaOutlets,
  tagNameFilterSuggestionsReceived,
  tagNameFilterSuggestionsRequested,
  transformMediaOutletIntoCompany,
  transformMediaOutletIntoCompanyRequested,
  allTagsReceived,
  getAllMediaOutletsTagsRequested,
  selectTagToAddToMediaOutlets,
  addTagToMediaOutlets,
  addTagToMediaOutletsRequested,
  selectTagToRemoveFromMediaOutlets,
  removeTagFromMediaOutlets,
  removeTagFromMediaOutletsRequested,
  selectTagsToDelete,
  tagsDeleted,
  deleteTagsRequested,
  selectTagNameToCreate,
  addedNewTag,
  addNewTagRequested,
  selectMediaOutlets,
  addedTagToMediaOutletsSearchResults,
  removedTagsFromMediaOutletsSearchResults,
  removeMediaOutletsByIds,
  updateBulkMediaOutlets,
  updateMediaOutletsRequested,
  mediaOutletsUpdated,
  MediaOutletPanelData,
  fetchMediaOutletsData,
  mediaOutletPanelDataRequested,
  mediaOutletPanelDataReceived,
  fetchReferenceListsLocalization,
  selectFilterItems,
  selectSelectedMediaOutletIds,
  addMediaResortToMediaOutletInTable,
  selectMediaResortsForBulkUpdate,
  addMediumCategory,
  selectMediumCategoryNameToAdd,
  mediumCategoryAdded,
  addMediumCategoryRequested,
  selectMediumCategoryIdToRemove,
  removeMediumCategory,
  mediumCategoryRemoved,
  removeMediumCategoryRequested,
  selectMediaResortToCreate,
  mediaResortCreated,
  createMediaResortRequested
} from 'app/pages/my-audience/media-outlets';
import { client } from 'app/common/graphql/graphql-gateway.client';
import {
  addedNewMediaResort,
  addedTagToMediaOutlet,
  addMediaResort,
  closeProfilePanel,
  MediaResort,
  removedTagsFromMediaOutlet,
  removeMediaOutlet,
  selectSelectedMediaOutlet
} from 'app/pages/my-audience/media-outlet-profile';
import {
  closeDeleteEntitiesDialog,
  createAndAssignTag,
  deleteTags,
  fetchTagsByEntityType,
  FilterItem,
  safe,
  SearchSuggestionCategory,
  selectSelectedTableRowsIdsSelector,
  TagCreationInput
} from 'app/common';
import { referenceListLocalizationReceived, referenceListLocalizationRequested, selectLocale } from 'app/localization';

export function* fetchMediaOutletsPagedWatcher() {
  yield takeEvery(firstPageOfMediaOutletsRequested.type, safe(fetchMediaOutletsPagedFlow));
  yield takeEvery(nextPageOfMediaOutletsRequested.type, safe(fetchMediaOutletsPagedFlow));
}

function* fetchMediaOutletsPagedFlow() {
  const pageNumber: number = yield select(selectPageNumber);
  const pageSize: number = yield select(selectPageSize);
  const searchText: string = yield select(selectSearchText);
  const filterItems: FilterItem[] = yield select(selectFilterItems);
  const sortingInput: MediaOutletsSortingInput = yield select(selectSortingInput);

  const pageOfMediaOutlets: PageOfMediaOutlets = yield call(
    fetchMediaOutletsByQueryParamsPaged,
    client,
    searchText,
    filterItems,
    (pageNumber - 1) * pageSize,
    pageSize,
    sortingInput
  );

  if (pageNumber === 1) {
    yield put(firstPageOfMediaOutletsReceived(pageOfMediaOutlets.mediaOutlets));
  } else {
    yield put(nextPageOfMediaOutletsReceived(pageOfMediaOutlets.mediaOutlets));
  }

  yield put(setTotalCountOfMediaOutlets(pageOfMediaOutlets.totalCount));
}

export function* mediaOutletSuggestionsRequestedWatcher() {
  yield takeLatest(mediaOutletSuggestionsRequested.type, safe(fetchMediaOutletSuggestionsFlow));
}

function* fetchMediaOutletSuggestionsFlow() {
  const searchText: string = yield select(selectSearchSuggestionText);
  const language = yield select(selectLocale);
  const mediaOutletSuggestions: SearchSuggestionCategory[] = yield call(fetchMediaOutletSuggestions, client, searchText, language);
  yield put(mediaOutletSuggestionsReceived(mediaOutletSuggestions));
}

function* removeMediaOutletFlow() {
  const selectedMediaOutlet: MediaOutlet = yield select(selectSelectedMediaOutlet);

  const removeMediaOutletId: string = yield call(removeMediaOutlet, client, selectedMediaOutlet.id);
  yield put(removedMediaOutlet(removeMediaOutletId));
}

export function* removeMediaOutletRequestedWatcher() {
  yield takeEvery(removeMediaOutletRequested.type, safe(removeMediaOutletFlow));
}

function* transformMediaOutletIntoCompanyFlow() {
  const selectedMediaOutlet: MediaOutlet = yield select(selectSelectedMediaOutlet);

  const transformedMediaOutletId: string = yield call(transformMediaOutletIntoCompany, client, selectedMediaOutlet.id);
  yield put(mediaOutletTransformedIntoCompany(transformedMediaOutletId));
  yield put(closeProfilePanel());
}

export function* transformMediaOutletIntoCompanyRequestedWatcher() {
  yield takeEvery(transformMediaOutletIntoCompanyRequested.type, safe(transformMediaOutletIntoCompanyFlow));
}

function* mediaOutletNameFilterSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const mediaOutlets = yield call(fetchMediaOutletSuggestionsByName, client, searchText, pageSize);
  yield put(mediaOutletNameFilterSuggestionsReceived(mediaOutlets));
}

export function* mediaOutletNameFilterSuggestionsRequestedWatcher() {
  yield takeEvery(mediaOutletNameFilterSuggestionsRequested.type, safe(mediaOutletNameFilterSuggestionsFlow));
}

function* contactNameFilterSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const mediaOutlets = yield call(fetchMediaOutletSuggestionsByContactName, client, searchText, pageSize);
  yield put(contactNameFilterSuggestionsReceived(mediaOutlets));
}

export function* contactFilterSuggestionsRequestedWatcher() {
  yield takeEvery(contactNameFilterSuggestionsRequested.type, safe(contactNameFilterSuggestionsFlow));
}

function* tagNameFilterSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const mediaOutlets = yield call(fetchMediaOutletSuggestionsByTagName, client, searchText, pageSize);
  yield put(tagNameFilterSuggestionsReceived(mediaOutlets));
}

export function* tagFilterSuggestionsRequestedWatcher() {
  yield takeEvery(tagNameFilterSuggestionsRequested.type, safe(tagNameFilterSuggestionsFlow));
}

function* categoryFilterSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const mediaOutlets = yield call(fetchMediaOutletSuggestionsByCategory, client, searchText, pageSize);
  yield put(categoryFilterSuggestionsReceived(mediaOutlets));
}

export function* categoryFilterSuggestionsRequestedWatcher() {
  yield takeEvery(categoryFilterSuggestionsRequested.type, safe(categoryFilterSuggestionsFlow));
}

function* languageFilterSuggestionsFlow() {
  const searchText = yield select(selectFilterSuggestionsSearchText);
  const pageSize = yield select(selectFilterSuggestionsPageSize);

  const mediaOutlets = yield call(fetchMediaOutletSuggestionsByLanguage, client, searchText, pageSize);
  yield put(languageFilterSuggestionsReceived(mediaOutlets));
}

export function* languageFilterSuggestionsRequestedWatcher() {
  yield takeEvery(languageFilterSuggestionsRequested.type, safe(languageFilterSuggestionsFlow));
}

function* removeMediaOutletsByIdsFlow() {
  const mediaOutletsToRemoveIds: string[] = yield select(selectMediaOutletsToRemoveIds);

  const mediaOutlets: MediaOutlet[] = yield call(removeMediaOutletsByIds, client, mediaOutletsToRemoveIds);
  yield put(mediaOutletsByIdsRemoved(mediaOutlets));
  yield put(closeDeleteEntitiesDialog());
}

export function* removeMediaOutletsByIdsWatcher() {
  yield takeEvery(removeMediaOutletsByIdsRequested.type, safe(removeMediaOutletsByIdsFlow));
}

function* fetchAllMediaOutletTagsFlow() {
  const allTags = yield call(fetchTagsByEntityType, client, 'MediaOutlet');
  yield put(allTagsReceived(allTags));
}

export function* getAllMediaOutletTagsRequestedWatcher() {
  yield takeEvery(getAllMediaOutletsTagsRequested.type, safe(fetchAllMediaOutletTagsFlow));
}

function* addTagToMediaOutletsFlow() {
  const tagToAdd = yield select(selectTagToAddToMediaOutlets);
  const selectedMediaOutletIds = yield select(selectSelectedMediaOutletIds);
  yield call(addTagToMediaOutlets, client, selectedMediaOutletIds, tagToAdd);
}

export function* addTagToMediaOutletsRequestedWatcher() {
  yield takeEvery(addTagToMediaOutletsRequested.type, safe(addTagToMediaOutletsFlow));
}

function* removeTagFromMediaOutletsFlow() {
  const tagToRemove = yield select(selectTagToRemoveFromMediaOutlets);
  const selectedMediaOutletIds = yield select(selectSelectedMediaOutletIds);
  yield call(removeTagFromMediaOutlets, client, selectedMediaOutletIds, tagToRemove);
  yield put(removedTagsFromMediaOutlet([tagToRemove]));
  yield put(removedTagsFromMediaOutletsSearchResults({ mediaOutletIds: selectedMediaOutletIds, tags: [tagToRemove] }));
}

export function* removeTagFromMediaOutletsRequestedWatcher() {
  yield takeEvery(removeTagFromMediaOutletsRequested.type, safe(removeTagFromMediaOutletsFlow));
}

function* deleteTagsFlow() {
  const tagsToDelete = yield select(selectTagsToDelete);
  const tagIdsToDelete = tagsToDelete.map((t) => t.id);
  const selectedMediaOutletIds = (yield select(selectMediaOutlets)).map((c) => c.id);
  yield call(deleteTags, client, tagIdsToDelete);
  yield put(tagsDeleted(tagIdsToDelete));
  yield put(removedTagsFromMediaOutlet(tagsToDelete));
  yield put(removedTagsFromMediaOutletsSearchResults({ mediaOutletIds: selectedMediaOutletIds, tags: tagsToDelete }));
}

export function* deleteTagsFromMediaOutletsRequestedWatcher() {
  yield takeEvery(deleteTagsRequested.type, safe(deleteTagsFlow));
}

function* addTagFlow() {
  const selectedMediaOutletIds: string[] = yield select(selectSelectedTableRowsIdsSelector);
  const tagToAdd = yield select(selectTagNameToCreate);
  const tagCreationInput = new TagCreationInput(tagToAdd, null, selectedMediaOutletIds, 'MediaOutlet');

  const tag = yield call(createAndAssignTag, client, tagCreationInput);
  yield put(addedTagToMediaOutlet(tag));
  yield put(addedNewTag(tag));
  yield put(addTagToMediaOutletsRequested({ mediaOutletIds: selectedMediaOutletIds, tag }));
  yield put(addedTagToMediaOutletsSearchResults({ mediaOutletIds: selectedMediaOutletIds, tag }));
}

export function* addMediaOutletTagRequestedWatcher() {
  yield takeEvery(addNewTagRequested.type, safe(addTagFlow));
}

function* updateMediaOutletsFlow() {
  const mediaResorts = yield select(selectMediaResortsForBulkUpdate);
  const mediaResortsIds = yield select(selectMediaResortsIdsForBulkUpdate);
  const selectedMediaOutlets = yield select(selectSelectedMediaOutlets);

  const mediaOutlets = yield call(
    updateBulkMediaOutlets,
    client,
    selectedMediaOutlets.map((mediaOutlet) => mediaOutlet.id),
    mediaResortsIds
  );

  yield put(mediaOutletsUpdated(mediaOutlets));
  yield put(addMediaResortToMediaOutletInTable({ mediaOutletIds: selectedMediaOutlets.map((mo) => mo.id), mediaResorts }));
}

export function* updateMediaOutletsRequestedWatcher() {
  yield takeEvery(updateMediaOutletsRequested.type, safe(updateMediaOutletsFlow));
}

function* fetchMediaOutletPanelDataFlow() {
  const data: MediaOutletPanelData = yield call(fetchMediaOutletsData, client);

  yield put(mediaOutletPanelDataReceived(data));
}

export function* fetchMediaOutletPanelDataRequestedWatcher() {
  yield takeEvery(mediaOutletPanelDataRequested.type, safe(fetchMediaOutletPanelDataFlow));
}

function* fetchReferenceListsLocalizationFlow() {
  const referenceLists = yield call(fetchReferenceListsLocalization, client);
  yield put(referenceListLocalizationReceived(referenceLists));
}

export function* fetchReferenceListsLocalizationRequestedWatcher() {
  yield takeEvery(referenceListLocalizationRequested.type, safe(fetchReferenceListsLocalizationFlow));
}

function* addMediumCategoryFlow() {
  const mediumCategoryNameToAdd: string = yield select(selectMediumCategoryNameToAdd);

  const mediumCategory = yield call(addMediumCategory, client, mediumCategoryNameToAdd);

  yield put(mediumCategoryAdded(mediumCategory));
}

export function* addMediumCategoryRequestedWatcher() {
  yield takeEvery(addMediumCategoryRequested.type, safe(addMediumCategoryFlow));
}

function* removeMediumCategoryFlow() {
  const mediumCategoryIdToRemove: string = yield select(selectMediumCategoryIdToRemove);

  const mediumCategory = yield call(removeMediumCategory, client, mediumCategoryIdToRemove);

  yield put(mediumCategoryRemoved(mediumCategory));
}

export function* removeMediumCategoryRequestedWatcher() {
  yield takeEvery(removeMediumCategoryRequested.type, safe(removeMediumCategoryFlow));
}

export function* createMediaResortFlow() {
  const mediaResort: MediaResort = yield select(selectMediaResortToCreate);

  const createdMediaResort: MediaResort = yield call(addMediaResort, client, mediaResort);

  yield put(mediaResortCreated(createdMediaResort));
  yield put(addedNewMediaResort(createdMediaResort));
}

export function* createMediaResortsWatcher() {
  yield takeEvery(createMediaResortRequested.type, safe(createMediaResortFlow));
}
