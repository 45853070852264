import { useAppSelector } from 'app/common';
import { FiltersPanel, selectTemplates, Template, TemplateCard, TemplatesOverviewTop, TemplatesPaging } from 'app/pages/my-activities/templates';
import 'app/pages/my-activities/templates/components/Templates.scss';

interface TemplatesOverviewProps {
  onChooseTemplate: (template: Template) => void;
}

export const TemplatesOverview = ({ onChooseTemplate }: TemplatesOverviewProps) => {
  const templates = useAppSelector<Template[]>(selectTemplates);

  return (
    <>
      <div className="templates">
        <TemplatesOverviewTop></TemplatesOverviewTop>
      </div>
      <div className="templates-cards">
        {templates.map((template) => (
          <TemplateCard key={template.id} template={template} onChooseTemplate={onChooseTemplate} />
        ))}
      </div>
      <FiltersPanel />
      <TemplatesPaging />
    </>
  );
};
