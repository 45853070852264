import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfMediaOutletsAndCompaniesRequested,
  selectIsLoadingMediaOutletsAndCompanies,
  selectPageSize,
  selectTotalCountOfMediaOutletsAndCompaniesAndCompanies,
  setPageNumber,
  selectPageNumber,
  setPageSize,
  resetCollaborationsTable
} from 'app/pages/my-audience/wizard-create-contact';

export const CollaborationTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCount = useAppSelector<number>(selectTotalCountOfMediaOutletsAndCompaniesAndCompanies);
  const isLoadingMediaOutletsAndCompanies = useAppSelector<boolean>(selectIsLoadingMediaOutletsAndCompanies);

  const pageSize = useAppSelector<number>(selectPageSize);
  const pageNumber = useAppSelector<number>(selectPageNumber);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
    dispatch(pageOfMediaOutletsAndCompaniesRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setPageNumber(1));
    dispatch(setPageSize(pageSize));
    dispatch(resetCollaborationsTable());
    dispatch(pageOfMediaOutletsAndCompaniesRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCount}
      isLoadingData={isLoadingMediaOutletsAndCompanies}
      page={pageNumber}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
