import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AddCompanySavedSearchDocument,
  AddCompanySavedSearchMutation,
  CompaniesSavedSearchInput,
  CompaniesSavedSearchWithIdInput,
  FetchCompaniesAvatarDetailsDocument,
  FetchCompaniesAvatarDetailsQuery,
  FetchCompaniesSavedSearchesPagedDocument,
  FetchCompaniesSavedSearchesPagedQuery,
  RemoveCompanySavedSearchDocument,
  RemoveCompanySavedSearchMutation,
  UpdateCompanySavedSearchDocument,
  UpdateCompanySavedSearchesDocument,
  UpdateCompanySavedSearchesMutation,
  UpdateCompanySavedSearchMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { AvatarDetailsInput, AvatarDetailsWithSavedSearchId, FilterItem } from 'app/common';
import {
  AvatarDetails,
  createSavedSearch,
  PageOfSavedSearches,
  SavedSearch,
  SavedSearchInput,
  SavedSearchWithIdInput
} from 'app/pages/my-audience/saved-searches';

export const fetchCompanySavedSearchesPaged = async (
  client: ApolloClient<NormalizedCacheObject>,
  skip: number,
  take: number,
  searchText: string,
  filterItems: FilterItem[]
): Promise<PageOfSavedSearches> => {
  const response = await client.query<FetchCompaniesSavedSearchesPagedQuery>({
    query: FetchCompaniesSavedSearchesPagedDocument,
    variables: {
      skip,
      take,
      searchText,
      filterItems
    }
  });

  return new PageOfSavedSearches(
    response.data.companiesSavedSearches.totalCount,
    response.data.companiesSavedSearches.savedSearches.map((savedSearch) => createSavedSearch(savedSearch))
  );
};

export const updateCompanySavedSearch = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  savedSearch: SavedSearchInput
): Promise<SavedSearch> => {
  const savedCompanySearch: CompaniesSavedSearchInput = {
    name: savedSearch.name,
    filterItems: savedSearch.filterItems,
    searchText: savedSearch.searchText
  };

  const response = await client.mutate<UpdateCompanySavedSearchMutation>({
    mutation: UpdateCompanySavedSearchDocument,
    variables: {
      id,
      savedCompanySearch
    }
  });

  return new SavedSearch(
    response.data.updateCompanySavedSearch.id,
    response.data.updateCompanySavedSearch.name,
    response.data.updateCompanySavedSearch.searchText,
    response.data.updateCompanySavedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.updateCompanySavedSearch.priority,
    response.data.updateCompanySavedSearch.count,
    new Date(response.data.updateCompanySavedSearch.createdAt),
    new Date(response.data.updateCompanySavedSearch.lastModifiedAt),
    response.data.updateCompanySavedSearch.createdBy
  );
};

export const updateCompanySavedSearches = async (
  client: ApolloClient<NormalizedCacheObject>,
  savedSearches: SavedSearchWithIdInput[]
): Promise<SavedSearch[]> => {
  const savedCompanySearches: CompaniesSavedSearchWithIdInput[] = savedSearches.map((ss) => ({
    id: ss.id,
    count: ss.count,
    filterItems: ss.filterItems,
    name: ss.name,
    priority: ss.priority,
    searchText: ss.searchText
  }));

  const response = await client.mutate<UpdateCompanySavedSearchesMutation>({
    mutation: UpdateCompanySavedSearchesDocument,
    variables: {
      savedCompanySearches
    }
  });

  return response.data.updateCompanySavedSearches.map(
    (node) =>
      new SavedSearch(
        node.id,
        node.name,
        node.searchText,
        node.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
        node.priority,
        node.count,
        new Date(node.createdAt),
        new Date(node.lastModifiedAt),
        node.createdBy
      )
  );
};

export const addCompanySavedSearch = async (client: ApolloClient<NormalizedCacheObject>, savedSearch: SavedSearchInput): Promise<SavedSearch> => {
  const savedCompanySearch: CompaniesSavedSearchInput = {
    name: savedSearch.name,
    filterItems: savedSearch.filterItems,
    searchText: savedSearch.searchText
  };
  const response = await client.mutate<AddCompanySavedSearchMutation>({
    mutation: AddCompanySavedSearchDocument,
    variables: {
      savedCompanySearch
    }
  });

  return new SavedSearch(
    response.data.saveCompanySearch.id,
    response.data.saveCompanySearch.name,
    response.data.saveCompanySearch.searchText,
    response.data.saveCompanySearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.saveCompanySearch.priority,
    response.data.saveCompanySearch.count,
    new Date(response.data.saveCompanySearch.createdAt),
    new Date(response.data.saveCompanySearch.lastModifiedAt),
    response.data.saveCompanySearch.createdBy
  );
};

export const removeCompanySavedSearch = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<SavedSearch> => {
  const response = await client.mutate<RemoveCompanySavedSearchMutation>({
    mutation: RemoveCompanySavedSearchDocument,
    variables: {
      id
    }
  });

  return new SavedSearch(
    response.data.removeCompanySavedSearch.id,
    response.data.removeCompanySavedSearch.name,
    response.data.removeCompanySavedSearch.searchText,
    response.data.removeCompanySavedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.removeCompanySavedSearch.priority,
    response.data.removeCompanySavedSearch.count,
    new Date(response.data.removeCompanySavedSearch.createdAt),
    new Date(response.data.removeCompanySavedSearch.lastModifiedAt),
    response.data.removeCompanySavedSearch.createdBy
  );
};

export const fetchCompaniesAvatarDetails = async (
  client: ApolloClient<NormalizedCacheObject>,
  companiesAvatarDetailsInputs: AvatarDetailsInput[]
): Promise<AvatarDetailsWithSavedSearchId[]> => {
  const response = await client.query<FetchCompaniesAvatarDetailsQuery>({
    query: FetchCompaniesAvatarDetailsDocument,
    variables: {
      companiesAvatarDetails: companiesAvatarDetailsInputs
    }
  });

  return response.data.companiesAvatarDetails.map(
    (contactsQueryInput) =>
      new AvatarDetailsWithSavedSearchId(
        contactsQueryInput.savedSearchId,
        contactsQueryInput.pageOfCompanies.companies.map((company) => new AvatarDetails(`${company.name}`, company.profilePictureUrl)),
        contactsQueryInput.pageOfCompanies.totalCount
      )
  );
};
