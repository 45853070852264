import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  CreateCompanyGeneralInfo,
  closeCreateCompanyWizard,
  selectCompanyCurrentStep,
  CreateCompanyFromContactStep,
  selectIsCreateCompanyWizardOpened,
  CreateCompanyAddressInfo,
  CreateCompanyFinish
} from 'app/pages/my-audience/wizard-create-contact';

export const CreateCompanyWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isCreateCompanyWizardOpened = useAppSelector<boolean>(selectIsCreateCompanyWizardOpened);
  const currentStep = useAppSelector<CreateCompanyFromContactStep>(selectCompanyCurrentStep);

  const handleDismissButtonClick = () => {
    dispatch(closeCreateCompanyWizard());
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isCreateCompanyWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'add-menu-create-contact.company-header-title' })}
      customWidth="700px"
    >
      <div className="wizard-panel-main">
        {currentStep === CreateCompanyFromContactStep.GeneralInfo && <CreateCompanyGeneralInfo />}
        {currentStep === CreateCompanyFromContactStep.Address && <CreateCompanyAddressInfo />}
        {currentStep === CreateCompanyFromContactStep.Finish && <CreateCompanyFinish />}
      </div>
    </WizardWrap>
  );
};
