import { Button, Popup } from '@fluentui/react-northstar';
import {
  DisableUnauthorized,
  hideUndoAlert,
  PillData,
  PillsPopupContent,
  SearchResultTableData,
  selectSelectedTableRows,
  showUndoAlert,
  Tag,
  undoQueue,
  useAppDispatch,
  useAppSelector,
  useSelectedTags
} from 'app/common';
import {
  addNewTagRequested,
  addTagToMediaOutletsRequested,
  deleteTagsRequested,
  filterTags,
  removeTagFromMediaOutletsRequested,
  selectAllTags,
  selectFilteredTagsInPanel,
  MediaOutlet,
  selectMediaOutlets,
  addedTagToMediaOutletsSearchResults,
  removedTagsFromMediaOutletsSearchResults
} from 'app/pages/my-audience/media-outlets';
import { addedTagToMediaOutlet, removedTagsFromMediaOutlet } from 'app/pages/my-audience/media-outlet-profile';
import { useIntl } from 'app/i18n';
import { Permission } from 'auth';

export const MediaOutletsTagsPopup = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedMediaOutletsTableRows = useAppSelector<SearchResultTableData>(selectSelectedTableRows);
  const mediaOutletIds = selectedMediaOutletsTableRows.map((mo) => mo.id);
  const allMediaOutlets = useAppSelector<MediaOutlet[]>(selectMediaOutlets);
  const selectedTags = useSelectedTags(selectedMediaOutletsTableRows, allMediaOutlets);

  const allTags = useAppSelector<Tag[]>(selectAllTags);
  const filteredTags = useAppSelector<Tag[]>(selectFilteredTagsInPanel);

  const addNewTag = (text: string) => {
    dispatch(addNewTagRequested({ mediaOutletIds, text }));
  };

  const onSelectValueHandler = (id: string) => {
    selectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => addTagToMediaOutlets(id),
      undoAction: () => deselectValueInStore(id)
    });

    dispatch(showUndoAlert(formatMessage({ id: 'search-results-table.undo-select-tag' })));
  };

  const selectValueInStore = (id: string) => {
    const tag = allTags.find((tag) => tag.id === id);
    dispatch(addedTagToMediaOutletsSearchResults({ mediaOutletIds, tag }));
    dispatch(addedTagToMediaOutlet(tag));
  };

  const deselectValueInStore = (id: string) => {
    const tag = allTags.find((tag) => tag.id === id);
    dispatch(removedTagsFromMediaOutlet([tag]));
    dispatch(removedTagsFromMediaOutletsSearchResults({ mediaOutletIds, tags: [tag] }));
    dispatch(hideUndoAlert());
  };

  const onDeselectValueHandler = (id: string) => {
    deselectValueInStore(id);
    undoQueue.waitAndExecute({
      executeAction: () => removeTagFromMediaOutlets(id),
      undoAction: () => addTagToMediaOutlets(id)
    });

    dispatch(showUndoAlert(formatMessage({ id: 'search-results-table.undo-deselect-tag' })));
  };

  const addTagToMediaOutlets = (id: string) => {
    const tag = allTags.find((tag) => tag.id === id);
    dispatch(addTagToMediaOutletsRequested({ mediaOutletIds, tag }));
    dispatch(hideUndoAlert());
  };

  const deleteTags = (tagIds: string[]) => {
    const tagsToDelete = allTags.filter((t) => tagIds.includes(t.id));
    dispatch(deleteTagsRequested(tagsToDelete));
  };

  const onFilterTags = (text: string) => dispatch(filterTags(text));

  const removeTagFromMediaOutlets = (id: string) => {
    const tag = allTags.find((tag) => tag.id === id);
    dispatch(removeTagFromMediaOutletsRequested({ mediaOutletIds, tag }));
    dispatch(hideUndoAlert());
  };

  return (
    <Popup
      trigger={
        <div>
          <DisableUnauthorized permissions={[Permission.ManageMediaOutlets]}>
            <Button text content={formatMessage({ id: 'search-results-table.add-update-tag' })} />
          </DisableUnauthorized>
        </div>
      }
      key="tags"
      inline={true}
      content={
        <PillsPopupContent
          entityName={formatMessage({ id: 'labels.tag' })}
          selectedValues={selectedTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          dropdownValues={filteredTags?.map((t) => new PillData(t.id, t.name, true)) || []}
          onAddValue={addNewTag}
          onSelectValue={onSelectValueHandler}
          onDeselectValue={onDeselectValueHandler}
          onDeleteValues={deleteTags}
          filterValues={onFilterTags}
          enabledDelete={true}
        />
      }
    />
  );
};
