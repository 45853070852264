import { useRef, useState } from 'react';
import { Icon } from '@fluentui/react';
import { Button, FilterIcon, Input } from '@fluentui/react-northstar';
import { FilterItem, useAppDispatch, useAppSelector } from 'app/common';
import { useOnClickOutside } from 'usehooks-ts';
import {
  FiltersPanel,
  openFiltersPanel,
  removeTemplatesFilter,
  selectFilterItems,
  selectSearchText,
  setTemplatesPageNumber,
  templatesRequested
} from 'app/pages/my-activities/templates';
import 'app/pages/my-activities/templates/components/Templates.scss';

import { FilterItemsTooltip } from 'app/common/components/filter/FilterItemsTooltip';
import { useIntl } from 'app/i18n';

export const TemplatesOverviewTop = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const filterItems = useAppSelector<FilterItem[]>(selectFilterItems);
  const [expandedFilterInput, setExpandedFilterInput] = useState(false);
  const searchText = useAppSelector<string>(selectSearchText);

  const handleOpenFiltersPanel = (): void => {
    dispatch(openFiltersPanel());
  };

  const onTitleInputChange = (searchText: string): void => {
    dispatch(setTemplatesPageNumber(1));
    dispatch(templatesRequested(searchText));
  };

  const handleUndoFilter = (filter: FilterItem): void => {
    dispatch(removeTemplatesFilter(filter));
    dispatch(templatesRequested(searchText));
  };

  const handleExpandedInput = () => {
    setExpandedFilterInput(false);
  };

  useOnClickOutside(ref, handleExpandedInput);
  return (
    <>
      <div className="templates-top-area">
        <h3 className="templates-title">{formatMessage({ id: 'all-templates.saved' })}</h3>
        <div className="search-results-content template">
          <div className="filter-input-wrap" ref={ref}>
            <div className="filter-pills-wrap">
              <FilterItemsTooltip filterItems={filterItems} onUndoFilter={handleUndoFilter} onOpenFilterPanel={handleOpenFiltersPanel} />
            </div>
          </div>
        </div>
        <div className="templates-wrapper">
          <div className="templates-filter">
            <Button
              tinted
              title={formatMessage({ id: 'filters.filter' })}
              content={formatMessage({ id: 'buttons.filter' })}
              icon={<FilterIcon />}
              className="table-filters-button"
              onClick={handleOpenFiltersPanel}
            />
          </div>
          <div className="table-filters-input-wrap">
            <Input
              clearable
              onChange={(_, data) => onTitleInputChange(data.value)}
              icon={<Icon iconName="Search" />}
              onClick={() => setExpandedFilterInput(true)}
              placeholder={formatMessage({ id: 'buttons.find' })}
              className={expandedFilterInput ? 'table-filters-input expanded' : 'table-filters-input'}
            />
          </div>
        </div>
      </div>

      <FiltersPanel />
    </>
  );
};
