import { Overlay } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import { Button, ChevronDownIcon, CloseIcon, FilterIcon } from '@fluentui/react-northstar';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { DirectionalHint, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { selectIsOnDesktop, selectIsOnTouch } from 'app/common';
import { DateFiltersMenu } from 'app/common/components/date-filters-menu';
import { useAppSelector } from 'app/common/hooks';
import { FormattedMessage, useIntl } from 'app/i18n';

import { useState } from 'react';

interface ContextualDateFiltersMenuProps {
  direction: DirectionalHint;
  onFilter: (startDate: Date, endDate: Date) => void;
  onResetFilter: () => void;
}

export const ContextualDateFiltersMenu = ({ direction, onFilter, onResetFilter }: ContextualDateFiltersMenuProps) => {
  const { formatMessage } = useIntl();

  const [toggleBottomOptions, setToggleBottomOptions] = useState(false);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);

  const renderMenuList = (): JSX.Element => (
    <div className="activities-date-wrap">
      {isOnDesktop && (
        <DateFiltersMenu
          isOnTouch={isOnTouch}
          isOnDesktop={isOnDesktop}
          onFilter={onFilter}
          onResetFilter={onResetFilter}
          toggleOverlay={setToggleBottomOptions}
        />
      )}
    </div>
  );

  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    directionalHint: direction,
    items: [{ key: 'new', onRender: renderMenuList }],
    iconProps: { iconName: 'Mail' }
  }));

  const dateFiltersOverlay = (
    <Overlay isDarkThemed={true} className="bottom-popup-overlay">
      <div className="activities-date-dialog-wrap">
        <div className="activities-date-dialog-intro">
          <div className="activities-date-dialog-intro-action">
            <CloseIcon onClick={() => setToggleBottomOptions(false)} />
          </div>
          <p className="activities-date-dialog-intro-title">
            <FormattedMessage id={'filter-by-date-popup.choose-date-label'} />
          </p>
        </div>
        <div className="activities-date-dialog-content">
          <DateFiltersMenu
            isOnTouch={isOnTouch}
            isOnDesktop={isOnDesktop}
            onFilter={onFilter}
            onResetFilter={onResetFilter}
            toggleOverlay={setToggleBottomOptions}
          />
        </div>
      </div>
    </Overlay>
  );

  return (
    <>
      {isOnDesktop && (
        <>
          <span className="icon">
            <FilterIcon />
          </span>
          <DefaultButton
            className="profile-panel-dropdown-button"
            text={formatMessage({ id: 'contacts-activities-filter.menu-items.by-date' })}
            menuProps={menuProps}
          />
        </>
      )}
      {isOnTouch && (
        <Button
          className="profile-panel-popup-button"
          content={formatMessage({ id: 'filter-by-date-popup.any-date-label' })}
          icon={<ChevronDownIcon />}
          onClick={() => setToggleBottomOptions(true)}
        />
      )}
      {toggleBottomOptions && isOnTouch && dateFiltersOverlay}
    </>
  );
};
