import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  addJobTitleRequested,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regEmail,
  regNumbers,
  selectCompanyJobTitles,
  WizardIntroInfo,
  isPhoneNumberValidOrEmpty
} from 'app/common';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { ControlledCreateDropdown, ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import { Company } from 'app/pages/my-audience/companies';
import {
  AddContactSteps,
  AddContactWizardFooter,
  AddProfileInformationForm,
  closeAddContactWizard,
  createCollaboration,
  goToNextStepAddContact,
  goToPreviousStepAddContact,
  selectCollaborationToAdd,
  selectSelectedCompany,
  selectSelectedContact,
  setCollaborationToAdd
} from 'app/pages/my-audience/company-profile';
import { Collaboration, Contact, JobTitle, MediumType } from 'app/pages/my-audience/contacts';
import { getNames } from 'country-list';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddProfileInformation = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const contactToAdd = useAppSelector<Contact>(selectSelectedContact);
  const collaborationToAdd = useAppSelector<Collaboration>(selectCollaborationToAdd);
  const selectedCompany = useAppSelector<Company>(selectSelectedCompany);
  const companyJobTitles = useAppSelector<JobTitle[]>(selectCompanyJobTitles);

  const jobTitles: string[] = useMemo(() => companyJobTitles.map((jt) => jt.name), [companyJobTitles]);

  const { handleSubmit, control, watch } = useForm<AddProfileInformationForm, any>({
    defaultValues: {
      jobTitle: collaborationToAdd.jobTitle?.name,
      jobDescription: collaborationToAdd.jobDescription,
      address: collaborationToAdd.address,
      city: collaborationToAdd.city,
      postalCode: collaborationToAdd.postalCode,
      country: collaborationToAdd.country,
      emailAddress: collaborationToAdd.emailAddress,
      landlinePhoneNumber: collaborationToAdd.landlinePhoneNumber?.value,
      mobilePhoneNumber: collaborationToAdd.mobilePhoneNumber?.value
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const areProfileInfoFieldsValid =
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('landlinePhoneNumber')) &&
    isPhoneNumberValidOrEmpty(watch('mobilePhoneNumber'));

  const handleCancelButtonClick = () => {
    dispatch(closeAddContactWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStepAddContact());
  };

  const handleNextButtonClick = () => dispatch(goToNextStepAddContact());

  const submitData = useCallback(() => {
    handleSubmit(
      (data) => {
        const selectedJobTitle = companyJobTitles.find((jt) => jt.name === data.jobTitle);
        const collaboration = createCollaboration(data, selectedJobTitle, selectedCompany);

        dispatch(setCollaborationToAdd(collaboration));
      },
      (error) => {
        Sentry.setExtra('Path', AddProfileInformation.name);
        Sentry.captureException(error);
      }
    )();
  }, [companyJobTitles, dispatch, handleSubmit, selectedCompany]);

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', MediumType.Company);

    dispatch(addJobTitleRequested(jobTitle));
  };

  useEffect(() => {
    return () => {
      submitData();
    };
  }, [submitData]);

  return (
    <>
      <AddContactSteps areFieldsValid={areProfileInfoFieldsValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntroInfo
          title={formatMessage({ id: 'companies-add-contact.wizard-personal-title' })}
          description={formatMessage({ id: 'companies-add-contact.wizard-personal-subtitle' })}
          info={
            <span className="wizard-panel-description text-bold">
              {contactToAdd.firstName} {contactToAdd.lastName}
            </span>
          }
        />
        <div className="wizard-panel-form">
          <div className="custom-dropdown">
            <ControlledCreateDropdown
              label={
                <span className="dropdown-label">
                  <FormattedMessage id={'add-menu-create-contact.additional-job-title-label'} />
                </span>
              }
              name="jobTitle"
              items={jobTitles}
              control={control}
              placeholder={formatMessage({ id: 'add-menu-create-contact.additional-job-title-placeholder' })}
              defaultValue={collaborationToAdd.jobTitle?.name}
              createNewEntityMessage="Click here to create new job title"
              onCreateNewEntityClick={handleCreateNewJobTitle}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'companies-add-contact.wizard-job-label' })} control={control} name="jobDescription" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'companies-add-contact.wizard-personal-address-label' })} control={control} name="address" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="emailAddress"
              label={formatMessage({ id: 'companies-add-contact.wizard-personal-email-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regEmail,
                  message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              name="landlinePhoneNumber"
              label={formatMessage({ id: 'companies-add-contact.wizard-personal-phone-label' })}
              control={control}
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'companies-add-contact.wizard-personal-mobile-phone-label' })}
              control={control}
              name="mobilePhoneNumber"
              rules={{
                pattern: {
                  value: regPhoneNumber,
                  message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={formatMessage({ id: 'companies-add-contact.wizard-zip-label' })}
              control={control}
              name="postalCode"
              rules={{
                pattern: {
                  value: regNumbers,
                  message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                }
              }}
            />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'companies-add-contact.wizard-personal-city-label' })} control={control} name="city" />
          </div>
          <div className="wizard-panel-dropdown">
            <ControlledDropdown
              label={<span className="dropdown-label">{formatMessage({ id: 'companies-add-contact.wizard-personal-country-label' })}</span>}
              name="country"
              items={getNames()}
              control={control}
              placeholder={formatMessage({ id: 'companies-add-contact.wizard-personal-country-placeholder' })}
              defaultValue={collaborationToAdd.country}
            />
          </div>
        </div>
        <AddContactWizardFooter
          onCancelButtonClick={handleCancelButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          isNextButtonDisabled={!areProfileInfoFieldsValid}
        />
      </div>
    </>
  );
};
