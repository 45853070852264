import { User, UserInfo } from 'app/common';
import jwt_decode from 'jwt-decode';

export const createUser = (gqlUser): User => {
  return new User(gqlUser.id, gqlUser.aadObjectId, gqlUser.firstName, gqlUser.lastName);
};

export const buildAadUserInfo = (accessToken: string): UserInfo => {
  const decodedToken = jwt_decode(accessToken);
  return new UserInfo(decodedToken['oid'], decodedToken['name'], decodedToken['unique_name']);
};

export const buildAugureUserInfo = (accessToken: string): User => {
  const decodedToken = jwt_decode(accessToken);
  return new User(decodedToken['id'], '', '', '', decodedToken['email']);
};
