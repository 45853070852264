import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createTemplate, FilterItem } from 'app/common';
import {
  AddTemplateDocument,
  AddTemplateMutation,
  FetchTemplatesDocument,
  FetchTemplatesQuery,
  RemoveTemplateDocument,
  RemoveTemplateMutation,
  TemplateInput,
  UpdateTemplateDocument,
  UpdateTemplateMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { PageOfTemplates, Template } from 'app/pages/my-activities/templates';

export const getTemplates = async (
  client: ApolloClient<NormalizedCacheObject>,
  pageNumber: number,
  pageSize: number,
  filterItems: FilterItem[],
  searchText: string
): Promise<PageOfTemplates> => {
  const response = await client.query<FetchTemplatesQuery>({
    query: FetchTemplatesDocument,
    variables: {
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      filterItems: filterItems,
      searchText: `${searchText}*`
    }
  });
  return new PageOfTemplates(
    response.data.templates.totalCount,
    response.data.templates.templates.map((template) => createTemplate(template))
  );
};

export const addTemplate = async (client: ApolloClient<NormalizedCacheObject>, template: Template): Promise<Template> => {
  const input: TemplateInput = {
    id: '',
    title: template.title,
    imageAsBase64: template.imageAsBase64,
    jsonBody: template.jsonBody,
    htmlBody: template.htmlBody
  };

  const response = await client.query<AddTemplateMutation>({
    query: AddTemplateDocument,
    variables: {
      input
    }
  });

  return createTemplate(response.data.addTemplate);
};

export const deleteTemplate = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<Template> => {
  const response = await client.query<RemoveTemplateMutation>({
    query: RemoveTemplateDocument,
    variables: {
      id
    }
  });

  return createTemplate(response.data.removeTemplate);
};

export const updateTemplate = async (client: ApolloClient<NormalizedCacheObject>, templateToUpdate: Template): Promise<Template> => {
  const input: TemplateInput = {
    id: templateToUpdate.id,
    title: templateToUpdate.title,
    jsonBody: templateToUpdate.jsonBody,
    htmlBody: templateToUpdate.htmlBody,
    imageAsBase64: templateToUpdate.imageAsBase64
  };

  const response = await client.query<UpdateTemplateMutation>({
    query: UpdateTemplateDocument,
    variables: {
      input
    }
  });

  return createTemplate(response.data.updateTemplate);
};
