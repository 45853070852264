import { call, put, takeEvery } from 'redux-saga/effects';
import { safe } from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { allBlacklistsReceived, getAllBlacklistsRequested } from 'app/pages/my-audience/contact-profile';
import { Blacklist } from 'app/pages/my-audience/contacts/index';
import { fethcAllBlacklists } from 'app/pages/my-audience/common/service/common.service';

function* fetchAllBlacklistsFlow() {
  const blacklists: Blacklist[] = yield call(fethcAllBlacklists, client);
  yield put(allBlacklistsReceived(blacklists));
}

export function* fetchAllBlacklistsFlowWatcher() {
  yield takeEvery(getAllBlacklistsRequested.type, safe(fetchAllBlacklistsFlow));
}
