import { isStringEmpty, printData, useAppDispatch, useAppSelector, WizardFinishIntro, WizardIntro } from 'app/common';
import { selectLocalizedAudiences, selectLocalizedFrequencies } from 'app/localization';
import { LocalizedListValue } from 'app/pages/my-audience/media-outlets';
import { FormattedMessage, useIntl } from 'app/i18n';
import {
  addMediaOutletRequested,
  closeCreateMediaOutletWizard,
  CreateMediaOutletFooter,
  CreateMediaOutletFromContactStep,
  CreateMediaOutletSteps,
  disableCreateMediaOutletWizardFinishButton,
  goToMediaOutletStep,
  goToPreviousMediaOutletStep,
  MediaOutletAddressInfo,
  MediaOutletGeneralInfo,
  selectMediaOutletAddressInfo,
  selectMediaOutletGeneralInfo
} from 'app/pages/my-audience/wizard-create-contact';

export const CreateMediaOutletFinish = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const generalInfoToAdd = useAppSelector<MediaOutletGeneralInfo>(selectMediaOutletGeneralInfo);
  const addressInfoToAdd = useAppSelector<MediaOutletAddressInfo>(selectMediaOutletAddressInfo);

  const localizedAudiences = useAppSelector<LocalizedListValue[]>(selectLocalizedAudiences);
  const localizedFrequencies = useAppSelector<LocalizedListValue[]>(selectLocalizedFrequencies);

  const selectedFrequency = generalInfoToAdd.frequencyKey ?? localizedFrequencies.find((f) => f.key === generalInfoToAdd.frequencyKey)?.label;
  const selectedAudience = generalInfoToAdd.audienceKey ?? localizedAudiences.find((f) => f.key === generalInfoToAdd.audienceKey)?.label;

  const handleCancelButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousMediaOutletStep());
  };

  const handleEdit = (step: CreateMediaOutletFromContactStep) => {
    dispatch(goToMediaOutletStep(step));
  };

  const handleFinishButtonClick = () => {
    dispatch(disableCreateMediaOutletWizardFinishButton());
    dispatch(addMediaOutletRequested());
    dispatch(closeCreateMediaOutletWizard());
  };

  return (
    <>
      <CreateMediaOutletSteps />
      <div className="wizard-panel-finish-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-contact.mo-finish-title' })} description="" />
        <div className="wizard-panel-finish-main-container">
          <div className="wizard-panel-finish-main">
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                title={formatMessage({ id: 'add-menu-create-contact.mo-finish-general' })}
                onEditButtonClick={() => handleEdit(CreateMediaOutletFromContactStep.GeneralInfo)}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    {' '}
                    <FormattedMessage id={'add-menu-create-contact.mo-name-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.name)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-descript-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(generalInfoToAdd.description)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-audience-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(selectedAudience)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-website-label'} />
                  </span>
                  {!isStringEmpty(addressInfoToAdd.websiteUrl) ? (
                    <a href={addressInfoToAdd.websiteUrl} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                      {printData(addressInfoToAdd.websiteUrl)}
                    </a>
                  ) : (
                    <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.websiteUrl)}</span>
                  )}
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-frequancy-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(selectedFrequency)}</span>
                </div>
              </div>
            </div>
            <div className="wizard-panel-finish-content">
              <WizardFinishIntro
                title={formatMessage({ id: 'add-menu-create-contact.mo-finish-address' })}
                onEditButtonClick={() => handleEdit(CreateMediaOutletFromContactStep.AddressInfo)}
              />
              <div className="wizard-panel-finish-main-content">
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    {' '}
                    <FormattedMessage id={'add-menu-create-contact.mo-address-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.address)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-po-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.poBox)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-postal-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.postalCode)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-city-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.city)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-country-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.country)}</span>
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-website-label'} />
                  </span>
                  {!isStringEmpty(addressInfoToAdd.websiteUrl) ? (
                    <a href={addressInfoToAdd.websiteUrl} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                      {printData(addressInfoToAdd.websiteUrl)}
                    </a>
                  ) : (
                    <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.websiteUrl)}</span>
                  )}
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-email-label'} />
                  </span>
                  {!isStringEmpty(addressInfoToAdd.emailAddress) ? (
                    <a href={'mailto:' + addressInfoToAdd.emailAddress} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                      {printData(addressInfoToAdd.emailAddress)}
                    </a>
                  ) : (
                    <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.emailAddress)}</span>
                  )}
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-phone-label'} />
                  </span>
                  {!isStringEmpty(addressInfoToAdd.phoneNumber) ? (
                    <a href={'tel:' + addressInfoToAdd.phoneNumber} className="wizard-panel-finish-main-input link" target="_blank" rel="noreferrer">
                      {printData(addressInfoToAdd.phoneNumber)}
                    </a>
                  ) : (
                    <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.phoneNumber)}</span>
                  )}
                </div>
                <div className="wizard-panel-finish-main-box">
                  <span className="wizard-panel-finish-main-label">
                    <FormattedMessage id={'add-menu-create-contact.mo-fax-label'} />
                  </span>
                  <span className="wizard-panel-finish-main-input">{printData(addressInfoToAdd.fax)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateMediaOutletFooter
        onCancelButtonClick={handleCancelButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onFinishButtonClick={handleFinishButtonClick}
      />
    </>
  );
};
