import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useAppDispatch } from 'app/common';
import { useIntl } from 'app/i18n';
import { deleteTemplateRequested } from 'app/pages/my-activities/templates';

interface DeleteTemplateDialogProps {
  id: string;
  isDialogOpened: boolean;
  closeDialog: () => void;
}

export const DeleteTemplateDialog = ({ id, isDialogOpened, closeDialog }: DeleteTemplateDialogProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'all-templates.delete-template' }),
    subText: formatMessage({ id: 'all-templates.are-you-sure-you-want-to-delete-template' })
  };

  const dialogModalProps = {
    className: 'dialog-wrap',
    isBlocking: false
  };

  const onCancel = () => {
    closeDialog();
  };

  const onConfirm = () => {
    dispatch(deleteTemplateRequested(id));
  };

  return (
    <Dialog hidden={!isDialogOpened} onDismiss={onCancel} key="template-delete-dialog" dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
      <div>
        <DialogFooter>
          <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={onCancel} />
          <Button primary content={formatMessage({ id: 'buttons.delete' })} onClick={onConfirm} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};
