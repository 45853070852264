import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AddMediaOutletSavedSearchDocument,
  AddMediaOutletSavedSearchMutation,
  FetchMediaOutletsAvatarDetailsDocument,
  FetchMediaOutletsAvatarDetailsQuery,
  FetchMediaOutletsSavedSearchesPagedDocument,
  FetchMediaOutletsSavedSearchesPagedQuery,
  MediaOutletsSavedSearchInput,
  MediaOutletsSavedSearchWithIdInput,
  RemoveMediaOutletSavedSearchDocument,
  RemoveMediaOutletSavedSearchMutation,
  UpdateMediaOutletSavedSearchDocument,
  UpdateMediaOutletSavedSearchesDocument,
  UpdateMediaOutletSavedSearchesMutation,
  UpdateMediaOutletSavedSearchMutation
} from 'app/common/graphql/generated/graphql-gateway';
import { FilterItem } from 'app/common';
import {
  AvatarDetails,
  createSavedSearch,
  PageOfSavedSearches,
  SavedSearch,
  SavedSearchInput,
  SavedSearchWithIdInput
} from 'app/pages/my-audience/saved-searches';
import { MediaOutletsAvatarDetailsInput, MediaOutletsAvatarDetailsWithSavedSearchId } from 'app/pages/my-audience/media-outlets-saved-searches';

export const fetchMediaOutletsSavedSearchesPaged = async (
  client: ApolloClient<NormalizedCacheObject>,
  skip: number,
  take: number,
  searchText: string,
  filterItems: FilterItem[]
): Promise<PageOfSavedSearches> => {
  const response = await client.query<FetchMediaOutletsSavedSearchesPagedQuery>({
    query: FetchMediaOutletsSavedSearchesPagedDocument,
    variables: {
      skip,
      take,
      searchText: searchText,
      filterItems: filterItems
    }
  });

  return new PageOfSavedSearches(
    response.data.mediaOutletSavedSearches.totalCount,
    response.data.mediaOutletSavedSearches.savedSearches.map((savedSearch) => createSavedSearch(savedSearch))
  );
};

export const updateMediaOutletSavedSearch = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  updatedSavedSearch: SavedSearchInput
): Promise<SavedSearch> => {
  const savedMediaOutletSearch: MediaOutletsSavedSearchInput = {
    name: updatedSavedSearch.name,
    filterItems: updatedSavedSearch.filterItems,
    searchText: updatedSavedSearch.searchText
  };

  const response = await client.mutate<UpdateMediaOutletSavedSearchMutation>({
    mutation: UpdateMediaOutletSavedSearchDocument,
    variables: {
      id,
      savedMediaOutletSearch
    }
  });

  return new SavedSearch(
    response.data.updateMediaOutletSavedSearch.id,
    response.data.updateMediaOutletSavedSearch.name,
    response.data.updateMediaOutletSavedSearch.searchText,
    response.data.updateMediaOutletSavedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.updateMediaOutletSavedSearch.priority,
    response.data.updateMediaOutletSavedSearch.count,
    new Date(response.data.updateMediaOutletSavedSearch.createdAt),
    new Date(response.data.updateMediaOutletSavedSearch.lastModifiedAt),
    response.data.updateMediaOutletSavedSearch.createdBy
  );
};

export const updateMediaOutletSavedSearches = async (
  client: ApolloClient<NormalizedCacheObject>,
  savedSearches: SavedSearchWithIdInput[]
): Promise<SavedSearch[]> => {
  const savedMediaOutletSearches: MediaOutletsSavedSearchWithIdInput[] = savedSearches.map((ss) => ({
    id: ss.id,
    name: ss.name,
    count: ss.count,
    filterItems: ss.filterItems,
    priority: ss.priority,
    searchText: ss.searchText
  }));

  const response = await client.mutate<UpdateMediaOutletSavedSearchesMutation>({
    mutation: UpdateMediaOutletSavedSearchesDocument,
    variables: {
      savedMediaOutletSearches
    }
  });

  return response.data.updateMediaOutletSavedSearches.map(
    (node) =>
      new SavedSearch(
        node.id,
        node.name,
        node.searchText,
        node.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
        node.priority,
        node.count,
        new Date(node.createdAt),
        new Date(node.lastModifiedAt),
        node.createdBy
      )
  );
};

export const addMediaOutletSavedSearch = async (client: ApolloClient<NormalizedCacheObject>, savedSearch: SavedSearchInput): Promise<SavedSearch> => {
  const savedMediaOutletSearch: MediaOutletsSavedSearchInput = {
    name: savedSearch.name,
    filterItems: savedSearch.filterItems,
    searchText: savedSearch.searchText
  };

  const response = await client.mutate<AddMediaOutletSavedSearchMutation>({
    mutation: AddMediaOutletSavedSearchDocument,
    variables: {
      savedMediaOutletSearch
    }
  });

  return new SavedSearch(
    response.data.saveMediaOutletSearch.id,
    response.data.saveMediaOutletSearch.name,
    response.data.saveMediaOutletSearch.searchText,
    response.data.saveMediaOutletSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.saveMediaOutletSearch.priority,
    response.data.saveMediaOutletSearch.count,
    new Date(response.data.saveMediaOutletSearch.createdAt),
    new Date(response.data.saveMediaOutletSearch.lastModifiedAt),
    response.data.saveMediaOutletSearch.createdBy
  );
};

export const removeMediaOutletSavedSearch = async (client: ApolloClient<NormalizedCacheObject>, id: string): Promise<SavedSearch> => {
  const response = await client.mutate<RemoveMediaOutletSavedSearchMutation>({
    mutation: RemoveMediaOutletSavedSearchDocument,
    variables: {
      id
    }
  });

  return new SavedSearch(
    response.data.removeMediaOutletSavedSearch.id,
    response.data.removeMediaOutletSavedSearch.name,
    response.data.removeMediaOutletSavedSearch.searchText,
    response.data.removeMediaOutletSavedSearch.filterItems.map((filterItem) => new FilterItem(filterItem.fieldName, filterItem.value)),
    response.data.removeMediaOutletSavedSearch.priority,
    response.data.removeMediaOutletSavedSearch.count,
    new Date(response.data.removeMediaOutletSavedSearch.createdAt),
    new Date(response.data.removeMediaOutletSavedSearch.lastModifiedAt),
    response.data.removeMediaOutletSavedSearch.createdBy
  );
};

export const fetchMediaOutletsAvatarDetails = async (
  client: ApolloClient<NormalizedCacheObject>,
  mediaOutletAvatarDetails: MediaOutletsAvatarDetailsInput[]
): Promise<MediaOutletsAvatarDetailsWithSavedSearchId[]> => {
  const response = await client.query<FetchMediaOutletsAvatarDetailsQuery>({
    query: FetchMediaOutletsAvatarDetailsDocument,
    variables: {
      mediaOutletAvatarDetails
    }
  });

  return response.data.mediaOutletsAvatarDetails.map(
    (mediaOutletsQueryInput) =>
      new MediaOutletsAvatarDetailsWithSavedSearchId(
        mediaOutletsQueryInput.savedSearchId,
        mediaOutletsQueryInput.pageOfMediaOutlets.mediaOutlets.map((mediaOutlet) => new AvatarDetails(`${mediaOutlet.name}`, mediaOutlet.profilePictureUrl)),
        mediaOutletsQueryInput.pageOfMediaOutlets.totalCount
      )
  );
};
