import { Avatar, Input, List, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import { useState } from 'react';
import {
  firstPageOfWizardContactsRequested,
  selectContactsToAddToList,
  selectSelectedListId,
  selectWizardContacts,
  setContactsToAddToList
} from 'app/pages/my-audience/lists-profile';
import { Contact } from 'app/pages/my-audience/contacts';
import emptyImage from 'assets/images/empty-search.png';
import { FormattedMessage, useIntl } from 'app/i18n';
import { ListContact } from 'app/pages/my-audience/lists';
import { getInitials } from 'app/common/validation';

export const AddContactsSearch = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const contacts = useAppSelector<Contact[]>(selectWizardContacts);
  const contactsToAdd = useAppSelector<ListContact[]>(selectContactsToAddToList);
  const selectedListId = useAppSelector<string>(selectSelectedListId);

  const [searchText, setSearchText] = useState('');

  const onInputChange = (searchText: string) => {
    setSearchText(searchText);
    dispatch(firstPageOfWizardContactsRequested(searchText));
  };

  const handleContactSelected = (selectedIndex: number) => {
    const contactToAdd: Contact = contacts[selectedIndex];
    const collaboration = contactToAdd.collaborations.find((c) => c.isPrimary);
    const mediumId = collaboration !== undefined ? collaboration.medium.id : '';

    dispatch(setContactsToAddToList([...contactsToAdd, new ListContact('', selectedListId, contactToAdd, mediumId)]));
    setSearchText('');
  };

  return (
    <div className="wizard-search">
      <Input
        className="profile-panel-comments-send-input"
        icon={<SearchIcon />}
        autoComplete="off"
        placeholder={formatMessage({ id: 'list-wizards.add-contact-search-input' })}
        value={searchText}
        onChange={(e) => onInputChange((e.target as HTMLTextAreaElement).value)}
      />
      {searchText !== '' && (
        <div className="dropdown-segments-wrap">
          <div className="dropdown-segments">
            <Segment key={'dropdown-names'} className="dropdown-segment">
              <List
                onSelectedIndexChange={(_, newProps) => {
                  if (newProps) handleContactSelected(newProps.selectedIndex);
                }}
                selectable
                items={contacts.map((contact) => ({
                  key: contact.id,
                  header: `${contact.firstName} ${contact.lastName}`,
                  content: contact.primaryEmailAddress(),
                  media: <Avatar getInitials={getInitials} name={`${contact.firstName} ${contact.lastName}`} />
                }))}
              />
            </Segment>
          </div>
        </div>
      )}
      {contacts?.length === 0 && searchText && (
        <Segment className="empty-search">
          <div className="empty-search-wrap">
            <img className="empty-search-image" src={emptyImage} alt="No results found" />
            <Text className="empty-search-text text-bold">
              <FormattedMessage id={'list-wizards.add-contact-no-results-title'} />
            </Text>
            <Text className="empty-search-text">
              <FormattedMessage id={'list-wizards.add-contact-no-results-subtitle'} />
            </Text>
          </div>
        </Segment>
      )}
    </div>
  );
};
