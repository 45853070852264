import { Contact, JobTitle, MediaResort, MediumType, MediumTypeInput, Topic } from 'app/pages/my-audience/contacts';

export class SendingsOverviewViewModel {
  constructor(public totalSent: number, public opens: number, public clicks: number) {}
}

export class ContactProfilePicture {
  constructor(public data: string, public fileExtension: string, public mimeType: string) {}
}

export class ContactUpdateModel {
  constructor(public salutation: string, public firstName: string, public lastName: string, public isGloballySignedOut: boolean) {}
}

export class Medium {
  constructor(public id: string, public name: string, public type: MediumType) {}
}

export class MediumInput {
  constructor(public id: string, public name: string, public type: MediumTypeInput) {}
}
export class ContactProfilePanelData {
  constructor(public contact: Contact, public topics: Topic[], public mediaResorts: MediaResort[], public blacklists: string[]) {}
}

export class UserByAadId {
  constructor(public id: string, public firstName: string, public lastName: string) {}
}

export class CompanyGeneralInfo {
  constructor(public name: string, public categoryName: string, public parentCompany: string, public comment: string) {}
}

export class CompanyAddressInfo {
  constructor(
    public address: string,
    public poBox: string,
    public postalCode: string,
    public city: string,
    public country: string,
    public websiteUrl: string,
    public emailAddress: string,
    public phoneNumber: string,
    public fax: string
  ) {}
}

export class CompanyProfileInfo {
  constructor(
    public jobTitle: string,
    public jobDescription: string,
    public resort: string,
    public phoneNumber: string,
    public mobilePhoneNumber: string,
    public emailAddress: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country: string
  ) {}
}

export class MediaOutletGeneralInfo {
  constructor(
    public name: string,
    public description: string,
    public audienceKey: string,
    public websiteUrl: string,
    public frequencyKey: string,
    public comment: string,
    public mediaSupportTypesKeys: string[],
    public preferredLanguages: string[]
  ) {}
}

export class MediaOutletAddressInfo {
  constructor(
    public address: string,
    public poBox: string,
    public postalCode: string,
    public city: string,
    public country: string,
    public websiteUrl: string,
    public emailAddress: string,
    public phoneNumber: string,
    public fax: string
  ) {}
}

export class MediaOutletCollaborationProfileInfo {
  constructor(
    public jobTitle: JobTitle,
    public jobDescription: string,
    public resort: string,
    public phoneNumber: string,
    public mobilePhoneNumber: string,
    public emailAddress: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country: string
  ) {}
}

export type AddMediaProfileInformationForm = {
  jobTitle: string;
  jobDescription: string;
  resort: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  emailAddress: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
};

export enum AddMediaOutletWizardSteps {
  SearchMediaOutlet = 1,
  ProfileInformation,
  Finish
}

export enum CreateMediaOutletWizardSteps {
  GeneralInformation = 1,
  AddressInfrormation,
  ProfileInformation,
  Finish
}

export enum AddCompanyWizardSteps {
  SearchCompany = 1,
  ProfileInformation,
  Finish
}

export enum CreateCompanyWizardSteps {
  GeneralInformation = 1,
  AddressInfrormation,
  ProfileInformation,
  Finish
}

export class ContactInfo {
  constructor(public collaborationId: string, public fieldName: string, public mediumName: string, public phoneNumber: string, public isPrimary: boolean) {}
}

export type CreateMediaOutletGeneralInfoForm = {
  name: string;
  description: string;
  audienceValue: string;
  websiteUrl: string;
  frequencyValue: string;
  comment: string;
  mediaSupportTypesValues: string[];
  preferredLanguages: string[];
};
