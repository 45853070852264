import React, { useEffect, useMemo, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import InfiniteScroll from 'react-infinite-scroller';
import {
  DetailsHeader,
  DetailsListLayoutMode,
  IColumn,
  IColumnDragDropDetails,
  IColumnReorderOptions,
  IDetailsRowProps,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import {
  closeTableColumnSelectionPopup,
  NewShowOrHideColumnsPopup,
  NewTablePopupMobile,
  openTableColumnSelectionPopup,
  openTableMobileColumnSelectionPopup,
  SearchResultTableData,
  selectIsOnTouch,
  selectIsTableColumnSelectionPopupVisible,
  selectIsTableMobileColumnSelectionPopupVisible,
  selectSelectedTableRows,
  setSelectedTableRows,
  TableHeader,
  TableRow,
  useAppDispatch,
  useAppSelector
} from 'app/common';
import { CompanySearchResultTableData } from 'app/pages/my-audience/companies';
import { MediaOutletSearchResultTableData } from 'app/pages/my-audience/media-outlets';
import { ListSearchResultTableData } from 'app/pages/my-audience/lists';
import { ContactSearchResultTableData } from 'app/pages/my-audience/contacts';
import 'app/common/components/table/searchResultsTable.scss';
import { useIntl } from 'app/i18n';

// TODO: remove infinite-scroll-component npm once all entities start using NewTable component

type TableRowsData = CompanySearchResultTableData[] | MediaOutletSearchResultTableData[] | ListSearchResultTableData[] | ContactSearchResultTableData[];

interface NewTableProps {
  isLoadingSearchResults: boolean;
  fixedColumnIndex: number;
  onOpenProfile: (id: string) => void;
  header: TableHeader[];
  tableRows: TableRowsData;
  totalCountOfEntities: number;
  onUpdateTableHeader: (updatedHeader: TableHeader[]) => void;
  handleLoadMore: () => void;
  onColumnClicked: (column: IColumn) => void;
}

export const NewTable = ({
  isLoadingSearchResults,
  fixedColumnIndex,
  onOpenProfile,
  header,
  handleLoadMore,
  tableRows,
  totalCountOfEntities,
  onUpdateTableHeader,
  onColumnClicked
}: NewTableProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const isTableColumnSelectionPopupVisible = useAppSelector<boolean>(selectIsTableColumnSelectionPopupVisible);
  const isTableMobileColumnSelectionPopupVisible = useAppSelector<boolean>(selectIsTableMobileColumnSelectionPopupVisible);
  const selectedItems = useAppSelector<SearchResultTableData>(selectSelectedTableRows);

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedTableRows = selection.getSelection() as TableRowsData;
          dispatch(setSelectedTableRows(selectedTableRows));
        },
        selectionMode: SelectionMode.multiple
      }),
    [dispatch]
  );

  useEffect(() => {
    if (selectedItems.length === 0) {
      selection.setAllSelected(false);
    }
  }, [selectedItems, selection]);

  const tableHeader: TableHeader[] =
    header.find((headerItem) => headerItem.fieldName === 'add') !== undefined
      ? [...header]
      : [...header].concat({
          key: 'column-dropdown',
          name: '',
          iconName: 'add',
          fieldName: 'add',
          minWidth: 40,
          maxWidth: 40,
          isRowHeader: false,
          isResizable: false,
          isIconOnly: true,
          onColumnClick: () => (isOnTouch ? dispatch(openTableMobileColumnSelectionPopup()) : dispatch(openTableColumnSelectionPopup())),
          isColumnVisible: true
        });

  const handleColumnReorder = ({ draggedIndex, targetIndex }: IColumnDragDropDetails) => {
    const draggedItem = header[draggedIndex];
    const newColumns: TableHeader[] = [...header];
    newColumns.splice(draggedIndex, 1);
    newColumns.splice(targetIndex, 0, draggedItem);
    onUpdateTableHeader(newColumns);
  };

  const getColumnReorderOptions = (): IColumnReorderOptions => {
    return {
      frozenColumnCountFromStart: 2,
      frozenColumnCountFromEnd: 1,
      onColumnDrop: handleColumnReorder
    };
  };

  const handleLoadMoreData = () => {
    // Check whether is loading is necessary so that Infinite Scroll Component does not trigger function multiple times
    if (!isLoadingSearchResults) handleLoadMore();
  };

  const handleClickOutside = () => {
    dispatch(closeTableColumnSelectionPopup());
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleColumnClicked = (_: React.MouseEvent<HTMLElement>, column: IColumn) => {
    if (column.fieldName === 'add') return;

    onColumnClicked(column);
  };

  return (
    <div className="search-results-table">
      <div ref={ref}>
        {isTableColumnSelectionPopupVisible && (
          <NewShowOrHideColumnsPopup tableHeader={tableHeader} onUpdateTableHeader={onUpdateTableHeader} fixedColumnIndex={fixedColumnIndex} />
        )}
      </div>
      <MarqueeSelection selection={selection} className="table-results" isDraggingConstrainedToRoot={true}>
        <div id="scrollableDiv">
          <InfiniteScroll pageStart={0} initialLoad={false} loadMore={handleLoadMoreData} hasMore={totalCountOfEntities > tableRows.length} loader={false}>
            <ShimmeredDetailsList
              items={tableRows}
              enableShimmer={!tableRows}
              columns={tableHeader.filter((column) => column.isColumnVisible === true)}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={formatMessage({ id: 'aria-labels.toggle-selection' })}
              ariaLabelForSelectAllCheckbox={formatMessage({ id: 'aria-labels.toggle-selection-for-all-items' })}
              checkButtonAriaLabel={formatMessage({ id: 'aria-labels.select-row' })}
              checkboxVisibility={1}
              columnReorderOptions={getColumnReorderOptions()}
              onRenderDetailsHeader={(props) => <DetailsHeader {...props}></DetailsHeader>}
              onRenderRow={(rowProps: IDetailsRowProps) => <TableRow rowProps={rowProps} selection={selection} onOpenProfile={onOpenProfile} />}
              onColumnHeaderClick={handleColumnClicked}
            />
          </InfiniteScroll>
        </div>
      </MarqueeSelection>
      {isTableMobileColumnSelectionPopupVisible && isOnTouch && (
        <NewTablePopupMobile tableHeader={tableHeader} onUpdateTableHeader={onUpdateTableHeader} fixedColumnIndex={fixedColumnIndex} />
      )}
    </div>
  );
};
