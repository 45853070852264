import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { useAppSelector, WizardIntro, useAppDispatch, regAtLeastOneCharacter, maximumContactLastNameLength, isTextInputValid } from 'app/common';
import {
  goToCreateContactNextStep,
  closeCreateContactWizard,
  ContactInfo,
  selectContactInfoToAdd,
  setContactInfoToAdd,
  ContactForm,
  setContactInfo,
  CreateContactSteps,
  CreateContactFooter
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { ControlledInput } from 'app/pages/my-audience/common';
import { FormattedMessage, useIntl } from 'app/i18n';

export const AddGeneralContactInfo = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const contactInfo = useAppSelector<ContactInfo>(selectContactInfoToAdd);

  const { handleSubmit, control, watch } = useForm<ContactForm, any>({
    defaultValues: {
      title: contactInfo.title,
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleNextButtonClick = () => {
    handleSubmit(
      (data) => {
        const newContactInfo: ContactInfo = setContactInfo(data);

        dispatch(setContactInfoToAdd(newContactInfo));
        dispatch(goToCreateContactNextStep());
      },
      (error) => {
        Sentry.setExtra('Path', AddGeneralContactInfo.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateContactWizard());
  };

  const handleFieldsValidation = () => {
    const watchLastName = watch('lastName');
    const isLastNameValid = isTextInputValid({ inputValue: watchLastName, maxInputLength: maximumContactLastNameLength });

    return isLastNameValid;
  };

  const areGeneralInfoValid = handleFieldsValidation();

  return (
    <>
      <CreateContactSteps areGeneralInfoValid={areGeneralInfoValid} />
      <div className="wizard-panel-form-wrap">
        <WizardIntro title={formatMessage({ id: 'add-menu-create-mo.contact-title' })} description="" />
        <div className="wizard-panel-form">
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-mo.contact-title-label' })} control={control} name="title" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput label={formatMessage({ id: 'add-menu-create-mo.contact-first-name-label' })} control={control} name="firstName" />
          </div>
          <div className="wizard-panel-input">
            <ControlledInput
              label={
                <span className="required-label">
                  <FormattedMessage id={'add-menu-create-mo.contact-last-name-label'} />
                  <span>*</span>
                </span>
              }
              control={control}
              name="lastName"
              rules={{
                pattern: {
                  value: regAtLeastOneCharacter,
                  message: formatMessage(
                    { id: 'error-messages.not-valid-name' },
                    { entity: formatMessage({ id: 'add-menu-create-mo.contact-last-name-label' }) }
                  )
                },
                maxLength: {
                  message: formatMessage({ id: 'error-messages.characters-max-number' }, { maxCharactersNumber: maximumContactLastNameLength }),
                  value: maximumContactLastNameLength
                },
                required: formatMessage({ id: 'error-messages.required-name' }, { entity: formatMessage({ id: 'add-menu-create-mo.contact-last-name-label' }) })
              }}
            />
          </div>
        </div>
        <CreateContactFooter
          onNextButtonClick={handleNextButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
          isNextButtonDisabled={!areGeneralInfoValid}
        />
      </div>
    </>
  );
};
