import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterItem, QueryParams, SearchSuggestionCategory, TableHeader, Tag, TagProjection } from 'app/common';
import {
  Company,
  CompanySearchResultTableData,
  createCompanySearchResultTableData,
  DefaultCompaniesSortingInput,
  ICompaniesSortingInput
} from 'app/pages/my-audience/companies';
import { RootState } from 'app/redux/store';

export interface CompaniesState {
  companies: Company[];
  companiesPreviousState: Company[];
  isFiltersPanelOpen: boolean;
  isSearchAnimationActive: boolean;
  pageNumber: number;
  pageSize: number;
  searchText: string;
  filterItems: FilterItem[];
  sortingInput: ICompaniesSortingInput;
  totalCountOfCompanies: number;
  isSavedSearchesPanelOpen: boolean;
  filterSuggestionsSearchText: string;
  filterSuggestionsPageSize: number;
  companyNameFilterSuggestions: string[];
  isLoadingNameSuggestions: boolean;
  contactNameFilterSuggestions: string[];
  isLoadingContactNameSuggestions: boolean;
  tagNameFilterSuggestions: string[];
  isLoadingTagNameSuggestions: boolean;
  countryNameFilterSuggestions: string[];
  isLoadingCountrySuggestions: boolean;
  registrationNumberFilterSuggestions: string[];
  isLoadingRegistrationNumberSuggestions: boolean;
  databaseTypeFilterSuggestions: string[];
  isLoadingDatabaseTypeSuggestions: boolean;
  cityFilterSuggestions: string[];
  isLoadingCitySuggestions: boolean;
  postalCodeFilterSuggestions: string[];
  isLoadingPostalCodeSuggestions: boolean;
  companyToDelete: Company;
  searchSuggestionsText: string;
  companiesSearchSuggestions: SearchSuggestionCategory[];
  isLoadingSearchSuggestions: boolean;
  isTransformCompanyDialogOpen: boolean;
  isRemoveCompanyDialogOpen: boolean;
  isLoadingSearchResults: boolean;
  searchResultsTableHeader: TableHeader[];
  exportHeader: TableHeader[];
  companiesToRemoveIds: string[];
  selectedCompanies: Company[];
  selectedCompaniesIds: string[];
  allTags: Tag[];
  filteredTags: Tag[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToCompanies: TagProjection;
  tagToRemoveFromCompanies: TagProjection;
  isLoadingPostCodeSuggestions: boolean;
}

const initialState: CompaniesState = {
  pageNumber: 1,
  pageSize: 30,
  searchText: '',
  filterItems: [],
  totalCountOfCompanies: 0,
  companies: [],
  companiesPreviousState: [],
  sortingInput: new DefaultCompaniesSortingInput(),
  isSavedSearchesPanelOpen: false,
  isFiltersPanelOpen: false,
  filterSuggestionsSearchText: '',
  filterSuggestionsPageSize: 10,
  companyNameFilterSuggestions: [],
  isLoadingNameSuggestions: false,
  contactNameFilterSuggestions: [],
  isLoadingContactNameSuggestions: false,
  tagNameFilterSuggestions: [],
  isLoadingTagNameSuggestions: false,
  countryNameFilterSuggestions: [],
  isLoadingCountrySuggestions: false,
  registrationNumberFilterSuggestions: [],
  isLoadingRegistrationNumberSuggestions: false,
  databaseTypeFilterSuggestions: [],
  isLoadingDatabaseTypeSuggestions: false,
  cityFilterSuggestions: [],
  isLoadingCitySuggestions: false,
  postalCodeFilterSuggestions: [],
  isSearchAnimationActive: false,
  isLoadingPostalCodeSuggestions: false,
  searchSuggestionsText: '',
  companiesSearchSuggestions: [],
  companyToDelete: {} as Company,
  isTransformCompanyDialogOpen: false,
  isRemoveCompanyDialogOpen: false,
  isLoadingSearchSuggestions: false,
  isLoadingSearchResults: false,
  isLoadingPostCodeSuggestions: false,
  searchResultsTableHeader: [],
  exportHeader: [],
  companiesToRemoveIds: [],
  selectedCompanies: [],
  selectedCompaniesIds: [],
  allTags: [],
  filteredTags: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToCompanies: {} as TagProjection,
  tagToRemoveFromCompanies: {} as TagProjection
};

export type SortingInputSelector = (state: RootState) => ICompaniesSortingInput;
export const selectSortingInput: SortingInputSelector = createSelector(
  [(state: RootState) => state.companies.sortingInput],
  (sortingInput: ICompaniesSortingInput) => sortingInput
);

export type SelectedCompaniesIdsSelector = (state: RootState) => string[];
export const selectSelectedCompaniesIds: SelectedCompaniesIdsSelector = createSelector(
  [(state: RootState) => state.companies.selectedCompaniesIds],
  (selectedCompaniesIds: string[]) => selectedCompaniesIds
);

export type TotalCountOfCompaniesSelector = (state: RootState) => number;
export const selectTotalCountOfCompanies: TotalCountOfCompaniesSelector = createSelector(
  [(state: RootState) => state.companies.totalCountOfCompanies],
  (totalCountOfCompanies: number) => totalCountOfCompanies
);

export type CompaniesToRemoveIdsSelector = (state: RootState) => string[];
export const selectCompaniesToRemoveIds: CompaniesToRemoveIdsSelector = createSelector(
  [(state: RootState) => state.companies.companiesToRemoveIds],
  (companiesToRemoveIds: string[]) => companiesToRemoveIds
);

export type CompaniesSelector = (state: RootState) => Company[];
export const selectCompanies: CompaniesSelector = createSelector([(state: RootState) => state.companies.companies], (companies: Company[]) => companies);

export type CompanyNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectCompanyNameFilterSuggestions: CompanyNameFilterSuggestionsSelector = createSelector(
  (state: RootState) => state.companies.companyNameFilterSuggestions,
  (companyNameFilterSuggestions: string[]) => companyNameFilterSuggestions
);

export type ContactNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectContactNameFilterSuggestions: ContactNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.contactNameFilterSuggestions],
  (contactNameFilterSuggestions: string[]) => contactNameFilterSuggestions
);

export type TagNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectTagNameFilterSuggestions: TagNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.tagNameFilterSuggestions],
  (tagNameFilterSuggestions: string[]) => tagNameFilterSuggestions
);

export type CountryFilterSuggestionsSelector = (state: RootState) => string[];
export const selectCountryFilterSuggestions: CountryFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.countryNameFilterSuggestions],
  (countryNameFilterSuggestions: string[]) => countryNameFilterSuggestions
);

export type RegistrationNumberFilterSuggestionsSelector = (state: RootState) => string[];
export const selectRegistrationNumberFilterSuggestions: RegistrationNumberFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.registrationNumberFilterSuggestions],
  (registrationNumberFilterSuggestions: string[]) => registrationNumberFilterSuggestions
);

export type DatabaseTypeFilterSuggestionsSelector = (state: RootState) => string[];
export const selectDatabaseTypeFilterSuggestions: DatabaseTypeFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.databaseTypeFilterSuggestions],
  (databaseTypeFilterSuggestions: string[]) => databaseTypeFilterSuggestions
);

export type CityFilterSuggestionsSelector = (state: RootState) => string[];
export const selectCityFilterSuggestions: CityFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.cityFilterSuggestions],
  (cityFilterSuggestions: string[]) => cityFilterSuggestions
);

export type PostalCodeFilterSuggestionsSelector = (state: RootState) => string[];
export const selectPostalCodeFilterSuggestions: PostalCodeFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.postalCodeFilterSuggestions],
  (postalCodeFilterSuggestions: string[]) => postalCodeFilterSuggestions
);

export type CountOfLoadedCompaniesSelector = (state: RootState) => number;
export const selectCountOfLoadedCompanies: CountOfLoadedCompaniesSelector = createSelector(
  [(state: RootState) => state.companies.companies],
  (companies: Company[]) => companies.length
);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector([(state: RootState) => state.companies.pageNumber], (pageNumber: number) => pageNumber);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.companies.pageSize], (pageSize: number) => pageSize);

export type IsTransformCompanyDialogOpenSelector = (state: RootState) => boolean;
export const selectIsTransformCompanyDialogOpen: IsTransformCompanyDialogOpenSelector = createSelector(
  [(state: RootState) => state.companies.isTransformCompanyDialogOpen],
  (isTransformCompanyDialogOpen: boolean) => isTransformCompanyDialogOpen
);

export type IsSearchAnimationActiveSelector = (state: RootState) => boolean;
export const selectIsSearchAnimationActive: IsSearchAnimationActiveSelector = createSelector(
  [(state: RootState) => state.companies.isSearchAnimationActive],
  (isSearchAnimationActive: boolean) => isSearchAnimationActive
);

export type IsSavedSearchesPanelOpenSelector = (state: RootState) => boolean;
export const selectIsSavedSearchesPanelOpen: IsSavedSearchesPanelOpenSelector = createSelector(
  [(state: RootState) => state.companies.isSavedSearchesPanelOpen],
  (isSavedSearchesPanelOpen: boolean) => isSavedSearchesPanelOpen
);

export type IsFilterPanelOpenSelector = (state: RootState) => boolean;
export const selectIsFilterPanelOpen: IsFilterPanelOpenSelector = createSelector(
  [(state: RootState) => state.companies.isFiltersPanelOpen],
  (isFiltersPanelOpen: boolean) => isFiltersPanelOpen
);

export type IsRemoveCompanyDialogOpenSelector = (state: RootState) => boolean;
export const selectIsRemoveCompanyDialogOpen: IsRemoveCompanyDialogOpenSelector = createSelector(
  [(state: RootState) => state.companies.isRemoveCompanyDialogOpen],
  (isRemoveCompanyDialogOpen: boolean) => isRemoveCompanyDialogOpen
);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector([(state: RootState) => state.companies.searchText], (searchText: string) => searchText);

export type CompaniesSearchSuggestionsTextSelector = (state: RootState) => string;
export const selectCompaniesSearchSuggestionsText: CompaniesSearchSuggestionsTextSelector = createSelector(
  [(state: RootState) => state.companies.searchSuggestionsText],
  (searchSuggestionsText: string) => searchSuggestionsText
);

export type CompaniesSearchSuggestionsSelector = (state: RootState) => SearchSuggestionCategory[];
export const selectCompaniesSearchSuggestions: CompaniesSearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.companiesSearchSuggestions],
  (companiesSearchSuggestions: SearchSuggestionCategory[]) => companiesSearchSuggestions
);

export type IsLoadingSearchSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchSuggestions: IsLoadingSearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingSearchSuggestions],
  (isLoadingSearchSuggestions: boolean) => isLoadingSearchSuggestions
);

export type IsLoadingSearchResultsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchResults: IsLoadingSearchResultsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingSearchResults],
  (isLoadingSearchResults: boolean) => isLoadingSearchResults
);

export type FilterItemsSelector = (state: RootState) => FilterItem[];
export const selectFilterItems: FilterItemsSelector = createSelector(
  [(state: RootState) => state.companies.filterItems],
  (filterItems: FilterItem[]) => filterItems
);

export type SearchResultTableRowsSelector = (state: RootState) => CompanySearchResultTableData[];
export const selectSearchResultTableRows: SearchResultTableRowsSelector = createSelector(
  [(state: RootState) => state.companies.companies],
  (companies: Company[]) => companies.map((company) => createCompanySearchResultTableData(company))
);

export type SearchResultsTableHeaderSelector = (state: RootState) => TableHeader[];
export const selectSearchResultTableHeader: SearchResultsTableHeaderSelector = createSelector(
  [(state: RootState) => state.companies.searchResultsTableHeader],
  (searchResultsTableHeader: TableHeader[]) => searchResultsTableHeader
);

export type ExportHeaderSelector = (state: RootState) => TableHeader[];
export const selectExportHeader: ExportHeaderSelector = createSelector(
  [(state: RootState) => state.companies.exportHeader],
  (exportHeader: TableHeader[]) => exportHeader
);

export type FilterSuggestionsSearchTextSelector = (state: RootState) => string;
export const selectFilterSuggestionsSearchText: FilterSuggestionsSearchTextSelector = createSelector(
  [(state: RootState) => state.companies.filterSuggestionsSearchText],
  (filterSuggestionsSearchText: string) => filterSuggestionsSearchText
);

export type FilterSuggestionsPageSizeSelector = (state: RootState) => number;
export const selectFilterSuggestionsPageSize: FilterSuggestionsPageSizeSelector = createSelector(
  [(state: RootState) => state.companies.filterSuggestionsPageSize],
  (filterSuggestionsPageSize: number) => filterSuggestionsPageSize
);

export type IsLoadingNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingNameSuggestions: IsLoadingNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingNameSuggestions],
  (isLoadingNameSuggestions: boolean) => isLoadingNameSuggestions
);

export type IsLoadingContactNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingContactNameSuggestions: IsLoadingContactNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingContactNameSuggestions],
  (isLoadingContactNameSuggestions: boolean) => isLoadingContactNameSuggestions
);

export type IsLoadingTagSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingTagNameSuggestions: IsLoadingTagSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingTagNameSuggestions],
  (isLoadingTagNameSuggestions: boolean) => isLoadingTagNameSuggestions
);

export type IsLoadingCountrySuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingCountrySuggestions: IsLoadingCountrySuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingCountrySuggestions],
  (isLoadingCountrySuggestions: boolean) => isLoadingCountrySuggestions
);

export type IsLoadingRegistrationNumberSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingRegistrationNumberSuggestions: IsLoadingRegistrationNumberSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingRegistrationNumberSuggestions],
  (isLoadingRegistrationNumberSuggestions: boolean) => isLoadingRegistrationNumberSuggestions
);

export type IsLoadingDatabaseTypeSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingDatabaseTypeSuggestions: IsLoadingDatabaseTypeSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingDatabaseTypeSuggestions],
  (isLoadingDatabaseTypeSuggestions: boolean) => isLoadingDatabaseTypeSuggestions
);

export type IsLoadingCitySuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingCitySuggestions: IsLoadingCitySuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingCitySuggestions],
  (isLoadingCitySuggestions: boolean) => isLoadingCitySuggestions
);

export type IsLoadingPostCodeSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingPostCodeSuggestions: IsLoadingPostCodeSuggestionsSelector = createSelector(
  [(state: RootState) => state.companies.isLoadingPostCodeSuggestions],
  (isLoadingPostCodeSuggestions: boolean) => isLoadingPostCodeSuggestions
);

export type AllTagsSelector = (state: RootState) => Tag[];
export const selectAllTags: AllTagsSelector = createSelector([(state: RootState) => state.companies.allTags], (allTags: Tag[]) => allTags);

export type FilteredTagsInPanelSelector = (state: RootState) => Tag[];
export const selectFilteredTagsInPanel: FilteredTagsInPanelSelector = createSelector(
  [(state: RootState) => state.companies.filteredTags],
  (filteredTags: Tag[]) => filteredTags
);

export type TagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: TagNameToCreateSelector = createSelector(
  [(state: RootState) => state.companies.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type TagsToDeleteSelector = (state: RootState) => Tag[];
export const selectTagsToDelete: TagsToDeleteSelector = createSelector(
  [(state: RootState) => state.companies.tagsToDelete],
  (tagsToDelete: Tag[]) => tagsToDelete
);

export type tagToAddToCompaniesSelector = (state: RootState) => TagProjection;
export const selectTagToAddToCompanies: tagToAddToCompaniesSelector = createSelector(
  [(state: RootState) => state.companies.tagToAddToCompanies],
  (tagToAddToCompanies: TagProjection) => tagToAddToCompanies
);

export type TagToRemoveFromCompaniesSelector = (state: RootState) => TagProjection;
export const selectTagToRemoveFromCompanies: TagToRemoveFromCompaniesSelector = createSelector(
  [(state: RootState) => state.companies.tagToRemoveFromCompanies],
  (tagToRemoveFromCompanies: TagProjection) => tagToRemoveFromCompanies
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setTotalCountOfCompanies: (state, action: PayloadAction<number>) => {
      state.totalCountOfCompanies = action.payload;
    },
    firstPageOfCompaniesRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = 1;
      state.totalCountOfCompanies = 0;
      state.companies = [];
    },
    firstPageOfCompaniesReceived: (state, action: PayloadAction<Company[]>) => {
      state.companies = action.payload;
      state.isLoadingSearchResults = false;

      const topCompanies = state.companies.slice(0, 5);
      state.companyNameFilterSuggestions = Array.from(new Set(topCompanies.map((company) => company.name)));
      state.contactNameFilterSuggestions = Array.from(
        new Set(
          topCompanies
            .flatMap((company) => company.companyContacts)
            .filter(Boolean)
            .map((contact) => `${contact.firstName} ${contact.lastName}`)
        )
      );
      state.tagNameFilterSuggestions = Array.from(new Set(topCompanies.flatMap((company) => company.tags).map((tag) => tag.name)));
      state.countryNameFilterSuggestions = Array.from(new Set(topCompanies.map((company) => company.contactData?.country).filter(Boolean)));
      state.registrationNumberFilterSuggestions = Array.from(new Set(topCompanies.map((company) => company.registrationNumber).filter(Boolean)));
      state.databaseTypeFilterSuggestions = Array.from(new Set(topCompanies.map((company) => company.databaseType).filter(Boolean)));
      state.cityFilterSuggestions = Array.from(new Set(topCompanies.map((company) => company.contactData?.city).filter(Boolean)));
      state.postalCodeFilterSuggestions = Array.from(new Set(topCompanies.map((company) => company.contactData?.postalCode).filter(Boolean)));
    },
    nextPageOfCompaniesRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = state.pageNumber + 1;
    },
    nextPageOfCompaniesReceived: (state, action: PayloadAction<Company[]>) => {
      state.companies = [...state.companies, ...action.payload];
      state.isLoadingSearchResults = false;
    },
    companiesSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.searchSuggestionsText = action.payload;
      state.isLoadingSearchSuggestions = true;
    },
    companiesSuggestionsReceived: (state, action: PayloadAction<SearchSuggestionCategory[]>) => {
      state.companiesSearchSuggestions = action.payload;
      state.isLoadingSearchSuggestions = false;
    },
    resetCompaniesSuggestions: (state) => {
      state.companiesSearchSuggestions = [];
      state.searchSuggestionsText = '';
    },
    removeCompanyRequested: (state, action: PayloadAction<Company>) => {
      state.companyToDelete = action.payload;
    },
    removedCompany: (state, action: PayloadAction<string>) => {
      state.companies = state.companies.filter((company) => company.id !== action.payload);
    },
    transformCompanyIntoMediaOutletRequested: () => {},
    companyTransformedIntoMediaOutlet: (state, action: PayloadAction<string>) => {
      state.companies = state.companies.filter((company) => company.id !== action.payload);
      state.totalCountOfCompanies--;
    },
    setIsTransformCompanyDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isTransformCompanyDialogOpen = action.payload;
    },
    setIsRemoveCompanyDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isRemoveCompanyDialogOpen = action.payload;
    },
    removeCompanyFromStore: (state, action: PayloadAction<Company>) => {
      state.companies = state.companies.filter((company) => company.id !== action.payload.id);
    },
    undoRemoveCompanyFromStore: (state, action: PayloadAction<Company>) => {
      state.companies = [action.payload, ...state.companies];
    },
    removeCompaniesFromStore: (state, action: PayloadAction<string[]>) => {
      state.companiesPreviousState = state.companies;
      state.companies = state.companies?.filter((company) => !action.payload.some((id) => id === company.id));
    },
    undoRemoveCompaniesFromStore: (state) => {
      state.companies = state.companiesPreviousState;
      state.companiesPreviousState = [];
    },
    addCompaniesFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = [...state.filterItems, action.payload];
    },
    removeCompaniesFilter: (state, action: PayloadAction<FilterItem>) => {
      const index = state.filterItems.findIndex((fi) => fi.fieldName === action.payload.fieldName && fi.value === action.payload.value);
      state.filterItems.splice(index, 1);
    },
    clearAllFilters: (state) => {
      state.filterItems = [];
      state.companyNameFilterSuggestions = [];
      state.contactNameFilterSuggestions = [];
      state.tagNameFilterSuggestions = [];
      state.countryNameFilterSuggestions = [];
      state.registrationNumberFilterSuggestions = [];
      state.databaseTypeFilterSuggestions = [];
      state.cityFilterSuggestions = [];
      state.postalCodeFilterSuggestions = [];
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setQueryParams: (state, action: PayloadAction<QueryParams>) => {
      state.searchText = action.payload.searchText;
      state.filterItems = action.payload.filterItems;
    },
    setFilterItems: (state, action: PayloadAction<FilterItem[]>) => {
      state.filterItems = action.payload;
    },
    updateCompany: (state, action: PayloadAction<Company>) => {
      const index = state.companies.findIndex((c) => c.id === action.payload.id);
      state.companies[index] = action.payload;
    },
    addedTagToCompanySearchResults: (state, action: PayloadAction<{ companyId: string; tag: TagProjection }>) => {
      const index = state.companies.findIndex((c) => c.id === action.payload.companyId);
      state.companies[index] = {
        ...state.companies[index],
        tags: [...state.companies[index]?.tags, action.payload.tag]
      };
    },
    removedTagFromCompanySearchResults: (state, action: PayloadAction<{ companyId: string; tag: TagProjection }>) => {
      const index = state.companies.findIndex((c) => c.id === action.payload.companyId);
      const updatedTags = state.companies[index].tags.filter((t) => t.id !== action.payload.tag.id);
      state.companies[index] = { ...state.companies[index], tags: [...updatedTags] };
    },
    updatedProfilePictureSearchResults: (state, action: PayloadAction<{ companyId: string; imageUrl: string }>) => {
      const index = state.companies.findIndex((c) => c.id === action.payload.companyId);
      state.companies[index] = { ...state.companies[index], profilePictureUrl: action.payload.imageUrl };
    },
    setSearchResultTableHeader: (state, action: PayloadAction<TableHeader[]>) => {
      state.searchResultsTableHeader = action.payload;
      state.exportHeader = action.payload.filter((headerItem) => headerItem.isColumnVisible && headerItem.fieldName !== 'add');
    },
    openSavedSearchesPanel: (state) => {
      state.isSavedSearchesPanelOpen = true;
    },
    closeSavedSearchesPanel: (state) => {
      state.isSavedSearchesPanelOpen = false;
    },
    openFiltersPanel: (state) => {
      state.isFiltersPanelOpen = true;
    },
    closeFiltersPanel: (state) => {
      state.isFiltersPanelOpen = false;
    },
    startSearchAnimation: (state) => {
      state.isSearchAnimationActive = true;
    },
    stopSearchAnimation: (state) => {
      state.isSearchAnimationActive = false;
    },
    startLoadingNameSuggestions: (state) => {
      state.isLoadingNameSuggestions = true;
      state.companyNameFilterSuggestions = [];
    },
    companyNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    companyNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.companyNameFilterSuggestions = action.payload;
      state.isLoadingNameSuggestions = false;
    },
    startLoadingContactNameSuggestions: (state) => {
      state.isLoadingContactNameSuggestions = true;
      state.contactNameFilterSuggestions = [];
    },
    contactNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    contactNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.contactNameFilterSuggestions = action.payload;
      state.isLoadingContactNameSuggestions = false;
    },
    startLoadingTagSuggestions: (state) => {
      state.isLoadingTagNameSuggestions = true;
      state.tagNameFilterSuggestions = [];
    },
    tagFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    tagFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.tagNameFilterSuggestions = action.payload;
      state.isLoadingTagNameSuggestions = false;
    },
    startLoadingCountrySuggestions: (state) => {
      state.isLoadingCountrySuggestions = true;
      state.countryNameFilterSuggestions = [];
    },
    countryFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    countryFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.countryNameFilterSuggestions = action.payload;
      state.isLoadingCountrySuggestions = false;
    },
    startLoadingRegistrationNumberSuggestions: (state) => {
      state.isLoadingRegistrationNumberSuggestions = true;
      state.registrationNumberFilterSuggestions = [];
    },
    registrationNumberFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    registrationNumberFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.registrationNumberFilterSuggestions = action.payload;
      state.isLoadingRegistrationNumberSuggestions = false;
    },
    startLoadingDatabaseTypeSuggestions: (state) => {
      state.isLoadingDatabaseTypeSuggestions = true;
      state.databaseTypeFilterSuggestions = [];
    },
    databaseTypeFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    databaseTypeFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.databaseTypeFilterSuggestions = action.payload;
      state.isLoadingDatabaseTypeSuggestions = false;
    },
    startLoadingCitySuggestions: (state) => {
      state.isLoadingCitySuggestions = true;
      state.cityFilterSuggestions = [];
    },
    cityFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    cityFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.cityFilterSuggestions = action.payload;
      state.isLoadingCitySuggestions = false;
    },
    startLoadingPostalCodeSuggestions: (state) => {
      state.isLoadingPostalCodeSuggestions = true;
      state.postalCodeFilterSuggestions = [];
    },
    postalCodeFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    postalCodeFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.postalCodeFilterSuggestions = action.payload;
      state.isLoadingPostalCodeSuggestions = false;
    },
    setSortingInput: (state, action: PayloadAction<ICompaniesSortingInput>) => {
      state.sortingInput = action.payload;
    },
    removeCompaniesByIdsRequested: (state, action: PayloadAction<string[]>) => {
      state.companiesToRemoveIds = action.payload;
    },
    companiesByIdsRemoved: (state, action: PayloadAction<Company[]>) => {
      state.companies = state.companies.filter((c) => !action.payload.map((p) => p.id).includes(c.id));
      state.companiesToRemoveIds = [];
      state.totalCountOfCompanies = state.totalCountOfCompanies - action.payload.length;
    },
    addNewTagRequested: (state, action: PayloadAction<{ selectedCompaniesIds: string[]; text: string }>) => {
      state.selectedCompaniesIds = action.payload.selectedCompaniesIds;
      state.tagNameToCreate = action.payload.text;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.allTags = [...state.allTags, action.payload];
      state.filteredTags = state.allTags;
      state.selectedCompanies = state.selectedCompanies.map((c) => {
        return !c.tags.includes(action.payload) ? (c = { ...c, tags: [...c.tags, action.payload] }) : c;
      });
      state.tagNameToCreate = '';
    },
    deleteTagsRequested: (state, action: PayloadAction<Tag[]>) => {
      state.tagsToDelete = action.payload;
    },
    tagsDeleted: (state, action: PayloadAction<string[]>) => {
      state.allTags = state.allTags.filter((tag) => !action.payload.includes(tag.id));
      state.filteredTags = state.filteredTags.filter((tag) => !action.payload.includes(tag.id));
      state.selectedCompanies = state.selectedCompanies.map((c) => (c = { ...c, tags: c.tags.filter((tag) => !action.payload.includes(tag.id)) }));
      state.tagsToDelete = [];
    },
    addTagToCompaniesRequested: (state, action: PayloadAction<{ selectedCompaniesIds: string[]; tag: Tag }>) => {
      state.selectedCompaniesIds = action.payload.selectedCompaniesIds;
      state.tagToAddToCompanies = action.payload.tag;
    },
    addTagToCompaniesInStore: (state, action: PayloadAction<{ selectedCompaniesIds: string[]; tag: Tag }>) => {
      state.selectedCompaniesIds = action.payload.selectedCompaniesIds;
      state.selectedCompanies = state.selectedCompanies.map((company) => ({ ...company, tags: [...company.tags, action.payload.tag] }));
      state.tagToAddToCompanies = action.payload.tag;
    },
    removeTagFromCompaniesInStore: (state, action: PayloadAction<{ selectedCompaniesIds: string[]; tag: Tag }>) => {
      state.selectedCompaniesIds = action.payload.selectedCompaniesIds;
      state.selectedCompanies = state.selectedCompanies.map((company) => ({
        ...company,
        tags: company.tags.filter((tag) => tag.id !== action.payload.tag.id)
      }));
    },
    removeTagFromCompaniesRequested: (state, action: PayloadAction<{ selectedCompaniesIds: string[]; tag: Tag }>) => {
      state.selectedCompaniesIds = action.payload.selectedCompaniesIds;
      state.tagToRemoveFromCompanies = action.payload.tag;
    },
    filterTags: (state, action: PayloadAction<string>) => {
      state.filteredTags = state.allTags.filter((tag) => tag.name.includes(action.payload));
    },
    getAllCompaniesTagsRequested: () => {},
    allTagsReceived: (state, action: PayloadAction<Tag[]>) => {
      state.allTags = action.payload;
      state.filteredTags = action.payload;
    },
    addedTagToCompaniesSearchResults: (state, action: PayloadAction<{ selectedCompaniesIds: string[]; tag: Tag }>) => {
      const { selectedCompaniesIds, tag } = action.payload;
      state.companies = state.companies.map((company) => {
        if (!selectedCompaniesIds.includes(company.id) || company.tags.map((t) => t.id).includes(tag.id)) return company;

        return {
          ...company,
          tags: [...company.tags, tag]
        };
      });
    },
    removedTagsFromCompaniesSearchResults: (state, action: PayloadAction<{ selectedCompaniesIds: string[]; tags: Tag[] }>) => {
      const { selectedCompaniesIds, tags } = action.payload;
      selectedCompaniesIds.forEach((element) => {
        const index = state.companies.findIndex((c) => c.id === element);
        tags.forEach((tag) => {
          state.companies[index] = {
            ...state.companies[index],
            tags: state.companies[index].tags.filter((t) => t.id !== tag.id)
          };
        });
      });
    }
  }
});

export const {
  setTotalCountOfCompanies,
  nextPageOfCompaniesRequested,
  nextPageOfCompaniesReceived,
  companiesSuggestionsRequested,
  companiesSuggestionsReceived,
  resetCompaniesSuggestions,
  removeCompanyRequested,
  removedCompany,
  transformCompanyIntoMediaOutletRequested,
  companyTransformedIntoMediaOutlet,
  setIsTransformCompanyDialogOpen,
  setIsRemoveCompanyDialogOpen,
  removeCompanyFromStore,
  clearAllFilters,
  undoRemoveCompanyFromStore,
  removeCompaniesFromStore,
  undoRemoveCompaniesFromStore,
  addCompaniesFilter,
  removeCompaniesFilter,
  updateCompany,
  addedTagToCompanySearchResults,
  removedTagFromCompanySearchResults,
  updatedProfilePictureSearchResults,
  setSearchResultTableHeader,
  openSavedSearchesPanel,
  closeSavedSearchesPanel,
  openFiltersPanel,
  closeFiltersPanel,
  startSearchAnimation,
  stopSearchAnimation,
  firstPageOfCompaniesRequested,
  firstPageOfCompaniesReceived,
  companyNameFilterSuggestionsRequested,
  companyNameFilterSuggestionsReceived,
  countryFilterSuggestionsRequested,
  countryFilterSuggestionsReceived,
  registrationNumberFilterSuggestionsRequested,
  registrationNumberFilterSuggestionsReceived,
  databaseTypeFilterSuggestionsRequested,
  databaseTypeFilterSuggestionsReceived,
  cityFilterSuggestionsRequested,
  cityFilterSuggestionsReceived,
  postalCodeFilterSuggestionsRequested,
  postalCodeFilterSuggestionsReceived,
  contactNameFilterSuggestionsReceived,
  contactNameFilterSuggestionsRequested,
  tagFilterSuggestionsReceived,
  tagFilterSuggestionsRequested,
  setSearchText,
  startLoadingNameSuggestions,
  startLoadingContactNameSuggestions,
  startLoadingCitySuggestions,
  startLoadingCountrySuggestions,
  startLoadingDatabaseTypeSuggestions,
  startLoadingPostalCodeSuggestions,
  startLoadingRegistrationNumberSuggestions,
  startLoadingTagSuggestions,
  setSortingInput,
  setQueryParams,
  setFilterItems,
  removeCompaniesByIdsRequested,
  companiesByIdsRemoved,
  addNewTagRequested,
  addTagToCompaniesRequested,
  addedNewTag,
  allTagsReceived,
  deleteTagsRequested,
  filterTags,
  getAllCompaniesTagsRequested,
  addTagToCompaniesInStore,
  removeTagFromCompaniesInStore,
  removeTagFromCompaniesRequested,
  tagsDeleted,
  addedTagToCompaniesSearchResults,
  removedTagsFromCompaniesSearchResults
} = companiesSlice.actions;

export default companiesSlice.reducer;
