import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfMediaOutletContactsRequested,
  selectMediaOutletContactsPageSize,
  selectMediaOutletContactsTotalCount,
  selectIsLoadingMediaOutletContacts,
  setMediaOutletContactsPageNumber,
  setMediaOutletContactsPageSize
} from 'app/pages/my-audience/media-outlet-profile';

export const ContactsPaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfContacts = useAppSelector<number>(selectMediaOutletContactsTotalCount);
  const pageSize = useAppSelector<number>(selectMediaOutletContactsPageSize);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingMediaOutletContacts);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setMediaOutletContactsPageNumber(pageNumber));
    dispatch(pageOfMediaOutletContactsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setMediaOutletContactsPageSize(pageSize));
    dispatch(pageOfMediaOutletContactsRequested());
  };

  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfContacts}
      isLoadingData={isLoadingContacts}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
