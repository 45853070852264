import { Button, Flex, List } from '@fluentui/react-northstar';
import { Person, PersonCardInteraction, PersonViewType } from '@microsoft/mgt-react';
import { Comment, CommentContent, CommentsInput, CommentUser, selectContext, useAppSelector } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useIntl } from 'app/i18n';
import { selectLocale } from 'app/localization';
import { DateTime } from 'luxon';
import { useState } from 'react';

export interface CommentsProps {
  comments: Comment[];
  mentions: CommentUser[];
  addComment(comment: Comment): void;
  addCommentMention(mention: CommentUser): void;
  mentionChange(mentionSearchText: string): void;
  disabled?: boolean;
}

export const Comments = ({ comments, mentions, addComment, addCommentMention, mentionChange, disabled = false }: CommentsProps) => {
  const { formatMessage } = useIntl();

  const locale = useAppSelector<string>(selectLocale);

  const teamsContext = useAppSelector(selectContext);
  const [showCommentReplies, setShowCommentReplies] = useState(new Array(comments.length).fill(false));

  const setShowCommentRepliesTrue = (index: number) => {
    const newArray = [...showCommentReplies];
    newArray[index] = true;
    setShowCommentReplies(newArray);
  };

  const setShowCommentRepliesFalse = (index: number) => {
    const newArray = [...showCommentReplies];
    newArray[index] = false;
    setShowCommentReplies(newArray);
  };

  return (
    <div className="profile-panel-comments-wrap">
      <List>
        {comments.length > 0 &&
          comments.map((comment, index) => {
            const commentCreatedAt = DateTime.fromJSDate(new Date(comment.createdAt));
            return (
              <div className="profile-panel-comments-list" key={comment.id}>
                <List.Item
                  key={comment.id}
                  header={
                    teamsContext?.app.host.clientType === 'web' || teamsContext?.app.host.clientType === 'desktop' ? (
                      <Person
                        className="person-avatar"
                        userId={comment.author.aadObjectId}
                        view={PersonViewType.oneline}
                        personCardInteraction={PersonCardInteraction.hover}
                      />
                    ) : (
                      <>
                        <ProfilePicture name={comment.author.name} size="medium" /> {comment.author.name}
                      </>
                    )
                  }
                  content={<CommentContent content={comment.content} mentions={comment.mentions.map((mention) => mention.name)} />}
                  headerMedia={`${commentCreatedAt.setLocale(locale).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at ${commentCreatedAt.toLocaleString(
                    DateTime.TIME_WITH_SECONDS
                  )}`}
                  index={index}
                />
                {comment.replies.length > 0 && !showCommentReplies[index] && (
                  <Flex hAlign="end">
                    <Button text content={formatMessage({ id: 'comments.show-replies-button' })} onClick={() => setShowCommentRepliesTrue(index)} />
                  </Flex>
                )}
                {showCommentReplies[index] &&
                  comment.replies.map((reply) => {
                    const replyCreatedAt = DateTime.fromJSDate(reply.createdAt);
                    return (
                      <List.Item
                        key={reply.id}
                        styles={{
                          backgroundColor: index % 2 === 0 ? '#f5f2f2' : 'transparent',
                          position: 'relative',
                          left: '20px'
                        }}
                        header={
                          teamsContext?.app.host.clientType === 'web' || teamsContext?.app.host.clientType === 'desktop' ? (
                            <Person userId={reply.author.aadObjectId} view={PersonViewType.oneline} personCardInteraction={PersonCardInteraction.hover} />
                          ) : (
                            <>
                              <ProfilePicture name={reply.author.name} size="small" /> {reply.author.name}
                            </>
                          )
                        }
                        content={
                          <CommentContent
                            content={reply.content.replaceAll('<at>', '@').replaceAll('</at>', '')}
                            mentions={reply.mentions.map((mention) => mention.name)}
                          />
                        }
                        headerMedia={`${replyCreatedAt.setLocale(locale).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at ${replyCreatedAt.toLocaleString(
                          DateTime.TIME_WITH_SECONDS
                        )}`}
                        index={index}
                      />
                    );
                  })}
                {comment.replies.length > 0 && showCommentReplies[index] && (
                  <Flex hAlign="end">
                    <Button text content={formatMessage({ id: 'comments.hide-replies-button' })} onClick={() => setShowCommentRepliesFalse(index)} />
                  </Flex>
                )}
              </div>
            );
          })}
      </List>
      <CommentsInput mentions={mentions} addComment={addComment} addCommentMention={addCommentMention} mentionChange={mentionChange} disabled={disabled} />
    </div>
  );
};
