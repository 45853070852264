import { Icon } from '@fluentui/react';
import { Button, Input, MenuButton, SearchIcon } from '@fluentui/react-northstar';
import { SelectedEntitiesLabel, debounceTimeInMilliseconds, useAppDispatch, useAppSelector } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  pageOfMediaOutletsAndCompaniesRequested,
  openCreateCompanyWizard,
  openCreateMediaOutletWizard,
  setSearchMediaText,
  selectCollaborationsToAdd
} from 'app/pages/my-audience/wizard-create-contact';
import { Permission, useAuthContext } from 'auth';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const CreateContactTableToolbar = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { hasPermission, hasAllPermissions } = useAuthContext();
  const hasPermissionToCreateMediaOutlets = hasPermission(Permission.ManageMediaOutlets);
  const hasPermissionToCreateCompanies = hasAllPermissions([Permission.ManageCompanies, Permission.ManageContacts]);

  const collaborationsToAdd = useAppSelector(selectCollaborationsToAdd);

  useEffect(() => {
    return () => {
      dispatch(setSearchMediaText(''));
    };
  }, [dispatch]);

  const actionItem = [
    {
      key: 'add-contact-toolbar-item-media-outlet',
      onClick: () => dispatch(openCreateMediaOutletWizard()),
      content: formatMessage({ id: 'add-menu-create-contact.media-outlet-type' }),
      icon: <Icon iconName="News" />,
      disabled: !hasPermissionToCreateMediaOutlets
    },
    {
      key: 'add-contact-toolbar-item-company',
      onClick: () => dispatch(openCreateCompanyWizard()),
      content: formatMessage({ id: 'add-menu-create-contact.company-type' }),
      icon: <Icon iconName="CityNext" />,
      disabled: !hasPermissionToCreateCompanies
    }
  ];

  const handleSearchMedia = (searchText: string) => {
    dispatch(setSearchMediaText(searchText));
    dispatch(pageOfMediaOutletsAndCompaniesRequested());
  };

  const handleInputChange = useDebouncedCallback(handleSearchMedia, debounceTimeInMilliseconds);

  return (
    <div className="wizard-panel-toolbar">
      <div className="wizard-panel-toolbar-create">
        <MenuButton
          trigger={<Button text content={formatMessage({ id: 'add-menu-create-contact.create-new-button' })} icon={<Icon iconName="Add" />} />}
          menu={actionItem}
          on="click"
        />
      </div>
      <SelectedEntitiesLabel counter={collaborationsToAdd.length} />
      <div className="wizard-panel-toolbar-filters">
        <div className="wizard-panel-toolbar-input">
          <Input
            clearable
            icon={<SearchIcon />}
            placeholder={formatMessage({ id: 'add-menu-create-contact.search-label' })}
            autoComplete="off"
            onChange={(_, data) => handleInputChange(data.value)}
          />
        </div>
      </div>
    </div>
  );
};
