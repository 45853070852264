import { FilterItem, ShimmerCards } from 'app/common';
import { DeleteSavedSearchDialog, SavedSearch, SavedSearchesGrid } from 'app/pages/my-audience/saved-searches';
import 'app/pages/my-audience/saved-searches/components/saved-searches.scss';

interface SavedSearchesProps {
  isLoadingSavedSearches: boolean;
  pageSize: number;
  savedSearches: SavedSearch[];
  totalCount: number;
  onSearchSavedSearches?: (searchText: string) => void;
  onFilterSavedSearches?: (filterItem: FilterItem) => void;
  onResetFilter?: () => void;
  onHandleLoadMore?: () => void;
  onSavedSearchClick: (savedSearch: SavedSearch) => void;
  onReorderSavedSearches: (reorderedSavedSearches: SavedSearch[]) => void;
  onSetSavedSearchToRemove: (savedSearchToRemove: SavedSearch) => void;
  onUpdateSavedSearch: (updatedSavedSearch: SavedSearch) => void;
  isDeleteDialogOpened: boolean;
  onRemoveSavedSearch: () => void;
  onCloseDeleteDialog: () => void;
  searchText?: string;
}

export const SavedSearches = ({
  savedSearches,
  totalCount,
  pageSize,
  isLoadingSavedSearches,
  onRemoveSavedSearch,
  isDeleteDialogOpened,
  onCloseDeleteDialog,
  onSearchSavedSearches,
  onFilterSavedSearches,
  onResetFilter,
  onHandleLoadMore,
  onSavedSearchClick,
  onReorderSavedSearches,
  onSetSavedSearchToRemove,
  onUpdateSavedSearch,
  searchText
}: SavedSearchesProps) => {
  return (
    <>
      <SavedSearchesGrid
        isLoadingSavedSearches={isLoadingSavedSearches}
        pageSize={pageSize}
        savedSearches={savedSearches}
        totalCount={totalCount}
        onUpdateSavedSearch={onUpdateSavedSearch}
        onSavedSearchClick={onSavedSearchClick}
        onSearchSavedSearches={onSearchSavedSearches}
        onFilterSavedSearches={onFilterSavedSearches}
        onResetFilter={onResetFilter}
        onLoadMore={onHandleLoadMore}
        onReorderSavedSearches={onReorderSavedSearches}
        onSetSavedSearchToRemove={onSetSavedSearchToRemove}
        searchText={searchText}
      />
      <DeleteSavedSearchDialog opened={isDeleteDialogOpened} removeSavedSearch={onRemoveSavedSearch} close={onCloseDeleteDialog} />
      {isLoadingSavedSearches && <ShimmerCards />}
    </>
  );
};
