import { debounceTimeInMilliseconds, selectCompanyJobTitles, useAppDispatch, useAppSelector } from 'app/common';
import { PanelToolbar } from 'app/pages/my-audience/common/components/panel-toolbar/PanelToolbar';
import {
  CollaborationsTable,
  AddContactWizard,
  ContactsPaging,
  setCompanyContactsPageNumber,
  setSearchCompanyContactsTextAndFilterItem,
  pageOfCompanyContactsRequested,
  setSearchWizardContactsTextAndFilterItem,
  pageOfWizardContactsRequested,
  openAddContactWizard
} from 'app/pages/my-audience/company-profile';
import { JobTitle } from 'app/pages/my-audience/contacts';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAuthContext, Permission } from 'auth';

export const ContactsTab = () => {
  const dispatch = useAppDispatch();
  const { hasAllPermissions } = useAuthContext();

  const hasPermissionToCreateAndEditCollaboration = hasAllPermissions([Permission.ManageContacts, Permission.ManageCompanies]);

  const companyJobTitles = useAppSelector<JobTitle[]>(selectCompanyJobTitles);
  const [inputValue, setInputValue] = useState('');

  const handleSearchByNameAndRole = useDebouncedCallback((searchText: string, role: string) => {
    dispatch(setCompanyContactsPageNumber(1));
    dispatch(setSearchCompanyContactsTextAndFilterItem({ searchText, role }));
    dispatch(pageOfCompanyContactsRequested());
    setInputValue(searchText);
  }, debounceTimeInMilliseconds);

  const handleOpenWizard = () => {
    dispatch(setCompanyContactsPageNumber(1));
    dispatch(setSearchWizardContactsTextAndFilterItem({ searchText: '', role: '' }));
    dispatch(pageOfWizardContactsRequested());
    dispatch(openAddContactWizard());
  };

  return (
    <>
      <PanelToolbar
        filterItems={companyJobTitles.map((jobTitle) => jobTitle.name)}
        onSearchByNameAndRole={handleSearchByNameAndRole}
        onOpenWizard={handleOpenWizard}
        inputValue={inputValue}
        disabled={!hasPermissionToCreateAndEditCollaboration}
      />
      <CollaborationsTable actionItemsDisabled={!hasPermissionToCreateAndEditCollaboration} />
      <ContactsPaging />
      <AddContactWizard />
    </>
  );
};
