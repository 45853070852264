import { Button } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from 'app/common';
import {
  MediaOutletAddressInformation,
  CreateMediaOutletSteps,
  CreateMediaOutletWizardStep,
  goToStep,
  MediaOutletGeneralInformation,
  selectAddressInfoToAdd,
  selectCollaborationsDetails,
  selectGeneralInfoToAdd,
  ReviewCollaborations,
  ReviewMediaOutletInformation,
  CreateMediaOutletFooter,
  closeCreateMediaOutletWizard,
  goToPreviousStep,
  addMediaOutletRequested
} from 'app/pages/my-audience/wizard-create-media-outlet';
import { FormattedMessage, useIntl } from 'app/i18n';
import { CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';

export interface FinishCreateMediaOutletProps {
  setCurrentStep?: (step: number) => void;
}

export const FinishCreateMediaOutlet: React.FC<FinishCreateMediaOutletProps> = ({ setCurrentStep }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const collaborationsDetails = useAppSelector<CollaborationWithContactInfo[]>(selectCollaborationsDetails);
  const generalInfo = useAppSelector<MediaOutletGeneralInformation>(selectGeneralInfoToAdd);
  const addressInfo = useAppSelector<MediaOutletAddressInformation>(selectAddressInfoToAdd);

  const handleEdit = (wizardStep: number) => {
    dispatch(goToStep(wizardStep));
  };

  const handleFinishButtonClick = () => {
    dispatch(addMediaOutletRequested());
  };

  const handleCancelButtonClick = () => {
    dispatch(closeCreateMediaOutletWizard());
  };

  const handleBackButtonClick = () => {
    dispatch(goToPreviousStep());
  };

  return (
    <>
      <CreateMediaOutletSteps isStepValid={true} />
      <div className="wizard-panel-finish-wrap">
        <div className="wizard-panel-intro">
          <h5 className="wizard-panel-title">
            <FormattedMessage id={'add-menu-create-mo.additional-finish-title'} />
          </h5>
        </div>
        <div className="wizard-panel-finish-main-container">
          <ReviewMediaOutletInformation onEdit={handleEdit} addressInfo={addressInfo} generalInfo={generalInfo} />
          <div className="wizard-panel-finish-content">
            <div className="wizard-panel-finish-main-intro">
              <h5 className="wizard-panel-finish-title">
                <FormattedMessage id={'add-menu-create-mo.additional-finish-collaboration'} />
              </h5>
              <div className="custom-accordion-head-actions">
                <Button
                  icon={<Icon iconName="Edit" />}
                  text
                  content={formatMessage({ id: 'buttons.edit' })}
                  onClick={() => handleEdit(CreateMediaOutletWizardStep.CollaborationDetails)}
                />
              </div>
            </div>
            {collaborationsDetails.map((details, i) => (
              <div key={i}>
                <ReviewCollaborations collaborationDetails={details} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <CreateMediaOutletFooter
        onFinishButtonClick={handleFinishButtonClick}
        onBackButtonClick={handleBackButtonClick}
        onCancelButtonClick={handleCancelButtonClick}
      />
    </>
  );
};
