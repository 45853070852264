import { IColumn } from '@fluentui/react';
import { NewTable, TableHeader, getAllJobTitlesRequested, getLanguagesRequested, useAppDispatch, useAppSelector } from 'app/common';
import {
  clearAllActivitiesFilters,
  contactProfilePanelDataRequested,
  firstPageOfSelectedContactActivitiesRequested,
  getAllMediaResortsRequested,
  getAllTopicsRequested,
  getListsByNameRequested,
  openProfilePanel,
  selectedContactListsRequested,
  selectedContactRecipientsRequested,
  selectedContactSendingActivitiesRequested
} from 'app/pages/my-audience/contact-profile';
import {
  ContactSearchResultTableData,
  ContactsSortingInput,
  firstPageOfContactsRequested,
  nextPageOfContactsRequested,
  selectIsLoadingSearchResults,
  selectSearchResultTableHeader,
  selectSearchResultTableRows,
  selectTotalCountOfContacts,
  setSearchResultTableHeader,
  setSortingInput
} from 'app/pages/my-audience/contacts';
import { useNavigate, useParams } from 'react-router-dom';

export const ContactsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const contactSearchResultTableData = useAppSelector<ContactSearchResultTableData[]>(selectSearchResultTableRows);
  const totalCountOfContacts = useAppSelector<number>(selectTotalCountOfContacts);
  const isLoadingSearchResults = useAppSelector<boolean>(selectIsLoadingSearchResults);

  const header = useAppSelector<TableHeader[]>(selectSearchResultTableHeader);

  const handleOpenProfile = (contactId: string) => {
    dispatch(openProfilePanel());
    dispatch(contactProfilePanelDataRequested(contactId));
    dispatch(selectedContactRecipientsRequested());
    dispatch(clearAllActivitiesFilters());
    dispatch(firstPageOfSelectedContactActivitiesRequested());
    dispatch(selectedContactSendingActivitiesRequested());
    dispatch(getAllJobTitlesRequested());
    dispatch(getListsByNameRequested(''));
    dispatch(getLanguagesRequested());
    dispatch(getAllMediaResortsRequested());
    dispatch(getAllTopicsRequested());
    dispatch(selectedContactListsRequested());
    navigate(`/my-audience/contacts/results/${params.contactsQuery}/contact-info/${contactId}/profile`);
  };

  const handleLoadMoreContacts = () => {
    dispatch(nextPageOfContactsRequested());
  };

  const handleColumnClicked = (selectedColumn: IColumn): void => {
    const sortOrder = selectedColumn.isSortedDescending ? 'ASC' : 'DESC';
    dispatch(setSortingInput(new ContactsSortingInput(selectedColumn.fieldName, sortOrder)));

    const index = header.findIndex((column) => selectedColumn.fieldName === column.fieldName);
    const changedHeader = { ...header[index], isSortedDescending: !selectedColumn.isSortedDescending, isSorted: true };

    const headerCopy: TableHeader[] = header.map((column: IColumn) => {
      return { ...column, isSorted: false, isSortedDescending: true };
    });

    headerCopy.splice(index, 1, changedHeader);
    dispatch(setSearchResultTableHeader(headerCopy));
    dispatch(firstPageOfContactsRequested());
  };

  const handleUpdateTableHeader = (updatedHeader: TableHeader[]) => {
    dispatch(setSearchResultTableHeader(updatedHeader));
  };

  return (
    <NewTable
      isLoadingSearchResults={isLoadingSearchResults}
      fixedColumnIndex={0}
      onOpenProfile={handleOpenProfile}
      header={header}
      tableRows={contactSearchResultTableData}
      totalCountOfEntities={totalCountOfContacts}
      handleLoadMore={handleLoadMoreContacts}
      onColumnClicked={handleColumnClicked}
      onUpdateTableHeader={handleUpdateTableHeader}
    />
  );
};
