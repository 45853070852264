import { Accordion, ChevronDownMediumIcon } from '@fluentui/react-northstar';
import { FormattedMessage } from 'app/i18n';
import { Blacklists } from 'app/pages/my-audience/contact-profile';

export const GDPR = () => {
  const panels = [
    {
      title: (
        <div className="accordion-head-box" key="blacklist-title">
          <span className="accordion-heading">
            <FormattedMessage id={'contacts-gdpr-accordion.blacklist'} />
          </span>
          <ChevronDownMediumIcon />
        </div>
      ),
      content: { key: 'blacklist-content', content: <Blacklists /> }
    }
  ];
  return (
    <div className="profile-panel-gdpr-accordion">
      <Accordion defaultActiveIndex={[0]} panels={panels} />
    </div>
  );
};
