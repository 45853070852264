import { TablePaging, useAppDispatch, useAppSelector } from 'app/common';
import {
  pageOfRecipientsRequested,
  resetAudienceTable,
  selectIsLoadingRecipients,
  selectRecipientsPageSize,
  selectRecipientsTotalCount,
  setRecipientsPageNumber,
  setRecipientsPageSize
} from 'app/pages/my-activities/sending-wizard';

export const AudienceTablePaging = () => {
  const dispatch = useAppDispatch();
  const totalCountOfRecipients = useAppSelector<number>(selectRecipientsTotalCount);
  const isLoadingContacts = useAppSelector<boolean>(selectIsLoadingRecipients);

  const pageSize = useAppSelector<number>(selectRecipientsPageSize);

  const handlePageClick = (pageNumber: number) => {
    dispatch(setRecipientsPageNumber(pageNumber));
    dispatch(pageOfRecipientsRequested());
  };

  const handleUpdatePageSize = (pageSize: number) => {
    dispatch(setRecipientsPageSize(pageSize));
    dispatch(resetAudienceTable());
    dispatch(pageOfRecipientsRequested());
  };
  return (
    <TablePaging
      onPageClick={handlePageClick}
      pageSize={pageSize}
      totalCount={totalCountOfRecipients}
      isLoadingData={isLoadingContacts}
      onUpdatePageSize={handleUpdatePageSize}
    />
  );
};
