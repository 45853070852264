import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-northstar';
import { useIntl } from 'app/i18n';

interface WizardFinishIntroProps {
  onEditButtonClick: () => void;
  title: string;
}

export const WizardFinishIntro = ({ onEditButtonClick, title }: WizardFinishIntroProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className="wizard-panel-finish-main-intro">
      <h5 className="wizard-panel-finish-title">{title}</h5>
      <div className="custom-accordion-head-actions">
        <Button icon={<Icon iconName="Edit" />} text content={formatMessage({ id: 'buttons.edit' })} onClick={onEditButtonClick} />
      </div>
    </div>
  );
};
