export class Template {
  constructor(
    public id: string,
    public title: string,
    public imageAsBase64: string,
    public jsonBody: string,
    public htmlBody: string,
    public lastModifiedBy: string,
    public lastModifiedAt: Date,
    public createdAt: Date,
    public createdBy: string
  ) {}
}

export class PageOfTemplates {
  constructor(public totalCount: number, public templates: Template[]) {}
}

export enum TemplatesFilterByType {
  IsSaved = 0,
  IsPrebuilt = 1,
  ShowAll
}
