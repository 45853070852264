import { AddIcon, Button, Input, SearchIcon } from '@fluentui/react-northstar';
import { DisableUnauthorized, selectIsOnDesktop, useAppSelector } from 'app/common';
import { FormattedMessage, useIntl } from 'app/i18n';
import { Permission } from 'auth';

export interface AddMediaToolbarProps {
  openWizard: () => void;
  searchByName: (item: string) => void;
  buttonText: string;
  searchedItemLength: number;
  inputValue: string;
}

export const AddMediaToolbar = ({ openWizard, searchByName, buttonText, searchedItemLength, inputValue }: AddMediaToolbarProps) => {
  const { formatMessage } = useIntl();
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);

  return (
    <div className="accordion-toolbar">
      {isOnDesktop && (
        <div className="accordion-toolbar-button">
          <DisableUnauthorized permissions={[Permission.ManageMediaOutlets]}>
            <Button content={buttonText} icon={<AddIcon />} onClick={openWizard} />
          </DisableUnauthorized>
        </div>
      )}
      <div className="accordion-toolbar-search">
        <span className="accordion-toolbar-items">
          <FormattedMessage id={'media-outlet-and-company-tabs.media-outlets-results-counter'} values={{ counter: searchedItemLength }} />
        </span>
        <Input
          clearable
          icon={<SearchIcon />}
          placeholder={formatMessage({ id: 'media-outlet-and-company-tabs.search-input-placeholder' })}
          value={inputValue}
          onChange={(e) => searchByName((e.target as HTMLTextAreaElement).value ?? '')}
        />
      </div>
    </div>
  );
};
