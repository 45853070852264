import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@fluentui/react-northstar';
import {
  addJobTitleRequested,
  isEmailAddressValidOrEmpty,
  isNumberValidOrEmpty,
  regPhoneNumber,
  regEmail,
  regNumbers,
  selectCompanyJobTitles,
  useAppDispatch,
  useAppSelector,
  WizardIntroInfo,
  WizardWrap,
  isPhoneNumberValidOrEmpty
} from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { ControlledCreateDropdown, ControlledDropdown, ControlledInput } from 'app/pages/my-audience/common';
import {
  closeEditCollaborationWizard,
  createCollaborationFromEditing,
  EditProfileInformationForm,
  resetSelectedContactForEditing,
  selectIsEditCollaborationWizardOpened,
  selectSelectedCollaborationForEditing,
  selectSelectedContactForEditing,
  updateCollaborationRequested
} from 'app/pages/my-audience/company-profile';
import { Collaboration, Contact, JobTitle } from 'app/pages/my-audience/contacts';
import { getNames } from 'country-list';
import { FormattedMessage, useIntl } from 'app/i18n';

export const EditCollaborationWizard = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isEditCollaborationWizardOpened = useAppSelector<boolean>(selectIsEditCollaborationWizardOpened);
  const contact = useAppSelector<Contact>(selectSelectedContactForEditing);
  const collaboration = useAppSelector<Collaboration>(selectSelectedCollaborationForEditing);
  const companyJobTitles = useAppSelector<JobTitle[]>(selectCompanyJobTitles);

  const jobTitles: string[] = useMemo(() => companyJobTitles.map((jt) => jt.name), [companyJobTitles]);

  const { handleSubmit, control, watch } = useForm<EditProfileInformationForm, any>({
    defaultValues: {
      jobTitle: collaboration.jobTitle?.name || '',
      country: collaboration.country,
      address: collaboration.address,
      jobDescription: collaboration.jobDescription,
      mobilePhoneNumber: collaboration.mobilePhoneNumber?.value || '',
      landlinePhoneNumber: collaboration.landlinePhoneNumber?.value || '',
      postalCode: collaboration.postalCode,
      emailAddress: collaboration.emailAddress,
      city: collaboration.city
    },
    reValidateMode: 'onSubmit',
    mode: 'all'
  });

  const handleSaveButtonClick = () => {
    handleSubmit(
      (data) => {
        const selectedJobTitle = companyJobTitles.find((jt) => jt.name === data.jobTitle);
        const updatedCollaboration = createCollaborationFromEditing(data, collaboration, selectedJobTitle);

        dispatch(updateCollaborationRequested({ contact: contact, collaboration: updatedCollaboration }));
        dispatch(closeEditCollaborationWizard());
      },
      (error) => {
        Sentry.setExtra('Path', EditCollaborationWizard.name);
        Sentry.captureException(error);
      }
    )();
  };

  const handleDismissButtonClick = () => {
    dispatch(closeEditCollaborationWizard());
    dispatch(resetSelectedContactForEditing());
  };

  const areProfileInfoFieldsValid =
    isEmailAddressValidOrEmpty(watch('emailAddress')) &&
    isNumberValidOrEmpty(watch('postalCode')) &&
    isPhoneNumberValidOrEmpty(watch('landlinePhoneNumber')) &&
    isPhoneNumberValidOrEmpty(watch('mobilePhoneNumber'));

  const handleCreateNewJobTitle = (name: string) => {
    const jobTitle = new JobTitle('', name, '', collaboration.medium.type);

    dispatch(addJobTitleRequested(jobTitle));
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={isEditCollaborationWizardOpened}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'companies-edit-collaboration.companies-edit-header' })}
      customWidth="500px"
      classModifier="edit"
    >
      <div className="wizard-panel">
        <div className="wizard-panel-content">
          <div className="wizard-panel-main">
            <div className="wizard-panel-form-wrap">
              <WizardIntroInfo
                title={formatMessage({ id: 'companies-edit-collaboration.companies-edit-title' })}
                info={
                  <div className="wizard-panel-intro-info">
                    <ProfilePicture name={`${contact.firstName} ${contact.lastName}`} imageUrl={contact.profilePictureUrl} size="medium" />
                    <span className="wizard-panel-intro-info-text">
                      {contact.firstName} {contact.lastName}
                    </span>
                  </div>
                }
              />
              <div className="wizard-panel-form">
                <div className="custom-dropdown">
                  <ControlledCreateDropdown
                    label={
                      <span className="dropdown-label">
                        <FormattedMessage id={'add-menu-create-contact.additional-job-title-label'} />
                      </span>
                    }
                    name="jobTitle"
                    items={jobTitles}
                    control={control}
                    placeholder={formatMessage({ id: 'add-menu-create-contact.additional-job-title-placeholder' })}
                    defaultValue={collaboration?.jobTitle?.name}
                    createNewEntityMessage={formatMessage({ id: 'aria-labels.create-job-title' })}
                    onCreateNewEntityClick={handleCreateNewJobTitle}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput name="jobDescription" label={formatMessage({ id: 'companies-edit-collaboration.wizard-job-label' })} control={control} />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput name="address" label={formatMessage({ id: 'companies-edit-collaboration.wizard-address-label' })} control={control} />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="emailAddress"
                    label={formatMessage({ id: 'companies-edit-collaboration.wizard-email-label' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regEmail,
                        message: formatMessage({ id: 'error-messages.not-valid-email-address' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="landlinePhoneNumber"
                    label={formatMessage({ id: 'companies-edit-collaboration.wizard-phone-label' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regPhoneNumber,
                        message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="mobilePhoneNumber"
                    label={formatMessage({ id: 'companies-edit-collaboration.wizard-mobile-phone-label' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regPhoneNumber,
                        message: formatMessage({ id: 'error-messages.not-valid-phone-number' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput
                    name="postalCode"
                    label={formatMessage({ id: 'companies-edit-collaboration.wizard-zip-label' })}
                    control={control}
                    rules={{
                      pattern: {
                        value: regNumbers,
                        message: formatMessage({ id: 'error-messages.field-contains-only-numbers' })
                      }
                    }}
                  />
                </div>
                <div className="wizard-panel-input">
                  <ControlledInput name="city" label={formatMessage({ id: 'companies-edit-collaboration.wizard-city-label' })} control={control} />
                </div>
                <div className="wizard-panel-dropdown">
                  <ControlledDropdown
                    name="country"
                    label={<span className="dropdown-label">{formatMessage({ id: 'companies-edit-collaboration.wizard-country-label' })}</span>}
                    items={getNames()}
                    control={control}
                    defaultValue={collaboration.country}
                    placeholder={formatMessage({ id: 'companies-edit-collaboration.wizard-country-placeholder' })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wizard-panel-bottom" style={{ maxWidth: '750px' }}>
          <div className="wizard-panel-bottom-button-cancel">
            <Button content={formatMessage({ id: 'buttons.cancel' })} onClick={handleDismissButtonClick} />
          </div>
          <div className="wizard-panel-bottom-actions">
            <Button content={formatMessage({ id: 'buttons.save' })} primary onClick={handleSaveButtonClick} disabled={!areProfileInfoFieldsValid} />
          </div>
        </div>
      </div>
    </WizardWrap>
  );
};
