import { Button, RadioGroup, StarIcon } from '@fluentui/react-northstar';
import { Dialog, DialogFooter, DialogType, Overlay } from '@fluentui/react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/common/hooks';
import { Collaboration, Contact, OwnContactData, PhoneNumber } from 'app/pages/my-audience/contacts';
import {
  closeNumberDialog,
  ContactInfo,
  selectIsChoosingPhoneNumberDefaultState,
  selectIsNumberDialogOpened,
  selectSelectedContact,
  setIsChoosingPhoneNumberDefaultState,
  updateCollaborationRequested,
  updateOwnContactDataRequested
} from 'app/pages/my-audience/contact-profile';
import { selectIsOnDesktop, selectIsOnTouch } from 'app/common';
import { useIntl } from 'app/i18n';

export const PhoneToDialDialog = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const contact = useAppSelector<Contact>(selectSelectedContact);
  const primaryContactData: Collaboration | OwnContactData = contact.ownContactData?.isPrimary
    ? contact.ownContactData
    : contact.collaborations.find((c) => c.isPrimary);
  const isOnDesktop = useAppSelector<boolean>(selectIsOnDesktop);
  const isOnTouch = useAppSelector<boolean>(selectIsOnTouch);
  const isNumberDialogOpened = useAppSelector<boolean>(selectIsNumberDialogOpened);
  const isChoosingPhoneNumberDefaultState = useAppSelector<boolean>(selectIsChoosingPhoneNumberDefaultState);
  const [activePopup, setActivePopup] = useState(false);

  const dialogContentProps = {
    type: DialogType.normal,
    title: formatMessage({ id: 'contacts-phone-dialog.title' })
  };

  const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 1000 } },
    className: 'profile-panel-phone-dialog-wrap'
  };

  const checkedPhoneNumberChanged = (phoneNumber) => {
    setNumberToDial(phoneNumber);
  };

  const handleSetNumberAsFavourite = (name: string, collaborationId: string, isPrimary: boolean) => {
    const updatedContactData = {
      ...primaryContactData,
      landlinePhoneNumber: new PhoneNumber(primaryContactData?.landlinePhoneNumber?.value, name === 'phoneNumber' ? isPrimary : false),
      mobilePhoneNumber: new PhoneNumber(primaryContactData?.mobilePhoneNumber?.value, name === 'mobilePhoneNumber' ? isPrimary : false)
    };

    collaborationId
      ? dispatch(updateOwnContactDataRequested(updatedContactData as OwnContactData))
      : dispatch(updateCollaborationRequested(updatedContactData as Collaboration));

    dispatch(setIsChoosingPhoneNumberDefaultState(true));
  };

  const getPrimaryPhoneNumbers = (): ContactInfo[] => {
    const contactData = [];

    if (primaryContactData?.mobilePhoneNumber?.value) {
      contactData.push(
        new ContactInfo(
          'phoneNumber',
          'mobilePhoneNumber',
          formatMessage({ id: 'contact-data-labels.mobile-phone-number' }),
          primaryContactData?.mobilePhoneNumber?.value,
          primaryContactData?.mobilePhoneNumber?.isPrimary
        )
      );
    }

    if (primaryContactData?.landlinePhoneNumber?.value) {
      contactData.push(
        new ContactInfo(
          'phoneNumber',
          'phoneNumber',
          formatMessage({ id: 'contact-data-labels.phone-number' }),
          primaryContactData?.landlinePhoneNumber?.value,
          primaryContactData?.landlinePhoneNumber?.isPrimary
        )
      );
    }

    return contactData;
  };

  const items = getPrimaryPhoneNumbers().map((contactData, index) => ({
    name: 'phoneNumber',
    key: `${contactData.fieldName}-${contactData.phoneNumber}-${index}`,
    label: (
      <div className="profile-panel-phone-inner">
        <p className="profile-panel-phone-item">{contactData.mediumName}</p>
        <p className="profile-panel-phone-item">{contactData.phoneNumber}</p>
        <div className="profile-panel-phone-item profile-panel-phone-item-star">
          <Button
            icon={<StarIcon />}
            primary={contactData.isPrimary ? true : false}
            iconOnly
            size="medium"
            title={contactData.isPrimary ? 'Primary number' : 'Set as Primary number'}
            className={contactData.isPrimary ? 'button-star button-star--primary' : 'button-star'}
            onClick={() => handleSetNumberAsFavourite(contactData.fieldName, contactData.collaborationId, !contactData.isPrimary)}
            disabled={isChoosingPhoneNumberDefaultState}
          />
        </div>
      </div>
    ),
    value: `${contactData.phoneNumber}`
  }));

  const [numberToDial, setNumberToDial] = useState<number>(+items[0]?.value);

  const handleDismissDialog = () => {
    dispatch(closeNumberDialog());
  };

  return (
    <>
      {isOnDesktop && (
        <Dialog hidden={!isNumberDialogOpened} onDismiss={handleDismissDialog} dialogContentProps={dialogContentProps} modalProps={dialogModalProps}>
          <div className="profile-panel-phone-dialog">
            <div className="profile-panel-phone-header">
              <span className="profile-panel-phone-header-item">{formatMessage({ id: 'contact-data-labels.name' })}</span>
              <span className="profile-panel-phone-header-item">{formatMessage({ id: 'contact-data-labels.phone-number' })}</span>
              <span className="profile-panel-phone-header-item">{formatMessage({ id: 'contacts-phone-dialog.set-as-default' })}</span>
            </div>
            <div className="profile-panel-phone-content">
              <RadioGroup
                vertical
                defaultCheckedValue={items[0]?.value}
                items={items}
                onCheckedValueChange={(_, data) => {
                  checkedPhoneNumberChanged(data.value);
                }}
              />
            </div>
            <DialogFooter>
              <Button content="Cancel" onClick={handleDismissDialog} />
              <a href={`tel:+${numberToDial}`} target="_blank" rel="noreferrer">
                <Button primary content="Call" onClick={handleDismissDialog} />
              </a>
            </DialogFooter>
          </div>
        </Dialog>
      )}
      {isOnTouch &&
        activePopup && ( // TODO: Revisit responsive functionality
          <Overlay isDarkThemed className="bottom-popup-overlay profile-panel-phone-popup-wrap">
            <div className="bottom-popup-wrap">
              <div className="profile-panel-phone-dialog">
                <div className="profile-panel-phone-content">
                  <RadioGroup
                    vertical
                    defaultCheckedValue={items[0]?.value}
                    items={items}
                    onCheckedValueChange={(_, data) => {
                      checkedPhoneNumberChanged(data.value);
                    }}
                  />
                </div>
                <div className="profile-panel-phone-actions">
                  <Button content="Cancel" onClick={handleDismissDialog} />
                  <a href={`tel:+${numberToDial}`} target="_blank" rel="noreferrer">
                    <Button primary content="Call" onClick={handleDismissDialog} disabled={items.length === 0} />
                  </a>
                </div>
              </div>
              <span className="bottom-popup-line" aria-hidden="true"></span>
            </div>
          </Overlay>
        )}
    </>
  );
};
